import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '@portal/shared/pipes';
import { DatePickerModule } from '@portal/shared/ui/date-picker';
import {
  FilterAutoCompleteInputComponent,
  FilterCheckboxComponent,
  FilterRadioComponent,
  SearchBoxComponent,
  SharedUiFilterModule,
  SwitchToggleComponent,
} from '@portal/shared/ui/filter';
import { SharedUiModalModule } from '@portal/shared/ui/modal';
import { SpinnerModule } from '@portal/shared/ui/spinner';
import { TableModule } from '@portal/shared/ui/table';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';
import { FormModule } from '@portal/shared/ui/form';
import { AutofocusDirective } from './components/auto-focus/auto-focus.directive';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { CurrencyAmountComponent } from './components/currency-amount/currency-amount.component';
import { ExportCsvComponent } from './components/export-csv/export-csv.component';
import { FilterDateComponent } from './components/filter/date/date.component';
import { FilterReportTypeComponent } from './components/filter/report-type/report-type.component';
import { ReportTypes } from './list/report-type.list';
import { TooltipBlockingRulesComponent } from './components/tooltip/tooltip.component';
import { LocaleCurrencyDecimals } from './pipes/locale-currency-decimals/locale-currency-decimals.pipe';
import { LocaleDatePipe } from './pipes/locale-date/locale-date.pipe';
import { OperatorsWordsPipe } from './pipes/operators-words/operators-words.pipe';
import { TransactionPropertiesPipe } from './pipes/transaction-properties/transaction-properties.pipe';
import { TextControlComponent } from './components/text-control/text-control.component';
import { SelectControlComponent } from './components/select-control/select-control.component';
import { CurrenciesControlComponent } from './components/currencies-control/currencies-control.component';
import { FormBankAccountComponent } from './components/form-bank-account/form-bank-account.component';
import { CollapsiblePanelComponent } from './components/collapsible-panel/collapsible-panel.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ZeroStateComponent } from './components/zero-state/zero-state.component';
import { ComparableDateRangeModule } from '@portal/shared/ui/comparable-date-range';
import { KeyboardAccessibilityDirective } from './directives/keyboard-accessibility.directive';
import { InputCountComponent } from './components/input-count/input-count.component';
import { TextareaCountComponent } from './components/textarea-count/textarea-count.component';
import { LocaleAmountPipe } from './pipes/locale-amount/locale-amount.pipe';
import { ChannelControlComponent } from './components/channel-control/channel-control.component';
import { ListValuePipe } from './pipes/list-value/list-value.pipe';
import { UserFilterComponent } from './components/filter/user/user-filter.component';
import { PortalColorPickerComponent } from './components/portal-color-picker/portal-color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { DragAndDropDirective } from './directives/drag-and-drop/drang-and-drop.directive';
import { CurrencyAmountPipe } from './pipes/currency-amount/currency-amount.pipe';
import { FormValidatedDirective } from './directives/form-validated.directive';
import { TooltipModule } from '@portal/shared/ui/tooltip/src';
import { StepsIndicatorComponent } from './components/steps-indicator/steps-indicator.component';
import { FilterReportStatusComponent } from './components/filter/report-status/report-status.component';
import { FrequencyTypes } from './list/frequency.list';
import { FilterFrequencyComponent } from './components/filter/frequency/frequency.component';
import { ReportStatusTypes } from './list/report-status.list';
import { FilterTransactionReportTypeComponent } from './components/filter/transaction-report-type/transaction-report-type.component';
import { TransactionReportTypes } from './list/transaction-report-type.list';
import { TransactionTypes } from '@portal/entity-services/interfaces';
import { ProcessorTypes } from '@portal/entity-services/payment-contracts/src/lib/components/form/processor-type.list';
import { ListValuesPipe } from './pipes/list-values/list-values.pipe';
import { TransactionReportTypesNew } from './list/transaction-report-type-new.list';
import { FilterHeaderStateComponent } from '../modules/transactions/components/filter/header-state/header-state.component';
import { HeaderStates } from '../modules/transactions/components/filter/header-state/header-state.list';
import { FilterMerchantIdComponent } from '../modules/transactions/components/filter/merchant-id/merchant-id.component';
import { ProcessorComponent } from '../modules/transactions/components/filter/processor/processor.component';
import { Products } from '../modules/transactions/components/filter/product/product.list';
import { TransactionFilterList } from '../modules/transactions/components/filter/transaction-filters.list';
import { FilterTransactionTypeComponent } from '../modules/transactions/components/filter/transaction-type/transaction-type.component';
import { FilterCurrencyComponent } from '../modules/transactions/components/filter/currency/currency.component';
import { FilterChannelComponent } from '../modules/transactions/components/filter/channel/channel.component';
import { Channels } from '../modules/transactions/components/filter/channel/channel.list';
import { Currencies } from '../modules/transactions/components/filter/currency/currency.list';
import { FilterProductComponent } from '../modules/transactions/components/filter/product/product.component';
import { FilterTransactionDateComponent } from './components/filter/transaction-date/transaction-date.component';
import { TransactionDates } from './list/transaction-date.list';

export * from '@portal/shared/ui/table';

@NgModule({
  declarations: [
    ExportCsvComponent,
    CopyToClipboardComponent,
    TooltipBlockingRulesComponent,
    OperatorsWordsPipe,
    LocaleDatePipe,
    LocaleCurrencyDecimals,
    TransactionPropertiesPipe,
    LocaleAmountPipe,
    AutofocusDirective,
    CurrencyAmountComponent,
    FilterDateComponent,
    FilterReportTypeComponent,
    TextControlComponent,
    SelectControlComponent,
    CurrenciesControlComponent,
    FormBankAccountComponent,
    CollapsiblePanelComponent,
    ZeroStateComponent,
    KeyboardAccessibilityDirective,
    InputCountComponent,
    TextareaCountComponent,
    ChannelControlComponent,
    UserFilterComponent,
    ListValuePipe,
    ListValuesPipe,
    PortalColorPickerComponent,
    DragAndDropDirective,
    CurrencyAmountPipe,
    FormValidatedDirective,
    StepsIndicatorComponent,
    FilterFrequencyComponent,
    FilterReportStatusComponent,
    FilterTransactionReportTypeComponent,
    FilterChannelComponent,
    FilterCurrencyComponent,
    FilterProductComponent,
    FilterHeaderStateComponent,
    FilterTransactionTypeComponent,
    ProcessorComponent,
    FilterMerchantIdComponent,
    FilterTransactionDateComponent,
  ],
  imports: [
    // 3rd-party modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbDatepickerModule,
    NgSelectModule,
    // shared modules from libs
    PipesModule,
    SharedUiModalModule,
    SharedUiFilterModule,
    TooltipModule,
    TableModule,
    FormModule,
    DatePickerModule,
    ColorPickerModule,
    SpinnerModule,
    CurrencyMaskModule,
    ComparableDateRangeModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    ReportTypes,
    FrequencyTypes,
    ReportStatusTypes,
    TransactionReportTypes,
    TransactionReportTypesNew,
    TransactionFilterList,
    Channels,
    Currencies,
    Products,
    HeaderStates,
    TransactionTypes,
    ProcessorTypes,
    TransactionDates,
  ],
  exports: [
    // re-export shared modules from libs
    PipesModule,
    SharedUiModalModule,
    SharedUiFilterModule,
    TableModule,
    DatePickerModule,
    ColorPickerModule,
    SpinnerModule,
    // export declared components, pipes and directives
    ExportCsvComponent,
    SearchBoxComponent,
    FilterAutoCompleteInputComponent,
    FilterCheckboxComponent,
    FilterRadioComponent,
    CopyToClipboardComponent,
    TooltipBlockingRulesComponent,
    OperatorsWordsPipe,
    LocaleDatePipe,
    LocaleCurrencyDecimals,
    TransactionPropertiesPipe,
    LocaleAmountPipe,
    AutofocusDirective,
    CurrencyAmountComponent,
    FilterDateComponent,
    FilterReportTypeComponent,
    FormBankAccountComponent,
    CollapsiblePanelComponent,
    CurrenciesControlComponent,
    SelectControlComponent,
    TextControlComponent,
    ZeroStateComponent,
    ComparableDateRangeModule,
    KeyboardAccessibilityDirective,
    InputCountComponent,
    ChannelControlComponent,
    InputCountComponent,
    TextareaCountComponent,
    UserFilterComponent,
    ListValuePipe,
    ListValuesPipe,
    PortalColorPickerComponent,
    DragAndDropDirective,
    SwitchToggleComponent,
    CurrencyAmountPipe,
    FormValidatedDirective,
    StepsIndicatorComponent,
    FilterFrequencyComponent,
    FilterReportStatusComponent,
    FilterTransactionReportTypeComponent,
    FilterChannelComponent,
    FilterCurrencyComponent,
    FilterProductComponent,
    FilterHeaderStateComponent,
    FilterTransactionTypeComponent,
    ProcessorComponent,
    FilterMerchantIdComponent,
    FilterTransactionDateComponent,
  ],
})
export class SharedModule {}
