export enum Brand {
  Amex = 'AMEX',
  Cb = 'CB',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Jal = 'JAL',
  Jcb = 'JCB',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Switch = 'SWITCH',
  Visa = 'VISA',
  Upi = 'UPI',
  Other = 'OTHER',
  Debit = 'DEBIT',
  FrenziCard = 'FRENZICARD',
  CardLink = 'CARDLINK',
  Farmlands = 'FARMLANDS',
  Farmland = 'FARMLAND',
  Qcard = 'Q_CARD',
  Ruralco = 'RURALCO',
  Rd1com = 'RD1_COM',
  SuperCard = 'SUPERCARD',
  SmartLoyalty = 'SMART_LOYALTY',
  Prowze = 'PROWZE',
  FlexiPay = 'FLEXIPAY',
  Ecardz = 'ECARDZ',
  HellsPizza = 'HELLS_PIZZA',
  SwipeCard = 'SWIPE_CARD',
  GenieCard = 'GENIE_CARD',
  TrueRewards = 'TRUE_REWARDS',
  Activa = 'ACTIVA',
  FarmersFinance = 'FARMERS_FINANCE',
  BarterCard = 'BARTERCARD',
  AaSmartfuel = 'AA_SMARTFUEL',
  GiftCard = 'GIFT_CARD',
  Ezipay = 'EZIPAY',
  Tranxactor = 'TRANXACTOR',
  WestfieldGiftcard = 'WESTFIELD_GIFTCARD',
  MtaGiftcard = 'MTA_GIFTCARD',
  Ecard = 'ECARD',
  BpGiftCard = 'BP_GIFT_CARD',
  BpFuelCard = 'BP_FUELCARD',
  FpfGift = 'FPF_GIFT',
  CrmLoyalty = 'CRM_LOYALTY',
  CrmPrepaid = 'CRM_PREPAID',
  Easycd = 'EASYCD',
  TtCard = 'TT_CARD',
  Dankort = 'DANKORT',
  Bankaxept = 'BANKAXEPT',
  Ikano = 'IKANO',
  Resursbank = 'RESURS_BANK',
  GavekortSenter = 'GAVEKORT_SENTER',
  GavekortKjede = 'GAVEKORT_KJEDE',
  Sentrumgavekort = 'SENTRUMSGAVEKORT',
  Xponcard = 'XPONCARD',
  UniversalPresenkort = 'UNIVERSAL_PRESENTKORT',
  NetsSentergavekort = 'NETS_SENTERGAVEKORT',
  NetsKjedegavekort = 'NETS_KJEDEGAVEKORT',
  IgiveGavekortK = 'IGIVE_GAVEKORT_K',
  IgiveGavekortS = 'IGIVE_GAVEKORT_S',
  IgiveKampanjeK = 'IGIVE_KAMPANJE_K',
  IgiveKampanjeS = 'IGIVE_KAMPANJE_S',
  IgiveFordel = 'IGIVE_FORDEL',
  PayexGavekort = 'PAYEX_GAVEKORT',
  Gavekort1 = 'GAVEKORT_1',
  NgGavekort = 'NG_GAVEKORT',
  EvrySenter = 'EVRY_SENTER',
  EvryGavekort = 'EVRY_GAVEKORT',
  Kjedekort1 = 'KJEDEKORT_1',
  GavekortSenter2 = 'GAVEKORT_SENTER_2',
  CashcomPresentkort = 'CASHCOMSPRESENTKORT',
  PayexGavekortSe = 'PAYEX_GAVEKORT_SE',
  NetsMsGavekort35 = 'NETS_MS_GAVEKORT_35',
  NetsMsGavekort36 = 'NETS_MS_GAVEKORT_36',
  PayexGavekortDk = 'PAYEX_GAVEKORT_DK',
  PbsKjedekort = 'PBS_KJEDEKORT',
  CenterkortDk = 'CENTERKORT_DK',
  Gavekort = 'GAVEKORT',
  PbsCenterkort = 'PBS_CENTERKORT',
  PbsHandelsStandkort = 'PBS_HANDELSSTANDKORT',
  OberthurGavekort = 'OBERTHUR_GAVEKORT',
  RiksLunchen = 'RIKSLUNCHEN',
  RiksKortet = 'RIKSKORTET',
  SparXpres = 'SPARXPRES',
  Sepaelv = 'SEPAELV',
  Girocard = 'GIROCARD',
  IsraCard = 'ISRACARD',
  IsraelPrivateLabel = 'ISRAEL_PRIVATE_LABEL',
  Crypto = 'CRYPTO',
  Carnet = 'CARNET',
  BbbyCob = 'BBBY_COB',
  BbbyPlcc = 'BBBY_PLCC',
  Ebt = 'EBT',
  Landsbankinn = 'LANDSBANKINN',
  Flexecash = 'FLEXECASH',
  Forbrugsforeningen = 'FORBRUGSFORENINGEN',
  Plcc = 'PLCC',
  Wex = 'WEX',
  NetsKjedekort = 'NETS_KJEDEKORT',
  NetsCenterkort = 'NETS_CENTERKORT',
  NetsHandelsstandkor = 'NETS_HANDELSSTANDKOR',
  Clave = 'CLAVE',
  Edenred = 'EDENRED',
  Voyager = 'VOYAGER',
}
