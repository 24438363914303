import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MobileViewService {
  private setMobileDevice$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  mobileDevice: Observable<boolean> = this.setMobileDevice$.asObservable();
  constructor() {}
  setMobileFlagView(mobile: boolean): void {
    this.setMobileDevice$.next(mobile);
  }
}
