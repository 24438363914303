import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';
import { Observable } from 'rxjs';
import { CONSTANTS, CustomDataService } from '../../../core/core.module';
import { IReportsWithTotal } from '../interfaces/reports-with-total.interface';
import { ISftpConnection, ISftpConnectionResponse } from '../interfaces/sftp-connection.interface';
import { processQueryParams } from '@portal/shared/helpers/src/lib/api/process-query-params';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ReportEngineDataService extends CustomDataService<IReportsWithTotal> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super(CONSTANTS.REPORT_ENGINE.REPORTS, http, httpUrlGenerator);
  }

  getWithQueryString(query: string): Observable<IReportsWithTotal> {
    return this.execute('GET', `${CONSTANTS.REPORT_ENGINE.REPORTS}?${query}`);
  }

  getReport(reportUid: string): Observable<ArrayBuffer> {
    let error: Error | undefined;
    if (reportUid == null) {
      error = new Error(`No reportUid key to get`);
    }
    return this.execute('GET', `${CONSTANTS.REPORT_ENGINE.REPORTS}/${reportUid}`, error, {
      responseType: 'arraybuffer',
    });
  }

  postWithQueryString(query: any): Observable<IReportsWithTotal> {
    return this.execute('POST', `${CONSTANTS.REPORT_ENGINE.REPORTS_POST}`, query);
  }

  getReportTemplatesCount(): Observable<number> {
    return this.execute('GET', `${CONSTANTS.REPORT_ENGINE.TRANSACTION_REPORT_TEMPLATES}/count`);
  }

  getReportTemplates(query: { pageSize: number }): Observable<string> {
    return this.http.get<string>(`${CONSTANTS.REPORT_ENGINE.TRANSACTION_REPORT_TEMPLATES}`, {
      params: processQueryParams(query),
    });
  }

  getReportTemplatesWithCount(): Observable<string> {
    return this.getReportTemplatesCount().pipe(
      switchMap((count) => {
        const query = { pageSize: count };
        return this.getReportTemplates(query);
      }),
    );
  }
  postSftpConnection(connectionName: string, entityUid: string): Observable<ISftpConnection> {
    return this.execute('POST', `${CONSTANTS.REPORT_ENGINE.SFTP_CONNECTIONS}`, {
      connectionName,
      entityUid,
    });
  }

  getAllSftpConnections(): Observable<ISftpConnectionResponse> {
    return this.execute('GET', `${CONSTANTS.REPORT_ENGINE.SFTP_CONNECTIONS}`);
  }

  deleteSftpConnection(connectionName: string): Observable<ISftpConnection> {
    return this.execute('DELETE', `${CONSTANTS.REPORT_ENGINE.SFTP_CONNECTIONS}/${connectionName}`);
  }
}
