/* eslint-disable @typescript-eslint/naming-convention */
import { EnvironmentName } from './enums/env.enum';
import { Auth, IApiEndpoints, IReportList } from './interfaces';
import { Features } from './enums/features.enum';

const portalConf = window['portalConfig'];

export const REPORTS: IReportList = portalConf?.REPORTS || {
  'app-install-uninstall': '1592548776571',
  'app-status': '1608718615402',
  'device-status': '1592548799733',
  'app-usage': '1592548810910',
  'application-purchases-Installs-counts': '1594369420299',
  'app-summary': '1594896133396',
  'device-summary': '1595847117000',
  'audit-event': '1596708073289',
  'app-popularity': '1601018385689',
  'developer-apps-downloads': '1594369420220',
  'application-installation-status': '1606142638693',
  'applications-installation-summary': '1594896133778',
  'device-application-versions': '1600931721459',
  'merchant-status': '1608045056975',
  'merchant-summary': '1608044646271',
  'mcc-bundles': '1592548810562',
  'application-crash': '1592548810976',
  notifications: '1596101091352',
};

const API_ENDPOINT: IApiEndpoints = portalConf?.API_ENDPOINT || {
  VF_APP_CATALOG: 'https://qat.test-gsc.vfims.com/oidc/vfappcatalog',
  VF_MARKETPLACE: 'https://qat.test-gsc.vfims.com/oidc/vfappmarket',
  ENTITY_SERVICE: 'https://qat.portal.test-gsc.vfims.com/oidc/ds-entity-service',
  RECEIPT_SERVICE: 'https://qat.portal.test-gsc.vfims.com/oidc/receipt-service',
  PDSP: 'https://qat.portal.test-gsc.vfims.com/oidc/dsapiquery',
  ECOM: 'https://qat.portal.test-gsc.vfims.com/oidc/api/v2',
  PAYPAL_ECOM: 'https://qat.portal.test-gsc.vfims.com/oidc/paypal-ecom',
  PUBLIC_KEY_MANAGEMENT: 'https://qat.portal.test-gsc.vfims.com/oidc/api/v2/key',
  ONBOARDING: 'https://qat.portal.test-gsc.vfims.com/oidc/api/v1/onboarding',
  ONBOARDING_PAYPAL: 'https://qat2.test-gsc.vfims.com/oidc/paypal-onboarding/v1/onboarding',
  APPLE_PAY_ONBOARDING: 'https://qat.portal.test-gsc.vfims.com/oidc/api/v2/apple-pay',
  VALIDATION_SERVICE: 'https://qat.portal.test-gsc.vfims.com/oidc/validation-service',
  REPORTS_SERVICE: 'https://qat2.test-gsc.vfims.com/oidc/vfreports',
  LOGGING_SERVICE: 'https://qat2.test-gsc.vfims.com/oidc/logging-service',
  VF_PETRO: 'https://petroqat-gsc.vficloud.net/oidc/pems',
  PETRO_PDRS: 'https://petroqat-gsc.vficloud.net/oidc/pdrs/v1',
  PETRO_VIPER_TABLE: 'https://petroqat-gsc.vficloud.net/oidc/pcms',
  JS_CLIENT: 'https://jsclient_qat.dimebox.com/jsclient.js',
  JS_CLIENT_CDN_URL: 'https://jsclient_qat.dimebox.com/static/jsclient/images/',
  CHECKOUT: 'https://qat.portal.test-gsc.vfims.com/oidc/checkout-service/v2',
  CHECKOUT_PUBLIC: 'https://qat.checkout.vficloud.net/v2',
  CUSTOMER: 'https://qat.test-gsc.vfims.com/oidc/customer-service/v2',
  ORDER_SERVICE: 'https://qat.portal.test-gsc.vfims.com/oidc/order-service',
  PORTFOLIO_SERVICE: 'https://qat.portal.test-gsc.vfims.com/oidc/portfolio-service',
  THREE_D_SECURE: 'https://qat.portal.test-gsc.vfims.com/oidc/3ds-service/v2',
  REPORT_ENGINE: 'https://qat.portal.test-gsc.vfims.com/oidc/report-engine/api/v1',
  REPORT_SCHEDULER: 'https://qat2.test-gsc.vfims.com/oidc/vfreports/v1',
  PORTAL_ANALYTIC: 'https://qat.portal.test-gsc.vfims.com/oidc/analytics/graphql',
  PUBLIC_CATALOG: {
    publicBucket: 'https://vf-wl-np-pub1.s3.eu-west-1.amazonaws.com/qat',
    publicApps:
      'https://vfappmakret-public-qa.s3.eu-west-2.amazonaws.com/ESTATE_OWNER_14ed3328-9184-4dd2-aae5-ff6497200324/appcatalog-available-apps-14ed3328-9184-4dd2-aae5-ff6497200324.json',
    publicBundles:
      'https://vfappmakret-public-qa.s3.eu-west-2.amazonaws.com/ESTATE_OWNER_14ed3328-9184-4dd2-aae5-ff6497200324/appcatalog-available-bundle-14ed3328-9184-4dd2-aae5-ff6497200324.json',
    publicAllAppsDetails:
      'https://vfappmakret-public-qa.s3.eu-west-2.amazonaws.com/ESTATE_OWNER_14ed3328-9184-4dd2-aae5-ff6497200324/appcatalog-apps-details-14ed3328-9184-4dd2-aae5-ff6497200324.json',
  },
  PUBLIC_APPMARKET: {
    publicBucketUrl: 'https://vfappmakret-public-qa.s3.eu-west-2.amazonaws.com',
  },
  FIN_OPS: 'https://qat.portal.test-gsc.vfims.com/oidc/vas/fin-ops',
  INVOICE: 'https://qat.portal.test-gsc.vfims.com/oidc/vas/invoice-service',
  EVENTS_SERVICE: 'https://qat.portal.test-gsc.vfims.com/oidc/event-service',
  RMS: 'https://qat.portal.test-gsc.vfims.com/oidc/rms/v1',
  CHARGEBACKS: 'https://qat.portal.test-gsc.vfims.com/oidc/vas/chargeback-service',
  UIS_INTERNAL: 'https://qat.portal.test-gsc.vfims.com/oidc/uis/v1',
  UIS_EXTERNAL: 'https://uis.us.qat.test-ic.vfims.com/v1/ext/users',
  RMS_ENTITY_SERVICE: 'https://qat.portal.test-gsc.vfims.com/oidc/rms/v1',
  SINGLE_USER_RMS_ENTITY_SERVICE:
    'https://qat.portal.test-gsc.vfims.com/oidc/ds-entity-service/entities',
  ERROR_LOGGING_SERVICE: 'https://qat.portal.test-gsc.vfims.com/oidc/api/logs/web',
  VHQ_ESTATE_OWNER_SERVICE:
    'https://qat.portal.test-gsc.vfims.com/oidc/IDS/DataServices/VHQRest.svc/MTS/api',
  BIN_SERVICE: 'https://qat.portal.test-gsc.vfims.com/oidc/bin-service/v1',
  KYC_LOOKUP_SERVICE: 'https://qat.portal.test-gsc.vfims.com/oidc/kyc-service',
};

export const PORTAL_APPS_ID = portalConf?.PORTAL_APPS_ID || {
  GTM: 'GTM-NRK7TQL',
};

const AUTH: Auth = portalConf?.AUTH || {
  OFFSET: 5000,
  TOKEN_TIMEOUT_FACTOR: 0.75,
  IFRAME_NAME: 'silent_refresh_iframe',
  PRODUCTION: true,
  CLIENT_ID: 'CommonPortal',
  REQUESTED_SCOPES: 'openid profile',
  AUTHORIZATION_ENDPOINT: 'https://uisqat1.test-vam.vfims.com/login',
  AUTHORIZATION_FORGOT_ENDPOINT: 'https://uisqat1.test-vam.vfims.com/forgot',
  MP_AUTHORIZATION_ENDPOINT: 'https://us.qat.test-ic.vfims.com:443/am/oauth2/authorize',
  SILENT_REFRESH_ENDPOINT: 'https://us.qat.test-ic.vfims.com:443/am/oauth2/authorize',
  TOKEN_ENDPOINT: 'https://us.qat.test-ic.vfims.com:443/am/oauth2/access_token',
  END_SESSION_ENDPOINT: 'https://us.qat.test-ic.vfims.com:443/am/oauth2/connect/endSession',
  LOGOUT_ENDPOINT: 'https://us.qat.test-ic.vfims.com/am/json/sessions/?_action=logout',
  CHANGE_PASSWORD_URL:
    'https://qat1.test-vam.vfims.com/XUI/?realm=%2FVerifoneUsers#profile/password',
  USER_INACTIVITY_TIMEOUT: 900000,
  USER_INACTIVITY_TIMEOUT_OFFSET: 120000,
  FRIC_MAX_IDLE_TIMEOUT: 1800000,
  FRIC_MAX_SESSION_TIMEOUT: 7200000,
};

// its important to export this variable
// for the compiler to have access to it
export const FEATURE_FLAGS = portalConf?.FEATURE_FLAGS || {
  [Features.SilentRefreshToken]: true,
  [Features.PortalWhiteLabeling]: true,
  [Features.BlockingRulesets]: true,
  [Features.PaymentActions]: true,
  [Features.VirtualTerminal]: true,
  [Features.PayByLink]: true,
  [Features.MobilePay]: true,
  [Features.Simulator]: true,
  [Features.LanguageSwitching]: true,
  [Features.AlwaysAutoCapture]: false, // Virtual Terminal Transactions
  [Features.TransactionDescription]: true, // Virtual Terminal Transactions
  [Features.ReportEngine]: true,
  [Features.PortalAnalytic]: true,
  [Features.SecureCardCapture]: true,
  [Features.PreAuthManualCapture]: true,
  [Features.VirtualTerminalTransactionType]: true,
  [Features.Settlements]: true,
  [Features.ApiKeysAuthentication]: true,
  [Features.OnboardingPaypal]: true,
  [Features.OrganisationServices]: true,
  [Features.FraudPreventionService]: true,
  [Features.MerchantCountryConfiguration]: true,
  [Features.MerchantConfiguration]: true,
  [Features.MerchantSettlement]: true,
  [Features.VASLiquidityOverview]: false,
  [Features.MerchantBalance]: true,
  [Features.Invoice]: true,
  [Features.WFInvoice]: true,
  [Features.WFMerchantBalance]: true,
  [Features.Notifications]: true,
  [Features.PblEmailDelivery]: true,
  [Features.PblSmsDelivery]: true,
  [Features.RecurringNonABSWeb]: false,
  [Features.ThreeDSecureProviderContracts]: true,
  [Features.ChangePassword]: true,
  [Features.Crypto]: true,
  [Features.DisputeManagementCardSchemes]: true,
  [Features.DisputeManagementReasonCodeManagement]: true,
  [Features.VHQCore]: true,
  [Features.ContentManagement]: true,
  [Features.DeviceManagement]: true,
  [Features.OnlineKeyManagement]: true,
  [Features.OfflineKeyManagement]: true,
  [Features.ParameterManagement]: true,
  [Features.SoftwareManagement]: true,
  [Features.TenantManagement]: true,
  [Features.AssetTracking]: true,
  [Features.DeviceSupport]: true,
  [Features.DeviceCare]: true,
  [Features.AutomatedDownloads]: true,
  [Features.ManualDownloads]: true,
  [Features.BasicDiagnostics]: true,
  [Features.AdvancedDiagnostics]: true,
  [Features.CheckoutThemes]: true,
  [Features.DemoAccount]: false,
  [Features.TlogViewer]: true,
  [Features.Accounts]: true,
  [Features.Roles]: true,
  [Features.Users]: true,
  [Features.Hierarchy]: true,
  [Features.Sites]: true,
  [Features.BulkSites]: true,
  [Features.SiteAssets]: true,
  [Features.SummaryReport]: true,
  [Features.DcrReport]: false,
  [Features.PluReport]: false,
  [Features.FuelReport]: false,
  [Features.DepartmentReport]: false,
  [Features.SiteConfiguration]: true,
  [Features.MobilePayment]: true,
  [Features.ProgramHostConfiguration]: true,
  [Features.MobileSiteConfiguration]: true,
  [Features.ViperTable]: true,
  [Features.PaymentDocuments]: true,
  [Features.HierarchyManagement]: true,
  [Features.GroupsManagement]: true,
  [Features.AuditLog]: true,
  [Features.TermsAndConditions]: true,
  [Features.WICOfflineReporting]: true,
  [Features.ReportScheduler]: true,
  [Features.PartnerCommissionReport]: true,
  [Features.NewLogo]: true,
  [Features.PreCheckThreeDSecureAuthorization]: false,
  [Features.SftpDelivery]: true,
  [Features.TransactionsReportTemplates]: true,
  [Features.GroupEntities]: true,
  [Features.UseUis]: true,
  [Features.ProcessorSettlement]: true,
  [Features.ReportingGroup]: true,
  [Features.TaxInputs]: true,
  [Features.Surcharge]: true,
  [Features.MerchantChoiceRouting]: true,
  [Features.CommerceKpi]: true,
  [Features.SettlementDateFilter]: true,
};

// threshold for PDSP's export to csv
export const PDSP_EXPORT_TRANSACTION_THRESHOLD =
  portalConf?.PDSP_EXPORT_TRANSACTION_THRESHOLD || 10000;
export const TXNCOUNTLIMIT_ASYNCREPORT = portalConf?.TXNCOUNTLIMIT_ASYNCREPORT || 10000;
export const MOP_AUTOSAVE_ORDER_INTERVAL = portalConf?.MOP_AUTOSAVE_ORDER_INTERVAL || 120000;
export const mockVHQ = portalConf?.mockVHQ ?? false;
export const CBA = portalConf?.CBA ?? false;
export const TERMS_AND_CONDITIONS = portalConf?.TERMS_AND_CONDITIONS || {
  LEGAL_URL: 'https://www.verifone.com/en/global/legal',
  DOCUMENTATION_URL: 'https://verifone.cloud/',
  API_REF: 'https://verifone.cloud/api-catalog',
  TC_URL: 'https://www.verifone.com/sites/default/files/Verifone_Central_Terms_of_Use.pdf',
  PP_URL: 'https://www.verifone.com/en/global/legal/privacy-policy',
  TC_VERSION: '2023-03-14',
};

export const IONIC = portalConf?.IONIC || {
  ON: false,
  WL: {
    domainName: 'localhost',
  },
};

export default {
  PRODUCTION: true,
  AUTH: AUTH,
  API_ENDPOINT,
  FEATURE_FLAGS,
  PDSP_EXPORT_TRANSACTION_THRESHOLD,
  TXNCOUNTLIMIT_ASYNCREPORT,
  ENVIRONMENT_NAME: EnvironmentName.QAT,
  PORTAL_APPS_ID,
  REPORTS,
  mockVHQ,
  MOP_AUTOSAVE_ORDER_INTERVAL,
  CBA: CBA,
  TERMS_AND_CONDITIONS,
  IONIC,
};
