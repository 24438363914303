import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DatePositions, DateService } from '@portal/shared/helpers';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { differenceInHours } from 'date-fns';

declare const $localize;

@Component({
  selector: 'portal-date-picker',
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() toDate: NgbDate;
  @Input() fromDate: NgbDate;
  @Input() futureMaxDate: NgbDate;
  @Output() fromDateApplied: EventEmitter<NgbDate> = new EventEmitter();
  @Output() toDateApplied: EventEmitter<NgbDate> = new EventEmitter();
  @Output() datePickerErrors: EventEmitter<string[]> = new EventEmitter();
  @Output() isInvalidDate?: EventEmitter<boolean> = new EventEmitter();
  dateFormat = this.dateService.getPlaceholder();
  hoveredDate: NgbDate;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _fromDate: NgbDate;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _dateToShow: NgbDate;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _toDate: NgbDate;
  maxDate: NgbDate;
  pendingFromDateString = '';
  pendingToDateString = '';

  fromDateValue$ = new Subject<string>();
  toDateValue$ = new Subject<string>();

  fromDateErrorMessages = [];
  toDateErrorMessages = [];
  localFormatDate: DatePositions;
  errorDates = {
    dateRequired: false,
    dateInvalid: false,
    startDateGreater: false,
    dateGreaterThanMaximum: false,
  };

  constructor(
    private dateService: DateService,
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
  ) {
    this.maxDate = calendar.getToday();
  }

  ngOnInit(): void {
    // Set fromDate from input if given
    // Otherwise use default one, which is today-10days
    if (this.fromDate) {
      this._fromDate = this.fromDate;
    } else {
      this._fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 10);
    }

    // Make sure that it shows last month and current month dates
    // Since future dates are disabled
    this._dateToShow = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);

    // Set toDate from input if given
    // Otherwise use default one, which is today
    if (this.toDate) {
      this._toDate = this.toDate;
    } else {
      this._toDate = this.calendar.getToday();
    }

    setTimeout(() => {
      this.fromDateApplied.emit(this._fromDate);
      this.toDateApplied.emit(this._toDate);
    }, 0);

    this.fromDateChange(this.fromDateValue$).subscribe((updatedValue: NgbDate) => {
      if (updatedValue.day && updatedValue.month && updatedValue.year) {
        this._fromDate = updatedValue;
        this.validateFromDate();
        this.fromDateApplied.emit(this._fromDate);
      } else {
        this.pendingFromDateString = '';
      }
    });
    this.toDateChange(this.toDateValue$).subscribe((updatedValue: NgbDate) => {
      this._toDate = updatedValue;
      this.validateToDate();
      this.toDateApplied.emit(this._toDate);
    });

    this.localFormatDate = this.dateService.getLocalFormatElements();
  }

  ngOnChanges(): void {
    this.maxDate = this.futureMaxDate ? this.futureMaxDate : this.maxDate;
    this._fromDate = this.fromDate || this.calendar.getPrev(this.calendar.getToday(), 'd', 10);
    this._toDate = !this.fromDate
      ? this.toDate || this.calendar.getToday()
      : this.toDate || this._toDate;
    this._dateToShow = this._fromDate;
    this.validateToDate();
    this.isInvalidDate.emit(
      this.fromDateErrorMessages.length > 0 || this.toDateErrorMessages.length > 0,
    );
  }

  fromDateChange(value: Observable<string>): Observable<NgbDate> {
    return this.dateChange(value);
  }

  toDateChange(value: Observable<string>): Observable<NgbDate> {
    return this.dateChange(value);
  }

  dateChange(value: Observable<string>): Observable<NgbDate> {
    return value.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((newValue: string) => {
        return this.getUpdatedValue(newValue);
      }),
    );
  }

  getUpdatedValue(newValue: string): Observable<NgbDate> {
    const newValueArray = this.dateService.splitDate(newValue);
    const updatedValue = NgbDate.from({
      day: Number(newValueArray[this.localFormatDate.dayPost]),
      month: Number(newValueArray[this.localFormatDate.monthPost]),
      year: Number(newValueArray[this.localFormatDate.yearPost]),
    });

    return of(updatedValue);
  }

  onDateSelection(date: NgbDate): void {
    // If the toDate is not set
    if (!this._toDate) {
      if (date.before(this._fromDate)) {
        this._fromDate = date;
        this.validateFromDate();
        this.fromDateApplied.emit(this._fromDate);
        return;
      }
      if (date.after(this._fromDate)) {
        this._toDate = date;
        this.validateToDate();
        this.toDateApplied.emit(this._toDate);
        return;
      }
    }

    // Enable same day selection
    if (this._fromDate && !this._toDate && date.equals(this._fromDate)) {
      this._toDate = date;
      this.validateToDate();
      this.toDateApplied.emit(this._toDate);
      return;
    }

    this._fromDate = date;
    this._toDate = null;
    this.validateFromDate();
    this.fromDateApplied.emit(this._fromDate);
    this.validateToDate();
    this.toDateApplied.emit(null);
    return;
  }

  isHovered(date: NgbDate): boolean {
    return (
      this._fromDate &&
      !this._toDate &&
      this.hoveredDate &&
      date.after(this._fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate): boolean {
    return date.after(this._fromDate) && date.before(this._toDate);
  }

  isFirst(date: NgbDate): boolean {
    return date.equals(this._fromDate);
  }

  isLast(date: NgbDate): boolean {
    return this._toDate
      ? date.equals(this._toDate)
      : this.hoveredDate?.after(this._fromDate) && date.equals(this.hoveredDate);
  }

  isRange(date: NgbDate): boolean {
    return (
      date.equals(this._fromDate) ||
      date.equals(this._toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(input: NgbDate): string[] {
    const errors = [];
    this.fromDateErrorMessages = [];
    this.toDateErrorMessages = [];
    this.errorDates = {
      dateRequired: false,
      dateInvalid: false,
      startDateGreater: false,
      dateGreaterThanMaximum: false,
    };
    const fromDate = this._fromDate
      ? new Date(this._fromDate.year, this._fromDate.month - 1, this._fromDate.day)
      : '';
    const toDate = this._toDate
      ? new Date(this._toDate.year, this._toDate.month - 1, this._toDate.day)
      : '';

    if (!input) {
      this.errorDates.dateRequired = true;
      errors.push($localize`Date is required`);
      return errors;
    }

    // Make sure that the validation runs in order and stop first
    if (isNaN(Date.UTC(input.year, input.month - 1, input.day))) {
      this.errorDates.dateInvalid = true;
      errors.push($localize`Date is invalid`);
    }

    if (fromDate && toDate && differenceInHours(fromDate, toDate) > 0) {
      this.errorDates.startDateGreater = true;
      errors.push($localize`Start date is greater than end date`);
    } else if (input.after(this.maxDate)) {
      this.errorDates.dateGreaterThanMaximum = true;
      errors.push($localize`Date is greater than maximum date`);
    }

    if (!this.calendar.isValid(input)) {
      this.errorDates.dateInvalid = true;
      errors.push($localize`Date is invalid`);
    }
    this.datePickerErrors.emit(errors);
    return errors;
  }

  onToDateChange(event): void {
    this.pendingToDateString = event.target.value;
    this.toDateValue$.next(this.pendingToDateString);
  }

  onFromDateChange(event): void {
    this.pendingFromDateString = event.target.value;
    this.fromDateValue$.next(this.pendingFromDateString);
  }

  validateFromDate(): void {
    this.fromDateErrorMessages = this.validateInput(this._fromDate);
  }

  validateToDate(): void {
    this.toDateErrorMessages = this.validateInput(this._toDate);
    // Displaying message error in startDate while input value in EndDate
    if (this.errorDates.startDateGreater && this.fromDateValue) {
      this.fromDateErrorMessages = this.validateInput(this._fromDate);
    }
  }

  get fromDateValue(): string {
    if (!this._fromDate) {
      this.pendingFromDateString = '';
    }
    if (!this.fromDateErrorMessages.length) {
      return this.formatter.format(this._fromDate);
    } else {
      return this.pendingFromDateString;
    }
  }

  get toDateValue(): string {
    if (!this._toDate) {
      this.pendingToDateString = '';
    }
    if (!this.toDateErrorMessages.length) {
      return this.formatter.format(this._toDate);
    } else {
      return this.pendingToDateString;
    }
  }
}
