/* This list was communicated to RMS team */

export enum Features {
  PaymentsPortalCore = 'PAYMENTS_PORTAL_CORE',
  PaymentActions = 'PAYMENT_ACTIONS',
  VirtualTerminal = 'VIRTUAL_TERMINAL',
  PayByLink = 'PAY_BY_LINK',
  ReportEngine = 'REPORT_ENGINE',
  PortalAnalytic = 'PORTAL_ANALYTIC',
  Settlements = 'SETTLEMENTS',
  TokenScopes = 'TOKEN_SCOPES',
  ThreeDSecureProviderContracts = '3DS_PROVIDER_CONTRACTS',
  PreCheckThreeDSecureAuthorization = 'PRE_CHECK_3DS_AUTHORIZATION',
  Notifications = 'NOTIFICATIONS',
  CheckoutThemes = 'CHECKOUT_THEMES',
  FraudPreventionService = 'FRAUD_PREVENTION_SERVICE',
  PblEmailDelivery = 'PBL_EMAIL_DELIVERY',
  PblSmsDelivery = 'PBL_SMS_DELIVERY',
  RecurringNonABSWeb = 'RECURRING_NON_ABS_WEB',
  UseUis = 'USE_UIS',
  ProcessorSettlement = 'PROCESSOR_SETTLEMENT',
  ReportingGroup = 'REPORTING_GROUP',
  TaxInputs = 'TAX_INPUTS',
  Surcharge = 'SURCHARGE',
  MerchantChoiceRouting = 'MERCHANT_CHOICE_ROUTING',
  CommerceKpi = 'COMMERCE_KPI',
  SettlementDateFilter = 'SETTLEMENT_DATE_FILTER',

  Crypto = 'CRYPTO',
  // FinOps Features
  MerchantCountryConfiguration = 'MERCHANT_COUNTRY_CONFIGURATION',
  MerchantConfiguration = 'MERCHANT_CONFIGURATION',
  MerchantSettlement = 'MERCHANT_SETTLEMENT',
  VASLiquidityOverview = 'VAS_LIQUIDITY_OVERVIEW',
  Invoice = 'INVOICE',
  MerchantBalance = 'MERCHANT_BALANCE',
  // WF
  WFInvoice = 'WF_INVOICE',
  WFMerchantBalance = 'WF_MERCHANT_BALANCE',
  // MOP Features
  MOPCore = 'MOP_CORE',
  // Petro Features
  PetroCore = 'PETRO_CORE',
  TlogViewer = 'TLOG_VIEWER',
  Accounts = 'ACCOUNTS',
  Roles = 'ROLES',
  Users = 'USERS',
  Hierarchy = 'HIERARCHY',
  Sites = 'SITES',
  BulkSites = 'BULK_SITES',
  SiteAssets = 'SITE_ASSETS',
  SummaryReport = 'SUMMARY_REPORT',
  DcrReport = 'DCR_REPORT',
  PluReport = 'PLU_REPORT',
  FuelReport = 'FUEL_REPORT',
  DepartmentReport = 'DEPARTMENT_REPORT',
  SiteConfiguration = 'SITE_CONFIGURATION',
  MobilePayment = 'MOBILE_PAYMENT',
  ProgramHostConfiguration = 'PROGRAM_HOST_CONFIGURATION',
  MobileSiteConfiguration = 'MOBILE_SITE_CONFIGURATION',
  ViperTable = 'VIPER_TABLE',
  ViperSiteView = 'VIPER_SITE_VIEW',
  Loyalty = 'LOYALTY',

  // VHQ Features
  VHQCore = 'VHQ_CORE',
  ContentManagement = 'CONTENT_MANAGEMENT',
  DeviceManagement = 'DEVICE_MANAGEMENT',
  OnlineKeyManagement = 'ONLINE_KEY_MANAGEMENT',
  OfflineKeyManagement = 'OFFLINE_KEY_MANAGEMENT',
  ParameterManagement = 'PARAMETER_MANAGEMENT',
  SoftwareManagement = 'SOFTWARE_MANAGEMENT',
  TenantManagement = 'TENANT_MANAGEMENT',
  AssetTracking = 'ASSET_TRACKING',
  DeviceSupport = 'DEVICE_SUPPORT',
  DeviceCare = 'DEVICE_CARE',
  AutomatedDownloads = 'AUTOMATED_DOWNLOADS',
  ManualDownloads = 'MANUAL_DOWNLOADS',
  BasicDiagnostics = 'BASIC_DIAGNOSTICS',
  AdvancedDiagnostics = 'ADVANCED_DIAGNOSTICS',
  HierarchyManagement = 'HIERARCHY_MANAGEMENT',
  GroupsManagement = 'GROUPS_MANAGEMENT',
  SkimDetectManagement = 'SKIMDETECT_MANAGEMENT',
  NotificationManagement = 'NOTIFICATION_MANAGEMENT',
  MessageManagement = 'MESSAGE_MANAGEMENT',
  ReportsManagement = 'REPORTS_MANAGEMENT',
  AlertsManagement = 'ALERT_MANAGEMENT',
  DeviceInitiatedDownloads = 'DEVICE_INITIATED_DOWNLOADS',
  BasicIntegrationManagement = 'BASIC_INTEGRATION_PLATFORM',

  // VHQ Features List Starts
  VHQ_Notifications = 'Notifications',
  VHQ_Dashboard = 'Dashboard',
  VHQ_Devices = 'Devices',
  VHQ_DeletedDevices = 'Deleted Devices',
  VHQ_Rollouts = 'Rollouts',
  VHQ_Hierarchies = 'Hierarchies',
  VHQ_Groups = 'Groups',
  VHQ_Tenants = 'Tenants',
  VHQ_Locations = 'Locations',
  VHQ_SoftwareLibrary = 'Software Library',
  VHQ_Alerts = 'Alerts',
  VHQ_AlertSubscriptions = 'Alert Subscriptions',
  VHQ_AlertConfigurations = 'Alert Configurations',
  VHQ_Sponsors = 'Sponsors',
  VHQ_KeyHandles = 'Key Handles',
  VHQ_ChainOfCustody = 'Chain of Custody',
  VHQ_AuditLog = 'Audit Log',
  VHQ_StandardReports = 'Standard Reports',
  VHQ_DeviceReport = 'Device Report',
  VHQ_ReferenceSets = 'Reference Sets',
  VHQ_SystemConfigurations = 'System Configurations',
  // VHQ Features List Ends

  // Marketplace Features
  MarketplaceCore = 'MARKETPLACE_CORE',
  // List of features that should be available by default and shouldn't be disabled
  SecureCardCapture = 'SECURE_CARD_CAPTURE',
  VirtualTerminalTransactionType = 'VIRTUAL_TERMINAL_TRANSACTION_TYPE',
  MobilePay = 'MOBILE_PAY',
  Simulator = 'SIMULATOR',
  LanguageSwitching = 'LANGUAGE_SWITCHING',
  AlwaysAutoCapture = 'ALWAYS_AUTO_CAPTURE',
  TransactionDescription = 'TRANSACTION_DESCRIPTION',
  PreAuthManualCapture = 'PRE_AUTH_MANUAL_CAPTURE',
  BlockingRulesets = 'BLOCKING_RULESETS',
  ApiKeysAuthentication = 'API_KEYS_AUTHENTICATION',
  OnboardingPaypal = 'ONBOARDING_PAYPAL',
  SilentRefreshToken = 'SILENT_REFRESH_TOKEN',
  PortalWhiteLabeling = 'PORTAL_WHITELABELING',
  OrganisationServices = 'ORGANISATION_SERVICES',
  ChangePassword = 'CHANGE_PASSWORD',
  DisputeManagementCardSchemes = 'DISPUTE_MANAGEMENT_CARD_SCHEMES',
  DisputeManagementReasonCodeManagement = 'DISPUTE_MANAGEMENT_REASON_CODE_MANAGEMENT',
  DemoAccount = 'DEMO_ACCOUNT',
  PaymentDocuments = 'PAYMENT_DOCUMENTS',
  AuditLog = 'AUDIT_LOG',
  TermsAndConditions = 'TERMS_AND_CONDITIONS',
  WICOfflineReporting = 'WIC_OFFLINE_REPORTING',
  ReportScheduler = 'REPORT_SCHEDULER',
  PartnerCommissionReport = 'PARTNER_COMMISSION_REPORT',
  NewLogo = 'NEW_LOGO',
  SftpDelivery = 'SFTP_DELIVERY',
  TransactionsReportTemplates = 'TRANSACTIONS_REPORT_TEMPLATES',
  GroupEntities = 'GROUP_ENTITIES',
  Sponsor = 'SPONSOR',
  Transaction = 'transaction',
  Order = 'order',
}

/* Phase 2 features - should be communicated to RMS team
  ThreeDSecureAuthenticationReports = '3DS_AUTHENTICATION_REPORTS',
*/
