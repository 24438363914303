<div
  [ngClass]="{ 'is-active': activeState, modal: true }"
  cdkTrapFocus="{{ activeState }}"
  data-e2e="modal"
>
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="card">
      <div class="card-content">
        <p data-e2e="title" class="title is-4">{{ title }}</p>
        <button
          class="modal-close is-large"
          aria-label="close"
          data-e2e="modal-close-button"
          (click)="close()"
          title="{{ description }}"
          type="button"
        ></button>
        <p data-e2e="modal-description">
          {{ description }}
        </p>
        <br />
        <!-- Buttons designed for the entities -->
        <div class="level-right is-12" *ngIf="cancelOptionalEntities && cancelLabel">
          <span class="container is-3">
            <button
              *ngIf="cancelOptionalEntities"
              data-e2e="modal-cancel-optional-button"
              class="level-item button is-info has-text-left"
              (click)="close()"
              title="{{ cancelOptionalEntities }}"
              type="button"
            >
              {{ cancelOptionalEntities }}
            </button>
          </span>

          <span *ngIf="confirmLabelEnableEntity" class="container level-right">
            <button
              data-e2e="modal-cancel-button"
              class="level-item button is-info is-outlined"
              (click)="close()"
              title="{{ cancelLabel }}"
              type="button"
            >
              {{ cancelLabel }}
            </button>

            <button
              data-e2e="modal-confirm-button"
              class="level-item button is-primary"
              (click)="confirm()"
              title="{{ confirmLabelEnableEntity }}"
              type="button"
            >
              {{ confirmLabelEnableEntity }}
            </button>
          </span>

          <span *ngIf="confirmLabelDisableEntity" class="container level-right">
            <button
              data-e2e="modal-confirm-button"
              class="level-item button is-danger is-outlined"
              (click)="confirm()"
              title="{{ confirmLabelDisableEntity }}"
              type="button"
            >
              {{ confirmLabelDisableEntity }}
            </button>

            <button
              data-e2e="modal-cancel-button"
              class="level-item button is-primary"
              (click)="close()"
              title="{{ cancelLabel }}"
              type="button"
            >
              {{ cancelLabel }}
            </button>
          </span>
        </div>

        <div class="level-right" *ngIf="!cancelOptionalEntities && confirmLabel && cancelLabel">
          <button
            data-e2e="modal-confirm-button"
            class="level-item button is-danger is-outlined"
            (click)="confirm()"
            title="{{ confirmLabel }}"
            type="button"
          >
            {{ confirmLabel }}
          </button>
          <button
            data-e2e="modal-cancel-button"
            class="level-item button is-primary"
            (click)="close()"
            title="{{ cancelLabel }}"
            type="button"
          >
            {{ cancelLabel }}
          </button>
        </div>
        <ng-content *ngIf="!confirmLabel && !cancelLabel"></ng-content>
      </div>
    </div>
  </div>
</div>
