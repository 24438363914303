import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISupportedProcessorSettings } from '../shared/interfaces/supported-processor-setting.interface';
import { ES_CONSTANTS as CONSTANTS } from '@portal/entity-services/interfaces';
import { UserService } from '@portal/shared/user';
import { IFilterVisibility } from '../shared/interfaces/filter-visibility.interface';

@Injectable({ providedIn: 'root' })
export class SupportedProcessorSettingsService {
  isProcessorFetchedSubject = new Subject();
  isProcessorFetched$ = this.isProcessorFetchedSubject.asObservable();
  private filterVisibility: IFilterVisibility = {
    isCurrencyVisible: true,
    isTransactionTypeVisible: true,
    isProductVisible: true,
    isProcessorVisible: true,
    isWalletVisible: true,
    isTransactionStateVisible: true,
  };
  constructor(private http: HttpClient, private userService: UserService) {}

  getSupportedProcessorSettings(entityUid: string = ''): Observable<ISupportedProcessorSettings> {
    if (!entityUid) {
      entityUid = this.userService.getEntityUid();
    }
    const url = `${CONSTANTS.ENTITY_SERVICE.ORGANISATION}${entityUid}/supportedProcessorSettings`;
    return this.http.get<ISupportedProcessorSettings>(url).pipe(map((response) => response));
  }

  setSupportedProcessorSettingsInSessionStorage(
    supportedProcessorSettings: ISupportedProcessorSettings,
  ): void {
    localStorage.setItem(
      'supported_processor_settings',
      JSON.stringify(supportedProcessorSettings),
    );
  }

  getSupportedProcessorSettingsFromSessionStorage(): ISupportedProcessorSettings {
    const supportedProcessorSettings = localStorage.getItem('supported_processor_settings');
    return JSON.parse(supportedProcessorSettings);
  }

  removeSupportedProcessorSettingsFromSessionStorage(): void {
    localStorage.removeItem('supported_processor_settings');
  }

  getFilterVisibility(): IFilterVisibility {
    return this.filterVisibility;
  }
  /** When the supported processor settings configuration for an EntityId is empty,
   * we don't limit filter items, we show them all as default behavior,
   * When in the configuration we have just one element we don't display on the UI,
   */
  updateFilterVisibility(): void {
    const supportedProcessorSettings = this.getSupportedProcessorSettingsFromSessionStorage();
    const supportedProcessorSettingsFilterMapping = {
      isCurrencyVisible: 'currenciesSupported',
      isTransactionTypeVisible: 'transactionTypesSupported',
      isProductVisible: 'paymentTypesSupported',
      isProcessorVisible: 'processorsSupported',
      isWalletVisible: 'walletsSupported',
      isTransactionStateVisible: 'transactionStatesSupported',
    };
    Object.keys(supportedProcessorSettingsFilterMapping).forEach((filterKey) => {
      const settingKey = supportedProcessorSettingsFilterMapping[filterKey];
      const setting = supportedProcessorSettings[settingKey];
      if (setting?.length === 1) {
        this.filterVisibility[filterKey] = false;
      }
    });
  }
}
