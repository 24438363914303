import { Pipe, PipeTransform } from '@angular/core';
import { cloneDeep } from 'lodash';

@Pipe({
  name: 'mopHighlight',
})
/**
 * Based on https://github.com/gmerabishvili/angular-ng-autocomplete/blob/master/projects/autocomplete-lib/src/lib/highlight.pipe.ts
 */
export class MopAutocompleteHighlightPipe implements PipeTransform {
  transform(element: any, searchTerm: string, attributes?: string[]): any {
    let pattern = searchTerm.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    pattern = pattern
      .split(' ')
      .filter((t) => {
        return t.length > 0;
      })
      .join('|');
    const regex = new RegExp(pattern, 'gi');

    if (!searchTerm) {
      return element;
    }

    // copy original object
    const elementCopy = { ...element };
    if (attributes) {
      attributes.forEach((attribute: string) => {
        let elementValue = cloneDeep(element[attribute]);
        if (typeof elementValue === 'string') {
          elementValue = elementValue.replace(regex, (match) => `<b>${match}</b>`);
        } else if (Array.isArray(elementValue) && elementValue.length > 0) {
          const descriptions: string[] = elementValue;
          for (let i = 0; i < descriptions.length; i++) {
            elementValue[i] = descriptions[i].replace(regex, (match) => `<b>${match}</b>`);
          }
        }
        // set bold value into attribute of copied object
        elementCopy[attribute] = elementValue;
      });
    }
    return elementCopy;
  }
}
