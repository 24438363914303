<div class="columns is-vcentered user-list">
  <table class="is-borderless width-full user-list-table">
    <tr>
      <td class="is-borderless width-is-four-fifths">
        <div class="column">
          <h1 class="title is-2" data-e2e="title" i18n="Page title">Users</h1>
        </div>
      </td>
      <td class="is-borderless width-is-one-fifth">
        <div class="columns mt-0 pr-4 has-text-right">
          <div class="column pl-0 pr-0" *ngIf="isGroupHierarchyPermitted()">
            <a
              #createGroupButton
              *restrictPermission="['add-organization-group']"
              [routerLink]="['/administration', 'organization-group', 'create']"
              class="button is-primary"
              data-e2e="link-new"
              i18n
            >
              Add new organization group
            </a>
          </div>
          <div class="column pl-0 pr-0">
            <a
              #createUserButton
              restrict-permission
              [routerLink]="['/administration', 'users', 'create']"
              class="button is-primary ml-2"
              data-e2e="link-new"
              i18n
              title="Add new user"
              i18n-title
            >
              Add new user
            </a>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>

<portal-filter-user
  *ngIf="!isGroupOrgListDisplayed"
  [filter]="filter"
  [filterTouched]="filterTouched"
  (usersFiltered)="onUserFiltered($event)"
  (usersFilterReset)="onUserFilterReset()"
  (userFilterChanged)="onUserFiltersChanged($event)"
>
</portal-filter-user>

<div class="columns">
  <div class="column is-10" *ngIf="!isGroupOrgListDisplayed">
    <p
      *ngIf="range"
      i18n="Number of users shown on the page vs total number of users"
    >
      {{ range }} of {{ totalCount }} users
    </p>
  </div>
  <ng-container
    *ngIf="isGroupHierarchyPermitted() && !isGroupOrgListDisplayed; then userGroupStatus"
  >
  </ng-container>
</div>

<div *ngIf="showGroupOrgList">
  <portal-list-organization-group
    [labelUsers]="labelUsers"
    [labelGroups]="labelGroups"
    (itemToggled)="toggleOrgUserOrGroupHierarchyUser()"
  >
  </portal-list-organization-group>
</div>

<ng-template #userGroupStatus>
  <div class="column is-2" *restrictPermission="['add-organization-group']">
    <table>
      <tr>
        <td>
          <span class="pr-2">{{ labelUsers }}</span>
        </td>
        <td>
          <div class="toggle">
            <input
              id="user-group-status"
              type="checkbox"
              [checked]="switchOn"
              (change)="toggleOrgUserOrGroupHierarchyUser()"
              data-e2e="user-group-status"
              class="switch is-rtl"
            />
            <label for="user-group-status" data-e2e="user-group-status-label-enabled">
              {{ labelGroups }}
            </label>
          </div>
        </td>
      </tr>
    </table>
  </div>
</ng-template>

<div class="users" *ngIf="!isGroupOrgListDisplayed">
  <portal-table
    [items]="users"
    [itemsClickable]="canGoToDetail"
    [loading$]="loading$"
    [filter]="filter"
    [tableConfig]="tableConfig"
    rowLinkHref="/administration/users"
    rowLinkHeading="Link to user details"
    i18n-rowLinkHeading="Link to user details"
    loadingMessage="Loading users"
    i18n-loadingMessage="Text that is shown while users are loading"
    notFoundMessage="No users found"
    i18n-notFoundMessage="Text that is shown when users are not found"
    (sortChanged)="onSortChanged($event)"
    tabindex="-1"
  ></portal-table>

  <portal-pagination
    [reset]="resetPagination"
    [itemsPerPage]="filter.limit"
    [totalItems]="totalCount"
    (paginationChanged)="onPagination($event)"
  ></portal-pagination>

  <ng-template #rolesTemplate let-user="data">
    <div *ngIf="isStatusDeleted(user.status)">-</div>
    <div class="has-text-truncate" *ngIf="!isStatusDeleted(user.status)">
      {{ user.roles | listValues : userRolesList }}
    </div>
  </ng-template>
</div>
