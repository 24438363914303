import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AppUpgrade } from './app-upgrade.model';
import { AppUpgradesState, AppUpgradesStore } from './app-upgrades.store';

@Injectable({
  providedIn: 'root',
})
export class AppUpgradesQuery extends QueryEntity<AppUpgradesState, AppUpgrade> {
  constructor(protected store: AppUpgradesStore) {
    super(store);
  }
}
