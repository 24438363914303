import { Component } from '@angular/core';

import { ITableConfig } from '@portal/shared/ui/table/src';
import { TRX_DATA_MOCK } from '../mock-data/wl-transaction-mock-data';

declare const $localize;

@Component({
  selector: 'portal-white-labeling-preview-content',
  templateUrl: './white-labeling-preview-content.component.html',
})
export class WhiteLabelingPreviewContentComponent {
  itemsList = TRX_DATA_MOCK;
  tableConfig: ITableConfig = {
    columns: [
      {
        key: 'createdDateTime',
        label: $localize`Created`,
        isSortable: true,
      },
      {
        key: 'referenceId',
        label: $localize`Reference`,
      },
      {
        key: 'organisationName',
        label: $localize`Organisation`,
      },
      {
        key: 'instrument[0].cardBrand',
        label: $localize`Product`,
      },
      {
        key: 'detailedAmount.totalAmount',
        label: $localize`Orig. amount`,
      },
      {
        key: 'paymentSummary.currentAmount.value',
        label: $localize`Curr. amount`,
      },
      {
        key: 'paymentSummary.displayTransactionType',
        label: $localize`Type`,
      },
      {
        key: 'paymentSummary.displayTransactionState',
        label: $localize`Status`,
      },
    ],

    rowIdKey: 'UUID',
    rowLabelKey: 'label',
  };
}
