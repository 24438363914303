import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(text: string, textToMatch: string): string {
    if (!textToMatch) {
      return text;
    }
    textToMatch = this.escapeTheCharacter(textToMatch);
    const re = new RegExp(`(${textToMatch})`, 'gi');
    return text.replace(re, '<span class="has-text-weight-bold">$1</span>');
  }

  escapeTheCharacter(text): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
}
