import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  DataServiceError,
  DefaultDataService,
  HttpMethods,
  QueryParams,
  RequestData,
} from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { of, throwError, Observable } from 'rxjs';
import { catchError, delay, map, timeout } from 'rxjs/operators';
import { ICheckEmail } from '../interfaces/check-email.interface';
import { ICount } from '../interfaces/count.interface';

export class CustomDataService<T> extends DefaultDataService<T> {
  patch(update: Update<T>): Observable<T> {
    const id = update && update.id;
    const updateOrError =
      id == null ? new Error(`No "${this.entityName}" update data or id`) : update.changes;
    return this.execute('PATCH', this.entityUrl + id, updateOrError);
  }

  getTotalCount(params?: QueryParams): Observable<ICount> {
    const method = 'GET';
    const url = `${this.entityUrl}count`;
    const options: any = params ? { params } : undefined;
    return this.execute(method, url, undefined, options).pipe(
      map((data) => {
        return data.count ? data : { count: data };
      }),
    );
  }

  checkEmailExist(params: QueryParams): Observable<ICheckEmail> {
    const method = 'GET';
    const url = `${this.entityUrl}checkEmail`;
    const options: any = params ? { params } : null;
    return this.execute(method, url, null, options).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  getByKeyWithQuery(key: string, queryParams: QueryParams | string): Observable<T> {
    let error: Error | undefined;

    if (!key) {
      error = new Error(`No "${this.entityName}" key to get`);
    }

    const qParams =
      typeof queryParams === 'string' ? { fromString: queryParams } : { fromObject: queryParams };
    const params = new HttpParams(qParams);
    return this.execute('GET', this.entitiesUrl + key, error, { params });
  }

  getDetailed(key: string): Observable<T> {
    let error: Error | undefined;

    if (!key) {
      error = new Error(`No "${this.entityName}" key to get`);
    }

    return this.execute('GET', this.entitiesUrl + key + '/detailed', error);
  }

  /**
   * Since the `execute` method doesn't support `PATCH` we need to copy/duplicate
   * the implementation of `PUT`. Since `handleError` and `handleDelete404` are
   * private method it was also necessary to make a copy of them.
   */

  protected execute(
    method: HttpMethods | 'PATCH',
    url: string,
    data?: any, // data, error, or undefined/null
    options?: any,
  ): Observable<any> {
    if (method !== 'PATCH') {
      return super.execute(method, url, data, options);
    }

    const req: RequestData | any = { method, url, data, options };

    if (data instanceof Error) {
      return this.handleErrorLocalCopy(req)(data);
    }

    let result$: Observable<ArrayBuffer>;

    result$ = this.http.patch(url, data, options);

    if (this.saveDelay) {
      result$ = result$.pipe(delay(this.saveDelay));
    }

    if (this.timeout) {
      result$ = result$.pipe(timeout(this.timeout + this.saveDelay));
    }

    return result$.pipe(catchError(this.handleErrorLocalCopy(req)));
  }

  private handleErrorLocalCopy(reqData: RequestData): (err: any) => Observable<{}> {
    return (err: any) => {
      const ok = this.handleDelete404LocalCopy(err, reqData);
      if (ok) {
        return ok;
      }
      const error = new DataServiceError(err, reqData);
      return throwError(error);
    };
  }

  private handleDelete404LocalCopy(error: HttpErrorResponse, reqData: RequestData): Observable<{}> {
    if (error.status === 404 && reqData.method === 'DELETE' && this.delete404OK) {
      return of({});
    }
    return undefined;
  }
}
