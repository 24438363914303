<div
  class="dropdown"
  id="langDropdown"
  [ngClass]="{
    'is-right': isNavbarItemMode,
    'is-active': isLanguageDropdownOpen
  }"
>
  <ng-container *ngIf="!isNavbarItemMode; else navbarItem">
    <div
      class="select"
      (keyup.enter)="openLanguageDropDown()"
      (keyup.esc)="closeLanguageDropDown()"
    >
      <button
        type="button"
        id="langButton"
        class="button is-chip is-middle"
        [class.has-border-primary]="isLanguageButtonFocused"
        data-e2e="select-locale"
        (click)="toggleLanguageDropdown()"
        (mousedown)="onMouseDown($event)"
        (keyup.enter)="openLanguageDropDown()"
        (keyup.esc)="closeLanguageDropDown()"
        tabindex="0"
      >
        <span selected data-e2e="authentication-filter-label" class="pr-3">{{ label }}</span>
      </button>
    </div>
  </ng-container>

  <ng-template #navbarItem>
    <div
      class="p-2 mr-2"
      (click)="toggleLanguageDropdown($event)"
      (keyup.enter)="openLanguageDropDown()"
      (keyup.esc)="closeLanguageDropDown()"
      tabindex="0"
    >
      <span data-e2e="authentication-filter-label">{{ label }}</span>
      <span class="arrow ml-1">
        <i class="fas fa-chevron-down" aria-hidden="true"></i>
      </span>
    </div>
  </ng-template>

  <div class="dropdown-menu">
    <div class="dropdown-content">
      <div class="dropdown-item">
        <portal-search-filter-autocomplete-input
          data-e2e="authentication-filter-search-input"
          (search)="onSearch($event)"
          (keyup.esc)="closeLanguageDropDown()"
          placeholder="Search for language"
          i18n-placeholder
        ></portal-search-filter-autocomplete-input>
      </div>

      <hr class="dropdown-divider" />

      <div class="dropdown-item is-scrollable p-0" tabindex="-1">
        <a
          class="dropdown-item langListButton"
          *ngFor="let language of filteredLanguages; let i = index"
          attr.data-e2e="authentication-filter-dropdown-item-{{ i }}"
          [class.is-active]="selectedLanguage.id === language.id"
          (click)="languageSelected.emit(language)"
          (keydown.enter)="languageSelected.emit(language)"
          (keyup.enter)="closeLanguageDropDown()"
          (keyup.esc)="closeLanguageDropDown()"
          tabindex="0"
        >
          <span
            *ngIf="searchedText"
            innerHTML="{{ language.text | highlight : searchedText }}"
          ></span>
          <span *ngIf="!searchedText"> {{ language.text }} </span>
        </a>
      </div>

      <hr class="dropdown-divider" />

      <div class="dropdown-item" *ngIf="!hideApplyButton">
        <button
          class="button is-primary"
          data-e2e="authentication-filter-search-input"
          (click)="onFilterApplied()"
          type="button"
          i18n
          title="Apply"
          i18n-title
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</div>
