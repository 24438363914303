import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import sortBy from 'lodash-es/sortBy';
import { TransactionFilter } from '../../enums/transaction-filter.enum';

export const transactionFilterList = {
  [TransactionFilter.Channel]: $localize`Channel`,
  [TransactionFilter.Currency]: $localize`Currency`,
  [TransactionFilter.Product]: $localize`Product`,
  [TransactionFilter.TransactionType]: $localize`Transaction type`,
  [TransactionFilter.TransactionStatus]: $localize`Transaction status`,
  [TransactionFilter.Wallet]: $localize`Wallet`,
  [TransactionFilter.StoredCredentialType]: $localize`Stored Credential type`,
  [TransactionFilter.Processor]: $localize`Processor`,
  [TransactionFilter.AccountType]: $localize`Account type`,
  [TransactionFilter.TokenType]: $localize`Token type`,
  [TransactionFilter.Amount]: $localize`Amount`,
  [TransactionFilter.ReuseTokenContext]: $localize`Reuse Token Context`,
  [TransactionFilter.Origin]: $localize`Origin`,
  [TransactionFilter.FundingSource]: $localize`Funding Source`,
  [TransactionFilter.MerchantId]: $localize`Merchant ID`,
  [TransactionFilter.Organisation]: $localize`Organisation ID`,
  [TransactionFilter.Organization]: $localize`Organization`,
  [TransactionFilter.TransactionDate]: $localize`Transaction date`,
  [TransactionFilter.SettledTime]: $localize`Transaction date`,
  [TransactionFilter.CutOffTime]: $localize`Transaction date`,
};

@Injectable()
export class TransactionFilterList extends SelectInputsList {
  keyValue = transactionFilterList;
  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);
}
