import { Component, Input, OnInit } from '@angular/core';
import { vfappcatalog, IAppPromo } from '@portal/marketplace/api-interfaces/src';

@Component({
  selector: 'marketplace-app-promo',
  templateUrl: './app-promo.component.html',
  styleUrls: ['./app-promo.component.scss'],
})
export class AppPromoComponent implements OnInit {
  @Input() promo: IAppPromo;

  constructor() {}

  ngOnInit(): void {}
}
