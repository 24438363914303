import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UpdatesIndicatorComponent } from './updates-indicator.component';

@NgModule({
  imports: [CommonModule],
  declarations: [UpdatesIndicatorComponent],
  exports: [UpdatesIndicatorComponent],
})
export class UpdatesIndicatorModule {}
