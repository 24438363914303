import { Injectable } from '@angular/core';
import { IApp } from '@portal/marketplace/api-interfaces/src';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  extractAppIds,
  transformIAppVersionAndIVfAppMarketAppToIApp,
} from '../../helpers/app-catalog.helpers';
import { PublicAppCatalogDataService } from './public-app-catalog-data.service';
import { PublicAppCatalogQuery } from './public-app-catalog.query';
import { PublicAppCatalogStore } from './public-app-catalog.store';

@Injectable({ providedIn: 'root' })
export class PublicAppCatalogService {
  constructor(
    private publicAppCatalogStore: PublicAppCatalogStore,
    private publicAppCatalogDataService: PublicAppCatalogDataService,
    public publicAppCatalogQuery: PublicAppCatalogQuery,
  ) {}

  loadPblicAppCatalog(): void {
    if (this.publicAppCatalogQuery.hasEntity()) {
      return;
    }
    const vfAppMarketApps = this.publicAppCatalogDataService.getPublicAppsFromVfAppMarket();
    vfAppMarketApps
      .pipe(
        switchMap((appMarketApps) => {
          return this.publicAppCatalogDataService
            .getPublicAppsFromAppCatalog(extractAppIds(appMarketApps['data']))
            .pipe(
              tap((appsAppCatalog) => {
                this.publicAppCatalogStore.set(
                  //add data to apps store
                  transformIAppVersionAndIVfAppMarketAppToIApp(
                    appMarketApps['data'],
                    appsAppCatalog['data'],
                  ),
                );
              }),
            );
        }),
      )
      .subscribe();
  }
  getApps(): Observable<IApp[]> {
    return this.publicAppCatalogQuery.selectAll();
  }

  getApp(id: string): Observable<IApp> {
    return this.publicAppCatalogQuery.selectEntity(id);
  }
}
