<div class="field">
  <div class="is-half">
    <label class="field">
      <div class="label" i18n>Address line 1</div>
      <div class="control">
        <input
          class="input"
          [formControl]="addressLine1"
          attr.data-e2e="{{ name }}-input-addressLine1"
          type="text"
          [ngClass]="{ 'is-danger': isInvalid(addressLine1) }"
        />
        <portal-form-error [formInput]="addressLine1" errorId="addressLine1"></portal-form-error>
      </div>
    </label>
  </div>
</div>

<div class="field">
  <div class="is-half">
    <label class="field">
      <div class="label">
        <span i18n>Address line 2</span>
        <span>&nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span></span>
      </div>
      <div class="control">
        <input
          class="input"
          [formControl]="addressLine2"
          attr.data-e2e="{{ name }}-input-addressLine2"
          type="text"
        />
        <portal-form-error [formInput]="addressLine2" errorId="addressLine2"></portal-form-error>
      </div>
    </label>
  </div>
</div>

<div class="columns is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd">
  <div class="column">
    <label class="label" i18n>Postal code</label>
    <div class="field">
      <div class="control">
        <input
          class="input"
          [formControl]="postCode"
          attr.data-e2e="{{ name }}-input-postCode"
          type="text"
          [ngClass]="{ 'is-danger': isInvalid(postCode) }"
        />
        <portal-form-error [formInput]="postCode" errorId="postCode"></portal-form-error>
      </div>
    </div>
  </div>

  <div class="column">
    <label class="label" i18n>City</label>
    <div class="field">
      <div class="control">
        <input
          class="input"
          [formControl]="city"
          attr.data-e2e="{{ name }}-input-city"
          type="text"
          [ngClass]="{ 'is-danger': isInvalid(city) }"
        />
        <portal-form-error [formInput]="city" errorId="city"></portal-form-error>
      </div>
    </div>
  </div>
</div>

<div class="field">
  <div class="is-half">
    <label class="field">
      <div class="label" i18n>Country</div>
      <ng-select
        [items]="countries"
        bindValue="alpha3"
        bindLabel="name"
        [multiple]="false"
        [closeOnSelect]="true"
        [formControl]="country"
        attr.data-e2e="{{ name }}-select-country"
        (change)="onCountrySelected($event)"
        notFoundText="No items found"
        i18n-notFoundText
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.name }}
        </ng-template>
      </ng-select>

      <portal-form-error [formInput]="country" errorId="country"></portal-form-error>
    </label>
  </div>
</div>

<div class="field" *ngIf="!canSkipStateAndProvince">
  <div class="is-half">
    <label class="field">
      <div class="label" i18n>Province / State</div>
      <ng-select
        [items]="countrySubdivisions"
        bindValue="name"
        bindLabel="name"
        [multiple]="false"
        [closeOnSelect]="true"
        [formControl]="countrySubdivision"
        attr.data-e2e="{{ name }}-select-countrySubdivision"
        notFoundText="No items found"
        i18n-notFoundText
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.name }}
        </ng-template>
      </ng-select>
      <portal-form-error
        [formInput]="countrySubdivision"
        errorId="countrySubdivision"
      ></portal-form-error>
    </label>
  </div>
</div>

<div
  class="columns is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
  *ngIf="coordinates"
>
  <div class="column">
    <label class="label">
      <span i18n>Latitude</span>
      <span>&nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span></span>
    </label>
    <div class="field">
      <div class="control">
        <input
          class="input"
          [formControl]="latitude"
          attr.data-e2e="{{ name }}-input-latitude'"
          type="text"
          [ngClass]="{ 'is-danger': isInvalid(latitude) }"
        />
        <portal-form-error [formInput]="latitude" errorId="latitude"></portal-form-error>
      </div>
    </div>
  </div>

  <div class="column">
    <label class="label">
      <span i18n>Longitude</span>
      <span>&nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span></span>
    </label>
    <div class="field">
      <div class="control">
        <input
          class="input"
          [formControl]="longitude"
          attr.data-e2e="{{ name }}-input-longitude'"
          type="text"
          [ngClass]="{ 'is-danger': isInvalid(longitude) }"
        />
        <portal-form-error [formInput]="longitude" errorId="longitude"></portal-form-error>
      </div>
    </div>
  </div>
</div>
