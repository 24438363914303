import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ISelectInput } from '@portal/shared/ui/filter';
import { SearchLimit } from '../../../modules/transactions/enums/search-limit-threshold.enum';
import { CountLimitAsyncReport } from '../../../modules/transactions/enums/count-limit-asyncreport.enum';
import { ExportOptions } from './enums/export-options.enum';
import { ITransactionReportTemplate } from '../../../modules/transactions/interfaces/transaction-report-template.interface';
import { ReportTemplateFieldTypes } from '../../../modules/report-templates/services/report-template-fields.list';
import { NotifierService } from '@portal/shared/ui/notifier';
import { TransactionReportType } from '../../enum/transaction-report-type.enum';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';
import { Features } from 'environments/enums/features.enum';
import { MobileViewService } from '../../../services/mobile-view.service';
import { ReportTemplateFieldColumnName } from '../../../modules/report-templates/enums/report-template-field-column-name.enum';
import { TransactionsPathEnum } from '../../../modules/transactions/enums/transactions-path-enum';

@Component({
  selector: 'portal-export-csv',
  templateUrl: './export-csv.component.html',
  styleUrls: ['./export-csv.component.scss'],
})
export class ExportCsvComponent implements OnInit {
  @Input() totalCount: number;
  @Input() finalTransactionCount: number;
  @Input() titleText: string;
  @Input() buttonText: string;
  @Input() loading: boolean;
  @Input() asyncReportIdGenerated: string;
  @Input() isDeviceMobile: boolean;

  @Input() transactionsPath: TransactionsPathEnum;
  @Output() generateReport: EventEmitter<ITransactionReportTemplate> = new EventEmitter();
  @Output() includeAllTransactionTypes: EventEmitter<boolean> = new EventEmitter();
  @Output() transactionsPathSelected: EventEmitter<string> = new EventEmitter();

  @Input() set transactionReportTemplates(templates: ITransactionReportTemplate[]) {
    if (templates.length) {
      this.transactionReportTemplatesHold = templates;
      this.setDefaultId();
      this.setDefaultSelectedTemplate();
    }
  }

  get transactionReportTemplates(): ITransactionReportTemplate[] {
    return this.transactionReportTemplatesHold;
  }

  transactionReportTemplatesHold: ITransactionReportTemplate[] = [];
  optBasicReport = ExportOptions.BasicReport;
  optFullReport = ExportOptions.FullReport;
  isOpened = false;
  selected: ITransactionReportTemplate | undefined;
  selectedFields: string[];
  threshold = SearchLimit.Threshold;
  asyncReportLimit = CountLimitAsyncReport.AsyncReportLimit;
  reportTemplateFieldTypesList: ISelectInput[];
  reportTemplateFields: ReportTemplateFieldTypes;
  isGenerateReportClicked = false;
  showHideReport = false;
  indexReport = 0;
  templateContentMaxHeight = 25;
  defaultId = '';
  selectCheckbox = false;
  transactionReportType = TransactionReportType;
  isTransactionsReportTemplateFeatureEnabled: boolean;
  maxTransactionLimit = 500000;
  reportTemplateFieldColumnNameList = ReportTemplateFieldColumnName;
  isOpen = false;

  constructor(
    private elementRef: ElementRef,
    reportTemplateFieldTypes: ReportTemplateFieldTypes,
    private notifierService: NotifierService,
    private featureToggleService: FeatureToggle,
    private mobileViewService: MobileViewService,
  ) {
    this.reportTemplateFieldTypesList = reportTemplateFieldTypes.list;
    this.reportTemplateFields = reportTemplateFieldTypes;
  }

  @HostListener('document:mousedown', ['$event'])
  clickOutside(event: MouseEvent): void {
    if (this.isOpened && !this.elementRef.nativeElement.contains(event.target)) {
      this.toggleShow();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.isInsideDropdownMenu(event.target)) {
      this.isOpen = false;
    }
  }

  isInsideDropdownMenu(target: any): boolean {
    const templateDropdownMenu = document.getElementById('template-dropdown');
    return templateDropdownMenu && templateDropdownMenu.contains(target);
  }

  ngOnInit(): void {
    this.isGenerateReportClicked = false;
    this.setDefaultId();
    this.setDefaultSelectedTemplate();
    this.isTransactionsReportTemplateFeatureEnabled = this.featureToggleService.isFeatureActive(
      Features.TransactionsReportTemplates,
    );
    this.transactionsPathSelected.emit(this.transactionsPath);
  }

  formatTemplateNameHumanReadable(field: string): string {
    return (this.reportTemplateFields.getValue(field) as string) || '';
  }

  toggleShow(): void {
    this.finalTransactionCount = 0;
    this.isOpened = !this.isOpened;
    this.asyncReportIdGenerated = '';
    this.isGenerateReportClicked = false;
    this.loading = false;
    if (this.isOpened) {
      this.setDefaultId();
      this.setDefaultSelectedTemplate();
    }
  }

  toggleReportTemplateFields(): void {
    this.isOpen = !this.isOpen;
  }

  onReportSelected(input: ITransactionReportTemplate): void {
    this.transactionsPathSelected.emit(this.transactionsPath);
    this.selected = input;
    this.selectedFields =
      this.selected?.fields?.filter((item) => this.reportTemplateFields.getValue(item)) || [];
    this.indexReport = input?.index || 0;
    this.showHideReport = false;
    this.isGenerateReportClicked = false;
  }

  showFieldContents(): void {
    this.showHideReport = true;
  }

  hideFieldContents(): void {
    this.showHideReport = false;
  }

  onExport(): void {
    if (this.selected?.id) {
      this.notifierService.destroyNotification();
      this.notifierService.info(
        $localize`It might take a few seconds to generate the report. Once your report is ready, the download will start automatically.`,
      );
      this.generateReport.emit(this.selected);
      this.isOpened = false;
    }
  }

  onGenerateReport(): void {
    this.isGenerateReportClicked = true;
    this.generateReport.emit(this.selected);
  }

  closeSideBar(): void {
    this.isOpened = false;
    this.isGenerateReportClicked = false;
  }

  onSelect(selected: boolean): void {
    this.includeAllTransactionTypes.emit(selected);
  }

  private setDefaultId(): void {
    this.defaultId = '';
    setTimeout(() => {
      this.defaultId = this.findBasicTemplate()?.id ?? '';
    }, 0); // TODO: we should not use setTimeout, we should use for examples 1. change detections strategies, 2. Make API response as observable.
  }

  private findBasicTemplate(): ITransactionReportTemplate | undefined {
    return this.transactionReportTemplatesHold?.find(
      (template: ITransactionReportTemplate) => template.id === TransactionReportType.Basic,
    );
  }

  private setDefaultSelectedTemplate(): void {
    const transactionReportTemplates: ITransactionReportTemplate | undefined =
      this.findBasicTemplate();
    this.selected = {
      id: transactionReportTemplates?.id,
      templateName: transactionReportTemplates?.templateName,
      fields: transactionReportTemplates?.fields,
      inheritanceType: transactionReportTemplates?.inheritanceType,
      entityUid: transactionReportTemplates?.entityUid,
      templateUid: transactionReportTemplates?.templateUid,
      isReportCustomized: transactionReportTemplates?.isReportCustomized,
    };
  }
}
