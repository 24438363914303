import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { IApp } from '@portal/marketplace/api-interfaces/src';

@Component({
  selector: 'marketplace-app-logo-public-template',
  templateUrl: './app-logo-public-template.component.html',
  styleUrls: ['./app-logo-public-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLogoPublicTemplateComponent implements OnInit, OnChanges {
  @Input() app: IApp;

  icon = 'assets/images/img-placeholder.png';

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.app?.currentValue) {
      this.icon = this.app.appIcons[0].publicUrl;
    }
  }
}
