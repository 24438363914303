import { NotificationType } from './notification-type.enum';

export class Notification {
  message: string;
  type: NotificationType;

  constructor(message: string, type: NotificationType) {
    this.message = message;
    this.type = type;
  }
}
