import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subject } from 'rxjs';

import { INavbarItem } from '@portal/shared/ui/layouts/src/lib/top-navbar/interfaces/navbar-item.interface';
import { WL_NAVBAR_ITEMS_MOCK } from './mock-data/wl-navbar-items-mock-data';
import { WhiteLabelingService, Palette } from '@portal/shared/white-labeling/src';
import { PaletteGeneratorService } from '@portal/shared/white-labeling/src/lib/services/palette-generator.service';
import { TemplateParameters } from '@portal/entity-services/interfaces/src/lib/organisations/interfaces/reseller.interface';
import { WHITE_LABELING_VARS } from '@portal/shared/white-labeling/src/lib/styles/white-labeling-vars';

declare const $localize;

@Component({
  selector: 'portal-white-labeling-preview-page',
  templateUrl: './white-labeling-preview-page.component.html',
  styleUrls: ['./white-labeling-preview-page.component.scss'],
})
export class WhiteLabelingPreviewPageComponent implements OnChanges {
  @Input() domainName: string;
  @Input() templateParameters: TemplateParameters;

  /**
   * set class name and variables to host element
   * when white label variables available
   */
  @HostBinding('style') style: SafeStyle;
  @HostBinding('class.white-labeled') isWhiteLabeled: boolean;

  logoSrc: string;
  stringifiedPalettes = '';
  navbarItems: INavbarItem[] = WL_NAVBAR_ITEMS_MOCK;
  navbarUserItems: INavbarItem = {
    title: 'User Name',
    children: [
      {
        title: $localize`My Account`,
      },
      {
        title: $localize`Logout`,
      },
    ],
  };

  private logoSrcSubject = new Subject<string>();
  private logoSrc$ = this.logoSrcSubject.asObservable();

  constructor(
    private sanitizer: DomSanitizer,
    private whiteLabelingService: WhiteLabelingService,
    private paletteGeneratorService: PaletteGeneratorService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.templateParameters && this.templateParameters) {
      this.loadWhiteLabelingLogo(this.templateParameters);
    }
  }

  whiteLabelingEditorApplied(parameters: TemplateParameters): void {
    const logo = parameters?.logo?.[0];
    if (logo && !logo.name.includes(this.domainName)) {
      this.previewLogoFiles(logo);
      this.logoSrc$.subscribe((src: string) => {
        if (src) {
          this.logoSrc = src;
        }
      });
    }

    const font = parameters?.pageStyle?.font?.[0];
    if (font) {
      this.whiteLabelingService.applyGoogleFont(font);
      this.stringifiedPalettes += this.JSVarsConvertToString({ font: `'${font}';` });
    }

    if (parameters.pageStyle) {
      const generatedPalettes: Palette = this.paletteGeneratorService.getPalette({
        ...parameters.pageStyle.brand,
        ...parameters.pageStyle.navBar,
      });
      const stringifiedGeneratedColorPalettes = this.JSVarsConvertToString({
        ...generatedPalettes.brand,
        ...generatedPalettes.navbar,
      });

      this.stringifiedPalettes += stringifiedGeneratedColorPalettes;

      this.setVariablesToHost();
    }
  }

  whiteLabelingEditorReseted(templateParameters: TemplateParameters): void {
    this.logoSrc = '';
    this.style = '';
    this.stringifiedPalettes = '';
    this.isWhiteLabeled = false;
    if (templateParameters) {
      this.loadWhiteLabelSettings(templateParameters);
    }
  }

  private loadWhiteLabelSettings(templateParameters: TemplateParameters): void {
    this.whiteLabelingEditorApplied(templateParameters);
    this.loadWhiteLabelingLogo(templateParameters);
  }

  private loadWhiteLabelingLogo(templateParameters: TemplateParameters): void {
    const logo = templateParameters?.logo?.[0];
    this.logoSrc =
      logo?.name && logo.name.includes(this.domainName)
        ? `${this.whiteLabelingService.getBasePath()}/${this.domainName}/logo.png`
        : '';
  }

  private JSVarsConvertToString(stylesApplied: object): string {
    return Object.keys(stylesApplied)
      .map((key) => `--${key}:${stylesApplied[key]}`)
      .join('; ');
  }

  private setVariablesToHost(): void {
    if (this.stringifiedPalettes) {
      const concatedVars = this.stringifiedPalettes.concat(
        this.JSVarsConvertToString(WHITE_LABELING_VARS),
      );
      this.style = this.sanitizer.bypassSecurityTrustStyle(concatedVars);
      this.isWhiteLabeled = true;
    }
  }

  private previewLogoFiles(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.logoSrcSubject.next(reader.result as string);
      },
      false,
    );

    reader.addEventListener(
      'error',
      () => {
        this.logoSrcSubject.next(null);
      },
      false,
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
