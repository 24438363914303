import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { Observable, Subscription } from 'rxjs';

import {
  EntityType,
  IPointInteraction,
  IOrganisation,
  IPaymentContract,
} from '@portal/entity-services/interfaces';
import { PaymentContractService } from '@portal/entity-services/payment-contracts/src/lib/services/payment-contract.service';
import { InteractionTypes } from '@portal/entity-services/points-of-interaction/src/lib/components/form/interaction-type.list';
import { PointInteractionService } from '@portal/entity-services/points-of-interaction/src/lib/services/point-interaction.service';
import { PaginationSortHandler } from '@portal/shared/helpers';
import { IResultsWithCount, IQueryParams, PageSize, SortOrder } from '@portal/shared/ui/table';
import { OrganisationService } from '../../../services/organisation.service';
import { EntityTypes } from '../../form/entity-type.list';

@Component({
  selector: 'portal-associated-entities',
  templateUrl: './associated-entities.component.html',
})
export class AssociatedEntitiesComponent extends PaginationSortHandler implements OnInit {
  @Input() organisationId: string;
  @Input() paginationId: string;
  @Input() associatedLabelName: string;
  @Input() associatedEntityType: string;
  @Output() paymentContractOutput: EventEmitter<IPaymentContract[]> = new EventEmitter();

  entityType = EntityType;

  poiloading$: Observable<boolean> = this.pointInteractionService.loading$;
  associatedPois$: Observable<IResultsWithCount<IPointInteraction>>;
  associatedPois: IPointInteraction[];
  organisationloading$: Observable<boolean> = this.organisationService.assOrgloading$;
  associatedOrganisations$: Observable<IResultsWithCount<IOrganisation>>;
  associatedOrganisations: IOrganisation[];
  ppcloading$: Observable<boolean> = this.paymentContractService.loading$;
  paymentContracts$: Observable<IResultsWithCount<IPaymentContract>>;
  paymentContracts: IPaymentContract[];

  totalCount: number;
  initialFilter: IQueryParams = {
    start: 0,
    limit: PageSize.Size5,
    order: SortOrder.Asc,
  };
  filter: IQueryParams;
  filterTouched = false;
  loadingRows: any[];
  loadingColumns: any[];
  columnsCount = 3;
  pageSizeOptions: PageSize[] = [
    PageSize.Size5,
    PageSize.Size10,
    PageSize.Size25,
    PageSize.Size50,
    PageSize.Size100,
  ];
  poiSubscription: Subscription;
  orgSubscription: Subscription;
  paymentContractSubscription: Subscription;

  constructor(
    readonly interactionTypes: InteractionTypes,
    readonly entityTypes: EntityTypes,
    private pointInteractionService: PointInteractionService,
    private organisationService: OrganisationService,
    @Inject(forwardRef(() => PaymentContractService))
    private paymentContractService: PaymentContractService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setInitialFilter();
    this.getAssociatedEntities();
  }

  afterPaginate(): void {
    this.getAssociatedEntities();
  }

  afterSorting(): void {
    this.getAssociatedEntities();
  }

  private setInitialFilter(): void {
    this.filter = cloneDeep(this.initialFilter);
  }

  private setLoadingSkeletonData(): void {
    this.loadingRows = Array(this.filter.limit);
    this.loadingColumns = Array(this.columnsCount);
  }

  private getAssociatedEntities(): void {
    switch (this.associatedEntityType) {
      case this.entityType.MERCHANT_SITE:
        this.getPois();
        break;
      case this.entityType.MERCHANT_COMPANY:
        this.getAssociatedOrganisations();
        break;
      case 'paymentContracts':
        this.getPaymentContracts();
        break;
    }
  }

  private getPois(): void {
    this.associatedPois = [];
    this.setLoadingSkeletonData();

    if (this.poiSubscription) {
      this.poiSubscription.unsubscribe();
    }

    this.associatedPois$ = this.pointInteractionService.getResultsWithCount({
      ...this.filter,
      parentIds: this.organisationId,
    });

    this.poiSubscription = this.associatedPois$.subscribe(
      (result: IResultsWithCount<IPointInteraction>) => {
        this.associatedPois = result.results;
        this.totalCount = result.count;
      },
    );
  }

  private getAssociatedOrganisations(): void {
    this.associatedOrganisations = [];
    this.setLoadingSkeletonData();
    if (this.orgSubscription) {
      this.orgSubscription.unsubscribe();
    }
    this.associatedOrganisations$ = this.organisationService.getAssociatedOrganisations({
      ...this.filter,
      parentId: this.organisationId,
    });
    this.orgSubscription = this.associatedOrganisations$.subscribe((data) => {
      this.associatedOrganisations = data.results;
      this.totalCount = data.count;
    });
  }

  private getPaymentContracts(): void {
    this.paymentContracts = [];
    this.setLoadingSkeletonData();

    if (this.paymentContractSubscription) {
      this.paymentContractSubscription.unsubscribe();
    }

    this.paymentContracts$ = this.paymentContractService.getResultsWithCount({
      ...this.filter,
      parentIds: this.organisationId,
    });

    this.paymentContractSubscription = this.paymentContracts$.subscribe(
      (result: IResultsWithCount<IPaymentContract>) => {
        this.paymentContracts = result.results;
        this.paymentContractOutput.emit(this.paymentContracts);
        this.totalCount = result.count;
      },
    );
  }
}
