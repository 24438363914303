/* This list was communicated to RMS team */

export enum Application {
  Marketplace = 'MARKETPLACE',
  Developer = 'DEVELOPER',
  EstateOwner = 'ESTATE_OWNER',
  MarketplaceManager = 'MARKETPLACE_MANAGER',
  Petro = 'PETRO',
  VHQ = 'VHQ',
  MOP = 'MOP',
  PaymentsPortal = 'PAYMENTS_PORTAL',
  Wrapper = 'WRAPPER',
  Authentication = 'AUTHENTICATION',
}
