import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vui-four-zero-four',
  templateUrl: './four-zero-four.component.html',
  styleUrls: ['./four-zero-four.component.scss'],
})
export class FourZeroFourComponent {
  constructor(private router: Router) {}
  navigateDashboard(): void {
    this.router.navigate(['/'], { state: { redirectToInitialPage: true } });
  }
}
