export enum Channel {
  Other = 'OTHER',
  SecureEcom = 'SECURE_ECOM',
  Mail = 'MAIL',
  Stored = 'STORED',
  Pos = 'POS',
  Ecom = 'ECOM',
  Tele = 'TELE',
  Mobile = 'MOBILE',
  Virtual = 'VIRTUAL',
}
