import { IGuardPermissions } from '@portal/shared/auth/authorization/src';
import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';
import { RouteName } from './route-name.enum';

export const routePermissions: { [key in RouteName]?: IGuardPermissions } = {
  [RouteName.List]: {
    read: [
      UserRoles.MerchantAdmin,
      UserRoles.MerchantReviewer,
      UserRoles.MerchantSupervisor,
      UserRoles.MerchantUser,
      UserRoles.ProviderWhiteLabelManager,
      UserRoles.VerifoneAdmin,
      UserRoles.VerifoneUser,
    ],
  },
  [RouteName.Create]: {
    read: [UserRoles.VerifoneAdmin],
    readPassword: [UserRoles.VerifoneAdmin],
  },
  [RouteName.Edit]: {
    read: [UserRoles.VerifoneAdmin],
    readPassword: [UserRoles.VerifoneAdmin],
  },
  [RouteName.Detail]: {
    read: [
      UserRoles.MerchantAdmin,
      UserRoles.MerchantReviewer,
      UserRoles.MerchantSupervisor,
      UserRoles.MerchantUser,
      UserRoles.ProviderWhiteLabelManager,
      UserRoles.VerifoneAdmin,
      UserRoles.VerifoneUser,
    ],
    delete: [UserRoles.VerifoneAdmin],
    edit: [UserRoles.VerifoneAdmin],
    enableDisable: [UserRoles.VerifoneAdmin],
    readPassword: [UserRoles.VerifoneAdmin, UserRoles.VerifoneUser],
  },
};
