import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedAuthAuthorizationModule } from '@portal/shared/auth/authorization';
import { PipesModule } from '@portal/shared/pipes';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { AppScreenCarouselComponent } from './app-screen-carousel.component';

@NgModule({
  imports: [CommonModule, NgxUsefulSwiperModule, SharedAuthAuthorizationModule, PipesModule],
  declarations: [AppScreenCarouselComponent],
  exports: [AppScreenCarouselComponent],
  providers: [],
})
export class AppScreenCarouselModule {}
