import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'marketplace-updates-indicator',
  templateUrl: './updates-indicator.component.html',
  styleUrls: ['./updates-indicator.component.scss'],
})
export class UpdatesIndicatorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
