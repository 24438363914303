import { Injectable } from '@angular/core';
import { defer, Observable, of } from 'rxjs';
import { toUint8Array } from 'js-base64';

@Injectable({
  providedIn: 'root',
})
export class BlobService {
  toBase64(blob: Blob): Observable<string> {
    return readFile(
      (fr) => fr.readAsDataURL(blob),
      (result) => (result as string).split('base64,')[1],
    );
  }
  fromBase64(data: string, type?: string): Observable<Blob> {
    return createBlob(toUint8Array(data), type);
  }
  toHex(blob: Blob): Observable<string> {
    return readFile(
      (fr) => fr.readAsText(blob),
      (result) => asciiToHex(result as string),
    );
  }
  fromHex(data: string, type?: string): Observable<Blob> {
    return createBlob(hexToAscii(data), type);
  }
}

function readFile<T>(
  read: (reader: FileReader) => void,
  project: (result: string | ArrayBuffer) => T,
): Observable<T> {
  return new Observable<T>((subscriber) => {
    const fr = new FileReader();
    fr.onload = () => {
      subscriber.next(project(fr.result));
      subscriber.complete();
    };
    fr.onerror = () => subscriber.error(fr.error);
    read(fr);
    return () => fr.readyState === 1 && fr.abort();
  });
}

function createBlob(data: any, type: string): Observable<Blob> {
  return defer(() => of(new Blob([data], { type })));
}

function asciiToHex(data: string): string {
  return data.replace(/./g, (c) => c.charCodeAt(0).toString(16));
}

function hexToAscii(data: string): string {
  return data.replace(/[0-9a-f]{2}/g, (dd) => String.fromCharCode(Number.parseInt(dd, 16)));
}
