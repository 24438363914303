import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InstallsIndicatorComponent } from './installs-indicator.component';

@NgModule({
  imports: [CommonModule],
  declarations: [InstallsIndicatorComponent],
  exports: [InstallsIndicatorComponent],
})
export class InstallsIndicatorModule {}
