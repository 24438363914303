import { Injectable } from '@angular/core';
import { Order } from '@datorama/akita';
import { DevAppsTransformerService } from '@portal/marketplace/api-tranformers/src';
import { AppStatus, IApp, StorePagination } from '@portal/marketplace/api-interfaces';
import { IQueryParams } from '@portal/shared/ui/table/src';
import { Observable } from 'rxjs';
import { AllAppsCatalogStore } from '../all-apps-catalog/all-apps-catalog.store';
import { IAppQuery, IAppQueryMMandDev } from './apps.query';
import { IAppStore, IAppStoreMMandDev } from './apps.store';

@Injectable({ providedIn: 'root' })
export class AppsService extends StorePagination {
  constructor(
    private appStore: IAppStore,
    public appsQuery: IAppQuery,
    private allAppsCatalogStore: AllAppsCatalogStore,
    private appsTransformerService: DevAppsTransformerService,
    private appStoreMMandDev: IAppStoreMMandDev,
    public appQueryMMandDev: IAppQueryMMandDev,
  ) {
    super();
  }

  addDataToAppsStore(apps: IApp[], portal?: string): void {
    if (portal === 'MM') {
      this.appStoreMMandDev.add(apps);
    } else {
      this.appStore.add(apps);
    }
    this.allAppsCatalogStore.add(this.appsTransformerService.transformIDevAppToIApp(apps));
  }

  getAppsByAppVersions(ids: string[] = [], excludeIds: string[] = []): Observable<IApp[]> {
    return this.appsQuery.selectAll({
      filterBy: (entity) =>
        (!ids.length || ids.includes(entity.id)) && !excludeIds.includes(entity.id),
    });
  }

  getAppsByAppVersionsObj(ids: string[] = [], excludeIds: string[] = []): IApp[] {
    return this.appsQuery.getAll({
      filterBy: (entity) =>
        (!ids.length || ids.includes(entity.id)) && !excludeIds.includes(entity.id),
    });
  }

  getAppsByAppVersionsPagination(paginationConfig?: IQueryParams): Observable<IApp[]> {
    const storePagination = this.appStore.getValue().pagination.pages;
    const idsToReturn = storePagination.get(paginationConfig.start / paginationConfig.limit);
    return this.appsQuery.selectMany(idsToReturn);
  }

  getAppsByAppVersionsPaginationObj(paginationConfig: IQueryParams): IApp[] {
    const storePagination = this.appStore.getValue().pagination.pages;
    const idsToReturn = storePagination.get(paginationConfig.start / paginationConfig.limit);
    return this.appsQuery.getAll({
      filterBy: (entity) => idsToReturn.includes(entity.appmarketAppId),
    });
  }

  getAllApps(portal?: string, pagination?: IQueryParams): Observable<IApp[]> {
    if (portal === 'MM') {
      const storePagination = this.appStore.getValue().pagination.pages;
      const idsToReturn = storePagination.get(pagination.start / pagination.limit);
      return this.appQueryMMandDev.selectMany(idsToReturn);
    }
    return this.appsQuery.selectAll({ limitTo: 25 });
  }

  getAllAppsForTag(): Observable<IApp[]> {
    return this.appsQuery.selectAll();
  }

  getSubmittedApps(): Observable<IApp[]> {
    return this.appQueryMMandDev.selectAll({
      filterBy: (app) => {
        return app.status === AppStatus.SUBMITTED;
      },
    });
  }

  getAppByAppId(appmarketAppId): Observable<IApp> {
    return this.appsQuery.selectEntity((entity) => entity.appmarketAppId === appmarketAppId);
  }

  getAppMarket(appmarketAppId): Observable<IApp[]> {
    return this.appsQuery.selectAll({
      filterBy: (app) => app.appmarketAppId === appmarketAppId,
    });
  }

  getAppsLoadingState(): Observable<boolean> {
    return this.appsQuery.selectLoading();
  }

  setLoading(loading: boolean): void {
    this.appStore.setLoading(loading);
  }

  getVfAppMarketAppId(appVersionId: string): string {
    if (this.appsQuery.getEntity(appVersionId))
      return this.appsQuery.getEntity(appVersionId).appmarketAppId;
  }

  getAppByVersionId(appVersionId: string): IApp {
    return this.appsQuery.getEntity(appVersionId);
  }

  getAppByVfAppMarketAppId(appMarketAppId): IApp {
    return this.appsQuery.getAll({
      filterBy: (entity) => entity.appmarketAppId === appMarketAppId,
    })[0];
  }

  /* DEPRECATED use `getAppsByVfAppMarketAppIds` */
  getAppsByVfAppMarketAppId(ids?: string[], itemsToTake?: number): Observable<IApp[]> {
    return this.appsQuery.selectAll({
      filterBy: (entity) => ids.includes(entity.appmarketAppId),
      limitTo: itemsToTake,
    });
  }

  getAppsByVfAppMarketAppIds(
    appMarketIds: string[],
    exludeAppmarketIds: string[] = [],
    itemsToTake?: number,
  ): IApp[] {
    return this.appsQuery.getAll({
      filterBy: (entity) =>
        appMarketIds.includes(entity.appmarketAppId) &&
        !exludeAppmarketIds.includes(entity.appmarketAppId),
      limitTo: itemsToTake,
    });
  }

  getAppsByVfAppMarketAppIdsObservable(
    appMarketIds: string[],
    exludeAppmarketIds: string[] = [],
    itemsToTake?: number,
  ): Observable<IApp[]> {
    return this.appsQuery.selectAll({
      filterBy: (entity) =>
        appMarketIds.includes(entity.appmarketAppId) &&
        !exludeAppmarketIds.includes(entity.appmarketAppId),
      limitTo: itemsToTake,
    });
  }

  getAppsByAppId(appId: string): IApp[] {
    return this.appsQuery.getAll({
      filterBy: (entity) => entity.app.id === appId,
      sortByOrder: Order.DESC,
    });
  }

  selectAppByVfAppMarketAppId(appMarketAppId): Observable<IApp[]> {
    return this.appsQuery.selectAll({
      filterBy: (entity) => entity.appmarketAppId === appMarketAppId,
    });
  }

  getPagination(): any {
    return this.appStore.getValue().pagination;
  }

  reset(): void {
    this.resetPagination(this.appStore);
  }
}
