import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPoi } from '@portal/marketplace/api-interfaces';
import { ITableConfig } from '@portal/shared/ui/table';

@Component({
  selector: 'marketplace-devices-table-template',
  templateUrl: './devices-table-template.component.html',
  styleUrls: ['./devices-table-template.component.scss'],
})
export class DevicesTableTemplateComponent implements OnInit {
  @Output() deviceClicked: EventEmitter<any> = new EventEmitter<any>();
  private _deviceList: IPoi[];
  get deviceList(): IPoi[] {
    return this._deviceList;
  }
  @Input('deviceList')
  set deviceList(val) {
    this._deviceList = val;
  }
  tableConfig: ITableConfig = {
    columns: [
      { key: 'altVfiPoiId', label: 'Terminal ID' },
      { key: 'device.serialNumber', label: 'Serial number' },
      { key: 'device.model.deviceType', label: 'Model' },
      { key: 'entity.altVfiEntityId', label: 'MID' },
      { key: 'entity.name', label: 'Merchant name' },
      {
        key: '',
        label: 'Status',
        cssClass: 'table-row-roles',
        innerCssClass: 'has-text-truncate',
        isRenderFunction: true,
        renderFunction: (device: IPoi) => {
          return device.device ? device.device.status : 'PENDING';
        },
      },
    ],
    rowIdKey: 'altVfiPoiId',
    rowLabelKey: 'name',
  };
  constructor() {}

  ngOnInit(): void {}
}
