import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ITreeOptions, TreeNode } from '@circlon/angular-tree-component';
import { Observable } from 'rxjs';
import { ViewMode } from './constants/constants';
import { TreeNodes } from './interfaces/tree-node.interface';

declare const $localize;

@Component({
  selector: 'portal-tree',
  templateUrl: './portal-tree.component.html',
})
export class PortalTreeComponent implements OnChanges {
  @ViewChild('tree') tree;
  @Input() nodes: TreeNodes[];
  @Input() selectedNodeIds: string[];
  @Input() options: ITreeOptions = {};
  @Input() showCount = false;
  @Input() showCheckBox = false;
  @Input() loading$: Observable<boolean>;
  @Input() loadingMessage = $localize`Loading items`;
  @Input() notFoundMessage = $localize`Items not found`;
  @Input() borderless = false;
  @Input() disableCheckbox = false;
  @Input() singleSelectMode = false;
  @Input() isCalculateCount = true;
  @Output() modifiedNodeData = new EventEmitter<any>();

  hasSelectedCheckbox = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _modeValue: any;

  constructor() {}

  ngOnChanges(): void {
    setTimeout(() => {
      if (this.nodes && this.nodes.length && this.showCheckBox) {
        this.updateTree();
      }
    }, 500);
  }

  mode(mode: any): void {
    this.modeValue = mode;

    switch (this.modeValue) {
      case ViewMode.expandAll: {
        this.tree.treeModel.expandAll();
        break;
      }
      case ViewMode.collapseAll: {
        this.tree.treeModel.collapseAll();
        break;
      }
      case ViewMode.selectAll: {
        this.selectDeselectAll(true);
        break;
      }
      case ViewMode.deSelectAll: {
        this.selectDeselectAll(false);
        break;
      }
      default: {
        break;
      }
    }
  }

  filterNodes(criteria: string): void {
    this.tree.treeModel.filterNodes(criteria, true);
  }

  selectDeselectAll(isSelected: boolean): void {
    this.tree.treeModel.doForAll((node: TreeNode) => {
      node.setIsSelected(isSelected);
      node.data.is_checked = isSelected;
    });
    setTimeout(() => {
      this.modifiedNodeData.emit(this.nodes);
    }, 500);
  }

  onCheck(node): void {
    this.hasSelectedCheckbox = !this.hasSelectedCheckbox;
    const isSelected = node.isSelected;
    node.data.is_checked = isSelected;
    if (node.data.children.length) {
      this.selectDeselectChildrens(node.data.children, isSelected);
    }
    this.modifiedNodeData.emit(this.nodes);
  }

  selectDeselectChildrens(child: any[], isSelected): void {
    child.forEach((element) => {
      element.is_checked = isSelected;
      if (element.children.length) {
        this.selectDeselectChildrens(element.children, isSelected);
      }
    });
  }

  updateTree(): void {
    this.hasSelectedCheckbox = false;
    this.tree.treeModel.doForAll((node: TreeNode) => {
      // For rendering preselected we need some time
      node.setIsSelected(node.data.is_checked);
      if (!node.data.count && this.isCalculateCount) {
        node.data.count = node.data.children?.length;
      }

      if (this.selectedNodeIds && this.selectedNodeIds.includes(node.id)) {
        node.data.is_checked = true;
        if (this.selectedNodeIds.length) this.hasSelectedCheckbox = true;
        node.ensureVisible();
        node.setIsSelected(true);
      }
    });
  }

  getModifiedData(): any {
    return this.nodes;
  }

  get modeValue(): any {
    return this._modeValue;
  }

  set modeValue(modeType: any) {
    this._modeValue = modeType;
  }
}
