import { Injectable } from '@angular/core';
import { ReportTypes } from '../../../shared/list/report-type.list';
import { ReportType } from '../../../shared/enum/report-type.enum';
import { UserService } from '@portal/entity-services/users/src';
import { AuthenticationService } from '@portal/shared/auth/authentication/src/lib/authentication.service';
import { IQueryParams, QueryParamsService } from '@portal/shared/ui/table';
import { BehaviorSubject, Observable, Subject, of, pipe } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { IUser } from '../../../core/core.module';
import { IReport } from '../../../shared/report-engine/interfaces/report.interface';
import { IReportScheduler } from '../../../shared/report-engine/interfaces/report.interface';
import { ReportEngineDataService } from '../../../shared/report-engine/services/report-engine-data.service';
import { ReportScheduleDataService } from '../../../shared/report-engine/services/report-schedule-data.service';
import { IResultsWithTotal } from '../interfaces/results-with-total.interface';
import { IScheduleReport } from '../interfaces/scheduler-report.interface';
import { ISearchPayload } from '../../../shared/interfaces';
import { SearchCriteriaConvertService } from '../../transactions/services/search-criteria-convert.service';
import { ListKey } from '../enums/list-key.enum';
import { IResultsReportScheduler } from '../interfaces/results-report-scheduler.interface';
import { OrganisationService } from '@portal/entity-services/organisations/src/lib/services/organisation.service';
import {
  IReportSchedulerDetails,
  IReportsSchedule,
  IUpdateReportSchedulerReponse,
} from '../interfaces/report-scheduler-details.interface';
import { ISftpConnection } from '../../../shared/report-engine/interfaces/sftp-connection.interface';
import { NotifierService } from '@portal/shared/ui/notifier';
import * as get from 'lodash/get';
import { SearchSelector } from '@portal/shared/ui/filter';

const notAvailable = $localize`N/A`;
const catchErrorMapHandle = (name: string): any => {
  return pipe(
    catchError(() => of({ [name]: notAvailable })),
    map((item) => item[name]),
    shareReplay(1),
  );
};
@Injectable({ providedIn: 'root' })
export class ReportEngineService {
  loading$: Observable<boolean>;
  sftpLoading$: Observable<boolean>;
  getReportsLoading$: Observable<boolean>;
  downloadReports$: Observable<boolean>;
  sftpConnectionsList$: BehaviorSubject<ISftpConnection[]> = new BehaviorSubject([]);

  setActionDownloadingReportDirectly$ = new Subject<boolean>();
  actionDownloadingReportDirectly$ = this.setActionDownloadingReportDirectly$.asObservable();

  schedulerReports: IScheduleReport[] = [
    {
      schedulerUid: '0',
      name: $localize`Settlement reports`,
      reports: 0,
      frequency: $localize`Daily`,
      organisation: 'organisation test',
      reportType: ReportType.MerchantSettlementReport,
      formattedReportType: this.reportTypes.keyValue[ReportType.MerchantSettlementReport],
    },
    {
      schedulerUid: '1',
      name: $localize`Daily transactions`,
      reports: 0,
      frequency: $localize`Daily`,
      organisation: 'organisation test',
      reportType: ReportType.DailyTransactionReport,
      formattedReportType: this.reportTypes.keyValue[ReportType.DailyTransactionReport],
    },
  ];
  reportSchedulers: IReportScheduler;

  private setLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private setSftpLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private setReportsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private downloadMultipleReport$ = new Subject<any>();

  constructor(
    public reportTypes: ReportTypes,
    private userService: UserService,
    private reportEngineDataService: ReportEngineDataService,
    private reportScheduleDataService: ReportScheduleDataService,
    private organisationService: OrganisationService,
    private authenticationService: AuthenticationService,
    private searchCriteriaConvertService: SearchCriteriaConvertService,
    private notifierService: NotifierService,
  ) {
    this.loading$ = this.setLoading$.asObservable();
    this.sftpLoading$ = this.setSftpLoading$.asObservable();
    this.getReportsLoading$ = this.setReportsLoading$.asObservable();
    this.downloadReports$ = this.downloadMultipleReport$.asObservable();
  }

  setDownloadingReportDirectly(downloadingReport: boolean): void {
    this.setActionDownloadingReportDirectly$.next(downloadingReport);
  }

  downloadSelectedReports(): void {
    this.downloadMultipleReport$.next();
  }

  getSearchPayload(filterParams: IQueryParams, method?: string): ISearchPayload {
    const searchPayload: ISearchPayload = {
      pageNumber: filterParams.currentPage,
      pageSize: filterParams.limit,
      orderCriteria: filterParams.sort.key,
      direction: filterParams.sort.order,
      criteriaMap: filterParams.searchCriteria,
    };

    if (filterParams.searchCriteria) {
      const criterias = Array.from(filterParams.searchCriteria.values());
      searchPayload.criteria = this.searchCriteriaConvertService.convert(criterias);
    }

    searchPayload?.criteria?.forEach((criteria) => {
      if (criteria.selector === ListKey.ClearingEntityUid) {
        criteria.selector = criteria.selector = SearchSelector.EntityId;
      }
    });

    if (method === ListKey.PostMethod) {
      searchPayload.orderBy = filterParams.sort.order;
      delete searchPayload.direction;
    }
    return searchPayload;
  }

  getReports(filter?: ISearchPayload): Observable<IResultsWithTotal<IReport>> {
    this.setReportsLoading$.next(true);
    let searchQuery = QueryParamsService.getFilterParamsToString(filter.criteriaMap);
    searchQuery = searchQuery.replace(/createdFrom|createdTo/g, 'createdOn');
    const queryParams = `orderCriteria=${filter.orderCriteria}&orderBy=${filter.direction}&search=${searchQuery}&pageSize=${filter.pageSize}&pageNumber=${filter.pageNumber}`;
    return this.reportEngineDataService.getWithQueryString(queryParams).pipe(
      catchError((error) => this.errorHandler(error)),
      finalize(() => this.setReportsLoading$.next(false)),
    );
  }

  getPostReports(filter?: ISearchPayload): Observable<IResultsWithTotal<IReport>> {
    this.setReportsLoading$.next(true);
    let searchQuery = QueryParamsService.getFilterParamsToString(filter.criteriaMap);
    searchQuery = searchQuery.replace(/createdFrom|createdTo/g, 'createdOn');
    filter.search = searchQuery;
    delete filter.criteria;
    delete filter.criteriaMap;
    return this.reportEngineDataService.postWithQueryString(filter).pipe(
      catchError((error) => this.errorHandler(error)),
      finalize(() => this.setReportsLoading$.next(false)),
    );
  }

  getScheduledReports(filter?: IQueryParams): Observable<IResultsReportScheduler> {
    let searchQuery = QueryParamsService.getReportsEngineFilterParamsToString(
      filter.searchCriteria,
    );
    searchQuery = searchQuery
      .split('&')
      .map((item) => item.replace('(', '').replace(')', ''))
      .join('&');
    const queryParams = `&orderBy=${filter.sort.order}&orderCriteria=${
      filter.sort.key
    }&${searchQuery}&size=${filter.limit}&page=${filter.currentPage - 1}`;
    this.setLoading(true);
    return this.reportScheduleDataService.getWithQueryStringSchedule(queryParams).pipe(
      map((schedules) => schedules),
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  getDownloadedReportUrl(reportUid: string): Observable<string> {
    return this.reportEngineDataService.getReport(reportUid).pipe(
      map((response) => {
        if (response) {
          return URL.createObjectURL(new Blob([response]));
        }
        return null;
      }),
    );
  }

  getReportTemplates(): Observable<string> {
    return this.reportEngineDataService.getReportTemplatesWithCount();
  }

  getOrganisationName(): Observable<string> {
    return this.userService
      .getByKey(this.authenticationService.userUid)
      .pipe(map((userInfo: IUser) => userInfo.entity.name));
  }

  setLoading(state: boolean): void {
    this.setLoading$.next(state);
  }

  setSftpLoading(state: boolean): void {
    this.setSftpLoading$.next(state);
  }

  showNotificationError(error: any): void {
    this.notifierService.error(
      $localize`Error` + `(${get(error, 'error.status')}) ${get(error, 'error.error.message')}   `,
    );
  }

  errorHandler(error: any): Observable<IResultsWithTotal<IReport>> {
    this.showNotificationError(error);
    return of({
      totals: 0,
      reports: [],
    });
  }

  getEntityUidName(uid: string): Observable<string> {
    return this.organisationService.getEntityByID(uid).pipe(catchErrorMapHandle('name'));
  }

  createReportScheduler(data: IReportsSchedule): Observable<IUpdateReportSchedulerReponse> {
    return this.reportScheduleDataService.createReportScheduler(data).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  updateReportScheduler(
    data: IReportSchedulerDetails | IReportsSchedule,
  ): Observable<IUpdateReportSchedulerReponse> {
    return this.reportScheduleDataService.updateReportScheduler(data).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  deleteReportScheduelerbyID(id: number): Observable<IUpdateReportSchedulerReponse> {
    return this.reportScheduleDataService.deleteReportScheduler(id).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  createSftpConnection(
    sftpConnection: string,
    entityUid: string,
    oldSftpConnection?: string,
  ): Observable<ISftpConnection> {
    if (sftpConnection?.trim().length > 0 && !oldSftpConnection) {
      return this.reportEngineDataService.postSftpConnection(sftpConnection, entityUid).pipe(
        map((response) => {
          return response as ISftpConnection;
        }),
      );
    }
  }

  editSftpConnection(
    oldSftpConnection: string,
    newSftpConnection: string,
    entityUid: string,
  ): Observable<ISftpConnection> {
    if (oldSftpConnection) {
      return this.reportEngineDataService.deleteSftpConnection(oldSftpConnection).pipe(
        switchMap(() => {
          return this.createSftpConnection(newSftpConnection, entityUid);
        }),
      );
    } else {
      return this.createSftpConnection(newSftpConnection, entityUid);
    }
  }

  getAllSftpConnectionDetails(): Observable<ISftpConnection[]> {
    this.setSftpLoading(true);
    return this.reportEngineDataService.getAllSftpConnections().pipe(
      map((response) => {
        if (response?.connections?.length) {
          this.sftpConnectionsList$.next(response.connections);
        }
        return response.connections;
      }),
      catchError(this.handlingErrorForSFTP),
      finalize(() => {
        this.setSftpLoading(false);
      }),
    );
  }

  handlingErrorForSFTP(): Observable<ISftpConnection[]> {
    return of([{ connectionName: '', entityUid: '' }]);
  }
}
