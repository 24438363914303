import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ISelectInput } from '../box/interfaces/select-input.interface';
import { BaseFilter } from '../components/base-filter';

declare const $localize;
@Component({
  selector: 'portal-hierarchy-filter',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss'],
})
export class HierarchyComponent extends BaseFilter implements OnInit {
  @Input() nodeData: any;
  @Input() selector: string;
  @Input() showCount = false;
  @Input() nodeType: string;
  @Input() disableCheckbox: boolean;
  @Input() isCalculateCount: boolean;
  @Input() entityRemoved$ = new Subject<ISelectInput>();
  @Input() hierarchyEntitiesAdded$ = new Subject<ISelectInput>();

  @ViewChild('tree') tree;

  selectedNodes = [];
  isOpen = false;
  title;

  private _entityText = $localize`Hierarchy`;

  @Input('filterTitle')
  set filterTitle(val) {
    this._entityText = val;
    this.title = val;
  }

  set entityText(val) {
    this._entityText = val;
  }
  get entityText(): string {
    return this._entityText;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.entityRemoved$.subscribe((entityToRemove: ISelectInput) => {
      if (entityToRemove.selector === this.selector) {
        this.selectedNodes = [];
        this.tree.mode('DESELECT');
        this.updateText();
      }
    });
    this.hierarchyEntitiesAdded$.subscribe((entities) => {
      if (entities && this.selector === 'hierarchy' && entities[this.selector]) {
        this.mapRetainedEntities(entities[this.selector]);
      }
    });
  }

  openMenu(): void {
    this.isOpen = !this.isOpen;
  }

  nodeSelectEvent(event): void {
    this.getSelectedNodes(event);
  }

  onFilterApplied(): void {
    this.isOpen = !this.isOpen;
    const values: string[] = this.selectedNodes;
    if (values.length) {
      this.filterApplied.emit({
        key: this.selector,
        selector: this.selector,
        operator: 'eq',
        value: Array.from(this.localPreSelectedEntities.values()),
        argument: `${Array.from(values).join(',')}`,
      });
    } else {
      this.filterApplied.emit({
        key: this.selector,
        selector: this.selector,
        toRemove: true,
      });
    }
    this.filterUpdated.emit();
    this.updateText();
  }
  mapRetainedEntities(value): void {
    const values: string[] = [];
    Array.from(value).forEach((val) => {
      values.push(val['argument']);
    });
    this.selectedNodes = values;
    this.onFilterApplied();
    this.isOpen = false;
  }

  getSelectedNodes(nodeData): Array<any> {
    this.selectedNodes = [];
    if (nodeData.length) {
      this.copyNodes(nodeData);
    }
    return this.selectedNodes;
  }
  copyNodes(child: any[]): void {
    child.forEach((element) => {
      if (element.is_checked && element.type === this.nodeType) {
        this.selectedNodes.push(element.id);
      } else if (element.children.length) {
        this.copyNodes(element.children);
      }
    });
  }

  updateText(): void {
    const values: string[] = this.selectedNodes;
    const text = this.title;
    if (values.length) {
      this.entityText = `${text} (+${values.length})`;
    } else {
      this.entityText = text;
    }
  }
}
