<ng-container *ngIf="nodes?.length && !(loading$ | async)">
  <tree-root id="tree" #tree [focused]="true" [nodes]="nodes" [options]="options">
    <ng-template #treeNodeFullTemplate let-node let-index="index" let-templates="templates">
      <div class="tree-node" [class.hierarchy--borderless]="borderless">
        <div class="tree-node-row is-flex is-align-items-center">
          <label
            class="checkbox is-inline-block"
            *ngIf="showCheckBox && !disableCheckbox && !singleSelectMode"
          >
            <input
              type="checkbox"
              (click)="node.mouseAction('checkboxClick', $event); onCheck(node)"
              [checked]="node.isSelected"
              [indeterminate]="node.isPartiallySelected"
            />
            <span></span>
          </label>
          <label
            class="checkbox is-inline-block"
            *ngIf="showCheckBox && disableCheckbox && !singleSelectMode"
          >
            <input
              type="checkbox"
              (click)="node.mouseAction('checkboxClick', $event); onCheck(node)"
              [checked]="node.isSelected && node.data.count !== 0"
              [indeterminate]="node.isPartiallySelected"
              [disabled]="node.data.count === 0 && disableCheckbox"
            />
            <span></span>
          </label>

          <label
            class="checkbox is-inline-block"
            *ngIf="showCheckBox && singleSelectMode && node.data.type === 'SITE'"
          >
            <input
              type="checkbox"
              (click)="node.mouseAction('checkboxClick', $event); onCheck(node)"
              [checked]="node.isSelected"
              [indeterminate]="node.isPartiallySelected"
              [disabled]="!node.isSelected && hasSelectedCheckbox && singleSelectMode"
            />
            <span></span>
          </label>
          <tree-node-expander
            class="is-flex is-align-content-center"
            [node]="node"
          ></tree-node-expander>
          <div
            class="node-content-wrapper"
            (click)="node.isCollapsed ? node.expand() : node.collapse()"
          >
            <span
              [class]="node.data.className"
              class="title tree-title is-size-6 has-text-weight-normal"
            >
              <span
                >{{ node.data.name
                }}<span class="has-text-grey" *ngIf="showCount && node.data.count >= 0"
                  >&nbsp;&nbsp;({{ node.data.count }})</span
                ></span
              >
            </span>
          </div>
        </div>
        <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
      </div>
    </ng-template>
  </tree-root>
</ng-container>
<ng-container *ngIf="!nodes?.length && !(loading$ | async)">
  <p>
    {{ notFoundMessage }}
  </p>
</ng-container>
<ng-container *ngIf="loading$ | async">
  <p class="pd-10">
    <portal-spinner [isHorizontal]="true" [isSmall]="true" [text]="loadingMessage"></portal-spinner>
  </p>
</ng-container>
