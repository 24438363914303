<div [ngClass]="{ dropdown: showFilterSubMenu }">
  <div [ngClass]="{ 'dropdown-menu is-large is-block': showFilterSubMenu }">
    <div
      [ngClass]="isMainDropdownFilter ? 'dropdown' : 'dropdown-content'"
      [class.is-hidden]="!isOpen && !isMainDropdownFilter"
      [class.is-active]="isOpen && isMainDropdownFilter"
    >
      <a
        *ngIf="!isMainDropdownFilter"
        class="is-hidden"
        (click)="openContent()"
        id="filter-transaction-date-detail"
        title="Transaction date filter"
        i18n-title
      >
        <i class="fas fa-chevron-right" aria-hidden="true"></i>
      </a>

      <ng-template [ngIf]="!isMainDropdownFilter" #dropdownContent>
        <div class="dropdown-item">
          <a
            *ngIf="!isMainDropdownFilter && !displayFromFilter"
            tabindex="0"
            (click)="backToMenu()"
            (keyup.enter)="backToMenu()"
            data-e2e="button-back-to-menu"
            title="Click to go back"
            i18n-title
          >
            <span class="icon is-small">
              <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
            </span>
            <span i18n>Back</span>
          </a>

          <h2
            *ngIf="!isMainDropdownFilter && !displayFromFilter"
            class="title is-4 has-margin-top has-margin-bottom"
            i18n="Filter name for Transaction date"
          >
            Transaction date
          </h2>
        </div>

        <div class="dropdown-item is-scrollable">
          <ng-container *ngFor="let entity of entities">
            <portal-search-filter-radio
              [id]="entity.id"
              [text]="entity.text"
              radioName="transactionDate"
              [selectedEntities]="localPreSelectedEntities"
              [refresh$]="refresh$"
              (selected)="onEntityAdded($event)"
            ></portal-search-filter-radio>
          </ng-container>
        </div>

        <hr class="dropdown-divider" />

        <div class="dropdown-item">
          <button
            class="button is-primary"
            (click)="onFilterAppliedAndEntitiesUpdated()"
            (keydown.enter)="onFilterAppliedAndEntitiesUpdated()"
            data-e2e="button-filter"
            type="button"
            i18n
            title="Apply"
            i18n-title
          >
            Apply
          </button>
        </div>
      </ng-template>

      <div *ngIf="isMainDropdownFilter" class="dropdown-menu is-large">
        <div class="dropdown-content">
          <ng-template [ngTemplateOutlet]="dropdownContent"></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
