import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle';
import { Features } from 'environments/enums/features.enum';
@Injectable()
export class CanLoadVHQModuleGuard implements CanLoad {
  constructor(private router: Router, private featureToggleService: FeatureToggle) {}

  canLoad(): boolean {
    const canLoad = this.featureToggleService.isFeatureActive(Features.VHQCore);
    if (!canLoad) {
      this.router.navigate(['/page-not-found']);
    }
    return canLoad;
  }
}
