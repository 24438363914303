import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, InjectFlags, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { DataServiceError } from '@ngrx/data';
import { AuthenticationService } from '@portal/shared/auth/authentication/src/lib/authentication.service';
import { HttpError } from '@portal/shared/error-handler/src/lib/enums/HttpError';
import { ErrorService } from '@portal/shared/ui/form';
import { NotifierService } from '@portal/shared/ui/notifier';
import { ErrorLoggingService } from '../services/error-logging.service';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  get router(): Router {
    return this.injector.get(Router);
  }

  get notifierService(): NotifierService {
    return this.injector.get(NotifierService);
  }

  get authenticationService(): AuthenticationService {
    return this.injector.get(AuthenticationService);
  }

  get ngZone(): NgZone {
    return this.injector.get(NgZone);
  }

  get errorLoggingService(): ErrorLoggingService {
    return this.injector.get(ErrorLoggingService, null, InjectFlags.Optional);
  }

  handleError(error: Error | HttpErrorResponse | DataServiceError | any): void {
    if (!navigator.onLine) {
      this.notifierService.error($localize`No Internet Connection.`);
    }

    if (!error.shouldBeSkipped) {
      this.ngZone.run(() => {
        if (error instanceof HttpErrorResponse || error instanceof DataServiceError) {
          this.serverErrorHandler(error);
        } else {
          this.clientErrorHandler(error);
        }
      });
    }
  }

  private serverErrorHandler(error: HttpErrorResponse | DataServiceError): void {
    const status = ErrorService.getStatusCode(error);
    switch (status) {
      case HttpError.CodeError400:
        this.notifierService.error(ErrorService.getErrorMessage(error));
        break;
      case HttpError.CodeError417:
        this.notifierService.error(ErrorService.getErrorMessage(error));
        break;
      case HttpError.CodeError404:
        this.router.navigate(['/page-not-found'], { skipLocationChange: true });
        break;
      case HttpError.CodeError401:
        this.authenticationService.logout();
        break;
      case HttpError.CodeError403:
        this.router.navigate(['/access-denied'], { skipLocationChange: true });
        break;
      case HttpError.CodeError500:
      case HttpError.CodeError503:
      case HttpError.CodeError504:
        this.notifierService.error(
          $localize`The server is too busy or maintenance is being performed. Try again.`,
        );
        break;
    }
    this.errorLoggingService?.postErrorLog(error);
    console.error(error);
  }

  private clientErrorHandler(error: Error): void {
    console.error(error);
  }
}
