import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { AddressType, GeometryType, IAddress } from '@portal/entity-services/interfaces';
import { CountryService, ICountry, TimeZoneService } from '@portal/shared/helpers';
import { ErrorService, MultilingualValidators } from '@portal/shared/ui/form';
import { forEach } from 'lodash';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { GeometryHelper } from '../../helpers/geometry.helper';
import { ICountrySubdivision } from '@portal/entity-services/interfaces/src/lib/organisations/interfaces/country-subdivion.interface';
import { CountrySubdivisionHelper } from '../../helpers/country-subdivision.helper';
import { ITimeZone } from '@portal/shared/helpers/src/lib/time-zone/interfaces/time-zone.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'portal-form-address',
  templateUrl: './form-address.component.html',
})
export class FormAddressComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() value: IAddress;
  @Input() type: AddressType;
  @Input() countries: ICountry[];
  @Input() hideFields: string[] = [];
  countrySubdivisions: ICountrySubdivision[];
  mapZonesByContient = this.timeZoneService.mapZonesByContient;
  timeZones = this.mapZonesByContient.get('Worldwide');

  formControls: { [key: string]: [string | string[], ValidatorFn[]] };
  subscriptions = new Subscription();

  isInvalid = ErrorService.isFieldInvalid;

  canSkipStateAndProvince = false;
  stateExcludeList = new Set(['NZL', 'ISR']);

  constructor(
    private timeZoneService: TimeZoneService,
    private countryService: CountryService,
    private customValidators: MultilingualValidators,
    private geometryHelper: GeometryHelper,
    private countrySubdivisionHelper: CountrySubdivisionHelper,
  ) {
    this.formControls = {
      addressUid: ['', []],
      addressLine1: [
        '',
        [
          this.customValidators.required,
          this.customValidators.maxLength(40),
          this.customValidators.validateCharacters,
        ],
      ],
      addressLine2: [
        '',
        [this.customValidators.maxLength(40), this.customValidators.validateCharacters],
      ],
      city: [
        '',
        [
          this.customValidators.required,
          this.customValidators.validateCharacters,
          this.customValidators.maxLength(28),
        ],
      ],
      postCode: ['', [this.customValidators.required, this.customValidators.validateCharacters]],
      addressType: ['', [this.customValidators.required, this.customValidators.validateCharacters]],
      country: ['', [this.customValidators.required, this.customValidators.validateCharacters]],
      countrySubdivision: [null, [this.customValidators.validateCharacters]],
    };
  }

  ngOnInit(): void {
    forEach(this.formControls, (formControl: [string, ValidatorFn[]], key: string) => {
      if (!this.hideFields.includes(key)) {
        const [state, validators]: [string, ValidatorFn[]] = formControl;
        this.form.setControl(key, new FormControl(state, validators));
      }
    });
    this.canSkipStateAndProvince = this.stateExcludeList.has(this.value?.country);

    if (!this.canSkipStateAndProvince) {
      this.populateCountrySubdivisions(this.value?.country);
    }

    if (!this.hideFields.includes('coordinates')) {
      this.form.setControl(
        'geometry',
        new FormGroup({
          type: new FormControl(GeometryType.Point),
          coordinates: new FormArray([
            new FormControl('', [
              this.customValidators.longitude,
              this.customValidators.conditional(
                (coordinates: FormArray) =>
                  !this.geometryHelper.isCoordinateEmpty(coordinates.at(1).value),
                this.customValidators.required,
              ),
            ]),
            new FormControl('', [
              this.customValidators.latitude,
              this.customValidators.conditional(
                (coordinates: FormArray) =>
                  !this.geometryHelper.isCoordinateEmpty(coordinates.at(0).value),
                this.customValidators.required,
              ),
            ]),
          ]),
        }),
      );

      this.subscriptions.add(
        this.longitude.valueChanges
          .pipe(distinctUntilChanged())
          .subscribe(() => this.latitude.updateValueAndValidity()),
      );

      this.subscriptions.add(
        this.latitude.valueChanges
          .pipe(distinctUntilChanged())
          .subscribe(() => this.longitude.updateValueAndValidity()),
      );
    }

    this.form.get('addressType').setValue(this.type);

    if (this.value) {
      this.form.patchValue(this.value);
      const continent = this.countryService.countriesAlpha3.get(this.value.country)?.continent;
      if (continent) {
        this.timeZones = this.getContinentTimeZones(continent);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get addressLine1(): AbstractControl {
    return this.form.get('addressLine1');
  }

  get addressLine2(): AbstractControl {
    return this.form.get('addressLine2');
  }

  get country(): AbstractControl {
    return this.form.get('country');
  }

  get countrySubdivision(): AbstractControl {
    return this.form.get('countrySubdivision');
  }

  get city(): AbstractControl {
    return this.form.get('city');
  }

  get postCode(): AbstractControl {
    return this.form.get('postCode');
  }

  get addressType(): AbstractControl {
    return this.form.get('addressType');
  }

  get coordinates(): FormArray {
    return this.form.get('geometry.coordinates') as FormArray;
  }

  get longitude(): AbstractControl {
    return this.coordinates.at(0);
  }

  get latitude(): AbstractControl {
    return this.coordinates.at(1);
  }

  onCountrySelected(country: ICountry): void {
    this.countrySubdivision.reset();
    this.canSkipStateAndProvince = this.stateExcludeList.has(country?.alpha3);

    if (this.canSkipStateAndProvince) {
      this.form.controls.countrySubdivision.setErrors(null);
      this.form.controls.countrySubdivision.clearValidators();
    } else {
      this.countrySubdivision.setValidators([
        this.customValidators.required,
        this.customValidators.validateCharacters,
      ]);
    }

    if (country?.alpha2 && !this.canSkipStateAndProvince) {
      this.populateCountrySubdivisions(country.alpha2);
    }
  }

  asIsOrder(): number {
    return 1;
  }

  private populateCountrySubdivisions(countryCode: string): void {
    this.countrySubdivisions =
      this.countrySubdivisionHelper.getSubdivisionFromCountryCode(countryCode);
  }

  private getContinentTimeZones(continent: string): ITimeZone[] {
    return this.mapZonesByContient
      .get(continent)
      .slice()
      .sort((a, b) => {
        return (
          parseInt(a.offset.substring(4), 10) - parseInt(b.offset.substring(4), 10) ||
          a.offset.substring(4).localeCompare(b.offset.substring(4)) ||
          a.name.localeCompare(b.name)
        );
      });
  }
}
