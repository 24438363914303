import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.router.navigated = false;

    if (!this.authService.isAuthenticated || this.authService.hasTokenExpired()) {
      this.authService.authenticate(route['_routerState'].url);
      return false;
    }

    return true;
  }
}
