import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IOrganisation } from '@apps/portal/src/app/core/core.module';
import { ErrorService } from '@portal/shared/ui/form/src';
import { concat, of, Observable, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { OrganisationService } from '../../services/organisation.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'portal-organisation-dropdown',
  templateUrl: './organisation-dropdown.component.html',
})
export class OrganisationDropdownComponent implements OnInit {
  @Input() isReadOnly = false;
  @Input() preselectedEntityId?: string;
  @Input() control: AbstractControl;
  @Input() organisations: IOrganisation[];
  @Input() populateParentEntity = false;
  @Output() selectedOrganisation = new EventEmitter<IOrganisation>();
  @Output() itemsFiltered = new EventEmitter<{ isFirstLoad: boolean; orgs: IOrganisation[] }>();

  limit = 100;
  isFirstLoad = true;
  organisations$: Observable<IOrganisation[]>;
  loading$: Observable<boolean>;
  setSearchInput$ = new Subject<string>();
  searchInput$ = this.setSearchInput$.asObservable();

  isInvalid = ErrorService.isFieldInvalid;

  constructor(private organisationService: OrganisationService) {
    this.loading$ = organisationService.loading$;
  }

  ngOnInit(): void {
    const initData$ = this.organisations ? of(this.organisations) : this._getInitialOrganisations();

    this.organisations$ = concat(
      initData$,
      this.searchInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((name) => !Object.is(name, null)),
        switchMap((name: string | null) => {
          return this.organisationService.getWithQuery({
            name,
            limit: `${this.limit}`,
            populateParentEntity: String(this.populateParentEntity),
          });
        }),
        tap((orgs) => this._emitOrganisations(orgs)),
        catchError(() => of([])),
      ),
    );
  }

  onOrganisationSelected(organisation: IOrganisation): void {
    this.selectedOrganisation.emit(organisation);
  }

  private _emitOrganisations(orgs: IOrganisation[]): void {
    this.itemsFiltered.emit({ isFirstLoad: this.isFirstLoad, orgs });
    this.isFirstLoad = false;
  }

  private _getInitialOrganisations(): Observable<IOrganisation[]> {
    return this.preselectedEntityId
      ? this.organisationService.getByKey(this.preselectedEntityId).pipe(
          map((org) => [org]),
          tap((orgs) => this._emitOrganisations(orgs)),
        )
      : this.organisationService
          .getWithQuery({
            limit: `${this.limit}`,
            populateParentEntity: String(this.populateParentEntity),
          })
          .pipe(tap((orgs) => this._emitOrganisations(orgs)));
  }
}
