/* eslint-disable @typescript-eslint/naming-convention */
import environment from 'environments/environment';

const ES_CONSTANTS = {
  ADMINISTRATION_ROUTE_PREFIX: 'administration',
  RECEIPT_SERVICE: environment.API_ENDPOINT.RECEIPT_SERVICE,
  ONBOARDING: environment.API_ENDPOINT.ONBOARDING,
  ONBOARDING_PAYPAL: environment.API_ENDPOINT.ONBOARDING_PAYPAL,
  ENTITY_SERVICE: {
    ROOT: environment.API_ENDPOINT.ENTITY_SERVICE,
    ORGANISATION: `${environment.API_ENDPOINT.ENTITY_SERVICE}/entities/`,
    ORGANISATION_LIGHTWEIGHT: `${environment.API_ENDPOINT.ENTITY_SERVICE}/entities/lightweight/`,
    PAYMENT_CONTRACT: `${environment.API_ENDPOINT.ENTITY_SERVICE}/paymentContracts/`,
    ORGANISATION_PAYMENT_CONTRACT: 'paymentContracts',
    THREE_D_SECURE_CONTRACT: `${environment.API_ENDPOINT.ENTITY_SERVICE}/threeDSContracts/`,
    USER: `${environment.API_ENDPOINT.ENTITY_SERVICE}/users/`,
    ORGANISATION_USER: 'users',
    POINT_INTERACTION: `${environment.API_ENDPOINT.ENTITY_SERVICE}/pois/`,
    ORGANISATION_POINT_INTERACTION: 'pois',
    ORGANISATION_ADDRESSES: 'addresses',
    ORGANISATION_CONTACTS: 'contacts',
    ORGANISATION_FEES: 'fees',
    ORGANISATION_ACCOUNTS: 'accounts',
    PAYMENT_CONTRACT_POINT_INTERACTION: 'poiRelationships',
    PAYMENT_CONTRACT_PROCESSOR_CONFIG: 'processors/configs',
    PAYMENT_CONTRACT_PROCESSOR_PARAMETERS: 'processors/parameters',
    PAYMENT_CONTRACT_PROCESSOR_ACCOUNT_DETAILS: 'processors/accountDetails',
    POINT_INTERACTION_PAYMENT_CONTRACT: 'paymentContracts',
    POINT_INTERACTION_DEVICES: 'devices',
    ORGANISATION_BUSINESS_INFORMATION: 'businessInformation',
    PAYMENT_CONTRACT_SETTLEMENT: 'settlement',
    WALLET_CONTRACTS: `walletContracts`,
    ACQUIRER: 'acquirers',
    PROCESSOR: 'processors',
    API_KEYS: 'apikeys',
    PRICE_LISTS: `${environment.API_ENDPOINT.ENTITY_SERVICE}/pricelists/`,
    ORG_PRICE_LISTS: `pricelists/`,
    FRAUD_PROVIDER: `${environment.API_ENDPOINT.ENTITY_SERVICE}/fraudProtectionContracts/`,
    SINGLE_USER_RMS_ENTITY_SERVICE: environment.API_ENDPOINT.SINGLE_USER_RMS_ENTITY_SERVICE,
    GRANT_TYPE: 'client_credentials',
    SURCHARGE_SETTINGS: 'surchargeSettings',
    VHQ_ESTATE_OWNER_SERVICE: environment.API_ENDPOINT.VHQ_ESTATE_OWNER_SERVICE,
  },
  STATUS_OPTION: {
    STATUS_ACTIVE: '?status=ACTIVE',
    STATUS_INACTIVE: '?status=INACTIVE',
    STATUS_BOTH: '?status=ACTIVE&status=INACTIVE',
  },
  UIS: {
    RESET_PASSWORD: `${environment.API_ENDPOINT.UIS_INTERNAL}/users/resetPassword`,
    CHANGE_PASSWORD: `${environment.API_ENDPOINT.UIS_INTERNAL}/users/changePassword`,
    TEMPLATE_RESELLER: `${environment.API_ENDPOINT.UIS_INTERNAL}/templates/domain`,
  },
  BIN_SERVICE: {
    BIN_RULES: `${environment.API_ENDPOINT.BIN_SERVICE}/binRules`,
    CARD_TABLE_GROUPS: `${environment.API_ENDPOINT.BIN_SERVICE}/cardTableGroups`,
  },
};
export { ES_CONSTANTS };
