import { Component, EventEmitter, Input, Output } from '@angular/core';
import { INavbarItem } from '../../interfaces/navbar-item.interface';

@Component({
  selector: 'vui-expansion-menu',
  templateUrl: './expansion-menu.component.html',
})
export class ExpansionMenuComponent {
  @Input() navbarItems: INavbarItem[];
  @Output() navigationStarted = new EventEmitter<void>();

  currentItem: INavbarItem = null;
  history = [];

  forward(item: INavbarItem): void {
    this.history.push(this.currentItem);
    this.currentItem = item;
  }

  back(): void {
    this.currentItem = this.history.pop();
  }

  close(i: number, items: INavbarItem[]): void {
    if (i === items.length - 1) {
      this.navigationStarted.emit();
    }
  }

  onNavbarItemClick(event: MouseEvent, navbarItem: INavbarItem): void {
    if (navbarItem.onClick) {
      event.preventDefault();
      navbarItem.onClick(event, navbarItem);
    }
    this.navigationStarted.emit();
  }
}
