import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HeroData } from '../interfaces/hero-data.interface';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { MenuRegion } from '../../top-navbar/enum/menu-region.enum';
import { CommonNavbarState } from '../../top-navbar/components/top-common-navbar/common-navbar-state.directive';

const initialState = {
  title: '' as string,
  menuRegion: MenuRegion.Home,
};
type HeroState = typeof initialState;

@Injectable({
  providedIn: 'root',
})
export class HeroService {
  /**
   * @deprecated
   * Use showTitle() and reset() instead
   */
  heroSubscription$ = new Subject<HeroData | null>();

  private state$ = new BehaviorSubject(initialState);

  private get state(): HeroState {
    return this.state$.value;
  }
  get title$(): Observable<string> {
    return this.select((state) => state.title);
  }
  get menuRegion$(): Observable<MenuRegion> {
    return this.select((state) => state.menuRegion);
  }

  constructor() {
    this.heroSubscription$.subscribe((data) => this.showTitle(data?.title, data?.l1NavigationItem));
  }

  showTitle(title: string, menuRegion: MenuRegion): void {
    this.setState({ title, menuRegion });
  }

  reset(): void {
    this.setState({ ...initialState });
  }

  handleNavbarState(navbarState: CommonNavbarState): void {
    if (navbarState) {
      this.showTitle(navbarState.title, navbarState.region);
    } else {
      this.reset();
    }
  }

  private setState(state: Partial<HeroState>): void {
    this.state$.next({
      ...this.state,
      ...state,
    });
  }

  private select<T>(selector: (state: HeroState) => T): Observable<T> {
    return this.state$.pipe(map(selector), distinctUntilChanged());
  }
}
