import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReceiptSalesChannels } from '@portal/entity-services/organisations/src/lib/components/form/receipt-sales-channels.list';
import { ISelectInput } from '@portal/shared/ui/filter/src';

@Component({
  selector: 'portal-channel-control',
  templateUrl: './channel-control.component.html',
})
export class ChannelControlComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() control: FormControl;
  @Input() invalid: boolean;
  @Input() e2eName = 'channelControl';
  @Input() emptyOptionalName: string;
  @Input() e2eErrorName = 'channelControl-error';
  @Input() bindValue = 'id';
  @Input() bindLabel = 'text';
  @Input() multiple = true;
  @Input() closeOnSelect: boolean;
  @Input() salesChannels: ISelectInput[];
  @Input() assignedChannels: string[] = [];
  @Input() isForOrgTemplate = false;

  @Output() remove = new EventEmitter<string>();

  constructor(private receiptSalesChannels: ReceiptSalesChannels) {}

  ngOnInit(): void {
    if (this.isForOrgTemplate) {
      this.updateDisabledItems();
    }
  }

  ngOnChanges(): void {
    if (this.isForOrgTemplate) {
      this.updateDisabledItems();
    }
  }

  updateDisabledItems(): void {
    this.salesChannels = this.receiptSalesChannels.list;
    if (this.salesChannels.length) {
      this.salesChannels = this.salesChannels.map((item) => {
        item.disabled =
          !this.control.value.includes(item.id) && this.assignedChannels.includes(item.id);
        return item;
      });
    }
  }

  isChannelDisabled(item): boolean {
    return !this.control.value.includes(item.id) && this.assignedChannels?.includes(item.id);
  }
}
