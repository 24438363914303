<div class="container is-narrow">
  <a
    [restrict-permission]="['read']"
    [routerLink]="['/administration', 'users']"
    data-e2e="link-back"
    title="Back to users"
    i18n-title
  >
    <span class="icon is-small">
      <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
    </span>
    <span i18n="Link back to Users">Users</span>
  </a>

  <h1 class="title is-2" data-e2e="title" i18n="Form title">Add new user</h1>

  <portal-form-user
    *ngIf="!loading"
    [loggedInUser]="loggedInUser"
    [disabled]="saving"
    [saving]="saving"
    [isUserNameToCreate]="true"
    (formValidated)="onFormValidated($event)"
  ></portal-form-user>
</div>
