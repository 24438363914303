import { Application } from '@portal/shared/auth/authorization/src/lib/enums/application.enum';
import { Locale } from '@portal/shared/languages/src/lib/enums/locale.enum';

const PAYMENTS_LANGUAGES = [
  Locale.Ar,
  Locale.Da,
  Locale.De,
  Locale.El,
  Locale.EnUs,
  Locale.Et,
  Locale.EsMx,
  Locale.Fi,
  Locale.Fr,
  Locale.He,
  Locale.Is,
  Locale.It,
  Locale.Lt,
  Locale.Lv,
  Locale.NlBe,
  Locale.No,
  Locale.Pl,
  Locale.Pt,
  Locale.Ru,
  Locale.Sv,
];

export const APPS_SUPPORTED_LANGUAGES = {
  [Application.Wrapper]: PAYMENTS_LANGUAGES,
  [Application.PaymentsPortal]: PAYMENTS_LANGUAGES,
  [Application.MOP]: [
    Locale.ElGr,
    Locale.En,
    Locale.EsEs,
    Locale.EsMx,
    Locale.EtEE,
    Locale.DaDk,
    Locale.DeDe,
    Locale.FiFI,
    Locale.FrCa,
    Locale.FrFr,
    Locale.IsIS,
    Locale.ItIt,
    Locale.LtLT,
    Locale.LvLV,
    Locale.NlBe,
    Locale.NlNl,
    Locale.NoNO,
    Locale.PlPl,
    Locale.PtPt,
    Locale.SvSE,
  ],
  [Application.Petro]: [],
  [Application.VHQ]: [
    Locale.Ar,
    Locale.Da,
    Locale.En,
    Locale.Et,
    Locale.Fi,
    Locale.Fr,
    Locale.He,
    Locale.Lt,
    Locale.Lv,
    Locale.No,
    Locale.Pl,
    Locale.Pt,
    Locale.Ru,
    Locale.Sv,
  ],
  [Application.Marketplace]: [],
  [Application.Authentication]: [
    Locale.Ar,
    Locale.Da,
    Locale.De,
    Locale.En,
    Locale.EsMx,
    Locale.Et,
    Locale.Fi,
    Locale.Fr,
    Locale.He,
    Locale.Is,
    Locale.It,
    Locale.Lt,
    Locale.Lv,
    Locale.No,
    Locale.NlBe,
    Locale.Pl,
    Locale.Pt,
    Locale.Ru,
    Locale.Sv,
  ],
};
export const APPS_DEFAULT_LANGUAGE = {
  [Application.Wrapper]: Locale.EnUs,
  [Application.PaymentsPortal]: Locale.EnUs,
  [Application.MOP]: Locale.En,
  [Application.Petro]: Locale.En,
  [Application.VHQ]: Locale.En,
  [Application.Marketplace]: Locale.En,
  [Application.Authentication]: Locale.En,
};
