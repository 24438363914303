import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import sortBy from 'lodash-es/sortBy';
import { TransactionState } from '../../../enums/transaction-state.enum';
import { SupportedProcessorSettingsService } from '@apps/portal/src/app/services/supported-processor-settings.service';
import { TranslationsKeyValue } from '@portal/shared/helpers/src/lib/create-list-from-inputs/interfaces/translations-key-value.interface';

@Injectable()
export class HeaderStates extends SelectInputsList {
  keyValue: TranslationsKeyValue = {
    [TransactionState.Initiated]: $localize`:transaction state|:Initiated`,
    [TransactionState.Authenticating]: $localize`:transaction state|:Authenticating`,
    [TransactionState.Approved]: $localize`:transaction state|:Approved`,
    [TransactionState.Received]: $localize`:transaction state|:Received`,
    [TransactionState.Declined]: $localize`:transaction state|:Declined`,
    [TransactionState.TimedOut]: $localize`:transaction state|:Timed out`,
    [TransactionState.Routing]: $localize`:transaction state|:Tele`,
    [TransactionState.Sent]: $localize`:transaction state|:Sent`,
    [TransactionState.Reversing]: $localize`:transaction state|:Reversing`,
    [TransactionState.Reversed]: $localize`:transaction state|:Reversed`,
    [TransactionState.ReverseError]: $localize`:transaction state|:Reverse Error`,
    [TransactionState.Authorised]: $localize`:transaction state|:Authorised`,
    [TransactionState.Repeating]: $localize`:transaction state|:Repeating`,
    [TransactionState.OnHold]: $localize`:transaction state|:On hold`,
    [TransactionState.Expired]: $localize`:transaction state|:Expired`,
    [TransactionState.Cancelled]: $localize`:transaction state|:Cancelled`,
    [TransactionState.Refunded]: $localize`:transaction state|:Refunded`,
    [TransactionState.Completed]: $localize`:transaction state|:Completed`,
    [TransactionState.AwaitingInvoice]: $localize`:transaction state|:Awaiting Invoice`,
    [TransactionState.ReadyPP]: $localize`:transaction state|:Ready PP`,
    [TransactionState.Submitted]: $localize`:transaction state|:Submitted`,
    [TransactionState.Success]: $localize`:transaction state|:Success`,
    [TransactionState.SettlementFailed]: $localize`:transaction state|:Settlement failed`,
    [TransactionState.SettlementRequested]: $localize`:transaction state|:Settlement requested`,
    [TransactionState.SettlementCompleted]: $localize`:transaction state|:Settlement completed`,
    [TransactionState.Settled]: $localize`:transaction state|:Settled`,
    [TransactionState.Failed]: $localize`:transaction state|:Failed`,
    [TransactionState.Disputed]: $localize`:transaction state|:Disputed`,
    [TransactionState.ReasonUpdated]: $localize`:transaction state|:Reason updated`,
    [TransactionState.Appealed]: $localize`:transaction state|:Appealed`,
    [TransactionState.Unmatched]: $localize`:transaction state|:Unmatched`,
    [TransactionState.Exception]: $localize`:transaction state|:Exception`,
    [TransactionState.Voided]: $localize`:transaction state|:Voided`,
    [TransactionState.Reconciled]: $localize`:transaction state|:Reconciled`,
    [TransactionState.ReconciliationFailed]: $localize`:transaction state|:Reconciliation failed`,
    [TransactionState.Rejected]: $localize`:transaction state|:Rejected`,
    [TransactionState.PartiallyRefunded]: $localize`:transaction state|:Partially refunded`,
    [TransactionState.Deferred]: $localize`:transaction state|:Deferred`,
    [TransactionState.ClearingRequested]: $localize`:transaction state|:Clearing requested`,
    [TransactionState.ClearingCompleted]: $localize`:transaction state|:Clearing completed`,
    [TransactionState.ClearingFailed]: $localize`:transaction state|:Clearing failed`,
    [TransactionState.CustomerAccepted]: $localize`:transaction state|:Customer Accepted`,
    [TransactionState.Confirmed]: $localize`:transaction state|:Confirmed`,
  };
  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);
  transactionStates: TransactionState[];
  constructor(private supportedProcessorSettingService: SupportedProcessorSettingsService) {
    super();
    this.getSortedList();
  }

  getSortedList(transactionStates = this.keyValue): void {
    this.list = sortBy(this.getSelectInputs(transactionStates), (item: ISelectInput) =>
      item.text.toLowerCase(),
    );
  }

  filteredList = (trxStates: TransactionState[]): ISelectInput[] => {
    return trxStates.map((trxType) => {
      return {
        id: trxType,
        text: this.keyValue[trxType],
      };
    });
  };

  supportedTransactionStatusFromProcessorSettings(): ISelectInput[] {
    const supportedProcessorSettings =
      this.supportedProcessorSettingService.getSupportedProcessorSettingsFromSessionStorage();
    if (supportedProcessorSettings?.transactionStatesSupported?.length) {
      const supportedTransactionStates = this.list.filter((transactionState) => {
        return supportedProcessorSettings?.transactionStatesSupported?.includes(
          transactionState.id,
        );
      });
      return supportedTransactionStates;
    }
    return this.list;
  }
}
