import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MultiselectTreeItem } from '@portal/shared/ui/tree/src/lib/portal-multiselect-tree/multiselect-tree-item.interface';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import cloneDeep from 'lodash-es/cloneDeep';

declare const $localize;

@UntilDestroy()
@Component({
  selector: 'portal-multiselect-tree',
  templateUrl: './portal-multiselect-tree.component.html',
  styleUrls: ['./portal-multiselect-tree.component.scss'],
})
export class PortalMultiselectTreeComponent implements OnInit, OnChanges {
  @Input() items: MultiselectTreeItem[] = [];
  @Input() itemHeight = 20;
  // @Input() selectedIds: string[] = []; not used for now
  @Input() notFoundMessage = $localize`Items not found`;
  @Input() isDisabled: boolean;
  @Output() selectItems = new EventEmitter<MultiselectTreeItem[]>();

  treeControl = new NestedTreeControl<MultiselectTreeItem>((node) => node.children);
  dataSourceLocal = new MatTreeNestedDataSource<MultiselectTreeItem>();
  dataSourceView = new MatTreeNestedDataSource<MultiselectTreeItem>();
  searchControl = new FormControl('');
  selectedListItems: MultiselectTreeItem[];

  ngOnInit(): void {
    this.dataSourceLocal.data = this.items;
    this.selectedListItems = this.selectedItems;
    this.dataSourceView.data = this.notSelectedItems(cloneDeep(this.items));
    this.searchControl.valueChanges
      .pipe(debounceTime(300), untilDestroyed(this), distinctUntilChanged())
      .subscribe((value) => {
        this.setFilter(value.toLowerCase());
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.updateTree();
    }
  }

  onApplyClick(): void {
    this.items.forEach((item) => {
      const treeNode = this.dataSourceView.data.find((d) => d.id === item.id);
      item.children.forEach((i) => {
        const childNode = treeNode?.children.find((d) => i.id === d.id);
        if (i?.id === childNode?.id) {
          i.checked = childNode.checked;
        }
      });
    });

    this.selectItems.emit(this.selectedItems);
  }

  getCountSelectedListItems(): number {
    return this.selectedListItems.filter((node) => !node.children.length).length;
  }

  setFilter(value: string, items: MultiselectTreeItem[] = this.dataSourceView.data): void {
    items.forEach((item) => {
      item.visible = item.name.toLowerCase().includes(value);
      const parent = this.getParent(item).parent;
      if (parent) {
        this.setParentFilter(value, parent, item.visible);
      }

      if (item.children) {
        this.setFilter(value, item.children);

        if (value.length) {
          this.treeControl.expand(item);
        } else {
          this.treeControl.collapse(item);
        }
      }
    });

    this.selectedListItems = this.selectedItems.filter((item) =>
      item.name.toLowerCase().replace('/_/g', '').includes(value),
    );
  }

  setParentFilter(value: string, node: MultiselectTreeItem, visible: boolean): void {
    node.visible = visible || node.visible || node.name.includes(value);
    const parent = this.getParent(node).parent;
    if (parent) {
      this.setParentFilter(value, parent, node.visible);
    }
  }

  onNodeCheck(item: MultiselectTreeItem, isChecked: boolean): void {
    if (this.isDisabled) return;
    item.checked = isChecked;
    this.setChildrenState(item.children, isChecked);
    this.setParentsState(item);
    this.expandChildren(item);
  }

  isPartiallySelected(item: MultiselectTreeItem): boolean {
    return this.hasSelectedChildren(item) && !this.areAllChildrenChecked(item);
  }

  hasChild(index: number, node: MultiselectTreeItem): boolean {
    return !!node.children && node.children.length > 0;
  }

  noFilteredItem(nodes: MultiselectTreeItem[] = this.dataSourceView.data): boolean {
    return !nodes.find(({ visible }) => visible);
  }

  getParentName(item: MultiselectTreeItem): MultiselectTreeItem {
    return this.getParent(item, this.dataSourceLocal.data)?.parent;
  }

  private updateTree(): void {
    this.items.forEach((treeNode) => {
      treeNode.checked = this.areAllChildrenChecked(treeNode);
      if (this.hasSelectedChildren(treeNode)) {
        this.expandChildren(treeNode);
      }
    });
  }

  private expandChildren(item: MultiselectTreeItem): void {
    this.treeControl.expand(item);
    if (item.children.length) {
      item.children.forEach((childItem) => this.expandChildren(childItem));
    }
  }

  private get selectedItems(): MultiselectTreeItem[] {
    const items = [];
    this.dataSourceLocal.data.forEach((item) => this.fillSelectedItems(items, item));
    return items;
  }

  private notSelectedItems(items: MultiselectTreeItem[]): MultiselectTreeItem[] {
    return items
      .filter((item) => {
        if (item.children.length) {
          item['children'] = item.children.filter((child) => !child.checked);
          return item;
        }
      })
      .filter((i) => i.children.length !== 0);
  }

  private fillSelectedItems(selectedItems: MultiselectTreeItem[], item: MultiselectTreeItem): void {
    if (item?.checked && this.areAllChildrenChecked(item)) {
      selectedItems.push(item);
    }

    if (item?.children?.length) {
      item.children.forEach((childItem) => {
        this.fillSelectedItems(selectedItems, childItem);
      });
    }
  }

  private setChildrenState(children, state: boolean): void {
    if (!children.length) {
      return;
    }

    children.forEach((item) => {
      item.checked = state;
      this.setChildrenState(item.children, state);
    });
  }

  private setParentsState(item: MultiselectTreeItem): void {
    const parent = this.getParent(item).parent;
    if (parent) {
      if (this.areAllChildrenChecked(parent)) {
        parent.checked = true;
      }

      if (!this.hasSelectedChildren(parent)) {
        parent.checked = false;
      }

      this.setParentsState(parent);
    }
  }

  private hasSelectedChildren(item: MultiselectTreeItem): boolean {
    if (!item.children?.length) {
      return false;
    }

    return item.children.some((childItem) => {
      if (childItem.checked) {
        return true;
      }

      return this.hasSelectedChildren(childItem);
    });
  }

  private areAllChildrenChecked(item: MultiselectTreeItem): boolean {
    if (!item.children?.length) {
      return item.checked;
    }
    return item.children.every((childItem) => this.areAllChildrenChecked(childItem));
  }

  private getParent(
    item: MultiselectTreeItem,
    children: MultiselectTreeItem[] = this.dataSourceView.data,
  ): { found: boolean; parent: MultiselectTreeItem } {
    // loops threw each child to find selected item in all items
    // for root node it doesn't return parent and marks item found
    // using found parameter it gets parent from upper level of recursion
    let result = { found: false, parent: null };
    children.forEach((node) => {
      if (node.id === item.id) {
        result = { found: true, parent: null };
        return;
      }
      if (node.children.length) {
        const { found, parent } = this.getParent(item, node.children);
        if (found) {
          result = parent ? { found, parent } : { found, parent: node };
        }
      }
    });

    return result;
  }
}
