import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
export interface IDropDownItem {
  id: string;
  display: string;
  isSelected: boolean;
}
@Component({
  selector: 'marketplace-version-selector',
  templateUrl: './version-selector.component.html',
  styleUrls: ['./version-selector.component.scss'],
})
export class VersionSelectorComponent implements OnInit, OnChanges {
  @Input() items: IDropDownItem[];
  itemSelected;
  @Output() selectVersion: EventEmitter<string> = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {
    this.itemSelected = 'aced928a-3004-4e6f-bb78-3ea0b664ea8c';
  }
  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['items'].currentValue) {
    //   if (changes['items'].currentValue['length'] > 0) {
    //     this.item = 0;
    //   }
    // }
  }
  onSelect(item: any): void {
    //this.selectVersion.emit(version);
  }
  compare(item1, item2): boolean {
    return true;
  }
}
