import { Component } from '@angular/core';
import { FilterStaticBase } from '@portal/shared/ui/filter/src/lib/components/static-base';
import {
  FILTER_CONTENT_ITEM,
  ISelectInput,
  SearchOperator,
  SearchSelector,
} from '@portal/shared/ui/filter/src';
import { ReportTypes } from '../../../list/report-type.list';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';
import { Features } from 'environments/enums/features.enum';
import { ReportType } from '../../../enum/report-type.enum';

declare const $localize;
@Component({
  selector: 'portal-filter-report-type',
  templateUrl: './report-type.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: FilterReportTypeComponent,
    },
  ],
})
export class FilterReportTypeComponent extends FilterStaticBase {
  reportTypeList: ISelectInput[];
  wicOfflineReportingFeature: boolean;
  isCustomScheduledReportFeature: boolean;
  isPartnerCommissionReportFeature: boolean;
  isMerchantConfigurationFeature: boolean;
  isInvoiceReportFeature: boolean;
  serviceProviderText = $localize`Report types`;

  constructor(reportTypes: ReportTypes, public featureToggleService: FeatureToggle) {
    super(SearchSelector.ReportType, reportTypes.list);
    this.reportTypeList = reportTypes.list;

    this.isCustomScheduledReportFeature = this.featureToggleService.isFeatureActive(
      Features.ReportScheduler,
    );

    this.isMerchantConfigurationFeature = this.featureToggleService.isFeatureActive(
      Features.MerchantConfiguration,
    );

    if (!this.isMerchantConfigurationFeature) {
      this.reportTypeList = this.reportTypeList?.filter((reportType) => {
        return reportType.id !== ReportType.UkTaxReport && reportType.id !== ReportType.EuTaxReport;
      });
    }

    if (!this.isCustomScheduledReportFeature) {
      this.reportTypeList = this.reportTypeList?.filter((reportType) => {
        return reportType.id !== ReportType.CustomScheduledReport;
      });
    }

    this.isPartnerCommissionReportFeature = this.featureToggleService.isFeatureActive(
      Features.PartnerCommissionReport,
    );

    if (!this.isPartnerCommissionReportFeature) {
      this.reportTypeList = this.reportTypeList?.filter((reportType) => {
        return reportType.id !== ReportType.PartnerCommissionReport;
      });
    }

    this.isInvoiceReportFeature = this.featureToggleService.isFeatureActive(Features.Invoice);

    if (!this.isInvoiceReportFeature) {
      this.reportTypeList = this.reportTypeList?.filter((reportType) => {
        return (
          reportType.id !== ReportType.BBXMonthlyInvoiceReport &&
          reportType.id !== ReportType.BBXDailyInvoiceReport
        );
      });
    }

    this.wicOfflineReportingFeature = this.featureToggleService.isFeatureActive(
      Features.WICOfflineReporting,
    );

    if (!this.wicOfflineReportingFeature) {
      this.reportTypeList = this.reportTypeList?.filter((reportType) => {
        return (
          reportType.id !== ReportType.WICReconciliationTransactionReport &&
          reportType.id !== ReportType.WICReconciliationAdjustmentReport &&
          reportType.id !== ReportType.WICReconciliationSummaryReport
        );
      });
    }
  }

  onEntityRemoved(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.delete(selectedEntity.id);
  }

  onEntityAdded(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.set(selectedEntity.id, selectedEntity);
  }

  onFilterApplied(): void {
    this.updateEntities();
    const values: string[] = this.getValues();

    this.filterApplied.emit({
      key: this.searchSelector,
      operator: SearchOperator.In,
      value: Array.from(this.localPreSelectedEntities.values()),
      argument: `(${Array.from(values).join(',')})`,
    });

    this.updateText();
    this.itemToggled.emit();
  }

  removeAllEntities(): void {
    this.localPreSelectedEntities.clear();
    this.updateText();
    if (this.isOpen) {
      this.itemToggled.emit();
    }
  }

  updateText(): void {
    const values: string[] = this.getValues();
    const text = $localize`Report types`;
    if (values.length) {
      this.serviceProviderText = `${text} (+${values.length})`;
    } else {
      this.serviceProviderText = text;
    }
  }
}
