import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import sortBy from 'lodash-es/sortBy';
import { LegalEntityType } from '@portal/entity-services/interfaces/src/lib/entities/enums/legal-entity-type.enum';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class LegalEntityTypes extends SelectInputsList {
  keyValue = {
    [LegalEntityType.CIC]: $localize`CIC`,
    [LegalEntityType.CIO]: $localize`CIO`,
    [LegalEntityType.CHARITY]: $localize`Charity`,
    [LegalEntityType.COOP]: $localize`COOP`,
    [LegalEntityType.GENERAL_PARTNERSHIP]: $localize`General Partnership`,
    [LegalEntityType.INDIVIDUAL]: $localize`Individual`,
    [LegalEntityType.LIMITED_LIABILITY_PARTNERSHIP]: $localize`Limited Liability Partnership`,
    [LegalEntityType.LIMITED_PARTNERSHIP]: $localize`Limited Partnership`,
    [LegalEntityType.PRIVATE_LIMITED_COMPANY]: $localize`Private Limited Company`,
    [LegalEntityType.PUBLIC_LIMITED_COMPANY]: $localize`Public Limited Company`,
    [LegalEntityType.SCOTTISH_LIMITED_PARTNERSHIP]: $localize`Scottish Limited Partnership`,
    [LegalEntityType.SOLE]: $localize`Sole`,
  };

  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);
}
