<div *ngIf="form" [formGroup]="form">
  <h2 class="title is-3" i18n>Reseller details</h2>
  <div class="field">
    <div class="is-half">
      <label class="field">
        <div class="label">
          <span i18n>Domain name</span>&nbsp;
          <span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
        </div>
        <div class="control">
          <input
            [formControl]="domainNameControl"
            [ngClass]="{ 'is-danger': isInvalid(domainNameControl) }"
            data-e2e="input-domain-name"
            type="text"
            class="input"
            placeholder="emea.live.verifone.com"
          />
        </div>
        <portal-form-error [formInput]="domainNameControl" errorId="domainName"></portal-form-error>
        <div class="label">
          <span class="has-text-grey has-text-weight-normal" i18n
            >The domain name which will be used by the resellers merchants to access the Payments
            application.</span
          >
        </div>
      </label>
    </div>
  </div>

  <div class="field">
    <div class="is-half">
      <label class="field">
        <div class="label">
          <span i18n>Login Domain Name</span>&nbsp;
          <span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
        </div>
        <div class="control">
          <input
            [formControl]="loginDomainNameControl"
            [ngClass]="{ 'is-danger': isInvalid(loginDomainNameControl) }"
            data-e2e="input-login-domain-name"
            type="text"
            class="input"
            placeholder="emea.verifone.com"
          />
        </div>
        <portal-form-error
          [formInput]="loginDomainNameControl"
          errorId="loginDomainName"
        ></portal-form-error>
      </label>
    </div>
  </div>

  <div class="field">
    <div class="is-half">
      <label class="field">
        <div class="label">
          <span i18n>Email sender</span>&nbsp;
          <span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
        </div>
        <div class="control">
          <input
            class="input"
            [formControl]="emailDomainNameControl"
            data-e2e="input-email-domain-name"
            type="text"
            placeholder="verifone.com"
            [ngClass]="{ 'is-danger': isInvalid(emailDomainNameControl) }"
          />
        </div>
        <portal-form-error
          [formInput]="emailDomainNameControl"
          errorId="emailSender"
        ></portal-form-error>
      </label>
    </div>
  </div>

  <div class="field">
    <div class="is-half">
      <label class="field">
        <div class="label">
          <span i18n>Brand name</span>&nbsp;
          <span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
        </div>
        <div class="control">
          <input
            class="input"
            [formControl]="brandText"
            placeholder="Verifone"
            data-e2e="input-brand-text"
            type="text"
            [ngClass]="{ 'is-danger': isInvalid(brandText) }"
          />
        </div>
        <div class="label">
          <span class="has-text-grey has-text-weight-normal" i18n>
            This text will be used as replacement for all "Verifone".
          </span>
        </div>
      </label>
    </div>
  </div>

  <div class="field">
    <div class="is-full">
      <label class="field">
        <div class="label">
          <span i18n>Support information</span>&nbsp;
          <span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
        </div>
        <div class="label">
          <span class="has-text-grey has-text-weight-normal" i18n>
            Add ways for customers to contact you, that will be displayed on the Help &amp; support
            page.
          </span>
        </div>
      </label>
    </div>
  </div>

  <div
    class="columns"
    *ngFor="let control of helpPage?.controls; let index = index; let last = last"
  >
    <ng-container
      *ngIf="{
        country: control.get('country'),
        emailOrUrl: control.get('emailOrUrl'),
        notes: control.get('notes')
      } as helpPageControls"
    >
      <div class="column is-4">
        <label class="field">
          <div class="label has-text-weight-light" i18n>Name</div>
          <div class="control">
            <input
              class="input"
              [formControl]="helpPageControls.country"
              data-e2e="input-country"
              type="text"
              placeholder="Country/region"
              i18n-placeholder
              [ngClass]="{ 'is-danger': isInvalid(helpPageControls.country) }"
            />
          </div>
        </label>
      </div>
      <div class="column is-4">
        <label class="field">
          <div class="label has-text-weight-light" i18n>Contact information</div>
          <div class="control">
            <input
              class="input"
              [formControl]="helpPageControls.emailOrUrl"
              data-e2e="input-email"
              type="text"
              placeholder="URL/Email"
              i18n-placeholder
              [ngClass]="{ 'is-danger': isInvalid(helpPageControls.emailOrUrl) }"
            />
          </div>
          <portal-form-error
            [formInput]="helpPageControls.emailOrUrl"
            errorId="help-page-email"
          ></portal-form-error>
        </label>
      </div>
      <div class="column is-3">
        <label class="field">
          <div class="label has-text-weight-light" i18n>Description</div>
          <div class="control">
            <textarea
              class="textarea"
              [formControl]="helpPageControls.notes"
              data-e2e="textarea-notes"
              [ngClass]="{ 'is-danger': isInvalid(helpPageControls.notes) }"
            ></textarea>
          </div>
        </label>
      </div>
      <div class="column">
        <div class="pt-5" *ngIf="helpPage.controls?.length > 1">
          <button
            [disabled]="disabled"
            (click)="helpPage.removeAt(index)"
            data-e2e="action-remove-button"
            type="button"
            class="button"
            title="Remove"
            i18n-title
          >
            <span class="icon">
              <i class="fa fa-times has-text-danger"></i>
            </span>
          </button>
        </div>
        <div class="pt-5" *ngIf="last">
          <button
            [disabled]="disabled"
            (click)="addHelpPageItem()"
            data-e2e="action-add-button"
            type="button"
            class="button"
            title="Add"
            i18n-title
          >
            <span class="icon">
              <i class="fas fa-plus"></i>
            </span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="field">
    <div class="is-half">
      <label class="field">
        <div class="label">
          <span i18n>Privacy Policy URL</span>&nbsp;
          <span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
        </div>
        <div class="control">
          <input
            class="input"
            [formControl]="privacyPolicyUrl"
            data-e2e="input-privacy-policy-url"
            type="text"
            [ngClass]="{ 'is-danger': isInvalid(privacyPolicyUrl) }"
          />
        </div>
      </label>
    </div>
  </div>

  <div class="field">
    <div class="label">
      <span i18n>Branding styles</span>&nbsp;
      <span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
    </div>
    <div class="label">
      <span class="has-text-grey has-text-weight-normal" data-e2e="custom-branding-styles">
        <span *ngIf="!isCustomStylesAvailable" i18n>No custom styles created</span>
        <span *ngIf="isCustomStylesAvailable" i18n>Custom branding styles available</span>
      </span>
    </div>
    <div class="control">
      <button
        type="button"
        class="button is-primary is-outlined"
        data-e2e="button-add-custom-styles"
        [disabled]="disabled"
        [routerLink]="
          currentPage === pages.Edit
            ? ['/administration', 'organisations', entityUid, 'edit', 'white-labeling-preview']
            : ['/administration', 'organisations', entityUid, 'white-labeling-preview']
        "
        (click)="setStateOrgFormValues()"
        title="Update custom styles"
        i18n-title
      >
        <span *ngIf="!isCustomStylesAvailable" i18n>Add custom styles</span>
        <span *ngIf="isCustomStylesAvailable" i18n>Edit custom styles</span>
      </button>
    </div>
    <div
      class="control mt-5"
      *ngIf="
        domainNameControl.value === templateParameters?.id &&
        canEditWhiteLabel &&
        !isTemplateParamsEmpty
      "
    >
      <button
        type="button"
        class="button is-danger is-outlined"
        data-e2e="button-remove-reseller-details"
        (click)="removeResellerDetails.open()"
        i18n
        title="Remove reseller details"
        i18n-title
      >
        Remove reseller details
      </button>
    </div>
  </div>
</div>

<!-- remove reseller details confirmation modal -->
<portal-base-modal #removeResellerDetails>
  <div title i18n>Remove reseller details?</div>
  <p description i18n>Your data will be permanently deleted.</p>
  <div class="level" buttons>
    <div class="level-left"></div>
    <div class="buttons">
      <button
        type="button"
        class="button is-outlined"
        data-e2e="delete-blocking-ruleset-confirm"
        (click)="removeResellerDetails.close()"
        i18n
        title="Cancel"
        i18n-title
      >
        Cancel
      </button>
      <button
        type="button"
        class="button is-primary"
        (click)="onRemoveResellerDetails()"
        i18n
        title="Remove"
        i18n-title
      >
        Remove
      </button>
    </div>
  </div>
</portal-base-modal>
