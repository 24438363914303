import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { BankAccountType } from '@portal/entity-services/interfaces/src/lib/payment-contracts/enums/bank-account-type.enum';
import { IBankAccount } from '@portal/entity-services/interfaces/src/lib/payment-contracts/interfaces/bank-account.interface';
import { AccountHolderTypes } from '@portal/entity-services/payment-contracts/src/lib/components/form/form-paypal-onboarding/account-holder-types.list';
import { BankAccountTypes } from '@portal/entity-services/payment-contracts/src/lib/components/form/form-paypal-onboarding/bank-account-type.list';
import { CountryService } from '@portal/shared/helpers/src/lib/country/country.service';
import { ICountry } from '@portal/shared/helpers/src/lib/country/interfaces/country.interface';
import { CurrencyService } from '@portal/shared/helpers/src/lib/currency/currency.service';
import { ICurrency } from '@portal/shared/helpers/src/lib/currency/interfaces/currency.interface';
import { MultilingualValidators } from '@portal/shared/ui/form/src/lib/error/custom-error.validator';
import { ErrorService } from '@portal/shared/ui/form/src/lib/error/error.service';

@Component({
  selector: 'portal-form-bank-account',
  templateUrl: './form-bank-account.component.html',
})
export class FormBankAccountComponent implements OnInit {
  @Input() bankAccount: FormGroup;
  @Input() bankAccountValue: IBankAccount;
  @Input() radioButtonGroupName: string;

  isInvalid = ErrorService.isFieldInvalid;

  bankAccountTypeList = this.bankAccountTypes.list;
  accountHolderTypesList = this.accountHolderTypes.list;
  countries: ICountry[] = this.countryService.countries;
  currenciesList: ICurrency[] = this.currencyService.currencies.filter((pt) => {
    return pt.code !== 'USS';
  });

  isAccountTypeDomestic = true;
  isAccountTypeSepa = false;
  isAccountTypeExternal = false;

  constructor(
    private countryService: CountryService,
    private currencyService: CurrencyService,
    private bankAccountTypes: BankAccountTypes,
    private accountHolderTypes: AccountHolderTypes,
    private customValidators: MultilingualValidators,
  ) {}

  ngOnInit(): void {
    if (this.bankAccountValue) {
      this.configAccountType(this.bankAccountValue.accountType);
      this.bankAccount.patchValue(this.bankAccountValue);
      // sortCode was replaced by routingTransitNumber, this catch old PPCs
      if (this.bankAccountValue.sortCode) {
        this.routingTransitNumber.patchValue(this.bankAccountValue.sortCode);
      }
    } else {
      this.configAccountType(BankAccountType.Domestic);
    }

    this.accountType.valueChanges.subscribe((value: BankAccountType) =>
      this.configAccountType(value),
    );
  }

  removeCurrency(currencyCode: string): void {
    this.currency.setValue(
      this.currency.value.filter((currency: string) => currency !== currencyCode),
    );
  }

  private configAccountType(type: BankAccountType): void {
    this.isAccountTypeDomestic = type === BankAccountType.Domestic;
    this.isAccountTypeSepa = type === BankAccountType.Sepa;
    this.isAccountTypeExternal = type === BankAccountType.External;

    switch (type) {
      case BankAccountType.Domestic:
        this.resetSepaValidators();
        this.resetExternalValidators();
        this.addDomesticValidators();
        break;
      case BankAccountType.Sepa:
        this.resetDomesticValidators();
        this.resetExternalValidators();
        this.addSepaValidators();
        break;
      case BankAccountType.External:
        this.resetDomesticValidators();
        this.resetSepaValidators();
        this.accountName.setValidators([Validators.required]);
        this.accountName.updateValueAndValidity();
        this.addExternalValidators();
        break;
    }
  }

  private resetExternalValidators(): void {
    this.externalAccountReference.setValidators([]);
    this.externalAccountReference.updateValueAndValidity();
    this.nickname.setValidators([]);
    this.nickname.updateValueAndValidity();
    this.description.setValidators([]);
    this.description.updateValueAndValidity();
  }

  private addExternalValidators(): void {
    this.externalAccountReference.setValidators([this.customValidators.required]);
    this.externalAccountReference.updateValueAndValidity();
    this.nickname.setValidators([
      this.customValidators.required,
      this.customValidators.maxLength(70),
    ]);
    this.nickname.updateValueAndValidity();
    this.description.setValidators([this.customValidators.maxLength(70)]);
    this.description.updateValueAndValidity();
  }

  private resetSepaValidators(): void {
    this.iban.setValidators([]);
    this.iban.updateValueAndValidity();
    this.country.setValidators([]);
    this.country.updateValueAndValidity();
  }

  private addSepaValidators(): void {
    this.iban.setValidators([this.customValidators.required, this.customValidators.iban]);
    this.iban.updateValueAndValidity();
    this.country.setValidators([this.customValidators.required]);
    this.country.updateValueAndValidity();
  }

  private resetDomesticValidators(): void {
    this.accountNumber.setValidators([]);
    this.accountNumber.updateValueAndValidity();
    this.routingTransitNumber.setValidators([]);
    this.routingTransitNumber.updateValueAndValidity();
  }

  private addDomesticValidators(): void {
    this.accountNumber.setValidators([this.customValidators.required]);
    this.accountNumber.updateValueAndValidity();
    this.routingTransitNumber.setValidators([
      this.customValidators.required,
      this.customValidators.digits,
      this.customValidators.minLength(9),
      this.customValidators.maxLength(9),
    ]);
    this.routingTransitNumber.updateValueAndValidity();
  }

  get accountType(): AbstractControl {
    return this.bankAccount.get('accountType');
  }

  get description(): AbstractControl {
    return this.bankAccount.get('description');
  }

  get nickname(): AbstractControl {
    return this.bankAccount.get('nickname');
  }

  get accountNumber(): AbstractControl {
    return this.bankAccount.get('accountNumber');
  }

  get iban(): AbstractControl {
    return this.bankAccount.get('iban');
  }

  get routingTransitNumber(): AbstractControl {
    return this.bankAccount.get('routingTransitNumber');
  }

  get accountName(): AbstractControl {
    return this.bankAccount.get('accountName');
  }

  get accountHolderType(): AbstractControl {
    return this.bankAccount.get('accountHolderType');
  }

  get currency(): AbstractControl {
    return this.bankAccount.get('currency');
  }

  get country(): AbstractControl {
    return this.bankAccount.get('country');
  }

  get externalAccountReference(): AbstractControl {
    return this.bankAccount.get('externalAccountReference');
  }
}
