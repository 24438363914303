<div class="columns is-vcentered">
  <div class="column">
    <portal-search-box
      [isDeviceMobile]="isDeviceMobile$ | async"
      [entities]="searchEntities"
      [searchTerm]="searchTerm"
      [selectedSelector]="selectedSearchSelector"
      [defaultEntityId]="entityNameSelector"
      (entitySearched)="onOrganisationSearched($event)"
      (searchEntityRemoved)="onSearchEntityRemoved($event)"
      (searchBoxInitComplete)="searchBoxInitComplete()"
      (searchUpdated)="onSearchUpdated($event)"
    ></portal-search-box>
    <div class="field is-grouped is-grouped-multiline filter-container has-margin-bottom">
      <div class="control">
        <portal-filter-wrapper>
          <portal-filter-country
            [selectedEntities]="formatselectedEntities()"
            [disabled]="organisationIdSearchSelected"
            (filterApplied)="onCountryFilterApplied($event)"
          >
          </portal-filter-country>
        </portal-filter-wrapper>
      </div>

      <div class="control">
        <portal-filter-wrapper>
          <div class="control">
            <portal-filter-entity-type
              data-e2e="entityType-filter"
              [selectedEntities]="filter?.searchCriteria"
              [disabled]="organisationIdSearchSelected"
              (filterUpdated)="onTypeFilterUpdated($event)"
            ></portal-filter-entity-type>
          </div>
        </portal-filter-wrapper>
      </div>

      <div class="control" *ngIf="filterTouched">
        <button
          i18n
          class="button is-outlined"
          (click)="resetFilter()"
          data-e2e="organisationsFilter-button-clear-filter"
          type="button"
          title="Clear filter"
          i18n-title
        >
          Clear filters
        </button>
      </div>
    </div>
  </div>
</div>
