<form [formGroup]="form">
  <div class="box form-organization-group">
    <div class="row box has-not-shadow has-not-border">
      <h2 class="title is-3" i18n>General details</h2>
      <label class="field">
        <div class="label" i18n>Group name</div>
        <div class="control" *ngIf="!groupModeEdit">
          <input
            class="input"
            [formControl]="name"
            data-e2e="input-group-name"
            type="text"
            [ngClass]="{ 'is-danger': isInvalid(name) }"
          />
          <portal-form-error [formInput]="name" errorId="name"></portal-form-error>
        </div>
        <div class="control" *ngIf="groupModeEdit">
          <span>{{ onSetControlGroupOrganizationValue() }}</span>
          <span>{{ groupViewDetailEdit?.groupOrganization?.name || '-' }}</span>
        </div>
      </label>
    </div>
    <div>
      <div class="rows">
        <div class="row box has-not-shadow has-not-border block-parent-organization">
          <h2 class="title is-3" i18n>Access Rights</h2>
          <label class="field">
            <div class="label" i18n>Parent Organization</div>
            <ng-container *ngIf="!groupModeEdit">
              <portal-hierarchy-single-select
                [control]="parentEntityUid"
                [organizationGroup]="true"
                [displayOptionApplyAndClear]="true"
                data-e2e="user-hierarchy-select"
                (selectedOrganisationAfterApply)="onSelectedOrganisation($event)"
              ></portal-hierarchy-single-select>
              <portal-form-error
                [formInput]="parentEntityUid"
                errorId="entityUid"
              ></portal-form-error>
            </ng-container>
            <ng-container *ngIf="groupModeEdit">
              <span>{{ onSetControlParentEntityUidValue() }}</span>
              <span>{{ groupViewDetailEdit?.parentOrganization?.name || '-' }}</span>
            </ng-container>
          </label>
        </div>
        <div class="row box has-not-shadow has-not-border block-sub-organizations">
          <label class="field" *ngIf="selectedParentEntity">
            <div class="label" i18n>Organizations</div>
            <div class="help is-danger">
              <p *ngIf="preSelectedOrganizationNumber > maximumLimitOrgToAdd">
                {{ messageMaximumLimitOrgReached }}
              </p>
              <p *ngIf="preSelectedOrganizationNumber < minimumLimitOrgToAdd">
                {{ messageMinimumLimitOrgReached }}
              </p>
              <p *ngIf="isParentEntityToExclude">
                {{ messageParentEntityNotIncluded }}
              </p>
            </div>

            <portal-hierarchy-filters
              [control]="entityUids"
              [selectedEntities]="searchPayload.criteriaMap"
              [numberOfSelectedEntities]="100"
              [isMainDropdownFilter]="true"
              [organizationGroup]="true"
              [selectedParentEntity]="selectedParentEntity"
              [selectedParentEntityChanged]="selectedParentEntityChanged"
              [groupModeEdit]="groupModeEdit"
              [selectedSubOrgToEdit]="groupViewDetailEdit?.subOrganizations"
              (resetParentEntityChanged)="onResetParentEntityChanged()"
              (filterApplied)="onFilterApplied($event)"
              (staticFilterUpdated)="onStaticFilterUpdated($event)"
              (allPreSelectedEntitiesNumber)="onAllPreSelectedEntitiesNumber($event)"
              (filterAppliedSelectedEntities)="onFilterAppliedSelectedEntities($event)"
              (openDropDown)="openContentOrganizationGroup($event)"
            ></portal-hierarchy-filters>
          </label>
        </div>
        <div
          class="row box has-not-shadow has-not-border block-users"
          [ngClass]="{ 'filter-open': isOpenFilters }"
          *ngIf="preSelectedOrganizationNumber"
        >
          <label class="field" *ngIf="selectedParentEntity">
            <div class="label mt-0">
              <span i18n>Users</span>
              <span class="has-text-grey has-text-weight-normal" i18n> (optional) </span>
            </div>
            <portal-shared-users-filter
              [control]="userIds"
              [searchUserPayload]="searchUserPayload"
              [selectedEntities]="searchPayload.criteriaMap"
              [selectedParentEntity]="selectedParentEntity"
              [organizationGroup]="true"
              [selectedSubOrganizations]="selectedSubOrganizations"
              [selectedUsersToEdit]="groupViewDetailEdit?.users"
              (filterUpdated)="onFilterUserUpdated($event)"
            ></portal-shared-users-filter>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="box__separator"></div>

  <div
    class="columns is-vcentered is-flex is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
  >
    <div class="column">
      <ng-container *ngIf="!organizationGroup">
        <a
          class="button has-text-danger is-text"
          data-e2e="link-cancel-organization-group"
          *restrictPermission="['cancel-organization-group']"
          (click)="onCancel()"
          i18n
          title="Click to cancel"
          i18n-title
        >
          Cancel
        </a>
      </ng-container>
      <ng-container *ngIf="organizationGroup">
        <a
          class="button has-text-danger is-text"
          data-e2e="link-discard-cancel-organization-group"
          *restrictPermission="['discard-changes-organization-group']"
          [routerLink]="['/administration', 'users', organizationGroup?.entityUid]"
          i18n
          title="Discard changes"
          i18n-title
        >
          Discard changes
        </a>
      </ng-container>
    </div>
    <div class="column has-text-right">
      <button
        *restrictPermission="['save-organization-group']"
        type="submit"
        class="button is-primary"
        [class.is-loading]="saving"
        data-e2e="button-submit-organization-group"
        [disabled]="!canSaveOrganizationGroup || saving"
        title="Save"
        i18n-title
      >
        <span *ngIf="!organizationGroup" i18n (click)="submit()">Save</span>
        <span *ngIf="organizationGroup" i18n>Save changes</span>
      </button>
    </div>
  </div>
</form>

<portal-base-modal #cancelModal>
  <div title i18n>Are you sure you want to cancel?</div>
  <div class="level" buttons>
    <div class="level-left"></div>
    <div class="buttons">
      <button
        type="button"
        class="button is-outlined"
        (click)="cancelModal.close()"
        i18n
        data-e2e="delete-blocking-ruleset-confirm"
        title="No"
        i18n-title
      >
        No
      </button>
      <button
        type="button"
        class="button is-primary"
        (click)="onCancelModalConfirmed()"
        i18n
        title="Yes"
        i18n-title
      >
        Yes
      </button>
    </div>
  </div>
</portal-base-modal>
