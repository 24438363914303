import { ES_CONSTANTS as CONSTANTS } from '@portal/entity-services/interfaces';
import { AuthenticationGuard } from '@portal/shared/auth/authentication';
import { CreateUserComponent } from './components/create/create.component';
import { DetailUserComponent } from './components/detail/detail.component';
import { ListUserComponent } from './components/list/list.component';
import { UpdateUserComponent } from './components/update/update.component';
import { RoleGuardService } from '@portal/shared/auth/authorization/src/lib/role-guard.service';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AuthorizationGuard } from '@portal/shared/auth/authorization/src/lib/authorization.guard';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle';
import { IGuardRoute } from '@portal/shared/auth/authorization/src/lib/interfaces/guard-route.interface';
import { Features } from 'environments/enums/features.enum';
import { RoutePermissions } from './routing/route-permissions.const';
import { RouteName } from './routing/route-name.enum';

export const routes: IGuardRoute[] = [
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/users`,
    component: ListUserComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, AuthorizationGuard],
    data: {
      permission: RoutePermissions[RouteName.List],
    },
  },
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/users/create`,
    component: CreateUserComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, AuthorizationGuard],
    data: {
      permission: RoutePermissions[RouteName.Create],
    },
  },
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/users/:id`,
    component: DetailUserComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, AuthorizationGuard],
    data: {
      permission: RoutePermissions[RouteName.Details],
    },
  },
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/users/:id/edit`,
    component: UpdateUserComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, AuthorizationGuard],
    data: {
      permission: RoutePermissions[RouteName.Edit],
    },
  },
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/users/:id/edit/change-password`,
    component: ChangePasswordComponent,
    canActivate: [AuthenticationGuard, FeatureToggle, AuthorizationGuard],
    data: {
      featureToggle: {
        featureName: Features.ChangePassword,
      },
    },
  },
];
export const routesTemp = routes;
