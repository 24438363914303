/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryParams } from '@ngrx/data';
import {
  BankAccountType,
  CartesBancairesProcessorType,
  EntityStatus,
  EntityType,
  ES_CONSTANTS,
  ES_CONSTANTS as CONSTANTS,
  IAddress,
  IBankAccount,
  IClearing,
  IContact,
  IEntity,
  IEntityAncestors,
  IEntityLightWeight,
  IFee,
  IGeometry,
  IOrganisation,
  IPaymentContract,
  IPointInteraction,
  IReceiptContract,
  IReceiptContractCreatePayload,
  IReceiptContractCreatePayloadData,
  IReceiptContractGetData,
  IReceiptProvider,
  ISettlementInformation,
  IUser,
  PaymentType,
  ProcessorType,
  SalesChannel,
} from '@portal/entity-services/interfaces';
import { IBusinessInformation } from '@portal/entity-services/forms/src/lib/interfaces/business-information.interface';
import { IEntityHierarchy } from '@portal/entity-services/interfaces/src/lib/organisations/interfaces/entity-hierarchy.interface';
import { GeometryHelper } from '../components/helpers/geometry.helper';
import { HttpError } from '@portal/shared/error-handler/src/lib/enums/HttpError';
import { UrlQueryParamsBuilderService } from '@portal/shared/url-query-params-builder';
import assign from 'lodash-es/assign';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import omit from 'lodash-es/omit';
import omitBy from 'lodash-es/omitBy';
import values from 'lodash-es/values';
import pick from 'lodash-es/pick';
import {
  catchError,
  defaultIfEmpty,
  finalize,
  flatMap,
  map,
  mergeAll,
  mergeMap,
  switchMap,
  toArray,
} from 'rxjs/operators';
import { ContactTypes } from '../components/form/contact-type.list';

import { CustomOrganisationDataService } from './organisation-data.service';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subject, throwError } from 'rxjs';
import { IPriceLists } from '@portal/entity-services/price-lists/src/lib/interfaces/price-lists.interface';
import { CountryService } from '@portal/shared/helpers/src/lib/country/country.service';
import { AuthenticationService } from '@portal/shared/auth/authentication';
import { VuiHttpService } from '@portal/shared/vui-http/src/lib/http-wrapper/vui-http.service';
import { TimeZoneService } from '@portal/shared/helpers/src/lib/time-zone/time-zone.service';
import { IQueryParams } from '@portal/shared/ui/table/src/lib/interfaces/query-params.interface';
import { IResultsWithCount } from '@portal/shared/ui/table/src/lib/interfaces/results-with-count.interface';
import { ICount } from '@portal/shared/vui-http/src/lib/interfaces/count.interface';
import { QueryParamsService } from '@portal/shared/ui/table/src/lib/services/query-params.service';
import { ICountry } from '@portal/shared/helpers/src/lib/country/interfaces/country.interface';
import { SearchSelector } from '../enums/search-selector.enum';
import { BlobService } from '@portal/shared/ui/blob/src/lib/blob/blob.service';
import { ReceiptProvider } from '../enums/receipt-provider.enum';
import { ErrorService } from '@portal/shared/ui/form';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';

export const MAX_API_ENTITY_COUNT = 10000;

@Injectable({ providedIn: 'root' })
export class OrganisationService {
  hierarchyViewCache$: Observable<IEntityHierarchy[]>;
  hierarchyViewLoading$: Observable<boolean>;
  hasInvoice4U: boolean;

  private setLoadingAdditionalInfo: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingAdditionalInfo$: Observable<boolean> = this.setLoadingAdditionalInfo.asObservable();
  entities$ = new Subject<IOrganisation[]>();
  private setLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private setPortionLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean> = this.setLoading$.asObservable();
  loadingNewPortion$: Observable<boolean> = this.setPortionLoading$.asObservable();

  private setAssOrgLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  assOrgloading$: Observable<boolean> = this.setAssOrgLoading$.asObservable();
  allowChildReuse = false;

  canUpdateSettlement: ProcessorType[] = [
    ProcessorType.Nets,
    ProcessorType.TietoEvry,
    ProcessorType.SwedbankPay,
    ...values(CartesBancairesProcessorType),
    ProcessorType.ElavonIsoUk,
    ProcessorType.Barclays,
  ];
  private setFilter$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  filter$: Observable<boolean> = this.setFilter$.asObservable();

  private hierarchyViewLoadingSubject = new BehaviorSubject(false);

  constructor(
    private customDataService: CustomOrganisationDataService,
    private urlQueryParamsBuilderService: UrlQueryParamsBuilderService,
    private countryService: CountryService,
    private vuiHttpService: VuiHttpService,
    private contactTypes: ContactTypes,
    private timeZoneService: TimeZoneService,
    private geometryHelper: GeometryHelper,
    private authService: AuthenticationService,
    private blobService: BlobService,
    private http: HttpClient,
  ) {
    this.hierarchyViewLoading$ = this.hierarchyViewLoadingSubject.asObservable();
  }

  getAllPaymentContractsCount(): Observable<number> {
    return this.vuiHttpService.get<number>(
      `${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT}/count?status=ACTIVE`,
    );
  }

  getAllPaymentContracts(): Observable<IPaymentContract[]> {
    return this.vuiHttpService.get<IPaymentContract[]>(
      `${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT}?populateEntity=true&status=ACTIVE`,
    );
  }

  setLoading(state: boolean): void {
    this.setLoading$.next(state);
  }

  setAssOrgLoading(state: boolean): void {
    this.setAssOrgLoading$.next(state);
  }

  // TODO: This method Returns all entities in a Lightweight way. In API we have /uids endpoint for this.
  //  Please replace this method with getAllEntityUids wherever it's used or rename and change it
  getDescendantIds(orgIds: string[]): Observable<string[] | unknown[]> {
    if (isEmpty(orgIds)) return of([]);

    return forkJoin(
      orgIds.map((id: string) =>
        this.vuiHttpService
          // returns ancestorId and all descendants
          .get<{ entityUid: string }[]>(`${CONSTANTS.ENTITY_SERVICE.ORGANISATION_LIGHTWEIGHT}`, {
            params: new HttpParams({
              fromObject: {
                ancestorId: id,
                limit: '10000',
              },
            }),
          })
          .pipe(
            map((response) => {
              if (!response) return null;
              return response.map((item) => item.entityUid).join(',');
            }),
          ),
      ),
    );
  }

  getAllLightWeight(limit?: number): Observable<IEntityLightWeight[]> {
    return this.vuiHttpService.get<IEntityLightWeight[]>(
      `${CONSTANTS.ENTITY_SERVICE.ORGANISATION_LIGHTWEIGHT}`,
      {
        params: new HttpParams({
          fromObject: {
            limit: String(limit ?? MAX_API_ENTITY_COUNT),
          },
        }),
      },
    );
  }

  getAllLightWeightWithCount(
    start: string,
    noLoader: boolean,
    parentId: string,
    entityType?: string,
  ): Observable<any> {
    if (!noLoader) {
      this.setLoading(true);
    } else {
      this.setPortionLoading$.next(true);
    }

    return this.vuiHttpService
      .get<IEntityLightWeight[]>(`${CONSTANTS.ENTITY_SERVICE.ORGANISATION_LIGHTWEIGHT}`, {
        params: new HttpParams({
          fromObject: {
            limit: '20',
            start,
            parentId,
            ...(entityType && { entityType }),
          },
        }),
      })
      .pipe(
        switchMap((value) =>
          forkJoin(value.map((val) => this.getTotalCount({ parentId: val['entityUid'] }))).pipe(
            map((val) =>
              val.map((vall, index) => ({
                count: vall.count,
                ...value[index],
                hasChildren: vall.count > 0,
              })),
            ),
          ),
        ),
        finalize(() => (!noLoader ? this.setLoading(false) : this.setPortionLoading$.next(false))),
      );
  }

  setAllowChildReuse(state: boolean): void {
    this.allowChildReuse = state;
  }

  getEntity(
    start: number,
    isPortionLoader: boolean,
    parentId: string,
    params?: Record<string, any>,
  ): Observable<any> {
    if (isPortionLoader) {
      this.setPortionLoading$.next(true);
    } else {
      this.setLoading(true);
    }
    return this.vuiHttpService
      .get<IEntity>(`${CONSTANTS.ENTITY_SERVICE.ORGANISATION}/${parentId}`)
      .pipe(
        switchMap((org) =>
          this.getTotalCount({ parentId: org.entityUid, ...params }).pipe(
            map((val) => ({
              ...org,
              count: val.count,
              hasChildren: val.count > 0,
            })),
          ),
        ),
        map((entity) => [entity]),
        finalize(() =>
          isPortionLoader ? this.setPortionLoading$.next(false) : this.setLoading(false),
        ),
      );
  }

  getHierarchyView(
    parentId: string,
    start?: number,
    params?: Record<string, any>,
    limit = '20',
  ): Observable<IEntityHierarchy[]> {
    this.setHierarchyViewLoading(true);
    return this.vuiHttpService
      .get<IEntityHierarchy[]>(`${CONSTANTS.ENTITY_SERVICE.ORGANISATION}/lightweight`, {
        params: new HttpParams({
          fromObject: {
            ...params,
            parentId,
            ...(params?.limit ? {} : { limit: String(limit) }),
            ...(start && { start }),
          },
        }),
      })
      .pipe(
        switchMap((entities) =>
          forkJoin(
            entities.map((val) => this.getTotalCount({ ...params, parentId: val['entityUid'] })),
          ).pipe(
            map((val) =>
              val.map((vall, index) => ({
                count: vall.count,
                ...entities[index],
                hasChildren: vall.count > 0,
                parentEntityUid: parentId,
              })),
            ),
          ),
        ),
        finalize(() => {
          this.setHierarchyViewLoading(false);
        }),
      );
  }

  getAllDescendantEntitiesByEntityIds(entityIds: string[]): Observable<string[]> {
    return forkJoin(
      entityIds.map((id: string) => {
        return this.vuiHttpService
          .get<string[]>(`${ES_CONSTANTS.ENTITY_SERVICE.ORGANISATION}uids`, {
            params: new HttpParams({
              fromObject: {
                parentId: id,
                limit: MAX_API_ENTITY_COUNT,
              },
            }),
          })
          .pipe(
            map((entities: string[]) => {
              if (!entities) return null;
              return entities.join(',');
            }),
          );
      }),
    );
  }

  getAllEntityUids(body: Record<string, any>): Observable<string[]> {
    const params = this.urlQueryParamsBuilderService.createHttpParams(body);
    return this.vuiHttpService.get<string[]>(`${ES_CONSTANTS.ENTITY_SERVICE.ORGANISATION}uids`, {
      params,
    });
  }

  getAncestors(
    entityUid: IEntity['entityUid'],
    params?: Record<string, any>,
  ): Observable<IEntityAncestors> {
    return this.vuiHttpService.get<IEntityAncestors>(
      `${ES_CONSTANTS.ENTITY_SERVICE.ORGANISATION}${entityUid}/ancestors`,
      { params: new HttpParams({ fromObject: params }) },
    );
  }

  getMerchantCompany(entity: IEntityAncestors): IEntityAncestors {
    if (!entity) {
      return;
    }

    if (entity.entityType === EntityType.MERCHANT_COMPANY) {
      return entity;
    } else {
      return this.getMerchantCompany(entity.parent);
    }
  }

  add(org: IOrganisation): Observable<IOrganisation> {
    this.setLoadingAdditionalInfo.next(true);
    this.setLoading(true);
    return this.customDataService.add(org).pipe(
      flatMap((addedOrg: IOrganisation) => {
        const addAddressesRequests = org.addresses
          ? this.addAddresses(org.addresses, addedOrg.entityUid)
          : of([]);

        return forkJoin([
          of(addedOrg),
          forkJoin(addAddressesRequests).pipe(defaultIfEmpty([])),
          org.contacts ? this.addContacts(org.contacts, addedOrg.entityUid) : of(null),
        ]);
      }),
      flatMap(([organisation, addresses, contacts]) => {
        return forkJoin([
          of(organisation),
          of(addresses),
          of(contacts),
          ...((org.bankAccounts
            ? this.addAccounts(org.bankAccounts, organisation)
            : [of(null)]) as Observable<any>[]),
        ]);
      }),
      map(([organisation, addresses, contacts]) => {
        organisation.addresses = addresses;
        organisation.contacts = contacts;
        return organisation;
      }),
      finalize(() => {
        this.setLoadingAdditionalInfo.next(false);
        this.setLoading(false);
      }),
    );
  }

  getReceiptProvider(receiptProviderType: string): Observable<IReceiptProvider> {
    return this.customDataService.getReceiptProvider(receiptProviderType);
  }

  createReceiptProvider(receiptProvider: IReceiptProvider): Observable<IReceiptProvider> {
    return this.customDataService.createReceiptProvider(receiptProvider);
  }

  createReceiptContract(
    id: string,
    params: IReceiptContractCreatePayload,
  ): Observable<IReceiptContract> {
    return this.serializeReceiptContract(params).pipe(
      switchMap((newParams) => this.customDataService.createReceiptContract(id, newParams)),
    );
  }

  getReceiptContracts(entityUids: string[]): Observable<IReceiptContract[]> {
    return this.customDataService.getReceiptContracts(entityUids).pipe(
      switchMap((receiptContractDataArray) => {
        return receiptContractDataArray?.length
          ? forkJoin(
              receiptContractDataArray.map((receiptContractData) =>
                this.parseReceiptContract(receiptContractData),
              ),
            )
          : of([]);
      }),
    );
  }

  checkHasInvoice4U(entityUid: string): void {
    this.getReceiptContracts([entityUid]).subscribe((receipt) => {
      this.hasInvoice4U = receipt && receipt[0]?.provider === ReceiptProvider.Invoice4U;
    });
  }

  private parseReceiptContract(
    receiptContractData: IReceiptContractGetData,
  ): Observable<IReceiptContract> {
    const logoString = receiptContractData.templateConfiguration?.receiptMerchantLogo;
    return logoString
      ? this.blobService.fromBase64(logoString).pipe(
          map((imageBlob) => ({
            ...receiptContractData,
            templateConfiguration: {
              ...receiptContractData.templateConfiguration,
              receiptMerchantLogo: new File([imageBlob], 'current-logo.png'),
            },
          })),
        )
      : of(receiptContractData as unknown as IReceiptContract);
  }

  updateReceiptContract(
    receiptContractId: string,
    params: IReceiptContractCreatePayload,
  ): Observable<IReceiptContract> {
    return this.serializeReceiptContract(params).pipe(
      switchMap((newParams) =>
        this.customDataService.updateReceiptContract(receiptContractId, newParams),
      ),
    );
  }

  private serializeReceiptContract(
    params: IReceiptContractCreatePayload,
  ): Observable<IReceiptContractCreatePayloadData> {
    const logoImage = params.templateConfiguration?.receiptMerchantLogo;
    return logoImage
      ? this.blobService.toBase64(logoImage).pipe(
          map((base64Image) => ({
            ...params,
            templateConfiguration: {
              ...params.templateConfiguration,
              receiptMerchantLogo: base64Image,
            },
          })),
        )
      : of(params as unknown as IReceiptContractCreatePayloadData);
  }

  deleteReceiptContract(receiptContractId: string): any {
    return this.customDataService.deleteReceiptContract(receiptContractId);
  }

  getReceiptTemplatesNames(): Observable<String[]> {
    return this.customDataService
      .getReceiptTemplatesNames()
      .pipe(
        map((templatesNames) =>
          templatesNames.map(
            (templateName) => (templateName = templateName.replace(/\.[^/.]+$/, '')),
          ),
        ),
      );
  }

  getByKeyWithAdditionalInfo(id: string): Observable<IOrganisation> {
    this.setLoading(true);
    return this.customDataService.getByKeyWithQuery(id, { populateParentEntity: 'true' }).pipe(
      switchMap((org: IOrganisation) => {
        if (org.status === EntityStatus.Deleted) {
          return throwError(new HttpErrorResponse({ status: HttpError.CodeError404 }));
        }
        return forkJoin([
          this.getAddresses(org.entityUid),
          this.getContacts(org.entityUid),
          this.getReceiptContract(org.entityUid),
        ]).pipe(
          map(
            ([addresses, contacts, receiptContract]: [
              IAddress[],
              IContact[],
              IReceiptContract,
            ]) => [org, addresses, contacts, receiptContract],
          ),
        );
      }),
      map(
        ([org, addresses, contacts, receiptContract]: [
          IOrganisation,
          IAddress[],
          IContact[],
          IReceiptContract,
        ]) => {
          org.addresses = addresses;
          org.contacts = contacts;
          org = this.formatOrg(org);
          org.businessIdentifiers = org.businessIdentifiers || [];
          org.receiptContract = receiptContract;
          return org;
        },
      ),
      finalize(() => this.setLoading(false)),
    );
  }

  getReceiptContract(id): Observable<IReceiptContract> {
    return this.getReceiptContracts([id]).pipe(map((recContracts) => recContracts[0]));
  }

  getByKey(id: string, noLoader = false): Observable<IOrganisation> {
    if (!noLoader) {
      this.setLoading(true);
    }

    return this.customDataService.getByKeyWithQuery(id, { populateParentEntity: 'true' }).pipe(
      map((org: IOrganisation) => {
        org = this.formatOrg(org);
        return org;
      }),
      finalize(() => !noLoader && this.setLoading(false)),
    );
  }

  getEntityByID(id: string, params?: Record<string, any>): Observable<IEntity> {
    return this.customDataService.getByKeyWithQuery(id, { ...params });
  }

  getEntityByDomainName(domainName: string): Observable<IOrganisation[]> {
    return this.customDataService.getWithQuery({
      domainName: domainName,
    });
  }

  getByKeyWithAncestors(
    id: string,
    noLoader = false,
    params?: Record<string, any>,
  ): Observable<IOrganisation> {
    if (!noLoader) {
      this.setLoading(true);
    }

    return this.customDataService.getByKeyWithQuery(id, { populateParentEntity: 'false' }).pipe(
      switchMap((organization) => this._getAncestors([organization.entityUid], params)),
      map((value) => (value ? value[0] : null)),
      finalize(() => !noLoader && this.setLoading(false)),
    );
  }

  getByKeyRaw(id: string): Observable<IEntity> {
    return this.customDataService.getById(id);
  }

  getAll(): Observable<IOrganisation[]> {
    this.setLoading(true);
    return this.customDataService.getAll().pipe(
      map((orgs) => orgs.map((org) => this.formatOrg(org))),
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  getWithQuery(params: string | QueryParams): Observable<IOrganisation[]> {
    this.setLoading(true);
    return this.customDataService.getWithQuery(params).pipe(
      map((orgs) => orgs.map((org) => this.formatOrg(org))),
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  getLightWeightWithQuery(
    params: string | QueryParams,
    noLoader: boolean,
    start: number,
    limit = 20,
  ): Observable<IOrganisation[]> {
    if (!noLoader) {
      this.setLoading(true);
    } else {
      this.setPortionLoading$.next(true);
    }

    return this.vuiHttpService
      .get<IEntityLightWeight[]>(`${CONSTANTS.ENTITY_SERVICE.ORGANISATION_LIGHTWEIGHT}`, {
        params: new HttpParams({
          fromObject: {
            ...(typeof params === 'string' ? { name: params } : params),
            ...{ limit: limit, start },
          },
        }),
      })
      .pipe(
        switchMap((organizations) =>
          organizations.length
            ? this._getAncestors(organizations.map((value) => value.entityUid))
            : of(organizations),
        ),
        finalize(() => (!noLoader ? this.setLoading(false) : this.setPortionLoading$.next(false))),
      );
  }

  getLightWeightWithQueryRaw(
    params: string | QueryParams,
    start: number = 0,
    limit: number = 20,
  ): Observable<IEntityLightWeight[]> {
    return this.http.get<IEntityLightWeight[]>(
      `${CONSTANTS.ENTITY_SERVICE.ORGANISATION_LIGHTWEIGHT}`,
      {
        params: new HttpParams({
          fromObject: {
            ...(typeof params === 'string' ? { name: params } : params),
            ...{ start: String(start), limit: String(limit) },
          },
        }),
      },
    );
  }

  private _getAncestors(ids: string[], params?: Record<string, any>): Observable<any[]> {
    return forkJoin(
      ids.map((id) =>
        this.getAncestors(id, params).pipe(
          map((val) => ({
            ...val,
            path: this._transform(val).slice(0, -4),
          })),
          catchError((err) => {
            const code = ErrorService.getStatusCode(err);
            if (code === HttpError.CodeError404) return of(null);
            return throwError(err);
          }),
        ),
      ),
    ).pipe(map((results) => results.filter(Boolean)));
  }

  private _transform(entity: IEntityAncestors, path = ''): string {
    if (entity.parent && entity.entityUid !== this.authService.entityUid) {
      return this._transform(entity.parent, `${path && entity.name} ${path && '/ ' + path}`);
    } else {
      return `${entity.name} / ${path}`;
    }
  }

  patch(org: Partial<IOrganisation>, id: string): Observable<IOrganisation> {
    return this.customDataService.patch({
      id,
      changes: org,
    });
  }

  getTotalCount(params?: QueryParams): Observable<ICount> {
    return this.customDataService.getTotalCount(params);
  }

  organizationGroupsWithCount(params: IQueryParams): Observable<IResultsWithCount<IOrganisation>> {
    const query = QueryParamsService.toQueryParams(params);
    const filterParams = QueryParamsService.getFilterParams(params.searchCriteria);
    assign(query, filterParams);
    const orgObservable: Observable<IOrganisation[]> = this.getWithQuery(
      omit(query, ['searchCriteria']),
    );
    const orgCountObservable: Observable<ICount> = this.getTotalCount(
      omit(query, ['searchCriteria']),
    );
    this.setLoading(true);

    return forkJoin([orgObservable, orgCountObservable]).pipe(
      map((results) => {
        return {
          results: results[0],
          count: results[1].count,
        };
      }),
    );
  }

  getResultsWithCount(params: IQueryParams): Observable<IResultsWithCount<IOrganisation>> {
    const entityUidSearchTerm = params.searchCriteria?.get(SearchSelector.EntityId);
    const query = QueryParamsService.toQueryParams(params);
    const filterParams = QueryParamsService.getFilterParams(params.searchCriteria);
    assign(query, filterParams);
    const orgObservable: Observable<IOrganisation[]> = entityUidSearchTerm
      ? this.getByKey(entityUidSearchTerm.argument).pipe(
          map((organisation) => [organisation]),
          catchError((err) => {
            if (err.error.status === HttpError.CodeError404) return of([]);
            else throw err;
          }),
        )
      : this.getWithQuery(omit(query, ['searchCriteria']));
    const orgCountObservable: Observable<ICount> = entityUidSearchTerm
      ? of(null)
      : this.getTotalCount(omit(query, ['searchCriteria']));
    this.setLoading(true);

    return forkJoin([orgObservable, orgCountObservable]).pipe(
      map((results) => {
        return {
          results: results[0],
          count: entityUidSearchTerm ? results[0].length : results[1].count,
        };
      }),
    );
  }

  getAssociatedOrganisations(params: IQueryParams): Observable<IResultsWithCount<IOrganisation>> {
    const query = QueryParamsService.toQueryParams(params);
    const orgObservable: Observable<IOrganisation[]> = this.customDataService
      .getWithQuery(query)
      .pipe(
        map((orgs) => orgs.map((org) => this.formatOrg(org))),
        finalize(() => {
          this.setAssOrgLoading(false);
        }),
      );

    const orgCountObservable: Observable<ICount> = this.getTotalCount(pick(query, ['parentId']));
    this.setAssOrgLoading(true);

    return forkJoin([orgObservable, orgCountObservable]).pipe(
      map((results) => {
        return {
          results: results[0],
          count: results[1].count,
        };
      }),
    );
  }

  update(org: IOrganisation): Observable<IOrganisation> {
    this.setLoadingAdditionalInfo.next(true);
    return this.vuiHttpService
      .patch(`${CONSTANTS.ENTITY_SERVICE.ORGANISATION}${org.entityUid}`, org)
      .pipe(finalize(() => this.setLoadingAdditionalInfo.next(false)));
  }

  addPaymentContract(
    contract: IPaymentContract,
    id: string,
  ): Observable<[IPaymentContract, [IBankAccount, IFee[]]]> {
    return this.customDataService
      .addPaymentContract(contract, id)
      .pipe(
        flatMap((newContract: IPaymentContract) => this.additionalCalls(contract, newContract)),
      );
  }

  additionalCalls(
    contract: IPaymentContract,
    newContract: IPaymentContract,
  ): Observable<[IPaymentContract, [IBankAccount, IFee[]]]> {
    return forkJoin([
      iif(
        () => this.canUpdateSettlement.includes(contract.processor.type),
        this.updateSettlement(contract.settlement, newContract.contractUid),
        of(newContract),
      ),
      iif(
        () =>
          (contract.processor.type === ProcessorType.Paypal &&
            !contract.salesChannels.includes(SalesChannel.Ecommerce)) ||
          (contract.processor.type === ProcessorType.Paypal &&
            contract.salesChannels.includes(SalesChannel.Pos)) ||
          (contract.salesChannels.includes(SalesChannel.Ecommerce) &&
            contract.paymentType.length === 1 &&
            contract.paymentType[0] === PaymentType.PayPalEcomManaged) ||
          contract.processor.type === ProcessorType.Crypto ||
          contract.processor.type === ProcessorType.AliPay ||
          contract.processor.type === ProcessorType.WeChat ||
          contract.processor.type === ProcessorType.KlarnaEcom ||
          contract.processor.type === ProcessorType.KlarnaQr ||
          contract.processor.type === ProcessorType.Affirm ||
          contract.processor.type === ProcessorType.OpOnlinePayment ||
          contract.processor.type === ProcessorType.Blik ||
          contract.processor.type === ProcessorType.VerifoneEu,
        forkJoin([
          iif(
            () => Boolean(contract.bankAccount),
            this.addClearingToCombineAccountUid(
              contract.bankAccount?.accountUid,
              contract.settlement?.clearing,
              newContract,
            ),
            of(null),
          ),
          iif(
            () => Boolean(contract.pricelistUid),
            this.customDataService.createRelationshipBetweenContractAndPriceList(
              newContract.contractUid,
              { pricelistUid: contract.pricelistUid },
            ),
            of(null),
          ),
        ]),
        of(null),
      ),
    ]);
  }

  getPaymentContracts(id: string, name = '', limit = ''): Observable<IPaymentContract[]> {
    this.setLoading(true);
    return this.customDataService.getPaymentContracts(id, name, limit).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  addUser(user: IUser, id: string): Observable<IUser> {
    return this.customDataService.addUser(user, id);
  }

  addPointInteraction(poi: IPointInteraction, id: string): Observable<IPointInteraction> {
    return this.customDataService.addPointInteraction(poi, id);
  }

  addPriceList(priceList: IPriceLists, id: string): Observable<IPriceLists> {
    return this.customDataService.addPriceList(priceList, id);
  }

  getBusinessInformation(id: string): Observable<IBusinessInformation> {
    return this.customDataService.getBusinessInformation(id);
  }

  updateBusinessInformation(
    information: IBusinessInformation,
    id: string,
  ): Observable<IBusinessInformation> {
    return this.customDataService.updateBusinessInformation(information, id);
  }

  formatAddress(address: IAddress): IAddress {
    const timezone = address?.timezone || address?.timezoneId;

    if (timezone) {
      // Because of timezone field is deprecated we need to have both fields: timezone and timezoneId
      address.timezoneId = address.timezone = timezone;
    }
    return address;
  }

  addAddresses(addresses: IAddress[], id: string): Observable<IAddress>[] {
    return addresses.map((address) =>
      this.customDataService.addAddress(this.formatAddress(address), id),
    );
  }

  getAddresses(entityUid: string): Observable<IAddress[]> {
    return this.customDataService.getAddresses(entityUid).pipe(
      map((addresses) =>
        addresses.map((address) => {
          const { offset, name } = {
            ...this.timeZoneService.mapZones.get(address.timezoneId ?? address.timezone),
          };

          if (offset && name) {
            address.formattedTimezone = `${offset} - ${name}`;
          }
          return this.formatAddress(address);
        }),
      ),
    );
  }

  deleteAddress(addressUid: string): Observable<IAddress> {
    return this.customDataService.deleteAddress(addressUid);
  }

  deleteSingleContact(contactUid: string): Observable<IContact> {
    return this.customDataService.deleteContact(contactUid);
  }

  enableAddress(addressUid: string): Observable<IAddress> {
    return this.customDataService.enableAddress(addressUid);
  }

  /**
   * Update or create address for contacts
   * Create option is necessary for the case when contact exists without an address
   * @param {IContact[]} contacts
   * @returns {Observable<IAddress[]>}
   */
  updateContactsAddresses(contacts: IContact[] = []): Observable<IAddress[]> {
    const requests = contacts.map((contact) => {
      const address = contact.addresses[0];
      const formattedAddress = this.formatAddress(address);

      if (address.addressUid) {
        return this.customDataService.updateAddress(formattedAddress);
      } else {
        return this.customDataService.addContactAddress(formattedAddress, contact.contactUid);
      }
    });

    return forkJoin(requests).pipe(defaultIfEmpty([]));
  }

  getContactAddresses(contacts: IContact[]): Observable<IAddress[]> {
    const requests = contacts.map((contact) =>
      this.customDataService.getContactAddresses(contact.contactUid),
    );
    return forkJoin(requests).pipe(
      map((results) => results.map(([address]) => this.formatAddress(address))),
      defaultIfEmpty([]),
    );
  }

  updateContacts(contacts: IContact[] = []): Observable<IContact[]> {
    const updateContactRequests = contacts.map((contact) =>
      this.customDataService.updateContact(contact),
    );

    return forkJoin(updateContactRequests).pipe(defaultIfEmpty([]));
  }

  addContacts(contacts: IContact[] = [], entityUid: string): Observable<IContact[]> {
    const addContactRequests = contacts.map((contact) =>
      this.customDataService.addContact(contact, entityUid).pipe(
        switchMap((addedContact: IContact) => {
          return this.customDataService
            .addContactAddress(contact.addresses[0], addedContact.contactUid)
            .pipe(
              map((contactAddress) => {
                addedContact.addresses = [this.formatAddress(contactAddress)];

                return addedContact;
              }),
            );
        }),
      ),
    );
    return forkJoin(addContactRequests).pipe(defaultIfEmpty([]));
  }

  getContacts(id: string): Observable<IContact[]> {
    return this.customDataService.getContact(id).pipe(
      switchMap((contacts: IContact[]) => {
        return forkJoin([of(contacts), this.getContactAddresses(contacts)]);
      }),
      map(([contacts, contactAddresses]: [IContact[], IAddress[]]) => {
        if (contacts && contactAddresses) {
          contacts.forEach((contact, index) => {
            if (contactAddresses[index]) {
              contact.addresses = [this.formatAddress(contactAddresses[index])];
            } else {
              contact.addresses = [];
            }
          });
          return contacts;
        } else {
          return [];
        }
      }),
    );
  }

  compareAddresses(address1: IAddress, address2: IAddress): boolean {
    const invalidFields = [
      'createdDate',
      'formattedTimezone',
      'timezone',
      'modifiedDate',
      'version',
      'status',
      'addressType',
      'addressUid',
      'countryName',
      'geometry',
      'timezoneId',
    ];

    // For geometry we need to implement separate method because this is special case
    const getGeometry = (geometry: IGeometry): IGeometry => {
      if (
        this.geometryHelper.isCoordinateEmpty(geometry?.coordinates[0]) &&
        this.geometryHelper.isCoordinateEmpty(geometry?.coordinates[1])
      ) {
        return undefined;
      }

      return geometry;
    };

    if (getGeometry(address1?.geometry) && getGeometry(address2?.geometry)) {
      return (
        isEqual(
          omitBy(address2, (value, prop) => invalidFields.includes(prop) || !value),
          omitBy(address1, (value, prop) => invalidFields.includes(prop) || !value),
        ) && isEqual(getGeometry(address1?.geometry), getGeometry(address2?.geometry))
      );
    } else {
      return isEqual(
        omitBy(address2, (value, prop) => invalidFields.includes(prop) || !value),
        omitBy(address1, (value, prop) => invalidFields.includes(prop) || !value),
      );
    }
  }

  compareContacts(contact1: IContact, contact2: IContact): boolean {
    const invalidFields = [
      'createdDate',
      'contactTypeFormatted',
      'version',
      'status',
      'modifiedDate',
      'addresses',
      'phoneNumbers',
    ];

    const isPhoneNumbersEqual = (): boolean => {
      return (
        contact1.phoneNumbers.length === contact2.phoneNumbers.length &&
        contact1.phoneNumbers.every((number, index) =>
          isEqual(omit(number, ['isPrimary']), omit(contact2.phoneNumbers[index], ['isPrimary'])),
        )
      );
    };

    return (
      isEqual(
        omitBy(contact2, (value, prop) => invalidFields.includes(prop) || !value),
        omitBy(contact1, (value, prop) => invalidFields.includes(prop) || !value),
      ) && isPhoneNumbersEqual()
    );
  }

  updateAddresses(addresses: IAddress[]): Observable<IAddress[]> {
    const updateAddressRequests = addresses.reduce((requests, address) => {
      if (address.addressUid) {
        requests.push(this.customDataService.updateAddress(this.formatAddress(address)));
      }
      return requests;
    }, []);

    return forkJoin(updateAddressRequests).pipe(defaultIfEmpty([]));
  }

  updateCrossEntityAccessForDescendants(
    id: string,
    poiCrossEntityAccessAllowed: boolean,
  ): Observable<IOrganisation[]> {
    const descendants = this.getLightWeightWithQuery(
      {
        parentId: id,
        entityType: EntityType.MERCHANT_SITE,
      },
      false,
      0,
    );
    return descendants.pipe(
      mergeAll(),
      mergeMap((organisation) => this.update({ ...organisation, poiCrossEntityAccessAllowed })),
      toArray(),
    );
  }

  enableContact(contactUid: string): Observable<IContact> {
    return this.customDataService.enableContact(contactUid);
  }

  delete(org: IOrganisation, reason: string): Observable<any> {
    return this.http.delete(`${CONSTANTS.ENTITY_SERVICE.ORGANISATION}${org.entityUid}`, {
      params: {
        reason,
      },
    });
  }

  hasChildren(org: IOrganisation): Observable<boolean> {
    return this.http
      .get<number>(`${CONSTANTS.ENTITY_SERVICE.ORGANISATION}count`, {
        params: {
          parentId: org.entityUid,
          status: [EntityStatus.Active, EntityStatus.Inactive],
        },
      })
      .pipe(map((count) => !!count));
  }

  addClearingToCombineAccountUid(
    bankAccountUid: string,
    clearing: IClearing,
    contract: IPaymentContract,
  ): Observable<IBankAccount> {
    const info = (accountUid): { clearing: IClearing } => {
      return {
        clearing: {
          accountUid,
          bankingPartner: clearing?.bankingPartner,
          delay: clearing?.delay,
        },
      };
    };

    return iif(
      () => Boolean(clearing),
      this.updateSettlement(info(bankAccountUid), contract.contractUid),
      of(null),
    );
  }

  addAccount(
    bankAccount: IBankAccount,
    contract: IPaymentContract | IOrganisation,
  ): Observable<IBankAccount> {
    return iif(
      () => bankAccount.accountType === BankAccountType.Domestic,
      this.customDataService.addDomesticAccount(
        this.removedBankAccountFields(bankAccount),
        contract.entityUid,
      ),
      iif(
        () => bankAccount.accountType === BankAccountType.Sepa,
        this.customDataService.addSepaAccount(
          this.removedBankAccountFields(bankAccount),
          contract.entityUid,
        ),
        this.customDataService.addExternalAccount(
          this.removedBankAccountFields(bankAccount),
          contract.entityUid,
        ),
      ),
    );
  }

  addAccounts(
    bankAccounts: IBankAccount[],
    contract: IPaymentContract | IOrganisation,
  ): IBankAccount[] {
    const bankAccountsArray = [];
    bankAccounts.forEach((bankAccount) => {
      bankAccountsArray.push(this.addAccount(bankAccount, contract));
    });
    return bankAccountsArray;
  }

  deleteAccount(id: string): Observable<void> {
    return this.customDataService.deleteAccount(id);
  }

  updateSettlement(information: ISettlementInformation, id: string): Observable<IPaymentContract> {
    return this.customDataService.updateSettlementInformation(information, id).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  deleteClearing(information: ISettlementInformation, id: string): Observable<IPaymentContract> {
    const settlement: ISettlementInformation = {
      ...information,
      clearing: null, // to remove field data we need to set it to null
    };
    return this.customDataService.deleteClearing(settlement, id).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  upsertFee(fees: IFee[], id: string): Observable<IFee[]> {
    return this.customDataService.upsertFee(fees, id);
  }

  deleteFee(entityUid: string): Observable<IFee[]> {
    return this.customDataService.deleteFee(entityUid);
  }

  getAccount(accountUid: string): Observable<IBankAccount> {
    return this.customDataService.getAccount(accountUid);
  }

  updateAccount(contract: IPaymentContract): Observable<IBankAccount> {
    return iif(
      () => contract.bankAccount.accountType === BankAccountType.Domestic,
      this.customDataService.updateAccountDomestic(contract.bankAccount),
      this.customDataService.updateAccountSepa(contract.bankAccount),
    );
  }

  updateAccountWithBankAccount(bankAccount: IBankAccount): Observable<IBankAccount> {
    this.removedBankAccountFields(bankAccount);
    return iif(
      () => bankAccount.accountType === BankAccountType.Domestic,
      this.customDataService.updateAccountDomestic(this.removedBankAccountFields(bankAccount)),
      iif(
        () => bankAccount.accountType === BankAccountType.Sepa,
        this.customDataService.updateAccountSepa(this.removedBankAccountFields(bankAccount)),
        this.customDataService.updateAccountExternal(this.removedBankAccountFields(bankAccount)),
      ),
    );
  }

  getAccounts(contract: IPaymentContract | IOrganisation): Observable<IBankAccount[]> {
    return this.customDataService.getAccounts(contract.entityUid);
  }

  updateAccountDomestic(account: IBankAccount): Observable<IBankAccount> {
    return this.customDataService.updateAccountDomestic(account);
  }

  updateAccountSepa(account: IBankAccount): Observable<IBankAccount> {
    return this.customDataService.updateAccountSepa(account);
  }

  enableOrganisation(orgUid: string): Observable<IOrganisation> {
    return this.customDataService.enableDisableOrganisation(orgUid, EntityStatus.Active);
  }

  disableOrganisation(orgUid: string): Observable<IOrganisation> {
    return this.customDataService.enableDisableOrganisation(orgUid, EntityStatus.Inactive);
  }

  setFilter(filter): void {
    this.setFilter$.next(filter);
  }

  formatOrg(org: IOrganisation): IOrganisation {
    let countryCode = get(org, 'locale.countryCode');
    const countryCodeBilling = get(org, 'addresses[0].country');
    const countryCodeShipping = get(org, 'addresses[1].country');

    if (org.locale && this.timeZoneService.mapZones.has(org.locale.timezoneId)) {
      const { offset, name } = this.timeZoneService.mapZones.get(org.locale.timezoneId);
      org.formattedTimezone = `${offset} - ${name}`;
    }

    if (countryCode) {
      const country: ICountry = this.countryService.countriesAlpha3.get(countryCode);
      org.country = country ? country.name : countryCode;
    }
    if (countryCodeBilling) {
      const country: ICountry = this.countryService.countriesAlpha3.get(countryCodeBilling);
      org.addresses[0].countryName = country ? country.name : countryCodeBilling;
    }
    if (countryCodeShipping) {
      const country: ICountry = this.countryService.countriesAlpha3.get(countryCodeShipping);
      org.addresses[1].countryName = country ? country.name : countryCodeShipping;
    }
    org.parentEntityUid = get(org, 'parentEntity.entityUid', org.parentEntityUid);

    if (org.contacts) {
      org.contacts.forEach((contact) => {
        contact.contactTypeFormatted =
          this.contactTypes.keyValue[contact.contactType] || contact.contactType;
        countryCode = get(contact, 'addresses[0].country', get(org, 'locale.countryCode'));
        if (contact.addresses && contact.addresses[0]) {
          contact.addresses[0].countryName = this.getCountryNameFromCountryCode(countryCode);
        }
      });
    }
    return org;
  }

  getCountryNameFromCountryCode(countryCode: string): string {
    const country: ICountry = this.countryService.countriesAlpha3.get(countryCode);

    return country ? country.name : countryCode;
  }

  createRelationshipBetweenContractAndPriceList(
    contractUid,
    pricelistUid,
  ): Observable<IPriceLists> {
    return this.customDataService.createRelationshipBetweenContractAndPriceList(
      contractUid,
      pricelistUid,
    );
  }

  deleteRelationshipBetweenContractAndPriceList(
    contractUid,
    pricelistUid,
  ): Observable<IPriceLists> {
    return this.customDataService.deleteRelationshipBetweenContractAndPriceList(
      contractUid,
      pricelistUid,
    );
  }

  getByAncestorIdLightWeight(
    ancestorId: string,
    limit = String(MAX_API_ENTITY_COUNT),
    params?: Record<string, any>,
  ): Observable<IEntityLightWeight[]> {
    return this.vuiHttpService.get<IEntityLightWeight[]>(
      `${CONSTANTS.ENTITY_SERVICE.ORGANISATION_LIGHTWEIGHT}`,
      {
        params: new HttpParams({
          fromObject: {
            ...params,
            ancestorId,
            limit,
          },
        }),
      },
    );
  }

  isPoiCrossEntityAccessEditable(entityType: EntityType): boolean {
    return entityType === EntityType.MERCHANT_COMPANY || entityType === EntityType.MERCHANT_SITE;
  }

  private setHierarchyViewLoading(isLoading: boolean): void {
    this.hierarchyViewLoadingSubject.next(isLoading);
  }

  private removedBankAccountFields(iBankAccount: IBankAccount): IBankAccount {
    if (
      iBankAccount.accountType === BankAccountType.Domestic ||
      iBankAccount.accountType === BankAccountType.Sepa
    ) {
      delete iBankAccount.nickname;
      delete iBankAccount.description;
      delete iBankAccount.externalAccountReference;
    } else if (iBankAccount.accountType === BankAccountType.External) {
      delete iBankAccount.accountNumber;
      delete iBankAccount.country;
      delete iBankAccount.iban;
      delete iBankAccount.routingTransitNumber;

      if (isEmpty(iBankAccount.description)) delete iBankAccount.description;
    }
    return iBankAccount;
  }

  createGroupEntityRelationships(
    paramEntityUids: Record<string, any>,
    entityGroupUid: string,
  ): Observable<any> {
    return this.customDataService.createGroupEntityRelationships(paramEntityUids, entityGroupUid);
  }

  getGroupEntityRelationships(paramEntityUid: string): Observable<IOrganisation[]> {
    return this.customDataService.getGroupEntityRelationships(paramEntityUid);
  }

  replaceGroupEntityRelationships(
    paramEntityUids: Record<string, any>,
    entityGroupUid: string,
  ): Observable<any> {
    return this.customDataService.replaceGroupEntityRelationships(paramEntityUids, entityGroupUid);
  }

  getEntityIdForParentUpdate(
    id: string,
  ): Observable<{ rootEntityId: string; merchantCompanyEntityCount: number }> {
    return this.getAncestors(id).pipe(
      switchMap((entity: IEntityAncestors) => {
        let entityId: string;
        let currentEntity = entity;
        if (currentEntity.entityUid === this.authService.entityUid) {
          return of({ rootEntityId: '', merchantCompanyEntityCount: 0 });
        }
        while (currentEntity.parent) {
          currentEntity = currentEntity.parent;
          entityId = currentEntity.entityUid;
          if (
            currentEntity.entityType === EntityType.ESTATE_OWNER ||
            currentEntity.entityUid === this.authService.entityUid
          ) {
            break;
          }
        }
        return this.getTotalCount({
          ancestorId: entityId,
          entityType: EntityType.MERCHANT_COMPANY,
        }).pipe(
          map((count) => {
            return { rootEntityId: entityId, merchantCompanyEntityCount: count.count };
          }),
        );
      }),
    );
  }

  domainNameAsyncValidator(entityUid: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return iif(
        () => control.value,
        this.getEntityByDomainName(control.value).pipe(
          catchError(() => of([])),
          map((organisations) => {
            const organisation = organisations[0];
            return organisation && organisation.entityUid !== entityUid
              ? {
                  serialNumberInvalid: {
                    message: '@@DOMAIN_NAME_INVALID',
                    displayMessage: $localize`${organisation.name} organisation is using this domain name already. Please add a different name`,
                  },
                }
              : null;
          }),
        ),
        of(null),
      );
    };
  }
}
