import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISelectInput } from '../../..';
import { FILTER_CONTENT_ITEM } from '../../content/token';
import { BaseFilter } from '../base-filter';
import { IFilterToApply } from '@portal/shared/ui/filter/src/lib/search/interfaces/filter-to-apply.interface';
import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';

@Component({
  selector: 'portal-multiple-selection-filter',
  templateUrl: './multiple-selection-filter.component.html',
  styleUrls: ['./multiple-selection-filter.component.scss'],
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: MultipleSelectionFilterComponent,
    },
  ],
})
export class MultipleSelectionFilterComponent extends BaseFilter implements AfterContentInit {
  @Input() entitiesAdded$ = new BehaviorSubject<ISelectInput[]>(null);
  constructor() {
    super();
  }
  ngAfterContentInit(): void {
    if (this.entitiesAdded$) {
      this.entitiesAdded$.subscribe((entities) => {
        if (entities && entities[this.selector]) {
          this.mapRetainedEntities(entities[this.selector]);
        }
      });
    }
  }
  mapRetainedEntities(value): void {
    Array.from(value).forEach((val: IFilterToApply) => {
      if (val.argument === UserRoles.PetroSiteAdmin) {
        this.onEntityAdded({ id: val.argument, text: 'Merchant' });
      } else {
        this.onEntityAdded({ id: val.argument, text: val.argument });
      }
    });
    this.updateEntities();
  }
}
