import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbsModule } from '@portal/shared/ui/breadcrumbs';
import { SpinnerModule } from '@portal/shared/ui/spinner';
import { TableModule } from '@portal/shared/ui/table';
import { CardsTableTemplateComponent } from './cards-table-template.component';

@NgModule({
  imports: [CommonModule, BreadcrumbsModule, SpinnerModule, TableModule],
  declarations: [CardsTableTemplateComponent],
  exports: [CardsTableTemplateComponent],
})
export class CardsTableTemplateModule {}
