import {
  ITag,
  ITagModel,
  TagRejectMessages,
  TTagType,
} from '@portal/marketplace/api-interfaces/src';
import { IndexBaseComponent } from '../index-page/index-base-component';
import { TagsService } from '@portal/marketplace/tags';
import { NotifierService } from '@portal/shared/ui/notifier';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Directive } from '@angular/core';

@Directive()
export abstract class TagsManagementPageBase extends IndexBaseComponent {
  abstract tagsType: TTagType;
  tags$: Observable<ITag[]>;
  tagsCount$: Observable<number>;
  isLoading$: Observable<boolean>;
  tagsService: TagsService;
  notifierService: NotifierService;
  componentToShow: 'NewTag' | 'EditTag';

  selectedTag: ITagModel | ITag;

  constructor(tagsService: TagsService, notifierService: NotifierService) {
    super();
    this.tagsService = tagsService;
    this.notifierService = notifierService;
    this.tags$ = this.tagsService.selectAll();
    this.tagsCount$ = this.tagsService.itemsCount();
    this.isLoading$ = this.tagsService.getLoadingState();
  }

  ngOnInit(): void {
    this.LoadTags();
  }

  newTagAction(): void {
    this.sidePanel.close();
    this.selectedTag = {
      tagType: this.tagsType,
    };
    this.componentToShow = 'NewTag';

    switch (this.tagsType) {
      case 'MERCHANT_DEVICE':
        this.sidePanel.title = 'Add Terminal Tag';
        break;
      case 'ACQUIRER_BUNDLE':
        this.sidePanel.title = 'Add Bundle Tag';
        break;
      case 'ACQUIRER_APP':
        this.sidePanel.title = 'Add App Tag';
        break;
    }

    this.sidePanel.toggle();
  }

  editTagAction(tag: ITag): void {
    this.sidePanel.close();
    this.selectedTag = tag;
    this.componentToShow = 'EditTag';

    switch (this.tagsType) {
      case 'MERCHANT_DEVICE':
        this.sidePanel.title = 'Edit Terminal Tag';
        break;
      case 'ACQUIRER_BUNDLE':
        this.sidePanel.title = 'Edit Bundle Tag';
        break;
      case 'ACQUIRER_APP':
        this.sidePanel.title = 'Edit App Tag';
        break;
    }

    this.sidePanel.toggle();
  }

  removeTagAction(tag: ITag): void {
    this.RemoveTag(tag);
  }

  loadAction(data): void {
    this.filter = data.filter;
    this.searchAndFilters = data.searchAndFilters;
    this.LoadTags();
  }

  afterPaginate(): void {}
  afterSorting(): void {}

  CancelTag(): void {
    this.sidePanel.close();
  }

  CreateNewTag(tag: ITag): void {
    this.tagsService
      .createTag(tag)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.CancelTag();
          this.LoadTags();
        },
        error: (err) => {
          const msg =
            TagRejectMessages[err.error.errors[0].messageKey] || err.error.errors[0].message;
          if (msg) {
            this.notifierService.error(msg);
          }
        },
      });
  }

  EditTag(tag: ITag): void {
    this.tagsService
      .updateTag(tag)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.CancelTag();
        },
        error: (err) => {
          const msg =
            TagRejectMessages[err.error.errors[0].messageKey] || err.error.errors[0].message;
          if (msg) {
            this.notifierService.error(msg);
          }
        },
      });
  }

  RemoveTag(tag: ITag): void {
    this.tagsService
      .removeTag(tag)
      .pipe(take(1))
      .subscribe(() => {
        this.LoadTags();
        this.CancelTag();
      });
  }

  LoadTags(): void {
    this.tagsService.loadAllTagsByType(this.tagsType, this.filter, this.searchAndFilters);
  }
}
