<div class="is-gapless control selected-static-filters">
  <ng-container *ngFor="let key of transactionFiltersArray">
    <ng-container *ngIf="isFilterSelected(selectedStaticFilters, key)">
      <div
        [className]="
          isDisplayedVerticalAndHorizontal ? 'mr-2 mb-2 transaction-filters' : 'transaction-filters'
        "
      >
        <button
          class="button filter-button is-filter is-applied is-multiple-filters"
          [class.is-hidden]="false"
          [class.is-rounded]="key === 'transactionDate'"
          data-e2e="event-onMouse-counterFilter"
          (focusin)="onMouseOver($event, CONSTANTS.counterFilter)"
          (focusout)="onMouseLeave($event, CONSTANTS.counterFilter)"
          (keyup.enter)="goToSubmenu(getSelectedFilterSubMenu(selectedStaticFilters, key))"
          (keydown.space)="
            goToSubmenu(getSelectedFilterSubMenu(selectedStaticFilters, key), $event)
          "
          (click)="goToSubmenu(getSelectedFilterSubMenu(selectedStaticFilters, key))"
        >
          <ng-container *ngFor="let filter of selectedStaticFilters; let index = index">
            <div *ngIf="key === filter.key" class="filters-in-line">
              <div class="filter-text" *ngIf="filter.currentSelectedIndex < 1">
                <span>{{ currentFilterName(filter.key) }}:</span>
              </div>
              <ng-container *ngIf="isSelectedLabelTextExceedsMaxWidth(selectedStaticFilters)">
                <div
                  class="filter-text"
                  *ngIf="
                    filter.currentSelectedIndex < 2 &&
                    filter.countSelectedItems <=
                      countItemsSubMenuSelectedByKey(filter.filterSubMenu)
                  "
                >
                  <span>
                    {{
                      currentFilterNameItem(filter.text, filter.key, filter.id) | removeUnderscore
                    }}
                  </span>
                  <span *ngIf="filter.currentSelectedIndex === 0 && filter.countSelectedItems > 1"
                    >,</span
                  >
                </div>
              </ng-container>
              <div
                class="filter-text counter-label"
                *ngIf="
                  filter.currentSelectedIndex === filter.countSelectedItems - 1 &&
                  isDisplayingCountFilters(filter.countSelectedItems)
                "
              >
                <span
                  *ngIf="
                    filter.countSelectedItems <
                      countItemsSubMenuSelectedByKey(filter.filterSubMenu);
                    else showLabelAll
                  "
                  [className]="
                    isHoverCounterFilter ? 'has-background-white' : 'has-background-grey-light'
                  "
                >
                  +{{ filter.countSelectedItems - numberTextFiltersToDisplay }}
                </span>
                <ng-template #showLabelAll>
                  <span
                    class="counter-label"
                    [className]="
                      isHoverCounterFilter ? 'has-background-white' : 'has-background-grey-light'
                    "
                    i18n
                    >all</span
                  >
                </ng-template>
              </div>
            </div>
          </ng-container>
        </button>
        <div
          *ngIf="key !== 'transactionDate'"
          class="button remove-button"
          [class.is-filter]="isHoverIconFilter"
          (click)="removeAllStaticFilter(selectedStaticFilters, key)"
          (keydown.enter)="removeAllStaticFilter(selectedStaticFilters, key)"
          (keydown.space)="removeAllStaticFilter(selectedStaticFilters, key, $event)"
          data-e2e="button-remove-filter"
          tabindex="0"
        >
          <span
            class="icon is-small"
            (mouseover)="onMouseOver($event, CONSTANTS.iconFilter)"
            (mouseleave)="onMouseLeave($event, CONSTANTS.iconFilter)"
            data-e2e="event-onMouse-iconFilter"
          >
            <i class="fas fa-times"></i>
          </span>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <!-- Submenu content -->
  <ng-content></ng-content>
</div>
