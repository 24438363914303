import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@portal/shared/pipes';
import { BreadcrumbsModule } from '@portal/shared/ui/breadcrumbs';
import { SpinnerModule } from '@portal/shared/ui/spinner';
import { VersionSelectorModule } from '../../shared/version-selector/version-selector.module';
import { BundleLogoTemplateModule } from '../bundle-logo-template/bundle-logo-template.module';
import { StatusIndicatorModule } from './../../shared/status-indicator/status-indicator.module';
import { BundlePageTemplateComponent } from './bundle-page-template.component';
import { AppIconModule } from '../../shared/app-icon/app-icon.module';

@NgModule({
  imports: [
    CommonModule,
    SpinnerModule,
    StatusIndicatorModule,
    RouterModule,
    VersionSelectorModule,
    BreadcrumbsModule,
    PipesModule,
    BundleLogoTemplateModule,
    AppIconModule,
  ],
  declarations: [BundlePageTemplateComponent],
  exports: [BundlePageTemplateComponent],
})
export class BundlePageTemplateModule {}
