<div
  class="dropdown"
  [ngClass]="!isDeviceMobile ? 'is-right' : ''"
  [class.is-active]="isOpened"
  (keydown.esc)="toggleShow()"
  data-e2e="exportCsv-dropdown"
>
  <div>
    <button
      class="button is-primary is-outlined has-text-right ml-2"
      (click)="toggleShow()"
      data-e2e="exportCsv-button-open"
      type="button"
      title="{{ buttonText }}"
    >
      {{ buttonText }}
      <span class="icon ml-2">
        <i [ngClass]="!isOpened ? 'fas fa-chevron-down' : 'fas fa-chevron-up'"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu" [class.is-wide-popup]="!isDeviceMobile" *ngIf="!loading">
    <div class="dropdown-content">
      <div
        *ngIf="!loading"
        class="column is-flex is-justify-content-space-between p-3"
        [class.is-flex-direction-column]="isDeviceMobile"
      >
        <p class="title pt-1" data-e2e="exportCsv-title">{{ titleText }}</p>
        <div class="dropdown" id="template-dropdown" [class.is-active]="isOpen">
          <div class="dropdown-trigger">
            <button
              class="button"
              [disabled]="!selected?.fields?.length"
              (click)="toggleReportTemplateFields()"
            >
              <span class="mr-2"i18n>Report Template Fields </span>
              <span *ngIf="selected?.fields?.length"> (+{{ selectedFields?.length }})</span>
              <span class="icon ml-2">
                <i [ngClass]="!isOpen ? 'fas fa-chevron-down' : 'fas fa-chevron-up'"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" [class.is-hidden]="!isOpen" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <div class="dropdown-item is-scrollable has-max-height-150">
                <ul class="block-list is-small is-outlined">
                  <li *ngFor="let field of selectedFields">
                    {{ formatTemplateNameHumanReadable(field) }}

                    ({{ reportTemplateFieldColumnNameList[field] }})
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="totalCount === 0; then noTransaction; else basicReport"></ng-container>
    </div>
  </div>
</div>

<ng-template #noTransaction>
  <div class="dropdown-item">
    <p data-e2e="exportCsv-description" i18n>No transactions for export.</p>
  </div>
  <div class="dropdown-item">
    <button
      class="button is-primary"
      disabled
      data-e2e="exportCsv-button-submit"
      type="button"
      i18n
      title="Download CSV"
      i18n-title
    >
      Download CSV
    </button>
  </div>
</ng-template>

<ng-template #basicReport>
  <ng-container
    *ngIf="totalCount > threshold && !(finalTransactionCount > maxTransactionLimit)"
    [ngTemplateOutlet]="warning"
  ></ng-container>
  <ng-container
    *ngIf="finalTransactionCount > maxTransactionLimit"
    [ngTemplateOutlet]="warningAboveCountMaxLimit"
  ></ng-container>
  <div class="export-csv dropdown-item pt-0 mb-4">
    <div class="label">
      <span data-e2e="exportCsv-types-title" i18n>Report Template</span>
    </div>
    <div>
      <div class="is-three-quarters">
        <ng-select
          [(ngModel)]="defaultId"
          bindValue="id"
          bindLabel="templateName"
          data-e2e="select-exportOptionsLis"
          notFoundText="No items found"
          i18n-notFoundText
          (change)="onReportSelected($event)"
          [clearable]="false"
          [closeOnSelect]="true"
        >
          <ng-option *ngFor="let option of transactionReportTemplates" [value]="option">{{
            option.templateName
          }}</ng-option>
        </ng-select>
      </div>
    </div>

    <div class="field" *ngIf="asyncReportIdGenerated">
      <ng-container
        *ngIf="totalCount <= threshold"
        [ngTemplateOutlet]="warningbelowthreshold"
      ></ng-container>
      <dt class="has-text-grey" i18n>Report ID</dt>
      <dd class="control" data-e2e="transaction-reportIdId">
        {{ asyncReportIdGenerated || '-' }}
        <portal-copy-to-clipboard
          [valueToCopy]="asyncReportIdGenerated"
          [dataE2eId]="'report-id'"
        ></portal-copy-to-clipboard>
      </dd>
    </div>

    <div class="is-flex is-justify-content-space-between pt-3" [class.is-flex-direction-column]="isDeviceMobile">
      <div class="help" *ngIf="isTransactionsReportTemplateFeatureEnabled">
        <button class="button has-text-black" aria-haspopup="true" aria-controls="dropdown-menu2">
          <i class="fa-regular fa-arrow-up-right-from-square mr-2"></i>
          <span
            [routerLink]="['/reports', 'transactions', 'view', 'templates']"
            i18n
            title="Manage templates"
            >Manage templates</span
          >
        </button>
      </div>
      <div class="is-flex" [class.is-flex-direction-column]="isDeviceMobile">
        <div class="is-one-quarter py-1 pl-1">
          <button
            type="button"
            class="button is-text has-text-danger is-transparent"
            (click)="closeSideBar()"
          >
            Cancel
          </button>
        </div>
        <div
          class="is-one-quarter py-1 pl-1"
          *ngIf="
            totalCount < asyncReportLimit ||
            selected?.id === transactionReportType.FullPDSP ||
            selected?.id === transactionReportType.BasicPDSP
          "
        >
          <button
            class="button is-primary"
            (click)="onExport()"
            data-e2e="exportCsv-button-submit"
            type="button"
            i18n
            title="Export"
            i18n-title
          >
            Export
          </button>
        </div>
        <div
          class="is-one-quarter py-1 pl-1"
          *ngIf="
            totalCount > asyncReportLimit &&
            selected?.id !== transactionReportType.BasicPDSP &&
            selected?.id !== transactionReportType.FullPDSP
          "
        >
          <div *ngIf="!isGenerateReportClicked || finalTransactionCount > maxTransactionLimit">
            <button
              [disabled]="isGenerateReportClicked"
              class="button is-primary"
              (click)="onGenerateReport()"
              data-e2e="generate-button-report"
              type="button"
              i18n
              title="Generate report"
              i18n-title
            >
              Generate report
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="columns"></div>
</ng-template>

<ng-template #warningbelowthreshold>
  <div class="pl-2 pr-2 pb-4">
    <div class="notification is-info is-light">
      <div class="icon">
        <i class="far fa-info-circle fa-lg"></i>
      </div>
      <p data-e2e="exportCsv-description-warning">
        <span class="has-text-weight-semibold" i18n>
          We can not provide instant download. Report will be created in few minutes and will be
          available for download in Generated reports page.
        </span>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #warning>
  <div class="pl-2 pr-2 pb-4">
    <div class="notification is-info is-light">
      <div class="icon">
        <i class="far fa-info-circle fa-lg"></i>
      </div>
      <p data-e2e="exportCsv-description-warning" i18n>
        <span class="has-text-weight-semibold">
          Your data set is too large for instant CSV download.
        </span>
        You can generate the report as is and download it later from the ‘Generated reports’ page.
        We will notify you by email when it’s ready for download.
      </p>
    </div>
  </div>
</ng-template>

<ng-template #warningAboveCountMaxLimit>
  <div class="pl-2 pr-2 pb-4">
    <div class="notification is-danger is-light">
      <div class="icon">
        <i class="far fa-info-circle fa-lg"></i>
      </div>
      <div data-e2e="exportCsv-description-alert">
        <p class="has-text-weight-semibold" i18n>
          We regret to inform you that this report has surpassed the maximum size limit of 500,000
          records.To generate the report successfully, we recommend:
        </p>
        <ol>
          <li i18n>Reducing the date range of the report</li>
          <li i18n>Selecting smaller organisation(s)</li>
          <li i18n>Applying more restricted filters</li>
        </ol>
      </div>
    </div>
  </div>
</ng-template>
