<div class="columns is-vcentered is-multiline">
  <div class="column">
    <portal-filter-wrapper>
      <div
        class="control is-full-width"
        *ngFor="let filter of getFilterListbyType(filterTypes.MultipleSearch); last as isLast"
      >
        <portal-search-filter
          (filterUpdated)="onFilterUpdated($event)"
          [applyFilterOnEnter]="filter.pressEnterKey"
          [inputType]="filter.inputType"
          [entities]="filter.data"
          [entityRemoved$]="removeEntity$"
        >
        </portal-search-filter>
      </div>

      <div class="control is-flex is-align-items-center" *ngIf="getFilterListbyType(filterTypes.Entity).length">
        <ng-container
          *ngFor="let filter of getFilterListbyType(filterTypes.Entity); last as isLast"
        >
          <portal-filter-entity
            [searchSelector]="filter.selector"
            [entityType]="filter.entityType ? filter.entityType : ''"
            [parentId]="filter.parentId ? filter.parentId : ''"
            [filterName]="filter.title"
            [entityRemoved$]="removeEntity$"
            (filterApplied)="onFilterUpdated($event)"
            (filterUpdated)="onFilterApplied($event)"
          >
          </portal-filter-entity>
        </ng-container>
      </div>

      <div class="is-flex wrap withGap" *ngIf="getFilterListbyType(filterTypes.Toggle).length">
        <ng-container
          *ngFor="let filter of getFilterListbyType(filterTypes.Toggle); last as isLast"
        >
          <portal-toggle-filter
            [selector]="filter.selector"
            [filterName]="filter.title"
            [entityRemoved$]="removeEntity$"
            [class.withPadding]="true"
            (filterApplied)="onFilterUpdated($event)"
            (filterUpdated)="onFilterApplied($event)"
          >
          </portal-toggle-filter>
        </ng-container>
      </div>

      <div class="control is-flex is-align-items-center" *ngIf="getFilterListbyType(filterTypes.ButtonGroup).length">
        <ng-container
          *ngFor="let filter of getFilterListbyType(filterTypes.ButtonGroup); last as isLast"
        >
          <portal-button-group-single
            [selector]="filter.selector"
            [filterName]="filter.title"
            [entities]="filter.data"
            [entityRemoved$]="removeEntity$"
            (filterApplied)="onFilterUpdated($event)"
            (filterUpdated)="onFilterApplied($event)"
          >
          </portal-button-group-single>
        </ng-container>
      </div>

      <div class="control is-flex is-align-items-center" *ngIf="getFilterListbyType(filterTypes.TreeFilter).length">
        <ng-container *ngFor="let filter of getFilterListbyType(filterTypes.TreeFilter)">
          <portal-tree-filter
            [selector]="filter.selector"
            [service$]="filter.service$"
            [templates]="filter?.templates"
            [title]="filter.title"
            [placeholder]="filter.placeholder"
            [options]="filter?.options"
            [entityRemoved$]="removeEntity$"
            (filterApplied)="onFilterApplied($event)"
            (filterUpdated)="onFilterUpdated($event)"
          >
          </portal-tree-filter>
        </ng-container>
      </div>

      <div class="control is-flex is-align-items-center" *ngIf="getFilterListbyType(filterTypes.Hierarchy).length">
        <ng-container
          *ngFor="let filter of getFilterListbyType(filterTypes.Hierarchy); last as isLast"
        >
          <portal-hierarchy-filter
            [nodeData]="filter.data"
            [selector]="filter.selector"
            [filterTitle]="filter.title"
            [nodeType]="filter.nodeSelectType"
            [showCount]="filter.showCountInHierarchy"
            [isCalculateCount]="filter.isCalculateCount"
            [disableCheckbox]="filter.disableCheckbox"
            [entityRemoved$]="removeEntity$"
            (filterApplied)="onFilterUpdated($event)"
            (filterUpdated)="onFilterApplied($event)"
            [hierarchyEntitiesAdded$]="addEntities$"
          >
          </portal-hierarchy-filter>
        </ng-container>
      </div>
      <div class="control is-flex is-align-items-center" *ngIf="getFilterListbyType(filterTypes.DatePicker).length">
        <ng-container
          *ngFor="let filter of getFilterListbyType(filterTypes.DatePicker); last as isLast"
        >
          <portal-date-filter
            [selector]="filter.selector"
            [filterName]="filter.title"
            [inLocalTime]="filter.inLocalTime"
            [entityRemoved$]="removeEntity$"
            (filterApplied)="onFilterApplied($event)"
            (filterUpdated)="onFilterUpdated($event)"
            [entitiesAdded$]="addEntities$"
            [isFromVhqDate]="isFromVhqFlag()"
          >
          </portal-date-filter>
        </ng-container>

        <ng-container
          *ngFor="let filter of getFilterListbyType(filterTypes.Search); last as isLast"
        >
          <portal-search-text-filter
            [selector]="filter.selector"
            [filterName]="filter.title"
            [entityRemoved$]="removeEntity$"
            (filterApplied)="onFilterApplied($event)"
            (filterUpdated)="onFilterUpdated($event)"
          >
          </portal-search-text-filter>
        </ng-container>
      </div>

      <div class="control is-flex is-align-items-center" *ngIf="displayedFilters.length">
        <ng-container *ngFor="let filter of displayedFilters; last as isLast">
          <button
            class="button is-chip is-applied is-size-7-mobile"
            (click)="removeFilter(filter)"
            data-e2e="button-remove-filter"
            title="{{ filter.argument | removeUnderscore }}"
            type="button"
          >
            <span class="filter-argument">{{ filter.argument | removeUnderscore }}</span>
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button>
        </ng-container>
      </div>
      <div class="control is-flex is-align-items-center" *ngIf="this.getFilterListInDropdown().length">
        <portal-add-filter
          (closeSubmenus)="onSubmenusClosed($event)"
          [filtersList]="this.getFilterListInDropdown()"
        >
          <portal-filter-wrapper>
            <ng-container
              *ngFor="let filter of getFilterListbyType(filterTypes.Multi); last as isLast"
            >
              <portal-multiple-selection-filter
                [selector]="filter.selector"
                [filterName]="filter.title"
                [entities]="filter.data"
                [entityRemoved$]="removeEntity$"
                [closeSubmenus$]="closeSubmenus$"
                (filterApplied)="onFilterApplied($event)"
                (filterUpdated)="onFilterUpdated($event)"
                [entitiesAdded$]="addEntities$"
              >
              </portal-multiple-selection-filter>
            </ng-container>
            <ng-container
              *ngFor="let filter of getFilterListbyType(filterTypes.Single); last as isLast"
            >
              <portal-one-selection-filter
                [selector]="filter.selector"
                [filterName]="filter.title"
                [entities]="filter.data"
                [entityRemoved$]="removeEntity$"
                (filterUpdated)="onFilterUpdated($event)"
                [entitiesAdded$]="addEntities$"
              >
              </portal-one-selection-filter>
            </ng-container>
          </portal-filter-wrapper>
        </portal-add-filter>
      </div>
      <div *ngIf="selectedFilters.length && filtersList.length" class="control is-flex is-align-items-center has-border-left">
        <button
          class="button is-transparent additional-filter-button ml-2"
          (click)="resetFilter()"
          data-e2e="button-clear-filter"
          type="button"
          i18n
          title="Clear filters"
          i18n-title
        >
          Clear filters
        </button>
      </div>
    </portal-filter-wrapper>
  </div>
</div>
