<div class="field is-horizontal has-border-bottom py-4 px-5">
  <div class="field-body">
    <div class="field width-0 is-flex-grow-1">
      <label class="label">
        <span data-e2e="title-start-date" i18n>Start</span>
      </label>
      <p class="control is-expanded has-icons-left">
        <input
          class="input"
          name="dpFromDate"
          [placeholder]="dateFormat"
          data-e2e="input-from-date"
          [value]="fromDateValue"
          (keyup)="onFromDateChange($event)"
          [ngClass]="{ 'is-danger': fromDateErrorMessages.length > 0 }"
          aria-label="Start date"
          i18n-aria-label
        />
        <span class="icon is-small is-left">
          <i class="far fa-calendar-alt" aria-hidden="true"></i>
        </span>
      </p>
      <div class="help">
        <span *ngIf="!fromDateErrorMessages.length">{{ dateFormat }}</span>
        <span class="has-text-danger" *ngIf="fromDateErrorMessages.length > 0">
          <p data-e2e="error-date-filter-from-date">{{ fromDateErrorMessages[0] }}</p>
        </span>
      </div>
    </div>
    <div class="field width-0 is-flex-grow-1">
      <label class="label">
        <span data-e2e="title-end-date" i18n>End</span>
      </label>
      <p class="control is-expanded has-icons-left">
        <input
          class="input"
          name="dpToDate"
          data-e2e="input-to-date"
          [placeholder]="dateFormat"
          [value]="toDateValue"
          (keyup)="onToDateChange($event)"
          [ngClass]="{ 'is-danger': toDateErrorMessages.length > 0 }"
          aria-label="End date"
          i18n-aria-label
        />
        <span class="icon is-small is-left">
          <i class="far fa-calendar-alt" aria-hidden="true"></i>
        </span>
      </p>
      <div class="help">
        <span *ngIf="!toDateErrorMessages.length">{{ dateFormat }}</span>
        <span
          class="has-text-danger"
          *ngIf="toDateErrorMessages.length > 0 && !errorDates.startDateGreater"
        >
          <p data-e2e="error-date-filter-to-date">{{ toDateErrorMessages[0] }}</p>
        </span>
      </div>
    </div>
    <div class="field is-flex-grow-1"></div>
  </div>
</div>

<div class="py-2 px-5">
  <ngb-datepicker
    #dp
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="2"
    [dayTemplate]="t"
    [maxDate]="maxDate"
    [startDate]="_dateToShow"
    outsideDays="hidden"
  >
  </ngb-datepicker>
</div>

<ng-template #t let-date let-focused="focused">
  <div
    class="custom-day"
    [class.focused]="focused"
    [class.first]="isFirst(date)"
    [class.last]="isLast(date)"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </div>
</ng-template>
