import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FilterStaticBase } from '@portal/shared/ui/filter/src/lib/components/static-base';
import { FILTER_CONTENT_ITEM, ISelectInput, SearchSelector } from '@portal/shared/ui/filter/src';
import { TransactionDates } from '../../../list/transaction-date.list';
import { Subject } from 'rxjs';
import { TransactionFilterList } from '@apps/portal/src/app/modules/transactions/components/filter/transaction-filters.list';
import { TransactionFilter } from '@apps/portal/src/app/modules/transactions/enums';
import { IFilterCriteria } from '@apps/portal/src/app/modules/report-engine/interfaces/report-scheduler-details.interface';
import { ListKey } from '@apps/portal/src/app/modules/report-engine/enums/list-key.enum';
import { TransactionDate } from '@apps/portal/src/app/shared/enum/transaction-date.enum';

@Component({
  selector: 'portal-filter-transaction-date',
  templateUrl: './transaction-date.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: FilterTransactionDateComponent,
    },
  ],
})
export class FilterTransactionDateComponent extends FilterStaticBase implements OnInit {
  @Input()
  isMainDropdownFilter: boolean;
  @Input()
  showFilterSubMenu: boolean;
  @Input()
  data: IFilterCriteria;
  @Output() transactionDateTextName: EventEmitter<any> = new EventEmitter();
  transactionDateText = this.transactionfilters.list.find(
    (filter) => filter.id === TransactionFilter.TransactionDate,
  ).text;

  refresh$ = new Subject<void>();

  constructor(
    private transactionDates: TransactionDates,
    private transactionfilters: TransactionFilterList,
  ) {
    super(SearchSelector.TransactionDate, transactionDates.list);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.data?.selector === ListKey.CutOffTime) {
      this.localPreSelectedEntities.set(TransactionDate.Created, {
        id: TransactionDate.Created,
        text: TransactionDates[TransactionDate.Created],
      });
    } else if (this.data?.selector === ListKey.SettledTime) {
      this.localPreSelectedEntities.set(TransactionDate.Settled, {
        id: TransactionDate.Settled,
        text: TransactionDates[TransactionDate.Settled],
      });
    }
  }

  openContent(): void {
    this.itemToggled.emit();
    if (this.displayFromFilter) {
      this.isOpen = this.displayFromFilter;
    }
  }

  onFilterAppliedAndEntitiesUpdated(): void {
    this.entities = this.transactionDates.list;
    const values: string[] = this.getValues();
    if (values.length) {
      this.filterUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        value: Array.from(this.localPreSelectedEntities.values()),
        argument: values[0],
      });
      this.staticFilterUpdated.emit({
        key: TransactionFilter.TransactionDate,
        selector: this.searchSelector,
        value: Array.from(this.localPreSelectedEntities.values()),
      });
    } else {
      this.filterUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        toRemove: true,
      });
      this.staticFilterUpdated.emit({
        key: TransactionFilter.TransactionDate,
        selector: this.searchSelector,
        toRemove: true,
      });
    }
    this.filterApplied.emit();
    this.listAllEntities.emit(this.entities);
    if (this.isOpen) {
      this.closeSubmenus$.next(true);
    }
    this.transactionDateTextName.emit(this.transactionDateText);

    this.isOpen = false;
  }

  removeAllEntities(): void {
    this.localPreSelectedEntities.clear();
  }

  onEntityAdded(selectedEntity: ISelectInput): void {
    this.removeAllEntities();
    this.localPreSelectedEntities.set(selectedEntity.id, selectedEntity);
  }
}
