import { Injectable } from '@angular/core';
import { IUser } from '@portal/entity-services/interfaces/src';
import { RestrictPermissionService } from '@portal/shared/auth/authorization/src';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';
import { INavbarItem } from '@portal/shared/ui/layouts/src/lib/top-navbar/interfaces/navbar-item.interface';
import { Features } from 'environments/enums/features.enum';
import {
  getNavbarItemLink,
  getSubNavItems,
  NavbarItemLink,
} from '../../helpers/navigation-route-helpers';
import { ApplicationRoot } from '../../enums/application-root.enum';
import { RolesService } from './roles.service';
import { MenuRegion } from '../../../../../ui/layouts/src/lib/top-navbar/enum/menu-region.enum';
import { VhqPermissionsService } from '../../../../../../shared/auth/vhq-features/vhq-permissions.service'
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DevicesNavbarService {
  languageRoute: string;
  currentApplication: ApplicationRoot;
  user: IUser;
  isVhqDevicesAllowed: boolean = false;
  allowedUrls:string[] = [];

  constructor(
    public rolesSerivce: RolesService,
    public vhqPermissionService: VhqPermissionsService,
    private router: Router
  ) {}

  getDevicesNavbarItems(params: {
    currentApplication: ApplicationRoot;
    languageRoute: string;
    user: IUser;
  }): INavbarItem | null {
    this.languageRoute = params.languageRoute;
    this.currentApplication = params.currentApplication;
    this.user = params.user;
    this.rolesSerivce.updatePermissionBasedOnRoles(this.user?.roles);
    const estateManagement = this.getEstateManagementNavItems();
    const softwareApps = this.getSoftwareAppsNavItems();
    const deviceReports = this.getReportsNavItems();

    return getSubNavItems(
      {
        id: 'devices',
        title: $localize`:Main menu navbar group name:Devices`,
        attributes: {
          'data-e2e': 'title-devices',
          groupTitle: $localize`:Main menu navbar group name:Devices`,
          description: $localize`:Main menu navbar group description:Easily track & monitor all your devices in real-time. Software Library ensures devices always have the latest updates, while Device Reports provide valuable insights to help make informed decisions about tech infrastructure.`,
        },
        region: MenuRegion.Devices,
      },
      [estateManagement, softwareApps, deviceReports],
    );
  }

  getEstateManagementNavItems(): INavbarItem | null {
    const estateManagement = {
      title: $localize`:Main menu navbar item name:Estate Management`,
      attributes: {
        'data-e2e': 'title-estate-management',
      },
      children: [],
    };

    this.isVhqDevicesAllowed = this.isFeatureAllowed(Features.VHQ_Devices);
    if (this.isVhqDevicesAllowed) {
      this.allowedUrls.push('devicemanagement/home');
      this.allowedUrls.push('devicemanagement/devices');
      estateManagement.children.push(
        ...[
          {
            title: $localize`:Main menu navbar item name:Dashboard`,
            attributes: {
              'data-e2e': 'sub-navbar-home',
            },
            ...this.getItemUrl('/devicemanagement/home', [ApplicationRoot.VHQ]),
          },
          {
            title: $localize`:Main menu navbar item name:Device Search`,
            attributes: {
              'data-e2e': 'sub-navbar-devices',
            },
            ...this.getItemUrl('/devicemanagement/devices', [ApplicationRoot.VHQ]),
          },
        ],
      );
    }
    if (this.isFeatureAllowed(Features.VHQ_DeletedDevices)) {
      this.allowedUrls.push('devicemanagement/devices/deletedDevices');
      estateManagement.children.push({
        title: $localize`:Main menu navbar item name:Deleted Devices`,
        attributes: {
          'data-e2e': 'link-deletedDevices',
        },
        ...this.getItemUrl('/devicemanagement/devices/deletedDevices', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_Rollouts)) {
      this.allowedUrls.push("devicemanagement/rollouts");
      estateManagement.children.push({
        title: $localize`:Main menu navbar item name:Rollouts`,
        attributes: {
          'data-e2e': 'sub-navbar-rollouts',
        },
        ...this.getItemUrl('/devicemanagement/rollouts', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_Hierarchies)) {
      this.allowedUrls.push("devicemanagement/hierarchies");
      estateManagement.children.push({
        title: $localize`:Main menu navbar item name:Hierarchies`,
        attributes: {
          'data-e2e': 'sub-navbar-hierarchy',
        },
        ...this.getItemUrl('/devicemanagement/hierarchies', [
          ApplicationRoot.VHQ,
        ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_Groups)) {
      this.allowedUrls.push("devicemanagement/groups");
      estateManagement.children.push({
        title: $localize`:Main menu navbar item name:Groups`,
        attributes: {
          'data-e2e': 'sub-navbar-groups',
        },
        ...this.getItemUrl('/devicemanagement/groups', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_Tenants)) {
      this.allowedUrls.push("devicemanagement/tenants");
      estateManagement.children.push({
        title: $localize`:Main menu navbar item name:Tenants`,
        attributes: {
          'data-e2e': 'link-vhq-tenants',
        },
        ...this.getItemUrl('/devicemanagement/tenants', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_Locations)) {
      this.allowedUrls.push("devicemanagement/locations");
      estateManagement.children.push({
        title: $localize`:Main menu navbar item name:Locations`,
        attributes: {
          'data-e2e': 'link-locations',
        },
        ...this.getItemUrl('/devicemanagement/locations', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_Alerts)) {
      this.allowedUrls.push("devicemanagement/alerts");
      estateManagement.children.push({
        title: $localize`:Main menu navbar item name:Alerts`,
        attributes: {
          'data-e2e': 'sub-navbar-alerts',
        },
        ...this.getItemUrl('/devicemanagement/alerts', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_AlertSubscriptions)) {
      this.allowedUrls.push("manageSubscriptions");
      estateManagement.children.push({
        title: $localize`:Main menu navbar item name:Manage Alert Subscriptions`,
        attributes: {
          'data-e2e': 'link-manageSubscriptions',
        },
        ...this.getItemUrl('/devicemanagement/alerts/manageSubscriptions', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_AlertConfigurations)) {
      this.allowedUrls.push("manageConfigurations");
      estateManagement.children.push({
        title: $localize`:Main menu navbar item name:Manage Alert Configurations`,
        attributes: {
          'data-e2e': 'link-manageConfigurations',
        },
        ...this.getItemUrl('/devicemanagement/alerts/manageConfigurations', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_SystemConfigurations)) {
      this.allowedUrls.push("devicemanagement/systemconfigurations");
      estateManagement.children.push({
        title: $localize`:Main menu navbar item name:System Configurations`,
        attributes: {
          'data-e2e': 'link-systemconfigurations',
        },
        ...this.getItemUrl('/devicemanagement/systemconfigurations', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_Sponsors)) {
      this.allowedUrls.push("devicemanagement/sponsors");
      estateManagement.children.push({
        title: $localize`:Main menu navbar item name:Sponsors`,
        attributes: {
          'data-e2e': 'link-sponsors',
        },
        ...this.getItemUrl('/devicemanagement/sponsors', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_KeyHandles)) {
      this.allowedUrls.push("devicemanagement/keyHandles");
      estateManagement.children.push({
        title: $localize`:Main menu navbar item name:Key Handles`,
        attributes: {
          'data-e2e': 'link-keyHandles',
        },
        ...this.getItemUrl('/devicemanagement/keyHandles', [ApplicationRoot.VHQ]),
      });
    }
    return estateManagement;
  }

  getSoftwareAppsNavItems(): INavbarItem | null {
    const softwareApps = {
      title: $localize`:Main menu navbar item name:Software / Apps`,
      attributes: {
        'data-e2e': 'title-software-library',
      },
      children: [],
    };

    if (this.isFeatureAllowed(Features.VHQ_SoftwareLibrary)) {
      this.allowedUrls.push("devicemanagement/software-library");
      softwareApps.children.push({
        title: $localize`:Main menu navbar item name:Software Library`,
        attributes: {
          'data-e2e': 'sub-navbar-software-library',
        },
        ...this.getItemUrl('/devicemanagement/software-library', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_ReferenceSets)) {
      this.allowedUrls.push("devicemanagement/referenceSets");
      softwareApps.children.push({
        title: $localize`:Main menu navbar item name:Reference Sets`,
        attributes: {
          'data-e2e': 'sub-navbar-alerts',
        },
        ...this.getItemUrl('/devicemanagement/referenceSets', [ApplicationRoot.VHQ]),
      });
    }

    return softwareApps;
  }

  getReportsNavItems(): INavbarItem | null {
    const deviceReports = {
      title: $localize`:Main menu navbar item name:Reports`,
      attributes: {
        'data-e2e': 'title-device-reports',
      },
      children: [],
    };
    if (this.isVhqDevicesAllowed) {
      this.allowedUrls.push("audit-log");
      deviceReports.children.push({
        title: $localize`:Main menu navbar item name:Audit Log`,
        attributes: {
          'data-e2e': 'link-vhq-audit-log',
        },
        ...this.getItemUrl('/reports/audit-log', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_StandardReports)) {
      this.allowedUrls.push("reports/standardreports");
      deviceReports.children.push({
        title: $localize`:Main menu navbar item name:Standard Reports`,
        attributes: {
          'data-e2e': 'sub-navbar-standardReports',
        },
        ...this.getItemUrl('/reports/standardreports', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_ChainOfCustody)) {
      this.allowedUrls.push("tracking-management");
      deviceReports.children.push({
        title: $localize`:Main menu navbar item name:Chain of Custody`,
        attributes: {
          'data-e2e': 'sub-navbar-trackingManagement',
        },
        ...this.getItemUrl('/reports/tracking-management', [ApplicationRoot.VHQ]),
      });
    }
    if (this.isFeatureAllowed(Features.VHQ_DeviceReport)) {
      this.allowedUrls.push("device-report");
      deviceReports.children.push({
        title: $localize`:Main menu navbar item name:Device Asset Tracking`,
        attributes: {
          'data-e2e': 'sub-navbar-deviceReport',
        },
        ...this.getItemUrl('/reports/device-report', [ApplicationRoot.VHQ]),
      });
    }

    localStorage.setItem("AllowedUrls", JSON.stringify(this.allowedUrls));
    return deviceReports;
  }

  navigateToAccessDeniedPage():void {
    this.router.navigateByUrl("/access-denied");
  }

  private getItemUrl(url: string, applications: ApplicationRoot[]): NavbarItemLink {
    return getNavbarItemLink(this.currentApplication, applications, this.languageRoute, url);
  }

  // get feature allowed
  isFeatureAllowed(feature): boolean {
    return this.vhqPermissionService.isPermissible(feature, 'View');
  }
}
