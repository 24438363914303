import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Title } from '@angular/platform-browser';
import cloneDeep from 'lodash-es/cloneDeep';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ISearchBoxValue, ISearchCriteria } from '@portal/shared/ui/filter';
import { EntityStatus } from '@portal/entity-services/interfaces';
import { IUser, UserStatus } from '@apps/portal/src/app/core/core.module';
import { RoleGuardService } from '@portal/shared/auth/authorization/src/lib/role-guard.service';
import { PaginationSortHandler } from '@portal/shared/helpers';
import { CompareService } from '@portal/shared/helpers/src/lib/compare/compare.service';
import {
  IQueryParams,
  IResultsWithCount,
  ISort,
  ITableConfig,
  PageSize,
  QueryParamsService,
  SortOrder,
} from '@portal/shared/ui/table';
import { SearchOperator } from '../../enums/search-operator.enum';
import { UserService } from '../../services/user.service';
import { FilterUserComponent } from '../filter/filter.component';
import { UserRoles } from '@portal/shared/role-group-picker/src/lib/user-role.list';
import { UserStatuses } from '../../services/user-statuses.list';
import { Features } from 'environments/enums/features.enum';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';

declare const $localize;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'portal-list-users',
  templateUrl: './list.component.html',
})
export class ListUserComponent extends PaginationSortHandler implements OnInit, AfterViewInit {
  @ViewChild(FilterUserComponent) filterUserComponent: FilterUserComponent;
  @ViewChild('rolesTemplate') rolesTemplate: TemplateRef<any>;
  @ViewChild('createGroupButton') createGroupButton: ElementRef<HTMLAnchorElement>;
  @ViewChild('createUserButton') createUserButton: ElementRef<HTMLAnchorElement>;
  loading$: Observable<boolean>;
  users: IUser[];
  totalCount: number;
  range: string;
  filter: IQueryParams;
  resetPagination: boolean;
  userCountSubcription: Subscription;
  usersWithCount$: Observable<IResultsWithCount<IUser>>;
  canGoToDetail = false;
  selectedSearchSelector: Subject<string> = new Subject();
  searchTerm: Subject<string> = new Subject();
  switchOn = false;
  isGroupOrgListDisplayed = false;
  labelUsers = $localize`Users`;
  labelGroups = $localize`Groups`;

  initialFilter: IQueryParams = {
    start: 0,
    limit: PageSize.Size10,
    order: SortOrder.Asc,
    populateEntity: true,
    searchCriteria: new Map<string, ISearchCriteria>(),
    status: [EntityStatus.Active_Inactive],
  };
  filterTouched = false;

  tableConfig: ITableConfig = {
    columns: [
      { key: 'name', label: $localize`Name`, isSortable: true },
      {
        key: 'email',
        label: $localize`Email`,
        isRenderFunction: true,
        renderFunction: (user: IUser) => (this.isStatusDeleted(user.status) ? '-' : user.email),
      },
      {
        key: 'roles',
        label: $localize`Roles`,
        cssClass: 'table-row-roles',
        isTemplateRef: true,
        templateRef: () => this.rolesTemplate,
      },
      { key: 'entity.name', label: $localize`Organisation` },
      {
        key: 'entity.status',
        label: $localize`Status`,
        isRenderFunction: true,
        renderFunction: (user: IUser) => this.userStatusList.lookup(user?.status)?.text,
      },
    ],
    rowIdKey: 'userUid',
    rowLabelKey: 'name',
  };

  private navigationHistory = [];

  constructor(
    private userStatusList: UserStatuses,
    private userService: UserService,
    private title: Title,
    private liveAnnouncer: LiveAnnouncer,
    private roleGuard: RoleGuardService,
    private route: ActivatedRoute,
    private router: Router,
    private compareService: CompareService,
    private userRolesList: UserRoles,
    private featureToggleService: FeatureToggle,
  ) {
    super();
    this.loading$ = userService.listLoading$;
    this.userService.filter$.pipe(take(1)).subscribe((data: any) => {
      if (!data) {
        this.setInitialFilterState();
      } else {
        // @ts-ignore
        this.filter = data.filter;
        this.updateFilterTouchedFlag();
      }
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (!event.url.includes('/users')) {
          this.setInitialFilterState();
          this.userService.setFilter({ filter: this.filter });
        }
      });
  }

  ngOnInit(): void {
    this.isGroupOrgListDisplayed = false;
    this.title.setTitle($localize`:Page title:Users`);
    this.liveAnnouncer.announce($localize`Navigated to ${this.title.getTitle()}`);
    this.usersNavigationHistory();
    this.canGoToDetail = this.roleGuard.canAccess(this.route.snapshot, 'read');
    this.getUsers();
  }

  ngAfterViewInit(): void {
    this.setFocus();
  }

  setFocus(): void {
    setTimeout(() => {
      if (this.createGroupButton) {
        this.createGroupButton.nativeElement.focus();
      } else if (this.createUserButton) {
        this.createUserButton.nativeElement.focus();
      } else if (this.filterUserComponent) {
        this.filterUserComponent?.focusSearchBox();
      } else {
        this.setFocus();
      }
    }, 1000);
  }

  toggleOrgUserOrGroupHierarchyUser(): void {
    localStorage.setItem('navigationFromOrgGroup', '');
    this.switchOn = !this.switchOn;
    this.isGroupOrgListDisplayed = this.switchOn ? true : false;
  }

  get showGroupOrgList(): boolean {
    this.isPageFromOrganizationGroup();
    return this.isGroupOrgListDisplayed;
  }

  setInitialFilterState(): void {
    this.filter = cloneDeep(this.initialFilter);
    this.filterTouched = false;
  }

  usersNavigationHistory(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.navigationHistory = [...this.navigationHistory, urlAfterRedirects];
        if (this.navigationHistory?.join(',').includes('organization-group')) {
          localStorage.setItem('navigationFromOrgGroup', this.navigationHistory.join(','));
        } else {
          localStorage.setItem('navigationFromOrgGroup', '');
        }
      });
  }

  isPageFromOrganizationGroup(): void {
    const pageFromOrganizationGroup = localStorage.getItem('navigationFromOrgGroup');
    if (pageFromOrganizationGroup?.includes('organization-group')) {
      this.isGroupOrgListDisplayed = this.switchOn = true;
    }
  }

  getUsers(): void {
    if (this.userCountSubcription) {
      this.userCountSubcription.unsubscribe();
    }

    this.usersWithCount$ = this.userService.getFilteredResultsWithCount(this.filter);
    this.userCountSubcription = this.usersWithCount$.subscribe(
      (results: IResultsWithCount<IUser>) => {
        this.users = results.results;
        this.totalCount = results.count;
        this.range = QueryParamsService.getRange<IUser>(this.filter, this.users);
      },
    );
  }

  isGroupHierarchyPermitted(): boolean {
    return this.featureToggleService.isFeatureActive(Features.GroupEntities);
  }

  afterPaginate(): void {
    this.getUsers();
  }

  afterSorting(): void {
    this.resetPagination = true;
    this.getUsers();
  }

  onSortChanged(sort: ISort): void {
    super.onSorting(sort.key, sort.order);
  }

  onUserFiltered(searchCriteria: ISearchBoxValue): void {
    this.resetPaginationForFilter();
    if (searchCriteria) {
      this.filter.searchCriteria.set(searchCriteria.key, {
        argument: searchCriteria.value,
        selector: searchCriteria.key,
        operator: SearchOperator.Equal,
      });
      this.updateFilterTouchedFlag();
    }

    this.filter.start = 0;
    this.filter.limit = PageSize.Size10;
    this.userService.setFilter({ filter: this.filter });
    this.getUsers();
  }

  onUserFilterReset(): void {
    if (this.filterUserComponent.filterEntityComponent) {
      this.filterUserComponent.filterEntityComponent.removeAllEntities();
    }
    this.setInitialFilterState();
    this.userService.setFilter({ filter: this.filter });
    this.getUsers();
  }

  onUserFiltersChanged(key: string): void {
    this.filter.searchCriteria.delete(key);
    this.resetPagination = false;
    this.userService.setFilter({ filter: this.filter });
    this.updateFilterTouchedFlag();
    this.getUsers();
  }

  updateFilterTouchedFlag(): void {
    this.filterTouched = !this.compareService.mapsEqual(
      this.initialFilter.searchCriteria,
      this.filter.searchCriteria,
    );
  }

  isStatusDeleted(status: UserStatus): boolean {
    return status === UserStatus.Deleted;
  }
}
