import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppLogoTemplateService {
  private payload = new Map();

  take(url: string): Observable<any> {
    if (this.payload.has(url)) {
      return this.payload.get(url);
    }

    const accessToken = JSON.parse(localStorage.getItem('access_token'));

    const obs = from(
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          throw new Error(`Response status: ${response.status}`);
        })
        .catch(() => {}),
    );

    this.payload.set(url, obs);
    return obs;
  }
}
