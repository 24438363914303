import { UserRoles } from '@portal/shared/auth/authorization';
import { createPermissions } from '@portal/shared/helpers/src';

export const NotificationsPermissions = createPermissions({
  list: {
    read: [
      UserRoles.MerchantAdmin,
      UserRoles.MerchantSupervisor,
      UserRoles.VerifoneAdmin,
      UserRoles.VerifoneUser,
    ],
  },
  create: {
    read: [UserRoles.MerchantAdmin, UserRoles.MerchantSupervisor, UserRoles.VerifoneAdmin],
  },
  edit: {
    read: [UserRoles.MerchantAdmin, UserRoles.MerchantSupervisor, UserRoles.VerifoneAdmin],
  },
  details: {
    read: [
      UserRoles.MerchantAdmin,
      UserRoles.MerchantSupervisor,
      UserRoles.VerifoneAdmin,
      UserRoles.VerifoneUser,
    ],
  },
  failures: {
    read: [UserRoles.MerchantAdmin, UserRoles.VerifoneAdmin, UserRoles.VerifoneUser],
  },
});
