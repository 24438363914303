import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideModule } from '@portal/shared/ui/click-outside/src/lib/click-outside.module';
import { SelectComponent } from './select.component';

@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, FormsModule, ClickOutsideModule, ReactiveFormsModule],
  exports: [SelectComponent],
})
export class SelectModule {}
