/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isBefore } from 'date-fns';
import { timer, Observable, Subject, Subscription, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { IFRAME_FILE, TIMEOUT_FACTOR_INTERVAL } from './constants';
import { IAuthenticationConfigs } from './interfaces';
import { Auth } from 'environments/interfaces';
import { NotifierService } from '@portal/shared/ui/notifier/src/lib/notifier.service';
import { EnvironmentVariableService } from '@portal/shared/helpers/src/lib/environment-variables/environment-variables';
import { Location } from '@angular/common';
import { Features } from 'environments/enums/features.enum';
import { CoockiesService } from '@portal/shared/helpers/src';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authConfigs: IAuthenticationConfigs;
  authEvents = new Subject<AuthenticationService.IEvent>();
  sessionTimer = new Subject();
  private tokenTimerSubscription: Subscription;
  private silentRefreshEventListener: (e: any) => void;
  private refreshTokenIframe: HTMLIFrameElement;

  private environmentVars;
  private expiryTime: number;

  constructor(
    private coockieService: CoockiesService,
    protected http: HttpClient,
    protected notifier: NotifierService,
    protected location: Location,
  ) {
    this.environmentVars = {
      CLIENT_ID: EnvironmentVariableService.getAuthVar('CLIENT_ID'),
      REQUESTED_SCOPES: EnvironmentVariableService.getAuthVar('REQUESTED_SCOPES'),
      AUTHORIZATION_ENDPOINT: EnvironmentVariableService.getAuthVar('AUTHORIZATION_ENDPOINT'),
      SILENT_REFRESH_ENDPOINT: EnvironmentVariableService.getAuthVar('SILENT_REFRESH_ENDPOINT'),
      TOKEN_ENDPOINT: EnvironmentVariableService.getAuthVar('TOKEN_ENDPOINT'),
      END_SESSION_ENDPOINT: EnvironmentVariableService.getAuthVar('END_SESSION_ENDPOINT'),
      LOGOUT_ENDPOINT: EnvironmentVariableService.getAuthVar('LOGOUT_ENDPOINT'),
      TOKEN_TIMEOUT_FACTOR: EnvironmentVariableService.getAuthVar('TOKEN_TIMEOUT_FACTOR'),
      OFFSET: EnvironmentVariableService.getAuthVar('OFFSET'),
      IFRAME_NAME: EnvironmentVariableService.getAuthVar('IFRAME_NAME'),
      USER_INACTIVITY_TIMEOUT: EnvironmentVariableService.getAuthVar('USER_INACTIVITY_TIMEOUT'),
      USER_INACTIVITY_TIMEOUT_OFFSET: EnvironmentVariableService.getAuthVar(
        'USER_INACTIVITY_TIMEOUT_OFFSET',
      ),
      FRIC_MAX_IDLE_TIMEOUT: EnvironmentVariableService.getAuthVar('FRIC_MAX_IDLE_TIMEOUT'),
      FRIC_MAX_SESSION_TIMEOUT: EnvironmentVariableService.getAuthVar('FRIC_MAX_SESSION_TIMEOUT'),
    };
    // initiate authConfigs
    // the AuthConfigs object get set only once when the app.component.ts get
    // initiated, however, if someone try to access a certain URL directly, one
    // of the routeGuard would get invoked first, if the URL is not accessible
    // and the routeGuard try to call the logout method, the authConfigs was not
    // set and an error would occur. Therefore we need to set the authConfigs
    // when this service initialized.
    let redirectUri = this.getRedirectUri();
    if (redirectUri.indexOf('?code') > -1) {
      const index = redirectUri.indexOf('?');
      redirectUri = redirectUri.substring(0, index);
    }
    this.updateAuthConfigs(this.environmentVars, {
      redirectUri: redirectUri,
      postLogoutRedirectUri: redirectUri,
    });
  }

  startTokenRefreshTimers(): void {
    if (!(this.hasIdTokenExpired() && this.hasTokenExpired())) {
      const timeout = this.getTimeout();
      this.removeTokenRefreshTimers();
      this.tokenTimerSubscription = timer(
        timeout,
        this.expiryTime * this.environmentVars.TOKEN_TIMEOUT_FACTOR,
      )
        .pipe(finalize(this.removeTokenRefreshTimers))
        .subscribe(() => {
          this.registerSilentRefreshListener();
          this.silentRefresh();
        });
    } else {
      this.notifier.warn($localize`Session has expired! Please login again`);
      this.logout();
    }
  }

  removeTokenRefreshTimers(): void {
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
  }

  get isAuthenticated(): boolean {
    return !!localStorage.getItem('access_token');
  }

  get userUid(): string {
    const access_token = localStorage.getItem('access_token');
    return access_token ? JSON.parse(atob(access_token.split('.')[1])).sub : '';
  }

  get entityUid(): string {
    const access_token = localStorage.getItem('access_token');
    return access_token ? JSON.parse(atob(access_token.split('.')[1])).entity_id : '';
  }

  get eoEntityUid(): string {
    if (localStorage.getItem('eoEntityUid')) {
      const eoEntityUid = localStorage.getItem('eoEntityUid');
      return eoEntityUid;
    }
  }

  get entityId(): string {
    if (localStorage.getItem('entityId')) {
      const entityId = localStorage.getItem('entityId');
      return entityId;
    }
  }

  get jwtToken(): string {
    return localStorage.getItem('access_token') || null;
  }

  updateAuthConfigs(env: Partial<Auth>, authConfigs = {}): void {
    this.authConfigs = {
      clientId: env.CLIENT_ID,
      requestedScopes: env.REQUESTED_SCOPES,
      authorizationEndpoint: env.AUTHORIZATION_ENDPOINT,
      silentRefreshEndpoint: env.SILENT_REFRESH_ENDPOINT,
      tokenEndpoint: env.TOKEN_ENDPOINT,
      endSessionEndpoint: env.END_SESSION_ENDPOINT,
      logoutEndpoint: env.LOGOUT_ENDPOINT,
      redirectUri: `${window.location.origin}/`,
      postLogoutRedirectUri: `${window.location.origin}/`,
      userInactivityTimeout: env.USER_INACTIVITY_TIMEOUT,
      userInactivityTimeoutOffset: env.USER_INACTIVITY_TIMEOUT_OFFSET,
      userSessionTimeout: env.FRIC_MAX_SESSION_TIMEOUT,
      ...authConfigs,
    };
  }

  // EXCHANGE FOR ACCESS TOKEN
  // =======================================================
  verifyState(state: string): boolean {
    const localState = localStorage.getItem('pkce_state');
    return localState === state;
  }

  /**
   * Requests the access token from the IdM, as per OAuth2 protocol
   * @param code code returned in the URI params by the callback from IdM
   * @param redirectUri should be the same URI as was previously given to the IdM
   * @example
   * /authorize?redirect_uri=A
   * /access_token?redirect_uri=A
   */
  exchangeCodeForToken(code: string, redirectUri = this.authConfigs.redirectUri): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      }),
    };

    const data = {
      client_id: this.authConfigs.clientId,
      redirect_uri: redirectUri,
      grant_type: 'authorization_code',
      code_verifier: localStorage.getItem('pkce_code_verifier'),
      code,
    };

    const payload = Object.keys(data)
      .map((key) => `${key}=${data[key]}`)
      .join('&');

    return this.http.post(this.authConfigs.tokenEndpoint, payload, options);
  }

  /**
   * @description
   * Handles response obtained from the authentication endpoint,
   * in accordance to the OAuth2 PKCE flow.
   * Saves the credentials in session storage.
   * @param data whatever is returned by `exchangeCodeForToken()`
   */
  handleAuthResponse(data: any): void {
    const { access_token, expires_in, id_token, scope, token_type } = data;
    const idTokenPayload = JSON.parse(atob(id_token.split('.')[1]));
    const accessTokenPayload = JSON.parse(atob(access_token.split('.')[1]));
    const id_token_expires_at = Number(idTokenPayload.exp) * 1000;
    const access_token_expires_at = Number(accessTokenPayload.exp) * 1000;

    // Store these values in LocalStorage
    localStorage.setItem('scope', JSON.stringify(scope));
    localStorage.setItem('id_token', JSON.stringify(id_token));
    localStorage.setItem('expires_in', JSON.stringify(expires_in));
    localStorage.setItem('access_token_expires_at', access_token_expires_at.toString());
    localStorage.setItem('id_token_expires_at', id_token_expires_at.toString());
    localStorage.setItem('id_token_iat', String(idTokenPayload.iat * 1000));
    localStorage.setItem('access_token_iat', String(accessTokenPayload.iat * 1000));
    localStorage.setItem('token_type', JSON.stringify(token_type));
    localStorage.setItem('access_token', JSON.stringify(access_token));

    if (!localStorage.getItem('session_start_time')) {
      const sessionStartTime = Math.min(
        parseInt(localStorage.getItem('id_token_iat'), 10),
        parseInt(localStorage.getItem('access_token_iat'), 10),
      );
      localStorage.setItem('session_start_time', sessionStartTime.toString());
      this.sessionTimer.next();
    }

    // send Auth EVENT
    this.authEvents.next({ type: AuthenticationService.Event.AuthResponseSuccess });
  }

  // HELPER FUNCTIONS
  // =======================================================
  generateRandomString(): string {
    const array = new Uint32Array(28);
    const crypto = window.crypto || (window as any).msCrypto;
    crypto?.getRandomValues(array);
    return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('');
  }

  /**
   * Calculate base64-urlencoded sha256 hash of the PKCE verifier.
   * @param {string} plain input text
   * @returns {string} PKCE challenge text
   */
  pkceChallengeFromVerifier(plain: string): string {
    return CryptoJS.SHA256(plain)
      .toString(CryptoJS.enc.Base64)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  /**
   * Redirects to the authentication endpoint
   * @param redirectPath @optional
   *  router path to be preserved; useful when the router haven't yet updated the location
   */
  authenticate(redirectPath: string = '/'): void {
    // Create and store a random 'state' value
    if (!localStorage.getItem('pkce_state')) {
      const state = this.generateRandomString();
      localStorage.setItem('pkce_state', state);
    }
    const loginTime = Date.now();
    if (redirectPath && !(redirectPath.includes('iss') || redirectPath.includes('client_id'))) {
      localStorage.setItem('redirect_path', redirectPath);
    }

    // Create and store a new PKCE codeVerifier (the plaintext random secret)
    if (!localStorage.getItem('pkce_code_verifier')) {
      const codeVerifier = this.generateRandomString();
      localStorage.setItem('pkce_code_verifier', codeVerifier);
    }

    // Hash and base64-urlencode the secret to use as the challenge
    const codeChallenge = this.pkceChallengeFromVerifier(
      localStorage.getItem('pkce_code_verifier'),
    );

    // Build the authorization URL
    const url =
      this.authConfigs.authorizationEndpoint +
      '?response_type=code' +
      '&client_id=' +
      encodeURIComponent(this.authConfigs.clientId) +
      '&state=' +
      encodeURIComponent(localStorage.getItem('pkce_state')) +
      '&scope=' +
      encodeURIComponent(this.authConfigs.requestedScopes) +
      '&redirect_uri=' +
      encodeURIComponent(this.authConfigs.redirectUri) +
      '&code_challenge=' +
      encodeURIComponent(codeChallenge) +
      '&code_challenge_method=S256' +
      '&login_time=' +
      loginTime;

    // Redirect to the authorization server
    window.location.assign(url);
  }

  /**
   * Performs a logout by clearing the session storage and redirecting the user to the logout page
   * @param skipLocationChange don't redirect and instead emit a `LOGGED_OUT` event
   * @param redirectPath store this path in the session storage for future use in calls to `authenticate()`.
   * @param isFederated Do not store redirect path when logging out from navbar
   * Useful for preserving the destination URL when sending the user back to the login page in case of an error.
   */
  logout({
    skipLocationChange,
    redirectPath,
    isFederated,
  }: { skipLocationChange?: boolean; redirectPath?: string; isFederated?: boolean } = {}): void {
    redirectPath = redirectPath || '/';
    const hasIdTokenExpired = this.hasIdTokenExpired();
    const hasTokenExpired = this.hasTokenExpired();
    const id_token = JSON.parse(localStorage.getItem('id_token'));
    const isUisIntegratedActive = EnvironmentVariableService.getFeatureFlag(Features.UseUis);
    this.removeTokenRefreshTimers();
    sessionStorage.clear();
    this.clearLocalStorage();
    if (redirectPath && !(redirectPath.includes('iss') || redirectPath.includes('client_id'))) {
      localStorage.setItem('redirect_path', redirectPath);
    }

    if (!isUisIntegratedActive && id_token) {
      const url = isFederated
        ? this.authConfigs.endSessionEndpoint + '?id_token_hint=' + id_token
        : this.authConfigs.endSessionEndpoint +
          '?id_token_hint=' +
          id_token +
          '&post_logout_redirect_uri=' +
          this.authConfigs.postLogoutRedirectUri;

      window.location.assign(url);
    } else {
      if (!isFederated && !localStorage.getItem('redirect_path')) {
        this.updateAuthConfigs(this.environmentVars, {
          redirectUri: `${window.location.origin}/`,
          postLogoutRedirectUri: `${window.location.origin}/`,
        });
      }
      if (hasIdTokenExpired || hasTokenExpired || skipLocationChange) {
        this.authEvents.next({
          type: AuthenticationService.Event.LoggedOut,
        });
      } else {
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-VFI-CONTEXT': this.coockieService.readCookie('X-VFI-CONTEXT'),
          }),
          withCredentials: true,
        };
        this.http
          .post(this.authConfigs.logoutEndpoint, '', options)
          .pipe(
            catchError(() => {
              return of(null);
            }),
          )
          .subscribe(() => {
            window.location.assign(`${window.location.origin}/`);
          });
      }
    }
  }

  clearLocalStorage(): void {
    localStorage.removeItem('id_token');
    localStorage.removeItem('id_token_expires_at');
    localStorage.removeItem('id_token_iat');
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expires_at');
    localStorage.removeItem('access_token_iat');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('scope');
    localStorage.removeItem('token_type');
    localStorage.removeItem('session_start_time');
  }

  getTimeout(): number {
    const accessTokenIssuedAt = parseInt(localStorage.getItem('access_token_iat'), 10);
    const accessTokenExpiresAt = parseInt(localStorage.getItem('access_token_expires_at'), 10);

    const idTokenIssuedAt = parseInt(localStorage.getItem('id_token_iat'), 10);
    const idTokenExpiresAt = parseInt(localStorage.getItem('id_token_expires_at'), 10);

    const fricExpiryTime = parseInt(this.environmentVars.FRIC_MAX_IDLE_TIMEOUT, 10);
    const accessTokenExpiryTime = accessTokenExpiresAt - accessTokenIssuedAt;
    const idTokenExpiryTime = idTokenExpiresAt - idTokenIssuedAt;

    const issuedTime = Math.min(accessTokenIssuedAt, idTokenIssuedAt);
    this.expiryTime = [fricExpiryTime, accessTokenExpiryTime, idTokenExpiryTime].reduce((a, b) =>
      Math.min(a, b),
    );

    return this.calcTokenTimeout(issuedTime, this.expiryTime);
  }

  /**
   * Returns time remaining until the expiration date of token
   * @param {Number} issuedTime - The time when token was created
   * @param {String} expiresAt - Expiration data of token in milliseconds
   */
  calcTokenTimeout(issuedTime: number, expiresAt: number): number {
    const [minFactor, maxFactor] = TIMEOUT_FACTOR_INTERVAL;
    const currentTime = new Date().getTime();
    let factor = this.environmentVars.TOKEN_TIMEOUT_FACTOR;

    if (!expiresAt) {
      return undefined;
    }

    if (factor > maxFactor) {
      factor = maxFactor;
    }

    if (factor < minFactor) {
      factor = minFactor;
    }
    const remainingTime = expiresAt * factor - (currentTime - issuedTime);
    const remainingTimeWithoutFactor = expiresAt + issuedTime - currentTime;

    if (remainingTime < 0) {
      return 0;
    }

    if (remainingTimeWithoutFactor - remainingTime < this.environmentVars.OFFSET) {
      return remainingTimeWithoutFactor - this.environmentVars.OFFSET;
    }

    return remainingTime;
  }

  setLoginDomainName(loginDomainName: string): void {
    this.authConfigs.authorizationEndpoint = `https://${loginDomainName}/login`;
  }

  removeSilentRefreshEventListener(): void {
    if (this.silentRefreshEventListener) {
      window.removeEventListener('message', this.silentRefreshEventListener);
      this.silentRefreshEventListener = undefined;
    }
  }

  processMessageEventMessage(event: MessageEvent): string {
    const expectedPrefix = '?';

    if (!event || !event.data || typeof event.data !== 'string') {
      return;
    }

    const prefixedMessage = event.data;

    if (!prefixedMessage.startsWith(expectedPrefix)) {
      return;
    }

    return '#' + prefixedMessage.substr(expectedPrefix.length);
  }

  registerSilentRefreshListener(): void {
    this.removeSilentRefreshEventListener();
    this.silentRefreshEventListener = (event) => {
      if (typeof event.data === 'string') {
        const message = this.processMessageEventMessage(event);
        const data: Record<string, any> = this.parseQueryString(message);

        if (data) {
          if (data.error || !data.code) {
            this.notifier.warn($localize`You are not authenticated, please login!`);
            this.logout();
            return;
          }

          this.exchangeCodeForToken(data.code, `${this.authConfigs.redirectUri}${IFRAME_FILE}`)
            .pipe(
              finalize(() => {
                this.removeIframe();
              }),
            )
            .subscribe((result) => {
              this.handleAuthResponse(result);
            });
        }
      }
    };

    window.addEventListener('message', this.silentRefreshEventListener);
  }

  /**
   * Parses the query string and extracts the URL params.
   * This query string must be a URL search or hash part that starts with "?" or "#" respectively.
   * @param queryString URL search or hash part, e.g. `"?code=123&state=98743"`
   * @return Record<string, string> hash with the parsed URL params, e.g. `{code:'123', state:'98743'}`
   */
  parseQueryString(queryString: string): Record<string, string> {
    if (!queryString) {
      return;
    }

    const data = Object.create(null);
    let separatorIndex, escapedKey, escapedValue, key, value;
    queryString = queryString.substr(1); // #code=123&iss=456

    if (!queryString || queryString.length === 0) {
      return data;
    }

    const pairs = queryString.split('&');
    pairs.forEach((pair) => {
      separatorIndex = pair.indexOf('=');

      if (separatorIndex === -1) {
        escapedKey = pair;
        escapedValue = null;
      } else {
        escapedKey = pair.substr(0, separatorIndex);
        escapedValue = pair.substr(separatorIndex + 1);
      }

      key = decodeURIComponent(escapedKey);
      value = decodeURIComponent(escapedValue);

      if (key.substr(0, 1) === '/') {
        key = key.substr(1);
      }

      data[key] = value;
    });

    return data;
  }

  /**
   * Send request to the authorization endpoint via src iframe attribute
   */
  silentRefresh(): void {
    const id_token = localStorage.getItem('id_token');
    if (!localStorage.getItem('pkce_state')) {
      const state = this.generateRandomString();
      localStorage.setItem('pkce_state', state);
    }
    if (!localStorage.getItem('pkce_code_verifier')) {
      const codeVerifier = this.generateRandomString();
      localStorage.setItem('pkce_code_verifier', codeVerifier);
    }
    const codeChallenge = this.pkceChallengeFromVerifier(
      localStorage.getItem('pkce_code_verifier'),
    );
    const url =
      this.authConfigs.silentRefreshEndpoint +
      '?response_type=code' +
      '&prompt=none' +
      `&client_id=${encodeURIComponent(this.environmentVars.CLIENT_ID)}` +
      `&scope=${encodeURIComponent(this.environmentVars.REQUESTED_SCOPES)}` +
      `&redirect_uri=${encodeURIComponent(`${this.authConfigs.redirectUri}${IFRAME_FILE}`)}` +
      `&id_token_hint=${encodeURIComponent(JSON.parse(id_token))}` +
      `&state=${encodeURIComponent(localStorage.getItem('pkce_state'))}` +
      '&code_challenge_method=S256' +
      `&code_challenge=${encodeURIComponent(codeChallenge)}`;

    this.refreshTokenIframe = document.createElement('iframe');
    this.refreshTokenIframe.id = this.environmentVars.IFRAME_NAME;
    this.refreshTokenIframe.style.display = 'none';
    this.refreshTokenIframe.src = url;
    document.body.appendChild(this.refreshTokenIframe);
  }

  removeIframe(): void {
    if (this.refreshTokenIframe) {
      this.refreshTokenIframe.remove();
    }
  }

  hasIdTokenExpired(): boolean {
    const idTokenExpiresAt = localStorage.getItem('id_token_expires_at');
    if (!idTokenExpiresAt) return true;

    return parseInt(idTokenExpiresAt, 10) < new Date().getTime();
  }

  hasTokenExpired(): boolean {
    let exp: number;
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      return true;
    }
    try {
      exp = JSON.parse(atob(accessToken.split('.')[1])).exp;
      const tokenExpiryDate = new Date(0);
      tokenExpiryDate.setUTCSeconds(exp);
      const currentDate = new Date();
      return isBefore(tokenExpiryDate, currentDate);
    } catch (error) {
      this.notifier.error($localize`Invalid session detected! Please login`);
      return true;
    }
  }

  private getRedirectUri(): string {
    const getBaseHref = (): string => document.querySelector('base')?.href || '/';
    return document.baseURI || `${window.location.origin}${getBaseHref()}`;
  }
}

export namespace AuthenticationService {
  export enum Event {
    AuthResponseSuccess = 'AUTH_SUCCESS',
    LoggedIn = 'LOGGED_IN',
    LoggedOut = 'LOGGED_OUT',
    InProgress = 'IN_PROGESS',
    User = 'USER',
  }

  export interface IEvent {
    type: string;
    data?: any;
  }
}
