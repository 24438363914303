import { Directive, HostBinding, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestrictPermissionService } from './services/restrict-permission.service';

@Directive({
  /* eslint-disable @angular-eslint/directive-selector */
  selector: '[restrict-permission][routerLink]',
  /* eslint-enable @angular-eslint/directive-selector */
})
export class RestrictPermissionRouterLinkDirective implements OnChanges {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('restrict-permission') restrictedActions: string[];
  @Input() routerLink: string[] | string;
  @HostBinding('style.display') display: string;

  constructor(
    private route: ActivatedRoute,
    private restrictPermissionService: RestrictPermissionService,
  ) {}

  ngOnChanges(): void {
    this.updateView();
  }

  canShowView(): boolean {
    const permissions = this.routerLink
      ? this.restrictPermissionService.getPermissionsForUrl(this.routerLink, {
          relativeTo: this.route,
        })
      : this.restrictPermissionService.getPermissionsForRoute(this.route.snapshot);
    const userhasPermissions = this.restrictPermissionService.checkPermissions(
      permissions,
      this.restrictedActions || ['read', 'self-read'],
    );
    return userhasPermissions;
  }

  updateView(): void {
    if (this.canShowView()) {
      this.display = null;
    } else {
      this.display = 'none';
    }
  }
}
