import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IUser } from '@portal/entity-services/interfaces';
import { OrganisationService } from '@portal/entity-services/organisations/src/lib/services/organisation.service';
import { AuthenticationService } from '@portal/shared/auth/authentication/src';
import { ToastService } from '@portal/shared/ui/toast';
import { UserService } from '@portal/shared/user/src';
import { finalize } from 'rxjs/operators';
import { UserFullNameService } from '../../services/user-full-name.service';
import { FormUserComponent } from '../form/form.component';

declare const $localize;

@Component({
  selector: 'portal-create-user',
  templateUrl: './create.component.html',
})
export class CreateUserComponent implements OnInit, AfterViewInit {
  @ViewChild(FormUserComponent) formUserComponent: FormUserComponent;
  saving = false;
  loading = false;
  loggedInUser: IUser;

  constructor(
    private organisationService: OrganisationService,
    private toastService: ToastService,
    private router: Router,
    private title: Title,
    private liveAnnouncer: LiveAnnouncer,
    private userFullNameService: UserFullNameService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle($localize`:Page title:Add new user`);
    this.liveAnnouncer.announce($localize`Navigated to ${this.title.getTitle()}`);
    const loggedInUserId = this.authenticationService.userUid;
    this.loading = true;
    this.userService.getByKey(loggedInUserId).subscribe((user: IUser) => {
      this.loggedInUser = user;
      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
    this.setFocus();
  }

  setFocus(): void {
    if (this.formUserComponent) {
      if (this.formUserComponent.firstNameInput) {
        this.formUserComponent.firstNameInput?.nativeElement?.focus();
      } else if (this.formUserComponent.fullNameInput) {
        this.formUserComponent.fullNameInput?.nativeElement?.focus();
      }
    } else {
      setTimeout(() => {
        this.setFocus();
      }, 1000);
    }
  }

  onFormValidated(user: IUser): void {
    const fullName = this.userFullNameService.getFullUserName(user);
    user.nameDetails.alternateFullName = user.name = fullName;
    this.saving = true;
    this.organisationService
      .addUser(user, user.entityUid)
      .pipe(finalize(() => (this.saving = false)))
      .subscribe((addedUser: IUser) => {
        this.toastService.showToast(
          $localize`${addedUser.name} (${addedUser.userUid}) is successfully created`,
        );
        this.router.navigate([`/administration/users/${addedUser.userUid}`]);
      });
  }
}
