import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { SharedAuthAuthorizationModule } from '@portal/shared/auth/authorization/src';
import { SharedUiFilterModule } from '@portal/shared/ui/filter';
import { FormModule } from '@portal/shared/ui/form';

import { MarketplaceTagsCanvasComponent } from './canvas/marketplace-tags-canvas.component';
import { MarketplaceTagFormComponent } from './form/marketplace-tag-form.component';
import { MarketplaceTagComponent } from './tag/marketplace-tag.component';

@NgModule({
  imports: [
    CommonModule,
    FormModule,
    ReactiveFormsModule,
    SharedUiFilterModule,
    SharedAuthAuthorizationModule,
  ],
  declarations: [
    MarketplaceTagComponent,
    MarketplaceTagFormComponent,
    MarketplaceTagsCanvasComponent,
  ],
  exports: [MarketplaceTagComponent, MarketplaceTagFormComponent, MarketplaceTagsCanvasComponent],
})
export class MarketplaceTagModule {}
