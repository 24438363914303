import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordInputComponent } from './password-input.component';
import { PasswordValidationComponent } from './validation/password-validation.component';
import { CapsLockDetectionDirective } from './caps-lock-detection/caps-lock-detection.directive';
import { FormsModule } from '@angular/forms';
import { FormModule } from '../../../form/src/lib/form.module';

@NgModule({
  imports: [CommonModule, FormsModule, FormModule],
  declarations: [PasswordInputComponent, PasswordValidationComponent, CapsLockDetectionDirective],
  exports: [PasswordInputComponent, PasswordValidationComponent],
})
export class PasswordInputModule {}
