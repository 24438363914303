import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { makeCaseConverter } from '@portal/shared/helpers/src/lib/case-converter/case-converter';
import { CaseTypes } from '@portal/shared/helpers/src/lib/case-converter/enums/case.enum';

const predefinedValues = {
  'email-or-url': 'emailOrUrl',
};
const preserveValuesFor = [];

@Injectable({ providedIn: 'root' })
export class WhiteLabelingCaseConverter {
  readonly toSnakeCase = makeCaseConverter(CaseTypes.Snake, predefinedValues, preserveValuesFor);
  readonly toCamelCase = makeCaseConverter(CaseTypes.Camel, predefinedValues, preserveValuesFor);
  readonly toKebabCase = makeCaseConverter(CaseTypes.Kebab, {}, preserveValuesFor);

  httpParamsToKebabCase(params: HttpParams): HttpParams {
    const paramsAsObject = params.keys().reduce((other, key) => {
      other[key] = params.getAll(key);
      return other;
    }, {});
    return new HttpParams({ fromObject: this.toKebabCase(paramsAsObject) });
  }
}
