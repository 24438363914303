import { Component, ViewChild } from '@angular/core';
import { PaymentTypes } from '@portal/entity-services/payment-contracts/src/lib/components/form/payment-type.list';
import { SearchSelector } from '@portal/shared/ui/filter/src/lib/search/enums/search-selector.enum';
import { SearchOperator } from '@portal/shared/ui/filter/src/lib/search/enums/search-operator.enum';
import { FILTER_CONTENT_ITEM } from '@portal/shared/ui/filter/src/lib/content/token';
import { FilterAutoCompleteInputComponent } from '../../lib/autocomplete/search-autocomplete-input.component';
import { ISelectInput } from '../box/interfaces/select-input.interface';
import cloneDeep from 'lodash-es/cloneDeep';
import { FilterBase } from '../filter-base';
import { SupportedProcessorSettingsService } from '@apps/portal/src/app/services/supported-processor-settings.service';

declare const $localize;
@Component({
  selector: 'portal-shared-payment-multi-select-filter',
  templateUrl: './payment-type-multi-select.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: PaymentTypeMultiSelectComponent,
    },
  ],
})
export class PaymentTypeMultiSelectComponent extends FilterBase {
  @ViewChild('paymentSearchInput', { static: true })
  paymentSearchInput: FilterAutoCompleteInputComponent;
  labelSelectAllPaymentType: string;

  paymentTypesList: ISelectInput[];

  constructor(
    paymentTypes: PaymentTypes,
    private supportedProcessorSettingService: SupportedProcessorSettingsService,
  ) {
    super(SearchSelector.PaymentType.toString(), paymentTypes.getSupportedPayments());
    this.paymentTypesList = paymentTypes.list;
    this.labelSelectAllPaymentType = $localize`Select all Payment types`;
    this.supportedProcessorSettingService.isProcessorFetched$?.subscribe(() => {
      this.updateListEntity(paymentTypes.getSupportedPayments());
    });
  }

  onSearch(searchValue: string): void {
    this.entities = cloneDeep(this.paymentTypesList);
    if (!searchValue) {
      this.searchedText = null;
    } else {
      this.searchedText = searchValue;
      this.entities = this.entities.filter((entity: ISelectInput) => {
        return entity.text.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    this.updateEntities();
  }

  updateEntities(): void {
    this.showSelectedEntities = cloneDeep(this.localPreSelectedEntities);
    this.entities = this.entities.filter((entity: ISelectInput) => {
      return !this.localPreSelectedEntities.has(entity.id);
    });
  }

  onFilterApplied(): void {
    this.updateEntities();
    const values: string[] = this.getValues();
    if (values.length) {
      this.filterApplied.emit({
        key: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.localPreSelectedEntities.values()),
        argument: `(${Array.from(values).join(',')})`,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        value: Array.from(this.localPreSelectedEntities.values()),
      });
    } else {
      this.filterApplied.emit({
        key: this.searchSelector,
        toRemove: true,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        toRemove: true,
      });
    }
    this.itemToggled.emit();
    this.resetInputValue();
  }

  clear(): void {
    this.removeAllEntities();
    this.resetInputValue();
    this.isOpen = false;
  }

  removeAllEntities(): void {
    this.localPreSelectedEntities.clear();
    this.showSelectedEntities.clear();
  }

  resetInputValue(): void {
    this.paymentSearchInput.reset();
    this.onSearch('');
  }
}
