import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  AppStatus,
  IApp,
  IAppFile,
  IAppPromo,
  VaultStatus,
} from '@portal/marketplace/api-interfaces';
import { VfAppMarketAppCatalogService } from '@portal/marketplace/app-catalog/src/lib/state/vf-app-market-app-catalog/vf-app-market-app-catalog.service';

import { AuthenticationService } from '@portal/shared/auth/authentication';
import { VuiHttpService } from '@portal/shared/vui-http';
import { AppCatalogService } from '../providers/app-catalog.service';
import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';
import { UserService } from '@portal/shared/user/src';

@Component({
  selector: 'marketplace-app-page-template',
  templateUrl: './app-page-template.component.html',
  styleUrls: ['./app-page-template.component.scss'],
})
export class AppPageTemplateComponent implements OnInit {
  @Input() set app(currentApp: IApp) {
    if (currentApp) {
      this.papp = currentApp;
      this.selectedVersion = currentApp.id;
      this.promo = {
        name: currentApp.appName,
        description: currentApp.description,
        version: currentApp.version,
        imageUrl:
          currentApp.appIcons != null && currentApp.appIcons.length > 0
            ? currentApp.appIcons[0].publicUrl
            : '',
        categories: currentApp.categories,
        models: currentApp.modelFamilies,
      };

      this.getAppDefaultIconState();
    }
  }
  @Input() showBreadCrumbs = true;
  @Output() selectVersion: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteImageEvt: EventEmitter<IAppFile> = new EventEmitter<IAppFile>();
  @Output() filesDropped: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Input() isPublic: Boolean = false;
  @Input()
  set showInstallsIndicator(val: boolean) {
    this.pshowInstallsIndicator = val;
  }

  @Input()
  set showUpdatesIndicator(val: boolean) {
    this.pshowUpdatesIndicator = val;
  }

  get showUpdatesIndicator(): boolean {
    return this.pshowUpdatesIndicator;
  }

  get showInstallsIndicator(): boolean {
    return this.pshowInstallsIndicator;
  }

  get app(): IApp {
    return this.papp;
  }

  selectedVersion: string;
  promo: IAppPromo;
  appStatus = AppStatus;
  showDefaultAppIcon = false;

  private pshowInstallsIndicator: boolean;
  private pshowUpdatesIndicator: boolean;
  private papp: IApp;

  constructor(
    public appCatalogService: AppCatalogService,
    protected vfAppMarketAppCatalogService: VfAppMarketAppCatalogService,
    private authenticationService: AuthenticationService,
    private vuiHttpService: VuiHttpService,
    private router: Router,
    private userService: UserService,
  ) {}

  filesChanged(files: File[]): void {
    this.filesDropped.emit(files);
  }

  ngOnInit(): void {}

  deleteImage(image: IAppFile): void {
    this.deleteImageEvt.emit(image);
  }

  onSelectVersion(version: string): void {
    this.selectVersion.emit(version);
  }

  isFirstVersion(): boolean {
    if (this.app)
      return this.app.appVersions && this.app.appVersions[0]
        ? this.app.id === this.app.appVersions[0].id
        : true;
  }

  hasReleaseNotes(): boolean {
    if (this.app)
      return (
        this.app.releaseNotes && this.app.releaseNotes !== '' && this.app.releaseNotes !== null
      );
  }

  getE2e(file: IAppFile): string {
    return file.fileName.indexOf('uninstall') >= 0
      ? 'app-page-app-uninstall'
      : 'app-page-app-install';
  }

  downloadFile(path: string, fileName: string): void {
    const token = JSON.parse(this.authenticationService.jwtToken);
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    this.vuiHttpService.get(path, { headers, responseType: 'blob' }).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      if (fileName) downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    });
  }

  getStatus(app: IApp): VaultStatus | '' {
    return app && app.acquirers && app.acquirers.length && app.acquirers[0].vaultStatus
      ? app.acquirers[0].vaultStatus
      : '';
  }

  private getAppDefaultIconState(): boolean {
    let USER_TYPE: 'MERCHANT' | 'EO' = 'MERCHANT';
    if (
      this.userService?.roles?.filter((role) =>
        [
          UserRoles.EstateOwnerAdmin,
          UserRoles.EstateOwnerDeviceAppManager,
          UserRoles.EstateOwnerSupportAdmin,
        ].includes(role),
      ).length
    ) {
      USER_TYPE = 'EO';
    }
    if (USER_TYPE === 'EO') {
      if ((this.app?.acquirers && this.app?.acquirers[0]?.isDefault) || this.app.isDefault) {
        this.showDefaultAppIcon = true;
      } else {
        this.showDefaultAppIcon = false;
      }
    } else if (USER_TYPE === 'MERCHANT') {
      if (this.app.acquirerIsDefault === true) {
        this.showDefaultAppIcon = true;
      } else {
        this.showDefaultAppIcon = false;
      }
    }
    return this.showDefaultAppIcon;
  }
}
