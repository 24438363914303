export const BundleDeploymentStatusColorMapping = {
  INSTALL: {
    SUCCESS: { name: 'Installed ', color: 'has-background-success' },
    FAILED: { name: 'Installation Failed', color: 'has-background-danger' },
    PARTIAL: { name: 'Partially installed', color: 'has-background-warning' },
    PENDING: { name: 'Installation in Progress', color: 'has-background-warning' },
  },
  UNINSTALL: {
    FAILED: { name: 'Removal Failed', color: 'has-background-danger' },
    PARTIAL: { name: 'Partially Removed', color: 'has-background-warning' },
    PENDING: { name: 'Removal in progress', color: 'has-background-warning' },
  },
};
