<div [ngClass]="{ 'is-danger': isInvalid(control) }">
  <ng-select
    bindValue="entityUid"
    bindLabel="name"
    [multiple]="false"
    [closeOnSelect]="true"
    [formControl]="control"
    data-e2e="select-entityUid"
    [loading]="loading$ | async"
    [clearable]="!(loading$ | async) && !(searchInput$ | async)"
    (change)="onOrganisationSelected($event)"
    [typeahead]="setSearchInput$"
    [items]="organisations$ | async"
    [readonly]="isReadOnly"
    notFoundText="No items found"
    i18n-notFoundText
  >
    <ng-template ng-loadingspinner-tmp>
      <span class="icon loader"> <i class="fas fa-spinner"></i> </span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <span [attr.data-e2e]="'org-name-' + item.name.split(' ').join('-')">
        {{ item.name }}
      </span>
    </ng-template>
  </ng-select>
</div>
