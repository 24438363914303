import { Injectable } from '@angular/core';
import { ISelectInput } from '@portal/shared/ui/filter';
import { SelectInputsList } from '../create-list-from-inputs/select-input-list';
import { Continent } from './continent.enum';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class Continents extends SelectInputsList {
  keyValue = {
    [Continent.Asia]: $localize`Asia`,
    [Continent.Africa]: $localize`Africa`,
    [Continent.Europe]: $localize`Europe`,
    [Continent.Pacific]: $localize`Pacific`,
    [Continent.Oceania]: $localize`Oceania`,
    [Continent.Atlantic]: $localize`Atlantic`,
    [Continent.Antarctica]: $localize`Antarctica`,
    [Continent.NorthAmerica]: $localize`North America`,
    [Continent.SouthAmerica]: $localize`South America`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
