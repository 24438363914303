<label class="field">
  <div class="label" data-e2e="textControl-label">
    <span>{{ name }} <span *ngIf="hasRequiredValidator" class="has-text-danger">*</span></span>
  </div>
  <div class="control has-character-count">
    <textarea
      [formControl]="control"
      [attr.data-e2e]="e2eName"
      [ngClass]="{ 'is-danger': invalid, 'has-fixed-size': !resizable }"
      [placeholder]="placeholder"
      [rows]="rows"
      class="textarea"
    ></textarea>
    <portal-form-error [formInput]="control" [errorId]="name"></portal-form-error>
    <span
      [ngClass]="{ 'has-text-danger': invalid }"
      [ngStyle]="{'bottom' : invalid ? '20px' : '0'}"
      class="character-count"
      style="top: initial"
    >
      {{ control.value.length }}
      <span class="character-count__divider"> / </span>
      {{ maxLength }}
    </span>
  </div>
</label>
