import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class FileService {
  private renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private document: Document, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  convertFileToFormData(name: string, file: File): FormData {
    const formData = new FormData();

    formData.append(name, file, file.name);
    return formData;
  }

  isImage(file: File): boolean {
    if (!file) return false;

    return file.type.includes('image');
  }

  getFileExtension(file: File): string {
    const fileNameParts = file.name.split('.');
    return fileNameParts[fileNameParts.length - 1];
  }

  readAsText(file: File): Observable<string> {
    const reader = new FileReader();
    const onLoadSubject$ = new Subject<string>();

    reader.readAsText(file);
    reader.onload = () => onLoadSubject$.next(reader.result.toString());

    return onLoadSubject$.asObservable();
  }

  downloadBuffer(data: ArrayBuffer, fileName: string): void {
    const url = URL.createObjectURL(new Blob([data]));
    this.download(url, fileName);
    URL.revokeObjectURL(url);
  }

  downloadFile(blob: Blob, fileName: string): void {
    const url = URL.createObjectURL(blob);
    this.download(url, fileName);
    URL.revokeObjectURL(url);
  }

  download(url: string, fileName: string): void {
    const linkElement = this.renderer.createElement('a');
    this.renderer.addClass(linkElement, 'is-hidden');
    this.renderer.setAttribute(linkElement, 'href', url);
    this.renderer.setAttribute(linkElement, 'download', fileName);
    this.renderer.appendChild(this.document.body, linkElement);
    linkElement.click();
    this.renderer.removeChild(this.document.body, linkElement);
  }
}
