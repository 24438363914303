import { Component, Input } from '@angular/core';
import { Step } from '../../interfaces/step.interface';

@Component({
  selector: 'portal-steps-indicator',
  templateUrl: './steps-indicator.component.html',
})
export class StepsIndicatorComponent {
  @Input() steps: Step[];
}
