import { Injectable } from '@angular/core';
import { ConnectionMode } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class ConnectionModes extends SelectInputsList {
  keyValue = {
    [ConnectionMode.IP]: $localize`IP Address`,
    [ConnectionMode.X25PstnPad]: $localize`X25 PSTN PAD`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
