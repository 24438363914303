import { Injectable } from '@angular/core';
import { ToastNotification } from './toast-notification';
import { ToastNotifier } from './toast-notifier';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  duration: number;
  notifier = new ToastNotifier();
  initialDuration = 2500;

  constructor() {
    this.duration = this.initialDuration;
  }

  showToast(message: string): void {
    this.notify(message);
  }

  setDuration(duration: number): void {
    this.duration = duration;
  }

  protected notify(message: string): void {
    const notification: ToastNotification = new ToastNotification(message);

    this.notifier.add(notification);

    setTimeout(() => {
      this.notifier.destroy(notification);
    }, this.duration);
  }
}
