import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { IPagination } from '@portal/shared/ui/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'marketplace-cards-table-template',
  templateUrl: './cards-table-template.component.html',
  styleUrls: ['./cards-table-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardsTableTemplateComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() items: any;
  ngZoneSubscription: Subscription;
  resetPagination = false;

  totalCount: number;

  private _viewType: 'Cards' | 'Table' = 'Cards';

  get viewType(): 'Cards' | 'Table' {
    return this._viewType;
  }

  set viewType(val) {
    this._viewType = val;
  }

  constructor(private ngZone: NgZone, private cdref: ChangeDetectorRef) {
    cdref.detach();
    this.ngZoneSubscription = ngZone.onStable.subscribe(() => {
      this.cdref.detectChanges();
    });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.ngZoneSubscription.unsubscribe();
  }

  switchView(type): void {
    this.viewType = type;
  }

  afterPaginate(): void {}
  afterSorting(): void {}
  onPagination(pagination: IPagination): void {
    this.resetPagination = false;

    this.afterPaginate();
  }
}
