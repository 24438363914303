import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DEFAULT_PAGE_SIZE, IBundle } from '@portal/marketplace/api-interfaces';
import { IQueryParams, ISort, ITableConfig, SortOrder } from '@portal/shared/ui/table';
import { Observable } from 'rxjs';

@Component({
  selector: 'marketplace-bundle-table-template',
  templateUrl: './bundle-table-template.component.html',
  styleUrls: ['./bundle-table-template.component.scss'],
})
export class BundleTableTemplateComponent implements OnInit {
  @ViewChild('onetimeCell', { static: true }) onetimeCellTemplate;
  @ViewChild('recurringCell', { static: true }) recurringCellTemplate;
  @Input() isMyBundles: Boolean = false;
  @Input() rowLinkHref: string = '/app-catalog/bundles/bundle';
  @Input() bundles: IBundle[];
  @Input() loading$: Observable<boolean>;
  @Input() filter: IQueryParams = {
    start: 0,
    limit: DEFAULT_PAGE_SIZE,
    sort: {
      key: 'bundleName',
      order: SortOrder.Asc,
    },
  };
  @Input() tableConfig: ITableConfig = {
    columns: [
      { key: 'bundleName', label: 'Name', isSortable: true },
      { key: 'bundleDesc', label: 'Description' },
      {
        key: 'oneTimePrice',
        label: 'One Time Price',
        templateRef: () => this.onetimeCellTemplate,
        isTemplateRef: true,
      },
      {
        key: 'recurringPrice',
        label: 'Monthly Price',
        templateRef: () => this.recurringCellTemplate,
        isTemplateRef: true,
      },
      { key: 'status', label: 'Status' },
      { key: 'bundleVersionName', label: 'Version' },
    ],
    rowIdKey: 'appmarketBundleId',
    rowLabelKey: 'name',
  };
  @Output() sortChanged: EventEmitter<any> = new EventEmitter();

  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.rowLinkHref =
        data.portal === 'estate-owner' ? '/bundles/bundle' : this.rowLinkHref;
    });
  }

  onSortChanged(sort: ISort): void {
    this.sortChanged.emit(sort);
  }
}
