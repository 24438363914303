import environment from '@environments';
import { FiltersList, FilterTypes } from '@portal/shared/ui/filter';
import { ITableConfig } from '@portal/shared/ui/table';
import { GET_FILTER_CONFIG_APP_SUMMARY, GET_TABLE_CONFIG_APP_SUMMARY } from './shared-configs';

export const APPLICATION_SUMMARY_TABLE_CONFIG = (role): ITableConfig => {
  return GET_TABLE_CONFIG_APP_SUMMARY(role, TABLE_CONFIG);
};

export const APPLICATION_SUMMARY_FILTER_CONFIG = (role): FiltersList[] => {
  return GET_FILTER_CONFIG_APP_SUMMARY(role, FILTER_CONFIG);
};

const TABLE_CONFIG = {
  columns: [
    { key: 'sub_merchant_name', label: 'Sub Merchant', isSortable: true },
    { key: 'alt_vfi_poi_id', label: 'Terminal ID', isSortable: true },
    { key: 'app_name', label: 'Name', isSortable: true },
    { key: 'assignment_date', label: 'Assignment date', isSortable: true },
    { key: 'app_version', label: 'Version', isSortable: true },
  ],
  rowIdKey: 'entityUid',
  rowLabelKey: 'name',
};

const FILTER_CONFIG: FiltersList[] = [
  {
    selector: 'search',
    title: 'Deploy Value',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'Application Name',
        id: 'app_name',
      },
      {
        text: 'Terminal ID',
        id: 'alt_vfi_poi_id',
      },
    ],
  },
  {
    selector: 'assignment_date',
    title: 'Time Created',
    type: FilterTypes.DatePicker,
    inLocalTime: true,
  },
];

export const ReportsFilterListConfig = (templates, options, service$, role): FiltersList[] => {
  const merchantFilter = {
      selector: 'merchant_entity_uid',
      title: 'Merchant Name',
      type: FilterTypes.TreeFilter,
      placeholder: 'Search',
      service$,
      templates,
      options,
    };


  let filterConfig = FILTER_CONFIG;
  filterConfig = [merchantFilter, ...FILTER_CONFIG];
  return filterConfig;
};

export const APPLICATION_SUMMARY_CONFIG = {
  report_id: environment.REPORTS['application-summary'],
  table: APPLICATION_SUMMARY_TABLE_CONFIG,
  filters: APPLICATION_SUMMARY_FILTER_CONFIG,
};