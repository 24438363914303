import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IApp, PaymentModel } from '@portal/marketplace/api-interfaces';
import { Observable } from 'rxjs';
import { PublicAppCatalogService } from '../state/public-app-catalog.service';
import { ITableConfig } from '@portal/shared/ui/table';
import { FileLoader } from '@portal/shared/helpers';
import { DomSanitizer } from '@angular/platform-browser';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'marketplace-public-main',
  templateUrl: './public-main.component.html',
  styleUrls: ['./public-main.component.scss'],
})
export class PublicMainComponent implements OnInit, AfterViewInit {
  @ViewChild('cellIconTemplate') cellIconTemplate: TemplateRef<any>;
  appCatalogUiData$: Observable<IApp[]>;
  isLoading$ = true;
  tableConfig: ITableConfig = {
    columns: [],
    rowIdKey: '',
    rowLabelKey: '',
  };

  constructor(
    private publicAppCatalogService: PublicAppCatalogService,
    private fileLoader: FileLoader,
    private sanitizer: DomSanitizer,
    private currencyPipe: CurrencyPipe,

    private router: Router,
  ) {}

  ngOnInit(): void {
    this.publicAppCatalogService.loadPblicAppCatalog();
    this.appCatalogUiData$ = this.publicAppCatalogService.getApps();
    this.appCatalogUiData$.subscribe((data) => {
      this.isLoading$ = false;
    });
  }

  ngAfterViewInit(): void {
    this.tableConfig = {
      columns: [
        {
          key: 'appIcons[0].publicUrl',
          label: '',
          isTemplateRef: true,
          templateRef: () => this.cellIconTemplate,
        },
        { key: 'appName', label: 'App Name' },
        { key: 'shortDescription', label: 'Description' },
        {
          key: 'price',
          label: 'Price',
          isRenderFunction: true,
          renderFunction: (app: IApp) => {
            const price = this.currencyPipe.transform(app.price, '$');
            return this.sanitizer.bypassSecurityTrustHtml(
              app.price === 0
                ? `<span data-e2e="price">Free</span>`
                : `<span data-e2e="price">${price}</span>`,
            );
          },
        },
        {
          key: '',
          label: 'Payment Model',
          isRenderFunction: true,
          renderFunction: (app: IApp) => {
            if (app.paymentModel === PaymentModel.FREE)
              return this.sanitizer.bypassSecurityTrustHtml(
                `<span data-e2e="payment-model"> Free </span>`,
              );
            else if (app.paymentModel === PaymentModel.ONETIME)
              return this.sanitizer.bypassSecurityTrustHtml(
                `<span data-e2e="payment-model"> One Time </span>`,
              );
            else if (app.paymentModel === PaymentModel.RECURRING)
              return this.sanitizer.bypassSecurityTrustHtml(
                `<span data-e2e="payment-model"> Monthly </span>`,
              );
          },
        },
        { key: 'version', label: 'Version' },
      ],
      rowIdKey: 'appmarketAppId',
      rowLabelKey: 'name',
    };
  }
  goToAppPage(app): void {
    this.router.navigate(['/app', app.appmarketAppId]);
  }
}
