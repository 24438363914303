import { formatNumber } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Inject, LOCALE_ID } from '@angular/core';
import { code } from 'currency-codes';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'localeCurrencyDecimals',
})
export class LocaleCurrencyDecimals implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
  transform(value: string | number, currencyCode = ''): string {
    const { digits = 2 } = code(currencyCode) || {};
    let amount = Number.isNaN(Number(value)) ? 0 : Number(value);
    if (digits === 0) {
      amount = Math.floor(amount);
    }
    return formatNumber(amount, this.locale, `1.${digits}`);
  }
}
