import { Injectable } from '@angular/core';
import { IApp } from '@portal/marketplace/api-interfaces/src';
import { DevAppsTransformerService } from '@portal/marketplace/api-tranformers/src';
import { AllAppsCatalogDataService } from '@portal/marketplace/app-catalog/src/lib/state/all-apps-catalog/all-apps-catalog-data.service';
import { AllAppsCatalogStore } from '@portal/marketplace/app-catalog/src/lib/state/all-apps-catalog/all-apps-catalog.store';
import { SortVersionsPipe } from '@portal/shared/pipes';

@Injectable({
  providedIn: 'root',
})
export class AllAppsCatalogService {
  constructor(
    private appsTransformerService: DevAppsTransformerService,
    private allAppsCatalogDataService: AllAppsCatalogDataService,
    private allAppsCatalogStore: AllAppsCatalogStore,
    private sortVersions: SortVersionsPipe,
  ) {}

  getAppById(versionId: string): void {
    this.allAppsCatalogDataService.getAppById(versionId).subscribe((response) => {
      if (response && response.data) {
        this.getAllVersions(
          this.appsTransformerService.transformIDevAppToIApp(response.data)[0],
          false,
        );
      }
    });
  }

  getAllVersions(app: IApp, isUpdate: boolean): void {
    this.allAppsCatalogDataService.getAllVersions(app.app.id).subscribe((versions) => {
      if (versions && versions.data) {
        if (isUpdate) {
          const appUpdated = {
            id: app.id,
            appVersions: this.sortVersions.transform(versions.data[0].appVersions),
          };
          this.allAppsCatalogStore.update(app.id, appUpdated);
        } else {
          const appUpdated = app;
          app.appVersions = this.sortVersions.transform(versions.data[0].appVersions);
          this.allAppsCatalogStore.add(appUpdated);
        }
        this.allAppsCatalogStore.setLoading(false);
      }
    });
  }
}
