<label class="radio">
  <input
    type="radio"
    [value]="id"
    (click)="onRadioSelected(id)"
    [name]="radioName"
    [(ngModel)]="selectedEntity.id"
    [disabled]="disabled"
    data-e2e="input-radio"
  />
  <span *ngIf="text; else contentText">{{ text }}</span>

  <ng-template #contentText>
    <ng-content></ng-content>
  </ng-template>
</label>
