import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@portal/shared/pipes';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { AppIconUploadComponent } from './app-icon-upload.component';

@NgModule({
  imports: [CommonModule, FileUploadModule, PipesModule],
  exports: [AppIconUploadComponent],
  declarations: [AppIconUploadComponent],
})
export class AppIconUploadModule {}
