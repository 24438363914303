import { Pipe, PipeTransform } from '@angular/core';
import { BlockingRulesetOperators } from '../../../modules/blocking-rules/enums/blocking-rulesets-operators.enum';

declare const $localize;
@Pipe({ name: 'operatorsWords' })
export class OperatorsWordsPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    const valuesMap = {
      [BlockingRulesetOperators.LessThan]: $localize`is less than`,
      [BlockingRulesetOperators.LessThanAndEqualTo]: $localize`is less than or equal to`,
      [BlockingRulesetOperators.Equal]: $localize`is equal to`,
      [BlockingRulesetOperators.NotEqual]: $localize`is not equal to`,
      [BlockingRulesetOperators.GreaterThanAndEqualTo]: $localize`is greater than or equal to`,
      [BlockingRulesetOperators.Greater]: $localize`is greater than`,
    };

    return valuesMap[value] || value;
  }
}
