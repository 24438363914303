import { Component } from '@angular/core';
import { FilterStaticBase } from '@portal/shared/ui/filter/src/lib/components/static-base';
import {
  FILTER_CONTENT_ITEM,
  ISelectInput,
  SearchOperator,
  SearchSelector,
} from '@portal/shared/ui/filter/src';
import { TransactionReportTypesNew } from '../../../list/transaction-report-type-new.list';

@Component({
  selector: 'portal-filter-transaction-report-type',
  templateUrl: './transaction-report-type.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: FilterTransactionReportTypeComponent,
    },
  ],
})
export class FilterTransactionReportTypeComponent extends FilterStaticBase {
  txnReportTypeList: ISelectInput[];
  serviceProviderText = $localize`Report types`;

  constructor(txnReportTypes: TransactionReportTypesNew) {
    super(SearchSelector.ReportType, txnReportTypes.list);
    this.txnReportTypeList = txnReportTypes.list;
  }

  onEntityRemoved(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.delete(selectedEntity.id);
  }

  onEntityAdded(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.set(selectedEntity.id, selectedEntity);
  }

  onFilterApplied(): void {
    this.updateEntities();
    const values: string[] = this.getValues();

    this.filterApplied.emit({
      key: this.searchSelector,
      operator: SearchOperator.In,
      value: Array.from(this.localPreSelectedEntities.values()),
      argument: `(${Array.from(values).join(',')})`,
    });

    this.updateText();
    this.itemToggled.emit();
  }

  removeAllEntities(): void {
    this.localPreSelectedEntities.clear();
    this.updateText();
  }

  updateText(): void {
    const values: string[] = this.getValues();
    const text = $localize`Report types`;
    if (values.length) {
      this.serviceProviderText = `${text} (+${values.length})`;
    } else {
      this.serviceProviderText = text;
    }
  }
}
