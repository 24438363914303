<div class="columns is-vcentered">
  <div class="column is-half">
    <h1 class="title is-2" data-e2e="title" i18n="Page title">Organizations</h1>
  </div>
  <div class="column has-text-right">
    <a
      #createButton
      class="button is-primary"
      restrict-permission
      [routerLink]="['/administration', 'organisations', 'create']"
      data-e2e="link-new"
      i18n
      title="Add new organisation"
      i18n-title
    >
      Add new organisation
    </a>
  </div>
</div>

<portal-filter-organisation
  [filter]="filter"
  [filterTouched]="filterTouched"
  (orgsFiltered)="onOrgFiltered($event)"
  (orgsFilterReset)="onOrgFilterReset()"
  (orgsFilterChanged)="onOrgFiltersChanged($event)"
>
</portal-filter-organisation>

<p *ngIf="range" class="has-margin-bottom" i18n>{{ range }} of {{ totalCount }} organisations</p>

<portal-table
  [items]="organisations"
  [itemsClickable]="canGoToDetail"
  [loading$]="loading$"
  [filter]="filter"
  [tableConfig]="tableConfig"
  rowLinkHref="/administration/organisations"
  rowLinkHeading="Link to organisation details"
  i18n-rowLinkHeading="Column header for screen readers only"
  loadingMessage="Loading organisations"
  i18n-loadingMessage
  notFoundMessage="No organisations found"
  i18n-notFoundMessage
  (sortChanged)="onSortChanged($event)"
></portal-table>

<portal-pagination
  [reset]="resetPagination"
  [itemsPerPage]="filter.limit"
  [totalItems]="totalCount"
  (paginationChanged)="onPagination($event)"
></portal-pagination>
