import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Key } from '../enums/key';

@Directive({
  selector: '[portalOnKeyPress]',
})
export class OnKeyPressDirective implements OnChanges, OnDestroy {
  @Output() portalOnKeyPress = new EventEmitter<void>();

  @Input() keyCode: Key;
  @Input() isActive = false;

  private unlistenDocumentKeyPress = () => {};

  constructor(private renderer2: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive) {
      this.toggleEvent();
    }
  }

  private toggleEvent(): void {
    if (this.isActive) {
      this.attachDocumentKeyPress();
    } else {
      this.unlistenDocumentKeyPress();
    }
  }

  private attachDocumentKeyPress(): void {
    this.unlistenDocumentKeyPress = this.renderer2.listen('document', 'keyup', ({ keyCode }) => {
      if (keyCode === this.keyCode) {
        this.portalOnKeyPress.emit();
      }
    });
  }

  ngOnDestroy(): void {
    this.unlistenDocumentKeyPress();
  }
}
