import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MultilingualValidators } from '@portal/shared/ui/form/src/lib/error/custom-error.validator';
import { isInputTruthyValue } from '@portal/shared/helpers/src/lib/is-input-truthy-value/is-input-truthy-value';

declare const $localize;

@Component({
  selector: 'portal-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() label = $localize`Password`;
  @Input() name?: string;
  @Input() isInvalid?: boolean;
  @Input() isPasswordValid: boolean;
  touched: boolean;
  password: string;
  isPasswordVisible: boolean;
  isCapsLockOn: boolean;
  isDisabled: boolean;
  focused: boolean;

  onChange: (password?: string) => void;
  onTouched: () => void;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _noValidation = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _inlineValidation = false;

  constructor(
    @Optional() @Self() private ngControl: NgControl,
    private multilingualValidators: MultilingualValidators,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input()
  get inlineValidation(): string | boolean {
    return this._inlineValidation;
  }

  set inlineValidation(value: string | boolean) {
    this._inlineValidation = isInputTruthyValue(value);
  }

  @Input()
  get noValidation(): string | boolean {
    return this._noValidation;
  }

  set noValidation(value: string | boolean) {
    this._noValidation = isInputTruthyValue(value);
  }

  ngOnInit(): void {
    const validators = [this.validate.bind(this)];
    if (this.control) {
      if (this.control.validator) {
        validators.push(this.control?.validator);
      }
      this.control.setValidators(validators);
      this.control.updateValueAndValidity();
    }
  }

  get control(): AbstractControl {
    return this.ngControl?.control;
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  onPasswordType(password: string): void {
    this.password = password;
    this.touched = true;
    this.onChange(password);
    this.onTouched();
  }

  toggleCapslock(state: boolean): void {
    this.isCapsLockOn = state;
  }

  writeValue(password?: string): void {
    this.password = password;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  /***
   * This method is used to validate the current value
   * of the form control. This method will be called
   * whenever a new value is reported to the parent form.
   * The method needs to return null if no errors are found,
   * or an error object containing all the details needed to
   * correctly display a meaningful error message to the user.
   * @param control
   */
  validate(control: AbstractControl): ValidationErrors | null {
    return this._noValidation ? null : this.multilingualValidators.password(control);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
