import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ITag, TTagType } from '@portal/marketplace/api-interfaces';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { TagsService } from '../state/tags.service';

@Component({
  selector: 'marketplace-tags-canvas',
  templateUrl: './marketplace-tags-canvas.component.html',
  styleUrls: ['./marketplace-tags-canvas.component.scss'],
})
export class MarketplaceTagsCanvasComponent implements OnInit, OnDestroy {
  private _tags: ITag[] = [];
  @Input('tags')
  set tags(tags: ITag[]) {
    this._tags = tags || [];
  }
  get tags(): ITag[] {
    return this._tags || [];
  }

  @Input() type: TTagType = 'MERCHANT_DEVICE';

  isDropdownOpen = false;

  @Output() tagAction = new EventEmitter();
  tagActionSub: Subscription;

  @Output() tagFilterAction = new EventEmitter();

  search$: Observable<ITag[]>;
  searchTerm$ = new Subject<string>();
  searchSub: Subscription;
  searchTags: ITag[] = [];
  searchTagsAll: ITag[] = [];

  constructor(public tagsService: TagsService) {}

  @HostListener('click', ['$event'])
  onClick(): void {
    if (this.isDropdownOpen) {
      this.toggleDropdown();
      this.updateSearchResults();
      this.onSearch('');
    }
  }

  ngOnInit(): void {
    this.search$ = this.search(this.searchTerm$);
    this.searchSub = this.search$
      .pipe(
        map((data) => {
          const filtered = data.filter((tag) => !this.tags.find((item) => item.id === tag.id));
          this.searchTagsAll = data;
          this.searchTags = filtered;
          return filtered;
        }),
      )
      .subscribe();

    if (this.isTagFilerAction()) {
      this.tagActionSub = this.tagAction.subscribe((tag) => {
        this._tags = this._tags.filter((item) => item.id !== tag.id);
        this.tagFilterAction.emit(this.tags);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
    if (this.tagActionSub) {
      this.tagActionSub.unsubscribe();
    }
  }

  onTagAction(tag: ITag): void {
    this.tagAction.emit(tag);
  }

  selectAction(): void {
    this.tagFilterAction.emit();
  }

  isAction(): boolean {
    return this.tagAction.observers.length > 0;
  }

  isTagFilerAction(): boolean {
    return this.tagFilterAction.observers.length > 0;
  }

  onSearch(searchValue = ''): void {
    this.searchTerm$.next(searchValue);
  }

  onSearchFieldClick(event): void {
    event.stopPropagation();
  }

  search(terms: Observable<string>): Observable<ITag[]> {
    return terms.pipe(debounceTime(1000), distinctUntilChanged()).pipe(
      switchMap((term: string) => {
        if (term) {
          return this.tagsService.searchForTagByName(this.type, term);
        } else {
          return of([]);
        }
      }),
    );
  }

  toggleDropdown(event?): void {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectTag(data: ITag): void {
    this._tags.push(data);
    this.updateSearchResults();
    this.tagFilterAction.emit(this.tags);
  }

  clearTags(): void {
    this._tags = [];
    this.tagFilterAction.emit(this.tags);
  }

  updateSearchResults(): void {
    this.searchTags = this.searchTagsAll.filter(
      (tag) => !this.tags.find((item) => item.id === tag.id),
    );
  }

  NOP(): void {}
}
