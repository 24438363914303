import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'portal-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input() title: string;
  @Input() isActive = false;
  @Output() toggled = new EventEmitter<boolean>();

  @ViewChild('accordionHeader') accordionHeader: ElementRef<HTMLDivElement>;

  constructor() {}

  toggleAccordion(): void {
    this.isActive = !this.isActive;
    this.toggled.emit(this.isActive);
  }
}
