/* 
index
-------------
eq : like
ne : not like
cn : %like%
nc : %not like%
bn : not like%
ew=%like
en=%not like
lt=<
le=<=
gt=>
ge=>=
nu=is null
un=is not null
in=in
*/

export enum IOperators {
  eq = 'eq',
  ne = 'ne',
  cn = 'cn',
  nc = 'nc',
  bw = 'bw',
  bn = 'bn',
  ew = 'ew',
  en = 'en',
  lt = 'lt',
  le = 'le',
  gt = 'gt',
  ge = 'ge',
  nu = 'nu',
  un = 'un',
  in = 'in',
}
