import { Injectable } from '@angular/core';
import { TokenType } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers';
import { ISelectInput } from '@portal/shared/ui/filter';
import { sortBy } from 'lodash';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class TokenTypes extends SelectInputsList {
  keyValue = {
    [TokenType.Analytics]: $localize`:token type|:Analytics`,
    [TokenType.Reuse]: $localize`:token type|:Re-use`,
    [TokenType.ReuseAndAnalytics]: $localize`:token type|:Re-use + analytics`,
  };

  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);
}
