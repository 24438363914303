import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { QueryParams } from '@ngrx/data';
import {
  EntityStatus,
  ES_CONSTANTS as CONSTANTS,
  IDevice,
  IPaymentContract,
  IPoiCutoverConfiguration,
  IPointInteraction,
} from '@portal/entity-services/interfaces';
import { SearchSelector } from '@portal/shared/ui/filter/src/lib/search/enums/search-selector.enum';
import { IQueryParams, IResultsWithCount, QueryParamsService } from '@portal/shared/ui/table';
import { ICount, VuiHttpService } from '@portal/shared/vui-http';
import omit from 'lodash-es/omit';
import assign from 'lodash-es/assign';
import { forkJoin, iif, of, BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { InteractionTypes } from '../components/form/interaction-type.list';
import { CustomPointInteractionDataService } from './point-interaction-data.service';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class PointInteractionService {
  deleteSerialNumber$ = new Subject();
  loading$: Observable<boolean>;

  private setLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private customDataService: CustomPointInteractionDataService,
    private httpService: VuiHttpService,
    private interactionTypes: InteractionTypes,
  ) {
    this.loading$ = this.setLoading$.asObservable();
  }

  serialNumberAsyncValidator(poiUid: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return iif(
        () => control.value,
        this.getPoiBySerialNumber(control.value).pipe(
          catchError(() => of([])),
          map((poi: IPointInteraction[]) => {
            return poi.length > 0 && poi[0].poiUid !== poiUid
              ? {
                  serialNumberInvalid: {
                    message: '@@SERIAL_NUMBER_INVALID',
                    displayMessage: $localize`This device serial number already exists.`,
                  },
                }
              : null;
          }),
        ),
        of(null),
      );
    };
  }

  setLoading(state: boolean): void {
    this.setLoading$.next(state);
  }

  getByKey(id: string): Observable<IPointInteraction> {
    this.setLoading(true);
    return forkJoin([
      this.customDataService.getByKeyWithQuery(id, { populateEntity: 'true' }),
      this.getPhysicalDevicePoi(id),
    ]).pipe(
      map(([poi, device]: [IPointInteraction, IDevice]) => {
        poi = this.formatPoi(poi);
        poi.serialNumber = device.serialNumber;
        poi.printerFormat = device.printerFormat;
        this.setLoading(false);
        return poi;
      }),
      catchError((error) => {
        this.setLoading(false);
        throw error;
      }),
    );
  }

  getPoiByID(id: string): Observable<IPointInteraction> {
    return this.customDataService.getByKeyWithQuery(id, {});
  }

  getWithQuery(params: string | QueryParams): Observable<IPointInteraction[]> {
    return this.customDataService.getWithQuery(params).pipe(
      map((pois) => pois.map((poi) => this.formatPoi(poi))),
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  getTotalCount(params?: QueryParams): Observable<ICount> {
    return this.customDataService.getTotalCount(params);
  }

  getPaymentContracts(id: string): Observable<IPaymentContract[]> {
    return this.customDataService.getPaymentContracts(id).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  delete(poiUid: string, serialNumber?: string): Observable<any> {
    if (serialNumber) {
      return this.removePhysicalDevicePoi(poiUid).pipe(
        switchMap(() => this.customDataService.delete(poiUid)),
      );
    }

    return this.customDataService.delete(poiUid);
  }

  addPhysicalDevicePoi(device: IDevice, poiUid: string): Observable<IPointInteraction> {
    //if the device does not exist we get and error thereby is necessary to catch the error and create the device
    return this.getPhysicalDevice(device.serialNumber).pipe(
      catchError(() => of({ status: null })),
      switchMap((info: IDevice) =>
        iif(() => info.status === EntityStatus.Active, of(info), this.addPhysicalDevice(device)),
      ),
      switchMap(() => this.customDataService.addPhysicalDevicePoi(device, poiUid)),
    );
  }

  addPhysicalDevice(device: IDevice): Observable<IDevice> {
    return this.customDataService.addPhysicalDevice(device);
  }

  updatePhysicalDevice(device: IDevice): Observable<IDevice> {
    return this.customDataService.updatePhysicalDevice(device);
  }

  getPhysicalDevice(serialNumber: string): Observable<IDevice> {
    return this.customDataService.getPhysicalDevice(serialNumber);
  }

  removePhysicalDevicePoi(poiUid: string): Observable<IPointInteraction> {
    return this.customDataService.removePhysicalDevicePoi(poiUid);
  }

  getPhysicalDevicePoi(poiUid: string): Observable<IDevice> {
    //if the Poi has no device the endpoint throws an error,
    //due to it, it's necessary to catch the error and return an empty serialNumber
    return this.customDataService
      .getPhysicalDevicePoi(poiUid)
      .pipe(catchError(() => of({ serialNumber: '' })));
  }

  getPoiBySerialNumber(serialNumber: string): Observable<IPointInteraction[]> {
    return this.customDataService.getPoiBySerialNumber(serialNumber);
  }

  update(poi: IPointInteraction): Observable<any> {
    return this.httpService
      .patch(`${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION}${poi.poiUid}`, poi)
      .pipe(
        map((response: any) => {
          if (!response) {
            return null;
          }
          return response;
        }),
        finalize(() => {
          this.setLoading(false);
        }),
      );
  }

  getResultsWithCount(params: IQueryParams): Observable<IResultsWithCount<IPointInteraction>> {
    const queryParams = { ...params };
    const searchCriteria = QueryParamsService.getFilterParams(queryParams.searchCriteria);
    const poiSearchCriteria = this.getPOISearchCriteria(searchCriteria);
    const filterParams = omit(assign(queryParams, poiSearchCriteria), ['searchCriteria']);
    const poisCount$: Observable<ICount> = this.getTotalCount(filterParams);
    let pois$: Observable<IPointInteraction[]>;

    if (!!filterParams.serialNumber) {
      pois$ = this.getPoiBySerialNumber(filterParams.serialNumber).pipe(catchError(() => of([])));
    } else {
      pois$ = this.customDataService.getWithQuery(filterParams);
    }

    this.setLoading(true);
    return forkJoin([pois$, poisCount$]).pipe(
      map(([pois, poisCount]) => {
        const poiList = this.formatPoiList(pois);
        return {
          results: poiList,
          count: poisCount.count,
        };
      }),
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  enable(id: string): Observable<IPointInteraction> {
    return this.customDataService.disableEnablePointInteraction(id, EntityStatus.Active.toString());
  }

  disable(id: string): Observable<IPointInteraction> {
    return this.customDataService.disableEnablePointInteraction(
      id,
      EntityStatus.Inactive.toString(),
    );
  }

  formatPoi(poi: IPointInteraction): IPointInteraction {
    poi.formattedType = this.interactionTypes.keyValue[poi.type] || poi.formattedType;
    if (poi.status) {
      poi.status = `${poi.status.toString().charAt(0).toUpperCase()}${poi.status
        .toString()
        .slice(1)
        .toLowerCase()}` as EntityStatus;
    }
    return poi;
  }

  getPoiCutoverConfigurations(
    poiCutoverStrategy: string = '',
  ): Observable<IPoiCutoverConfiguration[]> {
    return this.customDataService.getPoiCutoverConfigurations(poiCutoverStrategy);
  }

  protected formatPoiList(poiList: IPointInteraction[]): IPointInteraction[] {
    return poiList.map((poi: IPointInteraction) => this.formatPoi(poi));
  }

  private getPOISearchCriteria(queryParams: QueryParams): QueryParams {
    const newFilterParams = {};
    for (const param in queryParams) {
      newFilterParams[param === SearchSelector.EntityId ? SearchSelector.ParentIds : param] =
        queryParams[param].toString().replace(/[()]/g, '');
    }
    return newFilterParams;
  }
}
