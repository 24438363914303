import { Injectable } from '@angular/core';
import { Notification } from './notification';
import { NotificationType } from './notification-type.enum';
import { Notifier } from './notifier';

declare const $localize;

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  duration: number;
  unknownErrorMessage = $localize`Something went wrong!`;
  notifier = new Notifier();
  initialDuration = 8000;
  private notification: Notification;

  constructor() {
    this.duration = this.initialDuration;
  }

  error(message: string, isWithDuration = true): void {
    this.notify(message, isWithDuration, NotificationType.Fail);
  }

  success(message: string, isWithDuration: Boolean = true): void {
    this.notify(message, isWithDuration, NotificationType.Success);
  }

  info(message: string, isWithDuration: Boolean = true): void {
    this.notify(message, isWithDuration, NotificationType.Info);
  }

  warn(message: string, isWithDuration: Boolean = true): void {
    this.notify(message, isWithDuration, NotificationType.Warn);
  }

  setDuration(duration: number): void {
    this.duration = duration;
  }

  destroyNotification(): void {
    this.notifier.destroy(this.notification);
  }

  protected notify(
    message: string,
    isWithDuration: Boolean,
    notificationType: NotificationType,
  ): void {
    const notification: Notification = new Notification(
      message || this.unknownErrorMessage,
      notificationType,
    );

    this.notification = notification;
    this.notifier.add(notification);

    if (isWithDuration) {
      setTimeout(() => {
        this.notifier.destroy(notification);
      }, this.duration);
    }
  }
}
