/* eslint-disable no-unused-expressions */
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import isEmpty from 'lodash-es/isEmpty';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { BaseModalComponent } from '@portal/shared/ui/modal';
import { TemplateParameters } from '@portal/entity-services/interfaces';
import { TypeDefinitionHelper } from '@portal/shared/helpers';
import { ToastService } from '@portal/shared/ui/toast';
import { ErrorService, FormBase, MultilingualValidators } from '@portal/shared/ui/form';
import { TemplateParametersService } from '../../../services/template-parameters.service';
import { Pages } from '../../../enums/pages.enum';
import { FormSharedService } from '../form-shared.service';
import { AuthorizationService } from '@portal/shared/auth/authorization';
import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';
import { RestrictPermissionService } from '@portal/shared/auth/authorization/src/lib/services/restrict-permission.service';
import { organisationsPermissions } from '../../../routing/route-permissions.const';

declare const $localize;

@UntilDestroy()
@Component({
  selector: 'portal-reseller-details',
  templateUrl: './reseller-details.component.html',
})
export class ResellerDetailsComponent
  extends FormBase<TemplateParameters>
  implements OnInit, OnChanges
{
  @Input() entityUid: string;
  @Input() templateParametersForm: FormGroup;
  @Input() templateParameters: TemplateParameters;
  @Input() emailDomainNameControl: FormControl;
  @Input() domainNameControl: FormControl;
  @Input() loginDomainNameControl: FormControl;

  @ViewChild('removeResellerDetails') removeResellerDetails: BaseModalComponent;

  isInvalid = ErrorService.isFieldInvalid;

  currentPage: Pages;
  pages = Pages;
  isCustomStylesAvailable = false;
  isTemplateParamsEmpty = true;
  canEditWhiteLabel: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private typeDefinitionHelper: TypeDefinitionHelper,
    private formSharedService: FormSharedService,
    private templateParametersService: TemplateParametersService,
    private authorizationService: AuthorizationService,
    private restrictPermissionService: RestrictPermissionService,
    customValidators: MultilingualValidators,
  ) {
    super(
      fb.group({
        helpPage: fb.array(
          [
            fb.group({
              country: [''],
              emailOrUrl: [''],
              notes: [''],
            }),
          ],
          [],
        ),
        text: fb.group({
          loginDomainName: [''],
          brandText: [''],
          privacyPolicyUrl: ['', [customValidators.url]],
        }),
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.templateParameters && this.templateParameters) {
      this.updateFormStructure(this.templateParameters);
      this.form.patchValue(this.templateParameters);
      this.updateEmptyState();
      this.isTemplateParamsEmpty = this.templateParametersService.isTemplateParamsExist(
        this.templateParameters,
      );
    }

    if (changes.disabled) {
      this.checkAvailabilityOfFields();
    }
  }

  ngOnInit(): void {
    this.checkWhiteLabelPermissions();

    this.parentForm?.setControl(
      'templateParameters',
      this.fb.group({
        ...this.templateParametersForm.controls,
        ...this.form.controls,
      }),
    );

    this.templateParametersService.setInitialParameters(this.templateParameters);

    this.parentForm?.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateEmptyState();
    });

    this.setCurrentPageName();

    this.domainNameControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((domainNameValue) => {
        this.checkAvailabilityOfFields(!Boolean(domainNameValue));
      });
  }

  addHelpPageItem(): void {
    this.helpPage.push(
      this.fb.group({
        country: [''],
        emailOrUrl: [''],
        notes: [''],
      }),
    );
  }

  setStateOrgFormValues(): void {
    this.formSharedService.setOrgFormValueState(this.parentForm.getRawValue());
  }

  onRemoveResellerDetails(): void {
    this.removeResellerDetails.close();
    this.templateParametersService
      .deleteTemplateParameters(this.domainNameControl.value, this.entityUid)
      .subscribe(() => {
        this.toastService.showToast(
          $localize`White label settings of "${this.domainNameControl.value}" domain is successfully deleted.`,
        );
        this.router.navigate([`/administration/organisations/${this.entityUid}`]);
      });
  }

  private setCurrentPageName(): void {
    this.currentPage = this.activatedRoute.snapshot.url
      .map((urlSegment) => urlSegment.path)
      .find((path: Pages) => Object.values(Pages).includes(path)) as Pages;
  }

  private updateFormStructure(templateParameters: TemplateParameters): void {
    this.helpPage.clear();
    if (templateParameters?.helpPage?.length > 0) {
      templateParameters?.helpPage?.forEach(() => this.addHelpPageItem());
    } else {
      this.addHelpPageItem();
    }
  }

  private updateEmptyState(): void {
    this.isCustomStylesAvailable = this.isCustomStylesEmpty(
      this.parentFormTemplateParameters?.getRawValue(),
    );
  }

  private isCustomStylesEmpty(templateParameters: TemplateParameters): boolean {
    return !this.isObjectEmpty(templateParameters?.pageStyle) || !!templateParameters?.logo?.length;
  }

  private isObjectEmpty(object: Record<string, any>): boolean {
    if (!object) return true;

    return Object.values(object)
      .map((value) => {
        if (!this.typeDefinitionHelper.isPrimitive(value)) {
          return this.isObjectEmpty(value);
        }

        if (this.typeDefinitionHelper.isArray(value)) {
          return value.every((item) => this.isObjectEmpty(item));
        }

        return isEmpty(value);
      })
      .every((v) => v);
  }

  private checkAvailabilityOfFields(
    isDomainNameEmpty: boolean = !Boolean(this.domainNameControl?.value),
  ): void {
    const isWLManagerRole = this.authorizationService
      .getRoles()
      .includes(UserRoles.ProviderWhiteLabelManager);

    if (isWLManagerRole) {
      this.domainNameControl.disable();
    }

    if (isDomainNameEmpty) {
      this.helpPage.disable();
      this.text.disable();
      this.emailDomainNameControl.disable();
      this.loginDomainNameControl.disable();
      this.disabled = true;
    } else {
      this.helpPage.enable();
      this.text.enable();
      this.disabled = false;
      isWLManagerRole
        ? (this.emailDomainNameControl.disable(), this.loginDomainNameControl.disable())
        : (this.emailDomainNameControl.enable(), this.loginDomainNameControl.enable());
    }
  }

  private checkWhiteLabelPermissions(): void {
    this.canEditWhiteLabel = this.restrictPermissionService.checkPermissions(
      organisationsPermissions.edit,
      ['white-label'],
    );
  }

  get parentForm(): FormGroup {
    return this.templateParametersForm?.parent as FormGroup;
  }

  get parentFormTemplateParameters(): FormGroup {
    return this.parentForm?.get('templateParameters') as FormGroup;
  }

  get helpPage(): FormArray {
    return this.form.get('helpPage') as FormArray;
  }

  get country(): FormControl {
    return this.helpPage.get('country') as FormControl;
  }

  get email(): FormControl {
    return this.helpPage.get('email') as FormControl;
  }

  get notes(): FormControl {
    return this.helpPage.get('notes') as FormControl;
  }

  get text(): FormGroup {
    return this.form.get('text') as FormGroup;
  }

  get brandText(): FormControl {
    return this.text.get('brandText') as FormControl;
  }

  get loginDomainName(): FormControl {
    return this.text.get('loginDomainName') as FormControl;
  }

  get privacyPolicyUrl(): FormControl {
    return this.text.get('privacyPolicyUrl') as FormControl;
  }
}
