import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CompareService {
  /***
   * Compares provided map1 and map2 arguments
   * and return true if they are equal
   * otherwise false
   * @param map1 - first map
   * @param map2 - second map
   */
  mapsEqual(map1: Map<any, any>, map2: Map<any, any>): boolean {
    return (
      JSON.stringify(Array.from(map1.entries())) === JSON.stringify(Array.from(map2.entries()))
    );
  }
}
