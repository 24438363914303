import { Component, OnInit, Input } from '@angular/core';
import { AuthorizationService } from '@portal/shared/auth/authorization/src';
import { AppPermission } from '@portal/shared/auth/authorization/src/lib/interfaces/app-permission.interface';

@Component({
  selector: 'portal-entity-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit {
  @Input()
  features: AppPermission[] = [];

  featuresVM: { applicationName: string; features: string[] }[];

  constructor(private authorizatinService: AuthorizationService) {}

  ngOnInit(): void {
    this.featuresVM = this.authorizatinService.getApps(this.features).map((app) => ({
      applicationName: app.name,
      features: this.authorizatinService
        .getAppFeatures(app.application, this.features)
        .map((feature) => feature.featureId),
    }));
  }
}
