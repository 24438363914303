import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import isEmpty from 'lodash-es/isEmpty';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SearchSelector } from '../search/enums/search-selector.enum';
import { ISearchBoxValue } from './interfaces/search-box-value.interface';
import { ISelectInput } from './interfaces/select-input.interface';

declare const $localize;

@Component({
  selector: 'portal-search-box',
  templateUrl: './search-box.component.html',
})
export class SearchBoxComponent implements OnInit, OnDestroy {
  @Input() entities: ISelectInput[];
  @Input() defaultEntityId: string;
  @Input() selectedSelector: Subject<string>;
  @Input() searchTerm: Subject<string>;
  @Input() isDeviceMobile = false;
  @Input() disabled = false;
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() entitySearched: EventEmitter<ISearchBoxValue> = new EventEmitter();
  @Output() searchUpdated: EventEmitter<ISearchBoxValue> = new EventEmitter();
  @Output() searchEntityRemoved: EventEmitter<string> = new EventEmitter();
  @Output() searchBoxInitComplete: EventEmitter<void> = new EventEmitter();

  @ViewChild('searchTermElem', { static: true }) searchTermElem: NgModel;
  pattern: RegExp;
  patternMessage: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _searchTerm = '';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _searchSelector = '';
  searchPlaceholder: string;
  searchSub: Subscription;
  selectorSub: Subscription;
  searchText = $localize`:button|Search-box action button:Search`;
  selectedItem: ISelectInput;

  constructor(private cd: ChangeDetectorRef, private elementRef: ElementRef) {}
  ngOnInit(): void {
    let entity: ISelectInput;

    if (!isEmpty(this.entities)) {
      entity = this.getDefaultEntity();
      this._searchSelector = entity.id;
      this.selectedItem = entity;
      this.updateEntityLocalData(entity);
    }

    this.searchSub = this.searchTerm?.pipe(debounceTime(10)).subscribe((val: string) => {
      this._searchTerm = val;
    });
    this.selectorSub = this.selectedSelector?.pipe(debounceTime(10)).subscribe((val: string) => {
      this._searchSelector = val;
      if (this._searchSelector && !isEmpty(this.entities)) {
        entity =
          this.entities.find((value: ISelectInput) => {
            return value.id === this._searchSelector;
          }) || entity;
        if (entity) {
          this.selectedItem = entity;
          this.updateEntityLocalData(entity);
        }
      }
    });
    this.searchBoxInitComplete.emit();
  }

  setAutoFocusState(): void {
    this.elementRef?.nativeElement?.querySelector('.input').focus();
  }

  ngOnDestroy(): void {
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
    if (this.selectorSub) {
      this.selectorSub.unsubscribe();
    }
  }

  search(): void {
    this.entitySearched.emit(this.constructSearchOutput());
  }

  clear(): void {
    this._searchTerm = '';
  }

  updateSelectedEntity(item: ISelectInput): void {
    this.searchEntityRemoved.emit(this.selectedItem.id);
    this.selectedItem = item;
    this.searchUpdated.emit(this.constructSearchOutput());
    this.updateEntityLocalData(item);
  }

  isInputInvalid(): boolean {
    return this.pattern && this.searchTermElem.invalid && this.searchTermElem.errors.pattern;
  }

  onSearchTermChange(value: string): void {
    this.searchTermChange.emit(value);
    this.searchUpdated.emit(this.constructSearchOutput());
  }

  modelChange(input: string): void {
    this.checkforCardNumberSelector(input);
  }

  private checkforCardNumberSelector(input: string): void {
    if (this.selectedItem.id === SearchSelector.CardNumber && !Number.isNaN(Number(input))) {
      if (input.length === 6 || (input.length === 7 && !input.includes('*'))) {
        const insert = '*';
        const insertLocation = 6;
        let inputString = [];
        inputString = input.split('');
        inputString.splice(insertLocation, 0, insert);
        this._searchTerm = inputString.join('');
      }
    }
  }

  private constructSearchOutput(): ISearchBoxValue {
    return {
      key: this.selectedItem.id,
      operator: '=',
      value: this._searchTerm,
    };
  }

  private getDefaultEntity(): ISelectInput {
    return this.entities.find((entity) => entity.id === this.defaultEntityId) || this.entities[0];
  }

  private updateEntityLocalData(entity: ISelectInput): void {
    this.searchPlaceholder =
      entity?.placeholder || $localize`:Search input placeholder text:Type a ${entity.text}`;
    this.pattern = entity?.pattern || null;
    this.patternMessage = entity?.patternMessage || null;
    this.cd.detectChanges();
  }
}
