import { SettlementType } from '../enums/settlement-type.enum';
import { IClearing } from './clearing.interface';

export interface ISettlementInformation {
  manual?: boolean;
  settlementType?: SettlementType;
  clearing?: IClearing;
  defaultTime?: string;
  cutoverTimeZone?: string;
}
