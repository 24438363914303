<form
  class="card sidepanel sidepanel--theme-winter is-opened"
  [class.is-closed]="closed"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <div class="card-content tab-content">
    <div class="columns">
      <div class="column is-flex is-justify-content-space-between">
        <h2 class="title is-3">
          <span *ngIf="isCreating" i18n> Add new contact </span>
          <div *ngIf="!isCreating">
            <span i18n>Edit</span>&nbsp;
            <span>{{ value?.name?.firstName + ' ' + value?.name?.lastName }}</span>
          </div>
        </h2>
        <button
          (click)="closeSideBar()"
          class="delete is-medium"
          type="button"
          title="Close"
          i18n-title
        ></button>
      </div>
    </div>
    <label class="field">
      <div class="label" i18n>Contact type</div>
      <div class="control" *ngFor="let type of contactTypeList; index as contactIndex">
        <label class="radio">
          <input
            type="radio"
            name="contactType"
            attr.data-e2e="radio-contactType-{{ type.id }}"
            [value]="type.id"
            [formControl]="getContactTypeControl()"
          />
          <span>{{ type.text }}</span>
        </label>
      </div>
    </label>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label" i18n>First name</div>
          <div class="control">
            <input
              class="input"
              [formControl]="getFirstNameControl()"
              [value]="value?.name?.firstName"
              data-e2e="input-firstName"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(getFirstNameControl()) }"
            />
            <portal-form-error
              [formInput]="getFirstNameControl()"
              errorId="firstName"
            ></portal-form-error>
          </div>
        </label>
      </div>
    </div>
    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label" i18n>Last name</div>
          <div class="control">
            <input
              class="input"
              [formControl]="getLastNameControl()"
              [value]="value?.name?.lastName"
              data-e2e="input-lastName"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(getLastNameControl()) }"
            />
            <portal-form-error
              [formInput]="getLastNameControl()"
              errorId="lastName"
            ></portal-form-error>
          </div>
        </label>
      </div>
    </div>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label" i18n>Phone number</div>
          <div class="control">
            <!-- This component should be able to have disabled state -->
            <portal-int-tel-input [formControl]="getPhoneNumberControl()"></portal-int-tel-input>
          </div>
        </label>
      </div>
    </div>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label" i18n>Email address</div>
          <div class="control">
            <input
              class="input"
              [formControl]="getEmailControl()"
              [value]="value?.email"
              data-e2e="input-email"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(getEmailControl()) }"
            />
            <portal-form-error [formInput]="getEmailControl()" errorId="email"></portal-form-error>
          </div>
        </label>
      </div>
    </div>
    <portal-form-address
      name="contact"
      [form]="getContactAddressControl()"
      [countries]="countries"
      [type]="addressTypes.Billing"
      [value]="(value?.addresses)[0]"
      [hideFields]="['timezoneId', 'coordinates']"
    ></portal-form-address>
    <div class="mb-4"></div>
    <div class="mb-4"></div>
    <div
      class="columns is-vcentered is-flex is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
    >
      <div class="column">
        <button
          class="button has-text-danger is-text"
          (click)="closeSideBar()"
          type="button"
          i18n
          title="Discard"
          i18n-title
        >
          Discard
        </button>
      </div>
      <div class="column has-text-right">
        <button
          class="button is-primary has-text-right"
          [disabled]="disabled"
          type="submit"
          i18n
          title="Save contact"
          i18n-title
        >
          Save contact
        </button>
      </div>
    </div>
  </div>
</form>
