import { Application } from '../enums/application.enum';

export const APP_NAMES = {
  [Application.PaymentsPortal]: $localize`Payments Portal`,
  [Application.Marketplace]: $localize`Marketplace`,
  [Application.Developer]: $localize`Developer`,
  [Application.EstateOwner]: $localize`Estate Owner`,
  [Application.MarketplaceManager]: $localize`Marketplace Manager`,
  [Application.MOP]: $localize`Orders`,
  [Application.Petro]: $localize`Petro`,
  [Application.VHQ]: $localize`Device Management`,
};

// TODO:- Remove this const when we have KPI cards on home page. This is temporarily added to match L1 navigation item name.
// Remove its implmentation in wrapper/apps-dashboard.component.ts file as well.
export const NEW_APP_NAMES = {
  [Application.PaymentsPortal]: $localize`Commerce`,
  [Application.Marketplace]: $localize`App Marketplace`,
  [Application.Developer]: $localize`Developer`,
  [Application.EstateOwner]: $localize`Estate Owner`,
  [Application.MarketplaceManager]: $localize`Marketplace Manager`,
  [Application.MOP]: $localize`Boarding`,
  [Application.Petro]: $localize`C-Site Management`,
  [Application.VHQ]: $localize`Devices`,
};
