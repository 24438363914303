<div>
  <label class="label"
    >{{ name ? name : label }}&nbsp;<i
      *ngIf="isPasswordValid"
      class="fal has-pictogram-valid-icon has-text-success"
      aria-hidden="true"
    ></i
  ></label>
  <div class="control has-icons-right" (capsLock)="toggleCapslock($event)">
    <input
      [ngModel]="password"
      (ngModelChange)="onPasswordType($event)"
      [type]="isPasswordVisible ? 'text' : 'password'"
      (focus)="focused = true"
      (blur)="focused = false"
      [class.is-danger]="isInvalid"
      [disabled]="isDisabled"
      class="input"
    />
    <span
      class="icon is-small is-right eye-icon has-text-grey-lighter"
      (click)="togglePasswordVisibility()"
    >
      <span *ngIf="isPasswordVisible; else eyeIcon">
        <i class="far fa-eye-slash" aria-hidden="true"></i>
      </span>
    </span>
    <portal-form-error
      *ngIf="inlineValidation && control"
      [formInput]="control"
      [errorId]="label"
    ></portal-form-error>
  </div>
  <small class="has-text-danger" *ngIf="isCapsLockOn && focused" i18n>CapsLock is ON</small>
</div>

<ng-template #eyeIcon>
  <span>
    <i class="far fa-eye" aria-hidden="true"></i>
  </span>
</ng-template>
