export enum TransactionFilter {
  Channel = 'channel',
  Currency = 'currency',
  Product = 'brand',
  TransactionType = 'displayTransactionType',
  TransactionStatus = 'displayTransactionState',
  Wallet = 'wallet',
  StoredCredentialType = 'storedCredentialType',
  Processor = 'sourcePIM',
  AccountType = 'accountType',
  TokenType = 'instrumentType',
  Amount = 'amount',
  ReuseTokenContext = 'reuseTokenContext',
  Origin = 'sourceId',
  FundingSource = 'fundingSource',
  MerchantId = 'vfiMerchantId',
  Organisation = 'entityId',
  Organization = 'entityUid',
  ParentOrganisation = 'ancestorId',
  ProductFilter = 'product_filter',
  TransactionDate = 'transactionDate',
  SettledTime = 'settled_time',
  CutOffTime = 'cutoff_time',
}
