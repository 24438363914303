<div class="container is-narrow">
  <a
    [restrict-permission]="['read']"
    [routerLink]="['/administration', 'users']"
    data-e2e="link-new"
    *ngIf="!isPetroUser"
    title="Go to users"
    i18n-title
  >
    <span class="icon is-small">
      <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
    </span>
    <span i18n>Users</span>
  </a>

  <h1 class="title is-2" data-e2e="title">{{ user?.name }}</h1>

  <div class="box" *ngIf="loading">
    <portal-spinner
      [isHorizontal]="true"
      [isSmall]="true"
      text="Loading user"
      i18n-text
    ></portal-spinner>
  </div>

  <div class="box has-text-centered" *ngIf="!loading && !user" i18n>No user found</div>

  <portal-form-user
    *ngIf="user"
    [user]="user"
    [loggedInUser]="loggedInUser"
    [disabled]="loading"
    [saving]="saving"
    [isMyProfile]="isMyProfile"
    (formValidated)="onFormValidated($event)"
  ></portal-form-user>
</div>

<portal-base-modal #changeEntityModal>
  <div title i18n="Modal dialog title (moving a user to another Organisation)">Move User</div>
  <div description i18n>
    Moving <strong>{{ user?.name }}</strong> might result in broadening the access scope for the
    user. Are you sure you want to move <strong>{{ user?.name }}</strong
    >?
  </div>
  <div class="level" buttons>
    <button
      class="level-left button is-text has-text-primary"
      (click)="changeEntityModal.close()"
      type="button"
      i18n
      title="Cancel"
      i18n-title
    >
      Cancel
    </button>
    <div class="buttons">
      <button
        type="button"
        class="button is-danger is-outlined"
        (click)="changeEntityModal.close()"
        i18n
        title="Discard"
        i18n-title
      >
        Discard
      </button>
      <button
        type="button"
        class="button is-primary"
        (click)="confirmSaveUser$.next(true)"
        i18n
        title="Save user"
        i18n-title
      >
        Save User
      </button>
    </div>
  </div>
</portal-base-modal>
