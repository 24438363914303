import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ISelectInput } from '@portal/shared/ui/filter/src/lib/box/interfaces/select-input.interface';
import { DropdownSize } from '@portal/shared/ui/filter/src/lib/components/single-dropdown/dropdown-size.enum';
import * as isUndefined from 'lodash/isUndefined';
import { PaginationService } from 'ngx-pagination';
import { PageSize } from '../enums/page-size.enum';
import { IPagination } from '../interfaces/pagination.interface';

@Component({
  selector: 'portal-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnChanges {
  @Input() reset: boolean;
  @Input() itemsPerPage: number;
  @Input() totalItems: number;
  @Input() currentPage = 1;
  @Input() goto = true;
  @Input() allowInfiniteTotal = false;
  @Output() paginationChanged: EventEmitter<IPagination> = new EventEmitter<IPagination>();
  @Input() pageSizeOptions: PageSize[] = [
    PageSize.Size10,
    PageSize.Size25,
    PageSize.Size50,
    PageSize.Size100,
  ];
  @Input() paginationId = this.paginationService.defaultId();
  pageSizeValue: PageSize;
  readonly pageSize = PageSize;
  pageValue: string;
  pageSizeSelectedOption: ISelectInput;
  pageSizeOptionsList: ISelectInput[] = [];
  pageDropdownSize = DropdownSize.Middle;

  constructor(private paginationService: PaginationService) {
    this.pageSizeOptionList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageSizeOptions && !isUndefined(changes.pageSizeOptions.currentValue)) {
      this.pageSizeOptionList();
    }
    if (changes.totalItems && !isUndefined(changes.totalItems.currentValue)) {
      this.registerPagination();
    }
    if (changes.reset && changes.reset.currentValue) {
      this.registerPagination();
    }
    if (changes.itemsPerPage && changes.itemsPerPage.currentValue) {
      this.registerPagination();
      this.paginationService.setCurrentPage(this.paginationId, this.currentPage);
    }
    if (changes.currentPage && this.currentPage) {
      this.paginationService.setCurrentPage(this.paginationId, this.currentPage);
    }
  }

  onPageChange(page: string | number, totalPages: number): void {
    page = Number(page);
    if (Number.isInteger(page) && page > 0 && page <= totalPages) {
      this.paginationService.setCurrentPage(this.paginationId, page);
      this.paginationChanged.emit({ page, size: this.pageSizeValue });
      this.pageValue = '';
    }
  }

  onPageSizeChange(pageSize: ISelectInput): void {
    this.pageSizeValue = Number(pageSize.text);
    this.paginationChanged.emit({ page: 1, size: Number(pageSize.text) });
  }

  validateNumber(event: KeyboardEvent): boolean {
    if (['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab'].includes(event.code)) return true;
    return new RegExp(`^[0-9]$`).test(event.key);
  }

  private registerPagination(): void {
    this.pageSizeValue = this.itemsPerPage;
    this.pageSizeSelectedOption =
      this.pageSizeOptionsList.find((option) => +option.id === +this.pageSizeValue) ||
      this.pageSizeOptionsList[0];
    this.paginationService.register({
      id: this.paginationId,
      itemsPerPage: this.itemsPerPage,
      totalItems: this.totalItems,
      currentPage: this.currentPage,
    });
  }

  private pageSizeOptionList(): void {
    this.pageSizeOptionsList = this.pageSizeOptions.map((pageSize) => {
      return {
        id: String(pageSize),
        text: String(pageSize),
      };
    });
  }
}
