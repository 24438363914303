import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICurrency } from '@portal/shared/helpers/src/lib/currency/interfaces/currency.interface';

@Component({
  selector: 'portal-currencies-control',
  templateUrl: './currencies-control.component.html',
})
export class CurrenciesControlComponent {
  @Input() name: string;
  @Input() control: FormControl;
  @Input() invalid: boolean;
  @Input() e2eName = 'currencyControl';
  @Input() e2eErrorName = 'currencyControl-error';
  @Input() bindValue = 'code';
  @Input() bindLabel = 'name';
  @Input() multiple = true;
  @Input() closeOnSelect: boolean;
  @Input() currencies: ICurrency[];
  @Input() optionalText = false;
  @Output() remove = new EventEmitter<string>();
}
