import { Component, ElementRef, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ErrorService } from '@portal/shared/ui/form';
import { IIntTelInput } from '../models/int-tel-input.model';
import { IntTelInputService } from '../services/int-tel-input.service';
import { PhoneFormat } from '../enum/phone-format.enum';

declare const $localize;

@Component({
  selector: 'portal-int-tel-input',
  templateUrl: './int-tel-input.component.html',
  styleUrls: ['./int-tel-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => IntTelInputComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: IntTelInputComponent,
    },
  ],
})
export class IntTelInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() formControl: AbstractControl;
  @Input() alignOnSmallModal: boolean = false;
  @Input() defaultCountryCode: string = 'US';
  @Input() storeCountryCodeToLocalStorage: boolean = false;
  isCountryListOpen: boolean;
  countryCodes: IIntTelInput[];
  countryCodesFiltered: IIntTelInput[];
  selectedCountry: IIntTelInput;
  placeholderPrefix = $localize`Example`;
  placeholder: string;
  phoneNumber: string;

  isInvalid = ErrorService.isFieldInvalid;

  constructor(private intTelInputService: IntTelInputService, private elementRef: ElementRef) {}

  @HostListener('document:mousedown', ['$event'])
  clickOutside(event: MouseEvent): void {
    if (this.isCountryListOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.closeCountryList();
    }
  }

  ngOnInit(): void {
    this.countryCodes = this.intTelInputService.getCountriesWithPhoneCodes();
    this.countryCodesFiltered = this.countryCodes.slice();
    this._setDefaultCountry();
  }

  filterCountryCodes(searchKeyword: string): void {
    const searchKeywordLowerCase = searchKeyword.toLocaleLowerCase();
    this.countryCodesFiltered = this.countryCodes.filter(
      (countryCode) =>
        countryCode.name.toLowerCase().includes(searchKeywordLowerCase) ||
        countryCode.phoneCode.includes(searchKeywordLowerCase),
    );
  }

  onCountrySelect(event: MouseEvent | KeyboardEvent, country: IIntTelInput): void {
    event.preventDefault();
    this._setCountry(country, this.phoneNumber);
  }

  onPhoneNumberChange(inputNumber: string): void {
    this.phoneNumber = inputNumber;
    // Send number in E164 format to parent form
    this.onChange(this._formatNumber(inputNumber, PhoneFormat.E164));
    this._updateSelectedCountry(inputNumber);
  }

  toggleCountryList(): void {
    this.isCountryListOpen = !this.isCountryListOpen;
  }

  closeCountryList(): void {
    this.isCountryListOpen = false;
  }

  // Callback functions
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (phoneNumber?: string): void => {};
  onTouched = (): void => {};

  /***
   * Writes a new value to the element.
   * @param phoneNumber
   */
  writeValue(phoneNumber: string): void {
    this._updateSelectedCountry(phoneNumber);
    // Display format in NATIONAL format to the user
    this.phoneNumber = this._formatNumber(phoneNumber, PhoneFormat.NATIONAL);
  }

  /***
   * Registers a callback function
   * that is called when the control's
   * value changes in the UI.
   * @param onChange
   */
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  /***
   * Registers a callback function that is called
   * by the forms API on initialization to update
   * the form model on blur.
   * @param onTouched
   */
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  /***
   * This method is used to validate the current value
   * of the form control. This method will be called
   * whenever a new value is reported to the parent form.
   * The method needs to return null if no errors are found,
   * or an error object containing all the details needed to
   * correctly display a meaningful error message to the user.
   * @param control
   */
  validate(control: AbstractControl): ValidationErrors | null {
    const phoneNumber = control.value;
    const isValid =
      this.intTelInputService.isValidPhoneNumber(phoneNumber, this.selectedCountry?.alpha2) ||
      !phoneNumber;
    return isValid
      ? null
      : {
          phone: {
            message: '@@NOT_A_PHONE_NUMBER',
            displayMessage: $localize`Please enter a valid phone number`,
          },
        };
  }

  private _setDefaultCountry(): void {
    if (
      !this.defaultCountryCode ||
      this.defaultCountryCode.length !== 2
    ) {
      this.defaultCountryCode = 'US';
    }
    let defaultCountry = this.intTelInputService.getCountryFromAlpha2(this.defaultCountryCode);
    if (!defaultCountry) {
      defaultCountry = this.intTelInputService.getCountryFromAlpha2('US');
    }
    this._setCountry(defaultCountry);
  }

  private _updatePlaceholder(): void {
    let examplePhoneNumber = this.intTelInputService.getExampleNumberForCountry(
      this.selectedCountry?.alpha2,
    );
    // removing the initial 0 from example number for New Zealand and INDIA
    if (
      (this.selectedCountry?.alpha2 === 'NZ' || this.selectedCountry?.alpha2 === 'IN') &&
      examplePhoneNumber.startsWith('0')
    ) {
      examplePhoneNumber = examplePhoneNumber.slice(1);
    }
    this.placeholder = `${this.placeholderPrefix}: ${examplePhoneNumber}`;
  }

  private _updateSelectedCountry(phoneNumber: string): void {
    const countryCode = this.intTelInputService.getCountryCodeFromPhoneNumber(phoneNumber);
    if (countryCode) {
      const country = this.intTelInputService.getCountryFromAlpha2(countryCode);
      this._setCountry(country, phoneNumber);
    }
  }

  private _setCountry(country: IIntTelInput, phoneNumber?: string): void {
    this.closeCountryList();
    this.selectedCountry = country;
    this.onChange(this._formatNumber(phoneNumber, PhoneFormat.E164));
    // Validate existing input on country change
    this.onTouched();
    this._updatePlaceholder();
    if (this.storeCountryCodeToLocalStorage) {
      window.localStorage.setItem('defaultCountryCode', country.alpha2);
    }
  }

  private _formatNumber(phoneNumber: string, type: PhoneFormat): string {
    return phoneNumber
      ? this.intTelInputService.formatNumber(phoneNumber, this.selectedCountry?.alpha2, type)
      : phoneNumber;
  }
}
