import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  IMerchantChoiceRouting,
  IOrganisation,
  ProcessorType,
} from '@portal/entity-services/interfaces/src';
import { PaymentContractService } from '@portal/entity-services/payment-contracts/src/lib/services/payment-contract.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ICurrencyMaskConfig } from '@apps/portal/src/app/modules/transactions/interfaces';
import { CurrencyService } from '@portal/shared/helpers/src';
import { MultilingualValidators } from '@portal/shared/ui/form/src';
import { WhiteLabelingService } from '@portal/shared/white-labeling';

@UntilDestroy()
@Component({
  selector: 'portal-merchant-choice-routing',
  templateUrl: './merchant-choice-routing.component.html',
})
export class MerchantChoiceRoutingComponent implements OnInit {
  @Input() processorType: ProcessorType;
  @Input() editable: boolean;
  @Input() orgForm: FormGroup;
  @Input() organisation: IOrganisation;

  defaultCardTableGroupId: string;
  defaultBrands: string[];
  isEnabled: boolean;
  thresholdsData: {
    defaultCardBrand: string;
    minMcrThreshold: string;
  }[] = [];
  maxAmount = 9999999999.99;
  isWhiteLabelingEnabled = false;
  amountMask: ICurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    prefix: '',
    suffix: '',
    precision: 2,
    thousands: this.currencyService.thousands,
    decimal: this.currencyService.decimal,
  };

  constructor(
    private paymentContractService: PaymentContractService,
    private fb: FormBuilder,
    private currencyService: CurrencyService,
    private customValidators: MultilingualValidators,
    private whiteLabelingService: WhiteLabelingService,
  ) {}

  ngOnInit(): void {
    this.isWhiteLabelingEnabled = this.whiteLabelingService.isWhiteLabelingEnabled();
    if (this.mcrData?.thresholds) {
      this.isEnabled = this.mcrData.enabled;
      this.mcrData.thresholds.map((threshold) => {
        this.thresholdsData.push(threshold);
      });
    } else {
      this.isEnabled = false;
    }
    this.paymentContractService.getProcessor(this.processorType)?.subscribe((processorDetail) => {
      this.defaultCardTableGroupId = processorDetail.defaultCardTableGroupId;
      if (this.defaultCardTableGroupId) {
        this.paymentContractService
          .getCardTableGroup(this.defaultCardTableGroupId)
          .subscribe((data) => {
            this.defaultBrands = data?.dualBrandDetails?.defaultBrands;
            this.orgForm?.addControl('merchantChoiceRouting', this.fb.group({}));
            this.form?.addControl('currency', this.fb.control('AUD'));
            this.form?.addControl('enabled', this.fb.control(this.isEnabled));
            this.form?.addControl('thresholds', this.fb.array([]));
            if (this.thresholdsData.length) {
              this.thresholdsData.forEach((thresholdData) => {
                const brandGroup = this.fb.group({
                  defaultCardBrand: [thresholdData.defaultCardBrand.toUpperCase()],
                  minMcrThreshold: [
                    thresholdData.minMcrThreshold,
                    [
                      this.customValidators.required,
                      this.customValidators.maxAmount(this.maxAmount),
                    ],
                  ],
                });
                this.thresholds?.push(brandGroup);
              });
            } else {
              this.defaultBrands?.forEach((brandData) => {
                if (brandData) {
                  const brandGroup = this.fb.group({
                    defaultCardBrand: [brandData.toUpperCase()],
                    minMcrThreshold: [
                      0.0,
                      [
                        this.customValidators.required,
                        this.customValidators.maxAmount(this.maxAmount),
                      ],
                    ],
                  });
                  this.thresholds?.push(brandGroup);
                }
              });
            }

            if (!this.editable) {
              this.enabled?.disable();
              this.defaultBrands?.forEach((defaultBrand) => {
                this.getPaymentTypeControl(defaultBrand)?.disable();
              });
            }
          });
      }
    });
  }

  get form(): FormGroup {
    return this.orgForm?.get('merchantChoiceRouting') as FormGroup;
  }

  get mcrData(): IMerchantChoiceRouting {
    return this.organisation?.merchantProcessingSettings?.merchantChoiceRouting;
  }

  get thresholds(): FormArray {
    return this.form?.get('thresholds') as FormArray;
  }

  getPaymentTypeControl(paymentType: string): FormControl {
    const cardBrandFormGroup = this.thresholds?.controls?.find((control) => {
      const cardBrandValue = control?.get('defaultCardBrand')?.value;
      return cardBrandValue === paymentType || cardBrandValue === paymentType.toUpperCase();
    }) as FormGroup;
    return cardBrandFormGroup?.get('minMcrThreshold') as FormControl;
  }

  get currency(): FormControl {
    return this.form?.get('currency') as FormControl;
  }

  get enabled(): FormControl {
    return this.form?.get('enabled') as FormControl;
  }

  onToggle(): void {
    this.enabled.setValue(!this.enabled.value);
  }
}
