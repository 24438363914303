import { Injectable } from '@angular/core';
import { IAppVersion } from '@portal/marketplace/api-interfaces';
import { AppCatalogVersionsStore } from './app-versions.store';

@Injectable({
  providedIn: 'root',
})
export class AppVersionsService {
  constructor(public appCatalogVersionsStore: AppCatalogVersionsStore) {}
  addDataToAppCatalogVersionsStore(apps: IAppVersion[]): void {
    this.appCatalogVersionsStore.add(apps);
  }
}
