import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { LocalisedNgbDateParserFormatter } from '@portal/shared/helpers/src';
import { DatePickerComponent } from './date-picker.component';

@NgModule({
  imports: [CommonModule, NgbDatepickerModule],
  declarations: [DatePickerComponent],
  exports: [DatePickerComponent],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    {
      provide: NgbDateParserFormatter,
      useClass: LocalisedNgbDateParserFormatter,
    },
  ],
})
export class DatePickerModule {}
