import snakeCase from 'lodash-es/snakeCase';
import camelCase from 'lodash-es/camelCase';
import kebabCase from 'lodash-es/kebabCase';
import { Case, CaseConverterFn, CaseTypes } from './enums/case.enum';

export const makeCaseConverter = (
  convention: Case = CaseTypes.Snake,
  predefinedConversions: { [key: string]: string } = {},
  preserveValuesFor: string[] = [],
): CaseConverterFn => {
  const vocabulary = new Map(Object.entries(predefinedConversions));
  const setOfKeysToPreserveValuesFor = new Set(preserveValuesFor);
  const convertKey = (key: string): string => {
    let convertMethod;

    switch (convention) {
      case CaseTypes.Snake:
        convertMethod = snakeCase(key);
        break;
      case CaseTypes.Camel:
        convertMethod = camelCase(key);
        break;
      case CaseTypes.Kebab:
        convertMethod = kebabCase(key);
        break;
    }

    return vocabulary.get(key) ?? convertMethod;
  };
  const convertValue = (value: any): any => {
    return typeof value === 'string' ? value : convertCase(value);
  };

  function convertCase(data: any): any {
    if (typeof data === 'string') {
      return convertKey(data);
    }

    if (Array.isArray(data)) {
      return data.map(convertValue);
    }

    if (typeof data === 'object' && data !== null && !(data instanceof Date)) {
      return Object.entries(data)
        .map(([k, v]) => [convertKey(k), setOfKeysToPreserveValuesFor.has(k) ? v : convertValue(v)])
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
    }
    return data;
  }

  return convertCase;
};

export const toSnakeCase = makeCaseConverter(CaseTypes.Snake);
export const toCamelCase = makeCaseConverter(CaseTypes.Camel);
export const toKebabCase = makeCaseConverter(CaseTypes.Kebab);
