<div class="dropdown multi-select-filter" [ngClass]="{ 'is-active': isOpen }">
  <div class="dropdown-trigger">
    <button
      [attr.aria-expanded]="isOpen"
      class="button is-chip is-size-7-mobile"
      [class.is-applied]="getValues().length"
      [class.is-active]="isOpen"
      data-e2e="button-entity-filter"
      (click)="itemToggled.emit()"
      title="{{ title }}"
      type="button"
    >
      <span>{{ title }}</span>
      <span class="icon">
        <i class="fas fa-chevron-down"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu organisations-filter">
    <div class="dropdown-content multi-select-filter">
      <div class="dropdown-item">
        <portal-search-filter-autocomplete-input
          #entitySearchInput
          (search)="onSearch($event)"
        ></portal-search-filter-autocomplete-input>
      </div>

      <hr class="dropdown-divider" />

      <div
        *ngIf="limitExceeded"
        [ngClass]="{ 'pb-0': showSelectedEntities?.size }"
        class="dropdown-item has-text-danger"
        i18n
      >
        Select up to {{ selectLimit }} organisations
      </div>

      <ng-container *ngIf="showSelectedEntities?.size">
        <div class="dropdown-item">
          <label class="label" i18n>Selected</label>
          <ng-container *ngFor="let entity of showSelectedEntities | keyvalue">
            <portal-search-filter-checkbox
              [id]="entity.key"
              [text]="entity.value.text"
              [selectedEntities]="showSelectedEntities"
              (removed)="onEntityRemoved($event)"
              (added)="onEntityAdded($event)"
            ></portal-search-filter-checkbox>
          </ng-container>
        </div>

        <hr class="dropdown-divider" />
      </ng-container>

      <div class="dropdown-item has-text-truncate" *ngIf="loading$ | async">
        <span class="has-text-truncate">
          <portal-spinner
            [isHorizontal]="true"
            [isSmall]="true"
            text="Loading organisations..."
            i18n-text
          ></portal-spinner>
        </span>
      </div>

      <div class="dropdown-item is-scrollable" *ngIf="!(loading$ | async)">
        <span class="has-text-truncate" *ngIf="!entities?.length" i18n>
          Start typing to search for organisations..
        </span>
        <ng-container *ngFor="let entity of entities">
          <portal-search-filter-checkbox
            [id]="entity.entityUid"
            [text]="entity.name"
            [selectedEntities]="localPreSelectedEntities"
            [searchedText]="searchedText"
            (removed)="onEntityRemoved($event)"
            (added)="onEntityAdded($event)"
          ></portal-search-filter-checkbox>
        </ng-container>
      </div>

      <hr class="dropdown-divider" />
      <div class="dropdown-item">
        <button
          class="button is-primary"
          data-e2e="button-filter"
          i18n
          (click)="onFilterApplied()"
          type="button"
          title="Apply"
          i18n-title
        >
          Apply
        </button>
        <button
          class="button is-transparent additional-filter-button ml-2"
          data-e2e="button-clear-filter"
          i18n
          (click)="clear()"
          (keydown.enter)="clear()"
          type="button"
          title="Clear"
          i18n-title
        >
          Clear
        </button>
      </div>
    </div>
  </div>
</div>
