import { Component, Input } from '@angular/core';

@Component({
  selector: 'portal-switch-toggle',
  templateUrl: './switch-toggle.component.html',
})
export class SwitchToggleComponent {
  @Input()
  text: string;

  switchOn = false;

  reset(): void {
    this.switchOn = false;
  }
  toggleOnOff(): void {
    this.switchOn = !this.switchOn;
  }
}
