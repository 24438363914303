<div class="field has-addons">
  <div class="control">
    <div class="select" data-e2e="report-page-select-option" *ngIf="entities?.length !== 1">
      <select class="is-size-7-mobile" name="my-select" [(ngModel)]="selector" (change)="selectChange()">
        <option [value]="entity.id" *ngFor="let entity of entities">
          {{ entity.text }}
        </option>
      </select>
    </div>
    <label for="searchTerm" *ngIf="entities?.length === 1">
      <span class="button is-static">{{ entities[0].text }}</span>
    </label>
  </div>
  <div class="control is-expanded">
    <input
      class="input is-size-7-mobile"
      type="{{ inputType }}"
      [maxLength]="400"
      [(ngModel)]="_searchTerm"
      placeholder="Search Criteria"
      (keydown.enter)="onPressEnter()"
    />
  </div>
  <div class="control">
    <a class="button is-primary is-size-6-mobile" (click)="onFilterApplied()" data-e2e="report-page-search-btn">
      <i class="fas fa-search"></i>
      <span class="is-hidden-mobile"> &nbsp;Search </span>
    </a>
  </div>
</div>
