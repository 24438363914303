import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  AddressType,
  BankAccountType,
  EntityStatus,
  EntityType,
  IAddress,
  IBankAccount,
  IContact,
  IOrganisation,
  IPaymentContract,
  IReceiptContract,
  SalesChannel,
  TemplateParameters,
} from '@portal/entity-services/interfaces';
import { AuthenticationService } from '@portal/shared/auth/authentication';
import {
  AppPermission,
  AuthorizationService,
  RoleGuardService,
  UserRoles,
} from '@portal/shared/auth/authorization';
import { RestrictPermissionService } from '@portal/shared/auth/authorization/src/lib/services/restrict-permission.service';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle';
import { BaseModalComponent } from '@portal/shared/ui/modal';
import { ToastService } from '@portal/shared/ui/toast';
import { Features } from 'environments/enums/features.enum';
import get from 'lodash-es/get';
import omitBy from 'lodash-es/omitBy';
import isEmpty from 'lodash-es/isEmpty';
import { combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  finalize,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { ContactTypes } from '../../components/form/contact-type.list';
import { organisationsPermissions } from '../../routing/route-permissions.const';
import { OrganisationService } from '../../services/organisation.service';
import { TemplateParametersService } from '../../services/template-parameters.service';
import { EntityTypes } from '../form/entity-type.list';
import { IndustryTypes } from '../form/industry-type.list';
import { LegalEntityTypes } from '../form/legal-entity-type.list';
import { PaymentDocumentTypes } from '../../services/payment-document-types.list';
import { CombinedOrgTemplateParameters } from '../../interfaces/combined-org-template-parameters';
import { DetailResellerForm } from '../../interfaces/detail-reseller-form.interface';
import { FederationTypes } from '../form/federation-type.list';
import { NotifierService } from '@portal/shared/ui/notifier/src';
import { ISftpConnection } from '@apps/portal/src/app/shared/report-engine/interfaces/sftp-connection.interface';
import { ReportEngineService } from '@apps/portal/src/app/modules/report-engine/services/report-engine.service';
import { BusinessIdentifiersTypes } from '../form/business-identifiers-type.list';
import { ReceiptAttachmentTypes } from '../../services/receipt-attachment-types.list';
import { ReceiptProvider } from '../../enums/receipt-provider.enum';
import { ReceiptProviderTypes } from '../../services/receipt-provider.list';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReasonForDeletionList } from '../../services/reason-for-deletion.list';
import { MultilingualValidators } from '@portal/shared/ui/form';
import { ReceiptSalesChannels } from '../form/receipt-sales-channels.list';
import { ReceiptSalesChannel } from '../../enums/receipt-sales-channel.enum';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'portal-detail-organisation',
  templateUrl: './detail.component.html',
})
export class DetailOrganisationComponent implements OnInit, OnDestroy {
  @ViewChild('confirmDisableModal', { static: true }) confirmDisableModal: BaseModalComponent;
  @ViewChild('confirmDeleteModal', { static: true }) confirmDeleteModal: BaseModalComponent;
  @ViewChild('confirmEnableModal', { static: true }) confirmEnableModal: BaseModalComponent;

  loading$: Observable<boolean>;
  organisation$: Observable<IOrganisation>;
  organisation: IOrganisation;
  organisationId: string;
  paymentContracts$: Observable<IPaymentContract[]>;
  paymentContracts: IPaymentContract[];
  contacts$: Observable<IContact[]>;
  contact$: Observable<IContact>;
  contacts: IContact[];
  showError = false;
  statusActive = EntityStatus.Active;
  statusInactive = EntityStatus.Inactive;
  statusDeleted = EntityStatus.Deleted;
  bankAccountType = BankAccountType;

  canReadContact = false;
  canReadAddresses = false;
  canReadPaymentContracts = false;
  canReadPointOfInteraction = false;

  userEntityUid: string;
  bankAccounts: IBankAccount[] = [];
  entityFeatures: AppPermission[];
  featureLoadRoles = [UserRoles.VerifoneAdmin, UserRoles.ProviderWhiteLabelManager];
  hasAccessToFeatures: boolean;
  entityType = EntityType;
  paymentDocumentTypeListText = this.paymentDocumentTypes.list.map((item) => item.text).join(', ');

  organisationAddresses: Record<string, IAddress> = {
    billing: null,
    delivery: null,
    trading: null,
  };
  isShippingAddressSameAsBilling: boolean;
  isTradingAddressSameAsBilling: boolean;
  receiptContract: IReceiptContract;

  isSecureCardCaptureAvailable = this.isFeatureActive(Features.SecureCardCapture);
  isTokenScopeAvailable = this.isFeatureActive(Features.TokenScopes);
  isMcrAvailable = this.isFeatureActive(Features.MerchantChoiceRouting);
  hasPermissionToViewMcr = this.featureToggleService.hasAccessPermission([
    'et_m:w',
    'et:a',
    'et:r',
  ]);

  canEditWhiteLabel: boolean;
  isWhiteLabelFeatureActiveForOrg: boolean;
  resellerForm: FormGroup;
  templateParameters: TemplateParameters;
  resellerDetailsSaving$: Observable<boolean>;
  errorInFetchingFeatures = false;
  isSftpDeliveryEnabled: boolean;
  businessIdentifierTypeList = [
    ...this.businessIdentifiersTypes.list,
    ...this.businessIdentifiersTypes.InvalidBusinessIdentifierList,
  ];
  isReceiptProviderInvoice4U = false;
  receiptMerchantLogo: Blob;
  isDeletedOrg = false;
  isDisabledOrg = false;

  deleting = false;
  reasonsForDeletion = this.reasonForDeletionList.list;
  reason = new FormControl('', this.validators.required);
  ecomContract: IPaymentContract;
  receiptSalesChannelsList: Record<ReceiptSalesChannel, string>;

  form: FormGroup;

  constructor(
    public industryTypes: IndustryTypes,
    public federationTypes: FederationTypes,
    public receiptAttachmentTypes: ReceiptAttachmentTypes,
    public invoiceProviderTypes: ReceiptProviderTypes,
    private entityTypes: EntityTypes,
    private organisationService: OrganisationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private title: Title,
    private liveAnnouncer: LiveAnnouncer,
    private roleGuard: RoleGuardService,
    private authService: AuthorizationService,
    private featureToggleService: FeatureToggle,
    private contactTypes: ContactTypes,
    private legalEntityTypes: LegalEntityTypes,
    private authenticationService: AuthenticationService,
    private paymentDocumentTypes: PaymentDocumentTypes,
    private templateParametersService: TemplateParametersService,
    private restrictPermissionService: RestrictPermissionService,
    private notifierService: NotifierService,
    private reportEngineService: ReportEngineService,
    private businessIdentifiersTypes: BusinessIdentifiersTypes,
    private reasonForDeletionList: ReasonForDeletionList,
    private validators: MultilingualValidators,
    private fb: FormBuilder,
    private receiptSalesChannels: ReceiptSalesChannels,
  ) {
    this.receiptSalesChannelsList = this.receiptSalesChannels.keyValue;
  }

  ngOnInit(): void {
    this.form = this.fb.group({});
    this.checkWhiteLabelingPermissions();
    this.isSftpDeliveryEnabled = this.featureToggleService.isFeatureActive(Features.SftpDelivery);

    this.loading$ = combineLatest([
      this.organisationService.loading$,
      this.templateParametersService.loading$,
    ]).pipe(
      map(([...loadings]) => loadings.some((isLoading) => isLoading)),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.resellerDetailsSaving$ = combineLatest([
      this.templateParametersService.actionLoading$,
      this.organisationService.loadingAdditionalInfo$,
    ]).pipe(
      map(([...loadings]) => loadings.some((isLoading) => isLoading)),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.activatedRoute.paramMap.pipe(untilDestroyed(this)).subscribe((params: ParamMap) => {
      this.organisationId = params.get('id');
      this.loadOrganisation();
    });
  }

  ngOnDestroy(): void {
    this.templateParametersService.cleanInitialParameters();
  }

  loadOrganisation(): void {
    this.userEntityUid = this.authenticationService.entityUid;
    if (this.organisationId) {
      this.getOrganisation();
      if (this.isSftpDeliveryEnabled) {
        this.getAllSftpConnectionNames()
          .pipe(untilDestroyed(this))
          .subscribe((response) => {
            const selectedOrganization = response.find(
              (connection) => connection.entityUid === this.organisationId,
            );
            if (selectedOrganization) {
              this.organisation.sftpConnection = selectedOrganization?.connectionName;
            }
          });
      }
    }
  }

  isUsersEntityId(entityUid: string): boolean {
    return this.userEntityUid === entityUid;
  }

  getEntityType(entityType: string): string {
    const entityTypeValue = this.entityTypes.lookup(entityType);
    return entityTypeValue?.text;
  }

  getLegalEntityType(legalEntityType: string): string {
    return this.legalEntityTypes.getValue(legalEntityType, null);
  }

  getContactDetails(organisation: IOrganisation): IContact[] {
    if (organisation.contacts) {
      organisation.contacts.forEach((contact) => {
        const contactCountry = get(contact, 'addresses[0].country', '');
        contact.contactTypeFormatted =
          this.contactTypes.keyValue[contact.contactType] || contact.contactType;
        if (contact.addresses && contact.addresses[0] && contactCountry) {
          contact.addresses[0].countryName =
            this.organisationService.getCountryNameFromCountryCode(contactCountry);
        } else {
          contact.addresses = [];
        }
      });
    }
    return organisation.contacts;
  }

  getOrganisation(): void {
    this.canReadPaymentContracts = false;
    this.canReadAddresses = false;
    this.canReadContact = false;
    this.organisation$ = this.organisationService.getByKey(this.organisationId).pipe(
      switchMap((organisation) =>
        this.templateParametersService.getCombinedOrgWithTemplateParameters(
          organisation,
          this.canEditWhiteLabel,
          true,
        ),
      ),
      untilDestroyed(this),
    );
    this.organisation$.subscribe(({ org, templateParameters }: CombinedOrgTemplateParameters) => {
      if (org.status === EntityStatus.Deleted) {
        this.isDeletedOrg = true;
      }
      if (org.status === EntityStatus.Inactive) {
        this.isDisabledOrg = true;
      }

      this.organisation = org;
      this.templateParameters = templateParameters;
      if (org.status === EntityStatus.Active) {
        this.getAddresses();
        this.getContacts();
        this.getAccounts();
        this.getFeatures();
        this.getReceiptContracts();

        const ppcSnapshot = this.authService.getRouteSnapshot('administration/payment-contracts');
        if (this.roleGuard.canAccess(ppcSnapshot, 'read')) {
          this.canReadPaymentContracts = true;
          this.getPaymentContracts();
        }
      } else {
        this.contacts = null;
        this.paymentContracts = null;
      }

      const poiSnapshot = this.authService.getRouteSnapshot('administration/points-of-interaction');
      if (this.roleGuard.canAccess(poiSnapshot, 'read')) {
        this.canReadPointOfInteraction = true;
      }

      this.title.setTitle(this.organisation.name);
      this.liveAnnouncer.announce($localize`Navigated to ${this.title.getTitle()}`);
    });
  }

  getPaymentContracts(): void {
    this.paymentContracts$ = this.organisationService.getPaymentContracts(this.organisationId);
    this.paymentContracts$.pipe(untilDestroyed(this)).subscribe((contracts: IPaymentContract[]) => {
      this.paymentContracts = contracts;
      this.ecomContract = this.paymentContracts.find((contract) =>
        contract.salesChannels.includes(SalesChannel.Ecommerce),
      );
    });
  }

  getReceiptContracts(): void {
    this.organisationService
      .getReceiptContracts([this.organisationId])
      .pipe(
        map((recContracts) => recContracts[0]),
        catchError(() => {
          this.organisation.receiptContract = null;
          return of(null);
        }),
        untilDestroyed(this),
      )
      .subscribe((recContract: IReceiptContract) => {
        if (recContract) {
          this.isReceiptProviderInvoice4U = recContract.provider === ReceiptProvider.Invoice4U;

          this.organisation.receiptContract = recContract;
          this.receiptMerchantLogo = recContract.templateConfiguration?.receiptMerchantLogo;
        }
      });
  }

  getShippingTradingText(): string {
    if (this.isShippingAddressSameAsBilling && this.isTradingAddressSameAsBilling) {
      return $localize`The shipping and trading address are similar to the billing address`;
    }

    if (this.isShippingAddressSameAsBilling) {
      return $localize`The shipping address is similar to the billing address`;
    }

    if (this.isTradingAddressSameAsBilling) {
      return $localize`The trading address is similar to the billing address`;
    }
  }

  getAddresses(): void {
    const countryCode = this.organisation?.locale?.countryCode;
    this.isShippingAddressSameAsBilling = false;
    this.isTradingAddressSameAsBilling = false;

    this.organisationService
      .getAddresses(this.organisationId)
      .pipe(untilDestroyed(this))
      .subscribe((addresses: IAddress[]) => {
        this.organisation.addresses = addresses;
        this.canReadAddresses = this.organisation.status === EntityStatus.Active;
        for (const address of this.organisation.addresses) {
          if (
            address.status === EntityStatus.Inactive &&
            this.organisation.status === EntityStatus.Active
          ) {
            this.enableAddresses();
          }

          address.countryName = this.organisationService.getCountryNameFromCountryCode(
            address?.country ?? countryCode,
          );

          switch (address.addressType) {
            case AddressType.Billing:
              this.organisationAddresses.billing = address;
              break;
            case AddressType.Delivery:
              this.organisationAddresses.delivery = address;
              break;
            case AddressType.Trading:
              this.organisationAddresses.trading = address;
              break;
            default:
              break;
          }
        }

        if (this.organisationAddresses.delivery && this.organisationAddresses.billing) {
          this.isShippingAddressSameAsBilling = this.organisationService.compareAddresses(
            this.organisationAddresses.delivery,
            this.organisationAddresses.billing,
          );
        }

        if (this.organisationAddresses.trading && this.organisationAddresses.billing) {
          this.isTradingAddressSameAsBilling = this.organisationService.compareAddresses(
            this.organisationAddresses.trading,
            this.organisationAddresses.billing,
          );
        }
      });
  }

  getContacts(): void {
    this.contacts$ = this.organisationService.getContacts(this.organisationId);
    this.contacts$.pipe(untilDestroyed(this)).subscribe((contacts: IContact[]) => {
      this.organisation.contacts = this.contacts = contacts;
      this.organisation.contacts = this.getContactDetails(this.organisation);
      this.canReadContact = this.organisation.status === EntityStatus.Active;
      this.contacts.forEach((contact, index) => {
        if (
          contact.status === EntityStatus.Inactive &&
          this.organisation.status === EntityStatus.Active
        ) {
          this.enableContacts(contact.contactUid, index);
        }
      });
    });
  }

  getAccounts(): void {
    this.organisationService
      .getAccounts(this.organisation)
      .pipe(untilDestroyed(this))
      .subscribe((bankAccounts) => {
        this.bankAccounts = bankAccounts;
      });
  }

  getFeatures(): void {
    const needLoadFeatures = this.authService
      .getRoles()
      .some((role) => this.featureLoadRoles.includes(role));
    if (!needLoadFeatures) return;

    this.authService
      .getAppsPermissionsByEntity(this.organisationId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (features) => {
          this.entityFeatures = features;
          this.checkWhiteLabelFeatureState(features);
          this.errorInFetchingFeatures = false;
        },
        () => {
          this.entityFeatures = [];
          this.errorInFetchingFeatures = true;
        },
      );
  }

  enable(): void {
    this.organisation$ = this.organisationService.enableOrganisation(this.organisationId);
    this.organisation$
      .pipe(finalize(() => this.confirmEnableModal.close()))
      .subscribe((org: IOrganisation) => {
        this.organisation.status = org.status;
        this.liveAnnouncer.announce($localize`Navigated to ${this.title.getTitle()}`);
        this.organisation = undefined;
        this.getOrganisation();
        this.toastService.showToast(
          $localize`Organisation (${this.organisationId}) was successfully enabled`,
        );
      });
  }

  disable(): void {
    this.organisation$ = this.organisationService.disableOrganisation(this.organisationId);
    this.organisation$
      .pipe(finalize(() => this.confirmDisableModal.close()))
      .subscribe((org: IOrganisation) => {
        this.organisation.status = org.status;
        this.liveAnnouncer.announce($localize`Navigated to ${this.title.getTitle()}`);
        this.organisation = undefined;
        this.getOrganisation();
        this.toastService.showToast(
          $localize`Organisation (${this.organisationId}) was successfully disabled`,
        );
      });
  }

  enableAddresses(): void {
    for (const address of this.organisation.addresses) {
      const address$: Observable<IAddress> = this.organisationService.enableAddress(
        address.addressUid,
      );
      address$.subscribe((ad: IAddress) => {
        address.status = ad.status;
      });
    }
  }

  enableContacts(contactUid: string, index: number): void {
    this.contact$ = this.organisationService.enableContact(contactUid);
    this.contact$.subscribe((ct: IContact) => {
      this.contacts[index].status = ct.status;
    });
  }

  confirmDisable(): void {
    this.confirmDisableModal.open();
  }

  confirmDelete(): void {
    this.confirmDeleteModal.open();
  }

  confirmEnable(): void {
    this.confirmEnableModal.open();
  }

  onModalEnableConfirmed(): void {
    this.enable();
  }

  onModalDisableConfirmed(): void {
    this.disable();
  }

  onModalDeleteConfirmed(): void {
    this.reason.markAsTouched();
    this.reason.updateValueAndValidity();
    if (!this.reason.valid) return;
    this.deleting = true;
    this.organisationService
      .hasChildren(this.organisation)
      .pipe(untilDestroyed(this))
      .subscribe(
        (hasChildren) => {
          if (hasChildren) {
            this.confirmDeleteModal.close();
            this.notifierService.error(
              $localize`Your organization cannot be deleted, as it has sub-organizations associated to it. Please delete the associated sub-organizations first.`,
            );
            this.deleting = false;
          } else {
            this.delete(this.reason.value);
          }
        },
        (err) => {
          this.deleting = false;
          throw err;
        },
      );
  }

  onResellerDetailsSave(formData: DetailResellerForm): void {
    const templateData = {
      ...formData.templateParameters,
      id: formData.organisation.domainName,
    };

    const setTemplateParametersCall = this.templateParametersService.isTemplateChanged(templateData)
      ? this.templateParametersService.setTemplateParameters(templateData)
      : of(EMPTY);

    const setTemplateLogoImageCall = this.templateParametersService.isLogoChanged(templateData.logo)
      ? this.templateParametersService.setTemplateLogoImage(templateData)
      : of(EMPTY);

    const setTemplateFaviconImageCall = this.templateParametersService.isFaviconChanged(
      templateData.favicon,
    )
      ? this.templateParametersService.setTemplateFaviconImage(templateData)
      : of(EMPTY);

    const organisationChangedData = this.getOrganisationChangedData(formData.organisation);
    const organisationData: IOrganisation = {
      ...organisationChangedData,
      entityUid: this.organisationId,
    };
    const updateOrganisationCall = !isEmpty(organisationChangedData)
      ? this.organisationService.update(organisationData)
      : of(EMPTY);

    forkJoin([
      setTemplateParametersCall,
      setTemplateLogoImageCall,
      setTemplateFaviconImageCall,
      updateOrganisationCall,
    ]).subscribe(
      () => {
        this.toastService.showToast($localize`Reseller details were successfully saved`);
      },
      (errorObject: Object) => {
        this.notifierService.error(
          $localize` There was an error while saving Reseller details. (Error code: ${get(
            errorObject,
            'error.status',
          )}.) Please try again later or contact support.`,
        );
      },
    );
  }

  showPoiCrossEntityAccess(): boolean {
    return this.organisationService.isPoiCrossEntityAccessEditable(this.organisation.entityType);
  }

  showMcr(): boolean {
    return (
      this.isMcrAvailable &&
      this.hasPermissionToViewMcr &&
      this.organisation?.entityType === EntityType.MERCHANT_SITE &&
      Boolean(this.ecomContract)
    );
  }

  get businessIdentifierTypes(): string[] {
    return this.organisation.businessIdentifiers?.map(
      (item) =>
        this.businessIdentifierTypeList
          .find((selectInput) => selectInput.id === item.identifierType)
          ?.text.concat(`(${item.value})`) || item.identifierType.concat(`(${item.value})`),
    );
  }

  private getAllSftpConnectionNames(): Observable<ISftpConnection[]> {
    return this.reportEngineService.getAllSftpConnectionDetails();
  }

  private isFeatureActive(feature: Features): boolean {
    return this.featureToggleService.isFeatureActive(feature);
  }

  private getOrganisationChangedData(formData: IOrganisation): IOrganisation {
    return omitBy(formData, (value: any, key: string) => {
      if (!this.organisation.hasOwnProperty(key) && !value) {
        return true;
      }

      return this.organisation[key] === value;
    });
  }

  private checkWhiteLabelingPermissions(): void {
    this.canEditWhiteLabel = this.restrictPermissionService.checkPermissions(
      organisationsPermissions.details,
      ['white-label'],
    );
  }

  private checkWhiteLabelFeatureState(features: AppPermission[]): void {
    this.isWhiteLabelFeatureActiveForOrg = features.some(
      (feature) => feature.featureId === Features.PortalWhiteLabeling,
    );
  }

  private delete(reason: string): void {
    this.organisationService.delete(this.organisation, reason).subscribe(() => {
      this.toastService.showToast($localize`Successfully deleted organisation!`);
      this.router.navigate(['/administration/organisations']);
    });
  }
}
