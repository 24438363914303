import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppLogoPublicTemplateComponent } from './app-logo-public-template.component';

@NgModule({
  declarations: [AppLogoPublicTemplateComponent],
  imports: [CommonModule],
  exports: [AppLogoPublicTemplateComponent],
})
export class AppLogoPublicTemplateModule {}
