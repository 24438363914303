import { Injectable } from '@angular/core';
import { UserRoleGroup } from '@portal/entity-services/interfaces/src/lib/users/enums/user-role-groups.enum';
import {
  RoleEditPermissionMatrix,
  RolesByGroup,
} from '@portal/entity-services/interfaces/src/lib/users/enums/user-role-types';
import { UserRole } from '@portal/entity-services/interfaces/src/lib/users/enums/user-role.enum';
import { rolePermissionMatrix } from 'configs/user-roles-matrices/user-roles-matrix';
import isEmpty from 'lodash-es/isEmpty';
import uniq from 'lodash-es/uniq';

@Injectable()
export class UserRoleEditorMatrix {
  get editPermissionMatrix(): RoleEditPermissionMatrix {
    return rolePermissionMatrix;
  }

  getEditableRoles(roles: string[]): UserRole[] {
    let editableRoles = [];
    roles.forEach((role) => {
      if (!isEmpty(rolePermissionMatrix[role])) {
        editableRoles = editableRoles.concat(rolePermissionMatrix[role]);
      }
    });

    editableRoles = this.checkAvailabilityForMerchantOrderAdmin(roles, editableRoles);

    return editableRoles;
  }

  getRolesByGroup(roles: string[], groups: UserRoleGroup[]): RolesByGroup {
    const editableRoles = this.getEditableRoles(roles);
    const groupedRoles = {};

    groups.forEach((group) => {
      const reg = new RegExp(`\^${group}`, 'i');
      groupedRoles[group] = uniq(editableRoles.filter((role) => reg.test(role)));
    });

    if (localStorage.getItem('hideDeveloperRoles')) {
      delete groupedRoles['DEVELOPER'];
    }
    return groupedRoles;
  }

  private checkAvailabilityForMerchantOrderAdmin(
    roles: string[],
    editableRoles: UserRole[],
  ): UserRole[] {
    if (
      roles.includes(UserRole.MerchantAdmin) &&
      !roles.includes(UserRole.MerchantOrderAdmin) &&
      !roles.includes(UserRole.VerifoneAdmin)
    ) {
      return editableRoles.filter((item) => item !== UserRole.MerchantOrderAdmin);
    }
    return editableRoles;
  }
}
