import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { IStorePagination, ITag, paginationInitialState } from '@portal/marketplace/api-interfaces';

export interface TagsState extends IStorePagination<ITag> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tags', idKey: 'id', resettable: true })
export class TagsStore extends EntityStore<TagsState, ITag> {
  constructor() {
    super(paginationInitialState);
  }
}
