<div class="card">
  <div class="card-content">
    <article class="media">
      <div class="media-left" [routerLink]="link">
        <figure class="device-image is-75x100" data-e2e="device-card-device-img">
          <div
            *ngIf="status === 'PENDING'"
            class="device-pending-label has-background-primary has-text-white tag"
            i18n
          >
            PENDING
          </div>
          <img
            src="{{ poiIcon }}.png"
            srcset="
              {{ poiIcon }}.png,
              {{ poiIcon }}-2x.png 2x
            "
            width="75"
            height="100"
            alt="{{ poi?.name }}"
            title="{{ poi?.name }}"
            loading="lazy"
          />
        </figure>
      </div>
      <div class="media-content" [routerLink]="link">
        <div class="content">
          <p>
            <small data-e2e="device-card-device-id">TID: {{ poi?.altVfiPoiId }}</small
            ><br />
            <small data-e2e="device-card-mid">MID: {{ poi?.entity?.altVfiEntityId }}</small>
            <br />
            <ng-container *ngIf="status !== 'PENDING' && poi?.device?.serialNumber">
              <small data-e2e="device-card-device-serial"
                >Serial: {{ poi?.device?.serialNumber }}</small
              ><br /></ng-container>
            <small data-e2e="device-card-device-merchant"
                   class="has-text-truncate"
                   [attr.title]="poi?.entity?.name"
              >Merchant: {{ merchantName }}</small
            ><br />
          </p>
          <p>
            <span
              class="tag"
              [ngClass]="{ 'is-warning': status === 'PENDING', 'is-success': status !== 'PENDING' }"
              data-e2e="device-card-device-status"
              >{{ status }}</span
            >
            <span *ngIf="status !== 'PENDING'" class="tag" data-e2e="device-card-device-type">{{
              poi?.device?.model?.deviceType
            }}</span>
          </p>
        </div>
      </div>
      <div class="media-right" *ngIf="hasSelection">
        <label class="checkbox">
          <input type="checkbox" (change)="onPoiSelect(poi, $event)" [checked]="isChecked" />
          <span></span>
        </label>
      </div>
    </article>
  </div>
</div>
