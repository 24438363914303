import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthorizationService } from '@portal/shared/auth/authorization/src/lib/authorization.service';
import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';
import { Features } from 'environments/enums/features.enum';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';
import { CustomDataService } from '@portal/shared/vui-http/src';
import { HttpClient } from '@angular/common/http';
import { HttpUrlGenerator } from '@ngrx/data';
import { CONSTANTS } from '@apps/portal/src/app/core/core.module';
@Injectable({
  providedIn: 'root',
})
export class PetroService extends CustomDataService<any> {
  emailValue;
  loading$: Observable<boolean>;
  permissions: any;
  permissionsUpdated = new Subject<any>();

  dateFilterFlag = false;
  checkHierarchyAPI: Boolean = false;
  isHierarchyData: Boolean = false;
  private setLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public authService: AuthorizationService,
    private featureToggleService: FeatureToggle,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super(CONSTANTS.DATA_SERVICE.PERMISSIONS_LITE_SERVICE, http, httpUrlGenerator);
    this.loading$ = this.setLoading$.asObservable();
  }

  getPermissions(): Observable<any> {
    return this.execute('GET', `${CONSTANTS.DATA_SERVICE.PERMISSIONS_LITE_SERVICE}`);
  }

  setPermissions(permissions): void {
    this.permissions = permissions;
    this.permissionsUpdated.next({ ...this.permissions });
  }

  setLoading(state: boolean): void {
    this.setLoading$.next(state);
  }

  setUserEmail(value: any): any {
    this.emailValue = value;
  }

  isHelpdeskUser(): boolean {
    return this.authService.getRoles().includes(UserRoles.PetroHelpDeskAdmin);
  }

  isPetroUser(): boolean {
    return (
      this.authService.getRoles().includes(UserRoles.PetroSiteAdmin) ||
      this.authService.getRoles().includes(UserRoles.PetroSiteUser) ||
      this.authService.getRoles().includes(UserRoles.MajorOilAdmin) ||
      this.authService.getRoles().includes(UserRoles.MajorOilUser) ||
      this.authService.getRoles().includes(UserRoles.PetroPartnerAdmin) ||
      this.authService.getRoles().includes(UserRoles.PetroHelpDeskAdmin) ||
      this.authService.getRoles().includes(UserRoles.CommanderOnboarder) ||
      this.authService.getRoles().includes(UserRoles.PetroSalesforceAdmin) ||
      this.authService.getRoles().includes(UserRoles.PetroMerchantOnboarder)
    );
  }

  isPartnerUser(): boolean {
    return this.authService.getRoles().includes(UserRoles.PetroPartnerAdmin);
  }

  isAdminUser(): boolean {
    return (
      this.authService.getRoles().includes(UserRoles.PetroSiteAdmin) ||
      this.authService.getRoles().includes(UserRoles.MajorOilAdmin)
    );
  }

  isSubUser(): boolean {
    return (
      this.authService.getRoles().includes(UserRoles.PetroSiteUser) ||
      this.authService.getRoles().includes(UserRoles.MajorOilUser)
    );
  }

  isMultiUser(): boolean {
    return (
      this.authService.getRoles().includes(UserRoles.PetroSiteAdmin) &&
      this.authService.getRoles().includes(UserRoles.MajorOilAdmin)
    );
  }

  isPetroAdminOrUser(): boolean {
    return (
      this.authService.getRoles().includes(UserRoles.PetroSiteAdmin) ||
      this.authService.getRoles().includes(UserRoles.PetroSiteUser)
    );
  }

  isMajorOilAdminOrUser(): boolean {
    return (
      this.authService.getRoles().includes(UserRoles.MajorOilAdmin) ||
      this.authService.getRoles().includes(UserRoles.MajorOilUser)
    );
  }
  isMajorOilAdmin(): boolean {
    return this.authService.getRoles().includes(UserRoles.MajorOilAdmin);
  }
  isMajorOilUser(): boolean {
    return this.authService.getRoles().includes(UserRoles.MajorOilUser);
  }
  getPermissionListener(): Observable<any> {
    return this.permissionsUpdated.asObservable();
  }

  getPermissionObject(): any {
    return this.permissions;
  }

  showReportsMenu(): boolean {
    return this.showSiteAssetsMenu() || this.showTlogMenu() || this.summaryReportMenu();
  }

  showSiteAssetsMenu(): boolean {
    if (
      (this.isAdminUser() || this.isHelpdeskUser()) &&
      this.featureToggleService.isFeatureActive(Features.SiteAssets)
    ) {
      return true;
    }
    if (this.isSubUser() && this.featureToggleService.isFeatureActive(Features.SiteAssets)) {
      const reports = this.permissions?.permission_menu?.reports
        ? this.permissions?.permission_menu?.reports?.filter((i) => i.sub_menu === 'siteAssets')
        : '';
      return reports.length ? reports[0].view_access || reports[0].edit_access : false;
    }
    return false;
  }

  showTlogMenu(): boolean {
    if (
      (this.authService.getRoles().includes(UserRoles.PetroSiteUser) ||
        this.authService.getRoles().includes(UserRoles.PetroSiteAdmin)) &&
      this.featureToggleService.isFeatureActive(Features.TlogViewer)
    ) {
      const tlog = this.permissions?.permission_menu?.reports
        ? this.permissions?.permission_menu?.reports?.filter((i) => i.sub_menu === 'tlog')
        : '';
      return tlog.length ? tlog[0].view_access || tlog[0].edit_access : false;
    }
    return false;
  }

  summaryReportMenu(): boolean {
    if (
      (this.authService.getRoles().includes(UserRoles.PetroSiteAdmin) ||
        this.authService.getRoles().includes(UserRoles.PetroSiteUser)) &&
      this.featureToggleService.isFeatureActive(Features.SummaryReport)
    ) {
      const summaryReport = this.permissions?.permission_menu?.reports
        ? this.permissions?.permission_menu?.reports?.filter((i) => i.sub_menu === 'summaryReport')
        : '';
      return summaryReport.length
        ? summaryReport[0].view_access || summaryReport[0].edit_access
        : false;
    }
    return false;
  }

  // method is showTlogMenu() because it is having the same permission as tlogviewer
  departmentReportMenu(): boolean {
    if (this.featureToggleService.isFeatureActive(Features.DepartmentReport)) {
      return this.showTlogMenu();
    }
  }
  dcrReportMenu(): boolean {
    if (this.featureToggleService.isFeatureActive(Features.DcrReport)) {
      return this.showTlogMenu();
    }
  }
  fuelReportMenu(): boolean {
    if (this.featureToggleService.isFeatureActive(Features.FuelReport)) {
      return this.showTlogMenu();
    }
  }
  // method is showTlogMenu() because it is having the same permission as tlogviewer
  pluReportMenu(): boolean {
    if (this.featureToggleService.isFeatureActive(Features.PluReport)) {
      return this.showTlogMenu();
    }
  }

  showAccountsMenu(): boolean {
    if (this.featureToggleService.isFeatureActive(Features.Accounts)) {
      return this.isHelpdeskUser();
    }
  }

  showRolesMenu(): boolean {
    if (this.isAdminUser() && this.featureToggleService.isFeatureActive(Features.Roles)) {
      return true;
    }
    if (this.isSubUser() && this.featureToggleService.isFeatureActive(Features.Roles)) {
      const roles = this.permissions.permission_menu.administration.filter(
        (i) => i.sub_menu === 'roles',
      );
      return roles.length ? roles[0].view_access || roles[0].edit_access : false;
    }
    return false;
  }

  showHierarchyMenu(): boolean {
    return (
      (this.isSubUser() || this.isAdminUser() || this.isPartnerUser()) &&
      this.featureToggleService.isFeatureActive(Features.Hierarchy)
    );
  }

  showUsersMenu(): boolean {
    if (
      (this.isAdminUser() || this.isHelpdeskUser()) &&
      this.featureToggleService.isFeatureActive(Features.Users)
    ) {
      return true;
    }
    if (this.isSubUser() && this.featureToggleService.isFeatureActive(Features.Users)) {
      const users = this.permissions.permission_menu.administration.filter(
        (i) => i.sub_menu === 'users',
      );
      return users.length ? users[0].view_access || users[0].edit_access : false;
    }
    return false;
  }

  showSitesMenu(): boolean {
    if (
      (this.isAdminUser() || this.isHelpdeskUser() || this.isPartnerUser()) &&
      this.featureToggleService.isFeatureActive(Features.Sites)
    ) {
      return true;
    }
    if (this.isSubUser() && this.featureToggleService.isFeatureActive(Features.Sites)) {
      const site = this.permissions.permission_menu.administration.filter(
        (i) => i.sub_menu === 'sites',
      );
      return site.length ? site[0].view_access || site[0].edit_access : false;
    }
    return false;
  }

  showPartnerMenu(): boolean {
    return this.authService.getRoles().includes(UserRoles.PetroSiteAdmin);
  }

  //show only if we have edit site permission
  showBulkReportMenu(): boolean {
    if (this.isAdminUser() && this.featureToggleService.isFeatureActive(Features.BulkSites)) {
      return true;
    }
  }

  // for mobile payment and loyalty config
  showPaymentPartnerConfigurationMenu(): boolean {
    return this.hasReadOrWritePermissionForPaymentPartnerConfigs();
  }

  showPetroPaymentPartnerConfigurationMenu(): boolean {
    if (this.featureToggleService.isFeatureActive(Features.MobilePayment)) {
      return this.hasReadOrWritePermissionForPaymentPartnerConfigs();
    }
    return false;
  }

  showTablesMenu(): boolean {
    if (this.featureToggleService.isFeatureActive(Features.ViperTable)) {
      return true;
    }
  }

  showSiteView(): boolean {
    if (this.featureToggleService.isFeatureActive(Features.ViperSiteView)) {
      return true;
    }
  }

  showLoyalty(): boolean {
    return this.featureToggleService.isFeatureActive(Features.Loyalty);
  }

  isPaymentPartnerConfigurationEditPermission(): boolean {
    if (this.isAdminUser()) {
      return true;
    }
    if (this.isSubUser() && this.permissions) {
      const paymentPartnerConfig = this.permissions.permission_menu.configuration.find(
        (item) => item.sub_menu === 'paymentPartnerConfiguration',
      );
      return paymentPartnerConfig?.edit_access || false;
    }
    return false;
  }

  showSiteConfig(): boolean {
    if (this.featureToggleService.isFeatureActive(Features.SiteConfiguration)) {
      return this.isPetroAdminOrUser() || this.isMajorOilAdminOrUser();
    }
  }

  showTerms(): void {
    if (this.isPartnerUser()) {
      window.open(
        'assets/html/petro/VF_Petro_and_Convenience_Retail_Site_Solution_T&Cs_PARTNER_T&Cs.pdf',
        '_blank',
      );
    } else {
      window.open(
        'assets/html/petro/Verifone_Petroleum_and_Convenience_Retail_Site_Solution_Terms_and_Conditions(30_June_2023).pdf',
        '_blank',
      );
    }
  }

  hasReadOrWritePermissionForPaymentPartnerConfigs(): boolean {
    if (this.isAdminUser()) {
      return true;
    }

    if (this.isSubUser() && this.permissions) {
      const paymentConfigPermissions = this.permissions?.permission_menu.configuration.filter(
        (i) => i.sub_menu === 'paymentPartnerConfiguration',
      );
      return (
        paymentConfigPermissions?.length &&
        (paymentConfigPermissions[0].edit_access || paymentConfigPermissions[0].view_access)
      );
    }
    return false;
  }
}
