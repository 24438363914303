import { Injectable } from '@angular/core';
import { QueryParams } from '@ngrx/data';
import {
  ConnectionMode,
  EntityStatus,
  ES_CONSTANTS as CONSTANTS,
  IAccountDetails,
  IAcquirerConfig,
  IBankAccount,
  IClearing,
  IConfigOnboarding,
  IPaymentContract,
  IPointInteraction,
  IProcessorDetails,
  IProcessorConfig,
  PaymentType,
  ProcessorType,
  TransactionType,
  TransactionTypes,
  ISurcharge,
  ICardTableGroup,
} from '@portal/entity-services/interfaces';

import { CountryService, CurrencyService, DateService, ICountry } from '@portal/shared/helpers';
import { IQueryParams, IResultsWithCount, QueryParamsService } from '@portal/shared/ui/table';
import { ICount, VuiHttpService } from '@portal/shared/vui-http';
import assign from 'lodash-es/assign';
import get from 'lodash-es/get';
import isArray from 'lodash-es/isArray';
import omit from 'lodash-es/omit';
import { BehaviorSubject, concat, forkJoin, iif, Observable, of, Subject } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { ConnectionModes } from '../components/form/form-cartes-bancaires/connection-mode.list';
import { Erts } from '../components/form/form-cartes-bancaires/ert.list';
import { BankAccountTypes } from '../components/form/form-paypal-onboarding/bank-account-type.list';
import { FeeTypes } from '../lists/fee-types.list';
import { RateTypes } from '../lists/rate-types.list';
import { PaymentTypes } from '../components/form/payment-type.list';
import { ProcessorTypes } from '../components/form/processor-type.list';
import { SalesChannels } from '../components/form/sales-channel.list';
import { CustomPaymentContractDataService } from './payment-contract-data.service';
import { SearchSelector } from '@portal/shared/ui/filter/src';
import { HttpParams } from '@angular/common/http';
import { SettlementTypes } from '../components/form/settlement-type.list';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class PaymentContractService {
  entities$ = new Subject<IPaymentContract[]>();
  paymentContract: IPaymentContract;
  loading$: Observable<boolean>;
  private setLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private entities: Set<IPaymentContract> = new Set<IPaymentContract>();
  private params: string | QueryParams;

  constructor(
    private customDataService: CustomPaymentContractDataService,
    private dateService: DateService,
    private settlementTypes: SettlementTypes,
    private erts: Erts,
    private connectionModes: ConnectionModes,
    private salesChannels: SalesChannels,
    private processorTypes: ProcessorTypes,
    private paymentTypes: PaymentTypes,
    private transactionTypes: TransactionTypes,
    private httpService: VuiHttpService,
    private feeTypes: FeeTypes,
    private rateTypes: RateTypes,
    private countryService: CountryService,
    private bankAccountTypes: BankAccountTypes,
    private currencyService: CurrencyService,
  ) {
    this.loading$ = this.setLoading$.asObservable();
  }

  setLoading(state: boolean): void {
    this.setLoading$.next(state);
  }

  getPpcByID(id: string): Observable<IPaymentContract> {
    return this.customDataService.getByKeyWithQuery(id, {});
  }

  getByKey(id: string): Observable<IPaymentContract> {
    this.setLoading(true);
    return this.customDataService.getByKeyWithQuery(id, { populateEntity: 'true' }).pipe(
      switchMap((contract: IPaymentContract) =>
        forkJoin([
          of(contract),
          iif(
            () =>
              (contract.processor.type === ProcessorType.Paypal ||
                contract.processor.type === ProcessorType.Crypto ||
                contract.processor.type === ProcessorType.AliPay ||
                contract.processor.type === ProcessorType.WeChat ||
                contract.processor.type === ProcessorType.KlarnaEcom ||
                contract.processor.type === ProcessorType.Affirm ||
                contract.processor.type === ProcessorType.KlarnaQr ||
                contract.processor.type === ProcessorType.OpOnlinePayment ||
                contract.processor.type === ProcessorType.Blik ||
                contract.processor.type === ProcessorType.VerifoneEu) &&
              !!contract?.settlement?.clearing?.accountUid,
            this.customDataService.getAccount(contract?.settlement?.clearing?.accountUid),
            of(null),
          ),
        ]),
      ),
      map(([contract, bankAccount]: [IPaymentContract, IBankAccount]) => {
        contract = this.formatContract(contract, bankAccount);
        return contract;
      }),
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  getDetailed(id: string): Observable<any> {
    this.setLoading(true);
    return this.customDataService.getDetailed(id).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  getWithQuery(params: string | QueryParams): Observable<IPaymentContract[]> {
    this.entities.clear();
    this.params = params;
    this.setLoading(true);
    return this.customDataService.getWithQuery(params).pipe(
      map((contracts) => contracts.map((contract) => this.formatContract(contract))),
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  getTotalCount(params?: QueryParams): Observable<ICount> {
    return this.customDataService.getTotalCount(params);
  }

  /**
   * Payment Contract to update Status
   * @param paymentContract
   */
  updateContract(paymentContract: IPaymentContract): void {
    this.paymentContract = this.formatContract(paymentContract);
  }

  /**
   * Get this contract in the list.component to update the displayed list
   * @returns {IPaymentContract}
   */
  getContract(): IPaymentContract {
    return this.paymentContract;
  }

  getResultsWithCount(params: IQueryParams): Observable<IResultsWithCount<IPaymentContract>> {
    const query = QueryParamsService.toQueryParams(params);
    const filterParams = QueryParamsService.getFilterParams(params.searchCriteria);
    const updatedSearchCriteriaKeys = this.updateSearchCriteriaKeys(filterParams);
    const finalFilterParams = omit(assign(query, updatedSearchCriteriaKeys), ['searchCriteria']);

    const paymentContract$: Observable<IPaymentContract[]> = this.getWithQuery(finalFilterParams);
    const paymentContractCount$: Observable<ICount> = this.getTotalCount(finalFilterParams);
    this.setLoading(true);

    return forkJoin([paymentContract$, paymentContractCount$]).pipe(
      map((results) => {
        return {
          results: results[0],
          count: results[1].count,
        };
      }),
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  removePpcLinkPoi(
    poi: IPointInteraction,
    contract: IPaymentContract,
    handler: string,
  ): Observable<IPointInteraction | string> {
    return concat(
      this.updatePPCHandle(contract.contractUid, poi.poiUid, handler, false),
      this.deletePoiRelationship(poi, contract.contractUid),
    );
  }

  updatePPCHandle(
    contractUid: string,
    poiUid: string,
    handler: string,
    isActive: boolean,
  ): Observable<IPointInteraction> {
    return this.customDataService.updatePPCHandle(contractUid, poiUid, handler, isActive).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  getPoiRelationships(id: string): Observable<IPointInteraction[]> {
    return this.customDataService.getPoiRelationships(id).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  getPoiRelationshipsWithPayload(
    id: string,
    searchPayload: HttpParams,
  ): Observable<IPointInteraction[]> {
    return this.customDataService.getPoiRelationshipsWithPayload(id, searchPayload).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  getCountOfPoiRelationships(id: string): Observable<number> {
    return this.customDataService.getCountOfPoiRelationships(id);
  }

  addPoiRelationship(poi: Partial<IPointInteraction>, id: string): Observable<IPointInteraction> {
    return this.customDataService.addPoiRelationship(poi, id).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  deletePoiRelationship(poi: Partial<IPointInteraction>, id: string): Observable<string> {
    return this.customDataService.deletePoiRelationship(poi, id).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  initiateMerchantOnboardingEcommerce(contractUid: string): Observable<IConfigOnboarding> {
    return this.customDataService.initiateMerchantOnboardingEcommerce(contractUid).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  updateProcessorConfig(
    config: IProcessorConfig,
    id: string,
    activeLoader?: boolean,
  ): Observable<IPaymentContract> {
    return this.customDataService.updateProcessorConfig(config, id).pipe(
      finalize(() => {
        if (!activeLoader) this.setLoading(false);
      }),
    );
  }

  updateProcessorAccountDetails(
    accountDetails: IAccountDetails,
    id: string,
    activeLoader?: boolean,
  ): Observable<IPaymentContract> {
    return this.customDataService.updateProcessorAccountDetails(accountDetails, id).pipe(
      finalize(() => {
        if (!activeLoader) this.setLoading(false);
      }),
    );
  }

  update(ppc: IPaymentContract): Observable<any> {
    return this.httpService
      .put(`${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT}${ppc.contractUid}`, ppc)
      .pipe(
        map((response: any) => {
          if (!response) {
            return null;
          }
          return response;
        }),
        finalize(() => {
          this.setLoading(false);
        }),
      );
  }

  enable(id: string): Observable<IPaymentContract> {
    return this.customDataService.enableDisablePaymentContract(id, EntityStatus.Active.toString());
  }

  disable(id: string): Observable<IPaymentContract> {
    return this.customDataService.enableDisablePaymentContract(
      id,
      EntityStatus.Inactive.toString(),
    );
  }

  resetClearingDelay(clearing: IClearing): void {
    clearing.delay = null;
    clearing.commonDelay = '';
    clearing.paymentType = [];
  }

  getAcquirer(acquirer: string): Observable<IAcquirerConfig> {
    return this.customDataService.getAcquirer(acquirer).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  getProcessor(processor: string): Observable<IProcessorDetails> {
    return this.customDataService.getProcessor(processor).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  hasCutoverTimeConfig(processor: ProcessorType): boolean {
    return [ProcessorType.Barclays].includes(processor);
  }

  updateSurcharge(id: string, surchargePayload: ISurcharge[]): Observable<ISurcharge> {
    return this.customDataService.updateSurcharge(id, surchargePayload);
  }

  getSurcharge(id: string): Observable<ISurcharge[]> {
    return this.customDataService.getSurcharge(id);
  }

  getBinRules(params): Observable<{ response: string[] }> {
    return this.customDataService.getBinRules(params);
  }

  getCardTableGroup(id: string): Observable<ICardTableGroup> {
    return this.customDataService.getCardTableGroup(id);
  }

  protected formatContract(
    contract: IPaymentContract,
    bankAccount?: IBankAccount,
  ): IPaymentContract {
    contract.startDate = new Date(contract.startDate);
    contract.endDate = contract.endDate ? new Date(contract.endDate) : null;
    contract.formattedStartDate = this.dateService.toLocalisedShortString(contract.startDate);
    contract.formattedEndDate = contract.endDate
      ? this.dateService.toLocalisedShortString(contract.endDate)
      : '';
    contract.duration = contract.formattedEndDate
      ? `${contract.formattedStartDate} - ${contract.formattedEndDate}`
      : $localize`:Contract duration:${contract.formattedStartDate}:startDate: - ongoing`;
    contract.tidList = contract.tidList ? contract.tidList : [];
    contract.formattedSalesChannel =
      contract.salesChannels?.length > 1
        ? contract.salesChannels.join(', ')
        : contract.salesChannels?.toString();
    contract.formattedPaymentType = contract.paymentType.map((type: PaymentType) => {
      return this.paymentTypes.keyValue[type] || type;
    });
    if (
      contract?.contractExtendedStoredCredentialSettings?.allowedLimitedToSignupProcessingModels
        ?.length
    ) {
      contract.formattedAllowedLimitedToSignupProcessingModels =
        contract?.contractExtendedStoredCredentialSettings?.allowedLimitedToSignupProcessingModels
          ?.length > 1
          ? contract?.contractExtendedStoredCredentialSettings?.allowedLimitedToSignupProcessingModels?.join(
              ', ',
            )
          : contract?.contractExtendedStoredCredentialSettings?.allowedLimitedToSignupProcessingModels?.toString();
    }
    contract.processor.formattedType =
      this.processorTypes.keyValue[contract.processor.type] || contract.processor.type;
    if (contract.processor.config) {
      contract.processor.config = this.formatCarterBancairesConfig(contract.processor.config);
    }

    if (contract.settlement?.defaultTime) {
      if (contract.processor.type === ProcessorType.ElavonIsoUk) {
        const time = new Date(contract.settlement?.defaultTime);
        const hours = time.getUTCHours();
        const minutes = time.getUTCMinutes();
        const dateTimeString = `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')} UTC`;
        contract.settlement.defaultTime = dateTimeString;
      } else if (this.hasCutoverTimeConfig(contract.processor?.type)) {
        this.getProcessor(contract.processor?.type).subscribe((procConfig) => {
          if (procConfig && procConfig.cutoverTimeZone && contract.settlement?.defaultTime?.includes('T')) {
            contract.settlement.defaultTime =
              contract.settlement.defaultTime.split('T')[1].slice(0, 5) + ' ' + procConfig.cutoverTimeZone;
          } else if (!contract.settlement?.defaultTime) {
            contract.settlement.defaultTime = '-';
          }
        });
      }
    }

    if (contract.currency.length) {
      contract.formattedCurrency = contract.currency.map((currency: string) => {
        const currencyItems = this.currencyService.currenciesCode.get(currency);
        return currencyItems
          ? `${currencyItems.name} [${currencyItems.code}]`
          : `${currency} [${currency}]`;
      });
    }

    if (contract.status) {
      contract.status = `${contract.status.toString().charAt(0).toUpperCase()}${contract.status
        .toString()
        .slice(1)
        .toLowerCase()}` as EntityStatus;
    }

    if (contract.fees && contract.fees.length) {
      contract.fees = contract.fees.map((fee) => {
        const formatTransactionType = (transactionType): string[] => {
          return !Array.isArray(transactionType)
            ? [this.transactionTypes.keyValue[transactionType]]
            : transactionType.map((type: TransactionType) => {
                return this.transactionTypes.keyValue[type] || type;
              });
        };

        return {
          ...fee,
          formattedType: this.feeTypes.keyValue[fee.type],
          formattedRateType: this.rateTypes.keyValue[fee.rateType],
          formattedPaymentType: this.paymentTypes.keyValue[fee.paymentType] || fee.paymentType,
          formattedTransactionType: fee.transactionType
            ? formatTransactionType(fee.transactionType)
            : '',
        };
      });
    }

    if (bankAccount) {
      contract.bankAccount = bankAccount;
      contract.bankAccount.formattedAccountType =
        this.bankAccountTypes.keyValue[contract.bankAccount.accountType];

      if (contract.bankAccount.country) {
        const country: ICountry = this.countryService.countriesAlpha3.get(
          contract.bankAccount.country,
        );
        contract.bankAccount.countryName = country.name;
      }
    }

    if (get(contract, 'settlement.clearing', '')) {
      contract.settlement.clearing.delay = contract.settlement.clearing?.delay?.map((info) => {
        return {
          ...info,
          formattedPaymentType: this.paymentTypes.keyValue[info.paymentType],
        };
      });

      contract.settlement.clearing.paymentType = contract.settlement.clearing?.delay?.map(
        (info) => {
          return info.paymentType;
        },
      );
      //all delays share the same value
      const commonDelay =
        contract.settlement.clearing?.delay &&
        contract.settlement.clearing?.delay?.[0] &&
        contract.settlement.clearing?.delay?.[0]?.delay;
      contract.settlement.clearing.commonDelay = commonDelay;
    }

    const settlementType = contract.settlement && contract.settlement.settlementType;
    contract.formattedSettlementType =
      this.settlementTypes.keyValue[settlementType] || settlementType;

    this.entities.add(contract);
    return contract;
  }

  protected formatCarterBancairesConfig(config: IProcessorConfig): IProcessorConfig {
    if (config.usualConnectionMode) {
      config.formattedConnectionMode = this.connectionModes.keyValue[config.usualConnectionMode];
    }

    if (config.usualConnectionMode === ConnectionMode.IP) {
      const [ip, port] = config.parameterDownloadCommunicationDetails.split(' ');
      config.ipPort = port;
      config.parameterDownloadCommunicationDetails = ip;
    }

    const ert = isArray(config.ert) ? config.ert[0] : config.ert;
    config.formattedErt = this.erts.keyValue[ert] || ert;
    return config;
  }

  private updateSearchCriteriaKeys(queryParams: QueryParams): QueryParams {
    const newFilterParams = {};
    for (const param in queryParams) {
      newFilterParams[param === SearchSelector.EntityId ? SearchSelector.ParentIds : param] =
        queryParams[param];
      newFilterParams[param === SearchSelector.Acquirer ? SearchSelector.ServiceProvider : param] =
        queryParams[param];
    }
    delete newFilterParams[SearchSelector.EntityId];
    delete newFilterParams[SearchSelector.Acquirer];
    return newFilterParams;
  }

  getFullDate(cutoffTime: string): string {
    return new Date().toISOString().slice(0, 10) + 'T' + cutoffTime.slice(0, 5) + ':00Z';
  }
}
