<div class="dropdown" [class.is-active]="isOpen" data-e2e="button-entity-dropdown">
  <div class="dropdown-trigger">
    <button
      [attr.aria-expanded]="isOpen"
      class="button is-chip is-size-7-mobile"
      [class.is-applied]="selectedEntities.size"
      [class.is-active]="isOpen"
      data-e2e="button-entity-filter"
      [attr.aria-expanded]="isOpen"
      (click)="itemToggled.emit()"
      type="button"
      title="{{ headerText }}"
    >
      <span data-e2e="button-entity-filter-text">{{ headerText }}</span>
      <span data-e2e="button-entity-filter-icon" class="icon">
        <i class="fas fa-chevron-down"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu">
    <div class="dropdown-content">
      <div
        class="has-border-bottom"
        [ngClass]="{ 'is-flex': showQuickPicks, 'pt-3': !isSelectorsVisible && showQuickPicks }"
      >
        <div *ngIf="showQuickPicks" class="date-picker-sidebar has-border-right">
          <div>
            <div class="dropdown-item" *ngIf="isSelectorsVisible" data-e2e="date-filter-selectors">
              <label class="label">
                <span i18n>Date</span>
              </label>
              <portal-search-filter-radio
                *ngFor="let selector of selectors"
                [id]="selector.id"
                [text]="selector.label"
                [radioName]="radioName"
                [selectedEntities]="selectedRadioMap"
                [refresh$]="refreshRadioButton$"
                (selected)="onRadioSelected(selector)"
              ></portal-search-filter-radio>
            </div>
            <label class="label pl-3">
              <span i18n>Interval</span>
            </label>

            <div *ngFor="let quickPick of quickPicksList">
              <a
                tabindex="0"
                class="dropdown-item py-2"
                attr.data-e2e="{{ quickPick.id }}-quick-pick-option"
                [ngClass]="{ 'is-active': selectedQuickPick === quickPick.id }"
                (click)="onQuickPickSelect(quickPick.id)"
                (keydown.enter)="onQuickPickSelect(quickPick.id)"
                title="{{ quickPick.text }}"
              >
                <span>{{ quickPick.text }}</span>
              </a>
            </div>
          </div>
        </div>

        <div
          [ngClass]="{ 'dropdown-item': !showQuickPicks }"
          class="is-flex-grow-2"
        >
          <portal-date-picker
            [fromDate]="fromDate"
            [toDate]="toDate"
            (fromDateApplied)="onFromDateApplied($event)"
            (toDateApplied)="onToDateApplied($event)"
            (datePickerErrors)="onDatePickerErrors($event)"
            [futureMaxDate]="maxDate"
          ></portal-date-picker>
        </div>
      </div>

      <div class="is-flex p-3">
        <button
          class="button is-primary"
          data-e2e="button-filter"
          (click)="onFilterApplied()"
          [disabled]="!areInputsValid()"
          type="button"
          i18n
          title="Apply"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</div>
