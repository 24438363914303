<portal-table
  [items]="bundles"
  [loading$]="loading$"
  [tableConfig]="tableConfig"
  [filter]="filter"
  rowLinkHref="{{ rowLinkHref }}"
  rowLinkHeading="Link to bundle details"
  i18n-rowLinkHeading="Link to bundle page"
  loadingMessage="Loading Bundles"
  i18n-loadingMessage="Loading Bundles"
  notFoundMessage="Bundles coming soon!"
  i18n-notFoundMessage="Bundles coming soon!"
  (sortChanged)="onSortChanged($event)"
>
</portal-table>
<ng-template #onetimeCell let-bundle="data">
  $+{{ bundle.oneTimePrice }}
</ng-template>
<ng-template #recurringCell let-bundle="data">
  $+{{ bundle.recurringPrice }}
</ng-template>
