import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedAuthAuthorizationModule } from '@portal/shared/auth/authorization';
import { AppCardTemplateComponent } from './app-card-template/app-card-template.component';
import { AppCardTemplateModule } from './app-card-template/app-card-template.module';
import { AppCatalogTableTemplateComponent } from './app-catalog-table-template/app-catalog-table-template.component';
import { AppCatalogTableTemplateModule } from './app-catalog-table-template/app-catalog-table-template.module';
import { AppPageTemplateComponent } from './app-page-template/app-page-template.component';
import { AppPageTemplateModule } from './app-page-template/app-page-template.module';

@NgModule({
  imports: [
    CommonModule,
    AppCardTemplateModule,
    AppCatalogTableTemplateModule,
    AppPageTemplateModule,
    SharedAuthAuthorizationModule,
  ],
  declarations: [],
  exports: [AppCardTemplateComponent, AppCatalogTableTemplateComponent, AppPageTemplateComponent],
})
export class AppCatalogTemplatesModule {}
