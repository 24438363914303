import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PublicAppPageModule } from './public-app-page/public-app-page.module';
import { PublicMainComponent } from './public-main/public-main.component';
import { PublicMainModule } from './public-main/public-main.module';
import { PublicComponent } from './public.component';
import { PublicRoutes } from './public.routing';

@NgModule({
  declarations: [PublicComponent],
  imports: [CommonModule, PublicRoutes, PublicAppPageModule, PublicMainModule],
  exports: [PublicComponent, PublicMainComponent],
})
export class PublicModule {}
