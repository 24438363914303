import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import environment from '@environments';
import { IApp } from '@marketplace/api-interfaces/src';
import { Observable, from } from 'rxjs';

@Component({
  selector: 'marketplace-app-tag-logo-template',
  templateUrl: './app-tag-logo-template.component.html',
  styleUrls: ['./app-tag-logo-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTagLogoTemplateComponent implements OnInit, OnChanges {
  @ViewChild('appIcon') appIcons: ElementRef<any>;
  @Input() app: IApp;

  isPublic = true;
  publicImageSrc = 'assets/images/img-placeholder.png';

  constructor() { }

  ngOnInit() {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.app.currentValue) {
      const isPublic = !!changes.app.currentValue.isPublic;

      if (isPublic == null) {
        if (!this.hasPublicUrl()) {
          this.isPublic = true;
        }
      } else if (isPublic) {
        this.isPublic = true;
        if (this.hasPublicUrl()) {
          this.publicImageSrc = `${environment.API_ENDPOINT.PUBLIC_APPMARKET.publicBucketUrl}/app_icons/${this.app.app.id}/${this.app.id}/${this.app.appIcons[0].fileName}`;
        }
      } else if (isPublic === false && !this.hasPublicUrl()) {
        // app has no image and it's not public
        this.isPublic = true;
      } else {
        this.loadImageFromCatalog();
      }
    }
  }

  handleMissingImage(): void {
    this.loadImageFromCatalog();
  }

  private loadImageFromCatalog(): void {
    this.isPublic = false;
    this.take(this.app.appIcons[0].publicUrl).subscribe(
      (image) => this.drawImage(image),
      () => {
        this.isPublic = true;
        this.publicImageSrc = 'assets/images/img-placeholder.png';
      },
    );
  }

  private hasPublicUrl(): boolean {
    return this.app.appIcons && this.app.appIcons.length !== 0;
  }

  private take(url: string): Observable<any> {
    const accessToken = JSON.parse(localStorage.getItem('access_token'));

    const obs = from(
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          throw new Error(`Response status: ${response.status}`);
        })
        .catch(() => {}),
    );

    return obs;
  }

  private drawImage(blob: Blob): void {
    if (blob == null) {
      return;
    }

    createImageBitmap(blob).then((image) => {
      this.draw(image);
    });
  }

  private draw(img: ImageBitmap | HTMLImageElement): void {
    this.appIcons.nativeElement.width = img.width;
    this.appIcons.nativeElement.height = img.height;
    const ctx = this.appIcons.nativeElement.getContext('2d');

    ctx.drawImage(img, 0, 0, img.width, img.height);
  }
}
