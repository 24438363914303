export enum TransactionType {
  Authorisation = 'AUTHORISATION',
  Balance = 'BALANCE',
  Cancel = 'CANCEL',
  Capture = 'CAPTURE',
  CardActivation = 'CARD_ACTIVATION',
  CardDeactivation = 'CARD_DEACTIVATION',
  CardVerification = 'CARD_VERIFICATION',
  CashAdvance = 'CASH_ADVANCE',
  CashDeposit = 'CASH_DEPOSIT',
  ClearBatch = 'CLEAR_BATCH',
  Declined = 'DECLINED',
  DelayedCharge = 'DELAYED_CHARGE',
  ForexRate = 'FOREX_RATE',
  Load = 'LOAD',
  NoShow = 'NO_SHOW',
  NonFinancial = 'NON_FINANCIAL',
  PreAuth = 'PREAUTH',
  PreAuthIncrement = 'PREAUTH_INCREMENT',
  PreAuthCompletion = 'PREAUTH_COMPLETION',
  PreInquiry = 'PREAUTH_INQUIRY',
  PreUpload = 'PREAUTH_UPLOAD',
  PreReversal = 'PREAUTH_REVERSAL',
  Settlement = 'SETTLEMENT',
  Rate = 'RATE',
  Refund = 'REFUND',
  RefundVoid = 'REFUND_VOID',
  Reversal = 'REVERSAL',
  Sale = 'SALE',
  Unload = 'UNLOAD',
  Void = 'VOID',
  Reauthorisation = 'REAUTHORISATION',
  Extend = 'EXTEND',
  FundsTransfer = 'FUNDS_TRANSFER',
  Hold = 'HOLD',
  Payout = 'PAYOUT',
  Release = 'RELEASE',
  Chargeback = 'CHARGEBACK',
  DisputeDebit = 'DISPUTE_DEBIT',
  DisputeCredit = 'DISPUTE_CREDIT',
  RedemptionOrder = 'REDEMPTION_ORDER',
}
