import { Injectable } from '@angular/core';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({
  providedIn: 'root',
})
export class FirstPartOfSubdivisionsList {
  list: ISelectInput[];
  keyValue = {
    ['AF-BAL']: $localize`Balkh`,
    ['AF-BAM']: $localize`Bāmyān`,
    ['AF-BDG']: $localize`Bādghīs`,
    ['AF-BDS']: $localize`Badakhshān`,
    ['AF-BGL']: $localize`Baghlān`,
    ['AF-DAY']: $localize`Dāykundī`,
    ['AF-FRA']: $localize`Farāh`,
    ['AF-FYB']: $localize`Fāryāb`,
    ['AF-GHA']: $localize`Ghaznī`,
    ['AF-GHO']: $localize`Ghōr`,
    ['AF-HEL']: $localize`Helmand`,
    ['AF-HER']: $localize`Herāt`,
    ['AF-JOW']: $localize`Jowzjān`,
    ['AF-KAB']: $localize`Kābul`,
    ['AF-KAN']: $localize`Kandahār`,
    ['AF-KAP']: $localize`Kāpīsā`,
    ['AF-KDZ']: $localize`Kunduz`,
    ['AF-KHO']: $localize`Khōst`,
    ['AF-KNR']: $localize`Kunar`,
    ['AF-LAG']: $localize`Laghmān`,
    ['AF-LOG']: $localize`Lōgar`,
    ['AF-NAN']: $localize`Nangarhār`,
    ['AF-NIM']: $localize`Nīmrōz`,
    ['AF-NUR']: $localize`Nūristān`,
    ['AF-PAN']: $localize`Panjshayr`,
    ['AF-PAR']: $localize`Parwān`,
    ['AF-PIA']: $localize`Paktiyā`,
    ['AF-PKA']: $localize`Paktīkā`,
    ['AF-SAM']: $localize`Samangān`,
    ['AF-SAR']: $localize`Sar-e Pul`,
    ['AF-TAK']: $localize`Takhār`,
    ['AF-URU']: $localize`Uruzgān`,
    ['AF-WAR']: $localize`Wardak`,
    ['AF-ZAB']: $localize`Zābul`,
    ['AX-AX']: $localize`Åland Islands`,
    ['AL-01']: $localize`Berat`,
    ['AL-02']: $localize`Durrës`,
    ['AL-03']: $localize`Elbasan`,
    ['AL-04']: $localize`Fier`,
    ['AL-05']: $localize`Gjirokastër`,
    ['AL-06']: $localize`Korçë`,
    ['AL-07']: $localize`Kukës`,
    ['AL-08']: $localize`Lezhë`,
    ['AL-09']: $localize`Dibër`,
    ['AL-10']: $localize`Shkodër`,
    ['AL-11']: $localize`Tiranë`,
    ['AL-12']: $localize`Vlorë`,
    ['AL-BR']: $localize`Berat`,
    ['AL-BU']: $localize`Bulqizë`,
    ['AL-DI']: $localize`Dibër`,
    ['AL-DL']: $localize`Delvinë`,
    ['AL-DR']: $localize`Durrës`,
    ['AL-DV']: $localize`Devoll`,
    ['AL-EL']: $localize`Elbasan`,
    ['AL-ER']: $localize`Kolonjë`,
    ['AL-FR']: $localize`Fier`,
    ['AL-GJ']: $localize`Gjirokastër`,
    ['AL-GR']: $localize`Gramsh`,
    ['AL-HA']: $localize`Has`,
    ['AL-KA']: $localize`Kavajë`,
    ['AL-KB']: $localize`Kurbin`,
    ['AL-KC']: $localize`Kuçovë`,
    ['AL-KO']: $localize`Korçë`,
    ['AL-KR']: $localize`Krujë`,
    ['AL-KU']: $localize`Kukës`,
    ['AL-LB']: $localize`Librazhd`,
    ['AL-LE']: $localize`Lezhë`,
    ['AL-LU']: $localize`Lushnjë`,
    ['AL-MK']: $localize`Mallakastër`,
    ['AL-MM']: $localize`Malësi e Madhe`,
    ['AL-MR']: $localize`Mirditë`,
    ['AL-MT']: $localize`Mat`,
    ['AL-PG']: $localize`Pogradec`,
    ['AL-PQ']: $localize`Peqin`,
    ['AL-PR']: $localize`Përmet`,
    ['AL-PU']: $localize`Pukë`,
    ['AL-SH']: $localize`Shkodër`,
    ['AL-SK']: $localize`Skrapar`,
    ['AL-SR']: $localize`Sarandë`,
    ['AL-TE']: $localize`Tepelenë`,
    ['AL-TP']: $localize`Tropojë`,
    ['AL-TR']: $localize`Tiranë`,
    ['AL-VL']: $localize`Vlorë`,
    ['DZ-01']: $localize`Adrar`,
    ['DZ-02']: $localize`Chlef`,
    ['DZ-03']: $localize`Laghouat`,
    ['DZ-04']: $localize`Oum el Bouaghi`,
    ['DZ-05']: $localize`Batna`,
    ['DZ-06']: $localize`Béjaïa`,
    ['DZ-07']: $localize`Biskra`,
    ['DZ-08']: $localize`Béchar`,
    ['DZ-09']: $localize`Blida`,
    ['DZ-10']: $localize`Bouira`,
    ['DZ-11']: $localize`Tamanrasset`,
    ['DZ-12']: $localize`Tébessa`,
    ['DZ-13']: $localize`Tlemcen`,
    ['DZ-14']: $localize`Tiaret`,
    ['DZ-15']: $localize`Tizi Ouzou`,
    ['DZ-16']: $localize`Alger`,
    ['DZ-17']: $localize`Djelfa`,
    ['DZ-18']: $localize`Jijel`,
    ['DZ-19']: $localize`Sétif`,
    ['DZ-20']: $localize`Saïda`,
    ['DZ-21']: $localize`Skikda`,
    ['DZ-22']: $localize`Sidi Bel Abbès`,
    ['DZ-23']: $localize`Annaba`,
    ['DZ-24']: $localize`Guelma`,
    ['DZ-25']: $localize`Constantine`,
    ['DZ-26']: $localize`Médéa`,
    ['DZ-27']: $localize`Mostaganem`,
    ['DZ-28']: $localize`Msila`,
    ['DZ-29']: $localize`Mascara`,
    ['DZ-30']: $localize`Ouargla`,
    ['DZ-31']: $localize`Oran`,
    ['DZ-32']: $localize`El Bayadh`,
    ['DZ-33']: $localize`Illizi`,
    ['DZ-34']: $localize`Bordj Bou Arréridj`,
    ['DZ-35']: $localize`Boumerdès`,
    ['DZ-36']: $localize`El Tarf`,
    ['DZ-37']: $localize`Tindouf`,
    ['DZ-38']: $localize`Tissemsilt`,
    ['DZ-39']: $localize`El Oued`,
    ['DZ-40']: $localize`Khenchela`,
    ['DZ-41']: $localize`Souk Ahras`,
    ['DZ-42']: $localize`Tipaza`,
    ['DZ-43']: $localize`Mila`,
    ['DZ-44']: $localize`Aïn Defla`,
    ['DZ-45']: $localize`Naama`,
    ['DZ-46']: $localize`Aïn Témouchent`,
    ['DZ-47']: $localize`Ghardaïa`,
    ['DZ-48']: $localize`Relizane`,
    ['AS-AS']: $localize`American Samoa`,
    ['AD-02']: $localize`Canillo`,
    ['AD-03']: $localize`Encamp`,
    ['AD-04']: $localize`La Massana`,
    ['AD-05']: $localize`Ordino`,
    ['AD-06']: $localize`Sant Julià de Lòria`,
    ['AD-07']: $localize`Andorra la Vella`,
    ['AD-08']: $localize`Escaldes-Engordany`,
    ['AO-BGO']: $localize`Bengo`,
    ['AO-BGU']: $localize`Benguela`,
    ['AO-BIE']: $localize`Bié`,
    ['AO-CAB']: $localize`Cabinda`,
    ['AO-CCU']: $localize`Kuando Kubango`,
    ['AO-CNN']: $localize`Cunene`,
    ['AO-CNO']: $localize`Kwanza Norte`,
    ['AO-CUS']: $localize`Kwanza Sul`,
    ['AO-HUA']: $localize`Huambo`,
    ['AO-HUI']: $localize`Huíla`,
    ['AO-LNO']: $localize`Lunda Norte`,
    ['AO-LSU']: $localize`Lunda Sul`,
    ['AO-LUA']: $localize`Luanda`,
    ['AO-MAL']: $localize`Malange`,
    ['AO-MOX']: $localize`Moxico`,
    ['AO-NAM']: $localize`Namibe`,
    ['AO-UIG']: $localize`Uíge`,
    ['AO-ZAI']: $localize`Zaire`,
    ['AI-AI']: $localize`Anguilla`,
    ['AQ-AQ']: $localize`Antarctica`,
    ['AG-03']: $localize`Saint George`,
    ['AG-04']: $localize`Saint John`,
    ['AG-05']: $localize`Saint Mary`,
    ['AG-06']: $localize`Saint Paul`,
    ['AG-07']: $localize`Saint Peter`,
    ['AG-08']: $localize`Saint Philip`,
    ['AG-10']: $localize`Barbuda`,
    ['AG-11']: $localize`Redonda`,
    ['AR-A']: $localize`Salta`,
    ['AR-B']: $localize`Buenos Aires`,
    ['AR-C']: $localize`Ciudad Autónoma de Buenos Aires`,
    ['AR-D']: $localize`San Luis`,
    ['AR-E']: $localize`Entre Ríos`,
    ['AR-F']: $localize`La Rioja`,
    ['AR-G']: $localize`Santiago del Estero`,
    ['AR-H']: $localize`Chaco`,
    ['AR-J']: $localize`San Juan`,
    ['AR-K']: $localize`Catamarca`,
    ['AR-L']: $localize`La Pampa`,
    ['AR-M']: $localize`Mendoza`,
    ['AR-N']: $localize`Misiones`,
    ['AR-P']: $localize`Formosa`,
    ['AR-Q']: $localize`Neuquén`,
    ['AR-R']: $localize`Río Negro`,
    ['AR-S']: $localize`Santa Fe`,
    ['AR-T']: $localize`Tucumán`,
    ['AR-U']: $localize`Chubut`,
    ['AR-V']: $localize`Tierra del Fuego`,
    ['AR-W']: $localize`Corrientes`,
    ['AR-X']: $localize`Córdoba`,
    ['AR-Y']: $localize`Jujuy`,
    ['AR-Z']: $localize`Santa Cruz`,
    ['AM-AG']: $localize`Aragac̣otn`,
    ['AM-AR']: $localize`Ararat`,
    ['AM-AV']: $localize`Armavir`,
    ['AM-ER']: $localize`Erevan`,
    ['AM-GR']: $localize`Geġark'unik'`,
    ['AM-KT']: $localize`Kotayk'`,
    ['AM-LO']: $localize`Loṙi`,
    ['AM-SH']: $localize`Širak`,
    ['AM-SU']: $localize`Syunik'`,
    ['AM-TV']: $localize`Tavuš`,
    ['AM-VD']: $localize`Vayoć Jor`,
    ['AW-AW']: $localize`Aruba`,
    ['AU-ACT']: $localize`Australian Capital Territory`,
    ['AU-NSW']: $localize`New South Wales`,
    ['AU-NT']: $localize`Northern Territory`,
    ['AU-QLD']: $localize`Queensland`,
    ['AU-SA']: $localize`South Australia`,
    ['AU-TAS']: $localize`Tasmania`,
    ['AU-VIC']: $localize`Victoria`,
    ['AU-WA']: $localize`Western Australia`,
    ['AT-1']: $localize`Burgenland`,
    ['AT-2']: $localize`Kärnten`,
    ['AT-3']: $localize`Niederösterreich`,
    ['AT-4']: $localize`Oberösterreich`,
    ['AT-5']: $localize`Salzburg`,
    ['AT-6']: $localize`Steiermark`,
    ['AT-7']: $localize`Tirol`,
    ['AT-8']: $localize`Vorarlberg`,
    ['AT-9']: $localize`Wien`,
    ['AZ-ABS']: $localize`Abşeron`,
    ['AZ-AGA']: $localize`Ağstafa`,
    ['AZ-AGC']: $localize`Ağcabәdi`,
    ['AZ-AGM']: $localize`Ağdam`,
    ['AZ-AGS']: $localize`Ağdaş`,
    ['AZ-AGU']: $localize`Ağsu`,
    ['AZ-AST']: $localize`Astara`,
    ['AZ-BA']: $localize`Bakı`,
    ['AZ-BAB']: $localize`Babək`,
    ['AZ-BAL']: $localize`Balakən`,
    ['AZ-BAR']: $localize`Bərdə`,
    ['AZ-BEY']: $localize`Beyləqan`,
    ['AZ-BIL']: $localize`Biləsuvar`,
    ['AZ-CAB']: $localize`Cəbrayıl`,
    ['AZ-CAL']: $localize`Cəlilabad`,
    ['AZ-CUL']: $localize`Culfa`,
    ['AZ-DAS']: $localize`Daşkəsən`,
    ['AZ-FUZ']: $localize`Füzuli`,
    ['AZ-GAD']: $localize`Gədəbəy`,
    ['AZ-GA']: $localize`Gәncә`,
    ['AZ-GOR']: $localize`Goranboy`,
    ['AZ-GOY']: $localize`Göyçay`,
    ['AZ-GYG']: $localize`Göygöl`,
    ['AZ-HAC']: $localize`Hacıqabul`,
    ['AZ-IMI']: $localize`İmişli`,
    ['AZ-ISM']: $localize`İsmayıllı`,
    ['AZ-KAL']: $localize`Kəlbəcər`,
    ['AZ-KAN']: $localize`Kǝngǝrli`,
    ['AZ-KUR']: $localize`Kürdəmir`,
    ['AZ-LAC']: $localize`Laçın`,
    ['AZ-LA']: $localize`Lәnkәran`,
    ['AZ-LAN']: $localize`Lənkəran`,
    ['AZ-LER']: $localize`Lerik`,
    ['AZ-MAS']: $localize`Masallı`,
    ['AZ-MI']: $localize`Mingәçevir`,
    ['AZ-NA']: $localize`Naftalan`,
    ['AZ-NEF']: $localize`Neftçala`,
    ['AZ-NV']: $localize`Naxçıvan`,
    ['AZ-NX']: $localize`Naxçıvan`,
    ['AZ-OGU']: $localize`Oğuz`,
    ['AZ-ORD']: $localize`Ordubad`,
    ['AZ-QAB']: $localize`Qәbәlә`,
    ['AZ-QAX']: $localize`Qax`,
    ['AZ-QAZ']: $localize`Qazax`,
    ['AZ-QBA']: $localize`Quba`,
    ['AZ-QBI']: $localize`Qubadlı`,
    ['AZ-QOB']: $localize`Qobustan`,
    ['AZ-QUS']: $localize`Qusar`,
    ['AZ-SAB']: $localize`Sabirabad`,
    ['AZ-SAD']: $localize`Sәdәrәk`,
    ['AZ-SAH']: $localize`Şahbuz`,
    ['AZ-SAK']: $localize`Şәki`,
    ['AZ-SAL']: $localize`Salyan`,
    ['AZ-SAR']: $localize`Şәrur`,
    ['AZ-SA']: $localize`Şәki`,
    ['AZ-SAT']: $localize`Saatlı`,
    ['AZ-SBN']: $localize`Şabran`,
    ['AZ-SIY']: $localize`Siyәzәn`,
    ['AZ-SKR']: $localize`Şәmkir`,
    ['AZ-SMI']: $localize`Şamaxı`,
    ['AZ-SM']: $localize`Sumqayıt`,
    ['AZ-SMX']: $localize`Samux`,
    ['AZ-SR']: $localize`Şirvan`,
    ['AZ-SUS']: $localize`Şuşa`,
    ['AZ-TAR']: $localize`Tәrtәr`,
    ['AZ-TOV']: $localize`Tovuz`,
    ['AZ-UCA']: $localize`Ucar`,
    ['AZ-XAC']: $localize`Xaçmaz`,
    ['AZ-XA']: $localize`Xankәndi`,
    ['AZ-XCI']: $localize`Xocalı`,
    ['AZ-XIZ']: $localize`Xızı`,
    ['AZ-XVD']: $localize`Xocavәnd`,
    ['AZ-YAR']: $localize`Yardımlı`,
    ['AZ-YEV']: $localize`Yevlax`,
    ['AZ-YE']: $localize`Yevlax`,
    ['AZ-ZAN']: $localize`Zәngilan`,
    ['AZ-ZAQ']: $localize`Zaqatala`,
    ['AZ-ZAR']: $localize`Zәrdab`,
    ['BS-AK']: $localize`Acklins`,
    ['BS-BI']: $localize`Bimini`,
    ['BS-BP']: $localize`Black Point`,
    ['BS-BY']: $localize`Berry Islands`,
    ['BS-CE']: $localize`Central Eleuthera`,
    ['BS-CI']: $localize`Cat Island`,
    ['BS-CK']: $localize`Crooked Island and Long Cay`,
    ['BS-CO']: $localize`Central Abaco`,
    ['BS-CS']: $localize`Central Andros`,
    ['BS-EG']: $localize`East Grand Bahama`,
    ['BS-EX']: $localize`Exuma`,
    ['BS-FP']: $localize`City of Freeport`,
    ['BS-GC']: $localize`Grand Cay`,
    ['BS-HI']: $localize`Harbour Island`,
    ['BS-HT']: $localize`Hope Town`,
    ['BS-IN']: $localize`Inagua`,
    ['BS-LI']: $localize`Long Island`,
    ['BS-MC']: $localize`Mangrove Cay`,
    ['BS-MG']: $localize`Mayaguana`,
    ['BS-MI']: $localize`Moore's Island`,
    ['BS-NE']: $localize`North Eleuthera`,
    ['BS-NO']: $localize`North Abaco`,
    ['BS-NS']: $localize`North Andros`,
    ['BS-RC']: $localize`Rum Cay`,
    ['BS-RI']: $localize`Ragged Island`,
    ['BS-SA']: $localize`South Andros`,
    ['BS-SE']: $localize`South Eleuthera`,
    ['BS-SO']: $localize`South Abaco`,
    ['BS-SS']: $localize`San Salvador`,
    ['BS-SW']: $localize`Spanish Wells`,
    ['BS-WG']: $localize`West Grand Bahama`,
    ['BH-13']: $localize`Al Manāmah`,
    ['BH-14']: $localize`Al Janūbīyah`,
    ['BH-15']: $localize`Al Muḩarraq`,
    ['BH-16']: $localize`Al Wusţá`,
    ['BH-17']: $localize`Ash Shamālīyah`,
    ['BD-01']: $localize`Bandarban`,
    ['BD-02']: $localize`Barguna`,
    ['BD-03']: $localize`Bogra`,
    ['BD-04']: $localize`Brahmanbaria`,
    ['BD-05']: $localize`Bagerhat`,
    ['BD-06']: $localize`Barisal`,
    ['BD-07']: $localize`Bhola`,
    ['BD-08']: $localize`Comilla`,
    ['BD-09']: $localize`Chandpur`,
    ['BD-10']: $localize`Chittagong`,
    ['BD-11']: $localize`Cox's Bazar`,
    ['BD-12']: $localize`Chuadanga`,
    ['BD-13']: $localize`Dhaka`,
    ['BD-14']: $localize`Dinajpur`,
    ['BD-15']: $localize`Faridpur`,
    ['BD-16']: $localize`Feni`,
    ['BD-17']: $localize`Gopalganj`,
    ['BD-18']: $localize`Gazipur`,
    ['BD-19']: $localize`Gaibandha`,
    ['BD-20']: $localize`Habiganj`,
    ['BD-21']: $localize`Jamalpur`,
    ['BD-22']: $localize`Jessore`,
    ['BD-23']: $localize`Jhenaidah`,
    ['BD-24']: $localize`Jaipurhat`,
    ['BD-25']: $localize`Jhalakati`,
    ['BD-26']: $localize`Kishoreganj`,
    ['BD-27']: $localize`Khulna`,
    ['BD-28']: $localize`Kurigram`,
    ['BD-29']: $localize`Khagrachari`,
    ['BD-30']: $localize`Kushtia`,
    ['BD-31']: $localize`Lakshmipur`,
    ['BD-32']: $localize`Lalmonirhat`,
    ['BD-33']: $localize`Manikganj`,
    ['BD-34']: $localize`Mymensingh`,
    ['BD-35']: $localize`Munshiganj`,
    ['BD-36']: $localize`Madaripur`,
    ['BD-37']: $localize`Magura`,
    ['BD-38']: $localize`Moulvibazar`,
    ['BD-39']: $localize`Meherpur`,
    ['BD-40']: $localize`Narayanganj`,
    ['BD-41']: $localize`Netrakona`,
    ['BD-42']: $localize`Narsingdi`,
    ['BD-43']: $localize`Narail`,
    ['BD-44']: $localize`Natore`,
    ['BD-45']: $localize`Nawabganj`,
    ['BD-46']: $localize`Nilphamari`,
    ['BD-47']: $localize`Noakhali`,
    ['BD-48']: $localize`Naogaon`,
    ['BD-49']: $localize`Pabna`,
    ['BD-50']: $localize`Pirojpur`,
    ['BD-51']: $localize`Patuakhali`,
    ['BD-52']: $localize`Panchagarh`,
    ['BD-53']: $localize`Rajbari`,
    ['BD-54']: $localize`Rajshahi`,
    ['BD-55']: $localize`Rangpur`,
    ['BD-56']: $localize`Rangamati`,
    ['BD-57']: $localize`Sherpur`,
    ['BD-58']: $localize`Satkhira`,
    ['BD-59']: $localize`Sirajganj`,
    ['BD-60']: $localize`Sylhet`,
    ['BD-61']: $localize`Sunamganj`,
    ['BD-62']: $localize`Shariatpur`,
    ['BD-63']: $localize`Tangail`,
    ['BD-64']: $localize`Thakurgaon`,
    ['BD-A']: $localize`Barisal`,
    ['BD-B']: $localize`Chittagong`,
    ['BD-C']: $localize`Dhaka`,
    ['BD-D']: $localize`Khulna`,
    ['BD-E']: $localize`Rajshahi`,
    ['BD-F']: $localize`Rangpur`,
    ['BD-G']: $localize`Sylhet`,
    ['BB-01']: $localize`Christ Church`,
    ['BB-02']: $localize`Saint Andrew`,
    ['BB-03']: $localize`Saint George`,
    ['BB-04']: $localize`Saint James`,
    ['BB-05']: $localize`Saint John`,
    ['BB-06']: $localize`Saint Joseph`,
    ['BB-07']: $localize`Saint Lucy`,
    ['BB-08']: $localize`Saint Michael`,
    ['BB-09']: $localize`Saint Peter`,
    ['BB-10']: $localize`Saint Philip`,
    ['BB-11']: $localize`Saint Thomas`,
    ['BY-BR']: $localize`Bresckaja voblasć`,
    ['BY-HM']: $localize`Gorod Minsk`,
    ['BY-HO']: $localize`Gomel'skaja oblast'`,
    ['BY-HR']: $localize`Grodnenskaja oblast'`,
    ['BY-MA']: $localize`Mahilioŭskaja voblasć`,
    ['BY-MI']: $localize`Minskaja oblast'`,
    ['BY-VI']: $localize`Viciebskaja voblasć`,
    ['BE-BRU']: $localize`Brussels Hoofdstedelijk Gewest`,
    ['BE-VAN']: $localize`Antwerpen`,
    ['BE-VBR']: $localize`Vlaams-Brabant`,
    ['BE-VLG']: $localize`Vlaams Gewest`,
    ['BE-VLI']: $localize`Limburg`,
    ['BE-VOV']: $localize`Oost-Vlaanderen`,
    ['BE-VWV']: $localize`West-Vlaanderen`,
    ['BE-WAL']: $localize`wallonne, Région`,
    ['BE-WBR']: $localize`Brabant wallon`,
    ['BE-WHT']: $localize`Hainaut`,
    ['BE-WLG']: $localize`Liège`,
    ['BE-WLX']: $localize`Luxembourg`,
    ['BE-WNA']: $localize`Namur`,
    ['BZ-BZ']: $localize`Belize`,
    ['BZ-CY']: $localize`Cayo`,
    ['BZ-CZL']: $localize`Corozal`,
    ['BZ-OW']: $localize`Orange Walk`,
    ['BZ-SC']: $localize`Stann Creek`,
    ['BZ-TOL']: $localize`Toledo`,
    ['BJ-AK']: $localize`Atakora`,
    ['BJ-AL']: $localize`Alibori`,
    ['BJ-AQ']: $localize`Atlantique`,
    ['BJ-BO']: $localize`Borgou`,
    ['BJ-CO']: $localize`Collines`,
    ['BJ-DO']: $localize`Donga`,
    ['BJ-KO']: $localize`Kouffo`,
    ['BJ-LI']: $localize`Littoral`,
    ['BJ-MO']: $localize`Mono`,
    ['BJ-OU']: $localize`Ouémé`,
    ['BJ-PL']: $localize`Plateau`,
    ['BJ-ZO']: $localize`Zou`,
    ['BM-BM']: $localize`Bermuda`,
    ['BT-11']: $localize`Paro`,
    ['BT-12']: $localize`Chhukha`,
    ['BT-13']: $localize`Ha`,
    ['BT-14']: $localize`Samtse`,
    ['BT-15']: $localize`Thimphu`,
    ['BT-21']: $localize`Tsirang`,
    ['BT-22']: $localize`Dagana`,
    ['BT-23']: $localize`Punakha`,
    ['BT-24']: $localize`Wangdue Phodrang`,
    ['BT-31']: $localize`Sarpang`,
    ['BT-32']: $localize`Trongsa`,
    ['BT-33']: $localize`Bumthang`,
    ['BT-34']: $localize`Zhemgang`,
    ['BT-41']: $localize`Trashigang`,
    ['BT-42']: $localize`Monggar`,
    ['BT-43']: $localize`Pemagatshel`,
    ['BT-44']: $localize`Lhuentse`,
    ['BT-45']: $localize`Samdrup Jongkha`,
    ['BT-GA']: $localize`Gasa`,
    ['BT-TY']: $localize`Trashi Yangtse`,
    ['BO-B']: $localize`El Beni`,
    ['BO-C']: $localize`Cochabamba`,
    ['BO-H']: $localize`Chuquisaca`,
    ['BO-L']: $localize`La Paz`,
    ['BO-N']: $localize`Pando`,
    ['BO-O']: $localize`Oruro`,
    ['BO-P']: $localize`Potosí`,
    ['BO-S']: $localize`Santa Cruz`,
    ['BO-T']: $localize`Tarija`,
    ['BQ-BO']: $localize`Bonaire`,
    ['BQ-SA']: $localize`Saba`,
    ['BQ-SE']: $localize`Sint Eustatius`,
    ['BA-01']: $localize`Unsko-sanska županija`,
    ['BA-02']: $localize`Posavska županija`,
    ['BA-03']: $localize`Tuzlanska županija`,
    ['BA-04']: $localize`Zeničko-dobojska županija`,
    ['BA-05']: $localize`Bosansko-podrinjska županija`,
    ['BA-06']: $localize`Srednjobosanska županija`,
    ['BA-07']: $localize`Hercegovačko-neretvanska županija`,
    ['BA-08']: $localize`Zapadnohercegovačka županija`,
    ['BA-09']: $localize`Kanton Sarajevo`,
    ['BA-10']: $localize`Kanton br. 10`,
    ['BA-BIH']: $localize`Federacija Bosne i Hercegovine`,
    ['BA-BRC']: $localize`Brčko distrikt`,
    ['BA-SRP']: $localize`Republika Srpska`,
    ['BW-CE']: $localize`Central`,
    ['BW-CH']: $localize`Chobe`,
    ['BW-FR']: $localize`Francistown`,
    ['BW-GA']: $localize`Gaborone`,
    ['BW-GH']: $localize`Ghanzi`,
    ['BW-JW']: $localize`Jwaneng`,
    ['BW-KG']: $localize`Kgalagadi`,
    ['BW-KL']: $localize`Kgatleng`,
    ['BW-KW']: $localize`Kweneng`,
    ['BW-LO']: $localize`Lobatse`,
    ['BW-NE']: $localize`North East`,
    ['BW-NW']: $localize`North West`,
    ['BW-SE']: $localize`South East`,
    ['BW-SO']: $localize`Southern`,
    ['BW-SP']: $localize`Selibe Phikwe`,
    ['BW-ST']: $localize`Sowa Town`,
    ['BV-BV']: $localize`Bouvet Island`,
    ['BR-AC']: $localize`Acre`,
    ['BR-AL']: $localize`Alagoas`,
    ['BR-AM']: $localize`Amazonas`,
    ['BR-AP']: $localize`Amapá`,
    ['BR-BA']: $localize`Bahia`,
    ['BR-CE']: $localize`Ceará`,
    ['BR-DF']: $localize`Distrito Federal`,
    ['BR-ES']: $localize`Espírito Santo`,
    ['BR-GO']: $localize`Goiás`,
    ['BR-MA']: $localize`Maranhão`,
    ['BR-MG']: $localize`Minas Gerais`,
    ['BR-MS']: $localize`Mato Grosso do Sul`,
    ['BR-MT']: $localize`Mato Grosso`,
    ['BR-PA']: $localize`Pará`,
    ['BR-PB']: $localize`Paraíba`,
    ['BR-PE']: $localize`Pernambuco`,
    ['BR-PI']: $localize`Piauí`,
    ['BR-PR']: $localize`Paraná`,
    ['BR-RJ']: $localize`Rio de Janeiro`,
    ['BR-RN']: $localize`Rio Grande do Norte`,
    ['BR-RO']: $localize`Rondônia`,
    ['BR-RR']: $localize`Roraima`,
    ['BR-RS']: $localize`Rio Grande do Sul`,
    ['BR-SC']: $localize`Santa Catarina`,
    ['BR-SE']: $localize`Sergipe`,
    ['BR-SP']: $localize`São Paulo`,
    ['BR-TO']: $localize`Tocantins`,
    ['IO-IO']: $localize`British Indian Ocean Territory`,
    ['BN-BE']: $localize`Belait`,
    ['BN-BM']: $localize`Brunei-Muara`,
    ['BN-TE']: $localize`Temburong`,
    ['BN-TU']: $localize`Tutong`,
    ['BG-01']: $localize`Blagoevgrad`,
    ['BG-02']: $localize`Burgas`,
    ['BG-03']: $localize`Varna`,
    ['BG-04']: $localize`Veliko Tarnovo`,
    ['BG-05']: $localize`Vidin`,
    ['BG-06']: $localize`Vratsa`,
    ['BG-07']: $localize`Gabrovo`,
    ['BG-08']: $localize`Dobrich`,
    ['BG-09']: $localize`Kardzhali`,
    ['BG-10']: $localize`Kyustendil`,
    ['BG-11']: $localize`Lovech`,
    ['BG-12']: $localize`Montana`,
    ['BG-13']: $localize`Pazardzhik`,
    ['BG-14']: $localize`Pernik`,
    ['BG-15']: $localize`Pleven`,
    ['BG-16']: $localize`Plovdiv`,
    ['BG-17']: $localize`Razgrad`,
    ['BG-18']: $localize`Ruse`,
    ['BG-19']: $localize`Silistra`,
    ['BG-20']: $localize`Sliven`,
    ['BG-21']: $localize`Smolyan`,
    ['BG-22']: $localize`Sofia`,
    ['BG-23']: $localize`Sofia`,
    ['BG-24']: $localize`Stara Zagora`,
    ['BG-25']: $localize`Targovishte`,
    ['BG-26']: $localize`Haskovo`,
    ['BG-27']: $localize`Shumen`,
    ['BG-28']: $localize`Yambol`,
    ['BF-01']: $localize`Boucle du Mouhoun`,
    ['BF-02']: $localize`Cascades`,
    ['BF-03']: $localize`Centre`,
    ['BF-04']: $localize`Centre-Est`,
    ['BF-05']: $localize`Centre-Nord`,
    ['BF-06']: $localize`Centre-Ouest`,
    ['BF-07']: $localize`Centre-Sud`,
    ['BF-08']: $localize`Est`,
    ['BF-09']: $localize`Hauts-Bassins`,
    ['BF-10']: $localize`Nord`,
    ['BF-11']: $localize`Plateau-Central`,
    ['BF-12']: $localize`Sahel`,
    ['BF-13']: $localize`Sud-Ouest`,
    ['BF-BAL']: $localize`Balé`,
    ['BF-BAM']: $localize`Bam`,
    ['BF-BAN']: $localize`Banwa`,
    ['BF-BAZ']: $localize`Bazèga`,
    ['BF-BGR']: $localize`Bougouriba`,
    ['BF-BLG']: $localize`Boulgou`,
    ['BF-BLK']: $localize`Boulkiemdé`,
    ['BF-COM']: $localize`Comoé`,
    ['BF-GAN']: $localize`Ganzourgou`,
    ['BF-GNA']: $localize`Gnagna`,
    ['BF-GOU']: $localize`Gourma`,
    ['BF-HOU']: $localize`Houet`,
    ['BF-IOB']: $localize`Ioba`,
    ['BF-KAD']: $localize`Kadiogo`,
    ['BF-KEN']: $localize`Kénédougou`,
    ['BF-KMD']: $localize`Komondjari`,
    ['BF-KMP']: $localize`Kompienga`,
    ['BF-KOP']: $localize`Koulpélogo`,
    ['BF-KOS']: $localize`Kossi`,
    ['BF-KOT']: $localize`Kouritenga`,
    ['BF-KOW']: $localize`Kourwéogo`,
    ['BF-LER']: $localize`Léraba`,
    ['BF-LOR']: $localize`Loroum`,
    ['BF-MOU']: $localize`Mouhoun`,
    ['BF-NAM']: $localize`Namentenga`,
    ['BF-NAO']: $localize`Nahouri`,
    ['BF-NAY']: $localize`Nayala`,
    ['BF-NOU']: $localize`Noumbiel`,
    ['BF-OUB']: $localize`Oubritenga`,
    ['BF-OUD']: $localize`Oudalan`,
    ['BF-PAS']: $localize`Passoré`,
    ['BF-PON']: $localize`Poni`,
    ['BF-SEN']: $localize`Séno`,
    ['BF-SIS']: $localize`Sissili`,
    ['BF-SMT']: $localize`Sanmatenga`,
    ['BF-SNG']: $localize`Sanguié`,
    ['BF-SOM']: $localize`Soum`,
    ['BF-SOR']: $localize`Sourou`,
    ['BF-TAP']: $localize`Tapoa`,
    ['BF-TUI']: $localize`Tui`,
    ['BF-YAG']: $localize`Yagha`,
    ['BF-YAT']: $localize`Yatenga`,
    ['BF-ZIR']: $localize`Ziro`,
    ['BF-ZON']: $localize`Zondoma`,
    ['BF-ZOU']: $localize`Zoundwéogo`,
    ['BI-BB']: $localize`Bubanza`,
    ['BI-BL']: $localize`Bujumbura Rural`,
    ['BI-BM']: $localize`Bujumbura Mairie`,
    ['BI-BR']: $localize`Bururi`,
    ['BI-CA']: $localize`Cankuzo`,
    ['BI-CI']: $localize`Cibitoke`,
    ['BI-GI']: $localize`Gitega`,
    ['BI-KI']: $localize`Kirundo`,
    ['BI-KR']: $localize`Karuzi`,
    ['BI-KY']: $localize`Kayanza`,
    ['BI-MA']: $localize`Makamba`,
    ['BI-MU']: $localize`Muramvya`,
    ['BI-MW']: $localize`Mwaro`,
    ['BI-MY']: $localize`Muyinga`,
    ['BI-NG']: $localize`Ngozi`,
    ['BI-RT']: $localize`Rutana`,
    ['BI-RY']: $localize`Ruyigi`,
    ['KH-10']: $localize`Krâchéh`,
    ['KH-11']: $localize`Môndól Kiri`,
    ['KH-12']: $localize`Phnom Penh`,
    ['KH-13']: $localize`Preăh Vihéar`,
    ['KH-14']: $localize`Prey Veaeng`,
    ['KH-15']: $localize`Pousaat`,
    ['KH-16']: $localize`Rotanak Kiri`,
    ['KH-17']: $localize`Siem Reab`,
    ['KH-18']: $localize`Krŏng Preăh Sihanouk`,
    ['KH-19']: $localize`Stoĕng Trêng`,
    ['KH-1']: $localize`Bântéay Méanchey`,
    ['KH-20']: $localize`Svaay Rieng`,
    ['KH-21']: $localize`Taakaev`,
    ['KH-22']: $localize`Ŏtdâr Méanchey`,
    ['KH-23']: $localize`Krong Kaeb`,
    ['KH-24']: $localize`Krŏng Pailĭn`,
    ['KH-2']: $localize`Baat Dambang`,
    ['KH-3']: $localize`Kampong Chaam`,
    ['KH-4']: $localize`Kampong Chhnang`,
    ['KH-5']: $localize`Kâmpóng Spœ`,
    ['KH-6']: $localize`Kâmpóng Thum`,
    ['KH-7']: $localize`Kampot`,
    ['KH-8']: $localize`Kandaal`,
    ['KH-9']: $localize`Kaoh Kong`,
    ['CM-AD']: $localize`Adamaoua`,
    ['CM-CE']: $localize`Centre`,
    ['CM-EN']: $localize`Extrême-Nord`,
    ['CM-ES']: $localize`East`,
    ['CM-LT']: $localize`Littoral`,
    ['CM-NO']: $localize`Nord`,
    ['CM-NW']: $localize`Nord-Ouest`,
    ['CM-OU']: $localize`Ouest`,
    ['CM-SU']: $localize`South`,
    ['CM-SW']: $localize`South-West`,
    ['CA-AB']: $localize`Alberta`,
    ['CA-BC']: $localize`British Columbia`,
    ['CA-MB']: $localize`Manitoba`,
    ['CA-NB']: $localize`New Brunswick`,
    ['CA-NL']: $localize`Newfoundland and Labrador`,
    ['CA-NS']: $localize`Nova Scotia`,
    ['CA-NT']: $localize`Northwest Territories`,
    ['CA-NU']: $localize`Nunavut`,
    ['CA-ON']: $localize`Ontario`,
    ['CA-PE']: $localize`Prince Edward Island`,
    ['CA-QC']: $localize`Quebec`,
    ['CA-SK']: $localize`Saskatchewan`,
    ['CA-YT']: $localize`Yukon`,
    ['CV-B']: $localize`Ilhas de Barlavento`,
    ['CV-BR']: $localize`Brava`,
    ['CV-BV']: $localize`Boa Vista`,
    ['CV-CA']: $localize`Santa Catarina`,
    ['CV-CF']: $localize`Santa Catarina do Fogo`,
    ['CV-CR']: $localize`Santa Cruz`,
    ['CV-MA']: $localize`Maio`,
    ['CV-MO']: $localize`Mosteiros`,
    ['CV-PA']: $localize`Paul`,
    ['CV-PN']: $localize`Porto Novo`,
    ['CV-PR']: $localize`Praia`,
    ['CV-RB']: $localize`Ribeira Brava`,
    ['CV-RG']: $localize`Ribeira Grande`,
    ['CV-RS']: $localize`Ribeira Grande de Santiago`,
    ['CV-SD']: $localize`São Domingos`,
    ['CV-SF']: $localize`São Filipe`,
    ['CV-S']: $localize`Ilhas de Sotavento`,
    ['CV-SL']: $localize`Sal`,
    ['CV-SM']: $localize`São Miguel`,
    ['CV-SO']: $localize`São Lourenço dos Órgãos`,
    ['CV-SS']: $localize`São Salvador do Mundo`,
    ['CV-SV']: $localize`São Vicente`,
    ['CV-TA']: $localize`Tarrafal`,
    ['CV-TS']: $localize`Tarrafal de São Nicolau`,
    ['KY-KY']: $localize`Cayman Islands`,
    ['CF-AC']: $localize`Ouham`,
    ['CF-BB']: $localize`Bamïngï-Bangoran`,
    ['CF-BGF']: $localize`Bangî`,
    ['CF-BK']: $localize`Basse-Kotto`,
    ['CF-HK']: $localize`Haute-Kotto`,
    ['CF-HM']: $localize`Haut-Mbomou`,
    ['CF-HS']: $localize`Haute-Sangha / Mambéré-Kadéï,Prefecture`,
    ['CF-KB']: $localize`Gïrïbïngï`,
    ['CF-KG']: $localize`Kemö-Gïrïbïngï`,
    ['CF-LB']: $localize`Lobaye`,
    ['CF-MB']: $localize`Mbomou`,
    ['CF-MP']: $localize`Ömbëlä-Pökö`,
    ['CF-NM']: $localize`Nana-Mambéré`,
    ['CF-OP']: $localize`Ouham-Pendé`,
    ['CF-SE']: $localize`Sangä`,
    ['CF-UK']: $localize`Ouaka`,
    ['CF-VK']: $localize`Vakaga`,
    ['TD-BA']: $localize`Al Baţḩah`,
    ['TD-BG']: $localize`Baḩr al Ghazāl`,
    ['TD-BO']: $localize`Borkou`,
    ['TD-CB']: $localize`Chari-Baguirmi`,
    ['TD-EE']: $localize`Ennedi-Est`,
    ['TD-EO']: $localize`Ennedi-Ouest`,
    ['TD-GR']: $localize`Guéra`,
    ['TD-HL']: $localize`Hadjer Lamis`,
    ['TD-KA']: $localize`Kanem`,
    ['TD-LC']: $localize`Al Buḩayrah`,
    ['TD-LO']: $localize`Logone-Occidental`,
    ['TD-LR']: $localize`Logone-Oriental`,
    ['TD-MA']: $localize`Mandoul`,
    ['TD-MC']: $localize`Moyen-Chari`,
    ['TD-ME']: $localize`Mayo-Kebbi-Est`,
    ['TD-MO']: $localize`Mayo-Kebbi-Ouest`,
    ['TD-ND']: $localize`Madīnat Injamīnā`,
    ['TD-OD']: $localize`Ouaddaï`,
    ['TD-SA']: $localize`Salamat`,
    ['TD-SI']: $localize`Sila`,
    ['TD-TA']: $localize`Tandjilé`,
    ['TD-TI']: $localize`Tibastī`,
    ['TD-WF']: $localize`Wadi Fira`,
    ['CL-AI']: $localize`Aysén`,
    ['CL-AN']: $localize`Antofagasta`,
    ['CL-AP']: $localize`Arica y Parinacota`,
    ['CL-AR']: $localize`Araucanía`,
    ['CL-AT']: $localize`Atacama`,
    ['CL-BI']: $localize`Biobío`,
    ['CL-CO']: $localize`Coquimbo`,
    ['CL-LI']: $localize`Libertador General Bernardo O'Higgins`,
    ['CL-LL']: $localize`Los Lagos`,
    ['CL-LR']: $localize`Los Ríos`,
    ['CL-MA']: $localize`Magallanes`,
    ['CL-ML']: $localize`Maule`,
    ['CL-RM']: $localize`Región Metropolitana de Santiago`,
    ['CL-TA']: $localize`Tarapacá`,
    ['CL-VS']: $localize`Valparaíso`,
    ['CN-11']: $localize`Beijing,Municipality`,
    ['CN-12']: $localize`Tianjin,Municipality`,
    ['CN-13']: $localize`Hebei,Province`,
    ['CN-14']: $localize`Shanxi,Province`,
    ['CN-15']: $localize`Nei Mongol`,
    ['CN-21']: $localize`Liaoning`,
    ['CN-22']: $localize`Jilin`,
    ['CN-23']: $localize`Heilongjiang`,
    ['CN-31']: $localize`Shanghai`,
    ['CN-32']: $localize`Jiangsu`,
    ['CN-33']: $localize`Zhejiang`,
    ['CN-34']: $localize`Anhui`,
    ['CN-35']: $localize`Fujian`,
    ['CN-36']: $localize`Jiangxi`,
    ['CN-37']: $localize`Shandong`,
    ['CN-41']: $localize`Henan`,
    ['CN-42']: $localize`Hubei`,
    ['CN-43']: $localize`Hunan`,
    ['CN-44']: $localize`Guangdong`,
    ['CN-45']: $localize`Guangxi`,
    ['CN-46']: $localize`Hainan`,
    ['CN-50']: $localize`Chongqing`,
    ['CN-51']: $localize`Sichuan`,
    ['CN-52']: $localize`Guizhou`,
    ['CN-53']: $localize`Yunnan`,
    ['CN-54']: $localize`Xizang`,
    ['CN-61']: $localize`Shaanxi`,
    ['CN-62']: $localize`Gansu`,
    ['CN-63']: $localize`Qinghai`,
    ['CN-64']: $localize`Ningxia`,
    ['CN-65']: $localize`Xinjiang`,
    ['CN-71']: $localize`Taiwan`,
    ['CN-91']: $localize`Hong Kong`,
    ['CN-92']: $localize`Aomen`,
    ['CX-CX']: $localize`Christmas Island`,
    ['CC-CC']: $localize`Cocos  Islands`,
    ['CO-AMA']: $localize`Amazonas`,
    ['CO-ANT']: $localize`Antioquia`,
    ['CO-ARA']: $localize`Arauca`,
    ['CO-ATL']: $localize`Atlántico`,
    ['CO-BOL']: $localize`Bolívar`,
    ['CO-BOY']: $localize`Boyacá`,
    ['CO-CAL']: $localize`Caldas`,
    ['CO-CAQ']: $localize`Caquetá`,
    ['CO-CAS']: $localize`Casanare`,
    ['CO-CAU']: $localize`Cauca`,
    ['CO-CES']: $localize`Cesar`,
    ['CO-CHO']: $localize`Chocó`,
    ['CO-COR']: $localize`Córdoba`,
    ['CO-CUN']: $localize`Cundinamarca`,
    ['CO-DC,']: $localize`istrito Capital de Bogotá`,
    ['CO-GUA']: $localize`Guainía`,
    ['CO-GUV']: $localize`Guaviare`,
    ['CO-HUI']: $localize`Huila`,
    ['CO-LAG']: $localize`La Guajira`,
    ['CO-MAG']: $localize`Magdalena`,
    ['CO-MET']: $localize`Meta`,
    ['CO-NAR']: $localize`Nariño`,
    ['CO-NSA']: $localize`Norte de Santander`,
    ['CO-PUT']: $localize`Putumayo`,
    ['CO-QUI']: $localize`Quindío`,
    ['CO-RIS']: $localize`Risaralda`,
    ['CO-SAN']: $localize`Santander`,
    ['CO-SAP']: $localize`San Andrés, Providencia y Santa Catalina`,
    ['CO-SUC']: $localize`Sucre`,
    ['CO-TOL']: $localize`Tolima`,
    ['CO-VAC']: $localize`Valle del Cauca`,
    ['CO-VAU']: $localize`Vaupés`,
    ['CO-VID']: $localize`Vichada`,
    ['KM-A']: $localize`Andjouân`,
    ['KM-G']: $localize`Andjazîdja`,
    ['KM-M']: $localize`Mohéli`,
    ['CG-11']: $localize`Bouenza`,
    ['CG-12']: $localize`Pool`,
    ['CG-13']: $localize`Sangha`,
    ['CG-14']: $localize`Plateaux`,
    ['CG-15']: $localize`Cuvette-Ouest`,
    ['CG-16']: $localize`Pointe-Noire`,
    ['CG-2']: $localize`Lékoumou`,
    ['CG-5']: $localize`Kouilou`,
    ['CG-7']: $localize`Likouala`,
    ['CG-8']: $localize`Cuvette`,
    ['CG-9']: $localize`Niari`,
    ['CG-BZV']: $localize`Brazzaville`,
    ['CD-BC']: $localize`Bas-Congo`,
    ['CD-BN']: $localize`Bandundu`,
    ['CD-EQ']: $localize`Équateur`,
    ['CD-KA']: $localize`Katanga`,
    ['CD-KE']: $localize`Kasai-Oriental`,
    ['CD-KN']: $localize`Kinshasa`,
    ['CD-KW']: $localize`Kasai-Occidental`,
    ['CD-MA']: $localize`Maniema`,
    ['CD-NK']: $localize`Nord-Kivu`,
    ['CD-OR']: $localize`Orientale`,
    ['CD-SK']: $localize`Sud-Kivu`,
    ['CK-CK']: $localize`Cook Islands`,
    ['CR-A']: $localize`Alajuela`,
    ['CR-C']: $localize`Cartago`,
    ['CR-G']: $localize`Guanacaste`,
    ['CR-H']: $localize`Heredia`,
    ['CR-L']: $localize`Limón`,
    ['CR-P']: $localize`Puntarenas`,
    ['CR-SJ']: $localize`San José`,
    ['CI-01']: $localize`Lagunes`,
    ['CI-02']: $localize`Haut-Sassandra`,
    ['CI-03']: $localize`Savanes`,
    ['CI-04']: $localize`Vallée du Bandama`,
    ['CI-05']: $localize`Moyen-Comoé`,
    ['CI-06']: $localize`18 Montagnes`,
    ['CI-07']: $localize`Lacs`,
    ['CI-08']: $localize`Zanzan`,
    ['CI-09']: $localize`Bas-Sassandra`,
    ['CI-10']: $localize`Denguélé`,
    ['CI-11']: $localize`Nzi-Comoé`,
    ['CI-12']: $localize`Marahoué`,
    ['CI-13']: $localize`Sud-Comoé`,
    ['CI-14']: $localize`Worodougou`,
    ['CI-15']: $localize`Sud-Bandama`,
    ['CI-16']: $localize`Agnébi`,
    ['CI-17']: $localize`Bafing`,
    ['CI-18']: $localize`Fromager`,
    ['CI-19']: $localize`Moyen-Cavally`,
    ['HR-01']: $localize`Zagrebačka županija`,
    ['HR-02']: $localize`Krapinsko-zagorska županija`,
    ['HR-03']: $localize`Sisačko-moslavačka županija`,
    ['HR-04']: $localize`Karlovačka županija`,
    ['HR-05']: $localize`Varaždinska županija`,
    ['HR-06']: $localize`Koprivničko-križevačka županija`,
    ['HR-07']: $localize`Bjelovarsko-bilogorska županija`,
    ['HR-08']: $localize`Primorsko-goranska županija`,
    ['HR-09']: $localize`Ličko-senjska županija`,
    ['HR-10']: $localize`Virovitičko-podravska županija`,
    ['HR-11']: $localize`Požeško-slavonska županija`,
    ['HR-12']: $localize`Brodsko-posavska županija`,
    ['HR-13']: $localize`Zadarska županija`,
    ['HR-14']: $localize`Osječko-baranjska županija`,
    ['HR-15']: $localize`Šibensko-kninska županija`,
    ['HR-16']: $localize`Vukovarsko-srijemska županija`,
    ['HR-17']: $localize`Splitsko-dalmatinska županija`,
    ['HR-18']: $localize`Istarska županija`,
    ['HR-19']: $localize`Dubrovačko-neretvanska županija`,
    ['HR-20']: $localize`Međimurska županija`,
    ['HR-21']: $localize`Grad Zagreb`,
    ['CU-01']: $localize`Pinar del Río`,
    ['CU-03']: $localize`La Habana`,
    ['CU-04']: $localize`Matanzas`,
    ['CU-05']: $localize`Villa Clara`,
    ['CU-06']: $localize`Cienfuegos`,
    ['CU-07']: $localize`Sancti Spíritus`,
    ['CU-08']: $localize`Ciego de Ávila`,
    ['CU-09']: $localize`Camagüey`,
    ['CU-10']: $localize`Las Tunas`,
    ['CU-11']: $localize`Holguín`,
    ['CU-12']: $localize`Granma`,
    ['CU-13']: $localize`Santiago de Cuba`,
    ['CU-14']: $localize`Guantánamo`,
    ['CU-15']: $localize`Artemisa`,
    ['CU-16']: $localize`Mayabeque`,
    ['CU-99']: $localize`Isla de la Juventud`,
    ['CW-CW']: $localize`Curaçao`,
    ['CY-01']: $localize`Lefkoşa`,
    ['CY-02']: $localize`Lemesos`,
    ['CY-03']: $localize`Larnaka`,
    ['CY-04']: $localize`Ammochostos`,
    ['CY-05']: $localize`Baf`,
    ['CY-06']: $localize`Girne`,
    ['CZ-101']: $localize`Praha 1`,
    ['CZ-102']: $localize`Praha 2`,
    ['CZ-103']: $localize`Praha 3`,
    ['CZ-104']: $localize`Praha 4`,
    ['CZ-105']: $localize`Praha 5`,
    ['CZ-106']: $localize`Praha 6`,
    ['CZ-107']: $localize`Praha 7`,
    ['CZ-108']: $localize`Praha 8`,
    ['CZ-109']: $localize`Praha 9`,
    ['CZ-10A']: $localize`Praha 10`,
    ['CZ-10B']: $localize`Praha 11`,
    ['CZ-10C']: $localize`Praha 12`,
    ['CZ-10D']: $localize`Praha 13`,
    ['CZ-10E']: $localize`Praha 14`,
    ['CZ-10F']: $localize`Praha 15`,
    ['CZ-201']: $localize`Benešov`,
    ['CZ-202']: $localize`Beroun`,
    ['CZ-203']: $localize`Kladno`,
    ['CZ-204']: $localize`Kolín`,
    ['CZ-205']: $localize`Kutná Hora`,
    ['CZ-206']: $localize`Mělník`,
    ['CZ-207']: $localize`Mladá Boleslav`,
    ['CZ-208']: $localize`Nymburk`,
    ['CZ-209']: $localize`Praha-východ`,
    ['CZ-20A']: $localize`Praha-západ`,
    ['CZ-20B']: $localize`Příbram`,
    ['CZ-20C']: $localize`Rakovník`,
    ['CZ-311']: $localize`České Budějovice`,
    ['CZ-312']: $localize`Český Krumlov`,
    ['CZ-313']: $localize`Jindřichův Hradec`,
    ['CZ-314']: $localize`Písek`,
    ['CZ-315']: $localize`Prachatice`,
    ['CZ-316']: $localize`Strakonice`,
    ['CZ-317']: $localize`Tábor`,
    ['CZ-321']: $localize`Domažlice`,
    ['CZ-322']: $localize`Klatovy`,
    ['CZ-323']: $localize`Plzeň-město`,
    ['CZ-324']: $localize`Plzeň-jih`,
    ['CZ-325']: $localize`Plzeň-sever`,
    ['CZ-326']: $localize`Rokycany`,
    ['CZ-327']: $localize`Tachov`,
    ['CZ-411']: $localize`Cheb`,
    ['CZ-412']: $localize`Karlovy Vary`,
    ['CZ-413']: $localize`Sokolov`,
    ['CZ-421']: $localize`Děčín`,
    ['CZ-422']: $localize`Chomutov`,
    ['CZ-423']: $localize`Litoměřice`,
    ['CZ-424']: $localize`Louny`,
    ['CZ-425']: $localize`Most`,
    ['CZ-426']: $localize`Teplice`,
    ['CZ-427']: $localize`Ústí nad Labem`,
    ['CZ-511']: $localize`Česká Lípa`,
    ['CZ-512']: $localize`Jablonec nad Nisou`,
    ['CZ-513']: $localize`Liberec`,
    ['CZ-514']: $localize`Semily`,
    ['CZ-521']: $localize`Hradec Králové`,
    ['CZ-522']: $localize`Jičín`,
    ['CZ-523']: $localize`Náchod`,
    ['CZ-524']: $localize`Rychnov nad Kněžnou`,
    ['CZ-525']: $localize`Trutnov`,
    ['CZ-531']: $localize`Chrudim`,
    ['CZ-532']: $localize`Pardubice`,
    ['CZ-533']: $localize`Svitavy`,
    ['CZ-534']: $localize`Ústí nad Orlicí`,
    ['CZ-611']: $localize`Havlíčkův Brod`,
    ['CZ-612']: $localize`Jihlava`,
    ['CZ-613']: $localize`Pelhřimov`,
    ['CZ-614']: $localize`Třebíč`,
    ['CZ-615']: $localize`Žd'ár nad Sázavou`,
    ['CZ-621']: $localize`Blansko`,
    ['CZ-622']: $localize`Brno-město`,
    ['CZ-623']: $localize`Brno-venkov`,
    ['CZ-624']: $localize`Břeclav`,
    ['CZ-625']: $localize`Hodonín`,
    ['CZ-626']: $localize`Vyškov`,
    ['CZ-627']: $localize`Znojmo`,
    ['CZ-711']: $localize`Jeseník`,
    ['CZ-712']: $localize`Olomouc`,
    ['CZ-713']: $localize`Prostĕjov`,
    ['CZ-714']: $localize`Přerov`,
    ['CZ-715']: $localize`Šumperk`,
    ['CZ-721']: $localize`Kromĕříž`,
    ['CZ-722']: $localize`Uherské Hradištĕ`,
    ['CZ-723']: $localize`Vsetín`,
    ['CZ-724']: $localize`Zlín`,
    ['CZ-801']: $localize`Bruntál`,
    ['CZ-802']: $localize`Frýdek Místek`,
    ['CZ-803']: $localize`Karviná`,
    ['CZ-804']: $localize`Nový Jičín`,
    ['CZ-805']: $localize`Opava`,
    ['CZ-806']: $localize`Ostrava město`,
    ['CZ-JC']: $localize`Jihočeský kraj`,
    ['CZ-JM']: $localize`Jihomoravský kraj`,
    ['CZ-KA']: $localize`Karlovarský kraj`,
    ['CZ-KR']: $localize`Královéhradecký kraj`,
    ['CZ-LI']: $localize`Liberecký kraj`,
    ['CZ-MO']: $localize`Moravskoslezský kraj`,
    ['CZ-OL']: $localize`Olomoucký kraj`,
    ['CZ-PA']: $localize`Pardubický kraj`,
    ['CZ-PL']: $localize`Plzeňský kraj`,
    ['CZ-PR']: $localize`Praha, hlavní mešto`,
    ['CZ-ST']: $localize`Středočeský kraj`,
    ['CZ-US']: $localize`Ústecký kraj`,
    ['CZ-VY']: $localize`Vysočina`,
    ['CZ-ZL']: $localize`Zlínský kraj`,
  };
}
