import { Injectable } from '@angular/core';
import { ROLES_CONFIG } from '../../constants/vhq-roles-config';
import { FeaturesEnum } from '../../enums/vhq-features.enum';
import { Features } from 'environments/enums/features.enum';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  isDeviceDetailAddAllowed = false;
  isDeviceDetailEditAllowed = false;
  isDeviceDetailDeleteAllowed = false;
  isDeviceDetailViewAllowed = false;
  isShipmentReceiptAddAllowed = false;
  isShipmentReceiptEditAllowed = false;
  isShipmentReceiptDeleteAllowed = false;
  isShipmentReceiptViewAllowed = false;
  isChainOfCustodyAddAllowed = false;
  isChainOfCustodyEditAllowed = false;
  isChainOfCustodyDeleteAllowed = false;
  isChainOfCustodyViewAllowed = false;
  isInspectionAddAllowed = false;
  isInspectionEditAllowed = false;
  isInspectionDeleteAllowed = false;
  isInspectionViewAllowed = false;
  isLocationsAddAllowed = false;
  isLocationsEditAllowed = false;
  isLocationsDeleteAllowed = false;
  isLocationsViewAllowed = false;
  isTenantsAddAllowed = false;
  isTenantsEditAllowed = false;
  isTenantsDeleteAllowed = false;
  isTenantsViewAllowed = false;
  isDevicesAddAllowed = false;
  isDevicesCloneAllowed = false;
  isDevicesEditAllowed = false;
  isDevicesDeleteAllowed = false;
  isDevicesViewAllowed = false;
  isActivityCycleViewAllowed = false;
  isTrackingReportViewAllowed = false;
  isDevicesSwapViewAllowed = false;
  isDevicesSwapEditAllowed = false;
  isDeviceAutomationViewAllowed = false;
  isDeviceAutomationEditAllowed = false;
  isRolloutAddAllowed = false;
  isRolloutEditAllowed = false;
  isRolloutDeleteAllowed = false;
  isRolloutViewAllowed = false;
  isGroupEditAllowed = false;
  isGroupDeleteAllowed = false;
  isGroupAddAllowed = false;
  isGroupViewAllowed = false;
  isHierarchyAddAllowed = false;
  isHierarchyEditAllowed = false;
  isHierarchyDeleteAllowed = false;
  isHierarchyViewAllowed = false;
  isAlertsEditAllowed = false;
  isAlertsDeleteAllowed = false;
  isAlertsViewAllowed = false;
  isSoftwareAssignmentViewAllowed = false;
  isSoftwareAssignmentEditAllowed = false;
  isReferenceSetsViewAllowed = false;
  isSoftwareLibraryViewAllowed = false;
  isDeletedDeviceEditAllowed = false;
  isDeletedDeviceDeleteAllowed = false;
  isExportAddAllowed = false;
  isImportAddAllowed = false;
  isContentViewAllowed = false;
  isContentAddAllowed = false;
  iaDiagnosticActionsViewAllowed = false;
  isDiagnosticActionsAddAllowed = false;
  isDiagnosticActionsEditAllowed = false;
  isStatusEditAllowed = false;
  isAuditLogViewAllowed = false;
  isDeviceDetailsMoveAllowed = false;
  isDeviceDetailsDownloadScheduleAllowed = false;
  isDeviceDeleteAllowed = false;
  isMoveToHierarchyAllowed = false;
  isDeviceAdminPassCodeViewAllowed = false;
  isDeviceAdminPassCodeEditAllowed = false;
  isDeviceDetailsParametersAllowed = false;
  isDeviceDetailsContentsAllowed = false;
  isDeviceDetailsSoftwareAllowed = false;
  private ADD = 'Add';
  private EDIT = 'Edit';
  private DELETE = 'Delete';
  private VIEW = 'View';

  constructor(private featureToggleService: FeatureToggle) {}

  updatePermissionBasedOnRoles(userRoles: string[]): void {
    userRoles.forEach((roles: string) => {
      if (
        !this.isDeviceDetailAddAllowed &&
        this.featureToggleService.isFeatureActive(Features.HierarchyManagement) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICE_DETAILS]
      ) {
        this.isDeviceDetailAddAllowed = ROLES_CONFIG[roles][FeaturesEnum.DEVICE_DETAILS][this.ADD];
      }

      if (
        !this.isDeviceDetailEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICE_DETAILS]
      ) {
        this.isDeviceDetailEditAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.DEVICE_DETAILS][this.EDIT];
      }

      if (
        !this.isDeviceDetailDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICE_DETAILS]
      ) {
        this.isDeviceDetailDeleteAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.DEVICE_DETAILS][this.DELETE];
      }

      if (
        !this.isDeviceDetailViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICE_DETAILS]
      ) {
        this.isDeviceDetailViewAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.DEVICE_DETAILS][this.VIEW];
      }

      if (
        !this.isDevicesSwapViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICE_DETAILS]
      ) {
        this.isDevicesSwapViewAllowed = ROLES_CONFIG[roles][FeaturesEnum.DEVICE_DETAILS][this.VIEW];
      }

      if (
        !this.isDevicesSwapEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.SWAP]
      ) {
        this.isDevicesSwapEditAllowed = ROLES_CONFIG[roles][FeaturesEnum.SWAP][this.EDIT];
      }

      if (
        !this.isDeviceAutomationViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICES]
      ) {
        this.isDeviceAutomationViewAllowed = ROLES_CONFIG[roles][FeaturesEnum.DEVICES][this.VIEW];
      }

      if (
        !this.isDeviceAutomationEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICES]
      ) {
        this.isDeviceAutomationEditAllowed = ROLES_CONFIG[roles][FeaturesEnum.DEVICES][this.EDIT];
      }

      if (
        !this.isShipmentReceiptAddAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.SHIPMENT_RECEIPT]
      ) {
        this.isShipmentReceiptAddAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.SHIPMENT_RECEIPT][this.ADD];
      }

      if (
        !this.isShipmentReceiptEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.SHIPMENT_RECEIPT]
      ) {
        this.isShipmentReceiptEditAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.SHIPMENT_RECEIPT][this.EDIT];
      }

      if (
        !this.isShipmentReceiptDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.SHIPMENT_RECEIPT]
      ) {
        this.isShipmentReceiptDeleteAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.SHIPMENT_RECEIPT][this.DELETE];
      }

      if (
        !this.isShipmentReceiptViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.SHIPMENT_RECEIPT]
      ) {
        this.isShipmentReceiptViewAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.SHIPMENT_RECEIPT][this.VIEW];
      }

      if (
        !this.isChainOfCustodyAddAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.CHAIN_OF_CUSTODY]
      ) {
        this.isChainOfCustodyAddAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.CHAIN_OF_CUSTODY][this.ADD];
      }

      if (
        !this.isChainOfCustodyEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.CHAIN_OF_CUSTODY]
      ) {
        this.isChainOfCustodyEditAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.CHAIN_OF_CUSTODY][this.EDIT];
      }

      if (
        !this.isChainOfCustodyDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.CHAIN_OF_CUSTODY]
      ) {
        this.isChainOfCustodyDeleteAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.CHAIN_OF_CUSTODY][this.DELETE];
      }

      if (
        !this.isChainOfCustodyViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.CHAIN_OF_CUSTODY]
      ) {
        this.isChainOfCustodyViewAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.CHAIN_OF_CUSTODY][this.VIEW];
      }

      if (
        !this.isInspectionAddAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.INSPECTION]
      ) {
        this.isInspectionAddAllowed = ROLES_CONFIG[roles][FeaturesEnum.INSPECTION][this.ADD];
      }

      if (
        !this.isInspectionEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.INSPECTION]
      ) {
        this.isInspectionEditAllowed = ROLES_CONFIG[roles][FeaturesEnum.INSPECTION][this.EDIT];
      }

      if (
        !this.isInspectionDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.INSPECTION]
      ) {
        this.isInspectionDeleteAllowed = ROLES_CONFIG[roles][FeaturesEnum.INSPECTION][this.DELETE];
      }

      if (
        !this.isInspectionViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.INSPECTION]
      ) {
        this.isInspectionViewAllowed = ROLES_CONFIG[roles][FeaturesEnum.INSPECTION][this.VIEW];
      }

      if (
        !this.isLocationsAddAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.LOCATIONS]
      ) {
        this.isLocationsAddAllowed = ROLES_CONFIG[roles][FeaturesEnum.LOCATIONS][this.ADD];
      }

      if (
        !this.isLocationsEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.LOCATIONS]
      ) {
        this.isLocationsEditAllowed = ROLES_CONFIG[roles][FeaturesEnum.LOCATIONS][this.EDIT];
      }

      if (
        !this.isLocationsDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.LOCATIONS]
      ) {
        this.isLocationsDeleteAllowed = ROLES_CONFIG[roles][FeaturesEnum.LOCATIONS][this.DELETE];
      }

      if (
        !this.isLocationsViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.LOCATIONS]
      ) {
        this.isLocationsViewAllowed = ROLES_CONFIG[roles][FeaturesEnum.LOCATIONS][this.VIEW];
      }

      if (
        !this.isTenantsAddAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.TENANTS]
      ) {
        this.isTenantsAddAllowed = ROLES_CONFIG[roles][FeaturesEnum.TENANTS][this.ADD];
      }

      if (
        !this.isTenantsEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.TENANTS]
      ) {
        this.isTenantsEditAllowed = ROLES_CONFIG[roles][FeaturesEnum.TENANTS][this.EDIT];
      }

      if (
        !this.isTenantsDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.TENANTS]
      ) {
        this.isTenantsDeleteAllowed = ROLES_CONFIG[roles][FeaturesEnum.TENANTS][this.DELETE];
      }

      if (
        !this.isTenantsViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.TENANTS]
      ) {
        this.isTenantsViewAllowed = ROLES_CONFIG[roles][FeaturesEnum.TENANTS][this.VIEW];
      }

      if (
        !this.isDevicesAddAllowed &&
        this.featureToggleService.isFeatureActive(Features.SoftwareManagement) &&
        this.featureToggleService.isFeatureActive(Features.HierarchyManagement) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICES]
      ) {
        this.isDevicesAddAllowed = ROLES_CONFIG[roles][FeaturesEnum.DEVICES][this.ADD];
      }

      if (
        !this.isDevicesCloneAllowed &&
        this.featureToggleService.isFeatureActive(Features.SoftwareManagement) &&
        this.featureToggleService.isFeatureActive(Features.HierarchyManagement) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICES]
      ) {
        this.isDevicesCloneAllowed = ROLES_CONFIG[roles][FeaturesEnum.DEVICES][this.ADD];
      }

      if (
        !this.isDevicesEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICES]
      ) {
        this.isDevicesEditAllowed = ROLES_CONFIG[roles][FeaturesEnum.DEVICES][this.EDIT];
      }

      if (
        !this.isDevicesDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICES]
      ) {
        this.isDevicesDeleteAllowed = ROLES_CONFIG[roles][FeaturesEnum.DEVICES][this.DELETE];
      }

      if (
        !this.isDevicesViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICES]
      ) {
        this.isDevicesViewAllowed = ROLES_CONFIG[roles][FeaturesEnum.DEVICES][this.VIEW];
      }

      if (
        !this.isActivityCycleViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.ACTIVITY_CYCLE]
      ) {
        this.isActivityCycleViewAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.ACTIVITY_CYCLE][this.VIEW];
      }

      if (
        !this.isTrackingReportViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.TRACKING_REPORT]
      ) {
        this.isTrackingReportViewAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.TRACKING_REPORT][this.VIEW];
      }

      if (
        !this.isRolloutAddAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.ROLLOUT]
      ) {
        this.isRolloutAddAllowed = ROLES_CONFIG[roles][FeaturesEnum.ROLLOUT][this.ADD];
      }

      if (
        !this.isRolloutEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.ROLLOUT]
      ) {
        this.isRolloutEditAllowed = ROLES_CONFIG[roles][FeaturesEnum.ROLLOUT][this.EDIT];
      }

      if (
        !this.isRolloutDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.ROLLOUT]
      ) {
        this.isRolloutDeleteAllowed = ROLES_CONFIG[roles][FeaturesEnum.ROLLOUT][this.DELETE];
      }

      if (
        !this.isRolloutViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.ROLLOUT]
      ) {
        this.isRolloutViewAllowed = ROLES_CONFIG[roles][FeaturesEnum.ROLLOUT][this.VIEW];
      }

      if (
        !this.isGroupAddAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.GROUPS]
      ) {
        this.isGroupAddAllowed = ROLES_CONFIG[roles][FeaturesEnum.GROUPS][this.ADD];
      }

      if (
        !this.isGroupEditAllowed &&
        this.featureToggleService.isFeatureActive(Features.GroupsManagement) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.GROUPS]
      ) {
        this.isGroupEditAllowed = ROLES_CONFIG[roles][FeaturesEnum.GROUPS][this.EDIT];
      }

      if (
        !this.isGroupDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.GROUPS]
      ) {
        this.isGroupDeleteAllowed = ROLES_CONFIG[roles][FeaturesEnum.GROUPS][this.DELETE];
      }

      if (
        !this.isGroupViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.GROUPS]
      ) {
        this.isGroupViewAllowed = ROLES_CONFIG[roles][FeaturesEnum.GROUPS][this.VIEW];
      }

      if (
        !this.isHierarchyViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.HIERARCHIES]
      ) {
        this.isHierarchyViewAllowed = ROLES_CONFIG[roles][FeaturesEnum.HIERARCHIES][this.VIEW];
      }

      if (
        !this.isHierarchyAddAllowed &&
        this.featureToggleService.isFeatureActive(Features.HierarchyManagement) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.HIERARCHIES]
      ) {
        this.isHierarchyAddAllowed = ROLES_CONFIG[roles][FeaturesEnum.HIERARCHIES][this.ADD];
      }

      if (
        !this.isHierarchyEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.HIERARCHIES]
      ) {
        this.isHierarchyEditAllowed = ROLES_CONFIG[roles][FeaturesEnum.HIERARCHIES][this.EDIT];
      }

      if (
        !this.isHierarchyDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.HIERARCHIES]
      ) {
        this.isHierarchyDeleteAllowed = ROLES_CONFIG[roles][FeaturesEnum.HIERARCHIES][this.DELETE];
      }

      if (
        !this.isAlertsViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.ALERTS]
      ) {
        this.isAlertsViewAllowed = ROLES_CONFIG[roles][FeaturesEnum.ALERTS][this.ADD];
      }

      if (
        !this.isAlertsEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.ALERTS]
      ) {
        this.isAlertsEditAllowed = ROLES_CONFIG[roles][FeaturesEnum.ALERTS][this.EDIT];
      }

      if (
        !this.isAlertsDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.ALERTS]
      ) {
        this.isAlertsDeleteAllowed = ROLES_CONFIG[roles][FeaturesEnum.ALERTS][this.DELETE];
      }

      if (
        !this.isSoftwareAssignmentEditAllowed &&
        this.featureToggleService.isFeatureActive(Features.SoftwareManagement) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.SOFTWARE_ASSIGNMENT]
      ) {
        this.isSoftwareAssignmentEditAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.SOFTWARE_ASSIGNMENT][this.EDIT];
      }
      if (
        !this.isReferenceSetsViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.REFERENCE_SETS]
      ) {
        this.isReferenceSetsViewAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.REFERENCE_SETS][this.VIEW];
      }
      if (
        !this.isSoftwareLibraryViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.SOFTWARE_LIBRARY]
      ) {
        this.isSoftwareLibraryViewAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.SOFTWARE_LIBRARY][this.VIEW];
      }
      if (
        !this.isDeletedDeviceEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DELETED_DEVICES]
      ) {
        this.isDeletedDeviceEditAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.DELETED_DEVICES][this.EDIT];
      }
      if (
        !this.isDeletedDeviceDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DELETED_DEVICES]
      ) {
        this.isDeletedDeviceDeleteAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.DELETED_DEVICES][this.DELETE];
      }
      if (
        !this.isExportAddAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.EXPORT]
      ) {
        this.isExportAddAllowed = ROLES_CONFIG[roles][FeaturesEnum.EXPORT][this.ADD];
      }
      if (
        !this.isImportAddAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.IMPORT]
      ) {
        this.isImportAddAllowed = ROLES_CONFIG[roles][FeaturesEnum.IMPORT][this.ADD];
      }
      if (
        !this.isContentViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.CONTENT]
      ) {
        this.isContentViewAllowed = ROLES_CONFIG[roles][FeaturesEnum.CONTENT][this.VIEW];
      }
      if (
        !this.isDeviceDetailsDownloadScheduleAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DOWNLOAD_SCHEDULE]
      ) {
        this.isDeviceDetailsDownloadScheduleAllowed = ROLES_CONFIG[roles][FeaturesEnum.DOWNLOAD_SCHEDULE][this.VIEW];
      }
      if (
        !this.isContentAddAllowed &&
        this.featureToggleService.isFeatureActive(Features.ContentManagement) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.CONTENT]
      ) {
        this.isContentAddAllowed = ROLES_CONFIG[roles][FeaturesEnum.CONTENT][this.ADD];
      }
      if (
        !this.iaDiagnosticActionsViewAllowed &&
        this.featureToggleService.isFeatureActive(Features.BasicDiagnostics) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DIAGNOSTIC_ACTIONS]
      ) {
        this.iaDiagnosticActionsViewAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.DIAGNOSTIC_ACTIONS][this.VIEW];
      }
      if (
        !this.isDiagnosticActionsAddAllowed &&
        this.featureToggleService.isFeatureActive(Features.BasicDiagnostics) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DIAGNOSTIC_ACTIONS]
      ) {
        this.isDiagnosticActionsAddAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.DIAGNOSTIC_ACTIONS][this.ADD];
      }

      if (
        !this.isStatusEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DEVICE_STATUS]
      ) {
        this.isStatusEditAllowed = ROLES_CONFIG[roles][FeaturesEnum.DEVICE_STATUS][this.EDIT];
      }
      if (
        !this.isAuditLogViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.AUDIT_LOG]
      ) {
        this.isAuditLogViewAllowed = ROLES_CONFIG[roles][FeaturesEnum.AUDIT_LOG][this.VIEW];
      }
      if (
        !this.isDeviceDetailsMoveAllowed &&
        this.featureToggleService.isFeatureActive(Features.HierarchyManagement) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.MOVE]
      ) {
        this.isDeviceDetailsMoveAllowed = ROLES_CONFIG[roles][FeaturesEnum.MOVE][this.EDIT];
      }

      if (
        !this.isDeviceDeleteAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DELETE]
      ) {
        this.isDeviceDeleteAllowed = ROLES_CONFIG[roles][FeaturesEnum.DELETE][this.DELETE];
      }

      if (
        !this.isDeviceAdminPassCodeViewAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.ADMIN_CODE]
      ) {
        this.isDeviceAdminPassCodeViewAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.ADMIN_CODE][this.VIEW];
      }

      if (
        !this.isDeviceAdminPassCodeEditAllowed &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.ADMIN_CODE]
      ) {
        this.isDeviceAdminPassCodeEditAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.ADMIN_CODE][this.EDIT];
      }

      if (
        !this.isDeviceDetailsParametersAllowed &&
        this.featureToggleService.isFeatureActive(Features.ParameterManagement) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DOWNLOAD_SCHEDULE]
      ) {
        this.isDeviceDetailsParametersAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.DOWNLOAD_SCHEDULE][this.VIEW];
      }

      if (
        !this.isDeviceDetailsContentsAllowed &&
        this.featureToggleService.isFeatureActive(Features.ContentManagement) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.DOWNLOAD_SCHEDULE]
      ) {
        this.isDeviceDetailsContentsAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.DOWNLOAD_SCHEDULE][this.VIEW];
      }

      if (
        !this.isDeviceDetailsSoftwareAllowed &&
        this.featureToggleService.isFeatureActive(Features.SoftwareManagement) &&
        ROLES_CONFIG[roles] &&
        ROLES_CONFIG[roles][FeaturesEnum.SOFTWARE_ASSIGNMENT]
      ) {
        this.isDeviceDetailsSoftwareAllowed =
          ROLES_CONFIG[roles][FeaturesEnum.SOFTWARE_ASSIGNMENT][this.VIEW];
      }
    });
  }
}
