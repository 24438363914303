import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableModule } from '@portal/shared/ui/table';
import { AppCatalogTableTemplateComponent } from './app-catalog-table-template.component';

@NgModule({
  imports: [CommonModule, TableModule],
  declarations: [AppCatalogTableTemplateComponent],
  exports: [AppCatalogTableTemplateComponent],
})
export class AppCatalogTableTemplateModule {}
