import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationGroupRoutingModule } from './organization-group-routing.module';
import { CreateOrganizationGroupComponent } from './components/create-organization-group/create-organization-group.component';
import { FormOrganizationGroupComponent } from './components/form-organization-group/form-organization-group.component';
import { ListOrganizationGroupComponent } from './components/list-organization-group/list-organization-group.component';
import { FormModule } from '@portal/shared/ui/form';
import { SharedUiFilterModule } from '@portal/shared/ui/filter';
import { HierarchySingleSelectModule } from '@portal/shared/ui/hierarchy-single-select/src';
import { TableModule } from '@portal/shared/ui/table';
import { FilterOrganisationGroupComponent } from './filter/filter.component';
import { SharedUiModalModule } from '@portal/shared/ui/modal';
import { SharedAuthAuthorizationModule } from '@portal/shared/auth/authorization';
import { DetailsOrganizationGroupComponent } from './components/details-organization-group/details-organization-group.component';
import { SpinnerModule } from '@portal/shared/ui/spinner';

@NgModule({
  declarations: [
    CreateOrganizationGroupComponent,
    FormOrganizationGroupComponent,
    ListOrganizationGroupComponent,
    FilterOrganisationGroupComponent,
    DetailsOrganizationGroupComponent,
  ],
  imports: [
    CommonModule,
    OrganizationGroupRoutingModule,
    FormModule,
    SharedUiFilterModule,
    HierarchySingleSelectModule,
    TableModule,
    SharedUiModalModule,
    SharedAuthAuthorizationModule,
    SpinnerModule,
  ],
  exports: [
    CreateOrganizationGroupComponent,
    FormOrganizationGroupComponent,
    ListOrganizationGroupComponent,
  ],
})
export class OrganizationGroupModule {}
