export const TRX_DATA_MOCK = [
  {
    organisationName: 'BitPayDevBLRStore',
    UUID: 'fddd3c92-0b3d-4f0e-84fe-7e1bbc34245f',
    idempotencyKey: '91f88e2f-026f-4f3c-92f5-c31648803747',
    transactionId: 'fddd3c92-0b3d-4f0e-84fe-7e1bbc34245f',
    referenceId: '141351000376',
    initiatorTraceId: '000434',
    gatewayTraceId: 'fddd3c92-0b3d-4f0e-84fe-7e1bbc34245f',
    transactionType: 'SALE',
    additionalServices: [],
    amount: { currencyCode: 'EUR', value: 5.0 },
    detailedAmount: {
      currencyCode: 'EUR',
      totalAmount: '43.2 EUR',
      cashbackAmount: 0.0,
      feeAmounts: [],
      rebateAmounts: [],
    },
    createdDateTime: '2022-04-01',
    capturedDateTime: '2022-04-01',
    transmittedDateTime: '2022-04-01',
    capture: true,
    partial: false,
    instrument: [
      {
        cardBrand: 'PayPal',
        sequenceNumber: 0,
        expiryMonth: 5,
        expiryYear: 2022,
        effectiveDate: '2020-01-01',
        trackData: [],
        ICC: {
          data: '0057135071004150002256D22059019176870000000F00820239000095050480008000009C01005F24032205319F0607a00000000310109F100706001203a400009F2608e600b01fcfcf8c879F2701809F3303E0B8C89F34009F3501229F3602059c9F37040914f697DF800100DF8101029F0A080001050100000000',
          ARQC: 'E600B01FCFCF8C87',
          applicationInterchangeProfile: '3900',
          cryptogramInformationData: '80',
          issuerApplicationData: '06001203A40000',
          applicationExpirationDate: '220531',
          terminalCapabilities: 'E0B8C8',
          terminalType: '22',
          terminalVerificationResults: '0480008000',
          transactionType: '00',
          unpredictableNumber: '0914F697',
          expectedApplicationTransactionCounter: '059C',
        },
        maskedCardNumber: '507100******2256',
        cardVerificationType: 'CSCBYPASS',
        instrumentType: 'CARD',
      },
      { instrumentType: 'TOKEN' },
    ],
    customer: {
      phoneNumbers: [],
      authentication: { cardVerificationType: 'CSCBYPASS', AVSIndicator: 0 },
    },
    merchant: {
      UUID: '91f88e2f-026f-4f3c-92f5-c3c3b0ec66c5',
      id: '4340018',
      industry: 'RETAIL',
      name: 'VZY ELITT CB6                                               ',
      locale: { countryCode: 'FR', language: 'fr-FR' },
      sites: [],
      contracts: [
        {
          contractId: 'cd0bd05e-5361-4f39-b9f4-9fc771404615',
          merchantId: '4340018',
          otherParty: { role: 'ACQUIRER', acquiringInstitution: 1000600 },
          paymentType: [],
          MCC: '3333',
        },
      ],
      contacts: [],
      businessIdentifiers: [],
    },
    POI: {
      UUID: 'e3ada79f-d957-4292-9dc6-fa93d735f854',
      device: {
        serialNumber: '275329192',
        deviceType: '700',
        manufacturer: 'Verifone',
        capabilities: [
          'MANUAL',
          'MAG_STRIPE',
          'ICC',
          'EMV_CONTACTLESS',
          'MAG_STRIPE_CONTACTLESS',
          'PRINTER',
        ],
        updateCapabilities: [],
        messageCapabilities: [],
        supportedComms: ['UNKNOWN'],
        keyMetadata: [],
      },
      type: 'POINT_OF_SALE',
      contract: { poiId: '2' },
      software: [{ version: '1.1.0.0' }],
      verificationCapabilities: [],
    },
    context: {
      paymentContext: {
        cardPresent: true,
        cardholderPresent: true,
        salesChannel: 'POS',
        brandChoice: 'CARDHOLDER',
        merchantPresent: 'ATTENDED',
        merchantForce: true,
        authenticationMethod: ['NONE'],
        authenticationEntity: 'ICC',
        entryMode: 'ICC',
        authentication: ['NONE'],
        online: true,
        onlineReason: 'STATUS',
        additionalOnlineReason: ['TERMINAL', 'STATUS'],
        accountType: 'DEFAULT',
        attendantLanguage: 'fr-FR',
        channel: 'POS',
        fallbackReason: 'NO_FALLBACK',
        acquirerReference: '1',
        batchNumber: '001000',
        applicationId: 'Proximit‚ CB contact (20)',
        terminalDecision: [],
        logicalSysAcceptanceNumber: 110,
        authorisationForcingCode: 'NO_FORCING',
      },
      salesContext: { laneNumber: '2', storeIdentifier: '20_0000', invoiceNumber: '663' },
    },
    outcome: [
      {
        authorisationOnline: true,
        authorisationCode: 'JA123 ',
        forcedTransactionState: 'AUTHORISED',
        response: 'SUCCESS',
        responseCode: '0000',
        responseMessage: 'Approved',
        additionalRefusalReason: [],
        processorMCC: '3333',
        errorCode: '00',
        iccResponse: {
          data: '0057135071004150002256D22059019176870000000F00820239000095050480008000009C01005F24032205319F0607a00000000310109F100706001203a400009F2608e600b01fcfcf8c879F2701809F3303E0B8C89F34009F3501229F3602059c9F37040914f697DF800100DF8101029F0A080001050100000000',
          issuerAuthenticationData: '7D93541D00820000',
        },
        errorMessage: 'Transaction approved or successfully completed',
        transactionTraceId: '000434',
        outcomeType: 'TRAN_RESULT',
        actions: [],
      },
      {
        acquiringInstitutionId: '00001000600',
        acquirerResponseCode: '00',
        acquirerResponseMessage: 'Approved',
        acquirerReferenceNumber: '663',
        acquirerReferenceData: '18071918',
        processorRRN: '663',
        processorSTAN: '000434',
        outcomeType: 'ACQ_RESULT',
        idCheckResult: 'NOT_APPLICABLE',
        authorisationCodeCheckResult: 'NOT_APPLICABLE',
        metadata: {
          acquirerResponseCode: '00',
          sessionId: '28',
          messageTransactionInitiator: 'COn en a Gros...1508-1651-1651',
          deferredTransaction: false,
        },
      },
    ],
    paymentSummary: {
      state: 'AUTHORISED',
      displayStateHumanReadable: 'SALE AUTHORISED',
      displayTransactionState: 'Cancelled',
      displayTransactionType: 'Declined',
      displayPartial: false,
      authState: 'AUTHORISED',
      disputeStatuses: [],
      totalAuthorisedAmount: { currencyCode: 'EUR', value: 10.0 },
      currentAmount: { currencyCode: 'EUR', value: '12.0 EUR' },
      amountAvailableForCapture: { currencyCode: 'EUR', value: 0 },
      captureAmountModifier: 0,
      amountAvailableForRefund: { currencyCode: 'EUR', value: 10.0 },
      eventLog: [
        {
          uuid: 'eb29b9a3-1325-4bb6-9097-0d267954d841',
          stateHumanReadable: 'SALE AUTHORISED',
          transactionState: 'AUTHORISED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: { currencyCode: 'EUR', value: 10.0 },
          userId: '804-609-283',
          datetime: '2022-04-01',
          idempotencyKey: '416da9f3-ea3a-411b-b9e7-1982c8522bde',
          eventTreeUUIDs: ['eb29b9a3-1325-4bb6-9097-0d267954d841'],
        },
      ],
      allowedOperations: [],
      expiry: '2022-04-01',
    },
  },
  {
    organisationName: 'AUTOQA',
    UUID: '308f0f0b-deda-4dd9-bde7-2f45ad3ae62d',
    idempotencyKey: '91f88e2f-026f-4f3c-92f5-c31648803431',
    transactionId: '308f0f0b-deda-4dd9-bde7-2f45ad3ae62d',
    referenceId: '662',
    initiatorTraceId: '000433',
    gatewayTraceId: '308f0f0b-deda-4dd9-bde7-2f45ad3ae62d',
    transactionType: 'SALE',
    additionalServices: [],
    amount: { currencyCode: 'AMD', value: 5.0 },
    detailedAmount: {
      currencyCode: 'AMD',
      totalAmount: '33.4 AMD',
      cashbackAmount: 0.0,
      feeAmounts: [],
      rebateAmounts: [],
    },
    createdDateTime: '2022-04-01',
    capturedDateTime: '2022-04-01',
    transmittedDateTime: '2022-04-01',
    capture: true,
    partial: false,
    instrument: [
      {
        cardBrand: 'Visa',
        sequenceNumber: 0,
        expiryMonth: 5,
        expiryYear: 2022,
        effectiveDate: '2020-01-01',
        trackData: [],
        ICC: {
          data: '0057135071004150002256D22059019176870000000F00820239000095050480008000009C01005F24032205319F0607a00000000310109F100706001203a400009F260894491283fa2b44d29F2701809F3303E0B8C89F34009F3501229F3602059b9F37047e6543ebDF800100DF8101029F0A080001050100000000',
          ARQC: '94491283FA2B44D2',
          applicationInterchangeProfile: '3900',
          cryptogramInformationData: '80',
          issuerApplicationData: '06001203A40000',
          applicationExpirationDate: '220531',
          terminalCapabilities: 'E0B8C8',
          terminalType: '22',
          terminalVerificationResults: '0480008000',
          transactionType: '00',
          unpredictableNumber: '7E6543EB',
          expectedApplicationTransactionCounter: '059B',
        },
        maskedCardNumber: '507100******2256',
        cardVerificationType: 'CSCBYPASS',
        instrumentType: 'CARD',
      },
      { instrumentType: 'TOKEN' },
    ],
    customer: {
      phoneNumbers: [],
      authentication: { cardVerificationType: 'CSCBYPASS', AVSIndicator: 0 },
    },
    merchant: {
      UUID: '91f88e2f-026f-4f3c-92f5-c3c3b0ec66c5',
      id: '4340018',
      industry: 'RETAIL',
      name: 'VZY ELITT CB6                                               ',
      locale: { countryCode: 'FR', language: 'fr-FR' },
      sites: [],
      contracts: [
        {
          contractId: 'cd0bd05e-5361-4f39-b9f4-9fc771404615',
          merchantId: '4340018',
          otherParty: { role: 'ACQUIRER', acquiringInstitution: 1000600 },
          paymentType: [],
          MCC: '3333',
        },
      ],
      contacts: [],
      businessIdentifiers: [],
    },
    POI: {
      UUID: 'e3ada79f-d957-4292-9dc6-fa93d735f854',
      device: {
        serialNumber: '275329192',
        deviceType: '700',
        manufacturer: 'Verifone',
        capabilities: [
          'MANUAL',
          'MAG_STRIPE',
          'ICC',
          'EMV_CONTACTLESS',
          'MAG_STRIPE_CONTACTLESS',
          'PRINTER',
        ],
        updateCapabilities: [],
        messageCapabilities: [],
        supportedComms: ['UNKNOWN'],
        keyMetadata: [],
      },
      type: 'POINT_OF_SALE',
      contract: { poiId: '2' },
      software: [{ version: '1.1.0.0' }],
      verificationCapabilities: [],
    },
    context: {
      paymentContext: {
        cardPresent: true,
        cardholderPresent: true,
        salesChannel: 'POS',
        brandChoice: 'CARDHOLDER',
        merchantPresent: 'ATTENDED',
        merchantForce: true,
        authenticationMethod: ['NONE'],
        authenticationEntity: 'ICC',
        entryMode: 'ICC',
        authentication: ['NONE'],
        online: true,
        onlineReason: 'STATUS',
        additionalOnlineReason: ['TERMINAL', 'STATUS'],
        accountType: 'DEFAULT',
        attendantLanguage: 'fr-FR',
        channel: 'POS',
        fallbackReason: 'NO_FALLBACK',
        acquirerReference: '1',
        batchNumber: '001000',
        applicationId: 'Proximit‚ CB contact (20)',
        terminalDecision: [],
        logicalSysAcceptanceNumber: 110,
        authorisationForcingCode: 'NO_FORCING',
      },
      salesContext: { laneNumber: '2', storeIdentifier: '20_0000', invoiceNumber: '662' },
    },
    outcome: [
      {
        authorisationOnline: true,
        authorisationCode: 'SQA001',
        response: 'SUCCESS',
        responseCode: '0000',
        responseMessage: 'Approved',
        additionalRefusalReason: [],
        processorMCC: '3333',
        errorCode: '00',
        iccResponse: {
          data: '0057135071004150002256D22059019176870000000F00820239000095050480008000009C01005F24032205319F0607a00000000310109F100706001203a400009F260894491283fa2b44d29F2701809F3303E0B8C89F34009F3501229F3602059b9F37047e6543ebDF800100DF8101029F0A080001050100000000',
          issuerAuthenticationData: '9961A9B900000000',
        },
        errorMessage: 'Transaction approved or successfully completed',
        transactionTraceId: '000433',
        outcomeType: 'TRAN_RESULT',
        actions: [],
      },
      {
        acquiringInstitutionId: '00001000600',
        acquirerResponseCode: '00',
        acquirerResponseMessage: 'Approved',
        acquirerReferenceNumber: '662',
        processorRRN: '662',
        processorSTAN: '000433',
        outcomeType: 'ACQ_RESULT',
        idCheckResult: 'NOT_APPLICABLE',
        authorisationCodeCheckResult: 'NOT_APPLICABLE',
        metadata: { acquirerResponseCode: '00', sessionId: '28', deferredTransaction: false },
      },
    ],
    paymentSummary: {
      state: 'AUTHORISED',
      displayStateHumanReadable: 'SALE AUTHORISED',
      displayTransactionState: 'Approved',
      displayTransactionType: 'Refund',
      displayPartial: false,
      authState: 'AUTHORISED',
      disputeStatuses: [],
      totalAuthorisedAmount: { currencyCode: 'AMD', value: 10.0 },
      currentAmount: { currencyCode: 'AMD', value: '105.0 AMD' },
      amountAvailableForCapture: { currencyCode: 'AMD', value: 0 },
      captureAmountModifier: 0,
      amountAvailableForRefund: { currencyCode: 'AMD', value: 10.0 },
      eventLog: [
        {
          uuid: 'eb29b9a3-1325-4bb6-9097-0d267954d841',
          stateHumanReadable: 'SALE AUTHORISED',
          transactionState: 'AUTHORISED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: { currencyCode: 'AMD', value: 10.0 },
          userId: '804-609-283',
          datetime: '2022-04-01',
          idempotencyKey: '416da9f3-ea3a-411b-b9e7-1982c8522bde',
          eventTreeUUIDs: ['eb29b9a3-1325-4bb6-9097-0d267954d841'],
        },
      ],
      allowedOperations: [],
      expiry: '2022-04-01',
    },
  },
  {
    organisationName: 'VERIFONE BYOD',
    UUID: 'eb29b9a3-1325-4bb6-9097-0d267954d841',
    idempotencyKey: '416da9f3-ea3a-411b-b9e7-1982c8522bde',
    transactionId: 'eb29b9a3-1325-4bb6-9097-0d267954d841',
    referenceId: '011019001149',
    initiatorTraceId: '1149',
    gatewayTraceId: 'eb29b9a3-1325-4bb6-9097-0d267954d841',
    transactionType: 'SALE',
    additionalServices: [],
    amount: { currencyCode: 'GBP', value: 10.0 },
    detailedAmount: {
      currencyCode: 'GBP',
      totalAmount: '10.0 GBP',
      feeAmounts: [],
      rebateAmounts: [],
    },
    createdDateTime: '2022-04-01',
    timezone: 'Europe/London',
    capturedDateTime: '2022-04-01',
    capture: true,
    instrument: [
      {
        sequenceNumber: 1,
        expiryMonth: 12,
        expiryYear: 2024,
        trackData: [{ trackNumber: 2, trackFormat: 'ISO', trackLength: 32 }],
        ICC: {
          data: '9F1A0208269F2701809A032204019B0200009F360200029F03060000000000005F24032412319C01009F37042682A80E9F2608AE54941822F550B85F3401019F090200969F4104000008879F3501229F02060000000010009F101706010A03A000000F00564953414C3354455354434153459F33030008C85F2A020826820200009F1E083032333435363738950500000000008407A0000000031010',
          ARQC: 'AE54941822F550B8',
          amountAuthorised: 1000,
          applicationInterchangeProfile: '0000',
          cryptogramInformationData: '80',
          issuerApplicationData: '06010A03A000000F00564953414C335445535443415345',
          applicationExpirationDate: '241231',
          sequenceNumber: 1,
          terminalApplicationVersionNumber: '0096',
          terminalCapabilities: '0008C8',
          terminalCountryCode: '0826',
          transactionCurrencyCode: '0826',
          terminalType: '22',
          terminalVerificationResults: '0000000000',
          transactionDate: '220401',
          transactionStatusInformation: '0000',
          transactionType: '00',
          unpredictableNumber: '2682A80E',
          expectedApplicationTransactionCounter: '0002',
          transactionSequenceCounter: 887.0,
          dedicatedFileName: 'A0000000031010',
        },
        maskedCardNumber: '476173******0119',
        cardBrand: 'Vipps',
        cardProduct: 'VISA-CREDIT',
        cardVerificationType: 'CSCBYPASS',
        instrumentType: 'CARD',
      },
      { instrumentType: 'TOKEN' },
    ],
    customer: { phoneNumbers: [], authentication: { cardVerificationType: 'CSCBYPASS' } },
    merchant: {
      UUID: '5e890ade-5282-4f01-a3bd-a7c631445516',
      id: '540436504204553',
      name: 'Verifone T650p',
      locale: { countryCode: 'GB', language: 'en-US', timezone: 'GMT' },
      sites: [],
      deliveryAddress: {
        addressLine1: '100 Eureka Park',
        city: 'Ashford',
        countrySubdivision: 'Kent',
        postCode: 'TN25 4AZ',
        country: 'GB',
      },
      contracts: [
        {
          contractId: 'b0965bba-7f1c-4760-a896-1145ad23f5a7',
          merchantId: '540436504204553',
          paymentType: [],
          MCC: '5999',
        },
      ],
      email: 'helpdesk@verifone.com',
      contacts: [],
      businessIdentifiers: [],
    },
    POI: {
      UUID: 'e7691cd2-d1b5-4c3a-9b3f-4bdb1d44a949',
      id: '29080008',
      device: {
        serialNumber: '804-609-283',
        capabilities: [
          'PINPAD',
          'CONTACTLESS',
          'EMV_CONTACTLESS',
          'MAG_STRIPE_CONTACTLESS',
          'CUSTOMER_DISPLAY',
          'CASHIER_DISPLAY',
          'ICC',
          'MAG_STRIPE',
          'MANUAL',
          'PRINTER',
        ],
        updateCapabilities: [],
        messageCapabilities: [],
        supportedComms: ['WIFI'],
        keyMetadata: [],
      },
      site: {
        location: { country: 'GB', locationCategory: 'FIXED_MERCHANT' },
        phoneNumbers: [],
        contacts: [],
      },
      type: 'UNSPECIFIED',
      contract: { poiId: '29080008' },
      software: [{ version: '5.321.0.7' }],
      verificationCapabilities: [
        'CONSUMER_ON_DEVICE',
        'DIGITAL_SIGNATURE',
        'MANUAL',
        'OFFLINE_PIN',
        'STRONG_CUSTOMER_AUTHENTICATION',
        'CARDHOLDER_AUTH_DATA',
      ],
      pinLengthCapability: 12,
      approvalCodeLength: 12,
      captureCardCapable: false,
      contactlessSingleTapSupport: 'SUPPORTED',
    },
    context: {
      paymentContext: {
        cardPresent: true,
        cardholderPresent: true,
        salesChannel: 'POS',
        brandChoice: 'MERCHANT',
        merchantPresent: 'ATTENDED',
        authenticationMethod: ['NONE'],
        authenticationAttempts: '0',
        entryMode: 'ICC_CONTACTLESS',
        authentication: ['NONE'],
        online: true,
        onlineReason: 'ICC',
        additionalOnlineReason: [],
        accountType: 'CREDIT',
        channel: 'POS',
        fallbackReason: 'NO_FALLBACK',
        terminalDecision: [],
      },
      salesContext: {
        salesIdentifier: '78a324ad-90a4-4f84-80e4-93b81a76d4bf',
        invoiceNumber: '040010422101947',
      },
    },
    outcome: [
      {
        acquirerResponseCode: '00',
        acquirerResponseMessage: 'AUTH CODE:049789',
        schemeReferenceData: '302091336254721',
        outcomeType: 'ACQ_RESULT',
        idCheckResult: 'NOT_APPLICABLE',
        authorisationCodeCheckResult: 'NOT_APPLICABLE',
      },
      {
        authorisationCode: '049789',
        response: 'SUCCESS',
        responseCode: '0000',
        responseMessage: 'AUTH CODE:049789',
        additionalRefusalReason: [],
        cvvResult: '6',
        avsResultCode: 'C',
        iccResponse: {
          data: '9110C3C6B4C9BD04E6BC3030',
          issuerAuthenticationData: 'C3C6B4C9BD04E6BC3030',
        },
        transactionTraceId: '1149',
        outcomeType: 'TRAN_RESULT',
        actions: [],
      },
    ],
    paymentSummary: {
      state: 'AUTHORISED',
      displayStateHumanReadable: 'SALE AUTHORISED',
      displayTransactionState: 'Authorised',
      displayTransactionType: 'Preauthorisation',
      displayPartial: false,
      authState: 'AUTHORISED',
      disputeStatuses: [],
      totalAuthorisedAmount: { currencyCode: 'GBP', value: 10.0 },
      currentAmount: { currencyCode: 'GBP', value: '10.0 GBP' },
      amountAvailableForCapture: { currencyCode: 'GBP', value: 0 },
      captureAmountModifier: 0,
      amountAvailableForRefund: { currencyCode: 'GBP', value: 10.0 },
      eventLog: [
        {
          uuid: 'eb29b9a3-1325-4bb6-9097-0d267954d841',
          stateHumanReadable: 'SALE AUTHORISED',
          transactionState: 'AUTHORISED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: { currencyCode: 'GBP', value: 10.0 },
          userId: '804-609-283',
          datetime: '2022-04-01',
          idempotencyKey: '416da9f3-ea3a-411b-b9e7-1982c8522bde',
          eventTreeUUIDs: ['eb29b9a3-1325-4bb6-9097-0d267954d841'],
        },
      ],
      allowedOperations: [],
      expiry: '2022-04-01',
    },
    quickPaymentService: false,
  },
  {
    organisationName: 'Coffee Shop',
    UUID: '65e5e45d-4717-474e-b26a-7c17bb5e2f60',
    transactionId: '1873791955387984',
    referenceId: '141343000375',
    gatewayTraceId: '1873791955387984',
    transactionType: 'SALE',
    additionalServices: [],
    amount: { currencyCode: 'USD', value: 10.01 },
    detailedAmount: {
      currencyCode: 'USD',
      totalAmount: '33.4 USD',
      feeAmounts: [
        {
          amount: 0,
          currencyCode: 'USD',
          rateType: 'SELL_RATE',
          type: 'VERIFONE',
          feeConfiguration: [],
        },
      ],
      rebateAmounts: [],
    },
    createdDateTime: '2022-04-01',
    capture: true,
    instrument: [{ instrumentType: 'CRYPTO', cardBrand: 'Venmo QR' }],
    merchant: {
      UUID: '76e84daa-c954-4c6a-8f7f-09758c078670',
      id: '789569',
      locale: { countryCode: 'US', language: 'us', timezone: 'Europe/Stockholm' },
      sites: [],
      contracts: [
        {
          UUID: '9e7029b1-ad5f-40d7-b863-ba12c14ee244',
          contractId: '9e7029b1-ad5f-40d7-b863-ba12c14ee244',
          merchantId: 'swooosh',
          paymentType: ['CRYPTO'],
          MCC: '4444',
        },
      ],
      contacts: [],
      businessIdentifiers: [],
      externalEntityIdentifiers: {},
    },
    POI: {
      id: '84515378',
      device: {
        serialNumber: '100-100-201',
        capabilities: [],
        updateCapabilities: [],
        messageCapabilities: [],
        supportedComms: [],
        keyMetadata: [],
      },
      contract: { UUID: '470972d8-7eb4-45e0-a87e-cea5ae8e900e', poiId: '84515378' },
      software: [],
      verificationCapabilities: [],
    },
    context: {
      paymentContext: {
        cardPresent: false,
        cardholderPresent: false,
        salesChannel: 'POS',
        wallet: 'BITPAY',
        authenticationMethod: [],
        authentication: [],
        onlineReason: 'TERMINAL',
        additionalOnlineReason: [],
        accountType: 'DEFAULT',
        fallbackReason: 'NO_FALLBACK',
        terminalDecision: [],
      },
      salesContext: { salesReferenceNumber: '3' },
    },
    outcome: [
      { token: 'token', outcomeType: 'CRYPTO_RESULT' },
      { additionalRefusalReason: [], outcomeType: 'TRAN_RESULT', actions: [] },
    ],
    paymentSummary: {
      state: 'AUTHORISED',
      displayStateHumanReadable: 'SALE AUTHORISED',
      displayTransactionState: 'Expired',
      displayTransactionType: 'Authorisation',
      displayPartial: false,
      authState: 'AUTHORISED',
      disputeStatuses: [],
      totalAuthorisedAmount: { currencyCode: 'USD', value: 10.01 },
      currentAmount: { currencyCode: 'USD', value: '10.01 USD' },
      amountAvailableForCapture: { currencyCode: 'USD', value: 0 },
      captureAmountModifier: 0,
      amountAvailableForRefund: { currencyCode: 'USD', value: 10.01 },
      eventLog: [
        {
          uuid: '65e5e45d-4717-474e-b26a-7c17bb5e2f60',
          stateHumanReadable: 'SALE INITIATED',
          transactionState: 'INITIATED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: { currencyCode: 'USD', value: 10.01 },
          datetime: '2022-04-01',
          eventTreeUUIDs: ['65e5e45d-4717-474e-b26a-7c17bb5e2f60'],
        },
        {
          uuid: '65e5e45d-4717-474e-b26a-7c17bb5e2f60',
          stateHumanReadable: 'SALE AUTHORISED',
          transactionState: 'AUTHORISED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: { currencyCode: 'USD', value: 10.01 },
          datetime: '2022-04-01',
          eventTreeUUIDs: ['65e5e45d-4717-474e-b26a-7c17bb5e2f60'],
        },
      ],
      allowedOperations: [],
      expiry: '2022-04-01',
    },
    metadata: {
      storeHours: [{ day: 'TUESDAY', times: { start: '07:00', end: '17:00' } }],
      apmProviderTransactionId: 'hostTransactionId',
      apmBankTransactionId: '1E2FC19E5E5E4E18916609B7F8911C12',
      altVfiEntityId: '789569',
      apmDatePaid: '2019-01-02',
      apmProviderMerchantId: 'swooosh',
      altVfiPoiId: '84515378',
      apmDateCreated: '2019-01-02',
    },
  },
  {
    organisationName: 'Verifone',
    UUID: '954db4ee-8908-4a52-88ac-d4ef81ac8648',
    idempotencyKey: 'f78821e9-ad62-43db-a50e-a8777f8b346b',
    transactionId: '954db4ee-8908-4a52-88ac-d4ef81ac8648',
    referenceId: '140422000014',
    initiatorTraceId: '7719',
    gatewayTraceId: '954db4ee-8908-4a52-88ac-d4ef81ac8648',
    transactionType: 'SALE',
    additionalServices: [],
    amount: { currencyCode: 'USD', value: 41.94 },
    detailedAmount: {
      currencyCode: 'USD',
      totalAmount: '41.94 USD',
      feeAmounts: [
        {
          amount: 0,
          currencyCode: 'USD',
          rateType: 'SELL_RATE',
          type: 'VERIFONE',
          feeConfiguration: [],
        },
      ],
      rebateAmounts: [],
    },
    createdDateTime: '2022-04-01',
    capturedDateTime: '2022-04-01',
    transmittedDateTime: '2022-04-01',
    capture: true,
    instrument: [
      {
        cardholderName: 'John Smith',
        expiryMonth: 3,
        expiryYear: 2030,
        trackData: [],
        maskedCardNumber: '497794******9497',
        cardBrand: 'PayPal QR',
        cardProduct: 'VISA GOLD',
        fundingSource: 'UNKNOWN',
        country: 'FRA',
        cardVerificationType: 'CSCBYPASS',
        instrumentType: 'CARD',
      },
      { instrumentType: 'TOKEN' },
    ],
    customer: {
      phoneNumbers: [],
      shipping: {
        receiptEmail: 'Sudhakar.K@VERIFONE.com',
        email: 'Sudhakar.K@VERIFONE.com',
        recipientName: 'John',
        firstName: 'John',
        lastName: 'Doe',
        phoneNumber: { value: '1234567891', isPrimary: false, phoneType: 'HOME' },
        address: {
          addressLine1: 'Arizona street 5',
          city: 'Amsterdam',
          postCode: '1012 EA',
          country: 'NL',
        },
      },
      IPAddressV4: '86.83.91.157',
      IPCountry: 'NL',
      authentication: { cardVerificationType: 'CSCBYPASS' },
    },
    merchant: {
      UUID: '6d0484d9-5440-48f8-a03c-e7d1ad1ddf0e',
      id: '4335001',
      name: 'DEV_Verifone Synthetic Transactions EMEA',
      locale: { countryCode: 'IL', language: 'en', timezone: 'IST' },
      sites: [],
      contracts: [
        {
          contractId: '1a0fe35c-17ed-433f-9760-ee10f8fe6f74',
          merchantId: '4335001',
          otherParty: { role: 'ACQUIRER', acquiringInstitution: 49720030002 },
          paymentType: [],
          MCC: '1234',
        },
      ],
      URL: 'http://example.com',
      contacts: [],
      businessIdentifiers: [],
    },
    POI: {
      type: 'VIRTUAL',
      contract: { poiId: 'peace' },
      software: [],
      verificationCapabilities: [],
    },
    context: {
      paymentContext: {
        cardPresent: false,
        cardholderPresent: false,
        salesChannel: 'ECOMMERCE',
        brandChoice: 'MERCHANT',
        merchantPresent: 'UNATTENDED',
        authenticationMethod: ['SECURE_ELECTRONIC_COMMERCE'],
        entryMode: 'MANUAL',
        authentication: ['SECURE_ELECTRONIC_COMMERCE'],
        onlineReason: 'TERMINAL',
        additionalOnlineReason: [],
        accountType: 'CREDIT',
        channel: 'ECOM',
        fallbackReason: 'NO_FALLBACK',
        terminalDecision: [],
        logicalSysAcceptanceNumber: 1,
      },
      salesContext: { salesReferenceNumber: 'TEST-ECOM' },
    },
    outcome: [
      {
        acquiringInstitutionId: '49720030002',
        acquirerResponseCode: '00',
        acquirerResponseMessage: 'Approved',
        processorSTAN: '7719',
        outcomeType: 'ACQ_RESULT',
        idCheckResult: 'NOT_APPLICABLE',
        authorisationCodeCheckResult: 'NOT_APPLICABLE',
        metadata: {
          transmissionDateTime: '2022-04-01',
          applicationLocalDate: '0401',
          receiptHeaderText: 'ENSEIGNE    PECCAVET DCMP                         ',
          receiptFooterText: '77MELUN                                           ',
          acquirerResponseCode: '00',
          processorType: 'OTHER',
          syntheticTransactionResult: false,
          applicationLocalTime: '101922',
          cumulativeAmount: '137715.72',
          fundingSource: 'UNKNOWN',
        },
      },
      {
        authorisationOnline: true,
        authorisationCode: '526215',
        response: 'SUCCESS',
        responseCode: '0000',
        responseMessage: 'Approved',
        additionalRefusalReason: [],
        processorMCC: '1234',
        errorCode: '00',
        errorMessage: 'Transaction approved or successfully completed',
        transactionTraceId: '7719',
        outcomeType: 'TRAN_RESULT',
        actions: [],
      },
    ],
    paymentSummary: {
      state: 'AUTHORISED',
      displayStateHumanReadable: 'SALE AUTHORISED',
      displayTransactionState: 'Failed',
      displayTransactionType: 'Capture',
      displayPartial: false,
      authState: 'AUTHORISED',
      disputeStatuses: [],
      totalAuthorisedAmount: { currencyCode: 'USD', value: 41.94 },
      currentAmount: { currencyCode: 'USD', value: '41.94 USD' },
      amountAvailableForCapture: { currencyCode: 'USD', value: 0 },
      captureAmountModifier: 0,
      amountAvailableForRefund: { currencyCode: 'USD', value: 41.94 },
      eventLog: [
        {
          uuid: '954db4ee-8908-4a52-88ac-d4ef81ac8648',
          stateHumanReadable: 'SALE AUTHORISED',
          transactionState: 'AUTHORISED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: { currencyCode: 'USD', value: 41.94 },
          userId: '9a1ec4d4-06f3-4c08-b38d-eebcd442c0de',
          datetime: '2022-04-01',
          idempotencyKey: 'f78821e9-ad62-43db-a50e-a8777f8b346b',
          eventTreeUUIDs: ['954db4ee-8908-4a52-88ac-d4ef81ac8648'],
        },
      ],
      allowedOperations: [],
      expiry: '2022-04-01',
    },
  },
  {
    organisationName: 'Barclays ppc',
    UUID: '1583c03c-73e7-4d51-bd0c-8200635add56',
    transactionId: '848834765816759',
    referenceId: '112451',
    gatewayTraceId: '848834765816759',
    transactionType: 'SALE',
    additionalServices: [],
    amount: { currencyCode: 'EUR', value: 10.12, amountDescription: 'amountDescription' },
    detailedAmount: {
      currencyCode: 'EUR',
      totalAmount: '11.34 EUR',
      feeAmounts: [
        {
          amount: 0,
          currencyCode: 'EUR',
          rateType: 'SELL_RATE',
          type: 'VERIFONE',
          feeConfiguration: [],
        },
      ],
      rebateAmounts: [],
    },
    createdDateTime: '2022-04-01',
    capture: true,
    instrument: [{ instrumentType: 'CRYPTO', cardBrand: 'Visa' }],
    merchant: {
      UUID: '8f825e44-0545-4b4d-9219-4ae739326497',
      id: '333000',
      name: 'BitPayDevBLRStore',
      locale: { countryCode: 'SE', language: 'en-US', timezone: 'GMT+1' },
      sites: [],
      contracts: [
        {
          UUID: '3ad5d408-391f-4d0c-a131-e6a718096bd5',
          contractId: '3ad5d408-391f-4d0c-a131-e6a718096bd5',
          merchantId: '111111',
          paymentType: ['CRYPTO'],
          MCC: '5931',
        },
      ],
      contacts: [],
      businessIdentifiers: [],
      externalEntityIdentifiers: { siteReferenceId: 'BitPayDevBLRStore Reference' },
    },
    POI: {
      id: '333001',
      estateManagementId: '333000333001',
      device: {
        serialNumber: '333-000-001',
        capabilities: [],
        updateCapabilities: [],
        messageCapabilities: [],
        supportedComms: [],
        keyMetadata: [],
      },
      contract: { UUID: '55631f3e-ca66-41bf-8387-0240fefec3b6', poiId: '333001' },
      software: [],
      verificationCapabilities: [],
    },
    context: {
      paymentContext: {
        cardPresent: false,
        cardholderPresent: false,
        salesChannel: 'POS',
        wallet: 'BITPAY',
        authenticationMethod: [],
        authentication: [],
        onlineReason: 'TERMINAL',
        additionalOnlineReason: [],
        accountType: 'DEFAULT',
        channel: 'POS',
        fallbackReason: 'NO_FALLBACK',
        terminalDecision: [],
      },
      salesContext: { salesReferenceNumber: '11' },
    },
    outcome: [
      {
        token: '7RaVKGn6cjd16gC4o1aZTZ5riZJDzuLEFgAq8eQ4zxcXXuNUayNt5dWzQZy3oaHDj9',
        outcomeType: 'CRYPTO_RESULT',
      },
      {
        additionalRefusalReason: [],
        processorTransactionId: 'Cneek86s8fhtS1JCE9c2hQ',
        outcomeType: 'TRAN_RESULT',
        actions: [],
      },
    ],
    paymentSummary: {
      state: 'NOT_AUTHORISED',
      displayStateHumanReadable: 'SALE INITIATED',
      displayTransactionState: 'Settled',
      displayTransactionType: 'Cancel',
      displayPartial: false,
      authState: 'AUTHORISED',
      disputeStatuses: [],
      totalAuthorisedAmount: {
        currencyCode: 'EUR',
        value: 9.12,
        amountDescription: 'amountDescription',
      },
      currentAmount: {
        currencyCode: 'EUR',
        value: '9.12 EUR',
        amountDescription: 'amountDescription',
      },
      amountAvailableForCapture: {
        currencyCode: 'EUR',
        value: 0,
        amountDescription: 'amountDescription',
      },
      captureAmountModifier: 0,
      amountAvailableForRefund: {
        currencyCode: 'EUR',
        value: 0,
        amountDescription: 'amountDescription',
      },
      eventLog: [
        {
          uuid: '1583c03c-73e7-4d51-bd0c-8200635add56',
          stateHumanReadable: 'SALE INITIATED',
          transactionState: 'INITIATED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: {
            currencyCode: 'EUR',
            value: 9.12,
            amountDescription: 'amountDescription',
          },
          datetime: '2022-04-01',
          eventTreeUUIDs: ['1583c03c-73e7-4d51-bd0c-8200635add56'],
        },
      ],
      allowedOperations: [],
      expiry: '2022-04-01',
    },
    metadata: {
      storeHours: [
        { day: 'MONDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'TUESDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'WEDNESDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'THURSDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'FRIDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'SATURDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'SUNDAY', times: { start: '00:00', end: '23:59' } },
      ],
      altVfiEntityId: '333000',
      apmStoreIdentifier: '11',
      apmProviderMerchantId: '111111',
      altVfiPoiId: '333001',
    },
  },
  {
    organisationName: 'MARCV3 AUCHAN',
    UUID: '681fd64e-c1e7-4d66-a6be-e144ab17c83b',
    transactionId: '305358521334697',
    referenceId: '131533670370',
    gatewayTraceId: '305358521334697',
    transactionType: 'SALE',
    additionalServices: [],
    amount: { currencyCode: 'USD', value: 10.12, amountDescription: 'amountDescription' },
    detailedAmount: {
      currencyCode: 'USD',
      totalAmount: '51.34 USD',
      feeAmounts: [
        {
          amount: 0,
          currencyCode: 'USD',
          rateType: 'SELL_RATE',
          type: 'VERIFONE',
          feeConfiguration: [],
        },
      ],
      rebateAmounts: [],
    },
    createdDateTime: '2022-04-01',
    capture: true,
    instrument: [{ instrumentType: 'CRYPTO', cardBrand: 'American Express' }],
    merchant: {
      UUID: '8f825e44-0545-4b4d-9219-4ae739326497',
      id: '333000',
      name: 'BitPayDevBLRStore',
      locale: { countryCode: 'SE', language: 'en-US', timezone: 'GMT+1' },
      sites: [],
      contracts: [
        {
          UUID: '3ad5d408-391f-4d0c-a131-e6a718096bd5',
          contractId: '3ad5d408-391f-4d0c-a131-e6a718096bd5',
          merchantId: '111111',
          paymentType: ['CRYPTO'],
          MCC: '5931',
        },
      ],
      contacts: [],
      businessIdentifiers: [],
      externalEntityIdentifiers: { siteReferenceId: 'BitPayDevBLRStore Reference' },
    },
    POI: {
      id: '333001',
      estateManagementId: '333000333001',
      device: {
        serialNumber: '333-000-001',
        capabilities: [],
        updateCapabilities: [],
        messageCapabilities: [],
        supportedComms: [],
        keyMetadata: [],
      },
      contract: { UUID: '55631f3e-ca66-41bf-8387-0240fefec3b6', poiId: '333001' },
      software: [],
      verificationCapabilities: [],
    },
    context: {
      paymentContext: {
        cardPresent: false,
        cardholderPresent: false,
        salesChannel: 'POS',
        wallet: 'BITPAY',
        authenticationMethod: [],
        authentication: [],
        onlineReason: 'TERMINAL',
        additionalOnlineReason: [],
        accountType: 'DEFAULT',
        channel: 'POS',
        fallbackReason: 'NO_FALLBACK',
        terminalDecision: [],
      },
      salesContext: { salesReferenceNumber: '11' },
    },
    outcome: [
      {
        token: '7RaVKGn6cjd16gC4o1aZTZ8D7ND1kfoNkBfy1BFgU7SWbsCQNniTLym3a4P3gkoNuR',
        outcomeType: 'CRYPTO_RESULT',
      },
      {
        additionalRefusalReason: [],
        processorTransactionId: '9JzU3C7frBy4NxQ6cv57MP',
        outcomeType: 'TRAN_RESULT',
        actions: [],
      },
    ],
    paymentSummary: {
      state: 'NOT_AUTHORISED',
      displayStateHumanReadable: 'SALE INITIATED',
      displayTransactionState: 'Voided',
      displayTransactionType: 'Void',
      displayPartial: false,
      authState: 'AUTHORISED',
      disputeStatuses: [],
      totalAuthorisedAmount: {
        currencyCode: 'USD',
        value: 10.12,
        amountDescription: 'amountDescription',
      },
      currentAmount: {
        currencyCode: 'USD',
        value: '10.12 USD',
        amountDescription: 'amountDescription',
      },
      amountAvailableForCapture: {
        currencyCode: 'USD',
        value: 0,
        amountDescription: 'amountDescription',
      },
      captureAmountModifier: 0,
      amountAvailableForRefund: {
        currencyCode: 'USD',
        value: 0,
        amountDescription: 'amountDescription',
      },
      eventLog: [
        {
          uuid: '681fd64e-c1e7-4d66-a6be-e144ab17c83b',
          stateHumanReadable: 'SALE INITIATED',
          transactionState: 'INITIATED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: {
            currencyCode: 'USD',
            value: 10.12,
            amountDescription: 'amountDescription',
          },
          datetime: '2022-04-01',
          eventTreeUUIDs: ['681fd64e-c1e7-4d66-a6be-e144ab17c83b'],
        },
      ],
      allowedOperations: [],
      expiry: '2022-04-01',
    },
    metadata: {
      storeHours: [
        { day: 'MONDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'TUESDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'WEDNESDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'THURSDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'FRIDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'SATURDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'SUNDAY', times: { start: '00:00', end: '23:59' } },
      ],
      altVfiEntityId: '333000',
      apmStoreIdentifier: '11',
      apmProviderMerchantId: '111111',
      altVfiPoiId: '333001',
    },
  },
  {
    organisationName: 'BitPayDevBLRStore',
    UUID: '54ce9d9e-4622-418c-bf9e-6cae5c5fcb14',
    transactionId: '310993533713099',
    referenceId: '140422000030',
    gatewayTraceId: '310993533713099',
    transactionType: 'SALE',
    additionalServices: [],
    amount: { currencyCode: 'USD', value: 10.12, amountDescription: 'amountDescription' },
    detailedAmount: {
      currencyCode: 'USD',
      totalAmount: '15.14 USD',
      feeAmounts: [
        {
          amount: 0.25,
          currencyCode: 'USD',
          rateType: 'SELL_RATE',
          type: 'VERIFONE',
          feeConfiguration: [
            {
              feeType: 'PERCENTAGE',
              paymentTypes: ['CRYPTO'],
              transactionTypes: ['SALE'],
              value: 1.5,
            },
            {
              feeType: 'FIXED',
              paymentTypes: ['CRYPTO'],
              transactionTypes: ['SALE'],
              value: 0.1,
            },
          ],
        },
        {
          amount: 0.05,
          currencyCode: 'USD',
          rateType: 'BUY_RATE',
          type: 'PAYMENT_PROVIDER',
          feeConfiguration: [
            {
              feeType: 'PERCENTAGE',
              paymentTypes: ['CRYPTO'],
              transactionTypes: ['SALE'],
              value: 0.5,
            },
          ],
        },
        {
          amount: 0.0101,
          currencyCode: 'USD',
          rateType: 'BUY_RATE',
          type: 'BANKING_PARTNER',
          feeConfiguration: [
            {
              feeType: 'PERCENTAGE',
              paymentTypes: ['CRYPTO'],
              transactionTypes: ['SALE'],
              value: 0.1,
            },
          ],
        },
      ],
      rebateAmounts: [],
    },
    createdDateTime: '2022-04-01',
    settlementContext: {
      clearingContext: {
        clearingDelay: 0,
        bankingPartner: 'IBC',
        clearingAccountUid: 'b65d8abd-94ab-4b18-b679-d13fed6beae6',
      },
    },
    capture: true,
    instrument: [{ cardBrand: 'Swish', instrumentType: 'CRYPTO' }],
    merchant: {
      UUID: '8f825e44-0545-4b4d-9219-4ae739326497',
      id: '333000',
      name: 'BitPayDevBLRStore',
      locale: { countryCode: 'SE', language: 'en-US', timezone: 'GMT+1' },
      sites: [],
      contracts: [
        {
          UUID: '3ad5d408-391f-4d0c-a131-e6a718096bd5',
          contractId: '3ad5d408-391f-4d0c-a131-e6a718096bd5',
          merchantId: '111111',
          paymentType: ['CRYPTO'],
          MCC: '5931',
        },
      ],
      contacts: [],
      businessIdentifiers: [],
      externalEntityIdentifiers: { siteReferenceId: 'BitPayDevBLRStore Reference' },
    },
    POI: {
      id: '333001',
      estateManagementId: '333000333001',
      device: {
        serialNumber: '333-000-001',
        capabilities: [],
        updateCapabilities: [],
        messageCapabilities: [],
        supportedComms: [],
        keyMetadata: [],
      },
      contract: { UUID: '55631f3e-ca66-41bf-8387-0240fefec3b6', poiId: '333001' },
      software: [],
      verificationCapabilities: [],
    },
    context: {
      paymentContext: {
        cardPresent: false,
        cardholderPresent: false,
        salesChannel: 'POS',
        wallet: 'BITPAY',
        authenticationMethod: [],
        authentication: [],
        onlineReason: 'TERMINAL',
        additionalOnlineReason: [],
        accountType: 'DEFAULT',
        channel: 'POS',
        fallbackReason: 'NO_FALLBACK',
        terminalDecision: [],
      },
      salesContext: { salesReferenceNumber: '11' },
    },
    outcome: [
      {
        token: '7RaVKGn6cjd16gC4o1aZTZ4gG8joCwaoTDRkkeFW4vEFSY8CHRy7eFvvTHc7pzkcXy',
        outcomeType: 'CRYPTO_RESULT',
      },
      {
        additionalRefusalReason: [],
        processorTransactionId: 'FAdronTnFJzDZBRTVeio37',
        outcomeType: 'TRAN_RESULT',
        actions: [],
      },
    ],
    paymentSummary: {
      state: 'CANCELLED',
      displayStateHumanReadable: 'SALE CANCELLED',
      displayTransactionState: 'Voided',
      displayTransactionType: 'Refund Void',
      displayPartial: false,
      authState: 'AUTHORISED',
      disputeStatuses: [],
      totalAuthorisedAmount: {
        currencyCode: 'USD',
        value: 10.12,
        amountDescription: 'amountDescription',
      },
      currentAmount: {
        currencyCode: 'USD',
        value: '10.12 USD',
        amountDescription: 'amountDescription',
      },
      amountAvailableForCapture: {
        currencyCode: 'USD',
        value: 0,
        amountDescription: 'amountDescription',
      },
      captureAmountModifier: 0,
      amountAvailableForRefund: {
        currencyCode: 'USD',
        value: 0,
        amountDescription: 'amountDescription',
      },
      eventLog: [
        {
          uuid: '54ce9d9e-4622-418c-bf9e-6cae5c5fcb14',
          stateHumanReadable: 'SALE INITIATED',
          transactionState: 'INITIATED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: {
            currencyCode: 'USD',
            value: 10.12,
            amountDescription: 'amountDescription',
          },
          datetime: '2022-04-01',
          eventTreeUUIDs: ['54ce9d9e-4622-418c-bf9e-6cae5c5fcb14'],
        },
        {
          uuid: '54ce9d9e-4622-418c-bf9e-6cae5c5fcb14',
          stateHumanReadable: 'SALE CANCELLED',
          transactionState: 'CANCELLED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: {
            currencyCode: 'USD',
            value: 10.12,
            amountDescription: 'amountDescription',
          },
          datetime: '2022-04-01',
          eventTreeUUIDs: ['54ce9d9e-4622-418c-bf9e-6cae5c5fcb14'],
        },
        {
          uuid: '54ce9d9e-4622-418c-bf9e-6cae5c5fcb14',
          stateHumanReadable: 'SALE CANCELLED',
          transactionState: 'CANCELLED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: {
            currencyCode: 'USD',
            value: 10.12,
            amountDescription: 'amountDescription',
          },
          datetime: '2022-04-01',
          eventTreeUUIDs: ['54ce9d9e-4622-418c-bf9e-6cae5c5fcb14'],
        },
      ],
      allowedOperations: [],
      expiry: '2022-04-01',
    },
    metadata: {
      storeHours: [
        { day: 'MONDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'TUESDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'WEDNESDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'THURSDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'FRIDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'SATURDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'SUNDAY', times: { start: '00:00', end: '23:59' } },
      ],
      apmProviderTransactionId: 'FAdronTnFJzDZBRTVeio37',
      apmBankTransactionId: '',
      amlStatus: 'TIMEOUT',
      amlAction: 'ALLOW',
      altVfiEntityId: '333000',
      apmDatePaid: '2022-04-01',
      apmStoreIdentifier: '11',
      apmProviderMerchantId: '111111',
      altVfiPoiId: '333001',
      apmDateCreated: '',
    },
  },
  {
    organisationName: 'BitPayDevBLRStore',
    UUID: 'ebb5e45b-323d-4241-a881-86d52fcae180',
    transactionId: '010918393398',
    referenceId: '010918393398',
    initiatorTraceId: '393398',
    gatewayTraceId: 'ebb5e45b-323d-4241-a881-86d52fcae180',
    transactionType: 'SALE',
    additionalServices: [],
    amount: { currencyCode: 'AUD', value: 4.2 },
    detailedAmount: {
      currencyCode: 'AUD',
      totalAmount: '4.2 AUD',
      cashbackAmount: 0.0,
      feeAmounts: [],
      rebateAmounts: [],
    },
    createdDateTime: '2022-04-01',
    timezone: 'Australia/Sydney',
    settledDate: '2022-04-02',
    settlementContext: { settledDate: '2022-04-02' },
    capture: true,
    instrument: [
      {
        expiryMonth: 10,
        expiryYear: 2020,
        trackData: [{ trackNumber: 2, trackFormat: 'ISO', trackLength: 37 }],
        ICC: {
          data: '9F1A0205549F2701009A031911208A025A319F360252969F03060000000000009C01005F3401009F3704627AC35F9F260857622F0824A8D4619F090200029F5301529F34033F00009F3501229F02060000000001009F10120110800001220000000000000000000000FF9F3303E0F0C85F2A020554820238009F1E0834303030323036368407A000000004101095050000000000',
        },
        maskedCardNumber: '540221******8992',
        cardBrand: 'Debit',
        instrumentType: 'CARD',
      },
      { instrumentType: 'TOKEN' },
    ],
    customer: {
      phoneNumbers: [],
      authentication: {
        authenticationMethod: 'ONLINE_PIN',
        verificationResult: {
          method: 'ONLINE_PIN',
          verificationEntity: 'ISSUER',
          result: 'SUCC',
        },
      },
    },
    merchant: {
      UUID: '84f29a16-4982-4091-b55e-5d366e90353a',
      id: '87686236',
      name: 'SmokeTest',
      locale: { countryCode: 'AU' },
      sites: [],
      contracts: [
        {
          merchantId: '87686236',
          otherParty: { role: 'ACQUIRER', acquiringInstitution: 579986 },
          paymentType: [],
          MCC: '5999',
        },
      ],
      contacts: [],
      businessIdentifiers: [],
    },
    POI: {
      name: 'Smoke Test V',
      device: {
        serialNumber: '401-687-028',
        capabilities: [],
        updateCapabilities: [],
        messageCapabilities: [],
        supportedComms: [],
        keyMetadata: [],
      },
      contract: { poiId: 'W1SMHAFQ' },
      software: [],
      verificationCapabilities: [],
    },
    context: {
      paymentContext: {
        cardPresent: true,
        cardholderPresent: true,
        salesChannel: 'POS',
        merchantPresent: 'ATTENDED',
        authenticationMethod: [],
        entryMode: 'ICC',
        authentication: ['ONLINE_PIN'],
        onlineReason: 'TERMINAL',
        additionalOnlineReason: [],
        accountType: 'CHEQUE',
        channel: 'POS',
        fallbackReason: 'NO_FALLBACK',
        batchNumber: '20220402',
        terminalDecision: [],
      },
      salesContext: { invoiceNumber: '003201119145239' },
    },
    outcome: [
      {
        authorisationOnline: true,
        authorisationCode: '378521',
        response: 'SUCCESS',
        responseCode: '0000',
        additionalRefusalReason: [],
        outcomeType: 'TRAN_RESULT',
        actions: [],
        authorisingEntity: 'ISSUER',
      },
      {
        acquirerResponseCode: '00',
        processorRRN: '010918393398',
        processorSTAN: '393398',
        outcomeType: 'ACQ_RESULT',
        idCheckResult: 'NOT_APPLICABLE',
        authorisationCodeCheckResult: 'NOT_APPLICABLE',
      },
    ],
    paymentSummary: {
      state: 'AUTHORISED',
      displayStateHumanReadable: 'SALE AUTHORISED',
      displayTransactionState: 'Authorised',
      displayTransactionType: 'PreAuthorisation',
      displayPartial: false,
      authState: 'AUTHORISED',
      disputeStatuses: [],
      totalAuthorisedAmount: { currencyCode: 'AUD', value: 4.2 },
      currentAmount: { currencyCode: 'AUD', value: '4.2 AUD' },
      amountAvailableForCapture: { currencyCode: 'AUD', value: 0 },
      captureAmountModifier: 0,
      amountAvailableForRefund: { currencyCode: 'AUD', value: 4.2 },
      eventLog: [
        {
          uuid: 'ebb5e45b-323d-4241-a881-86d52fcae180',
          stateHumanReadable: 'SALE AUTHORISED',
          transactionState: 'AUTHORISED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: { currencyCode: 'AUD', value: 4.2 },
          datetime: '2022-04-01',
          eventTreeUUIDs: ['ebb5e45b-323d-4241-a881-86d52fcae180'],
        },
      ],
      allowedOperations: [],
      expiry: '2022-04-01',
    },
    metadata: {
      'TXN_ADDITIONAL#INTERCHANGE_STAN': '393398',
      'TXN_ADDITIONAL#SETTLEMENT_DATE': '20220402000000000',
      'TXN_ADDITIONAL#RECEIVING_NODE': '1',
      'TXN_ADDITIONAL#INTERCHANGE_LINK': 'Westpac_LI1',
      ACQUIRER_NAME: 'Westpac',
      'TXN_ADDITIONAL#INTERCHANGE_TRANSMISSION_DATETIME': '2022-04-01',
    },
  },
  {
    organisationName: 'BitPayDevBLRStore',
    UUID: '0ea8edfe-982b-4a5a-9e4f-249883d99b12',
    transactionId: '1873791219993744',
    referenceId: '621523662',
    gatewayTraceId: '1873791219993744',
    transactionType: 'SALE',
    additionalServices: [],
    amount: { currencyCode: 'JPY', value: 10.0 },
    detailedAmount: {
      currencyCode: 'JPY',
      totalAmount: '64.2 JPY',
      cashbackAmount: 0.0,
      feeAmounts: [],
      rebateAmounts: [],
    },
    createdDateTime: '2022-04-01',
    capture: true,
    instrument: [{ cardBrand: 'Mastercard', instrumentType: 'CRYPTO' }],
    merchant: {
      UUID: '8f825e44-0545-4b4d-9219-4ae739326497',
      id: '333000',
      name: 'BitPayDevBLRStore',
      locale: { countryCode: 'US', language: 'en-US' },
      sites: [],
      contracts: [
        {
          UUID: '3ad5d408-391f-4d0c-a131-e6a718096bd5',
          contractId: '3ad5d408-391f-4d0c-a131-e6a718096bd5',
          merchantId: '111111',
          paymentType: ['CRYPTO'],
          MCC: '5931',
        },
      ],
      contacts: [],
      businessIdentifiers: [],
      externalEntityIdentifiers: { siteReferenceId: 'BitPayDevBLRStore Reference' },
    },
    POI: {
      id: '333001',
      estateManagementId: '333000333001',
      device: {
        serialNumber: '333-000-001',
        capabilities: [],
        updateCapabilities: [],
        messageCapabilities: [],
        supportedComms: [],
        keyMetadata: [],
      },
      contract: { UUID: '55631f3e-ca66-41bf-8387-0240fefec3b6', poiId: '333001' },
      software: [],
      verificationCapabilities: [],
    },
    context: {
      paymentContext: {
        cardPresent: false,
        cardholderPresent: false,
        salesChannel: 'POS',
        wallet: 'BITPAY',
        authenticationMethod: [],
        authentication: [],
        onlineReason: 'TERMINAL',
        additionalOnlineReason: [],
        accountType: 'DEFAULT',
        channel: 'POS',
        fallbackReason: 'NO_FALLBACK',
        terminalDecision: [],
      },
      salesContext: {},
    },
    outcome: [
      {
        token: '7RaVKGn6cjd16gC4o1aZTZB82me2skepDhg1pEP6CKhThRZMMyDXxNTediaz5mxfH9',
        outcomeType: 'CRYPTO_RESULT',
      },
      {
        additionalRefusalReason: [],
        processorTransactionId: 'L965cFEqx7GpH1W4cgS5yB',
        outcomeType: 'TRAN_RESULT',
        actions: [],
      },
    ],
    paymentSummary: {
      state: 'NOT_AUTHORISED',
      displayStateHumanReadable: 'SALE INITIATED',
      displayTransactionState: 'Initiated',
      displayTransactionType: 'Sale',
      displayPartial: false,
      authState: 'AUTHORISED',
      disputeStatuses: [],
      totalAuthorisedAmount: { currencyCode: 'JPY', value: 10.0 },
      currentAmount: { currencyCode: 'JPY', value: '10.0 JPY' },
      amountAvailableForCapture: { currencyCode: 'JPY', value: 0 },
      captureAmountModifier: 0,
      amountAvailableForRefund: { currencyCode: 'JPY', value: 0 },
      eventLog: [
        {
          uuid: '0ea8edfe-982b-4a5a-9e4f-249883d99b12',
          stateHumanReadable: 'SALE INITIATED',
          transactionState: 'INITIATED',
          transactionType: 'SALE',
          transactionPartial: false,
          amount: { currencyCode: 'JPY', value: 10.0 },
          datetime: '2022-04-01',
          eventTreeUUIDs: ['0ea8edfe-982b-4a5a-9e4f-249883d99b12'],
        },
      ],
      allowedOperations: [],
      expiry: '2022-04-01',
    },
    metadata: {
      storeHours: [
        { day: 'MONDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'TUESDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'WEDNESDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'THURSDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'FRIDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'SATURDAY', times: { start: '00:00', end: '23:59' } },
        { day: 'SUNDAY', times: { start: '00:00', end: '23:59' } },
      ],
      altVfiEntityId: '333000',
      apmProviderMerchantId: '111111',
      altVfiPoiId: '333001',
    },
  },
];
