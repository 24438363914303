import environment from '@environments';
import { UserRoles } from '@portal/shared/auth/authorization/src';
import { FiltersList, FilterTypes } from '@portal/shared/ui/filter';
import { ITableConfig } from '@portal/shared/ui/table';
import { GET_FILTER_CONFIG, GET_TABLE_CONFIG } from './shared-configs';

export const APPLICATION_CRASH_TABLE_CONFIG = (role): ITableConfig => {
  return TABLE_CONFIG;
};

export const APPLICATION_CRASH_FILTER_CONFIG = (role): FiltersList[] => {
  return FILTER_CONFIG;
};

const TABLE_CONFIG: ITableConfig = {
  columns: [
    { key: 'app_name', label: 'App Name', isSortable: true },
    { key: 'app_package_name', label: 'App Package Name', isSortable: true },
    { key: 'device_serial_number', label: 'Terminal Serial Number', isSortable: true },
    { key: 'event_date', label: 'Event Date', isSortable: true },
    { key: 'log_created_date', label: 'Log Created Date', isSortable: true },
    { key: 'detailed_data', label: 'Detailed Data', isSortable: true },
    { key: 'severity', label: 'Severity', isSortable: true },
    { key: 'log_uid', label: 'Log uid', isSortable: true },
  ],
  rowIdKey: 'entityUid',
  rowLabelKey: 'name',
};

const FILTER_CONFIG: FiltersList[] = [
  {
    selector: 'time_created',
    title: 'Deploy Value',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'App Name',
        id: 'app_name',
      },
      {
        text: 'App Package Name',
        id: 'app_package_name',
      },
      {
        text: 'Terminal Serial Number',
        id: 'device_serial_number',
      },
    ],
  },
  {
    selector: 'event_date',
    title: 'Event Date',
    type: FilterTypes.DatePicker,
    inLocalTime: true,
  },
  {
    selector: 'log_created_date',
    title: 'Log Created Date',
    type: FilterTypes.DatePicker,
    inLocalTime: true,
  },
];

export const APPLICATION_CRASH_CONFIG = {
  report_id: environment.REPORTS['application-crash'],
  table: APPLICATION_CRASH_TABLE_CONFIG,
  filters: APPLICATION_CRASH_FILTER_CONFIG,
};
