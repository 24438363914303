import { IGuardPermissions, UserRoles } from '@portal/shared/auth/authorization';

type CheckoutThemesPermissionScope = 'list' | 'details' | 'create' | 'edit' | 'customize';
export const CheckoutThemesPermissions: Record<CheckoutThemesPermissionScope, IGuardPermissions> = {
  list: {
    read: [UserRoles.MerchantSupervisor, UserRoles.VerifoneAdmin, UserRoles.MerchantCashier],
  },
  details: {
    read: [UserRoles.MerchantSupervisor, UserRoles.VerifoneAdmin, UserRoles.MerchantCashier],
    delete: [UserRoles.MerchantSupervisor, UserRoles.MerchantCashier],
  },
  create: {
    read: [UserRoles.MerchantSupervisor, UserRoles.MerchantCashier],
  },
  edit: {
    read: [UserRoles.MerchantSupervisor, UserRoles.MerchantCashier],
  },
  customize: {
    read: [UserRoles.MerchantSupervisor, UserRoles.MerchantCashier],
  },
};
