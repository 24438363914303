import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IApp } from '@portal/marketplace/api-interfaces/src';
import { PublicAppCatalogState, PublicAppCatalogStore } from './public-app-catalog.store';

@Injectable({
  providedIn: 'root',
})
export class PublicAppCatalogQuery extends QueryEntity<PublicAppCatalogState, IApp> {
  loading$ = this.selectLoading();
  constructor(protected store: PublicAppCatalogStore) {
    super(store);
  }
}
