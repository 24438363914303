import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ITableConfig } from '../interfaces/table-config.interface';
import { ISelection } from '../interfaces/selection';
import { Observable } from 'rxjs';
import { PageSize } from '../enums/page-size.enum';
import { IGroup } from '../interfaces/group';
import { IGroupableItem } from '../interfaces/groupableItem.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'grouped-table',
  templateUrl: './grouped-table.component.html',
  styleUrls: ['./grouped-table.component.scss'],
})
export class GroupedTableComponent implements OnInit, OnChanges {
  @Input()
  set items(val: IGroupableItem[]) {
    if (!val) {
      return;
    }
    this._items = val;
    this.groupItems();
  }

  get items(): IGroupableItem[] {
    return this._items;
  }

  @Output() selectedChange = new EventEmitter<any>();
  @Input() tableConfig: ITableConfig;
  @Input() defaultSelection: ISelection[] = [];
  @Input() groupBy: string;
  @Input() groupSumLabel: string;
  @Input() loading$: Observable<boolean>;

  groupDefaultSelection: ISelection[] = [];
  groups: IGroup[];
  outerTableConfig: ITableConfig;

  page = 1;
  size = 0;
  limit = PageSize.Size10;
  allSelectedItems = [];
  selectedItemsByPage = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _items: IGroupableItem[];

  constructor() {
    this.outerTableConfig = {
      columns: [
        { key: 'name', label: this.groupBy, innerCssClass: 'has-text-truncate' },
        {
          key: 'sum',
          label: this.groupSumLabel,
          cssClass: 'table-row-roles',
          innerCssClass: 'has-text-truncate',
        },
      ],
      rowIdKey: 'name',
      rowLabelKey: 'label',
    };
  }

  ngOnInit(): void {
    this.outerTableConfig.columns[0].label = this.groupBy;
    this.outerTableConfig.columns[1].label = this.groupSumLabel;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['defaultSelection'] && changes['defaultSelection'].currentValue.length > 0) {
      this.groupItems();
    }
  }

  groupSelectionChanged(groups): void {}

  groupItems(): void {
    if (this.items === null || this.items === undefined) return;

    const obj = {};

    for (let i = 0; i < this.items.length; i++) {
      const groups = this.items[i].groups;
      if (groups == null || !groups.length) continue;

      groups.forEach((group) => {
        if (!obj.hasOwnProperty(group)) {
          obj[group] = { sum: 0, items: [] };
        }
        obj[group].sum++;
        obj[group]['items'].push(this.items[i].item);
      });
    }

    this.groups = Object.keys(obj)
      .map((k) => {
        const group: IGroup = {
          name: k,
          sum: obj[k]['sum'],
          items: obj[k]['items'],
          defaultSelection: [
            ...this.defaultSelection.map((x) => Object.assign({}, x)),
            ...this.allSelectedItems,
          ],
        };
        return group;
      })
      .slice((this.page - 1) * this.limit, (this.page - 1) * this.limit + this.limit);

    this.size = Object.keys(obj).length;
  }

  getSize(): number {
    return Math.ceil(this.size / this.limit);
  }

  onPagination(event): void {
    this.limit = event.size;
    this.page = event.page;
    this.groupItems();
  }

  selectionChanged(items: any[], item: IGroup): void {
    let selectedItems: any[] = [];
    this.selectedItemsByPage[this.page - 1] = [];

    this.groups.map((group) => {
      if (group.name === item.name) {
        group.defaultSelection.map((df) => {
          const selected = items.some((x) => x.id === df.id);
          df.checked = selected;
        });
        group.defaultSelection = group.defaultSelection.concat(
          items.filter((x) => !group.defaultSelection.some((y) => y.id === x.id)),
        );
        selectedItems = selectedItems.concat(items);
      } else {
        selectedItems = selectedItems.concat(group.defaultSelection.filter((x) => x.checked));
      }
    });

    const selectedItemsByPageMap = new Map(selectedItems.map((item) => [item.id, item]));
    selectedItemsByPageMap.forEach((item) => this.selectedItemsByPage[this.page - 1].push(item));

    const selectedItemsMap = new Map();
    this.selectedItemsByPage.forEach((pageSelectedItems) => {
      pageSelectedItems.forEach((item) => {
        selectedItemsMap.set(item.id, item);
      });
    });

    this.allSelectedItems = [];
    selectedItemsMap.forEach((item) => this.allSelectedItems.push(item));

    this.selectedChange.emit(this.allSelectedItems);
  }
}
