import { RouterModule } from '@angular/router';
import { IPortalRoute } from '@portal/shared/core';
import { PublicAppPageComponent } from './public-app-page/public-app-page.component';
import { PublicMainComponent } from './public-main/public-main.component';
import { PublicComponent } from './public.component';

const routes: IPortalRoute[] = [
  {
    path: 'welcome',
    component: PublicMainComponent,
    data: {
      breadcrumbs: {
        label: 'Apps Preview',
        path: '',
      },
    },
    // children: [
    //   {
    //     path: '',
    //     component: PublicMainComponent,
    //     // loadChildren: () => import('./bundles/bundles.module').then(m => m.BundlesPageModule),
    //   },
    // ],
  },
  {
    path: 'app/:id',
    component: PublicAppPageComponent,
    data: {
      breadcrumbs: {
        label: 'Application catalog',
        path: '/welcome',
      },
    },
  },
];

export const PublicRoutes = RouterModule.forChild(routes);
