import { Injectable } from '@angular/core';
import { IUser, UserRole } from '@portal/entity-services/interfaces/src';

import { Application, AuthorizationService } from '@portal/shared/auth/authorization/src';
import { INavbarItem } from '@portal/shared/ui/layouts/src/lib/top-navbar/interfaces/navbar-item.interface';
import {
  getFullUrlForApp,
  getNavbarItemLink,
  getSubNavItems,
  NavbarItemLink,
} from '../../helpers/navigation-route-helpers';
import { ApplicationRoot } from '../../enums/application-root.enum';
import { MenuRegion } from '../../../../../ui/layouts/src/lib/top-navbar/enum/menu-region.enum';
import { APPS_SUPPORTED_LANGUAGES } from '../../../../../../../configs/localization/supported-languages';

@Injectable({
  providedIn: 'root',
})
export class BoardingNavbarService {
  languageRoute: string;
  currentApplication: ApplicationRoot;
  constructor(private authorizationService: AuthorizationService) {}

  getBoardingNavbarItems(params: {
    currentApplication: ApplicationRoot;
    languageRoute: string;
    user: IUser;
  }): INavbarItem | null {
    this.languageRoute = params.languageRoute;
    this.currentApplication = params.currentApplication;
    const ordersItems = this.getOrdersNavbarItems();
    const repairItems = this.getRepairNavbarItems();
    const reportsItems = this.getReportsNavbarItem();

    return getSubNavItems(
      {
        id: 'boarding',
        title: $localize`:Main menu navbar group name:Boarding`,
        attributes: {
          'data-e2e': 'title-boarding',
          groupTitle: $localize`:Main menu navbar group name:Boarding`,
          description: $localize`:Main menu navbar group description:Verifone’s Boarding Services’, enables ordering & configuring omnichannel payment solutions loaded with software and services you need from a single location.`,
        },
        region: MenuRegion.Boarding,
      },
      [ordersItems, repairItems, reportsItems],
    );
  }

  getOrdersNavbarItems(): INavbarItem | null {
    const ordersNavbarItem = {
      title: $localize`:Main menu navbar item name:Orders`,
      attributes: {
        'data-e2e': 'title-orders',
      },
      children: [],
    };

    if (this.hasMOPExclusiveRole() || this.hasMopAccess()) {
      ordersNavbarItem.children.push({
        title: $localize`:Main menu navbar item name:All Orders`,
        attributes: {
          'data-e2e': 'link-orders',
        },
        ...this.getMopItemUrl('/order/dashboard', [ApplicationRoot.MOP]),
      });
    }
    return ordersNavbarItem;
  }

  getRepairNavbarItems(): INavbarItem | null {
    const repairsNavbarItem = {
      title: $localize`:Main menu navbar item name:Repairs`,
      attributes: {
        'data-e2e': 'title-repairs',
      },
      children: [],
    };
    return repairsNavbarItem;
  }

  getReportsNavbarItem(): INavbarItem | null {
    const reportsNavbarItem = {
      title: $localize`:Main menu navbar item name:Reports`,
      attributes: {
        'data-e2e': 'title-reports',
      },
      children: [],
    };
    return reportsNavbarItem;
  }

  private getMopItemUrl(url: string, applications: ApplicationRoot[]): NavbarItemLink {
    if (applications[0] === ApplicationRoot.MOP) {
      const mopSupportedLanguages = APPS_SUPPORTED_LANGUAGES[Application.MOP];
      let mopLocale = null;
      if (this.languageRoute) {
        mopLocale = mopSupportedLanguages.find((value) => {
          return value.includes(this.languageRoute);
        });
      }
      const mopLanguage = mopLocale ? mopLocale.toLowerCase() : this.languageRoute;
      return {
        href: getFullUrlForApp(applications[0], mopLanguage, url),
        path: url,
      };
    } else {
      return getNavbarItemLink(this.currentApplication, applications, this.languageRoute, url);
    }
  }

  private hasMopAccess(): boolean {
    const mopAccessRoles = this.authorizationService
      .getRoles()
      .filter((role) =>
        [UserRole.VerifoneSalesManager, UserRole.VerifoneSalesRepresentative].includes(
          role as UserRole,
        ),
      );
    return this.authorizationService.isApplicationUser(Application.MOP, mopAccessRoles);
  }

  private hasMOPExclusiveRole(): boolean {
    const exclusiveMOPRoles = this.authorizationService
      .getRoles()
      .filter((role) =>
        [
          UserRole.ResellerManager,
          UserRole.ResellerRepresentative,
          UserRole.MerchantUnapproved,
          UserRole.MerchantOrderAdmin,
        ].includes(role as UserRole),
      );
    return this.authorizationService.isApplicationUser(Application.MOP, exclusiveMOPRoles);
  }
}
