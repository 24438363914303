import { RoleEditPermissionMatrix } from '@portal/entity-services/interfaces/src/lib/users/enums/user-role-types';
import { UserRole } from '@portal/entity-services/interfaces/src/lib/users/enums/user-role.enum';

export const rolePermissionMatrix: RoleEditPermissionMatrix = {
  [UserRole.MerchantAdmin]: [UserRole.MerchantAdmin, UserRole.MerchantUser],
  [UserRole.VerifoneAdmin]: [
    UserRole.EstateOwnerAdmin,
    UserRole.EstateOwnerDeviceAppManager,
    UserRole.MerchantAdmin,
    UserRole.MerchantUser,
    UserRole.DeveloperAdmin,
    UserRole.DeveloperUser,
  ],
  [UserRole.EstateOwnerAdmin]: [
    UserRole.EstateOwnerAdmin,
    UserRole.EstateOwnerDeviceAppManager,
    UserRole.MerchantAdmin,
    UserRole.MerchantUser,
  ],
  [UserRole.DeveloperAdmin]: [UserRole.DeveloperAdmin, UserRole.DeveloperUser],
  [UserRole.VerifoneSalesManager]: [UserRole.VerifoneSalesRepresentative],
  [UserRole.ResellerManager]: [UserRole.ResellerRepresentative],
};
