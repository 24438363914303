<div [ngClass]="{ 'is-active': activeState, modal: true }" cdkTrapFocus="{{ activeState }}">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="card">
      <div class="card-content">
        <p data-e2e="modal-title" class="title is-4">
          <ng-content select="[title]"></ng-content>
        </p>
        <p data-e2e="modal-description">
          <ng-content select="[description]"></ng-content>
        </p>
        <div class="control">
          <ng-content select="[modal-content]"></ng-content>
        </div>
        <br />
        <div>
          <ng-content select="[buttons]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
