<ng-container *ngIf="src">
  <ng-container *ngFor="let notification of src.notifications">
    <div class="notification is-toast toast-animation" data-e2e="notifier-toast">
      {{ notification.message }}
      <button
        class="delete is-medium"
        (click)="src.destroy(notification)"
        data-e2e="notifier-close"
        type="button"
        title="Close"
        i18n-title
      ></button>
    </div>
  </ng-container>
</ng-container>
