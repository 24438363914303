/* eslint-disable @typescript-eslint/naming-convention */
import environment from 'environments/environment';

const CONSTANTS = {
  DASHBOARD_ROUTE_PREFIX: 'dashboard',
  ADMINISTRATION_ROUTE_PREFIX: 'administration',
  REPORTING_ROUTE_PREFIX: 'reports',
  PAYMENT_TOOLS_ROUTE_PREFIX: 'payment-tools',
  VALIDATION_SERVICE: {
    ENTITIES: `${environment.API_ENDPOINT.VALIDATION_SERVICE}/entities`,
    RULESETS: 'rulesets',
    DESCENDANTS: 'descendants',
  },
  DATA_SERVICE: {
    GET_TRANSACTIONS_COUNT: `${environment.API_ENDPOINT.PDSP}/transactions/count`,
    GET_TRANSACTIONS: `${environment.API_ENDPOINT.PDSP}/transactions/query`,
    TRANSACTIONS: `${environment.API_ENDPOINT.PDSP}/transactions`,
    EXPORT_TRANSACTIONS_TO_CSV: `${environment.API_ENDPOINT.PDSP}/transactions/csvExport`,
    GET_AUTHENTICATIONS: `${environment.API_ENDPOINT.THREE_D_SECURE}/3d`,
    GET_SETTLEMENTS: `${environment.API_ENDPOINT.PDSP}/batches`,
    PERMISSIONS_LITE_SERVICE: `${environment.API_ENDPOINT.VF_PETRO}/v1/permissions/lite`,
    GET_PROCESSOR_SETTLEMENTS: `${environment.API_ENDPOINT.PDSP}/processorSettlementBatches`,
    GET_PROCESSOR_SETTLEMENTS_SUBBATCHES: `${environment.API_ENDPOINT.PDSP}/subBatches`,
  },
  REPORT_ENGINE: {
    ROOT: environment.API_ENDPOINT.REPORT_ENGINE,
    REPORTS: `${environment.API_ENDPOINT.REPORT_ENGINE}/reports`,
    REPORTS_POST: `${environment.API_ENDPOINT.REPORT_ENGINE}/reports/getReports`,
    ROOT_SCHEDULER: environment.API_ENDPOINT.REPORT_SCHEDULER,
    REPORTS_SCHEDULER: `${environment.API_ENDPOINT.REPORT_SCHEDULER}/reports/report/schedule`,
    EXPORT_TRANSACTION_REPORTS_TO_CSV: `${environment.API_ENDPOINT.REPORT_ENGINE}/reports/transactions`,
    SFTP_CONNECTIONS: `${environment.API_ENDPOINT.REPORT_ENGINE}/sftpConnections`,
    TRANSACTION_REPORT_TEMPLATES: `${environment.API_ENDPOINT.REPORT_ENGINE}/reportTemplates/transactions`,
  },
  FIN_OPS: {
    MERCHANT_COUNTRY_CONFIG: `${environment.API_ENDPOINT.FIN_OPS}/finOpsConfigurations`,
    MERCHANT_CONFIG: `${environment.API_ENDPOINT.FIN_OPS}/merchants`,
    MERCHANT_SETTLEMENT: `${environment.API_ENDPOINT.FIN_OPS}/payouts`,
    MERCHANT_FUNDINGS: `${environment.API_ENDPOINT.FIN_OPS}/fundings`,
    ACCOUNT_BALANCES: `${environment.API_ENDPOINT.FIN_OPS}/accountbalances`,
    MERCHANT_BALANCES: `${environment.API_ENDPOINT.FIN_OPS}/merchantbalances`,
    MERCHANT_SETTLEMENT_V2: `${environment.API_ENDPOINT.FIN_OPS}/v2/payouts`,
  },
  REPORTS: {
    INVOICE: `${environment.API_ENDPOINT.INVOICE}/invoices`,
    MERCHANT_BALANCE: `${environment.API_ENDPOINT.INVOICE}/merchant-balances`,
    AUDIT_LOG: `${environment.API_ENDPOINT.EVENTS_SERVICE}/events`,
  },
  DISPUTE: {
    CHARGEBACKS: `${environment.API_ENDPOINT.CHARGEBACKS}/chargebacks`,
    REASON_CODES: `${environment.API_ENDPOINT.CHARGEBACKS}/chargebacks/reasoncodes`,
  },
};

export { CONSTANTS };
