import environment from '@environments';
import { FiltersList, FilterTypes } from '@portal/shared/ui/filter';
import { ITableConfig } from '@portal/shared/ui/table';
import { GET_FILTER_CONFIG_APP_USAGE, GET_TABLE_CONFIG_APP_USAGE } from './shared-configs';
import { NgIf } from '@angular/common';

export const APP_USAGE_TABLE_CONFIG = (role): ITableConfig => {
  return GET_TABLE_CONFIG_APP_USAGE(role, TABLE_CONFIG);
};

export const APP_USAGE_FILTER_CONFIG = (role): FiltersList[] => {
  return GET_FILTER_CONFIG_APP_USAGE(role, FILTER_CONFIG);
};

const TABLE_CONFIG: ITableConfig = {
  columns: [
    { key: 'app_name', label: 'App Name', isSortable: true },
    // { key: 'device_serial_number', label: 'Terminal Serial No.', isSortable: true },
    { key: 'usage_time_min', label: 'Usage Time (min.)', isSortable: true },
    { key: 'number_of_days_used', label: 'Number Of Days Used', isSortable: true },
    {
      key: 'average_usage_time_per_day_min',
      label: 'Daily Average Usage  (min.)',
      isSortable: true,
    },
    { key: 'tid', label: 'TID', isSortable: true },
    // { key: 'mid', label: 'MID', isSortable: true },
  ],
  rowIdKey: 'entityUid',
  rowLabelKey: 'name',
};

const FILTER_CONFIG: FiltersList[] = [
  {
    selector: 'search',
    title: 'Deploy Value',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'Application Name',
        id: 'app_name',
      },
      {
        text: 'MID',
        id: 'mid',
      },
      {
        text: 'TID',
        id: 'tid',
      },
    ],
  },
  {
    selector: 'event_date',
    title: 'Date Range',
    type: FilterTypes.DatePicker,
    inLocalTime: true,
  },
];

export const ReportFilterListConfig = (templates, options, service$, role): FiltersList[] => {
  const merchantFilter = {
    selector: 'merchant_entity_uid',
    title: 'Merchant Name',
    type: FilterTypes.TreeFilter,
    placeholder: 'Search',
    service$,
    templates,
    options,
  };
  let filterConfig = FILTER_CONFIG;
  filterConfig = [merchantFilter, ...FILTER_CONFIG];
  return filterConfig;
};

export const APP_USAGE_CONFIG = {
  report_id: environment.REPORTS['app-usage'],
  table: APP_USAGE_TABLE_CONFIG,
  filters: ReportFilterListConfig,
};
