<div class="dropdown" [class.is-active]="isOpen">
  <div class="dropdown-trigger">
    <button
      [attr.aria-expanded]="isOpen"
      class="button is-chip is-size-7-mobile"
      [class.is-applied]="getValues().length"
      [class.is-active]="isOpen"
      data-e2e="button-country-filter"
      (click)="itemToggled.emit()"
      type="button"
      title="{{ paymentTypeText }}"
    >
      <span>{{ paymentTypeText }}</span>
      <span class="icon">
        <i class="fas fa-chevron-down"></i>
      </span>
    </button>
  </div>

  <div class="dropdown-menu">
    <div class="dropdown-content">
      <div class="dropdown-item is-scrollable">
        <ng-container *ngFor="let paymentType of paymentTypesList">
          <portal-search-filter-radio
            [id]="paymentType.id"
            [text]="paymentType.text"
            radioName="paymentTypeSearch"
            [selectedEntities]="localPreSelectedEntities"
            [refresh$]="refreshRadioList$"
            (selected)="onEntityAdded($event)"
            data-e2e="radio-search-box"
          >
          </portal-search-filter-radio>
        </ng-container>
      </div>

      <div class="dropdown-item">
        <button
          class="button is-primary"
          data-e2e="button-filter"
          i18n
          (click)="onFilterApplied()"
          type="button"
          title="Apply"
          i18n-title
        >
          Apply
        </button>
        <button
          class="button is-transparent button-clear-filter"
          data-e2e="button-clear-filter"
          i18n
          (click)="removeAllEntities()"
          type="button"
          title="Clear"
          i18n-title
        >
          Clear
        </button>
      </div>
    </div>
  </div>
</div>
