<div class="file-browse is-fullheight">
  <ng-container *ngIf="disabled; then whenDisabled; else whenEnabled"></ng-container>

  <ng-template #whenDisabled>
    <label
      (fileDropped)="fileDropped($event)"
      [ngClass]="{ 'has-background-white-bis': disabled }"
      class="has-text-grey file-browse-container has-border-grey has-text-centered"
    >
      <div>
        <img [src]="uploadImage" alt="upload" />
        <div class="is-size-7">
          Drag & drop files here <br />
          or
        </div>
        <div class="browse" data-e2e="file-upload-browse-btn">Browse</div>
        <p
          *ngIf="supportedFilesText; else generalText"
          class="has-text-gray is-size-7 has-text-centered"
          i18n
        >
          {{ supportedFilesText }}
        </p>
        <ng-template #generalText>
          <p class="has-text-gray is-size-7 has-text-centered" i18n>Supported formats PDF</p>
        </ng-template>

        <div class="mt-2">
          <ng-content></ng-content>
        </div>
      </div>
    </label>
  </ng-template>


  <ng-template #whenEnabled>
      <label
      marketplaceDragDrop
      (fileDropped)="fileDropped($event)"
      [aspect]="aspect"
      [ngClass]="{ disabled: disabled }"
      class="has-text-grey file-browse-container has-text-centered"
    >
      <div>
        <img [src]="uploadImage" alt="upload" />
        <div class="is-size-7" i18n>Drag & drop files here</div>
        <div class="is-size-7" i18n>or</div>
        <div class="browse" data-e2e="file-upload-browse-btn" (click)="browse()" i18n>Browse</div>
        <p
          *ngIf="supportedFilesText; else generalText"
          class="has-text-gray is-size-7 has-text-centered"
          i18n
        >
          {{ supportedFilesText }}
        </p>
        <ng-template #generalText>
          <p class="has-text-gray is-size-7 has-text-centered" i18n>Supported formats PDF</p>
        </ng-template>

        <div class="mt-2">
          <ng-content></ng-content>
        </div>
      </div>

      <input
        type="file"
        style="background-color: white; border-color: lightgray;"
        id="file-input"
        #fileInput
        [disabled]="disabled"
        [accept]="accept"
        name="file"
        (change)="fileProgress($event.target.files)"
      />
    </label>

    <ng-container *ngIf="files.length > 0 && !existingFileName">
      <div class="is-ellipsis" *ngFor="let file of files">{{ file.name }}</div>
    </ng-container>
  </ng-template>
</div>
