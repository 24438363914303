import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'portal-zero-state',
  templateUrl: './zero-state.component.html',
  styleUrls: ['./zero-state.component.scss'],
})
export class ZeroStateComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    const snapshotData = this.activatedRoute.snapshot?.data;
    if (snapshotData?.title) {
      this.title = snapshotData.title;
    }
    if (snapshotData?.description) {
      this.description = snapshotData.description;
    }
  }
}
