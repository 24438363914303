import { Observable } from 'rxjs';

/**
 * @description
 * Marks the error object in such a way that it is ignored by the global error handler.
 *
 * ## DEPRECATION NOTICE
 *
 * *Do not use this in new code.*
 *
 * Skipping the global error handler does not make much sense.
 * Unhandled errors can potentially cause unpredictable behavior.
 * So a common practice is to handle all the errors,
 * and reacting to them by giving a tangible feedback to the user,
 * or by simply logging them.
 * And this is the purpose of the global error handler.
 * If some error doesn't need or can't be treated in a generic way,
 * then it might as well be caught and suppressed
 * in the module which is aware of such an error.
 *
 * If you don't want you error to be handled in the global error handler,
 * then you must handle it somewhere yourself. Here you have a few options:
 * 1. You can catch the error using `catchError`, so that you can suppress it,
 *  and then you can either continue the observable by returning a meaningful value,
 *  or you can complete the observable by returning `EMPTY`, depending on your needs;
 * 2. If you wish your error to reach some higher level consumer of your observable,
 *  then you can rethrow it from `catchError` with `return throwError(error)`;
 * 3. You can also handle errors by subscribing to the observable with `.subscribe()`
 *  and providing the "error" callback;
 *
 * If you don't handle the error yourself, but instead you want that error to reach some
 * consumer of your observable, then it is the responsibility of that consumer
 * of your observable to handle the error and to suppress it if necessary,
 * and of course this is where you can handle your error in such a case.
 *
 * In other words, either handle the error yourself as you need, or let the global handler do it.
 *
 * @deprecated
 * Don't use this operator in new code. Use `catchError` to handle the error instead.
 */
export function skipGlobalErrorHandler(
  selector?: (error: any) => boolean,
  thisArg = null,
): (source) => Observable<any> {
  return function <T>(source: Observable<T>): Observable<T> {
    return new Observable((subscriber) => {
      source.subscribe({
        next(value): void {
          subscriber.next(value);
        },
        error(error): void {
          const shouldBeSkipped = selector ? selector.call(thisArg, error) : true;
          if (shouldBeSkipped) {
            error.shouldBeSkipped = true;
          }
          subscriber.error(error);
        },
        complete(): void {
          subscriber.complete();
        },
      });
    });
  };
}
