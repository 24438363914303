import { RouterModule } from '@angular/router';
import { IPortalRoute } from '@portal/shared/core';

const TagsManagementRoutes: IPortalRoute[] = [
  {
    path: 'devices',
    loadChildren: () => import('./tags-devices/tags-page.module').then((m) => m.TagsPageModule),
    data: {
      breadcrumbs: {
        label: 'Terminals',
      },
    },
  },
  {
    path: 'devices/filter',
    loadChildren: () =>
      import('./tags-device-filter/tags-device-filter.module').then((m) => m.TagsDeviceFilerModule),
    data: {},
  },
  {
    path: 'devices/:tagId',
    loadChildren: () =>
      import('./tags-devices-assign/tags-devices-assign.module').then(
        (m) => m.TagsDevicesAssignModule,
      ),
    data: {
      breadcrumbs: {
        label: 'Terminals',
        path: 'devices',
      },
    },
  },
  {
    path: 'bundles',
    loadChildren: () =>
      import('./tags-bundles/tags-bundles.module').then((m) => m.TagsBundlesModule),
    data: {},
  },
  {
    path: 'bundles/filter',
    loadChildren: () =>
      import('./tags-bundle-filter/tags-bundle-filter.module').then((m) => m.TagsBundleFilerModule),
    data: {},
  },

  {
    path: 'apps',
    loadChildren: () =>
      import('./tags-apps/tags-apps.module').then((m) => m.TagsAppsModule),
    data: {},
  },
];

export const MarketplaceTagsManagementRoutes = RouterModule.forChild(TagsManagementRoutes);
