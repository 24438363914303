<section class="block mp-block">
  <div class="block mp-block">
    <!-- Main container -->
    <div class="container">
      <portal-breadcrumbs> </portal-breadcrumbs>
      <div class="is-flex is-justify-content-space-between is-align-items-flex-start">
        <h1 class="title is-hidden-mobile" data-e2e="page-title">
          <ng-content select="[title]"></ng-content>
        </h1>

        <div class="is-flex is-align-items-flex-start">
          <ng-content select="[Actions]"></ng-content>

          <div class="buttons has-addons is-hidden-mobile">
            <button
              data-e2e="page-selector-cards-view"
              class="button"
              [ngClass]="{ 'is-primary is-active': viewType === 'Cards' }"
              (click)="switchView('Cards')"
            >
              <span class="icon">
                <i class="fas fa-th"></i>
              </span>
              <span>Cards</span>
            </button>

            <button
              data-e2e="page-selector-table-view"
              class="button"
              [ngClass]="{ 'is-primary is-active': viewType === 'Table' }"
              (click)="switchView('Table')"
            >
              <span class="icon">
                <i class="fas fa-th-list"></i>
              </span>
              <span>List</span>
            </button>
          </div>
        </div>
      </div>

      <ng-content select="[Filters]"></ng-content>
    </div>
  </div>
</section>

<ng-container *ngIf="isLoading && viewType === 'Cards'">
  <portal-spinner class="local"></portal-spinner>
</ng-container>

<ng-container *ngIf="viewType === 'Table'">
  <ng-content select="[Table]"></ng-content>
</ng-container>

<ng-container *ngIf="viewType === 'Cards'">
  <ng-container *ngIf="!isLoading && items?.length !== 0">
    <ng-content select="[Cards]"></ng-content>
  </ng-container>
  <ng-container *ngIf="!isLoading && items?.length === 0">
    <ng-content select="[no-results]"></ng-content>
  </ng-container>
</ng-container>

<ng-container *ngIf="!isLoading && items?.length !== 0 || viewType === 'Table'">
  <ng-content select="[Pagination]"></ng-content>
</ng-container>



