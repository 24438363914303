import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITag, TTagColor } from '@portal/marketplace/api-interfaces';

@Component({
  selector: 'marketplace-tag',
  templateUrl: './marketplace-tag.component.html',
  styleUrls: ['./marketplace-tag.component.scss'],
})
export class MarketplaceTagComponent {
  @Input() tag: ITag;
  @Input() color: TTagColor = 'default';
  @Output() tagAction = new EventEmitter();

  onTagAction(tag: ITag): void {
    this.tagAction.emit(tag);
  }
  isAction(): boolean {
    return this.tagAction.observers.length > 0;
  }
}
