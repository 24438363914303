import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers';
import { ReasonForDeletion } from '../enums/reason-for-deletion.enum';

@Injectable({
  providedIn: 'root',
})
export class ReasonForDeletionList extends SelectInputsList {
  keyValue = {
    [ReasonForDeletion.NoLongerRequired]: $localize`:reason for deletion|:This organization is no longer required`,
    [ReasonForDeletion.CreatedByMistake]: $localize`:reason for deletion|:Organization was created by mistake`,
    [ReasonForDeletion.Restructuring]: $localize`:reason for deletion|:Hierarchy restructuring`,
    [ReasonForDeletion.ContractEnded]: $localize`:reason for deletion|:Contract has been ended`,
    [ReasonForDeletion.Other]: $localize`:reason for deletion|:Other`,
  };
  readonly list = this.getSelectInputs(this.keyValue);
}
