<label>
  <div class="label" data-e2e="textControl-label">
    <span>{{ name }}</span>
    &nbsp;<span *ngIf="optional" class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
  </div>
  <div class="control">
    <input
      [formControl]="control"
      [attr.data-e2e]="e2eName"
      [ngClass]="{ 'is-danger': invalid }"
      type="text"
      class="input"
    />
    <portal-form-error [formInput]="control" [errorId]="name"></portal-form-error>
  </div>
</label>
