import { UserRoles } from '@portal/shared/auth/authorization/src';
import { FiltersList, FilterTypes } from '@portal/shared/ui/filter';

export const devicesFiltersList: FiltersList[] = [
  {
    selector: 'altVfiPoiId',
    title: 'Terminal ID',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'Terminal ID',
        id: 'altVfiPoiId',
      },
      {
        text: 'MID',
        id: 'altVfiEntityId',
      },
    ],
  },
];
export const filterList: FiltersList[] = [
  {
    selector: 'altVfiPoiId',
    title: 'Terminal ID',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'Terminal ID',
        id: 'altVfiPoiId',
      },
      {
        text: 'MID',
        id: 'altVfiEntityId',
      },
    ],
  },
];

export const DevicesFilterListConfig = (templates, options, service$, role): FiltersList[] => {
  const merchantFilter = {
    selector: 'parentIds',
    title: 'Merchant Name',
    type: FilterTypes.TreeFilter,
    placeholder: 'Search',
    service$,
    templates,
    options,
  };

  let filterConfig = devicesFiltersList;
  if (
    role.includes(UserRoles.EstateOwnerAdmin) ||
    role.includes(UserRoles.EstateOwnerDeviceAppManager)
  ) {
    filterConfig = [merchantFilter, ...devicesFiltersList];
  }

  return filterConfig;
};
