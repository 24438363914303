import { Injectable } from '@angular/core';
import { SupportedProcessorSettingsService } from '@apps/portal/src/app/services/supported-processor-settings.service';
import { PaymentType } from '@portal/entity-services/interfaces/src';
import { SelectInputKeysFilter, SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { Features } from 'environments/enums/features.enum';
import { sortBy } from 'lodash';
import { TranslationsKeyValue } from '@portal/shared/helpers/src/lib/create-list-from-inputs/interfaces/translations-key-value.interface';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class PaymentTypes extends SelectInputsList {
  list: ISelectInput[];
  keyValue = this.selectInputKeysFilter.filterByFeature(
    {
      [PaymentType.Affirm]: 'Affirm',
      [PaymentType.Afterpay]: 'Afterpay',
      [PaymentType.Alipay]: 'AliPay',
      [PaymentType.Amex]: 'American Express',
      [PaymentType.BaiduPay]: 'Baidu',
      [PaymentType.Bank]: $localize`Bank`,
      [PaymentType.BankAxept]: 'BankAxept',
      [PaymentType.Blik]: 'Blik',
      [PaymentType.Card]: $localize`Card`,
      [PaymentType.BbbyCob]: `COB`,
      [PaymentType.BbbyPlcc]: `BBBY PLCC`,
      [PaymentType.Carnet]: `Carnet`,
      [PaymentType.Cash]: $localize`Cash`,
      [PaymentType.Cb]: 'Carte Bancaire',
      [PaymentType.Cheque]: 'Check',
      [PaymentType.Clave]: 'Clave',
      [PaymentType.Crypto]: 'Crypto',
      [PaymentType.Dankort]: 'Dankort',
      [PaymentType.Debit]: 'Debit',
      [PaymentType.Diners]: 'Diners',
      [PaymentType.Discover]: 'Discover',
      [PaymentType.Ebt]: 'EBT',
      [PaymentType.EbtWicOffline]: 'WIC Smart Card EBT',
      [PaymentType.Edenred]: 'Edenred',
      [PaymentType.Electron]: 'Electron',
      [PaymentType.Flexecash]: 'Flexecash',
      [PaymentType.Forbrugsforeningen]: 'Forbrugsforeningen',
      [PaymentType.GarminPay]: 'Garmin Pay',
      [PaymentType.Gift]: 'Gift',
      [PaymentType.GiftCard]: $localize`Gift Card`,
      [PaymentType.Girocard]: 'Girocard',
      [PaymentType.Ikano]: 'Ikano',
      [PaymentType.IsraCard]: 'IsraCard',
      [PaymentType.IsraelPrivateLabel]: 'Israel Private Label',
      [PaymentType.Jcb]: 'JCB',
      [PaymentType.JdPay]: 'JD Pay',
      [PaymentType.Klarna]: 'Klarna',
      [PaymentType.KlarnaEcom]: 'Klarna Ecom',
      [PaymentType.KlarnaQr]: 'Klarna Pos',
      [PaymentType.Landsbankinn]: 'Landsbankinn',
      [PaymentType.Maestro]: 'Maestro',
      [PaymentType.Mastercard]: 'Mastercard',
      [PaymentType.Mobilepay]: 'MobilePay',
      [PaymentType.OpOnlinePayment]: 'OpOnlinePayment',
      [PaymentType.Other]: $localize`Other`,
      [PaymentType.Paypal]: 'PayPal',
      [PaymentType.PaypalQR]: 'PayPal QR',
      [PaymentType.PayPalEcom]: 'PayPal Ecom',
      [PaymentType.PayPalEcomManaged]: 'PayPal E-commerce Managed',
      [PaymentType.Plcc]: 'PLCC',
      [PaymentType.Qcard]: 'Q Card',
      [PaymentType.ResursBank]: 'Resurs Bank',
      [PaymentType.SamsungPay]: 'Samsung Pay',
      [PaymentType.Swish]: 'Swish',
      [PaymentType.TenPay]: 'Ten Pay',
      [PaymentType.Upi]: 'Upi',
      [PaymentType.Venmo]: 'Venmo',
      [PaymentType.VenmoQR]: 'Venmo QR',
      [PaymentType.Vipps]: 'Vipps',
      [PaymentType.Visa]: 'Visa',
      [PaymentType.Voyager]: 'Voyager',
      [PaymentType.VPay]: 'Vpay',
      [PaymentType.Wechat]: 'WeChat',
      [PaymentType.WestpacDebit]: 'Westpac Debit',
      [PaymentType.Wex]: 'Wex',
      [PaymentType.Zelle]: 'Zelle',
      [PaymentType.Zip]: 'ZIP',
    },
    {
      [Features.MobilePay]: [PaymentType.Mobilepay],
      [Features.Crypto]: [PaymentType.Crypto],
    },
  );

  constructor(
    private selectInputKeysFilter: SelectInputKeysFilter,
    private supportedProcessorSettingsService: SupportedProcessorSettingsService,
  ) {
    super();
    this.getSortedList();
  }
  getSupportedPayments(): ISelectInput[] {
    const supportedProcessorSettings =
      this.supportedProcessorSettingsService.getSupportedProcessorSettingsFromSessionStorage();
    if (supportedProcessorSettings?.paymentTypesSupported?.length) {
      const supportedPayments: TranslationsKeyValue = {};
      supportedProcessorSettings.paymentTypesSupported.forEach((payment) => {
        supportedPayments[payment] = this.keyValue[payment] || payment;
      });
      this.getSortedList(supportedPayments);
    }
    return this.list;
  }
  getSortedList(payments = this.keyValue): void {
    this.list = sortBy(this.getSelectInputs(payments), (item: ISelectInput) =>
      item.text.toLowerCase(),
    );
  }
}
