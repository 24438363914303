import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  AddressType,
  ContactType,
  IAddress,
  IContact,
  PhoneType,
} from '@portal/entity-services/interfaces';
import { CountryService, ICountry } from '@portal/shared/helpers/src';
import { Autoscroll, ErrorService, MultilingualValidators } from '@portal/shared/ui/form';
import { ContactTypes } from '../contact-type.list';

@Component({
  selector: 'portal-form-contact',
  templateUrl: './form-contact.component.html',
})
export class FormContactComponent extends Autoscroll implements OnInit, OnChanges {
  @Input()
  value: IContact;

  @Input()
  isCreating = false;

  @Input()
  disabled = false;

  @Output()
  close: EventEmitter<boolean> = new EventEmitter();

  @Output()
  contactUpdated: EventEmitter<Partial<IContact>> = new EventEmitter();

  @Output()
  contactCreated: EventEmitter<FormGroup> = new EventEmitter();

  form: FormGroup;
  countries: ICountry[];
  addresses: IAddress;
  addressTypes = AddressType;

  closed: boolean;
  animationDelayConstant = 500;
  contactTypeList = this.contactTypes.list;
  isInvalid = ErrorService.isFieldInvalid;

  editingTitle = '';

  constructor(
    public fb: FormBuilder,
    private contactTypes: ContactTypes,
    private customValidators: MultilingualValidators,
    private countryService: CountryService,
    protected elementRef: ElementRef,
  ) {
    super(elementRef);
    this.form = fb.group(
      {
        contactUid: [''],
        contactType: [ContactType.Business, [customValidators.required]],
        name: fb.group(
          {
            firstName: ['', [customValidators.required, customValidators.names]],
            lastName: ['', [customValidators.required, customValidators.names]],
          },
          { validators: [customValidators.required] },
        ),
        phoneNumbers: fb.array(
          [
            fb.group({
              value: ['', [customValidators.required]],
              phoneType: [PhoneType.Home],
            }),
          ],
          [customValidators.required],
        ),
        email: ['', [customValidators.required, customValidators.email]],
        addresses: fb.array([fb.group({})]),
        isPrimary: [false],
      },
      { validators: [customValidators.required] },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && this.form) {
      this.enableDisableForm();
    }
  }

  ngOnInit(): void {
    if (this.value) {
      this.form.patchValue(this.value);
    }
    this.countries = this.countryService.countries;
    this.addresses = this.getContactAddressControl().value;
  }

  enableDisableForm(): void {
    (this.disabled ? this.disableForm : this.enableForm).call(this);
  }

  disableForm(): void {
    this.form.disable({
      emitEvent: false,
    });
  }

  enableForm(): void {
    this.form.enable({
      emitEvent: false,
    });
  }

  closeSideBar(): void {
    this.closed = true;
    this.close.emit();
  }

  onContactCreated(): void {
    this.contactCreated.emit(this.form);
    this.closeSideBar();
  }

  onContactUpdated(): void {
    this.value = this.form.value;
    this.contactUpdated.emit({ ...this.value });
    this.closeSideBar();
  }

  onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this._saveContact();
    }
  }

  getContactTypeControl(): AbstractControl {
    return this.form.get('contactType');
  }

  getFirstNameControl(): AbstractControl {
    return this.form.get('name').get('firstName');
  }

  getLastNameControl(): AbstractControl {
    return this.form.get('name').get('lastName');
  }

  getPhoneNumberControl(): AbstractControl {
    return (this.form.get('phoneNumbers') as FormArray).at(0).get('value');
  }

  getEmailControl(): AbstractControl {
    return this.form.get('email');
  }

  getContactAddressControl(): AbstractControl {
    return (this.form.get('addresses') as FormArray).at(0);
  }

  private _saveContact(): void {
    if (this.isCreating) {
      this.onContactCreated();
    } else {
      this.onContactUpdated();
    }
  }
}
