<portal-filter-organisation-group
  [filter]="filter"
  [filterTouched]="filterTouched"
  (orgsFiltered)="onOrgFiltered($event)"
  (nameFiltered)="onOrganisationGroupsByName($event)"
  (orgsFilterReset)="onOrgFilterReset()"
>
</portal-filter-organisation-group>

<div class="columns">
  <div class="column is-10">
    <p *ngIf="range" class="has-margin-bottom" i18n>
      {{ range }} of {{ totalCount }} organisations
    </p>
  </div>

  <div class="column is-2">
    <table>
      <tr>
        <td>
          <span class="pr-2"> {{ labelUsers }}</span>
        </td>
        <td>
          <div class="toggle">
            <input
              id="user-group-status"
              type="checkbox"
              [checked]="switchOn"
              (change)="toggleOnOff()"
              data-e2e="user-group-status"
              class="switch is-rtl"
            />
            <label for="user-group-status" data-e2e="user-group-status-label-enabled">
              {{ labelGroups }}
            </label>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>

<portal-table
  [items]="organisations"
  [itemsClickable]="canGoToDetail"
  [loading$]="loading$"
  [filter]="filter"
  [tableConfig]="tableConfig"
  rowLinkHref="/administration/organization-group"
  rowLinkHeading="Link to organisation group details"
  i18n-rowLinkHeading="Column header for screen readers only"
  loadingMessage="Loading organisation groups"
  i18n-loadingMessage
  notFoundMessage="No organisation groups found"
  i18n-notFoundMessage
  (sortChanged)="onSortChanged($event)"
></portal-table>

<portal-pagination
  [reset]="resetPagination"
  [itemsPerPage]="filter.limit"
  [totalItems]="totalCount"
  (paginationChanged)="onPagination($event)"
></portal-pagination>
