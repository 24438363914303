import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IPaymentContract } from '@portal/entity-services/interfaces';
import { PaymentContractService } from '@portal/entity-services/payment-contracts/src/lib/services/payment-contract.service';
import {
  FilterCheckboxComponent,
  FilterContentItem,
  FILTER_CONTENT_ITEM,
  IFilterToApply,
  ISearchCriteria,
  ISelectInput,
  SearchOperator,
  SearchSelector,
  SwitchToggleComponent,
} from '@portal/shared/ui/filter';
import cloneDeep from 'lodash-es/cloneDeep';
import filter from 'lodash-es/filter';
import forEach from 'lodash-es/forEach';
import { forkJoin, from, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinct, distinctUntilChanged, switchMap, toArray } from 'rxjs/operators';
import { FiltersService } from '@portal/shared/ui/filter/src/lib/services/filters.service';

@UntilDestroy()
@Component({
  selector: 'portal-filter-merchant-id',
  templateUrl: './merchant-id.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: FilterMerchantIdComponent,
    },
  ],
})
export class FilterMerchantIdComponent extends FilterContentItem implements OnInit, OnDestroy {
  @Input()
  selectedEntities: Map<string, ISearchCriteria> = new Map();

  @Input()
  isMainDropdownFilter: boolean;

  @Input()
  displayFromFilter: boolean;

  @Input()
  closeSubMenu: boolean;

  @Input()
  isSubMenuFilter: boolean;

  @Input()
  showFilterSubMenu: boolean;

  @Input()
  localPreSelectedContracts: Map<string, ISelectInput> = new Map();

  @Output()
  merchantIdTextName: EventEmitter<any> = new EventEmitter();

  @Output()
  filterApplied = new EventEmitter<void>();

  @Output()
  filterUpdated = new EventEmitter<IFilterToApply>();

  @Output()
  staticFilterUpdated = new EventEmitter<IFilterToApply>();

  @Output()
  itemToggled = new EventEmitter<number>();

  @Output()
  listAllEntities: EventEmitter<ISelectInput[]> = new EventEmitter();

  @Output() buttonFocused: EventEmitter<boolean> = new EventEmitter();

  @Output()
  preSelectedContractsChange: EventEmitter<Map<string, ISelectInput>> = new EventEmitter();

  @ViewChild('preSelectedEntities') preSelectedEntities: FilterCheckboxComponent;

  @ViewChild('switchToggle') switchToggle: SwitchToggleComponent;

  searchSelector = SearchSelector.VfiMerchantId; // VfiMerchantId refers to transaction.merchant.id
  showSelectedContracts: Map<string, ISelectInput> = new Map();
  contracts: IPaymentContract[] = [];
  contractsAll: IPaymentContract[] = [];
  searchedText = '';

  loading$: Observable<boolean>;
  searchTerm$ = new Subject<string>();
  search$: Observable<IPaymentContract[]>;

  debounce = 1000;
  merchantIdText = $localize`Merchant ID`;
  labelSelectAllIds = $localize`Select all IDs`;
  merchantIdName = '';

  staticListUpdateSubject$ = new Subject<IFilterToApply>();
  staticListUpdateSub: Subscription;
  backToMenuSubject$ = new Subject<void>();
  backToMenuSub: Subscription;

  isActive = true;

  private allEntities: ISelectInput[];
  private initialDebounce = 1000;

  constructor(
    public paymentContractService: PaymentContractService,
    private filtersService: FiltersService,
  ) {
    super();
    this.loading$ = paymentContractService.loading$;
    this.startListeningForSearch();
  }

  ngOnInit(): void {
    this.updateSelectedContracts();
    this.updateText();
    this.getContracts();
    this.merchantIdName = this.merchantIdText;
    this.closeSubMenu = false;
    if (this.isSubMenuFilter) {
      this.isActive = false;
      this.isOpen = false;
    }
    this.staticListUpdateSub = this.staticListUpdateSubject$
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.staticFilterUpdated.emit({
          key: this.searchSelector,
          selector: this.searchSelector,
          value: Array.from(this.localPreSelectedContracts.values()),
        });
      });

    this.backToMenuSub = this.backToMenuSubject$.pipe(debounceTime(100)).subscribe(() => {
      const element = document.getElementById('add-filter');
      if (element) {
        element.click();
      }
    });

    this.filtersService.actionRemoveFilter$.subscribe((selectedContract: ISelectInput) => {
      if (this.searchSelector === selectedContract.key) {
        this.localPreSelectedContracts.delete(selectedContract.id);
        this.filterUpdate();
        this.onFilterApplied();
        this.itemToggled.emit();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.staticListUpdateSub) {
      this.staticListUpdateSub.unsubscribe();
    }
    if (this.backToMenuSub) {
      this.backToMenuSub.unsubscribe();
    }
  }

  backToMenu(): void {
    this.itemToggled.emit();
    this.backToMenuSubject$.next();
    this.isOpen = false;
    this.isActive = false;
  }

  openContent(): void {
    this.itemToggled.emit();
    this.updateText();
    this.onFilterApplied();
    this.updateContracts();
    this.closeSubMenu = false;
    this.isOpen = true;
  }

  setEntities(contracts: IPaymentContract[]): void {
    this.allEntities = contracts?.map((contract) => {
      return {
        id: contract.contractUid,
        text: contract?.entity?.name,
        merchantId: contract.merchantId,
      };
    });
    this.listAllEntities.emit(this.allEntities);
    this.merchantIdTextName.emit(this.merchantIdName);

    const selectedEntities = this.selectedEntities
      ?.get(SearchSelector.VfiMerchantId)
      ?.argument?.replace('(', '')
      ?.replace(')', '');

    const selectedEntitiesArr = selectedEntities?.split(',');
    this.allEntities?.forEach((entity) => {
      selectedEntitiesArr?.forEach((preSelected) => {
        if (entity?.id === preSelected) {
          this.onContractAdded(entity);
        }
      });
    });
  }

  onSearch(searchValue = ''): void {
    this.searchedText = searchValue;
    this.searchTerm$.next(searchValue);
  }

  unableToFindNonExistentPPC(): void {
    this.onSearch(this.searchedText);
    const searchedTextNotFoundContract: Map<string, ISelectInput> = new Map();
    searchedTextNotFoundContract.set(this.searchedText, {
      id: this.searchedText,
      text: '',
    });
    const selectedValues = this.getValues();
    const selectedValuesString = selectedValues?.length
      ? Array.from(selectedValues).join(',') + ','
      : '';
    this.filterUpdated.emit({
      key: this.searchSelector,
      selector: this.searchSelector,
      operator: SearchOperator.In,
      value: [
        ...Array.from(searchedTextNotFoundContract.values()),
        ...Array.from(this.localPreSelectedContracts.values()),
      ],
      argument: '(' + selectedValuesString + this.searchedText + ')',
    });
    this.staticFilterUpdated.emit({
      key: this.searchSelector,
      selector: this.searchSelector,
      value: Array.from(searchedTextNotFoundContract.values()),
    });

    this.filterApplied.emit();
    this.itemToggled.emit();
  }

  onFilterApplied(): void {
    // unable to find non-existent PPC
    if (!this.contracts?.length && !this.isSearchValueSelected() && this.searchedText) {
      this.unableToFindNonExistentPPC();
    } else {
      this.resetInputValue();
      this.filterApplied.emit();
      this.itemToggled.emit();
      this.setEntities(this.contracts);
      this.preSelectedContractsChange.emit(this.localPreSelectedContracts)
      if (this.isSubMenuFilter) {
        this.closeSubMenu = true;
      }
    }
  }

  onClear(): void {
    this.localPreSelectedContracts.clear();
    this.showSelectedContracts.clear();
    this.resetInputValue();
    this.filterUpdate();
  }

  clear(): void {
    this.onClear();
  }

  onContractRemoved(selectedContract: ISelectInput): void {
    this.localPreSelectedContracts.delete(selectedContract.id);
    this.filterUpdate();
  }

  onContractAdded(selectedContract: ISelectInput): void {
    this.localPreSelectedContracts.set(selectedContract.id, {
      id: selectedContract.id,
      text: selectedContract.text,
      merchantId: selectedContract.merchantId,
    });
    this.filterUpdate();
  }

  getList(): Observable<IPaymentContract[]> {
    this.debounce = 0;
    this.searchTerm$.next('');
    return null;
  }

  getValues(): string[] {
    return Array.from(this.localPreSelectedContracts.values()).map(
      (value: ISelectInput) => value.merchantId,
    );
  }

  isSearchValueSelected(): boolean {
    const values = this.getValues();
    return values?.includes(this.searchedText) || false;
  }

  private filterUpdate(): void {
    const values: string[] = this.getValues();
    if (values.length) {
      this.filterUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.localPreSelectedContracts.values()),
        argument: `(${Array.from(values).join(',')})`,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        value: Array.from(this.localPreSelectedContracts.values()),
      });
    } else {
      this.filterUpdated.emit({
        key: this.searchSelector,
        toRemove: true,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        toRemove: true,
      });
    }

    this.updateContracts();
    this.updateText();
  }

  private startListeningForSearch(): void {
    this.search$ = this.search(this.searchTerm$);
    this.search$.pipe(untilDestroyed(this)).subscribe((contracts: IPaymentContract[]) => {
      if (this.localPreSelectedContracts.size) {
        this.contracts = contracts;
        this.contractsAll = contracts;
        this.updateContracts();
      } else {
        this.contracts = filter(contracts, (contract: IPaymentContract) => contract.merchantId);
        this.contractsAll = this.contracts;
      }
    });
  }

  // TODO We need to review this method in a new PR, it's not working as espected
  private updateSelectedContracts(): void {
    if (this.isSubMenuFilter) {
      return;
    }
    const entitiesToFetch: string[] = Array.from(
      this.selectedEntities.get(this.searchSelector)?.values || [],
    );
    if (entitiesToFetch.length) {
      forkJoin(
        entitiesToFetch.map((merchantId: string) => {
          return this.getPPCsByContractName(merchantId);
        }),
      ).subscribe((response: IPaymentContract[][]) => {
        forEach(response, (contracts: IPaymentContract[]) => {
          forEach(contracts, (contract: IPaymentContract) => {
            this.localPreSelectedContracts.set(contract.contractUid, {
              id: contract.contractUid,
              text: contract.entity?.name,
              merchantId: contract.merchantId,
            });
          });
        });
        this.updateText();
      });
    }
  }

  private updateContracts(): void {
    this.showSelectedContracts = cloneDeep(this.localPreSelectedContracts);
    const contractsToExclude = Array.from(this.localPreSelectedContracts.keys());
    this.contracts = filter(this.contractsAll, (contract: IPaymentContract) => {
      return contract.contractUid && !contractsToExclude.includes(contract.contractUid);
    });
  }

  private getPPCsByContractName(term: string): Observable<IPaymentContract[]> {
    const params = term ? { merchantId: term } : { limit: '50' };
    return this.paymentContractService
      .getWithQuery({ ...params, populateEntity: 'true' })
      .pipe(switchMap(from), toArray());
  }

  private getContracts(term: string = ''): void {
    const params = term ? { merchantId: term } : { limit: '50' };
    this.paymentContractService
      .getWithQuery({ ...params, populateEntity: 'true' })
      .pipe(
        switchMap(from),
        distinct((contract: IPaymentContract) => contract.contractUid),
        toArray(),
      )
      .subscribe((contracts) => {
        if (this.localPreSelectedContracts.size) {
          this.contracts = contracts;
          this.contractsAll = contracts;
          this.updateContracts();
        } else {
          this.contracts = filter(contracts, (contract: IPaymentContract) => contract.contractUid);
          this.contractsAll = this.contracts;
        }
        this.setEntities(contracts);
      });
  }

  private search(terms: Observable<string>): Observable<IPaymentContract[]> {
    return terms.pipe(debounceTime(this.debounce), distinctUntilChanged()).pipe(
      switchMap((term: string) => {
        // Always reset debounce time
        this.debounce = this.initialDebounce;
        return this.getPPCsByContractName(term);
      }),
    );
  }

  private updateText(): void {
    const values: string[] = this.getValues();
    const text = $localize`Merchant ID`;
    this.merchantIdText = values.length ? `${text} (+${values.length})` : text;
  }

  private resetInputValue(): void {
    this.searchedText = '';
    this.closeSubMenu = false;
    this.searchTerm$.next('');
  }
}
