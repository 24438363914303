export enum PaymentAction {
  Refund = 'Refund',
  Capture = 'Capture',
  Void = 'Void',
  VoidCapture = 'VoidCapture',
  RefundPreview = 'RefundPreview',
  CompleteRefund = 'CompleteRefund',
  CancelRefund = 'CancelRefund',
  MultipleCapture = `MultipleCapture`,
  Extend = 'Extend',
}
