import { Directive, ElementRef, HostListener, Input, OnChanges, Renderer2 } from '@angular/core';
import { ITooltipAttributes } from './tooltip.interface';
import { ToolTipElements } from './enums/ToolTipElements';

/**
 - `.has-tooltip-top` - to make a tooltip appear on above the element
 - `.has-tooltip-right` - to make a tooltip appear on the right of the element
 - `.has-tooltip-left` - to make a tooltip appear on the left of the element
 - `.has-tooltip-bottom` - to make a tooltip appear below the element
 - `.has-tooltip-multiline` - a tooltip with more then one line
 */

@Directive({
  selector: '[portalTooltip],[dataTooltip]',
})
export class TooltipDirective implements OnChanges {
  @Input('dataTooltip') tooltipTitle: string;
  @Input() portalTooltip: string;
  // Default values
  @Input() position = ToolTipElements.Bottom as string;
  @Input() delay = ToolTipElements.Delay as number;
  dataTooltip: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter(): void {
    if (!this.dataTooltip && this.el.nativeElement.getAttribute('data-tooltip')) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.el.nativeElement) {
      this.hide();
    }
  }

  show(): void {
    this.create();
  }

  hide(): void {
    if ((this.el.nativeElement as HTMLElement).contains(this.dataTooltip)) {
      window.setTimeout(() => {
        this.renderer.removeChild(this.el.nativeElement, this.dataTooltip);
        this.dataTooltip = null;
      }, this.delay);
    }
  }

  create(): void {
    this.dataTooltip = this.renderer.createElement('div');
    this.renderer.appendChild(this.el.nativeElement, this.dataTooltip);

    this.renderer.addClass(this.el.nativeElement, 'tooltip');
    if (this.tooltipTitle?.length > ToolTipElements.MultilineLimit) {
      this.renderer.addClass(this.el.nativeElement, `has-tooltip-${ToolTipElements.Multiline}`);
    }
    this.renderer.addClass(this.el.nativeElement, `has-tooltip-${this.position}`);

    // delay setting
    this.renderer.setStyle(this.el.nativeElement, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.el.nativeElement, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.el.nativeElement, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.el.nativeElement, 'transition', `opacity ${this.delay}ms`);
  }

  ngOnChanges(): void {
    const {
      text = '',
      position = 'top',
      multiline,
      color,
      alwaysActive,
      nowrap = false,
    }: ITooltipAttributes = this.portalTooltip ? JSON.parse(this.portalTooltip) : '';
    if (text) {
      this.el.nativeElement.setAttribute('data-tooltip', text);
      this.el.nativeElement.classList.add(`has-tooltip-${position}`);
      if (color) {
        this.el.nativeElement.classList.add(`has-tooltip-${color}`);
      }
      if (multiline) {
        this.el.nativeElement.classList.add('has-tooltip-multiline');
      }
      if (alwaysActive) {
        this.el.nativeElement.classList.add('has-tooltip-active');
      }
      if (nowrap) {
        this.renderer.setStyle(this.el.nativeElement, 'white-space', 'nowrap');
      }
    }
  }
}
