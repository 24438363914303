import { Component, Input, OnInit } from '@angular/core';
import { PaymentModel } from '@portal/marketplace/api-interfaces/src';

@Component({
  selector: 'marketplace-app-price',
  templateUrl: './app-price.component.html',
  styleUrls: ['./app-price.component.scss'],
})
export class AppPriceComponent implements OnInit {
  @Input() price: number = null;
  @Input() paymentModel: PaymentModel;
  @Input() isRegular = false;

  constructor() {}

  ngOnInit(): void {}

  get displayPaymentModel(): boolean {
    return this.price && this.paymentModel === 'RECURRING';
  }
}
