import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vui-four-zero-four',
  templateUrl: './four-zero-four.component.html',
  styleUrls: ['./four-zero-four.component.scss'],
})
export class FourZeroFourComponent {
  constructor() {}
}
