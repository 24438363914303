import { IGuardPermissions } from '@portal/shared/auth/authorization/src';
import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';

import { RouteName } from './route-name.enum';

export const routePermissions: { [key in RouteName]?: IGuardPermissions } = {
  [RouteName.list]: {
    read: [UserRoles.MerchantCashier, UserRoles.MerchantSupervisor, UserRoles.VerifoneAdmin],
  },
  [RouteName.id]: {
    read: [UserRoles.MerchantCashier, UserRoles.MerchantSupervisor, UserRoles.VerifoneAdmin],
    disable: [UserRoles.MerchantCashier, UserRoles.MerchantSupervisor],
    enable: [UserRoles.MerchantCashier, UserRoles.MerchantSupervisor],
  },
  [RouteName.create]: {
    read: [UserRoles.MerchantCashier, UserRoles.MerchantSupervisor],
  },
};
