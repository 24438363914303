import { Component, EventEmitter, Input, Output } from '@angular/core';

declare const $localize;

@Component({
  selector: 'portal-search-filter-autocomplete-input',
  templateUrl: './search-autocomplete-input.component.html',
})
export class FilterAutoCompleteInputComponent {
  @Input() placeholder = $localize`:placeholder|Search input placeholder:Search`;
  @Input() id: string;
  @Output() search: EventEmitter<string> = new EventEmitter();

  @Input() searchInputValue: string = '';

  placeholderText = $localize`:placeholder|Search input placeholder:Search`;
  constructor() {}

  onSearch(searchValue: string): void {
    this.search.emit(searchValue);
  }

  reset(): void {
    this.searchInputValue = '';
  }
}
