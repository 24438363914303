export enum FilterSubMenu {
  Channel = 'channel',
  Currency = 'currency',
  Product = 'product',
  TransactionType = 'transaction-type',
  HeaderState = 'header-state',
  Processor = 'processor',
  SalesChannel = 'sales-channel',
  MerchantId = 'merchant-id',
  Organisation = 'organisation',
  Organisations = 'organisations',
  CreatedDate = 'created-date',
  SettledDate = 'settled-date',
  TransactionDate = 'transaction-date',
}
