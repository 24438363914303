import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedAuthAuthorizationModule } from '@portal/shared/auth/authorization';
import { AttributesModule } from '@portal/shared/helpers/src/lib/attributes/attributes.module';
import { PipesModule } from '@portal/shared/pipes';
import { ClickOutsideModule } from '@portal/shared/ui/click-outside';
import { ExpansionMenuComponent } from './components/expansion-menu/expansion-menu.component';
import { NavbarDropdownComponent } from './components/navbar-dropdown/navbar-dropdown.component';
import { TopNavbarComponent } from './top-navbar.component';
import { TopCommonNavbarComponent } from './components/top-common-navbar/top-common-navbar.component';
import { CommonNavbarStateDirective } from './components/top-common-navbar/common-navbar-state.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ClickOutsideModule,
    AttributesModule,
    PipesModule,
    SharedAuthAuthorizationModule,
  ],
  declarations: [
    TopNavbarComponent,
    NavbarDropdownComponent,
    ExpansionMenuComponent,
    TopCommonNavbarComponent,
    CommonNavbarStateDirective,
  ],
  exports: [
    TopNavbarComponent,
    TopCommonNavbarComponent,
    CommonNavbarStateDirective,
    CommonNavbarStateDirective,
  ],
})
export class TopNavbarModule {}
