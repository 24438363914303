import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedAuthAuthorizationModule } from '@portal/shared/auth/authorization';
import { PipesModule } from '@portal/shared/pipes';
import { BreadcrumbsModule } from '@portal/shared/ui/breadcrumbs';
import { AppIconModule } from '../../shared/app-icon/app-icon.module';
import { AppPriceModule } from '../../shared/app-price/app-price.module';
import { AppPromoModule } from '../../shared/app-promo/app-promo.module';
import { AppScreenCarouselModule } from '../../shared/app-screen-carousel/app-screen-carousel.module';
import { FileUploadModule } from '../../shared/file-upload/file-upload.module';
import { MainIconModule } from '../../shared/main-icon/main-icon.module';
import { StatusIndicatorModule } from '../../shared/status-indicator/status-indicator.module';
import { VersionSelectorModule } from '../../shared/version-selector/version-selector.module';
import { AppLogoPublicTemplateModule } from '../app-logo-public-template/app-logo-public-template.module';
import { AppLogoTemplateModule } from '../app-logo-template/app-logo-template.module';
import { AppPageTemplateComponent } from './app-page-template.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StatusIndicatorModule,
    AppScreenCarouselModule,
    SharedAuthAuthorizationModule,
    AppPriceModule,
    VersionSelectorModule,
    MainIconModule,
    AppPromoModule,
    FileUploadModule,
    PipesModule,
    AppLogoTemplateModule,
    BreadcrumbsModule,
    AppLogoPublicTemplateModule,
    AppIconModule,
  ],
  declarations: [AppPageTemplateComponent],
  exports: [AppPageTemplateComponent],
})
export class AppPageTemplateModule {}
