import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
@Injectable({ providedIn: 'root' })
export class ReasonsForDeletion extends SelectInputsList {
  keyValue = {
    ['This user is no longer required.']: $localize`:reason for user deletion|:This user is no longer required.`,
    ['Wrong email address has been added.']: $localize`:reason for user deletion|:Wrong email address has been added.`,
    ['Others']: $localize`:reason for user deletion|:Others`,
  };
  list: ISelectInput[] = this.getSelectInputs();
}
