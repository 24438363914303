export enum AppAction {
  REGISTER = 'REGISTERED',
  SUBMIT = 'SUBMITTED',
  REVIEW = 'IN_REVIEW',
  APPROVE = 'APPROVED',
  DISABLE = 'DISABLED',
  WITHDRAW = 'WITHDRAWN',
  NEW_VERSION = 'NEW_VERSION',
  REJECT = 'REJECTED',
}
