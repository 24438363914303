import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { httpInterceptorProviders } from './interceptors';

@NgModule({
  imports: [CommonModule],
})
export class SharedVuiHttpModule {
  static init(): ModuleWithProviders<SharedVuiHttpModule> {
    return {
      ngModule: SharedVuiHttpModule,
      providers: [httpInterceptorProviders],
    };
  }
}
