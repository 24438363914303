// import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import environment from '@environments';
import { VuiHttpService } from '@portal/shared/vui-http';
import { Observable } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PublicAppCatalogDataService {
  constructor(private http: VuiHttpService) {}
  getPublicAppsFromVfAppMarket(): Observable<any> {
    return fromFetch(environment.API_ENDPOINT.PUBLIC_CATALOG.publicApps).pipe(
      switchMap((response) => {
        if (response.ok) {
          return response.json();
        }
      }),
    );
    // return this.http.get(environment.API_ENDPOINT.PUBLIC_CATALOG.publicApps);
  }
  getPublicAppsFromAppCatalog(appsIds: string[]): Observable<any> {
    return fromFetch(environment.API_ENDPOINT.PUBLIC_CATALOG.publicAllAppsDetails).pipe(
      switchMap((response) => {
        if (response.ok) {
          return response.json();
        }
      }),
    );
    // return this.http.get(environment.API_ENDPOINT.PUBLIC_CATALOG.publicAllAppsDetails);
  }
}
