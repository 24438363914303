import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestrictPermissionService } from './services/restrict-permission.service';

@Directive({
  /* eslint-disable @angular-eslint/directive-selector */
  selector: '[restrictPermission]',
  /* eslint-enable @angular-eslint/directive-selector */
})
export class RestrictPermissionDirective implements OnChanges {
  @Input('restrictPermission') restrictedActions: string[];
  @Input('restrictPermissionRouterLink') routerLink: string[] | string;

  private hasView = false;

  constructor(
    private route: ActivatedRoute,
    private viewContainer: ViewContainerRef,
    private restrictPermissionService: RestrictPermissionService,
    private templateRef: TemplateRef<any>,
  ) {}

  ngOnChanges(): void {
    this.updateView();
  }

  canShowView(): boolean {
    const permissions = this.routerLink
      ? this.restrictPermissionService.getPermissionsForUrl(this.routerLink, {
          relativeTo: this.route,
        })
      : this.restrictPermissionService.getPermissionsForRoute(this.route.snapshot);
    const userhasPermissions = this.restrictPermissionService.checkPermissions(
      permissions,
      this.restrictedActions,
    );
    return userhasPermissions;
  }

  updateView(): void {
    const canShow = this.canShowView();
    if (canShow && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!canShow && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
