<ng-container *ngIf="!showLoader; else loading">
  <div *ngIf="!isAuthenticated; else authenticatedUserContainer">
    <vui-top-common-navbar [navbarItems]="[]" [logoSrc]="logoSrc" [hasLogoClass]="isLogoVisible">
      <ng-content select="[navbar-menu-item-end]" navbar-menu-item-end></ng-content>
      <ng-content select="[mobile-login]" mobile-login></ng-content>
    </vui-top-common-navbar>

    <div class="mt-1">
      <portal-notifier></portal-notifier>
    </div>

    <ng-content select="[vui-public]"></ng-content>

    <div class="splash__container section" *ngIf="withSignInSplash">
      <ng-content select=".splash__message"></ng-content>
      <button
        *ngIf="!isFederatedUser"
        i18n
        (click)="signIn(true)"
        class="button is-primary"
        data-e2e="app-button-login"
        type="button"
        title="Go to Sign In"
        i18n-title
      >
        Go To Sign In
      </button>
    </div>
  </div>

  <ng-template #authenticatedUserContainer>
    <!-- PORTAL NAVIGATION -->
    <ng-content select="vui-top-common-navbar"></ng-content>
    <ng-content select="[vui-top-common-navbar]"></ng-content>
    <ng-content select="vhq-hierarchy"></ng-content>

    <!-- Hero Image and data -->
    <vui-hero></vui-hero>

    <!-- PORTAL CONTENT -->
    <div class="portal__content" role="main">
      <div class="is-small" [class.section]="!isWhiteLabelingPreview">
        <div [class.container]="!isWhiteLabelingPreview">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>

<!-- PORTAL NOTIFIER -->
<portal-notifier></portal-notifier>

<ng-template #loading>
  <div class="portal_spinner" role="main">
    <portal-spinner [isLarge]="true"></portal-spinner>
  </div>
</ng-template>

<portal-mandatory-modal #acceptTermsAndConditionsModal>
  <div title i18n>
    <ng-container *ngIf="!needsToAcceptNewVersion && needsToAcceptTnC">
      Welcome to your {{ brandText }} account!
    </ng-container>
    <ng-container *ngIf="needsToAcceptNewVersion">
      Acceptance of updated Terms and conditions is required
    </ng-container>
  </div>
  <div description i18n>
    <ng-container *ngIf="!needsToAcceptNewVersion && needsToAcceptTnC">
      Before you get started please read and accept {{ brandText }}'s
      <a [href]="termsConditionLink" target="_blank">Terms and Conditions</a>
    </ng-container>
    <ng-container *ngIf="needsToAcceptNewVersion">
      A change in our <a [href]="termsConditionLink" target="_blank">Terms and Conditions</a> takes
      effect on {{ currentTnCVersion }}. Please read and accept the terms.
    </ng-container>
  </div>
  <div class="level" buttons>
    <div class="level-left"></div>
    <div class="buttons">
      <button
        class="button is-danger is-outlined"
        data-e2e="logout-button"
        (click)="onTnCLogout()"
        type="button"
        i18n
        title="Logout"
        i18n-title
      >
        Logout
      </button>
      <button
        type="button"
        class="button is-primary"
        (click)="onAcceptTnC()"
        data-e2e="accept-terms-and-coditions-button"
        i18n
        title="Accept Terms & Conditions"
        i18n-title
      >
        Accept Terms & Conditions
      </button>
    </div>
  </div>
</portal-mandatory-modal>

<portal-mandatory-modal #userActivityModal>
  <div title i18n>Session Timeout</div>
  <div description i18n>
    <ng-container *ngIf="isUserIdleTimeout">
      Your online session is about to timeout. Please click "Continue" to keep working or click "Log Off" to end your session now.
    </ng-container>
    <ng-container *ngIf="isSessionTimeout">
      Your online session is about to timeout. Please save your work before timeout.
    </ng-container>
  </div>
  <div class="level" buttons>
    <div class="level-left"></div>
    <div class="buttons">
      <button
        *ngIf="isUserIdleTimeout"
        type="button"
        class="button is-danger is-outlined"
        (click)="closeUserSession()"
        title="Log Off"
        i18n-title
        i18n
      >
        Log Off
      </button>
      <button
        *ngIf="isUserIdleTimeout"
        type="button"
        class="button is-primary"
        (click)="continueUserSession()"
        title="Continue"
        i18n-title
        i18n
      >
        Continue
      </button>
      <button
        *ngIf="isSessionTimeout"
        type="button"
        class="button is-primary"
        (click)="continueUserSession()"
        title="Ok"
        i18n-title
        i18n
      >
        Ok
      </button>
    </div>
  </div>
</portal-mandatory-modal>
