import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileService } from './services/file.service';
import { FileInputComponent } from './components/file-input/file-input.component';
import { SharedModule } from '@apps/portal/src/app/shared/shared.module';
import { FileComponent } from '@portal/shared/ui/file-input/src/lib/components/file/file.component';
import { FormModule } from '@portal/shared/ui/form';

@NgModule({
  imports: [CommonModule, SharedModule, FormModule],
  exports: [FileInputComponent, FileComponent],
  declarations: [FileInputComponent, FileComponent],
  providers: [FileService],
})
export class FileInputModule {}
