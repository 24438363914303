import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import environment from 'environments/environment';
import { GoogleTagManagerInterceptor } from './google-tag-manager.interceptor';
import { GtmService } from './gtm.service';

export function appInitFactory(service: GtmService): () => void {
  const resultFunction = () => service.init();
  return resultFunction;
}

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: 'googleTagManagerId', useValue: environment.PORTAL_APPS_ID.GTM },
    GtmService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [GtmService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: GoogleTagManagerInterceptor, multi: true },
  ],
})
export class GoogleTagManagerModule {}
