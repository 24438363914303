import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import get from 'lodash-es/get';

import { UserRoles } from '@portal/shared/auth/authorization/src';
import { UserService } from '@portal/shared/user/src';

import { IApp, VaultStatus } from '@portal/marketplace/api-interfaces';

@Component({
  selector: 'marketplace-app-card-template',
  templateUrl: './app-card-template.component.html',
  styleUrls: ['./app-card-template.component.scss'],
})
export class AppCardTemplateComponent implements OnInit {
  @Input() app: IApp;
  @Input() showUpdatesIndicator = false;
  @Input() showInstallsIndicator = false;
  @Input() showTags = false;
  @Input() showVaultStatus = false;
  @Input() showIsFeatured: Boolean = true;
  @Input() disabled = false;
  @Input() isPublic = true;
  @Input() isFeaturedPath: string;
  @Input() isPublicPath: string;
  @Input() isPilotPath: string;
  @Output() goToAppPageClickEvent: EventEmitter<IApp> = new EventEmitter();
  @Output() assignAppToDevicesClick: EventEmitter<any> = new EventEmitter();
  isDropdownOpen: Boolean = false;
  showUpdateIcon: Boolean = false;
  hasInstallsFlag = false;
  hasUpdatesFlag = false;
  hasPublicFlag = false;
  hasPilotFlag = false;
  hasFeaturedFlag = false;
  showAppIcon = false;

  constructor(private userService: UserService) {}

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  gotoAppPageClick(): void {
    if (!this.disabled) {
      this.goToAppPageClickEvent.emit(this.app);
    }
  }

  get displayPaymentModel(): boolean {
    return this.app.paymentModel === 'RECURRING';
  }

  ngOnInit(): void {
    this.hasInstallsFlag = this.hasInstalls();
    this.hasUpdatesFlag = this.hasUpdates();
    this.hasPublicFlag = get(this.app, this.isPublicPath);
    this.hasFeaturedFlag = get(this.app, this.isFeaturedPath);
    this.hasPilotFlag = get(this.app, this.isPilotPath);
  }

  hasInstalls(): boolean {
    return this.showInstallsIndicator && !!this.app.hasAssignments;
  }

  hasUpdates(): boolean {
    return this.showUpdatesIndicator && this.app.isAvailableForUpgrade;
  }

  mapTags(tags: any[]): string[] {
    return tags.map((tag) => tag['displayName']);
  }

  getStatus(app: IApp): VaultStatus | '' {
    return app && app.acquirers && app.acquirers[0].vaultStatus ? app.acquirers[0].vaultStatus : '';
  }
  getIcons(): boolean {
    let USER_TYPE: 'MERCHANT' | 'EO' = 'MERCHANT';
    if (
      this.userService?.roles?.filter((role) =>
        [
          UserRoles.EstateOwnerAdmin,
          UserRoles.EstateOwnerDeviceAppManager,
          UserRoles.EstateOwnerSupportAdmin,
        ].includes(role),
      ).length
    ) {
      USER_TYPE = 'EO';
    }
    if (USER_TYPE === 'EO') {
      if (this.app?.acquirers[0]?.isDefault || this.app.isDefault) {
        this.showAppIcon = true;
      }
    } else if (USER_TYPE === 'MERCHANT') {
      if (this.app?.acquirerApp?.isDefault) {
        this.showAppIcon = true;
      }
    }
    return this.showAppIcon;
  }
}
