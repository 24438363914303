<div class="bulma-steps-component mb-2">
    <ul class="steps has-content-centered">
        <ng-container *ngFor="let step of steps; let index = index;">
            <li class="steps-segment" [ngClass]="{'is-active': step.active}">
                <span class="steps-marker" >
                    <span *ngIf="step.completed" class="icon">
                        <i class="fa fa-check"></i>
                    </span>
                    <span *ngIf="step.active">{{ index + 1}}</span>
                </span>
                <div class="steps-content">
                    <p class="is-size-4">{{ step.text }}</p>
                </div>
            </li>
        </ng-container>
    </ul>
</div>