<div class="box mt-2">
  <div class="field is-grouped is-grouped-multiline filter-container is-relative">
    <div class="dropdown-item">
      <p class="control has-icons-left">
        <input
          [formControl]="searchControl"
          type="text"
          class="input is-small"
          placeholder="Search by feature"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-search" aria-hidden="true"></i>
        </span>
      </p>
    </div>
  </div>
  <div class="box__separator"></div>
  <div [style.max-height.rem]="itemHeight" class="multiselect-box">
    <div class="selected-features">
      <div class="has-text-weight-bold">Selected</div>
      <ng-container *ngFor="let selectedListItem of selectedListItems">
        <div class="selected-list"*ngIf="getParentName(selectedListItem)?.name">
          <ng-container
            *ngTemplateOutlet="itemListWithCheckbox; context: { item: selectedListItem }"
          ></ng-container>
        </div>
      </ng-container>
    </div>
    <div class="box__separator"></div>
  
    <!-- TODO: Convert it to use angular-tree-component and remove material tree -->
    <mat-tree [dataSource]="dataSourceView" [treeControl]="treeControl" class="pt-2">
      <mat-tree-node
        *matTreeNodeDef="let item"
        class="tree-item not-expandable"
        [class.hidden]="!item.visible"
      >
        <ng-container *ngTemplateOutlet="itemWithCheckbox; context: { item: item }"></ng-container>
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let item; when: hasChild" [class.hidden]="!item.visible">
        <div class="tree-item">
          <span
            matTreeNodeToggle
            class="tree-item-toggle"
            [ngClass]="{ 'icon-rotate': treeControl.isExpanded(item) }"
            [attr.aria-label]="'Toggle ' + item.name"
          >
            <i class="fas fa-chevron-down"></i>
          </span>
          <ng-container *ngTemplateOutlet="itemWithCheckbox; context: { item: item }"></ng-container>
        </div>
        <ul class="tree-item-shift-line" [class.hidden]="!treeControl.isExpanded(item)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </mat-nested-tree-node>
    </mat-tree>
  
    <ng-container *ngIf="!dataSourceView.data.length || noFilteredItem()">
      <p class="pt-3 pl-3">
        {{ notFoundMessage }}
      </p>
    </ng-container>
  
    <ng-template #itemWithCheckbox let-item="item">
      <label class="checkbox">
        <input
          [checked]="item.checked"
          [indeterminate]="isPartiallySelected(item)"
          (change)="onNodeCheck(item, checkbox.checked)"
          [disabled]="isDisabled"
          #checkbox
          type="checkbox"
        />
        <span [class.checked]="checkbox.checked"></span>
      </label>
  
      <span>{{ item.name | removeUnderscore | titlecase }}</span>
      <span *ngIf="item.children?.length" class="has-text-grey pl-1">
        ({{ item.children.length }})
      </span>
      <span *ngIf="item.isInheritedFeatures" class="has-text-grey has-text-weight-normal">
        &nbsp; (Inherited)</span
      >
    </ng-template>
  
    <ng-template #itemListWithCheckbox let-item="item">
      <label class="checkbox">
        <input
          [checked]="item.checked"
          [indeterminate]="isPartiallySelected(item)"
          (change)="onNodeCheck(item, checkbox.checked)"
          [disabled]="isDisabled"
          #checkbox
          type="checkbox"
        />
        <span [class.checked]="checkbox.checked">
          <span>
            <span class="pl-2">{{ item.name | removeUnderscore | titlecase }}</span>
            <span
              *ngIf="item.isInheritedFeatures"
              class="has-text-grey has-text-weight-normal"
              i18n
            >
              &nbsp; (Inherited)
            </span>
          </span>
        </span>
        <div class="has-text-grey has-text-weight-normal pl-3">
          {{ getParentName(item)?.name }}
        </div>
      </label>
      <!-- <label class="checkbox">
        <input
          [checked]="item.checked"
          [indeterminate]="isPartiallySelected(item)"
          (change)="onNodeCheck(item, checkbox.checked)"
          [disabled]="isDisabled"
          #checkbox
          type="checkbox"
        />
        <div [class.checked]="checkbox.checked">
          <div>
            <span class="pl-2">{{ item.name | removeUnderscore | titlecase }}</span>
            <span
              *ngIf="item.isInheritedFeatures"
              class="has-text-grey has-text-weight-normal"
              i18n
            >
              &nbsp; (Inherited)
            </span>
          </div>
        </div>
        <div class="has-text-grey has-text-weight-normal pl-2">
          {{ getParentName(item)?.name }}
        </div>
      </label> -->
    </ng-template>
  </div>
  <div class="box__separator"></div>
  
  <div class="dropdown-item">
    {{ getCountSelectedListItems() }} <span i18n>items selected</span>
  
    <button
      type="button"
      class="button is-primary is-pulled-right"
      data-e2e="button-apply"
      i18n
      (click)="onApplyClick()"
      title="Apply"
      i18n-title
    >
      Apply
    </button>
  </div>
</div>

