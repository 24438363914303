<div class="field has-addons mb-1">
  <!-- Country dropdown -->
  <div class="control">
    <div class="dropdown" [class.is-active]="isCountryListOpen">
      <!-- Country list trigger -->
      <div class="dropdown-trigger">
        <button
          [attr.aria-expanded]="isCountryListOpen"
          class="button has-text-dark"
          type="button"
          [class.is-active]="isCountryListOpen"
          (click)="toggleCountryList()"
          title="Phone"
          i18n-title
        >
          <!-- Flag -->
          <svg class="flag-icon">
            <use
              [attr.xlink:href]="
                'assets/images/int-tel-input/flags.svg#flag-icon-' + selectedCountry.alpha2
                  | lowercase
              "
            ></use>
          </svg>

          <!-- /Flag -->

          <!-- Calling code -->
          <span class="country-calling-code ml-2">
            {{ selectedCountry.phoneCode }}
          </span>
          <!-- /Calling code -->

          <!-- Dropdown icon -->
          <span class="icon">
            <i class="fas fa-chevron-down"></i>
          </span>
          <!-- /Dropdown icon -->
        </button>
      </div>
      <!-- Country list trigger -->

      <div
        class="dropdown-menu"
        [ngClass]="[alignOnSmallModal ? 'dropdown-alignment' : '']"
        id="dropdown-menu"
        role="menu"
      >
        <div class="dropdown-content">
          <div class="dropdown-item">
            <p class="control has-icons-left">
              <input
                #searchRef
                (input)="filterCountryCodes(searchRef.value)"
                class="input is-small has-border-radius"
                type="text"
                placeholder="Search for countries"
                i18n-placeholder
                data-e2e="input-searchForCountries"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-search" aria-hidden="true"></i>
              </span>
            </p>
          </div>
          <hr class="dropdown-divider" />
          <div class="dropdown-item is-scrollable">
            <ul>
              <li *ngFor="let countryCode of countryCodesFiltered">
                <button
                  (click)="onCountrySelect($event, countryCode)"
                  class="has-background-white is-borderless width-full has-text-left is-flex is-justify-content-space-between is-align-items-center mb-3 is-clickable"
                >
                  <span class="is-flex is-align-items-center">
                    <svg class="flag-icon">
                      <use
                        [attr.xlink:href]="
                          'assets/images/int-tel-input/flags.svg#flag-icon-' + countryCode.alpha2
                            | lowercase
                        "
                      ></use>
                    </svg>
                    <span
                      class="ml-2"
                      innerHTML="{{ countryCode.name | highlight : searchRef.value }}"
                    >
                    </span>
                  </span>
                  <span
                    class="has-text-weight-semibold phone-code-text has-text-grey-light"
                    innerHTML="{{ countryCode.phoneCode | highlight : searchRef.value }}"
                  >
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Country dropdown -->

  <!-- Phone input -->
  <div class="control is-flex-grow-1">
    <input
      class="input is-size-7-mobile"
      type="tel"
      #phoneInputRef
      (blur)="onTouched()"
      [value]="phoneNumber"
      [placeholder]="placeholder"
      (input)="onPhoneNumberChange(phoneInputRef.value)"
      [ngClass]="{ 'is-danger': isInvalid(formControl) }"
    />
  </div>
  <!-- /Phone input -->
</div>

<portal-form-error [formInput]="formControl" errorId="phoneNumber"></portal-form-error>
