import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { ToastService } from '@portal/shared/ui/toast';

declare const $localize;
@Component({
  selector: 'portal-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
})
export class CopyToClipboardComponent {
  @Input() valueToCopy: string;
  @Input() dataE2eId: string;
  @Input() buttonConfig: {
    text: string;
    class: string;
    iconClass?: string;
  };
  @Input() copyMessage?: string;
  @ViewChild('focusButton', { read: ElementRef }) focusButton: ElementRef<HTMLInputElement>;

  constructor(public toastService: ToastService) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();
    if (!this.valueToCopy) {
      return null;
    }
    navigator.clipboard
      .writeText(this.valueToCopy.toString())
      .then(() => {
        this.toastService.showToast(
          this.copyMessage
            ? this.copyMessage
            : $localize`Successfully copied ${this.valueToCopy} to clipboard!`,
        );
      })
      .catch(() => {});
  }
}
