import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { SortOrder } from '../enums/sort-order.enum';
import { IQueryParams } from '../interfaces/query-params.interface';
import { ITableConfig } from '../interfaces/table-config.interface';
import { ISelection } from '../interfaces/selection';
import { SelectionService } from '../table/providers/selection.service';

declare const $localize;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'marketplace-tree-table',
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.scss'],
  providers: [SelectionService],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'is-block table-container' },
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TreeTableComponent implements OnInit {
  @Input() items: any[];
  @Input() itemsClickable = true;
  @Input() loading$: Observable<boolean>;
  @Input() filter: IQueryParams;

  @Input() tableConfig: ITableConfig;
  @Input() rowLinkHref = '';
  @Input() rowLinkHeading = $localize`Link to item`;
  @Input() loadingMessage = $localize`Loading items`;
  @Input() notFoundMessage = $localize`Items not found`;
  @Input()
  set defaultSelection(val: ISelection[]) {
    this.selectionService.defaultSelection = val;
  }

  @Output() sortChanged: EventEmitter<any> = new EventEmitter();
  @Output() selectedChange = new EventEmitter<any>();

  @ContentChild('lineHeader', { static: true }) lineHeaderTmpl: TemplateRef<any>;

  hasRowLink: boolean;
  colspan: number;
  errorMessage = $localize`Table configuration is missing`;
  expandedElement: any = null;
  get selection(): ISelection[] {
    return this.selectionService.selection;
  }
  get activeSelection(): ISelection[] {
    return this.selectionService.activeSelection;
  }
  constructor(private selectionService: SelectionService) {}

  ngOnInit(): void {
    this.selectionService.tableConfig = this.tableConfig;
    const linkColumnLenght = this.rowLinkHref ? 1 : 0;
    const isSelection = this.tableConfig?.selectionConfig ? 1 : 0;
    this.hasRowLink = !!linkColumnLenght && this.itemsClickable;
    this.colspan = get(this, 'tableConfig.columns.length', 0) + linkColumnLenght + isSelection + 1;

    this.selectionService.onSelectedChanged.subscribe((data: any) => {
      this.selectedChange.emit(data);
    });
  }

  onRowSelected(item, event): void {
    const selection: ISelection = {
      id: item[this.tableConfig.rowIdKey],
      item: item,
      checked: event.target.checked,
    };
    this.selectionService.onRowSelected(selection);
  }

  onRowClicked(event: Event): void {
    const linkCssClass = 'table-row-link';
    const eventTarget: any = event.target;
    if (eventTarget.tagName !== 'DIV') {
      event.stopPropagation();
      return;
    }
    if (this.hasRowLink && !eventTarget.classList.contains(linkCssClass)) {
      const row = eventTarget.closest('tr');
      const link = row.querySelector(`.${linkCssClass}`);
      if (link) {
        link.click();
      }
    }
  }

  onSortChanged(key: string, order: SortOrder): void {
    this.sortChanged.emit({ key, order });
  }

  isSelected(id): boolean {
    return this.selectionService.isSelected(id);
  }
  isDisabled(id: string): boolean {
    return this.selectionService.isDisabled(id);
  }
  selectAll(event): void {
    this.selectionService.selectAll(this.items, event.target.checked);
  }
}
