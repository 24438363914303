import { UserRole } from '@portal/entity-services/interfaces/src/lib/users/enums/user-role.enum';
import { Application } from '@portal/shared/auth/authorization/src/lib/enums/application.enum';
import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';

/*
 * This matrix is introduced to show which user role user should have to be able to access an application
 * It is a compilation of read permissions from all application routes
 * If user doesn't have a role from the list, there is no point in allowing him to open a specific application
 * We need this matrix only untill RMS Phase 2 - features list will be filtered on RMS based on current user roles.
 */
export const applicationRolePermissionMatrix: {
  [key in Application]?: UserRole[] | UserRoles[];
} = {
  [Application.PaymentsPortal]: [
    UserRole.MerchantAdmin,
    UserRole.MerchantSupervisor,
    UserRole.MerchantCashier,
    UserRole.MerchantUser,
    UserRole.MerchantReviewer,
    UserRole.MerchantPOIAdmin,
    UserRole.VerifoneAdmin,
    UserRole.VerifoneUser,
    UserRole.VerifoneFinOpsUser,
    UserRole.VerifoneSettlementUser,
    UserRole.VerifoneSalesManager,
    UserRole.VerifoneSalesRepresentative,
    UserRole.ResellerManager,
    UserRole.ResellerRepresentative,
    UserRole.MerchantUnapproved,
    UserRole.ProviderWhiteLabelManager,
    UserRole.MerchantExternalPartner,
    UserRole.VerifoneMaster,
    UserRole.PetroLoyalty,
    UserRole.PetroMobilePayments,
    UserRole.PetroBackOffice,
    UserRole.PetroFuelPriceSign,
    UserRole.PetroDvr,
    UserRole.PetroPropietaryCard,
    UserRole.PetroCommanderEF,
    UserRole.PetroInternal,
    UserRole.PetroPartnerAdmin,
  ],
  [Application.Marketplace]: [
    UserRole.MerchantAdmin,
    UserRole.MerchantUser,
    UserRole.DeveloperAdmin,
    UserRole.DeveloperUser,
    UserRole.EstateOwnerAdmin,
    UserRole.EstateOwnerDeviceAppManager,
    UserRole.EstateOwnerSupportAdmin,
    UserRole.EstateOwnerUser,
    UserRole.VerifoneAdmin,
    UserRole.VerifoneUser,
  ],
  [Application.MOP]: [
    UserRole.VerifoneSalesManager,
    UserRole.VerifoneSalesRepresentative,
    UserRole.ResellerManager,
    UserRole.ResellerRepresentative,
    UserRole.MerchantUnapproved,
    UserRole.MerchantOrderAdmin,
  ],
  [Application.Petro]: [
    UserRoles.PetroSiteAdmin,
    UserRoles.PetroSiteUser,
    UserRoles.MajorOilAdmin,
    UserRoles.MajorOilUser,
    UserRoles.PetroPartnerAdmin,
    UserRoles.PetroHelpDeskAdmin,
  ],
  [Application.VHQ]: [
    UserRoles.VerifoneAdmin,
    UserRoles.VerifoneHelpDesk,
    UserRoles.ResellerManager,
    UserRoles.MerchantAdmin,
    UserRoles.MerchantSupervisor,
    UserRoles.EstateOwnerAdmin,
    UserRoles.MerchantUser,
    UserRoles.PartnerFieldServiceProvider,
    UserRoles.VerifoneFieldServiceProvider,
    UserRoles.PartnerHelpdesk,
    UserRoles.VerifoneFieldServiceSupervisor,
    UserRoles.PartnerFieldServiceSupervisor,
    UserRoles.PartnerFieldServiceUser,
  ],
};
