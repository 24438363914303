/* eslint-disable @typescript-eslint/naming-convention */
export const ROLES_CONFIG = {
  VERIFONE_ADMIN: {
    'Device Details': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Shipment Receipt': {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Chain Of Custody': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Inspection: {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    Locations: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Tenants: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Devices: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Activity Cycle': {
      View: true,
    },
    'Tracking Report': {
      View: true,
    },
    Rollouts: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Hierarchies: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Groups: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Alerts: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Software Assignment': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Reference Sets': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Software Library': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Deleted Devices': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Export: {
      Add: true,
      Edit: false,
      Delete: false,
      View: true,
    },
    Import: {
      Add: true,
      Edit: false,
      Delete: false,
      View: true,
    },
    Content: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Diagnostic Actions': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Device Status': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Move: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Download Schedule': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Delete: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Swap: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    AdminPassCode: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
  },
  VERIFONE_HELPDESK: {
    'Device Details': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Shipment Receipt': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Locations: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Tenants: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
    Devices: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Activity Cycle': {
      View: true,
    },
    'Tracking Report': {
      View: true,
    },
    Rollouts: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Groups: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Alerts: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Software Assignment': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Reference Sets': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Software Library': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Deleted Devices': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Export: {
      Add: true,
      Edit: false,
      Delete: false,
      View: true,
    },
    Import: {
      Add: true,
      Edit: false,
      Delete: false,
      View: true,
    },
    Content: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Diagnostic Actions': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Device Status': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Move: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Download Schedule': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Delete: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Swap: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    AdminPassCode: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
  },
  RESELLER_MANAGER: {
    'Device Details': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Shipment Receipt': {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Chain Of Custody': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Inspection: {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Activity Cycle': {
      View: true,
    },
    'Tracking Report': {
      View: true,
    },
    Rollouts: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Software Assignment': {
      Add: true,
      Edit: false,
      Delete: true,
      View: true,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Export: {
      Add: true,
      Edit: false,
      Delete: false,
      View: true,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Diagnostic Actions': {
      Add: true,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Device Status': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Move: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Download Schedule': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Delete: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Swap: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    AdminPassCode: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
  },
  MERCHANT_ADMIN: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Shipment Receipt': {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Chain Of Custody': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Inspection: {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Activity Cycle': {
      View: true,
    },
    'Tracking Report': {
      View: true,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    Export: {
      Add: true,
      Edit: false,
      Delete: false,
      View: true,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Device Status': {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Move: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Delete: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  MERCHANT_SUPERVISOR: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Shipment Receipt': {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Chain Of Custody': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Inspection: {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Activity Cycle': {
      View: true,
    },
    'Tracking Report': {
      View: true,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    Export: {
      Add: true,
      Edit: false,
      Delete: false,
      View: true,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Device Status': {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Move: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Delete: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  MERCHANT_USER: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Shipment Receipt': {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Chain Of Custody': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Inspection: {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Devices: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Activity Cycle': {
      View: true,
    },
    'Tracking Report': {
      View: true,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Export: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Device Status': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Move: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Delete: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  PARTNER_HELPDESK: {
    'Device Details': {
      Add: false,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Shipment Receipt': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Locations: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Devices: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Activity Cycle': {
      View: true,
    },
    'Tracking Report': {
      View: true,
    },
    Rollouts: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Hierarchies: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Groups: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Alerts: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Software Assignment': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Reference Sets': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Software Library': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Deleted Devices': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Export: {
      Add: true,
      Edit: false,
      Delete: false,
      View: true,
    },
    Import: {
      Add: true,
      Edit: false,
      Delete: false,
      View: true,
    },
    Content: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Diagnostic Actions': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Device Status': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Move: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Download Schedule': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Delete: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Swap: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
    AdminPassCode: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
  },
  VERIFONE_FIELD_SERVICE_PROVIDER: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Shipment Receipt': {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Activity Cycle': {
      View: true,
    },
    'Tracking Report': {
      View: true,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Export: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Device Status': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Move: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Delete: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  PARTNER_FIELD_SERVICE_PROVIDER: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Shipment Receipt': {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Activity Cycle': {
      View: true,
    },
    'Tracking Report': {
      View: true,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Export: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Device Status': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Move: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Delete: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  ESTATE_OWNER_ADMIN: {
    'Device Details': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Shipment Receipt': {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    'Chain Of Custody': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Inspection: {
      Add: true,
      Edit: true,
      Delete: false,
      View: true,
    },
    Locations: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Tenants: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Devices: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Activity Cycle': {
      View: true,
    },
    'Tracking Report': {
      View: true,
    },
    Rollouts: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Hierarchies: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Groups: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Alerts: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Software Assignment': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Reference Sets': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Software Library': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Deleted Devices': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Export: {
      Add: true,
      Edit: false,
      Delete: false,
      View: true,
    },
    Import: {
      Add: true,
      Edit: false,
      Delete: false,
      View: true,
    },
    Content: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Diagnostic Actions': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Device Status': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: true,
    },
    Move: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    'Download Schedule': {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Delete: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    Swap: {
      Add: true,
      Edit: true,
      Delete: true,
      View: true,
    },
    AdminPassCode: {
      Add: false,
      Edit: true,
      Delete: false,
      View: true,
    },
  },
  FIELD_SERVICE_USER: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Shipment Receipt': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Activity Cycle': {
      View: false,
    },
    'Tracking Report': {
      View: false,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Export: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Device Status': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Move: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Delete: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  MERCHANT_CASHIER: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Shipment Receipt': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Activity Cycle': {
      View: false,
    },
    'Tracking Report': {
      View: false,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Export: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Device Status': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Move: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Delete: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  ESTATE_OWNER_DEVICE_APP_MANAGER: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Shipment Receipt': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Activity Cycle': {
      View: false,
    },
    'Tracking Report': {
      View: false,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Export: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Device Status': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Move: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Delete: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  VERIFONE_SALES_MANAGER: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Shipment Receipt': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Activity Cycle': {
      View: false,
    },
    'Tracking Report': {
      View: false,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Export: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Device Status': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Move: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Delete: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  VERIFONE_SALES_REPRESENTATIVE: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Shipment Receipt': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Activity Cycle': {
      View: false,
    },
    'Tracking Report': {
      View: false,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Export: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Device Status': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Move: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Delete: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  VERIFONE_USER: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Shipment Receipt': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Activity Cycle': {
      View: false,
    },
    'Tracking Report': {
      View: false,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Export: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Device Status': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Move: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Delete: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  DEVELOPER_ADMIN: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Shipment Receipt': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Activity Cycle': {
      View: false,
    },
    'Tracking Report': {
      View: false,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Export: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Device Status': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Move: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Delete: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  DEVELOPER_USER: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Shipment Receipt': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Activity Cycle': {
      View: false,
    },
    'Tracking Report': {
      View: false,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Export: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Device Status': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Move: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Delete: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
  RESELLER_REPRESENTATIVE: {
    'Device Details': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Shipment Receipt': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Chain Of Custody': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Inspection: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Locations: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Tenants: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Devices: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Activity Cycle': {
      View: false,
    },
    'Tracking Report': {
      View: false,
    },
    Rollouts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Hierarchies: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Groups: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Alerts: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Assignment': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Reference Sets': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Software Library': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Deleted Devices': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Export: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Import: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Content: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Diagnostic Actions': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Device Status': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Audit Log': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Move: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    'Download Schedule': {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Delete: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    Swap: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
    AdminPassCode: {
      Add: false,
      Edit: false,
      Delete: false,
      View: false,
    },
  },
};
