import { Injectable } from '@angular/core';
import { UisAuthenticationErrors } from '../enums/uis-authentication-errors.enum';

declare const $localize;

@Injectable()
export class UisAuthenticationErrorService {
  errorsMap = {
    [UisAuthenticationErrors.NoSuchAttribute]: $localize`The current password is incorrect. Please verify the data and try again.`,
    [UisAuthenticationErrors.ChangedPasswordConstraintViolation]: $localize`The password you have entered matches one of your last 10 passwords. Please choose a new one.`,
    [UisAuthenticationErrors.ConstraintViolation]: $localize`The password you have entered matches one of your last 10 passwords. Please choose a new one.`,
    [UisAuthenticationErrors.LinkExpired]: $localize`Your reset password link is expired. Please request a new link.`,
    [UisAuthenticationErrors.NameOrPasswordIncorrect]: $localize`The email or password you have entered is invalid.`,
    [UisAuthenticationErrors.Unexpected]: $localize`Unexpected error happened. There can be technical issues on our end or related to your system. Please try again shortly.`,
    [UisAuthenticationErrors.UserDisabled]: $localize`User is disabled, please contact support.`,
    [UisAuthenticationErrors.Other]: $localize`The password cannot be saved due to various reasons. These can be technical issues on our end or related to your system. Please try again shortly.`,
    [UisAuthenticationErrors.UserLockedOut]: $localize`You have been locked out of your account, please try to login again after some time.`,
    [UisAuthenticationErrors.PasswordExpired]: $localize`Your password is expired.`,
  };

  /**
   * If no errors are found by the given errorCode
   * return Other error message
   * @param errorCode
   */
  getErrorMessage(errorCode: UisAuthenticationErrors, useAlternateMessage?: boolean): string {
    if (errorCode === UisAuthenticationErrors.NameOrPasswordIncorrect && useAlternateMessage) {
      return $localize`The current password is incorrect.`;
    }
    return this.errorsMap[errorCode] || this.errorsMap[UisAuthenticationErrors.Other];
  }
}
