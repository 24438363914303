import { Direction } from '../enums/direction.enum';
import { Locale } from '../enums/locale.enum';
import { ILanguage } from '../interfaces/language';

export const LANGUAGES: ILanguage[] = [
  {
    id: Locale.En,
    name: 'English',
    direction: Direction.LTR,
    shortname: Locale.En,
    text: 'English',
  },
  {
    id: Locale.EnUs,
    name: 'English',
    direction: Direction.LTR,
    shortname: Locale.EnUs,
    text: 'English',
  },
  {
    id: Locale.Fi,
    name: 'Finnish',
    direction: Direction.LTR,
    shortname: Locale.Fi,
    text: 'Suomen kieli',
  },
  {
    id: Locale.FiFI,
    name: 'Finnish',
    direction: Direction.LTR,
    shortname: Locale.FiFI,
    text: 'Suomen kieli',
  },
  {
    id: Locale.Fr,
    name: 'French',
    direction: Direction.LTR,
    shortname: Locale.Fr,
    text: 'Français',
  },
  {
    id: Locale.FrFr,
    name: 'French',
    direction: Direction.LTR,
    shortname: Locale.FrFr,
    text: 'Français',
  },
  {
    id: Locale.FrCa,
    name: 'French (Canada)',
    direction: Direction.LTR,
    shortname: Locale.FrCa,
    text: 'Français (Canada)',
  },
  {
    id: Locale.He,
    name: 'Hebrew',
    direction: Direction.RTL,
    shortname: Locale.He,
    text: 'עברית',
  },
  {
    id: Locale.Pt,
    name: 'Portuguese',
    direction: Direction.LTR,
    shortname: Locale.Pt,
    text: 'Português',
  },
  {
    id: Locale.PtPt,
    name: 'Portuguese',
    direction: Direction.LTR,
    shortname: Locale.PtPt,
    text: 'Português',
  },
  {
    id: Locale.Da,
    name: 'Danish',
    direction: Direction.LTR,
    shortname: Locale.Da,
    text: 'Dansk',
  },
  {
    id: Locale.DaDk,
    name: 'Danish',
    direction: Direction.LTR,
    shortname: Locale.DaDk,
    text: 'Dansk',
  },
  {
    id: Locale.Sv,
    name: 'Swedish',
    direction: Direction.LTR,
    shortname: Locale.Sv,
    text: 'Svenskan',
  },
  {
    id: Locale.SvSE,
    name: 'Swedish',
    direction: Direction.LTR,
    shortname: Locale.SvSE,
    text: 'Svenskan',
  },
  {
    id: Locale.Pl,
    name: 'Polish',
    direction: Direction.LTR,
    shortname: Locale.Pl,
    text: 'Polski',
  },
  {
    id: Locale.PlPl,
    name: 'Polish',
    direction: Direction.LTR,
    shortname: Locale.PlPl,
    text: 'Polski',
  },
  {
    id: Locale.No,
    name: 'Norwegian',
    direction: Direction.LTR,
    shortname: Locale.No,
    text: 'Norsk',
  },
  {
    id: Locale.NoNO,
    name: 'Norwegian',
    direction: Direction.LTR,
    shortname: Locale.NoNO,
    text: 'Norsk',
  },
  {
    id: Locale.Lv,
    name: 'Latvian',
    direction: Direction.LTR,
    shortname: Locale.Lv,
    text: 'Latviešu',
  },
  {
    id: Locale.LvLV,
    name: 'Latvian',
    direction: Direction.LTR,
    shortname: Locale.LvLV,
    text: 'Latviešu',
  },
  {
    id: Locale.Lt,
    name: 'Lithuanian',
    direction: Direction.LTR,
    shortname: Locale.Lt,
    text: 'Lietùvių',
  },
  {
    id: Locale.LtLT,
    name: 'Lithuanian',
    direction: Direction.LTR,
    shortname: Locale.LtLT,
    text: 'Lietùvių',
  },
  {
    id: Locale.Ar,
    name: 'Arabic',
    direction: Direction.RTL,
    shortname: Locale.Ar,
    text: 'اللغة العربية',
  },
  {
    id: Locale.Ru,
    name: 'Russian',
    direction: Direction.LTR,
    shortname: Locale.Ru,
    text: 'Русский',
  },
  {
    id: Locale.Et,
    name: 'Estonian',
    direction: Direction.LTR,
    shortname: Locale.Et,
    text: 'Eesti keel',
  },
  {
    id: Locale.EtEE,
    name: 'Estonian',
    direction: Direction.LTR,
    shortname: Locale.EtEE,
    text: 'Eesti keel',
  },
  {
    id: Locale.De,
    name: 'German',
    direction: Direction.LTR,
    shortname: Locale.De,
    text: 'Deutsch',
  },
  {
    id: Locale.DeDe,
    name: 'German',
    direction: Direction.LTR,
    shortname: Locale.DeDe,
    text: 'Deutsch',
  },
  {
    id: Locale.It,
    name: 'Italian',
    direction: Direction.LTR,
    shortname: Locale.It,
    text: 'Italiana',
  },
  {
    id: Locale.ItIt,
    name: 'Italian',
    direction: Direction.LTR,
    shortname: Locale.ItIt,
    text: 'Italiana',
  },
  {
    id: Locale.Is,
    name: 'Icelandic',
    direction: Direction.LTR,
    shortname: Locale.Is,
    text: 'Íslensk',
  },
  {
    id: Locale.IsIS,
    name: 'Icelandic',
    direction: Direction.LTR,
    shortname: Locale.IsIS,
    text: 'Íslensk',
  },
  {
    id: Locale.Es,
    name: 'Spanish',
    direction: Direction.LTR,
    shortname: Locale.Es,
    text: 'Español',
  },
  {
    id: Locale.EsEs,
    name: 'Spanish',
    direction: Direction.LTR,
    shortname: Locale.EsEs,
    text: 'Español',
  },
  {
    id: Locale.EsMx,
    name: 'Spanish (Mexico)',
    direction: Direction.LTR,
    shortname: Locale.EsMx,
    text: 'Español (México)',
  },
  {
    id: Locale.Nl,
    name: 'Dutch',
    direction: Direction.LTR,
    shortname: Locale.Nl,
    text: 'Nederlands',
  },
  {
    id: Locale.NlNl,
    name: 'Dutch',
    direction: Direction.LTR,
    shortname: Locale.NlNl,
    text: 'Nederlands',
  },
  {
    id: Locale.NlBe,
    name: 'Dutch (Belgium)',
    direction: Direction.LTR,
    shortname: Locale.NlBe,
    text: 'Nederlands (België)',
  },
  {
    id: Locale.ElGr,
    name: 'Greek',
    direction: Direction.LTR,
    shortname: Locale.ElGr,
    text: 'Ελληνικά',
  },
  {
    id: Locale.El,
    name: 'Greek',
    direction: Direction.LTR,
    shortname: Locale.El,
    text: 'Ελληνικά',
  },
];
