import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src/lib/create-list-from-inputs/select-input-list';
import { ISelectInput } from '@portal/shared/ui/filter/src/lib/box/interfaces/select-input.interface';
import { OpeningHoursDay } from '../enums/opening-hours-day.enum';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class OpeningHoursDays extends SelectInputsList {
  keyValue = {
    [OpeningHoursDay.Monday]: $localize`Monday`,
    [OpeningHoursDay.Tuesday]: $localize`Tuesday`,
    [OpeningHoursDay.Wednesday]: $localize`Wednesday`,
    [OpeningHoursDay.Thursday]: $localize`Thursday`,
    [OpeningHoursDay.Friday]: $localize`Friday`,
    [OpeningHoursDay.Saturday]: $localize`Saturday`,
    [OpeningHoursDay.Sunday]: $localize`Sunday`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
