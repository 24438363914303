import { Injectable } from '@angular/core';
import { Application, APPLICATION_PATH, UserRoles } from '@portal/shared/auth/authorization/src';
import { UserService } from '@portal/shared/user/src';

@Injectable({
  providedIn: 'root',
})
export class RedirectToMarketplaceService {
  constructor(private userService: UserService) {}
  redirectToMarketplace(): void {
    // this redirects should correspond to nginx configuration
    // if you are going to change it - change in both places
    if (
      this.userService.roles.includes(UserRoles.MerchantAdmin) ||
      this.userService.roles.includes(UserRoles.MerchantUser)
    ) {
      location.assign(`/${APPLICATION_PATH[Application.Marketplace]}`);
    }
    if (
      this.userService.roles.includes(UserRoles.EstateOwnerAdmin) ||
      this.userService.roles.includes(UserRoles.EstateOwnerDeviceAppManager) ||
      this.userService.roles.includes(UserRoles.EstateOwnerSupportAdmin) ||
      this.userService.roles.includes(UserRoles.EstateOwnerUser)
    ) {
      location.assign(`/${APPLICATION_PATH[Application.EstateOwner]}`);
    }

    if (
      this.userService.roles.includes(UserRoles.VerifoneAdmin) ||
      this.userService.roles.includes(UserRoles.VerifoneUser)
    ) {
      location.assign(`/${APPLICATION_PATH[Application.MarketplaceManager]}`);
    }
    if (
      this.userService.roles.includes(UserRoles.DeveloperAdmin) ||
      this.userService.roles.includes(UserRoles.DeveloperUser)
    ) {
      location.assign(`/${APPLICATION_PATH[Application.Developer]}`);
    }
  }
}
