import { Injectable } from '@angular/core';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src/lib/feature-toggle.service';
import { Features } from 'environments/enums/features.enum';
import { TranslationsKeyValue } from './interfaces/translations-key-value.interface';

export type FeatureDisabledKeys = Partial<Record<Features, string[]>>;

@Injectable({ providedIn: 'root' })
export class SelectInputKeysFilter {
  constructor(private featureToggleService: FeatureToggle) {}

  filterByFeature(
    keyValue: TranslationsKeyValue,
    featureDisabledKeys: FeatureDisabledKeys,
  ): TranslationsKeyValue {
    const disabledKeys = new Set<string>();
    Object.entries(featureDisabledKeys).forEach(([featureName, keys]) => {
      if (!this.featureToggleService.isFeatureActive(<Features>featureName)) {
        keys.forEach((key) => disabledKeys.add(key));
      }
    });

    const availableKeyValue: TranslationsKeyValue = {};
    Object.entries(keyValue)
      .filter(([key]) => !disabledKeys.has(key))
      .forEach(([key, value]) => (availableKeyValue[key] = value));

    return availableKeyValue;
  }
}
