import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

// This need for jest
type DragEventType = DragEvent;

@Directive({ selector: '[portalDragAndDrop]' })
export class DragAndDropDirective {
  @Output() fileDropped = new EventEmitter<FileList>();

  @HostBinding('class.file-over') fileOver: boolean;

  @HostListener('dragover', ['$event']) onDragOver(event: DragEventType): void {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEventType): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.fileOver = false;
  }

  @HostListener('dragstart', ['$event']) onDragStart(event: DragEventType): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event: DragEventType): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEventType): void {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;

    this.fileOver = false;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
