import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IOrganisation } from '@portal/entity-services/interfaces/src/lib/organisations/interfaces/organisation.interface';
import { TemplateParameters } from '@portal/entity-services/interfaces/src/lib/organisations/interfaces/reseller.interface';

@Injectable()
export class FormSharedService {
  whiteLabelPreviewFormValues$: Observable<TemplateParameters>;
  organisationFormValues$: Observable<IOrganisation>;

  private whiteLabelPreviewFormValuesSubject: BehaviorSubject<TemplateParameters> =
    new BehaviorSubject(null);
  private organisationFormValuesSubject: BehaviorSubject<IOrganisation> = new BehaviorSubject(null);

  constructor() {
    this.whiteLabelPreviewFormValues$ = this.whiteLabelPreviewFormValuesSubject.asObservable();
    this.organisationFormValues$ = this.organisationFormValuesSubject.asObservable();
  }

  setWhiteLabelFormValueState(value: TemplateParameters): void {
    this.whiteLabelPreviewFormValuesSubject.next(value);
  }

  setOrgFormValueState(value: IOrganisation): void {
    this.organisationFormValuesSubject.next(value);
  }
}
