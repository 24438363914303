import { Injectable } from '@angular/core';
import { QueryParams } from '@ngrx/data';
import { IUser } from '@portal/entity-services/interfaces';
import { IQueryParams, IResultsWithCount, QueryParamsService } from '@portal/shared/ui/table';
import { ICount } from '@portal/shared/vui-http';
import omit from 'lodash-es/omit';
import assign from 'lodash-es/assign';
import { BehaviorSubject, forkJoin, from, Observable, Subject } from 'rxjs';
import { finalize, map, mergeMap, reduce, switchMap } from 'rxjs/operators';
import { CustomUserDataService } from './user-data.service';
import { UserService as SharedUserService } from '@portal/shared/user/src/lib/user.service';
import { ICheckEmail } from '@portal/shared/vui-http/src/lib/interfaces/check-email.interface';
const MAX_API_USERS_COUNT = 320;
// ToDo: https://jira.verifone.com/browse/SGC-55482
import { IUserAppMarketUserSettingsResponse } from '@portal/marketplace/api-interfaces/src/lib/user/IUser';
import { UserAppmarketSettings } from '@portal/marketplace/api-interfaces/src/lib/user/user-appmarket-settings.enum';

@Injectable()
export class UserService {
  entities$ = new Subject<IUser[]>();
  listLoading$: Observable<boolean>;
  detailLoading$: Observable<boolean>;
  filter$: Observable<{ filter: IQueryParams }>;

  private setLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private setFilter$: BehaviorSubject<{ filter: IQueryParams }> = new BehaviorSubject(null);

  constructor(
    public customDataService: CustomUserDataService,
    private sharedUserService: SharedUserService,
  ) {
    this.listLoading$ = this.setLoading$.asObservable();
    this.detailLoading$ = this.sharedUserService.loading$;
    this.filter$ = this.setFilter$.asObservable();
  }

  setLoading(state: boolean): void {
    this.setLoading$.next(state);
  }

  getAll(): Observable<IUser[]> {
    return this.customDataService.getWithQuery({
      limit: String(MAX_API_USERS_COUNT),
    });
  }

  getUsersByEntity(entityUid: string): Observable<IUser[]> {
    return this.sharedUserService.getUsersByEntity(entityUid);
  }

  getAllWithoutLimit(): Observable<IUser[]> {
    return this.getTotalCount().pipe(
      switchMap((usersData) => {
        const piecesCount = Math.ceil(usersData.count / MAX_API_USERS_COUNT);
        return from([...Array(piecesCount).keys()]);
      }),
      mergeMap((index: number) =>
        this.customDataService.getWithQuery({
          start: String(index * MAX_API_USERS_COUNT),
          limit: String(MAX_API_USERS_COUNT),
        }),
      ),
      reduce((users, piece) => users.concat(piece), []),
    );
  }

  getByKey(id: string): Observable<IUser> {
    return this.sharedUserService.getByKey(id);
  }

  getTotalCount(params?: QueryParams): Observable<ICount> {
    return this.customDataService.getTotalCount(params);
  }

  getAppMarketUserSettings(): Observable<{ data: IUserAppMarketUserSettingsResponse[] }> {
    return this.sharedUserService.getAppMarketUserSettings();
  }

  setAppMarketUserSettings(
    identifier: UserAppmarketSettings,
    value: string | boolean,
  ): Observable<{ data: IUserAppMarketUserSettingsResponse[] }> {
    return this.sharedUserService.setAppMarketUserSettings(identifier, value);
  }

  checkEmailExist(params?: QueryParams): Observable<ICheckEmail> {
    return this.sharedUserService.checkEmailExist(params);
  }

  delete(userId: string, reasonForDeletion?: string): Observable<any> {
    return this.sharedUserService.delete(userId, reasonForDeletion);
  }

  update(user: IUser): Observable<any> {
    this.setLoading(true);
    return this.sharedUserService.update(user);
  }

  partialUpdate(partialUser: Partial<IUser>): Observable<IUser> {
    return this.sharedUserService.partialUpdate(partialUser);
  }

  getFilteredResultsWithCount(params: IQueryParams): Observable<IResultsWithCount<IUser>> {
    const query = QueryParamsService.toQueryParams(params);
    const filterParams = QueryParamsService.getFilterParams(params.searchCriteria);
    assign(query, filterParams);
    const users$: Observable<IUser[]> = this.customDataService.getWithQuery(
      omit(query, ['searchCriteria']),
    );
    const userCount$: Observable<ICount> = this.getTotalCount(omit(query, ['searchCriteria']));
    this.setLoading(true);

    return forkJoin([users$, userCount$]).pipe(
      map((results) => {
        return {
          results: results[0],
          count: results[1].count,
        };
      }),
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  setFilter(value: { filter: IQueryParams }): void {
    this.setFilter$.next(value);
  }
}
