import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@portal/shared/auth/authentication/src/lib/authentication.service';
import { Observable } from 'rxjs';
import { CustomHttpParams } from '@apps/vhq/src/app/modules/deviceprofile/services/custom-http-params';
import { WhiteLabelingService } from '@portal/shared/white-labeling/src/lib/services/white-labeling.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private whiteLabelingService: WhiteLabelingService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const whiteLabelingBasePath = this.whiteLabelingService.getBasePath();
    if (whiteLabelingBasePath && req.url.includes(whiteLabelingBasePath)) {
      return next.handle(req);
    }

    let eoEntityUid: string;
    let entityId: string;

    if (localStorage.getItem('organisationData')) {
      const organisation = JSON.parse(localStorage.getItem('organisationData'));
      if (organisation.entityType === 'ESTATE_OWNER') {
        eoEntityUid = this.authService.entityId;
        entityId = this.authService.entityId;
      } else {
        eoEntityUid = this.authService.eoEntityUid;
        entityId = this.authService.entityId;
      }
    }

    let customerUniqueId: any = null;
    if (localStorage.getItem('organisationData')) {
      const orgData = localStorage.getItem('organisationData');
      JSON.parse(orgData)['businessIdentifiers'].forEach((item) => {
        if (item['identifierType'] === 'VHQ_CUSTOMER_UNIQUEID') {
          customerUniqueId = item.value;
        }
      });
    }

    const token = JSON.parse(localStorage.getItem('access_token'));
    if (!token) {
      return next.handle(req);
    }
    if (
      req.url &&
      // Only intercept non-authentication request as
      // they use specific headers that should not be modified
      !req.url.includes('access_token')
    ) {
      if (this.authService.hasTokenExpired()) {
        const redirectPath = localStorage.getItem('redirect_path');
        this.authService.logout({ redirectPath: redirectPath || '' });
        return next.handle(req);
      }

      // Clone the request and replace the original headers with modified ones
      let modifiedReq;
      if (req.body && req.body instanceof FormData && !(req.params instanceof CustomHttpParams)) {
        modifiedReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`),
        });
      } else {
        if (req.params instanceof CustomHttpParams) {
          const headers: HttpHeaders = req.params.param.headers;
          if(req.params?.param?.responseType === 'text') {
            modifiedReq = req.clone({
              headers: req.headers
                .set('IDP', headers.getAll('IDP'))
                .set('Authorization', `Bearer ${token}`)
                .set('id-token', JSON.parse(localStorage.getItem('id_token')))
                .set('Access-Control-Allow-Origin', '*')
                .set(
                  'eoEntityUid',
                  eoEntityUid === undefined || eoEntityUid === null ? '' : eoEntityUid,
                )
                .set('entityId', entityId === undefined || entityId === null ? '' : entityId)
                .set(
                  'CustomerUniqueId',
                  customerUniqueId === undefined || customerUniqueId === null ? '' : customerUniqueId,
                )
            });
            modifiedReq.responseType = 'text';
          } else {
            modifiedReq = req.clone({
              headers: req.headers
                .set(
                  'CustomerName',
                  headers.getAll('CustomerName') ? headers.getAll('CustomerName') : '',
                )
                .set('IDP', headers.getAll('IDP'))
                .set('Authorization', `Bearer ${token}`)
                .set('id-token', JSON.parse(localStorage.getItem('id_token')))
                .set('Access-Control-Allow-Origin', '*')
                .set(
                  'eoEntityUid',
                  eoEntityUid === undefined || eoEntityUid === null ? '' : eoEntityUid,
                )
                .set('entityId', entityId === undefined || entityId === null ? '' : entityId)
                .set(
                  'CustomerUniqueId',
                  customerUniqueId === undefined || customerUniqueId === null ? '' : customerUniqueId,
                )
            });
          }
        } else {
          modifiedReq = req.clone({
            headers: req.headers
              .set('Content-type', 'application/json') // this header breaks image loading
              .set(
                'Authorization',
                req.headers?.get('Authorization')
                  ? req.headers?.get('Authorization')
                  : `Bearer ${token}`,
              )
              .set(
                'CustomerUniqueId',
                customerUniqueId === undefined || customerUniqueId === null ? '' : customerUniqueId,
              )
          });
        }
      }
      // send cloned request with header to the next handler.z
      return next.handle(modifiedReq);
    }
    return next.handle(req);
  }
}
