import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { Status } from '../enum/status.enum';

declare const $localize;
@Injectable()
export class ReportStatusTypes extends SelectInputsList {
  keyValue = {
    [Status.Enabled]: $localize`:report scheduler status|:Enabled`,
    [Status.Disabled]: $localize`:report scheduler status|:Disabled`,
  };
  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
