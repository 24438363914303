import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  AddressType,
  IOrganisation,
  IPoiCutoverConfiguration,
  IReceiptContractCreatePayload,
  IReceiptProvider,
  PoiCutoverStrategy,
} from '@portal/entity-services/interfaces';
import { CountryService, ICountry } from '@portal/shared/helpers';
import { ToastService } from '@portal/shared/ui/toast';
import { OrganisationService } from '../../services/organisation.service';
import isEmpty from 'lodash-es/isEmpty';
import differenceBy from 'lodash-es/differenceBy';
import { AuthorizationService } from '@portal/shared/auth/authorization/src';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY, forkJoin, NEVER, of } from 'rxjs';
import { PaymentDocumentType } from '../../enums/payment-document-type.enum';
import { NotifierService } from '@portal/shared/ui/notifier';
import { PointInteractionService } from '@portal/entity-services/points-of-interaction/src/lib/services/point-interaction.service';
import { ReportEngineService } from '@apps/portal/src/app/modules/report-engine/services/report-engine.service';
import { ReceiptTemplateType } from '../../enums/receipt-template-type.enum';
import { ReceiptNotificationType } from '../../enums/receipt-notification-type.enum';
import { ReceiptAttachmentType } from '@portal/entity-services/interfaces/src/lib/organisations/enums/receipt-attachment-type.enum';
import { ReceiptProvider } from '../../enums/receipt-provider.enum';

declare const $localize;

@UntilDestroy()
@Component({
  selector: 'portal-create-organisation',
  templateUrl: './create.component.html',
})
export class CreateOrganisationComponent implements OnInit {
  countries: ICountry[];
  saving = false;
  isInvoice4uProviderCreated = false;
  isVerifoneReceiptProviderCreated = false;
  poiCutoverConfigurations: IPoiCutoverConfiguration[] = [];

  constructor(
    private organisationService: OrganisationService,
    private countryService: CountryService,
    private router: Router,
    private toastService: ToastService,
    private title: Title,
    private liveAnnouncer: LiveAnnouncer,
    private authorizationService: AuthorizationService,
    private notifierService: NotifierService,
    private poiService: PointInteractionService,
    private reportEngineService: ReportEngineService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle($localize`Add new organisation`);
    this.liveAnnouncer.announce($localize`Navigated to ${this.title.getTitle()}`);

    this.countries = this.countryService.countries;
    this.organisationService.getReceiptProvider(ReceiptProvider.Invoice4U).subscribe((provider) => {
      if (provider) {
        this.isInvoice4uProviderCreated = true;
      }
    });
    this.organisationService
      .getReceiptProvider(ReceiptProvider.Verifone)
      .pipe(untilDestroyed(this))
      .subscribe((provider) => {
        if (provider) {
          this.isVerifoneReceiptProviderCreated = true;
        }
      });
    this.poiService
      .getPoiCutoverConfigurations(PoiCutoverStrategy.FlexibleBatch)
      .pipe(
        catchError(() => {
          return of([]);
        }),
      )
      .subscribe((response) => {
        this.poiCutoverConfigurations = response;
      });
  }

  onFormValidated(formValue: IOrganisation): void {
    const formData = this.formValueToRequestData(formValue);

    formData.addresses = formData.addresses.filter((item) => item.addressType);
    const billingAddress = formData.addresses.find(
      (address) => address.addressType === AddressType.Billing,
    );

    // no longer timzeone will be required, it will be generated from the entity service.
    delete formData.locale.timezoneId;

    formData.locale.countryCode = billingAddress?.country;
    if (!formData.locale.countryCode) {
      delete formData.locale;
    }

    formData.businessIdentifiers = formData.businessIdentifiers.filter((item) => {
      return !isEmpty(item.value);
    });

    if (
      formData.isTradingAddressSectionDisplayed &&
      !formData.isTradingAddressDisplayed &&
      formData.isBillingAddressDisplayed
    ) {
      formData.addresses.push({ ...billingAddress, ...{ addressType: AddressType.Trading } });
    }

    if (
      formData.isShippingAddressSectionDisplayed &&
      !formData.isShippingAddressDisplayed &&
      formData.isBillingAddressDisplayed
    ) {
      formData.addresses.push({ ...billingAddress, ...{ addressType: AddressType.Delivery } });
    }

    if (!this.organisationService.isPoiCrossEntityAccessEditable(formData.entityType)) {
      delete formData.poiCrossEntityAccessAllowed;
    }

    const featuresToAdd = differenceBy(formData?.features, formData?.initialFeatures, 'featureId');

    const receiptContractCreationParams: IReceiptContractCreatePayload = {
      provider: formData.receiptProvider,
      apiToken: formData.apiToken ?? '',
      allowChildReuse: this.organisationService.allowChildReuse,
    };

    if (receiptContractCreationParams.provider === ReceiptProvider.Verifone) {
      receiptContractCreationParams.notifications = [
        {
          type: ReceiptNotificationType.Email,
          attachmentType: formData.receiptAttachmentType ?? ReceiptAttachmentType.None,
        },
      ];
      receiptContractCreationParams.templateConfiguration = {
        receiptMerchantLogo: formData.receiptMerchantLogo?.length
          ? formData.receiptMerchantLogo[0]
          : null,
        templates: [
          {
            type: ReceiptTemplateType.TemplateId,
            templateId: formData.receiptTemplate ?? '',
          },
        ],
      };
      if (formData.customTemplates?.length) {
        formData.customTemplates.forEach((customTemplate) => {
          const fullCustomTemplate = {
            ...customTemplate,
            type: ReceiptTemplateType.TemplateId,
          };
          receiptContractCreationParams.templateConfiguration.templates.push(fullCustomTemplate);
        });
      }
    }

    const providerCreationParams: IReceiptProvider = {
      type: formData.receiptProvider,
      receiptTypes: [
        PaymentDocumentType.FullReceipt,
        PaymentDocumentType.Invoice,
        PaymentDocumentType.InvoiceReceipt,
      ],
    };

    this.organisationService
      .add(formData)
      .pipe(
        switchMap((addedOrg: IOrganisation) => {
          const addAppsPermissionsRequest = featuresToAdd?.length
            ? this.authorizationService
                .addAppsPermissionsToEntity(addedOrg.entityUid, featuresToAdd)
                .pipe(
                  catchError(() => {
                    return of(null);
                  }),
                )
            : of(EMPTY);

          const isInvoiceContractCreatedForCurrentProvider =
            (this.isInvoice4uProviderCreated &&
              formData.receiptProvider === ReceiptProvider.Invoice4U) ||
            (this.isVerifoneReceiptProviderCreated &&
              formData.receiptProvider === ReceiptProvider.Verifone);

          const receiptContractCreationError = $localize`Receipt contract could not be added at this moment. Please try again later.`;

          return forkJoin([
            addAppsPermissionsRequest,
            isInvoiceContractCreatedForCurrentProvider
              ? this.organisationService.createReceiptProvider(providerCreationParams)
              : of(null),
            formData.receiptProvider && formData.receiptProvider !== ReceiptProvider.None
              ? this.organisationService
                  .createReceiptContract(addedOrg.entityUid, receiptContractCreationParams)
                  .pipe(
                    catchError(() => {
                      this.toastService.showToast(receiptContractCreationError);
                      return of(NEVER);
                    }),
                  )
              : of(null),
            formData.sftpConnection
              ? this.reportEngineService.createSftpConnection(
                  formData.sftpConnection,
                  addedOrg.entityUid,
                )
              : of(null),
          ]).pipe(
            map(() => addedOrg),
            catchError(() => {
              this.notifierService.error(
                $localize`Organisation is created and features are not assigned. Please assign on the edit page of the organisation`,
              );
              return of(NEVER);
            }),
          );
        }),
      )
      .subscribe((addedOrg: IOrganisation) => {
        this.toastService.showToast(
          $localize`${addedOrg.name} (${addedOrg.entityUid}) is successfully created`,
        );
        this.router.navigate([`/administration/organisations/${addedOrg.entityUid}`]);
      });
  }

  private formValueToRequestData(formValue: IOrganisation): IOrganisation {
    return {
      ...formValue,
      domainName: formValue.domainName?.toLowerCase(),
    };
  }
}
