<div class="file-browse is-fullheight">
  <div
    marketplaceDragDrop
    (fileDropped)="fileProgress($event)"
    [aspect]="aspect"
    class="has-text-grey file-browse-container has-border-grey has-text-centered"
  >
    <div>
      <img [src]="uploadImage" alt="upload" />
      <div class="is-size-7" i18n>Drag & drop files here</div>
      <div class="is-size-7" i18n>or</div>
      <div
        class="is-clickable has-text-link browse"
        data-e2e="file-upload-browse-btn"
        (click)="browse()"
        i18n
      >
        Browse
      </div>
      <p
        *ngIf="supportedFilesText; else generalText"
        class="has-text-gray is-size-7 has-text-centered"
        i18n
      >
        {{ supportedFilesText }}
      </p>
      <ng-template #generalText>
        <p class="has-text-gray is-size-7 has-text-centered" i18n>Supported formats PNG or JPG.</p>
      </ng-template>

      <div class="mt-2">
        <ng-content></ng-content>
      </div>
    </div>
  </div>

  <input
    type="file"
    style="background-color: white; border-color: lightgray;"
    id="file-input"
    #fileInput
    [accept]="accept"
    name="file"
    (change)="fileProgress($event.target.files)"
  />
</div>
<ng-container *ngIf="selectedFiles.length > 0">
  <div *ngFor="let file of selectedFiles">{{ file }}</div>
</ng-container>
