import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntTelInputComponent } from './components/int-tel-input.component';
import { PipesModule } from '@portal/shared/pipes';
import { FormModule } from '@portal/shared/ui/form';
import { IntTelInputService } from './services/int-tel-input.service';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, PipesModule, FormModule, FormsModule],
  declarations: [IntTelInputComponent],
  providers: [IntTelInputService],
  exports: [IntTelInputComponent],
})
export class IntTelInputModule {}
