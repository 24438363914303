import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { Key } from '@portal/shared/ui/on-key-press/src/lib/enums/key';
import { FilterContentItem } from '../content/item';
import { FILTER_CONTENT_ITEM } from '../content/token';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'portal-filter-wrapper',
  templateUrl: './wrapper.component.html',
})
export class WrapperComponent implements AfterContentInit {
  @ContentChildren(FILTER_CONTENT_ITEM as any, { descendants: true })
  items: QueryList<FilterContentItem>;
  @Input() class = '';

  unsubscribeFromItemToggledEvents = new Subject<void>();

  activeItem: FilterContentItem;
  Key = Key;

  ngAfterContentInit(): void {
    (this.items.changes as Observable<QueryList<FilterContentItem>>)
      .pipe(
        startWith(this.items),
        tap(() => this.unsubscribeFromItemToggledEvents.next()),
        map((items) => items.toArray()),
        tap((items: FilterContentItem[]) => {
          items.forEach((item: FilterContentItem) => {
            item.itemToggled
              .pipe(takeUntil(this.unsubscribeFromItemToggledEvents))
              .subscribe(() => {
                this.itemToggledHandler(item);
              });
          });
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  itemToggledHandler(toggledItem: FilterContentItem): void {
    this.activeItem = this.activeItem === toggledItem ? null : toggledItem;
    this.items.forEach((item: FilterContentItem) => {
      item.isOpen = this.activeItem === item;
      if (item.isOpen) {
        item.getList();
      }
    });
  }

  closeAllFilter(): void {
    this.activeItem = null;
    this.items.forEach((item: FilterContentItem) => {
      item.isOpen = false;
    });
  }
}
