import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormModule } from '@portal/shared/ui/form/src/lib/form.module';
import { FormBusinessInformationComponent } from './form-business-information/form-business-information.component';

@NgModule({
  declarations: [FormBusinessInformationComponent],
  imports: [CommonModule, FormModule],
  exports: [FormBusinessInformationComponent],
})
export class EntityServiceFormsModule {}
