import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  IActionMapping,
  ITreeOptions,
  ITreeState,
  KEYS,
  TREE_ACTIONS,
  TreeComponent,
  TreeModel,
  TreeNode,
} from '@circlon/angular-tree-component';
import { FilterContentItem } from '@portal/shared/ui/filter/src/lib/content/item';
import {
  BehaviorSubject,
  EMPTY,
  forkJoin,
  Observable,
  of,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import {
  EntityStatus,
  IEntity,
  IEntityAncestors,
  IOrganisation,
} from '@portal/entity-services/interfaces';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterEntityComponent } from '@portal/shared/ui/filter/src/lib/entity/entity.component';
import { AuthenticationService } from '@portal/shared/auth/authentication/src/lib/authentication.service';
import { OrganisationService } from '@portal/entity-services/organisations/src/lib/services/organisation.service';
import { FILTER_CONTENT_ITEM } from '@portal/shared/ui/filter/src/lib/content/token';
import { ISelectInput } from '../box/interfaces/select-input.interface';
import { cloneDeep, forEach, uniqBy } from 'lodash';
import { LanguageHandle } from '@portal/shared/languages';
import { IFilterToApply } from '../search/interfaces/filter-to-apply.interface';
import { ISearchCriteria } from '../search/interfaces/search-criteria.interface';
import { SearchSelector } from '../search/enums/search-selector.enum';
import { SearchOperator } from '../search/enums/search-operator.enum';
import { FilterAutoCompleteInputComponent } from '@portal/shared/ui/filter/src/lib/autocomplete/search-autocomplete-input.component';
import { HttpError } from '@portal/shared/error-handler/src/lib/enums/HttpError';
import { FilterSubMenu } from '../enums/filter-sub-menu.enum';
import { AbstractControl, FormControl } from '@angular/forms';
import { SearchParameterList } from './search-parameters.list';
import { SearchParameters } from '../enums/search-parameters.enum';
import { ErrorService } from '@portal/shared/ui/form';
import { NotifierService } from '@portal/shared/ui/notifier';
import * as get from 'lodash/get';
import { FiltersService } from '@portal/shared/ui/filter/src/lib/services/filters.service';
import { DOCUMENT } from '@angular/common';

declare const $localize;
type SelectedNode = IEntity & { children: SelectedNode[] };

@UntilDestroy()
@Component({
  selector: 'portal-hierarchy-filters',
  templateUrl: './hierarchy-filter.component.html',
  styleUrls: ['./hierarchy-filter.component.scss'],
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: HierarchyFilterComponent,
    },
  ],
})
export class HierarchyFilterComponent
  extends FilterContentItem
  implements OnInit, OnDestroy, OnChanges
{
  static initialDebounce = 1000;

  @Input() resetEntity$ = new Subject();
  @Input()
  set searchSelector(val) {
    this._searchSelector = val;
  }

  get searchSelector(): any {
    return this._searchSelector;
  }

  @Input() isSubMenuFilter: boolean;
  @Input() isMainDropdownFilter: boolean;
  @Input() displayFromFilter: boolean;
  @Input() showFilterSubMenu: boolean;
  @Input() selectedParentEntity: IEntity;
  @Input() selectedParentEntityChanged: boolean;

  @Input()
  set filterName(val) {
    this.entityText = val;
  }

  @Input() entityType = '';
  @Input() numberOfSelectedEntities?: number;
  @Input() isPreSelectedAncestorIds: boolean;
  @Input() entityRemoved$ = new Subject<ISelectInput>();
  @Input() selectedEntities: Map<string, ISearchCriteria>; // Pre-selected, from store
  @Input() selectLimit = 0;
  @Input() notFoundMessage = $localize`Items not found`;
  @Input() organizationGroup: boolean;
  @Input('control') controlEntityUids: AbstractControl = new FormControl();
  @Input() selectedSubOrgToEdit: IOrganisation[];
  @Input() groupModeEdit: boolean;
  @Input() allowDeleted = false;
  @Input() editSchedulerHierarchyFilter: boolean;
  @Input() isScheduler: boolean;
  @Output() listAllEntities: EventEmitter<ISelectInput[]> = new EventEmitter();
  @Output() organisationTextName: EventEmitter<any> = new EventEmitter();
  @Output() filterApplied: EventEmitter<IFilterToApply> = new EventEmitter();
  @Output() filterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();
  @Output() staticFilterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();
  @Output() selectedAncestorsFilterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();
  @Output() isExceededSelectedAncestors: EventEmitter<boolean> = new EventEmitter();
  @Output() selectedAncestorsSchedulerFilterUpdated: EventEmitter<IFilterToApply> =
    new EventEmitter();
  @Output() selectedAncestorEntitiesNumber: EventEmitter<number> = new EventEmitter();
  @Output() allPreSelectedEntitiesNumber: EventEmitter<number> = new EventEmitter();
  @Output() filterAppliedSelectedEntities: EventEmitter<Map<string, ISelectInput>> =
    new EventEmitter();
  @Output() resetParentEntityChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() openDropDown: EventEmitter<boolean> = new EventEmitter();
  @Output() buttonFocused: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('tree') tree: TreeComponent;
  @ViewChild('applyButton') applyButton: ElementRef;

  @ViewChild(forwardRef(() => FilterAutoCompleteInputComponent))
  private searchInput: FilterAutoCompleteInputComponent;

  set entityText(val) {
    this._entityText = val;
  }

  get entityText(): string {
    return this._entityText;
  }

  title = '';
  staticListUpdateSubject$ = new Subject<IFilterToApply>();
  itemToggled: EventEmitter<number> = new EventEmitter();
  isOpen = false;
  localPreSelectedEntities: Map<string, ISelectInput> = new Map();
  selectedEntitiesStored = new Map();
  allPreSelectedEntities: Map<string, ISelectInput> = new Map();
  allPreSelectedEntityValues = [];
  localPreSelectedAncestorEntities: Map<string, ISelectInput> = new Map();
  localPreSelectedEntitiesOnClick: Map<string, ISelectInput> = new Map();
  selectNode: { click: boolean; event: Record<string, any> };
  deselectNode: { click: boolean; event: Record<string, any> };
  entitiesAll: IEntity[] = [];
  entities: IEntity[] = [];
  allEntities: ISelectInput[];
  searchedText = '';
  isOrgExist = true;
  loading$: Observable<boolean>;
  showSelectedEntities: Map<string, ISelectInput> = new Map();
  searchTerm$ = new Subject<string>();
  search$: Observable<IOrganisation[]>;
  debounce = 1000;
  filterLabel = this.title;
  treeState: ITreeState;

  currentRootEntity = this.authService.entityUid;

  treeActionsMapping: IActionMapping = {
    keys: {
      [KEYS.SPACE]: TREE_ACTIONS.TOGGLE_SELECTED,
      [KEYS.ENTER]: () => {
        this.onFilterApplied();
      },
    },
  };

  rootParentNodeData: IOrganisation;
  localExpandedHierarchyList: Map<string, IOrganisation> = new Map();
  limitSelectedEntityElements = 20;
  ancestorEntitiesElements = 0;
  disabledApply = false;

  options: ITreeOptions = {
    getChildren: (node: TreeNode) => {
      this.setCurrentNodeExpanded(node.data);
      return this.organisationService
        .getHierarchyView(node.id, 0, {}, String(this.limitSelectedEntityElements))
        .pipe(
          map((hierarchyList) => {
            this.setLocalExpandedHierarchyList(hierarchyList);
            return hierarchyList.filter(
              (item, index) =>
                !this.tree?.treeModel?.getNodeBy(
                  (nodeItem) =>
                    nodeItem.data.entityUid === item.entityUid &&
                    hierarchyList.indexOf(item) !== index,
                ),
            );
          }),
          map((value) =>
            uniqBy(
              node.id === this.currentRootEntity
                ? [...(this.selectedNodes?.length ? this.selectedNodes : []), ...value]
                : value,
              SearchSelector.EntityUid,
            ),
          ),
        )
        .toPromise();
    },
    useCheckbox: true,
    idField: 'entityUid',
    displayField: 'name',
    useVirtualScroll: false,
    nodeHeight: 22,
    actionMapping: this.treeActionsMapping,
  };
  loadingNewPortion$: Observable<boolean>;
  loadingMore$ = new BehaviorSubject(0);
  backToMenuSubject$ = new Subject<IFilterToApply>();
  backToMenuSub: Subscription;
  isActive = true;
  searchParameterList = this.searchParameters.list;
  searchParameterType = SearchParameters.Name;

  get searchPlaceholder(): string {
    return $localize`Search by ${
      this.searchParameterList.find((input) => input.id === this.searchParameterType).text
    }`;
  }

  includeDeleted = this.allowDeleted && this.preselectedIncludeDeleted;

  get status(): EntityStatus[] {
    return this.includeDeleted
      ? [EntityStatus.Active, EntityStatus.Inactive, EntityStatus.Deleted]
      : [EntityStatus.Active, EntityStatus.Inactive];
  }

  get preselectedStatus(): EntityStatus[] {
    const values = this.selectedEntities?.get(SearchSelector.Status)?.values as Set<EntityStatus>;
    return values && [...values];
  }

  get preselectedIncludeDeleted(): boolean {
    return this.preselectedStatus ? this.preselectedStatus.includes(EntityStatus.Deleted) : true;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _start: number;
  private stopLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _searchSelector: any = SearchSelector.EntityId;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _entityText = $localize`Organisations`;
  private loadingStatus = false;
  private selectedPath: string[];
  private selectedNodes: SelectedNode[];

  constructor(
    public organisationService: OrganisationService,
    private authService: AuthenticationService,
    private languageHandle: LanguageHandle,
    private searchParameters: SearchParameterList,
    private notifierService: NotifierService,
    private filtersService: FiltersService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
    this.loading$ = organisationService.loading$;
    this.loadingNewPortion$ = organisationService.loadingNewPortion$.pipe(
      tap((value) => (this.loadingStatus = value)),
    );
    this._start = 0;
  }

  setCurrentNodeExpanded(nodeData: IOrganisation): void {
    if (!this.localExpandedHierarchyList.get(nodeData?.entityUid)) {
      this.localExpandedHierarchyList.set(nodeData.entityUid, {
        count: nodeData.count,
        entityUid: nodeData.entityUid,
        name: nodeData.name,
        hasChildren: nodeData.hasChildren,
        parentEntityUid: null,
      });
    }
  }

  setLocalExpandedHierarchyList(hierarchyList: IOrganisation[]): void {
    hierarchyList.forEach((entity) => {
      if (!this.localExpandedHierarchyList.get(entity?.entityUid)) {
        this.localExpandedHierarchyList.set(entity.entityUid, {
          count: entity.count,
          entityUid: entity.entityUid,
          name: entity.name,
          hasChildren: entity.hasChildren,
          parentEntityUid: entity.parentEntityUid,
        });
      }
    });
  }

  getNodeByEntityUid(entityUid: string): IOrganisation {
    return this.localExpandedHierarchyList?.get(entityUid);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Adding a group organization group:
    // If the parent organization changed, the sub organizations will be updated according
    if (this.selectedParentEntityChanged) {
      this.onFilterClear();
      this.getList();
      this.startSearching();
      this.resetParentEntityChanged.emit(false);
    }

    // To edit sub organizations of an added group organization
    if (this.groupModeEdit && this.organizationGroup && this.selectedSubOrgToEdit) {
      this.getValues();
      this.getList();
      this.startSearching();
      this.selectedSubOrgToEdit?.forEach((subOrgToEdit) => {
        this.localPreSelectedEntities.set(subOrgToEdit.entityUid, {
          id: subOrgToEdit.entityUid,
          text: subOrgToEdit.name,
        });
      });

      if (this.localPreSelectedEntities) {
        // Entities adding a new organization group
        this.allPreSelectedEntities = cloneDeep(this.localPreSelectedEntities);
        this.setAllPreSelectedEntityValues();
        if (this.allPreSelectedEntities?.size) {
          this.setControlValues();
        }
      }

      this.localPreSelectedEntities?.forEach((selected) => {
        if (!this.treeState?.selectedLeafNodeIds) {
          this.treeState = {
            ...this.treeState,
            selectedLeafNodeIds: { ...this.treeState?.selectedLeafNodeIds, [selected.id]: true },
          };
        } else {
          this.treeState.selectedLeafNodeIds = {
            ...this.treeState.selectedLeafNodeIds,
            [selected.id]: true,
          };
        }
      });
      this.tree?.treeModel?.setState(this.treeState);
      this.tree?.treeModel?.update();
      this.tree?.sizeChanged();
    }

    this.updateText();
    if (changes.selectedEntities) {
      this.includeDeleted = this.allowDeleted && this.preselectedIncludeDeleted;
      this.setCurrentRootEntity();
      this.initEntities();
      this.isActive = true;
    }
  }

  setCurrentRootEntity(): void {
    this.currentRootEntity = this.selectedParentEntity?.entityUid || this.authService.entityUid;
  }

  ngOnInit(): void {
    this.setCurrentRootEntity();
    if (this.editSchedulerHierarchyFilter) {
      this.startSearching();
      this.getValues();
      this.getList();
      this.initEntities();
    } else {
      this.initData();
    }

    if (this.isSubMenuFilter) {
      this.isActive = false;
    }
    this.backToMenuSub = this.backToMenuSubject$.pipe(debounceTime(100)).subscribe(() => {
      const element = document.getElementById('add-filter');
      if (element) {
        element.click();
      }
    });

    this.itemToggled.pipe(untilDestroyed(this)).subscribe(() => (this._start = 0));
    this.entityRemoved$.pipe(untilDestroyed(this)).subscribe((entityToRemove: ISelectInput) => {
      if (entityToRemove?.id) {
        this.onRemoveSelectedOrganization(entityToRemove);
      }
    });

    this.filtersService.actionRemoveFilter$.subscribe((entityToRemove: ISelectInput) => {
      if (SearchSelector.EntityId === entityToRemove.key) {
        const selectedEntity = this.localPreSelectedEntities.get(entityToRemove.id);
        if (selectedEntity) {
          this.onRemoveSelectedOrganization(entityToRemove);
          this.itemToggled.emit();
        }
      }
    });

    this.getEntityById(this.currentRootEntity)?.subscribe((entity) => {
      this.rootParentNodeData = cloneDeep(entity);
    });

    this.isExceededSelectedAncestors.emit(this.disabledApply);

    this.resetEntity$.subscribe(() => {
      this.resetInputValue();
    });
  }

  setControlValues(): void {
    const selectedEntityUids = [];
    Array.from(this.localPreSelectedEntities.values()).forEach((value) => {
      // Exclude the Parent entity from form (add new organization group)
      if (this.selectedParentEntity?.entityUid !== value.id) {
        selectedEntityUids.push(value.id);
      }
    });
    this.controlEntityUids.setValue(selectedEntityUids);
    this.allPreSelectedEntitiesNumber.emit(selectedEntityUids.length);
  }

  ngOnDestroy(): void {
    if (this.backToMenuSub) {
      this.backToMenuSub.unsubscribe();
    }
  }

  backToMenu(): void {
    this.itemToggled.emit();
    this.backToMenuSubject$.next();
    this.isOpen = false;
    this.isActive = false;
    this.isMainDropdownFilter = false;
  }

  openContent(): void {
    this.itemToggled.emit();
    this.isOpen = true;
    this.getList();
  }

  openContentOrganizationGroup(): void {
    this.setCurrentRootEntity();
    if (!this.isOpen && this.organizationGroup) {
      this.isOpen = true;
      this.getValues();
      this.getList();
      this.startSearching();
    }
    this.openDropDown.emit(this.isOpen && this.isMainDropdownFilter && this.isActive);
  }

  updateApplyRemovingEntity(): void {
    const values = new Set<string>();
    Array.from(this.allPreSelectedEntities.values()).forEach((val: ISelectInput) => {
      values.add(val.id);
    });

    if (Array.from(values).length) {
      this.filterApplied.emit({
        selector: this.searchSelector,
        key: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.allPreSelectedEntities.values()),
        argument: `(${Array.from(Array.from(values)).join(',')})`,
      });
    }
  }

  onClose(): void {
    this.isOpen = false;
    this.openDropDown.emit(!this.isOpen && !this.isMainDropdownFilter && !this.isActive);
  }

  onRemoveSelectedOrganization(selectedEntity: ISelectInput): void {
    this.allPreSelectedEntities.delete(selectedEntity.id);
    this.localPreSelectedEntities.delete(selectedEntity.id);
    this.selectedEntitiesStored.delete(selectedEntity.id);
    this.onEntityRemoved(selectedEntity);
    this.deselected(selectedEntity);
    this.updateApplyRemovingEntity();
    this.setControlValues();
    this.setAllPreSelectedEntityValues();
    this.filterUpdate(this.filterUpdated, this.selectedAncestorsFilterUpdated, 1);
  }

  getEntitiesByName(term: string, noLoader?: boolean): Observable<IOrganisation[]> {
    const reachedTheEnd = (val: IOrganisation[]): boolean => {
      return (this.stopLoading = val.length < 19);
    };
    this.isOrgExist = true;
    if (this.entityType !== '') {
      return term
        ? this.searchParameterType === SearchParameters.EntityUid
          ? this.organisationService.getByKey(term).pipe(map((value) => [value]))
          : this.organisationService
              .getLightWeightWithQuery(
                {
                  [this.searchParameterType]: term,
                  entityType: this.entityType,
                },
                noLoader,
                this._start,
                this.limitSelectedEntityElements,
              )
              .pipe(tap(reachedTheEnd))
        : this.organisationService
            .getEntity(this._start, noLoader, this.currentRootEntity)
            .pipe(tap(reachedTheEnd));
    }

    return term
      ? this.searchParameterType === SearchParameters.EntityUid
        ? this.organisationService.getByKeyWithAncestors(term).pipe(
            map((value) => [value]),
            catchError((err) => {
              if (err.error.status === HttpError.CodeError404) {
                this.isOrgExist = false;
                return of([]);
              }
            }),
          )
        : this.organisationService
            .getLightWeightWithQuery(
              {
                [this.searchParameterType]: term,
              },
              noLoader,
              this._start,
              this.limitSelectedEntityElements,
            )
            .pipe(tap(reachedTheEnd))
      : this.organisationService
          .getEntity(this._start, noLoader, this.currentRootEntity)
          .pipe(tap(reachedTheEnd));
  }

  loadMore(node: TreeNode): void {
    this.loadingMore$.next(node.id);
    this.organisationService
      .getHierarchyView(
        node.data.entityUid,
        node.data.children.length === 1 ? 0 : node.data.children.length + 1,
        {},
        String(this.limitSelectedEntityElements),
      )
      .subscribe((entities) => {
        if (entities?.length) {
          this.setLocalExpandedHierarchyList(entities);
          if (this.tree?.treeModel?.getState()?.selectedLeafNodeIds[node.data.entityUid]) {
            entities.forEach((entity) => {
              this.treeState.selectedLeafNodeIds = {
                ...this.treeState.selectedLeafNodeIds,
                [entity.entityUid]: false,
              };
            });
            this.tree?.treeModel?.setState(this.treeState);
          }
        }
        node.data.children.push(
          ...(this.selectedNodes?.length
            ? entities.filter((entity) => !this.selectedPath?.includes(entity.entityUid))
            : entities),
        );
        this.loadingMore$.next(0);
        this.tree?.treeModel?.update();
        this.tree?.sizeChanged();
      });
  }

  updateEntities(): void {
    if (this.selectLimit > 0) {
      this.localPreSelectedEntities = new Map(
        [...this.localPreSelectedEntities.entries()].slice(0, this.selectLimit),
      );
    }

    this.showSelectedEntities = cloneDeep(this.localPreSelectedEntities);
    Array.from(this.localPreSelectedEntities?.values()).forEach((value) => {
      if (this.treeState?.selectedLeafNodeIds) {
        this.treeState.selectedLeafNodeIds[value.id] = true;
      }
    });
    this.tree?.treeModel?.setState(this.treeState);
    this.entities = this.entitiesAll;
    // TODO review it why does not return all the Entities
    this.allEntities = this.entitiesAll?.map((entity) => {
      return {
        id: entity.entityUid,
        text: entity.name,
      };
    });
    this.listAllEntities.emit(this.allEntities);
    this.organisationTextName.emit(FilterSubMenu.Organisation);
  }

  setSelectedEntities(value: ISearchCriteria): void {
    // Set selectedFilters
    const values = new Set<string>(value.argument?.replace('(', '')?.replace(')', '')?.split(','));
    const selectedForState = {};
    forEach(Array.from(values), (argument: string) => {
      selectedForState[argument] = true;
      const node = this.tree?.treeModel.getNodeById(argument);
      this.localPreSelectedEntities.set(argument, {
        id: argument,
        text: node?.data?.name,
      });
    });
    this.treeState = this.treeState
      ? {
          ...this.treeState,
          selectedLeafNodeIds: selectedForState,
        }
      : { selectedLeafNodeIds: selectedForState };
    this.updateSelectedEntities();
  }

  startListeningForSearch(): void {
    this.search$ = this.search(this.searchTerm$);
    this.search$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.entities = data;
      this.entitiesAll = data;
      if (this.localPreSelectedEntities.size) {
        this.updateEntities();
        this.selectedPath = undefined;
        this.selectedNodes = undefined;
        Array.from(this.localPreSelectedEntities.values()).forEach((value) => {
          this.treeState.selectedLeafNodeIds[value.id] = true;
          const id = value.id;
          if (id !== this.currentRootEntity && !this.selectedPath?.includes(id)) {
            this.organisationService
              .getAncestors(id)
              .pipe(
                catchError((err) => {
                  const code = ErrorService.getStatusCode(err);
                  if (code === HttpError.CodeError404) return EMPTY;
                  return throwError(err);
                }),
              )
              .subscribe(
                (ancestors) => {
                  this.flatten(ancestors);
                },
                (error: Object) => {
                  this.notifierService.error(
                    $localize`Error` +
                      ` (${get(error, 'error.status')}) ${get(error, 'error.error.message')}`,
                  );
                },
              );
          }
        });
      }
    });
  }

  startSearching(): void {
    this.search$ = this.search(this.searchTerm$);
    this.search$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.entities = data;
      this.entitiesAll = data;
      if (this.localPreSelectedEntities.size) {
        this.updateEntities();
        this.selectedPath = undefined;
        this.selectedNodes = undefined;
        Array.from(this.localPreSelectedEntities.values()).forEach((value) => {
          this.treeState.selectedLeafNodeIds[value.id] = true;
        });
      }
    });
  }

  updateSelectedEntities(): void {
    this.showSelectedEntities = cloneDeep(this.localPreSelectedEntities);
    const entitiesToFetch: string[] = [];
    Array.from(this.showSelectedEntities.values()).forEach((val: ISelectInput) => {
      if (!val.text) {
        entitiesToFetch.push(val.id);
      }
    });

    if (entitiesToFetch.length) {
      forkJoin(
        entitiesToFetch.map((id: string) => {
          return this.getEntityById(id);
        }),
      )
        .pipe(untilDestroyed(this))
        .subscribe((orgs: IOrganisation[]) => {
          forEach(orgs, (org: IOrganisation) => {
            const input = this.showSelectedEntities.get(org.entityUid);
            if (input) {
              input.text = org.name;
            }
            this.localPreSelectedEntities.set(org.entityUid, {
              id: org.entityUid,
              text: org.name,
            });
          });
          if (this.isScheduler) {
            const values: string[] = this.getValues();
            this.filterUpdated.emit({
              key: this.searchSelector,
              selector: this.searchSelector,
              operator: SearchOperator.In,
              value: Array.from(this.localPreSelectedEntities.values()),
              argument: `(${Array.from(values).join(',')})`,
            });
          } else {
            this.delayFilterUpdate();
          }
          this.updateText();
        });
    } else {
      if (this.treeState?.selectedLeafNodeIds) {
        this.treeState.selectedLeafNodeIds = {};
        this.tree?.treeModel?.setState(this.treeState);
      }
    }
  }

  onStatusChange(): void {
    this.entitiesAll = this.entities = [];
    this._start = 0;
    this.tree?.treeModel.update();

    this.organisationService
      .getEntity(this._start, false, this.authService.entityUid)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.entities = data;
        this.entitiesAll = data;
        if (this.localPreSelectedEntities.size) {
          this.updateEntities();
          this.selectedPath = undefined;
          this.selectedNodes = undefined;
          Array.from(this.localPreSelectedEntities.values()).forEach((value) => {
            this.treeState.selectedLeafNodeIds[value.id] = true;
          });
        }
      });

    this.initEntities();
    if (this.searchedText) {
      this.searchTerm$.next('');
      this.searchTerm$.next(this.searchedText);
    }
  }

  onSearch(searchValue = ''): void {
    this.searchedText = searchValue;
    this._start = 0;
    this.searchTerm$.next(searchValue);
  }

  search(terms: Observable<string>): Observable<IOrganisation[]> {
    return terms.pipe(distinctUntilChanged(), debounceTime(this.debounce)).pipe(
      switchMap((term: string) => {
        // Always reset debounce time
        if (this.treeState) {
          this.treeState.expandedNodeIds = {};
          this.treeState.selectedLeafNodeIds = {};
          this.tree?.treeModel?.update();
        }
        this.debounce = FilterEntityComponent.initialDebounce;
        return this.getEntitiesByName(term);
      }),
      tap(() => this.tree?.sizeChanged()),
    );
  }

  clear(): void {
    if (this.isOpen) {
      this.itemToggled.emit();
    }
    this.isOpen = false;
    this.removeAllEntities();
  }

  getEntityById(id: string): Observable<IOrganisation> {
    return this.organisationService.getByKey(id);
  }

  onFilterApplied(): void {
    this.filterUpdate(this.filterApplied, this.selectedAncestorsFilterUpdated, 0);
    this.itemToggled.emit();
    this.isOpen = false;
    if (this.isSubMenuFilter) {
      this.isActive = false;
      this.isMainDropdownFilter = false;
    }
    this.filterAppliedSelectedEntities.emit(this.localPreSelectedEntities);
    this.openDropDown.emit(!this.isOpen && !this.isMainDropdownFilter && !this.isActive);
  }

  resetInputValue(): void {
    this.onSearch('');
    this.searchParameterType = SearchParameters.Name;
  }

  onFilterClear(): void {
    this.removeAllEntities();
    this.resetInputValue();
    this.delayFilterUpdate();
  }

  removeAllEntities(): void {
    this.localPreSelectedEntities.clear();
    this.localPreSelectedAncestorEntities.clear();
    this.updateSelectedEntities();
    this.updateText();
  }

  onEntityRemoved(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.delete(selectedEntity.id);
  }

  onEntityAdded(selectedEntity: ISelectInput): void {
    if (selectedEntity?.id) {
      this.localPreSelectedEntities.set(selectedEntity.id, {
        id: selectedEntity.id,
        text: this.tree?.treeModel.getNodeById(selectedEntity.id).data?.name,
      });
    }
  }

  getList(): Observable<IOrganisation[]> {
    this.searchedText = '';
    this.searchInput?.reset();

    const entities = cloneDeep(this.selectedEntities.get(this.searchSelector));

    if (entities) {
      this.setSelectedEntities(entities);
    }

    this.debounce = 0;
    this.searchTerm$.next(this.searchedText);
    return null;
  }

  getValues(): string[] {
    const values = new Set<string>();
    Array.from(this.localPreSelectedEntities.values()).forEach((val: ISelectInput) => {
      values.add(val.id);
    });
    return Array.from(values);
  }

  getSelectedAncestorValues(): string[] {
    const values = new Set<string>();
    Array.from(this.localPreSelectedAncestorEntities.values()).forEach((val: ISelectInput) => {
      values.add(val.id);
    });
    return Array.from(values);
  }

  updateText(): void {
    const values: string[] = this.getValues();
    // important not to change this line
    const text = this.entityText;
    if (values.length || this.numberOfSelectedEntities) {
      this.title = `${text} (+${this.numberOfSelectedEntities || values.length})`;
    } else {
      this.title = text;
    }
  }

  get limitExceeded(): boolean {
    return this.selectLimit > 0 && this.localPreSelectedEntities.size > this.selectLimit;
  }

  deselected(event: any): void {
    this.deselectNode = { click: true, event: event };
    this.numberOfSelectedEntities = null;
    if (event) {
      if (
        this.treeState?.selectedLeafNodeIds[
          event?.node?.data?.entityUid || event?.data?.entityUid || event?.id
        ]
      ) {
        this.treeState.selectedLeafNodeIds = {
          ...this.treeState.selectedLeafNodeIds,
          [event?.node?.data?.entityUid || event?.data?.entityUid || event?.id]: false,
        };
      }
      if (event?.node?.parent || event?.parent) {
        this.deselected(event?.node?.parent || event?.parent);
      }
    } else {
      this.tree?.treeModel?.setState(this.treeState);
    }
    if (!event?.id) {
      this.filterUpdate(this.filterUpdated, this.selectedAncestorsFilterUpdated, 0);
    }
  }

  selected(event: any): void {
    if (event?.data?.entityUid && event?.data?.name) {
      if (!this.selectedEntitiesStored.get(event.data.entityUid)) {
        this.selectedEntitiesStored.set(event.data.entityUid, event.data.name);
      }
    }

    this.selectNode = { click: true, event: event };

    this.numberOfSelectedEntities = null;
    // setTimeout is as tree updates are slow
    setTimeout(() => {
      if (event?.parent && typeof event.parent.data.entityUid === 'string') {
        if (
          event.parent.data.children.find(
            (val) => !this.tree?.treeModel?.getState().selectedLeafNodeIds[val.entityUid],
          )
        ) {
          return;
        }
        if (this.treeState) {
          this.treeState.selectedLeafNodeIds[event.parent.data.entityUid] = true;
          this.tree?.treeModel?.setState(this.treeState);
          // recursion to update till the root
          this.selected(event.parent);
        }
      } else {
        // The root entity not selected
        if (event?.data?.entityUid) {
          if (this.treeState) {
            this.treeState.selectedLeafNodeIds[event.data.entityUid] = true;
            this.tree?.treeModel?.setState(this.treeState);
            this.tree?.treeModel?.update();
            this.tree?.sizeChanged();
          }
        }
      }
    });
    this.delayFilterUpdate();
  }

  insertNodeInSelectedAncestorEntities(entityUid: string): void {
    if (!this.localPreSelectedAncestorEntities.get(entityUid)) {
      this.localPreSelectedAncestorEntities.set(entityUid, {
        id: entityUid,
        text: this.getNodeByEntityUid(entityUid)?.name,
      });
    }
  }

  removeSelectedNodesFromAncestorEntities(
    parentEntityUid: string,
    ancestorSelectedEntities: string[],
  ): void {
    ancestorSelectedEntities.forEach((nodeId) => {
      if (this.getNodeByEntityUid(nodeId).parentEntityUid === parentEntityUid) {
        if (this.localPreSelectedAncestorEntities?.get(nodeId)) {
          this.localPreSelectedAncestorEntities.delete(nodeId);
        }
      }
    });
  }

  getCountedSelectedNodesFromParent(
    parentEntityUid: string,
    ancestorSelectedEntities: string[],
  ): number {
    let cnt = 0;
    ancestorSelectedEntities.forEach((nodeId) => {
      if (this.getNodeByEntityUid(nodeId)?.parentEntityUid === parentEntityUid) {
        cnt++;
      }
    });
    return cnt;
  }

  filterSetAncestorEntities(): void {
    this.localPreSelectedAncestorEntities = new Map<string, ISelectInput>();
    this.localPreSelectedAncestorEntities = cloneDeep(this.localPreSelectedEntities);
    this.updateText();
    if (
      !this.selectNode?.event?.data?.parentEntityUid &&
      !this.selectNode?.event?.data?.parent &&
      this.selectNode?.event?.data?.entityUid === this.currentRootEntity &&
      this.getValues()?.length === 1
    ) {
      this.localPreSelectedAncestorEntities.set(this.currentRootEntity, {
        id: this.currentRootEntity,
        text:
          this.rootParentNodeData?.name ||
          this.tree?.treeModel?.getNodeById(this.currentRootEntity)?.data?.name ||
          this.getPreSelectedEntityName(this.currentRootEntity),
      });
    } else {
      // If the root entity in, removing it
      if (
        this.localPreSelectedEntities.get(this.currentRootEntity) &&
        this.getValues()?.length > 1
      ) {
        this.localPreSelectedEntities.delete(this.currentRootEntity);
      }

      this.localPreSelectedAncestorEntities = cloneDeep(this.localPreSelectedEntities);
      const values = new Set<string>();
      Array.from(this.localPreSelectedAncestorEntities.values()).forEach((val: ISelectInput) => {
        values.add(val.id);
      });
      this.updateText();

      const ancestorEntities = Array.from(values);
      let countNodesFromParent = 0,
        countingSelectedExpandedNodes = 0;
      ancestorEntities.forEach((nodeId) => {
        const selectedEntity = this.getNodeByEntityUid(nodeId);
        if (selectedEntity?.parentEntityUid) {
          // parent node
          countNodesFromParent = this.getNodeByEntityUid(
            this.getNodeByEntityUid(nodeId).parentEntityUid,
          ).count;

          countingSelectedExpandedNodes = this.getCountedSelectedNodesFromParent(
            this.getNodeByEntityUid(nodeId).parentEntityUid,
            ancestorEntities,
          );

          // Remove the selected expanded nodes, and insert the parent
          if (countingSelectedExpandedNodes >= countNodesFromParent) {
            this.removeSelectedNodesFromAncestorEntities(
              this.getNodeByEntityUid(nodeId).parentEntityUid,
              ancestorEntities,
            );
            this.insertNodeInSelectedAncestorEntities(
              this.getNodeByEntityUid(nodeId).parentEntityUid,
            );
          }
        }
      });
    }

    if (this.isPreSelectedAncestorIds) {
      this.ancestorEntitiesElements = this.localPreSelectedAncestorEntities?.size || 0;
      if (this.selectNode?.click || this.deselectNode?.click) {
        this.delayFilterUpdate();
        this.selectNode = { click: false, event: this.selectNode?.event || null };
        this.deselectNode = { click: false, event: this.deselectNode?.event || null };
      }
      // we don't emit
      if (this.getValues()?.length > this.limitSelectedEntityElements) {
        this.localPreSelectedAncestorEntities = cloneDeep(this.localPreSelectedEntities);
      }

      this.updateText();
      this.disableOrEnableUseCheckbox();
    }
    this.selectedAncestorEntitiesNumber.emit(this.localPreSelectedAncestorEntities?.size || 0);
  }

  delayFilterUpdate(): void {
    setTimeout(() => {
      this.filterUpdate(this.filterUpdated, this.selectedAncestorsFilterUpdated, 0);
    }, 0);
  }

  getPreSelectedEntityName(entity: string): string {
    return this.selectedEntitiesStored?.has(entity) ? this.selectedEntitiesStored?.get(entity) : '';
  }

  checkTabPress(event): void {
    if ((event.target as HTMLElement)?.id === 'orgClearButton') {
      event.preventDefault();
      this.onFilterClear();
    }
  }

  onKeyDownApply(): void {
    const body = document.querySelector('body');
    body.addEventListener('keyup', this.checkTabPress);
  }

  onFocusIn(event: FocusEvent, treeModel: TreeModel): void {
    const treeElement = event.currentTarget as HTMLElement;

    if (event.target === treeElement) {
      treeModel.setFocus(true);
      if (!treeModel.focusedNode) {
        treeModel.getFirstRoot()?.focus();
      }
    } else {
      // prevent inputs inside the tree stealing the focus
      treeElement.focus();
    }
  }

  onFocusOut(event: FocusEvent, treeModel: TreeModel): void {
    if (event.target === event.currentTarget) {
      treeModel.setFocus(false);
    }
  }

  toggleCheckbox(node: TreeNode): void {
    const newNodeSelectedState = !node.isSelected;
    this.updateNodeAndDescendants(node, newNodeSelectedState);
    setTimeout(() => {
      node.setIsSelected(newNodeSelectedState);
      this.updateText();
    }, 50);
  }

  private updateNodeAndDescendants(node: TreeNode, newNodeSelectedState: boolean): void {
    // Update the node state
    node.setIsSelected(newNodeSelectedState);

    // If the node has children, recursively update their state
    if (node.children) {
      node.children.map((childNode) => {
        this.updateNodeAndDescendants(childNode, newNodeSelectedState);
      });
    }
  }

  private disableOrEnableUseCheckbox(): void {
    if (this.localPreSelectedAncestorEntities.size > this.limitSelectedEntityElements) {
      this.notificationLimitElements();
      this.disabledApply = true;
      this.isExceededSelectedAncestors.emit(this.disabledApply);
    } else {
      this.disabledApply = false;
      this.isExceededSelectedAncestors.emit(this.disabledApply);
    }
  }

  private notificationLimitElements(): void {
    if (this.localPreSelectedAncestorEntities.size > this.limitSelectedEntityElements) {
      this.notifierService.destroyNotification();
      this.notifierService.setDuration(10000);
      this.notifierService.info($localize`You can only select up to 20 organizations.`);
    }
  }

  private filterUpdate(
    filterEmitEvent: EventEmitter<IFilterToApply>,
    selectedAncestorsEmitEvent: EventEmitter<IFilterToApply>,
    state = 0,
  ): void {
    if (!state) {
      if (this.tree) this.localPreSelectedEntities.clear();
      if (this.tree?.treeModel?.getState()?.selectedLeafNodeIds) {
        Object.keys(this.tree?.treeModel?.getState()?.selectedLeafNodeIds)?.forEach((entityId) => {
          if (this.treeState?.selectedLeafNodeIds?.[entityId]) {
            this.localPreSelectedEntities.set(entityId, {
              id: entityId,
              text:
                this.tree?.treeModel?.getNodeById(entityId)?.data?.name ||
                this.getPreSelectedEntityName(entityId),
            });
          }
        });
      }
    }

    this.updateEntities();
    this.updateText();
    const values: string[] = this.getValues();
    const selectedAncestorValues: string[] = this.getSelectedAncestorValues();
    if (values.length) {
      filterEmitEvent.emit({
        selector: this.searchSelector,
        key: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.localPreSelectedEntities.values()),
        argument: `(${Array.from(values).join(',')})`,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        value: Array.from(this.localPreSelectedEntities.values()),
      });
    } else {
      filterEmitEvent.emit({
        key: this.searchSelector,
        toRemove: true,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        toRemove: true,
      });
    }

    if (selectedAncestorValues.length) {
      selectedAncestorsEmitEvent.emit({
        selector: this.searchSelector,
        key: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.localPreSelectedAncestorEntities.values()),
        argument: `(${Array.from(selectedAncestorValues).join(',')})`,
      });
      this.selectedAncestorsSchedulerFilterUpdated.emit({
        key: this.searchSelector,
        value: Array.from(this.localPreSelectedAncestorEntities.values()),
      });
    } else {
      selectedAncestorsEmitEvent.emit({
        key: this.searchSelector,
        toRemove: true,
      });
      this.selectedAncestorsSchedulerFilterUpdated.emit({
        key: this.searchSelector,
        toRemove: true,
      });
    }

    // Entities adding a new organization group
    this.allPreSelectedEntities = cloneDeep(this.localPreSelectedEntities);
    this.setAllPreSelectedEntityValues();
    if (this.allPreSelectedEntities?.size) {
      this.setControlValues();
    }

    this.filterSetAncestorEntities();
  }

  private setAllPreSelectedEntityValues(): void {
    this.allPreSelectedEntityValues = [];
    this.allPreSelectedEntities?.forEach((value) => {
      this.allPreSelectedEntityValues.push({ id: value.id, text: value.text });
    });
    this.allPreSelectedEntityValues = [...new Set(this.allPreSelectedEntityValues)];
  }

  private async flatten(data: IEntityAncestors): Promise<any> {
    const result = [];
    while (data) {
      result.push(data);
      if (data?.parent?.entityUid === this.currentRootEntity) {
        break;
      }
      data = data.parent;
    }
    result.forEach((value) => {
      delete value.parent;
    });
    this.selectedPath = [...(this.selectedPath || []), ...result.map((value) => value.entityUid)];
    const response = await forkJoin(
      result.map((val) =>
        this.organisationService.getTotalCount({
          parentId: val[SearchSelector.EntityUid],
        }),
      ),
    )
      .pipe(
        map((val) =>
          val.map((totalCountData, index) => ({
            ...result[index],
            count: totalCountData.count,
            hasChildren: totalCountData.count > 0,
            isExpanded: index !== 0,
          })),
        ),
      )
      .toPromise();
    const length = result.length;
    let selectedNode;

    if (this.selectedNodes?.length) {
      const index = response.findIndex((resp) =>
        this.findInsertExistingNode(resp.entityUid, this.selectedNodes),
      );
      const existingNode = index >= 0 ? response[index] : undefined;
      if (existingNode) {
        selectedNode = response[index - 1];
        this.insertChild(selectedNode, response.slice(0, index - 1));
        this.findInsertExistingNode(existingNode.entityUid, this.selectedNodes, selectedNode);
        this.selectedNodes = cloneDeep(this.selectedNodes);
      } else {
        selectedNode = response[length - 1];
        this.insertChild(selectedNode, response);
        this.selectedNodes = [...this.selectedNodes, selectedNode];
      }
    } else {
      selectedNode = response[length - 1];
      this.insertChild(selectedNode, response);
      this.selectedNodes = [selectedNode];
    }
    this.expandTree();
  }

  private findInsertExistingNode(
    id: string,
    selectedNodes?: SelectedNode[],
    nodeToInsert?: SelectedNode,
  ): SelectedNode {
    if (!selectedNodes) {
      return;
    }
    for (const item of selectedNodes) {
      if (item.entityUid === id) {
        const children = item?.children || [];
        if (nodeToInsert && !children.some((child) => child.entityUid === nodeToInsert.entityUid)) {
          item.children = [...(item?.children || []), nodeToInsert];
        }
        return item;
      } else {
        const found = this.findInsertExistingNode(id, item.children, nodeToInsert);
        if (found) return found;
      }
    }
    return null;
  }

  private insertChild(
    selectedNode: SelectedNode,
    array: SelectedNode[],
    index: number = array.length - 2,
  ): void {
    if (index >= 0 && selectedNode) {
      selectedNode.children = [array[index]];
      this.treeState = {
        ...this.treeState,
        expandedNodeIds: { ...this.treeState?.expandedNodeIds, [selectedNode.entityUid]: true },
      };
      this.tree?.treeModel.setState(this.treeState);
      this.insertChild(selectedNode.children[0], array, index - 1);
    }
  }

  private expandTree(): void {
    const expandedNodeIds = this.entities?.[0]?.hasChildren
      ? { [this.entities[0].entityUid]: true }
      : {};
    this.selectedPath?.forEach((value) => {
      const someNode = this.tree?.treeModel?.getNodeById(value);
      if (!someNode?.hasChildren) {
        return;
      }
      expandedNodeIds[value] = true;
      someNode?.expand();
    });

    this.treeState = {
      ...this.treeState,
      expandedNodeIds: { ...this.treeState?.expandedNodeIds, ...expandedNodeIds },
    };
  }

  private initEntities(): void {
    if (!this.selectedEntities) {
      this.selectedEntities = new Map();
    }

    const entities = cloneDeep(this.selectedEntities.get(this.searchSelector));
    this.staticListUpdateSubject$
      .pipe(debounceTime(100))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.staticFilterUpdated.emit({
          key: this.searchSelector,
          value: Array.from(this.localPreSelectedEntities.values()),
        });
      });

    if (entities) {
      const preSelectedEntities = entities?.argument?.replace('(')?.replace(')')?.split(',');
      const allEntities = preSelectedEntities?.map((entity) => {
        return {
          id: entity,
          text: this.tree?.treeModel?.getNodeById(entity)?.data?.name || '',
        };
      });
      this.listAllEntities.emit(allEntities);
      this.setSelectedEntities(entities);
    }

    if (this.allowDeleted) {
      this.updateStatusFilter();
    }
  }

  private initData(): void {
    this.initEntities();
    this.startListeningForSearch();
  }

  private updateStatusFilter(): void {
    this.filterUpdated.emit({
      key: SearchSelector.Status,
      selector: SearchSelector.Status,
      value: this.status.map((id) => ({ id })),
    });
  }

  get isApplyButtonFocused(): boolean {
    this.buttonFocused.emit(document.activeElement === document.getElementById('orgApplyButton'));
    return document.activeElement === document.getElementById('orgApplyButton');
  }
  get isClearButtonFocused(): boolean {
    this.buttonFocused.emit(document.activeElement === document.getElementById('orgClearButton'));
    return document.activeElement === document.getElementById('orgClearButton');
  }
  get isLoadMoreButtonFocused(): boolean {
    this.buttonFocused.emit(
      document.activeElement === document.getElementById('orgLoadMoreButton'),
    );
    return document.activeElement === document.getElementById('orgLoadMoreButton');
  }
}
