import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'portal-modal',
  templateUrl: './deprecated-modal.component.html',
})
export class DeprecatedModalComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() description: string;
  @Input() confirmLabel: string;
  @Input() cancelLabel: string;
  @Input() cancelOptionalEntities: string;
  @Input() confirmLabelDisableEntity: string;
  @Input() confirmLabelEnableEntity: string;
  @Output() confirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _element: any;
  private _isActive = false;

  // eslint-disable-next-line
  constructor(private el: ElementRef) {
    this._element = el.nativeElement;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (this.activeState && event.key === 'Escape') {
      this.close();
    }
  }

  ngOnInit(): void {
    this._element.addEventListener('click', this.onClickOutside.bind(this));
  }

  ngOnDestroy(): void {
    this._element.removeEventListener('click', this.onClickOutside.bind(this));
  }

  onClickOutside(event: any): void {
    if (event.target.className === 'modal-background') {
      this.close();
    }
  }

  get activeState(): boolean {
    return this._isActive;
  }

  set activeState(state: boolean) {
    this._isActive = state;
  }

  open(): void {
    this.activeState = true;
  }

  close(): void {
    this.activeState = false;
    this.confirmed.emit(false);
  }

  confirm(): void {
    this.activeState = false;
    this.confirmed.emit(true);
  }
}
