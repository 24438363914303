import { QueryParams } from '@ngrx/data';
import { ISearchCriteria } from '@portal/shared/ui/filter';
import { forEach, isUndefined, omitBy } from 'lodash';
import { SortOrder } from '../enums/sort-order.enum';
import { IPagination } from '../interfaces/pagination.interface';
import { IQueryParams } from '../interfaces/query-params.interface';

// @dynamic
export class QueryParamsService {
  static removeUndefined(): Function {
    return (value) => {
      return isUndefined(value) || value === 'undefined' || value === 'null';
    };
  }

  static toQueryParams(filter: IQueryParams): QueryParams {
    const queryParams = {
      ...filter,
      start: String(filter.start),
      limit: String(filter.limit),
    };

    const sanitized = omitBy(queryParams, QueryParamsService.removeUndefined());
    return sanitized;
  }

  static getFilterParams(searchCriteria: Map<string, ISearchCriteria>): QueryParams {
    const filterParams = {};
    if (searchCriteria) {
      forEach(Array.from(searchCriteria.values()), (crit: ISearchCriteria) => {
        filterParams[crit.selector] = crit.argument;
      });
    }
    return filterParams;
  }

  static getFilterParamsToString(searchCriteria: Map<string, ISearchCriteria>): string {
    return Array.from(searchCriteria.values())
      .map((crit: ISearchCriteria) => `${crit.selector}${crit.operator}${crit.argument}`)
      .join(';');
  }

  static getReportsEngineFilterParamsToString(
    searchCriteria: Map<string, ISearchCriteria>,
  ): string {
    return Array.from(searchCriteria.values())
      .map((crit: ISearchCriteria) => `${crit.selector}${crit.operator}${crit.argument}`)
      .join('&');
  }

  static changePage(filter: IQueryParams, pagination: IPagination): IQueryParams {
    return {
      ...filter,
      start: (pagination.page - 1) * pagination.size,
      limit: pagination.size,
      currentPage: pagination.page,
    };
  }

  static changeSort(filter: IQueryParams, order: SortOrder): IQueryParams {
    return {
      ...filter,
      start: 0,
      order: order,
    };
  }

  static getRange<T>(filter: IQueryParams, entities: T[] = []): string {
    if (!entities.length) {
      return '';
    }
    return `${filter.start + 1} - ${filter.start + entities.length}`;
  }
}
