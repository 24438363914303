import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IGuardRoute, RoleGuardService } from '@portal/shared/auth/authorization';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';
import { Features } from 'environments/enums/features.enum';
import { FormOrganizationGroupComponent } from './components/form-organization-group/form-organization-group.component';
import { AuthenticationGuard } from '@portal/shared/auth/authentication/src/lib/authentication.guard';
import { ES_CONSTANTS as CONSTANTS } from '@portal/entity-services/interfaces';
import { AuthorizationGuard } from '@portal/shared/auth/authorization/src/lib/authorization.guard';
import { RoutePermissions } from './routing/route-permissions.const';
import { RouteName } from './routing/route-name.enum';
import { CreateOrganizationGroupComponent } from './components/create-organization-group/create-organization-group.component';
import { DetailsOrganizationGroupComponent } from '@apps/portal/src/app/modules/organization-group/components/details-organization-group/details-organization-group.component';

export const routes: IGuardRoute[] = [
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/organization-group`,
    name: RouteName.List,
    component: FormOrganizationGroupComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, FeatureToggle],
    data: {
      featureToggle: {
        featureName: Features.GroupEntities,
      },
    },
  },
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/organization-group/create`,
    name: RouteName.Create,
    component: CreateOrganizationGroupComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, AuthorizationGuard],
    data: {
      permission: RoutePermissions[RouteName.Create],
      featureToggle: {
        featureName: Features.GroupEntities,
      },
    },
  },
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/organization-group/:id`,
    component: DetailsOrganizationGroupComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, AuthorizationGuard],
    data: {
      permission: RoutePermissions[RouteName.Details],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrganizationGroupRoutingModule {}
