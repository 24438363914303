export enum VerificationCapability {
  CardholderAuthData = 'CARDHOLDER_AUTH_DATA',
  ConsumerOnDevice = 'CONSUMER_ON_DEVICE',
  DigitalSignature = 'DIGITAL_SIGNATURE',
  Manual = 'MANUAL',
  ManualSignature = 'MANUAL_SIGNATURE',
  None = 'NONE',
  OnlinePin = 'ONLINE_PIN',
  OfflinePin = 'OFFLINE_PIN',
  OfflineBiometrics = 'OFFLINE_BIOMETRICS',
  OffilineDigitalSignature = 'OFFLINE_DIGITAL_SIGNATURE',
  PkiSignature = 'PKI_SIGNATURE',
  SecureEcommerce = 'SECURE_ECOMMERCE',
  Unknown = 'UNKNOWN',
}
