import { Component, Input } from '@angular/core';
import { TERMS_AND_CONDITIONS } from '@environments';
import { NavbarItemLink } from '@portal/shared/core/src/index';

@Component({
  selector: 'vui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() isPetroUser = false;
  @Input() isLoggedIn = false;
  @Input() supportLink: NavbarItemLink | null = null;
  termsConditionsLink = TERMS_AND_CONDITIONS.TC_URL;
  privacyPolicyLink = TERMS_AND_CONDITIONS.PP_URL;
  legalLink = TERMS_AND_CONDITIONS.LEGAL_URL;
  documentationLink = TERMS_AND_CONDITIONS.DOCUMENTATION_URL;
  apiRefLink = TERMS_AND_CONDITIONS.API_REF;
  currentYear = new Date().getFullYear();
}
