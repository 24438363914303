import { IGuardPermissions } from '@portal/shared/auth/authorization/src';
import { RouteName } from './route-name.enum';
import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';

export const routePermissions: { [key in RouteName]?: IGuardPermissions } = {
  [RouteName.list]: {
    read: [UserRoles.VerifoneAdmin, UserRoles.VerifoneUser],
  },
  [RouteName.details]: {
    read: [UserRoles.VerifoneAdmin, UserRoles.VerifoneUser],
  },
  [RouteName.edit]: {
    read: [UserRoles.VerifoneAdmin, UserRoles.VerifoneUser],
  },
  [RouteName.create]: {
    read: [UserRoles.VerifoneAdmin, UserRoles.VerifoneUser],
  },
};
