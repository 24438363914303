import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import environment from '@environments';
import cloneDeep from 'lodash-es/cloneDeep';
import set from 'lodash-es/set';
import snakeCase from 'lodash-es/snakeCase';
import { ConnectableObservable, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, publishLast, tap } from 'rxjs/operators';
import { APPLICATION_PATH } from './constant/application-path.constant';
import { APP_CONFIG } from './di-tokens/app-token';
import { Application } from './enums/application.enum';
import { IAppConfig } from './interfaces/app-config.interface';
import {
  AppPermission,
  IAppPermissionAssignment,
  IAppPermissions,
  IOrganisationAppPermission,
  IOrganisationAppPermissionManagement,
} from './interfaces/app-permission.interface';

import { IGuardPermissions } from './interfaces/guard-permissions.interface';
import { IGuardRoute } from './interfaces/guard-route.interface';
import { IUserApps } from './interfaces/user-apps.interface';
import { UserRoleEditorMatrix } from '@portal/entity-services/users/src/lib/services/user-role-editor-matrix.service';
import { UserRole } from '@portal/entity-services/interfaces/src/lib/users/enums/user-role.enum';
import {
  ES_CONSTANTS as CONSTANTS,
  IOrganisation,
  IUser,
  UserStatus,
} from '@portal/entity-services/interfaces';
import { AuthenticationService } from '@portal/shared/auth/authentication/src/lib/authentication.service';
import { DataServiceError } from '@ngrx/data';
import { ErrorService } from '@portal/shared/ui/form/src';
import { NotifierService } from '@portal/shared/ui/notifier/src/lib/notifier.service';
import { IAuthorizationInfo } from './interfaces/authorization-info.interface';
import { DEFAULT_FEATURES } from './constant/default-features';
import { UserService } from '@portal/shared/user/src/lib/user.service';
import { APP_NAMES } from '@portal/shared/auth/authorization/src/lib/constant/applications.constant';
import { applicationRolePermissionMatrix } from 'configs/user-roles-matrices/user-roles-matrix.applications';
import { UserRoles } from './enums/user-roles.enum';

declare const $localize;
@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  federatedUser$: Observable<boolean>;
  federatedUserSubject = new Subject<boolean>();
  private RMSApiEndpoint = environment.API_ENDPOINT.RMS;
  private appsPermissionsPrivate: AppPermission[] = [];
  private authInfo$: ConnectableObservable<IAuthorizationInfo>;

  private user: IUser;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    @Inject(APP_CONFIG) private currentAppConfig: IAppConfig,
    private userService: UserService,
    private userRoleEditorMatrix: UserRoleEditorMatrix,
    private authenticationService: AuthenticationService,
    private notifierService: NotifierService,
  ) {
    this.federatedUser$ = this.federatedUserSubject.asObservable();
  }

  get userIsDeleted(): boolean {
    return this.user?.status === UserStatus.Deleted;
  }

  get appsPermissions(): AppPermission[] {
    return (
      (this.appsPermissionsPrivate.length && this.appsPermissionsPrivate) ||
      this.getAppsPermissions()
    );
  }

  get currentUser(): IUser {
    return this.user;
  }

  getRoles(): string[] {
    let roles = [];
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      roles = JSON.parse(atob(accessToken.split('.')[1])).roles;
    }

    return roles.map((role: string) => snakeCase(role)).map((role: string) => role.toUpperCase());
  }

  getUserEntityId(): string {
    let userEntityId = '';
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      userEntityId = JSON.parse(atob(accessToken.split('.')[1])).entity_id;
    }
    return userEntityId;
  }

  getAllPermissions(): string[] {
    let permissionsList: string[] = this.getAccessTokenPermissions();
    permissionsList = permissionsList.filter(permission => permission.startsWith("v"));
    return permissionsList;
  }

  getAppsPermissions(): AppPermission[] {
    const idToken = localStorage.getItem('id_token');
    if (!idToken) return [];
    const appsPermissions: AppPermission[] =
      JSON.parse(atob(idToken.split('.')[1]))?.features?.map((feature) => {
        const featurePermissionData = feature.split('#');
        return { featureId: featurePermissionData[0], application: featurePermissionData[1] };
      }) || [];
    const availableApplications = this.getAvailableAppsForTheUser();

    this.appsPermissionsPrivate = appsPermissions.filter((appPermission) =>
      availableApplications.some((application) => application === appPermission.application),
    );
    return this.appsPermissionsPrivate;
  }

  getAccessTokenPermissions(): string[] {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      const permissions = JSON.parse(atob(accessToken.split('.')[1]))?.permissions.split(' ');
      return permissions;
    }
  }

  addAppsPermissionsToEntity(
    entityUid: string,
    appsPermissions: IAppPermissionAssignment[],
  ): Observable<void> {
    const url = `${this.RMSApiEndpoint}/entities/${entityUid}/features`;
    return this.http.post<void>(url, { features: appsPermissions });
  }

  updateEntityAppsPermissions(
    entityUid: string,
    appsPermissions: IAppPermissionAssignment[],
  ): Observable<void> {
    const url = `${this.RMSApiEndpoint}/entities/${entityUid}/features`;
    return this.http.put<void>(url, { features: appsPermissions });
  }

  deleteEntityFeature(entityUid: string, featureId: string): Observable<void> {
    const url = `${this.RMSApiEndpoint}/entities/${entityUid}/features/${featureId}`;
    return this.http.delete<void>(url);
  }

  getApps(appsPermissions: AppPermission[]): IUserApps[] {
    const apps = appsPermissions.map((appPermission) => appPermission.application);

    return [...new Set(apps)].map((app) => ({
      application: app,
      path: APPLICATION_PATH[app],
      name: APP_NAMES[app],
    }));
  }

  getAppFeatures(application: Application, appsPermissions: AppPermission[]): AppPermission[] {
    return appsPermissions.filter((appPermission) => appPermission.application === application);
  }

  getCurrentAppPermissions(): AppPermission[] {
    if (!this.appsPermissions.length) {
      this.getAppsPermissions();
    }
    return this.appsPermissions.filter(
      (appPermission) => appPermission.application === this.currentAppConfig.name,
    );
  }

  getAppsPermissionsByEntity(entityUid: string): Observable<AppPermission[]> {
    const url = `${this.RMSApiEndpoint}/entities/${entityUid}/features`;
    return this.http
      .get(url)
      .pipe(map((response: IOrganisationAppPermission) => response.features));
  }

  getDetailedAppsPermissionsByEntity(
    entityUid: string,
  ): Observable<IOrganisationAppPermissionManagement> {
    const url = `${this.RMSApiEndpoint}/entities/${entityUid}/features/detail`;
    return this.http.get<IOrganisationAppPermissionManagement>(url);
  }

  getAllAppsPermissions(): Observable<AppPermission[]> {
    const url = `${this.RMSApiEndpoint}/features`;
    return this.http
      .get<IOrganisationAppPermission>(url)
      .pipe(map((verifoneFeatures: IAppPermissions) => verifoneFeatures.features));
  }

  getRouteSnapshot(routePath: string): ActivatedRouteSnapshot {
    const snapshot = cloneDeep(this.activatedRoute.snapshot);
    set(snapshot, 'routeConfig.path', routePath);
    const designatedRoute: Route = this.router.config.find((config: IGuardRoute) => {
      return config.path && config.path.includes(snapshot.routeConfig.path);
    });
    if (designatedRoute) {
      set(snapshot, 'routeConfig.data', designatedRoute.data);
      return snapshot;
    }
    return null;
  }

  getLazyRouteSnapshot(
    routePath: string,
    routePermissions: IGuardPermissions,
  ): ActivatedRouteSnapshot {
    if (routePermissions) {
      const snapshot = cloneDeep(this.activatedRoute.snapshot);
      set(snapshot, 'routeConfig.path', routePath);
      set(snapshot, 'routeConfig.data.permission', routePermissions);
      return snapshot;
    }
    return null;
  }

  /**
   * Checking whether the user has permission to edit a user with certain roles
   * @param roles - Roles of the user we want to edit
   */
  hasPermissionToManage(roles: UserRole[]): boolean {
    const myRoles = this.getRoles();
    const editableRoles = this.userRoleEditorMatrix.getEditableRoles(myRoles);

    return roles.some((v) => editableRoles.includes(v));
  }

  authorize({ forceAuthorization = false } = {}): Observable<IAuthorizationInfo> {
    if (forceAuthorization && this.authInfo$) {
      this.authInfo$.connect().unsubscribe();
      this.authInfo$ = null;
    }

    if (this.authInfo$) return this.authInfo$;

    this.authInfo$ = this.userService.getByKey(this.authenticationService.userUid).pipe(
      tap((user) => {
        // IDM doesn't allow disabled users to log in, but we should double check on portal side
        if (user.status === UserStatus.Inactive) {
          throw new HttpErrorResponse({ status: 403 });
        }
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse || error instanceof DataServiceError) {
          const statusCode = ErrorService.getStatusCode(error);
          const messages = {
            401: $localize`We are not able to find your account in our systems`,
            403: $localize`The account you are using doesn't have the correct permissions to access Verifone Cloud Services`,
          };
          const message = messages[statusCode];
          if (message) {
            this.notifierService.error(message);
            return throwError(
              new Error(`Failed to read account information (HTTP status ${statusCode})`),
            );
          }
        }
        return throwError(error);
      }),
      tap((user) => {
        this.user = user;
      }),
      map((user) => ({ user, appsPermissions: this.getAppsPermissions() })),
      tap(() => {
        // todo: consider either moving this effect elsewhere, e.g. by lifting it to the caller site,
        //  or incorporating it into the authInfo$ / IAuthorizationInfo (in which case it won't be a pure effect anymore)
        this.getSingleUserDetails(this.user.entity?.entityUid);
      }),
      publishLast(),
    ) as ConnectableObservable<IAuthorizationInfo>;
    this.authInfo$.connect();
    return this.authInfo$;
  }

  isAuthorized(): Observable<boolean> {
    const userUid = this.authenticationService.userUid;

    if (!userUid) return of(false);

    if (this.user) {
      return of(this.checkAuthorization());
    }

    return this.authorize().pipe(map(() => this.checkAuthorization()));
  }

  isApplicationUser(application: Application, userRoles?: UserRole[] | UserRoles[]): boolean {
    const currentUserRoles = userRoles || this.getRoles();
    return currentUserRoles.some((userRole) =>
      applicationRolePermissionMatrix[application].includes(userRole as UserRole),
    );
  }

  isApplicationUserForAllAssignedRoles(
    application: Application,
    userRoles?: UserRole[] | UserRoles[],
  ): boolean {
    const currentUserRoles = userRoles || this.getRoles();
    return currentUserRoles.every((userRole) =>
      applicationRolePermissionMatrix[application].includes(userRole as UserRole),
    );
  }

  private checkAuthorization(): boolean {
    return (
      (!!this.appsPermissions.length || !!DEFAULT_FEATURES.length) &&
      this.user.status !== UserStatus.Deleted &&
      !!this.getRoles().length
    );
  }

  private getAvailableAppsForTheUser(): Application[] {
    const currentUserRoles = this.getRoles();

    return (Object.keys(applicationRolePermissionMatrix) as Application[]).filter((application) =>
      this.isApplicationUser(application, currentUserRoles),
    );
  }

  private getSingleUserDetails(entityUid: string): void {
    this.http
      .get(`${CONSTANTS.ENTITY_SERVICE.SINGLE_USER_RMS_ENTITY_SERVICE}/${entityUid}`)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          throw error;
        }),
      )
      .subscribe(
        (entity: IOrganisation) => {
          this.federatedUserSubject.next(entity.federationStatus);
        },
        () => {
          this.federatedUserSubject.next(false);
        },
      );
  }
}
