import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  constructor(private gtmService: GoogleTagManagerService) {}

  init(): void {
    this.gtmService.addGtmToDom();
  }
}
