import { Injectable } from '@angular/core';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({
  providedIn: 'root',
})
export class SecondPartOfSubdivisionsList {
  list: ISelectInput[];
  keyValue = {
    ['DK-81']: $localize`Nordjylland`,
    ['DK-82']: $localize`Midtjylland`,
    ['DK-83']: $localize`Syddanmark`,
    ['DK-84']: $localize`Hovedstaden`,
    ['DK-85']: $localize`Sjælland`,
    ['DJ-AR']: $localize`Arta`,
    ['DJ-AS']: $localize`Ali Sabieh`,
    ['DJ-DI']: $localize`Dikhīl`,
    ['DJ-DJ']: $localize`Djibouti`,
    ['DJ-OB']: $localize`Obock`,
    ['DJ-TA']: $localize`Tadjourah`,
    ['DM-02']: $localize`Saint Andrew`,
    ['DM-03']: $localize`Saint David`,
    ['DM-04']: $localize`Saint George`,
    ['DM-05']: $localize`Saint John`,
    ['DM-06']: $localize`Saint Joseph`,
    ['DM-07']: $localize`Saint Luke`,
    ['DM-08']: $localize`Saint Mark`,
    ['DM-09']: $localize`Saint Patrick`,
    ['DM-10']: $localize`Saint Paul`,
    ['DM-11']: $localize`Saint Peter`,
    ['DO-01']: $localize`Distrito Nacional,District`,
    ['DO-02']: $localize`Azua`,
    ['DO-03']: $localize`Baoruco`,
    ['DO-04']: $localize`Barahona`,
    ['DO-05']: $localize`Dajabón`,
    ['DO-06']: $localize`Duarte`,
    ['DO-07']: $localize`La Estrelleta`,
    ['DO-08']: $localize`El Seibo`,
    ['DO-09']: $localize`Espaillat`,
    ['DO-10']: $localize`Independencia`,
    ['DO-11']: $localize`La Altagracia`,
    ['DO-12']: $localize`La Romana`,
    ['DO-13']: $localize`La Vega`,
    ['DO-14']: $localize`María Trinidad Sánchez`,
    ['DO-15']: $localize`Monte Cristi`,
    ['DO-16']: $localize`Pedernales`,
    ['DO-17']: $localize`Peravia`,
    ['DO-18']: $localize`Puerto Plata`,
    ['DO-19']: $localize`Hermanas Mirabal`,
    ['DO-20']: $localize`Samaná`,
    ['DO-21']: $localize`San Cristóbal`,
    ['DO-22']: $localize`San Juan`,
    ['DO-23']: $localize`San Pedro de Macorís`,
    ['DO-24']: $localize`Sánchez Ramírez`,
    ['DO-25']: $localize`Santiago`,
    ['DO-26']: $localize`Santiago Rodríguez`,
    ['DO-27']: $localize`Valverde`,
    ['DO-28']: $localize`Monseñor Nouel`,
    ['DO-29']: $localize`Monte Plata`,
    ['DO-30']: $localize`Hato Mayor`,
    ['DO-31']: $localize`San José de Ocoa`,
    ['DO-32']: $localize`Santo Domingo`,
    ['DO-33']: $localize`Cibao Nordeste`,
    ['DO-34']: $localize`Cibao Noroeste`,
    ['DO-35']: $localize`Cibao Norte`,
    ['DO-36']: $localize`Cibao Sur`,
    ['DO-37']: $localize`El Valle`,
    ['DO-38']: $localize`Enriquillo`,
    ['DO-39']: $localize`Higuamo`,
    ['DO-40']: $localize`Ozama`,
    ['DO-41']: $localize`Valdesia`,
    ['DO-42']: $localize`Yuma`,
    ['EC-A']: $localize`Azuay`,
    ['EC-B']: $localize`Bolívar`,
    ['EC-C']: $localize`Carchi`,
    ['EC-D']: $localize`Orellana`,
    ['EC-E']: $localize`Esmeraldas`,
    ['EC-F']: $localize`Cañar`,
    ['EC-G']: $localize`Guayas`,
    ['EC-H']: $localize`Chimborazo`,
    ['EC-I']: $localize`Imbabura`,
    ['EC-L']: $localize`Loja`,
    ['EC-M']: $localize`Manabí`,
    ['EC-N']: $localize`Napo`,
    ['EC-O']: $localize`El Oro`,
    ['EC-P']: $localize`Pichincha`,
    ['EC-R']: $localize`Los Ríos`,
    ['EC-SD']: $localize`Santo Domingo de los Tsáchilas`,
    ['EC-SE']: $localize`Santa Elena`,
    ['EC-S']: $localize`Morona-Santiago`,
    ['EC-T']: $localize`Tungurahua`,
    ['EC-U']: $localize`Sucumbíos`,
    ['EC-W']: $localize`Galápagos`,
    ['EC-X']: $localize`Cotopaxi`,
    ['EC-Y']: $localize`Pastaza`,
    ['EC-Z']: $localize`Zamora-Chinchipe`,
    ['EG-ALX']: $localize`Al Iskandarīyah`,
    ['EG-ASN']: $localize`Aswān`,
    ['EG-AST']: $localize`Asyūţ`,
    ['EG-BA']: $localize`Al Baḩr al Aḩmar`,
    ['EG-BH']: $localize`Al Buḩayrah`,
    ['EG-BNS']: $localize`Banī Suwayf`,
    ['EG-C']: $localize`Al Qāhirah`,
    ['EG-DK']: $localize`Ad Daqahlīyah`,
    ['EG-DT']: $localize`Dumyāţ`,
    ['EG-FYM']: $localize`Al Fayyūm`,
    ['EG-GH']: $localize`Al Gharbīyah`,
    ['EG-GZ']: $localize`Al Jīzah`,
    ['EG-IS']: $localize`Al Ismā'īlīyah`,
    ['EG-JS']: $localize`Janūb Sīnā'`,
    ['EG-KB']: $localize`Al Qalyūbīyah`,
    ['EG-KFS']: $localize`Kafr ash Shaykh`,
    ['EG-KN']: $localize`Qinā,Governorate`,
    ['EG-LX']: $localize`Al Uqşur`,
    ['EG-MN']: $localize`Al Minyā`,
    ['EG-MNF']: $localize`Al Minūfīyah`,
    ['EG-MT']: $localize`Maţrūḩ`,
    ['EG-PTS']: $localize`Būr Sa‘īd`,
    ['EG-SHG']: $localize`Sūhāj`,
    ['EG-SHR']: $localize`Ash Sharqīyah`,
    ['EG-SIN']: $localize`Shamāl Sīnā'`,
    ['EG-SUZ']: $localize`As Suways`,
    ['EG-WAD']: $localize`Al Wādī al Jadīd`,
    ['SV-AH']: $localize`Ahuachapán`,
    ['SV-CA']: $localize`Cabañas`,
    ['SV-CH']: $localize`Chalatenango`,
    ['SV-CU']: $localize`Cuscatlán`,
    ['SV-LI']: $localize`La Libertad`,
    ['SV-MO']: $localize`Morazán`,
    ['SV-PA']: $localize`La Paz`,
    ['SV-SA']: $localize`Santa Ana`,
    ['SV-SM']: $localize`San Miguel`,
    ['SV-SO']: $localize`Sonsonate`,
    ['SV-SS']: $localize`San Salvador`,
    ['SV-SV']: $localize`San Vicente`,
    ['SV-UN']: $localize`La Unión`,
    ['SV-US']: $localize`Usulután`,
    ['GQ-AN']: $localize`Annobón`,
    ['GQ-BN']: $localize`Bioko Nord`,
    ['GQ-BS']: $localize`Bioko Sud`,
    ['GQ-C']: $localize`Região Continental`,
    ['GQ-CS']: $localize`Centro Sud`,
    ['GQ-I']: $localize`Região Insular`,
    ['GQ-KN']: $localize`Kié-Ntem`,
    ['GQ-LI']: $localize`Litoral`,
    ['GQ-WN']: $localize`Wele-Nzas`,
    ['ER-AN']: $localize`Ansabā`,
    ['ER-DK']: $localize`Debubawi K’eyyĭḥ Baḥri`,
    ['ER-DU']: $localize`Al Janūbī`,
    ['ER-GB']: $localize`Gash-Barka`,
    ['ER-MA']: $localize`Al Awsaţ`,
    ['ER-SK']: $localize`Semienawi K’eyyĭḥ Baḥri`,
    ['EE-37']: $localize`Harjumaa`,
    ['EE-39']: $localize`Hiiumaa`,
    ['EE-44']: $localize`Ida-Virumaa`,
    ['EE-49']: $localize`Jõgevamaa`,
    ['EE-51']: $localize`Järvamaa`,
    ['EE-57']: $localize`Läänemaa`,
    ['EE-59']: $localize`Lääne-Virumaa`,
    ['EE-65']: $localize`Põlvamaa`,
    ['EE-67']: $localize`Pärnumaa`,
    ['EE-70']: $localize`Raplamaa`,
    ['EE-74']: $localize`Saaremaa`,
    ['EE-78']: $localize`Tartumaa`,
    ['EE-82']: $localize`Valgamaa`,
    ['EE-84']: $localize`Viljandimaa`,
    ['EE-86']: $localize`Võrumaa`,
    ['ET-AA']: $localize`Addis Ababa`,
    ['ET-AF']: $localize`Afar,State`,
    ['ET-AM']: $localize`Amara,State`,
    ['ET-BE']: $localize`Benshangul-Gumaz`,
    ['ET-DD']: $localize`Dire Dawa`,
    ['ET-GA']: $localize`Gambēla Hizboch`,
    ['ET-HA']: $localize`Harari People`,
    ['ET-OR']: $localize`Oromia`,
    ['ET-SN']: $localize`Southern Nations, Nationalities and Peoples`,
    ['ET-SO']: $localize`Somali`,
    ['ET-TI']: $localize`Tigrai`,
    ['FK-FK']: $localize`Falkland Islands`,
    ['FO-FO']: $localize`Faroe Islands`,
    ['FJ-01']: $localize`Ba`,
    ['FJ-02']: $localize`Bua`,
    ['FJ-03']: $localize`Cakaudrove`,
    ['FJ-04']: $localize`Kadavu`,
    ['FJ-05']: $localize`Lau`,
    ['FJ-06']: $localize`Lomaiviti`,
    ['FJ-07']: $localize`Macuata`,
    ['FJ-08']: $localize`Nadroga and Navosa`,
    ['FJ-09']: $localize`Naitasiri`,
    ['FJ-10']: $localize`Namosi`,
    ['FJ-11']: $localize`Ra`,
    ['FJ-12']: $localize`Rewa`,
    ['FJ-13']: $localize`Serua`,
    ['FJ-14']: $localize`Tailevu`,
    ['FJ-C']: $localize`Central`,
    ['FJ-E']: $localize`Eastern`,
    ['FJ-N']: $localize`Northern`,
    ['FJ-R']: $localize`Rotuma`,
    ['FJ-W']: $localize`Western`,
    ['FI-01']: $localize`Ahvenanmaan maakunta`,
    ['FI-02']: $localize`Etelä-Karjala`,
    ['FI-03']: $localize`Etelä-Pohjanmaa`,
    ['FI-04']: $localize`Etelä-Savo`,
    ['FI-05']: $localize`Kainuu`,
    ['FI-06']: $localize`Egentliga Tavastland`,
    ['FI-07']: $localize`Keski-Pohjanmaa`,
    ['FI-08']: $localize`Keski-Suomi`,
    ['FI-09']: $localize`Kymenlaakso`,
    ['FI-10']: $localize`Lappi,Region`,
    ['FI-11']: $localize`Birkaland`,
    ['FI-12']: $localize`Österbotten`,
    ['FI-13']: $localize`Norra Karelen`,
    ['FI-14']: $localize`Norra Österbotten`,
    ['FI-15']: $localize`Norra Savolax`,
    ['FI-16']: $localize`Päijänne-Tavastland`,
    ['FI-17']: $localize`Satakunda`,
    ['FI-18']: $localize`Nyland,Region`,
    ['FI-19']: $localize`Egentliga Finland`,
    ['FR-01']: $localize`Ain`,
    ['FR-02']: $localize`Aisne`,
    ['FR-03']: $localize`Allier`,
    ['FR-04']: $localize`Alpes-de-Haute-Provence`,
    ['FR-05']: $localize`Hautes-Alpes`,
    ['FR-06']: $localize`Alpes-Maritimes`,
    ['FR-07']: $localize`Ardèche`,
    ['FR-08']: $localize`Ardennes`,
    ['FR-09']: $localize`Ariège`,
    ['FR-10']: $localize`Aube`,
    ['FR-11']: $localize`Aude`,
    ['FR-12']: $localize`Aveyron`,
    ['FR-13']: $localize`Bouches-du-Rhône`,
    ['FR-14']: $localize`Calvados`,
    ['FR-15']: $localize`Cantal`,
    ['FR-16']: $localize`Charente`,
    ['FR-17']: $localize`Charente-Maritime`,
    ['FR-18']: $localize`Cher`,
    ['FR-19']: $localize`Corrèze`,
    ['FR-21']: $localize`Côte-d'Or`,
    ['FR-22']: $localize`Côtes-d'Armor`,
    ['FR-23']: $localize`Creuse`,
    ['FR-24']: $localize`Dordogne`,
    ['FR-25']: $localize`Doubs`,
    ['FR-26']: $localize`Drôme`,
    ['FR-27']: $localize`Eure`,
    ['FR-28']: $localize`Eure-et-Loir`,
    ['FR-29']: $localize`Finistère`,
    ['FR-2A']: $localize`Corse-du-Sud`,
    ['FR-2B']: $localize`Haute-Corse`,
    ['FR-30']: $localize`Gard`,
    ['FR-31']: $localize`Haute-Garonne`,
    ['FR-32']: $localize`Gers`,
    ['FR-33']: $localize`Gironde`,
    ['FR-34']: $localize`Hérault`,
    ['FR-35']: $localize`Ille-et-Vilaine`,
    ['FR-36']: $localize`Indre`,
    ['FR-37']: $localize`Indre-et-Loire`,
    ['FR-38']: $localize`Isère`,
    ['FR-39']: $localize`Jura`,
    ['FR-40']: $localize`Landes`,
    ['FR-41']: $localize`Loir-et-Cher`,
    ['FR-42']: $localize`Loire`,
    ['FR-43']: $localize`Haute-Loire`,
    ['FR-44']: $localize`Loire-Atlantique`,
    ['FR-45']: $localize`Loiret`,
    ['FR-46']: $localize`Lot`,
    ['FR-47']: $localize`Lot-et-Garonne`,
    ['FR-48']: $localize`Lozère`,
    ['FR-49']: $localize`Maine-et-Loire`,
    ['FR-50']: $localize`Manche`,
    ['FR-51']: $localize`Marne`,
    ['FR-52']: $localize`Haute-Marne`,
    ['FR-53']: $localize`Mayenne`,
    ['FR-54']: $localize`Meurthe-et-Moselle`,
    ['FR-55']: $localize`Meuse`,
    ['FR-56']: $localize`Morbihan`,
    ['FR-57']: $localize`Moselle`,
    ['FR-58']: $localize`Nièvre`,
    ['FR-59']: $localize`Nord`,
    ['FR-60']: $localize`Oise`,
    ['FR-61']: $localize`Orne`,
    ['FR-62']: $localize`Pas-de-Calais`,
    ['FR-63']: $localize`Puy-de-Dôme`,
    ['FR-64']: $localize`Pyrénées-Atlantiques`,
    ['FR-65']: $localize`Hautes-Pyrénées`,
    ['FR-66']: $localize`Pyrénées-Orientales`,
    ['FR-67']: $localize`Bas-Rhin`,
    ['FR-68']: $localize`Haut-Rhin`,
    ['FR-69']: $localize`Rhône`,
    ['FR-70']: $localize`Haute-Saône`,
    ['FR-71']: $localize`Saône-et-Loire`,
    ['FR-72']: $localize`Sarthe`,
    ['FR-73']: $localize`Savoie`,
    ['FR-74']: $localize`Haute-Savoie`,
    ['FR-75']: $localize`Paris`,
    ['FR-76']: $localize`Seine-Maritime`,
    ['FR-77']: $localize`Seine-et-Marne`,
    ['FR-78']: $localize`Yvelines`,
    ['FR-79']: $localize`Deux-Sèvres`,
    ['FR-80']: $localize`Somme`,
    ['FR-81']: $localize`Tarn`,
    ['FR-82']: $localize`Tarn-et-Garonne`,
    ['FR-83']: $localize`Var`,
    ['FR-84']: $localize`Vaucluse`,
    ['FR-85']: $localize`Vendée`,
    ['FR-86']: $localize`Vienne`,
    ['FR-87']: $localize`Haute-Vienne`,
    ['FR-88']: $localize`Vosges`,
    ['FR-89']: $localize`Yonne`,
    ['FR-90']: $localize`Territoire de Belfort`,
    ['FR-91']: $localize`Essonne`,
    ['FR-92']: $localize`Hauts-de-Seine`,
    ['FR-93']: $localize`Seine-Saint-Denis`,
    ['FR-94']: $localize`Val-de-Marne`,
    ['FR-95']: $localize`Val-d'Oise`,
    ['FR-A']: $localize`Alsace`,
    ['FR-B']: $localize`Aquitaine`,
    ['FR-BL']: $localize`Saint-Barthélemy`,
    ['FR-C']: $localize`Auvergne`,
    ['FR-CP']: $localize`Clipperton`,
    ['FR-D']: $localize`Bourgogne`,
    ['FR-E']: $localize`Bretagne`,
    ['FR-F']: $localize`Centre`,
    ['FR-G']: $localize`Champagne-Ardenne`,
    ['FR-GF']: $localize`Guyane`,
    ['FR-GP']: $localize`Guadeloupe`,
    ['FR-H']: $localize`Corse`,
    ['FR-I']: $localize`Franche-Comté`,
    ['FR-J']: $localize`Île-de-France`,
    ['FR-K']: $localize`Languedoc-Roussillon`,
    ['FR-L']: $localize`Limousin`,
    ['FR-MF']: $localize`Saint-Martin`,
    ['FR-M']: $localize`Lorraine`,
    ['FR-MQ']: $localize`Martinique`,
    ['FR-NC']: $localize`Nouvelle-Calédonie`,
    ['FR-N']: $localize`Midi-Pyrénées`,
    ['FR-O']: $localize`Nord-Pas-de-Calais`,
    ['FR-P']: $localize`Basse-Normandie`,
    ['FR-PF']: $localize`Polynésie française`,
    ['FR-PM']: $localize`Saint-Pierre-et-Miquelon`,
    ['FR-Q']: $localize`Haute-Normandie`,
    ['FR-RE']: $localize`La Réunion`,
    ['FR-R']: $localize`Pays-de-la-Loire`,
    ['FR-S']: $localize`Picardie`,
    ['FR-TF']: $localize`Terres australes françaises`,
    ['FR-T']: $localize`Poitou-Charentes`,
    ['FR-U']: $localize`Provence-Alpes-Côte-d'Azur`,
    ['FR-V']: $localize`Rhône-Alpes`,
    ['FR-WF']: $localize`Wallis-et-Futuna`,
    ['FR-YT']: $localize`Mayotte`,
    ['GF-GF']: $localize`French Guiana`,
    ['PF-PF']: $localize`French Polynesia`,
    ['TF-TF']: $localize`French Southern Territories`,
    ['GA-1']: $localize`Estuaire`,
    ['GA-2']: $localize`Haut-Ogooué`,
    ['GA-3']: $localize`Moyen-Ogooué`,
    ['GA-4']: $localize`Ngounié`,
    ['GA-5']: $localize`Nyanga`,
    ['GA-6']: $localize`Ogooué-Ivindo`,
    ['GA-7']: $localize`Ogooué-Lolo`,
    ['GA-8']: $localize`Ogooué-Maritime`,
    ['GA-9']: $localize`Woleu-Ntem`,
    ['GM-B']: $localize`Banjul`,
    ['GM-L']: $localize`Lower River`,
    ['GM-M']: $localize`Central River`,
    ['GM-N']: $localize`North Bank`,
    ['GM-U']: $localize`Upper River`,
    ['GM-W']: $localize`Western`,
    ['GE-AB']: $localize`Abkhazia`,
    ['GE-AJ']: $localize`Ajaria`,
    ['GE-GU']: $localize`Guria`,
    ['GE-IM']: $localize`Imereti`,
    ['GE-KA']: $localize`K'akheti`,
    ['GE-KK']: $localize`Kvemo Kartli`,
    ['GE-MM']: $localize`Mtskheta-Mtianeti`,
    ['GE-RL']: $localize`Rach'a-Lechkhumi-Kvemo Svaneti`,
    ['GE-SJ']: $localize`Samtskhe-Javakheti`,
    ['GE-SK']: $localize`Shida Kartli`,
    ['GE-SZ']: $localize`Samegrelo-Zemo Svaneti`,
    ['GE-TB']: $localize`Tbilisi`,
    ['DE-BB']: $localize`Brandenburg`,
    ['DE-BE']: $localize`Berlin`,
    ['DE-BW']: $localize`Baden-Württemberg`,
    ['DE-BY']: $localize`Bayern`,
    ['DE-HB']: $localize`Bremen`,
    ['DE-HE']: $localize`Hessen`,
    ['DE-HH']: $localize`Hamburg`,
    ['DE-MV']: $localize`Mecklenburg-Vorpommern`,
    ['DE-NI']: $localize`Niedersachsen`,
    ['DE-NW']: $localize`Nordrhein-Westfalen`,
    ['DE-RP']: $localize`Rheinland-Pfalz`,
    ['DE-SH']: $localize`Schleswig-Holstein`,
    ['DE-SL']: $localize`Saarland`,
    ['DE-SN']: $localize`Sachsen`,
    ['DE-ST']: $localize`Sachsen-Anhalt`,
    ['DE-TH']: $localize`Thüringen`,
    ['GH-AA']: $localize`Greater Accra`,
    ['GH-AH']: $localize`Ashanti`,
    ['GH-BA']: $localize`Brong-Ahafo`,
    ['GH-CP']: $localize`Central`,
    ['GH-EP']: $localize`Eastern`,
    ['GH-NP']: $localize`Northern`,
    ['GH-TV']: $localize`Volta`,
    ['GH-UE']: $localize`Upper East`,
    ['GH-UW']: $localize`Upper West`,
    ['GH-WP']: $localize`Western`,
    ['GI-GI']: $localize`Gibraltar`,
    ['GR-01']: $localize`Aitoloakarnanía`,
    ['GR-03']: $localize`Voiotía`,
    ['GR-04']: $localize`Évvoia`,
    ['GR-05']: $localize`Evrytanía`,
    ['GR-06']: $localize`Fthiótida`,
    ['GR-07']: $localize`Fokída`,
    ['GR-11']: $localize`Argolída`,
    ['GR-12']: $localize`Arkadía`,
    ['GR-13']: $localize`Achaḯa`,
    ['GR-14']: $localize`Ileía`,
    ['GR-15']: $localize`Korinthía`,
    ['GR-16']: $localize`Lakonía`,
    ['GR-17']: $localize`Messinía`,
    ['GR-21']: $localize`Zákynthos`,
    ['GR-22']: $localize`Kérkyra`,
    ['GR-23']: $localize`Kefallinía`,
    ['GR-24']: $localize`Lefkáda`,
    ['GR-31']: $localize`Árta`,
    ['GR-32']: $localize`Thesprotía`,
    ['GR-33']: $localize`Ioánnina`,
    ['GR-34']: $localize`Préveza`,
    ['GR-41']: $localize`Kardítsa`,
    ['GR-42']: $localize`Lárisa`,
    ['GR-43']: $localize`Magnisía`,
    ['GR-44']: $localize`Tríkala`,
    ['GR-51']: $localize`Grevená`,
    ['GR-52']: $localize`Dráma`,
    ['GR-53']: $localize`Imathía`,
    ['GR-54']: $localize`Thessaloníki`,
    ['GR-55']: $localize`Kavála`,
    ['GR-56']: $localize`Kastoriá`,
    ['GR-57']: $localize`Kilkís`,
    ['GR-58']: $localize`Kozáni`,
    ['GR-59']: $localize`Pélla`,
    ['GR-61']: $localize`Piería`,
    ['GR-62']: $localize`Sérres`,
    ['GR-63']: $localize`Flórina`,
    ['GR-64']: $localize`Chalkidikí`,
    ['GR-69']: $localize`Ágion Óros`,
    ['GR-71']: $localize`Évros`,
    ['GR-72']: $localize`Xánthi`,
    ['GR-73']: $localize`Rodópi`,
    ['GR-81']: $localize`Dodekánisa`,
    ['GR-82']: $localize`Kykládes`,
    ['GR-83']: $localize`Lésvos`,
    ['GR-84']: $localize`Sámos`,
    ['GR-85']: $localize`Chíos`,
    ['GR-91']: $localize`Irakleío`,
    ['GR-92']: $localize`Lasíthi`,
    ['GR-93']: $localize`Rethýmnis`,
    ['GR-94']: $localize`Chaniá`,
    ['GR-A1']: $localize`Attikí`,
    ['GR-A']: $localize`Anatolikí Makedonía kai Thráki`,
    ['GR-B']: $localize`Kentrikí Makedonía`,
    ['GR-C']: $localize`Dytikí Makedonía`,
    ['GR-D']: $localize`Ípeiros`,
    ['GR-E']: $localize`Thessalía`,
    ['GR-F']: $localize`Ionía Nísia`,
    ['GR-G']: $localize`Dytikí Elláda`,
    ['GR-H']: $localize`Stereá Elláda`,
    ['GR-I']: $localize`Attikí`,
    ['GR-J']: $localize`Peloponnísos`,
    ['GR-K']: $localize`Voreío Aigaío`,
    ['GR-L']: $localize`Notío Aigaío`,
    ['GR-M']: $localize`Krítí`,
    ['GL-KU']: $localize`Kommune Kujalleq`,
    ['GL-QA']: $localize`Qaasuitsup Kommunia`,
    ['GL-QE']: $localize`Qeqqata Kommunia`,
    ['GL-SM']: $localize`Kommuneqarfik Sermersooq`,
    ['GD-01']: $localize`Saint Andrew`,
    ['GD-02']: $localize`Saint David`,
    ['GD-03']: $localize`Saint George`,
    ['GD-04']: $localize`Saint John`,
    ['GD-05']: $localize`Saint Mark`,
    ['GD-06']: $localize`Saint Patrick`,
    ['GD-10']: $localize`Southern Grenadine Islands`,
    ['GP-GP']: $localize`Guadeloupe`,
    ['GU-GU']: $localize`Guam`,
    ['GT-AV']: $localize`Alta Verapaz`,
    ['GT-BV']: $localize`Baja Verapaz`,
    ['GT-CM']: $localize`Chimaltenango`,
    ['GT-CQ']: $localize`Chiquimula`,
    ['GT-ES']: $localize`Escuintla`,
    ['GT-GU']: $localize`Guatemala`,
    ['GT-HU']: $localize`Huehuetenango`,
    ['GT-IZ']: $localize`Izabal`,
    ['GT-JA']: $localize`Jalapa`,
    ['GT-JU']: $localize`Jutiapa`,
    ['GT-PE']: $localize`Petén`,
    ['GT-PR']: $localize`El Progreso`,
    ['GT-QC']: $localize`Quiché`,
    ['GT-QZ']: $localize`Quetzaltenango`,
    ['GT-RE']: $localize`Retalhuleu`,
    ['GT-SA']: $localize`Sacatepéquez`,
    ['GT-SM']: $localize`San Marcos`,
    ['GT-SO']: $localize`Sololá`,
    ['GT-SR']: $localize`Santa Rosa`,
    ['GT-SU']: $localize`Suchitepéquez`,
    ['GT-TO']: $localize`Totonicapán`,
    ['GT-ZA']: $localize`Zacapa`,
    ['GG-GG']: $localize`Guernsey`,
    ['GW-BA']: $localize`Bafatá`,
    ['GW-BL']: $localize`Bolama`,
    ['GW-BM']: $localize`Biombo`,
    ['GW-BS']: $localize`Bissau`,
    ['GW-CA']: $localize`Cacheu`,
    ['GW-GA']: $localize`Gabú`,
    ['GW-L']: $localize`Leste`,
    ['GW-N']: $localize`Norte`,
    ['GW-OI']: $localize`Oio`,
    ['GW-QU']: $localize`Quinara`,
    ['GW-S']: $localize`Sul`,
    ['GW-TO']: $localize`Tombali`,
    ['GN-B']: $localize`Boké`,
    ['GN-BE']: $localize`Beyla`,
    ['GN-BF']: $localize`Boffa`,
    ['GN-BK']: $localize`Boké`,
    ['GN-C']: $localize`Conakry`,
    ['GN-CO']: $localize`Coyah`,
    ['GN-DB']: $localize`Dabola`,
    ['GN-DI']: $localize`Dinguiraye`,
    ['GN-D']: $localize`Kindia`,
    ['GN-DL']: $localize`Dalaba`,
    ['GN-DU']: $localize`Dubréka`,
    ['GN-FA']: $localize`Faranah`,
    ['GN-F']: $localize`Faranah`,
    ['GN-FO']: $localize`Forécariah`,
    ['GN-FR']: $localize`Fria`,
    ['GN-GA']: $localize`Gaoual`,
    ['GN-GU']: $localize`Guékédou`,
    ['GN-KA']: $localize`Kankan`,
    ['GN-KB']: $localize`Koubia`,
    ['GN-KD']: $localize`Kindia`,
    ['GN-KE']: $localize`Kérouané`,
    ['GN-K']: $localize`Kankan`,
    ['GN-KN']: $localize`Koundara`,
    ['GN-KO']: $localize`Kouroussa`,
    ['GN-KS']: $localize`Kissidougou`,
    ['GN-LA']: $localize`Labé`,
    ['GN-LE']: $localize`Lélouma`,
    ['GN-L']: $localize`Labé`,
    ['GN-LO']: $localize`Lola`,
    ['GN-MC']: $localize`Macenta`,
    ['GN-MD']: $localize`Mandiana`,
    ['GN-ML']: $localize`Mali`,
    ['GN-M']: $localize`Mamou`,
    ['GN-MM']: $localize`Mamou`,
    ['GN-N']: $localize`Nzérékoré`,
    ['GN-NZ']: $localize`Nzérékoré`,
    ['GN-PI']: $localize`Pita`,
    ['GN-SI']: $localize`Siguiri`,
    ['GN-TE']: $localize`Télimélé`,
    ['GN-TO']: $localize`Tougué`,
    ['GN-YO']: $localize`Yomou`,
    ['GY-BA']: $localize`Barima-Waini`,
    ['GY-CU']: $localize`Cuyuni-Mazaruni`,
    ['GY-DE']: $localize`Demerara-Mahaica`,
    ['GY-EB']: $localize`East Berbice-Corentyne`,
    ['GY-ES']: $localize`Essequibo Islands-West Demerara`,
    ['GY-MA']: $localize`Mahaica-Berbice`,
    ['GY-PM']: $localize`Pomeroon-Supenaam`,
    ['GY-PT']: $localize`Potaro-Siparuni`,
    ['GY-UD']: $localize`Upper Demerara-Berbice`,
    ['GY-UT']: $localize`Upper Takutu-Upper Essequibo`,
    ['HT-AR']: $localize`Artibonite`,
    ['HT-CE']: $localize`Centre`,
    ['HT-GA']: $localize`Grandans`,
    ['HT-ND']: $localize`Nò`,
    ['HT-NE']: $localize`Nòdès`,
    ['HT-NI']: $localize`Nip`,
    ['HT-NO']: $localize`Nòdwès`,
    ['HT-OU']: $localize`Lwès`,
    ['HT-SD']: $localize`Sid`,
    ['HT-SE']: $localize`Sidès`,
    ['HM-HM']: $localize`Heard & McDonald Islands`,
    ['HN-AT']: $localize`Atlántida`,
    ['HN-CH']: $localize`Choluteca`,
    ['HN-CL']: $localize`Colón`,
    ['HN-CM']: $localize`Comayagua`,
    ['HN-CP']: $localize`Copán`,
    ['HN-CR']: $localize`Cortés`,
    ['HN-EP']: $localize`El Paraíso`,
    ['HN-FM']: $localize`Francisco Morazán`,
    ['HN-GD']: $localize`Gracias a Dios`,
    ['HN-IB']: $localize`Islas de la Bahía`,
    ['HN-IN']: $localize`Intibucá`,
    ['HN-LE']: $localize`Lempira`,
    ['HN-LP']: $localize`La Paz`,
    ['HN-OC']: $localize`Ocotepeque`,
    ['HN-OL']: $localize`Olancho`,
    ['HN-SB']: $localize`Santa Bárbara`,
    ['HN-VA']: $localize`Valle`,
    ['HN-YO']: $localize`Yoro`,
    ['HK-HK']: $localize`Hong Kong`,
    ['HU-BA']: $localize`Baranya`,
    ['HU-BC']: $localize`Békéscsaba`,
    ['HU-BE']: $localize`Békés`,
    ['HU-BK']: $localize`Bács-Kiskun`,
    ['HU-BU']: $localize`Budapest`,
    ['HU-BZ']: $localize`Borsod-Abaúj-Zemplén`,
    ['HU-CS']: $localize`Csongrád`,
    ['HU-DE']: $localize`Debrecen`,
    ['HU-DU']: $localize`Dunaújváros`,
    ['HU-EG']: $localize`Eger`,
    ['HU-ER']: $localize`Érd`,
    ['HU-FE']: $localize`Fejér`,
    ['HU-GS']: $localize`Győr-Moson-Sopron`,
    ['HU-GY']: $localize`Győr`,
    ['HU-HB']: $localize`Hajdú-Bihar`,
    ['HU-HE']: $localize`Heves`,
    ['HU-HV']: $localize`Hódmezővásárhely`,
    ['HU-JN']: $localize`Jász-Nagykun-Szolnok`,
    ['HU-KE']: $localize`Komárom-Esztergom`,
    ['HU-KM']: $localize`Kecskemét`,
    ['HU-KV']: $localize`Kaposvár`,
    ['HU-MI']: $localize`Miskolc`,
    ['HU-NK']: $localize`Nagykanizsa`,
    ['HU-NO']: $localize`Nógrád`,
    ['HU-NY']: $localize`Nyíregyháza`,
    ['HU-PE']: $localize`Pest`,
    ['HU-PS']: $localize`Pécs`,
    ['HU-SD']: $localize`Szeged`,
    ['HU-SF']: $localize`Székesfehérvár`,
    ['HU-SH']: $localize`Szombathely`,
    ['HU-SK']: $localize`Szolnok`,
    ['HU-SN']: $localize`Sopron`,
    ['HU-SO']: $localize`Somogy`,
    ['HU-SS']: $localize`Szekszárd`,
    ['HU-ST']: $localize`Salgótarján`,
    ['HU-SZ']: $localize`Szabolcs-Szatmár-Bereg`,
    ['HU-TB']: $localize`Tatabánya`,
    ['HU-TO']: $localize`Tolna`,
    ['HU-VA']: $localize`Vas`,
    ['HU-VE']: $localize`Veszprém`,
    ['HU-VM']: $localize`Veszprém`,
    ['HU-ZA']: $localize`Zala`,
    ['HU-ZE']: $localize`Zalaegerszeg`,
    ['IS-0']: $localize`Reykjavík`,
    ['IS-1']: $localize`Höfuðborgarsvæði utan Reykjavíkur`,
    ['IS-2']: $localize`Suðurnes`,
    ['IS-3']: $localize`Vesturland`,
    ['IS-4']: $localize`Vestfirðir`,
    ['IS-5']: $localize`Norðurland vestra`,
    ['IS-6']: $localize`Norðurland eystra`,
    ['IS-7']: $localize`Austurland`,
    ['IS-8']: $localize`Suðurland`,
    ['IN-AN']: $localize`Andaman and Nicobar Islands`,
    ['IN-AP']: $localize`Andhra Pradesh`,
    ['IN-AR']: $localize`Arunachal Pradesh`,
    ['IN-AS']: $localize`Assam`,
    ['IN-BR']: $localize`Bihar`,
    ['IN-CH']: $localize`Chandigarh`,
    ['IN-CT']: $localize`Chhattisgarh`,
    ['IN-DD']: $localize`Daman and Diu`,
    ['IN-DL']: $localize`Delhi`,
    ['IN-DN']: $localize`Dadra and Nagar Haveli`,
    ['IN-GA']: $localize`Goa`,
    ['IN-GJ']: $localize`Gujarat`,
    ['IN-HP']: $localize`Himachal Pradesh`,
    ['IN-HR']: $localize`Haryana`,
    ['IN-JH']: $localize`Jharkhand`,
    ['IN-JK']: $localize`Jammu and Kashmir`,
    ['IN-KA']: $localize`Karnataka`,
    ['IN-KL']: $localize`Kerala`,
    ['IN-LD']: $localize`Lakshadweep`,
    ['IN-MH']: $localize`Maharashtra`,
    ['IN-ML']: $localize`Meghalaya`,
    ['IN-MN']: $localize`Manipur`,
    ['IN-MP']: $localize`Madhya Pradesh`,
    ['IN-MZ']: $localize`Mizoram`,
    ['IN-NL']: $localize`Nagaland`,
    ['IN-OR']: $localize`Odisha`,
    ['IN-PB']: $localize`Punjab`,
    ['IN-PY']: $localize`Puducherry`,
    ['IN-RJ']: $localize`Rajasthan`,
    ['IN-SK']: $localize`Sikkim`,
    ['IN-TG']: $localize`Telangana`,
    ['IN-TN']: $localize`Tamil Nadu`,
    ['IN-TR']: $localize`Tripura`,
    ['IN-UP']: $localize`Uttar Pradesh`,
    ['IN-UT']: $localize`Uttarakhand`,
    ['IN-WB']: $localize`West Bengal`,
    ['ID-AC']: $localize`Aceh`,
    ['ID-BA']: $localize`Bali`,
    ['ID-BB']: $localize`Bangka Belitung`,
    ['ID-BE']: $localize`Bengkulu`,
    ['ID-BT']: $localize`Banten`,
    ['ID-GO']: $localize`Gorontalo`,
    ['ID-JA']: $localize`Jambi`,
    ['ID-JB']: $localize`Jawa Barat`,
    ['ID-JI']: $localize`Jawa Timur`,
    ['ID-JK']: $localize`Jakarta Raya`,
    ['ID-JT']: $localize`Jawa Tengah`,
    ['ID-JW']: $localize`Jawa`,
    ['ID-KA']: $localize`Kalimantan`,
    ['ID-KB']: $localize`Kalimantan Barat`,
    ['ID-KI']: $localize`Kalimantan Timur`,
    ['ID-KR']: $localize`Kepulauan Riau`,
    ['ID-KS']: $localize`Kalimantan Selatan`,
    ['ID-KT']: $localize`Kalimantan Tengah`,
    ['ID-KU']: $localize`Kalimantan Utara`,
    ['ID-LA']: $localize`Lampung`,
    ['ID-MA']: $localize`Maluku`,
    ['ID-ML']: $localize`Maluku`,
    ['ID-MU']: $localize`Maluku Utara`,
    ['ID-NB']: $localize`Nusa Tenggara Barat`,
    ['ID-NT']: $localize`Nusa Tenggara Timur`,
    ['ID-NU']: $localize`Nusa Tenggara`,
    ['ID-PA']: $localize`Papua`,
    ['ID-PB']: $localize`Papua Barat`,
    ['ID-PP']: $localize`Papua`,
    ['ID-RI']: $localize`Riau`,
    ['ID-SA']: $localize`Sulawesi Utara`,
    ['ID-SB']: $localize`Sumatera Barat`,
    ['ID-SG']: $localize`Sulawesi Tenggara`,
    ['ID-SL']: $localize`Sulawesi`,
    ['ID-SM']: $localize`Sumatera`,
    ['ID-SN']: $localize`Sulawesi Selatan`,
    ['ID-SR']: $localize`Sulawesi Barat`,
    ['ID-SS']: $localize`Sumatera Selatan`,
    ['ID-ST']: $localize`Sulawesi Tengah`,
    ['ID-SU']: $localize`Sumatera Utara`,
    ['ID-YO']: $localize`Yogyakarta`,
    ['IR-01']: $localize`Āz̄arbāyjān-e Sharqī`,
    ['IR-02']: $localize`Āz̄arbāyjān-e Gharbī`,
    ['IR-03']: $localize`Ardabīl`,
    ['IR-04']: $localize`Eşfahān`,
    ['IR-05']: $localize`Īlām`,
    ['IR-06']: $localize`Būshehr`,
    ['IR-07']: $localize`Tehrān`,
    ['IR-08']: $localize`Chahār Maḩāll va Bakhtīārī`,
    ['IR-10']: $localize`Khūzestān`,
    ['IR-11']: $localize`Zanjān`,
    ['IR-12']: $localize`Semnān`,
    ['IR-13']: $localize`Sīstān va Balūchestān`,
    ['IR-14']: $localize`Fārs`,
    ['IR-15']: $localize`Kermān`,
    ['IR-16']: $localize`Kordestān`,
    ['IR-17']: $localize`Kermānshāh`,
    ['IR-18']: $localize`Kohgīlūyeh va Būyer Aḩmad`,
    ['IR-19']: $localize`Gīlān`,
    ['IR-20']: $localize`Lorestān`,
    ['IR-21']: $localize`Māzandarān`,
    ['IR-22']: $localize`Markazī`,
    ['IR-23']: $localize`Hormozgān`,
    ['IR-24']: $localize`Hamadān`,
    ['IR-25']: $localize`Yazd`,
    ['IR-26']: $localize`Qom`,
    ['IR-27']: $localize`Golestān`,
    ['IR-28']: $localize`Qazvīn`,
    ['IR-29']: $localize`Khorāsān-e Janūbī`,
    ['IR-30']: $localize`Khorāsān-e Razavī`,
    ['IR-31']: $localize`Khorāsān-e Shemālī`,
    ['IR-32']: $localize`Alborz`,
    ['IQ-AN']: $localize`Al Anbār`,
    ['IQ-AR']: $localize`Arbīl`,
    ['IQ-BA']: $localize`Al Başrah`,
    ['IQ-BB']: $localize`Bābil`,
    ['IQ-BG']: $localize`Baghdād`,
    ['IQ-DA']: $localize`Dahūk`,
    ['IQ-DI']: $localize`Diyālá`,
    ['IQ-DQ']: $localize`Dhī Qār`,
    ['IQ-KA']: $localize`Karbalā'`,
    ['IQ-KI']: $localize`Kirkūk`,
    ['IQ-MA']: $localize`Maysān`,
    ['IQ-MU']: $localize`Al Muthanná`,
    ['IQ-NA']: $localize`An Najaf`,
    ['IQ-NI']: $localize`Nīnawá`,
    ['IQ-QA']: $localize`Al Qādisīyah`,
    ['IQ-SD']: $localize`Şalāḩ ad Dīn`,
    ['IQ-SU']: $localize`As Sulaymānīyah`,
    ['IQ-WA']: $localize`Wāsiţ`,
    ['IE-C']: $localize`Connacht`,
    ['IE-CE']: $localize`An Clár`,
    ['IE-CN']: $localize`An Cabhán`,
    ['IE-CO']: $localize`Corcaigh`,
    ['IE-CW']: $localize`Carlow`,
    ['IE-D']: $localize`Baile Átha Cliath`,
    ['IE-DL']: $localize`Donegal`,
    ['IE-G']: $localize`Gaillimh`,
    ['IE-KE']: $localize`Cill Dara`,
    ['IE-KK']: $localize`Cill Chainnigh`,
    ['IE-KY']: $localize`Ciarraí`,
    ['IE-LD']: $localize`An Longfort`,
    ['IE-LH']: $localize`Louth`,
    ['IE-LK']: $localize`Limerick`,
    ['IE-L']: $localize`Laighin`,
    ['IE-LM']: $localize`Leitrim`,
    ['IE-LS']: $localize`Laois`,
    ['IE-M']: $localize`An Mhumhain`,
    ['IE-MH']: $localize`An Mhí`,
    ['IE-MN']: $localize`Monaghan`,
    ['IE-MO']: $localize`Maigh Eo`,
    ['IE-OY']: $localize`Offaly`,
    ['IE-RN']: $localize`Ros Comáin`,
    ['IE-SO']: $localize`Sligeach`,
    ['IE-TA']: $localize`Tiobraid Árann`,
    ['IE-U']: $localize`Ulaidh`,
    ['IE-WD']: $localize`Port Láirge`,
    ['IE-WH']: $localize`An Iarmhí`,
    ['IE-WW']: $localize`Cill Mhantáin`,
    ['IE-WX']: $localize`Loch Garman`,
    ['IM-IM']: $localize`Isle of Man`,
    ['IL-HA']: $localize`Haifa`,
    ['IL-JM']: $localize`Jerusalem`,
    ['IL-M']: $localize`Central District`,
    ['IL-TA']: $localize`Tel Aviv`,
    ['IL-Z']: $localize`Northern District`,
    ['IT-21']: $localize`Piemonte`,
    ['IT-23']: $localize`Val d'Aoste`,
    ['IT-25']: $localize`Lombardia`,
    ['IT-32']: $localize`Trentino-Alto Adige`,
    ['IT-34']: $localize`Veneto`,
    ['IT-36']: $localize`Friuli-Venezia Giulia`,
    ['IT-42']: $localize`Liguria`,
    ['IT-45']: $localize`Emilia-Romagna`,
    ['IT-52']: $localize`Toscana`,
    ['IT-55']: $localize`Umbria`,
    ['IT-57']: $localize`Marche`,
    ['IT-62']: $localize`Lazio`,
    ['IT-65']: $localize`Abruzzo`,
    ['IT-67']: $localize`Molise`,
    ['IT-72']: $localize`Campania`,
    ['IT-75']: $localize`Puglia`,
    ['IT-77']: $localize`Basilicata`,
    ['IT-78']: $localize`Calabria`,
    ['IT-82']: $localize`Sicilia`,
    ['IT-88']: $localize`Sardegna`,
    ['IT-AG']: $localize`Agrigento`,
    ['IT-AL']: $localize`Alessandria`,
    ['IT-AN']: $localize`Ancona`,
    ['IT-AO']: $localize`Aosta`,
    ['IT-AP']: $localize`Ascoli Piceno`,
    ['IT-AQ']: $localize`L'Aquila`,
    ['IT-AR']: $localize`Arezzo`,
    ['IT-AT']: $localize`Asti`,
    ['IT-AV']: $localize`Avellino`,
    ['IT-BA']: $localize`Bari`,
    ['IT-BG']: $localize`Bergamo`,
    ['IT-BI']: $localize`Biella`,
    ['IT-BL']: $localize`Belluno`,
    ['IT-BN']: $localize`Benevento`,
    ['IT-BO']: $localize`Bologna`,
    ['IT-BR']: $localize`Brindisi`,
    ['IT-BS']: $localize`Brescia`,
    ['IT-BT']: $localize`Barletta-Andria-Trani`,
    ['IT-BZ']: $localize`Bolzano`,
    ['IT-CA']: $localize`Cagliari`,
    ['IT-CB']: $localize`Campobasso`,
    ['IT-CE']: $localize`Caserta`,
    ['IT-CH']: $localize`Chieti`,
    ['IT-CI']: $localize`Carbonia-Iglesias`,
    ['IT-CL']: $localize`Caltanissetta`,
    ['IT-CN']: $localize`Cuneo`,
    ['IT-CO']: $localize`Como`,
    ['IT-CR']: $localize`Cremona`,
    ['IT-CS']: $localize`Cosenza`,
    ['IT-CT']: $localize`Catania`,
    ['IT-CZ']: $localize`Catanzaro`,
    ['IT-EN']: $localize`Enna`,
    ['IT-FC']: $localize`Forlì-Cesena`,
    ['IT-FE']: $localize`Ferrara`,
    ['IT-FG']: $localize`Foggia`,
    ['IT-FI']: $localize`Firenze`,
    ['IT-FM']: $localize`Fermo`,
    ['IT-FR']: $localize`Frosinone`,
    ['IT-GE']: $localize`Genova`,
    ['IT-GO']: $localize`Gorizia`,
    ['IT-GR']: $localize`Grosseto`,
    ['IT-IM']: $localize`Imperia`,
    ['IT-IS']: $localize`Isernia`,
    ['IT-KR']: $localize`Crotone`,
    ['IT-LC']: $localize`Lecco`,
    ['IT-LE']: $localize`Lecce`,
    ['IT-LI']: $localize`Livorno`,
    ['IT-LO']: $localize`Lodi`,
    ['IT-LT']: $localize`Latina`,
    ['IT-LU']: $localize`Lucca`,
    ['IT-MB']: $localize`Monza e Brianza`,
    ['IT-MC']: $localize`Macerata`,
    ['IT-ME']: $localize`Messina`,
    ['IT-MI']: $localize`Milano`,
    ['IT-MN']: $localize`Mantova`,
    ['IT-MO']: $localize`Modena`,
    ['IT-MS']: $localize`Massa-Carrara`,
    ['IT-MT']: $localize`Matera`,
    ['IT-NA']: $localize`Napoli`,
    ['IT-NO']: $localize`Novara`,
    ['IT-NU']: $localize`Nuoro`,
    ['IT-OG']: $localize`Ogliastra`,
    ['IT-OR']: $localize`Oristano`,
    ['IT-OT']: $localize`Olbia-Tempio`,
    ['IT-PA']: $localize`Palermo`,
    ['IT-PC']: $localize`Piacenza`,
    ['IT-PD']: $localize`Padova`,
    ['IT-PE']: $localize`Pescara`,
    ['IT-PG']: $localize`Perugia`,
    ['IT-PI']: $localize`Pisa`,
    ['IT-PN']: $localize`Pordenone`,
    ['IT-PO']: $localize`Prato`,
    ['IT-PR']: $localize`Parma`,
    ['IT-PT']: $localize`Pistoia`,
    ['IT-PU']: $localize`Pesaro e Urbino`,
    ['IT-PV']: $localize`Pavia`,
    ['IT-PZ']: $localize`Potenza`,
    ['IT-RA']: $localize`Ravenna`,
    ['IT-RC']: $localize`Reggio Calabria`,
    ['IT-RE']: $localize`Reggio Emilia`,
    ['IT-RG']: $localize`Ragusa`,
    ['IT-RI']: $localize`Rieti`,
    ['IT-RM']: $localize`Roma`,
    ['IT-RN']: $localize`Rimini`,
    ['IT-RO']: $localize`Rovigo`,
    ['IT-SA']: $localize`Salerno`,
    ['IT-SI']: $localize`Siena`,
    ['IT-SO']: $localize`Sondrio`,
    ['IT-SP']: $localize`La Spezia`,
    ['IT-SR']: $localize`Siracusa`,
    ['IT-SS']: $localize`Sassari`,
    ['IT-SV']: $localize`Savona`,
    ['IT-TA']: $localize`Taranto`,
    ['IT-TE']: $localize`Teramo`,
    ['IT-TN']: $localize`Trento`,
    ['IT-TO']: $localize`Torino`,
    ['IT-TP']: $localize`Trapani`,
    ['IT-TR']: $localize`Terni`,
    ['IT-TS']: $localize`Trieste`,
    ['IT-TV']: $localize`Treviso`,
    ['IT-UD']: $localize`Udine`,
    ['IT-VA']: $localize`Varese`,
    ['IT-VB']: $localize`Verbano-Cusio-Ossola`,
    ['IT-VC']: $localize`Vercelli`,
    ['IT-VE']: $localize`Venezia`,
    ['IT-VI']: $localize`Vicenza`,
    ['IT-VR']: $localize`Verona`,
    ['IT-VS']: $localize`Medio Campidano`,
    ['IT-VT']: $localize`Viterbo`,
    ['IT-VV']: $localize`Vibo Valentia`,
    ['JM-01']: $localize`Kingston`,
    ['JM-02']: $localize`Saint Andrew`,
    ['JM-03']: $localize`Saint Thomas`,
    ['JM-04']: $localize`Portland`,
    ['JM-05']: $localize`Saint Mary`,
    ['JM-06']: $localize`Saint Ann`,
    ['JM-07']: $localize`Trelawny`,
    ['JM-08']: $localize`Saint James`,
    ['JM-09']: $localize`Hanover`,
    ['JM-10']: $localize`Westmoreland`,
    ['JM-11']: $localize`Saint Elizabeth`,
    ['JM-12']: $localize`Manchester`,
    ['JM-13']: $localize`Clarendon`,
    ['JM-14']: $localize`Saint Catherine`,
    ['JP-01']: $localize`Hokkaido`,
    ['JP-02']: $localize`Aomori`,
    ['JP-03']: $localize`Iwate`,
    ['JP-04']: $localize`Miyagi`,
    ['JP-05']: $localize`Akita`,
    ['JP-06']: $localize`Yamagata`,
    ['JP-07']: $localize`Fukushima`,
    ['JP-08']: $localize`Ibaraki`,
    ['JP-09']: $localize`Tochigi`,
    ['JP-10']: $localize`Gunma`,
    ['JP-11']: $localize`Saitama`,
    ['JP-12']: $localize`Chiba`,
    ['JP-13']: $localize`Tôkyô`,
    ['JP-14']: $localize`Kanagawa`,
    ['JP-15']: $localize`Niigata`,
    ['JP-16']: $localize`Toyama`,
    ['JP-17']: $localize`Ishikawa`,
    ['JP-18']: $localize`Fukui`,
    ['JP-19']: $localize`Yamanashi`,
    ['JP-20']: $localize`Nagano`,
    ['JP-21']: $localize`Gifu`,
    ['JP-22']: $localize`Shizuoka`,
    ['JP-23']: $localize`Aichi`,
    ['JP-24']: $localize`Mie`,
    ['JP-25']: $localize`Shiga`,
    ['JP-26']: $localize`Kyoto`,
    ['JP-27']: $localize`Osaka`,
    ['JP-28']: $localize`Hyogo`,
    ['JP-29']: $localize`Nara`,
    ['JP-30']: $localize`Wakayama`,
    ['JP-31']: $localize`Tottori`,
    ['JP-32']: $localize`Shimane`,
    ['JP-33']: $localize`Okayama`,
    ['JP-34']: $localize`Hiroshima`,
    ['JP-35']: $localize`Yamaguchi`,
    ['JP-36']: $localize`Tokushima`,
    ['JP-37']: $localize`Kagawa`,
    ['JP-38']: $localize`Ehime`,
    ['JP-39']: $localize`Kochi`,
    ['JP-40']: $localize`Fukuoka`,
    ['JP-41']: $localize`Saga`,
    ['JP-42']: $localize`Nagasaki`,
    ['JP-43']: $localize`Kumamoto`,
    ['JP-44']: $localize`Oita`,
    ['JP-45']: $localize`Miyazaki`,
    ['JP-46']: $localize`Kagoshima`,
    ['JP-47']: $localize`Okinawa`,
  };
}
