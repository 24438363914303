import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IOrganisation, IUser } from '@portal/entity-services/interfaces/src';
import { OrganisationService } from '@portal/entity-services/organisations/src/lib/services/organisation.service';
import { UserService } from '@portal/entity-services/users/src/lib/services/user.service';
import { NotifierService } from '@portal/shared/ui/notifier/src';
import { ToastService } from '@portal/shared/ui/toast';
import { EntityType } from '@portal/entity-services/interfaces';
import { Observable, of } from 'rxjs';
import { DataServiceError } from '@ngrx/data';
import { catchError } from 'rxjs/operators';
import * as get from 'lodash/get';

declare const $localize;

@Component({
  selector: 'portal-create-organization-group',
  templateUrl: './create-organization-group.component.html',
  styleUrls: ['./create-organization-group.component.scss'],
})
export class CreateOrganizationGroupComponent {
  maximumLimitOrgToAdd = 100;
  minimumLimitOrgToAdd = 2;
  preSelectedOrganizationNumber: number;

  constructor(
    private organisationService: OrganisationService,
    private userService: UserService,
    private notifierService: NotifierService,
    private toastService: ToastService,
    private router: Router,
  ) {}

  onFormValidated(data: Record<string, any>): void {
    if (
      this.preSelectedOrganizationNumber <= this.maximumLimitOrgToAdd &&
      this.preSelectedOrganizationNumber >= this.minimumLimitOrgToAdd
    ) {
      this.addgroupHierarchy(data);
    }
  }

  onSelectedOrganizationNumber(organizationsNumber: number): void {
    this.preSelectedOrganizationNumber = organizationsNumber;
  }

  addgroupHierarchy(data: Record<string, any>): void {
    const singleEntityPayload = {
      name: data.name,
      parentEntityUid: data.parentEntityUid,
      entityType: EntityType.GROUP,
    };

    const entityUids = {
      entityUids: data.entityUids,
    };

    let userIds = data?.userIds;
    userIds = userIds?.filter((id) => id);

    this.createSingleEntity(singleEntityPayload).subscribe(
      (organization) => {
        const groupEntityId = organization.entityUid;
        const groupName = organization.name;
        let usersUpdated = 0;
        this.createGroupEntityRelationships(entityUids, groupEntityId).subscribe(
          (relationship) => {
            // 204 - Entities associated with group entity.
            if (relationship.status === 204) {
              // No users selected
              if (!userIds?.length) {
                this.toastService.showToast(
                  $localize`Organization group ${groupName} has been created`,
                );
                this.router.navigate(['/administration/users']);
              }
              this.getUsersByIds(userIds).map((user) => {
                user.subscribe((userData) => {
                  const userToUpdate = { ...userData };
                  userToUpdate.entityUid = groupEntityId;
                  this.userService.update(userToUpdate).subscribe(
                    () => {
                      usersUpdated++;
                      if (usersUpdated >= userIds.length) {
                        this.toastService.showToast(
                          $localize`Organization group ${groupName} has been created`,
                        );
                        this.router.navigate(['/administration/users']);
                      }
                    },
                    (errorUserUpdate: DataServiceError) => {
                      this.notifierService.error(
                        $localize`Error` +
                          ` (${get(errorUserUpdate, 'error.status')}) ${get(
                            errorUserUpdate,
                            'error.error.message',
                          )}`,
                      );
                    },
                  );
                });
              });
            }
          },
          (errorRelationship: DataServiceError) => {
            this.notifierService.error(
              $localize`Error` +
                ` (${get(errorRelationship, 'error.status')}) ${
                  get(errorRelationship, 'error.error.message') ||
                  get(errorRelationship, 'error.message')
                }`,
            );
          },
        );
      },
      (errorCreateEntity: DataServiceError) => {
        this.notifierService.error(
          $localize`Error` +
            ` (${get(errorCreateEntity, 'error.status')}) ${
              get(errorCreateEntity, 'error.error.message') ||
              get(errorCreateEntity, 'error.message')
            }`,
        );
      },
    );
  }

  createSingleEntity(singleEntityPayload: IOrganisation): Observable<IOrganisation> {
    return this.organisationService.add(singleEntityPayload);
  }

  createGroupEntityRelationships(
    paramEntityUids: Record<string, any>,
    entityGroupUid: string,
  ): Observable<any> {
    return this.organisationService.createGroupEntityRelationships(paramEntityUids, entityGroupUid);
  }

  getUsersByIds(userIds: string[]): Observable<IUser>[] {
    return userIds.map((userUid) => {
      if (!userUid) {
        return of(null);
      }
      return this.userService.getByKey(userUid).pipe(catchError(() => of({ userUid })));
    });
  }
}
