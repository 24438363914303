import { Fonts } from '@portal/entity-services/organisations/src/lib/enums/fonts.enum';

export const GOOGLE_FONTS_LINKS = {
  [Fonts.Roboto]: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap',
  [Fonts.OpenSans]: 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap',
  [Fonts.Lato]: 'https://fonts.googleapis.com/css2?family=Lato&display=swap',
  [Fonts.Slabo]: 'https://fonts.googleapis.com/css2?family=Slabo+13px&display=swap',
  [Fonts.Oswald]: 'https://fonts.googleapis.com/css2?family=Oswald&display=swap',
  [Fonts.SourceSansPro]: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap',
  [Fonts.Montserrat]: 'https://fonts.googleapis.com/css2?family=Montserrat&display=swap',
  [Fonts.Raleway]: 'https://fonts.googleapis.com/css2?family=Raleway&display=swap',
  [Fonts.PTSans]: 'https://fonts.googleapis.com/css2?family=PT+Sans&display=swap',
  [Fonts.Lora]: 'https://fonts.googleapis.com/css2?family=Lora&display=swap',
  [Fonts.NotoSans]: 'https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap',
  [Fonts.NotoSerif]: 'https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap',
  [Fonts.NunitoSans]: 'https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap',
  [Fonts.ConcertOne]: 'https://fonts.googleapis.com/css2?family=Concert+One&display=swap',
  [Fonts.Prompt]: 'https://fonts.googleapis.com/css2?family=Prompt&display=swap',
};
