<div class="marketplace-tag control" data-e2e="marketplace-tag">
  <span class="tag is-bold   is-{{ tag?.color || color }}">
    <span *ngIf="tag?.name" class="has-text-white">{{ tag?.name }}</span>
    <ng-content *ngIf="!tag?.name"></ng-content>
    <div
      *ngIf="isAction()"
      class="delete"
      data-e2e="marketplace-tag-action"
      (click)="onTagAction(tag)"
    ></div>
  </span>
</div>
