import { vfappmarket } from '../services.url';

export const TAGS_API = {
  GET_ALL_TAGS: function (merchantEntityUid: string): string {
    return `${vfappmarket}/v1/entityTags/entity/${merchantEntityUid}`;
  },

  GET_ALL_POIS_FOR_TAG: function (merchantEntityUid: string, entityTagId: string): string {
    return `${vfappmarket}/v1/entityTags/entity/${merchantEntityUid}/entityTag/${entityTagId}/pois`;
  },

  GET_ALL_BUNDLES_FOR_TAG: function (merchantEntityUid: string, entityTagId: string): string {
    return `${vfappmarket}/v1/entityTags/entity/${merchantEntityUid}/entityTag/${entityTagId}/bundles`;
  },

  CREATE_TAG: function (merchantEntityUid: string): string {
    return `${vfappmarket}/v1/entityTags/entity/${merchantEntityUid}`;
  },

  UPDATE_TAG: function (merchantEntityUid: string, entityTagId: string): string {
    return `${vfappmarket}/v1/entityTags/entity/${merchantEntityUid}/entityTag/${entityTagId}`;
  },

  DELETE_TAG: function (merchantEntityUid: string, entityTagId: string): string {
    return `${vfappmarket}/v1/entityTags/entity/${merchantEntityUid}/entityTag/${entityTagId}`;
  },
};
