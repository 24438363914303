import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MarketplaceTagsManagementRoutes } from './tags-management.routing';

@NgModule({
  imports: [CommonModule, RouterModule, MarketplaceTagsManagementRoutes],
  declarations: [],
  exports: [],
})
export class TagsManagementModule {}
