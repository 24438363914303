<div class="dropdown" [class.is-active]="isOpen" data-e2e="statusType-dropdownButton">
  <div class="dropdown-trigger">
    <button
      [attr.aria-expanded]="isOpen"
      class="button is-chip is-size-7-mobile"
      [class.is-applied]="getValues().length"
      [class.is-active]="isOpen"
      data-e2e="statusType-filterButton"
      (click)="itemToggled.emit()"
      type="button"
      title="{{ serviceProviderText }}"
    >
      <span data-e2e="statusType-filterButtonText">{{ serviceProviderText }}</span>
      <span class="icon">
        <i class="fas fa-chevron-down"></i>
      </span>
    </button>
  </div>

  <div class="dropdown-menu">
    <div class="dropdown-content">
      <div class="dropdown-item is-scrollable">
        <ng-container *ngFor="let statusType of statusTypeList">
          <portal-search-filter-radio
            [id]="statusType.id"
            [text]="statusType.text"
            [selectedEntities]="localPreSelectedEntities"
            (selected)="onEntityTypeSelection(statusType)"
            [refresh$]="refresh$"
          ></portal-search-filter-radio>
        </ng-container>
      </div>

      <hr class="dropdown-divider" />
      <div class="dropdown-item">
        <button
          class="button is-primary"
          data-e2e="button-filter"
          i18n
          (click)="onFilterApplied()"
          type="button"
          title="Apply"
          i18n-title
        >
          Apply
        </button>
        <button
          class="button is-outlined ml-2"
          data-e2e="button-clear-filter"
          i18n
          (click)="removeAllEntities()"
          type="button"
          title="Clear"
          i18n-title
        >
          Clear
        </button>
      </div>
    </div>
  </div>
</div>
