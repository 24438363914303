import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { AttachEvent } from '../enums/attach-event';

@Directive({
  selector: '[portalClickOutside]',
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Output() portalClickOutside = new EventEmitter<void>();

  @Input() attachEventOn = AttachEvent.Click;

  private clickAttachedToDocument = false;
  private clickedInside = false;
  private unlistenDocumentClick = () => {};
  private unlistenElementClick = () => {};
  private unlistenElementHover = () => {};

  /**
   * Needs to check if user had clicked inside of the host element
   * in order to handle situation if clicked child of the host element
   * was removed from DOM.
   */
  @HostListener('click')
  onClick(): void {
    this.clickedInside = true;
  }

  constructor(private elementRef: ElementRef, private renderer2: Renderer2) {}

  ngOnInit(): void {
    this.attachEvent();
  }

  private attachEvent(): void {
    switch (this.attachEventOn) {
      case AttachEvent.Click:
        this.attachDocumentClickOnClick();
        break;
      case AttachEvent.Hover:
        this.attachDocumentClickOnHover();
        break;
      case AttachEvent.Automatically:
        /**
         * If directive activated by clicking of parent element then
         * attached document click will listen also initial click
         * because of propagation. That's way we need to attach event on next tick.
         */
        setTimeout(() => this.attachDocumentClick());
        break;
    }
  }

  private attachDocumentClickOnClick(): void {
    this.unlistenElementClick = this.renderer2.listen(
      this.elementRef.nativeElement,
      'click',
      (event: MouseEvent) => {
        if (!this.clickAttachedToDocument) {
          this.attachDocumentClick();
        }
      },
    );
  }

  private attachDocumentClickOnHover(): void {
    this.unlistenElementHover = this.renderer2.listen(
      this.elementRef.nativeElement,
      'mouseover',
      (event: MouseEvent) => {
        if (!this.clickAttachedToDocument) {
          this.attachDocumentClick();
        }
      },
    );
  }

  private attachDocumentClick(): void {
    this.unlistenDocumentClick = this.renderer2.listen('document', 'click', (event) => {
      this.clickAttachedToDocument = true;
      if (this.clickedInside) {
        if (event?.target?.attributes?.id?.nodeValue === 'wrapper-component') {
          this.onOutsideClick();
        } else {
          this.clickedInside = false;
        }
      } else if (!this.elementRef.nativeElement.contains(event.target)) {
        this.onOutsideClick();
      }
    });
  }

  private onOutsideClick(): void {
    this.portalClickOutside.emit();
    this.unlistenDocumentClick();
    this.clickAttachedToDocument = false;
  }

  ngOnDestroy(): void {
    this.unlistenDocumentClick();
    this.unlistenElementClick();
    this.unlistenElementHover();
  }
}
