import { Component, Input } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { get, map } from 'lodash';
import { ErrorCheck } from './enums/error-check.enum';
import { ErrorService } from './error.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'portal-form-error',
  templateUrl: './error.component.html',
})
export class PortalFormErrorComponent {
  @Input() formInput: AbstractControl;
  @Input() checkOn: ErrorCheck;
  @Input() errorId: string;

  isInvalid(): boolean {
    return ErrorService.isFieldInvalid(this.formInput, this.checkOn);
  }

  get messages(): string[] {
    if (!this.formInput) {
      return [];
    }
    return map(this.formInput.errors, (error: ValidationErrors, errorKey: string) => {
      // ng-bootstrap datepicker includes validation but without messages,
      // so we need to add it ourselves
      if (errorKey === 'ngbDate') {
        error = ErrorService.addMessageToNgbError(error);
      }
      return get(error, 'displayMessage', '');
    });
  }
}
