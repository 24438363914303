import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IRequestOptions } from '../interfaces/IRequestOptions.interface';
import { HttpMethods } from './http-methods.enum';

@Injectable({
  providedIn: 'root',
})
export class VuiHttpService {
  constructor(private httpClient: HttpClient) {}

  get<T>(url: string, options?: IRequestOptions): Observable<T> {
    return this.request<T>(HttpMethods.GET, url, null, options).pipe(
      map((response) => response),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  put<T>(url: string, data?: any, options?: IRequestOptions): Observable<T> {
    return this.request<T>(HttpMethods.PUT, url, data, options).pipe(
      map((response) => response),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  post<T>(url: string, data?: any, options?: IRequestOptions): Observable<T> {
    return this.request<T>(HttpMethods.POST, url, data, options).pipe(
      map((response) => response),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  patch<T>(url: string, data?: any, options?: IRequestOptions): Observable<T> {
    return this.request<T>(HttpMethods.PATCH, url, data, options).pipe(
      map((response) => response),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  delete<T>(url: string, options?: IRequestOptions): Observable<T> {
    return this.request<T>(HttpMethods.DELETE, url, null, options).pipe(
      map((response) => response),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  private request<T>(
    method: string,
    url: string,
    data: any,
    options: IRequestOptions = {},
  ): Observable<T> {
    return new Observable((observer: any) => {
      const { headers, params, reportProgress, responseType, withCredentials } = options;

      const req = new HttpRequest(method, url, data, {
        headers,
        params,
        reportProgress,
        responseType,
        withCredentials,
      });

      this.httpClient.request(req).subscribe(
        (response: HttpResponse<any>) => {
          if (options.responseValue !== 'event' && response.type !== HttpEventType.Response) {
            return;
          }
          switch (options.responseValue) {
            case 'body':
              observer.next(response.body);
              break;
            case 'event':
              observer.next(response);
              break;
            default:
              observer.next(response.body);
          }
          observer.complete();
        },

        (error) => {
          switch (error.status) {
            case 403:
              observer.complete();
              break;
            default:
              observer.error(error);
              break;
          }
        },
      );
    });
  }
}
