import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({ selector: '[capsLock]' })
export class CapsLockDetectionDirective {
  @Output() capsLock = new EventEmitter<boolean>();

  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keyup', ['$event'])
  onKeyUpAndDown(event: KeyboardEvent): void {
    const capsOn = event.getModifierState && event.getModifierState('CapsLock');
    this.capsLock.emit(capsOn);
  }
}
