<form (ngSubmit)="search()" class="has-padding-bottom">
  <div class="field has-addons">
    <div class="control select-search-box" *ngIf="entities.length > 1">
      <portal-single-dropdown
        [entities]="entities"
        [isAddon]="true"
        [isChip]="isDeviceMobile"
        [isLightGrey]="isDeviceMobile"
        [selectedItem]="selectedItem"
        (selected)="updateSelectedEntity($event)"
        data-e2e="select-search-box"
      ></portal-single-dropdown>
    </div>
    <div class="control is-expanded">
      <input
        class="input is-size-7-mobile"
        type="text"
        name="searchTerm"
        data-e2e="input-search"
        [maxLength]="400"
        [pattern]="pattern"
        [(ngModel)]="_searchTerm"
        [placeholder]="searchPlaceholder"
        [ngClass]="{
          'is-danger': isInputInvalid()
        }"
        [disabled]="disabled"
        (change)="onSearchTermChange($event.target.value)"
        #searchTermElem="ngModel"
        (ngModelChange)="modelChange($event)"
        id="searchBox"
      />
      <span *ngIf="isInputInvalid()" class="help is-danger">
        <span> {{ patternMessage }} </span>
      </span>
    </div>
    <div class="control">
      <button
        type="submit"
        class="button is-primary is-size-6-mobile"
        data-e2e="button-search"
        [disabled]="isInputInvalid() || disabled"
        [ngSwitch]="isDeviceMobile"
        title="Search"
        i18n-title
      >
        <span *ngSwitchCase="true">
          <i class="fal fa-search"></i>
        </span>
        <span *ngSwitchCase="false">
          {{ searchText }}
        </span>
      </button>
    </div>
  </div>
</form>
