import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  IFilterToApply,
  ISearchBoxValue,
  ISearchCriteria,
  ISelectInput,
  SearchBoxComponent,
} from '@portal/shared/ui/filter';
import { SearchOperator } from '@portal/shared/ui/filter';
import { FilterCountryComponent } from '@portal/shared/ui/filter/src/lib/country/country.component';
import { IQueryParams } from '@portal/shared/ui/table';
import { Subject, Observable } from 'rxjs';
import { SearchSelector } from '../../enums/search-selector.enum';
import { FilterEntityTypeComponent } from './entity-type/entity-type.component';
import isEmpty from 'lodash-es/isEmpty';
import orderBy from 'lodash-es/orderBy';
import { MobileViewService } from '../../../../../../../apps/portal/src/app/services/mobile-view.service';
declare const $localize;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'portal-filter-organisation',
  templateUrl: './filter.component.html',
})
export class FilterOrganisationsComponent {
  @ViewChild(FilterEntityTypeComponent) filterTypeComponent: FilterEntityTypeComponent;
  @ViewChild(FilterCountryComponent) filterCountryComponent: FilterCountryComponent;
  @ViewChild(SearchBoxComponent, { static: true })
  searchBox: SearchBoxComponent;
  @Input()
  filter: IQueryParams;

  @Input()
  filterTouched: boolean;

  @Output()
  orgsFiltered: EventEmitter<ISearchBoxValue> = new EventEmitter();

  @Output()
  orgsFilterReset: EventEmitter<void> = new EventEmitter();

  @Output()
  orgsFilterChanged: EventEmitter<string> = new EventEmitter();

  @Output()
  searchUpdated: EventEmitter<ISelectInput> = new EventEmitter();

  searchEntities: ISelectInput[] = orderBy(
    [
      {
        id: SearchSelector.Name,
        text: $localize`:Search criteria in Organisations list:Organisation name`,
      },
      {
        id: SearchSelector.EntityId,
        text: $localize`:Search criteria in Organisations list:Organisation ID`,
        pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
        patternMessage: $localize`Please enter valid UUID`,
      },
      {
        id: SearchSelector.TaxRegistrationNumber,
        text: $localize`:Search criteria in Organisations list:Tax Registration Number`,
      },
      {
        id: SearchSelector.AltVfiEntityId,
        text: $localize`:Alternative ID assigned by Verifone to an Organisation:Verifone ID`,
      },
      {
        id: SearchSelector.BusinessIdentifierValue,
        text: $localize`:Search criteria in Organisations list:Business identifier`,
      },
    ],
    'text',
  );

  searchTerm: Subject<string> = new Subject();
  selectedSearchSelector: Subject<string> = new Subject();
  organisationIdSearchSelected = false;
  entityNameSelector = SearchSelector.Name;
  isDeviceMobile$: Observable<boolean>;

  constructor(private mobileViewService: MobileViewService) {
    this.isDeviceMobile$ = this.mobileViewService.mobileDevice;
  }

  isKnownSearchEntity(entityKey: string): boolean {
    return this.searchEntities.some((entity: ISelectInput) => {
      return entity.id === entityKey;
    });
  }

  onOrganisationSearched(input: ISearchBoxValue): void {
    // Check for an unknown search entity.
    if (!this.isKnownSearchEntity(input.key)) {
      return;
    }

    if (isEmpty(input.value)) {
      this.orgsFilterChanged.emit(input.key);
      this.orgsFiltered.emit();
    } else {
      this.orgsFiltered.emit({
        key: input.key,
        value: input.value,
        operator: SearchOperator.Equal,
      });
    }
  }

  resetFilter(): void {
    this.orgsFilterReset.emit();
    this.orgsFilterChanged.emit();
    this.filterTypeComponent.removeAllEntities();
    this.filterCountryComponent.clear();
    this.searchTerm.next('');
  }

  onCountryFilterApplied(filter: IFilterToApply): void {
    const values = new Set<string>();

    if (filter.value) {
      filter.value.forEach((value: ISelectInput) => {
        values.add(value.id);
      });
    }

    if (values.size) {
      this.orgsFiltered.emit({
        key: SearchSelector.CountryAlpha3,
        operator: SearchOperator.In,
        value: `${Array.from(values).join(',')}`,
      });
    } else {
      this.onSearchEntityRemoved(SearchSelector.CountryAlpha3);
      if (this.filterTypeComponent.localPreSelectedEntities.size) {
        const orgTypeFilterCurrentValue = Array.from(
          this.filterTypeComponent.localPreSelectedEntities,
        )[0][0];
        this.orgsFiltered.emit({
          key: SearchSelector.EntityType,
          value: orgTypeFilterCurrentValue,
          operator: SearchOperator.In,
        });
      } else {
        this.orgsFiltered.emit();
      }
    }
  }

  onTypeFilterUpdated(filter: IFilterToApply): void {
    this.orgsFiltered.emit({
      key: SearchSelector.EntityType,
      value: (filter.value || []).map((v) => v.id).join(','),
      operator: SearchOperator.In,
    });

    if (filter.toRemove) {
      this.orgsFilterReset.emit();
    }
  }

  onSearchEntityRemoved(key: string): void {
    this.orgsFilterChanged.emit(key);
  }

  formatselectedEntities(): Map<string, ISearchCriteria> {
    const filterFormatted = new Map<string, ISearchCriteria>();
    if (this.filter.searchCriteria.has(SearchSelector.CountryAlpha3)) {
      filterFormatted.set(
        SearchSelector.CountryAlpha3,
        this.filter.searchCriteria.get(SearchSelector.CountryAlpha3),
      );
    }

    return filterFormatted;
  }

  searchBoxInitComplete(): void {
    this.searchEntities.forEach((entity) => {
      if (this.filter.searchCriteria.has(entity.id)) {
        this.selectedSearchSelector.next(entity.id);
        this.searchTerm.next(this.filter.searchCriteria.get(entity.id).argument);
      }
    });
  }

  onSearchUpdated(searchInput: ISelectInput): void {
    this.organisationIdSearchSelected = searchInput.key === SearchSelector.EntityId;
    this.searchUpdated.emit(searchInput);
  }
  focusSearchBox(): void {
    if (this.searchBox) {
      this.searchBox?.setAutoFocusState();
    }
  }
}
