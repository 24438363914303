import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';
import {
  EntityStatus,
  ES_CONSTANTS as CONSTANTS,
  IAddress,
  IBankAccount,
  IContact,
  IFee,
  IOrganisation,
  IPaymentContract,
  IPointInteraction,
  IReceiptContract,
  IReceiptProvider,
  ISettlementInformation,
  IThreeDSecureProviderContract,
  IUser,
  IReceiptContractCreatePayloadData,
  IReceiptContractGetData,
} from '@portal/entity-services/interfaces';
import { IBusinessInformation } from '@portal/entity-services/forms/src/lib/interfaces/business-information.interface';
// Fixing cyclic dependency that fails portal uni tests
import { CustomDataService } from '@portal/shared/vui-http/src/lib/ngrx-data/custom-data.service';
import { Observable } from 'rxjs';
import { IPriceLists } from '@portal/entity-services/price-lists/src/lib/interfaces/price-lists.interface';
import { map } from 'rxjs/operators';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class CustomOrganisationDataService extends CustomDataService<IOrganisation> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super(CONSTANTS.ENTITY_SERVICE.ORGANISATION, http, httpUrlGenerator);
  }

  addPaymentContract(contract: IPaymentContract, id: string): Observable<IPaymentContract> {
    const entityOrError =
      id && contract
        ? contract
        : new Error($localize`No "${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT}" entity to add`);
    return this.execute(
      'POST',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_PAYMENT_CONTRACT}`,
      entityOrError,
    );
  }

  getPaymentContracts(id: string, name = '', limit = ''): Observable<IPaymentContract[]> {
    let query = '';
    let error: Error | undefined;
    if (id == null) {
      error = new Error($localize`No "${this.entityName}" key to get`);
    }

    if (name !== '') {
      query = `name=${name}`;
    }

    if (limit !== '') {
      query = `${query}&limit=${limit}`;
    }

    return this.execute(
      'GET',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_PAYMENT_CONTRACT}?${query}`,
      error,
    );
  }

  getReceiptProvider(receiptProviderType: string): Observable<IReceiptProvider> {
    return this.execute(
      'GET',
      `${CONSTANTS.RECEIPT_SERVICE}/receiptProviders/${receiptProviderType}`,
    );
  }

  createReceiptProvider(receiptProvider: IReceiptProvider): Observable<IReceiptProvider> {
    return this.execute(
      'PUT',
      `${CONSTANTS.RECEIPT_SERVICE}/receiptProviders/${receiptProvider.type}`,
      receiptProvider,
    );
  }

  createReceiptContract(
    id: string,
    params: IReceiptContractCreatePayloadData,
  ): Observable<IReceiptContract> {
    return this.execute(
      'POST',
      `${CONSTANTS.RECEIPT_SERVICE}/entities/${id}/receiptContracts`,
      params,
    );
  }

  getReceiptContracts(entityUids: string[]): Observable<IReceiptContractGetData[]> {
    return this.execute(
      'GET',
      `${CONSTANTS.RECEIPT_SERVICE}/receiptContracts?entityUids=${entityUids}`,
    );
  }

  updateReceiptContract(
    receiptContractId: string,
    receiptContractParam: IReceiptContractCreatePayloadData,
  ): Observable<IReceiptContract> {
    return this.execute(
      'PATCH',
      `${CONSTANTS.RECEIPT_SERVICE}/receiptContracts/${receiptContractId}`,
      receiptContractParam,
    );
  }

  deleteReceiptContract(receiptContractId: string): Observable<any> {
    return this.execute(
      'DELETE',
      `${CONSTANTS.RECEIPT_SERVICE}/receiptContracts/${receiptContractId}`,
    );
  }

  getReceiptTemplatesNames(): Observable<String[]> {
    return this.execute('GET', `${CONSTANTS.RECEIPT_SERVICE}/templates`);
  }

  getThreeDSecureContracts(
    id: string,
    status: EntityStatus = EntityStatus.Active,
  ): Observable<IThreeDSecureProviderContract[]> {
    let error: Error | undefined;
    if (!id) {
      error = new Error($localize`No "${this.entityName}" key to get`);
    }
    const params = status ? { status } : {};

    return this.execute('GET', `${this.entityUrl}${id}/threeDSContracts`, error, { params });
  }

  addUser(user: IUser, id: string): Observable<IUser> {
    const entityOrError =
      id && user ? user : new Error($localize`No "${CONSTANTS.ENTITY_SERVICE.USER}" entity to add`);
    return this.execute(
      'POST',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_USER}`,
      entityOrError,
    );
  }

  addPointInteraction(poi: IPointInteraction, id: string): Observable<IPointInteraction> {
    const entityOrError =
      id && poi
        ? poi
        : new Error($localize`No "${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION}" entity to add`);
    return this.execute(
      'POST',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_POINT_INTERACTION}`,
      entityOrError,
    );
  }

  addPriceList(priceList: IPriceLists, id: string): Observable<IPriceLists> {
    const entityOrError =
      id && priceList
        ? priceList
        : new Error($localize`No "${CONSTANTS.ENTITY_SERVICE.PRICE_LISTS}" entity to add`);
    return this.execute(
      'POST',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.ORG_PRICE_LISTS}`,
      entityOrError,
    );
  }

  getBusinessInformation(id: string): Observable<IBusinessInformation> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error($localize`No "${this.entityName}" key to get`);
    }
    return this.execute(
      'GET',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_BUSINESS_INFORMATION}`,
      error,
    );
  }

  updateBusinessInformation(
    information: IBusinessInformation,
    id: string,
  ): Observable<IBusinessInformation> {
    const updateOrError =
      !information || !id
        ? new Error($localize`No "${this.entityName}" business information update data or id`)
        : information;
    return this.execute(
      'PUT',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_BUSINESS_INFORMATION}`,
      updateOrError,
    );
  }

  addAddress(address: IAddress, id: string): Observable<IAddress> {
    const entityOrError =
      id && address
        ? address
        : new Error(
            $localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ADDRESSES}" entity to add`,
          );
    return this.execute(
      'POST',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ADDRESSES}?geocoding=true`,
      entityOrError,
    );
  }

  getAddressesByAddressUid(addressUid: string): Observable<IAddress> {
    return this.execute(
      'GET',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ADDRESSES}/${addressUid}`,
    );
  }

  getAddresses(id: string): Observable<[IAddress]> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error($localize`No "${this.entityName}" key to get`);
    }
    return this.execute(
      'GET',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ADDRESSES}${CONSTANTS.STATUS_OPTION.STATUS_BOTH}`,
      error,
    );
  }

  updateAddress(address: IAddress): Observable<IAddress> {
    const entityOrError =
      address && address.addressUid
        ? address
        : new Error(
            $localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ADDRESSES}" entity to update`,
          );
    return this.execute(
      'PUT',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ADDRESSES}/${address.addressUid}?geocoding=true`,
      entityOrError,
    );
  }

  deleteAddress(addressUid: string): Observable<IAddress> {
    let err: Error | undefined;
    if (addressUid == null) {
      err = new Error($localize`No "${this.entityName}" addressUid to get`);
    }

    return this.execute(
      'DELETE',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ADDRESSES}/${addressUid}`,
      err,
    );
  }

  enableAddress(addressUid: string): Observable<IAddress> {
    return this.execute(
      'PATCH',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ADDRESSES}/${addressUid}`,
      { status: EntityStatus.Active },
    );
  }

  addContact(contact: IContact, id: string): Observable<IContact> {
    const entityOrError =
      id && contact
        ? contact
        : new Error(
            $localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION_CONTACTS}" entity to add`,
          );
    return this.execute(
      'POST',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_CONTACTS}`,
      entityOrError,
    );
  }

  getContactByContactUid(contactUid: string): Observable<IContact> {
    return this.execute(
      'GET',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_CONTACTS}/${contactUid}`,
    );
  }

  getContact(id: string): Observable<IContact[]> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error($localize`No "${this.entityName}" key to get`);
    }
    return this.execute(
      'GET',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_CONTACTS}${CONSTANTS.STATUS_OPTION.STATUS_BOTH}`,
      error,
    );
  }

  updateContact(contact: IContact): Observable<IContact> {
    const entityOrError =
      contact && contact.contactUid
        ? contact
        : new Error(
            $localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION_CONTACTS}" entity to update`,
          );
    return this.execute(
      'PATCH',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_CONTACTS}/${contact.contactUid}`,
      entityOrError,
    );
  }

  deleteContact(contactUid: string): Observable<IContact> {
    let err: Error | undefined;
    if (contactUid == null) {
      err = new Error($localize`No "${this.entityName}" contactUid to get`);
    }

    return this.execute(
      'DELETE',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_CONTACTS}/${contactUid}`,
      err,
    );
  }

  enableContact(contactUid: string): Observable<IContact> {
    return this.execute(
      'PATCH',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_CONTACTS}/${contactUid}`,
      { status: EntityStatus.Active },
    );
  }

  addContactAddress(address: IAddress, id: string): Observable<IAddress> {
    const entityOrError =
      id && address
        ? address
        : new Error(
            $localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION_CONTACTS}" entity to add`,
          );
    return this.execute(
      'POST',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_CONTACTS}/${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ADDRESSES}`,
      entityOrError,
    );
  }

  getContactAddresses(id: string): Observable<[IAddress]> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error($localize`No "${this.entityName}" key to get`);
    }
    return this.execute(
      'GET',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_CONTACTS}/${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ADDRESSES}`,
      error,
    );
  }

  updateSettlementInformation(information: ISettlementInformation, id: string): any {
    const updateOrError =
      !information || !id
        ? new Error($localize`No "${this.entityName}" settlement update data or id`)
        : information;
    // PATCH method is used instead of PUT since PUT removes all related addresses for the contact
    return this.execute(
      'PATCH',
      `${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT}${id}/${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT_SETTLEMENT}`,
      updateOrError,
    );
  }

  deleteClearing(information: ISettlementInformation, id: string): any {
    const updateOrError =
      !information || !id
        ? new Error($localize`No "${this.entityName}" settlement update data or id`)
        : information;
    return this.execute(
      'PATCH',
      `${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT}${id}/${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT_SETTLEMENT}`,
      updateOrError,
    );
  }

  upsertFee(fees: IFee[], id: string): Observable<IFee[]> {
    const entityOrError =
      id && fees
        ? fees
        : new Error($localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION_FEES}" to add/update`);
    return this.execute(
      'PUT',
      `${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT}${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_FEES}`,
      entityOrError,
    );
  }

  deleteFee(id: string): Observable<IFee[]> {
    const entityOrError = id
      ? id
      : new Error($localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION_FEES}" to add/update`);
    return this.execute(
      'DELETE',
      `${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT}${id}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_FEES}`,
      entityOrError,
    );
  }

  addDomesticAccount(information: IBankAccount, entityUid: string): Observable<IBankAccount> {
    const entityOrError =
      entityUid && information
        ? information
        : new Error($localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION}" entity to add`);
    return this.execute('POST', `${this.entityUrl}${entityUid}/accounts/domestic`, entityOrError);
  }

  addSepaAccount(information: IBankAccount, entityUid: string): Observable<IBankAccount> {
    const entityOrError =
      entityUid && information
        ? information
        : new Error($localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION}" entity to add`);
    return this.execute('POST', `${this.entityUrl}${entityUid}/accounts/sepa`, entityOrError);
  }

  addExternalAccount(information: IBankAccount, entityUid: string): Observable<IBankAccount> {
    const entityOrError =
      entityUid && information
        ? information
        : new Error($localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION}" entity to add`);
    return this.execute(
      'POST',
      `${this.entityUrl}${entityUid}/accounts/externalAccountReference`,
      entityOrError,
    );
  }

  getAccount(id: string): Observable<IBankAccount> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error(
        $localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ACCOUNTS}" key to get`,
      );
    }
    return this.execute(
      'GET',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ACCOUNTS}/${id}`,
      error,
    );
  }

  deleteAccount(id: string): Observable<void> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error(
        $localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ACCOUNTS}" key to get`,
      );
    }
    return this.execute(
      'DELETE',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ACCOUNTS}/${id}`,
      error,
    );
  }

  getAccounts(id: string): Observable<IBankAccount[]> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error(
        $localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ACCOUNTS}" key to get`,
      );
    }
    return this.execute('GET', `${this.entityUrl}${id}/accounts`, error);
  }

  updateAccountDomestic(account: IBankAccount): Observable<IBankAccount> {
    const updateOrError =
      !account || !account?.accountUid
        ? new Error($localize`No account update data or accountUid domestic`)
        : account;
    return this.execute(
      'PUT',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ACCOUNTS}/domestic/${account.accountUid}`,
      updateOrError,
    );
  }

  updateAccountSepa(account: IBankAccount): Observable<IBankAccount> {
    const updateOrError =
      !account || !account?.accountUid
        ? new Error($localize`No account update data or accountUid sepa`)
        : account;
    return this.execute(
      'PUT',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ACCOUNTS}/sepa/${account.accountUid}`,
      updateOrError,
    );
  }

  updateAccountExternal(account: IBankAccount): Observable<IBankAccount> {
    const updateOrError =
      !account || !account?.accountUid
        ? new Error($localize`No account update data or accountUid sepa`)
        : account;
    return this.execute(
      'PUT',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ACCOUNTS}/externalAccountReference/${account.accountUid}`,
      updateOrError,
    );
  }

  enableDisableOrganisation(orgUid: string, statusToSend: string): Observable<IOrganisation> {
    return this.execute('PATCH', `${this.entityUrl}${orgUid}`, { status: statusToSend });
  }

  createRelationshipBetweenContractAndPriceList(
    contractUid,
    pricelistUid,
  ): Observable<IPriceLists> {
    return this.execute(
      'POST',
      `${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT}${contractUid}/pricelistRelationship`,
      pricelistUid,
    );
  }

  deleteRelationshipBetweenContractAndPriceList(
    contractUid,
    pricelistUid,
  ): Observable<IPriceLists> {
    let error: Error | undefined;
    if (!contractUid) {
      error = new Error($localize`No "${CONSTANTS.ENTITY_SERVICE.PRICE_LISTS}" key to get`);
    }
    return this.execute(
      'DELETE',
      `${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT}${contractUid}/pricelistRelationship/${pricelistUid}`,
      error,
    );
  }

  createGroupEntityRelationships(
    paramEntityUids: Record<string, any>,
    entityGroupUid: string,
  ): Observable<any> {
    const paramEntityUidsOrError = paramEntityUids
      ? paramEntityUids
      : new Error(
          $localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION}${entityGroupUid}/groupRelationships" relationships created`,
        );
    return this.execute(
      'POST',
      `${CONSTANTS.ENTITY_SERVICE.ORGANISATION}${entityGroupUid}/groupRelationships`,
      paramEntityUidsOrError,
      {
        observe: 'response',
      },
    );
  }

  getGroupEntityRelationships(paramEntityUid: string): Observable<IOrganisation[]> {
    let error: Error | undefined;
    if (!paramEntityUid) {
      error = new Error($localize`No "${paramEntityUid}" key to get`);
    }
    return this.execute(
      'GET',
      `${CONSTANTS.ENTITY_SERVICE.ORGANISATION}${paramEntityUid}/groupRelationships`,
      error,
    );
  }

  replaceGroupEntityRelationships(
    paramEntityUids: Record<string, any>,
    entityGroupUid: string,
  ): Observable<any> {
    const paramEntityUidsOrError = paramEntityUids
      ? paramEntityUids
      : new Error(
          $localize`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION}${entityGroupUid}/groupRelationships" relationships replaced`,
        );
    return this.execute(
      'PUT',
      `${CONSTANTS.ENTITY_SERVICE.ORGANISATION}${entityGroupUid}/groupRelationships`,
      paramEntityUidsOrError,
      {
        observe: 'response',
      },
    );
  }
}
