export const VerifoneUserRoles = {
  VerifoneAdmin: 'VERIFONE_ADMIN',
  VerifoneUser: 'VERIFONE_USER',
  VerifoneHelpDesk: 'VERIFONE_HELPDESK',
  VerifoneSaleManager: 'VERIFONE_SALES_MANAGER',
  VerifoneSalesRepresentative: 'VERIFONE_SALES_REPRESENTATIVE',
  VerifoneFinOpsUser: 'VERIFONE_FINOPS_USER',
  VerifoneSettlementUser: 'VERIFONE_SETTLEMENT_USER',
  VerifoneFieldServiceProvider: 'VERIFONE_FIELD_SERVICE_PROVIDER',
  VerifoneMaster: 'VERIFONE_MASTER',
  VerifoneFieldServiceSupervisor: 'VERIFONE_FIELD_SERVICE_SUPERVISOR',
  VerifoneReconAdmin: 'VERIFONE_RECON_ADMIN',
  VerifoneReconUser: 'VERIFONE_RECON_USER',
  VerifoneStipAdmin: 'VERIFONE_STIP_ADMIN',
  VerinfoneViweOnlyFinOpsUser: 'VERIFONE_VIEWONLY_FINOPS_USER',
  VerinfoneViweOnlySettlementUser: 'VERIFONE_VIEWONLY_SETTLEMENT_USER',
  MarketplaceManager: 'VERIFONE_ADMIN',
};

export const ExternalUserRoles = {
  MerchantAdmin: 'MERCHANT_ADMIN',
  MerchantReviewer: 'MERCHANT_REVIEWER',
  MerchantSupervisor: 'MERCHANT_SUPERVISOR',
  MerchantCashier: 'MERCHANT_CASHIER',
  MerchantUser: 'MERCHANT_USER',
  MerchantUnapproved: 'MERCHANT_UNAPPROVED',
  MerchantPOIAdmin: 'MERCHANT_POI_ADMIN',
  ProviderWhiteLabelManager: 'PROVIDER_WHITE_LABEL_MANAGER',
  ProviderAdmin: 'PROVIDER_ADMIN',
  ProviderUser: 'PROVIDER_USER',
  DeveloperAdmin: 'DEVELOPER_ADMIN',
  DeveloperUser: 'DEVELOPER_USER',
  DeveloperSupportAdmin: 'DEVELOPER_SUPPORT_ADMIN',
  DeveloperSupportAppReviewer: 'DEVELOPER_SUPPORT_APP_REVIEWER',
  DeveloperSupportEngineer: 'DEVELOPER_SUPPORT_ENGINEER',
  EstateOwnerAdmin: 'ESTATE_OWNER_ADMIN',
  EstateOwnerSupportAdmin: 'ESTATE_OWNER_SUPPORT_ADMIN',
  EstateOwnerSupportEngineer: 'ESTATE_OWNER_SUPPORT_ENGINEER',
  EstateOwnerMerchantManager: 'ESTATE_OWNER_MERCHANT_MANAGER',
  EstateOwnerDeviceAppManager: 'ESTATE_OWNER_DEVICE_APP_MANAGER',
  EstateOwnerDeviceAppReviewer: 'ESTATE_OWNER_DEVICE_APP_REVIEWER',
  EstateOwnerUser: 'ESTATE_OWNER_USER',
  PetroSiteAdmin: 'PETRO_SITE_ADMIN',
  MajorOilAdmin: 'MAJOR_OIL_ADMIN',
  PetroHelpDeskAdmin: 'PETRO_HELPDESK_ADMIN',
  PetroSiteUser: 'PETRO_SITE_USER',
  MajorOilUser: 'MAJOR_OIL_USER',
  PetroPartnerAdmin: 'PETRO_PARTNER_ADMIN',
  ResellerManager: 'RESELLER_MANAGER',
  ResellerRepresentative: 'RESELLER_REPRESENTATIVE',
  PartnerHelpdesk: 'PARTNER_HELPDESK',
  PartnerFieldServiceProvider: 'PARTNER_FIELD_SERVICE_PROVIDER',
  CommanderOnboarder: 'COMMANDER_ONBOARDER',
  PetroSalesforceAdmin: 'PETRO_SALESFORCE_ADMIN',
  PetroMerchantOnboarder: 'PETRO_MERCHANT_ONBOARDER',
  SystemOnboardingBackend: 'SYSTEM_ONBOARDING_BACKEND',
  MerchantExternalPartner: 'MERCHANT_EXTERNAL_PARTNER',
  PartnerFieldServiceSupervisor: 'PARTNER_FIELD_SERVICE_SUPERVISOR',
  PartnerFieldServiceUser: 'PARTNER_FIELD_SERVICE_USER',
  MerchantOrderAdmin: 'MERCHANT_ORDER_ADMIN',
  PetroBackOffice: 'PETRO_BACK_OFFICE',
  PetroCommanderEF: 'PETRO_COMMANDER_EF',
  PetroDvr: 'PETRO_DVR',
  PetroFuelPriceSign: 'PETRO_FUEL_PRICE_SIGN',
  PetroInternal: 'PETRO_INTERNAL',
  PetroLoyalty: 'PETRO_LOYALTY',
  PetroMobilePayments: 'PETRO_MOBILE_PAYMENTS',
  PetroPropietaryCard: 'PETRO_PROPIETARY_CARD',
  ProcessorRoutingManager: 'PROCESSOR_ROUTING_MANAGER',
  SystemEventPublisher: 'SYSTEM_EVENT_PUBLISHER',
  SystemSignUpBackend: 'SYSTEM_SIGNUP_BACKEND',
  TokenProvider: 'TOKEN_PROVIDER',
};

export const UserRoles = {
  ...VerifoneUserRoles,
  ...ExternalUserRoles,
};

export type UserRoles =
  | (typeof VerifoneUserRoles)[keyof typeof VerifoneUserRoles]
  | (typeof ExternalUserRoles)[keyof typeof ExternalUserRoles];
