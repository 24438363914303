import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'portal-collapsible-panel',
  templateUrl: './collapsible-panel.component.html',
})
export class CollapsiblePanelComponent {
  @Input() expanded = false;
  @Input() showTitle = true;
  @Input() expandedTitle: string;
  @Input() collapsedTitle: string;
  @Input() e2eToggleName = 'collapsiblePanel-toggle';
  @Output() expand = new EventEmitter<void>();
  @Output() collapse = new EventEmitter<void>();

  toggle(): void {
    if (this.expanded) {
      this.collapse.emit();
    } else {
      this.expand.emit();
    }
  }
}
