import { Injectable } from '@angular/core';
import { Ert } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class Erts extends SelectInputsList {
  keyValue = {
    [Ert.Unspecified]: $localize`Unspecified`,
    [Ert.TelephoneOrder]: $localize`Telephone order`,
    [Ert.MailOrder]: $localize`Mail order`,
    [Ert.OpenNetworksInternet]: $localize`Open networks (Internet)`,
    [Ert.TelevisionOrder]: $localize`Television order`,
    [Ert.RecurringPaymentOpenNetworks]: $localize`Recurring payment in open networks`,
    [Ert.RecurringPaymentOtherMeans]: $localize`Recurring payment through other means`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
