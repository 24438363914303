/* eslint-disable no-unused-expressions */
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ITreeOptions,
  ITreeState,
  TreeComponent,
  TreeModel,
  TreeNode,
} from '@circlon/angular-tree-component';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';

import { FilterAutoCompleteInputComponent, SearchSelector } from '@portal/shared/ui/filter';
import {
  EntityType,
  IEntity,
  IEntityAncestors,
  IOrganisation,
} from '@portal/entity-services/interfaces';
import { AuthenticationService } from '@portal/shared/auth/authentication';
import { OrganisationService } from '@portal/entity-services/organisations/src/lib/services/organisation.service';
import { Direction, LanguageHandle } from '@portal/shared/languages';
import { PointerIdentifier } from '../enums/pointer-identifier';
import { ErrorService } from '@portal/shared/ui/form/src/lib/error/error.service';

type SelectedNode = IEntity & { children: SelectedNode[] };

@UntilDestroy()
@Component({
  selector: 'portal-hierarchy-single-select',
  templateUrl: './hierarchy-single-select.component.html',
  styleUrls: ['./hierarchy-single-select.component.scss'],
})
export class HierarchySingleSelectComponent implements OnInit {
  static initialDebounce = 400;
  static reachedConstant = 19;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('control') controlEntityUid: AbstractControl = new FormControl();
  @Input() organizationGroup: boolean;
  @Input() controlEntityName: AbstractControl = new FormControl();
  @Input() hasInitialSetter = false;
  @Input() organisations: IOrganisation[] | IEntity[];
  @Input() id: string;
  @Input() displayOptionApplyAndClear: boolean;
  @Input() rootEntityIdForParentUpdate: string;
  @Input() merchantCompanyEntityCount: number;
  @Input() showTrashCanEntities = false;
  @Input() hideOrganisation: IOrganisation;
  @Output() selectedOrganisation: EventEmitter<IEntity> = new EventEmitter();
  @Output() selectedOrganisationAfterApply: EventEmitter<IEntity> = new EventEmitter();
  @Output() itemsFiltered = new EventEmitter<{ isFirstLoad: boolean; orgs: IOrganisation[] }>();

  @ViewChild(TreeComponent) private tree: TreeComponent;
  @ViewChild(forwardRef(() => FilterAutoCompleteInputComponent))
  private searchInput: FilterAutoCompleteInputComponent;
  @ViewChild('orgSingleInput', { read: ElementRef }) orgSingleInput: ElementRef<HTMLInputElement>;
  @ViewChild('orgGroupInput', { read: ElementRef }) orgGroupInput: ElementRef<HTMLInputElement>;
  selectedNode: SelectedNode;
  loading$: Observable<boolean>;
  loadingNewPortion$: Observable<boolean>;
  loadingMore$ = new BehaviorSubject(0);
  hierarchyViewLoading$: Observable<boolean>;
  searchTerm$ = new Subject<string>();
  search$: Observable<IOrganisation[]>;
  nextPage$ = new Subject<number>();
  pagination$: Observable<IOrganisation[]>;
  selectedEntities: IEntity = {};
  storedSelectedEntities: IEntity = {};
  selectedActiveEntities: IEntity = {};
  entitiesAll: IEntity[] = [];
  entities: IEntity[] = [];
  searchedText = '';
  isOpen = false;
  hasFilteredText = false;
  isFirstLoad = true;
  debounce = 400;
  treeState: ITreeState;
  options: ITreeOptions = {
    getChildren: (node: TreeNode) => {
      return this.organisationService
        .getHierarchyView(
          node.id,
          null,
          this.rootEntityIdForParentUpdate
            ? {
                limit: 1000,
                ...(this.merchantCompanyEntityCount
                  ? { entityType: EntityType.MERCHANT_COMPANY }
                  : {}),
              }
            : null,
        )
        .pipe(
          map((hierarchyList) =>
            hierarchyList.filter((item) => item.entityUid !== this.selectedNode?.entityUid),
          ),
          map((value) =>
            node.id === (this.rootEntityIdForParentUpdate || this.authService.entityUid)
              ? [...(this.selectedNode ? [this.selectedNode] : []), ...value]
              : value,
          ),
          map((list) => {
            if (!this.merchantCompanyEntityCount) {
              list = list.map((item) => {
                return item;
              });
              return list.filter((item) => item.entityUid !== this.hideOrganisation?.entityUid);
            }
            return list;
          }),
        )
        .toPromise();
    },
    useCheckbox: false,
    idField: 'entityUid',
    displayField: 'name',
    rtl:
      this.languageHandle.getCurrentObjectLanguage(this.languageHandle.getBuildLanguage())
        .direction === Direction.RTL,
  };

  isInvalid = ErrorService.isFieldInvalid;

  private start: number;
  private stopLoading: boolean;
  private loadingStatus = false;
  private isEventExpand = false;
  private selectedPath: string[];

  constructor(
    private organisationService: OrganisationService,
    private authService: AuthenticationService,
    private languageHandle: LanguageHandle,
  ) {
    this.loading$ = organisationService.loading$.pipe(shareReplay(1));
    this.hierarchyViewLoading$ = organisationService.hierarchyViewLoading$.pipe(shareReplay(1));
    this.loadingNewPortion$ = organisationService.loadingNewPortion$.pipe(
      tap((value) => (this.loadingStatus = value)),
    );
    this.start = 0;
  }

  ngOnInit(): void {
    this.controlEntityUid.statusChanges.subscribe(() => this.asyncControlsNgFormStates());
    this.controlEntityUid.valueChanges.subscribe((value: string) => {
      if (value) {
        this.setSelectedEntities(value);
      }
    });

    if (this.organisations?.length) {
      const organisation = this.organisations.find((org) => !Object.is(org, null));
      this.selectedEntities = organisation;
      this.controlEntityName.setValue(organisation.name);
    } else if (this.controlEntityUid.value) {
      this.setSelectedEntities(this.controlEntityUid.value);
    }

    this.startListeningForSearch();
    this.startListeningForPageChange();
    this.asyncControlsNgFormStates();
    if (this.hasInitialSetter) {
      this.searchTerm$.next('');
    }
  }

  onScrolled(event: {
    target: { offsetHeight: number; scrollTop: number; scrollHeight: number };
  }): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1 &&
      !this.stopLoading &&
      !this.loadingStatus
    ) {
      this.nextPage$.next(this.start);
    }
  }

  onLoadMore(node: TreeNode): void {
    this.loadingMore$.next(node.id);
    this.organisationService
      .getHierarchyView(
        node.data.entityUid,
        node.data.children.length === 1 ? 0 : node.data.children.length + 1,
        this.rootEntityIdForParentUpdate
          ? {
              limit: 1000,
              ...(this.merchantCompanyEntityCount
                ? { entityType: EntityType.MERCHANT_COMPANY }
                : {}),
            }
          : null,
      )
      .subscribe((value) => {
        node.data.children.push(
          ...(this.selectedNode?.entityUid
            ? value.filter((value1) => !this.selectedPath.includes(value1.entityUid))
            : value),
        );
        this.loadingMore$.next(0);
        this.tree.treeModel.update();
      });
  }

  onActivateItem(selectedEntity: IEntity): void {
    this.selectedEntities = selectedEntity;
    this.storedSelectedEntities = selectedEntity;
    this.isOpen = false;
    this.setControlsValue(this.selectedEntities);
  }

  onDeactivateItem(): void {
    this.selectedEntities = this.storedSelectedEntities;
    this.setControlsValue(this.selectedEntities);
    this.onFilterApplied();
    this.asyncControlsNgFormStates();
  }

  removeSelectedParentOrg(entityUid: string): void {
    if (entityUid === this.selectedEntities.entityUid) {
      this.selectedEntities = null;
      this.onClear();
    }
  }

  onSearch(searchValue = ''): void {
    this.searchedText = searchValue;
    this.start = 0;
    this.searchTerm$.next(searchValue);
  }

  onClickOutside(): void {
    this.isOpen = false;
    this.asyncControlsNgFormStates();
  }

  onToggleExpanded(): void {
    this.isEventExpand = true;
  }

  onToggleDropdown(event: any): void {
    // https://developer.mozilla.org/en-US/docs/Web/API/PointerEvent/pointerId
    if (event.pointerId !== PointerIdentifier.CausingByNotItself && !this.isEventExpand) {
      this.isOpen = !this.isOpen;
      if (!this.entities.length) {
        this.start = 0;
      }
      this.searchedText = '';
      this.searchInput.reset();
      this.searchTerm$.next(this.searchedText);
    }

    this.isEventExpand = false;
    this.asyncControlsNgFormStates();
    if (this.displayOptionApplyAndClear) {
      this.isOpen = true;
    }
  }

  onFilterApplied(applied = false): void {
    this.updateEntities();
    this.start = 0;
    if (!applied) {
      this.selectedOrganisation.emit(this.selectedEntities);
    }
    // If is applied from the template
    if (applied) {
      this.selectedOrganisationAfterApply.emit(this.selectedEntities);
      this.isOpen = false;
    }
  }

  onClear(): void {
    this.selectedEntities = null;
    this.setControlsValue(this.selectedEntities);
    this.onFilterApplied();
    this.asyncControlsNgFormStates();
  }

  onFocusIn(event: FocusEvent, treeModel: TreeModel): void {
    const treeElement = event.currentTarget as HTMLElement;

    if (event.target === treeElement) {
      treeModel.setFocus(true);
      if (!treeModel.focusedNode) {
        treeModel.getFirstRoot()?.focus();
      }
    } else {
      // prevent inputs inside the tree stealing the focus
      treeElement.focus();
    }
  }

  onFocusOut(event: FocusEvent, treeModel: TreeModel): void {
    if (event.target === event.currentTarget) {
      treeModel.setFocus(false);
    }
  }

  private startListeningForPageChange(): void {
    this.pagination$ = this.paginate(this.nextPage$);
    this.pagination$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.entities = [...this.entities, ...data];
      this.entitiesAll = [...this.entitiesAll, ...data];
      if (Object.keys(this.selectedEntities).length) {
        this.updateEntities();
      }
    });
  }

  private paginate(terms: Observable<number>): Observable<IOrganisation[]> {
    return terms.pipe(debounceTime(500)).pipe(
      switchMap((page: number) => {
        this.start = page + 20;
        return this.getEntitiesByName(this.searchedText, true);
      }),
    );
  }

  private getEntitiesByName(term: string, noLoader?: boolean): Observable<IOrganisation[]> {
    const reachedTheEnd = (organisation: IOrganisation[]): boolean => {
      this.hasFilteredText = Boolean(term);
      return (this.stopLoading =
        organisation.length < HierarchySingleSelectComponent.reachedConstant);
    };

    return forkJoin([
      term
        ? this.organisationService.getLightWeightWithQuery(
            {
              name: term,
              ...(this.rootEntityIdForParentUpdate
                ? {
                    ancestorId: this.rootEntityIdForParentUpdate,
                    limit: '1000',
                    ...(this.merchantCompanyEntityCount
                      ? { entityType: EntityType.MERCHANT_COMPANY }
                      : {}),
                  }
                : {}),
            },
            noLoader,
            this.start,
          )
        : this.organisationService
            .getEntity(
              this.start,
              noLoader,
              this.rootEntityIdForParentUpdate || this.authService.entityUid,
              this.merchantCompanyEntityCount ? { entityType: EntityType.MERCHANT_COMPANY } : null,
            )
            .pipe(
              map(([org]) => {
                if (
                  !this.merchantCompanyEntityCount &&
                  org.entityUid === this.hideOrganisation?.parentEntityUid
                ) {
                  let hasChildren = org.count > 1;
                  org = { ...org, count: org.count - 1, hasChildren };
                }
                return [org];
              }),
            ),
      this.showTrashCanEntities
        ? this.organisationService.getLightWeightWithQuery(
            {
              name: term,
              labels: 'TRASHCAN',
              limit: '1000',
            },
            noLoader,
            this.start,
          )
        : of([]),
    ])
      .pipe(
        map(([organisationList, trashCanOrganisationList]) => {
          trashCanOrganisationList = trashCanOrganisationList.map((org) => ({
            ...org,
            trashCan: true,
          }));
          return [
            ...(!this.showTrashCanEntities || this.rootEntityIdForParentUpdate
              ? organisationList
              : []),
            ...trashCanOrganisationList,
          ];
        }),
      )
      .pipe(tap(reachedTheEnd));
  }

  private setSelectedEntities(selectedEntityUid: string): void {
    const selectedForState = {
      [selectedEntityUid]: true,
    };

    this.treeState = this.treeState
      ? {
          ...this.treeState,
          activeNodeIds: selectedForState,
        }
      : { activeNodeIds: selectedForState };
    this.updateSelectedEntities(selectedEntityUid);
  }

  private startListeningForSearch(): void {
    this.search$ = this.search(this.searchTerm$);
    this.search$.pipe(untilDestroyed(this)).subscribe((entities: IEntity[]) => {
      this.entities = entities;
      this.entitiesAll = entities;
      this.emitOrganisations(entities);
      if (Object.keys(this.selectedEntities || {}).length) {
        this.updateEntities();
      }
    });
  }

  private updateSelectedEntities(selectedEntityUid: string): void {
    this.getEntityById(selectedEntityUid)
      .pipe(untilDestroyed(this))
      .subscribe((organisation: IOrganisation) => {
        this.selectedEntities = organisation;
        this.controlEntityName.setValue(organisation.name);
        this.onFilterApplied();
        this.asyncControlsNgFormStates();
      });
  }

  private search(terms: Observable<string>): Observable<IOrganisation[]> {
    return terms.pipe(distinctUntilChanged(), debounceTime(this.debounce)).pipe(
      switchMap((term: string) => {
        // Always reset debounce time
        this.debounce = HierarchySingleSelectComponent.initialDebounce;
        return this.getEntitiesByName(term);
      }),
    );
  }

  private getEntityById(id: string): Observable<IOrganisation> {
    return this.organisationService.getByKey(id, true);
  }

  private updateEntities(): void {
    if (this.selectedEntities?.entityUid) {
      this.treeState.activeNodeIds[this.selectedEntities.entityUid] = true;
      this.tree?.treeModel?.setState(this.treeState);
    }
    this.entities = this.entitiesAll;

    const id = this.selectedEntities?.entityUid;
    if (id && id !== (this.rootEntityIdForParentUpdate || this.authService.entityUid)) {
      this.organisationService.getAncestors(id).subscribe((value) => {
        this.flatten(value);
      });
    }
  }

  private setControlsValue(organisation: IOrganisation): void {
    this.controlEntityUid.setValue(organisation?.entityUid);
    this.controlEntityName.setValue(organisation?.name);
  }

  private emitOrganisations(orgs: IOrganisation[]): void {
    this.itemsFiltered.emit({ isFirstLoad: this.isFirstLoad, orgs });
    this.isFirstLoad = false;
  }

  private asyncControlsNgFormStates(): void {
    this.controlEntityName.touched
      ? this.controlEntityUid.markAsTouched()
      : this.controlEntityUid.markAsUntouched();
    this.controlEntityUid.disabled
      ? this.controlEntityName.disable()
      : this.controlEntityName.enable();
    this.controlEntityUid.validator
      ? this.controlEntityName.setValidators(this.controlEntityUid.validator)
      : this.controlEntityName.setValidators([]);
    this.controlEntityUid.asyncValidator && !this.rootEntityIdForParentUpdate
      ? this.controlEntityName.setAsyncValidators(this.controlEntityUid.asyncValidator)
      : this.controlEntityName.setAsyncValidators([]);
  }

  private async flatten(data: IEntityAncestors): Promise<any> {
    const result = [];
    while (data) {
      result.push(data);
      if (
        data?.parent?.entityUid === (this.rootEntityIdForParentUpdate || this.authService.entityUid)
      ) {
        break;
      }
      data = data.parent;
    }
    result.map((value) => {
      delete value.parent;
      return value;
    });
    this.selectedPath = result.map((value) => value.entityUid);
    const response = await forkJoin(
      result.map((val) =>
        this.organisationService
          .getTotalCount({
            parentId: val[SearchSelector.EntityUid],
            ...(this.merchantCompanyEntityCount ? { entityType: EntityType.MERCHANT_COMPANY } : {}),
          })
          .pipe(
            map((countData) =>
              val.entityUid === this.hideOrganisation?.parentEntityUid &&
              !this.merchantCompanyEntityCount
                ? { count: countData.count - 1 }
                : countData,
            ),
          ),
      ),
    )
      .pipe(
        map((val) =>
          val.map((totalCountData, index) => ({
            count: totalCountData.count,
            ...result[index],
            hasChildren: totalCountData.count > 0,
            isExpanded: index !== 0,
          })),
        ),
      )
      .toPromise();
    const length = result.length;
    const selectedNode = response[length - 1];
    this.insertChild(selectedNode, response);
    this.selectedNode = selectedNode;

    this.expandTree();
  }

  private insertChild(
    selectedNode: SelectedNode,
    array: SelectedNode[],
    index: number = array.length - 2,
  ): void {
    if (index >= 0 && selectedNode) {
      selectedNode.children = [array[index]];
      this.insertChild(selectedNode.children[0], array, index - 1);
    }
  }

  private expandTree(): void {
    const expandedNodeIds = this.entities[0]?.hasChildren
      ? { [this.entities[0].entityUid]: true }
      : {};
    this.selectedPath.forEach((value, index) => {
      if (!index) {
        return;
      }
      expandedNodeIds[value] = true;
      const someNode = this.tree?.treeModel?.getNodeById(value);
      someNode?.expand();
    });

    this.treeState = {
      ...this.treeState,
      expandedNodeIds,
    };
  }
}
