<div class="block mp-block">
  <div class="hero-head"></div>
  <div class="block mp-block">
    <div class="conatiner is-fullwidth mt-20">
      <portal-breadcrumbs *ngIf="poi">
        <a [routerLink]="[altVfiPoiId]">{{ altVfiPoiId }}</a>
      </portal-breadcrumbs>
      <div class="is-flex is-justify-content-space-between is-align-items-flex-start">
        <h1 *ngIf="poi" class="title is-hidden-mobile" data-e2e="page-title">{{ altVfiPoiId }}</h1>
        <div class="is-flex is-align-items-flex-start">
          <ng-content select=".action-buttons"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="poi" class="container">
  <div class="box">
    <div class="tile">
      <div class="device-container tile p-3 is-2 is-marginless">
        <figure class="device-image is-300x400" data-e2e="device-image">
          <div
            class="device-pending-label has-background-primary has-text-white tag is-size-4"
            *ngIf="status === 'PENDING'"
            i18n
          >
            PENDING
          </div>
          <img
            src="{{ poiIcon }}.png"
            srcset="
          {{ poiIcon }}.png,
          {{ poiIcon }}-2x.png 2x"
            width="300"
            height="400"
            alt="{{ poi?.name }}"
            title="{{ poi?.name }}"
            loading="lazy"
          />
        </figure>
      </div>
      <div class="tile table-container">
        <table class="table is-borderless is-fullwidth">
          <tr>
            <td>
              <span
                class="tag td-padding"
                [ngClass]="{
                  'is-warning': status === 'PENDING',
                  'is-success': status !== 'PENDING'
                }"
                data-e2e="device-status"
                >{{ status }}</span
              >
              <span *ngIf="status !== 'PENDING'" class="tag td-padding" data-e2e="device-type">{{
                poi?.device?.model?.deviceType
              }}</span>
            </td>
            <td></td>
            <td></td>
          </tr>

          <tr *ngIf="poi?.altVfiPoiId">
            <td>
              <div i18n>Terminal ID</div>
            </td>
            <td>
              <div data-e2e="terminal-terminalid">{{ altVfiPoiId }}</div>
            </td>
            <td></td>
          </tr>

          <tr *ngIf="poi?.device?.serialNumber">
            <td>
              <div i18n>Serial number</div>
            </td>
            <td>
              <div data-e2e="device-serialnumber">{{ poi?.device?.serialNumber }}</div>
            </td>
            <td></td>
          </tr>

          <tr *ngIf="parentEntityName">
            <td>
              <div i18n>Parent Merchant name</div>
            </td>
            <td>
              <div data-e2e="terminal-parent-merchant-name">{{ parentEntityName }}</div>
            </td>
            <td></td>
          </tr>

          <tr *ngIf="poi?.entity?.name">
            <td>
              <div i18n>Sub Merchant's name</div>
            </td>
            <td>
              <div data-e2e="sub-merchant-name">{{ poi?.entity?.name }}</div>
            </td>
            <td></td>
          </tr>

          <tr *ngIf="poi?.entity?.altVfiEntityId">
            <td>
              <div i18n>Sub Merchant's MID</div>
            </td>
            <td>
              <div data-e2e="sub-merchant-mid">{{ poi?.entity?.altVfiEntityId }}</div>
            </td>
            <td></td>
          </tr>

          <!-- Hierarchy -->
          <!-- <tr>
          <td><div i18n>Hierarchy</div></td>
          <td>
            <nav class="breadcrumb is-marginless" aria-label="breadcrumbs">
              <ul>
                <li><span i18n>Horeca</span></li>
                <li><span i18n>Stach</span></li>
                <li><span i18n>NL</span></li>
                <li><span i18n>AMS</span></li>
                <li><span i18n>Stach Kerstraat</span></li>
              </ul>
            </nav>
          </td>
          <td></td>
        </tr> -->

          <!-- <tr>
          <td><div i18n>Flash</div></td>
          <td>
            <div class="columns is-mobile is-desktop is-vcentered">
              <div class="column is-2-mobile is-2-tablet is-1-desktop">
                <span>88%</span>
              </div>
              <div class="column is-10-mobile is-8-tablet is-6-desktop">
                <progress class="progress is-small is-warning" value="88" max="100">75%</progress>
              </div>
            </div>
          </td>
          <td></td>
        </tr>
  
        <tr>
          <td><div i18n>RAM</div></td>
          <td>
            <div class="columns is-mobile is-desktop is-vcentered">
              <div class="column is-2-mobile is-2-tablet is-1-desktop">
                <span>63%</span>
              </div>
              <div class="column is-10-mobile is-8-tablet is-6-desktop">
                <progress class="progress is-small is-success" value="63" max="100">75%</progress>
              </div>
            </div>
          </td>
          <td></td>
        </tr> -->
        </table>
      </div>
    </div>
  </div>
</div>

<ng-content *ngIf="poi"></ng-content>

<div *ngIf="!poi" class="container has-text-centered mt-6">
  <h2 class="title is-spaced">There is no data available</h2>
  <p class="subtitle">You can try to select an existing terminal</p>
  <a [routerLink]="['../../']" class="button is-primary">Back to terminals</a>
</div>
