import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { QuickPicks } from '../enums/date.enum';
declare const $localize;

@Injectable({ providedIn: 'root' })
export class CompareToQuickpicks extends SelectInputsList {
  keyValue = {
    [QuickPicks.PreviousPeriod]: $localize`Previous period`,
    [QuickPicks.PreviousYear]: $localize`Previous year`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
