import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITokenScope } from '@portal/entity-services/interfaces';
import omitBy from 'lodash-es/omitBy';

@Component({
  selector: 'portal-create-token-scope',
  templateUrl: './create.component.html',
})
export class CreateTokenScopeComponent {
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<ITokenScope> = new EventEmitter<ITokenScope>();
  @Input() saving: boolean;

  onCanceled(): void {
    this.closed.emit();
  }

  onFormValidated(tokenScope: ITokenScope): void {
    const removedEmptyValues = omitBy(tokenScope, (value) => !value && value !== 0);

    this.saved.emit(removedEmptyValues);
  }
}
