import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { Frequency } from '../enum/frequency.enum';

declare const $localize;
@Injectable()
export class FrequencyTypes extends SelectInputsList {
  keyValue = {
    [Frequency.Daily]: $localize`:report scheduler frequency|:Daily`,
    [Frequency.Weekly]: $localize`:report scheduler frequency|:Weekly`,
    [Frequency.Monthly]: $localize`:report scheduler frequency|:Monthly`,
  };
  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
