import { Directive, HostBinding, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Directive({
  selector: '[portalSrcBlob], [srcBlob]',
})
export class SrcBlobDirective implements OnDestroy {
  @Input() set srcBlob(value: Blob) {
    this.cleanup();
    this.url = value ? URL.createObjectURL(value) : null;
    this.src = this.url && this.sanitizer.bypassSecurityTrustUrl(this.url);
  }

  @HostBinding('attr.src')
  src: SafeUrl;

  private url: string;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnDestroy(): void {
    this.cleanup();
  }

  private cleanup(): void {
    if (this.url) URL.revokeObjectURL(this.url);
  }
}
