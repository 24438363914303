import { Injectable } from '@angular/core';
import {
  HelpPageParameter,
  HelpPageParameterResponse,
  IOrganisation,
  TemplateParameters,
  TemplateParametersResponse,
} from '@portal/entity-services/interfaces';
import { FileService } from '@portal/shared/ui/file-input/src/lib/services/file.service';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import omit from 'lodash-es/omit';
import omitBy from 'lodash-es/omitBy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap } from 'rxjs/operators';
import { CombinedOrgTemplateParameters } from '../interfaces/combined-org-template-parameters';
import { OrganisationService } from './organisation.service';
import { TemplateParametersDataService } from './template-parameters-data.service';

@Injectable({ providedIn: 'root' })
export class TemplateParametersService {
  loading$: Observable<boolean>;
  actionLoading$: Observable<boolean>;

  private initialTemplateParameters: TemplateParameters;
  private loadingSubject = new BehaviorSubject(false);
  private actionLoadingSubject = new BehaviorSubject(false);

  private actionInProgressCount = 0;

  constructor(
    private fileService: FileService,
    private organisationService: OrganisationService,
    private dataService: TemplateParametersDataService,
  ) {
    this.loading$ = this.loadingSubject.asObservable();
    this.actionLoading$ = this.actionLoadingSubject.asObservable();
  }

  getCombinedOrgWithTemplateParameters(
    org: IOrganisation,
    canEditWhiteLabel: boolean,
    setLoading = false,
  ): Observable<CombinedOrgTemplateParameters> {
    if (setLoading) this.setLoadingState(true);

    return (
      org.domainName && canEditWhiteLabel
        ? this.getTemplateParameters(org.domainName).pipe(
            map((templateParameters: TemplateParameters) => ({ org, templateParameters })),
            catchError(() => of({ org, templateParameters: null })),
          )
        : of({ org, templateParameters: null })
    ).pipe(finalize(() => this.setLoadingState(false)));
  }

  getOrgWithTemplateParametersAndCombine(
    entityUid: string,
    canEditWhiteLabel: boolean,
  ): Observable<CombinedOrgTemplateParameters> {
    this.setLoadingState(true);
    return this.organisationService
      .getEntityByID(entityUid)
      .pipe(switchMap((org) => this.getCombinedOrgWithTemplateParameters(org, canEditWhiteLabel)));
  }

  setTemplateLogoImage(templateParameters: TemplateParameters): Observable<void> {
    if (isEmpty(templateParameters.logo)) return of();

    this.setActionLoadingState(true);

    const logoFile = templateParameters.logo[0];
    const formData = this.fileService.convertFileToFormData('templateLogoFile', logoFile);

    return this.dataService
      .setTemplateLogoImage(templateParameters.id, formData)
      .pipe(finalize(() => this.setActionLoadingState(false)));
  }

  setTemplateFaviconImage(templateParameters: TemplateParameters): Observable<void> {
    if (isEmpty(templateParameters.favicon)) return of();

    this.setActionLoadingState(true);

    const faviconFile = templateParameters.favicon[0];
    const formData = this.fileService.convertFileToFormData('faviconFile', faviconFile);

    return this.dataService
      .setTemplateFaviconImage(templateParameters.id, formData)
      .pipe(finalize(() => this.setActionLoadingState(false)));
  }

  setTemplateParameters(templateParameters: TemplateParameters): Observable<void> {
    this.setActionLoadingState(true);

    const data = this.omitEmptyFields(templateParameters);
    const formattedData = this.formatTemplateParameters(data);

    return this.dataService
      .setTemplateParameters(templateParameters.id, formattedData)
      .pipe(finalize(() => this.setActionLoadingState(false)));
  }

  isLogoChanged(logo: TemplateParameters['logo']): boolean {
    const logo1 = logo?.[0];
    const logo2 = this.initialTemplateParameters?.logo?.[0];
    return !isEqual(
      omit(logo1, ['lastModifiedDate', 'lastModified']),
      omit(logo2, ['lastModifiedDate', 'lastModified']),
    );
  }

  isFaviconChanged(favicon: TemplateParameters['favicon']): boolean {
    const favicon1 = favicon?.[0];
    const favicon2 = this.initialTemplateParameters?.favicon?.[0];
    return !isEqual(
      omit(favicon1, ['lastModifiedDate', 'lastModified']),
      omit(favicon2, ['lastModifiedDate', 'lastModified']),
    );
  }

  isTemplateChanged(template: TemplateParameters): boolean {
    const fieldsToOmit = ['logo', 'favicon', 'id'];

    return !isEqual(
      omit(template, fieldsToOmit),
      omit(this.initialTemplateParameters, fieldsToOmit),
    );
  }

  isPageStyleChanged(template: TemplateParameters): boolean {
    return !isEqual(template?.pageStyle, this.initialTemplateParameters?.pageStyle);
  }

  isCustomStylesChanged(template: TemplateParameters): boolean {
    return (
      this.isPageStyleChanged(template) ||
      this.isLogoChanged(template?.logo) ||
      this.isFaviconChanged(template?.favicon)
    );
  }

  setInitialParameters(templateParameters: TemplateParameters): void {
    this.initialTemplateParameters = templateParameters;
  }

  getInitialParameters(): TemplateParameters {
    return this.initialTemplateParameters;
  }

  cleanInitialParameters(): void {
    this.setInitialParameters(null);
  }

  deleteTemplateParameters(domainName: string, entityUid: string): Observable<any> {
    return this.dataService
      .deleteTemplateParameters(domainName)
      .pipe(mergeMap(() => this.organisationService.update({ domainName: '', entityUid })));
  }

  isTemplateParamsExist(templateParameters: TemplateParameters): boolean {
    const domaineName = templateParameters?.id;
    const isDomaineName = isEmpty(domaineName);

    const font = templateParameters.pageStyle?.font;
    const isFontEmpty = isEmpty(font) || isEmpty(font[0]);

    const navBar = templateParameters.pageStyle?.navBar;
    const isNavBarEmpty =
      isEmpty(navBar?.navbarBackgroundColor) || isEmpty(navBar?.navbarItemColor);

    const brand = templateParameters.pageStyle?.brand;
    const isBrandEmpty =
      isEmpty(brand?.brandBackground) ||
      isEmpty(brand?.brandText) ||
      isEmpty(brand?.brandOutlinedText);

    const text = templateParameters.text;
    const isTextEmpty =
      isEmpty(text?.brandText) || isEmpty(text?.privacyPolicyUrl) || isEmpty(text?.loginDomainName);

    const helpPage = templateParameters.helpPage;
    const isHelpPageEmpty =
      isEmpty(helpPage) || helpPage.some((helpDetails) => isEmpty(helpDetails.country));

    return (
      isDomaineName &&
      isFontEmpty &&
      isNavBarEmpty &&
      isBrandEmpty &&
      isTextEmpty &&
      isHelpPageEmpty
    );
  }

  private getTemplateParameters(domainName: string): Observable<TemplateParameters> {
    return this.dataService.getTemplateParameters(domainName).pipe(
      map((template: TemplateParametersResponse) => {
        const helpPage: HelpPageParameter[] = (template?.helpPage || []).map((item) => ({
          country: Object.keys(item)[0],
          emailOrUrl: Object.values(item)[0].emailOrUrl,
          notes: Object.values(item)[0].notes,
        }));
        const logo = template?.logo && [new File([''], template.logo)];
        const favicon = template?.favicon && [new File([''], template.favicon)];

        return {
          ...template,
          pageStyle: omitBy(template?.pageStyle, isEmpty),
          helpPage,
          logo,
          favicon,
        };
      }),
    );
  }

  private setLoadingState(state: boolean): void {
    this.loadingSubject.next(state);
  }

  private setActionLoadingState(state: boolean): void {
    if (state) {
      this.actionInProgressCount++;
      this.actionLoadingSubject.next(true);
    } else {
      this.actionInProgressCount--;
      if (this.actionInProgressCount === 0) {
        this.actionLoadingSubject.next(false);
      }
    }
  }

  private formatTemplateParameters(
    templateParameters: TemplateParameters,
  ): TemplateParametersResponse {
    const helpPage: HelpPageParameterResponse[] = (templateParameters.helpPage || []).map(
      (item) => {
        return {
          [item.country]: {
            emailOrUrl: item?.emailOrUrl,
            notes: item?.notes,
          },
        };
      },
    );

    return {
      ...omit(templateParameters, ['logo']),
      text: templateParameters?.text || {},
      helpPage,
    };
  }

  private omitEmptyFields(templateParameters: TemplateParameters): TemplateParameters {
    const parameters: TemplateParameters = {
      id: templateParameters.id,
      text: templateParameters.text,
      pageStyle: {},
    };

    const font = templateParameters.pageStyle?.font;
    const isFontEmpty = isEmpty(font) || isEmpty(font[0]);
    if (!isFontEmpty) {
      parameters.pageStyle.font = font;
    }

    const navBar = templateParameters.pageStyle?.navBar;
    const isNavBarEmpty =
      isEmpty(navBar?.navbarBackgroundColor) || isEmpty(navBar?.navbarItemColor);
    if (!isNavBarEmpty) {
      parameters.pageStyle.navBar = navBar;
    }

    const brand = templateParameters.pageStyle?.brand;
    const isBrandEmpty =
      isEmpty(brand?.brandBackground) ||
      isEmpty(brand?.brandText) ||
      isEmpty(brand?.brandOutlinedText);
    if (!isBrandEmpty) {
      parameters.pageStyle.brand = {
        ...brand,
      };
    }

    const helpPage = templateParameters.helpPage;
    const isHelpPageEmpty =
      isEmpty(helpPage) || helpPage.some((helpDetails) => isEmpty(helpDetails.country));
    if (!isHelpPageEmpty) {
      parameters.helpPage = helpPage;
    }

    return parameters;
  }
}
