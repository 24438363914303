// import { HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
// import { AuthenticationService } from '@portal/shared/auth/authentication';
import { VuiHttpService } from '../../../../vui-http/src/lib/http-wrapper/vui-http.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secureLoad',
})
export class SecureLoadPipe implements PipeTransform {
  constructor(
    // private authenticationService: AuthenticationService,
    private vuiHttpService: VuiHttpService,
    private sanitizer: DomSanitizer,
  ) {}

  transform(url: string): Observable<any> {
    // const token = this.authenticationService.jwtToken;

    // const headers = new HttpHeaders(token ? { Authorization: `Bearer ${token}` } : {});

    return (
      this.vuiHttpService
        .get(url, { responseType: 'blob' })
        // create an object url of that blob that we can use in the src attribute
        .pipe(map((e: any) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))))
    );

    // return new Observable<any>(observer => {
    //   const { next, error } = observer;
    //   observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

    //   this.vuiHttpService.get(url, { headers, responseType: 'blob' }).subscribe(
    //     (response: any) => {
    //       const reader = new FileReader();
    //       const dataType = response.type;
    //       reader.readAsDataURL(response);
    //       reader.onloadend = () => {
    //         observer.next(this.sanitizer.bypassSecurityTrustUrl(reader.result as string));
    //       };
    //     },
    //     err => {
    //       this.notifierService.error(err.message);
    //     },
    //   );

    //   return {
    //     unsubscribe(): any {},
    //   };
    // });
  }
}
