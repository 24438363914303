import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VfAppMarketAppCatalogService } from '../vf-app-market-app-catalog/vf-app-market-app-catalog.service';
import { AppUpgrade } from './app-upgrade.model';
import { AppUpgradesQuery } from './app-upgrades.query';
import { AppUpgradesStore } from './app-upgrades.store';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppUpgradesService {
  constructor(
    private appUpgradesStore: AppUpgradesStore,
    public appUpgradesQuery: AppUpgradesQuery,
    private vfAppMarketAppCatalogService: VfAppMarketAppCatalogService,
  ) {}

  loadAppUpgrades(appId: string, entityUid?: string): void {
    if (!this.appUpgradesQuery.hasEntity((entity: AppUpgrade) => (entity.appId = appId))) {
      this.loadAppUpgradesObs(appId, entityUid).subscribe();
    }
  }

  loadAppUpgradesObs(appId: string, entityUid?: string): Observable<AppUpgrade[]> {
    return this.vfAppMarketAppCatalogService
      .getAppUpdates(appId, entityUid)
      .pipe(tap((data) => this.appUpgradesStore.set(data.data)));
  }

  getAppUpdate$(appId: string): Observable<AppUpgrade> {
    return this.appUpgradesQuery.selectEntity(appId);
  }

  reset(): void {
    this.appUpgradesStore.setHasCache(false);
    this.appUpgradesStore.reset();
  }
}
