import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortOrder } from '../enums/sort-order.enum';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'portal-sort-button',
  templateUrl: './sort-button.component.html',
})
export class SortButtonComponent {
  @Input() order: SortOrder;
  @Output() sortChanged: EventEmitter<SortOrder> = new EventEmitter<SortOrder>();
  SortOrder = SortOrder;

  changeOrder(): void {
    if (this.order === SortOrder.Asc) {
      this.order = SortOrder.Desc;
    } else {
      this.order = SortOrder.Asc;
    }
    this.sortChanged.emit(this.order);
  }
}
