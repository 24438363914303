import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotifierComponent } from './notifier.component';

export * from './notifier.component';
export * from './notification-type.enum';
export * from './notification';
export * from './notifier.service';
export * from './notifier';

@NgModule({
  declarations: [NotifierComponent],
  imports: [CommonModule],
  exports: [NotifierComponent],
})
export class NotifierModule {}
