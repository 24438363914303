import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@apps/portal/src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ENTITY_METADATA_TOKEN } from '@ngrx/data';
import { SharedAuthAuthorizationModule } from '@portal/shared/auth/authorization';
import { SharedUiFilterModule } from '@portal/shared/ui/filter';
import { FormModule } from '@portal/shared/ui/form';
import { SharedUiModalModule } from '@portal/shared/ui/modal';
import { TableModule } from '@portal/shared/ui/table';
import { NgxMaskModule } from 'ngx-mask';
import { CreateOrganisationComponent } from './components/create/create.component';
import { DetailOrganisationComponent } from './components/detail/detail.component';
import { SecureCardCaptureModule } from './components/detail/secure-card-capture/secure-card-capture.module';
import { CreateTokenScopeComponent } from './components/detail/token-scope/create/create.component';
import { FormTokenScopeComponent } from './components/detail/token-scope/form/form.component';
import { LinkedTokenScopeComponent } from './components/detail/token-scope/linked-token-scope/linked-token-scope.component';
import { TokenScopeComponent } from './components/detail/token-scope/token-scope.component';
import { UpdateTokenScopeComponent } from './components/detail/token-scope/update/update.component';
import { FilterEntityTypeComponent } from './components/filter/entity-type/entity-type.component';
import { FilterOrganisationsComponent } from './components/filter/filter.component';
import { FormAddressComponent } from './components/form/form-address/form-address.component';
import { FormContactComponent } from './components/form/form-contact/form-contact.component';
import { FormOrganisationComponent } from './components/form/form.component';
import { ListOrganisationComponent } from './components/list/list.component';
import { HierarchySingleSelectModule } from '@portal/shared/ui/hierarchy-single-select';
import { UpdateOrganisationComponent } from './components/update/update.component';
import { OrganisationsRoutes } from './organisations.routing';
import { entityMetadata } from './store/entity-metadata';
import { PortalTreeModule } from '@portal/shared/ui/tree';
import { IntTelInputModule } from '@portal/shared/ui/int-tel-input';
import { FileInputModule } from '@portal/shared/ui/file-input';
import { WhiteLabelingPreviewModule } from './components/white-labeling-preview/white-labeling-preview.module';
import { SharedUiAccordionsModule } from '@portal/shared/ui/accordions';
import { AssociatedEntitiesComponent } from './components/detail/associated-entities/associated-entities.component';
import { FeaturesComponent } from './components/features/features.component';
import { ResellerDetailsComponent } from './components/form/reseller-details/reseller-details.component';
import { DetailResellerFormComponent } from './components/detail/detail-reseller-form/detail-reseller-form.component';
import { FormSharedService } from './components/form/form-shared.service';
import { EntityServiceFormsModule } from '../../../forms/src/lib/forms.module';
import { ReportEngineDataService } from '@apps/portal/src/app/shared/report-engine/services/report-engine-data.service';
import { ReportEngineService } from '@apps/portal/src/app/modules/report-engine/services/report-engine.service';
import { ReportScheduleDataService } from '@apps/portal/src/app/shared/report-engine/services/report-schedule-data.service';
import { SharedUiBlobModule } from '@portal/shared/ui/blob/src/lib/shared-ui-blob.module';
import { PipesModule } from '@portal/shared/pipes/src';
import { MerchantChoiceRoutingComponent } from './components/merchant-choice-routing/merchant-choice-routing.component';

@NgModule({
  declarations: [
    FilterEntityTypeComponent,
    ListOrganisationComponent,
    DetailOrganisationComponent,
    CreateOrganisationComponent,
    FormOrganisationComponent,
    UpdateOrganisationComponent,
    FormAddressComponent,
    FilterOrganisationsComponent,
    TokenScopeComponent,
    CreateTokenScopeComponent,
    FormTokenScopeComponent,
    UpdateTokenScopeComponent,
    LinkedTokenScopeComponent,
    FormContactComponent,
    AssociatedEntitiesComponent,
    FeaturesComponent,
    ResellerDetailsComponent,
    DetailResellerFormComponent,
    MerchantChoiceRoutingComponent,
  ],
  imports: [
    CommonModule,
    FileInputModule,
    SharedUiAccordionsModule,
    SharedUiModalModule,
    SharedUiFilterModule,
    EntityServiceFormsModule,
    SharedModule,
    TableModule,
    NgSelectModule,
    FormModule,
    PortalTreeModule,
    FormsModule,
    ReactiveFormsModule,
    OrganisationsRoutes,
    HierarchySingleSelectModule,
    SharedAuthAuthorizationModule,
    NgxMaskModule.forRoot(),
    IntTelInputModule,
    SecureCardCaptureModule,
    WhiteLabelingPreviewModule,
    SharedUiBlobModule,
    PipesModule,
  ],
  providers: [
    FormSharedService,
    { provide: ENTITY_METADATA_TOKEN, multi: true, useValue: entityMetadata },
    ReportEngineDataService,
    ReportEngineService,
    ReportScheduleDataService,
  ],
  exports: [
    ListOrganisationComponent,
    DetailOrganisationComponent,
    CreateOrganisationComponent,
    FormOrganisationComponent,
    UpdateOrganisationComponent,
    FormAddressComponent,
    FilterOrganisationsComponent,
    FilterEntityTypeComponent,
    FormContactComponent,
    AssociatedEntitiesComponent,
    ResellerDetailsComponent,
    MerchantChoiceRoutingComponent,
  ],
})
export class OrganisationsModule {
  constructor() {}
}
