import { EntityHttpResourceUrls, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class BackendApiUrlGenerator implements HttpUrlGenerator {
  entityResource(entityName: string, _root: string): string {
    return entityName;
  }
  collectionResource(entityName: string, _root: string): string {
    return entityName;
  }
  registerHttpResourceUrls(_entityHttpResourceUrls: EntityHttpResourceUrls): void {}
}
