import { Injectable } from '@angular/core';
import { UserStatus } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers';
import { ISelectInput } from '@portal/shared/ui/filter';
import { sortBy } from 'lodash';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class UserStatuses extends SelectInputsList {
  keyValue = {
    [UserStatus.Active]: $localize`Enabled`,
    [UserStatus.Inactive]: $localize`Disabled`,
    [UserStatus.Deleted]: $localize`Deleted`,
  };

  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);
}
