<marketplace-cards-table-template [isLoading]="isLoading$" [items]="appCatalogUiData$ | async">
  <ng-container title> Applications Catalog </ng-container>

  <ng-container Loading> isLoading </ng-container>
  <ng-container Cards>
    <marketplace-app-catalog-cards-template
      (navigateToAppPage)="goToAppPage($event)"
      [isPublic]="true"
      [apps]="appCatalogUiData$ | async"
    >
    </marketplace-app-catalog-cards-template>
  </ng-container>
  <ng-container Table>
    <marketplace-app-catalog-table-template
      rowLink="/app"
      [apps]="appCatalogUiData$ | async"
      [tableConfig]="tableConfig"
    >
    </marketplace-app-catalog-table-template>
  </ng-container>

  <ng-container no-results>
    <div class="container has-text-centered">
      <h2 class="title is-spaced">There is no data available</h2>

      <!-- <p class="subtitle">You May Try Another Filter</p> -->
      <!-- <a class="button is-primary" (click)="goToAppCatalog()">Applications Catalog</a> -->
    </div>
  </ng-container>
</marketplace-cards-table-template>

<ng-template #cellIconTemplate let-app="data">
  <img *ngIf="app.appIcons.length" [src]="app.appIcons[0].publicUrl" width="32" />
  <img *ngIf="!app.appIcons.length" src="assets/images/img-placeholder.png" width="32" />
</ng-template>
