import { Inject, Injectable, Optional } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src/lib/create-list-from-inputs/select-input-list';
import { LANGUAGES } from '@portal/shared/languages/src/lib/constants/lang.constants';
import { Locale } from '@portal/shared/languages/src/lib/enums/locale.enum';
import sortBy from 'lodash-es/sortBy';
import { LanguageOption } from '../interfaces/language-option';
import { SUPPORTED_LANGUAGES } from '../constants/supported-languages';
declare const $localize;

@Injectable({
  providedIn: 'root',
})
export class LanguageOptions extends SelectInputsList {
  keyValue = {
    [Locale.Ar]: $localize`Arabic`,
    [Locale.Da]: $localize`Danish`,
    [Locale.DaDk]: $localize`Danish`,
    [Locale.De]: $localize`German`,
    [Locale.DeDe]: $localize`German`,
    [Locale.En]: $localize`English`,
    [Locale.EnUs]: $localize`English`,
    [Locale.EsEs]: $localize`Spanish`,
    [Locale.EsMx]: $localize`Spanish (Mexico)`,
    [Locale.Es]: $localize`Spanish`,
    [Locale.Et]: $localize`Estonian`,
    [Locale.EtEE]: $localize`Estonian`,
    [Locale.Fi]: $localize`Finnish`,
    [Locale.FiFI]: $localize`Finnish`,
    [Locale.Fr]: $localize`French`,
    [Locale.FrCa]: $localize`French (Canada)`,
    [Locale.FrFr]: $localize`French`,
    [Locale.He]: $localize`Hebrew`,
    [Locale.Is]: $localize`Icelandic`,
    [Locale.IsIS]: $localize`Icelandic`,
    [Locale.It]: $localize`Italian`,
    [Locale.ItIt]: $localize`Italian`,
    [Locale.Lt]: $localize`Lithuanian`,
    [Locale.LtLT]: $localize`Lithuanian`,
    [Locale.Lv]: $localize`Latvian`,
    [Locale.LvLV]: $localize`Latvian`,
    [Locale.Nl]: $localize`Dutch`,
    [Locale.NlBe]: $localize`Dutch (Belgium)`,
    [Locale.NlNl]: $localize`Dutch`,
    [Locale.No]: $localize`Norwegian`,
    [Locale.NoNO]: $localize`Norwegian`,
    [Locale.Pl]: $localize`Polish`,
    [Locale.PlPl]: $localize`Polish`,
    [Locale.Pt]: $localize`Portuguese`,
    [Locale.PtPt]: $localize`Portuguese`,
    [Locale.Ru]: $localize`Russian`,
    [Locale.Sv]: $localize`Swedish`,
    [Locale.SvSE]: $localize`Swedish`,
    [Locale.El]: $localize`Greek`,
    [Locale.ElGr]: $localize`Greek`,
  };

  list: LanguageOption[];

  constructor(
    @Optional() @Inject(SUPPORTED_LANGUAGES) private supportedLanguages: Locale[] = [Locale.En],
  ) {
    super();
    this.list = sortBy(this.getSelectInputs(), ['text']);
  }

  protected getSelectInputs(): LanguageOption[] {
    return this.supportedLanguages.map((languageId) => {
      const language = LANGUAGES.find((lang) => lang.id === languageId);
      const endonym = language?.text;
      const exonym = this.keyValue[languageId];
      return {
        id: languageId,
        text: `${exonym} (${endonym})`,
        name: exonym,
      };
    });
  }
}
