<p class="control has-icons-left">
  <input
    #searchRef
    class="input is-medium"
    type="text"
    placeholder="{{ placeholder }}"
    (input)="onSearch(searchRef.value)"
    data-e2e="input-search-autocomplete"
    [attr.id]="id"
    portalAutoFocus
    [maxLength]="400"
    [(ngModel)]="searchInputValue"
  />
  <span class="icon is-small is-left"> <i class="fas fa-search" aria-hidden="true"></i> </span>
</p>
