import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BUNDLES_API } from '@portal/marketplace/api-interfaces/src';
import { UserService } from '@portal/shared/user/src';
import { UserRoles } from '@portal/shared/auth/authorization/src';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DevicesAssignedToBundleDataService {
  constructor(private http: HttpClient, private userService: UserService) {}
  getDevicesAssignedToBundle(
    appMarketBundleId: string,
    merchantEntityUid?: string,
  ): Observable<any> {
    let url = BUNDLES_API.MERCHANT.GET_DEVICES_ASSIGNED_TO_BUNDLE(
      merchantEntityUid ? merchantEntityUid : this.userService.getEntityUid(),
      appMarketBundleId,
    );
    if (
      this.userService.roles.filter((role) =>
        [UserRoles.EstateOwnerAdmin, UserRoles.EstateOwnerDeviceAppManager].includes(role),
      ).length
    ) {
      url = BUNDLES_API.MERCHANT.GET_DEVICES_ASSIGNED_TO_BUNDLE(
        merchantEntityUid,
        appMarketBundleId,
      );
    }

    return this.http.get(url);
  }
}
