import { Injectable } from '@angular/core';
import environment from 'environments/environment';
import get from 'lodash-es/get';

@Injectable()
export class EnvironmentVariableService {
  static isEnvironmentLocal(): boolean {
    return (
      // eslint-disable-next-line @typescript-eslint/naming-convention
      !(environment as Partial<{ DOCKER_ENV: boolean | any }>)?.DOCKER_ENV &&
      window.location.hostname.includes('localhost')
    );
  }

  static getAuthVar(variableName: string): string | number {
    const variableEnv = `AUTH.${variableName}`;
    return get(environment, variableEnv);
  }

  static getVar(variableName: string): number | boolean {
    return get(environment, variableName);
  }

  static getFeatureFlag(variableName: string): boolean {
    return get(environment.FEATURE_FLAGS, variableName);
  }

  static getVars(areaName: string): Object {
    return get(environment, areaName, {});
  }

  static getEstateOwnerUrl(): string {
    return environment.API_ENDPOINT.VHQ_ESTATE_OWNER_SERVICE;
  }
}
