import { Injectable } from '@angular/core';
import { BankAccountType } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class BankAccountTypes extends SelectInputsList {
  keyValue = {
    [BankAccountType.Domestic]: $localize`Domestic`,
    [BankAccountType.Sepa]: $localize`Sepa`,
    [BankAccountType.External]: $localize`External`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
