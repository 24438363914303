import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DevAppsTransformerService } from '@portal/marketplace/api-tranformers/src';
import {
  AppStatus,
  FileType,
  IApp,
  IAppVersion,
  INewApp,
  INewVersion,
  StorePagination,
} from '@portal/marketplace/api-interfaces';
import { IFilterToApply } from '@portal/shared/ui/filter/src';
import { NotifierService } from '@portal/shared/ui/notifier';
import { IQueryParams } from '@portal/shared/ui/table';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AllAppsCatalogStore } from '../all-apps-catalog/all-apps-catalog.store';
import { IAppStoreMMandDev } from '../apps/apps.store';
import { DevAppCatalogDataService } from './dev-app-catalog-data.service';
import { DevAppCatalogQuery } from './dev-app-catalog.query';
import { DevAppCatalogStore } from './dev-app-catalog.store';

@Injectable({
  providedIn: 'root',
})
export class DevAppCatalogService extends StorePagination {
  private updateSubject = new Subject();
  private changeStatusStateSubject = new Subject<boolean>();
  // tslint:disable-next-line
  constructor(
    private appsStore: IAppStoreMMandDev,
    private devAppsCatalogStore: DevAppCatalogStore,
    private devAppCatalogQuery: DevAppCatalogQuery,
    private allAppsCatalogStore: AllAppsCatalogStore,
    private devAppCatalogDataService: DevAppCatalogDataService,
    private appsTransformerService: DevAppsTransformerService,
    private router: Router,
    private notifierService: NotifierService,
  ) {
    super();
  }

  getChangeStatusState(): Observable<boolean> {
    return this.changeStatusStateSubject.asObservable();
  }

  getUpdateStatus(): Observable<any> {
    return this.updateSubject.asObservable();
  }
  loadApps(pagination: IQueryParams, filters: IFilterToApply[]): void {
    this.devAppsCatalogStore.setLoading(true);
    this.devAppCatalogDataService
      .loadApps(pagination, filters)
      .pipe(
        tap(apps => {
          this.devAppsCatalogStore.update({ metadata: apps['metadata'] });
          this.updateStorePagination(
            this.devAppsCatalogStore,
            pagination,
            apps['data'].map(app => app.latestAppVersion.id),
          );
        }),
        map(appsRes => {
          return this.appsTransformerService.transformIDevAppToIApp(appsRes['data']);
        }),
      )
      .subscribe(apps => {
        this.devAppsCatalogStore.add(apps);
        this.devAppsCatalogStore.setLoading(false);
      });
  }
  loadApp(appId: string): void {
    this.devAppsCatalogStore.setLoading(true);
    this.devAppCatalogDataService
      .loadApp(appId)
      .pipe(
        map(res => {
          return this.appsTransformerService.transformIDevAppToIApp([res['data'][0]]);
        }),
      )
      .subscribe(app => {
        this.devAppsCatalogStore.add(app);
        this.devAppsCatalogStore.setLoading(false);
      });
  }

  getApps(pagination: IQueryParams): Observable<IApp[]> {
    const storePagination = this.devAppsCatalogStore.getValue().pagination.pages;
    const idsToReturn = storePagination.get(pagination.start / pagination.limit);
    return this.devAppCatalogQuery.selectMany(idsToReturn);
  }
  getMetadata(): any {
    return this.devAppCatalogQuery.getValue().metadata;
  }
  registerApp(newApp: INewApp): Observable<any> {
    return this.devAppCatalogDataService.registerApp(newApp).pipe(
      catchError(error => {
        error.error.errors.forEach(err => {
          this.notifierService.error(err.message);
        });
        return of(null);
      }),
      tap(response => {
        if (response) {
          this.notifierService.success('Application registered');
          this.devAppsCatalogStore.add(response.data[0]);
          this.appsStore.add(this.appsTransformerService.transformIDevAppToIApp(response.data));
          this.allAppsCatalogStore.add(
            this.appsTransformerService.transformIDevAppToIApp(response.data),
          );
          this.router.navigate(['my-apps', 'page', response.data[0].latestAppVersion.id]);
        }
      }),
    );
  }

  submitApp(appId: string, appVersion: IAppVersion): void {
    this.devAppCatalogDataService.submitApp(appId, appVersion).subscribe(
      (response: any) => {
        if (response) {
          this.notifierService.success('Application submitted');
          this.devAppsCatalogStore.update(appId, response.data[0]);
          this.updateSubject.next();
          // this.appsStore.update(appId, response.data[0]);
          // this.allAppsCatalogStore.update(appId, response.data[0]);
        }
      },
      error => {
        this.updateSubject.next(error);
        error.error.errors.forEach(err => {
          this.notifierService.error(err.message);
        });
      },
    );
  }

  updateApp(appId: string, appVersion: IAppVersion): void {
    this.devAppCatalogDataService.updateApp(appId, appVersion).subscribe(
      res => {
        this.notifierService.success('Application updated successfully');
        this.devAppsCatalogStore.remove(appId);
        this.devAppsCatalogStore.add(res.data[0]);
        this.updateSubject.next();
      },

      error => {
        error.error.errors.forEach(err => {
          this.notifierService.error(err.message);
        });
        this.updateSubject.next(error);
      },
    );
    // const currentApp = this.allAppsCatalogQuery.getEntity(appId);
    // const sabj = new Subject<any>();
    // this.devAppCatalogDataService.updateApp(appId, appVersion).subscribe(sabj);
    // sabj.subscribe(
    //   (response: any) => {
    //     if (response) {
    //       this.notifierService.success('Application data saved');
    //       this.devAppsCatalogStore.update(appId, response.data[0]);
    //       this.appsStore.update(appId, response.data[0]);
    //       this.allAppsCatalogStore.update(appId, response.data[0]);
    //       const versions: IVersionData[] = [];
    //       currentApp.appVersions.forEach(version => {
    //         if (version.id === appId) {
    //           versions.push({ id: version.id, version: response.data[0].version });
    //         } else {
    //           versions.push(version);
    //         }
    //       });
    //       this.allAppsCatalogStore.update(appId, { appVersions: versions });
    //     }
    //   },
    //   error => {
    //     error.error.errors.forEach(err => {
    //       this.notifierService.error(err.message);
    //     });
    //   },
    // );
    // return sabj;
  }

  getAppPackage(appId: string, type: FileType): void {
    this.devAppCatalogDataService.getAppPackage(appId, type).subscribe((response: any) => {
      if (response) {
      }
    });
  }

  uploadAppPackage(appId: string, files: File[], type: FileType): void {
    this.devAppCatalogDataService.uploadAppPackage(appId, files, type).subscribe(
      (response: any) => {
        if (response) {
          this.notifierService.success('File uploaded');
          this.devAppsCatalogStore.update(
            appId,
            this.appsTransformerService.transformFiles(appId, response.data),
          );
          this.appsStore.update(
            appId,
            this.appsTransformerService.transformFiles(appId, response.data),
          );
          this.allAppsCatalogStore.update(
            appId,
            this.appsTransformerService.transformFiles(appId, response.data),
          );
        }
      },
      error => {
        error.error.errors.forEach(err => {
          this.notifierService.error(err.message);
        });
      },
    );
  }

  deleteFile(appVersionId: string, fileType: string, fileNumber: number): void {
    this.devAppCatalogDataService.deleteFile(appVersionId, fileType, fileNumber).subscribe(
      response => {
        if (response.status && response.status === 204) {
          this.notifierService.success('File deleted');
          let fileField: string;
          switch (fileType) {
            case FileType.APK: {
              fileField = 'apks';
              break;
            }
            case FileType.QSA_REPORT: {
              fileField = 'qsaReports';
              break;
            }
            case FileType.USER_GUIDE: {
              fileField = 'userGuides';
              break;
            }
            case FileType.APP_ICON: {
              fileField = 'appIcons';
              break;
            }
            case FileType.SCREENSHOT: {
              fileField = 'screenshots';
              break;
            }
            default: {
              break;
            }
          }

          const app: IApp = {
            id: appVersionId,
          };

          app[fileField] = [];

          this.devAppCatalogQuery.getEntity(appVersionId)[fileField].forEach(file => {
            app[fileField].push(file);
          });

          const index = app[fileField]
            .map(file => {
              return file.fileNumber;
            })
            .indexOf(fileNumber);

          if (index > -1) {
            app[fileField].splice(index, 1);
          }
          this.devAppsCatalogStore.update(appVersionId, app);
        }
      },
      error => {
        error.error.errors.forEach(err => {
          this.notifierService.error(err.message);
        });
      },
    );
  }

  addNewVersion(app: IApp, newVersion: INewVersion): void {
    this.devAppCatalogDataService.addNewVersion(app.app.id, newVersion).subscribe(
      (response: any) => {
        if (response) {
          this.notifierService.success('Application new version added');
          this.devAppsCatalogStore.update(app.id, response.data[0]);
          this.appsStore.update(app.id, response.data[0]);
          this.allAppsCatalogStore.update(app.id, { newVersionAllowed: false });
          this.allAppsCatalogStore.add(
            this.appsTransformerService.transformIDevAppToIApp(response.data),
          );

          this.router.navigate(['my-apps', 'page', response.data[0].id]);
        }
      },
      error => {
        error.error.errors.forEach(err => {
          this.notifierService.error(err.message);
        });
      },
    );
  }

  changeState(
    appVersionId: string,
    newState: AppStatus,
    message?: string,
    currentState?: AppStatus,
  ): void {
    this.changeStatusStateSubject.next(true);
    this.devAppCatalogDataService.changeState(appVersionId, newState, message).subscribe(
      () => {
        if (newState === AppStatus.WITHDRAWN && currentState === AppStatus.APPROVED) {
          this.notifierService.success(`Application withdraw request submitted`);
        } else {
          this.notifierService.success(`Application ${newState.toLowerCase().replace(/_/g, ' ')}`);
          this.appsStore.update(appVersionId, { id: appVersionId, status: newState });
        }
        if (newState === AppStatus.WITHDRAWN) {
          this.devAppsCatalogStore.update(appVersionId, {
            id: appVersionId,
            status: newState,
            newVersionAllowed: true,
          });
        } else {
          this.devAppsCatalogStore.update(appVersionId, {
            id: appVersionId,
            status: newState,
          });
        }
      },
      error => {
        error.error.errors.forEach(err => {
          this.notifierService.error(err.message);
        });
      },
      () => {
        this.changeStatusStateSubject.next(false);
      },
    );
  }

  getAppVersionsByAppId(appId: string): Observable<any> {
    return this.devAppCatalogDataService.getVersionsByAppId(appId);
  }

  getPendingApps(): Observable<any> {
    return this.devAppCatalogDataService.getPendingApps().pipe(map(({ data }) => data));
  }
  getPagination(): any {
    return this.devAppsCatalogStore.getValue().pagination;
  }
  reset(): void {
    this.resetPagination(this.devAppsCatalogStore);
  }
}
