import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorService } from '@portal/shared/ui/form/src/lib/error/error.service';
import { MultilingualValidators } from '@portal/shared/ui/form/src/lib/error/custom-error.validator';
import { IPasswordChange } from '@portal/entity-services/interfaces/src/lib/users/interfaces/password-change.interface';
import { ToastService } from '@portal/shared/ui/toast';
import { Router } from '@angular/router';
import { UserService } from '@portal/shared/user/src/lib/user.service';
import { UisAuthenticationErrorService } from '@portal/shared/ui/layouts/src/lib/uis-authentication/services/uis-authentication-error.service';
import { HeroService } from '@portal/shared/ui/layouts';
import { MenuRegion } from '@portal/shared/ui/layouts/src/lib/top-navbar/enum/menu-region.enum';
declare const $localize;

@Component({
  selector: 'portal-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit {
  @Input() config?: {
    showBacklink: boolean;
    navigateUrl: string;
  };

  isInvalid = ErrorService.isFieldInvalid;
  form: FormGroup;
  errorMessage: string;

  constructor(
    private fb: FormBuilder,
    customValidator: MultilingualValidators,
    private toastService: ToastService,
    private router: Router,
    public userService: UserService,
    public uisAuthenticationErrorService: UisAuthenticationErrorService,
    private heroService: HeroService,
  ) {
    this.form = this.fb.group(
      {
        currentPassword: ['', Validators.required],
        newPassword: ['', Validators.required],
        newPasswordRepeat: ['', Validators.required],
      },
      {
        validator: customValidator.passwordMatch('newPassword', 'newPasswordRepeat'),
      },
    );
  }
  ngOnInit(): void {
    this.heroService.showTitle($localize`Administration`, MenuRegion.Administration);
  }
  get currentPassword(): FormControl {
    return this.form.get('currentPassword') as FormControl;
  }

  get newPassword(): FormControl {
    return this.form.get('newPassword') as FormControl;
  }

  onSubmit(): void {
    const request: IPasswordChange = {
      data: {
        ...this.form.getRawValue(),
        emailUser: this.userService.email,
      },
    };
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.errorMessage = null;
      this.userService.changePassword(request).subscribe(
        () => {
          this.toastService.showToast($localize`Your password has been successfully changed.`);
          this.router.navigate([
            this.config?.navigateUrl || `/administration/users/${this.userService.userId}/edit`,
          ]);
        },
        ({ error: { errorResultCode } }) =>
          (this.errorMessage = this.uisAuthenticationErrorService.getErrorMessage(
            errorResultCode,
            true,
          )),
      );
    }
  }
}
