import { IApp, IAppVersion, IDevApp, IVfAppMarketApp } from '@portal/marketplace/api-interfaces';
import { IAppUpdate } from '@portal/marketplace/api-interfaces/src/lib/apps/IAppUpdate.interface';
import { SortVersionsUpdatesPipe } from '@portal/shared/pipes';

const sortVersionsUpdates = new SortVersionsUpdatesPipe();

export function extractAppIds(apps: IVfAppMarketApp[]): string[] {
  const ids: string[] = [];
  for (const app of apps) {
    ids.push(app.appVersionId);
  }
  return ids;
}

//  get apps from vfappmarket and appverions from app catalog and return array of IApp
export function transformIAppVersionAndIVfAppMarketAppToIApp(
  vfAppMarketApps: IVfAppMarketApp[],
  appVersions: IAppVersion[],
): IApp[] {
  const appsArray: IApp[] = [];
  for (const appMarketApp of vfAppMarketApps) {
    const appVersionApp = getAppVersion(appMarketApp.appVersionId, appVersions);
    if (appVersionApp) {
      const iApp: IApp = {
        app: appVersionApp.app,
        appId: appMarketApp.appId,
        acquirers: appMarketApp.acquirers || [],
        acquirerApp: appMarketApp.acquirerApp,
        id: appVersionApp.id,
        developerId: appVersionApp.developerId,
        version: appVersionApp.version,
        appName: appVersionApp.appName,
        status: appVersionApp.status,
        description: appVersionApp.description,
        numberOfDownloads: appVersionApp.numberOfDownloads,
        totalSales: appVersionApp.totalSales,
        revenue: appVersionApp.revenue,
        numberOfCurrentInstallations: appVersionApp.numberOfCurrentInstallations,
        shortDescription: appVersionApp.shortDescription,
        apks: appVersionApp.apks,
        screenshots: appVersionApp.screenshots,
        appIcons: appVersionApp.appIcons,
        qsaReports: appVersionApp.qsaReports,
        userGuides: appVersionApp.userGuides,
        privacyPolicy: appVersionApp.privacyPolicy,
        supportContactInfo: appVersionApp.supportContactInfo,
        pricingModel: appMarketApp.pricingModel,
        paymentModel: appVersionApp.paymentModel,
        minimumPrice: appVersionApp.minimumPrice,
        categories: appVersionApp.categories,
        modelFamilies: appVersionApp.modelFamilies,
        appSupportEmail: appVersionApp.appSupportEmail,
        appSupportWebsite: appVersionApp.appSupportWebsite,
        appSupportContactNumber: appVersionApp.appSupportContactNumber,
        appTermOfUse: appVersionApp.appTermOfUse,
        appmarketAppId: appMarketApp.appmarketAppId,
        price: appMarketApp.price,
        createdDate: appVersionApp.createdDate,
        updatedDate: appVersionApp.updatedDate,
        modifiedBy: appVersionApp.modifiedBy,
        releaseNotes: appVersionApp.releaseNotes,
        appVersions: appVersionApp.appVersions,
        testingInstructions: appVersionApp.testingInstructions,
        isFeatured: appMarketApp.isFeatured,
        isPublic: appMarketApp.isPublic,
        isPilot: appMarketApp.isPilot,
        isDefault: appMarketApp.isDefault,
        isAvailableForUpgrade: appMarketApp.isAvailableForUpgrade,
        hasAssignments: appMarketApp.hasAssignments,
        isDefaultApp: appVersionApp.isDefaultApp,
        appcatalogIsDefaultApp: appMarketApp.appcatalogIsDefaultApp,
        acquirerIsDefault: appMarketApp.acquirerIsDefault,
      };
      appsArray.push(iApp);
    } /*else {              //TODO: remove commented if nothing will broken
      const iApp: IApp = {
        app: {
          id: '',
          developerAppId: '',
          developerOrg: null,
          latestAppVersionId: ';'
        },
        id: '',
        developerId: '',
        version: '',
        appName: '',
        status: null,
        description: '',
        numberOfDownloads: 0,
        totalSales: 0,
        revenue: 0,
        numberOfCurrentInstallations: 0,
        shortDescription: '',
        apks: null,
        screenshots: null,
        appIcons: null,
        qsaReports: null,
        userGuides: null,
        privacyPolicy: '',
        supportContactInfo: '',
        pricingModel: null,
        paymentModel: null,
        minimumPrice: 0,
        categories: null,
        modelFamilies: null,
        appSupportEmail: '',
        appSupportWebsite: '',
        appSupportContactNumber: '',
        appTermOfUse: '',
        appmarketAppId: appMarketApp.appmarketAppId
      };
      appsArray.push(iApp);
    }*/
  }
  return appsArray;
}

export function transformIAppVersionToIApp(appVersionApp: IAppVersion): IApp[] {
  const appsArray: IApp[] = [];
  if (appVersionApp) {
    const iApp: IApp = {
      app: appVersionApp.app,
      acquirers: [],
      id: appVersionApp.id,
      developerId: appVersionApp.developerId,
      version: appVersionApp.version,
      appName: appVersionApp.appName,
      status: appVersionApp.status,
      description: appVersionApp.description,
      numberOfDownloads: appVersionApp.numberOfDownloads,
      totalSales: appVersionApp.totalSales,
      revenue: appVersionApp.revenue,
      numberOfCurrentInstallations: appVersionApp.numberOfCurrentInstallations,
      shortDescription: appVersionApp.shortDescription,
      apks: appVersionApp.apks,
      screenshots: appVersionApp.screenshots,
      appIcons: appVersionApp.appIcons,
      qsaReports: appVersionApp.qsaReports,
      userGuides: appVersionApp.userGuides,
      privacyPolicy: appVersionApp.privacyPolicy,
      supportContactInfo: appVersionApp.supportContactInfo,
      pricingModel: appVersionApp.pricingModel,
      paymentModel: appVersionApp.paymentModel,
      isDefaultApp: appVersionApp.isDefaultApp,
      minimumPrice: appVersionApp.minimumPrice,
      categories: appVersionApp.categories,
      modelFamilies: appVersionApp.modelFamilies,
      appSupportEmail: appVersionApp.appSupportEmail,
      appSupportWebsite: appVersionApp.appSupportWebsite,
      appSupportContactNumber: appVersionApp.appSupportContactNumber,
      appTermOfUse: appVersionApp.appTermOfUse,
      createdDate: appVersionApp.createdDate,
      updatedDate: appVersionApp.updatedDate,
      modifiedBy: appVersionApp.modifiedBy,
      releaseNotes: appVersionApp.releaseNotes,
      appVersions: appVersionApp.appVersions,
    };
    appsArray.push(iApp);
  }
  return appsArray;
}

function getAppVersion(appVersionId, appVersions: IAppVersion[]): IAppVersion {
  for (const appVersion of appVersions) {
    if (appVersion.id === appVersionId) {
      return appVersion;
    }
  }
}

export function transformAppCatalogAppToIApp(apps: IDevApp[]): IApp[] {
  const appsArray: IApp[] = [];
  for (const app of apps) {
    const appVersionApp = app.latestAppVersion;
    const iApp: IApp = {
      app: {
        id: app.id,
        developerAppId: app.developerAppId,
        developerOrg: app.developerOrg,
        latestAppVersionId: app.latestAppVersionId,
      },
      id: appVersionApp.id,
      version: appVersionApp.version,
      appName: appVersionApp.appName,
      status: appVersionApp.status,
      description: appVersionApp.description,
      numberOfDownloads: appVersionApp.numberOfDownloads,
      totalSales: appVersionApp.totalSales,
      revenue: appVersionApp.revenue,
      numberOfCurrentInstallations: appVersionApp.numberOfCurrentInstallations,
      shortDescription: appVersionApp.shortDescription,
      apks: appVersionApp.apks,
      screenshots: appVersionApp.screenshots,
      appIcons: appVersionApp.appIcons,
      qsaReports: appVersionApp.qsaReports,
      userGuides: appVersionApp.userGuides,
      privacyPolicy: appVersionApp.privacyPolicy,
      supportContactInfo: appVersionApp.supportContactInfo,
      pricingModel: appVersionApp.pricingModel,
      paymentModel: appVersionApp.paymentModel,
      isDefaultApp: appVersionApp.isDefaultApp,
      minimumPrice: appVersionApp.minimumPrice,
      categories: appVersionApp.categories,
      modelFamilies: appVersionApp.modelFamilies,
      appSupportEmail: appVersionApp.appSupportEmail,
      appSupportWebsite: appVersionApp.appSupportWebsite,
      appSupportContactNumber: appVersionApp.appSupportContactNumber,
      appTermOfUse: appVersionApp.appTermOfUse,
      createdDate: appVersionApp.createdDate,
      updatedDate: appVersionApp.updatedDate,
      modifiedBy: appVersionApp.modifiedBy,
      releaseNotes: appVersionApp.releaseNotes,
      appVersions: app.appVersions,
    };
    appsArray.push(iApp);
  }
  return appsArray;
}

export function transformAppUpdatesToIApp(updates: IAppUpdate[]): IApp[] {
  const appsArray: IApp[] = [];
  for (const app of updates) {
    if (app.versions && app.versions.length > 0) {
      for (const version of app.versions) {
        const iApp: IApp = {
          appmarketAppId: version.appmarketAppId,
          versionsToUpdate: sortVersionsUpdates.transform(app.versions),
        };
        appsArray.push(iApp);
      }
    }
  }
  return appsArray;
}
