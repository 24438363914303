<label class="checkbox">
  <input
    type="checkbox"
    [value]="id"
    (click)="onCheckboxChecked(id)"
    [checked]="isEntitySelected(id)"
    [disabled]="disabled"
    data-e2e="input-search-checkbox"
    tabindex="0"
  />
  <span
    *ngIf="searchedText"
    innerHTML="{{ text | removeUnderscore | highlight : searchedText }}"
  ></span>
  <span *ngIf="!searchedText"> {{ text | removeUnderscore }} </span>
</label>
