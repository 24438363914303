<ng-container *ngIf="valueToCopy">
  <button
    *ngIf="buttonConfig; else iconTemplate"
    [ngClass]="buttonConfig.class"
    attr.data-e2e="{{ dataE2eId }}"
    title="{{ buttonConfig.text }}"
    type="button"
    #focusButton
  >
    <span *ngIf="buttonConfig.iconClass" class="icon"
      ><i [class]="buttonConfig.iconClass"></i
    ></span>
    <span>{{ buttonConfig.text }}</span>
  </button>
  <ng-template #iconTemplate>
    <button
      class="button icon is-transparent"
      attr.data-e2e="icon-copy-{{ dataE2eId }}"
      type="button"
      title="Copy"
      i18n-title
    >
      <i class="far fa-clone" aria-hidden="true"></i>
    </button>
  </ng-template>
</ng-container>
