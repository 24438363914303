import { Injectable } from '@angular/core';
import {
  Application,
  APPLICATION_PATH,
  AppPermission,
  AuthorizationService,
} from '@portal/shared/auth/authorization';
import { UserService } from '@portal/shared/user/src/lib/user.service';
import { LanguageHandle } from '@portal/shared/languages';
import { IOrganisation, IUser, UserRole } from '@portal/entity-services/interfaces';
import { EnvironmentVariableService } from '@portal/shared/helpers';
import { RedirectToMarketplaceService } from '@marketplace/services/src/lib/redirect-to-marketplace.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONSTANTS } from '@apps/portal/src/app/core/core.module';
import { ISearchPayload } from '@apps/portal/src/app/shared/interfaces';
import { CustomDeviceDataService } from '@apps/vhq/src/app/core/services/device-data.service';
import { ICount } from '@portal/shared/vui-http';
import { QueryParams } from '@ngrx/data';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private userService: UserService,
    private authorizationService: AuthorizationService,
    private languageService: LanguageHandle,
    private redirectToMarketplaceService: RedirectToMarketplaceService,
    private http: HttpClient,
    public customDeviceDataService: CustomDeviceDataService,
  ) {}

  getTransactionCount(queryParams: ISearchPayload): Observable<{ queryResult: string }> {
    return this.http.post<{ queryResult: string }>(
      `${CONSTANTS.DATA_SERVICE.TRANSACTIONS}/count`,
      queryParams,
    );
  }

  redirectToDefaultApplication(user: IUser, appsPermissions: AppPermission[]): void {
    const defaultApp = this.getDefaultApp(appsPermissions);

    if (this.userService.defaultApplication === null) {
      this.userService.setDefaultApplication(defaultApp);
    }
    if (defaultApp === Application.Marketplace) {
      this.redirectToMarketplaceService.redirectToMarketplace();
      return;
    }

    const languagePath = this.languageService.getSupportedLanguagePath(user?.language, defaultApp);
    if (!languagePath) {
      window.location.href = `${window.location.origin}/${APPLICATION_PATH[defaultApp]}`;
    } else {
      window.location.href = `${window.location.origin}/${APPLICATION_PATH[defaultApp]}/${languagePath}`;
    }
  }

  getDefaultApp(appsPermissions: AppPermission[]): Application {
    if (EnvironmentVariableService.getVar('CBA')) {
      return Application.Marketplace;
    }
    if (this.authorizationService.isApplicationUserForAllAssignedRoles(Application.Petro)) {
      return Application.Petro;
    }
    if (
      this.hasMOPExclusiveRole() &&
      appsPermissions.some((appPermission) => appPermission.application === Application.MOP)
    ) {
      return Application.MOP;
    }
    if (
      appsPermissions.some(
        (appPermission) => appPermission.application === Application.PaymentsPortal,
      )
    ) {
      return Application.PaymentsPortal;
    }
    const firstApp = appsPermissions[0].application;
    return Object.values(Application).includes(firstApp) ? firstApp : Application.PaymentsPortal;
  }

  fetchEstateOwnerDetails(estateOwnerUrl: string): Observable<any> {
    return this.http.get(estateOwnerUrl);
  }

  fetchTotalActiveDeviceCount(
    url: string,
    customerID: string,
    categoryType: string,
    dataInterval: number,
  ): Observable<any> {
    const req = {
      getWidgetForCommonPortalReq: {
        Category: categoryType,
        Days: dataInterval,
      },
    };
    return this.http.post(`${url}/GetWidgetForCommonPortal`, req, this.getVHQHeaders(customerID));
  }

  fetchEntityCountForCustomer(): Observable<ICount> {
    return this.customDeviceDataService.getTotalCount({
      labels: 'VHQ_ETYPE_EO',
    });
  }
  getWithQuery(params: string | QueryParams): Observable<IOrganisation[]> {
    return this.customDeviceDataService.getWithQuery(params).pipe(
      map((orgs) => this.filterorgs(orgs)),
      map((orgs) => orgs.map((org) => this.formatOrg(org))),
    );
  }

  private filterorgs(orgs: IOrganisation[]): IOrganisation[] {
    const filteredOrgs: IOrganisation[] = [];

    orgs.filter(function (item: IOrganisation): any {
      if (
        item.url != null &&
        item?.businessIdentifiers?.some((x) => x.identifierType === 'VHQ_CUSTOMER_ID')
      ) {
        filteredOrgs.push(item);
      }
    });
    return filteredOrgs;
  }

  private formatOrg(org: IOrganisation): IOrganisation {
    return org;
  }
  private hasMOPExclusiveRole(): boolean {
    const exclusiveMOPRoles = this.authorizationService
      .getRoles()
      .filter((role) =>
        [
          UserRole.ResellerManager,
          UserRole.ResellerRepresentative,
          UserRole.MerchantUnapproved,
          UserRole.MerchantOrderAdmin,
        ].includes(role as UserRole),
      );
    return this.authorizationService.isApplicationUser(Application.MOP, exclusiveMOPRoles);
  }

  private getVHQHeaders(customerID: string): Object {
    const idToken = JSON.parse(localStorage.getItem('id_token'));
    return {
      headers: new HttpHeaders({
        CustomerId: customerID,
        CustomerName: customerID,
        IDP: 'FORGEROCK',
        'id-token': idToken,
        'Access-Control-Allow-Origin': '*',
      }),
    };
  }
}
