<div class="navbar-common">
  <nav
    class="navbar"
    style="position: sticky; top: 0"
    role="navigation"
    aria-label="main navigation"
    #navbar
  >
    <div class="navbar-container">
      <div class="navbar-brand" #navbarBrand>
        <a
          [attr.href]="isLinksInteractive ? (appSwitcherUrl$ | async) : null"
          class="navbar-item mr-4"
          *ngIf="hasAppSwitcher$ | async"
          title="Logo"
          i18n-title
        >
          <i class="fa-sharp fa-solid fa-grid"></i>
        </a>
        <a class="navbar-item" [attr.href]="isLinksInteractive ? '' : null">
          <div class="logo" [class.has-logo-black]="showNewLogo && isLogoUploaded">
            <img [src]="logoSrc" alt="Logo"/>
          </div>
        </a>
        <a
          role="button"
          class="navbar-burger burger"
          [class.is-active]="showMobileDropDown"
          [class.is-hidden]="allNavbarItems?.length === 0"
          aria-label="menu"
          aria-expanded="false"
          (click)="toggleMobileDropdown()"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div class="navbar-menu" #navbarMenu [class.is-active]="showMobileDropDown">
        <div class="navbar-start">
          <div
            *ngFor="let navbarItem of filteredNavbarItems"
            [attr.id]="navbarItem.id"
            class="navbar-menu-item"
            #navbarMenuItem
          >
            <ng-container *ngIf="!navbarItem.children; else navbarDropDown">
              <a
                [routerLink]="navbarItem.routerLink"
                [helpersAttributes]="navbarItem.attributes"
                [restrict-permission]="navbarItem.restrictPermissions || ['read', 'self-read']"
                class="navbar-item"
                tabindex="0"
              >
                <span class="navbar-item-inner">{{ navbarItem.title }}</span>
              </a>
            </ng-container>
            <ng-template #navbarDropDown>
              <vui-navbar-dropdown [navbarItems]="navbarItem.children">
                <a class="navbar-link is-arrowless" [helpersAttributes]="navbarItem.attributes">
                  <span class="navbar-item-inner">
                    {{ navbarItem.title }}
                    <span class="arrow">
                      <i class="fas fa-chevron-down" aria-hidden="true"></i>
                    </span>
                  </span>
                </a>
              </vui-navbar-dropdown>
            </ng-template>
          </div>
          <div class="navbar-menu-item" [style.maxWidth.px]="moreDropDownMaxWidth" #navbarMenuItem>
            <ng-container *ngIf="moreDropDownItems.length">
              <vui-navbar-dropdown [navbarItems]="moreDropDownItems" [isRight]="true">
                <a class="navbar-link">
                  <span class="navbar-item-inner">
                    <span
                      i18n="
                        This group (dropdown) of the main menu contains other menu items that did
                        not fit on the screen
                      "
                      >More</span
                    >
                    <span class="arrow">
                      <i class="fas fa-chevron-down" aria-hidden="true"></i>
                    </span>
                  </span>
                </a>
              </vui-navbar-dropdown>
            </ng-container>
          </div>
        </div>
        <div class="navbar-end">
          <div class="navbar-menu-item" [attr.id]="navbarUserItem?.id" #navbarMenuItem>
            <vui-navbar-dropdown
              *ngIf="navbarUserItem && !moreDropDownItems.length"
              [navbarItems]="navbarUserItem.children"
              [isRight]="true"
              classList="has-circle"
            >
              <a class="navbar-link is-arrowless" [helpersAttributes]="navbarUserItem.attributes">
                <div class="circle">{{ navbarUserItem.title | firstLetters : 2 }}</div>
                <span class="arrow">
                  <i class="fas fa-chevron-down" aria-hidden="true"></i>
                </span>
              </a>
            </vui-navbar-dropdown>
          </div>
          <ng-content select="[navbar-menu-item-end]"></ng-content>
        </div>
        <div *ngIf="showMobileDropDown" class="navbar-mobile">
          <div class="navbar-item has-dropdown is-active">
            <vui-expansion-menu
              class="navbar-dropdown"
              [navbarItems]="allNavbarItems"
              (navigationStarted)="toggleMobileDropdown()"
            ></vui-expansion-menu>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
