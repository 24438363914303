import { Component } from '@angular/core';
import { ToastNotifier } from '../toast-notifier';
import { ToastService } from '../toast.service';

@Component({
  selector: 'portal-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  get src(): ToastNotifier {
    return this.toastService.notifier;
  }

  constructor(public toastService: ToastService) {}
}
