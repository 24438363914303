import { Injectable } from '@angular/core';
import { RestrictPermissionService } from '@portal/shared/auth/authorization/src/lib/services/restrict-permission.service';
import { INavbarItem } from '../interfaces/navbar-item.interface';

@Injectable({
  providedIn: 'root',
})
export class TopNavbarService {
  constructor(private restrictPermissionService: RestrictPermissionService) {}

  filterByPermissions(navbarItems: INavbarItem[]): INavbarItem[] {
    return navbarItems
      .map((item) =>
        item.children ? { ...item, children: this.filterByPermissions(item.children) } : item,
      )
      .filter((item) => this.canShowItem(item));
  }

  canShowItem(navbarItem: INavbarItem): boolean {
    if (navbarItem.routerLink) {
      return this.restrictPermissionService.checkPermissions(
        this.restrictPermissionService.getPermissionsForUrl(navbarItem.routerLink),
        ['read', 'self-read'],
      );
    }
    if (navbarItem.children) return navbarItem.children.some((item) => this.canShowItem(item));
    return true;
  }
}
