import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { CoockiesService } from './coockies/coockies.service';
import { CountryService } from './country/country.service';
import { CurrencyService } from './currency/currency.service';
import { FileLoader } from './file-loader/file-loader';

export const helpersRoutes: Route[] = [];

@NgModule({
  imports: [CommonModule, RouterModule],
  providers: [CoockiesService, CountryService, CurrencyService, FileLoader],
})
export class HelpersModule {}
