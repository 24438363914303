import { Injectable } from '@angular/core';
import { QueryParams } from '@ngrx/data';
import { IUser } from '@portal/entity-services/interfaces/src/lib/users/interfaces/user.interface';
import { CustomUserDataService } from '@portal/entity-services/users/src/lib/services/user-data.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class UserFilterService {
  loading$: Observable<boolean>;

  private loadingSubject = new BehaviorSubject(false);

  constructor(public userDataService: CustomUserDataService) {
    this.loading$ = this.loadingSubject.asObservable();
  }

  getEntityUsers(queryParams: QueryParams): Observable<IUser[]> {
    this.setLoading(true);
    return this.userDataService
      .getWithQuery(queryParams)
      .pipe(finalize(() => this.setLoading(false)));
  }

  private setLoading(isLoading: boolean): void {
    this.loadingSubject.next(isLoading);
  }
}
