import { IBankAccount } from '@apps/portal/src/app/core/core.module';
import { InputOverride } from './input-override.interface';
import { ICurrency } from '@portal/shared/helpers';
import { EntityStatus } from '../../organisations/enums/entity-status.enum';
import { IOrganisation } from '../../organisations/interfaces/organisation.interface';
import { PaymentType } from '../enums/payment-type.enum';
import { ProcessorType } from '../enums/processor-type.enum';
import { SalesChannel } from '../enums/sales-channel.enum';
import { IAccountDetails } from './account-details.interface';
import { IFee } from './fee.interface';
import { IProcessorConfig } from './processor-config.interface';
import { ISettlementInformation } from './settlement-information.interface';
import { IProcessorParameters } from './processor-parameters.interface';

export interface IPaymentContract {
  // API fields
  contractUid: string;
  name: string;
  mcc: string;
  startDate: Date | string;
  endDate?: Date | string;
  merchantId?: string;
  currency: ICurrency['code'][];
  paymentType: PaymentType[];
  processor?: {
    type?: ProcessorType;
    formattedType?: string;
    config?: IProcessorConfig;
    parameters?: IProcessorParameters;
    accountDetails?: IAccountDetails;
    acquirer?: string;
    cutoverTime?: Date | string;
    cutoverTimeZone?: string;
    cutoverTimes?: {
      cutoverTime: string;
      cutoverTimeZone: string;
    }[];
  };
  /**
   * @deprecated will be removed after
   * all components are migrated to salesChannels
   */
  salesChannel: SalesChannel;
  salesChannels: SalesChannel[];
  status: EntityStatus;
  createdDate?: string;
  modifiedDate?: string;
  version?: number;
  entityUid?: string;
  entity?: IOrganisation;
  objectType?: string;
  tidList?: string[];
  //relationship between a Contract and a POI
  altPoiId?: string;
  additionalParameters?: {
    beaconId?: string;
  };
  fees?: IFee[];
  pricelistUid?: string;
  //relationship between a Contract and an Account
  bankAccount?: IBankAccount;

  // Derived fields
  formattedStartDate?: string;
  formattedEndDate?: string;
  duration?: string;
  settlement?: ISettlementInformation;
  formattedSettlementType?: string;
  formattedSalesChannel?: string;
  formattedPaymentType?: string[];
  formattedCurrency?: string[];
  inputOverrides?: InputOverride[];
  contractInputOverrides?: InputOverride[];
  externalId?: string;
  contractTransactionProcessingSettings?: {
    unmatchedRefundAllowed: boolean;
    networkTokenSupported?: boolean;
  };
  contractIssuerInstalmentSettings?: {
    issuerInstalmentAllowed?: boolean;
    maximumNumberOfInstalments?: number;
  };
  contractStoredCredentialSettings?: {
    cardOnFilePaymentAllowed?: boolean;
  };
  contractExtendedStoredCredentialSettings?: {
    allowedLimitedToSignupProcessingModels?: string[];
  };
  formattedAllowedLimitedToSignupProcessingModels?: string;
}
