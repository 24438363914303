import { ActiveState } from '@datorama/akita';
import { EntityState } from '@ngrx/entity';

export interface IStorePagination<T> extends EntityState<T>, ActiveState {
  pagination: {
    pages: Map<number, Array<string>>;
    size: number;
  };
  metadata: {
    count?: number;
    limit?: number;
    offset?: number;
    sort?: string;
  };
}
export const paginationInitialState = {
  pagination: { pages: new Map<number, Array<string>>(), size: 0 },
  metadata: {
    count: 0,
    limit: 0,
    offset: 0,
    sort: '',
  },
};
