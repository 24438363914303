<vui-top-navbar
  [logoSrc]="logoSrc"
  [navbarItems]="navbarItems"
  [navbarUserItem]="navbarUserItems"
  [isLinksInteractive]="false"
></vui-top-navbar>

<div class="wl-preview-content section is-small" tabindex="-1">
  <div class="container">
    <portal-white-labeling-preview-content></portal-white-labeling-preview-content>
  </div>
</div>
