import { Injectable } from '@angular/core';
import { ISelectInput } from '@portal/shared/ui/filter';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ReportTemplateField } from '../enums/report-template-field.enum';

@Injectable({
  providedIn: 'root',
})
export class ReportTemplateFieldTypes extends SelectInputsList {
  keyValue = {
    [ReportTemplateField.OrganisationName]: $localize`Organisation name`,
    [ReportTemplateField.IssuerInstalmentAmount]: $localize`Issuer instalment amount`,
    [ReportTemplateField.OrganisationID]: $localize`Organisation ID`,
    [ReportTemplateField.IssuerInstalmentsNumber]: $localize`Issuer instalments number`,
    [ReportTemplateField.ProcessorCardBrand]: $localize`Processor card brand`,
    [ReportTemplateField.CreationDateTime]: $localize`Creation date and time`,
    [ReportTemplateField.ProcessorAcquirerName]: $localize`Processor acquirer name`,
    [ReportTemplateField.ProcessorIssuerName]: $localize`Processor issuer name`,
    [ReportTemplateField.CreationTimezone]: $localize`Creation timezone`,
    [ReportTemplateField.Technology]: $localize`Technology`,
    [ReportTemplateField.CreationUTC]: $localize`Creation UTC`,
    [ReportTemplateField.MerchantCountry]: $localize`Merchant country`,
    [ReportTemplateField.TransactionID]: $localize`Transaction ID`,
    [ReportTemplateField.CardIssuerCountry]: $localize`Fee configuration Card issuer country`,
    [ReportTemplateField.TransactionReference]: $localize`Transaction reference`,
    [ReportTemplateField.FallbackTransaction]: $localize`Fallback transaction`,
    [ReportTemplateField.Product]: $localize`Product`,
    [ReportTemplateField.PaymentMode]: $localize`Payment mode`,
    [ReportTemplateField.OriginalAmount]: $localize`Original amount`,
    [ReportTemplateField.PaymentProcessingFee]: $localize`Payment processing fee`,
    [ReportTemplateField.OriginalAmountCurrency]: $localize`Original amount currency`,
    [ReportTemplateField.FeeCurrency]: $localize`Fee currency`,
    [ReportTemplateField.CurrentAmount]: $localize`Current amount.`,
    [ReportTemplateField.UserAgent]: $localize`User agent`,
    [ReportTemplateField.CurrentAmountCurrency]: $localize`Current amount currency`,
    [ReportTemplateField.PaymentProductType]: $localize`Payment product type`,
    [ReportTemplateField.TransactionType]: $localize`Transaction type`,
    [ReportTemplateField.TransactionState]: $localize`Transaction state`,
    [ReportTemplateField.PaymentChannel]: $localize`Payment channel`,
    [ReportTemplateField.AddressVerificationServicesResult]: $localize`Address verification services result`,
    [ReportTemplateField.AcquirerReferenceNumber]: $localize`Acquirer reference number (ARN)`,
    [ReportTemplateField.MaskedCardNumber]: $localize`Masked card number`,
    [ReportTemplateField.ReceiverRegistrationNumber]: $localize`Receiver registration no. (RRN)`,
    [ReportTemplateField.DeviceSerialNumber]: $localize`Device serial number`,
    [ReportTemplateField.STAN]: $localize`STAN`,
    [ReportTemplateField.AcquirerResponseMessage]: $localize`Acquirer response message`,
    [ReportTemplateField.TransactionDescription]: $localize`Transaction description`,
    [ReportTemplateField.ProcessorReceiverRegistrationNumber]: $localize`Processor Receiver registration no. (RRN)`,
    [ReportTemplateField.SettlementDate]: $localize`Settlement date`,
    [ReportTemplateField.DeferredMonths]: $localize`Deferred months`,
    [ReportTemplateField.SettlementID]: $localize`Settlement ID`,
    [ReportTemplateField.PaymentMethod]: $localize`Payment method`,
    [ReportTemplateField.BatchID]: $localize`Batch ID`,
    [ReportTemplateField.IssuerInstalmentPaymentIndex]: $localize`Issuer instalment payment index`,
    [ReportTemplateField.TerminalID]: $localize`Terminal ID`,
    [ReportTemplateField.ProcessortransactionID]: $localize`Processor transaction ID`,
    [ReportTemplateField.CustomerID]: $localize`Customer ID`,
    [ReportTemplateField.IssuerDueDate]: $localize`Issuer due date`,
    [ReportTemplateField.CardBIN]: $localize`Card BIN`,
    [ReportTemplateField.CustomerAppPhoneNumber]: $localize`Customer app phone number`,
    [ReportTemplateField.CardLastFourDigits]: $localize`Card - last four digits`,
    [ReportTemplateField.TransactionEntryMode]: $localize`Transaction entry mode`,
    [ReportTemplateField.CardDetailsIssuerCountry]: $localize`Card issuer country`,
    [ReportTemplateField.SalesDescription]: $localize`Sales description`,
    [ReportTemplateField.CustomerBillingCity]: $localize`Customer billing city`,
    [ReportTemplateField.CashierID]: $localize`Cashier ID`,
    [ReportTemplateField.CustomerBillingCountry]: $localize`Customer billing country`,
    [ReportTemplateField.AccountType]: $localize`Account type`,
    [ReportTemplateField.CustomerBillingFirstName]: $localize`Customer billing first name`,
    [ReportTemplateField.AcquirerAccountType]: $localize`Acquirer account type`,
    [ReportTemplateField.CustomerEmailAddress]: $localize`Customer email address`,
    [ReportTemplateField.PaymentProviderContractType]: $localize`Payment Provider Contract type`,
    [ReportTemplateField.ShippingAddress]: $localize`Shipping address`,
    [ReportTemplateField.PaymentDocumentProvider]: $localize`Payment document provider`,
    [ReportTemplateField.ShippingCity]: $localize`Shipping city`,
    [ReportTemplateField.PaymentDocumentType]: $localize`Payment document type`,
    [ReportTemplateField.ShippingCountry]: $localize`Shipping country`,
    [ReportTemplateField.PaymentDocumentID]: $localize`Payment document ID`,
    [ReportTemplateField.ShippingEmail]: $localize`Shipping email`,
    [ReportTemplateField.ThirdPartyTransactionReference]: $localize`3rd party transaction reference`,
    [ReportTemplateField.ShippingCustomerFirstName]: $localize`Shipping customer first name`,
    [ReportTemplateField.CashierName]: $localize`Cashier name`,
    [ReportTemplateField.ShippingPhone]: $localize`Shipping phone`,
    [ReportTemplateField.AcquirerMerchantID]: $localize`Acquirer merchant ID`,
    [ReportTemplateField.ShippingPostalCode]: $localize`Shipping postal code`,
    [ReportTemplateField.MerchantReference]: $localize`Merchant reference`,
    [ReportTemplateField.MerchantId]: $localize`Merchant ID`,
    [ReportTemplateField.ShippingState]: $localize`Shipping state`,
    [ReportTemplateField.TokenType]: $localize`Token type`,
    [ReportTemplateField.EstateManagementID]: $localize`Estate management ID`,
    [ReportTemplateField.StoredCredentialsType]: $localize`Stored credentials type`,
    [ReportTemplateField.SiteReferenceID]: $localize`Site reference ID`,
    [ReportTemplateField.TaxIndicator]: $localize`Tax indicator`,
    [ReportTemplateField.AcquirerResponseCode]: $localize`Acquirer response code`,
    [ReportTemplateField.TaxAmount]: $localize`Tax amount`,
    [ReportTemplateField.AcquirerAuthorisationResult]: $localize`Acquirer authorisation result`,
    [ReportTemplateField.PurchaseOrdernumber]: $localize`PO number`,
    [ReportTemplateField.IssuerAuthorisationResult]: $localize`Issuer authorisation result`,
    [ReportTemplateField.AuthorisationResponse]: $localize`Authorisation response`,
    [ReportTemplateField.IDcheckResult]: $localize`ID check result`,
    [ReportTemplateField.ChequeAccountNumber]: $localize`Cheque account number`,
    [ReportTemplateField.ElectronicCommerceIndicator]: $localize`Electronic commerce indicator`,
    [ReportTemplateField.ChequeNumber]: $localize`Cheque number`,
    [ReportTemplateField.CardholderAuthVerification]: $localize`Cardholder auth. verification (CAVV)`,
    [ReportTemplateField.ResponseCode]: $localize`Response code`,
    [ReportTemplateField.CreditTerm]: $localize`Credit term`,
    [ReportTemplateField.FraudScore]: $localize`Fraud score`,
    [ReportTemplateField.IssuerInstalmentDownPaymentAmount]: $localize`Issuer instalment down payment amount`,
    [ReportTemplateField.FraudReason]: $localize`Fraud reason`,
    [ReportTemplateField.CashbackAmount]: $localize`Cashback amount`,
    [ReportTemplateField.GratuityAmount]: $localize`Gratuity amount`,
    [ReportTemplateField.CharitableDonation]: $localize`Charitable Donation`,
    [ReportTemplateField.VerifoneTerminalId]: $localize`Internal Terminal ID`,
    [ReportTemplateField.StoreIdentifier]: $localize`Store identifier`,
    [ReportTemplateField.PpcId]: $localize`PPC ID`,
    [ReportTemplateField.ParentTransactionId]: $localize`Parent transaction ID`,
    [ReportTemplateField.InvoiceNumber]: $localize`Invoice number`,
    [ReportTemplateField.CardExpiryMonth]: $localize`Card expiry month`,
    [ReportTemplateField.CardExpiryYear]: $localize`Card expiry year`,
    [ReportTemplateField.StoredCredentialReference]: $localize`Stored credential reference`,
    [ReportTemplateField.TransactionTimezone]: $localize`Transaction timezone`,
    [ReportTemplateField.CreationDate]: $localize`Creation date`,
    [ReportTemplateField.CreationTime]: $localize`Creation time`,
    [ReportTemplateField.FraudReasonCode]: $localize`Fraud reason code`,
    [ReportTemplateField.PoiName]: $localize`POI name`,
    [ReportTemplateField.SalesChannel]: $localize`Sales channel`,
    [ReportTemplateField.AcquirerSettlementID]: $localize`Acquirer Settlement ID`,
    [ReportTemplateField.CVVResult]: $localize`CVV Result`,
    [ReportTemplateField.TransactionOnlineFlag]: $localize`Transaction Online Flag`,
    [ReportTemplateField.SchemeIdentifier]: $localize`Scheme Identifier`,
    [ReportTemplateField.PaymentAccountReference]: $localize`Payment Account Reference`,
    [ReportTemplateField.MessageNo]: $localize`Message No`,
    [ReportTemplateField.MerchantPresent]: $localize`Merchant Present`,
    [ReportTemplateField.ConfirmDateTime]: $localize`Confirm Date Time`,
    [ReportTemplateField.CaptureStatus]: $localize`Capture Status`,
    [ReportTemplateField.AuthDateTime]: $localize`Auth Date Time`,
    [ReportTemplateField.TaxDiscount]: $localize`Tax discount`,
    [ReportTemplateField.Acquirer]: $localize`Acquirer`,
    [ReportTemplateField.PrmType]: $localize`PRM type`,
    [ReportTemplateField.AncestorName]: $localize`Ancestor name`,
    [ReportTemplateField.AuthenticationMode]: $localize`Authentication mode`,
    [ReportTemplateField.ThreeDsStatus]: $localize`3DS status`,
    [ReportTemplateField.ThreeDsVersion]: $localize`3DS version`,
    [ReportTemplateField.TerminalManufacturer]: $localize`Terminal manufacturer`,
    [ReportTemplateField.SimcardIdentifier]: $localize`Sim card identifier (ICCID)`,
    [ReportTemplateField.CrossBorderFee]: $localize`Cross border fee`,
    [ReportTemplateField.PlatformFee]: $localize`Platform fee`,
  };
  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
