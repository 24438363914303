import { Injectable } from '@angular/core';
import { PaymentType } from '@portal/entity-services/interfaces/src';
import { SelectInputKeysFilter, SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { Features } from 'environments/enums/features.enum';
import sortBy from 'lodash-es/sortBy';
import { Brand } from '../../../enums/brand.enum';
import { TransactionInstrumentType } from '../../../enums/transaction-instrument-type.enum';
import { TranslationsKeyValue } from '@portal/shared/helpers/src/lib/create-list-from-inputs/interfaces/translations-key-value.interface';
import { SupportedProcessorSettingsService } from '@apps/portal/src/app/services/supported-processor-settings.service';

@Injectable()
export class Products extends SelectInputsList {
  keyValue = this.selectInputKeysFilter.filterByFeature(
    {
      [Brand.Amex]: 'American Express',
      [Brand.Cb]: 'Carte Bancaire',
      [Brand.Diners]: 'Diners Club',
      [Brand.Discover]: 'Discover',
      [Brand.Jal]: 'JAL',
      [Brand.Jcb]: 'JCB',
      [Brand.Maestro]: 'Maestro',
      [Brand.Mastercard]: 'Mastercard',
      [Brand.Switch]: 'Switch',
      [Brand.Visa]: 'Visa',
      [Brand.Upi]: 'Upi',
      [Brand.Other]: $localize`Other`,
      [Brand.Debit]: $localize`Debit`,
      [Brand.FrenziCard]: 'FrenzCard',
      [Brand.CardLink]: 'Cardlink',
      [Brand.Farmlands]: 'Farmlands',
      [Brand.Farmland]: 'Farmland',
      [Brand.Qcard]: 'Q Card',
      [Brand.Ruralco]: 'Ruralco',
      [Brand.Rd1com]: 'RD1 Com',
      [Brand.SuperCard]: 'SuperCard',
      [Brand.SmartLoyalty]: 'SmartLoyalty',
      [Brand.Prowze]: 'Prowze',
      [Brand.FlexiPay]: 'Flexipay',
      [Brand.Ecardz]: 'Ecardz',
      [Brand.HellsPizza]: 'Hells Pizza',
      [Brand.SwipeCard]: 'Swipe Card',
      [Brand.GenieCard]: 'Genie Card',
      [Brand.TrueRewards]: 'True Rewards',
      [Brand.Activa]: 'Activa',
      [Brand.FarmersFinance]: 'Farmers Finance',
      [Brand.BarterCard]: 'BarterCard',
      [Brand.AaSmartfuel]: 'AA Smartfuel',
      [Brand.GiftCard]: 'Giftcard',
      [Brand.Ezipay]: 'EziPay',
      [Brand.Tranxactor]: 'Tranxactor',
      [Brand.WestfieldGiftcard]: 'Westfield Giftcard',
      [Brand.MtaGiftcard]: 'MTA Giftcard',
      [Brand.Ecard]: 'Ecard',
      [Brand.BpGiftCard]: 'BP Giftcard',
      [Brand.BpFuelCard]: 'BP Fuelcard',
      [Brand.FpfGift]: 'FPF Giftcard',
      [Brand.CrmLoyalty]: 'CRM Loyalty',
      [Brand.CrmPrepaid]: 'CRM Prepaid',
      [Brand.Easycd]: 'EasyCD',
      [Brand.TtCard]: 'TT Card',
      [Brand.Dankort]: 'Dankort',
      [Brand.Bankaxept]: 'BankAxept',
      [Brand.Ikano]: 'Ikano',
      [Brand.Resursbank]: 'Resurs Bank',
      [Brand.GavekortSenter]: 'Gavekort Senter',
      [Brand.GavekortKjede]: 'Gavekort Kjede',
      [Brand.Sentrumgavekort]: 'SentrumsGavekort',
      [Brand.Xponcard]: 'XponCard',
      [Brand.UniversalPresenkort]: 'Universal Persentkort',
      [Brand.NetsSentergavekort]: 'Nets Sentergravekort',
      [Brand.NetsKjedegavekort]: 'Nets Kjedegavekort',
      [Brand.IgiveGavekortK]: 'iGive Gavekort K',
      [Brand.IgiveGavekortS]: 'iGive Gavekort S',
      [Brand.IgiveKampanjeK]: 'iGive Kampanje K',
      [Brand.IgiveKampanjeS]: 'iGive Kampanje S',
      [Brand.IgiveFordel]: 'iGive Fordel',
      [Brand.PayexGavekort]: 'Payex Gavekort',
      [Brand.Gavekort1]: 'Gavekort 1',
      [Brand.NgGavekort]: 'Ng Gavekort',
      [Brand.EvrySenter]: 'Evry Senter',
      [Brand.EvryGavekort]: 'Evry Gavekort',
      [Brand.Kjedekort1]: 'Kjedekort 1',
      [Brand.GavekortSenter2]: 'GavekortSenter 2',
      [Brand.CashcomPresentkort]: 'Cashcom Presentkort',
      [Brand.PayexGavekortSe]: 'Payex Gavekort Se',
      [Brand.NetsMsGavekort35]: 'Nets Ms Gavekort 35',
      [Brand.NetsMsGavekort36]: 'Nets Ms Gavekort 36',
      [Brand.PayexGavekortDk]: 'Payex Gavekort Dk',
      [Brand.PbsKjedekort]: 'Pbs Kjedekort',
      [Brand.CenterkortDk]: 'Centerkort Dk',
      [Brand.Gavekort]: 'Gavekort',
      [Brand.Flexecash]: 'Flexecash',
      [Brand.PbsCenterkort]: 'PBS Centerkort',
      [Brand.PbsHandelsStandkort]: 'PBS Handelsstandkort',
      [Brand.OberthurGavekort]: 'Oberthur Gavekort',
      [Brand.RiksLunchen]: 'Rikslunchen',
      [Brand.RiksKortet]: 'Rikskortet',
      [Brand.SparXpres]: 'SparXpres',
      [Brand.Sepaelv]: 'Sepaelv',
      [Brand.Girocard]: 'Girocard',
      [Brand.IsraCard]: 'IsraCard',
      [Brand.IsraelPrivateLabel]: 'Israel Private Label',
      [Brand.Crypto]: 'Crypto',
      [Brand.Carnet]: 'Carnet',
      [Brand.BbbyCob]: 'COB',
      [Brand.BbbyPlcc]: 'PLCC (BBBY)',
      [Brand.Plcc]: 'PLCC',
      [Brand.Ebt]: 'EBT',
      [Brand.Forbrugsforeningen]: 'Forbrugsforeningen',
      [Brand.Landsbankinn]: 'Landsbankinn',
      [Brand.Wex]: 'WEX',
      [Brand.NetsKjedekort]: 'Nets Kjedekort',
      [Brand.NetsCenterkort]: 'Nets Centerkort',
      [Brand.NetsHandelsstandkor]: 'Nets Handelsstandkor',
      [Brand.Clave]: 'Clave',
      [Brand.Edenred]: 'EDENRED',
      [Brand.Voyager]: 'Voyager',
      [PaymentType.Paypal]: 'PayPal',
      [PaymentType.PaypalQR]: 'PayPal QR',
      [TransactionInstrumentType.Blik]: 'Blik',
      [TransactionInstrumentType.MobilePay]: 'MobilePay',
      [TransactionInstrumentType.Swish]: 'Swish',
      [TransactionInstrumentType.Vipps]: 'Vipps',
      [TransactionInstrumentType.VenmoQR]: 'Venmo QR',
      [TransactionInstrumentType.Cheque]: 'Check',
      [TransactionInstrumentType.Klarna]: 'Klarna',
      [TransactionInstrumentType.Alipay]: 'Alipay',
      [TransactionInstrumentType.Wechat]: 'Wechat',
      [TransactionInstrumentType.Affirm]: 'Affirm',
      [TransactionInstrumentType.OPOnlinePayment]: 'OP Online Payment',
    },
    {
      [Features.Crypto]: [Brand.Crypto],
    },
  );

  list: ISelectInput[];

  constructor(
    private selectInputKeysFilter: SelectInputKeysFilter,
    private supportedProcessorSettingsService: SupportedProcessorSettingsService,
  ) {
    super();
    this.getSortedList();
  }

  getSupportedProduct(): ISelectInput[] {
    const supportedProcessorSettings =
      this.supportedProcessorSettingsService.getSupportedProcessorSettingsFromSessionStorage();
    if (supportedProcessorSettings?.paymentTypesSupported?.length) {
      const supportedProducts: TranslationsKeyValue = {};
      supportedProcessorSettings.paymentTypesSupported.forEach((product) => {
        supportedProducts[product] = this.keyValue[product] || product;
      });
      this.getSortedList(supportedProducts);
    }
    return this.list;
  }

  getSortedList(processors = this.keyValue): void {
    this.list = sortBy(this.getSelectInputs(processors), (item: ISelectInput) =>
      item.text.toLowerCase(),
    );
  }
}
