import { INavbarItem } from '@portal/shared/ui/layouts/src/lib/top-navbar/interfaces/navbar-item.interface';
import { ApplicationRoot } from '../enums/application-root.enum';

export type NavbarItemLink = Pick<INavbarItem, 'href' | 'routerLink' | 'path'>;

export const getSubNavItems = (l1: INavbarItem, l2s: INavbarItem[]): INavbarItem | null => {
  const items = {
    ...l1,
    children: l2s.filter((item) => item?.children?.length),
  };
  return items.children.length ? items : null;
};

export const getNavbarItemLink = (
  currentApp: ApplicationRoot,
  applications: ApplicationRoot[],
  languageRoute: string,
  path: string,
): NavbarItemLink => {
  if (applications.includes(currentApp)) {
    return {
      routerLink: path,
      path,
    };
  } else {
    return {
      href: getFullUrlForApp(applications[0], languageRoute, path),
      path,
    };
  }
};

export const getFullUrlForApp = (
  application: ApplicationRoot,
  languagePath: string,
  path: string,
): string => {
  let url = window.location.origin + `/${application}`;
  if (languagePath) {
    url = url + '/' + languagePath;
  }
  url += path;
  return url;
};

export const combineNavItems = (items: INavbarItem[]): INavbarItem[] => {
  return items.filter((item) => item?.children?.length || item?.href || item?.routerLink);
};
