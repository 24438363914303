import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'portal-text-control',
  templateUrl: './text-control.component.html',
})
export class TextControlComponent {
  @Input() name: string;
  @Input() control: FormControl;
  @Input() invalid: boolean;
  @Input() optional: boolean;
  @Input() e2eName = 'textControl-input';
}
