import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';
import {
  ES_CONSTANTS as CONSTANTS,
  IPoiCutoverConfiguration,
} from '@portal/entity-services/interfaces';
import { IDevice, IPaymentContract, IPointInteraction } from '@portal/entity-services/interfaces';
import { CustomDataService } from '@portal/shared/vui-http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomPointInteractionDataService extends CustomDataService<IPointInteraction> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super(CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION, http, httpUrlGenerator);
  }

  getPaymentContracts(id: string): Observable<IPaymentContract[]> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error(`No "${this.entityName}" key to get`);
    }
    return this.execute(
      'GET',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION_PAYMENT_CONTRACT}${CONSTANTS.STATUS_OPTION.STATUS_BOTH}`,
      error,
    );
  }

  disableEnablePointInteraction(id: string, status: string): Observable<IPointInteraction> {
    const poiOrError = id ? { status: status } : new Error(`No "${this.entityName}" key to get`);
    return this.execute('PATCH', `${this.entityUrl}${id}`, poiOrError);
  }

  addPhysicalDevice(device: IDevice): Observable<IDevice> {
    const entityOrError = device.serialNumber ? device : new Error(`No serialNumber to add`);
    return this.execute(
      'POST',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION_DEVICES}`,
      entityOrError,
    );
  }

  updatePhysicalDevice(device: IDevice): Observable<IDevice> {
    const entityOrError = device.serialNumber
      ? device
      : new Error(`No "${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION_DEVICES}" to add`);
    return this.execute(
      'PUT',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION_DEVICES}/${device.serialNumber}`,
      entityOrError,
    );
  }

  getPhysicalDevice(serialNumber: string): Observable<IDevice> {
    let error: Error | undefined;
    if (serialNumber == null) {
      error = new Error(`No serialNumber key to get`);
    }
    return this.execute(
      'GET',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION_DEVICES}/${serialNumber}`,
      error,
    );
  }

  addPhysicalDevicePoi(device: IDevice, poiUid: string): Observable<IPointInteraction> {
    const entityOrError =
      poiUid && device.serialNumber
        ? device
        : new Error(`No "${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION_DEVICES}" to add`);
    return this.execute(
      'POST',
      `${this.entityUrl}${poiUid}/${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION_DEVICES}`,
      entityOrError,
    );
  }

  removePhysicalDevicePoi(poiUid: string): Observable<IPointInteraction> {
    let err: Error | undefined;
    if (!poiUid) {
      err = new Error(`No poiUid key to delete`);
    }

    return this.execute(
      'DELETE',
      `${this.entityUrl}${poiUid}/${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION_DEVICES}`,
      err,
    );
  }

  getPhysicalDevicePoi(poiUid: string): Observable<IDevice> {
    let error: Error | undefined;
    if (poiUid == null) {
      error = new Error(`No poiUid key to get`);
    }
    return this.execute(
      'GET',
      `${this.entityUrl}${poiUid}/${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION_DEVICES}`,
      error,
    );
  }

  getPoiBySerialNumber(serialNumber: string): Observable<IPointInteraction[]> {
    let error: Error | undefined;
    if (serialNumber == null) {
      error = new Error(`No serialNumber key to get`);
    }
    return this.execute(
      'GET',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION_DEVICES}/${serialNumber}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_POINT_INTERACTION}?populateEntity=true`,
      error,
    );
  }

  getPoiCutoverConfigurations(poiCutoverStrategy: string): Observable<IPoiCutoverConfiguration[]> {
    let query = '';
    if (poiCutoverStrategy !== '') {
      query = `poiCutoverStrategy=${poiCutoverStrategy}`;
    }

    return this.execute(
      'GET',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/poiCutoverConfigurations?${query}`,
    );
  }
}
