import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EntityStatus } from '@portal/entity-services/interfaces/src/lib/organisations/enums/entity-status.enum';
import { IOrganisation } from '@portal/entity-services/interfaces/src/lib/organisations/interfaces/organisation.interface';
import { RoleGuardService } from '@portal/shared/auth/authorization';
import { PaginationSortHandler } from '@portal/shared/helpers';
import { CompareService } from '@portal/shared/helpers/src/lib/compare/compare.service';
import { ISearchBoxValue, ISearchCriteria, SearchOperator } from '@portal/shared/ui/filter';
import {
  IQueryParams,
  IResultsWithCount,
  ISort,
  ITableConfig,
  PageSize,
  QueryParamsService,
  SortOrder,
} from '@portal/shared/ui/table';
import { cloneDeep } from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { OrganisationService } from '../../services/organisation.service';
import { EntityTypes } from '../form/entity-type.list';
import { FilterOrganisationsComponent } from '@portal/entity-services/organisations/src/lib/components/filter/filter.component';

declare const $localize;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'portal-list-organisations',
  templateUrl: './list.component.html',
})
export class ListOrganisationComponent
  extends PaginationSortHandler
  implements OnInit, AfterViewInit
{
  @ViewChild('createButton') createButton: ElementRef;
  @ViewChild(FilterOrganisationsComponent)
  filterOrganizationComponent: FilterOrganisationsComponent;
  loading$: Observable<boolean>;
  organisations$: Observable<IOrganisation[]>;
  organisations: IOrganisation[];
  range: string;
  filter: IQueryParams;
  totalCount: number;
  orgSubscription: Subscription;
  orgsWithCount$: Observable<IResultsWithCount<IOrganisation>>;
  canGoToDetail = false;
  selectedSearchSelector: Subject<string> = new Subject();
  searchTerm: Subject<string> = new Subject();

  initialFilter: IQueryParams = {
    start: 0,
    limit: PageSize.Size10,
    order: SortOrder.Asc,
    populateParentEntity: true,
    searchCriteria: new Map<string, ISearchCriteria>(),
    status: [EntityStatus.Active, EntityStatus.Inactive, EntityStatus.Deleted],
  };
  filterTouched = false;

  tableConfig: ITableConfig = {
    columns: [
      { key: 'name', label: $localize`Name`, isSortable: true },
      { key: 'country', label: $localize`Country` },
      { key: 'parentEntity.name', label: $localize`Parent organisation` },
      {
        key: 'entityType',
        label: $localize`Type`,
        isRenderFunction: true,
        renderFunction: (organisation: IOrganisation) =>
          this.entityTypeList.lookup(organisation?.entityType)?.text,
      },
      {
        key: 'status',
        label: $localize`Status`,
        isRenderFunction: true,
        renderFunction: (org: IOrganisation) => {
          return org.status === EntityStatus.Active
            ? $localize`Enabled`
            : org.status === EntityStatus.Inactive
            ? $localize`Disabled`
            : $localize`Deleted`;
        },
      },
    ],
    rowIdKey: 'entityUid',
    rowLabelKey: 'name',
  };

  constructor(
    private entityTypeList: EntityTypes,
    private organisationService: OrganisationService,
    private title: Title,
    private liveAnnouncer: LiveAnnouncer,
    private roleGuard: RoleGuardService,
    private route: ActivatedRoute,
    private router: Router,
    private compareService: CompareService,
  ) {
    super();
    this.loading$ = organisationService.loading$;
    this.organisationService.filter$.pipe(take(1)).subscribe((data: any) => {
      if (!data) {
        this.setInitialFilterState();
      } else {
        this.filter = data.filter;
        this.updateFilterTouchedFlag();
      }
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (!event.url.includes('/organisations')) {
          this.setInitialFilterState();
          this.organisationService.setFilter({ filter: this.filter });
        }
      });
  }

  ngOnInit(): void {
    this.title.setTitle($localize`Organisations`);
    this.liveAnnouncer.announce($localize`Navigated to ${this.title.getTitle()}`);
    this.getOrganisations();
    this.canGoToDetail = this.roleGuard.canAccess(this.route.snapshot, 'read');
  }
  ngAfterViewInit(): void {
    if (this.createButton && this.createButton.nativeElement.style.display !== 'none') {
      this.createButton.nativeElement.focus();
    } else if (this.filterOrganizationComponent) {
      this.filterOrganizationComponent.focusSearchBox();
    }
  }
  setInitialFilterState(): void {
    this.filter = cloneDeep(this.initialFilter);
    this.filterTouched = false;
  }

  getOrganisations(): void {
    if (this.orgSubscription) {
      this.orgSubscription.unsubscribe();
    }
    this.orgsWithCount$ = this.organisationService.getResultsWithCount(this.filter);
    this.orgSubscription = this.orgsWithCount$.subscribe(
      (result: IResultsWithCount<IOrganisation>) => {
        this.organisations = result.results;
        this.totalCount = result.count;
        this.range = QueryParamsService.getRange<IOrganisation>(this.filter, this.organisations);
      },
    );
  }

  afterPaginate(): void {
    this.getOrganisations();
  }

  afterSorting(): void {
    this.getOrganisations();
  }

  onSortChanged(sort: ISort): void {
    super.onSorting(sort.key, sort.order);
  }

  onOrgFiltered(orgFilter: ISearchBoxValue): void {
    this.resetPaginationForFilter();
    if (orgFilter) {
      this.filter.searchCriteria.set(orgFilter.key, {
        argument: orgFilter.value,
        selector: orgFilter.key,
        operator: SearchOperator.Equal,
      });
      this.updateFilterTouchedFlag();
    }

    this.filter.start = 0;
    this.filter.limit = PageSize.Size10;
    this.organisationService.setFilter({ filter: this.filter });
    this.getOrganisations();
  }

  onOrgFilterReset(): void {
    this.filter.searchCriteria.clear();
    this.setInitialFilterState();
    this.getOrganisations();
  }

  onOrgFiltersChanged(key: string): void {
    this.filter.searchCriteria.delete(key);
    this.onOrgFilterReset();
    this.resetPagination = true;
    this.updateFilterTouchedFlag();
  }

  updateFilterTouchedFlag(): void {
    this.filterTouched = !this.compareService.mapsEqual(
      this.initialFilter.searchCriteria,
      this.filter.searchCriteria,
    );
  }
}
