import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vui-user-deleted',
  templateUrl: './user-deleted.component.html',
  styleUrls: ['./user-deleted.component.scss'],
})
export class UserDeletedComponent {
  constructor(private router: Router) {}
  navigateUsers(): void {
    this.router.navigate(['/administration/users']);
  }

  navigateDashboard(): void {
    this.router.navigate(['/'], { state: { redirectToInitialPage: true } });
  }
}
