import { Injectable } from '@angular/core';
import { ES_CONSTANTS, TemplateParametersResponse } from '@portal/entity-services/interfaces';
import { CustomDataService } from '@portal/shared/vui-http';
import { Observable, of } from 'rxjs';
import { HttpUrlGenerator } from '@ngrx/data';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

const ErrorMessages = {
  SET: $localize`No domain name to add`,
  GET: $localize`No domain name to get`,
  DELETE: $localize`No domain name to delete`,
};

declare const $localize;
@Injectable({ providedIn: 'root' })
export class TemplateParametersDataService extends CustomDataService<TemplateParametersResponse> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super(ES_CONSTANTS.UIS.TEMPLATE_RESELLER, http, httpUrlGenerator);
  }

  getTemplateParameters(domainName: string): Observable<TemplateParametersResponse> {
    const error = domainName ? null : new Error(ErrorMessages.GET);
    return this.execute('GET', `${this.entityUrl}/${domainName}/templateParameters`, error);
  }

  setTemplateLogoImage(domainName: string, data: FormData): Observable<void> {
    const httpUrl = `${this.entityUrl}/${domainName}/templateLogo`;
    const isRequestValid = domainName && data;
    if (!isRequestValid) {
      console.error(
        new HttpErrorResponse({
          error: new Error(ErrorMessages.SET),
          url: httpUrl,
        }),
      );
      return of(null);
    }

    return this.execute('POST', httpUrl, data);
  }

  setTemplateFaviconImage(domainName: string, data: FormData): Observable<void> {
    const httpUrl = `${this.entityUrl}/${domainName}/favicon`;
    const isRequestValid = domainName && data;
    if (!isRequestValid) {
      console.error(
        new HttpErrorResponse({
          error: new Error(ErrorMessages.SET),
          url: httpUrl,
        }),
      );
      return of(null);
    }

    return this.execute('POST', httpUrl, data);
  }

  setTemplateParameters(domainName: string, data: TemplateParametersResponse): Observable<void> {
    const httpUrl = `${this.entityUrl}/${domainName}/templateParameters`;
    const isRequestValid = domainName && data;
    if (!isRequestValid) {
      console.error(
        new HttpErrorResponse({
          error: new Error(ErrorMessages.SET),
          url: httpUrl,
        }),
      );
      return of(null);
    }

    return this.execute('POST', httpUrl, data);
  }

  deleteTemplateParameters(domainName: string): Observable<void> {
    const httpUrl = `${this.entityUrl}/${domainName}/templateParameters`;
    if (!domainName) {
      console.error(
        new HttpErrorResponse({
          error: new Error(ErrorMessages.DELETE),
          url: httpUrl,
        }),
      );
      return of(null);
    }

    return this.execute('DELETE', httpUrl);
  }
}
