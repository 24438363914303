import { IEntity } from './entity.interface';
import { PaymentDocumentType } from '@portal/entity-services/organisations/src/lib/enums/payment-document-type.enum';
import { TransactionType } from '../../transaction/enums/transaction-type.enum';
import { ReceiptTemplateType } from '@portal/entity-services/organisations/src/lib/enums/receipt-template-type.enum';
import { ReceiptCopyIdentifier } from '@portal/entity-services/organisations/src/lib/enums/receipt-copy-identifier.enum';
import { ReceiptNotificationType } from '../enums/receipt-notification-type.enum';
import { ReceiptContractStatus } from '@portal/entity-services/organisations/src/lib/enums/receipt-status.enum';
import { ReceiptAttachmentType } from '../enums/receipt-attachment-type.enum';
import { ReceiptProvider } from '@portal/entity-services/organisations/src';
import { ReceiptSalesChannel } from '@portal/entity-services/organisations/src/lib/enums/receipt-sales-channel.enum';

export interface IReceiptContract {
  provider: ReceiptProvider;
  apiToken?: string;
  allowChildReuse?: boolean;
  receiptContractUid?: string;
  providerConfiguration?: IReceiptProvider;
  createdDate?: string;
  modifiedDate?: string;
  version?: number;
  status?: ReceiptContractStatus;
  objectType?: string;
  entity?: IEntity;
  notifications?: IReceiptNotification;
  templateConfiguration?: IReceiptTemplateConfiguration;
}

export interface IReceiptProvider {
  type: ReceiptProvider;
  receiptTypes: string[];
}

export interface IReceiptTemplate {
  type: ReceiptTemplateType;
  templateId: string;
  receiptCustomText?: string;
  receiptCopyIdentifier?: ReceiptCopyIdentifier;
  receiptType?: PaymentDocumentType;
  transactionTypes?: TransactionType[];
  salesChannels?: ReceiptSalesChannel[];
}

export interface IReceiptTemplateConfiguration {
  receiptMerchantLogo?: Blob;
  receiptFooterText?: string;
  templates: IReceiptTemplate[];
}

export interface IReceiptNotification {
  type: ReceiptNotificationType;
  subject?: string;
  fromName?: string;
  fromEmail?: string;
  attachmentType?: ReceiptAttachmentType;
}

export interface IReceiptContractCreatePayload {
  provider: ReceiptProvider;
  apiToken?: string;
  allowChildReuse?: boolean;
  notifications?: IReceiptNotification[];
  templateConfiguration?: IReceiptTemplateConfiguration;
}

export interface IReceiptContractCreatePayloadData
  extends Omit<IReceiptContractCreatePayload, 'templateConfiguration'> {
  templateConfiguration?: IReceiptTemplateConfigurationData;
}

export interface IReceiptTemplateConfigurationData
  extends Omit<IReceiptTemplateConfiguration, 'receiptMerchantLogo'> {
  receiptMerchantLogo?: string;
}

export interface IReceiptContractGetData extends Omit<IReceiptContract, 'templateConfiguration'> {
  templateConfiguration?: IReceiptTemplateConfigurationData;
}
