import { vfappcatalog, vfappmarket } from '../services.url';

export const APPS_API = {
  EO: {
    GET_APPS: function (acquirerEntityUid): string {
      return `${vfappmarket}/v1/apps/acquirers/${acquirerEntityUid}/available`;
    },
    GET_APP: function (acquirerEntityUid, appmarketAppId): string {
      return `${vfappmarket}/v1/apps/acquirers/${acquirerEntityUid}/app/${appmarketAppId}`;
    },
    GET_VAULTS: function (acquirerEntityUid: string): string {
      return `${vfappmarket}/v1/apps/acquirers/${acquirerEntityUid}/vault`;
    },
    GET_VAULT: function (acquirerEntityUid: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/apps/acquirers/${acquirerEntityUid}/vault/app/${appmarketAppId}`;
    },
    UPDATE: function (acquirerEntityUid, appmarketAppId): string {
      return `${vfappmarket}/v1/apps/acquirers/${acquirerEntityUid}/app/${appmarketAppId}`;
    },
    GENERATE_PUBLIC_CATALOG: function (acquirerEntityUid: string): string {
      return `${vfappmarket}/v1/apps/acquirers/${acquirerEntityUid}/public`;
    },
  },
  MARKETPLACE_MANAGER: {
    GET_APPS: function (): string {
      return `${vfappcatalog}/v1/apps`;
    },
    GET_SUBMITTED_APPS: function (): string {
      return `${vfappcatalog}/v1/apps?latestAppVersion.status=SUBMITTED`;
    },
    GET_APP_VERSION_FROM_VF_APP_MARKET: function (appId: string, appVersionId: string): string {
      return `${vfappmarket}/v1/apps/${appId}/version/${appVersionId}`;
    },
    GET_APP_VERSION: function (appVersionId: string): string {
      return `${vfappcatalog}/v1/apps-versions/${appVersionId}`;
    },
  },
  MERCHANT: {
    GET_APPS: function (merchantEntityUid): string {
      return `${vfappmarket}/v1/apps/merchants/${merchantEntityUid}/available?limitVersions=1`;
    },
    GET_APP: function (merchantEntityUid: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/apps/merchants/${merchantEntityUid}/app/${appmarketAppId}`;
    },
    MY_APPS(merchantEntityUid): string {
      return `${vfappmarket}/v1/apps/merchants/${merchantEntityUid}/owned`;
    },
    MY_BUNDLES(merchantEntityUid): string {
      return `${vfappmarket}/v1/bundles/merchants/${merchantEntityUid}/owned`;
    },
    GET_DEVICES_ASSIGNED_WITH_APP(merchantEntityUid: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/devices/merchants/${merchantEntityUid}/app/${appmarketAppId}`;
    },
    GET_APPS_UPDATES(merchantEntityUid): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/apps/upgrades`;
    },
    GET_APP_UPDATES(merchantEntityUid: string, appId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/app/${appId}/upgrades`;
    },
    GET_BUNDLES_UPDATES(merchantEntityUid): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/bundles/upgrades`;
    },
  },
  GET_APPS_EO: function (entityUid): string {
    return APPS_API.EO.GET_APPS(entityUid);
  },
  GET_APPS_MERCHANT: function (entityUid): string {
    return APPS_API.MERCHANT.GET_APPS(entityUid);
  },
  GET_APPS_FROM_CATALOG: function (ids: string[] | Set<string>): string {
    let query = '';
    let length = 0;
    for (const id of ids) {
      length++;
      query += 'id=' + id + '&';
    }
    query = query.substring(0, query.length - 1);
    return `${vfappcatalog}/v1/apps-versions?${query}&size=${length}`;
  },
  ASSIGN_APP_TO_DEVICES: {
    ASSIGN: function (merchantId: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantId}/apps/${appmarketAppId}/assign`;
    },
    EVALUATION: function (merchantId: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantId}/apps/${appmarketAppId}/assign/evaluate`;
    },
  },
  UNASSIGN_APP_FROM_DEVICES: {
    UNASSIGN: function (merchantId: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantId}/apps/${appmarketAppId}/unassign`;
    },
    EVALUATION: function (merchantId: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantId}/apps/${appmarketAppId}/unassign/evaluate`;
    },
  },
  GET_DEVICES_ASSIGNED_TO_APP: function (org: string, appId: string): string {
    return `${vfappmarket}/v1/devices/merchants/${org}/app/${appId}`;
  },


  // tags
  GET_APP_TAGS: function (acquirerEntityUid: string): string {
    return `${vfappmarket}/v1/entityTags/acquirers/${acquirerEntityUid}/apps`;
  },

  DELETE_APP_TAG: function (acquirerEntityUid: string, tagId: string): string {
    return `${vfappmarket}/v1/entityTags/entity/${acquirerEntityUid}/entityTag/${tagId}`;
  },

  UPDATE_APP_TAG: function (acquirerEntityUid: string, tagId: string): string {
    return `${vfappmarket}/v1/entityTags/entity/${acquirerEntityUid}/entityTag/${tagId}`;
  },

  GET_APPS_BY_TAGS: function (entityUid: string, entiTagsIds: string[]): string {
    const params = `?entitiesTagIds=${entiTagsIds.join('&entitiesTagIds=')}`;
    return `${vfappmarket}/v1/entityTags/entity/${entityUid}/entityTags/apps${params}`;
  },

  ASSIGN_TAG_TO_APP: function (
    acquirerEntityUid: string,
    tagId: string,
    appId: string,
  ): string {
    return `${vfappmarket}/v1/entityTags/acquirers/${acquirerEntityUid}/entityTag/${tagId}/app/${appId}/assign`;
  },

  UNASSIGN_TAG_FROM_BUNDLE: function (
    acquirerEntityUid: string,
    tagId: string,
    appId: string,
  ): string {
    return `${vfappmarket}/v1/entityTags/acquirers/${acquirerEntityUid}/entityTag/${tagId}/app/${appId}/unassign`;
  },

  UNASSIGN_TAGS_FROM_APPS: function (acquirerEntityUid: string): string {
    return `${vfappmarket}/v1/entityTags/acquirers/${acquirerEntityUid}/apps/unassign`;
  },

  ASSIGN_TAGS_FROM_APPS: function (acquirerEntityUid: string): string {
    return `${vfappmarket}/v1/entityTags/acquirers/${acquirerEntityUid}/apps/assign`;
  },




};

// }
// {
//   GET_APPS_MERCHANT: function(acquirerEntityUid: string) {
//     return `${vfappmarket}/v1/apps/merchants/1f31a43f-7faa-40e5-840f-90e7c6bce0ec/available`;
//   },
//   GET_APPS_FROM_CATALOG: function(ids: string[]) {
//     let query = '';
//     for (const id of ids) {
//       query += 'id=' + id + '&';
//     }
//     query = query.substring(0, query.length - 1);
//     return `http://appcatalog-test-b5ad796e019743a0.elb.eu-west-2.amazonaws.com/app-catalog/v1/apps-versions?${query}`;
//   },
//   GET_DEVICES_ASSIGNED_TO_APP: function(org: string, appId: string) {
//     return `${vfappmarket}/v1/devices/merchants/${org}/app/${appId}`;
//   },
//   ASSIGN_APP_TO_DEVICES: {
//     ASSIGN: function(merchantId: string, appmarketAppId: string) {
//       return `${vfappmarket}/v1/assignments/merchants/${merchantId}/apps/${appmarketAppId}/assign`;
//     },
//     EVALUATION: function(merchantId: string, appmarketAppId: string) {
//       return `${vfappmarket}/v1/assignments/merchants/${merchantId}/apps/${appmarketAppId}/assign/evaluate`;
//     }
//   },
//   UNASSIGN_APP_FROM_DEVICES: {
//     UNASSIGN: function(merchantId: string, appmarketAppId: string) {
//       return `${vfappmarket}/v1/assignments/merchants/${merchantId}/apps/${appmarketAppId}/unassign`;
//     },
//     EVALUATION: function(merchantId: string, appmarketAppId: string) {
//       return `${vfappmarket}/v1/assignments/merchants/${merchantId}/apps/${appmarketAppId}/unassign/evaluate`;
//     }
//   }
// };
