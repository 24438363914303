export enum Continent {
  Europe = 'Europe',
  Asia = 'Asia',
  Africa = 'Africa',
  Oceania = 'Oceania',
  Pacific = 'Pacific',
  SouthAmerica = 'SouthAmerica',
  NorthAmerica = 'NorthAmerica',
  Antarctica = 'Antarctica',
  Atlantic = 'Atlantic',
}
