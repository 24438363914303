<div class="dropdown" [class.is-active]="isDropdownOpen">
  <div class="dropdown-trigger">
    <button
      [attr.aria-expanded]="isOpen"
      class="button is-chip is-size-7-mobile"
      (click)="openMenu()"
      data-e2e="button-add-filter"
      id="add-filter"
      type="button"
      title="Add filter"
      i18n-title
    >
      <span i18n>Add filter</span>
      <span class="icon">
        <i class="fas fa-plus"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu add-filter-menu">
    <div class="dropdown-content" [class.is-hidden]="!isOpen" data-e2e="addFilter-filterMenu">
      <a
        tabindex="0"
        class="dropdown-item has-icons"
        data-e2e="addFilter-transactionStatus"
        (click)="goToSubmenu('header-state')"
        (keyup.enter)="goToSubmenu('header-state')"
        title="Status filter"
        i18n-title
      >
        <span i18n>Transaction status</span>
        <span class="icon is-hover-visible">
          <i class="fas fa-chevron-right" aria-hidden="true"> </i>
        </span>
      </a>

      <a
        tabindex="0"
        class="dropdown-item has-icons"
        data-e2e="addFilter-storedCredentialType"
        (click)="goToSubmenu('stored-credential-type')"
        (keyup.enter)="goToSubmenu('stored-credential-type')"
        title="Stored credential filter"
        i18n-title
      >
        <span i18n>Stored Credential type</span>
        <span class="icon is-hover-visible">
          <i class="fas fa-chevron-right" aria-hidden="true"> </i>
        </span>
      </a>
    </div>

    <!-- Submenu content -->
    <ng-content></ng-content>
  </div>
</div>
