import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers';
import { ISelectInput } from '@portal/shared/ui/filter';
import { sortBy } from 'lodash';
import { ReceiptAttachmentType } from '@portal/entity-services/interfaces/src/lib/organisations/enums/receipt-attachment-type.enum';

declare const $localize;

@Injectable({
  providedIn: 'root',
})
export class ReceiptAttachmentTypes extends SelectInputsList {
  keyValue = {
    [ReceiptAttachmentType.Pdf]: $localize`PDF`,
    [ReceiptAttachmentType.Png]: $localize`PNG`,
    [ReceiptAttachmentType.None]: $localize`None`,
  };
  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);
}