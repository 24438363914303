export enum SearchOperator {
  Equal = '==',
  NotEqual = '!=',
  LessThan = '=lt=',
  LessThanAndEqualTo = '=le=',
  GreaterThan = '=gt=',
  GreaterThanAndEqualTo = '=ge=',
  Regex = '=regex=',
  Contains = '=contains=',
  In = '=in=',
  Out = '=out=',
  SingleEqual = '=',
}
