<!-- <button
class="button is-chip"
[class.is-applied]="getValues().length"
[class.is-active]="isOpen"
data-e2e="button-entity-filter"
(click)="itemToggled.emit()"
> -->

<div class="button is-chip">
  <div class="field is-grouped">
    <div class="control has-toggle-label">
      <label class="label" for="inline1">{{ filterName }}</label>
    </div>
    <div class="control">
      <label class="toggle">
        <input type="checkbox" [checked]="status" (change)="onChange($event)" />
        <span> <!-- empty tag is necessary --></span>
      </label>
    </div>
  </div>
  <!-- </button> -->
</div>
