<div class="columns is-vcentered">
  <div class="column">
    <!-- Search box -->
    <portal-search-box
      [entities]="searchEntities"
      [defaultEntityId]="defaultSearchSelector"
    ></portal-search-box>

    <portal-filter-wrapper>
      <div class="control">
        <portal-wl-filter-date
          [selectedEntities]="criteriaMap"
          [quickPick]="dateFilterQuickPick"
          [showQuickPicks]="true"
          [showAnyDateQuickPick]="searchTermValue !== ''"
          [selectors]="dateFilterSelectors"
        ></portal-wl-filter-date>
      </div>
      <div class="control">
        <portal-hierarchy-filters [selectedEntities]="criteriaMap" [resetEntity$]="removeEntity$"></portal-hierarchy-filters>
      </div>
      <div class="control" *ngFor="let filter of selectedStaticFilters">
        <button
          class="button is-chip is-applied is-size-7-mobile"
          (click)="removeStaticFilter(filter)"
          title="{{ filter.text | removeUnderscore }}"
          type="button"
        >
          <span>{{ filter.text | removeUnderscore }}</span>
          <span class="icon is-small">
            <i class="fas fa-times"></i>
          </span>
        </button>
      </div>
      <portal-wl-add-filter (closeSubmenus)="onSubmenusClosed($event)">
        <!-- Submenu content -->
        <portal-filter-wrapper>
          <portal-wl-filter-header-state
            [entityRemoved$]="removeEntity$"
            [closeSubmenus$]="closeSubmenus$"
            (staticFilterUpdated)="onStaticFilterUpdated($event)"
          >
          </portal-wl-filter-header-state>
          <portal-wl-filter-stored-credential-type
            [entityRemoved$]="removeEntity$"
            [closeSubmenus$]="closeSubmenus$"
            (staticFilterUpdated)="onStaticFilterUpdated($event)"
          ></portal-wl-filter-stored-credential-type>
        </portal-filter-wrapper>
      </portal-wl-add-filter>
    </portal-filter-wrapper>
  </div>
</div>
