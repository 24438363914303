import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FilterContentItem } from '../content/item';
import { FILTER_CONTENT_ITEM } from '../content/token';
import { FiltersList } from '../interfaces/filters-list';

@Component({
  selector: 'portal-add-filter',
  templateUrl: './add-filter.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: AddFilterComponent,
    },
  ],
})
export class AddFilterComponent extends FilterContentItem implements OnInit, OnDestroy {
  @Input() filtersList: FiltersList;
  @Output()
  closeSubmenus: EventEmitter<boolean> = new EventEmitter();

  filterApplied: EventEmitter<void>;

  itemToggled: EventEmitter<number> = new EventEmitter();
  isOpen = false;
  isDropdownOpen = false;

  goToSubmenuSubject$ = new Subject<string>();
  goToSubmenuSub: Subscription;

  constructor() {
    super();
  }

  onFilterApplied(): void {
    // This is intentional
  }

  getList(): any {
    return null;
  }

  ngOnInit(): void {
    this.goToSubmenuSub = this.goToSubmenuSubject$
      .pipe(debounceTime(100))
      .subscribe((submenu: string) => {
        const element = document.getElementById(`filter-${submenu}-detail`);
        if (element) {
          element.click();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.goToSubmenuSub) {
      this.goToSubmenuSub.unsubscribe();
    }
  }

  goToSubmenu(submenu: string): void {
    this.itemToggled.emit();
    this.goToSubmenuSubject$.next(submenu);
  }

  openMenu(): void {
    this.isDropdownOpen = true;
    this.itemToggled.emit();
    if (this.isOpen) {
      this.closeSubmenus.emit(true);
    }
  }
}
