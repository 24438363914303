import { vfappmarket } from '../services.url';

export const BUNDLES_API = {
  MERCHANT: {
    GET_BUNDLES: function (merchantEntityUid: string): string {
      return `${vfappmarket}/v1/bundles/merchants/${merchantEntityUid}/available`;
    },
    GET_APP_BUNDLES: function (merchantEntityUid: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/bundles/merchants/${merchantEntityUid}/apps/${appmarketAppId}`;
    },
    GET_BUNDLE: function (merchantEntityUid: string, bundleId: string): string {
      return `${vfappmarket}/v1/bundles/merchants/${merchantEntityUid}/bundle/${bundleId}`;
    },
    GET_OWNED_BUNDLES: function (merchantEntityUid: string): string {
      return `${vfappmarket}/v1/bundles/merchants/${merchantEntityUid}/owned?fetchAll=true`;
    },
    GET_DEVICES_ASSIGNED_TO_BUNDLE: function (
      merchantEntityUid: string,
      appmarketBundleId: string,
    ): string {
      return `${vfappmarket}/v1/devices/merchants/${merchantEntityUid}/bundle/${appmarketBundleId}`;
    },
    UPDATES: {
      GET_BUNDLE_UPDATES: function (
        merchantEntityUid: string,
        bundleId: string,
        fetchAll: boolean,
      ): string {
        if (fetchAll) {
          return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/bundles/${bundleId}/upgrades?fetchAll=true`;
        }
        return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/bundles/${bundleId}/upgrades`;
      },
      UPDATE_BUNDLE_EVALUATION: function (
        merchantEntityUid: string,
        appmarketBundleId: string,
      ): string {
        return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/bundles/${appmarketBundleId}/upgrade/pois/evaluate`;
      },
      COMPLETE_BUNDLE_UPDATE: function (
        merchantEntityUid: string,
        appmarketBundleId: string,
      ): string {
        return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/bundles/${appmarketBundleId}/upgrade/pois`;
      },
    },
  },
  GET_BUNDLES: function (acquirerEntityUid: string): string {
    return `${vfappmarket}/v1/bundles/acquirers/${acquirerEntityUid}`;
  },
  GET_BUNDLE: function (acquirerEntityUid: string, appmarketBundleId: string): string {
    return `${vfappmarket}/v1/bundles/acquirers/${acquirerEntityUid}/bundle/${appmarketBundleId}`;
  },
  CREATE_BUNDLE: function (acquirerEntityUid: string): string {
    return `${vfappmarket}/v1/bundles/acquirers/${acquirerEntityUid}`;
  },
  ASSIGN_APP_TO_BUNDLE: function (acquirerEntityUid: string, bundleId: string): string {
    return `${vfappmarket}/v1/bundles/acquirers/${acquirerEntityUid}/bundle/${bundleId}/assign`;
  },
  REMOVE_BUNDLE: function (acquirerEntityUid: string, bundleId: string): string {
    return `${vfappmarket}/v1/bundles/acquirers/${acquirerEntityUid}/bundle/${bundleId}`;
  },
  UPDATE_BUNDLE: function (acquirerEntityUid: string, bundleId: string): string {
    return `${vfappmarket}/v1/bundles/acquirers/${acquirerEntityUid}/bundle/${bundleId}`;
  },
  UPDATE_BUNDLE_WHITELIST: function (acquirerEntityUid: string, appmarketBundleId: string): string {
    return `${vfappmarket}/v1/bundles/acquirers/${acquirerEntityUid}/bundle/${appmarketBundleId}/whitelist`;
  },
  UPLOAD_IMAGE: function (acquirerEntityUid: string, bundleId: string): string {
    return `${vfappmarket}/v1/bundles/acquirers/${acquirerEntityUid}/bundle/${bundleId}/image`;
  },

  GET_BUNDLE_TAGS: function (acquirerEntityUid: string): string {
    return `${vfappmarket}/v1/entityTags/acquirers/${acquirerEntityUid}/bundles`;
  },

  DELETE_BUNDLE_TAG: function (acquirerEntityUid: string, tagId: string): string {
    return `${vfappmarket}/v1/entityTags/entity/${acquirerEntityUid}/entityTag/${tagId}`;
  },

  UPDATE_BUNDLE_TAG: function (acquirerEntityUid: string, tagId: string): string {
    return `${vfappmarket}/v1/entityTags/entity/${acquirerEntityUid}/entityTag/${tagId}`;
  },

  GET_BUNDLES_BY_TAGS: function (entityUid: string, entiTagsIds: string[]): string {
    const params = `?entitiesTagIds=${entiTagsIds.join('&entitiesTagIds=')}`;
    return `${vfappmarket}/v1/entityTags/entity/${entityUid}/entityTags/bundles${params}`;
  },

  ASSIGN_TAG_TO_BUNDLE: function (
    acquirerEntityUid: string,
    tagId: string,
    bundleId: string,
  ): string {
    return `${vfappmarket}/v1/entityTags/acquirers/${acquirerEntityUid}/entityTag/${tagId}/bundle/${bundleId}/assign`;
  },

  UNASSIGN_TAG_FROM_BUNDLE: function (
    acquirerEntityUid: string,
    tagId: string,
    bundleId: string,
  ): string {
    return `${vfappmarket}/v1/entityTags/acquirers/${acquirerEntityUid}/entityTag/${tagId}/bundle/${bundleId}/unassign`;
  },

  UNASSIGN_TAGS_FROM_BUNDLES: function (acquirerEntityUid: string): string {
    return `${vfappmarket}/v1/entityTags/acquirers/${acquirerEntityUid}/bundles/unassign`;
  },

  ASSIGN_TAGS_FROM_BUNDLES: function (acquirerEntityUid: string): string {
    return `${vfappmarket}/v1/entityTags/acquirers/${acquirerEntityUid}/bundles/assign`;
  },
};
