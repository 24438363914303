import { Injectable } from '@angular/core';
import { EntityType } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { sortBy } from 'lodash';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class EntityTypes extends SelectInputsList {
  keyValue = {
    [EntityType.MERCHANT_COMPANY]: $localize`Merchant Company`,
    [EntityType.MERCHANT_SITE]: $localize`Merchant Site`,
    [EntityType.ESTATE_OWNER]: $localize`Estate Owner`,
    [EntityType.DEVELOPER_COMPANY]: $localize`Developer`,
    [EntityType.UNDEFINED]: $localize`Unspecified`,
    [EntityType.MERCHANT_COMPANY_GROUP]: $localize`Merchant Company Group`,
  };

  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);

  constructor() {
    super();
  }
}
