<div class="row" data-e2e="portal-color-picker">
  <div class="field">
    <div class="columns mr-0 mb-0">
      <label
        class="column pb-0 is-flex is-align-items-center color-picker-label"
        [class.is-active]="isOpened"
        for="color-picker"
        (click)="toggleDialog()"
      >
        <div
          class="input width-0 pl-3 pr-4 color-picker-selected-color"
          [style.background]="selectedColor"
        ></div>
        <span i18n="Hex color code" class="pl-2 pr-2">HEX:</span>
        <input
          maxlength="7"
          size="7"
          type="text"
          class="input mr-2"
          (input)="handleInputEvent($event)"
          [(ngModel)]="selectedColor"
          (ngModelChange)="acceptColor($event)"
          [ngStyle]="{ width: 'auto' }"
          [class.is-danger]="errorMessages.length"
        />
        <span class="icon is-size-5 is-pointer" (keyup.enter)="toggleDialog()" tabindex="0">
          <i class="fas fa-chevron-down"></i>
        </span>
      </label>
    </div>

    <div *ngIf="errorMessages.length" class="help is-danger">
      <p data-e2e="color-picker-error-message">{{ errorMessages[0] }}</p>
    </div>
  </div>

  <div class="row" *ngIf="isOpened">
    <div data-e2e="color-picker" class="pl-3">
      <span
        id="color-picker"
        [style.background]="selectedColor"
        [cpToggle]="true"
        cpDialogDisplay="inline"
        cpOutputFormat="hex"
        [cpAlphaChannel]="'disabled'"
        [cpOKButton]="true"
        [cpOKButtonText]="acceptBtnText"
        cpOKButtonClass="button is-primary is-size-6"
        (cpInputChange)="onCpInputChange($event)"
        (colorPickerSelect)="acceptColor($event)"
        [(colorPicker)]="selectedColor"
        [cpFallbackColor]="fallBackColor"
      ></span>
    </div>
  </div>
</div>
