import { IThreeDSecureStatus } from './interfaces/three-d-secure-enroll-status.interface';
import { Injectable } from '@angular/core';
import { sortBy } from 'lodash';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class ThreeDSecureStatusService {
  threeDSecureEnrollmentStatus: IThreeDSecureStatus[] = sortBy([
    {
      value: 'Y',
      description: $localize`Yes - Bank is participating in 3D Secure protocol and will return the ACSUrl`,
      name: $localize`Yes`,
    },
    {
      value: 'N',
      description: $localize`No - Bank is not participating in 3D Secure protocol`,
      name: $localize`No`,
    },
    {
      value: 'U',
      description: $localize`Unavailable - The DS or ACS is not available for authentication at the time of the request`,
      name: $localize`Unavailable`,
    },
    {
      value: 'B',
      description: $localize`Bypass- Merchant authentication rule is triggered to bypass authentication in this use case`,
      name: $localize`Bypass`,
    },
  ]);
  threeDSecureAuthenticationStatus: IThreeDSecureStatus[] = sortBy([
    {
      value: 'Y',
      description: $localize`Successful Authentication`,
      name: $localize`Successful`,
    },
    {
      value: 'N',
      description: $localize`Failed Authentication`,
      name: $localize`Failed`,
    },
    {
      value: 'A',
      description: $localize`Successful Attempts Transaction`,
      name: $localize`Authentication attempts`,
    },
    {
      value: 'C',
      description: $localize`Challenge Required for Authentication`,
      name: $localize`Challenge required`,
    },
    {
      value: 'R',
      description: $localize`Authentication Rejected (Merchant must not submit for authorization)`,
      name: $localize`Rejected`,
    },
    {
      value: 'U',
      description: $localize`Unable to Complete Authentication`,
      name: $localize`Unable to complete`,
    },
  ]);
}
