import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SecureLoadPipe } from '@portal/shared/pipes';
@Injectable({ providedIn: 'root' })
export class FileLoader {
  imagesArray = {};

  constructor(private secureLoad: SecureLoadPipe, private sanitizer: DomSanitizer) {}

  load(id: string, url: string): any {
    if (!(this.imagesArray && this.imagesArray[id])) {
      this.secureLoad.transform(url).subscribe((img) => {
        if (img !== 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==') {
          this.imagesArray[
            id
          ] = `<img src='${img.changingThisBreaksApplicationSecurity}' class='image is-32x32'/>`;
        }
      });
      this.imagesArray[
        id
      ] = `<img src='data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==' class='image is-32x32'/>`;
      return this.sanitizer.bypassSecurityTrustHtml(this.imagesArray[id]);
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(this.imagesArray[id]);
    }
  }
}
