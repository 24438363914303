<label class="field">
  <div class="label" i18n="Anti Money Laundering (AML) Timeout setting - can be Block or Allow">
    AML timeout
  </div>
  <div class="control">
    <label class="radio" *ngFor="let action of actionAmlTimeouts.list">
      <input
        type="radio"
        [formControl]="actionAmlTimeout"
        data-e2e="radio-actionAmlTimeout"
        [value]="action.id"
      />
      <span>{{ action.text }}</span>
    </label>
  </div>
</label>

<div class="field" *ngIf="openingHoursForm">
  <div class="label" i18n>Opening hours</div>
  <div class="control">
    <ng-container *ngFor="let dayControl of openingHoursForm.controls; let i = index">
      <div class="field is-grouped">
        <div class="control is-expanded">
          <label class="checkbox is-pulled-left">
            <input
              type="checkbox"
              data-e2e="checkbox-openingHours"
              checked="dayControl.get('timesString').value!=='Closed'"
              [formControl]="dayControl.get('isEditing')"
            />
            <span>
              {{ openingHoursDays.list[i].text }}
            </span>
          </label>
        </div>
        <div class="control">
          <span [class.has-line-through]="dayControl.value.isEditing">
            {{ dayControl.value.timesString }}
          </span>
        </div>
      </div>

      <ng-container *ngIf="dayControl.value.isEditing">
        <ng-container
          *ngFor="let timeslotControl of dayControl.get('times').controls; let i = index"
        >
          <div class="field is-grouped timeslot-field-group">
            <div class="control">
              <label class="field">
                <div class="label" *ngIf="i === 0" i18n>Opening hours</div>
                <div class="control">
                  <!-- TODO: for some reason there is no directives for such names,
                         investigate this solution  -->
                  <!-- 
                    [validation]="false"
                  -->
                  <input
                    class="input"
                    type="text"
                    placeholder="09:00"
                    mask="Hh:m0"
                    [dropSpecialCharacters]="false"
                    [formControl]="timeslotControl.get('start')"
                    (blur)="onOpeningHoursChanged(dayControl)"
                    [ngClass]="{
                      'is-danger':
                        isInvalid(timeslotControl.get('start')) ||
                        (isInvalid(timeslotControl) && timeslotControl.errors?.timePair) ||
                        (isInvalid(dayControl.get('times')) &&
                          dayControl.get('times').errors?.timePairOverlap)
                    }"
                    data-e2e="input-timeslot-start"
                  />
                </div>
                <portal-form-error
                  [formInput]="timeslotControl.get('start')"
                  errorId="timeslotStart"
                ></portal-form-error>
              </label>
            </div>
            <div
              class="control timeslot-control-separator has-border-bottom"
              [class.timeslot-control-separator--first]="i === 0"
            ></div>
            <div class="control">
              <label class="field">
                <div class="label" *ngIf="i === 0" i18n>Closing hours</div>
                <div class="control">
                  <!-- TODO: for some reason there is no directives for such names,
                         investigate this solution  -->
                  <!-- 
                    [validation]="false"
                  -->
                  <input
                    class="input"
                    type="text"
                    placeholder="18:00"
                    mask="Hh:m0"
                    [dropSpecialCharacters]="false"
                    [formControl]="timeslotControl.get('end')"
                    (blur)="onOpeningHoursChanged(dayControl)"
                    [ngClass]="{
                      'is-danger':
                        isInvalid(timeslotControl.get('end')) ||
                        (isInvalid(timeslotControl) && timeslotControl.errors?.timePair) ||
                        (isInvalid(dayControl.get('times')) &&
                          dayControl.get('times').errors?.timePairOverlap)
                    }"
                    data-e2e="input-timeslot-end"
                  />
                </div>
                <portal-form-error
                  [formInput]="timeslotControl.get('end')"
                  errorId="timeslotEnd"
                ></portal-form-error>
                <portal-form-error
                  [formInput]="timeslotControl"
                  errorId="timeslotPair"
                ></portal-form-error>
              </label>
            </div>
            <div
              class="control timeslot-control-remove-button"
              [class.timeslot-control-remove-button--first]="i === 0"
            >
              <button
                class="button is-transparent is-empty has-text-grey"
                (click)="removeTimeslot(dayControl.get('times'), i)"
                data-e2e="button-remove-timeslot"
                type="button"
                title="Remove timeslot"
                i18n-title
              >
                <span class="icon is-large">
                  <span class="is-sr-only" i18n>Remove timeslot</span>
                  <i class="fal fa-times fa-lg"></i>
                </span>
              </button>
            </div>
          </div>
        </ng-container>

        <div class="field timeslot-field-group">
          <portal-form-error
            [formInput]="dayControl.get('times')"
            errorId="timeslotPairOverlap"
          ></portal-form-error>
        </div>

        <div class="field timeslot-field-group">
          <div class="control">
            <button
              type="button"
              class="button has-text-primary is-text"
              (click)="addTimeslot(dayControl.get('times'))"
              data-e2e="button-add-timeslot"
              i18n
              title="Add more timeslots"
              i18n-title
            >
              Add more timeslots
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <portal-form-error [formInput]="openingHours" errorId="markOneOption"></portal-form-error>
</div>
