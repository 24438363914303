import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QueryParams } from '@ngrx/data/src/dataservices/interfaces';
import { IUser, UserStatus } from '@portal/entity-services/interfaces/src';
import { CustomUserDataService } from '@portal/entity-services/users/src/lib/services/user-data.service';
import { FilterAutoCompleteInputComponent } from '@portal/shared/ui/filter/src/lib/autocomplete/search-autocomplete-input.component';
import { FilterContentItem } from '@portal/shared/ui/filter/src/lib/content/item';
import { FILTER_CONTENT_ITEM } from '@portal/shared/ui/filter/src/lib/content/token';
import { SearchOperator } from '@portal/shared/ui/filter/src/lib/search/enums/search-operator.enum';
import { SearchSelector } from '@portal/shared/ui/filter/src/lib/search/enums/search-selector.enum';
import { IFilterToApply } from '@portal/shared/ui/filter/src/lib/search/interfaces/filter-to-apply.interface';
import { ISearchCriteria } from '@portal/shared/ui/filter/src/lib/search/interfaces/search-criteria.interface';
import { Subject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UserFilterService } from './services/user-filter.service';
import { ISelectInput } from '@portal/shared/ui/filter/src/lib/box/interfaces/select-input.interface';
import forEach from 'lodash-es/forEach';

@UntilDestroy()
@Component({
  selector: 'portal-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss'],
  providers: [
    CustomUserDataService,
    UserFilterService,
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: UserFilterComponent,
    },
  ],
})
export class UserFilterComponent extends FilterContentItem implements OnInit, OnChanges {
  @Input() entityId: string;
  @Input() selector = SearchSelector.UserUid;
  @Input() multiSelector = SearchSelector.Users;
  @Input() selectedEntities: Map<string, ISearchCriteria>;

  @Output() select = new EventEmitter<IUser>();
  @Output() filterApplied = new EventEmitter<IFilterToApply>();
  @Output() itemToggled = new EventEmitter<number>(); // "number" type from FilterContentItem

  @ViewChild(FilterAutoCompleteInputComponent, { static: true })
  searchInput: FilterAutoCompleteInputComponent;
  userStatuses = UserStatus;
  isOpen: boolean;
  isInactiveVisible: boolean;
  loading$: Observable<boolean>;
  users: IUser[] = [];
  strUserUids: string;
  searchText: string;
  localPreSelectedEntities: Map<string, ISelectInput> = new Map();
  isFilterApplied = false;

  private dataSubscription: Subscription;
  private searchSubject = new Subject<string>();

  constructor(private userFilterService: UserFilterService) {
    super();
  }

  ngOnInit(): void {
    this.loading$ = this.userFilterService.loading$;
    this.searchSubject
      .pipe(untilDestroyed(this), debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.searchText = value;
        this.loadUsers();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.entityId?.firstChange && changes?.entityId?.currentValue) {
      this.clearSearch();
      this.clearSelectedUser();
      this.loadUsers();
    }

    if (changes?.selectedEntities) {
      this.onSelect();
    }
  }

  onSearch(value: string): void {
    this.searchSubject.next(value);
  }

  onSelect(): void {
    this.selectedEntities.forEach((searchEntity: ISearchCriteria) => {
      if (
        searchEntity?.selector === this.multiSelector ||
        searchEntity?.selector === this.selector
      ) {
        this.strUserUids = searchEntity?.argument;
      }
    });

    if (this.strUserUids) {
      const values = new Set<string>(this.strUserUids.replace('(', '').replace(')', '').split(','));
      forEach(Array.from(values), (argument: string) => {
        this.localPreSelectedEntities.set(argument, {
          id: argument,
          text: '',
        });
      });
      this.isFilterApplied = true;
    } else {
      this.isFilterApplied = false;
    }
  }

  onFilterApplied(): void {
    const filterToApply = this.getFilterToApply();
    this.filterApplied.emit(filterToApply);
    this.itemToggled.emit();
    this.clearSearch();
  }

  onFilterClear(): void {
    this.clearSelectedUser();
    this.onFilterApplied();
  }

  onInactiveChange(): void {
    this.loadUsers();
  }

  clear(): void {
    this.clearSearch();
    this.clearSelectedUser();
    this.localPreSelectedEntities.clear();
    this.isFilterApplied = false;
    this.isOpen = false;
  }

  getList(): any {
    if (!this.users.length) {
      this.loadUsers();
    }
  }

  onEntityRemoved(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.delete(selectedEntity.id);
    this.strUserUids = Array.from(this.localPreSelectedEntities.keys()).join(',');
  }

  onEntityAdded(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.set(selectedEntity.id, selectedEntity);
    this.strUserUids = Array.from(this.localPreSelectedEntities.keys()).join(',');
  }

  private loadUsers(): void {
    if (this.entityId) {
      this.dataSubscription?.unsubscribe();
      this.clearUsers();

      const queryParams = this.getQueryParams();
      this.dataSubscription = this.userFilterService
        .getEntityUsers(queryParams)
        .pipe(untilDestroyed(this))
        .subscribe((data) => (this.users = data));
    }
  }

  private getQueryParams(): QueryParams {
    const userStatuses = [this.isInactiveVisible ? UserStatus.Inactive : UserStatus.Active].filter(
      Boolean,
    );

    return {
      status: userStatuses.join(','),
      ...(this.searchText
        ? {
            name: this.searchText,
          }
        : {}),
    };
  }

  private getFilterToApply(): IFilterToApply {
    const isEmptyFilter = !Boolean(this.strUserUids);
    this.isFilterApplied = !isEmptyFilter;
    const eventUserSelector =
      this.localPreSelectedEntities.size > 1 ? this.multiSelector : this.selector;
    if (this.localPreSelectedEntities.size) {
      return {
        key: eventUserSelector,
        selector: eventUserSelector,
        operator: SearchOperator.Equal,
        value: isEmptyFilter ? [] : [{ id: this.strUserUids }],
        argument: this.strUserUids,
        toRemove: isEmptyFilter,
      };
    } else {
      return {
        key: eventUserSelector,
        selector: eventUserSelector,
        toRemove: true,
      };
    }
  }

  private clearUsers(): void {
    this.users = [];
  }

  private clearSelectedUser(): void {
    this.strUserUids = null;
    this.localPreSelectedEntities.clear();
    this.isFilterApplied = false;
  }

  private clearSearch(): void {
    if (!this.searchText) {
      return;
    }
    this.searchSubject.next('');
    this.searchInput.reset();
  }
}
