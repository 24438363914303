import { Component, Input } from '@angular/core';

@Component({
  selector: 'portal-change-password-error',
  templateUrl: './change-password-error.component.html',
})
export class ChangePasswordErrorComponent {
  @Input() show: boolean;
  @Input() errorMessage: string;
}
