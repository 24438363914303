import { EntityStore } from '@datorama/akita';
import { IQueryParams } from '@portal/shared/ui/table/src';
export class StorePagination {
  updateStorePagination(store: EntityStore, paginationConfig: IQueryParams, ids: string[]): void {
    const storePagination = store.getValue().pagination.pages;
    storePagination.set(paginationConfig.start / paginationConfig.limit, ids);
    store.update({ pagination: { pages: storePagination, size: paginationConfig.limit } });
  }
  checkIfNeedFetchData(store: EntityStore, paginationConfig): boolean {
    const storePagination = store.getValue().pagination.pages;

    if (storePagination.get(paginationConfig.start / paginationConfig.limit)) {
      return false;
    }
    return true;
  }
  resetPagination(store: EntityStore): void {
    store.reset();
    store.update({
      pagination: { pages: new Map<number, Array<string>>(), size: 0 },
    });
    store.setHasCache(false);
  }
}
