import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';
import { CustomDataService } from '@portal/shared/vui-http';
import { IVDevice } from '../../common/interfaces/device/IVDevice';
import { VHQ_CONSTANTS as CONSTANTS } from '../../core/constants';

@Injectable({
  providedIn: 'root',
})
export class CustomDeviceDataService extends CustomDataService<IVDevice> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super(CONSTANTS.ENTITY_SERVICE.ORGANISATION, http, httpUrlGenerator);
  }
}
