<form [formGroup]="form" (ngSubmit)="submit()">
  <portal-reseller-details
    [entityUid]="entityUid"
    [disabled]="disabled"
    [templateParametersForm]="templateParametersForm"
    [templateParameters]="templateParameters"
    [domainNameControl]="domainNameControl"
    [emailDomainNameControl]="emailDomainNameControl"
  ></portal-reseller-details>

  <button
    [disabled]="disabled"
    data-e2e="templateParameters-saveButton"
    type="submit"
    class="button is-primary mt-5"
    i18n
    title="Save reseller details"
    i18n-title
  >
    Save reseller details
  </button>
</form>
