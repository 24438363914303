import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AppUpgrade } from './app-upgrade.model';

export interface AppUpgradesState extends EntityState<AppUpgrade> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app-upgrades', idKey: 'appId', resettable: true })
export class AppUpgradesStore extends EntityStore<AppUpgradesState, AppUpgrade> {
  constructor() {
    super();
  }
}
