import { Injectable } from '@angular/core';
import { sortBy } from 'lodash';
import { Continent } from '../continent/continent.enum';
import { ICountry } from './interfaces/country.interface';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class CountryService {
  countries: ICountry[] = sortBy(
    [
      {
        numeric: '004',
        alpha2: 'AF',
        alpha3: 'AFG',
        name: $localize`Afghanistan`,
        continent: Continent.Asia,
      },
      {
        numeric: '248',
        alpha2: 'AX',
        alpha3: 'ALA',
        name: $localize`Åland Islands`,
        continent: Continent.Europe,
      },
      {
        numeric: '008',
        alpha2: 'AL',
        alpha3: 'ALB',
        name: $localize`Albania`,
        continent: Continent.Europe,
      },
      {
        numeric: '012',
        alpha2: 'DZ',
        alpha3: 'DZA',
        name: $localize`Algeria`,
        continent: Continent.Africa,
      },
      {
        numeric: '016',
        alpha2: 'AS',
        alpha3: 'ASM',
        name: $localize`American Samoa`,
        continent: Continent.Oceania,
      },
      {
        numeric: '020',
        alpha2: 'AD',
        alpha3: 'AND',
        name: $localize`Andorra`,
        continent: Continent.Europe,
      },
      {
        numeric: '024',
        alpha2: 'AO',
        alpha3: 'AGO',
        name: $localize`Angola`,
        continent: Continent.Africa,
      },
      {
        numeric: '660',
        alpha2: 'AI',
        alpha3: 'AIA',
        name: $localize`Anguilla`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '010',
        alpha2: 'AQ',
        alpha3: 'ATA',
        name: $localize`Antarctica`,
        continent: $localize`Antarctica`,
      },
      {
        numeric: '028',
        alpha2: 'AG',
        alpha3: 'ATG',
        name: $localize`Antigua and Barbuda`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '032',
        alpha2: 'AR',
        alpha3: 'ARG',
        name: $localize`Argentina`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '051',
        alpha2: 'AM',
        alpha3: 'ARM',
        name: $localize`Armenia`,
        continent: Continent.Asia,
      },
      {
        numeric: '533',
        alpha2: 'AW',
        alpha3: 'ABW',
        name: $localize`Aruba`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '036',
        alpha2: 'AU',
        alpha3: 'AUS',
        name: $localize`Australia`,
        continent: Continent.Oceania,
      },
      {
        numeric: '040',
        alpha2: 'AT',
        alpha3: 'AUT',
        name: $localize`Austria`,
        continent: Continent.Europe,
      },
      {
        numeric: '031',
        alpha2: 'AZ',
        alpha3: 'AZE',
        name: $localize`Azerbaijan`,
        continent: Continent.Asia,
      },
      {
        numeric: '044',
        alpha2: 'BS',
        alpha3: 'BHS',
        name: $localize`Bahamas`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '048',
        alpha2: 'BH',
        alpha3: 'BHR',
        name: $localize`Bahrain`,
        continent: Continent.Asia,
      },
      {
        numeric: '050',
        alpha2: 'BD',
        alpha3: 'BGD',
        name: $localize`Bangladesh`,
        continent: Continent.Asia,
      },
      {
        numeric: '052',
        alpha2: 'BB',
        alpha3: 'BRB',
        name: $localize`Barbados`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '112',
        alpha2: 'BY',
        alpha3: 'BLR',
        name: $localize`Belarus`,
        continent: Continent.Europe,
      },
      {
        numeric: '056',
        alpha2: 'BE',
        alpha3: 'BEL',
        name: $localize`Belgium`,
        continent: Continent.Europe,
      },
      {
        numeric: '084',
        alpha2: 'BZ',
        alpha3: 'BLZ',
        name: $localize`Belize`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '204',
        alpha2: 'BJ',
        alpha3: 'BEN',
        name: $localize`Benin`,
        continent: Continent.Africa,
      },
      {
        numeric: '060',
        alpha2: 'BM',
        alpha3: 'BMU',
        name: $localize`Bermuda`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '064',
        alpha2: 'BT',
        alpha3: 'BTN',
        name: $localize`Bhutan`,
        continent: Continent.Asia,
      },
      {
        numeric: '068',
        alpha2: 'BO',
        alpha3: 'BOL',
        name: $localize`Bolivia (Plurinational State of)`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '535',
        alpha2: 'BQ',
        alpha3: 'BES',
        name: $localize`Bonaire, Sint Eustatius and Saba`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '070',
        alpha2: 'BA',
        alpha3: 'BIH',
        name: $localize`Bosnia and Herzegovina`,
        continent: Continent.Europe,
      },
      {
        numeric: '072',
        alpha2: 'BW',
        alpha3: 'BWA',
        name: $localize`Botswana`,
        continent: Continent.Africa,
      },
      {
        numeric: '074',
        alpha2: 'BV',
        alpha3: 'BVT',
        name: $localize`Bouvet Island`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '076',
        alpha2: 'BR',
        alpha3: 'BRA',
        name: $localize`Brazil`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '086',
        alpha2: 'IO',
        alpha3: 'IOT',
        name: $localize`British Indian Ocean Territory`,
        continent: Continent.Africa,
      },
      {
        numeric: '096',
        alpha2: 'BN',
        alpha3: 'BRN',
        name: $localize`Brunei Darussalam`,
        continent: Continent.Asia,
      },
      {
        numeric: '100',
        alpha2: 'BG',
        alpha3: 'BGR',
        name: $localize`Bulgaria`,
        continent: Continent.Europe,
      },
      {
        numeric: '854',
        alpha2: 'BF',
        alpha3: 'BFA',
        name: $localize`Burkina Faso`,
        continent: Continent.Africa,
      },
      {
        numeric: '108',
        alpha2: 'BI',
        alpha3: 'BDI',
        name: $localize`Burundi`,
        continent: Continent.Africa,
      },
      {
        numeric: '132',
        alpha2: 'CV',
        alpha3: 'CPV',
        name: $localize`Cabo Verde`,
        continent: Continent.Africa,
      },
      {
        numeric: '116',
        alpha2: 'KH',
        alpha3: 'KHM',
        name: $localize`Cambodia`,
        continent: Continent.Asia,
      },
      {
        numeric: '120',
        alpha2: 'CM',
        alpha3: 'CMR',
        name: $localize`Cameroon`,
        continent: Continent.Africa,
      },
      {
        numeric: '124',
        alpha2: 'CA',
        alpha3: 'CAN',
        name: $localize`Canada`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '136',
        alpha2: 'KY',
        alpha3: 'CYM',
        name: $localize`Cayman Islands`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '140',
        alpha2: 'CF',
        alpha3: 'CAF',
        name: $localize`Central African Republic`,
        continent: Continent.Africa,
      },
      {
        numeric: '148',
        alpha2: 'TD',
        alpha3: 'TCD',
        name: $localize`Chad`,
        continent: Continent.Africa,
      },
      {
        numeric: '152',
        alpha2: 'CL',
        alpha3: 'CHL',
        name: $localize`Chile`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '156',
        alpha2: 'CN',
        alpha3: 'CHN',
        name: $localize`China`,
        continent: Continent.Asia,
      },
      {
        numeric: '162',
        alpha2: 'CX',
        alpha3: 'CXR',
        name: $localize`Christmas Island`,
        continent: Continent.Oceania,
      },
      {
        numeric: '166',
        alpha2: 'CC',
        alpha3: 'CCK',
        name: $localize`Cocos (Keeling) Islands`,
        continent: Continent.Oceania,
      },
      {
        numeric: '170',
        alpha2: 'CO',
        alpha3: 'COL',
        name: $localize`Colombia`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '174',
        alpha2: 'KM',
        alpha3: 'COM',
        name: $localize`Comoros`,
        continent: Continent.Africa,
      },
      {
        numeric: '178',
        alpha2: 'CG',
        alpha3: 'COG',
        name: $localize`Congo`,
        continent: Continent.Africa,
      },
      {
        numeric: '180',
        alpha2: 'CD',
        alpha3: 'COD',
        name: $localize`Congo (Democratic Republic of the)`,
        continent: Continent.Africa,
      },
      {
        numeric: '184',
        alpha2: 'CK',
        alpha3: 'COK',
        name: $localize`Cook Islands`,
        continent: Continent.Oceania,
      },
      {
        numeric: '188',
        alpha2: 'CR',
        alpha3: 'CRI',
        name: $localize`Costa Rica`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '384',
        alpha2: 'CI',
        alpha3: 'CIV',
        name: $localize`Côte d'Ivoire`,
        continent: Continent.Africa,
      },
      {
        numeric: '191',
        alpha2: 'HR',
        alpha3: 'HRV',
        name: $localize`Croatia`,
        continent: Continent.Europe,
      },
      {
        numeric: '192',
        alpha2: 'CU',
        alpha3: 'CUB',
        name: $localize`Cuba`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '531',
        alpha2: 'CW',
        alpha3: 'CUW',
        name: $localize`Curaçao`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '196',
        alpha2: 'CY',
        alpha3: 'CYP',
        name: $localize`Cyprus`,
        continent: Continent.Asia,
      },
      {
        numeric: '203',
        alpha2: 'CZ',
        alpha3: 'CZE',
        name: $localize`Czechia`,
        continent: Continent.Europe,
      },
      {
        numeric: '208',
        alpha2: 'DK',
        alpha3: 'DNK',
        name: $localize`Denmark`,
        continent: Continent.Europe,
      },
      {
        numeric: '262',
        alpha2: 'DJ',
        alpha3: 'DJI',
        name: $localize`Djibouti`,
        continent: Continent.Africa,
      },
      {
        numeric: '212',
        alpha2: 'DM',
        alpha3: 'DMA',
        name: $localize`Dominica`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '214',
        alpha2: 'DO',
        alpha3: 'DOM',
        name: $localize`Dominican Republic`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '218',
        alpha2: 'EC',
        alpha3: 'ECU',
        name: $localize`Ecuador`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '818',
        alpha2: 'EG',
        alpha3: 'EGY',
        name: $localize`Egypt`,
        continent: Continent.Africa,
      },
      {
        numeric: '222',
        alpha2: 'SV',
        alpha3: 'SLV',
        name: $localize`El Salvador`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '226',
        alpha2: 'GQ',
        alpha3: 'GNQ',
        name: $localize`Equatorial Guinea`,
        continent: Continent.Africa,
      },
      {
        numeric: '232',
        alpha2: 'ER',
        alpha3: 'ERI',
        name: $localize`Eritrea`,
        continent: Continent.Africa,
      },
      {
        numeric: '233',
        alpha2: 'EE',
        alpha3: 'EST',
        name: $localize`Estonia`,
        continent: Continent.Europe,
      },
      {
        numeric: '231',
        alpha2: 'ET',
        alpha3: 'ETH',
        name: $localize`Ethiopia`,
        continent: Continent.Africa,
      },
      {
        numeric: '238',
        alpha2: 'FK',
        alpha3: 'FLK',
        name: $localize`Falkland Islands (Malvinas)`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '234',
        alpha2: 'FO',
        alpha3: 'FRO',
        name: $localize`Faroe Islands`,
        continent: Continent.Europe,
      },
      {
        numeric: '242',
        alpha2: 'FJ',
        alpha3: 'FJI',
        name: $localize`Fiji`,
        continent: Continent.Oceania,
      },
      {
        numeric: '246',
        alpha2: 'FI',
        alpha3: 'FIN',
        name: $localize`Finland`,
        continent: Continent.Europe,
      },
      {
        numeric: '250',
        alpha2: 'FR',
        alpha3: 'FRA',
        name: $localize`France`,
        continent: Continent.Europe,
      },
      {
        numeric: '254',
        alpha2: 'GF',
        alpha3: 'GUF',
        name: $localize`French Guiana`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '258',
        alpha2: 'PF',
        alpha3: 'PYF',
        name: $localize`French Polynesia`,
        continent: Continent.Oceania,
      },
      {
        numeric: '260',
        alpha2: 'TF',
        alpha3: 'ATF',
        name: $localize`French Southern Territories`,
        continent: Continent.Africa,
      },
      {
        numeric: '266',
        alpha2: 'GA',
        alpha3: 'GAB',
        name: $localize`Gabon`,
        continent: Continent.Africa,
      },
      {
        numeric: '270',
        alpha2: 'GM',
        alpha3: 'GMB',
        name: $localize`Gambia`,
        continent: Continent.Africa,
      },
      {
        numeric: '268',
        alpha2: 'GE',
        alpha3: 'GEO',
        name: $localize`Georgia`,
        continent: Continent.Asia,
      },
      {
        numeric: '276',
        alpha2: 'DE',
        alpha3: 'DEU',
        name: $localize`Germany`,
        continent: Continent.Europe,
      },
      {
        numeric: '288',
        alpha2: 'GH',
        alpha3: 'GHA',
        name: $localize`Ghana`,
        continent: Continent.Africa,
      },
      {
        numeric: '292',
        alpha2: 'GI',
        alpha3: 'GIB',
        name: $localize`Gibraltar`,
        continent: Continent.Europe,
      },
      {
        numeric: '300',
        alpha2: 'GR',
        alpha3: 'GRC',
        name: $localize`Greece`,
        continent: Continent.Europe,
      },
      {
        numeric: '304',
        alpha2: 'GL',
        alpha3: 'GRL',
        name: $localize`Greenland`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '308',
        alpha2: 'GD',
        alpha3: 'GRD',
        name: $localize`Grenada`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '312',
        alpha2: 'GP',
        alpha3: 'GLP',
        name: $localize`Guadeloupe`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '316',
        alpha2: 'GU',
        alpha3: 'GUM',
        name: $localize`Guam`,
        continent: Continent.Oceania,
      },
      {
        numeric: '320',
        alpha2: 'GT',
        alpha3: 'GTM',
        name: $localize`Guatemala`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '831',
        alpha2: 'GG',
        alpha3: 'GGY',
        name: $localize`Guernsey`,
        continent: Continent.Europe,
      },
      {
        numeric: '324',
        alpha2: 'GN',
        alpha3: 'GIN',
        name: $localize`Guinea`,
        continent: Continent.Africa,
      },
      {
        numeric: '624',
        alpha2: 'GW',
        alpha3: 'GNB',
        name: $localize`Guinea-Bissau`,
        continent: Continent.Africa,
      },
      {
        numeric: '328',
        alpha2: 'GY',
        alpha3: 'GUY',
        name: $localize`Guyana`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '332',
        alpha2: 'HT',
        alpha3: 'HTI',
        name: $localize`Haiti`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '334',
        alpha2: 'HM',
        alpha3: 'HMD',
        name: $localize`Heard Island and McDonald Islands`,
        continent: Continent.Oceania,
      },
      {
        numeric: '336',
        alpha2: 'VA',
        alpha3: 'VAT',
        name: $localize`Holy See`,
        continent: Continent.Europe,
      },
      {
        numeric: '340',
        alpha2: 'HN',
        alpha3: 'HND',
        name: $localize`Honduras`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '344',
        alpha2: 'HK',
        alpha3: 'HKG',
        name: $localize`Hong Kong`,
        continent: Continent.Asia,
      },
      {
        numeric: '348',
        alpha2: 'HU',
        alpha3: 'HUN',
        name: $localize`Hungary`,
        continent: Continent.Europe,
      },
      {
        numeric: '352',
        alpha2: 'IS',
        alpha3: 'ISL',
        name: $localize`Iceland`,
        continent: Continent.Europe,
      },
      {
        numeric: '356',
        alpha2: 'IN',
        alpha3: 'IND',
        name: $localize`India`,
        continent: Continent.Asia,
      },
      {
        numeric: '360',
        alpha2: 'ID',
        alpha3: 'IDN',
        name: $localize`Indonesia`,
        continent: Continent.Asia,
      },
      {
        numeric: '364',
        alpha2: 'IR',
        alpha3: 'IRN',
        name: $localize`Iran (Islamic Republic of)`,
        continent: Continent.Asia,
      },
      {
        numeric: '368',
        alpha2: 'IQ',
        alpha3: 'IRQ',
        name: $localize`Iraq`,
        continent: Continent.Asia,
      },
      {
        numeric: '372',
        alpha2: 'IE',
        alpha3: 'IRL',
        name: $localize`Ireland`,
        continent: Continent.Europe,
      },
      {
        numeric: '833',
        alpha2: 'IM',
        alpha3: 'IMN',
        name: $localize`Isle of Man`,
        continent: Continent.Europe,
      },
      {
        numeric: '376',
        alpha2: 'IL',
        alpha3: 'ISR',
        name: $localize`Israel`,
        continent: Continent.Asia,
      },
      {
        numeric: '380',
        alpha2: 'IT',
        alpha3: 'ITA',
        name: $localize`Italy`,
        continent: Continent.Europe,
      },
      {
        numeric: '388',
        alpha2: 'JM',
        alpha3: 'JAM',
        name: $localize`Jamaica`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '392',
        alpha2: 'JP',
        alpha3: 'JPN',
        name: $localize`Japan`,
        continent: Continent.Asia,
      },
      {
        numeric: '832',
        alpha2: 'JE',
        alpha3: 'JEY',
        name: $localize`Jersey`,
        continent: Continent.Europe,
      },
      {
        numeric: '400',
        alpha2: 'JO',
        alpha3: 'JOR',
        name: $localize`Jordan`,
        continent: Continent.Asia,
      },
      {
        numeric: '398',
        alpha2: 'KZ',
        alpha3: 'KAZ',
        name: $localize`Kazakhstan`,
        continent: Continent.Asia,
      },
      {
        numeric: '404',
        alpha2: 'KE',
        alpha3: 'KEN',
        name: $localize`Kenya`,
        continent: Continent.Africa,
      },
      {
        numeric: '296',
        alpha2: 'KI',
        alpha3: 'KIR',
        name: $localize`Kiribati`,
        continent: Continent.Oceania,
      },
      {
        numeric: '408',
        alpha2: 'KP',
        alpha3: 'PRK',
        name: $localize`Korea (Democratic People's Republic of)`,
        continent: Continent.Asia,
      },
      {
        numeric: '410',
        alpha2: 'KR',
        alpha3: 'KOR',
        name: $localize`Korea (Republic of)`,
        continent: Continent.Asia,
      },
      {
        numeric: '414',
        alpha2: 'KW',
        alpha3: 'KWT',
        name: $localize`Kuwait`,
        continent: Continent.Asia,
      },
      {
        numeric: '417',
        alpha2: 'KG',
        alpha3: 'KGZ',
        name: $localize`Kyrgyzstan`,
        continent: Continent.Asia,
      },
      {
        numeric: '418',
        alpha2: 'LA',
        alpha3: 'LAO',
        name: $localize`Lao People's Democratic Republic`,
        continent: Continent.Asia,
      },
      {
        numeric: '428',
        alpha2: 'LV',
        alpha3: 'LVA',
        name: $localize`Latvia`,
        continent: Continent.Europe,
      },
      {
        numeric: '422',
        alpha2: 'LB',
        alpha3: 'LBN',
        name: $localize`Lebanon`,
        continent: Continent.Asia,
      },
      {
        numeric: '426',
        alpha2: 'LS',
        alpha3: 'LSO',
        name: $localize`Lesotho`,
        continent: Continent.Africa,
      },
      {
        numeric: '430',
        alpha2: 'LR',
        alpha3: 'LBR',
        name: $localize`Liberia`,
        continent: Continent.Africa,
      },
      {
        numeric: '434',
        alpha2: 'LY',
        alpha3: 'LBY',
        name: $localize`Libya`,
        continent: Continent.Africa,
      },
      {
        numeric: '438',
        alpha2: 'LI',
        alpha3: 'LIE',
        name: $localize`Liechtenstein`,
        continent: Continent.Europe,
      },
      {
        numeric: '440',
        alpha2: 'LT',
        alpha3: 'LTU',
        name: $localize`Lithuania`,
        continent: Continent.Europe,
      },
      {
        numeric: '442',
        alpha2: 'LU',
        alpha3: 'LUX',
        name: $localize`Luxembourg`,
        continent: Continent.Europe,
      },
      {
        numeric: '446',
        alpha2: 'MO',
        alpha3: 'MAC',
        name: $localize`Macao`,
        continent: Continent.Asia,
      },
      {
        numeric: '807',
        alpha2: 'MK',
        alpha3: 'MKD',
        name: $localize`Macedonia (the former Yugoslav Republic of)`,
        continent: Continent.Europe,
      },
      {
        numeric: '450',
        alpha2: 'MG',
        alpha3: 'MDG',
        name: $localize`Madagascar`,
        continent: Continent.Africa,
      },
      {
        numeric: '454',
        alpha2: 'MW',
        alpha3: 'MWI',
        name: $localize`Malawi`,
        continent: Continent.Africa,
      },
      {
        numeric: '458',
        alpha2: 'MY',
        alpha3: 'MYS',
        name: $localize`Malaysia`,
        continent: Continent.Asia,
      },
      {
        numeric: '462',
        alpha2: 'MV',
        alpha3: 'MDV',
        name: $localize`Maldives`,
        continent: Continent.Asia,
      },
      {
        numeric: '466',
        alpha2: 'ML',
        alpha3: 'MLI',
        name: $localize`Mali`,
        continent: Continent.Africa,
      },
      {
        numeric: '470',
        alpha2: 'MT',
        alpha3: 'MLT',
        name: $localize`Malta`,
        continent: Continent.Europe,
      },
      {
        numeric: '584',
        alpha2: 'MH',
        alpha3: 'MHL',
        name: $localize`Marshall Islands`,
        continent: Continent.Oceania,
      },
      {
        numeric: '474',
        alpha2: 'MQ',
        alpha3: 'MTQ',
        name: $localize`Martinique`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '478',
        alpha2: 'MR',
        alpha3: 'MRT',
        name: $localize`Mauritania`,
        continent: Continent.Africa,
      },
      {
        numeric: '480',
        alpha2: 'MU',
        alpha3: 'MUS',
        name: $localize`Mauritius`,
        continent: Continent.Africa,
      },
      {
        numeric: '175',
        alpha2: 'YT',
        alpha3: 'MYT',
        name: $localize`Mayotte`,
        continent: Continent.Africa,
      },
      {
        numeric: '484',
        alpha2: 'MX',
        alpha3: 'MEX',
        name: $localize`Mexico`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '583',
        alpha2: 'FM',
        alpha3: 'FSM',
        name: $localize`Micronesia (Federated States of)`,
        continent: Continent.Oceania,
      },
      {
        numeric: '498',
        alpha2: 'MD',
        alpha3: 'MDA',
        name: $localize`Moldova (Republic of)`,
        continent: Continent.Europe,
      },
      {
        numeric: '492',
        alpha2: 'MC',
        alpha3: 'MCO',
        name: $localize`Monaco`,
        continent: Continent.Europe,
      },
      {
        numeric: '496',
        alpha2: 'MN',
        alpha3: 'MNG',
        name: $localize`Mongolia`,
        continent: Continent.Asia,
      },
      {
        numeric: '499',
        alpha2: 'ME',
        alpha3: 'MNE',
        name: $localize`Montenegro`,
        continent: Continent.Europe,
      },
      {
        numeric: '500',
        alpha2: 'MS',
        alpha3: 'MSR',
        name: $localize`Montserrat`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '504',
        alpha2: 'MA',
        alpha3: 'MAR',
        name: $localize`Morocco`,
        continent: Continent.Africa,
      },
      {
        numeric: '508',
        alpha2: 'MZ',
        alpha3: 'MOZ',
        name: $localize`Mozambique`,
        continent: Continent.Africa,
      },
      {
        numeric: '104',
        alpha2: 'MM',
        alpha3: 'MMR',
        name: $localize`Myanmar`,
        continent: Continent.Asia,
      },
      {
        numeric: '516',
        alpha2: 'NA',
        alpha3: 'NAM',
        name: $localize`Namibia`,
        continent: Continent.Africa,
      },
      {
        numeric: '520',
        alpha2: 'NR',
        alpha3: 'NRU',
        name: $localize`Nauru`,
        continent: Continent.Oceania,
      },
      {
        numeric: '524',
        alpha2: 'NP',
        alpha3: 'NPL',
        name: $localize`Nepal`,
        continent: Continent.Asia,
      },
      {
        numeric: '528',
        alpha2: 'NL',
        alpha3: 'NLD',
        name: $localize`Netherlands`,
        continent: Continent.Europe,
      },
      {
        numeric: '540',
        alpha2: 'NC',
        alpha3: 'NCL',
        name: $localize`New Caledonia`,
        continent: Continent.Oceania,
      },
      {
        numeric: '554',
        alpha2: 'NZ',
        alpha3: 'NZL',
        name: $localize`New Zealand`,
        continent: Continent.Oceania,
      },
      {
        numeric: '558',
        alpha2: 'NI',
        alpha3: 'NIC',
        name: $localize`Nicaragua`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '562',
        alpha2: 'NE',
        alpha3: 'NER',
        name: $localize`Niger`,
        continent: Continent.Africa,
      },
      {
        numeric: '566',
        alpha2: 'NG',
        alpha3: 'NGA',
        name: $localize`Nigeria`,
        continent: Continent.Africa,
      },
      {
        numeric: '570',
        alpha2: 'NU',
        alpha3: 'NIU',
        name: $localize`Niue`,
        continent: Continent.Oceania,
      },
      {
        numeric: '574',
        alpha2: 'NF',
        alpha3: 'NFK',
        name: $localize`Norfolk Island`,
        continent: Continent.Oceania,
      },
      {
        numeric: '580',
        alpha2: 'MP',
        alpha3: 'MNP',
        name: $localize`Northern Mariana Islands`,
        continent: Continent.Oceania,
      },
      {
        numeric: '578',
        alpha2: 'NO',
        alpha3: 'NOR',
        name: $localize`Norway`,
        continent: Continent.Europe,
      },
      {
        numeric: '512',
        alpha2: 'OM',
        alpha3: 'OMN',
        name: $localize`Oman`,
        continent: Continent.Asia,
      },
      {
        numeric: '586',
        alpha2: 'PK',
        alpha3: 'PAK',
        name: $localize`Pakistan`,
        continent: Continent.Asia,
      },
      {
        numeric: '585',
        alpha2: 'PW',
        alpha3: 'PLW',
        name: $localize`Palau`,
        continent: Continent.Oceania,
      },
      {
        numeric: '275',
        alpha2: 'PS',
        alpha3: 'PSE',
        name: $localize`Palestine, State of`,
        continent: Continent.Asia,
      },
      {
        numeric: '591',
        alpha2: 'PA',
        alpha3: 'PAN',
        name: $localize`Panama`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '598',
        alpha2: 'PG',
        alpha3: 'PNG',
        name: $localize`Papua New Guinea`,
        continent: Continent.Oceania,
      },
      {
        numeric: '600',
        alpha2: 'PY',
        alpha3: 'PRY',
        name: $localize`Paraguay`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '604',
        alpha2: 'PE',
        alpha3: 'PER',
        name: $localize`Peru`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '608',
        alpha2: 'PH',
        alpha3: 'PHL',
        name: $localize`Philippines`,
        continent: Continent.Asia,
      },
      {
        numeric: '612',
        alpha2: 'PN',
        alpha3: 'PCN',
        name: $localize`Pitcairn`,
        continent: Continent.Oceania,
      },
      {
        numeric: '616',
        alpha2: 'PL',
        alpha3: 'POL',
        name: $localize`Poland`,
        continent: Continent.Europe,
      },
      {
        numeric: '620',
        alpha2: 'PT',
        alpha3: 'PRT',
        name: $localize`Portugal`,
        continent: Continent.Europe,
      },
      {
        numeric: '630',
        alpha2: 'PR',
        alpha3: 'PRI',
        name: $localize`Puerto Rico`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '634',
        alpha2: 'QA',
        alpha3: 'QAT',
        name: $localize`Qatar`,
        continent: Continent.Asia,
      },
      {
        numeric: '638',
        alpha2: 'RE',
        alpha3: 'REU',
        name: $localize`Réunion`,
        continent: Continent.Africa,
      },
      {
        numeric: '642',
        alpha2: 'RO',
        alpha3: 'ROU',
        name: $localize`Romania`,
        continent: Continent.Europe,
      },
      {
        numeric: '643',
        alpha2: 'RU',
        alpha3: 'RUS',
        name: $localize`Russian Federation`,
        continent: Continent.Europe,
      },
      {
        numeric: '646',
        alpha2: 'RW',
        alpha3: 'RWA',
        name: $localize`Rwanda`,
        continent: Continent.Africa,
      },
      {
        numeric: '652',
        alpha2: 'BL',
        alpha3: 'BLM',
        name: $localize`Saint Barthélemy`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '654',
        alpha2: 'SH',
        alpha3: 'SHN',
        name: $localize`Saint Helena, Ascension and Tristan da Cunha`,
        continent: Continent.Africa,
      },
      {
        numeric: '659',
        alpha2: 'KN',
        alpha3: 'KNA',
        name: $localize`Saint Kitts and Nevis`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '662',
        alpha2: 'LC',
        alpha3: 'LCA',
        name: $localize`Saint Lucia`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '663',
        alpha2: 'MF',
        alpha3: 'MAF',
        name: $localize`Saint Martin (French part)`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '666',
        alpha2: 'PM',
        alpha3: 'SPM',
        name: $localize`Saint Pierre and Miquelon`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '670',
        alpha2: 'VC',
        alpha3: 'VCT',
        name: $localize`Saint Vincent and the Grenadines`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '882',
        alpha2: 'WS',
        alpha3: 'WSM',
        name: $localize`Samoa`,
        continent: Continent.Oceania,
      },
      {
        numeric: '674',
        alpha2: 'SM',
        alpha3: 'SMR',
        name: $localize`San Marino`,
        continent: Continent.Europe,
      },
      {
        numeric: '678',
        alpha2: 'ST',
        alpha3: 'STP',
        name: $localize`Sao Tome and Principe`,
        continent: Continent.Africa,
      },
      {
        numeric: '682',
        alpha2: 'SA',
        alpha3: 'SAU',
        name: $localize`Saudi Arabia`,
        continent: Continent.Asia,
      },
      {
        numeric: '686',
        alpha2: 'SN',
        alpha3: 'SEN',
        name: $localize`Senegal`,
        continent: Continent.Africa,
      },
      {
        numeric: '688',
        alpha2: 'RS',
        alpha3: 'SRB',
        name: $localize`Serbia`,
        continent: Continent.Europe,
      },
      {
        numeric: '690',
        alpha2: 'SC',
        alpha3: 'SYC',
        name: $localize`Seychelles`,
        continent: Continent.Africa,
      },
      {
        numeric: '694',
        alpha2: 'SL',
        alpha3: 'SLE',
        name: $localize`Sierra Leone`,
        continent: Continent.Africa,
      },
      {
        numeric: '702',
        alpha2: 'SG',
        alpha3: 'SGP',
        name: $localize`Singapore`,
        continent: Continent.Asia,
      },
      {
        numeric: '534',
        alpha2: 'SX',
        alpha3: 'SXM',
        name: $localize`Sint Maarten (Dutch part)`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '703',
        alpha2: 'SK',
        alpha3: 'SVK',
        name: $localize`Slovakia`,
        continent: Continent.Europe,
      },
      {
        numeric: '705',
        alpha2: 'SI',
        alpha3: 'SVN',
        name: $localize`Slovenia`,
        continent: Continent.Europe,
      },
      {
        numeric: '090',
        alpha2: 'SB',
        alpha3: 'SLB',
        name: $localize`Solomon Islands`,
        continent: Continent.Oceania,
      },
      {
        numeric: '706',
        alpha2: 'SO',
        alpha3: 'SOM',
        name: $localize`Somalia`,
        continent: Continent.Africa,
      },
      {
        numeric: '710',
        alpha2: 'ZA',
        alpha3: 'ZAF',
        name: $localize`South Africa`,
        continent: Continent.Africa,
      },
      {
        numeric: '239',
        alpha2: 'GS',
        alpha3: 'SGS',
        name: $localize`South Georgia and the South Sandwich Islands`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '728',
        alpha2: 'SS',
        alpha3: 'SSD',
        name: $localize`South Sudan`,
        continent: Continent.Africa,
      },
      {
        numeric: '724',
        alpha2: 'ES',
        alpha3: 'ESP',
        name: $localize`Spain`,
        continent: Continent.Europe,
      },
      {
        numeric: '144',
        alpha2: 'LK',
        alpha3: 'LKA',
        name: $localize`Sri Lanka`,
        continent: Continent.Asia,
      },
      {
        numeric: '729',
        alpha2: 'SD',
        alpha3: 'SDN',
        name: $localize`Sudan`,
        continent: Continent.Africa,
      },
      {
        numeric: '740',
        alpha2: 'SR',
        alpha3: 'SUR',
        name: $localize`Suriname`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '744',
        alpha2: 'SJ',
        alpha3: 'SJM',
        name: $localize`Svalbard and Jan Mayen`,
        continent: Continent.Europe,
      },
      {
        numeric: '748',
        alpha2: 'SZ',
        alpha3: 'SWZ',
        name: $localize`Eswatini`,
        continent: Continent.Africa,
      },
      {
        numeric: '752',
        alpha2: 'SE',
        alpha3: 'SWE',
        name: $localize`Sweden`,
        continent: Continent.Europe,
      },
      {
        numeric: '756',
        alpha2: 'CH',
        alpha3: 'CHE',
        name: $localize`Switzerland`,
        continent: Continent.Europe,
      },
      {
        numeric: '760',
        alpha2: 'SY',
        alpha3: 'SYR',
        name: $localize`Syrian Arab Republic`,
        continent: Continent.Asia,
      },
      {
        numeric: '158',
        alpha2: 'TW',
        alpha3: 'TWN',
        name: $localize`Taiwan, Province of China[a]`,
        continent: Continent.Asia,
      },
      {
        numeric: '762',
        alpha2: 'TJ',
        alpha3: 'TJK',
        name: $localize`Tajikistan`,
        continent: Continent.Asia,
      },
      {
        numeric: '834',
        alpha2: 'TZ',
        alpha3: 'TZA',
        name: $localize`Tanzania, United Republic of`,
        continent: Continent.Africa,
      },
      {
        numeric: '764',
        alpha2: 'TH',
        alpha3: 'THA',
        name: $localize`Thailand`,
        continent: Continent.Asia,
      },
      {
        numeric: '626',
        alpha2: 'TL',
        alpha3: 'TLS',
        name: $localize`Timor-Leste`,
        continent: Continent.Asia,
      },
      {
        numeric: '768',
        alpha2: 'TG',
        alpha3: 'TGO',
        name: $localize`Togo`,
        continent: Continent.Africa,
      },
      {
        numeric: '772',
        alpha2: 'TK',
        alpha3: 'TKL',
        name: $localize`Tokelau`,
        continent: Continent.Oceania,
      },
      {
        numeric: '776',
        alpha2: 'TO',
        alpha3: 'TON',
        name: $localize`Tonga`,
        continent: Continent.Oceania,
      },
      {
        numeric: '780',
        alpha2: 'TT',
        alpha3: 'TTO',
        name: $localize`Trinidad and Tobago`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '788',
        alpha2: 'TN',
        alpha3: 'TUN',
        name: $localize`Tunisia`,
        continent: Continent.Africa,
      },
      {
        numeric: '792',
        alpha2: 'TR',
        alpha3: 'TUR',
        name: $localize`Turkey`,
        continent: Continent.Asia,
      },
      {
        numeric: '795',
        alpha2: 'TM',
        alpha3: 'TKM',
        name: $localize`Turkmenistan`,
        continent: Continent.Asia,
      },
      {
        numeric: '796',
        alpha2: 'TC',
        alpha3: 'TCA',
        name: $localize`Turks and Caicos Islands`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '798',
        alpha2: 'TV',
        alpha3: 'TUV',
        name: $localize`Tuvalu`,
        continent: Continent.Oceania,
      },
      {
        numeric: '800',
        alpha2: 'UG',
        alpha3: 'UGA',
        name: $localize`Uganda`,
        continent: Continent.Africa,
      },
      {
        numeric: '804',
        alpha2: 'UA',
        alpha3: 'UKR',
        name: $localize`Ukraine`,
        continent: Continent.Europe,
      },
      {
        numeric: '784',
        alpha2: 'AE',
        alpha3: 'ARE',
        name: $localize`United Arab Emirates`,
        continent: Continent.Asia,
      },
      {
        numeric: '826',
        alpha2: 'GB',
        alpha3: 'GBR',
        name: $localize`United Kingdom of Great Britain and Northern Ireland`,
        continent: Continent.Europe,
      },
      {
        numeric: '840',
        alpha2: 'US',
        alpha3: 'USA',
        name: $localize`United States of America`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '581',
        alpha2: 'UM',
        alpha3: 'UMI',
        name: $localize`United States Minor Outlying Islands`,
        continent: Continent.Oceania,
      },
      {
        numeric: '858',
        alpha2: 'UY',
        alpha3: 'URY',
        name: $localize`Uruguay`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '860',
        alpha2: 'UZ',
        alpha3: 'UZB',
        name: $localize`Uzbekistan`,
        continent: Continent.Asia,
      },
      {
        numeric: '548',
        alpha2: 'VU',
        alpha3: 'VUT',
        name: $localize`Vanuatu`,
        continent: Continent.Oceania,
      },
      {
        numeric: '862',
        alpha2: 'VE',
        alpha3: 'VEN',
        name: $localize`Venezuela (Bolivarian Republic of venezuela)`,
        continent: Continent.SouthAmerica,
      },
      {
        numeric: '704',
        alpha2: 'VN',
        alpha3: 'VNM',
        name: $localize`Viet Nam`,
        continent: Continent.Asia,
      },
      {
        numeric: '092',
        alpha2: 'VG',
        alpha3: 'VGB',
        name: $localize`Virgin Islands (British)`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '850',
        alpha2: 'VI',
        alpha3: 'VIR',
        name: $localize`Virgin Islands (U.S.)`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '876',
        alpha2: 'WF',
        alpha3: 'WLF',
        name: $localize`Wallis and Futuna`,
        continent: Continent.Oceania,
      },
      {
        numeric: '732',
        alpha2: 'EH',
        alpha3: 'ESH',
        name: $localize`Western Sahara`,
        continent: Continent.Africa,
      },
      {
        numeric: '887',
        alpha2: 'YE',
        alpha3: 'YEM',
        name: $localize`Yemen`,
        continent: Continent.Asia,
      },
      {
        numeric: '894',
        alpha2: 'ZM',
        alpha3: 'ZMB',
        name: $localize`Zambia`,
        continent: Continent.Africa,
      },
      {
        numeric: '716',
        alpha2: 'ZW',
        alpha3: 'ZWE',
        name: $localize`Zimbabwe`,
        continent: Continent.Africa,
      },
    ],
    ['name'],
  );

  countriesAlpha2 = new Map<string, ICountry>(
    this.countries.map((country: ICountry) => {
      return [country.alpha2, country];
    }),
  );

  countriesAlpha3 = new Map<string, ICountry>(
    this.countries.map((country: ICountry) => {
      return [country.alpha3, country];
    }),
  );

  klarnaProviderBillingCountries: ICountry[] = sortBy(
    [
      {
        numeric: '840',
        alpha2: 'US',
        alpha3: 'USA',
        name: $localize`United States of America`,
        continent: Continent.NorthAmerica,
      },
      {
        numeric: '578',
        alpha2: 'NO',
        alpha3: 'NOR',
        name: $localize`Norway`,
        continent: Continent.Europe,
      },
      {
        numeric: '752',
        alpha2: 'SE',
        alpha3: 'SWE',
        name: $localize`Sweden`,
        continent: Continent.Europe,
      },
      {
        numeric: '246',
        alpha2: 'FI',
        alpha3: 'FIN',
        name: $localize`Finland`,
        continent: Continent.Europe,
      },

      {
        numeric: '208',
        alpha2: 'DK',
        alpha3: 'DNK',
        name: $localize`Denmark`,
        continent: Continent.Europe,
      },
    ],
    ['name'],
  );
}
