import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { sortBy } from 'lodash';
import { TransactionReportType } from '../enum/transaction-report-type.enum';

declare const $localize;
@Injectable()
export class TransactionReportTypesNew extends SelectInputsList {
  keyValue = {
    [TransactionReportType.Basic]: $localize`:transaction report type|:Transaction report - Basic`,
    [TransactionReportType.Full]: $localize`:transaction report type|:Transaction report - Full`,
    [TransactionReportType.Custom]: $localize`:transaction report type|:Transaction report - Custom`,
  };
  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);

  constructor() {
    super();
  }
}
