import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@portal/shared/pipes';
import { InstallsIndicatorModule } from '../../shared/installs-indicator/installs-indicator.module';
import { UpdatesIndicatorModule } from '../../shared/updates-indicator/updates-indicator.module';
import { AppLogoTemplateComponent } from './app-logo-template.component';

@NgModule({
  imports: [CommonModule, InstallsIndicatorModule, UpdatesIndicatorModule, PipesModule],
  exports: [AppLogoTemplateComponent],
  declarations: [AppLogoTemplateComponent],
})
export class AppLogoTemplateModule {}
