import { Component, Input } from '@angular/core';

@Component({
  selector: 'vui-uis-auth-page-content',
  templateUrl: './auth-page-content.component.html',
  styleUrls: ['./auth-page-content.component.scss'],
})
export class AuthPageContentComponent {
  @Input() title: string;
  @Input() showHeader: boolean;
  @Input() showFooter: boolean;
}
