export enum HttpError {
  CodeSucceeded200 = 200, // 200 - OK. The client request has succeeded.
  CodeError400 = 400, // 400 - Bad request.
  CodeError401 = 401, // 401 - Access denied.
  CodeError403 = 403, // 403 - Access Forbidden.
  CodeError404 = 404, // 404 - Page not found.
  CodeError417 = 417, // 417 - Expectation Failed
  CodeError500 = 500, // 500 - Internal server error
  CodeError503 = 503, // 503 - Service unavailable,
  CodeError504 = 504, // 504 - Timeout
  CodeError303 = 303,
}
