import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComparableDateRangeComponent } from './comparable-date-range.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ComparableDateService } from './services/date.service';
import { DatePickerModule } from '@portal/shared/ui/date-picker/src';
import { SharedUiFilterModule } from '@portal/shared/ui/filter/src';

@NgModule({
  imports: [CommonModule, NgbDatepickerModule, DatePickerModule, SharedUiFilterModule],
  declarations: [ComparableDateRangeComponent, DateRangeComponent],
  exports: [ComparableDateRangeComponent],
  providers: [ComparableDateService],
})
export class ComparableDateRangeModule {}
