<form class="wl-form card" [formGroup]="form" data-e2e="white-labeling-editor">
  <div class="wl-editor-title" data-e2e="white-labeling-editor-title">
    <h2 class="title is-3 has-text-left mb-0">
      <span i18n>Settings</span>
    </h2>
  </div>
  <hr class="dropdown-divider" />
  <div class="box is-flex is-flex-direction-column wl-editor-options">
    <portal-accordion-wrapper data-e2e="accordion-wrapper">
      <portal-accordion
        #logoAccordion
        title="Logo"
        (click)="logoTabClicked($event)"
        (keydown.enter)="logoTabClicked($event)"
        (keydown.tab)="onTabKeyDownOnLogoHeader($event)"
        i18n-title
        data-e2e="logo-accordion"
      >
        <ng-container *ngIf="logoTabActive">
          <portal-file-input
            [formControl]="logo"
            [imgSrc]="logoSrc"
            (fileChanged)="onLogoLoaded($event)"
            acceptableFileTypes=".png"
            title="Drag & drop your logo here"
            i18n-title
            data-e2e="logo-file-input"
          ></portal-file-input>

          <div class="mt-2" data-e2e="logo-supported-format">
            <span i18n>Supported format(s): </span><span>.png</span>
          </div>
          <div data-e2e="logo-recommended-resolution">
            <span i18n>Recommended resolution: </span><span>200x38 px</span>
          </div>
        </ng-container>
      </portal-accordion>

      <portal-accordion
        #favIconAccordion
        title="Favicon"
        (click)="favIconTabClicked($event)"
        (keydown.enter)="favIconTabClicked($event)"
        (keydown)="onKeyDownOnFavIconHeader($event)"
        i18n-title
        data-e2e="favicon-accordion"
      >
        <ng-container *ngIf="favIconTabActive">
          <portal-file-input
            [formControl]="favicon"
            [imgSrc]="faviconSrc"
            (fileChanged)="onFaviconLoaded($event)"
            acceptableFileTypes=".png"
            title="Drag & drop your favicon here"
            i18n-title
            data-e2e="favicon-file-input"
          ></portal-file-input>

          <div class="mt-2" data-e2e="favicon-supported-format">
            <span i18n>Supported format(s): </span> <span>.png</span>
          </div>
          <div data-e2e="favicon-recommended-resolution">
            <span i18n>Recommended resolution: </span> <span>16x16 px</span>
          </div>
        </ng-container>
      </portal-accordion>

      <portal-accordion
        #typographyAccordion
        title="Typography"
        (click)="typographyTabClicked($event)"
        (keydown.enter)="typographyTabClicked($event)"
        (keydown)="onKeyDownOnTypographyHeader($event)"
        i18n-title
        data-e2e="typography-accordion"
      >
        <ng-container *ngIf="typographyTabActive">
          <portal-select-control
            class="width-full"
            [control]="font"
            [options]="typographyList"
            placeholder="Select font"
            i18n-placeholder
            data-e2e="typography-select"
          ></portal-select-control>
        </ng-container>
      </portal-accordion>

      <portal-accordion
        #navBarAccordion
        title="Navigation bar"
        (click)="navBarTabClicked($event)"
        (keydown.enter)="navBarTabClicked($event)"
        (keydown)="onKeyDownOnNavBarHeader($event)"
        i18n-title
        data-e2e="navbar-accordion"
      >
        <ng-container *ngIf="navBarTabActive">
          <div class="has-text-left pb-3" data-e2e="navbar-background-color">
            <div class="pb-1" i18n>Background color</div>
            <portal-color-picker [formControl]="navbarBackgroundColor"></portal-color-picker>
          </div>

          <div class="has-text-left" data-e2e="navbar-font-color">
            <div class="pb-1" i18n>Font color</div>
            <portal-color-picker [formControl]="navbarItemColor"></portal-color-picker>
          </div>
        </ng-container>
      </portal-accordion>

      <portal-accordion
        #buttonsAccordion
        title="Buttons"
        (click)="buttonsTabClicked($event)"
        (keydown.enter)="buttonsTabClicked($event)"
        (keydown)="onKeyDownOnButtonsHeader($event)"
        i18n-title
        data-e2e="buttons-accordion"
      >
        <ng-container *ngIf="buttonsTabActive">
          <div class="title is-6 mb-3 mt-1" i18n data-e2e="primary-button-title">
            Primary button
          </div>
          <div class="has-text-left pb-3" data-e2e="primary-button-background-color">
            <div class="pb-1" i18n>Background color</div>
            <portal-color-picker [formControl]="brandBackground"></portal-color-picker>
          </div>

          <div class="has-text-left pb-6" data-e2e="primary-button-font-color">
            <div class="pb-1" i18n>Font color</div>
            <portal-color-picker [formControl]="brandText"></portal-color-picker>
          </div>

          <div class="mb-3" data-e2e="secondary-button-title">
            <span class="title is-6" i18n>Secondary button</span>&nbsp;
            <span class="has-text-grey">(outlined)</span>
          </div>

          <div class="has-text-left" data-e2e="secondary-button-font-color">
            <div class="pb-1" i18n>Font color</div>
            <portal-color-picker [formControl]="brandOutlinedText"></portal-color-picker>
          </div>
        </ng-container>
      </portal-accordion>
    </portal-accordion-wrapper>

    <div class="m-top-auto">
      <div class="mt-4" i18n data-e2e="apply-changes-note">
        Apply changes to see a preview of the page. The changes will be saved when you update the
        organisation page.
      </div>

      <div class="mt-4 columns is-mobile">
        <div class="column has-text-left">
          <button
            #resetButton
            *ngIf="isCustomStylesChanged"
            type="button"
            class="level-left button is-text has-text-danger is-borderless"
            (click)="onReset()"
            (keydown)="onKeyDownOnResetButton($event)"
            data-e2e="white-labeling-editor-reset-button"
            i18n
            title="Reset changes"
            i18n-title
          >
            Reset changes
          </button>
        </div>
        <div class="column has-text-right">
          <button
            #applyButton
            type="button"
            class="button is-primary"
            (click)="onApply()"
            (keydown)="onKeyDownOnApplyButton($event)"
            data-e2e="white-labeling-editor-apply-button"
            i18n
            title="Apply changes"
            i18n-title
          >
            Apply changes
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- back to Organisation edit page confirmation modal -->
<portal-base-modal #resetModal>
  <div title i18n>Are you sure you want to discard changes?</div>
  <div class="level" buttons>
    <div class="level-left"></div>
    <div class="buttons">
      <button
        type="button"
        class="button is-outlined"
        (click)="resetModal.close()"
        i18n
        data-e2e="delete-blocking-ruleset-confirm"
        title="Keep editing"
        i18n-title
      >
        Keep editing
      </button>
      <button
        type="button"
        class="button is-primary"
        (click)="onResetModalConfirmed()"
        i18n
        data-e2e="reset-modal-reset-button"
        title="Reset changes"
        i18n-title
      >
        Reset changes
      </button>
    </div>
  </div>
</portal-base-modal>