import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'marketplace-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() name: string;
  @Input() totalBytes = 0;
  @Input() showDeleteBtn: boolean = false;
  @Output() delete = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  percent = 0;

  _bytesLoaded: number;
  get bytesLoaded(): number {
    return this._bytesLoaded;
  }
  @Input('bytesLoaded')
  set bytesLoaded(value: number) {
    this._bytesLoaded = value;
    this.updateUploadPercent();
  }

  cancelUpload(): void {
    this.bytesLoaded = 0;
    this.percent = 0;
    this.cancel.emit();
  }

  updateUploadPercent(): void {
    // after cancel uploading and retry
    // Value for totalBytes didn't received
    if (this.totalBytes === 0) {
      this.percent = 0;
    } else {
      this.percent = Math.round((this.bytesLoaded / this.totalBytes) * 100);
    }
  }

  deleteFile(): void {
    this.bytesLoaded = 0;
    this.percent = 0;
    this.delete.emit();
  }
}
