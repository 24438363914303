export enum EnvironmentName {
  DEV = 'dev',
  MP_DEV = 'mp-dev',
  QAT = 'qat',
  CST = 'cst',
  CST_US = 'cst-us',
  PROD = 'prod',
  PROD_NZ = 'prod-nz',
  PROD_US = 'prod-us',
  PROD_EMEA = 'prod-emea',
  PROD_AU = 'prod-au',
  PROD_CBA = 'prod-cba',
}
