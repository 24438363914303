import { Component, Input, OnInit } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'vui-app-tag',
  templateUrl: './app-tag.component.html',
  styleUrls: ['./app-tag.component.scss'],
})
export class AppTagComponent implements OnInit {
  @Input() tags: string[];

  constructor() {}

  ngOnInit(): void {}
}
