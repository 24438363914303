export enum TransactionActionStatus {
  Initiated = 'INITIATED',
  Authorized = 'AUTHORIZED', // Status from transaction action
  Authorised = 'AUTHORISED', // Check if this status exists in Paypal transaction actions - SGS-9453
  AuthorizationVoided = 'AUTHORIZATION_VOIDED',
  Refunded = 'REFUNDED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Declined = 'DECLINED',
  SettlementCancelled = 'SETTLEMENT_CANCELLED',
  SettlementRequested = 'SETTLEMENT_REQUESTED',
  SettlementSubmitted = 'SETTLEMENT_SUBMITTED',
  SettlementCompleted = 'SETTLEMENT_COMPLETED',
  SettlementPartial = 'SETTLEMENT_PARTIAL',
  SettlementDeclined = 'SETTLEMENT_DECLINED',
  Voided = 'VOIDED',
  Unknown = 'UNKNOWN',
  Success = 'SUCCESS',
}
