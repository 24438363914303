import { CommonModule } from '@angular/common';
import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { GlobalErrorHandler } from './global-error-handler/global-error-handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OperationErrorInterceptor } from './services/operation-error.interceptor';
import { ErrorHandlerOptions } from './interfaces/error-handler-options';
import { ERROR_HANDLER_OPTIONS } from './services/error-handler-options.token';
import { errorHandlerOptionsDefaults } from './constants/error-handler-options.defaults';
import { ErrorLoggingService } from './services/error-logging.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: Window, useValue: window },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ErrorLoggingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OperationErrorInterceptor,
      multi: true,
    },
  ],
})
export class ErrorHandlerModule {
  constructor(@Optional() @SkipSelf() parentModule?: ErrorHandlerModule) {
    if (parentModule) {
      throw new Error('ErrorHandlerModule is already loaded. Import it in the AppModule only');
    }
  }
  static forRoot(options: ErrorHandlerOptions): ModuleWithProviders<ErrorHandlerModule> {
    return {
      ngModule: ErrorHandlerModule,
      providers: [
        {
          provide: ERROR_HANDLER_OPTIONS,
          useValue: { ...errorHandlerOptionsDefaults, ...options },
        },
      ],
    };
  }
}
