import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { ENTITY_METADATA_TOKEN } from '@ngrx/data';
import { HierarchySingleSelectModule } from '@portal/shared/ui/hierarchy-single-select/src';
import { SharedAuthAuthorizationModule } from '@portal/shared/auth/authorization/src/lib/shared-auth-authorization.module';
import { PipesModule } from '@portal/shared/pipes';
import { SharedRoleGroupPickerModule } from '@portal/shared/role-group-picker';
import { SharedUiFilterModule } from '@portal/shared/ui/filter/src/lib/shared-ui-filter.module';
import { FormModule } from '@portal/shared/ui/form';
import { SharedUiModalModule } from '@portal/shared/ui/modal';
import { TableModule } from '@portal/shared/ui/table';
import { CreateUserComponent } from './components/create/create.component';
import { DetailUserComponent } from './components/detail/detail.component';
import { FilterUserComponent } from './components/filter/filter.component';
import { FormUserComponent } from './components/form/form.component';
import { ListUserComponent } from './components/list/list.component';
import { UpdateUserComponent } from './components/update/update.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangePasswordErrorComponent } from './components/change-password/change-password-error/change-password-error.component';
import { CustomUserDataService } from './services/user-data.service';
import { UserRoleEditorMatrix } from './services/user-role-editor-matrix.service';
import { UserService } from './services/user.service';
import { entityMetadata } from './store/entity-metadata';
import { routes } from './user.routes';
import { UserModule as SharedUserModule } from '@portal/shared/user/src';
import { PasswordInputModule } from '@portal/shared/ui/password-input';
import { UisAuthenticationLayoutModule } from '@portal/shared/ui/layouts/src/lib/uis-authentication/uis-authentication-layout.module';
import { SharedModule } from '@apps/portal/src/app/shared/shared.module';
import { SpinnerModule } from '@portal/shared/ui/spinner';
import { OrganizationGroupModule } from '@apps/portal/src/app/modules/organization-group/organization-group.module';
import { IntTelInputModule } from '@portal/shared/ui/int-tel-input';

@NgModule({
  declarations: [
    ListUserComponent,
    CreateUserComponent,
    FormUserComponent,
    DetailUserComponent,
    UpdateUserComponent,
    FilterUserComponent,
    ChangePasswordComponent,
    ChangePasswordErrorComponent,
  ],
  imports: [
    FormModule,
    RouterModule.forChild(routes),
    CommonModule,
    TableModule,
    SharedUiModalModule,
    SharedUiFilterModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedUserModule,
    HierarchySingleSelectModule,
    SharedRoleGroupPickerModule,
    SharedAuthAuthorizationModule,
    PasswordInputModule,
    UisAuthenticationLayoutModule,
    SharedModule,
    SpinnerModule,
    OrganizationGroupModule,
    IntTelInputModule,
  ],
  providers: [
    CustomUserDataService,
    UserRoleEditorMatrix,
    UserService,
    { provide: ENTITY_METADATA_TOKEN, multi: true, useValue: entityMetadata },
    { provide: 'Window', useValue: window },
  ],
  exports: [
    ListUserComponent,
    CreateUserComponent,
    FormUserComponent,
    DetailUserComponent,
    UpdateUserComponent,
    ChangePasswordComponent,
  ],
})
export class UserModule {}
