<label class="field">
  <div class="label" i18n>Bank Account Type</div>
  <div class="control" *ngFor="let type of bankAccountTypeList">
    <label class="radio" attr.data-e2e="radio-bankAccount-label-{{ type.id }}">
      <input
        [formControl]="accountType"
        [value]="type.id"
        [name]="radioButtonGroupName || 'bankAccount'"
        type="radio"
        attr.data-e2e="radio-bankAccount-{{ type.id }}"
      />
      <span>{{ type.text }}</span>
    </label>
  </div>
</label>

<ng-container *ngIf="isAccountTypeDomestic">
  <portal-text-control
    name="Account Number"
    [control]="accountNumber"
    [invalid]="isInvalid(accountNumber)"
    [e2eName]="'input-accountNumber'"
    data-e2e="formBankAccount-accountNumber"
    class="field"
    i18n-name
  ></portal-text-control>

  <portal-text-control
    name="ABA routing number"
    [control]="routingTransitNumber"
    [invalid]="isInvalid(routingTransitNumber)"
    [e2eName]="'input-routing-transit-number'"
    data-e2e="formBankAccount-routingTransitNumber"
    class="field"
    i18n-name
  ></portal-text-control>
</ng-container>

<ng-container *ngIf="isAccountTypeSepa">
  <portal-text-control
    name="IBAN"
    [control]="iban"
    [invalid]="isInvalid(iban)"
    [e2eName]="'input-iban'"
    data-e2e="formBankAccount-iban"
    class="field"
    i18n-name
  ></portal-text-control>

  <label class="field" data-e2e="formBankAccount-country">
    <div class="label" i18n>Country</div>
    <ng-select
      [formControl]="country"
      [items]="countries"
      [multiple]="false"
      [closeOnSelect]="true"
      bindValue="alpha3"
      bindLabel="name"
      data-e2e="select-country"
      notFoundText="No items found"
      i18n-notFoundText
    >
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        {{ item.name }}
      </ng-template>
    </ng-select>

    <portal-form-error [formInput]="country" [errorId]="'country'"></portal-form-error>
  </label>
</ng-container>

<ng-container>
  <portal-text-control
    name="Account Holder Name"
    [control]="accountName"
    [invalid]="isInvalid(accountName)"
    [e2eName]="'input-accountName'"
    class="field"
    i18n-name
  ></portal-text-control>
</ng-container>

<ng-container *ngIf="isAccountTypeExternal">
  <portal-text-control
    name="External Account Reference"
    [control]="externalAccountReference"
    [invalid]="isInvalid(externalAccountReference)"
    [e2eName]="'input-externalAccountReference'"
    class="field"
    data-e2e="formBankAccount-externalAccountReference"
    i18n-name
  ></portal-text-control>
</ng-container>

<portal-select-control
  name="Account Holder Type"
  placeholder="- Select Account Holder Type -"
  [control]="accountHolderType"
  [invalid]="isInvalid(accountHolderType)"
  [options]="accountHolderTypesList"
  [e2eName]="'select-accountHolderType'"
  [e2eErrorName]="'accountHolderType'"
  class="field"
  i18n-name
  i18n-placeholder
></portal-select-control>

<portal-currencies-control
  name="Currency"
  [control]="currency"
  [invalid]="isInvalid(currency)"
  [currencies]="currenciesList"
  [multiple]="false"
  [closeOnSelect]="true"
  [e2eName]="'select-currency-account'"
  [e2eErrorName]="'currency-account'"
  (remove)="removeCurrency($event)"
  class="field"
  i18n-name
></portal-currencies-control>

<portal-text-control
  name="Description"
  [control]="description"
  [invalid]="isInvalid(description)"
  [e2eName]="'input-description'"
  data-e2e="formBankAccount-description"
  class="field"
  i18n-name
></portal-text-control>

<portal-text-control
  name="Nickname"
  [control]="nickname"
  [invalid]="isInvalid(nickname)"
  [e2eName]="'input-nickname'"
  data-e2e="formBankAccount-nickname"
  class="field"
  i18n-name
></portal-text-control>
