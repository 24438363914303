import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@portal/shared/pipes';
import { DragAndDropDirectiveModule } from '../file-upload/directives/drag-and-drop.module';
import { FileUploadFormControlComponent } from './file-upload-form-control.component';

@NgModule({
  imports: [CommonModule, PipesModule, DragAndDropDirectiveModule],
  declarations: [FileUploadFormControlComponent],
  exports: [FileUploadFormControlComponent],
})
export class FileUploadFormControlModule {}
