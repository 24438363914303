import { Injectable } from '@angular/core';
import { vhqFeatureModules } from './modules';
import { vhqOperations } from './operations';
import { AuthorizationService } from '../authorization/src';

@Injectable({
  providedIn: 'root'
})
export class VhqPermissionsService {

  allPermissions: string[];
  allOperations = vhqOperations.Objects;
  allFeatureModules = vhqFeatureModules.modules;

  constructor(
    private auth: AuthorizationService
  ) { }

  initializePermissions() {
    this.allPermissions = this.auth.getAllPermissions();
  }

  /**
   *
   * @param module - Takes Feature/Module Name like Devices
   * @returns - List of Tabs in the form of Array of Objects
   */
  getAllTabs(module) {
    let moduleSelected = this.allFeatureModules.filter(featureObject => featureObject.Module === module);
    let tabs = moduleSelected[0].Tabs
    let actions = moduleSelected[0].Actions
    if (tabs && tabs.length > 0) {
      for (let m = tabs.length-1; m >= 0; m--) {
        let tabSource = tabs[m];
        let tabObjects = tabSource.Object;
        let tabOperations = tabSource.Operation;
        let isDeleted = false;
        if (tabObjects && tabObjects.length > 0) {
          for (let n = 0; n < tabObjects.length; n++) {
            let moduleOperation = tabOperations[n];

            if (tabObjects[n].includes('|')) {
              let moduleObjectArray = [];
              moduleObjectArray = tabObjects[n].split('|');

              for (let i = 0; i< moduleObjectArray.length; i++) {
                let moduleObject = moduleObjectArray[i];
                isDeleted = false;
                isDeleted =  this.validateTabs(isDeleted, moduleOperation, moduleSelected, tabSource, moduleObject, i, moduleObjectArray.length);
                if (isDeleted === false) {
                  break;
                }
              }
            } else {
              let moduleObject = tabObjects[n];
              isDeleted =  this.validateTabs(isDeleted, moduleOperation, moduleSelected, tabSource, moduleObject, -1, 0);
            }

            if (isDeleted)
              break;
          }
        } else if (tabSource.SubTabs.length > 0) {
          for (let k = tabSource.SubTabs.length-1; k >= 0; k--) {
            let subTabSource = tabSource.SubTabs[k];
            let subTabObjects = subTabSource.Object;
            let subTabOperations = subTabSource.Operation;
            let isDeleted = false;
            if (subTabObjects && subTabObjects.length > 0) {
              for (let n = 0; n < subTabObjects.length; n++) {
                let moduleOperation = subTabOperations[n];
                if (subTabObjects[n].includes('|')) {
                  let moduleObjectArray = [];
                  moduleObjectArray = subTabObjects[n].split('|');

                  for (let i = 0; i< moduleObjectArray.length; i++) {
                    let moduleObject = moduleObjectArray[i];
                    isDeleted = false;
                    isDeleted =  this.validateSubTabs(isDeleted, moduleOperation, moduleSelected, subTabSource, moduleObject, m, i, moduleObjectArray.length);
                    if (isDeleted === false) {
                      break;
                    }
                  }
                } else {
                  let moduleObject = subTabObjects[n];
                  isDeleted =  this.validateSubTabs(isDeleted, moduleOperation, moduleSelected, subTabSource, moduleObject, m, -1, 0);
                }

                if (isDeleted)
                  break;
              }
            } else if (subTabSource.ChildTabs.length > 0) {
              for (let b = subTabSource.ChildTabs.length-1; b >= 0; b--) {
                let childTabSource = subTabSource.ChildTabs[b];
                let multiObjects = childTabSource.Object;
                let multiOperations = childTabSource.Operation;
                let isDeleted = false;
                if (multiObjects && multiObjects.length > 0) {
                  for (let a = 0; a < multiObjects.length; a++) {
                    let moduleOperation = multiOperations[a];

                    if (multiObjects[a].includes('|')) {
                      let moduleObjectArray = [];
                      moduleObjectArray = multiObjects[a].split('|');

                      for (let i = 0; i< moduleObjectArray.length; i++) {
                        let moduleObject = moduleObjectArray[i];
                        isDeleted = false;
                        isDeleted =  this.validateChildTabs(isDeleted, moduleOperation, moduleSelected, childTabSource, moduleObject, m, k, i, moduleObjectArray.length);
                        if (isDeleted === false) {
                          break;
                        }
                      }
                    } else {
                      let moduleObject = multiObjects[a];
                      isDeleted =  this.validateChildTabs(isDeleted, moduleOperation, moduleSelected, childTabSource, moduleObject, m, k, -1, 0);
                    }

                    if (isDeleted)
                      break;
                  }
                }
              }
              if(subTabSource.ChildTabs.length === 0) {
                let subActionIndex = tabSource.SubTabs.findIndex(item => (item.Action === subTabSource.Action))
                tabSource.SubTabs.splice(subActionIndex, 1);
              }
            }
          }
          if(tabSource.SubTabs.length === 0) {
            let tabIndex = actions.findIndex(item => (item.Action === tabSource.Action))
            tabs.splice(tabIndex, 1);
          }
        }
      }
    }

    return tabs;
  }

  // Note: currentIndex - Index of each Module Permission containing pipe(|) like "Basic Parameter Management|Advanced Parameter Management" currentIndex is 0 and 1 for Basic and Advance
  // splitPermissionsLength - Its length of "Basic Parameter Management|Advanced Parameter Management" split array
  validateTabs(isDeleted, moduleOperation, moduleSelected, tabSource, moduleObject, currentIndex, splitPermissionsLength) {
    let objectsArray = this.allOperations.filter(item => (item.Object === moduleObject));
    if (objectsArray && objectsArray.length > 0) {
      let operationSource = objectsArray[0].Operations.filter(item => (item.Operation === moduleOperation));
      if (operationSource && operationSource.length > 0) {
        let multipermissions = operationSource[0].Permission.split('&');
        if (multipermissions && multipermissions.length > 0) {
          for (let o = 0; o < multipermissions.length; o++) {
            let isRemoved = false;
            let permissions = multipermissions[o].split('|');
            if (permissions && permissions.length > 0) {
              for (let p = 0; p < permissions.length; p++) {
                let isExists = this.allPermissions.filter((u) => u == permissions[p]);
                if (isExists && isExists.length > 0) {
                  break;
                } else if (p === permissions.length - 1 && (currentIndex === -1 || (currentIndex > -1 && currentIndex === splitPermissionsLength - 1))) {
                  let moduleSourceArray = moduleSelected;
                  if (moduleSourceArray && moduleSourceArray.length > 0) {
                    let index = moduleSourceArray[0].Tabs.findIndex(item => (item.Action === tabSource.Action))
                    moduleSourceArray[0].Tabs.splice(index, 1);
                    isRemoved = true;
                    isDeleted = true;
                  }
                }
              }
            }
            if (isRemoved)
              break;
          }
        }
      }
    }

    return isDeleted;
  }

  validateSubTabs(isDeleted, moduleOperation, moduleSelected, subTabSource, moduleObject, m, currentIndex, splitPermissionsLength) {
    let objectsArray = this.allOperations.filter(item => (item.Object === moduleObject));
    if (objectsArray && objectsArray.length > 0) {
      let operationSource = objectsArray[0].Operations.filter(item => (item.Operation === moduleOperation));
      if (operationSource && operationSource.length > 0) {
        let multipermissions = operationSource[0].Permission.split('&');
        if (multipermissions && multipermissions.length > 0) {
          for (let o = 0; o < multipermissions.length; o++) {
            let isRemoved = false;
            let permissions = multipermissions[o].split('|');
            if (permissions && permissions.length > 0) {
              for (let p = 0; p < permissions.length; p++) {
                let isExists = this.allPermissions.filter((u) => u == permissions[p]);
                if (isExists && isExists.length > 0) {
                  break;
                } else if (p === permissions.length - 1 && (currentIndex === -1 || (currentIndex > -1 && currentIndex === splitPermissionsLength - 1))) {
                  let moduleSourceArray = moduleSelected;
                  if (moduleSourceArray && moduleSourceArray.length > 0) {
                    let index = moduleSourceArray[0].Tabs[m].SubTabs.findIndex(item => (item.Action === subTabSource.Action))
                    moduleSourceArray[0].Tabs[m].SubTabs.splice(index, 1);
                    isRemoved = true;
                    isDeleted = true;
                  }
                }
              }
            }
            if (isRemoved)
              break;
          }
        }
      }
    }

    return isDeleted;
  }

  validateChildTabs(isDeleted, moduleOperation, moduleSelected, childTabSource, moduleObject, m, k, currentIndex, splitPermissionsLength) {
    let objectsArray = this.allOperations.filter(item => (item.Object === moduleObject));
    if (objectsArray && objectsArray.length > 0) {
      let operationSource = objectsArray[0].Operations.filter(item => (item.Operation === moduleOperation));
      if (operationSource && operationSource.length > 0) {
        let multipermissions = operationSource[0].Permission.split('&');
        if (multipermissions && multipermissions.length > 0) {
          for (let i = 0; i < multipermissions.length; i++) {
            let isRemoved = false;
            let permissions = multipermissions[i].split('|');
            if (permissions && permissions.length > 0) {
              for (let j = 0; j < permissions.length; j++) {
                let isExists = this.allPermissions.filter((u) => u == permissions[j]);
                if (isExists && isExists.length > 0) {
                  break;
                } else if (j === permissions.length - 1 && (currentIndex === -1 || (currentIndex > -1 && currentIndex === splitPermissionsLength - 1))) {
                  let moduleSourceArray = moduleSelected;
                  if (moduleSourceArray && moduleSourceArray.length > 0) {
                    let index = moduleSourceArray[0].Tabs[m].SubTabs[k].ChildTabs.findIndex(item => (item.Action === childTabSource.Action))
                    moduleSourceArray[0].Tabs[m].SubTabs[k].ChildTabs.splice(index, 1);
                    isDeleted = true;
                    isRemoved = isDeleted;
                  }
                }
              }
            }
            if (isRemoved)
              break;
          }
        }
      }
    }

    return isDeleted;
  }

  /**
   *
   * @param tabToCheck - Tab name to validate permission
   * @param moduleName - Module where tab to check
   * @returns - true or false
   */
  isTabPermissionAllowed(tabToCheck:string, moduleName:string): boolean {
    let allTabs = this.getAllTabs(moduleName);

    let isTabAllowed = false;

    allTabs.forEach(tab => {
      if (tab.Action === tabToCheck) {
        isTabAllowed = true;
        return;
      } else if (tab?.SubTabs?.length >= 1) {
        for (let subTab of tab.SubTabs) {
          if (subTab.Action === tabToCheck) {
            isTabAllowed = true;
            break;
          } else if (subTab?.ChildTabs?.length >= 1) {
            for (let childTab of subTab?.ChildTabs) {
              if (childTab.Action === tabToCheck) {
                isTabAllowed = true;
                break;
              }
            }
          }
        }
      }
    })

    return isTabAllowed;
  }

  /**
   *
   * @param module - Takes Feature/Module Name like Devices
   * @returns - List of Actions in the form of Array of Objects
   */
  getActionsForModule(module): any[] {
    let moduleSelected:any = this.allFeatureModules.filter(featureObject => featureObject.Module === module);
    let actions = moduleSelected[0].Actions;
    if (actions && actions.length > 0) {
      for (let y = actions.length-1; y >= 0; y--) {
        let actionSource = actions[y];
        let multiObjects = actionSource.Object;
        let multiOperations = actionSource.Operation;
        let isDeleted = false;
        if (multiObjects && multiObjects.length > 0) {
          for (let z = 0; z < multiObjects.length; z++) {
            let moduleOperation = multiOperations[z];

            if (multiObjects[z].includes('|')) {
              let moduleObjectArray = [];
              moduleObjectArray = multiObjects[z].split('|');

              for (let i = 0; i< moduleObjectArray.length; i++) {
                let moduleObject = moduleObjectArray[i];
                isDeleted = false;
                isDeleted =  this.validateActions(isDeleted, moduleOperation, moduleSelected, actionSource, moduleObject, i, moduleObjectArray.length);
                if (isDeleted === false) {
                  break;
                }
              }
            } else {
              let moduleObject = multiObjects[z];
              isDeleted =  this.validateActions(isDeleted, moduleOperation, moduleSelected, actionSource, moduleObject, -1, 0);
            }

            if (isDeleted)
              break;
          }
        } else if (actionSource.SubActions.length > 0) {
          for (let k = actionSource.SubActions.length-1; k >= 0; k--) {
            let subActionSource = actionSource.SubActions[k];
            let multiObjects = subActionSource.Object;
            let multiOperations = subActionSource.Operation;
            let isDeleted = false;
            if (multiObjects && multiObjects.length > 0) {
              for (let a = 0; a < multiObjects.length; a++) {
                let moduleOperation = multiOperations[a];

                if (multiObjects[a].includes('|')) {
                  let moduleObjectArray = [];
                  moduleObjectArray = multiObjects[a].split('|');

                  for (let i = 0; i< moduleObjectArray.length; i++) {
                    let moduleObject = moduleObjectArray[i];
                    isDeleted = false;
                    isDeleted =  this.validateSubActions(isDeleted, moduleOperation, moduleSelected, subActionSource, moduleObject, y, i, moduleObjectArray.length);
                    if (isDeleted === false) {
                      break;
                    }
                  }
                } else {
                  let moduleObject = multiObjects[a];
                  isDeleted =  this.validateSubActions(isDeleted, moduleOperation, moduleSelected, subActionSource, moduleObject, y, -1, 0);
                }

                if (isDeleted)
                  break;
              }
            } else if (subActionSource.ChildActions.length > 0) {
              for (let b = subActionSource?.ChildActions.length-1; b >= 0; b--) {
                let childActionSource = subActionSource?.ChildActions[b];
                let multiObjects = childActionSource.Object;
                let multiOperations = childActionSource.Operation;
                let isDeleted = false;
                if (multiObjects && multiObjects.length > 0) {
                  for (let a = 0; a < multiObjects.length; a++) {
                    let moduleOperation = multiOperations[a];

                    if (multiObjects[a].includes('|')) {
                      let moduleObjectArray = [];
                      moduleObjectArray = multiObjects[a].split('|');

                      for (let i = 0; i< moduleObjectArray.length; i++) {
                        let moduleObject = moduleObjectArray[i];
                        isDeleted = false;
                        isDeleted =  this.validateChildActions(isDeleted, moduleOperation, moduleSelected, childActionSource, moduleObject, k, y, i, moduleObjectArray.length);
                        if (isDeleted === false) {
                          break;
                        }
                      }
                    } else {
                      let moduleObject = multiObjects[a];
                      isDeleted =  this.validateChildActions(isDeleted, moduleOperation, moduleSelected, childActionSource, moduleObject, k, y, -1, 0);
                    }

                    if (isDeleted)
                      break;
                  }
                }
              }
              if(subActionSource.ChildActions.length === 0) {
                let subActionIndex = actionSource.SubActions.findIndex(item => (item.Action === subActionSource.Action))
                actionSource.SubActions.splice(subActionIndex, 1);
              }
            }
          }
          if(actionSource.SubActions.length === 0) {
            let actionIndex = actions.findIndex(item => (item.Action === actionSource.Action))
            actions.splice(actionIndex, 1);
          }
        }
      }
    }

    return actions;
  }

  validateActions(isDeleted, moduleOperation, moduleSelected, actionSource, moduleObject, currentIndex, splitPermissionsLength) {
    let objectsArray = this.allOperations.filter(item => (item.Object === moduleObject));
    if (objectsArray && objectsArray.length > 0) {
      let operationSource = objectsArray[0].Operations.filter(item => (item.Operation === moduleOperation));
      if (operationSource && operationSource.length > 0) {
        let multipermissions = operationSource[0].Permission.split('&');
        if (multipermissions && multipermissions.length > 0) {
          for (let i = 0; i < multipermissions.length; i++) {
            let isRemoved = false;
            let permissions = multipermissions[i].split('|');
            if (permissions && permissions.length > 0) {
              for (let j = 0; j < permissions.length; j++) {
                let isExists = this.allPermissions.filter((u) => u == permissions[j]);
                if (isExists && isExists.length > 0) {
                  break;
                } else if (j === permissions.length - 1 && (currentIndex === -1 || (currentIndex > -1 && currentIndex === splitPermissionsLength - 1))) {
                  let moduleSourceArray = moduleSelected;
                  if (moduleSourceArray && moduleSourceArray.length > 0) {
                      let index = moduleSourceArray[0].Actions.findIndex(item => (item.Action === actionSource.Action))
                      moduleSourceArray[0].Actions.splice(index, 1);
                      isRemoved = true;
                      isDeleted = true;
                  }
                }
              }
            }
            if (isRemoved)
                break;
          }
        }
      }
    }
    return isDeleted;
  }

  validateSubActions(isDeleted, moduleOperation, moduleSelected, subActionSource, moduleObject, y, currentIndex, splitPermissionsLength) {
    let objectsArray = this.allOperations.filter(item => (item.Object === moduleObject));
    if (objectsArray && objectsArray.length > 0) {
      let operationSource = objectsArray[0].Operations.filter(item => (item.Operation === moduleOperation));
      if (operationSource && operationSource.length > 0) {
        let multipermissions = operationSource[0].Permission.split('&');
        if (multipermissions && multipermissions.length > 0) {
          for (let i = 0; i < multipermissions.length; i++) {
            let isRemoved = false;
            let permissions = multipermissions[i].split('|');
            if (permissions && permissions.length > 0) {
              for (let j = 0; j < permissions.length; j++) {
                let isExists = this.allPermissions.filter((u) => u == permissions[j]);
                if (isExists && isExists.length > 0) {
                  break;
                } else if (j === permissions.length - 1 && (currentIndex === -1 || (currentIndex > -1 && currentIndex === splitPermissionsLength - 1))) {
                  let moduleSourceArray = moduleSelected;
                  if (moduleSourceArray && moduleSourceArray.length > 0) {
                      let index = moduleSourceArray[0].Actions[y].SubActions.findIndex(item => (item.Action === subActionSource.Action))
                      moduleSourceArray[0].Actions[y].SubActions.splice(index, 1);
                      isRemoved = true;
                      isDeleted = true;
                  }
                }
              }
            }
            if (isRemoved)
                break;
          }
        }
      }
    }
    return isDeleted;
  }

  validateChildActions(isDeleted, moduleOperation, moduleSelected, childActionSource, moduleObject, k,  y, currentIndex, splitPermissionsLength) {
    let objectsArray = this.allOperations.filter(item => (item.Object === moduleObject));
    if (objectsArray && objectsArray.length > 0) {
      let operationSource = objectsArray[0].Operations.filter(item => (item.Operation === moduleOperation));
      if (operationSource && operationSource.length > 0) {
        let multipermissions = operationSource[0].Permission.split('&');
        if (multipermissions && multipermissions.length > 0) {
          for (let i = 0; i < multipermissions.length; i++) {
            let isRemoved = false;
            let permissions = multipermissions[i].split('|');
            if (permissions && permissions.length > 0) {
              for (let j = 0; j < permissions.length; j++) {
                let isExists = this.allPermissions.filter((u) => u == permissions[j]);
                if (isExists && isExists.length > 0) {
                  break;
                } else if (j === permissions.length - 1 && (currentIndex === -1 || (currentIndex > -1 && currentIndex === splitPermissionsLength - 1))) {
                  let moduleSourceArray = moduleSelected;
                  if (moduleSourceArray && moduleSourceArray.length > 0) {
                      let index = moduleSourceArray[0].Actions[y].SubActions[k].ChildActions.findIndex(item => (item.Action === childActionSource.Action))
                      moduleSourceArray[0].Actions[y].SubActions[k].ChildActions.splice(index, 1);
                      isRemoved = true;
                      isDeleted = true;
                  }
                }
              }
            }
            if (isRemoved)
                break;
          }
        }
      }
    }

    return isDeleted;
  }

  /**
   *
   * @param module - Takes Feature/Module Name like Devices
   * @param section - Takes Sub Module/Section of Feature like Last HB, Status in Devices Module
   * @returns - True or False based on its permission access
   */
  isPermissible(module: string, section: String): boolean {
    if (!this.allPermissions) {
      this.initializePermissions();
    }

    let moduleSelected:any = this.allFeatureModules.filter(featureObject => featureObject.Module === module);
    if (moduleSelected && moduleSelected.length > 0) {
      let sectionSource = moduleSelected[0].Sections.filter((item) => item.Action == section);

      if (sectionSource && sectionSource.length > 0) {
        let sectionObjects = sectionSource[0].Object;
        let sectionOperations = sectionSource[0].Operation;

        if (sectionObjects && sectionObjects.length > 0) {
          let retval = 0;
          for (let j = 0; j < sectionObjects.length; j++) {
            let moduleOperation = sectionOperations[j];

            if (sectionObjects[j].includes('|')) {
              let moduleObjectArray = [];
              moduleObjectArray = sectionObjects[j].split('|');

              for (let i = 0; i< moduleObjectArray.length; i++) {
                let moduleObject = moduleObjectArray[i];
                retval = 0;
                retval =  this.validateSections(retval, moduleOperation, moduleObject, i, moduleObjectArray.length);
                if (retval === 0) {
                  break;
                }
              }
            } else {
              let moduleObject = sectionObjects[j];
              retval =  this.validateSections(retval, moduleOperation, moduleObject, -1, 0);
            }

          }

          if (retval === 0) {
            return true;
          }
        }
      } else {
        return false;
      }
    }
    return false;
  }

  validateSections(retval, moduleOperation, moduleObject, currentIndex, splitPermissionsLength) {
    let objectsArray = this.allOperations.filter(item => (item.Object === moduleObject));
    if (objectsArray && objectsArray.length > 0) {
      let operationSource = objectsArray[0].Operations.filter(item => (item.Operation === moduleOperation));
      if (operationSource && operationSource.length > 0) {
        let multipermissions = operationSource[0].Permission.split('&');
        if (multipermissions && multipermissions.length > 0) {
          for (let i = 0; i < multipermissions.length; i++) {
            let permissions = multipermissions[i].split('|');
            if (permissions && permissions.length > 0) {
              for (let j = 0; j < permissions.length; j++) {
                let isExists = this.allPermissions.filter((u) => u == permissions[j]);
                if (isExists && isExists.length > 0) {
                  break;
                } else if (j === permissions.length - 1 && (currentIndex === -1 || (currentIndex > -1 && currentIndex === splitPermissionsLength - 1))) {
                  retval++;
                  return false;
                }
              }
            }
          }
        }
      }
    }
    return retval;
  }

  /**
   *
   * @param module - Takes Feature/Module Name like Devices
   * @returns - List of Sections in the form of Array of Objects
   */
  getSectionsForModule(module) {
    let moduleSelected = this.allFeatureModules.filter(featureObject => featureObject.Module === module);
    let sections = moduleSelected[0].Sections;

    for (let i = sections.length -1 ; i >= 0; i--) {
      let isAllowed = this.isPermissible(module, sections[i].Action);

      if (!isAllowed) {
        sections.splice(i, 1);
      }
    }

    return sections;
  }

  /**
   *  Update existing object after validating with permission
   * @param pillObject - list of pills or kpis object
   * @param pillsArray - array containing permissions for each pill or kpi
   */
  updateKPIsAndCharts(pillObject, pillsArray) {
    for (let i = pillObject.length - 1; i >= 0; i--) {
      const pill = pillObject[i];
      const correspondingPill = pillsArray.find(pills => pills.Action === pill.Label);
      if (!correspondingPill) {
        pillObject.splice(i, 1);
      }
    }
  }
}
