import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { IOrganizationGroup } from '../../interfaces/organization-group.interface';
import { ErrorService, FormBase, MultilingualValidators } from '@portal/shared/ui/form';
import { IEntity, IUser } from '@portal/entity-services/interfaces/src';
import { IQueryParams, PageSize, SortOrder } from '@portal/shared/ui/table/src';
import {
  IFilterToApply,
  ISearchCriteria,
  ISelectInput,
  SearchOperator,
  SearchSelector,
} from '@portal/shared/ui/filter';
import { UserService as SharedUserService } from '@portal/shared/user/src';
import { BaseModalComponent } from '@portal/shared/ui/modal';
import { SearchPayloadService } from '@apps/portal/src/app/modules/transactions/services/search-payload.service';
import { SearchCriteriaService } from '../../../transactions/services/search-criteria.service';
import { Router } from '@angular/router';
import { EntityStatus } from '@portal/entity-services/interfaces';
import { ISearchPayload } from '../../../transactions/interfaces';
import { IGroupViewDetailEdit } from '../../interfaces/group-view-detail-edit.interface';
import { Subject } from 'rxjs';
import * as forEach from 'lodash/forEach';
import * as isEmpty from 'lodash/isEmpty';
import * as concat from 'lodash/concat';
import * as without from 'lodash/without';
import * as cloneDeep from 'lodash/cloneDeep';

declare const $localize;
@Component({
  selector: 'portal-form-organization-group',
  templateUrl: './form-organization-group.component.html',
  styleUrls: ['./form-organization-group.component.scss'],
})
export class FormOrganizationGroupComponent extends FormBase<IOrganizationGroup> implements OnInit {
  @Input() saving = false;
  @Input() groupModeEdit: boolean;
  @Input() groupViewDetailEdit: IGroupViewDetailEdit;
  @Input() organizationGroup: IEntity;
  @Input() selectedParentEntity: IEntity;
  @Output() filterUpdated: EventEmitter<Map<string, ISearchCriteria>> = new EventEmitter();
  @Output() selectedOrganizationNumber: EventEmitter<number> = new EventEmitter();
  @Output() closeEditForm: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('cancelModal') cancelModal: BaseModalComponent;

  isInvalid = ErrorService.isFieldInvalid;

  removeEntity$ = new Subject<ISelectInput>();
  selectedStaticFilters: ISelectInput[] = [];
  staticFiltersMap: {
    [key: string]: ISelectInput[];
  } = {};
  searchCriteria: Map<string, ISearchCriteria> = new Map([]);
  initialSearchCriteria: Map<string, ISearchCriteria> = new Map().set(SearchSelector.EntityUid, {
    operator: SearchOperator.In,
    values: new Set(['true']),
    selector: SearchSelector.Organization,
    argument: `${true}`,
  });

  // Multiple organisations
  searchPayload: ISearchPayload;
  initialSearchPayload: ISearchPayload = {
    pageNumber: 1,
    pageSize: PageSize.Size10,
    orderCriteria: SearchSelector.Created,
    direction: SortOrder.Desc,
    criteriaMap: new Map<string, ISearchCriteria>([
      [
        SearchSelector.CreatedFrom,
        {
          argument: SearchPayloadService.getLast7DaysDateFilter().start,
          selector: SearchSelector.CreatedFrom,
          operator: SearchOperator.GreaterThanAndEqualTo,
        },
      ],
      [
        SearchSelector.CreatedTo,
        {
          argument: SearchPayloadService.getLast7DaysDateFilter().end,
          selector: SearchSelector.CreatedTo,
          operator: SearchOperator.LessThanAndEqualTo,
        },
      ],
      [
        SearchSelector.RelatedTransaction,
        {
          argument: null,
          operator: SearchOperator.Equal,
          selector: SearchSelector.RelatedTransaction,
        },
      ],
    ]),
  };
  searchUserPayload: IQueryParams;
  initialUserSearchPayload: IQueryParams = {
    start: 0,
    limit: PageSize.Size100,
    order: SortOrder.Asc,
    populateEntity: true,
    status: [EntityStatus.Active],
    searchCriteria: new Map<string, ISearchCriteria>(),
  };
  selectedParentEntityChanged = false;
  preSelectedOrganizationNumber: number;
  maximumLimitOrgToAdd = 100;
  minimumLimitOrgToAdd = 2;
  isParentEntityToExclude = false;
  messageMaximumLimitOrgReached = $localize`Maximum limit of adding 100 organizations reached`;
  messageMinimumLimitOrgReached = $localize`Please add at least two organization to create a group`;
  messageParentEntityNotIncluded = $localize`The selected parent organization will not be included in the group`;
  usersDetails: IUser[] = [];
  selectedSubOrganizations: Map<string, ISelectInput> = new Map();
  readonly canSaveOrganizationGroup = true;
  isOpenFilters: boolean;

  constructor(
    private fb: FormBuilder,
    private sharedUserService: SharedUserService,
    private router: Router,
    customValidators: MultilingualValidators,
  ) {
    super(
      fb.group({
        name: [
          '',
          [
            customValidators.required,
            customValidators.validateCharacters,
            customValidators.maxLength(100),
          ],
        ],
        groupUid: ['', []],
        parentEntityUid: ['', [customValidators.required]],
        entityUids: [[], [customValidators.required]],
        userIds: [[]],
      }),
    );
  }

  ngOnInit(): void {
    this.searchPayload = cloneDeep(this.initialSearchPayload);
    this.searchUserPayload = cloneDeep(this.initialUserSearchPayload);
    this.setInitialFilterState();
  }

  openContentOrganizationGroup(value: boolean): void {
    this.isOpenFilters = value;
  }

  onSetControlGroupOrganizationValue(): void {
    this.name.setValue(this.groupViewDetailEdit?.groupOrganization?.name);
    this.groupUid.setValue(this.groupViewDetailEdit?.groupOrganization?.entityUid);
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get groupUid(): AbstractControl {
    return this.form.get('groupUid');
  }

  onSetControlParentEntityUidValue(): void {
    this.parentEntityUid.setValue(this.groupViewDetailEdit?.parentOrganization.entityUid);
  }

  get parentEntityUid(): AbstractControl {
    return this.form.get('parentEntityUid');
  }

  get entityUids(): AbstractControl {
    return this.form.get('entityUids');
  }

  get userIds(): AbstractControl {
    return this.form.get('userIds');
  }

  onSelectedOrganisation(entity: IEntity): void {
    this.selectedParentEntityChanged =
      (this.selectedParentEntity?.entityUid &&
        entity?.entityUid !== this.selectedParentEntity?.entityUid) ||
      false;
    this.selectedParentEntity = entity;
  }

  onResetParentEntityChanged(): void {
    this.selectedParentEntityChanged = false;
  }

  // Multiple filters
  onFilterApplied(filter: IFilterToApply): void {
    const values = [...new Set(SearchCriteriaService.removeParenthesesFromIds(filter?.argument))];
    if (values.includes(this.selectedParentEntity?.entityUid)) {
      this.isParentEntityToExclude = true;
    } else {
      this.isParentEntityToExclude = false;
    }
  }

  onAllPreSelectedEntitiesNumber(preSelectedOrganizationNumber: number): void {
    this.preSelectedOrganizationNumber = preSelectedOrganizationNumber;
    this.selectedOrganizationNumber.emit(preSelectedOrganizationNumber);
  }

  onFilterAppliedSelectedEntities(selectedEntities: Map<string, ISelectInput> = new Map()): void {
    this.selectedSubOrganizations = selectedEntities;
  }

  onStaticFilterUpdated(updatedFlter: IFilterToApply): void {
    if (updatedFlter) {
      const { toRemove, key, value } = updatedFlter;
      if (toRemove) {
        delete this.staticFiltersMap[key];
      } else {
        this.staticFiltersMap[key] = [];

        forEach(value, ({ id, text }: ISelectInput) => {
          this.staticFiltersMap[key].push({ id, text, key });
        });
      }
    }

    this.selectedStaticFilters = [];
    if (!isEmpty(this.staticFiltersMap)) {
      forEach(this.staticFiltersMap, (inputs: ISelectInput[]) => {
        this.selectedStaticFilters = concat(this.selectedStaticFilters, inputs);
      });
    }
  }

  removeStaticFilter(input: ISelectInput): void {
    this.selectedStaticFilters = without(this.selectedStaticFilters, input);
    this.removeEntity$.next(input);
  }

  onFilterUserUpdated(updatedFilter: IFilterToApply): void {
    if (updatedFilter) {
      const { toRemove, key, selector, argument, operator, value } = updatedFilter;
      if (toRemove) {
        this.searchPayload.criteriaMap.delete(key);
      } else {
        const currentArgumentsString = this.searchPayload.criteriaMap.get(key)?.argument;
        const updatedArguments = argument?.substring(1, argument.length - 1).split(',') || [];
        const currentArguments =
          currentArgumentsString?.substring(1, currentArgumentsString.length - 1).split(',') || [];
        const argumentsToRemove = currentArguments.filter((arg) => !updatedArguments.includes(arg));
        argumentsToRemove.forEach((argToRemove) => {
          const staticFilter = this.selectedStaticFilters.find(
            (arg) => arg?.id === argToRemove && arg.key === key,
          );
          if (staticFilter) {
            this.removeStaticFilter(staticFilter);
          }
        });

        this.searchPayload.criteriaMap.set(key, {
          argument,
          selector,
          operator,
          values: new Set(Array.isArray(value) ? value?.map((item) => item.id) : [value]),
        });
      }
    }
  }

  setEntityUid(): void {
    if (this.searchCriteria.has(SearchSelector.EntityUid)) {
      this.searchCriteria.delete(SearchSelector.EntityUid);
    }
    const argument = this.sharedUserService.userDetails?.entity?.entityUid;
    this.searchCriteria.set(SearchSelector.EntityUid, {
      argument: argument,
      selector: SearchSelector.Organization,
      operator: SearchOperator.SingleEqual,
    });
  }

  setInitialFilterState(): void {
    this.searchCriteria = cloneDeep(this.initialSearchCriteria);
    this.setEntityUid();
  }

  onCancelModalConfirmed(): void {
    this.closeEditForm.emit();
    this.cancelModal.close();
  }

  onCancel(): void {
    this.cancelModal.open();
  }
}
