import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { IAmount } from '../../interfaces/amount.interface';
import { LocaleCurrencyDecimals } from '../locale-currency-decimals/locale-currency-decimals.pipe';

@Pipe({
  name: 'localeAmount',
})
export class LocaleAmountPipe implements PipeTransform {
  private localeCurrencyDecimalsPipe: LocaleCurrencyDecimals;

  constructor(@Inject(LOCALE_ID) locale: string) {
    this.localeCurrencyDecimalsPipe = new LocaleCurrencyDecimals(locale);
  }

  transform(amount: IAmount): string {
    if (!amount) return '';

    const value = this.localeCurrencyDecimalsPipe.transform(amount.value, amount.currencyCode);
    return `${value} ${amount.currencyCode}`;
  }
}
