export enum Locale {
  ElGr = 'el-GR',
  El = 'El',
  En = 'en',
  EnUs = 'en-US',
  EsEs = 'es-ES',
  EsMx = 'es-MX',
  Es = 'es',
  Fi = 'fi',
  FiFI = 'fi-FI',
  Fr = 'fr',
  FrFr = 'fr-FR',
  FrCa = 'fr-CA',
  He = 'he',
  Pt = 'pt',
  PtPt = 'pt-PT',
  Da = 'da',
  DaDk = 'da-DK',
  Sv = 'sv',
  SvSE = 'sv-SE',
  Pl = 'pl',
  PlPl = 'pl-PL',
  Nl = 'nl',
  NlNl = 'nl-NL',
  No = 'no',
  NoNO = 'no-NO',
  Lt = 'lt',
  LtLT = 'lt-LT',
  Lv = 'lv',
  LvLV = 'lv-LV',
  Ar = 'ar',
  Ru = 'ru',
  Et = 'et',
  EtEE = 'et-EE',
  De = 'de',
  DeDe = 'de-DE',
  It = 'it',
  ItIt = 'it-IT',
  Is = 'is',
  IsIS = 'is-IS',
  NlBe = 'nl-BE',
}
