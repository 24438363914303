import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { DateFilterQuickPick } from '../enums/quick-pick.enum';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class DateFilterQuickPicks extends SelectInputsList {
  keyValue = {
    [DateFilterQuickPick.AnyDate]: $localize`:date filter option|:Any time`,
    [DateFilterQuickPick.Custom]: $localize`:date filter option|:Custom`,
    [DateFilterQuickPick.Today]: $localize`:date filter option|:Today`,
    [DateFilterQuickPick.Yesterday]: $localize`:date filter option|:Yesterday`,
    [DateFilterQuickPick.LastWeek]: $localize`:date filter option|:Last week`,
    [DateFilterQuickPick.LastMonth]: $localize`:date filter option|:Last month`,
    [DateFilterQuickPick.Last7Days]: $localize`:date filter option|:Last 7 days`,
    [DateFilterQuickPick.Last30Days]: $localize`:date filter option|:Last 30 days`,
    [DateFilterQuickPick.Last18Months]: $localize`:date filter option|:Last 18 months`,
  };

  list: ISelectInput[] = this.getSelectInputs();
}
