import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import environment from '@environments';
import { ErrorLogRequest, Message, Mdc, SimpleMessage } from '../interfaces/error-log.interface';
import { DataServiceError } from '@ngrx/data';
import { LogMessageType } from '../enums/log-message-type.enum';
import { LogLevel } from '../enums/log-level.enum';
import { ErrorService } from '@portal/shared/ui/form/src';
import apiAcronymMap from '../constants/api-acronym-map';
import apiOperationMap from '../constants/api-operation-map';
import { OperationError } from '../error/operation-error';
import { CaseTypes, makeCaseConverter } from '@portal/shared/helpers';
import { ERROR_HANDLER_OPTIONS } from './error-handler-options.token';
import { ErrorHandlerOptions } from '../interfaces/error-handler-options';

const toSnakeCase = makeCaseConverter(CaseTypes.Snake);

@Injectable()
export class ErrorLoggingService {
  private readonly apiUrl = environment.API_ENDPOINT.ERROR_LOGGING_SERVICE;

  constructor(
    private http: HttpClient,
    @Inject(Window) private window,
    @Inject(ERROR_HANDLER_OPTIONS) private options: ErrorHandlerOptions,
  ) {}

  postErrorLog(error: OperationError | HttpErrorResponse | DataServiceError | Error): void {
    const url = this.apiUrl;
    const errorLog = this.formatError(error);
    this.http.post(url, toSnakeCase(errorLog)).subscribe({
      error: (err) => {
        console.error('Could not push logs', err);
      },
    });
  }

  private formatError(
    error: OperationError | HttpErrorResponse | DataServiceError | Error,
  ): ErrorLogRequest {
    const unwrappedError = error instanceof DataServiceError ? error.error : error;
    const mdc = this.getMdc(unwrappedError);
    const message: Message =
      unwrappedError instanceof OperationError
        ? this.formatOperationErrorMessage(unwrappedError)
        : this.formatSimpleErrorMessage(unwrappedError);
    return {
      mdc,
      message,
      level: LogLevel.Error,
      sourceHost: this.window.location.host,
      timestamp: new Date().toISOString(),
      loggerName: this.options.loggerName,
    };
  }

  private getMdc(error: HttpErrorResponse): Mdc {
    const appVersion = localStorage.getItem('version') || '';
    const appInfo = {
      appName: this.options.appName,
      appVersion: appVersion,
    };
    const traceId = error?.headers?.get('x-b3-traceid');
    if (traceId) {
      return {
        ...appInfo,
        traceId: traceId,
        correlationId: traceId,
      };
    }
    return appInfo;
  }

  private formatSimpleErrorMessage(error: Error | HttpErrorResponse): SimpleMessage {
    if (error instanceof HttpErrorResponse) {
      return {
        type: LogMessageType.Simple,
        traceMessage: `${error.message}\n${ErrorService.getErrorMessage(error)}`,
        traceDetails: {
          url: error.url,
          status: error.status,
          error: error.error,
        },
      };
    }
    return {
      type: LogMessageType.Simple,
      traceMessage: ErrorService.getErrorMessage(error),
    };
  }

  private formatOperationErrorMessage(error: OperationError): Message {
    return {
      type: LogMessageType.Operation,
      source: this.options.appName,
      target: this.getTargetAcronym(error.url),
      operation: this.getOperation(error),
      url: error.url,
      inTime: error.inTime,
      outTime: error.outTime,
      responseCode: ErrorService.getStatusCode(error).toString(),
      errorCode: error.error?.code,
      errorDescription: ErrorService.getErrorMessage(error),
    };
  }

  private getTargetAcronym(url: string): string {
    const serviceAliasKey = Object.keys(apiAcronymMap).find((pattern) => url.indexOf(pattern) >= 0);
    return serviceAliasKey ? apiAcronymMap[serviceAliasKey] : 'OTHER';
  }

  private getOperation(error: OperationError): string {
    const url = error.url;
    const method = String(error.method).toUpperCase();
    const resourceAliasKey = Object.keys(apiOperationMap).find(
      (pattern) => url.indexOf(pattern) >= 0,
    );
    return resourceAliasKey ? `${method}_${apiOperationMap[resourceAliasKey]}` : method;
  }
}
