import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@portal/shared/pipes';
import { AppPriceComponent } from './app-price.component';

@NgModule({
  imports: [CommonModule, PipesModule],
  declarations: [AppPriceComponent],
  exports: [AppPriceComponent],
})
export class AppPriceModule {}
