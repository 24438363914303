<div class="box">
  <ng-container *ngIf="!isOrganisationTypeStore(); else linkedTokenScope">
    <h2 class="title is-3" i18n>Token scopes</h2>

    <ng-container
      *ngIf="(tokenScopes$ | async).length; then tokenScopeSection; else emptyTokenScopes"
    ></ng-container>

    <ng-container *ngIf="isCreateMode">
      <div class="box__separator"></div>

      <div class="content">
        <portal-create-token-scope
          (closed)="onClose()"
          (saved)="onSave($event)"
          [saving]="saving"
        ></portal-create-token-scope>
      </div>
    </ng-container>

    <button
      type="button"
      class="button is-primary is-outlined"
      data-e2e="add-new-token-scope-button"
      *ngIf="!isCreateMode"
      (click)="onCreate()"
      [disabled]="!canAddTokenScope()"
      i18n
      title="Add new token scope"
      i18n-title
    >
      Add new token scope
    </button>
    <p class="help" *ngIf="!canAddTokenScope()" i18n>Entity type should be Merchant company</p>
  </ng-container>
</div>

<ng-template #emptyTokenScopes>
  <p class="content" i18n data-e2e="empty-token-scopes">
    There are no token scopes created for this organisation
  </p>
</ng-template>

<ng-template #tokenScopeSection>
  <ng-container *ngFor="let tokenScope of tokenScopes$ | async; trackBy: trackById">
    <div class="level">
      <div class="level-left token-scope-name">
        <p class="has-text-truncate">{{ tokenScope.name }} / {{ tokenScope.tokenScopeUid }}</p>
      </div>
      <div class="level-right buttons">
        <button
          class="button is-primary is-outlined"
          (click)="onEdit(tokenScope.tokenScopeUid)"
          data-e2e="edit-token-scope-button"
          type="button"
          title="Edit token scope"
          i18n-title
        >
          <span class="icon">
            <i class="far fa-edit" aria-hidden="true"></i>
          </span>
        </button>

        <button
          class="button is-danger is-primary is-outlined"
          (click)="onDelete(tokenScope)"
          data-e2e="delete-token-scope-button"
          type="button"
          title="Delete token scope"
          i18n-title
        >
          <span class="icon">
            <i class="far fa-trash" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>
    <ng-container *ngIf="editModeState.has(tokenScope.tokenScopeUid)">
      <div class="box__separator"></div>
      <div class="content">
        <portal-update-token-scope
          [tokenScope]="tokenScope"
          (closed)="onClose($event)"
        ></portal-update-token-scope>
      </div>
      <div class="box__separator"></div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #linkedTokenScope>
  <portal-linked-token-scope
    [organisation]="organisation"
    (unlinked)="unlink($event)"
  ></portal-linked-token-scope>
</ng-template>

<portal-modal
  #confirmModal
  i18n-title="Modal dialog title (deleting a token scope)"
  title="Be careful!"
  i18n-description
  description="If you delete a token scope, all tokens in this scope will be deleted."
  (confirmed)="onModalConfirmed($event)"
>
  <div class="level-right">
    <button
      class="level-item button is-primary is-outlined"
      (click)="confirmModal.close()"
      data-e2e="keep-token-scope-button"
      type="button"
      i18n
      title="Keep Token scope"
      i18n-title
    >
      Keep Token scope
    </button>
    <button
      class="button is-danger"
      (click)="confirmModal.confirm()"
      data-e2e="confirm-button"
      type="button"
      i18n
      title="Delete Token scope"
      i18n-title
    >
      Delete Token scope
    </button>
  </div>
</portal-modal>
