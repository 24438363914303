import { Component } from '@angular/core';
import { FILTER_CONTENT_ITEM, SearchSelector } from '@portal/shared/ui/filter';
import { FilterStaticBase } from '@portal/shared/ui/filter/src/lib/components/static-base';
import { HeaderStates } from './header-state.list';

@Component({
  selector: 'portal-wl-filter-header-state',
  templateUrl: './header-state.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: WLFilterHeaderStateComponent,
    },
  ],
})
export class WLFilterHeaderStateComponent extends FilterStaticBase {
  constructor(headerStates: HeaderStates) {
    super(SearchSelector.DisplayTransactionState, headerStates.list);
  }
}
