import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StatusIndicatorModule } from '../../shared/status-indicator/status-indicator.module';
import { AppDetailsTemplateComponent } from './app-details-template.component';

@NgModule({
  imports: [CommonModule, StatusIndicatorModule],
  exports: [AppDetailsTemplateComponent],
  declarations: [AppDetailsTemplateComponent],
})
export class AppDetailsTemplateModule {}
