import { Injectable } from '@angular/core';
import { FileType, IApp, IAppFile, IDevApp } from '@portal/marketplace/api-interfaces';
import { SortVersionsPipe } from '@portal/shared/pipes';

@Injectable({
  providedIn: 'root',
})
export class DevAppsTransformerService {
  constructor(private sortVersions: SortVersionsPipe) {}

  transformIDevAppToIApp(apps: IDevApp[]): IApp[] {
    const appsArray: IApp[] = [];
    for (const app of apps) {
      const appVersionApp: IApp = app.latestAppVersion ? app.latestAppVersion : app;
      const iApp: IApp = {
        app: {
          id: app.latestAppVersion ? app.id : app['app']?.id,
          developerAppId: app.latestAppVersion ? app.developerAppId : app['app']?.developerAppId,
          developerOrg: app.latestAppVersion ? app.developerOrg : app['app']?.developerOrg,
          latestAppVersionId: app.latestAppVersionId,
        },
        id: appVersionApp.id,
        version: appVersionApp.version,
        appName: appVersionApp.appName,
        status: appVersionApp.status,
        description: appVersionApp.description,
        developerId: appVersionApp.developerId,
        numberOfDownloads: appVersionApp.numberOfDownloads,
        totalSales: appVersionApp.totalSales,
        revenue: appVersionApp.revenue,
        numberOfCurrentInstallations: appVersionApp.numberOfCurrentInstallations,
        shortDescription: appVersionApp.shortDescription,
        //inAppPayments: appVersionApp.inAppPayments,
        apks: appVersionApp.apks,
        screenshots: appVersionApp.screenshots,
        appIcons: appVersionApp.appIcons,
        qsaReports: appVersionApp.qsaReports,
        userGuides: appVersionApp.userGuides,
        privacyPolicy: appVersionApp.privacyPolicy,
        supportContactInfo: appVersionApp.supportContactInfo,
        pricingModel: appVersionApp.pricingModel,
        paymentModel: appVersionApp.paymentModel,
        minimumPrice: appVersionApp.minimumPrice,
        isDefaultApp: appVersionApp.isDefaultApp,
        categories: appVersionApp.categories,
        modelFamilies: appVersionApp.modelFamilies,
        appSupportEmail: appVersionApp.appSupportEmail,
        appSupportWebsite: appVersionApp.appSupportWebsite,
        appSupportContactNumber: appVersionApp.appSupportContactNumber,
        appTermOfUse: appVersionApp.appTermOfUse,
        updatedDate: appVersionApp.updatedDate,
        createdDate: appVersionApp.createdDate,
        modifiedBy: appVersionApp.modifiedBy,
        testingInstructions: appVersionApp.testingInstructions,
        releaseNotes: appVersionApp.releaseNotes,
        appVersions: app.appVersions ? this.sortVersions.transform(app.appVersions) : [],
        newVersionAllowed: appVersionApp.newVersionAllowed,
      };
      appsArray.push(iApp);
    }
    return appsArray;
  }

  transformFiles(appID: string, files: IAppFile[]): IApp {
    const app: IApp = { id: appID };
    if (files[0].fileType === FileType.APK) {
      app.apks = files;
    }
    if (files[0].fileType === FileType.QSA_REPORT) {
      app.qsaReports = files;
    }
    if (files[0].fileType === FileType.USER_GUIDE) {
      app.userGuides = files;
    }
    if (files[0].fileType === FileType.APP_ICON) {
      app.appIcons = files;
    }
    if (files[0].fileType === FileType.SCREENSHOT) {
      app.screenshots = files;
    }

    return app;
  }
}
