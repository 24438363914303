import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@portal/shared/pipes';
import { ProgressBarComponent } from './progress-bar.component';

@NgModule({
  imports: [CommonModule, PipesModule],
  declarations: [ProgressBarComponent],
  exports: [ProgressBarComponent],
})
export class ProgressBarModule {}
