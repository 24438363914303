import { Directive, OnDestroy, ElementRef, AfterViewChecked } from '@angular/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class Autoscroll implements AfterViewChecked, OnDestroy {
  private isListenerAdded = false;
  private submitButton: HTMLButtonElement;
  private boundSubmitClickHandler = this.submitClickHandler.bind(this);

  constructor(protected elementRef?: ElementRef) {}

  ngAfterViewChecked(): void {
    if (!this.isListenerAdded) {
      this.addSubmitListener();
    }
  }

  ngOnDestroy(): void {
    this.removeSubmitListener();
  }

  protected addSubmitListener(): void {
    if (this.elementRef) {
      this.removeSubmitListener();
      this.submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
      this.submitButton?.addEventListener('click', this.boundSubmitClickHandler);
      this.isListenerAdded = Boolean(this.submitButton);
    }
  }

  private removeSubmitListener(): void {
    this.submitButton?.removeEventListener('click', this.boundSubmitClickHandler);
    this.isListenerAdded = false;
  }

  /**
   * Event handler is added on click to submit button instead in submit method
   * since certain pages do not submit the form in case when it is invalid
   */
  private submitClickHandler({ currentTarget }: Record<'currentTarget', HTMLButtonElement>): void {
    const formElement = currentTarget.form || this.elementRef.nativeElement || document;
    const invalidControl = formElement.querySelector('.ng-invalid') as HTMLElement;

    if (invalidControl) {
      invalidControl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      let focusElement = formElement.querySelector(
        '.ng-pristine.ng-invalid.ng-touched, ' +
          '.ng-invalid.ng-touched.is-danger, ' +
          '.is-invalid, ' +
          '.is-danger, ' +
          'ng-pristine.ng-invalid.ng-touched, ' +
          'ng-pristine.ng-invalid.ng-touched.is-danger, ' +
          'ng-pristine.ng-invalid.is-danger.ng-touched, ' +
          'input.ng-pristine.ng-invalid.is-danger.ng-touched, ' +
          'ng-select-searchable.ng-pristine.ng-invalid.ng-touched, ' +
          '.ng-touched .ng-dirty .ng-invalid' +
          '.is-danger[type="tel"]' +
          '.ng-invalid.ng-touched',
      );
      if (focusElement && (focusElement as HTMLInputElement).type === 'hidden') {
        focusElement = formElement.querySelector('.ng-invalid.ng-touched:not([type="hidden"])');
      } else if (focusElement && focusElement.tagName.toLowerCase() === 'ng-select') {
        const innerInput = focusElement.querySelector('input[type="text"]');
        innerInput?.focus();
        return;
      }
      focusElement?.focus();
    }
  }
}
