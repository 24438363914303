export enum LegalEntityType {
  CIC = 'CIC',
  CIO = 'CIO',
  CHARITY = 'CHARITY',
  COOP = 'COOP',
  GENERAL_PARTNERSHIP = 'GENERAL_PARTNERSHIP',
  INDIVIDUAL = 'INDIVIDUAL',
  LIMITED_LIABILITY_PARTNERSHIP = 'LIMITED_LIABILITY_PARTNERSHIP',
  LIMITED_PARTNERSHIP = 'LIMITED_PARTNERSHIP',
  PRIVATE_LIMITED_COMPANY = 'PRIVATE_LIMITED_COMPANY',
  PUBLIC_LIMITED_COMPANY = 'PUBLIC_LIMITED_COMPANY',
  SCOTTISH_LIMITED_PARTNERSHIP = 'SCOTTISH_LIMITED_PARTNERSHIP',
  SOLE = 'SOLE',
}
