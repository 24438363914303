import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { QuickPicks } from '../enums/date.enum';
declare const $localize;

@Injectable({ providedIn: 'root' })
export class DateRangeQuickpicks extends SelectInputsList {
  keyValue = {
    [QuickPicks.Today]: $localize`Today`,
    [QuickPicks.Yesterday]: $localize`Yesterday`,
    [QuickPicks.LastWeek]: $localize`Last week`,
    [QuickPicks.LastMonth]: $localize`Last month`,
    [QuickPicks.Last7Days]: $localize`Last 7 days`,
    [QuickPicks.Last30Days]: $localize`Last 30 days`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
