<div class="container" data-e2e="marketplace-tags-canvas">
  <div *ngIf="tags.length === 0 && !isTagFilerAction()" class="box" data-e2e="no-data">
    <section class="section">
      <ng-content selec=".container"></ng-content>
    </section>
  </div>

  <div
    *ngIf="isTagFilerAction() || tags.length > 0"
    class="box field is-grouped is-grouped-multiline"
  >
    <ng-container *ngFor="let tag of tags">
      <marketplace-tag
        [tag]="tag"
        *ngIf="isAction()"
        (tagAction)="onTagAction(tag)"
      ></marketplace-tag>
      <marketplace-tag [tag]="tag" *ngIf="!isAction()"></marketplace-tag>
    </ng-container>
  </div>

  <!-- <marketplace-tag
      *ngIf="isTagFilerAction()"
      class="is-bordered add-action"
      [tag]="selectTag"
      (tagAction)="selectAction($event)"
    ></marketplace-tag> -->

  <!-- TODO: this can be extracted as a filter -->
  <div
    *ngIf="isTagFilerAction()"
    class="dropdown"
    [ngClass]="{ 'is-active': isDropdownOpen == true }"
  >
    <div class="dropdown-trigger">
      <marketplace-tag
        class="is-bordered add-action"
        [tag]="{ name: 'Select Tag' }"
        (click)="toggleDropdown($event)"
        (tagAction)="NOP($event)"
      ></marketplace-tag>
    </div>
    <div class="dropdown-menu" id="dropdown-tag" role="menu">
      <div class="dropdown-content" (click)="onSearchFieldClick($event)">
        <div class="dropdown-item">
          <div class="field">
            <portal-search-filter-autocomplete-input
              *ngIf="isDropdownOpen"
              (search)="onSearch($event)"
            ></portal-search-filter-autocomplete-input>
          </div>
        </div>
        <ul *ngIf="searchTags.length" class="menu-list">
          <li *ngFor="let tag of searchTags">
            <a class="marketplace-tag" data-e2e="result-tag">
              <marketplace-tag [tag]="tag" (click)="selectTag(tag)"></marketplace-tag>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <span
    *ngIf="isTagFilerAction() && tags.length"
    class="button is-transparent clear-all"
    (click)="clearTags()"
    data-e2e="clear-all"
    >Clear All</span
  >
  <!-- INFO: Possible tags -->
  <!-- <div class="control">
            <span class="tag is-light is-marginless is-danger is-rounded">
                Technology
                <button class="delete"></button>
            </span>
        </div>

        <div class="control">
            <span class="tag is-light is-marginless is-warning is-rounded">
                CSS
                <button class="delete"></button>
            </span>
        </div>

        <div class="control">
            <span class="tag is-light is-marginless is-success is-rounded">
                Flexbox
                <button class="delete"></button>
            </span>
        </div>

        <div class="control">
            <span class="tag is-light is-marginless is-primary is-rounded">
                Web Design
                <button class="delete"></button>
            </span>
        </div>

        <div class="control">
            <span class="tag is-light is-marginless is-info is-rounded">
                Community
                <button class="delete"></button>
            </span>
        </div>

        <div class="control">
            <span class="tag is-light is-marginless is-rounded">
                Development
                <button class="delete"></button>
            </span>
        </div> -->
</div>
