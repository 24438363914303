import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AppCatalogCardsTemplateModule,
  AppCatalogTableTemplateModule,
} from '@portal/marketplace/templates';
import { BreadcrumbsModule } from '@portal/shared/ui/breadcrumbs';
import { PublicMainComponent } from './public-main.component';
import { PipesModule } from '@portal/shared/pipes';
import { CardsTableTemplateModule } from '../../../../../templates/src/lib/ui/cards-table-template/cards-table-template.module';

@NgModule({
  imports: [
    CommonModule,
    AppCatalogCardsTemplateModule,
    BreadcrumbsModule,
    CardsTableTemplateModule,
    AppCatalogTableTemplateModule,
    PipesModule,
  ],
  declarations: [PublicMainComponent],
  exports: [PublicMainComponent],
  providers: [CurrencyPipe],
})
export class PublicMainModule {}
