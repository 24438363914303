import { Directive, ViewChild } from '@angular/core';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '@portal/marketplace/api-interfaces/src';
import { PaginationSortHandler } from '@portal/shared/helpers/src';
import { IFilterToApply } from '@portal/shared/ui/filter/src';
import { OffCanvasComponent } from '@portal/shared/ui/off-canvas';
import { IQueryParams } from '@portal/shared/ui/table/src';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class IndexBaseComponent extends PaginationSortHandler {
  @ViewChild('sidePanel') sidePanel: OffCanvasComponent;
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  initialFilter: IQueryParams = {
    start: 0,
    limit: DEFAULT_PAGE_SIZE,
  };

  searchAndFilters: IFilterToApply[] = [];

  private _viewType: 'Cards' | 'Table' = 'Cards';

  get viewType(): 'Cards' | 'Table' {
    return this._viewType;
  }

  set viewType(val) {
    this._viewType = val;
  }

  private _filterApps: 'All' | 'Updates' = 'All';

  get filterApps(): 'All' | 'Updates' {
    return this._filterApps;
  }

  set filterApps(val) {
    this._filterApps = val;
  }

  constructor() {
    super();
    this.filter = this.initialFilter;
  }

  switchView(type): void {
    this.viewType = type;
  }

  switchFilter(type): void {
    this.filter = type;
  }
  // DEPRECATED, this method has no sense, attribute should be configured with filter
  checkIfIsFeaturedFilter(filters: IFilterToApply[]): IFilterToApply[] {
    const arrayCopy = JSON.parse(JSON.stringify(filters));
    for (const filter of arrayCopy) {
      if (filter.selector === 'isFeatured') {
        filter.argument = 'true';
      }
    }
    return arrayCopy;
  }
}
