<form
  [formGroup]="form"
  (ngSubmit)="submit()"
  (keyup.Tab)="onTabKeyNavigation()"
  (keyup.shift.tab)="onTabKeyNavigation()"
>
  <div class="box">
    <h2 class="title is-3" i18n>General details</h2>

    <label class="field" *ngIf="!isUserNameToCreate && !this.isNamewithNewConfig">
      <div class="label" i18n>Full name</div>
      <div class="control">
        <input
          #fullNameInput
          class="input"
          [formControl]="name"
          data-e2e="input-full-name"
          type="text"
          [ngClass]="{ 'is-danger': isInvalid(name) }"
        />
        <portal-form-error [formInput]="name" errorId="name"></portal-form-error>
      </div>
      <ng-container *ngIf="!isNamewithNewConfig">
        <div class="has-text-grey is-size-7 pl-1">
          <span *ngIf="!isUserNameToUpdate" i18n
            >The full name is now created by using the first, middle, and last name for each
            user.</span
          >
          <span *ngIf="isUserNameToUpdate" i18n
            >The full name will not be displayed once you save the user with the new
            configuration.</span
          >
        </div>
        <div class="is-size-7 pl-1">
          <a
            (click)="setIsUserNameToUpdate()"
            *ngIf="!isUserNameToUpdate"
            i18n
            title="Update user name"
            i18n-title
            >Click here if you want to update the user's name.</a
          >
        </div>
      </ng-container>
    </label>

    <ng-container *ngIf="isUserNameToUpdate || isUserNameToCreate">
      <label class="field">
        <div class="label" i18n>First name</div>
        <div class="control">
          <input
            #firstNameInput
            class="input"
            [formControl]="firstName"
            data-e2e="input-first-name"
            type="text"
            [ngClass]="{ 'is-danger': isInvalid(firstName) }"
          />
          <portal-form-error [formInput]="firstName" errorId="firstName"></portal-form-error>
        </div>
      </label>

      <label class="field">
        <div class="label">
          <span i18n> Middle name </span>
          <span>
            &nbsp;&nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
          </span>
        </div>
        <div class="control">
          <input
            class="input"
            [formControl]="middleName"
            data-e2e="input-middle-name"
            type="text"
            [ngClass]="{ 'is-danger': isInvalid(middleName) }"
          />
          <portal-form-error [formInput]="middleName" errorId="middleName"></portal-form-error>
        </div>
      </label>

      <label class="field">
        <div class="label">
          <span i18n>Last name</span>
          <span>
            &nbsp;&nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
          </span>
        </div>
        <div class="control">
          <input
            class="input"
            [formControl]="lastName"
            data-e2e="input-last-name"
            type="text"
            [ngClass]="{ 'is-danger': isInvalid(lastName) }"
          />
          <portal-form-error [formInput]="lastName" errorId="lastName"></portal-form-error>
        </div>
      </label>
    </ng-container>

    <label class="field">
      <div class="label" i18n>Email</div>
      <div class="control">
        <input
          class="input"
          [formControl]="email"
          data-e2e="input-email"
          type="text"
          [ngClass]="{ 'is-danger': isInvalid(email) }"
        />
        <portal-form-error [formInput]="email" errorId="email"></portal-form-error>
      </div>
    </label>

    <ng-container *ngIf="user && resetPasswordFeatureActive && !isMyProfile && !federationStatus">
      <button
        *restrictPermission="['reset-password']"
        type="button"
        class="field button is-primary is-outlined"
        [disabled]="disabled"
        (click)="resetPasswordModal.open()"
        data-e2e="button-reset-password"
        i18n
        title="Send reset password email"
        i18n-title
      >
        Send reset password email
      </button>
    </ng-container>

    <label class="field" *ngIf="!loadingDefaultCountry">
      <div class="label" i18n>
        Phone Number
        <span class="has-text-grey has-text-weight-normal">(Optional)</span>
      </div>
      <div class="control">
        <portal-int-tel-input
          [formControl]="phoneNumberControl"
          [class.is-danger]="isInvalid(phoneNumberControl)"
          [defaultCountryCode]="defaultCountryCode"
        ></portal-int-tel-input>
      </div>
    </label>

    <label class="field" data-e2e="field-locale" *ngIf="canSwitchLanguage">
      <div class="label" i18n>Interface language</div>
      <portal-filter-language
        [languages]="languagesList"
        [label]="selectedLanguage?.name"
        [selectedLanguage]="selectedLanguage"
        [hideApplyButton]="hideApplyButton"
        [isLanguageButtonFocused]="isLanguageButtonFocused"
        (languageSelected)="onSelectLanguage($event)"
      ></portal-filter-language>
    </label>

    <div data-e2e="default-application" *ngIf="selfEditMode && apps.length > 1">
      <portal-select-control
        name="Default Application"
        [control]="defaultApplication"
        [invalid]="isInvalid(defaultApplication)"
        [options]="appList"
        [e2eName]="'select-application'"
        [e2eErrorName]="'select-application-error'"
        class="field"
        i18n-name
      ></portal-select-control>
    </div>

    <div class="box__separator"></div>

    <h2 class="title is-3" i18n>Access rights</h2>

    <label class="field">
      <div *ngIf="isPetroUser" class="label" i18n>Organization</div>
      <div *ngIf="!isPetroUser" class="label" i18n>Organisation</div>
      <portal-hierarchy-single-select
        [control]="entityUid"
        data-e2e="user-hierarchy-select"
        (selectedOrganisation)="onOrganisationSelected($event)"
      ></portal-hierarchy-single-select>
      <portal-form-error [formInput]="entityUid" errorId="entityUid"></portal-form-error>
    </label>

    <div class="has-margin-top-2x" *ngIf="!this.selfEditMode && loggedInUser">
      <portal-role-group-picker
        [user]="user"
        [loggedInUser]="loggedInUser"
        [disabled]="disabled"
        [allRoleGroups]="editableRoles"
        [isVerifoneEmail]="isVerifoneEmail"
        (selectedRoles)="onSetRoles($event)"
        data-e2e="user-role-picker"
      ></portal-role-group-picker>
      <portal-form-error [formInput]="roles" errorId="roles"></portal-form-error>
    </div>

    <ng-container *ngIf="toShowDeveloperNotificationSettings()">
      <div class="box__separator"></div>
      <h2 class="title is-3">Email Notifications Setting</h2>
      <div class="field">
        <div class="toggle">
          <input
            id="user-app-notification-setting"
            type="checkbox"
            [checked]="notificationUpdateStatus"
            (change)="onNotificationUpdate($event)"
            data-e2e="user-app-notification-setting"
          />
          <label for="user-app-notification-setting" data-e2e="user-app-notification-setting-label"
            >Receive email notifications when app status changes</label
          >
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isMyProfile && !federationStatus">
      <ng-container *ngIf="resetPasswordFeatureActive; else redirectResetPassword">
        <div class="box__separator"></div>
        <h2 class="title is-3" i18n>Password</h2>
        <a
          class="button is-primary is-outlined"
          [routerLink]="['/administration', 'users', user?.userUid, 'edit', 'change-password']"
          data-e2e="change-password"
          i18n
          title="Change password"
          i18n-title
        >
          Change password
        </a>
      </ng-container>

      <ng-template #redirectResetPassword>
        <div class="box__separator"></div>
        <h2 class="title is-3" i18n>Change password</h2>
        <p class="label has-text-weight-normal" i18n>
          You will be redirected to a secure page to change your password.
        </p>
        <button
          type="button"
          class="button"
          (click)="changePasswordModal.open()"
          data-e2e="button-change-password"
          i18n
          title="Change password"
          i18n-title
        >
          Change password
        </button>
      </ng-template>
    </ng-container>
  </div>

  <div
    class="columns is-vcentered is-flex is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
  >
    <div class="column">
      <ng-container *ngIf="!user">
        <a
          class="button has-text-danger is-text"
          data-e2e="link-cancel"
          restrict-permission
          [routerLink]="['/administration', 'users']"
          i18n
          title="Cancel"
          i18n-title
        >
          Cancel
        </a>
      </ng-container>
      <ng-container *ngIf="user">
        <a
          class="button has-text-danger is-text"
          data-e2e="link-cancel"
          restrict-permission
          [routerLink]="['/administration', 'users', user?.userUid]"
          i18n
          title="Discard changes"
          i18n-title
        >
          Discard changes
        </a>
      </ng-container>
    </div>
    <div class="column has-text-right">
      <button
        type="submit"
        class="button is-primary"
        [class.is-loading]="saving"
        data-e2e="button-submit"
        (click)="checkFirstName()"
        [disabled]="!canSaveUser || saving"
        title="Save"
        i18n-title
      >
        <span *ngIf="!user" i18n>Save</span>
        <span *ngIf="user" i18n>Save changes</span>
      </button>
    </div>
  </div>
</form>

<portal-base-modal #resetPasswordModal>
  <div title i18n>Reset password</div>
  <div description i18n>
    The user will receive an email and it will be redirected to a secure page to change his
    password.
  </div>
  <div class="level" buttons>
    <div class="level-left"></div>
    <div class="buttons">
      <button
        class="button"
        data-e2e="cancel-reset-password-button"
        (click)="resetPasswordModal.close()"
        i18n
        title="Cancel"
        i18n-title
      >
        Cancel
      </button>
      <button
        type="button"
        class="button is-primary"
        (click)="onResetPasswordModalConfirmed()"
        data-e2e="confirm-reset-password-button"
        i18n
        title="Confirm"
        i18n-title
      >
        Confirm
      </button>
    </div>
  </div>
</portal-base-modal>
<portal-base-modal #changePasswordModal>
  <div title i18n>Redirect notice</div>
  <div description i18n>
    You will be redirected to a secure page to change your password. Make sure to save changes
    before continuing.
  </div>
  <div class="level" buttons>
    <div class="level-left"></div>
    <div class="buttons">
      <button
        class="button"
        data-e2e="cancel-change-password-button"
        (click)="changePasswordModal.close()"
        i18n
        title="Cancel"
        i18n-title
      >
        Cancel
      </button>
      <button
        type="button"
        class="button is-primary"
        (click)="onChangePasswordModalConfirmed()"
        data-e2e="confirm-change-password-button"
        i18n
        title="Confirm"
        i18n-title
      >
        Confirm
      </button>
    </div>
  </div>
</portal-base-modal>
