export enum BlockingRulesetsTransactionProperties {
  Amount = 'amount',
  CurrencyCode = 'currencyCode',
  CustomerId = 'customerId',
  CustomerCountryCode = 'customerCountryCode',
  CustomerIP = 'customerIP',
  CustomerIPCountryCode = 'customerIPCountryCode',
  IssuerCountryCode = 'issuerCountryCode',
  EntityId = 'entityId',
  ThreeDSecureEnrollmentStatus = 'threeDSecureEnrollmentStatus',
  ThreeDSecureAuthenticationStatus = 'threeDSecureAuthenticationStatus',
  ThreeDSecureErrorNo = 'threeDSecureErrorNo',
  FraudScore = 'fraudScore',
}
