<div
  [ngClass]="[
    isDropUp ? 'dropdown is-up' : 'dropdown',
    isProductQuantityAvailable ? 'product-quantity' : ''
  ]"
  (portalClickOutside)="onOusideclick()"
  [class.is-active]="isOpen"
>
  <ng-container
    *ngIf="{
      isSmall: isSmall(),
      isMiddle: isMiddle(),
      isLarge: isLarge()
    } as size"
  >
    <div class="dropdown-trigger">
      <button
        [attr.aria-expanded]="isOpen"
        class="button"
        type="button"
        [ngClass]="{
          'is-small': size.isSmall && !isMiddleButtonAndSmallDropdown,
          'is-middle': size.isMiddle || isMiddleButtonAndSmallDropdown,
          'is-large': size.isLarge,
          'is-addon': isAddon,
          'is-chip': isChip,
          'has-background-light': isLightGrey
        }"
        [class.has-background-light]="isLightGrey"
        (click)="toggleDropdown()"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        title="{{ label }}"
      >
        <span>{{ label }}</span>
        <span class="icon">
          <i
            [ngClass]="[isDropUp ? 'fas fa-chevron-up' : 'fas fa-chevron-down']"
            aria-hidden="true"
          ></i>
        </span>
      </button>
    </div>
    <div
      class="dropdown-menu"
      [ngClass]="{
        'is-small': size.isSmall || isMiddleButtonAndSmallDropdown,
        'is-middle': size.isMiddle && !isMiddleButtonAndSmallDropdown,
        'is-large': size.isLarge
      }"
      id="dropdown-menu"
      role="menu"
    >
      <div class="dropdown-content">
        <div class="dropdown-item is-scrollable p-0">
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            *ngFor="let entity of entities"
            attr.data-e2e="dropdown-item-{{ entity?.id }}"
            [class.is-active]="entity?.id === selectedItem?.id"
            (click)="onSelectItem(entity)"
            (keydown.enter)="onSelectItem(entity)"
            (keydown.escape)="toggleDropdown()"
            (keydown.space)="onSelectItem(entity, $event)"
          >
            {{ entity.text }}
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
