declare const $localize;

export const WL_NAVBAR_ITEMS_MOCK = [
  {
    title: $localize`Dashboard`,
  },
  {
    title: $localize`Reports`,
    children: [
      {
        title: $localize`Orders/Transactions`,
      },
      {
        title: $localize`3D Secure Authentications`,
      },
      {
        title: $localize`Settlements`,
      },
      {
        title: $localize`Audit Log`,
      },
      {
        title: $localize`Report Scheduler`,
      },
      {
        title: $localize`Generated Reports`,
      },
      {
        title: $localize`Monthly Invoice Summary`,
      },
      {
        title: $localize`Payout`,
      },
      {
        title: 'Payout (ACH)',
      },
      {
        title: 'Monthly Statement',
      },
      {
        title: $localize`Device Management`,
        children: [
          {
            title: $localize`Chain of Custody`,
            href: '/vhq/reports/tracking-management',
          },
          {
            title: $localize`Device Report`,
            href: '/vhq/reports/device-report',
          },
        ],
      },
    ],
  },
  {
    title: $localize`Device Management`,
    children: [
      {
        title: $localize`Devices`,
      },
      {
        title: $localize`Locations`,
      },
      {
        title: $localize`Tenants`,
      },
    ],
  },

  {
    title: $localize`Payment Tools`,
    children: [
      {
        title: $localize`Virtual Terminal`,
      },
      {
        title: $localize`Pay by Link`,
      },
    ],
  },
  {
    title: $localize`Administration`,
    children: [
      {
        title: $localize`Organisations`,
      },
      {
        title: $localize`Payment Provider Contracts`,
      },
      {
        title: $localize`Points of Interaction`,
      },
      {
        title: $localize`3DS Contracts`,
      },
      {
        title: $localize`Users`,
      },
      {
        title: $localize`Wallets`,
      },
      {
        title: $localize`Blocking Rulesets`,
      },
      {
        title: $localize`Added Value Services`,
        children: [
          {
            title: $localize`Fraud Prevention Services`,
          },
        ],
      },
      {
        title: $localize`Price lists`,
      },
      {
        title: $localize`:main menu item|Link in the main menu that opens the Notifications page:Notifications`,
      },
      {
        title: $localize`:main menu item|Link in the main menu that opens the "Checkout Themes" page:Checkout Themes`,
      },
    ],
  },
  {
    title: $localize`Support`,
  },
];
