import { Injectable } from '@angular/core';
import { UserRole } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class UserRoles extends SelectInputsList {
  keyValue = {
    [UserRole.MerchantAdmin]: $localize`Merchant Admin`,
    [UserRole.MerchantReviewer]: $localize`Merchant Reviewer`,
    [UserRole.MerchantSupervisor]: $localize`Merchant Supervisor`,
    [UserRole.MerchantCashier]: $localize`Merchant Cashier`,
    [UserRole.MerchantUser]: $localize`Merchant User`,
    [UserRole.MerchantPOIAdmin]: $localize`Merchant POI Admin`,
    [UserRole.VerifoneAdmin]: $localize`Verifone Admin`,
    [UserRole.VerifoneUser]: $localize`Verifone User`,
    [UserRole.VerifoneFinOpsUser]: $localize`Verifone FinOps User`,
    [UserRole.VerifoneSettlementUser]: $localize`Verifone Settlement User`,
    [UserRole.DeveloperAdmin]: $localize`Developer Admin`,
    [UserRole.DeveloperUser]: $localize`Developer User`,
    [UserRole.VerifoneHelpdesk]: $localize`Verifone HelpDesk`,
    [UserRole.EstateOwnerAdmin]: $localize`Estate Owner Admin`,
    [UserRole.EstateOwnerUser]: $localize`Estate Owner User`,
    [UserRole.EstateOwnerDeviceAppManager]: $localize`Estate Owner Device App Manager`,
    [UserRole.VerifoneSalesManager]: $localize`Verifone Sales Manager`,
    [UserRole.VerifoneSalesRepresentative]: $localize`Verifone Sales Representative`,
    [UserRole.ResellerManager]: $localize`Reseller Manager`,
    [UserRole.ResellerRepresentative]: $localize`Reseller Representative`,
    [UserRole.MerchantUnapproved]: $localize`Merchant Unapproved`,
    [UserRole.PartnerHelpdesk]: $localize`Partner Helpdesk`,
    [UserRole.PartnerFieldServiceProvider]: $localize`Partner Field Service Provider`,
    [UserRole.VerifoneFieldServiceProvider]: $localize`Verifone Field Service Provider`,
    [UserRole.MerchantOrderAdmin]: $localize`Merchant Order Admin`,
    [UserRole.ProviderWhiteLabelManager]: $localize`:user role|Role with permission to define white label settings for provider:Provider White Label Manager`,
    [UserRole.MerchantExternalPartner]: $localize`Merchant External Partner`,
    [UserRole.VerifoneMaster]: $localize`Verifone Master`,
    [UserRole.VerifoneFieldServiceSupervisor]: $localize`Verifone Field Service Supervisor`,
    [UserRole.PartnerFieldServiceSupervisor]: $localize`Partner Field Service Supervisor`,
    [UserRole.PartnerFieldServiceUser]: $localize`Partner Field Service User`,
    [UserRole.PetroLoyalty]: $localize`Loyalty`,
    [UserRole.PetroMobilePayments]: $localize`Mobile Payments`,
    [UserRole.PetroBackOffice]: $localize`Back Office`,
    [UserRole.PetroFuelPriceSign]: $localize`Fuel Price Sign`,
    [UserRole.PetroDvr]: $localize`DVR`,
    [UserRole.PetroPropietaryCard]: $localize`Proprietary Card`,
    [UserRole.PetroCommanderEF]: $localize`Commander EF`,
    [UserRole.PetroInternal]: $localize`Internal`,
    [UserRole.PetroSiteAdmin]: `Petro Site Admin`,
    [UserRole.PetroSiteUser]: `Petro Site User`,
    [UserRole.MajorOilAdmin]: `Major Oil Admin`,
    [UserRole.MajorOilUser]: `Major Oil User`,
    [UserRole.PetroHelpDeskAdmin]: `Petro Helpdesk Admin`,
    [UserRole.PetroSalesforceAdmin]: `Petro Sales Force Admin`,
    [UserRole.PetroMerchantOnboarder]: `Petro Merchant Onboarder`,
    [UserRole.CommanderOnboarder]: `Commander Onboarder`,
    [UserRole.PetroPartnerAdmin]: `Petro Partner Admin`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
