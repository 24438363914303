import { Injectable } from '@angular/core';
import { TokenFormat } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers';
import { ISelectInput } from '@portal/shared/ui/filter';
import { sortBy } from 'lodash';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class TokenFormats extends SelectInputsList {
  keyValue = {
    [TokenFormat.Alphanumeric]: $localize`:token format|:Alphanumeric`,
    [TokenFormat.Numeric]: $localize`:token format|:Numeric`,
  };

  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);
}
