<div [ngClass]="{ dropdown: showFilterSubMenu }">
  <div [ngClass]="{ 'dropdown-menu is-block': showFilterSubMenu }">
    <div
      [ngClass]="isMainDropdownFilter ? 'dropdown' : 'dropdown-content multi-select-filter'"
      [class.is-hidden]="!isOpen && !isMainDropdownFilter"
      [class.is-active]="isOpen && isMainDropdownFilter"
    >
      <a
        *ngIf="!isMainDropdownFilter"
        class="is-hidden"
        (click)="openContent()"
        id="filter-currency-detail"
        data-e2e="button-currency-filter"
        title="Currency filter"
        i18n-title
      >
        <i class="fas fa-chevron-right" aria-hidden="true"></i>
      </a>

      <div class="dropdown-trigger" *ngIf="isMainDropdownFilter">
        <button
          [attr.aria-expanded]="isOpen"
          class="button is-chip is-size-7-mobile"
          [class.is-applied]="getValues().length"
          [class.is-active]="isOpen"
          data-e2e="button-currency-filter"
          (click)="openContent()"
          type="button"
          title="{{ currencyText }} ({{ getValues().length }})"
        >
          <span>{{ currencyText }} ({{ getValues().length }})</span>
          <span class="icon">
            <i class="fas fa-chevron-down"></i>
          </span>
        </button>
      </div>

      <ng-template [ngIf]="!isMainDropdownFilter" #dropdownContent>
        <div class="dropdown-item">
          <a
            *ngIf="!isMainDropdownFilter && !displayFromFilter"
            tabindex="0"
            (click)="backToMenu()"
            (keyup.enter)="backToMenu()"
            data-e2e="button-back-to-menu"
            title="Click to go back"
            i18n-title
          >
            <span class="icon is-small">
              <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
            </span>
            <span i18n>Back</span>
          </a>

          <h2
            *ngIf="!isMainDropdownFilter && !displayFromFilter"
            class="title is-4 has-margin-top has-margin-bottom"
            i18n
          >
            Currency
          </h2>

          <portal-search-filter-autocomplete-input
            [searchInputValue]="searchedText"
            (search)="onSearch($event)"
          ></portal-search-filter-autocomplete-input>
        </div>

        <hr class="dropdown-divider" />

        <ng-container *ngIf="showSelectedEntities.size">
          <div class="dropdown-item is-scrollable">
            <label class="label" i18n="Label for selected filter items">Selected</label>
            <ng-container *ngFor="let entity of showSelectedEntities | keyvalue">
              <portal-search-filter-checkbox
                #preSelectedEntities
                [id]="entity.key"
                [text]="entity.value.text"
                [selectedEntities]="localPreSelectedEntities"
                (removed)="onEntityRemoved($event)"
                (added)="onEntityAdded($event)"
              ></portal-search-filter-checkbox>
            </ng-container>
          </div>

          <hr class="dropdown-divider" />
        </ng-container>

        <div class="dropdown-item is-scrollable">
          <ng-container *ngFor="let entity of entities">
            <portal-search-filter-checkbox
              [id]="entity.id"
              [text]="entity.text"
              [selectedEntities]="localPreSelectedEntities"
              [searchedText]="searchedText"
              (removed)="onEntityRemoved($event)"
              (added)="onEntityAdded($event)"
            ></portal-search-filter-checkbox>
          </ng-container>
        </div>

        <hr class="dropdown-divider" />

        <div class="dropdown-item">
          <button
            class="button is-primary"
            (click)="onFilterAppliedAndEntitiesUpdated()"
            data-e2e="button-apply-filter"
            type="button"
            i18n
            title="Apply"
            i18n-title
          >
            Apply
          </button>
          <button
            class="button is-outlined ml-2"
            data-e2e="button-clear-filter"
            i18n
            (click)="onFilterCleared()"
            (keydown.enter)="onFilterCleared()"
            type="button"
            title="Clear"
            i18n-title
          >
            Clear
          </button>
        </div>
      </ng-template>

      <div *ngIf="isMainDropdownFilter" class="dropdown-menu is-large">
        <div class="dropdown-content">
          <ng-template [ngTemplateOutlet]="dropdownContent"></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
