export enum PaymentModel {
  FREE = 'FREE',
  ONETIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}

export enum PaymentModelTranslation {
  FREE = 'FREE',
  ONETIME = 'ONE TIME',
  RECURRING = 'RECURRING',
}
