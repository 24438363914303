import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FILTER_CONTENT_ITEM, ISelectInput, SearchSelector } from '@portal/shared/ui/filter';
import { FilterStaticBase } from '@portal/shared/ui/filter/src/lib/components/static-base';
import { TransactionFilterList } from '../transaction-filters.list';
import { Currencies } from './currency.list';
import { FiltersService } from '@portal/shared/ui/filter/src/lib/services/filters.service';
import { SupportedProcessorSettingsService } from '@apps/portal/src/app/services/supported-processor-settings.service';
import { TransactionFilter } from '../../../enums/transaction-filter.enum';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'portal-filter-currency',
  templateUrl: './currency.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: FilterCurrencyComponent,
    },
  ],
})
export class FilterCurrencyComponent extends FilterStaticBase {
  @Input() isMainDropdownFilter: boolean;
  @Input() showFilterSubMenu: boolean;
  @Output()
  currencyTextName: EventEmitter<string> = new EventEmitter();

  currencyText = this.transactionfilters.list.find(
    (filter) => filter.id === TransactionFilter.Currency,
  ).text;

  constructor(
    private currencies: Currencies,
    private transactionfilters: TransactionFilterList,
    private filtersService: FiltersService,
    private supportedProcessorSettingService: SupportedProcessorSettingsService,
  ) {
    super(SearchSelector.Currency, currencies.getSupportedCurrencies());
    this.filtersService.actionRemoveFilter$.subscribe((entityToRemove: ISelectInput) => {
      if (SearchSelector.Currency === entityToRemove.key) {
        const selectedEntity = this.localPreSelectedEntities.get(entityToRemove.id);
        if (selectedEntity?.id) {
          this.onEntityRemoved(entityToRemove);
          this.onFilterApplied();
          this.itemToggled.emit();
        }
      }
    });

    this.supportedProcessorSettingService.isProcessorFetched$.subscribe(() => {
      this.setAllEntities(currencies.getSupportedCurrencies());
    });

    this.subMenuComponentSelected$.subscribe((submenu) => {
      if (SearchSelector.Currency.includes(submenu)) {
        this.synchronizeLocalPreSelectedEntities();
      }
      this.updateEntities();
    });

    this.updateEntities$.subscribe(() => {
      if (this.isMainDropdownFilter) {
        this.synchronizeLocalPreSelectedEntities();
      }
      this.updateEntities();
    });

    this.updateCounter$.subscribe(() => {
      const localPreSelected: Map<string, ISelectInput> = new Map();
      if (this.criteriaMapPreSelected.has(SearchSelector.Currency)) {
        const argumentArr = this.getArgumentArray(
          this.criteriaMapPreSelected,
          SearchSelector.Currency,
        );

        if (this.criteriaMapPreSelected.get(SearchSelector.Currency)?.argument) {
          argumentArr?.forEach((argument) => {
            localPreSelected.set(argument, {
              id: argument,
              text: this.currencies.list.find((currency) => currency.id === argument)?.text || '',
            });
          });
          this.localPreSelectedEntities = cloneDeep(localPreSelected);
        } else {
          this.localPreSelectedEntities.clear();
        }
      }
      this.updateEntities();
    });
  }

  onFilterAppliedAndEntitiesUpdated(): void {
    this.entities = this.currencies.list;
    this.onFilterApplied();
    this.updateEntities();
    if (this.isOpen) {
      this.closeSubmenus$.next(true);
    }
    this.currencyTextName.emit(this.currencyText);
    this.isOpen = false;
  }

  private synchronizeLocalPreSelectedEntities(): void {
    if (this.criteriaMapPreSelected?.get(SearchSelector.Currency)?.argument) {
      const argumentArr = this.getArgumentArray(this.selectedEntities, SearchSelector.Currency);

      this.localPreSelectedEntities.clear();
      argumentArr?.forEach((argument) => {
        this.setLocalPreSelectedEntities(
          argument,
          this.currencies.list.find((currency) => currency.id === argument)?.text || '',
        );
      });
    } else {
      this.localPreSelectedEntities.clear();
    }
    this.updateEntities();
  }
}
