import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IApp } from '@portal/marketplace/api-interfaces';
import { AppsService } from '@portal/marketplace/app-catalog';
import { Observable } from 'rxjs/internal/Observable';
import { PublicAppCatalogService } from './state/public-app-catalog.service';

@Component({
  selector: 'marketplace-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
})
export class PublicComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
