import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

declare const $localize;
const noop = (): void => {};

@Component({
  selector: 'portal-color-picker',
  templateUrl: './portal-color-picker.component.html',
  styleUrls: ['./portal-color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PortalColorPickerComponent,
      multi: true,
    },
  ],
})
export class PortalColorPickerComponent implements ControlValueAccessor {
  selectedColor: string;
  fallBackColor = '#ffffff';
  hexColorPattern = /^#?([a-fA-F0-9]{6})$/;
  errorMessages = [];
  isOpened = false;
  acceptBtnText = $localize`Apply`;

  private onChange: (value: any) => void = noop;
  private onTouched: () => void = noop;

  onCpInputChange(data: Record<string, any>): void {
    this.writeValue(data?.color || this.selectedColor);
  }

  toggleDialog(): void {
    this.isOpened = !this.isOpened;
  }

  acceptColor(color: string): void {
    this.selectedColor = color;
    this.isOpened = false;
    this.errorMessages = this.validateHexColor(this.selectedColor);
    this.onChange(this.selectedColor);
  }

  validateHexColor(str: string): string[] {
    const errors = [];
    if (str.length && !str.match(this.hexColorPattern)) {
      errors.push($localize`Hex Color code error`);
    }

    return errors;
  }

  handleInputEvent(event: Event): void {
    event.preventDefault();
    this.errorMessages = this.validateHexColor(this.selectedColor);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(color: any): void {
    this.selectedColor = color;
  }
}
