import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddFilterComponent } from './add-filter.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AddFilterComponent],
  exports: [AddFilterComponent],
})
export class AddFilterModule {}
