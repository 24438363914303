import { ProcessorType } from './processor-type.enum';

export enum CartesBancairesProcessorType {
  BnpParibas = ProcessorType.BnpParibas,
  BnppVersAtos = ProcessorType.BnppVersAtos,
  CreditDuNordVersNatixis = ProcessorType.CreditDuNordVersNatixis,
  CreditMutuel = ProcessorType.CreditMutuel,
  CreditAgricole = ProcessorType.CreditAgricole,
  Sg = ProcessorType.Sg,
  Lcl = ProcessorType.Lcl,
  Cic = ProcessorType.Cic,
  Bred = ProcessorType.Bred,
  CaisseDepargne = ProcessorType.CaisseDepargne,
  CreditDuNordVersAtos = ProcessorType.CreditDuNordVersAtos,
  Hsbc = ProcessorType.Hsbc,
  LaBanquePostal = ProcessorType.LaBanquePostal,
  LesBanquesPopulaires = ProcessorType.LesBanquesPopulaires,
  CmArkea = ProcessorType.CmArkea,
  BanqueChabrieres = ProcessorType.BanqueChabrieres,
  Bfcoi = ProcessorType.Bfcoi,
}
