import { Injectable } from '@angular/core';
import { FilterTypes, IApp, StorePagination } from '@portal/marketplace/api-interfaces/src';
import { IFilterToApply } from '@portal/shared/ui/filter/src';
import { IQueryParams } from '@portal/shared/ui/table/src';
import { Observable, Subject } from 'rxjs';
import { transformAppCatalogAppToIApp } from '../../helpers/app-catalog.helpers';
import { AppCatalogDataService } from '../app-catalog-data.service';
import { IAppQueryMMandDev } from '../apps/apps.query';
import { AppsService } from '../apps/apps.service';
import { IAppStoreMMandDev } from '../apps/apps.store';

@Injectable({
  providedIn: 'root',
})
export class MaretplaceManagerAppCatalogService extends StorePagination {
  private filterCallSubject: Subject<any>;
  constructor(
    private appsService: AppsService,
    private appCatalogDataService: AppCatalogDataService,
    private mmStore: IAppStoreMMandDev,
    public appQueryMMandDev: IAppQueryMMandDev,
  ) {
    super();
  }

  loadMarketPlaceManagerSubmittedApps(): void {
    // this.appCatalogDataService
    //   .getAppsForMarketplaceManager(FilterTypes.SUBMITTED)
    //   .subscribe(apps => {
    //     this.appsService.addDataToAppsStore(transformAppCatalogAppToIApp(apps['data']), 'MM');
    //   });
  }

  loadMarketPlaceManagerApps(
    pagination: IQueryParams,
    searchAndFilters: IFilterToApply[],
    type: string,
  ): void {
    this.mmStore.setLoading(true);
    if (this.checkIfNeedFetchData(this.mmStore, pagination)) {
      this.appCatalogDataService
        .getAppsForMarketplaceManager(pagination, searchAndFilters, type)
        .subscribe((apps) => {
          this.updateStorePagination(
            this.mmStore,
            pagination,
            apps['data'].map((app) => app.latestAppVersion.id),
          );
          this.mmStore.update({ metadata: apps['metadata'] });
          this.mmStore.add(transformAppCatalogAppToIApp(apps['data']));
          this.mmStore.setLoading(false);
          //this.loadMarketPlaceManagerSubmittedApps();
        });
    } else {
      this.mmStore.setLoading(false);
    }
  }
  getApps(pagination: IQueryParams): Observable<IApp[]> {
    const storePagination = this.mmStore.getValue().pagination.pages;
    const idsToReturn = storePagination.get(pagination.start / pagination.limit);
    return this.appQueryMMandDev.selectMany(idsToReturn);
  }
  getPagination(): any {
    return this.mmStore.getValue().pagination;
  }
  reset(): void {
    this.resetPagination(this.mmStore);
  }
}
