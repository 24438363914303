import { Injectable } from '@angular/core';
import { AccountHolderType } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class AccountHolderTypes extends SelectInputsList {
  keyValue = {
    [AccountHolderType.Business]: $localize`Business`,
    [AccountHolderType.Personal]: $localize`Personal`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
