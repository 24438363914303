import { Component } from '@angular/core';
import { FILTER_CONTENT_ITEM, SearchSelector } from '@portal/shared/ui/filter';
import { FilterStaticBase } from '@portal/shared/ui/filter/src/lib/components/static-base';
import { StoredCredentialTypeList } from './stored-credential-type.list';

@Component({
  selector: 'portal-wl-filter-stored-credential-type',
  templateUrl: './stored-credential-type.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: WLStoredCredentialComponent,
    },
  ],
})
export class WLStoredCredentialComponent extends FilterStaticBase {
  constructor(storedCredentialTypeList: StoredCredentialTypeList) {
    super(SearchSelector.StoredCredentialType, storedCredentialTypeList.list);
  }
}
