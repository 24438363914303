export enum ScheduleArgumentName {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export enum ScheduleArgumentValue {
  DAILY = 'day',
  WEEKLY = 'week_day',
  MONTHLY = 'month_day',
}
