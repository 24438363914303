export enum EntityType {
  BANKING_PARTNER = 'BANKING_PARTNER',
  DEVELOPER_COMPANY = 'DEVELOPER_COMPANY',
  MERCHANT_COMPANY = 'MERCHANT_COMPANY',
  MERCHANT_SITE = 'MERCHANT_SITE',
  ESTATE_OWNER = 'ESTATE_OWNER',
  RESELLER = 'RESELLER',
  UNDEFINED = 'UNDEFINED',
  GROUP = 'GROUP',
  MERCHANT_COMPANY_GROUP = 'MERCHANT_COMPANY_GROUP',
}
