<div [hidden]="files.length && !multiple">
  <div class="file has-name">
    <label
      class="file-label box has-text-centered p-4 has-background-light"
      [ngClass]="{ 'has-border-danger has-border-width-2': filesError }"
      portalDragAndDrop
      data-e2e="file-upload-box"
      (fileDropped)="onFileChanged($event)"
      [ngClass]="{
        'has-background-white-bis has-text-grey has-border-top has-border-bottom has-border-right has-border-left has-border-width-2':
          disabled
      }"
    >
      <input
        #fileInput
        data-e2e="file-upload-input"
        type="file"
        [attr.multiple]="multiple"
        class="file-input"
        (change)="onFileChanged($event.target.files)"
        [disabled]="disabled"
        [accept]="acceptableFileTypes"
      />
      <div class="width-full">
        <ng-container *ngIf="!filesError; else errorBlock">
          <span class="icon has-text-link is-size-2" [ngClass]="{ 'has-text-grey': disabled }">
            <i class="fal fa-cloud-upload fa-2x"></i>
          </span>
          <p class="is-size-5" data-e2e="file-upload-title">{{ title }}</p>
        </ng-container>

        <ng-template #errorBlock>
          <span class="help is-danger is-size-5 pb-3">{{ filesError }}</span>
          <button type="button" class="button" i18n title="Upload again" i18n-title>
            Upload again
          </button>
        </ng-template>
      </div>
    </label>
  </div>

  <div class="help mb-3" data-e2e="file-upload-helper-text">
    <p class="mb-0" i18n>Accepted file types: {{ acceptableFileTypes }}</p>
    <p *ngIf="maxFileSizeInKb" i18n>Maximum upload file size: {{ maxFileSizeInKb }} KB</p>
  </div>
</div>

<ng-container *ngFor="let file of files">
  <div class="box p-0 is-flex is-justify-content-center" *ngIf="imgSrc">
    <img data-e2e="file-upload-image" [src]="imgSrc" alt="{{ file.name }}" />
  </div>

  <portal-file
    *ngIf="file"
    [file]="file"
    [disabled]="disabled"
    (deleted)="onFileDeleted($event)"
  ></portal-file>
  <div class="help is-danger">
    <p *ngIf="control.errors">{{ getErrorMessage(file.name) }}</p>
  </div>
  <button
    type="button"
    class="button is-text has-text-primary is-borderless"
    (click)="uploadAgain(file)"
    [attr.data-e2e]="'file-upload-again-' + file.name"
    [disabled]="disabled"
    i18n
    title="Upload again"
    i18n-title
  >
    Upload again
  </button>
</ng-container>
