import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DateFilter } from '@portal/shared/helpers';
import { FilterContentItem } from '@portal/shared/ui/filter/src/lib/content/item';
import { FILTER_CONTENT_ITEM } from '@portal/shared/ui/filter/src/lib/content/token';
import { endOfDay, startOfDay } from 'date-fns';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ISelectInput } from '../../box/interfaces/select-input.interface';
import { ISearchCriteria } from '../../search/interfaces/search-criteria.interface';
import { Application, AuthorizationService } from '@portal/shared/auth/authorization';
import { IFilterToApply } from '../../search/interfaces/filter-to-apply.interface';
import { DateTypeEnum } from '../../enums/date-types';
declare const $localize;
@UntilDestroy()
@Component({
  selector: 'portal-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: DateFilterComponent,
    },
  ],
})
export class DateFilterComponent extends FilterContentItem implements OnInit, AfterContentInit {
  @Input() entitiesAdded$ = new BehaviorSubject<ISelectInput[]>(null);
  @Input() selectedEntities: any;
  @Input() selector: string;
  @Input() selectedValue: string;
  @Input() entityRemoved$ = new Subject<ISelectInput>();
  @Input() searchInput = false;
  @Input() filterName: string;
  @Input() inLocalTime = false;
  @Input() isInputDisabled: boolean = true;
  @Input() isFromVhqDate: boolean = false;

  @Output() filterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();
  @Output() selectedFilterValue: EventEmitter<string> = new EventEmitter();
  @Output() filterApplied: EventEmitter<any> = new EventEmitter();

  itemToggled: EventEmitter<number> = new EventEmitter();
  isOpen = false;

  selectedRadioButton: Map<string, ISelectInput> = new Map();
  searchedText: string;
  showSelectedEntities: Map<string, ISelectInput> = new Map();
  dateHeaderText = '';
  dateTypes = DateTypeEnum;
  selectedKey = DateTypeEnum.Custom;
  dateTypeSelection = DateTypeEnum.Custom;
  selectedDateType = new Map([
    [this.dateTypeSelection, { id: this.dateTypeSelection, text: this.dateTypeSelection }],
  ]);
  dateTypeSelections = [
    {
      id: 'Custom',
      text: $localize`Custom  date`,
      key: 'Custom',
    },
  ];

  selectors: ISelectInput[] = [
    {
      id: `${DateFilter.Today}`,
      text: $localize`Today`,
      key: 'DATE_RANGE_TODAY',
    },
    {
      id: `${DateFilter.Yesterday}`,
      text: $localize`Yesterday`,
      key: 'DATE_RANGE_YESTERDAY',
    },
    {
      id: `${DateFilter.LastWeek}`,
      text: $localize`Last week`,
      key: 'DATE_RANGE_LAST_WEEK',
    },
    {
      id: `${DateFilter.LastMonth}`,
      text: $localize`Last month`,
      key: 'DATE_RANGE_LAST_MONTH',
    },
    {
      id: `${DateFilter.Last7Days}`,
      text: $localize`Last 7 days`,
      key: 'DATE_RANGE_7_DAYS',
    },
    {
      id: `${DateFilter.Last30Days}`,
      text: $localize`Last 30 days`,
      key: 'DATE_RANGE_30_DAYS',
    },
  ];

  fromDate: NgbDate;
  toDate: NgbDate;

  radioName = 'createdSettledDate';

  constructor(
    public formatter: NgbDateParserFormatter,
    public calendar: NgbCalendar,
    public authService: AuthorizationService,
  ) {
    super();
    //predefined date is not required for petro for now.
    if (!this.authService.isApplicationUser(Application.Petro)) {
      this.dateTypeSelections.push({
        id: 'Predefined',
        text: $localize`Predefined  Date`,
        key: 'Predefined',
      });
    }
  }

  ngOnInit(): void {
    if (!this.selectedEntities) {
      this.selectedEntities = new Map();
    }
    // Pre select from and to date
    if (this.selectedEntities && this.selectedEntities.size) {
      Array.from(this.selectedEntities.values()).forEach((input: ISearchCriteria) => {
        const date = new Date(input.argument);
        const dateAfterFormatted = NgbDate.from({
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        });

        if (input.selector.includes('From')) {
          this.fromDate = dateAfterFormatted;
        } else {
          this.toDate = dateAfterFormatted;
        }
      });
      this.updateDateHeaderText();
    }

    this.entityRemoved$.subscribe((entityToRemove: ISelectInput) => {
      if (entityToRemove.selector === this.selector && this.fromDate && this.toDate) {
        this.fromDate = null;
        this.toDate = null;
        this.filterUpdated.emit({
          key: this.selector,
          selector: this.selector,
          toRemove: true,
        });
        this.selectedKey = DateTypeEnum.Custom;
        this.dateTypeSelection = this.selectedKey;
        this.setSelectedDateType(this.dateTypeSelection);
        this.filterApplied.emit();
        this.updateDateHeaderText();
      }
    });
  }

  ngAfterContentInit(): void {
    if (this.entitiesAdded$) {
      this.entitiesAdded$.subscribe((entities) => {
        if (entities && entities[this.selector]) {
          this.dateTypeSelection = DateTypeEnum.Custom;
          this.mapRetainDate(entities[this.selector]);
        }
      });
    }
  }

  mapRetainDate(value: IFilterToApply[]): void {
    const findDateFrom = value.find((item) => item.key === 'created_date_from');
    const findDateTo = value.find((item) => item.key === 'created_date_to');
    const fromDate = new Date(findDateFrom['argument']);
    const toDate = new Date(findDateTo['argument']);
    fromDate.setDate(fromDate.getDate() + 1);
    const dateFrom = NgbDate.from({
      day: fromDate.getDate(),
      month: fromDate.getMonth() + 1,
      year: fromDate.getFullYear(),
    });
    const dateTo = NgbDate.from({
      day: toDate.getDate(),
      month: toDate.getMonth() + 1,
      year: toDate.getFullYear(),
    });
    this.onFromDateApplied(dateFrom);
    this.onToDateApplied(dateTo);
    this.updateDateHeaderText();
  }

  onFilterApplied(): void {
    // clean old filters
    if (this.dateTypeSelection === 'Custom') {
      this.filterUpdated.emit({
        key: `${this.selector}_from`,
        selector: this.selector,
        toRemove: true,
      });
      this.filterUpdated.emit({
        key: `${this.selector}_to`,
        selector: this.selector,
        toRemove: true,
      });

      const startDay = this.inLocalTime
        ? `${this.fromDate.year}-${this.fromDate.month
            .toString()
            .padStart(2, '0')}-${this.fromDate.day.toString().padStart(2, '0')} 00:00:00`
        : this.isFromVhqDate ? endOfDay(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day))
            .toISOString()
            .replace(/T/, ' ')
            .replace(/\..+/, '') :
            startOfDay(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day))
            .toISOString()
            .replace(/T/, ' ')
            .replace(/\..+/, '');

      const endDay = this.inLocalTime
        ? `${this.toDate.year}-${this.toDate.month.toString().padStart(2, '0')}-${this.toDate.day
            .toString()
            .padStart(2, '0')} 23:59:59`
        : endOfDay(new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day))
            .toISOString()
            .replace(/T/, ' ')
            .replace(/\..+/, '');

      this.filterUpdated.emit({
        key: `${this.selector}_from`,
        operator: 'ge',
        selector: this.selector,
        argument: startDay,
      });
      this.filterUpdated.emit({
        key: `${this.selector}_to`,
        selector: this.selector,
        operator: 'le',
        argument: endDay,
      });
    } else {
      const foundSelector = this.selectors.find((selector: ISelectInput) => {
        return this.selectedKey === selector.id;
      });

      this.filterUpdated.emit({
        key: `${foundSelector.id}`,
        selector: this.selector,
        operator: 'tr',
        argument: foundSelector.key,
      });
    }

    this.updateDateHeaderText();
    this.filterApplied.emit();
    this.itemToggled.emit();
    this.isOpen = false;
  }

  getList(): Observable<ISelectInput[]> {
    return null;
  }

  toggleFilter(): void {
    this.itemToggled.emit();
  }

  onDateTypeSelection(_selectedKey): void {
    this.selectedRadioButton.clear();
    this.dateTypeSelection = _selectedKey;
    this.setSelectedDateType(this.dateTypeSelection);
  }
  setSelectedDateType(dateTypeSelection: DateTypeEnum): void {
    this.selectedDateType = new Map([
      [dateTypeSelection, { id: dateTypeSelection, text: dateTypeSelection }],
    ]);
  }
  onRadioFilterApplied(selected: ISelectInput): void {
    this.selectedRadioButton.clear();
    this.selectedRadioButton.set(selected.id, selected);
    const inputs: ISelectInput[] = Array.from(this.selectedRadioButton.values());
    this.selectedKey = inputs[0].id as DateTypeEnum;
  }

  clearField(): void {
    this.isOpen = true;
    this.dateHeaderText = '';
    this.filterUpdated.emit(null);
  }

  onFromDateApplied(fromDate: NgbDate): void {
    this.fromDate = fromDate;
  }

  onToDateApplied(toDate: NgbDate): void {
    this.toDate = toDate;
  }

  areInputsValid(): boolean {
    return Boolean(
      (this.toDate && this.fromDate && this.selectedKey) || this.selectedRadioButton.size > 0,
    );
  }

  updateDateHeaderText(): void {
    let dateHeaderText = '';
    const foundSelector = this.selectors.find((selector: ISelectInput) => {
      return this.selectedKey === selector.id;
    });
    if (this.dateTypeSelection !== 'Custom') {
      dateHeaderText = foundSelector.text ? `${foundSelector.text}: ` : '';
    }
    if (this.dateTypeSelection === 'Custom') {
      if (this.fromDate === this.toDate) {
        dateHeaderText += this.formatter.format(this.fromDate);
      } else {
        dateHeaderText += `${this.formatter.format(this.fromDate)} - ${this.formatter.format(
          this.toDate,
        )}`;
      }
      this.selectedFilterValue.emit(dateHeaderText);
      this.selectedValue = dateHeaderText;
    }
  }
}
