import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TemplateParameters } from '@portal/entity-services/interfaces';
import { ErrorService, FormBase, MultilingualValidators } from '@portal/shared/ui/form';
import { BaseModalComponent } from '@portal/shared/ui/modal';
import { merge } from 'rxjs';
import { TemplateParametersService } from '../../../services/template-parameters.service';
import { WhiteLabelingService } from '@portal/shared/white-labeling';
import { filter } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileService } from '@portal/shared/ui/file-input/src/lib/services/file.service';
import { FormSharedService } from '../../form/form-shared.service';
import { FontsList } from '../../../../shared-lists/fonts.list';
import { ToastService } from '@portal/shared/ui/toast/src';
import { AccordionComponent } from '@portal/shared/ui/accordions/src/lib/accordion/accordion.component';

@UntilDestroy()
@Component({
  selector: 'portal-form-white-labeling-editor',
  templateUrl: './form-white-labeling-editor.component.html',
  styleUrls: ['./form-white-labeling-editor.component.scss'],
})
export class FormWhiteLabelingEditorComponent
  extends FormBase<TemplateParameters>
  implements OnChanges, OnInit, AfterViewInit
{
  @Input() templateParameters: TemplateParameters;
  @Output() appliedChanges: EventEmitter<TemplateParameters> =
    new EventEmitter<TemplateParameters>();
  @Output() resetedChanges: EventEmitter<TemplateParameters> =
    new EventEmitter<TemplateParameters>();
  @Output() valueChanges: EventEmitter<TemplateParameters> = new EventEmitter<TemplateParameters>();

  @ViewChild('resetModal') resetModal: BaseModalComponent;

  @ViewChild('logoAccordion') logoAccordionComponent: AccordionComponent;
  @ViewChild('favIconAccordion') favIconAccordionComponent: AccordionComponent;
  @ViewChild('typographyAccordion') typographyAccordionComponent: AccordionComponent;
  @ViewChild('navBarAccordion') navBarAccordionComponent: AccordionComponent;
  @ViewChild('buttonsAccordion') buttonsAccordionComponent: AccordionComponent;
  @ViewChild('resetButton') resetButton: ElementRef<HTMLButtonElement>;
  @ViewChild('applyButton') applyButton: ElementRef<HTMLButtonElement>;

  isCustomStylesChanged = false;
  isInvalid = ErrorService.isFieldInvalid;
  isFirstChange = true;
  logoSrc: string | SafeUrl;
  faviconSrc: string | SafeUrl;
  typographyList = this.typhographyFontList.list;
  logoTabActive = false;
  favIconTabActive = false;
  typographyTabActive = false;
  navBarTabActive = false;
  buttonsTabActive = false;

  constructor(
    fb: FormBuilder,
    customValidators: MultilingualValidators,
    private templateParametersService: TemplateParametersService,
    private whiteLabelingService: WhiteLabelingService,
    private fileService: FileService,
    private sanitizer: DomSanitizer,
    private formSharedService: FormSharedService,
    private typhographyFontList: FontsList,
    private toastService: ToastService,
  ) {
    super(
      fb.group({
        logo: [[]],
        favicon: [[]],
        pageStyle: fb.group({
          font: fb.array([fb.control('')]),
          navBar: fb.group({
            navbarBackgroundColor: ['', customValidators.colorHexa],
            navbarItemColor: ['', customValidators.colorHexa],
          }),
          brand: fb.group({
            brandBackground: ['', customValidators.colorHexa],
            brandText: ['', customValidators.colorHexa],
            brandOutlinedText: ['', customValidators.colorHexa],
          }),
        }),
      }),
    );

    this.formSharedService.whiteLabelPreviewFormValues$
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((templateParameters: TemplateParameters) =>
        this.form.patchValue(templateParameters),
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.templateParameters && this.templateParameters) {
      this.form.patchValue(this.templateParameters, { emitEvent: false });
      this.emitChanges(this.appliedChanges);
      this.emitChanges(this.valueChanges);
      this.templateParametersService.setInitialParameters(this.form.getRawValue());
      this.setLogoAndFaviconSrc();
    }
  }

  ngOnInit(): void {
    merge(this.logo.valueChanges, this.favicon.valueChanges, this.pageStyle.valueChanges)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateChangedState());
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.logoAccordionComponent) {
        this.logoAccordionComponent.accordionHeader.nativeElement.focus();
      }
    }, 3000);
  }

  onLogoLoaded(files: File[]): void {
    if (this.fileService.isImage(files[0])) {
      this.logoSrc = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
    }
  }

  onFaviconLoaded(files: File[]): void {
    if (this.fileService.isImage(files[0])) {
      this.faviconSrc = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
    }
  }

  /** sharing White Label editor form with root form */
  submit(): void {
    this.formSharedService.setWhiteLabelFormValueState(this.form.getRawValue());
  }

  onResetModalConfirmed(): void {
    const initialParameters = this.templateParametersService.getInitialParameters();
    if (initialParameters) {
      this.form?.patchValue(initialParameters);
    } else {
      this.form?.reset();
    }
    this.resetedChanges.emit(initialParameters);
    this.setLogoAndFaviconSrc();
    this.resetModal.close();
  }

  onReset(): void {
    this.resetModal.open();
  }

  onApply(): void {
    if (this.form.valid) {
      this.emitChanges(this.appliedChanges);
    } else {
      this.toastService.showToast($localize`Please edit invalid fields in the form`);
      this.expandAllSections();
    }
  }

  isTabOnAccordionHeader(event: any): boolean {
    return event.code === 'Tab' && (event.target as Element).classList.contains('accordion-header');
  }

  isAltTabOnAccordionHeader(event: any): boolean {
    return event.shiftKey && this.isTabOnAccordionHeader(event);
  }

  logoTabClicked(isActive): void {
    this.logoTabActive = isActive;
  }

  onTabKeyDownOnLogoHeader(event: KeyboardEvent): void {
    if (!this.logoAccordionComponent.isActive) {
      event.preventDefault();
      this.favIconAccordionComponent.accordionHeader.nativeElement.focus();
    }
  }

  favIconTabClicked(isActive): void {
    this.favIconTabActive = isActive;
  }

  onKeyDownOnFavIconHeader(event: KeyboardEvent): void {
    if (this.isAltTabOnAccordionHeader(event)) {
      if (!this.logoAccordionComponent.isActive) {
        event.preventDefault();
        this.logoAccordionComponent.accordionHeader.nativeElement.focus();
      }
    } else if (this.isTabOnAccordionHeader(event)) {
      if (!this.favIconAccordionComponent.isActive) {
        event.preventDefault();
        this.typographyAccordionComponent.accordionHeader.nativeElement.focus();
      }
    }
  }

  typographyTabClicked(isActive): void {
    this.typographyTabActive = isActive;
  }

  onKeyDownOnTypographyHeader(event: KeyboardEvent): void {
    if (this.isAltTabOnAccordionHeader(event)) {
      if (!this.favIconAccordionComponent.isActive) {
        event.preventDefault();
        this.favIconAccordionComponent.accordionHeader.nativeElement.focus();
      }
    } else if (this.isTabOnAccordionHeader(event)) {
      if (!this.typographyAccordionComponent.isActive) {
        event.preventDefault();
        this.navBarAccordionComponent.accordionHeader.nativeElement.focus();
      }
    }
  }

  navBarTabClicked(isActive): void {
    this.navBarTabActive = isActive;
  }

  onKeyDownOnNavBarHeader(event: KeyboardEvent): void {
    if (this.isAltTabOnAccordionHeader(event)) {
      if (!this.typographyAccordionComponent.isActive) {
        event.preventDefault();
        this.typographyAccordionComponent.accordionHeader.nativeElement.focus();
      }
    } else if (this.isTabOnAccordionHeader(event)) {
      if (!this.navBarAccordionComponent.isActive) {
        event.preventDefault();
        this.buttonsAccordionComponent.accordionHeader.nativeElement.focus();
      }
    }
  }

  buttonsTabClicked(isActive): void {
    this.buttonsTabActive = isActive;
  }

  onKeyDownOnButtonsHeader(event: KeyboardEvent): void {
    if (this.isAltTabOnAccordionHeader(event)) {
      if (!this.navBarAccordionComponent.isActive) {
        event.preventDefault();
        this.navBarAccordionComponent.accordionHeader.nativeElement.focus();
      }
    } else if (this.isTabOnAccordionHeader(event)) {
      if (!this.buttonsAccordionComponent.isActive) {
        event.preventDefault();
        if (!this.resetButton) {
          this.applyButton.nativeElement.focus();
        } else {
          this.resetButton.nativeElement.focus();
        }
      }
    }
  }

  onKeyDownOnResetButton(event: KeyboardEvent): void {
    if (event.shiftKey && event.code === 'Tab' && !this.buttonsAccordionComponent.isActive) {
      event.preventDefault();
      this.buttonsAccordionComponent.accordionHeader.nativeElement.focus();
    }
  }

  onKeyDownOnApplyButton(event: KeyboardEvent): void {
    if (
      event.shiftKey &&
      event.code === 'Tab' &&
      !this.resetButton &&
      !this.buttonsAccordionComponent.isActive
    ) {
      event.preventDefault();
      this.buttonsAccordionComponent.accordionHeader.nativeElement.focus();
    }
  }

  private setLogoAndFaviconSrc(): void {
    const initialDomainName = this.templateParameters?.id;
    this.logoSrc = this.whiteLabelingService.getLogoUrl(initialDomainName);
    this.faviconSrc = this.whiteLabelingService.getFaviconUrl(initialDomainName);
  }

  private updateChangedState(): void {
    this.isCustomStylesChanged = this.templateParametersService.isCustomStylesChanged(
      this.form.getRawValue(),
    );
    this.emitChanges(this.valueChanges);
  }

  private emitChanges(emitter: EventEmitter<TemplateParameters>): void {
    emitter.emit(this.form.getRawValue());
  }

  get logo(): FormControl {
    return this.form.get('logo') as FormControl;
  }

  get favicon(): FormControl {
    return this.form.get('favicon') as FormControl;
  }

  get pageStyle(): FormGroup {
    return this.form.get('pageStyle') as FormGroup;
  }

  get font(): FormControl {
    return (this.pageStyle.get('font') as FormArray).at(0) as FormControl;
  }

  get navBar(): FormGroup {
    return this.pageStyle.get('navBar') as FormGroup;
  }

  get navbarBackgroundColor(): FormControl {
    return this.navBar.get('navbarBackgroundColor') as FormControl;
  }

  get navbarItemColor(): FormControl {
    return this.navBar.get('navbarItemColor') as FormControl;
  }

  get brand(): FormGroup {
    return this.pageStyle.get('brand') as FormGroup;
  }

  get brandBackground(): FormControl {
    return this.brand.get('brandBackground') as FormControl;
  }

  get brandText(): FormControl {
    return this.brand.get('brandText') as FormControl;
  }

  get brandOutlinedText(): FormControl {
    return this.brand.get('brandOutlinedText') as FormControl;
  }

  private expandAllSections(): void {
    if (!this.logoAccordionComponent.isActive && !this.logo.valid) {
      this.logoAccordionComponent.accordionHeader.nativeElement.click();
    }
    if (!this.favIconAccordionComponent.isActive && !this.favicon.valid) {
      this.favIconAccordionComponent.accordionHeader.nativeElement.click();
    }
    if (!this.typographyAccordionComponent.isActive && !this.font.valid) {
      this.typographyAccordionComponent.accordionHeader.nativeElement.click();
    }
    if (
      !this.navBarAccordionComponent.isActive &&
      (!this.navbarBackgroundColor.valid || !this.navbarItemColor.valid)
    ) {
      this.navBarAccordionComponent.accordionHeader.nativeElement.click();
    }
    if (
      !this.buttonsAccordionComponent.isActive &&
      (!this.brandBackground.valid || !this.brandText.valid || !this.brandOutlinedText.valid)
    ) {
      this.buttonsAccordionComponent.accordionHeader.nativeElement.click();
    }
  }
}
