import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { ITag, ITagModel, TTagColor } from '@portal/marketplace/api-interfaces';
import { MultilingualValidators, FormBase } from '@portal/shared/ui/form';
import { NotifierService } from '@portal/shared/ui/notifier';
import { TagsService } from '../state/tags.service';

@Component({
  selector: 'marketplace-tag-form',
  templateUrl: './marketplace-tag-form.component.html',
  styleUrls: ['./marketplace-tag-form.component.scss'],
})
export class MarketplaceTagFormComponent extends FormBase<ITagModel> implements OnInit {
  @Input() submitLabel: 'Add Tag' | 'Update Tag' = 'Add Tag';

  @Input() set tag(tag: ITagModel | ITag) {
    this.tagId.setValue(tag.id);
    this.name.setValue(tag.name);
    this.type.setValue(tag.tagType);
    this.color.setValue(tag.color || 'default');
    this.description.setValue(tag.description);
    this.currentTag = tag;
  }

  @Output() formCanceled: EventEmitter<any> = new EventEmitter();
  @Output() formRemoved: EventEmitter<any> = new EventEmitter();

  colors: TTagColor[] = ['error', 'warning', 'success', 'special', 'actionable', 'default'];

  private currentTag: ITagModel | ITag;

  constructor(
    // eslint-disable-next-line
    private tagsService: TagsService,
    public customValidators: MultilingualValidators,
    public notifierService: NotifierService,
    public fb: FormBuilder,
  ) {
    super(
      fb.group({
        id: [''],
        name: ['', [customValidators.required, customValidators.maxLength(255)]],
        tagType: ['', [customValidators.required]],
        description: ['', [customValidators.required, customValidators.maxLength(255)]],
        color: ['default'],
      }),
    );
  }

  isTagNameExist(name: string): boolean {
    return this.tagsService.isTagNameExist(name);
  }

  get tagId(): AbstractControl {
    return this.form.get('id');
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get type(): AbstractControl {
    return this.form.get('tagType');
  }

  get color(): AbstractControl {
    return this.form.get('color');
  }

  get description(): AbstractControl {
    return this.form.get('description');
  }

  showRemove(): boolean {
    return this.submitLabel === 'Update Tag';
  }

  onRemove(): void {
    this.formRemoved.emit(this.currentTag);
  }

  onSubmit(): void {
    if (!this.name.value) {
      this.notifierService.info(`Please enter name for new tag.`);
    } else if (this.submitLabel === 'Add Tag' && this.isTagNameExist(this.name.value)) {
      this.notifierService.warn(
        `Tag with such name already exists. Please enter a different name.`,
      );
    } else {
      super.submit();
    }
  }

  onCancel(): void {
    this.formCanceled.emit();
  }

  ngOnInit(): void {}
}
