<div class="service-control-wrapper">
  <div class="input" [class.is-active]="isOpen" (click)="changeDropdownState()">
    <span class="placeholder" *ngIf="!roots.length">- {{ placeholder }} -</span>
    <div class="selectedFeature-list">
      <button
        type="button"
        class="button is-primary mb-2 mr-2"
        *ngFor="let root of roots"
        title="Remove"
        i18n-title
      >
        <span
          >{{ root.item.name }}
          {{ root.selectedCount ? '(+' + root.selectedCount + ')' : '' }}</span
        >
        <span class="icon is-size-7" (click)="removeSelected(root.item)">
          <i class="fas fa-times"></i>
        </span>
      </button>
    </div>
    <span class="input-icon is-size-7" [ngClass]="{ 'icon-rotate': isOpen }">
      <i class="fas fa-chevron-down"></i>
    </span>
  </div>
  <portal-multiselect-tree
    id="dropdown"
    *ngIf="isOpen"
    [items]="items"
    [isDisabled]="isDisabled"
    (selectItems)="getSelectedItems($event)"
  >
  </portal-multiselect-tree>
</div>
