import { IGuardPermissions } from '@portal/shared/auth/authorization/src';
import { RouteName } from './route-name.enum';
import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';

export const routePermissions: { [key in RouteName]?: IGuardPermissions } = {
  [RouteName.appCatalog]: {
    read: [
      UserRoles.EstateOwnerAdmin,
      UserRoles.EstateOwnerDeviceAppManager,
      UserRoles.EstateOwnerSupportAdmin,
      UserRoles.EstateOwnerUser,
    ],
  },
};
