import { ITableConfig } from '@portal/shared/ui/table';
export const TABLE_CONFIG_APP_INSTALL_UNINSTALL_STATUS: ITableConfig = {
  // { key: 'uninstall_status_val', label: ' App/Bundle' },
  // { key: 'app_name', label: 'Name', isSortable: true },
  // { key: 'app_name', label: 'App Name', isSortable: true },
  columns: [
    { key: 'alt_vfi_poi_id', label: 'Terminal ID', isSortable: true },
    { key: 'device_serial_number', label: 'Terminal serial no.', isSortable: true },
    { key: 'status', label: 'Status', isSortable: true },
    { key: 'assignment_status_val', label: 'Operation type', isSortable: true },
    { key: 'assignment_type_val', label: 'Operation detail', isSortable: true },
    { key: 'price', label: 'Price', isSortable: true },
    { key: 'time_created', label: 'Time', isSortable: true },
    { key: 'unassignment_date', label: 'Unassignment time', isSortable: true },
  ],
  rowIdKey: 'entityUid',
  rowLabelKey: 'name',
};
