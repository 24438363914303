<div class="error-page">
  <div class="error-page-content">
    <div class="service-unavailable-503 has-text-grey-lighter">
      <h1>503</h1>
    </div>
    <div class="page-error-box box">
      <h2 class="title is-3 has-text-left" i18n>Error 503. Service unavailable</h2>
      <p class="has-text-left" i18n>
        The server is too busy or maintenance is being performed. Try again later.
      </p>
      <div class="div-links">
        <p>
          <i class="arrow has-border-primary right"></i>
          <a (click)="navigateDashboard()" class="text-redirections has-text-priamary" i18n>
            See dashboard
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
