import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { SearchSelector } from '../search/enums/search-selector.enum';
import { SearchOperator } from '../search/enums/search-operator.enum';
import { FILTER_CONTENT_ITEM } from '../content/token';
import cloneDeep from 'lodash-es/cloneDeep';
import { FilterAutoCompleteInputComponent } from '../autocomplete/search-autocomplete-input.component';
import { ISelectInput } from '../box/interfaces/select-input.interface';
import { UserService } from '@portal/entity-services/users/src/lib/services/user.service';
import { FilterBase } from '../filter-base';
import { IEntity, IUser } from '@portal/entity-services/interfaces';
import filter from 'lodash-es/filter';
import {
  FilterCheckboxComponent,
  IFilterToApply,
  ISearchCriteria,
  SwitchToggleComponent,
} from '@portal/shared/ui/filter';
import { from, Observable, Subject } from 'rxjs';
import { debounceTime, distinct, distinctUntilChanged, switchMap, toArray } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IQueryParams, QueryParamsService } from '@portal/shared/ui/table';
import assign from 'lodash-es/assign';
import omit from 'lodash-es/omit';
import { CustomUserDataService } from '@portal/entity-services/users/src/lib/services/user-data.service';
import { AbstractControl, FormControl } from '@angular/forms';

declare const $localize;

@UntilDestroy()
@Component({
  selector: 'portal-shared-users-filter',
  templateUrl: './users-filter.component.html',
  styleUrls: ['./users-filter.component.scss'],
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: UsersFilterComponent,
    },
  ],
})
export class UsersFilterComponent extends FilterBase implements OnChanges {
  @Input() searchUserPayload: IQueryParams;
  @Input() selectedEntities: Map<string, ISearchCriteria> = new Map();
  @Input() selectedParentEntity: IEntity;
  @Input() organizationGroup: boolean;
  @Input() selectedSubOrganizations: Map<string, ISelectInput> = new Map();
  @Input() selectedUsersToEdit: IUser[];
  @Output()
  filterUpdated = new EventEmitter<IFilterToApply>();
  @Input('control') controlSelectedUsers: AbstractControl = new FormControl();
  @Output()
  listAllEntities: EventEmitter<ISelectInput[]> = new EventEmitter();

  @ViewChild('preSelectedEntities') preSelectedEntities: FilterCheckboxComponent;
  @ViewChild('switchToggle')
  switchToggle: SwitchToggleComponent;
  userSearchInput: FilterAutoCompleteInputComponent;
  labelSelectAllUsers = $localize`Select all Users`;
  usersText = $localize`Users`;
  listLoading$: Observable<boolean>;
  users: IUser[] = [];
  usersAll: IUser[] = [];
  usersByEntityAll: IUser[] = [];
  searchSelector = SearchSelector.EntityUid;
  localPreSelectedUsers: Map<string, ISelectInput> = new Map();
  allPreSelectedUsers: Map<string, ISelectInput> = new Map();
  allPreSelectedUserValues = [];
  showSelectedUsers: Map<string, ISelectInput> = new Map();
  searchedText = '';
  searchTerm$ = new Subject<string>();
  search$: Observable<IUser[]>;
  debounce = 1000;
  private initialDebounce = 1000;
  private allItems: ISelectInput[];

  constructor(private userService: UserService, public customDataService: CustomUserDataService) {
    super();
    this.listLoading$ = userService.listLoading$;
    this.startSearchingForUsers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Mode Edit adding organization group
    if (this.organizationGroup && this.selectedUsersToEdit) {
      const paymentTypesList: ISelectInput[] = [];
      this.selectedUsersToEdit?.forEach((user) => {
        if (user.name && user.userUid) {
          this.onEntityAdded({ id: user.userUid, text: user.name });
        }
      });
    }
  }

  openContent(): void {
    this.startSearchingForUsers();
    if (this.selectedParentEntity?.entityUid) {
      this.getUsersByEntities();
      this.updateText();
      this.onFilterApplied();
      this.updateUsers();
    }
    this.isOpen = !this.isOpen;
  }

  onClose(): void {
    this.isOpen = false;
  }

  onEntityRemoved(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.delete(selectedEntity.id);
    this.localPreSelectedUsers.delete(selectedEntity.id);
    this.delayFilterUpdate();
  }

  onEntityAdded(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.set(selectedEntity.id, {
      id: selectedEntity.id,
      text: selectedEntity.text,
    });
    this.localPreSelectedUsers.set(selectedEntity.id, {
      id: selectedEntity.id,
      text: selectedEntity.text,
    });
    this.delayFilterUpdate();
  }

  delayFilterUpdate(): void {
    setTimeout(() => {
      this.filterUpdate();
    }, 0);
  }

  getList(): Observable<ISelectInput[]> {
    this.debounce = 0;
    this.searchTerm$.next('');
    return null;
  }

  setEntities(users: IUser[]): void {
    if (!this.users) {
      return;
    }
    this.allItems = users?.map((user) => {
      return {
        id: user.userUid,
        name: user.name,
      };
    });

    this.listAllEntities.emit(this.allItems);

    const selectedEntities = this.selectedEntities
      ?.get(SearchSelector.EntityUid)
      ?.argument?.replace('(', '')
      ?.replace(')', '');

    const selectedEntitiesArr =
      selectedEntities?.split(',') || Array.from(this.localPreSelectedUsers.keys());

    this.allItems?.forEach((entity) => {
      selectedEntitiesArr?.forEach((preSelected) => {
        if (entity.id === preSelected) {
          this.onUserAdded(entity);
        }
      });
    });
  }

  onUserAdded(user: ISelectInput): void {
    this.localPreSelectedUsers.set(user.userUid, {
      id: user.userUid,
      text: user.name,
    });
    this.localPreSelectedEntities.set(user.userUid, {
      id: user.userUid,
      text: user.name,
    });
    this.filterUpdate();
  }

  updateSwitchToggleState(): void {
    if (this.usersAll.length && this.switchToggle) {
      this.switchToggle.switchOn = this.localPreSelectedUsers.size === this.usersAll.length;
    }
  }

  removeSelectedUser(selectedUser: ISelectInput): void {
    this.allPreSelectedUsers.delete(selectedUser.id);
    this.localPreSelectedEntities.delete(selectedUser.id);
    this.localPreSelectedUsers.delete(selectedUser.id);
    this.setAllPreSelectedUserValues();
    this.delayFilterUpdate();
  }

  onSelectAllSwitchToggle(): void {
    if (this.switchToggle.switchOn) {
      Array.from(this.users).forEach((user) => {
        this.localPreSelectedUsers.set(user.userUid, {
          id: user.userUid,
          text: user.name,
        });
      });
      if (this.showSelectedUsers.size > 0) {
        this.showSelectedUsers.forEach((selectedCheckbox) => {
          if (!this.preSelectedEntities.isEntitySelected(selectedCheckbox.id)) {
            this.preSelectedEntities.onCheckboxChecked(selectedCheckbox.id);
          }
        });
      }
    } else if (this.localPreSelectedUsers.size > 0) {
      this.removeAllEntities();
      this.filterUpdate();
    }

    this.filterUpdate();
  }

  getValues(): string[] {
    const values = new Set<string>();
    Array.from(this.localPreSelectedEntities.values()).forEach((val: ISelectInput) => {
      values.add(val.id);
    });

    return Array.from(values);
  }

  filterUpdate(): void {
    const values: string[] = this.getValues();
    if (values.length) {
      this.filterUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.localPreSelectedUsers.values()),
        argument: `(${Array.from(values).join(',')})`,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        value: Array.from(this.localPreSelectedUsers.values()),
      });
    } else {
      this.filterUpdated.emit({
        key: this.searchSelector,
        toRemove: true,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        toRemove: true,
      });
    }

    this.updateUsers();
    this.updateText();
    this.updateSwitchToggleState();

    this.setEntities(this.users);

    // Users adding a new organization group
    this.allPreSelectedUsers = cloneDeep(this.localPreSelectedUsers);
    this.setAllPreSelectedUserValues();
    this.allPreSelectedUserValues = [...new Set(this.allPreSelectedUserValues)];
    this.setControlValues();
  }

  setAllPreSelectedUserValues(): void {
    this.allPreSelectedUserValues = [];
    this.allPreSelectedUsers?.forEach((value) => {
      this.allPreSelectedUserValues.push({ id: value.id, text: value.text });
    });
    this.allPreSelectedUserValues = [...new Set(this.allPreSelectedUserValues)];
  }

  setControlValues(): void {
    const selectedUserIds = [];
    Array.from(this.localPreSelectedEntities.values()).forEach((value) => {
      selectedUserIds.push(value.id);
    });
    this.controlSelectedUsers.setValue(selectedUserIds);
  }

  onSearch(searchValue: string): void {
    this.searchedText = searchValue;
    if (searchValue) {
      this.searchTerm$.next(searchValue);
    } else {
      this.usersAll = this.users = this.usersByEntityAll;
    }
  }

  onFilterApplied(): void {
    this.updateEntities();
    const values: string[] = this.getValues();

    if (values.length) {
      this.filterApplied.emit({
        key: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.localPreSelectedEntities.values()),
        argument: `(${Array.from(values).join(',')})`,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        value: Array.from(this.localPreSelectedEntities.values()),
      });
    } else {
      this.filterApplied.emit({
        key: this.searchSelector,
        toRemove: true,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        toRemove: true,
      });
    }
    this.itemToggled.emit();
    this.resetInputValue();
    this.isOpen = false;
  }

  onClear(): void {
    this.removeAllEntities();
    this.resetInputValue();
    this.filterUpdate();
  }

  clear(): void {
    this.onClear();
  }

  removeAllEntities(): void {
    this.localPreSelectedEntities.clear();
    this.showSelectedUsers.clear();
    this.localPreSelectedUsers.clear();
  }

  resetInputValue(): void {
    this.onSearch('');
  }

  private updateText(): void {
    const values: string[] = this.getValues();
    const text = $localize`Users`;
    this.usersText = values.length ? `${text} (+${values.length})` : text;
  }

  private getUsersByEntities(): void {
    let searchEntitiesUids = [...Array.from(this.selectedSubOrganizations?.keys())];
    if (this.organizationGroup && this.selectedParentEntity) {
      const arrIds = [this.selectedParentEntity.entityUid];
      searchEntitiesUids.forEach((id) => {
        arrIds.push(id);
      });
      searchEntitiesUids = arrIds;
      searchEntitiesUids = [...new Set(searchEntitiesUids)];
    }

    searchEntitiesUids = searchEntitiesUids.filter((id) => id);

    const entityUids = searchEntitiesUids?.join(',') || '';

    const userPayload = cloneDeep(this.searchUserPayload);
    userPayload.searchCriteria = new Map<string, ISearchCriteria>();

    if (userPayload?.searchCriteria.has(SearchSelector.EntityUid)) {
      userPayload.searchCriteria.delete(SearchSelector.EntityUid);
    }

    userPayload?.searchCriteria.set(SearchSelector.EntityUid, {
      argument: entityUids,
      selector: SearchSelector.EntityUid,
      operator: SearchOperator.Equal,
    });

    const query = QueryParamsService.toQueryParams(userPayload);
    const filterParams = QueryParamsService.getFilterParams(userPayload.searchCriteria);
    assign(query, filterParams);

    this.customDataService
      .getWithQuery(omit(query, ['searchCriteria']))
      .pipe(
        switchMap(from),
        distinct((user: IUser) => user.userUid),
        toArray(),
      )
      .subscribe((users) => {
        this.users = users;
        this.usersAll = users;
        this.updateUsers();
      });
  }

  private startSearchingForUsers(): void {
    this.search$ = this.search(this.searchTerm$);
    this.search$?.pipe(untilDestroyed(this)).subscribe((users: IUser[]) => {
      let result: IUser[] = [];
      if (this.usersByEntityAll.length && users.length) {
        result = users.filter((user: IUser) =>
          this.usersByEntityAll.some(({ userUid }) => user.userUid === userUid),
        );
      }
      if (this.localPreSelectedUsers.size) {
        this.users = result || users;
        this.usersAll = result || users;
        this.updateUsers();
      } else {
        this.users = filter(users, function (user: IUser) {
          return user.userUid;
        });
        this.usersAll = this.users;
      }
    });
  }

  private updateUsers(): void {
    this.showSelectedUsers = cloneDeep(this.localPreSelectedUsers);
    const usersToExclude = Array.from(this.localPreSelectedUsers.keys());
    this.users = filter(this.usersAll, (user: IUser) => {
      return user.userUid && !usersToExclude.includes(user.userUid);
    });
  }

  private search(terms: Observable<string>): Observable<IUser[]> {
    return terms.pipe(debounceTime(this.debounce), distinctUntilChanged()).pipe(
      switchMap((term: string) => {
        if (this.searchUserPayload?.searchCriteria.has(SearchSelector.Name)) {
          this.searchUserPayload.searchCriteria.delete(SearchSelector.Name);
        }

        this.searchUserPayload?.searchCriteria.set(SearchSelector.Name, {
          argument: term,
          selector: SearchSelector.Name,
          operator: SearchOperator.Equal,
        });

        const query = QueryParamsService.toQueryParams(this.searchUserPayload);
        const filterParams = QueryParamsService.getFilterParams(
          this.searchUserPayload.searchCriteria,
        );
        assign(query, filterParams);

        // Always reset debounce time
        this.debounce = this.initialDebounce;
        return this.customDataService.getWithQuery(omit(query, ['searchCriteria'])).pipe(
          switchMap(from),
          distinct((user: IUser) => user.userUid),
          toArray(),
        );
      }),
    );
  }
}
