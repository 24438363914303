import environment from '@environments';
import { FiltersList } from '@portal/shared/ui/filter';
import { ITableConfig } from '@portal/shared/ui/table';

export const APP_SUMMARY_TABLE_CONFIG = (role): ITableConfig => {
  return TABLE_CONFIG;
};

export const APP_SUMMARY_FILTER_CONFIG = (role): FiltersList[] => {
  return FILTER_CONFIG;
};

const TABLE_CONFIG = {
  columns: [
    { key: 'applications_count', label: 'Applications Count', isSortable: true },
    { key: 'downloads', label: 'Downloads', isSortable: true },
    { key: 'installations', label: 'Installations', isSortable: true },
    { key: 'pending_installations', label: 'Pending Installations', isSortable: true },
  ],
  rowIdKey: 'entityUid',
  rowLabelKey: 'name',
};

const FILTER_CONFIG: FiltersList[] = [];

export const APP_SUMMARY_CONFIG = {
  report_id: environment.REPORTS['app-summary'],
  table: APP_SUMMARY_TABLE_CONFIG,
  filters: APP_SUMMARY_FILTER_CONFIG,
};
