/* eslint-disable @typescript-eslint/naming-convention */
import { Directive, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, of, Subject, Subscription, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  startWith,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { IUser } from '@portal/entity-services/interfaces';
import { UserService as EsUserService } from '@portal/entity-services/users';
import { AuthenticationService } from '@portal/shared/auth/authentication';
import { AuthorizationService } from '@portal/shared/auth/authorization';
import { Features } from 'environments/enums/features.enum';
import { NotifierService } from '@portal/shared/ui/notifier';
import { UserService } from '@portal/shared/user';
import { AppPermission } from '@portal/shared/auth/authorization/src/lib/interfaces/app-permission.interface';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';
import { EnvironmentVariableService } from '@portal/shared/helpers';
import { WhiteLabelingService } from '@portal/shared/white-labeling/src';
import { UserActivityService } from '@portal/shared/user/src/lib/user-activity.service';
import { Location } from '@angular/common';

const NO_AUTO_LOGIN_REDIRECT_QUERY_KEY = 'no-auto-login-redirect';

@UntilDestroy()
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class PortalBase implements OnInit, OnDestroy {
  @Output() whiteLabelLoaded = new EventEmitter<void>();

  router: Router;
  route: ActivatedRoute;

  showLoader = true;
  isAuthenticated = false;
  isWhiteLabelingEnabled: boolean;

  authenticatedUser: IUser;
  appsPermissions: AppPermission[];

  userService: UserService;
  cba = !!EnvironmentVariableService.getVar('CBA');

  protected esUserService: EsUserService;
  protected notifierService: NotifierService;
  protected featureToggleService: FeatureToggle;
  protected authorizationService: AuthorizationService;
  protected authenticationService: AuthenticationService;
  protected whiteLabelingService: WhiteLabelingService;
  protected location: Location;
  protected userActivityService: UserActivityService;

  protected authLoadingSubject = new Subject<boolean>();
  protected whiteLabelLoadingSubject = new Subject<boolean>();
  protected authLoading$ = this.authLoadingSubject.asObservable();
  protected whiteLabelLoading$ = this.whiteLabelLoadingSubject.asObservable();

  protected subscription: Subscription;
  private getAuthorizationSub: Subscription;

  constructor(injector: Injector) {
    this.userService = injector.get(UserService);
    this.esUserService = injector.get(EsUserService);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.authenticationService = injector.get(AuthenticationService);
    this.authorizationService = injector.get(AuthorizationService);
    this.featureToggleService = injector.get(FeatureToggle);
    this.notifierService = injector.get(NotifierService);
    this.whiteLabelingService = injector.get(WhiteLabelingService);
    this.location = injector.get(Location);
    this.userActivityService = injector.get(UserActivityService);
  }

  ngOnInit(): void {
    this.subscribeToLoading();

    this.initWhiteLabel();

    this.subscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        withLatestFrom(this.route.queryParams),
        mergeMap((data: any) => {
          const [, params] = data;
          const { code, state, error } = params;
          if (code && state && this.authenticationService.verifyState(state)) {
            return this.authenticationService.exchangeCodeForToken(code);
          }
          if (error) {
            return of({ error });
          }
          return of(params);
        }),
        catchError((error) => {
          const redirectPath = localStorage.getItem('redirect_path');
          this.authenticationService.logout({ skipLocationChange: true, redirectPath });
          return throwError(error);
        }),
      )
      .subscribe((result: any) => {
        this.setAuthLoadingState(true);
        result = this.getParameters(result);
        const { access_token, id_token, scope, token_type, expires_in, error } = result;
        const isAuthCallback = access_token && scope && id_token && token_type && expires_in;
        const redirectPath = isAuthCallback
          ? localStorage.getItem('redirect_path') ?? ''
          : this.location.path(true);
        if (error) {
          this.authenticationService.logout({ skipLocationChange: true, redirectPath });
          return;
        }
        if (isAuthCallback) {
          this.authenticationService.handleAuthResponse(result);
        } else if (
          !this.userService.isLogin() &&
          !(this.hasNoAutoLoginRedirectQueryParam() || this.cba || this.hasIonicFlags())
        ) {
          this.signIn();
          return;
        }

        if (!this.getAuthorizationSub && this.authenticationService.userUid) {
          this.getAuthorizationSub = this.authorizationService.authorize().subscribe(
            async ({ user, appsPermissions }) => {
              this.authenticatedUser = user;
              this.userService.userDetails = user;
              this.appsPermissions = appsPermissions;

              if (isAuthCallback && redirectPath) {
                await this.router.navigateByUrl(redirectPath, {
                  replaceUrl: true,
                });
                localStorage.removeItem('redirect_path');
              }

              this.authenticationService.authEvents.next({
                type: AuthenticationService.Event.LoggedIn,
              });

              if (this.featureToggleService.isFeatureActive(Features.SilentRefreshToken)) {
                this.authenticationService.startTokenRefreshTimers();
              }
            },
            () => {
              this.authenticationService.logout({ skipLocationChange: true, redirectPath });
            },
          );
        }

        if (this.isAuthenticated || !this.getAuthorizationSub) {
          this.setAuthLoadingState(false);
        }
      });
    if (this.userService.isLogin()) {
      this.userService.setCurrentUser();
    }
  }

  getParameters(result): any {
    result = {
      access_token: JSON.parse(localStorage.getItem('access_token')),
      id_token: JSON.parse(localStorage.getItem('id_token')),
      scope: JSON.parse(localStorage.getItem('scope')),
      token_type: JSON.parse(localStorage.getItem('token_type')),
      expires_in: JSON.parse(localStorage.getItem('expires_in')),
      ...result,
    };
    return result;
  }

  signIn(withoutRedirect: boolean = false): void {
    if (this.isWhiteLabelingEnabled) {
      this.whiteLabelingService
        .loadParameters()
        .pipe(untilDestroyed(this))
        .subscribe((parameters) => {
          const loginDomainName = parameters?.text?.loginDomainName;
          if (loginDomainName) {
            this.authenticationService.setLoginDomainName(loginDomainName);
          }

          if (withoutRedirect) {
            this.authenticationService.authenticate();
          } else {
            this.authenticationService.authenticate(this.location.path(true));
          }
        });
    } else if (withoutRedirect) {
      this.authenticationService.authenticate();
    } else {
      this.authenticationService.authenticate(this.location.path(true));
    }
  }

  logout(): void {
    this.authenticationService.logout();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.getAuthorizationSub?.unsubscribe();
    this.authenticationService.removeTokenRefreshTimers();
  }

  protected setAuthLoadingState(state: boolean): void {
    this.authLoadingSubject.next(state);
  }

  protected setWhiteLabelLoadingState(state: boolean): void {
    this.whiteLabelLoadingSubject.next(state);
  }

  private initWhiteLabel(): void {
    this.isWhiteLabelingEnabled = this.featureToggleService.isConfigFeatureActive(
      Features.PortalWhiteLabeling,
    );

    if (this.isWhiteLabelingEnabled) {
      this.loadWhiteLabelingSettings();
    }
  }

  private loadWhiteLabelingSettings(): void {
    this.setWhiteLabelLoadingState(true);
    this.whiteLabelingService
      .isWhiteLabelingExists()
      .pipe(untilDestroyed(this), take(1))
      .subscribe(
        (isExist) => {
          if (isExist) {
            this.applyWhiteLabeling();
          } else {
            this.setWhiteLabelLoadingState(false);
          }
        },
        () => this.setWhiteLabelLoadingState(false),
      );
  }

  private hasNoAutoLoginRedirectQueryParam(): boolean {
    const queryParams = this.route.snapshot.queryParams;
    return queryParams.hasOwnProperty(NO_AUTO_LOGIN_REDIRECT_QUERY_KEY);
  }

  private hasIonicFlags(): boolean {
    return !!EnvironmentVariableService.getVar('IONIC.ON');
  }

  private subscribeToLoading(): void {
    combineLatest([
      this.authLoading$.pipe(startWith(true)),
      this.whiteLabelLoading$.pipe(startWith(false)),
    ])
      .pipe(
        map(([...loadings]) => loadings.some((isLoading) => isLoading)),
        distinctUntilChanged(),
      )
      .subscribe((isLoading) => (this.showLoader = isLoading));
  }

  private applyWhiteLabeling(): void {
    this.whiteLabelingService
      .loadCollection()
      .pipe(
        untilDestroyed(this),
        take(1),
        finalize(() => this.setWhiteLabelLoadingState(false)),
      )
      .subscribe(([parameters, logo, favicon]) => {
        if (logo) {
          this.whiteLabelLoaded.emit();
        }
        if (favicon) {
          this.whiteLabelingService.applyFavicon();
        }
        if (parameters) {
          const stylesApplied = this.whiteLabelingService.applyStyles();
          const fontApplied = this.whiteLabelingService.applyGoogleFont(
            parameters?.pageStyle?.font?.[0],
          );
          if (stylesApplied || fontApplied) {
            this.whiteLabelingService.applyWhiteLabeling();
          }
        }
      });
  }
}
