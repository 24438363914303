import { PaymentType } from '../enums/payment-type.enum';
import { ProcessorType } from '../enums/processor-type.enum';

export interface IProcessorDetails {
  type?: ProcessorType;
  issuerInstalmentSettings?: {
    issuerInstalmentAllowed?: boolean;
    minimumNumberOfInstalments?: number;
    maximumNumberOfInstalments?: number;
  };
  transactionProcessingSettings?: {
    unmatchedRefundAllowed: boolean;
    networkTokenSupported: boolean;
    fundsTransferViaRefundAllowed: boolean;
    surchargingSupported: boolean;
    maxPercentSurcharge: number;
  };
  storedCredentialSettings?: {
    cardOnFilePaymentAllowedcardOnFilePaymentAllowed?: boolean;
  };
  contractExtendedStoredCredentialSettings?: {
    extendedSettingsMode?: string;
    allowedLimitedToSignupProcessingModels?: string[];
  };
  tokenizationProcessingSettings?: {
    tokenTypeSupport?: string;
  };
  defaultCardTableGroupId: string;
  cutoverTime?: string;
  cutoverTimeZone?: string;
  cutoverTimes?: {
    cutoverTime?: string;
    cutoverTimeZone?: string;
  }[];
  paymentProviderContractSettings: {
    currenciesSupported: string[];
    paymentTypesSupported: PaymentType[];
  };
}
