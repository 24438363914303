import { Injectable } from '@angular/core';
import { sortBy } from 'lodash';
import { Continent } from '../continent/continent.enum';
import { ITimeZone } from './interfaces/time-zone.interface';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class TimeZoneService {
  //https://www.timeanddate.com/time/zones/
  timeZones: ITimeZone[] = sortBy(
    [
      {
        abbreviation: 'ACDT',
        name: $localize`Australian Central Daylight Time\nCDT – Central Daylight Time\nCDST – Central Daylight Savings Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +10:30',
      },
      {
        abbreviation: 'ACST',
        name: $localize`Australian Central Standard Time\nCST – Central Standard Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +9:30',
      },
      {
        abbreviation: 'ACT',
        name: $localize`Acre Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -5',
      },
      {
        abbreviation: 'ACT',
        name: $localize`Australian Central Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +9:30 / +10:30',
      },
      {
        abbreviation: 'ACWST',
        name: $localize`Australian Central Western Standard Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +8:45',
      },
      {
        abbreviation: 'ADT',
        name: $localize`Arabia Daylight Time\nAST – Arabia Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +4',
      },
      {
        abbreviation: 'ADT',
        name: $localize`Atlantic Daylight Time\nADST – Atlantic Daylight Saving Time\nAST – Atlantic Summer Time\nHAA – Heure Avancée de l'Atlantique (French)\n`,
        locations: [Continent.NorthAmerica, Continent.Atlantic],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'AEDT',
        name: $localize`Australian Eastern Daylight Time\nEDT – Eastern Daylight Time\nEDST – Eastern Daylight Saving Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'AEST',
        name: $localize`Australian Eastern Standard Time\nEST – Eastern Standard Time\nAET – Australian Eastern Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +10',
      },
      {
        abbreviation: 'AET',
        name: $localize`Australian Eastern Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +10:00 / +11:00',
      },
      {
        abbreviation: 'AFT',
        name: $localize`Afghanistan Time`,
        locations: [Continent.Asia],
        offset: 'UTC +4:30',
      },
      {
        abbreviation: 'AKDT',
        name: $localize`Alaska Daylight Time\nADST – Alaska Daylight Saving Time`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -8',
      },
      {
        abbreviation: 'AKST',
        name: $localize`Alaska Standard Time\nAT – Alaska Time`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -9',
      },
      {
        abbreviation: 'ALMT',
        name: $localize`Alma-Ata Time`,
        locations: [Continent.Asia],
        offset: 'UTC +6',
      },
      {
        abbreviation: 'AMST',
        name: $localize`Amazon Summer Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'AMST',
        name: $localize`Armenia Summer Time\nAMDT – Armenia Daylight Time`,
        locations: [Continent.Asia],
        offset: 'UTC +5',
      },
      {
        abbreviation: 'AMT',
        name: $localize`Amazon Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -4',
      },
      {
        abbreviation: 'AMT',
        name: $localize`Armenia Time`,
        locations: [Continent.Asia],
        offset: 'UTC +4',
      },
      {
        abbreviation: 'ANAST',
        name: $localize`Anadyr Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'ANAT',
        name: $localize`Anadyr Time`,
        locations: [Continent.Asia],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'AQTT',
        name: $localize`Aqtobe Time`,
        locations: [Continent.Asia],
        offset: 'UTC +5',
      },
      {
        abbreviation: 'ART',
        name: $localize`Argentina Time`,
        locations: [Continent.Antarctica, Continent.SouthAmerica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'AST',
        name: $localize`Arabia Standard Time\nAST – Arabic Standard Time\nAST – Al Manamah Standard Time`,
        locations: [Continent.Asia],
        offset: 'UTC +3',
      },
      {
        abbreviation: 'AST',
        name: $localize`Atlantic Standard Time\nAT – Atlantic Time\nAST – Tiempo Estándar del Atlántico (Spanish)\nHNA – Heure Normale de l'Atlantique (French)\n`,
        locations: [Continent.NorthAmerica, Continent.Atlantic, Continent.SouthAmerica],
        offset: 'UTC -4',
      },
      {
        abbreviation: 'AT',
        name: $localize`Atlantic Time`,
        locations: [Continent.NorthAmerica, Continent.Atlantic],
        offset: 'UTC -4:00 / -3:00',
      },
      {
        abbreviation: 'AWDT',
        name: $localize`Australian Western Daylight Time\nWDT – Western Daylight Time\nWST – Western Summer Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +9',
      },
      {
        abbreviation: 'AWST',
        name: $localize`Australian Western Standard Time\nWST – Western Standard Time\nWAT – Western Australia Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'AZOST',
        name: $localize`Azores Summer Time\nAZODT – Azores Daylight Time`,
        locations: [$localize`Atlantic`],
        offset: 'UTC +0',
      },
      {
        abbreviation: 'AZOT',
        name: $localize`Azores Time\nAZOST – Azores Standard Time`,
        locations: [$localize`Atlantic`],
        offset: 'UTC -1',
      },
      {
        abbreviation: 'AZST',
        name: $localize`Azerbaijan Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +5',
      },
      {
        abbreviation: 'AZT',
        name: $localize`Azerbaijan Time`,
        locations: [Continent.Asia],
        offset: 'UTC +4',
      },
      {
        abbreviation: 'AoE',
        name: $localize`Anywhere on Earth`,
        locations: [Continent.Oceania],
        offset: 'UTC -12',
      },
      {
        abbreviation: 'BNT',
        name: $localize`Brunei Darussalam Time\nBDT – Brunei Time`,
        locations: [Continent.Asia],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'BOT',
        name: $localize`Bolivia Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -4',
      },
      {
        abbreviation: 'BRST',
        name: $localize`Brasília Summer Time\nBST – Brazil Summer Time\nBST – Brazilian Summer Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -2',
      },
      {
        abbreviation: 'BRT',
        name: $localize`Brasília Time\nBT – Brazil Time\nBT – Brazilian Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'BST',
        name: $localize`Bangladesh Standard Time`,
        locations: [Continent.Asia],
        offset: 'UTC +6',
      },
      {
        abbreviation: 'BST',
        name: $localize`Bougainville Standard Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'BST',
        name: $localize`British Summer Time\nBDT – British Daylight Time\nBDST – British Daylight Saving Time`,
        locations: [Continent.Europe],
        offset: 'UTC +1',
      },
      {
        abbreviation: 'BTT',
        name: $localize`Bhutan Time`,
        locations: [Continent.Asia],
        offset: 'UTC +6',
      },
      {
        abbreviation: 'CAST',
        name: $localize`Casey Time`,
        locations: [Continent.Antarctica],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'CAT',
        name: $localize`Central Africa Time`,
        locations: [Continent.Africa],
        offset: 'UTC +2',
      },
      {
        abbreviation: 'CCT',
        name: $localize`Cocos Islands Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +6:30',
      },
      {
        abbreviation: 'CDT',
        name: $localize`Central Daylight Time\nCDST – Central Daylight Saving Time\nNACDT – North American Central Daylight Time\nHAC – Heure Avancée du Centre (French)\n`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -5',
      },
      {
        abbreviation: 'CDT',
        name: $localize`Cuba Daylight Time`,
        locations: [Continent.NorthAmerica, Continent.SouthAmerica],
        offset: 'UTC -4',
      },
      {
        abbreviation: 'CEST',
        name: $localize`Central European Summer Time\nCEDT – Central European Daylight Time\nECST – European Central Summer Time\nMESZ – Mitteleuropäische Sommerzeit (German)\n`,
        locations: [Continent.Europe, Continent.Antarctica],
        offset: 'UTC +2',
      },
      {
        abbreviation: 'CET',
        name: $localize`Central European Time\nECT – European Central Time\nCET – Central Europe Time\nMEZ – Mitteleuropäische Zeit (German)\n`,
        locations: [Continent.Europe, Continent.Africa],
        offset: 'UTC +1',
      },
      {
        abbreviation: 'CHADT',
        name: $localize`Chatham Island Daylight Time\nCDT – Chatham Daylight Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +13:45',
      },
      {
        abbreviation: 'CHAST',
        name: $localize`Chatham Island Standard Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +12:45',
      },
      {
        abbreviation: 'CHOST',
        name: $localize`Choibalsan Summer Time\nCHODT – Choibalsan Daylight Time\nCHODST – Choibalsan Daylight Saving Time`,
        locations: [Continent.Asia],
        offset: 'UTC +9',
      },
      {
        abbreviation: 'CHOT',
        name: $localize`Choibalsan Time`,
        locations: [Continent.Asia],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'CHUT',
        name: $localize`Chuuk Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +10',
      },
      {
        abbreviation: 'CIDST',
        name: $localize`Cayman Islands Daylight Saving Time`,
        locations: [Continent.NorthAmerica, Continent.SouthAmerica],
        offset: 'UTC -4',
      },
      {
        abbreviation: 'CIST',
        name: $localize`Cayman Islands Standard Time\nCIT – Cayman Islands Time`,
        locations: [Continent.NorthAmerica, Continent.SouthAmerica],
        offset: 'UTC -5',
      },
      {
        abbreviation: 'CKT',
        name: $localize`Cook Island Time`,
        locations: [Continent.Oceania],
        offset: 'UTC -10',
      },
      {
        abbreviation: 'CLST',
        name: $localize`Chile Summer Time\nCLDT – Chile Daylight Time`,
        locations: [Continent.SouthAmerica, Continent.Antarctica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'CLT',
        name: $localize`Chile Standard Time\nCT – Chile Time\nCLST – Chile Standard Time`,
        locations: [Continent.SouthAmerica, Continent.Antarctica],
        offset: 'UTC -4',
      },
      {
        abbreviation: 'COT',
        name: $localize`Colombia Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -5',
      },
      {
        abbreviation: 'CST',
        name: $localize`Central Standard Time\nCT – Central Time\nNACST – North American Central Standard Time\nCST – Tiempo Central Estándar (Spanish)\nHNC – Heure Normale du Centre (French)\n`,
        locations: [Continent.NorthAmerica, Continent.SouthAmerica],
        offset: 'UTC -6',
      },
      {
        abbreviation: 'CST',
        name: $localize`China Standard Time`,
        locations: [Continent.Asia],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'CST',
        name: $localize`Cuba Standard Time`,
        locations: [Continent.NorthAmerica, Continent.SouthAmerica],
        offset: 'UTC -5',
      },
      {
        abbreviation: 'CT',
        name: $localize`Central Time`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -6:00 / -5:00',
      },
      {
        abbreviation: 'CVT',
        name: $localize`Cape Verde Time`,
        locations: [Continent.Africa],
        offset: 'UTC -1',
      },
      {
        abbreviation: 'CXT',
        name: $localize`Christmas Island Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +7',
      },
      {
        abbreviation: 'ChST',
        name: $localize`Chamorro Standard Time\nGST – Guam Standard Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +10',
      },
      {
        abbreviation: 'DAVT',
        name: $localize`Davis Time`,
        locations: [Continent.Antarctica],
        offset: 'UTC +7',
      },
      {
        abbreviation: 'DDUT',
        name: $localize`Dumont-d'Urville Time`,
        locations: [Continent.Antarctica],
        offset: 'UTC +10',
      },
      {
        abbreviation: 'EASST',
        name: $localize`Easter Island Summer Time\nEADT – Easter Island Daylight Time`,
        locations: [Continent.Oceania],
        offset: 'UTC -5',
      },
      {
        abbreviation: 'EAST',
        name: $localize`Easter Island Standard Time`,
        locations: [Continent.Oceania],
        offset: 'UTC -6',
      },
      {
        abbreviation: 'EAT',
        name: $localize`Eastern Africa Time\nEAT – East Africa Time`,
        locations: [Continent.Africa, Continent.Oceania],
        offset: 'UTC +3',
      },
      {
        abbreviation: 'ECT',
        name: $localize`Ecuador Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -5',
      },
      {
        abbreviation: 'EDT',
        name: $localize`Eastern Daylight Time\nEDST – Eastern Daylight Savings Time\nNAEDT – North American Eastern Daylight Time\nHAE – Heure Avancée de l'Est (French)\nEDT – Tiempo de verano del Este (Spanish)\n`,
        locations: [Continent.NorthAmerica, Continent.SouthAmerica],
        offset: 'UTC -4',
      },
      {
        abbreviation: 'EEST',
        name: $localize`Eastern European Summer Time\nEEDT – Eastern European Daylight Time\nOESZ – Osteuropäische Sommerzeit (German)\n`,
        locations: [Continent.Europe, Continent.Asia],
        offset: 'UTC +3',
      },
      {
        abbreviation: 'EET',
        name: $localize`Eastern European Time\nOEZ – Osteuropäische Zeit (German)\n`,
        locations: [Continent.Europe, Continent.Asia, Continent.Africa],
        offset: 'UTC +2',
      },
      {
        abbreviation: 'EGST',
        name: $localize`Eastern Greenland Summer Time\nEGST – East Greenland Summer Time`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC +0',
      },
      {
        abbreviation: 'EGT',
        name: $localize`East Greenland Time\nEGT – Eastern Greenland Time`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -1',
      },
      {
        abbreviation: 'EST',
        name: $localize`Eastern Standard Time\nET – Eastern Time\nNAEST – North American Eastern Standard Time\nET – Tiempo del Este (Spanish)\nHNE – Heure Normale de l'Est (French)\n`,
        locations: [Continent.NorthAmerica, Continent.SouthAmerica],
        offset: 'UTC -5',
      },
      {
        abbreviation: 'ET',
        name: $localize`Eastern Time`,
        locations: [Continent.NorthAmerica, Continent.SouthAmerica],
        offset: 'UTC -5:00 / -4:00',
      },
      {
        abbreviation: 'FET',
        name: $localize`Further-Eastern European Time`,
        locations: [Continent.Europe],
        offset: 'UTC +3',
      },
      {
        abbreviation: 'FJST',
        name: $localize`Fiji Summer Time\nFJDT – Fiji Daylight Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +13',
      },
      {
        abbreviation: 'FJT',
        name: $localize`Fiji Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'FKST',
        name: $localize`Falkland Islands Summer Time\nFKDT – Falkland Island Daylight Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'FKT',
        name: $localize`Falkland Island Time\nFKST – Falkland Island Standard Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -4',
      },
      {
        abbreviation: 'FNT',
        name: $localize`Fernando de Noronha Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -2',
      },
      {
        abbreviation: 'GALT',
        name: $localize`Galapagos Time`,
        locations: [Continent.Oceania],
        offset: 'UTC -6',
      },
      {
        abbreviation: 'GAMT',
        name: $localize`Gambier Time\nGAMT – Gambier Islands Time`,
        locations: [Continent.Oceania],
        offset: 'UTC -9',
      },
      {
        abbreviation: 'GET',
        name: $localize`Georgia Standard Time`,
        locations: [Continent.Asia, Continent.Europe],
        offset: 'UTC +4',
      },
      {
        abbreviation: 'GFT',
        name: $localize`French Guiana Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'GILT',
        name: $localize`Gilbert Island Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'GMT',
        name: $localize`Greenwich Mean Time\nUTC – Coordinated Universal Time\nGT – Greenwich Time`,
        locations: [
          Continent.Europe,
          Continent.Africa,
          Continent.NorthAmerica,
          Continent.Antarctica,
        ],
        offset: 'UTC +0',
      },
      {
        abbreviation: 'GST',
        name: $localize`Gulf Standard Time`,
        locations: [Continent.Asia],
        offset: 'UTC +4',
      },
      {
        abbreviation: 'GST',
        name: $localize`South Georgia Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -2',
      },
      {
        abbreviation: 'GYT',
        name: $localize`Guyana Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -4',
      },
      {
        abbreviation: 'HDT',
        name: $localize`Hawaii-Aleutian Daylight Time\nHADT – Hawaii Daylight Time`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -9',
      },
      {
        abbreviation: 'HKT',
        name: $localize`Hong Kong Time`,
        locations: [Continent.Asia],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'HOVST',
        name: $localize`Hovd Summer Time\nHOVDT – Hovd Daylight Time\nHOVDST – Hovd Daylight Saving Time`,
        locations: [Continent.Asia],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'HOVT',
        name: $localize`Hovd Time`,
        locations: [Continent.Asia],
        offset: 'UTC +7',
      },
      {
        abbreviation: 'HST',
        name: $localize`Hawaii Standard Time\nHAST – Hawaii-Aleutian Standard Time`,
        locations: [Continent.NorthAmerica, Continent.Oceania],
        offset: 'UTC -10',
      },
      {
        abbreviation: 'ICT',
        name: $localize`Indochina Time`,
        locations: [Continent.Asia],
        offset: 'UTC +7',
      },
      {
        abbreviation: 'IDT',
        name: $localize`Israel Daylight Time`,
        locations: [Continent.Asia],
        offset: 'UTC +3',
      },
      {
        abbreviation: 'IOT',
        name: $localize`Indian Chagos Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +6',
      },
      {
        abbreviation: 'IRDT',
        name: $localize`Iran Daylight Time\nIRST – Iran Summer Time\nIDT – Iran Daylight Time`,
        locations: [Continent.Asia],
        offset: 'UTC +4:30',
      },
      {
        abbreviation: 'IRKST',
        name: $localize`Irkutsk Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +9',
      },
      {
        abbreviation: 'IRKT',
        name: $localize`Irkutsk Time`,
        locations: [Continent.Asia],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'IRST',
        name: $localize`Iran Standard Time\nIT – Iran Time`,
        locations: [Continent.Asia],
        offset: 'UTC +3:30',
      },
      {
        abbreviation: 'IST',
        name: $localize`India Standard Time\nIT – India Time\nIST – Indian Standard Time`,
        locations: [Continent.Asia],
        offset: 'UTC +5:30',
      },
      {
        abbreviation: 'IST',
        name: $localize`Irish Standard Time\nIST – Irish Summer Time`,
        locations: [Continent.Europe],
        offset: 'UTC +1',
      },
      {
        abbreviation: 'IST',
        name: $localize`Israel Standard Time`,
        locations: [Continent.Asia],
        offset: 'UTC +2',
      },
      {
        abbreviation: 'JST',
        name: $localize`Japan Standard Time`,
        locations: [Continent.Asia],
        offset: 'UTC +9',
      },
      {
        abbreviation: 'KGT',
        name: $localize`Kyrgyzstan Time`,
        locations: [Continent.Asia],
        offset: 'UTC +6',
      },
      {
        abbreviation: 'KOST',
        name: $localize`Kosrae Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'KRAST',
        name: $localize`Krasnoyarsk Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'KRAT',
        name: $localize`Krasnoyarsk Time`,
        locations: [Continent.Asia],
        offset: 'UTC +7',
      },
      {
        abbreviation: 'KST',
        name: $localize`Korea Standard Time\nKST – Korean Standard Time\nKT – Korea Time`,
        locations: [Continent.Asia],
        offset: 'UTC +9',
      },
      {
        abbreviation: 'KUYT',
        name: $localize`Kuybyshev Time\nSAMST – Samara Summer Time`,
        locations: [Continent.Europe],
        offset: 'UTC +4',
      },
      {
        abbreviation: 'LHDT',
        name: $localize`Lord Howe Daylight Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'LHST',
        name: $localize`Lord Howe Standard Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +10:30',
      },
      {
        abbreviation: 'LINT',
        name: $localize`Line Islands Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +14',
      },
      {
        abbreviation: 'MAGST',
        name: $localize`Magadan Summer Time\nMAGST – Magadan Island Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'MAGT',
        name: $localize`Magadan Time\nMAGT – Magadan Island Time`,
        locations: [Continent.Asia],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'MART',
        name: $localize`Marquesas Time`,
        locations: [Continent.Oceania],
        offset: 'UTC -9:30',
      },
      {
        abbreviation: 'MAWT',
        name: $localize`Mawson Time`,
        locations: [Continent.Antarctica],
        offset: 'UTC +5',
      },
      {
        abbreviation: 'MDT',
        name: $localize`Mountain Daylight Time\nMDST – Mountain Daylight Saving Time\nNAMDT – North American Mountain Daylight Time\nHAR – Heure Avancée des Rocheuses (French)\n`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -6',
      },
      {
        abbreviation: 'MHT',
        name: $localize`Marshall Islands Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'MMT',
        name: $localize`Myanmar Time`,
        locations: [Continent.Asia],
        offset: 'UTC +6:30',
      },
      {
        abbreviation: 'MSD',
        name: $localize`Moscow Daylight Time\nMoscow Summer Time`,
        locations: [Continent.Europe],
        offset: 'UTC +4',
      },
      {
        abbreviation: 'MSK',
        name: $localize`Moscow Standard Time\nMCK – Moscow Time`,
        locations: [Continent.Europe, Continent.Asia],
        offset: 'UTC +3',
      },
      {
        abbreviation: 'MST',
        name: $localize`Mountain Standard Time\nMT – Mountain Time\nNAMST – North American Mountain Standard Time\nHNR – Heure Normale des Rocheuses (French)\n`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -7',
      },
      {
        abbreviation: 'MT',
        name: $localize`Mountain Time`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -7:00 / -6:00',
      },
      {
        abbreviation: 'MUT',
        name: $localize`Mauritius Time`,
        locations: [Continent.Africa],
        offset: 'UTC +4',
      },
      {
        abbreviation: 'MVT',
        name: $localize`Maldives Time`,
        locations: [Continent.Asia],
        offset: 'UTC +5',
      },
      {
        abbreviation: 'MYT',
        name: $localize`Malaysia Time\nMST – Malaysian Standard Time`,
        locations: [Continent.Asia],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'NCT',
        name: $localize`New Caledonia Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'NDT',
        name: $localize`Newfoundland Daylight Time\nHAT – Heure Avancée de Terre-Neuve (French)\n`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -2:30',
      },
      {
        abbreviation: 'NFDT',
        name: $localize`Norfolk Daylight Time\nNFDT – Norfolk Island Daylight Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'NFT',
        name: $localize`Norfolk Time\nNFT – Norfolk Island Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'NOVST',
        name: $localize`Novosibirsk Summer Time\nOMSST – Omsk Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +7',
      },
      {
        abbreviation: 'NOVT',
        name: $localize`Novosibirsk Time\nOMST – Omsk Standard Time`,
        locations: [Continent.Asia],
        offset: 'UTC +7',
      },
      {
        abbreviation: 'NPT',
        name: $localize`Nepal Time`,
        locations: [Continent.Asia],
        offset: 'UTC +5:45',
      },
      {
        abbreviation: 'NRT',
        name: $localize`Nauru Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'NST',
        name: $localize`Newfoundland Standard Time\nHNT – Heure Normale de Terre-Neuve (French)\n`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -3:30',
      },
      {
        abbreviation: 'NUT',
        name: $localize`Niue Time`,
        locations: [Continent.Oceania],
        offset: 'UTC -11',
      },
      {
        abbreviation: 'NZDT',
        name: $localize`New Zealand Daylight Time`,
        locations: [Continent.Oceania, Continent.Antarctica],
        offset: 'UTC +13',
      },
      {
        abbreviation: 'NZST',
        name: $localize`New Zealand Standard Time`,
        locations: [Continent.Oceania, Continent.Antarctica],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'OMSST',
        name: $localize`Omsk Summer Time\nNOVST – Novosibirsk Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +7',
      },
      {
        abbreviation: 'OMST',
        name: $localize`Omsk Standard Time\nOMST – Omsk Time\nNOVT – Novosibirsk Time`,
        locations: [Continent.Asia],
        offset: 'UTC +6',
      },
      {
        abbreviation: 'ORAT',
        name: $localize`Oral Time`,
        locations: [Continent.Asia],
        offset: 'UTC +5',
      },
      {
        abbreviation: 'PDT',
        name: $localize`Pacific Daylight Time\nPDST – Pacific Daylight Saving Time\nNAPDT – North American Pacific Daylight Time\nHAP – Heure Avancée du Pacifique (French)\n`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -7',
      },
      {
        abbreviation: 'PET',
        name: $localize`Peru Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -5',
      },
      {
        abbreviation: 'PETST',
        name: $localize`Kamchatka Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'PETT',
        name: $localize`Kamchatka Time\nPETT – Petropavlovsk-Kamchatski Time`,
        locations: [Continent.Asia],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'PGT',
        name: $localize`Papua New Guinea Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +10',
      },
      {
        abbreviation: 'PHOT',
        name: $localize`Phoenix Island Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +13',
      },
      {
        abbreviation: 'PHT',
        name: $localize`Philippine Time\nPST – Philippine Standard Time`,
        locations: [Continent.Asia],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'PKT',
        name: $localize`Pakistan Standard Time\nPKT – Pakistan Time`,
        locations: [Continent.Asia],
        offset: 'UTC +5',
      },
      {
        abbreviation: 'PMDT',
        name: $localize`Pierre & Miquelon Daylight Time`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -2',
      },
      {
        abbreviation: 'PMST',
        name: $localize`Pierre & Miquelon Standard Time`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'PONT',
        name: $localize`Pohnpei Standard Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'PST',
        name: $localize`Pacific Standard Time\nPT – Pacific Time\nNAPST – North American Pacific Standard Time\nPT – Tiempo del Pacífico (Spanish)\nHNP – Heure Normale du Pacifique (French)\n`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -8',
      },
      {
        abbreviation: 'PST',
        name: $localize`Pitcairn Standard Time`,
        locations: [Continent.Oceania],
        offset: 'UTC -8',
      },
      {
        abbreviation: 'PT',
        name: $localize`Pacific Time`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -8:00 / -7:00',
      },
      {
        abbreviation: 'PWT',
        name: $localize`Palau Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +9',
      },
      {
        abbreviation: 'PYST',
        name: $localize`Paraguay Summer Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'PYT',
        name: $localize`Paraguay Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -4',
      },
      {
        abbreviation: 'PYT',
        name: $localize`Pyongyang Time\nPYST – Pyongyang Standard Time`,
        locations: [Continent.Asia],
        offset: 'UTC +8:30',
      },
      {
        abbreviation: 'QYZT',
        name: $localize`Qyzylorda Time`,
        locations: [Continent.Asia],
        offset: 'UTC +6',
      },
      {
        abbreviation: 'RET',
        name: $localize`Reunion Time`,
        locations: [Continent.Africa],
        offset: 'UTC +4',
      },
      {
        abbreviation: 'ROTT',
        name: $localize`Rothera Time`,
        locations: [Continent.Antarctica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'SAKT',
        name: $localize`Sakhalin Time`,
        locations: [Continent.Asia],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'SAMT',
        name: $localize`Samara Time\nSAMT – Samara Standard Time`,
        locations: [Continent.Europe],
        offset: 'UTC +4',
      },
      {
        abbreviation: 'SAST',
        name: $localize`South Africa Standard Time\nSAST – South African Standard Time`,
        locations: [Continent.Africa],
        offset: 'UTC +2',
      },
      {
        abbreviation: 'SBT',
        name: $localize`Solomon Islands Time\nSBT – Solomon Island Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'SCT',
        name: $localize`Seychelles Time`,
        locations: [Continent.Africa],
        offset: 'UTC +4',
      },
      {
        abbreviation: 'SGT',
        name: $localize`Singapore Time\nSST – Singapore Standard Time`,
        locations: [Continent.Asia],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'SRET',
        name: $localize`Srednekolymsk Time`,
        locations: [Continent.Asia],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'SRT',
        name: $localize`Suriname Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'SST',
        name: $localize`Samoa Standard Time`,
        locations: [Continent.Oceania],
        offset: 'UTC -11',
      },
      {
        abbreviation: 'SYOT',
        name: $localize`Syowa Time`,
        locations: [Continent.Antarctica],
        offset: 'UTC +3',
      },
      {
        abbreviation: 'TAHT',
        name: $localize`Tahiti Time`,
        locations: [Continent.Oceania],
        offset: 'UTC -10',
      },
      {
        abbreviation: 'TFT',
        name: $localize`French Southern and Antarctic Time\nKIT – Kerguelen (Islands) Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +5',
      },
      {
        abbreviation: 'TJT',
        name: $localize`Tajikistan Time`,
        locations: [Continent.Asia],
        offset: 'UTC +5',
      },
      {
        abbreviation: 'TKT',
        name: $localize`Tokelau Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +13',
      },
      {
        abbreviation: 'TLT',
        name: $localize`East Timor Time`,
        locations: [Continent.Asia],
        offset: 'UTC +9',
      },
      {
        abbreviation: 'TMT',
        name: $localize`Turkmenistan Time`,
        locations: [Continent.Asia],
        offset: 'UTC +5',
      },
      {
        abbreviation: 'TOST',
        name: $localize`Tonga Summer Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +14',
      },
      {
        abbreviation: 'TOT',
        name: $localize`Tonga Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +13',
      },
      {
        abbreviation: 'TRT',
        name: $localize`Turkey Time`,
        locations: [Continent.Asia, Continent.Europe],
        offset: 'UTC +3',
      },
      {
        abbreviation: 'TVT',
        name: $localize`Tuvalu Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'ULAST',
        name: $localize`Ulaanbaatar Summer Time\nULAST – Ulan Bator Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +9',
      },
      {
        abbreviation: 'ULAT',
        name: $localize`Ulaanbaatar Time\nULAT – Ulan Bator Time`,
        locations: [Continent.Asia],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'UTC',
        name: $localize`Coordinated Universal Time`,
        locations: [$localize`Worldwide`],
        offset: 'UTC',
      },
      {
        abbreviation: 'UYST',
        name: $localize`Uruguay Summer Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -2',
      },
      {
        abbreviation: 'UYT',
        name: $localize`Uruguay Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'UZT',
        name: $localize`Uzbekistan Time`,
        locations: [Continent.Asia],
        offset: 'UTC +5',
      },
      {
        abbreviation: 'VET',
        name: $localize`Venezuelan Standard Time\nHLV – Hora Legal de Venezuela (Spanish)\n`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -4',
      },
      {
        abbreviation: 'VLAST',
        name: $localize`Vladivostok Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'VLAT',
        name: $localize`Vladivostok Time`,
        locations: [Continent.Asia],
        offset: 'UTC +10',
      },
      {
        abbreviation: 'VOST',
        name: $localize`Vostok Time`,
        locations: [Continent.Antarctica],
        offset: 'UTC +6',
      },
      {
        abbreviation: 'VUT',
        name: $localize`Vanuatu Time\nEFATE – Efate Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +11',
      },
      {
        abbreviation: 'WAKT',
        name: $localize`Wake Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'WARST',
        name: $localize`Western Argentine Summer Time`,
        locations: [Continent.SouthAmerica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'WAST',
        name: $localize`West Africa Summer Time`,
        locations: [Continent.Africa],
        offset: 'UTC +2',
      },
      {
        abbreviation: 'WAT',
        name: $localize`West Africa Time`,
        locations: [Continent.Africa],
        offset: 'UTC +1',
      },
      {
        abbreviation: 'WEST',
        name: $localize`Western European Summer Time\nWEDT – Western European Daylight Time\nWESZ – Westeuropäische Sommerzeit (German)\n`,
        locations: [Continent.Europe, Continent.Africa],
        offset: 'UTC +1',
      },
      {
        abbreviation: 'WET',
        name: $localize`Western European Time\nGMT – Greenwich Mean Time\nWEZ – Westeuropäische Zeit (German)\n`,
        locations: [Continent.Europe, Continent.Africa],
        offset: 'UTC +0',
      },
      {
        abbreviation: 'WFT',
        name: $localize`Wallis and Futuna Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +12',
      },
      {
        abbreviation: 'WGST',
        name: $localize`Western Greenland Summer Time\nWGST – West Greenland Summer Time`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -2',
      },
      {
        abbreviation: 'WGT',
        name: $localize`West Greenland Time\nWGT – Western Greenland Time`,
        locations: [Continent.NorthAmerica],
        offset: 'UTC -3',
      },
      {
        abbreviation: 'WIB',
        name: $localize`Western Indonesian Time\nWIB – Waktu Indonesia Barat`,
        locations: [Continent.Asia],
        offset: 'UTC +7',
      },
      {
        abbreviation: 'WIT',
        name: $localize`Eastern Indonesian Time\nWIT – Waktu Indonesia Timur`,
        locations: [Continent.Asia],
        offset: 'UTC +9',
      },
      {
        abbreviation: 'WITA',
        name: $localize`Central Indonesian Time\nWITA – Waktu Indonesia Tengah`,
        locations: [Continent.Asia],
        offset: 'UTC +8',
      },
      {
        abbreviation: 'WST',
        name: $localize`West Samoa Time\nST – Samoa Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +13',
      },
      {
        abbreviation: 'WST',
        name: $localize`Western Sahara Summer Time`,
        locations: [Continent.Africa],
        offset: 'UTC +1',
      },
      {
        abbreviation: 'WT',
        name: $localize`Western Sahara Standard Time\nWT – Western Sahara Time`,
        locations: [Continent.Africa],
        offset: 'UTC +0',
      },
      {
        abbreviation: 'YAKST',
        name: $localize`Yakutsk Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +10',
      },
      {
        abbreviation: 'YAKT',
        name: $localize`Yakutsk Time`,
        locations: [Continent.Asia],
        offset: 'UTC +9',
      },
      {
        abbreviation: 'YAPT',
        name: $localize`Yap Time`,
        locations: [Continent.Oceania],
        offset: 'UTC +10',
      },
      {
        abbreviation: 'YEKST',
        name: $localize`Yekaterinburg Summer Time`,
        locations: [Continent.Asia],
        offset: 'UTC +6',
      },
      {
        abbreviation: 'YEKT',
        name: $localize`Yekaterinburg Time`,
        locations: [Continent.Asia],
        offset: 'UTC +5',
      },
    ],
    ['name'],
  );

  mapZonesByContient = new Map<string, ITimeZone[]>();

  mapZones = new Map<string, ITimeZone>(
    this.timeZones.map((timeZone: ITimeZone) => {
      return [timeZone.abbreviation, { name: timeZone.name, offset: timeZone.offset }];
    }),
  );

  constructor() {
    this.timeZones.forEach((zone: ITimeZone) => {
      const { name, abbreviation, offset } = zone;
      const data: ITimeZone = { name, abbreviation, offset };

      zone.locations.forEach((location: string) => {
        if (this.mapZonesByContient.has(location)) {
          const continentTimeZones = this.mapZonesByContient.get(location);
          const updatedContinentTimeZones = [...continentTimeZones, data];
          this.mapZonesByContient.set(location, updatedContinentTimeZones);
        } else {
          this.mapZonesByContient.set(location, [data]);
        }
      });
    });
  }
}
