import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter/src';
import { SearchParameters } from '../enums/search-parameters.enum';

@Injectable({
  providedIn: 'root',
})
export class SearchParameterList extends SelectInputsList {
  keyValue = {
    [SearchParameters.Name]: $localize`:Parameter for searching organisation|:Name`,
    [SearchParameters.EntityUid]: $localize`:Parameter for searching organisation|:Organisation ID`,
    [SearchParameters.BusinessIdentifierValue]: $localize`:Parameter for searching organisation|:Business Identifier Value`,
  };

  list: ISelectInput[] = this.getSelectInputs();
}
