import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IQueryParams } from '@portal/shared/ui/table';
import {
  IFilterToApply,
  ISearchBoxValue,
  ISelectInput,
  SearchOperator,
} from '@portal/shared/ui/filter';
import orderBy from 'lodash-es/orderBy';
import { SearchSelector } from '@portal/entity-services/organisations/src/lib/enums/search-selector.enum';
import { Subject } from 'rxjs';
import isEmpty from 'lodash-es/isEmpty';
import { HierarchyFilterComponent } from '@portal/shared/ui/filter/src/lib/hierarchy-filter/hierarchy-filter.component';

@Component({
  selector: 'portal-filter-organisation-group',
  templateUrl: './filter.component.html',
})
export class FilterOrganisationGroupComponent {
  @Input()
  filter: IQueryParams;

  @Input()
  filterTouched: boolean;

  @Output()
  orgsFiltered: EventEmitter<ISearchBoxValue> = new EventEmitter();

  @Output()
  nameFiltered: EventEmitter<ISearchBoxValue> = new EventEmitter();

  @Output()
  orgsFilterReset: EventEmitter<void> = new EventEmitter();

  @Output()
  orgsFilterChanged: EventEmitter<string> = new EventEmitter();

  @Output()
  searchUpdated: EventEmitter<ISelectInput> = new EventEmitter();

  @ViewChild(HierarchyFilterComponent) filterEntityComponent: HierarchyFilterComponent;

  searchEntities: ISelectInput[] = orderBy(
    [
      {
        id: SearchSelector.Name,
        text: $localize`:Search criteria in Organisation Group list:Group name`,
      },
      {
        id: '',
        text: '',
      },
    ],
    'text',
  );

  searchTerm: Subject<string> = new Subject();
  selectedSearchSelector: Subject<string> = new Subject();
  organisationIdSearchSelected = false;
  entityNameSelector = SearchSelector.Name;

  selectedOrganisations = new Map<string, ISelectInput>();
  orgFilterDisplayDetails: ISelectInput = {
    id: SearchSelector.EntityId,
    text: $localize`Entities`,
  };
  resetEntity$ = new Subject();

  isKnownSearchEntity(entityKey: string): boolean {
    return this.searchEntities.some((entity: ISelectInput) => {
      return entity.id === entityKey;
    });
  }

  onOrganisationSearched(input: ISearchBoxValue): void {
    // Check for an unknown search entity.
    if (!this.isKnownSearchEntity(input.key)) {
      return;
    }

    if (isEmpty(input.value)) {
      this.orgsFilterChanged.emit(input.key);
      this.nameFiltered.emit();
    } else {
      this.nameFiltered.emit({
        key: input.key,
        value: input.value,
        operator: SearchOperator.Equal,
      });
    }
  }

  onOrgFilterApplied(filter: IFilterToApply): void {
    const values = new Set<string>();
    this.selectedOrganisations.clear();
    if (filter && filter.value) {
      filter.value.forEach((value: ISelectInput) => {
        values.add(value.id);
        this.selectedOrganisations.set(value.id, value);
      });
    }

    if (values.size) {
      this.orgFilterDisplayDetails.text = $localize`Organisations (${values.size})`;
      this.orgsFiltered.emit({
        key: SearchSelector.EntityId,
        operator: SearchOperator.In,
        value: `${Array.from(values).join(',')}`,
      });
    } else {
      this.orgFilterDisplayDetails.text = $localize`Organisations`;
      this.onSearchEntityRemoved(SearchSelector.EntityId);
      this.orgsFiltered.emit();
    }
  }

  resetFilter(): void {
    this.searchTerm.next('');
    this.orgsFilterReset.emit();
    this.orgsFilterChanged.emit();
    this.nameFiltered.emit();
    this.selectedOrganisations.clear();
    this.selectedOrganisations.clear();
    this.filterEntityComponent.removeAllEntities();
    this.resetEntity$.next();
  }

  onSearchEntityRemoved(key: string): void {
    this.orgsFilterChanged.emit(key);
  }

  searchBoxInitComplete(): void {
    this.searchEntities.forEach((entity) => {
      if (this.filter.searchCriteria.has(entity.id)) {
        this.selectedSearchSelector.next(entity.id);
        this.searchTerm.next(this.filter.searchCriteria.get(entity.id).argument);
      }
    });
  }

  onSearchUpdated(searchInput: ISelectInput): void {
    this.organisationIdSearchSelected = searchInput.key === SearchSelector.EntityId;
    this.searchUpdated.emit(searchInput);
  }
}
