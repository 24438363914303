import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicAppCatalogService } from '../state/public-app-catalog.service';

@Component({
  selector: 'marketplace-public-app-page',
  templateUrl: './public-app-page.component.html',
  styleUrls: ['./public-app-page.component.scss'],
})
export class PublicAppPageComponent implements OnInit {
  app$ = this.publicAppCatalogService.getApp(this.route.snapshot.params.id);

  constructor(
    private route: ActivatedRoute,
    private publicAppCatalogService: PublicAppCatalogService,
  ) {}

  ngOnInit(): void {
    this.publicAppCatalogService.loadPblicAppCatalog();
  }
}
