import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers';
import { ISelectInput } from '@portal/shared/ui/filter';
import { PaymentDocumentType } from '../enums/payment-document-type.enum';

declare const $localize;

@Injectable({
  providedIn: 'root',
})
export class PaymentDocumentTypes extends SelectInputsList {
  keyValue = {
    [PaymentDocumentType.Invoice]: $localize`Tax invoice`,
    [PaymentDocumentType.FullReceipt]: $localize`Receipt`,
    [PaymentDocumentType.InvoiceReceipt]: $localize`Tax invoice receipt`,
  };
  list: ISelectInput[] = this.getSelectInputs();
}
