import { Pipe, PipeTransform } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers';

@Pipe({
  name: 'listValues',
})
export class ListValuesPipe implements PipeTransform {
  transform(keys: string[], list: SelectInputsList): any {
    return keys?.length && list ? keys.map((key) => list.getValue(key)).join(', ') : '';
  }
}
