import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'marketplace-main-icon',
  templateUrl: './main-icon.component.html',
  styleUrls: ['./main-icon.component.scss'],
})
export class MainIconComponent implements OnInit {
  @Input() image: string;
  constructor() {}

  ngOnInit(): void {}

  changeIcon(): void {}
}
