export default {
  'ds-entity-service': 'ES',
  'receipt-service': 'RCS',
  rms: 'RMS',
  dsapiquery: 'PDSP',
  'oidc/api/v2': 'ECOM',
  'paypal-ecom': 'PAYPAL_ECOM',
  vfappcatalog: 'VF_APP_CATALOG',
  vfappmarket: 'VF_MARKETPLACE',
  onboarding: 'ONBOARDING',
  'apple-pay': 'APPLEPAY_ONBOARDING',
  'validation-service': 'VS',
  vfreports: 'RS',
  'logging-service': 'LS',
  'checkout-service': 'CO',
  'customer-service': 'CS',
  'order-service': 'OS',
  'portfolio-service': 'PS',
  '3ds-service': 'TDS',
  'report-engine': 'RE',
  analytics: 'PA',
  'invoice-service': 'IS',
  'event-service': 'EVS',
  'chargeback-service': 'CGS',
  'uis/v1': 'UIS',
  'ext/users': 'UIS_EXTERNAL',
  'logs/web': 'LOGGING_SERVICE',
  'bin-service': 'BIN_SERVICE',
};
