<table
  class="table is-hoverable is-focusable is-fullwidth is-relative"
  data-e2e="portal-table"
  [class.is-loading]="loading$ | async"
>
  <thead *ngIf="tableConfig && tableConfig.columns">
    <tr>
      <th *ngIf="tableConfig.selectionConfig" data-e2e="hcell-checkmarks">
        <ng-container *ngIf="tableConfig.selectionConfig.selectionType === 'Multi'">
          <label class="checkbox">
            <input
              type="checkbox"
              class="selectAll"
              (click)="selectAll($event)"
              data-e2e="select-all-checkbox"
              [indeterminate]="
                activeSelection?.length > 0 && activeSelection?.length !== items?.length
              "
              [checked]="activeSelection?.length > 0 && selection?.length === items?.length"
            />
            <span></span>
          </label>
        </ng-container>
      </th>
      <th *ngFor="let column of tableConfig.columns" [attr.data-e2e]="'hcell-' + column.key">
        {{ column.label }}
        <portal-sort-button
          [order]="getColumnOrder(column.key)"
          (sortChanged)="onSortChanged(column.key, $event)"
          *ngIf="filter && column.isSortable"
        >
        </portal-sort-button>
      </th>
      <th *ngIf="hasRowLink" data-e2e="hcell-links">
        <span class="is-sr-only">{{ rowLinkHeading }}</span>
      </th>
      <th *ngIf="tableConfig?.actions" data-e2e="hcell-actions">
        <div class="dropdown is-right is-hoverable">
          <div class="dropdown-trigger">
            <button
              type="button"
              class="button is-empty is-transparent"
              data-e2e="hcell-actions-button"
              title="Open"
              i18n-title
            >
              <span class="icon">
                <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <a
                *ngIf="tableConfig?.actions?.exportCsv"
                class="dropdown-item"
                (click)="exportCsv()"
                data-e2e="actions-export-csv"
              >
                Export to CSV
              </a>
            </div>
          </div>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="tableConfig">
      <ng-container *ngIf="(loading$ | async) && !items; else dataRows">
        <tr *ngFor="let row of loadingRows" data-e2e="table-loading-row">
          <td
            *ngFor="let column of loadingColumns; let i = index"
            [ngStyle]="tableConfig.columns[i]?.loadingInlineStyle || null"
          ></td>
        </tr>
      </ng-container>
      <ng-template #dataRows data-e2e="table-data-rows">
        <tr
          *ngFor="let item of items"
          (click)="onRowClicked($event)"
          data-e2e="table-row"
          [class.is-disabled]="hasDisabledRow(item)"
        >
          <ng-container *ngIf="tableConfig?.selectionConfig?.selectionType === 'Multi'">
            <td>
              <label class="checkbox">
                <input
                  type="checkbox"
                  [checked]="isSelected(item[tableConfig.rowIdKey])"
                  [disabled]="isDisabled(item[tableConfig.rowIdKey])"
                  (change)="onRowSelected(item, $event)"
                  value="item[tableConfig.rowIdKey]"
                  data-e2e="select-checkbox"
                />
                <span></span>
              </label>
            </td>
          </ng-container>
          <ng-container *ngIf="tableConfig?.selectionConfig?.selectionType === 'Single'">
            <td>
              <label class="radio">
                <input
                  type="radio"
                  [checked]="isSelected(item[tableConfig.rowIdKey])"
                  [disabled]="isDisabled(item[tableConfig.rowIdKey])"
                  (change)="onRowSelected(item, $event)"
                  name="radio"
                  value="item[tableConfig.rowIdKey]"
                  data-e2e="select-checkbox-of-single-type"
                />
                <span></span>
              </label>
            </td>
          </ng-container>

          <td
            *ngFor="let column of tableConfig.columns; let i = index"
            [ngClass]="column.cssClass"
            [ngStyle]="tableConfig.columns[i]?.cssStyle || null"
            [attr.data-label]="column.label"
            [attr.title]="column.isfromDeviceManager ? (item | getItem : column.key) || '' : ''"
          >
            <div
              *ngIf="!column.isRenderFunction && !column.isTemplateRef"
              [ngClass]="column.innerCssClass"
              [attr.data-e2e]="column.key"
            >
              {{ item | getItem : column.key }}
            </div>
            <div
              *ngIf="column.isRenderFunction"
              [ngStyle]="column.inlineStyle"
              [ngClass]="column.innerCssClass"
              [innerHTML]="renderFunction(column.renderFunction(item), column.renderImage)"
              [attr.data-e2e]="column.key"
              [attr.style]="'pointer-events: none;'"
            ></div>
            <div *ngIf="column.isTemplateRef" [ngStyle]="column.inlineStyle || null">
              <ng-container
                *ngTemplateOutlet="column.templateRef(); context: { data: item }"
              ></ng-container>
            </div>
          </td>
          <td *ngIf="hasRowLink">
            <a
              class="table-row-link is-pulled-right"
              [routerLink]="[rowLinkHref, item | getItem : tableConfig.rowIdKey]"
              data-e2e="link-detail"
              *ngIf="!(loading$ | async)"
            >
              <span class="icon" data-e2e="icon">
                <span class="is-sr-only">{{ item | getItem : tableConfig.rowLabelKey }}</span>
                <i class="fas fa-chevron-right" aria-hidden="true"></i>
              </span>
            </a>
          </td>
          <td *ngIf="tableConfig?.actions"></td>
        </tr>
      </ng-template>
    </ng-container>
    <tr *ngIf="!tableConfig">
      <td [attr.colspan]="colspan">{{ errorMessage }}</td>
    </tr>
    <tr
      *ngIf="!(loading$ | async) && !items?.length"
      data-e2e="table-no-data-row"
      class="has-text-centered"
    >
      <td [attr.colspan]="colspan">{{ notFoundMessage }}</td>
    </tr>
  </tbody>
</table>
