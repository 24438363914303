<vui-uis-auth-page-content [showHeader]="true" title="Change password" i18n-title>
  <ng-container header *ngIf="config?.showBacklink">
    <p class="mb-2">
      <a
        [routerLink]="['/administration', 'users', userService.userId, 'edit']"
        data-e2e="link-back"
        title="Back to my account"
        i18n-title
      >
        <span class="icon is-small">
          <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
        </span>
        <span i18n="Link back to My Account">My account</span>
      </a>
    </p>
  </ng-container>
  <ng-container content>
    <portal-change-password-error
      [show]="errorMessage"
      [errorMessage]="errorMessage"
    ></portal-change-password-error>
    <form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="onSubmit()">
      <p class="has-text-weight-semibold mb-1" i18n>Minimal password requirements:</p>
      <portal-password-validation
        [passwordConfirm]="true"
        [control]="newPassword"
        [validated]="ngForm.submitted"
      ></portal-password-validation>
      <portal-password-input
        class="field mt-4"
        formControlName="currentPassword"
        [noValidation]="true"
        [isInvalid]="isInvalid(currentPassword)"
        label="Current password"
        i18n-label
      ></portal-password-input>
      <portal-password-input
        class="field"
        formControlName="newPassword"
        label="New password"
        i18n-label
      ></portal-password-input>
      <portal-password-input
        class="field"
        formControlName="newPasswordRepeat"
        label="Confirm new password"
        i18n-label="Label for password confirmation field"
      ></portal-password-input>
      <div class="columns is-vcentered is-mobile mt-2">
        <div class="column">
          <a
            class="button is-primary is-outlined"
            restrict-permission
            [routerLink]="
              config?.navigateUrl || ['/administration', 'users', userService.userId, 'edit']
            "
            i18n
            data-e2e="changePassword-link-cancel"
            title="Cancel"
            i18n-title
          >
            Cancel
          </a>
        </div>

        <div class="column is-narrow">
          <button
            class="button is-primary"
            type="submit"
            i18n
            data-e2e="changePassword-button-submit"
            title="Update"
            i18n-title
          >
            Update
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</vui-uis-auth-page-content>
