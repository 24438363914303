import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedAuthAuthorizationModule } from '@portal/shared/auth/authorization';
import { MainIconComponent } from './main-icon.component';

@NgModule({
  imports: [CommonModule, SharedAuthAuthorizationModule],
  declarations: [MainIconComponent],
  exports: [MainIconComponent],
})
export class MainIconModule {}
