import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPoi } from '@portal/marketplace/api-interfaces';

@Component({
  selector: 'marketplace-device-card-template',
  templateUrl: './device-card-template.component.html',
  styleUrls: ['./device-card-template.component.scss'],
})
export class DeviceCardTemplateComponent implements OnInit {
  @Input() poi: IPoi;
  @Input() set link(val: string[]) {
    this.rLink = val;
  }
  @Input() set isChecked(value: boolean) {
    this.hasSelection = true;
    this.checked = value;
  }
  @Output() onPoiSelection: EventEmitter<{ poi: IPoi; checked: boolean }> = new EventEmitter<{
    poi: IPoi;
    checked: boolean;
  }>();

  hasSelection = false;
  private rLink: string[];
  private checked = false;

  get link(): string[] {
    return this.rLink || ['device', this.poi ? this.poi.altVfiPoiId : '404'];
  }

  get isChecked(): boolean {
    return this.checked;
  }

  constructor() {}
  get poiIcon(): string {
    if (this.poi && this.poi.device) {
      return `assets/images/devices/${this.poi.device?.model?.deviceType}/card`;
    }

    return `assets/images/devices/PENDING/card`;
  }

  get status(): string {
    return this.poi.device ? this.poi.status : 'PENDING';
  }
  get merchantName(): string {
    if (this.poi.entity.name.length > 18) {
      return this.poi.entity.name.substring(0, 15) + '...';
    } else {
      return this.poi.entity.name;
    }
  }

  ngOnInit(): void {}

  onPoiSelect(poi: IPoi, event): void {
    this.onPoiSelection.emit({ poi, checked: event.target.checked });
  }
}
