import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import {
  DevAppCatalogState,
  DevAppCatalogStore,
} from '@portal/marketplace/app-catalog/src/lib/state/dev-app-catalog/dev-app-catalog.store';
import { IApp, IDevApp } from '@portal/marketplace/api-interfaces/src';

@Injectable({
  providedIn: 'root',
})
export class DevAppCatalogQuery extends QueryEntity<DevAppCatalogState, IApp> {
  // AppCatalogData$ = this.selectAll();
  loading$ = this.selectLoading();
  itemsCount$ = this.select((state) => state.metadata.count);
  constructor(
    protected store: DevAppCatalogStore,
    // eslint-disable-next-line
    private routerQuery: RouterQuery,
  ) {
    super(store);
  }
}
