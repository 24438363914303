<div class="columns is-vcentered">
  <div class="column">
    <portal-search-box
      [isDeviceMobile]="isDeviceMobile$ | async"
      [entities]="searchEntities"
      [searchTerm]="searchTerm"
      [selectedSelector]="selectedSearchSelector"
      (entitySearched)="onUserSearched($event)"
      (searchEntityRemoved)="onSearchEntityRemoved($event)"
      (searchBoxInitComplete)="searchBoxInitComplete()"
    ></portal-search-box>
    <div class="field is-grouped is-grouped-multiline filter-container">
      <div class="control">
        <portal-filter-wrapper>
          <div class="control">
            <portal-hierarchy-filters
              [selectedEntities]="filter?.searchCriteria"
              [resetEntity$]="resetEntity$"
              (filterApplied)="onOrgFilterApplied($event)"
            >
            </portal-hierarchy-filters>
          </div>
          <div class="control xs-full-width">
            <portal-filter-user-status
              [enableDeletedStatus]="true"
              (filterUpdated)="onUserStatusFilterUpdated($event)"
            ></portal-filter-user-status>
          </div>
        </portal-filter-wrapper>
      </div>

      <div class="control" *ngIf="filterTouched">
        <button
          i18n
          class="button is-outlined"
          (click)="resetFilter()"
          data-e2e="usersFilter-button-clear-filter"
          type="button"
          title="Clear filter"
          i18n-title
        >
          Clear filters
        </button>
      </div>
    </div>
  </div>
</div>
