<section class="block mp-block">
  <div class="block mp-block">
    <div class="columns">
      <div class="column is-12">
        <portal-breadcrumbs>
          {{ bundle?.bundleName }}
        </portal-breadcrumbs>
      </div>
    </div>
    <!-- Main container -->
    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <!-- <div class="level-item"> -->
        <h1
          class="level-item is-size-3 has-text-weight-bold"
          data-e2e="bundle-page-bundle-name-main"
        >
          {{ bundle?.bundleName }}
        </h1>
        <ng-content select=".bundles-versions"></ng-content>
      </div>

      <!-- Right side -->
      <div class="level-right">
        <ng-content select=".action-buttons"></ng-content>
      </div>
    </nav>
  </div>
</section>

<div class="container">
  <div *ngIf="bundleLoadingState === true" class="spinner-container bundle-spinner">
    <portal-spinner></portal-spinner>
  </div>
  <div *ngIf="bundleLoadingState === false" class="box">
    <div class="columns">
      <div class="column is-3">
        <figure class="image is-480x480">
          <marketplace-bundle-logo-template [bundle]="bundle"></marketplace-bundle-logo-template>
        </figure>
      </div>

      <div class="column is-9">
        <div class="tile is-vertical is-parent is-padivingless details-list">
          <div class="tile is-child tables">
            <div class="definition-list is-multiline">
              <div class="definition">
                <div class="term" data-e2e="bundle-details-status">
                  <strong>Status:</strong>
                </div>
                <div class="description">
                  <ng-container [ngSwitch]="bundle?.status">
                    <ng-container *ngSwitchCase="'ACTIVE'"
                      ><span class="tag is-success">Active</span></ng-container
                    >
                    <ng-container *ngSwitchDefault>{{ bundle?.status }}</ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="box__separator"></div>
              <div class="definition">
                <div class="term" i18n><strong>Market:</strong></div>
                <div class="description">
                  <ng-container *ngFor="let category of bundle?.categoryFamiles | keyvalue">
                    <span class="tag no-wrap" data-e2e="bundle-details-market">{{
                      category.value.displayName
                    }}</span
                    >&nbsp;
                  </ng-container>
                </div>
              </div>
              <div class="box__separator"></div>
              <div class="definition">
                <div class="term">
                  <strong>Supported terminals:</strong>
                </div>
                <div class="description">
                  <ng-container *ngFor="let model of bundle?.modelFamilies | keyvalue">
                    <span class="tag no-wrap" data-e2e="bundle-tag">{{
                      model.value.displayName
                    }}</span>
                    &nbsp;
                  </ng-container>
                </div>
              </div>
              <div class="box__separator"></div>
              <div class="definition">
                <div class="term" data-e2e="bundle-details-version">
                  <strong>Version:</strong>
                </div>
                <div class="description">
                  {{ bundle?.bundleVersionName }}
                </div>
              </div>
              <div class="box__separator"></div>
              <div class="definition">
                <div class="term" i18n><strong>Price:</strong></div>
                <div class="description">
                  <p
                    *ngIf="bundle?.oneTimePrice === 0 && bundle?.recurringPrice === 0"
                    class="is-6"
                  >
                    Free
                  </p>
                  <p *ngIf="bundle?.oneTimePrice > 0" class="is-6">
                    One Time : {{ bundle?.oneTimePrice | currency : '$' }}
                  </p>
                  <p *ngIf="bundle?.recurringPrice > 0" class="is-6">
                    Monthly Price : {{ bundle?.recurringPrice | currency : '$' }}
                  </p>
                </div>
              </div>
              <ng-container *ngIf="bundle?.bundleDesc">
                <div class="box__separator"></div>
                <div class="definition">
                  <div class="term" i18n>
                    <strong>Description:</strong>
                  </div>
                  <div class="description" data-e2e="bundle-details-short-description">
                    {{ bundle?.bundleDesc }}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p>&nbsp;</p>
  <div class="container">
    <div *ngIf="appsLoadingState" class="spinner-container">
      <portal-spinner></portal-spinner>
    </div>
    <ng-container *ngIf="!appsLoadingState && apps">
      <h1 class="title">
        {{ apps.length }} Apps in this bundle
        <ng-content select=".create-bundle-btn"></ng-content>
      </h1>
      <div class="columns is-multiline">
        <div
          data-e2e="bundle-details-pricing-free-title"
          class="column is-grid is-4-desktop is-6-tablet"
        >
          <div class="card">
            <div class="card-header">
              <p class="card-header-title">Free</p>
            </div>
            <div class="card-content">
              <ng-container *ngFor="let app of apps"
                ><ng-container
                  *ngIf="app?.pricingModel === 'FREE'"
                  [ngTemplateOutlet]="appcard"
                  [ngTemplateOutletContext]="{ $implicit: app }"
                ><div class="ribbon"><marketplace-app-icon
                  *ngIf="app?.acquirerIsDefault"
                  icon="has-text-warning has-new-store-icon"
                  title="Default app"
                ></marketplace-app-icon></div>
                </ng-container
              ></ng-container>
            </div>
          </div>
        </div>
        <div
          data-e2e="bundle-details-pricing-onetime-title"
          class="column is-grid is-4-desktop is-6-tablet"
        >
          <div class="card">
            <div class="card-header">
              <p class="card-header-title">Once</p>
            </div>
            <div class="card-content">
              <ng-container *ngFor="let app of apps"
                ><ng-container
                  *ngIf="app?.pricingModel === 'ONE_TIME'"
                  [ngTemplateOutlet]="appcard"
                  [ngTemplateOutletContext]="{ $implicit: app }"
                ><div class="ribbon"><marketplace-app-icon
                  *ngIf="app?.acquirerIsDefault"
                  icon="has-text-warning has-new-store-icon"
                  title="Default app"
                ></marketplace-app-icon></div>
                </ng-container
              ></ng-container>
            </div>
          </div>
        </div>
        <div
          data-e2e="bundle-details-pricing-rec-title"
          class="column is-grid is-4-desktop is-6-tablet"
        >
          <div class="card">
            <div class="card-header">
              <p class="card-header-title">Monthly</p>
            </div>
            <div class="card-content">
              <ng-container *ngFor="let app of apps"
                ><ng-container
                  *ngIf="app?.pricingModel === 'RECURRING'"
                  [ngTemplateOutlet]="appcard"
                  [ngTemplateOutletContext]="{ $implicit: app }"
                ><div class="ribbon"><marketplace-app-icon
                  *ngIf="app?.acquirerIsDefault"
                  icon="has-text-warning has-new-store-icon"
                  title="Default app"
                ></marketplace-app-icon></div>
                </ng-container
              ></ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <p>&nbsp;</p>
  <ng-content select=".devices"></ng-content>
  <p>&nbsp;</p>
  <ng-content select=".merchants"></ng-content>
  <p>&nbsp;</p>

  <ng-template #appcard let-app>
    <div class="content">
      <ng-container data-e2e="bundle-details-free-pricing-link">
        <article class="media is-clickable" (click)="navigateToAppPage.emit(app)">
          <div class="media-left">
            <figure class="image is-48x48">
              <img
                data-e2e="bundle-details-free-app-icon"
                [src]="app.appIcons[0].publicUrl | secureLoad | async"
                alt="icon"
              />
            </figure>
          </div>
          <div class="media-content">
            <strong class="is-block" data-e2e="bundle-details-free-app-name">{{
              app?.appName
            }}</strong>
            <p class="subtitle" data-e2e="bundle-details-free-app-display-name">
              {{ app?.shortDescription }}
            </p>
            <span *ngIf="app?.pricingModel !== 'FREE'" class="is-block has-text-right"
            >{{ calculatePrice(app) | currency : '$'
          }}<ng-container *ngIf="app?.pricingModel === 'RECURRING'">/month</ng-container></span
          >
          <span *ngIf="app?.status === 'DISABLED'" class="has-text-danger">App is disabled</span>
          </div>
        </article>
      </ng-container>
      <hr class="m-0" />
    </div>
  </ng-template>
</div>
