import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ITag } from '@portal/marketplace/api-interfaces';
import { TagsState, TagsStore } from './tags.store';

@Injectable({
  providedIn: 'root',
})
export class TagsQuery extends QueryEntity<TagsState, ITag> {
  constructor(protected store: TagsStore) {
    super(store);
  }
}
