export enum InteractionType {
  Unspecified = 'UNSPECIFIED',
  AutoFuelDispenser = 'AUTO_FUEL_DISPENSER',
  AutoTellerMachine = 'AUTO_TELLER_MACHINE',
  EletronicCashRegister = 'ELECTRONIC_CASH_REGISTER',
  InflightCommerceTerminal = 'INFLIGHT_COMMERCE_TERMINAL',
  InterVoiceResponse = 'INTER_VOICE_RESPONSE',
  PointOfSale = 'POINT_OF_SALE',
  Kiosk = 'KIOSK',
  Unattended = 'UNATTENDED',
  Standalone = 'STANDALONE',
  Mobile = 'MOBILE',
  RfDevice = 'RF_DEVICE',
  Ticket = 'TICKET',
  CallCentre = 'CALL_CENTRE',
  Virtual = 'VIRTUAL',
}
