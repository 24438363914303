import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ISelectInput } from '@portal/shared/ui/filter/src';
import { ITransactionReportTemplate } from '../../../modules/transactions/interfaces/transaction-report-template.interface';
import { DropdownPosition } from '@ng-select/ng-select';

@Component({
  selector: 'portal-select-control',
  templateUrl: './select-control.component.html',
})
export class SelectControlComponent implements OnInit {
  @Input() name: string;
  @Input() optional = false;
  @Input() helperText: string;
  @Input() helperTextAsLink: boolean;
  @Input() control: FormControl;
  @Input() invalid: boolean;
  @Input() placeholder: string;
  @Input() options: ISelectInput[] | ITransactionReportTemplate[];
  @Input() e2eName = 'selectControl-select';
  @Input() e2eErrorName = 'selectControl-error';
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() isClearable: boolean = true;
  @Input() disabled: boolean = false;

  @Output() select = new EventEmitter<string>();
  @Output() selectEvent = new EventEmitter<ISelectInput | ITransactionReportTemplate>();
  @Output() detailsEvent = new EventEmitter<any>();

  onHelperTextEvent: Event;

  ngOnInit(): void {
    if (!this.control?.value) {
      this.control?.setValue(null);
    }
  }

  selectElement(control: Record<string, any>): void {
    this.options.forEach((option) => {
      if (option.id === control?.value) {
        this.selectEvent.emit(option);
      }
    });
    this.select.emit(control?.value);
  }

  onHelperText(): void {
    if (this.control?.value) {
      this.options.forEach((option) => {
        if (option.id === this.control?.value) {
          this.onHelperTextEvent = option;
        }
      });
    }
    this.detailsEvent.emit(this.onHelperTextEvent);
  }

  trackById(item: ISelectInput): string {
    return item.id;
  }
}
