import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import forEach from 'lodash-es/forEach';
import get from 'lodash-es/get';

import { ErrorService, MultilingualValidators } from '@portal/shared/ui/form';
import { ActionAmlTimeouts } from './action-aml-timeout.list';
import { OpeningHoursDays } from './opening-hours-day.list';
import { IBusinessInformation } from '../interfaces/business-information.interface';
import { ActionAmlTimeout } from '../enums/action-aml-timeout.enum';
import { IOpeningHours } from '../interfaces/opening-hours.interface';
import { IOpeningHoursTime } from '../interfaces/opening-hours-times.interface';
import { OpeningHoursDay } from '../enums/opening-hours-day.enum';

declare const $localize;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'portal-form-business-information',
  templateUrl: './form-business-information.component.html',
})
export class FormBusinessInformationComponent implements OnChanges {
  @Input() form: FormGroup;
  @Input() value: IBusinessInformation;
  @Input() disabled: boolean;

  formControls: { [key: string]: [string | string[], ValidatorFn[]] };

  isInvalid = ErrorService.isFieldInvalid;

  constructor(
    public actionAmlTimeouts: ActionAmlTimeouts,
    public openingHoursDays: OpeningHoursDays,
    private customValidators: MultilingualValidators,
  ) {
    this.formControls = {
      actionAMLTimeout: [ActionAmlTimeout.Allow, []],
      openingHours: [[], [this.customValidators.markOneOption]],
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes, 'form.currentValue') && get(changes, 'form.firstChange')) {
      forEach(this.formControls, (formControl: [string, ValidatorFn[]], key: string) => {
        const [state, validators]: [string, ValidatorFn[]] = formControl;
        this.form.setControl(key, new FormControl(state, validators));
      });
    }

    if (get(changes, 'value.currentValue')) {
      this.form.patchValue(this.value);
      this.buildOpeningHoursForm();

      if (this.disabled) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  get actionAmlTimeout(): AbstractControl {
    return this.form.get('actionAMLTimeout');
  }

  get openingHours(): AbstractControl {
    return this.form.get('openingHours');
  }

  get openingHoursForm(): FormArray {
    return this.form.get('openingHoursForm') as FormArray;
  }

  onOpeningHoursChanged(control: FormGroup): void {
    const openingHours: IOpeningHours[] = [];
    this.openingHoursForm.value.forEach((value, i) => {
      value.times.forEach((timeslot: IOpeningHoursTime) => {
        if (timeslot.start && timeslot.end) {
          openingHours.push({
            day: this.openingHoursDays.list[i].id as OpeningHoursDay,
            times: timeslot,
          });
        }
      });
    });
    this.openingHours.setValue(openingHours);

    control.patchValue({
      timesString: this.getTimesString(control),
    });
  }

  addTimeslot(control: FormArray): void {
    control.push(this.createTimesForm());
  }

  removeTimeslot(control: FormArray, index: number): void {
    control.removeAt(index);
    this.onOpeningHoursChanged(control.parent as FormGroup);
  }

  private getTimesString(control?: FormGroup): string {
    const closed = $localize`Closed`;

    if (!control) {
      return closed;
    }

    if (control.invalid) {
      return control.value.timesString;
    }

    const timesString = control.value.times
      .map((timeslot: IOpeningHoursTime) => {
        if (timeslot.start && timeslot.end) {
          return `${timeslot.start} - ${timeslot.end}`;
        }
      })
      .filter((timeslot: string) => timeslot)
      .join(', ');

    return timesString || closed;
  }

  private createTimesForm(value?: IOpeningHoursTime): FormGroup {
    return new FormGroup(
      {
        start: new FormControl(get(value, 'start', ''), this.customValidators.time),
        end: new FormControl(get(value, 'end', ''), this.customValidators.time),
      },
      this.customValidators.timePair,
    );
  }

  private buildOpeningHoursForm(): void {
    const controls = this.openingHoursDays.list.map((day) => {
      const openDays = this.openingHours.value.filter(
        (value: IOpeningHours) => value.day === day.id,
      );

      if (openDays.length) {
        const control = new FormGroup({
          isEditing: new FormControl(false),
          times: new FormArray(
            openDays.map((openDay: IOpeningHours) => {
              return this.createTimesForm(openDay.times);
            }),
            [this.customValidators.timePairOverlap],
          ),
        });
        control.setControl('timesString', new FormControl(this.getTimesString(control)));
        return control;
      }

      return new FormGroup({
        isEditing: new FormControl(false),
        times: new FormArray([this.createTimesForm()], this.customValidators.timePairOverlap),
        timesString: new FormControl(this.getTimesString()),
      });
    });

    this.form.setControl('openingHoursForm', new FormArray(controls));
  }
}
