<div class="container has-max-width-390">
  <ng-container *ngIf="showHeader">
    <ng-content select="[header]"></ng-content>
  </ng-container>
  <div [ngClass]="{ 'my-6': !showHeader }">
    <div class="box">
      <div class="splash__message">
        <h4 class="title is-3">{{ title }}</h4>

        <ng-content select="[content]"></ng-content>
      </div>
    </div>

    <div *ngIf="showFooter" class="has-text-centered">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>
