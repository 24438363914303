<footer>
  <div class="container">
    <div class="content">
      <div class="has-bg-logo-white-small" title="Verifone logo" i18n-title></div>
      <div class="links">
        <a class="link" *ngIf="documentationLink" [href]="documentationLink" target="_blank" i18n
          >Documentation</a
        >
        <a class="link" *ngIf="apiRefLink && !isPetroUser" [href]="apiRefLink" target="_blank" i18n
          >API Reference</a
        >
        <ng-container *ngIf="isLoggedIn && supportLink">
          <a
            class="link"
            *ngIf="supportLink?.routerLink"
            [routerLink]="supportLink?.routerLink"
            i18n
            >Support</a
          >
          <a class="link" *ngIf="supportLink?.href" [href]="supportLink?.href" i18n>Support</a>
        </ng-container>
      </div>
    </div>
    <div class="legal">
      <div class="content">
        <span i18n> &#169; {{ currentYear }} VeriFone </span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a *ngIf="legalLink" [href]="legalLink" target="_blank" i18n>
          Privacy and Compliance Policies
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a
          *ngIf="termsConditionsLink"
          [href]="termsConditionsLink"
          target="_blank"
          i18n
          data-e2e="terms-condition"
          >Terms and conditions</a
        >
        &nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    </div>
  </div>
</footer>
