import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { FilterStaticBase } from '@portal/shared/ui/filter/src/lib/components/static-base';
import { FILTER_CONTENT_ITEM, ISelectInput, SearchSelector } from '@portal/shared/ui/filter/src';
import { ReportStatusTypes } from '../../../list/report-status.list';

@Component({
  selector: 'portal-filter-report-status',
  templateUrl: './report-status.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: FilterReportStatusComponent,
    },
  ],
})
export class FilterReportStatusComponent extends FilterStaticBase {
  statusTypeList: ISelectInput[];
  serviceProviderText = $localize`Report Status`;
  refresh$ = new Subject<void>();

  private currentSelectedEntity: ISelectInput;

  constructor(statusTypes: ReportStatusTypes) {
    super(SearchSelector.Status, statusTypes.list);
    this.statusTypeList = statusTypes.list;
  }

  removeAllEntities(): void {
    this.localPreSelectedEntities.clear();
    this.refresh$.next();
  }

  onEntityTypeSelection(selectedEntity: ISelectInput): void {
    if (this.currentSelectedEntity?.id !== selectedEntity.id) {
      this.localPreSelectedEntities.clear();
    }
    if (this.localPreSelectedEntities.has(selectedEntity.id)) {
      this.onEntityRemoved(selectedEntity);
      this.removeAllEntities();
    } else {
      this.onEntityAdded(selectedEntity);
      this.currentSelectedEntity = selectedEntity;
    }
  }
}
