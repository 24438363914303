import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISelectInput } from '@portal/shared/ui/filter';

@Component({
  selector: 'portal-search-filter-checkbox',
  templateUrl: './search-checkbox.component.html',
})
export class FilterCheckboxComponent {
  @Input()
  id: string;

  @Input()
  text: string;

  @Input()
  merchantId: string;

  @Input()
  selectedEntities: Map<string, ISelectInput>;

  @Input()
  searchedText: string;

  @Input()
  disabled: boolean;

  @Output()
  removed: EventEmitter<ISelectInput> = new EventEmitter();

  @Output()
  added: EventEmitter<ISelectInput> = new EventEmitter();

  onCheckboxChecked(selectedEntity: string): void {
    const entity: ISelectInput = {
      id: selectedEntity,
      text: this.text,
      merchantId: this.merchantId,
    };
    if (this.selectedEntities.has(selectedEntity)) {
      this.removed.emit(entity);
    } else {
      this.added.emit(entity);
    }
  }

  isEntitySelected(entity: string): boolean {
    return this.selectedEntities.has(entity);
  }
}
