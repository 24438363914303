import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthPageContentComponent } from './auth-page-content/auth-page-content.component';
import { UisAuthenticationErrorService } from './services/uis-authentication-error.service';

@NgModule({
  imports: [CommonModule],
  declarations: [AuthPageContentComponent],
  providers: [UisAuthenticationErrorService],
  exports: [AuthPageContentComponent],
})
export class UisAuthenticationLayoutModule {}
