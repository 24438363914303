import { Features } from 'environments/enums/features.enum';
// Features are temporally added to the default list until they appear in RMS database
export const DEFAULT_FEATURES = [
  Features.SilentRefreshToken,
  Features.SecureCardCapture,
  Features.Simulator,
  Features.LanguageSwitching,
  Features.AlwaysAutoCapture,
  Features.TransactionDescription,
  Features.OrganisationServices,
  Features.ChangePassword,
  Features.DisputeManagementReasonCodeManagement,
  Features.DemoAccount,
  Features.PreCheckThreeDSecureAuthorization,
  Features.NewLogo,
  Features.RecurringNonABSWeb,
  Features.WFInvoice,
  Features.WFMerchantBalance,
  Features.CheckoutThemes,
  Features.TransactionsReportTemplates,
  Features.UseUis,

  //PETRO FEATURES
  Features.TlogViewer,
  Features.Accounts,
  Features.Roles,
  Features.Users,
  Features.Hierarchy,
  Features.Sites,
  Features.SiteAssets,
  Features.BulkSites,
  Features.TlogViewer,
  Features.SummaryReport,
  Features.DcrReport,
  Features.PluReport,
  Features.FuelReport,
  Features.DepartmentReport,
  Features.SiteConfiguration,
  Features.MobilePayment,
  Features.ProgramHostConfiguration,
  Features.MobileSiteConfiguration,
  Features.ViperTable,
  Features.ViperSiteView,
  Features.Loyalty,
];
