<div class="accordion" [class.is-active]="isActive" data-e2e="accordion">
  <div
    #accordionHeader
    class="accordion-header"
    data-e2e="accordion-header"
    tabindex="0"
    (click)="toggleAccordion()"
    (keydown.enter)="toggleAccordion()"
  >
    <span class="is-size-5 has-text-weight-semibold" data-e2e="accordion-title">{{ title }}</span>
    <span class="icon is-size-5">
      <i class="fas fa-chevron-down"></i>
    </span>
  </div>

  <div class="accordion-body" data-e2e="accordion-body" tabindex="-1">
    <div class="accordion-content mb-3">
      <ng-content></ng-content>
    </div>
  </div>

  <hr class="dropdown-divider" />
</div>
