export function processQueryParams(query: Record<keyof any, any | any[]>): Record<string, string> {
  const notEmpty = (value: any): boolean => value || value === 0;
  const arrayAsString = (value: any[]): string => value.filter(notEmpty).join(',');

  return Object.fromEntries(
    Object.entries(query)
      .map(([key, value]) => [key, arrayAsString(Array.isArray(value) ? value : [value])])
      .filter(([, value]) => notEmpty(value)),
  );
}
