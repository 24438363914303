import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth-interceptor';
import { FirstResponseInterceptor } from './no-data/no-data.interceptor';
import { ReportsInterceptor } from './reports/reports-interceptor';
import { VhqMockInterceptorService } from './vhq-mock/vhq-mock-interceptor.service';
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: VhqMockInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: FirstResponseInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ReportsInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: Response302InterceptorService, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: UploadInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
];
