import environment from 'environments/environment';

const VHQ_CONSTANTS = {
  VHQ_ROUTE_PREFIX: 'devicemanagement',
  DEVICES: 'devices',
  VHQ_REPORTS: 'reports',
  VHQ_IMPORT_EXPORT: 'import-export',
  ONBOARDING: environment.API_ENDPOINT.ONBOARDING,
  LOGGING_SERVICE: `${environment.API_ENDPOINT.LOGGING_SERVICE}/latest`,
  ENTITY_SERVICE: {
    ROOT: environment.API_ENDPOINT.ENTITY_SERVICE,
    ORGANISATION: `${environment.API_ENDPOINT.ENTITY_SERVICE}/entities/`,
  },
  GET_USER_LOGS: 'Get User Logs',
  MESSAGE: 'Message',
  RIGHT: 'right',
  LEFT: 'left',
};

export { VHQ_CONSTANTS };
