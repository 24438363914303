<div class="buttons has-addons">
  <ng-container *ngFor="let entity of entities">
    <button
      class="button is-info is-selected"
      [id]="entity.id"
      (selected)="onEntityAdded($event)"
      title="{{ entity.text }}"
      type="button"
    >
      {{ entity.text }}
    </button>
  </ng-container>
</div>
