import { Injectable } from '@angular/core';
import { IUser } from '@portal/entity-services/interfaces/src';
import { RestrictPermissionService } from '@portal/shared/auth/authorization/src';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';

import { INavbarItem } from '@portal/shared/ui/layouts/src/lib/top-navbar/interfaces/navbar-item.interface';
import { Features } from 'environments/enums/features.enum';
import {
  getNavbarItemLink,
  getSubNavItems,
  NavbarItemLink,
} from '../../helpers/navigation-route-helpers';
import { AuditLogPermissions, AuditLogRouteNames } from '../../permissions/payments/audit-log';
import { InvoiceRouteNames, InvoiceRoutePermissions } from '../../permissions/payments/invoice';
import {
  MerchantBalancePermissions,
  MerchantBalanceRouteNames,
} from '../../permissions/payments/merchant-balance';
import { PayByLinkPermissions, PayByLinkRouteNames } from '../../permissions/payments/pay-by-link';
import {
  PaymentsDashboardRouteName,
  PaymentsDashboardRoutePermissions,
} from '../../permissions/payments/payments-dashboard';
import {
  ReportEnginePermissions,
  ReportEngineRouteNames,
} from '../../permissions/payments/report-engine';
import {
  SecureAuthenticationsPersmissions,
  SecureAuthenticationsRoute,
} from '../../permissions/payments/secure-authentications';
import {
  SettlementsRouteName,
  SettlementsRoutePermissions,
} from '../../permissions/payments/settlements';
import {
  TransactionsRouteName,
  TransactionsRoutePermissions,
} from '../../permissions/payments/transactions';
import {
  VirtualTerminalPermissions,
  VirtualTerminalRouteNames,
} from '../../permissions/payments/virtual-terminal';
import {
  WfInvoiceRouteNames,
  WfInvoiceRoutePermissions,
} from '../../permissions/payments/wf-invoice';
import {
  WfMerchantBalanceRouteNames,
  WfMerchantBalanceRoutePermissions,
} from '../../permissions/payments/wf-merchant-balance';
import {
  DisputeManagementRouteNames,
  DisputeManagementRoutePermissions,
} from '../../permissions/payments/dispute-management';
import {
  ReasonCodeManagementRouteNames,
  ReasonCodeManagementRoutePermissions,
} from '../../permissions/payments/reason-code-management';
import { ApplicationRoot } from '../../enums/application-root.enum';
import { PetroService } from '../petro/petro.service';
import { MenuRegion } from '../../../../../ui/layouts/src/lib/top-navbar/enum/menu-region.enum';

@Injectable({
  providedIn: 'root',
})
export class CommerceNavbarService {
  languageRoute: string;
  currentApplication: ApplicationRoot;

  constructor(
    private featureToggleService: FeatureToggle,
    private restrictPermissionService: RestrictPermissionService,
    private petroService: PetroService,
  ) {}

  getCommerceNavigationItems(params: {
    currentApplication: ApplicationRoot;
    languageRoute: string;
    user: IUser;
  }): INavbarItem | null {
    this.languageRoute = params.languageRoute;
    this.currentApplication = params.currentApplication;
    const reporting = this.getReportingNavItems();
    const paymentTools = this.getPaymentToolsNavItems();
    const paymentDerivations = this.getPaymentDerivationsNavItems();
    const petroReports = this.getPetroReportsNavbarItems();

    return getSubNavItems(
      {
        id: 'commerce',
        title: $localize`:Main menu navbar group name:Commerce`,
        attributes: {
          'data-e2e': 'title-reports',
          groupTitle: $localize`:Main menu navbar group name:Commerce`,
          description:
            this.currentApplication === ApplicationRoot.Petro
              ? `Manage business transactions seamlessly with a Commerce platform. Get detailed insights into payment reports and streamline financial processes. Keep track of Petro reports effortlessly and make informed decisions to drive business forward.`
              : $localize`:Main menu navbar group description:Optimize commerce operations with a comprehensive suite of payment tools, designed to streamline transactions and maximize revenue. Stay on top of financial performance with detailed reporting on sales and revenue, and never miss a payment with reliable payment deliverables.`,
        },
        region: MenuRegion.Commerce,
      },
      [reporting, paymentDerivations, paymentTools, petroReports],
    );
  }

  getPetroReportsNavbarItems(): INavbarItem | null {
    const petroReportsItems = {
      title: $localize`:Main menu navbar item name:Petro Reports`,
      attributes: {
        'data-e2e': 'title-petro-reports',
      },
      children: [],
    };

    if (this.petroService.showSiteAssetsMenu()) {
      petroReportsItems.children.push({
        title: $localize`:Main menu navbar item name:Site Assets`,
        attributes: {
          'data-e2e': 'link-site-assets',
        },
        ...this.getItemUrl('/reports/site-assets', [ApplicationRoot.Petro]),
      });
    }

    if (this.petroService.showTlogMenu()) {
      petroReportsItems.children.push({
        title: $localize`:Main menu navbar item name:Tlog Viewer`,
        attributes: {
          'data-e2e': 'link-tlog-viewer',
        },
        ...this.getItemUrl('/reports/tlog-viewer', [ApplicationRoot.Petro]),
      });
    }

    if (this.petroService.summaryReportMenu()) {
      petroReportsItems.children.push({
        title: $localize`:Main menu navbar item name:Summary Report`,
        attributes: {
          'data-e2e': 'link-summary-report',
        },
        ...this.getItemUrl('/reports/summary-report', [ApplicationRoot.Petro]),
      });
    }

    return petroReportsItems;
  }

  getReportingNavItems(): INavbarItem {
    const reporting = {
      title: $localize`:Main menu navbar item name:Reporting`,
      attributes: {
        'data-e2e': 'title-reporting',
      },
      children: [],
    };

    if (this.showPortalAnalytic) {
      reporting.children.push({
        title: $localize`:Main menu navbar item name:Payments Dashboard`,
        attributes: {
          'data-e2e': 'link-payments-dashboard',
        },
        ...this.getItemUrl('/dashboard', [ApplicationRoot.Payments]),
      });
    }

    if (this.showTransactions) {
      reporting.children.push({
        title: $localize`:Main menu navbar item name:Orders/Transactions`,
        attributes: {
          'data-e2e': 'link-transactions',
        },
        ...this.getItemUrl('/reports/transactions/view/orders', [ApplicationRoot.Payments]),
      });
    }

    if (this.showSecureAuthentications) {
      reporting.children.push({
        title: $localize`:Main menu navbar item name:3D Secure Authentications`,
        attributes: {
          'data-e2e': 'link-secure-authentications',
        },
        ...this.getItemUrl('/reports/secure-authentications', [ApplicationRoot.Payments]),
      });
    }

    if (this.showSettlements) {
      reporting.children.push({
        title: $localize`:Main menu navbar item name:Settlements`,
        attributes: {
          'data-e2e': 'link-settlements',
        },
        ...this.getItemUrl('/reports/settlements', [ApplicationRoot.Payments]),
      });
    }
    if (this.showReportScheduler) {
      reporting.children.push({
        title: $localize`:Main menu navbar item name:Report Scheduler`,
        attributes: {
          'data-e2e': 'link-report-scheduler',
        },
        ...this.getItemUrl('/reports/report-scheduler', [ApplicationRoot.Payments]),
      });
    }

    if (this.showGeneratedReports) {
      reporting.children.push({
        title: $localize`:Main menu navbar item name:Generated Reports`,
        attributes: {
          'data-e2e': 'link-generated-reports',
        },
        ...this.getItemUrl('/reports/generated-reports', [ApplicationRoot.Payments]),
      });
    }

    if (this.showAuditLog) {
      reporting.children.push({
        title: $localize`:Main menu navbar item name:Audit Log`,
        attributes: {
          'data-e2e': 'link-audit-log',
        },
        ...this.getItemUrl('/reports/audit-log', [ApplicationRoot.Payments]),
      });
    }
    return reporting;
  }

  getPaymentDerivationsNavItems(): INavbarItem {
    const paymentDerivations = {
      title: $localize`:Main menu navbar item name:Payment Derivations`,
      attributes: {
        'data-e2e': 'title-payment-derivatives',
      },
      children: [],
    };
    if (this.showDisputeManagement) {
      paymentDerivations.children.push({
        title: $localize`:Main menu navbar item name:Dispute Management`,
        attributes: {
          'data-e2e': 'title-dispute-management',
        },
        ...this.getItemUrl('/dispute-management/card-schemes', [ApplicationRoot.Payments]),
      });
    }
    if (this.showReasonCodeManagement) {
      paymentDerivations.children.push({
        title: $localize`:Main menu navbar item name:Reason Code Management`,
        attributes: {
          'data-e2e': 'title-reason-code-management',
        },
        ...this.getItemUrl('/dispute-management/reason-code-management', [
          ApplicationRoot.Payments,
        ]),
      });
    }
    if (this.showInvoice) {
      paymentDerivations.children.push({
        title: $localize`:Main menu navbar item name:Monthly Invoice Summary`,
        attributes: {
          'data-e2e': 'link-invoices',
        },
        ...this.getItemUrl('/reports/invoices', [ApplicationRoot.Payments]),
      });
    }

    if (this.showMerchantBalance) {
      paymentDerivations.children.push({
        title: $localize`:Main menu navbar item name:Payout`,
        attributes: {
          'data-e2e': 'link-merchant-payout-report',
        },
        ...this.getItemUrl('/reports/merchant-payouts', [ApplicationRoot.Payments]),
      });
    }

    if (this.showWFMerchantBalance) {
      paymentDerivations.children.push({
        title: $localize`:Main menu navbar item name:Payout (ACH)`,
        attributes: {
          'data-e2e': 'link-merchant-payout-ach-report',
        },
        ...this.getItemUrl('/reports/merchant-payouts-ach', [ApplicationRoot.Payments]),
      });
    }

    if (this.showWFInvoice) {
      paymentDerivations.children.push({
        title: $localize`:Main menu navbar item name:Monthly Statement`,
        attributes: {
          'data-e2e': 'link-monthly-statement-report',
        },
        ...this.getItemUrl('/reports/monthly-statement', [ApplicationRoot.Payments]),
      });
    }
    return paymentDerivations;
  }

  getPaymentToolsNavItems(): INavbarItem {
    const paymentTools = {
      title: $localize`:Main menu navbar item name:Payment Tools`,
      attributes: {
        'data-e2e': 'title-payment-tools',
      },
      children: [],
    };

    if (this.showVirtualTerminal) {
      paymentTools.children.push({
        title: $localize`:Main menu navbar item name:Virtual Terminal`,
        attributes: {
          'data-e2e': 'link-virtual-terminal',
        },
        region: MenuRegion.VirtualTerminal,
        ...this.getItemUrl('/payment-tools/virtual-terminal', [ApplicationRoot.Payments]),
      });
    }

    if (this.showPayByLink) {
      paymentTools.children.push({
        title: $localize`:Main menu navbar item name:Pay by Link`,
        attributes: {
          'data-e2e': 'link-pay-by-link',
        },
        region: MenuRegion.PayByLink,
        ...this.getItemUrl('/payment-tools/pay-by-link/list', [ApplicationRoot.Payments]),
      });
    }

    return paymentTools;
  }

  private getItemUrl(url: string, applications: ApplicationRoot[]): NavbarItemLink {
    return getNavbarItemLink(this.currentApplication, applications, this.languageRoute, url);
  }

  private get showPortalAnalytic(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.PortalAnalytic) &&
      this.restrictPermissionService.checkPermissions(
        PaymentsDashboardRoutePermissions[PaymentsDashboardRouteName.home],
        ['read'],
      )
    );
  }

  private get showTransactions(): boolean {
    return this.restrictPermissionService.checkPermissions(
      TransactionsRoutePermissions.routePermissions[TransactionsRouteName.RouteName.List],
      ['read'],
    );
  }

  private get showSecureAuthentications(): boolean {
    return this.restrictPermissionService.checkPermissions(
      SecureAuthenticationsPersmissions.routePermissions[SecureAuthenticationsRoute.RouteName.List],
      ['read'],
    );
  }

  private get showSettlements(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.Settlements) &&
      this.restrictPermissionService.checkPermissions(
        SettlementsRoutePermissions.routePermissions[SettlementsRouteName.RouteName.List],
        ['read'],
      )
    );
  }

  private get showGeneratedReports(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.ReportEngine) &&
      this.restrictPermissionService.checkPermissions(
        ReportEnginePermissions.routePermissions[
          ReportEngineRouteNames.RouteName.GeneratedReportsList
        ],
        ['read'],
      )
    );
  }

  private get showAuditLog(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.AuditLog) &&
      this.restrictPermissionService.checkPermissions(
        AuditLogPermissions.routePermissions[AuditLogRouteNames.RouteName.List],
        ['read'],
      )
    );
  }

  private get showReportScheduler(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.ReportScheduler) &&
      this.restrictPermissionService.checkPermissions(
        ReportEnginePermissions.routePermissions[
          ReportEngineRouteNames.RouteName.ReportSchedulerList
        ],
        ['read'],
      )
    );
  }

  private get showInvoice(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.Invoice) &&
      this.restrictPermissionService.checkPermissions(
        InvoiceRoutePermissions.routePermissions[InvoiceRouteNames.RouteName.List],
        ['read'],
      )
    );
  }

  private get showMerchantBalance(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.MerchantBalance) &&
      this.restrictPermissionService.checkPermissions(
        MerchantBalancePermissions.routePermissions[MerchantBalanceRouteNames.RouteName.List],
        ['read'],
      )
    );
  }

  private get showWFMerchantBalance(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.WFMerchantBalance) &&
      this.restrictPermissionService.checkPermissions(
        WfMerchantBalanceRoutePermissions.routePermissions[
          WfMerchantBalanceRouteNames.RouteName.List
        ],
        ['read'],
      )
    );
  }

  private get showWFInvoice(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.WFInvoice) &&
      this.restrictPermissionService.checkPermissions(
        WfInvoiceRoutePermissions.routePermissions[WfInvoiceRouteNames.RouteName.List],
        ['read'],
      )
    );
  }

  private get showVirtualTerminal(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.VirtualTerminal) &&
      this.restrictPermissionService.checkPermissions(
        VirtualTerminalPermissions.routePermissions[VirtualTerminalRouteNames.RouteName.List],
        ['read'],
      )
    );
  }

  private get showPayByLink(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.PayByLink) &&
      this.restrictPermissionService.checkPermissions(
        PayByLinkPermissions.routePermissions[PayByLinkRouteNames.RouteName.list],
        ['read'],
      )
    );
  }

  private get showDisputeManagement(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.DisputeManagementCardSchemes) &&
      this.restrictPermissionService.checkPermissions(
        DisputeManagementRoutePermissions.routePermissions[
          DisputeManagementRouteNames.RouteName.List
        ],
        ['read'],
      )
    );
  }

  private get showReasonCodeManagement(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.DisputeManagementReasonCodeManagement) &&
      this.restrictPermissionService.checkPermissions(
        ReasonCodeManagementRoutePermissions.routePermissions[
          ReasonCodeManagementRouteNames.RouteName.List
        ],
        ['read'],
      )
    );
  }
}
