import { Component, Input } from '@angular/core';

declare const $localize;

@Component({
  selector: 'portal-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() isLarge = false;
  @Input() isMedium = false;
  @Input() isSmall = false;
  @Input() text = $localize`Loading...`;
  @Input() showText = false;
  @Input() inlineText = false;
  @Input() isVertical = false;
  @Input() isHorizontal = false;

  constructor() {}

  get showSpan(): boolean {
    return (this.showText || this.inlineText) && !this.isHorizontal && !this.isVertical;
  }
  get showDiv(): boolean {
    return this.isHorizontal || this.isVertical;
  }

}
