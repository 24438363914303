import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AppStatus,
  DEV_APPS_API,
  FileType,
  FormDataCreator,
  IAppVersion,
  MimeTypes,
} from '@portal/marketplace/api-interfaces/src';
import { INewApp, INewVersion } from '@portal/marketplace/api-interfaces/src';
import { IFilterToApply } from '@portal/shared/ui/filter/src';
import { IPagination, IQueryParams } from '@portal/shared/ui/table';
import { UrlQueryParamsBuilderService } from '@portal/shared/url-query-params-builder/src/lib/url-query-params-builder.service';
import { VuiHttpService } from '@portal/shared/vui-http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DevAppCatalogDataService extends FormDataCreator {
  constructor(
    private vuiHttpService: VuiHttpService,
    private urlQueryParamsBuilderService: UrlQueryParamsBuilderService,
    private httpClient: HttpClient,
  ) {
    super();
  }

  loadApps(paginationConfig: IQueryParams, filters: IFilterToApply[]): Observable<any> {
    const url = DEV_APPS_API.GET_APPS();
    return this.vuiHttpService.get(url, {
      params: this.urlQueryParamsBuilderService.createHttpParams(
        {
          page: paginationConfig.start / paginationConfig.limit,
          size: paginationConfig.limit,
          ...(paginationConfig.sort
            ? {
                sort: `latestAppVersion.${paginationConfig.sort.key},${paginationConfig.sort.order}`,
              }
            : {
                sort: `latestAppVersion.appName,ASC`,
              }),
        },
        filters,
      ),
    });
  }
  loadApp(appId: string): Observable<any> {
    const url = DEV_APPS_API.GET_APP_VERSION(appId);
    return this.vuiHttpService.get(url);
  }

  registerApp(newApp: INewApp): Observable<any> {
    const url = DEV_APPS_API.REGISTER_APP();
    return this.vuiHttpService.post(url, { data: newApp });
  }

  getAppById(versionId: string): Observable<any> {
    const url = DEV_APPS_API.GET_APP_VERSION(versionId);
    return this.vuiHttpService.get(url);
  }

  submitApp(appId: string, appVersion: IAppVersion): Observable<any> {
    const url = DEV_APPS_API.GET_APP_VERSION(appId);
    return this.httpClient.put(url, { data: appVersion });
  }

  updateApp(appId: string, appVersion: IAppVersion): Observable<any> {
    const url = DEV_APPS_API.GET_APP_VERSION(appId);
    return this.vuiHttpService.patch(url, { data: appVersion });
  }

  getAppPackage(appId: string, type: FileType): Observable<any> {
    const url = DEV_APPS_API.APP_PACKAGE(appId, type);
    return this.vuiHttpService.get(url);
  }

  uploadAppPackage(appId: string, files: File[], type: FileType): Observable<any> {
    let formData: FormData;
    const url = DEV_APPS_API.APP_PACKAGE(appId, type);
    switch (type) {
      case FileType.APK:
        formData = this.createFormDataWithFiles(files, MimeTypes.APK);
        break;
      case FileType.QSA_REPORT:
        formData = this.createFormDataImageFile(files);
        break;
      case FileType.USER_GUIDE:
        formData = this.createFormDataImageFile(files);
        break;
      case FileType.SCREENSHOT:
        formData = this.createFormDataImageFile(files);
        break;
      case FileType.APP_ICON:
        formData = this.createFormDataImageFile(files);
        break;
    }
    return this.vuiHttpService.patch(url, formData);
  }

  deleteFile(appVersionId: string, fileType: string, fileNumber: number): Observable<any> {
    const url = DEV_APPS_API.DELETE_FILE(appVersionId, fileType, fileNumber);
    return this.vuiHttpService.delete(url, { responseValue: 'event' });
  }

  changeState(appVersionId: string, status: string, message?: string): Observable<any> {
    const url = DEV_APPS_API.CHANGE_STATUS(appVersionId, status);
    return message
      ? this.vuiHttpService.patch(url, {
          data: { message: message },
        })
      : this.vuiHttpService.patch(url, null);
  }

  addNewVersion(appId: string, newVersion: INewVersion): Observable<any> {
    const url = DEV_APPS_API.ADD_NEW_VERSION(appId);
    return this.vuiHttpService.post(url, { data: newVersion });
  }

  getVersionsByAppId(appId: string): Observable<any> {
    const url = DEV_APPS_API.GET_APP_VERSIONS_BY_APP_ID(appId);
    return this.vuiHttpService.get(url);
  }

  getPendingApps(): Observable<any> {
    const url = DEV_APPS_API.GET_ALL_APPS_VERSIONS();
    const params = new HttpParams({
      fromObject: {
        status: [
          AppStatus.REGISTERED,
          AppStatus.SUBMITTED,
          AppStatus.IN_REVIEW,
          AppStatus.REJECTED,
        ],
      },
    });
    return this.vuiHttpService.get(url, { params });
  }
}
