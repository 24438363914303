import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CurrencyService, ICurrency } from '@portal/shared/helpers/src';
import { ITooltipAttributes } from '@portal/shared/ui/tooltip/src';
import { ICurrencyMaskConfig } from '../../../modules/transactions/interfaces/currency-mask-config.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'portal-currency-amount',
  templateUrl: './currency-amount.component.html',
})
export class CurrencyAmountComponent implements OnChanges {
  @Input() amount: FormControl;
  @Input() currency: FormControl;
  @Input() canChangeCurrency = true;
  @Input() currencies: ICurrency[];
  @Input() invalid: boolean;
  @Input() id: string;
  @Input() isLightGrey: false;
  @Input() maxLength = 0;

  @Output() currencySelected: EventEmitter<string> = new EventEmitter();
  @Output() amountSelected: EventEmitter<number> = new EventEmitter();

  @Input() amountMask: ICurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    prefix: '',
    suffix: '',
    precision: this.getPrecision(),
    thousands: this.currencyService.thousands,
    decimal: this.currencyService.decimal,
  };

  private currencyChanges$ = new Subject();

  get currencyIsSelectable(): boolean {
    return this.currencies?.length > 1 && this.canChangeCurrency;
  }

  constructor(private currencyService: CurrencyService, private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currency) {
      this.updateAmountMask();
      this.currencyChanges$.next();
      this.currency?.valueChanges
        .pipe(untilDestroyed(this), takeUntil(this.currencyChanges$))
        .subscribe(() => {
          this.updateAmountMask();
        });
    }
  }

  setAutoFocusState(state: boolean): void {
    if (state) {
      this.elementRef?.nativeElement?.querySelector('.input').focus();
    }
  }

  onCurrencySelected(): void {
    this.updateAmountMask();
    this.currencyService.setLocalStorageDefaultCurrencyCode(this.currency.value);
    this.currencySelected.emit(this.currency.value);
  }
  onAmountSelected(): void {
    this.amountSelected.emit(this.amount.value);
  }

  onInputChange(event: Event): void {
    const inputValueWithCommas = (event.target as HTMLInputElement).value;
    const inputValue = parseFloat(inputValueWithCommas.replace(/,/g, ''));
    if (!isNaN(inputValue)) {
      this.amount.setValue(inputValue);
      this.amountSelected.emit(this.amount.value);
    }
  }
  getTooltipTextAttribute(): string {
    const tooltipAttribute: ITooltipAttributes = {
      text: this.currencies?.find((currency) => currency.code === this.currency.value)
        ?.formattedName,
      multiline: false,
      nowrap: true,
    };
    return JSON.stringify(tooltipAttribute);
  }

  private updateAmountMask(): void {
    const oldPrecision = this.amountMask.precision;
    const newPrecision = this.getPrecision();
    this.amountMask = {
      ...this.amountMask,
      precision: newPrecision,
    };
    if (newPrecision !== oldPrecision) {
      this.formatAmount();
    }
  }

  private getPrecision(): number {
    return this.currencyService.getCurrencyDigits(this.currency?.value);
  }

  private formatAmount(): void {
    const oldAmount = this.amount?.value;
    if (oldAmount) {
      const newAmount = this.currencyService.roundOff(oldAmount, this.currency?.value);
      if (oldAmount !== newAmount) {
        this.amount.setValue(newAmount);
      }
    }
  }
}
