export enum AppCategoriesFamilies {
  'Alternate payments' = 'ALTERNATE_PAYMENTS',
  'Advertising and media' = 'ADVERTISING_AND_MEDIA',
  'Charity' = 'CHARITY',
  'Customer engagement' = 'CUSTOMER_ENGAGEMENT',
  'Customer relationship management' = 'CUSTOMER_RELATIONSHIP_MANAGEMENT',
  'Employee management' = 'EMPLOYEE_MANAGEMENT',
  'Finance' = 'FINANCE',
  'Inventory management' = 'INVENTORY_MANAGEMENT',
  'Loyalty and promotion' = 'LOYALTY_AND_PROMOTION',
  'Order management' = 'ORDER_MANAGEMENT',
  'Reporting and analytics' = 'REPORTING_AND_ANALYTICS',
  'ALTERNATE_PAYMENTS' = 'Alternate payments',
  'ADVERTISING_AND_MEDIA' = 'Advertising and media',
  'CHARITY' = 'Charity',
  'CUSTOMER_ENGAGEMENT' = 'Customer engagement',
  'CUSTOMER_RELATIONSHIP_MANAGEMENT' = 'Customer relationship management',
  'EMPLOYEE_MANAGEMENT' = 'Employee management',
  'FINANCE' = 'Finance',
  'INVENTORY_MANAGEMENT' = 'Inventory management',
  'LOYALTY_AND_PROMOTION' = 'Loyalty and promotion',
  'ORDER_MANAGEMENT' = 'Order management',
  'REPORTING_AND_ANALYTICS' = 'Reporting and analytics',
}
