import environment from '@environments';
import { FiltersList, FilterTypes } from '@portal/shared/ui/filter';
import { ITableConfig } from '@portal/shared/ui/table';

export const APPLICATIONS_INSTALLATION_SUMMARY_TABLE_CONFIG = (role): ITableConfig => {
  //return GET_TABLE_CONFIG(role, TABLE_CONFIG);
  return TABLE_CONFIG;
};

const APPLICATIONS_INSTALLATION_SUMMARY_FILTER_CONFIG = (role): FiltersList[] => {
  //return GET_FILTER_CONFIG(role, FILTER_CONFIG);
  return FILTER_CONFIG;
};

export const TABLE_CONFIG = {
  columns: [
    { key: 'app_name', label: 'App Name', isSortable: true },
    { key: 'app_version', label: 'App Version', isSortable: true },
    { key: 'installations', label: 'Total number of installations', isSortable: true },
    {
      key: 'successful_installations',
      label: 'Total number of successful installations',
      isSortable: true,
    },
    {
      key: 'failed_installations',
      label: 'Total number of failed installations',
      isSortable: true,
    },
    {
      key: 'pending_installations',
      label: 'Total number of pending installations',
      isSortable: true,
    },
  ],
  rowIdKey: 'entityUid',
  rowLabelKey: 'name',
};

const FILTER_CONFIG: FiltersList[] = [
  {
    selector: 'app_name',
    title: 'Application Name',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'Application Name',
        id: 'app_name',
      },
    ],
  },
];

export const APPLICATIONS_INSTALLATION_SUMMARY_CONFIG = {
  report_id: environment.REPORTS['applications-installation-summary'],
  table: APPLICATIONS_INSTALLATION_SUMMARY_TABLE_CONFIG,
  filters: APPLICATIONS_INSTALLATION_SUMMARY_FILTER_CONFIG,
};
