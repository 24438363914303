<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="field columns">
    <div class="column is-half">
      <label class="field">
        <div class="label" i18n>Name</div>
        <div class="control">
          <input
            class="input"
            data-e2e="input-name"
            [formControl]="name"
            [ngClass]="{ 'is-danger': name.invalid && name.touched }"
            type="text"
          />
          <portal-form-error [formInput]="name" errorId="name"></portal-form-error>
        </div>
      </label>
    </div>
  </div>

  <div class="field columns">
    <div class="column is-half">
      <label class="field">
        <div class="label" i18n>Verifone Token format</div>
        <div class="control">
          <label class="radio" *ngFor="let format of tokenFormatList">
            <input
              type="radio"
              name="tokenFormat"
              data-e2e="radio-tokenFormat"
              [value]="format.id"
              [formControl]="tokenFormat"
            />
            <span>{{ format.text }}</span>
          </label>
          <portal-form-error [formInput]="tokenFormat" errorId="tokenFormat"></portal-form-error>
        </div>
      </label>
    </div>
  </div>

  <div class="field columns">
    <div class="column is-half">
      <label class="field">
        <div class="label" i18n>Verifone Token type</div>
        <div class="control">
          <label class="radio" *ngFor="let type of tokenTypeList">
            <input
              type="radio"
              name="tokenType"
              data-e2e="radio-tokenType"
              [value]="type.id"
              [formControl]="tokenType"
            />
            <span>{{ type.text }}</span>
          </label>
          <portal-form-error [formInput]="tokenType" errorId="tokenType"></portal-form-error>
        </div>
      </label>
    </div>
  </div>

  <h5
    class="button is-text has-text-primary additional-settings-button has-text-black-bis pl-0"
    [ngClass]="{ 'is-active': isAdditionalSettingsOpen }"
    data-e2e="additional-settings-button"
    (click)="toggleAdditionalSettings()"
  >
    <span class="icon m-0">
      <i class="fas fa-lg fa-angle-down" aria-hidden="true"></i>
    </span>
    <span i18n>Additional Settings</span>
  </h5>

  <ng-container *ngIf="isAdditionalSettingsOpen">
    <div class="field columns">
      <div class="column is-half">
        <label class="field">
          <div class="label">
            <span i18n>Token length</span>
            <span class="has-text-grey has-text-weight-normal" i18n> (Optional)</span>
          </div>

          <div class="control">
            <input
              class="input"
              [formControl]="tokenLength"
              [ngClass]="{ 'is-danger': tokenLength.invalid && tokenLength.touched }"
              data-e2e="tokenLength"
              placeholder="16"
              type="text"
            />
            <portal-form-error [formInput]="tokenLength" errorId="tokenLength"></portal-form-error>
          </div>
          <p class="help" i18n>The token length can be a number between 16 and 255</p>
        </label>
      </div>
    </div>

    <div class="field columns">
      <div class="column is-half">
        <label class="field">
          <div class="label">
            <span i18n>Card prefix length</span>
            <span class="has-text-grey has-text-weight-normal" i18n> (Optional)</span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="prefixLengthFromCard"
              [ngClass]="{
                'is-danger': prefixLengthFromCard.invalid && prefixLengthFromCard.touched
              }"
              data-e2e="prefixLength"
              type="text"
            />
            <portal-form-error
              [formInput]="prefixLengthFromCard"
              errorId="prefixLengthFromCard"
            ></portal-form-error>
          </div>
          <p class="help" i18n>
            Determines how many digits from the original PAN are included as a prefix for tokens
            under this scope.
          </p>
        </label>
      </div>
    </div>

    <div class="field columns">
      <div class="column is-half">
        <label class="field">
          <div class="label">
            <span i18n>Card suffix length</span>
            <span class="has-text-grey has-text-weight-normal" i18n> (Optional)</span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="suffixLengthFromCard"
              [ngClass]="{
                'is-danger': suffixLengthFromCard.invalid && suffixLengthFromCard.touched
              }"
              data-e2e="suffixLength"
              type="text"
            />
            <portal-form-error
              [formInput]="suffixLengthFromCard"
              errorId="suffixLengthFromCard"
            ></portal-form-error>
          </div>
          <p class="help" i18n>
            Determines how many digits from the original PAN are included as a suffix for tokens
            under this scope.
          </p>
        </label>
      </div>
    </div>

    <div class="field columns">
      <div class="column is-half">
        <label class="field">
          <div class="label">
            <span i18n>Fixed prefix</span>
            <span class="has-text-grey has-text-weight-normal" i18n> (Optional)</span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="fixedPrefixStr"
              [ngClass]="{ 'is-danger': fixedPrefixStr.invalid && fixedPrefixStr.touched }"
              data-e2e="fixedPrefix"
              type="text"
            />
            <portal-form-error
              [formInput]="fixedPrefixStr"
              errorId="fixedPrefixStr"
            ></portal-form-error>
          </div>
          <p class="help" i18n>
            The provided value is left padded with the tokens under this scope. The maximum length
            should not exceed 6 characters.
          </p>
        </label>
      </div>
    </div>

    <div class="field columns">
      <div class="column is-half">
        <label class="field">
          <div class="label">
            <span i18n>Fixed suffix</span>
            <span class="has-text-grey has-text-weight-normal" i18n> (Optional)</span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="fixedSuffixStr"
              [ngClass]="{ 'is-danger': fixedSuffixStr.invalid && fixedSuffixStr.touched }"
              data-e2e="fixedSuffix"
              type="text"
            />
            <portal-form-error
              [formInput]="fixedSuffixStr"
              errorId="fixedSuffixStr"
            ></portal-form-error>
          </div>
          <p class="help" i18n>
            The provided value is right padded with the tokens under this scope. The maximum length
            should not exceed 4 characters.
          </p>
        </label>
      </div>
    </div>

    <div class="field columns">
      <div class="column is-half">
        <label class="field">
          <div class="label">
            <span i18n>Token expiration time</span>
            <span class="has-text-grey has-text-weight-normal" i18n> (Optional)</span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="defaultTokenExpirationInDays"
              [ngClass]="{
                'is-danger':
                  defaultTokenExpirationInDays.invalid && defaultTokenExpirationInDays.touched
              }"
              data-e2e="tokenExpiration"
              type="text"
            />
            <portal-form-error
              [formInput]="defaultTokenExpirationInDays"
              errorId="defaultTokenExpirationInDays"
            ></portal-form-error>
          </div>
          <p class="help" i18n>
            The default number of days before a token expires under this scope.
          </p>
        </label>
      </div>
    </div>

    <div class="field columns" *ngIf="isCardSchemeTokenAvailable">
      <div class="column is-half">
        <label class="field">
          <div class="label" i18n>Card Scheme Token Availability</div>
          <div class="control">
            <label class="checkbox" data-e2e="checkbox-label-cardSchemeTokenAvailability">
              <input
                type="checkbox"
                data-e2e="checkbox-cardSchemeTokenAvailability"
                [formControl]="cardSchemeTokenAvailability"
              />
              <span class="content" i18n>Generate network token & PAR with the Verifone token</span>
            </label>
            <portal-form-error
              [formInput]="cardSchemeTokenAvailability"
              errorId="cardSchemeTokenAvailability"
            ></portal-form-error>
          </div>
        </label>
      </div>
    </div>

    <div class="field columns" *ngIf="isCardSchemeTokenAvailable && isTokenRequestorIdType">
      <div class="column is-half">
        <label class="field">
          <div class="label" i18n>Token Requestor ID type</div>
          <div class="control">
            <label class="radio" *ngFor="let tokenRequestorIdType of tokenRequestorIdTypeList">
              <input
                type="radio"
                name="schemeTokenRequestorTypeControl"
                data-e2e="radio-schemeTokenRequestorTypeControl"
                [value]="tokenRequestorIdType.id"
                [formControl]="schemeTokenRequestorTypeControl"
              />
              <span>{{ tokenRequestorIdType.text }}</span>
            </label>
            <p class="help" i18n>
              Network token/PAR will be provided only if the Token Requestor ID is provided by the
              merchant
            </p>
            <portal-form-error
              [formInput]="schemeTokenRequestorTypeControl"
              errorId="schemeTokenRequestorTypeControl"
            ></portal-form-error>
          </div>
        </label>
      </div>
    </div>

    <ng-container
      *ngIf="isMerchantRequestorId && isTokenRequestorIdType && isCardSchemeTokenAvailable"
    >
      <div class="field columns">
        <div class="column is-half">
          <label class="field">
            <div class="label" i18n>Merchant Requestor ID VISA</div>
            <div class="control">
              <input
                class="input"
                data-e2e="input-merchantRequestorIdVisa"
                [formControl]="merchantRequestorIdVisa"
                [ngClass]="{
                  'is-danger': merchantRequestorIdVisa.invalid && merchantRequestorIdVisa.touched
                }"
                type="text"
              />
              <portal-form-error
                [formInput]="merchantRequestorIdVisa"
                errorId="merchantRequestorIdVisa"
              ></portal-form-error>
            </div>
            <p class="help" i18n>11 numeric characters</p>
          </label>
        </div>
      </div>

      <div class="field columns">
        <div class="column is-half">
          <label class="field">
            <div class="label" i18n>Merchant Requestor ID MASTERCARD</div>
            <div class="control">
              <input
                class="input"
                data-e2e="input-merchantRequestorIdMastercard"
                [formControl]="merchantRequestorIdMastercard"
                [ngClass]="{
                  'is-danger':
                    merchantRequestorIdMastercard.invalid && merchantRequestorIdMastercard.touched
                }"
                type="text"
              />
              <portal-form-error
                [formInput]="merchantRequestorIdMastercard"
                errorId="merchantRequestorIdMastercard"
              ></portal-form-error>
            </div>
            <p class="help" i18n>11 numeric characters</p>
          </label>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="field is-grouped">
    <div class="control">
      <button
        type="submit"
        class="button is-primary"
        data-e2e="save-token-button"
        [disabled]="!hasFormChanged"
        i18n
        title="Save token scope"
        i18n-title
      >
        Save token scope
      </button>
    </div>
    <div class="control">
      <button
        type="button"
        class="button"
        data-e2e="cancel-button"
        (click)="onCancel()"
        i18n
        title="Cancel"
        i18n-title
      >
        Cancel
      </button>
    </div>
  </div>
</form>
