<swiper [config]="config" class="image-swiper" *ngIf="!isPublic && images?.length > 0">
  <!-- Add Arrows -->

  <div class="buttons is-pulled-right">
    <button class="button button-prev" data-e2e="swiper-button-prev" title="prev">
      <i class="fas fa-chevron-left"></i>
    </button>
    <button class="button button-next" data-e2e="swiper-button-next" title="next">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>

  <div class="swiper-wrapper">
    <div *ngFor="let img of images" class="swiper-slide is-flex" data-e2e="app-screenshot">
      <ng-container *ngIf="isDeleteAbility">
        <div
          class="delete"
          *restrictPermission="['delete-screenshot']"
          (click)="deleteImage(img)"
        ></div>
      </ng-container>
      <img *ngIf="img" [src]="img?.publicUrl | secureLoad | async" alt="screenshot" />
    </div>
  </div>
  <!-- <div class="swiper-button-next" data-e2e="swiper-button-next"></div>
  <div class="swiper-button-prev" data-e2e="swiper-button-prev"></div> -->
</swiper>
<swiper [config]="config" class="image-swiper" *ngIf="isPublic && images?.length > 0">
  <!-- Add Arrows -->

  <div class="buttons is-pulled-right">
    <button class="button button-prev" data-e2e="swiper-button-prev" title="prev">
      <i class="fas fa-chevron-left"></i>
    </button>
    <button class="button button-next" data-e2e="swiper-button-next" title="next">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>

  <div class="swiper-wrapper">
    <div *ngFor="let img of images" class="swiper-slide is-flex" data-e2e="app-screenshot">
      <ng-container *ngIf="isDeleteAbility">
        <div
          class="delete"
          *restrictPermission="['delete-screenshot']"
          (click)="deleteImage(img)"
        ></div>
      </ng-container>
      <img [src]="img.publicUrl | secureLoad | async" alt="screenshot" />
    </div>
  </div>
  <!-- <div class="swiper-button-next" data-e2e="swiper-button-next"></div>
  <div class="swiper-button-prev" data-e2e="swiper-button-prev"></div> -->
</swiper>
<!-- Add Pagination -->
<div class="pagination-wrapper">
  <div class="swiper-pagination" data-e2e="swiper-pagination"></div>
</div>
