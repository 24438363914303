import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import isEmpty from 'lodash-es/isEmpty';

import { NotifierService } from '@portal/shared/ui/notifier';
import { AuthorizationService } from './authorization.service';
import { SelfCheckService } from './self-check.service';
import { RestrictPermissionService } from './services/restrict-permission.service';

declare const $localize;

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(
    private authorizationService: AuthorizationService,
    private selfCheckService: SelfCheckService,
    private router: Router,
    private notifierService: NotifierService,
    private restrictPermissionService: RestrictPermissionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.router.navigated = false;

    // Always allow user to view only 'My profile' page
    if (this.selfCheckService.isMyProfilePage(route)) {
      return true;
    }

    // this will be passed from the route config
    // on the roles property
    const actionName = this.selfCheckService.isSelfPage(route) ? 'self-read' : 'read';
    const permissions = this.restrictPermissionService.getPermissionsForRoute(route);
    const expectedRoles = permissions[actionName] || [];
    const rolesFromToken: string[] = this.authorizationService.getRoles() || [];

    if (!this.restrictPermissionService.checkPermissions(permissions, [actionName])) {
      if (isEmpty(expectedRoles) && isEmpty(rolesFromToken)) {
        this.notifierService.error($localize`The url that you are trying to access doesn't exist!`);
      }
      return false;
    }
    return true;
  }

  /**
   * Role guard based on actions (To Capture, Refund, see detail, etc..)
   * This usually happens if the action itself is on the same route as the list page itself
   * @param route Current route object
   * @param action Action to execute, such as read, delete, update, capture, refund, etc..
   *
   * @returns true or false if the user can access the child page / action
   */
  canAccess(route: ActivatedRouteSnapshot, action: string): boolean {
    // this will be passed from the route config
    // on the roles property
    const permissions = this.restrictPermissionService.getPermissionsForRoute(route);

    return this.restrictPermissionService.checkPermissions(permissions, [action]);
  }
}
