import environment from '@environments';
import { FiltersList, FilterTypes } from '@portal/shared/ui/filter';
import { ITableConfig } from '@portal/shared/ui/table';
import { GET_FILTER_CONFIG, GET_TABLE_CONFIG } from './shared-configs';

export const MERCHANT_STATUS_TABLE_CONFIG = (role): ITableConfig => {
  return GET_TABLE_CONFIG(role, TABLE_CONFIG);
};

export const MERCHANT_STATUS_FILTER_CONFIG = (role): FiltersList[] => {
  return GET_FILTER_CONFIG(role, FILTER_CONFIG);
};

const TABLE_CONFIG = {
  columns: [{ key: 'merchant_status', label: 'Status', isSortable: true }],
  rowIdKey: 'entityUid',
  rowLabelKey: 'name',
};

const FILTER_CONFIG: FiltersList[] = [
  {
    selector: 'merchant_status',
    title: 'Merchant Status',
    type: FilterTypes.Single,
    data: [
      {
        text: 'ACTIVE',
        id: 'ACTIVE',
      },
      {
        text: 'INACTIVE',
        id: 'INACTIVE',
      },
    ],
  },
];

export const MERCHANT_STATUS_CONFIG = {
  report_id: environment.REPORTS['merchant-status'],
  table: MERCHANT_STATUS_TABLE_CONFIG,
  filters: MERCHANT_STATUS_FILTER_CONFIG,
};
