import { Injectable } from '@angular/core';
import { BusinessIdentifiersType } from '@portal/entity-services/interfaces/src';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class BusinessIdentifiersTypes extends SelectInputsList {
  keyValue = {
    [BusinessIdentifiersType.Abn]: 'ABN',
    [BusinessIdentifiersType.Ein]: 'EIN',
    [BusinessIdentifiersType.Kennitala]: 'Kennitala',
    [BusinessIdentifiersType.Siret]: 'SIRET',
    [BusinessIdentifiersType.chamberCommerceNumber]: $localize`Chamber of Commerce number`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  InvalidBusinessIdentifierList: ISelectInput[] = this.getSelectInputs({
    [BusinessIdentifiersType.VhqCustomerId]: 'VHQ Customer ID',
    [BusinessIdentifiersType.VhqCustomerUniqueId]: 'VHQ Customer UID',
  });
}
