import { Pipe, PipeTransform } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers';

@Pipe({
  name: 'listValue',
})
export class ListValuePipe implements PipeTransform {
  transform(key: string, list: SelectInputsList, defaultValue = `[${key}]`): any {
    return key && list ? list.getValue(key, defaultValue) : '';
  }
}
