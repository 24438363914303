<section class="block mp-block">
  <div class="block mp-block">
    <div class="container">
      <portal-breadcrumbs>{{ app?.appName }}</portal-breadcrumbs>
      <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap">
        <div class="is-flex is-align-items-center is-flex-wrap-wrap">
          <h1 class="title mr-2 mb-2 p-0" data-e2e="app-page-app-name-main">
            {{ app?.appName }}
          </h1>
          <ng-content select=".versions-dropdown"></ng-content>
        </div>
        <div class="is-flex">
          <ng-content select=".action-buttons"></ng-content>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="tile app-details box">
  <div class="tile narrow is-parent pr-5 pt-5 pb-5">
    <ng-content select=".icon-upload"></ng-content>
    <div *restrictPermission="['read-only-app-icon']" class="is-flex">
      <marketplace-app-logo-template
        *ngIf="!isPublic"
        [app]="app"
        [showInstallsIndicator]="showInstallsIndicator"
        [showUpdatesIndicator]="showUpdatesIndicator"
      >
      </marketplace-app-logo-template>
    </div>
    <marketplace-app-logo-public-template *ngIf="isPublic" [app]="app">
    </marketplace-app-logo-public-template>
  </div>
  <div class="tile is-vertical is-parent is-paddingless">
    <div class="tile is-child tables">
      <dl class="definition-list is-multiline">
        <div class="definition">
          <div class="term">
            <strong>Supported terminals:</strong>
          </div>
          <div class="description">
            <span *ngFor="let tag of app?.modelFamilies" class="tag has-border-grey-light" data-e2e="modelFamilyTag">{{
              tag.displayName
            }}</span>
          </div>
        </div>
        <div class="box__separator"></div>
        <div class="definition">
          <div class="term">
            <strong>Categories:</strong>
          </div>
          <div class="description">
            <span *ngFor="let tag of app?.categories" class="tag has-border-grey-light" data-e2e="categoryTag">{{
              tag.displayName
            }}</span>
          </div>
        </div>
        <div class="box__separator"></div>
        <div class="definition">
          <div class="term"><strong>Price:</strong></div>
          <div class="description">
            <marketplace-app-price
              [price]="app?.price ? app?.price : app?.minimumPrice"
              [paymentModel]="app?.paymentModel"
              [isRegular]="true"
            ></marketplace-app-price>
          </div>
        </div>
        <div class="box__separator"></div>
        <div class="definition">
          <div class="term">
            <strong>Short description:</strong>
          </div>
          <div class="description">
            {{ app?.shortDescription }}
          </div>
        </div>
      </dl>
    </div>
  </div>
</div>
<div class="margin-bottom-5">
  <ng-content select=".general-edit"></ng-content>
</div>
<div class="box app-details-container">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-full">
        <marketplace-status-indicator [status]="app?.status" *restrictPermission="['app-status']">
        </marketplace-status-indicator>
        <marketplace-status-indicator
          [status]="getStatus(app)"
          *restrictPermission="['app-vault-status']"
        >
        </marketplace-status-indicator>
      </div>
      <div class="column is-flex">
        <p data-e2e="app-version">Version: {{ app?.version }}</p>
        <marketplace-app-icon
          *ngIf="app?.isFeatured"
          icon="fa-crown"
          title="Featured app"
        ></marketplace-app-icon>
        <marketplace-app-icon
          *ngIf="app?.isPilot"
          icon="fa-flask"
          title="Pilot app"
        ></marketplace-app-icon>
        <marketplace-app-icon
          *ngIf="app?.isPublic"
          icon="fa-user-friends"
          title="Public app"
        ></marketplace-app-icon>
        <marketplace-app-icon
          *ngIf="showDefaultAppIcon"
          icon="has-text-warning has-new-store-icon"
          title="Default app"
        ></marketplace-app-icon>
      </div>
    </div>
    <div class="columns is-paddingless is-gapless" data-e2e="app-page-details">
      <div
        class="column is-ellipsis"
        data-e2e="app-page-app-guide"
        *restrictPermission="['user-guide-download-link']"
      >
        <dl>
          <dt>
            <strong>
              <i class="fas fa-certificate"></i>
              User guide download link 
            </strong>
            <ng-content select=".guide-report-file"></ng-content>
          </dt>
          <dd data-e2e="app-page-app-guide">
            <div *ngFor="let file of app?.userGuides">
              <a
                (click)="downloadFile(file.publicUrl, file.fileName)"
                target="_blank"
                rel="noopener noreferrer"
                >{{ file.fileName }}</a
              >
            </div>
            <div *ngIf="!(app?.userGuides?.length)">
              N/A
            </div>
          </dd>
        </dl>
      </div>
      <ng-container *ngIf="isPublic!==true">
        <div class="column" data-e2e="app-page-app-apk" *restrictPermission="['apk-file-size-link']">
          <div class="tile">
            <div class="tile">
              <dl class="apk-size-container">
                <dt>
                  <strong> <i class="fas fa-robot"></i> APK file size</strong>
                </dt>
                <dd data-e2e="app-page-app-filesize" class="apk-size" *ngIf="app?.apks">
                  {{ (app?.apks)[0]?.fileSize | readableFileSize }}
                </dd>
              </dl>
            </div>
            <div class="tile">
              <ng-content select=".app-apk-file"></ng-content>
            </div>
          </div>
        </div>
        <div class="column" *restrictPermission="['apk-download-link']">
          <dl>
            <dt>
              <strong> APK Download Link </strong>
            </dt>
            <dd>
              <div [attr.data-e2e]="getE2e(file)" *ngFor="let file of app?.apks">
                <a (click)="downloadFile(file.publicUrl, file.fileName)">{{ file.fileName }}</a>
              </div>
            </dd>
          </dl>

          <ng-content select=".app-apk-file"></ng-content>
        </div>
        
        <div class="column is-ellipsis" *restrictPermission="['qsa-report-download-link']">
          <dl>
            <dt>
              <strong> QSA Report Download Link </strong>
            </dt>
            <dd>
              <div data-e2e="qsa-file-download" *ngFor="let file of app?.qsaReports">
                <a (click)="downloadFile(file.publicUrl, file.fileName)">{{ file.fileName }}</a>
              </div>
            </dd>
          </dl>

          <ng-content select=".app-qsa-file"></ng-content>
        </div>
      </ng-container>
    </div>
    <div class="columns">
      <div class="column is-4 has-margin-bottom" data-e2e="app-page-app-screenshots">
        <h2 class="title one-row">
          Screenshots
        </h2>
      </div>
    </div>

    <section class="columns is-full" data-e2e="app-page-screenshots">
      <div class="column is-three-quarters">
        <marketplace-app-screen-carousel
          [isPublic]="isPublic"
          [isDeleteAbility]="app?.status === appStatus.REGISTERED"
          [images]="app?.screenshots"
          (deleteImageEvt)="deleteImage($event)"
        ></marketplace-app-screen-carousel>
      </div>
      <div class="column">
        <ng-content select=".screenshots-upload"></ng-content>
      </div>
    </section>
    <section class="columns">
      <div
        class="column is-one-third"
        data-e2e="release-notes-title"
        *ngIf="!isFirstVersion() || hasReleaseNotes()"
      >
        <h2 class="title one-row">
          What's new
          <ng-content select=".release-notes-edit"></ng-content>
        </h2>
        <div
          *ngIf="!isFirstVersion() || hasReleaseNotes()"
          class="content"
          data-e2e="release-notes"
        >
          <p class="is-formatted">{{ app?.releaseNotes }}</p>
        </div>
      </div>
      <div class="column is-two-thirds" data-e2e="app-page-description-title">
        <h2 class="title one-row">
          Description
          <ng-content select=".description-edit"></ng-content>
        </h2>

        <p
          class="is-formatted"
          data-e2e="app-page-description"
          *ngIf="app?.description?.length > 0"
        >
          {{ app?.description }}
        </p>
      </div>
    </section>
    <section class="columns" *restrictPermission="['testing-instructions']">
      <div class="column is-two-thirds" data-e2e="app-page-testing-title">
        <h2 class="title one-row">Testing Instructions</h2>
        <p class="is-formatted" data-e2e="app-page-testing">
          {{ app?.testingInstructions }}
        </p>
      </div>
    </section>
    <section class="columns is-multiline padding-bottom">
      <div class="column is-half" data-e2e="app-page-app-support">
        <h1 class="title">
          <i class="fas fa-life-ring has-text-grey"></i>
          App support
          <ng-content select=".app-support-edit"></ng-content>
        </h1>

        <dl class="columns is-multiline is-gapless" *ngIf="app?.appSupportEmail">
          <dt class="column is-half">
            <strong> Support email </strong>
          </dt>
          <dd class="column is-half is-ellipsis" data-e2e="app-page-app-appsupportemail">
            <a href="mailto:{{ app?.appSupportEmail }}">{{ app?.appSupportEmail }}</a>
          </dd>
        </dl>

        <dl class="columns is-multiline is-gapless" *ngIf="app?.appSupportWebsite">
          <dt class="column is-half">
            <strong> Website </strong>
          </dt>
          <dd class="column is-half is-ellipsis" data-e2e="app-page-app-appsupportwebsite">
            <a href="{{ app?.appSupportWebsite }}" target="_blank">{{ app?.appSupportWebsite }}</a>
          </dd>
        </dl>

        <dl class="columns is-multiline is-gapless" *ngIf="app?.appSupportContactNumber">
          <dt class="column is-half">
            <strong> Contact number </strong>
          </dt>
          <dd class="column is-half is-ellipsis" data-e2e="app-page-app-appsupportcontactnumber">
            {{ app?.appSupportContactNumber }}
          </dd>
        </dl>

        <dl class="columns is-multiline is-gapless" *ngIf="app?.privacyPolicy">
          <dt class="column is-half">
            <strong> Privacy policy </strong>
          </dt>
          <dd class="column is-half is-ellipsis" data-e2e="app-page-app-privacypolicy">
            <a href="{{ app?.privacyPolicy }}" target="_blank" rel="noopener noreferrer">
              {{ app?.privacyPolicy }}</a
            >
          </dd>
        </dl>

        <dl class="columns is-multiline is-gapless" *ngIf="app?.privacyPolicy">
          <dt class="column is-half">
            <strong> Terms of use </strong>
          </dt>
          <dd class="column is-half is-ellipsis" data-e2e="app-page-app-apptermofuse">
            <a href="{{ app?.appTermOfUse }}" target="_blank"> {{ app?.appTermOfUse }}</a>
          </dd>
        </dl>
      </div>

      <div class="column is-half" data-e2e="app-page-developer">
        <h1 class="title">
          <i class="fas fa-building has-text-grey"></i>
          Developer organization
          <ng-content select=".developer-edit"></ng-content>
        </h1>
        <dl class="columns is-multiline is-gapless">
          <dt class="column is-half">
            <strong> Name </strong>
          </dt>
          <dd class="column is-half is-ellipsis" data-e2e="app-page-dev-org-name">
            {{ app?.app?.developerOrg?.name }}
          </dd>
        </dl>
        <dl class="columns is-multiline is-gapless">
          <dt class="column is-half">
            <strong> Email </strong>
          </dt>
          <dd class="column is-half is-ellipsis" data-e2e="app-page-dev-org-email">
            <a href="mailto:{{ app?.app?.developerOrg?.email }}">{{
              app?.app?.developerOrg?.email
            }}</a>
          </dd>
        </dl>
        <dl class="columns is-multiline is-gapless">
          <dt class="column is-half">
            <strong> Website </strong>
          </dt>
          <dd class="column is-half is-ellipsis" data-e2e="app-page-dev-org-website">
            <a href="{{ app?.app?.developerOrg?.website }}" target="_blank">{{
              app?.app?.developerOrg?.website
            }}</a>
          </dd>
        </dl>
      </div>
    </section>
  </div>
  <hr />

  <ng-content select="[content]"></ng-content>

  <!-- DEPRECATED! Please use `content` as a section for any content. You can even put multiple elemetns, not only one -->
  <!-- <div content> Content One </div> -->
  <!-- <div content> Content Two </div> -->
  <div class="columns is-multiline">
    <section class="column is-full">
      <ng-content select=".devices"></ng-content>
    </section>
    <section class="column is-full">
      <ng-content select=".bundles-includes-app"></ng-content>
    </section>
    <section class="column is-full">
      <ng-content select=".reports"></ng-content>
    </section>
    <section class="column is-full">
      <ng-content select=".unpublish"></ng-content>
    </section>
  </div>
</div>
