import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ITreeOptions, TreeComponent } from '@circlon/angular-tree-component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';

import { IFilterToApply } from '../search/interfaces/filter-to-apply.interface';
import { ISelectInput } from '../box/interfaces/select-input.interface';
import { TreeFilterLazyLoad } from './tree-filter.interface';

@Component({
  selector: 'portal-tree-filter',
  templateUrl: './tree-filter.component.html',
  styleUrls: ['./tree-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeFilterComponent implements OnInit {
  @Input() selector: string;
  @ViewChild('tree') tree: TreeComponent;

  @Input() templates: {
    treeNodeTemplate?: TemplateRef<any>;
  };
  @Input() title: string;
  @Input() placeholder: string;
  @Input() options: ITreeOptions = {};
  @Input() service$: (search?: string) => Observable<any>;

  @Input() entityRemoved$ = new Subject<ISelectInput>();
  @Output() filterApplied = new EventEmitter();
  @Output() filterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();

  isOpen = false;
  nodes$ = new BehaviorSubject(null);

  lazyloadid = TreeFilterLazyLoad.id;
  isLoading$ = new BehaviorSubject(false);

  private search$ = new BehaviorSubject(null);
  private localPreSelectedEntities: Map<string, ISelectInput> = new Map();

  constructor() {
    this.search$.pipe(debounceTime(500)).subscribe((value) => {
      if (this.isOpen) {
        this.load(value);
      }
    });
  }

  ngOnInit(): void {
    this.entityRemoved$.subscribe((entityToRemove) => {
      this.tree.treeModel.selectedLeafNodeIds[entityToRemove.id] = false;
    });
  }

  toggleFilter(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen && this.nodes$.getValue() == null) {
      this.load();
    }
  }

  closeFilter(): void {
    this.isOpen = false;
  }

  onSearch(search: string): void {
    if (search === '') {
      search = null;
    }

    if (this.search$.getValue() !== search) {
      this.search$.next(search);
    }
  }

  onFilterApplied(): void {
    this.updateEntities();

    const keys: string[] = Array.from(this.localPreSelectedEntities.keys());
    const value = Array.from(this.localPreSelectedEntities.values());

    if (keys.length) {
      this.filterUpdated.emit({
        operator: 'eq',
        value: value,
        argument: keys.join(','),
        selector: this.selector,
        key: keys.map((key) => `${this.selector}-${key}`).join(','),
      });
    } else {
      this.filterUpdated.emit({
        key: this.selector,
        selector: this.selector,
        toRemove: true,
      });
    }

    this.filterApplied.emit();
    this.toggleFilter();
  }

  onUpdate(): void {
    // DO NOT REMOVE
  }

  private updateEntities(): void {
    this.localPreSelectedEntities.clear();
    const treeState = this.tree?.treeModel?.getState();
    if (treeState) {
      Object.keys(treeState.selectedLeafNodeIds).forEach((id) => {
        if (treeState.selectedLeafNodeIds[id]) {
          this.localPreSelectedEntities.set(id, {
            id,
          });
        }
      });
    }
  }

  private load(search?: string): void {
    this.isLoading$.next(true);
    this.service$(search)
      ?.pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe((nodes) => {
        this.nodes$.next(nodes);
      });
  }
}
