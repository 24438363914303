import { vfappmarket } from '../services.url';

export const DEVICE_GROUPS_API = {
  GET_DEVICE_GROUPS_FOR_MERCHANT: function (merchantEntityUid: string): string {
    return `${vfappmarket}/v1/deviceGroups/merchants/${merchantEntityUid}`;
  },
  CREATE_DEVICE_GROUP_MERCHANT: function (merchantEntityUid: string): string {
    return `${vfappmarket}/v1/deviceGroups/merchants/${merchantEntityUid}`;
  },
  DELETE_GROUP: function (org: string, groupId: string): string {
    return `${vfappmarket}/v1/deviceGroups/merchants/${org}/deviceGroup/${groupId}`;
  },
  UPDATE_DEVICE_GROUP_MERCHANT: function (
    merchantEntityUid: string,
    deviceGroupId: string,
  ): string {
    return `${vfappmarket}/v1/deviceGroups/merchants/${merchantEntityUid}/deviceGroup/${deviceGroupId}`;
  },
  ASSIGN_DEVICE_TO_GROUP: {
    EVALUATE: function (merchantId: string, deviceGroupId: string): string {
      return `${vfappmarket}/v1/deviceGroups/merchants/${merchantId}/deviceGroup/${deviceGroupId}/devices/assign/evaluate`;
    },
    ASSIGN: function (merchantId: string, deviceGroupId: string): string {
      return `${vfappmarket}/v1/deviceGroups/merchants/${merchantId}/deviceGroup/${deviceGroupId}/devices/assign`;
    },
  },
};
