import { Inject, Injectable, LOCALE_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Application } from '@portal/shared/auth/authorization/src/lib/enums/application.enum';
import { LANGUAGES } from '../constants/lang.constants';
import { Direction } from '../enums/direction.enum';
import { Locale } from '../enums/locale.enum';
import { ILanguage } from '../interfaces/language';
import {
  APPS_DEFAULT_LANGUAGE,
  APPS_SUPPORTED_LANGUAGES,
} from 'configs/localization/supported-languages';
import { SUPPORTED_LANGUAGES } from '../constants/supported-languages';
import { EnvironmentVariableService } from '@portal/shared/helpers/src/lib/environment-variables/environment-variables';
import { DEFAULT_LANGUAGE } from '../constants/default-language';

@Injectable({
  providedIn: 'root',
})
export class LanguageHandle {
  private renderer: Renderer2;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(SUPPORTED_LANGUAGES) private supportedLanguages: Locale[],
    @Inject(DEFAULT_LANGUAGE) private defaultLanguage: Locale,
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setLanguage(language: Locale): void {
    const currentLang = this.getCurrentObjectLanguage(this.getDocumentLang()).shortname; // short name fr for FR-fr
    let targetLang = language || this.storageLocale || currentLang; // shortname as well

    if (currentLang !== targetLang && !this.isKnownLocale(targetLang as Locale)) {
      targetLang =
        this.getSupportedFallbackLocale(this.supportedLanguages, targetLang) || targetLang;
    }

    if (this.mustUpdateLocation(currentLang, targetLang)) {
      this.updateLocation(currentLang, targetLang);
    } else {
      this.updateDocumentAttributes();
    }
  }

  getBuildLanguage(): string {
    const currentLocale = this.locale;
    const objectLang = this.getCurrentObjectLanguage(currentLocale);
    return objectLang.shortname;
  }

  getCurrentObjectLanguage(locale: string): ILanguage {
    const findLanguage = (shortname: string): ILanguage => {
      const shortnameLowerCase = shortname?.toLowerCase();
      return LANGUAGES.find((l) => l.shortname.toLowerCase() === shortnameLowerCase);
    };
    return findLanguage(locale) || findLanguage(this.defaultLanguage);
  }

  get storageLocale(): Locale {
    const locale = window.localStorage.getItem('locale') as Locale;
    return this.isKnownLocale(locale) ? locale : ('' as Locale);
  }

  getSupportedFallbackLocale(supportedLanguages: Locale[], targetLocale: string): Locale | '' {
    if (!supportedLanguages || !targetLocale) return '';
    return (
      supportedLanguages.find(
        (supportedLocale) => supportedLocale.split('-')[0] === targetLocale.split('-')[0],
      ) || ''
    );
  }

  getSupportedLanguagePath(userLanguage: string, app: Application): string {
    const supportedLanguages = APPS_SUPPORTED_LANGUAGES[app];
    const language = !supportedLanguages?.includes(userLanguage)
      ? this.getSupportedFallbackLocale(supportedLanguages, userLanguage)
      : userLanguage;
    const defaultLanguage = APPS_DEFAULT_LANGUAGE[app];
    return language === defaultLanguage ? '' : language.toLowerCase();
  }

  resolveAlias(locale: string): string {
    if (locale === Locale.EnUs) return Locale.En;
    return locale;
  }

  private isKnownLocale(locale: Locale): boolean {
    return this.supportedLanguages.includes(locale);
  }

  private mustUpdateLocation(currentLang: string, targetLang: string): boolean {
    return (
      targetLang !== currentLang &&
      this.isKnownLocale(targetLang as Locale) &&
      !EnvironmentVariableService.isEnvironmentLocal()
    );
  }

  private updateLocation(currentLang: string, targetLang: string): void {
    const hasLangPrefix = (lang): boolean => lang !== this.defaultLanguage;
    const appPath = hasLangPrefix(currentLang) ? '..' : '';
    const langPrefix = hasLangPrefix(targetLang) ? '/' + targetLang.toLowerCase() : '';
    // TODO: This is temporary fix for redirection in case of different language codes. Should be removed when all apps have same language and same language codes
    let relativePath: string | string[] = this.location.path(true);
    relativePath = relativePath.split('/');
    const newLocation = this.location.prepareExternalUrl(
      `${appPath}${langPrefix}${relativePath.join('/')}`,
    );
    window.location.replace(newLocation);
  }

  private updateDocumentAttributes(): void {
    const appLocale = this.getBuildLanguage();
    const objectLang = this.getCurrentObjectLanguage(appLocale);
    const htmlElement = this.document.querySelector('html');
    // angular doesn't set the "dir" attribute properly at compile time for some reason
    // see https://github.com/angular/angular-cli/issues/16047
    this.renderer.setAttribute(htmlElement, 'dir', objectLang.direction);
    this.renderer.setAttribute(htmlElement, 'lang', objectLang.shortname);

    if (objectLang.direction === Direction.RTL) {
      // flip ui-design-system icons for rtl direction
      htmlElement.classList.add('fa-dir-flip');
    }
  }

  private getDocumentLang(): string {
    return this.document.documentElement.lang;
  }
}
