import { Injectable } from '@angular/core';
import { ReceiptSalesChannel } from '../../enums/receipt-sales-channel.enum';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class ReceiptSalesChannels extends SelectInputsList {
  keyValue = {
    [ReceiptSalesChannel.Ecommerce]: $localize`E-commerce`,
    [ReceiptSalesChannel.Mail]: $localize`Mail`,
    [ReceiptSalesChannel.Moto]: $localize`Moto`,
    [ReceiptSalesChannel.NonMoto]: $localize`Non-Moto`,
    [ReceiptSalesChannel.Other]: $localize`Other`,
    [ReceiptSalesChannel.Phone]: $localize`Phone`,
    [ReceiptSalesChannel.Pos]: $localize`POS`,
    [ReceiptSalesChannel.Recurring]: $localize`Recurring`,
    [ReceiptSalesChannel.Stored]: $localize`Stored`,
    [ReceiptSalesChannel.Virtual]: $localize`Virtual`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
