import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OperationError } from '../error/operation-error';
import environment from '@environments';

@Injectable()
export class OperationErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const inTime = Date.now();
    if (request.url.includes(environment.API_ENDPOINT.ERROR_LOGGING_SERVICE)) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError(error => {
        const outTime = Date.now();
        if (error instanceof HttpErrorResponse) {
          const operationError = new OperationError({
            ...error,
            inTime,
            outTime,
            method: request.method,
          });
          return throwError(operationError);
        }
      }),
    );
  }
}
