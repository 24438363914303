import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers';
import { ISelectInput } from '@portal/shared/ui/filter';
import { sortBy } from 'lodash';
import { ReceiptProvider } from '../enums/receipt-provider.enum';

declare const $localize;

@Injectable({
  providedIn: 'root',
})
export class ReceiptProviderTypes extends SelectInputsList {
  keyValue = {
    [ReceiptProvider.Invoice4U]: $localize`Invoice4U`,
    [ReceiptProvider.Verifone]: $localize`Verifone`,
    [ReceiptProvider.None]: $localize`None`,
  };
  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);
}
