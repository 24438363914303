import { Injectable } from '@angular/core';
import { ES_CONSTANTS, ITokenScope } from '@portal/entity-services/interfaces';
import { VuiHttpService } from '@portal/shared/vui-http';
import { differenceBy, unionBy } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenScopeService {
  private _tokenScopes: ITokenScope[] = [];
  private _tokenScopes$: BehaviorSubject<ITokenScope[]> = new BehaviorSubject(this._tokenScopes);
  private _linkedTokenScopes: ITokenScope[] = [];
  private _linkedTokenScopes$: BehaviorSubject<ITokenScope[]> = new BehaviorSubject(
    this._linkedTokenScopes,
  );
  private _filteredTokenScopes$: Observable<ITokenScope[]> = this._tokenScopes$.pipe(
    map((tokenScopes) => differenceBy(tokenScopes, this._linkedTokenScopes, 'tokenScopeUid')),
  );

  constructor(private vuiHttpService: VuiHttpService) {}

  get tokenScopes$(): Observable<ITokenScope[]> {
    return this._tokenScopes$.asObservable();
  }

  get filteredTokenScopes$(): Observable<ITokenScope[]> {
    return this._filteredTokenScopes$;
  }

  get linkedTokenScopes$(): Observable<ITokenScope[]> {
    return this._linkedTokenScopes$.asObservable();
  }

  setTokenScopes(tokenScopes: ITokenScope[]): void {
    this._tokenScopes = tokenScopes;
    this._tokenScopes$.next(this._tokenScopes);
  }

  setLinkedTokenScopes(linkedTokenScopes: ITokenScope[]): void {
    this._linkedTokenScopes = linkedTokenScopes;
    this._linkedTokenScopes$.next(this._linkedTokenScopes);
  }

  getAll(merchantCompanyEntityUid: string): Observable<ITokenScope[]> {
    return this.vuiHttpService
      .get<ITokenScope[]>(
        `${ES_CONSTANTS.ENTITY_SERVICE.ORGANISATION}${merchantCompanyEntityUid}/tokenScopes`,
      )
      .pipe(
        tap((tokenScopes: ITokenScope[]) => {
          this.setTokenScopes(tokenScopes);
        }),
      );
  }

  getAllLinked(entityUid: string): Observable<ITokenScope[]> {
    return this.vuiHttpService
      .get<ITokenScope[]>(
        `${ES_CONSTANTS.ENTITY_SERVICE.ORGANISATION}${entityUid}/allowedTokenScopes`,
      )
      .pipe(
        tap((tokenScopes: ITokenScope[]) => {
          this.setLinkedTokenScopes(tokenScopes);
        }),
      );
  }

  addTokenScope(
    merchantCompanyEntityUid: string,
    tokenScope: ITokenScope,
  ): Observable<ITokenScope> {
    return this.vuiHttpService
      .post<ITokenScope>(
        `${ES_CONSTANTS.ENTITY_SERVICE.ORGANISATION}${merchantCompanyEntityUid}/tokenScopes`,
        tokenScope,
      )
      .pipe(
        tap((addedTokenScope: ITokenScope) => {
          this.setTokenScopes([...this._tokenScopes, addedTokenScope]);
        }),
      );
  }

  updateTokenScope(newTokenScope: ITokenScope): Observable<ITokenScope> {
    return this.vuiHttpService
      .put<ITokenScope>(
        `${ES_CONSTANTS.ENTITY_SERVICE.ROOT}/tokenScopes/${newTokenScope.tokenScopeUid}`,
        newTokenScope,
      )
      .pipe(
        tap((updatedTokenScope: ITokenScope) => {
          const newTokenScopes = this._tokenScopes.map((tokenScope) =>
            tokenScope.tokenScopeUid === newTokenScope.tokenScopeUid
              ? updatedTokenScope
              : tokenScope,
          );
          this.setTokenScopes(newTokenScopes);
        }),
      );
  }

  deleteTokenScope(tokenScopeUid: string): Observable<ITokenScope> {
    return this.vuiHttpService
      .delete<ITokenScope>(`${ES_CONSTANTS.ENTITY_SERVICE.ROOT}/tokenScopes/${tokenScopeUid}`)
      .pipe(
        tap(() => {
          this.setTokenScopes(
            this._tokenScopes.filter((tokenScope) => tokenScope.tokenScopeUid !== tokenScopeUid),
          );
        }),
      );
  }

  unlinkTokenScope(
    entityUid: string,
    linkedTokenScopeUid: ITokenScope['tokenScopeUid'],
  ): Observable<any> {
    return this.vuiHttpService
      .delete<any>(
        `${ES_CONSTANTS.ENTITY_SERVICE.ORGANISATION}${entityUid}/allowedTokenScopes/${linkedTokenScopeUid}`,
      )
      .pipe(
        tap(() => {
          this.setLinkedTokenScopes(
            this._linkedTokenScopes.filter(
              (linked) => linked.tokenScopeUid !== linkedTokenScopeUid,
            ),
          );
          this.setTokenScopes(this._tokenScopes$.getValue());
        }),
      );
  }

  linkTokenScopes(entityUid: string, tokenScopes: ITokenScope[]): Observable<any> {
    return this.vuiHttpService
      .post<any>(`${ES_CONSTANTS.ENTITY_SERVICE.ORGANISATION}${entityUid}/allowedTokenScopes`, {
        tokenScopeUids: tokenScopes.map((v) => v.tokenScopeUid),
      })
      .pipe(
        tap(() => {
          this.setLinkedTokenScopes(unionBy(this._linkedTokenScopes, tokenScopes, 'tokenScopeUid'));
          this.setTokenScopes(this._tokenScopes$.getValue());
        }),
      );
  }

  clearAll(): void {
    this.setTokenScopes([]);
    this.setLinkedTokenScopes([]);
  }
}
