import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'portal-date-range-filter',
  templateUrl: './date-range-filter.component.html',
})
export class DateRangeFilterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
