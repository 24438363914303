import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseModalComponent } from './base-modal.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  imports: [CommonModule, A11yModule],
  declarations: [BaseModalComponent],
  exports: [BaseModalComponent],
})
export class BaseModalModule {}
