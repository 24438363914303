import { Injectable } from '@angular/core';
import { ISearchCriteria } from '@portal/shared/ui/filter/src';
import { JoinCriteriaOperator } from '../enums/join-criteria-operator.enum';

@Injectable({
  providedIn: 'root',
})
export class SearchCriteriaService {
  /**
   * @desc removes "(" and ")" from the beginning and the end of string
   * and returns array of entity ids
   * @param ids
   */
  static removeParenthesesFromIds(ids: string): string[] {
    return ids?.replace('(', '').replace(')', '').split(',');
  }

  joinCriterias(
    criterias: ISearchCriteria[],
    operator: JoinCriteriaOperator = JoinCriteriaOperator.OR,
  ): ISearchCriteria {
    const queryString = criterias
      .map((criteria) => this.criteriaToQueryString(criteria))
      .join(operator);
    return {
      argument: `(${queryString})`,
    };
  }

  joinArguments(args: string[], separator = ','): string {
    return `(${args.join(separator)})`;
  }

  criteriaToQueryString(criteria: ISearchCriteria): string {
    return `${criteria.selector}${criteria.operator}${criteria.argument}`;
  }
}
