import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { vfappmarket, FileUploadAcceptType } from '@portal/marketplace/api-interfaces';
import { NotifierService } from '@portal/shared/ui/notifier';

@Component({
  selector: 'marketplace-file-upload-form-control',
  templateUrl: './file-upload-form-control.component.html',
  styleUrls: ['./file-upload-form-control.component.scss'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadFormControlComponent),
      multi: true,
    },
  ],
})
export class FileUploadFormControlComponent implements AfterViewInit, OnInit, ControlValueAccessor {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('previewContainer') previewContainer: ElementRef;

  @Input() multiple: Boolean = false;
  @Input() aspect: string; // example '4/3' | '1/1' | '16/9'
  @Input() supportedFileTypes: FileUploadAcceptType[] = [];
  @Input() image: any = null;
  @Input() supportedFilesText: string;
  @Input() accept: string;
  @Input() sizeMaxLimit: number; // size in kBytes
  @Input() set existingFileName(value: string) {
    this.eFileName = value;
    if (value && this.files.length > 0) {
      this.files = [];
    }
  }
  get existingFileName(): string {
    return this.eFileName;
  }
  // @Input() sizeMinLimit: number; // size in kbytes
  // @Input() dimensionMaxLimit: string; example '512x515'
  // @Input() dimensionMinLimit: string; example '512x515'
  @Output() filesChanged = new EventEmitter<File[]>();

  uploadImage = './assets/images/file-upload/upload.svg';
  files: File[] = [];
  previewUrl: any = null;
  hasPreviewUrl = false;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  disabled = false;

  private eFileName;

  constructor(private notifierService: NotifierService) {}

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList): void {
    const file = event && event.item(0);
    this.testFiles([file]);
    this.fileProgress([file]);
    this.onTouched();
    this.existingFileName = null;

    this.files = [file];
  }

  ngOnInit(): void {
    if (this.image !== undefined && !this.multiple) {
      this.previewUrl = this.image && this.image[0] === '/' ? vfappmarket + this.image : this.image;
    }
  }
  get selectedFiles(): string[] {
    return this.files.map((file) => file.name);
  }
  fileIsValid(file: File): boolean {
    let valid = true;

    if (valid && !this.fileTypeIsValid(file.name)) {
      this.notifierService.error('Invalid file type');
      valid = false;
    }
    if (valid && !this.fileSizeIsValid(file.size)) {
      this.notifierService.error('Invalid file size');
      valid = false;
    }

    return valid;
  }

  fileChanged(): void {}
  fileProgress(files: File[]): void {}

  fileTypeIsValid(name: string): boolean {
    const type = name.substring(name.lastIndexOf('.')).toLowerCase();
    const isValid =
      !this.supportedFileTypes.length ||
      this.supportedFileTypes.indexOf(<FileUploadAcceptType>type) > -1;
    return isValid;
  }

  fileSizeIsValid(size: number): boolean {
    let isValid = true;
    if (this.sizeMaxLimit) {
      // size comes in Bytes but we expect in kBytes
      isValid = Math.ceil(size / 1024) <= this.sizeMaxLimit;
    }
    return isValid;
  }

  preview(file: File): void {
    if (this.multiple) return;

    const mimeType = file.type;
    if (mimeType.match(/image\/*/) === null) {
      return;
    }
    this.hasPreviewUrl = true;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  ngAfterViewInit(): void {}

  writeValue(): void {}

  onTouched(): void {}

  registerOnChange(fn: any): void {
    this.fileProgress = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  resizeImage(file: File): void {
    const mimeType = file.type;
    if (mimeType.match(/image\/*/) === null) {
      return;
    }

    this.hasPreviewUrl = true;

    const tempImage = new Image();
    const reader = new FileReader();

    tempImage.addEventListener('load', () => {
      const a = tempImage.width / tempImage.height;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = Number(this.aspect.split('/')[0]) * 100 * 2;
      canvas.height = Number(this.aspect.split('/')[1]) * 100 * 2;
      const nh = canvas.width / a;
      const dx = 0;
      const dy = canvas.height / 2 - nh / 2;

      ctx.drawImage(tempImage, 0, 0, tempImage.width, tempImage.height, dx, dy, canvas.width, nh);

      this.previewUrl = canvas.toDataURL(mimeType, 1);

      canvas.toBlob(
        (blob: Blob) => {
          const f = new File([blob], file.name, { type: mimeType });
          this.filesChanged.emit([f]);
        },
        'image/png',
        1,
      );
    });

    reader.addEventListener(
      'load',
      () => {
        tempImage.src = reader.result as string;
      },
      false,
    );

    reader.readAsDataURL(file);
  }

  browse(): void {
    this.fileInput.nativeElement.click();
  }

  removeFile(index?: number): void {
    if (index !== undefined) this.files.splice(index, 1);
    else this.files = [];
    this.previewUrl = null;
    this.filesChanged.emit(this.files);
  }

  fileDropped(files): void {
    this.testFiles(files);
    this.onTouched();
    this.fileProgress(files);
    this.existingFileName = null;
    this.files = files;
  }
  testFiles(files): void {
    if (this.aspect && this.files[0]) {
      this.resizeImage(this.files[0]);
    } else if (this.files[0]) {
      this.preview(this.files[0]);
      //this.filesChanged.emit(files);
    }

    this.fileInput.nativeElement.value = null; // allow to reselect same file
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
