import { Component } from '@angular/core';
import { ProcessorTypes } from '@portal/entity-services/payment-contracts/src/lib/components/form/processor-type.list';
import { cloneDeep, forEach } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { ISelectInput } from '../box/interfaces/select-input.interface';
import { FILTER_CONTENT_ITEM } from '../content/token';
import { FilterBase } from '../filter-base';
import { SearchOperator } from '../search/enums/search-operator.enum';
import { SearchSelector } from '../search/enums/search-selector.enum';

declare const $localize;

@Component({
  selector: 'portal-shared-service-provider',
  templateUrl: './service-provider.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: ServiceProviderComponent,
    },
  ],
})
export class ServiceProviderComponent extends FilterBase {
  processorTypesList: ISelectInput[];
  refreshRadioList$ = new Subject();
  serviceProviderText = $localize`Service provider`;
  constructor(processorTypes: ProcessorTypes) {
    super(SearchSelector.ServiceProvider.toString(), processorTypes.list);
    this.processorTypesList = processorTypes.list;
  }

  getEntityById(id: string): ISelectInput {
    return this.processorTypesList.find((element) => element.id === id);
  }

  updateSelectedEntities(): void {
    this.showSelectedEntities = cloneDeep(this.localPreSelectedEntities);
    const entitiesToFetch: string[] = [];
    Array.from(this.showSelectedEntities.values()).forEach((val: ISelectInput) => {
      if (!val.text) {
        entitiesToFetch.push(val.id);
      }
    });

    if (entitiesToFetch.length) {
      forkJoin(
        entitiesToFetch.map((id: string) => {
          return this.getEntityById(id);
        }),
      ).subscribe((providers: ISelectInput[]) => {
        forEach(providers, (provider: ISelectInput) => {
          const input = this.showSelectedEntities.get(provider.id);
          input.text = provider.text;
          this.localPreSelectedEntities.set(provider.id, {
            id: provider.id,
            text: provider.text,
          });
        });
        this.updateText();
      });
    }
  }

  onEntityAdded(selectedEntity: ISelectInput): void {
    // single select - we add just one filter each time
    this.localPreSelectedEntities.clear();
    this.localPreSelectedEntities.set(selectedEntity.id, selectedEntity);
  }

  onFilterApplied(): void {
    this.updateEntities();
    const values: string[] = this.getValues();
    if (values.length) {
      this.filterApplied.emit({
        key: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.localPreSelectedEntities.values()),
        argument: `(${Array.from(values).join(',')})`,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        value: Array.from(this.localPreSelectedEntities.values()),
      });
    } else {
      this.filterApplied.emit({
        key: this.searchSelector,
        toRemove: true,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        toRemove: true,
      });
    }

    this.updateText();
    this.itemToggled.emit();
  }

  removeAllEntities(): void {
    this.localPreSelectedEntities.clear();
    this.updateSelectedEntities();
    this.updateText();
    this.refreshRadioList$.next();
  }

  updateText(): void {
    const values: string[] = this.getValues();
    const text = $localize`Service provider`;
    if (values.length) {
      this.serviceProviderText = `${text} (+${values.length})`;
    } else {
      this.serviceProviderText = text;
    }
  }
}
