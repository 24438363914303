import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonGroupBaseFilterDirective } from './button-group-base-filter.directive';
import { ButtonGroupMultiComponent } from './button-group-multi/button-group-multi.component';
import { ButtonGroupSingleComponent } from './button-group-single/button-group-single.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ButtonGroupBaseFilterDirective,
    ButtonGroupMultiComponent,
    ButtonGroupSingleComponent,
  ],
  exports: [ButtonGroupMultiComponent, ButtonGroupSingleComponent],
})
export class ButtonGroupFilterModule {}
