// @ts-ignore
import * as UDSColors from 'assets/uds/colors/colors.json';

/***
 * This is local variables
 * which we use to style
 * between component and
 * generated variables
 *
 * don't use comments into variables,
 * and carefully check all new added
 * variables to keep the exisiting approach
 * because any comment and missed
 * quote, bracket and symbol
 * could couse an error during bypass
 */

const colors = UDSColors;

export const WHITE_LABELING_VARS = {
  'primary-color': 'var(--brand-text)',
  'primary-hover-focus-color': 'var(--brand-focus-text)',

  'primary-button-background-color': 'var(--brand-background)',
  'primary-button-danger-background-color': colors['$vf-danger-100'],
  'primary-button-danger-color': colors['$vf-danger-100'],
  'primary-button-danger-text-color': colors['$vf-white-100'],
  'primary-button-text-color': 'var(--brand-text)',

  'primary-button-hover-border-color': 'var(--brand-hover-border)',

  'primary-button-hover-focus-background-color': 'var(--brand-focus-background)',
  'primary-button-hover-focus-text-color': 'var(--brand-hover-text)',

  'primary-button-focus-border-color': 'var(--brand-focus-border)',
  'primary-button-focus-box-shadow-color': 'var(--brand-focus-box-shadow)',

  'primary-button-chip-active-background-color': 'var(--brand-outlined-active-background)',
  'primary-button-active-background-color': 'var(--brand-active-background)',
  'primary-button-active-text-color': 'var(--brand-active-text)',
  'primary-button-active-border-color': 'var(--brand-active-border)',

  'primary-button-outlined-background-color': colors['$vf-white-100'],
  'primary-button-outlined-text-color': 'var(--brand-outlined-text)',
  'primary-button-outlined-border-color': 'var(--brand-outlined-border)',

  'primary-button-outlined-hover-focus-text-color': 'var(--brand-outlined-hover-text)',
  'primary-button-outlined-hover-focus-border-color': 'var(--brand-outlined-focus-border)',

  'primary-button-outlined-active-background-color': 'var(--brand-outlined-active-background)',
  'primary-button-outlined-active-text-color': 'var(--brand-outlined-active-text)',
  'primary-button-outlined-active-border-color': 'var(--brand-outlined-active-border)',

  'navbar-background-color': 'var(--navbar-background)',
  'navbar-box-shadow-color': 'var(--navbar-box-shadow)',
  'navbar-burger-color': 'var(--navbar-burger)',

  'navbar-item-color': 'var(--navbar-item)',
  'navbar-item-hover-background-color': 'var(--navbar-item-hover-background)',
  'navbar-item-hover-color': 'var(--navbar-item-hover)',

  'navbar-item-link-color': 'var(--navbar-item-link)',
  'navbar-item-link-focus-hover-active-background-color':
    'var(--navbar-item-link-focus-hover-active-background)',
  'navbar-item-link-focus-hover-active-color': 'var(--navbar-item-link-focus-hover-active)',
  'navbar-item-link-border-color': 'var(--navbar-item-link-border)',
  'navbar-item-link-circle-background-color': 'var(--navbar-item-link-circle-background)',

  'navbar-dropdown-background-color': 'var(--navbar-dropdown-background)',
  'navbar-dropdown-item-focus-hover-color': 'var(--navbar-dropdown-item-hover)',
  'navbar-dropdown-item-focus-hover-background-color':
    'var(--navbar-dropdown-item-hover-background)',
  'navbar-dropdown-item-active-color': 'var(--navbar-dropdown-item-active)',
  'navbar-dropdown-item-active-background-color': 'var(--navbar-dropdown-item-active-background)',

  'dropdown-content-border-color': colors['$vf-black-25'],
  'dropdown-content-background-color': colors['$vf-white-100'],
  'dropdown-content-box-shadow-color': 'var(--brand-box-shadow)',
  'dropdown-item-color': colors['$vf-black-100'],
  'dropdown-item-hover-background-color': 'var(--brand-hover-light-background)',
  'dropdown-item-active-color': colors['$vf-white-100'],
  'dropdown-item-active-background-color': 'var(--brand-background)',
  'dropdown-item-filter-focus-background-color': 'var(--brand-hover-light-background)',

  'control-color': 'var(--brand-text)',
  'control-active-focus-border-color': 'var(--brand-border)',
  'control-active-focus-box-shadow-color': 'var(--brand-focus-box-shadow)',

  'link-color': 'var(--brand-outlined-text)',

  'step-color': 'var(--brand-text)',
  'step-background-color': 'var(--brand-background)',
  'step-default-background-color': colors['$vf-black-25'],

  'ng-select-background-color': 'var(--brand-hover-light-background)',
  'ng-select-tag-border-color': 'var(--brand-outlined-hover-border)',

  'ng-datepicker-custom-day-background-color': 'var(--brand-background)',
  'ng-datepicker-range-background-color': 'var(--brand-hover-light-background)',
  'ng-datepicker-weekday-color': 'var(--brand-text)',
  'ng-datepicker-weekday-disabled-color': 'var(--brand-text-disabled)',
  'ng-datepicker-weekdays-arrows': 'var(--brand-outlined-text)',

  'tab-color': 'var(--brand-outlined-text)',
  'tab-border-color': 'var(--brand-border)',

  'table-link-color': 'var(--brand-text)',
  'table-background-color': 'var(--brand-hover-light-background)',

  'tree-wrapper-hover-background-color': 'var(--brand-outlined-focus-background)',
  'tree-wrapper-active-background-color': 'var(--brand-hover-light-background)',

  'home-card-kpi-border-color': colors['$vf-black-50'],
};
