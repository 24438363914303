import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FILTER_CONTENT_ITEM, ISelectInput, SearchSelector } from '@portal/shared/ui/filter';
import { FilterStaticBase } from '@portal/shared/ui/filter/src/lib/components/static-base';
import { TransactionFilterList } from '../transaction-filters.list';
import { Channels } from './channel.list';
import { FiltersService } from '@portal/shared/ui/filter/src/lib/services/filters.service';
import { TransactionFilter } from '../../../enums/transaction-filter.enum';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'portal-filter-channel',
  templateUrl: './channel.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: FilterChannelComponent,
    },
  ],
})
export class FilterChannelComponent extends FilterStaticBase {
  @Input() isMainDropdownFilter: boolean;
  @Input() showFilterSubMenu: boolean;
  @Output()
  channelTextName: EventEmitter<string> = new EventEmitter();
  channelText = this.transactionfilters.list.find(
    (filter) => filter.id === TransactionFilter.Channel,
  ).text;
  constructor(
    private channels: Channels,
    private transactionfilters: TransactionFilterList,
    private filtersService: FiltersService,
  ) {
    super(SearchSelector.Channel, channels.list);
    this.filtersService.actionRemoveFilter$.subscribe((entityToRemove: ISelectInput) => {
      if (SearchSelector.Channel === entityToRemove.key) {
        const selectedEntity = this.localPreSelectedEntities.get(entityToRemove.id);
        if (selectedEntity?.id) {
          this.onEntityRemoved(entityToRemove);
          this.onFilterApplied();
          this.itemToggled.emit();
        }
      }
    });

    this.subMenuComponentSelected$.subscribe((submenu) => {
      if (SearchSelector.Channel.includes(submenu)) {
        this.synchronizeLocalPreSelectedEntities();
      }
      this.updateEntities();
    });

    this.updateEntities$.subscribe(() => {
      if (this.isMainDropdownFilter) {
        this.synchronizeLocalPreSelectedEntities();
      }
      this.updateEntities();
    });

    this.updateCounter$.subscribe(() => {
      const localPreSelected: Map<string, ISelectInput> = new Map();
      if (this.criteriaMapPreSelected.has(SearchSelector.Channel)) {
        const argumentArr = this.getArgumentArray(
          this.criteriaMapPreSelected,
          SearchSelector.Channel,
        );

        if (this.criteriaMapPreSelected.get(SearchSelector.Channel)?.argument) {
          argumentArr?.forEach((argument) => {
            localPreSelected.set(argument, {
              id: argument,
              text: this.channels.getValue(argument),
            });
          });
          this.localPreSelectedEntities = cloneDeep(localPreSelected);
        } else {
          this.localPreSelectedEntities.clear();
        }
      }
      this.updateEntities();
    });
  }

  onFilterAppliedAndEntitiesUpdated(): void {
    this.entities = this.channels.list;
    this.onFilterApplied();
    this.updateEntities();
    if (this.isOpen) {
      this.closeSubmenus$.next(true);
    }
    this.channelTextName.emit(this.channelText);
    this.isOpen = false;
  }

  private synchronizeLocalPreSelectedEntities(): void {
    if (this.criteriaMapPreSelected?.get(SearchSelector.Channel)?.argument) {
      const argumentArr = this.getArgumentArray(this.selectedEntities, SearchSelector.Channel);

      this.localPreSelectedEntities.clear();
      argumentArr.forEach((argument) => {
        this.setLocalPreSelectedEntities(argument, this.channels.getValue(argument));
      });
    } else {
      this.localPreSelectedEntities.clear();
    }
    this.updateEntities();
  }
}
