import { Component, ElementRef, HostListener } from '@angular/core';
import { ModalBase } from '../modal-base';

@Component({
  selector: 'portal-mandatory-modal',
  templateUrl: './mandatory-modal.component.html',
})
export class MandatoryModalComponent extends ModalBase {
  constructor(public el: ElementRef) {
    super(el);
  }

  //To disable the close feature of modal
  @HostListener('document:keydown.escape', ['$event'])
  onKeydown(): boolean {
    return false;
  }

  onClickOutside(): boolean {
    return false;
  }
}
