import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterContentItem, FILTER_CONTENT_ITEM } from '@portal/shared/ui/filter';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'portal-wl-add-filter',
  templateUrl: './add-filter.component.html',
  styleUrls: ['./add-filter.component.scss'],
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: WLAddFilterComponent,
    },
  ],
})
export class WLAddFilterComponent extends FilterContentItem implements OnInit, OnDestroy {
  @Input() isDeviceMobile = false;

  @Output() itemToggled = new EventEmitter();
  @Output() filterApplied = new EventEmitter<void>();
  @Output() closeSubmenus = new EventEmitter<boolean>();

  isOpen = false;
  isDropdownOpen = false;

  private goToSubmenuSubject$ = new Subject<string>();
  private goToSubmenuSub: Subscription;

  onFilterApplied(): void {}

  getList(): any {
    return null;
  }

  ngOnInit(): void {
    this.goToSubmenuSub = this.goToSubmenuSubject$
      .pipe(debounceTime(100))
      .subscribe((submenu: string) => {
        const element = document.getElementById(`filter-${submenu}-detail`);
        if (element) {
          element.click();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.goToSubmenuSub) {
      this.goToSubmenuSub.unsubscribe();
    }
  }

  goToSubmenu(submenu: string): void {
    this.itemToggled.emit();
    this.goToSubmenuSubject$.next(submenu);
  }

  openMenu(): void {
    this.isDropdownOpen = true;
    this.itemToggled.emit();
    if (this.isOpen) {
      this.closeSubmenus.emit(true);
    }
  }
}
