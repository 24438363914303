import { Injectable } from '@angular/core';
import { colord as ColorD } from 'colord';
import { BasePalette } from '../interfaces/base-palette.interface';
import { NavbarPalette } from '../interfaces/navbar-palette.interface';
import { Palette } from '../interfaces/palette.interface';
import { BrandPalette } from '../interfaces/brand-palette.interface';
import { toKebabCase } from '@portal/shared/helpers/src/lib/case-converter/case-converter';

@Injectable({ providedIn: 'root' })
export class PaletteGeneratorService {
  getPalette(basePalette: BasePalette): Palette {
    const {
      brandBackground,
      brandText,
      brandOutlinedText,
      navbarBackgroundColor,
      navbarItemColor,
    } = basePalette;
    return {
      brand: this.getBrandPalette(brandBackground, brandText, brandOutlinedText),
      navbar: this.getNavbarPalette(navbarBackgroundColor, navbarItemColor),
    };
  }

  getBrandPalette(brand: string, brandText: string, brandOutlinedText: string): BrandPalette {
    const brandClr = ColorD(brand);
    const outlinedClr = ColorD(brandOutlinedText);
    const brandLightenClr = brandClr.lighten(0.09);
    const brandDarkenClr = brandClr.darken(0.11);

    const white = '#fff';
    const outlineBorder = '#c4ccd4';
    const brandBorder = brandClr.toRgbString();
    const brandBoxShadow = brandClr.alpha(0.1).toRgbString();
    const brandLighten = brandLightenClr.toRgbString();
    const brandDarken = brandDarkenClr.toRgbString();
    const brandActiveTextClr = brandClr.lighten(-0.11);
    const outlinedActiveTextClr = outlinedClr.lighten(-0.11);

    const isBrandDark = brandClr.isDark();

    const brandHoverBackgroundLight = brandClr.lighten(0.3).toRgbString();
    const brandHover = isBrandDark ? brandLighten : brandDarken;
    const brandHoverBorder = isBrandDark ? brandDarken : brandLighten;
    const brandActiveBackground = isBrandDark ? brandDarken : brandLighten;

    const brandActiveText = brandActiveTextClr.toRgbString();
    const outlinedActiveText = outlinedActiveTextClr.toRgbString();

    return toKebabCase({
      // default
      brandBackground: brand,
      brandText: brandText,
      brandTextDisabled: ColorD(brandText).alpha(0.3).toRgbString(),
      brandBorder: brandBorder,
      brandBoxShadow: brandBoxShadow,
      brandFocusBoxShadow: brandBoxShadow,

      // hover
      brandHoverBackground: brandHover,
      brandHoverLightBackground: brandHoverBackgroundLight,
      brandHoverText: brandText, // SGC-84953 TODO: we should create for hovered
      brandHoverBorder: brandBorder,

      // focus
      brandFocusBackground: brandHover,
      brandFocusText: brandText, // SGC-84953 TODO: we should create for focused
      brandFocusBorder: brandHoverBorder,

      // active
      brandActiveBackground: brandActiveBackground,
      brandActiveText: brandText, // SGC-84953 TODO: compare with default color
      brandActiveBorder: brandActiveText,

      // outlined default
      brandOutlinedBackground: white,
      brandOutlinedText: brandOutlinedText,
      brandOutlinedBorder: outlineBorder,

      // outlined hover
      brandOutlinedHoverBackground: white,
      brandOutlinedHoverText: brandOutlinedText, // SGC-84953 TODO: we should create for hovered,
      brandOutlinedHoverBorder: brandHover,

      // outlined focus
      brandOutlinedFocusBackground: white,
      brandOutlinedFocusText: brandOutlinedText, // SGC-84953 TODO: we should create for focused,
      brandOutlinedFocusBorder: brandHover,

      // outlined active
      brandOutlinedActiveBackground: white,
      brandOutlinedActiveText: outlinedActiveText,
      brandOutlinedActiveBorder: brandHover,
    });
  }

  getNavbarPalette(navbar: string, navbarText: string): NavbarPalette {
    const white = '#fff';
    const black = '#000';
    const shadow = 'rgba(10, 10, 10, 0.1)';

    const navbarClr = ColorD(navbar);
    const isNavbarDark = navbarClr.isDark();

    const itemHoverBackgroundClr = navbarClr.saturate(0.02).lighten(0.12);
    const dropdownItemHoverClr = isNavbarDark
      ? ColorD(black).lighten(0.04)
      : ColorD(white).darken(0.04);
    const navbarDropdownItemHoverBackground = navbarClr.alpha(0.1).toRgbString();
    const navbarBurger = navbarClr
      .rotate(isNavbarDark ? -3 : 3)
      .saturate(isNavbarDark ? 0.07 : -0.07)
      .lighten(isNavbarDark ? 0.85 : -0.85)
      .toRgbString();

    const itemHover = isNavbarDark ? white : black;
    const itemHoverBackground = itemHoverBackgroundClr.toRgbString();
    const dropdownItemHover = dropdownItemHoverClr.toRgbString();
    const navbarBorder = navbarClr.toRgbString();

    return toKebabCase({
      navbarBackground: navbar,
      navbarItem: navbarText,

      // item hover
      navbarItemHover: itemHover,
      navbarItemHoverBackground: itemHoverBackground,

      // item active
      navbarItemActive: itemHover,
      navbarItemActiveBackground: itemHoverBackground,

      navbarItemLink: navbarText,
      navbarItemLinkFocusHoverActiveBackground: itemHoverBackground,
      navbarItemLinkFocusHoverActive: itemHover,
      navbarItemLinkBorder: navbarBorder,
      navbarItemLinkCircleBackground: navbarDropdownItemHoverBackground,

      // dropdown item
      navbarDropdownItem: navbar,
      navbarDropdownBackground: white,
      navbarDropdownArrow: dropdownItemHover,
      navbarDropdownBoxedShadow: shadow,

      // dropdown item hover
      navbarDropdownItemHover: navbar,
      navbarDropdownItemHoverBackground: navbarDropdownItemHoverBackground,

      // dropdown item active
      navbarDropdownItemActive: navbar,
      navbarDropdownItemActiveBackground: navbarDropdownItemHoverBackground,

      navbarBoxShadow: shadow,
      navbarBurger: navbarBurger,
      navbarDividerBackground: navbar,
    });
  }
}
