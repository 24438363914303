<div
  class="users-filter dropdown width-full"
  [class.is-active]="isOpen"
  [ngClass]="{ 'displayed-multiplied-org-group': allPreSelectedUserValues?.length > 2 }"
>
  <div class="dropdown-trigger width-full rows">
    <div
      class="selected-users width-full rows"
      [ngClass]="{ 'selected-organizations box': allPreSelectedUserValues?.length > 2 }"
      *ngIf="organizationGroup && allPreSelectedUserValues?.length"
    >
      <div class="buttons selected-user-groups">
        <button
          type="button"
          class="button button-selected-entity mb-2 mr-2 is-primary is-outlined has-text-black has-text-truncate"
          *ngFor="let selectedUser of allPreSelectedUserValues"
          title="{{ selectedUser.text }}"
        >
          <span>{{ selectedUser.text }} </span>
          <span class="icon is-size-7" (click)="removeSelectedUser(selectedUser)">
            <i class="fas fa-times"></i>
          </span>
        </button>
      </div>
    </div>

    <div class="row width-full mb-2" *ngIf="!organizationGroup">
      <button
        class="button is-chip width-full is-size-7-mobile"
        [class.is-applied]="getValues().length"
        [class.is-active]="isOpen"
        data-e2e="button-entity-filter"
        (click)="itemToggled.emit()"
        title="{{ usersText }}"
        type="button"
      >
        <span> {{ usersText }}</span>
        <span class="icon">
          <i class="fas fa-chevron-down"></i>
        </span>
      </button>
    </div>

    <div class="input is-flex mb-5 mt-3" *ngIf="organizationGroup">
      <div class="selectedFeature-list" readonly="true" (click)="openContent()"></div>
      <span class="input-icon is-size-7" [ngClass]="{ 'icon-rotate': isOpen }">
        <i class="fas fa-chevron-down"></i>
      </span>
    </div>
  </div>
  <div class="dropdown-menu mt-4">
    <div class="dropdown-content multi-select-filter">
      <div class="field is-grouped">
        <div
          (click)="onClose()"
          (keyup.enter)="onClose()"
          aria-label="close"
          data-e2e="hierarchy-close-button"
          class="modal-close is-medium column"
        ></div>
      </div>
      <div class="dropdown-item">
        <portal-search-filter-autocomplete-input
          [searchInputValue]="searchedText"
          (search)="onSearch($event)"
        ></portal-search-filter-autocomplete-input>

        <portal-switch-toggle
          *ngIf="!organizationGroup"
          #switchToggle
          [text]="labelSelectAllUsers"
          (change)="onSelectAllSwitchToggle()"
        >
        </portal-switch-toggle>
      </div>

      <hr class="dropdown-divider" />
      <ng-container *ngIf="showSelectedUsers?.size">
        <div class="dropdown-item is-scrollable">
          <label class="label" i18n>Selected</label>
          <ng-container *ngFor="let entity of showSelectedUsers | keyvalue">
            <portal-search-filter-checkbox
              #preSelectedEntities
              [id]="entity.key"
              [text]="entity.value.text"
              [selectedEntities]="showSelectedUsers"
              (removed)="onEntityRemoved($event)"
              (added)="onEntityAdded($event)"
            ></portal-search-filter-checkbox>
          </ng-container>
        </div>
      </ng-container>

      <div class="dropdown-item has-text-truncate" *ngIf="listLoading$ | async">
        <span class="has-text-truncate">
          <portal-spinner
            [isHorizontal]="true"
            [isSmall]="true"
            text="Loading users ids..."
            i18n-text
          ></portal-spinner>
        </span>
      </div>

      <hr class="dropdown-divider" />
      <div class="dropdown-item has-text-truncate" *ngIf="listLoading$ | async">
        <span class="has-text-truncate">
          <portal-spinner
            [isHorizontal]="true"
            [isSmall]="true"
            text="Loading users..."
            i18n-text
          ></portal-spinner>
        </span>
      </div>
      <div class="dropdown-item is-scrollable" *ngIf="!(listLoading$ | async)">
        <span class="has-text-truncate" *ngIf="!users?.length" i18n>
          Start typing to search for users..
        </span>
        <ng-container *ngFor="let user of users">
          <portal-search-filter-checkbox
            [id]="user.userUid"
            [text]="user.name"
            [selectedEntities]="localPreSelectedEntities"
            [searchedText]="searchedText"
            (added)="onEntityAdded($event)"
            (removed)="onEntityRemoved($event)"
          >
          </portal-search-filter-checkbox>
        </ng-container>
      </div>

      <hr class="dropdown-divider" />

      <div class="dropdown-item">
        <button
          class="button is-primary"
          data-e2e="users-filter-button-apply"
          i18n
          (click)="onFilterApplied()"
          type="button"
          title="Apply"
          i18n-title
        >
          Apply
        </button>
        <button
          class="button is-transparent button-clear-filter ml-2"
          data-e2e="button-clear-filter"
          i18n
          (click)="onClear()"
          (keydown.enter)="onClear()"
          type="button"
          title="Clear"
          i18n-title
        >
          Clear
        </button>
      </div>
    </div>
  </div>
</div>
