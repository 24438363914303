<div
  class="hierarchy-filter dropdown"
  [ngClass]="{ 'is-active': isOpen, 'display-from-filter': displayFromFilter }"
>
  <a class="is-hidden" (click)="openContent()" id="filter-organisations-detail">
    <i class="fas fa-chevron-right" aria-hidden="true"></i>
  </a>
  <div class="dropdown-trigger" *ngIf="!displayFromFilter">
    <button
      [attr.aria-expanded]="isOpen"
      id="orgFilterButton"
      [className]="isNewDesignFilter ? 'button is-filter ' : 'button is-chip is-size-7-mobile'"
      [class.is-applied]="getValues().length"
      [class.is-active]="isOpen"
      (click)="itemToggled.emit()"
      (keydown.space)="onKeyDownSpace()"
      title="{{ title }}"
      type="button"
    >
      <span>{{ title }}</span>
      <span class="icon">
        <i class="fas fa-chevron-down"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu">
    <div class="dropdown-content">
      <div class="dropdown-item">
        <portal-search-filter-autocomplete-input
          (search)="onSearch($event)"
        ></portal-search-filter-autocomplete-input>
      </div>

      <div class="field is-grouped flex-end" *ngIf="displaySubOrganisationCheckBox">
        <div class="control toggle-label has-toggle-label">
          <label i18n>Include sub-organisations</label>
        </div>
        <div class="control">
          <label class="toggle">
            <input
              id="hierarchy"
              type="checkbox"
              name="{{ hierarchy }}"
              [checked]="isCheckedDefault"
              (click)="onCheckboxToggle()"
              attr.data-e2e="{{ hierarchy }}-input-checkbox"
            />
            <span></span>
          </label>
        </div>
      </div>

      <hr class="dropdown-divider" />

      <div class="dropdown-item has-text-truncate" *ngIf="loading$ | async">
        <span class="has-text-truncate">
          <portal-spinner
            [isHorizontal]="true"
            [isSmall]="true"
            text="Loading organisations..."
            i18n-text
          ></portal-spinner>
        </span>
      </div>

      <div
        class="dropdown-item is-scrollable"
        *ngIf="!(loading$ | async)"
        (scroll)="onScrolled($event)"
      >
        <span class="has-text-truncate" *ngIf="!entities?.length" i18n>
          Start typing to search for organisations..
        </span>

        <!-- tree -->
        <tree-root
          #tree
          [options]="options"
          [nodes]="entities"
          [(state)]="treeState"
          (activate)="onActivateEntity($event.node.data)"
          (deactivate)="onDeactivateEntity()"
          (focusin)="onFocusIn($event, tree.treeModel)"
          (focusout)="onFocusOut($event, tree.treeModel)"
          tabindex="0"
        >
          <ng-template #treeNodeFullTemplate let-node let-index="index" let-templates="templates">
            <div
              [class]="node.getClass()"
              [class.tree-node]="true"
              [class.tree-node-expanded]="node.isExpanded && node.hasChildren"
              [class.tree-node-collapsed]="node.isCollapsed && node.hasChildren"
              [class.tree-node-leaf]="node.isLeaf"
              [class.tree-node-active]="node.isActive"
              [class.tree-node-focused]="node.isFocused"
              [class.is-tree-filtered]="hasFilteredText"
            >
              <tree-node-drop-slot
                *ngIf="index === 0"
                [dropIndex]="node.index"
                [node]="node.parent"
              >
              </tree-node-drop-slot>

              <tree-node-wrapper
                [node]="node"
                [index]="index"
                [templates]="templates"
              ></tree-node-wrapper>

              <tree-node-children [node]="node" [templates]="templates"></tree-node-children>

              <div
                class="load-more"
                *ngIf="
                  node.isExpanded && node?.children && node?.children?.length < node.data.count
                "
              >
                <span
                  *ngIf="!(loadingMore$ | async); else loadingTemplateForMore"
                  class="button has-text-primary is-text pl-5"
                  (click)="onLoadMore(node)"
                  i18n
                >
                  Load more...
                </span>
                <ng-template #loadingTemplateForMore>
                    <div class="load-more-container">
                      <div class="spinner-container">
                        <div class="spinner" role="status" aria-live="assertive"></div>
                      </div>
                      <span i18n> Loading... </span>
                    </div>
                </ng-template>
              </div>

              <tree-node-drop-slot
                [dropIndex]="node.index + 1"
                [node]="node.parent"
              ></tree-node-drop-slot>
            </div>
          </ng-template>

          <ng-template #treeNodeTemplate let-node let-index="index">
            <div class="is-flex is-flex-direction-column">
              <div>
                <span class="is-size-6" style="display: inline-block">{{ node.data?.name }}</span>
                <span class="has-text-grey" *ngIf="node.data?.count">
                  ({{ node.data?.count }})
                </span>
              </div>
              <span class="is-size-7" *ngIf="node.data?.path">{{ node.data?.path }}</span>
            </div>
          </ng-template>
        </tree-root>

        <span class="has-text-truncate" *ngIf="loadingNewPortion$ | async">
          <portal-spinner [isSmall]="true"></portal-spinner>
        </span>
      </div>

      <hr class="dropdown-divider" />

      <div class="dropdown-item">
        <button
          id="applyButton"
          class="button is-primary"
          (click)="onFilterApplied(true)"
          i18n
          title="Apply"
          i18n-title
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</div>
