import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { EnvironmentVariableService } from '@portal/shared/helpers';
import { AuthenticationService } from '@portal/shared/auth/authentication';

@Injectable({ providedIn: 'root' })
export class RedirectToInitialPage implements CanActivate {
  constructor(private router: Router, private authService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const cba = EnvironmentVariableService.getVar('CBA');
    const authenticated = this.authService.isAuthenticated;
    if (cba === true && !authenticated && Object.keys(route.queryParams).length === 0) {
      const tree = this.router.createUrlTree(['/welcome']);
      tree.queryParams = route.queryParams;
      tree.fragment = route.fragment;
      return tree;
    }
    return true;
  }
}
