<div class="container is-narrow">
  <a
    [restrict-permission]="['read']"
    [routerLink]="['/administration', 'users']"
    data-e2e="link-back"
    *ngIf="!isPetroUser"
    title="Back to users"
    i18n-title
    #backToUserLink
  >
    <span class="icon is-small">
      <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
    </span>
    <span i18n>Users</span>
  </a>

  <h1 class="title is-2" data-e2e="title">{{ user?.name }}</h1>
  <div class="box" *ngIf="loading$ | async">
    <portal-spinner
      [isHorizontal]="true"
      [isSmall]="true"
      text="Loading user detail"
      i18n-text
    ></portal-spinner>
  </div>

  <div class="box has-text-centered" *ngIf="!(loading$ | async) && !user" i18n>No user found</div>

  <ng-container *ngIf="!(loading$ | async) && user">
    <div class="box">
      <div
        class="columns is-multiline is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
      >
        <div class="column">
          <h2 class="title is-3" i18n>General information</h2>
        </div>
        <div
          class="column has-text-left-mobile has-text-right"
          *restrictPermission="['system-information-view']"
        >
          <portal-copy-to-clipboard
            [valueToCopy]="formatCopyText()"
            [buttonConfig]="copyButtonConfig"
            [copyMessage]="copyMessage"
            dataE2eId="copy-system-information"
          ></portal-copy-to-clipboard>
        </div>
      </div>

      <dl
        class="columns is-multiline is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
      >
        <dt class="column is-one-third has-text-grey" i18n>First name</dt>
        <dd class="column is-two-thirds wrap-content">
          {{ user?.nameDetails?.firstName || (user?.name?.split())[0] }}
        </dd>
        <dt class="column is-one-third has-text-grey" i18n>Middle name</dt>
        <dd class="column is-two-thirds wrap-content">{{ user?.nameDetails?.middleName }}</dd>
        <dt class="column is-one-third has-text-grey" i18n>Last name</dt>
        <dd class="column is-two-thirds wrap-content">{{ user?.nameDetails?.lastName }}</dd>
        <dt class="column is-one-third has-text-grey">
          <span *ngIf="isPetroUser" i18n>Organization</span>
          <span *ngIf="!isPetroUser" i18n>Organization</span>
        </dt>
        <dd class="column is-two-thirds wrap-content">{{ user?.entity?.name || '-' }}</dd>
        <dt class="column is-one-third has-text-grey">
          <span *ngIf="isPetroUser" i18n>Organization ID</span>
          <span *ngIf="!isPetroUser" i18n>Organization ID</span>
        </dt>
        <dd class="column is-two-thirds wrap-content">{{ user?.entity?.entityUid }}</dd>
        <ng-container *ngIf="!isUserDeleted">
          <dt class="column is-one-third has-text-grey" i18n>Email</dt>
          <dd class="column is-two-thirds wrap-content">{{ user?.email }}</dd>
        </ng-container>
        <ng-container *ngIf="!isUserDeleted">
          <dt class="column is-one-third has-text-grey" i18n>Phone Number</dt>
          <dd class="column is-two-thirds wrap-content">{{ user?.mobilePhoneNumber }}</dd>
        </ng-container>
        <dt class="column is-one-third has-text-grey" i18n>Roles</dt>
        <dd class="column is-two-thirds">
          {{ user?.roles | listValues : userRolesList }}
        </dd>
        <dt class="column is-one-third has-text-grey" i18n>Status</dt>
        <dd class="column is-two-thirds">{{ displayedStatus }}</dd>
      </dl>

      <ng-container *restrictPermission="['system-information-view']">
        <div class="box__separator"></div>
        <h2 class="title is-3" i18n>System information</h2>
        <dl
          class="columns is-multiline is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
        >
          <dt class="column is-one-third has-text-grey" i18n>Environment</dt>
          <dd class="column is-two-thirds">{{ environmentFormat }}</dd>
          <dt class="column is-one-third has-text-grey" i18n>Release version</dt>
          <dd class="column is-two-thirds">{{ version }}</dd>
        </dl>
      </ng-container>
    </div>

    <ng-container *restrictPermission="['app-notification-setting']">
      <div class="box">
        <h2 class="title is-3" i18n>Notification settings</h2>
        <dl
          class="columns is-multiline is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
        >
          <dt class="column is-one-third has-text-grey" i18n>Update on app status changed?</dt>
          <dd class="column is-two-thirds" i18n>{{appStatusValue}}</dd>
        </dl>
      </div>
    </ng-container>

    <div
      class="columns is-vcentered is-flex is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
    >
      <ng-container
        *ngIf="user?.status === statuses.Active && (hasUserPermission() || selfEditMode)"
      >
        <div class="column">
          <ng-container *ngIf="!selfEditMode">
            <button
              #disableButton
              class="button is-danger is-outlined"
              data-e2e="button-disable"
              (click)="onClickDisableButton()"
              *restrictPermission="['update']"
              type="button"
              i18n
              title="Disable user"
              i18n-title
            >
              Disable user
            </button>
          </ng-container>
        </div>

        <div class="column has-text-right">
          <a
            #editButton
            class="button is-primary is-outlined"
            data-e2e="link-edit"
            [restrict-permission]="selfEditPermission"
            [routerLink]="['/administration', 'users', user?.userUid || '', 'edit']"
            i18n
            title="Click to edit user"
            i18n-title
          >
            Edit user
          </a>
        </div>
      </ng-container>
      <ng-container *ngIf="user?.status === statuses.Inactive">
        <div class="column">
          <ng-container>
            <button
              #deleteButton
              class="button is-danger is-outlined"
              data-e2e="button-disable"
              (click)="onConfirmDelete()"
              *restrictPermission="['delete-user']"
              type="button"
              i18n
              title="Delete user"
              i18n-title
            >
              Delete user
            </button>
          </ng-container>
        </div>

        <div class="column has-text-right">
          <button
            #enableButton
            class="button is-primary"
            data-e2e="button-enable"
            (click)="onClickEnableButton()"
            *restrictPermission="['update']"
            type="button"
            i18n
            title="Enable user"
            i18n-title
          >
            Enable user
          </button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<portal-base-modal (closeModal)="onCloseDeleteModal()" #confirmDeleteModal>
  <div title i18n>Delete user</div>
  <div description>
    <span i18n>
      Are you sure you want to delete user <b>{{ user?.name }}</b
      >? This will permanently delete their account.
    </span>
    <label class="field">
      <div class="label pt-2" i18n>Please select a reason for deletion:</div>
      <div class="control pb-1" *ngFor="let reason of reasonsForDeletionOptions; let i = index">
        <label class="radio" attr.data-e2e="radio-reason-{{ i }}">
          <input
            type="radio"
            name="reasonForDeletion"
            [value]="reason.id"
            [(ngModel)]="reasonForDeletion"
          />
          <span>{{ reason.text }}</span>
        </label>
      </div>
    </label>
  </div>
  <div class="level" buttons>
    <button
      type="button"
      class="level-left button is-primary"
      (click)="confirmDeleteModal.close()"
      i18n
      title="Keep user"
      i18n-title
    >
      Keep user
    </button>
    <div class="buttons">
      <button
        type="button"
        class="button is-danger is-outlined"
        (click)="onModalConfirmed()"
        i18n
        title="Delete user"
        i18n-title
        [disabled]="disableDeleteButton"
      >
        Delete user
      </button>
    </div>
  </div>
</portal-base-modal>

<portal-base-modal #confirmEnableModal [isManualFocusAdded]="true">
  <div title i18n>Enable user</div>
  <div description i18n>
    Are you sure you want to enable <b>{{ user?.name }}</b
    >? They will be able to log in.
  </div>
  <div class="level buttons row" buttons>
    <div class="column">
      <button
        type="button"
        class="button is-primary"
        (click)="onEnableModalConfirmed()"
        i18n
        title="Enable user"
        i18n-title
      >
        Enable user
      </button>
    </div>
    <div class="column has-text-right">
      <button
        type="button"
        class="button is-secondary"
        (click)="confirmEnableModal.close()"
        i18n="Button in Enable User dialog (equivalent to Cancel)"
        title="Keep user disabled"
        i18n-title
      >
        Keep user disabled
      </button>
    </div>
    <div></div>
  </div>
</portal-base-modal>

<portal-base-modal #confirmDisableModal [isManualFocusAdded]="true">
  <div title i18n>Disable user</div>
  <div description i18n>
    Are you sure you want to disable <b>{{ user?.name }}</b
    >? They will no longer be able to log in.
  </div>
  <div class="level buttons row" buttons>
    <div class="column">
      <button
        type="button"
        class="button is-primary"
        (click)="onDisableModalConfirmed()"
        i18n
        title="Disable user"
        i18n-title
      >
        Disable user
      </button>
    </div>
    <div class="column has-text-right">
      <button
        type="button"
        class="button is-secondary"
        (click)="confirmDisableModal.close()"
        i18n="Button in Enable User dialog (equivalent to Cancel)"
        title="Keep user enabled"
        i18n-title
      >
        Keep user enabled
      </button>
    </div>
  </div>
</portal-base-modal>
