import { Injectable } from '@angular/core';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({
  providedIn: 'root',
})
export class FourthPartOfSubdivisionsList {
  list: ISelectInput[];
  keyValue = {
    ['NZ-AUK']: $localize`Auckland`,
    ['NZ-BOP']: $localize`Bay of Plenty`,
    ['NZ-CAN']: $localize`Canterbury`,
    ['NZ-CIT']: $localize`Chatham Islands Territory`,
    ['NZ-GIS']: $localize`Gisborne District`,
    ['NZ-HKB']: $localize`Hawke's Bay`,
    ['NZ-MBH']: $localize`Marlborough District`,
    ['NZ-MWT']: $localize`Manawatu-Wanganui`,
    ['NZ-N']: $localize`North Island`,
    ['NZ-NSN']: $localize`Nelson City`,
    ['NZ-NTL']: $localize`Northland`,
    ['NZ-OTA']: $localize`Otago`,
    ['NZ-S']: $localize`South Island`,
    ['NZ-STL']: $localize`Southland`,
    ['NZ-TAS']: $localize`Tasman District`,
    ['NZ-TKI']: $localize`Taranaki`,
    ['NZ-WGN']: $localize`Wellington`,
    ['NZ-WKO']: $localize`Waikato`,
    ['NZ-WTC']: $localize`West Coast`,
    ['NI-AN']: $localize`Atlántico Norte`,
    ['NI-AS']: $localize`Atlántico Sur`,
    ['NI-BO']: $localize`Boaco`,
    ['NI-CA']: $localize`Carazo`,
    ['NI-CI']: $localize`Chinandega`,
    ['NI-CO']: $localize`Chontales`,
    ['NI-ES']: $localize`Estelí`,
    ['NI-GR']: $localize`Granada`,
    ['NI-JI']: $localize`Jinotega`,
    ['NI-LE']: $localize`León`,
    ['NI-MD']: $localize`Madriz`,
    ['NI-MN']: $localize`Managua`,
    ['NI-MS']: $localize`Masaya`,
    ['NI-MT']: $localize`Matagalpa`,
    ['NI-NS']: $localize`Nueva Segovia`,
    ['NI-RI']: $localize`Rivas`,
    ['NI-SJ']: $localize`Río San Juan`,
    ['NG-AB']: $localize`Abia`,
    ['NG-AD']: $localize`Adamawa`,
    ['NG-AK']: $localize`Akwa Ibom`,
    ['NG-AN']: $localize`Anambra`,
    ['NG-BA']: $localize`Bauchi`,
    ['NG-BE']: $localize`Benue`,
    ['NG-BO']: $localize`Borno`,
    ['NG-BY']: $localize`Bayelsa`,
    ['NG-CR']: $localize`Cross River`,
    ['NG-DE']: $localize`Delta`,
    ['NG-EB']: $localize`Ebonyi`,
    ['NG-ED']: $localize`Edo`,
    ['NG-EK']: $localize`Ekiti`,
    ['NG-EN']: $localize`Enugu`,
    ['NG-FC']: $localize`Abuja Federal Capital Territory`,
    ['NG-GO']: $localize`Gombe`,
    ['NG-IM']: $localize`Imo`,
    ['NG-JI']: $localize`Jigawa`,
    ['NG-KD']: $localize`Kaduna`,
    ['NG-KE']: $localize`Kebbi`,
    ['NG-KN']: $localize`Kano`,
    ['NG-KO']: $localize`Kogi`,
    ['NG-KT']: $localize`Katsina`,
    ['NG-KW']: $localize`Kwara`,
    ['NG-LA']: $localize`Lagos`,
    ['NG-NA']: $localize`Nasarawa`,
    ['NG-NI']: $localize`Niger`,
    ['NG-OG']: $localize`Ogun`,
    ['NG-ON']: $localize`Ondo`,
    ['NG-OS']: $localize`Osun`,
    ['NG-OY']: $localize`Oyo`,
    ['NG-PL']: $localize`Plateau`,
    ['NG-RI']: $localize`Rivers`,
    ['NG-SO']: $localize`Sokoto`,
    ['NG-TA']: $localize`Taraba`,
    ['NG-YO']: $localize`Yobe`,
    ['NG-ZA']: $localize`Zamfara`,
    ['NE-1']: $localize`Agadez`,
    ['NE-2']: $localize`Diffa`,
    ['NE-3']: $localize`Dosso`,
    ['NE-4']: $localize`Maradi`,
    ['NE-5']: $localize`Tahoua`,
    ['NE-6']: $localize`Tillabéri`,
    ['NE-7']: $localize`Zinder`,
    ['NE-8']: $localize`Niamey`,
    ['U-NU']: $localize`Niue`,
    ['NF-NF']: $localize`Norfolk Island`,
    ['MP-MP']: $localize`Northern Mariana Islands`,
    ['NO-01']: $localize`Østfold`,
    ['NO-02']: $localize`Akershus`,
    ['NO-03']: $localize`Oslo`,
    ['NO-04']: $localize`Hedmark`,
    ['NO-05']: $localize`Oppland`,
    ['NO-06']: $localize`Buskerud`,
    ['NO-07']: $localize`Vestfold`,
    ['NO-08']: $localize`Telemark`,
    ['NO-09']: $localize`Aust-Agder`,
    ['NO-10']: $localize`Vest-Agder`,
    ['NO-11']: $localize`Rogaland`,
    ['NO-12']: $localize`Hordaland`,
    ['NO-14']: $localize`Sogn og Fjordane`,
    ['NO-15']: $localize`Møre og Romsdal`,
    ['NO-16']: $localize`Sør-Trøndelag`,
    ['NO-17']: $localize`Nord-Trøndelag`,
    ['NO-18']: $localize`Nordland`,
    ['NO-19']: $localize`Romsa`,
    ['NO-20']: $localize`Finnmark`,
    ['NO-21']: $localize`Svalbard`,
    ['NO-22']: $localize`Jan Mayen`,
    ['OM-BA']: $localize`Al Bāţinah`,
    ['OM-BU']: $localize`Al Buraymī`,
    ['OM-DA']: $localize`Ad Dākhilīyah`,
    ['OM-MA']: $localize`Masqaţ`,
    ['OM-MU']: $localize`Musandam`,
    ['OM-SH']: $localize`Ash Sharqīyah`,
    ['OM-WU']: $localize`Al Wusţá`,
    ['OM-ZA']: $localize`Az̧ Z̧āhirah`,
    ['OM-ZU']: $localize`Z̧ufār`,
    ['PK-BA']: $localize`Balochistan`,
    ['PK-GB']: $localize`Gilgit-Baltistān`,
    ['PK-IS']: $localize`Islāmābād`,
    ['PK-JK']: $localize`Azad Kashmir`,
    ['PK-KP']: $localize`Khaībar Pakhtūnkhwā`,
    ['PK-PB']: $localize`Panjāb`,
    ['PK-SD']: $localize`Sindh`,
    ['PK-TA']: $localize`Federally Administered Tribal Areas`,
    ['PW-002']: $localize`Aimeliik`,
    ['PW-004']: $localize`Airai`,
    ['PW-010']: $localize`Angaur`,
    ['PW-050']: $localize`Hatobohei`,
    ['PW-100']: $localize`Kayangel`,
    ['PW-150']: $localize`Koror`,
    ['PW-212']: $localize`Melekeok`,
    ['PW-214']: $localize`Ngaraard`,
    ['PW-218']: $localize`Ngarchelong`,
    ['PW-222']: $localize`Ngardmau`,
    ['PW-224']: $localize`Ngatpang`,
    ['PW-226']: $localize`Ngchesar`,
    ['PW-227']: $localize`Ngeremlengui`,
    ['PW-228']: $localize`Ngiwal`,
    ['PW-350']: $localize`Peleliu`,
    ['PW-370']: $localize`Sonsorol`,
    ['PS-BTH']: $localize`Bayt Laḩm`,
    ['PS-DEB']: $localize`Dayr al Balaḩ`,
    ['PS-GZA']: $localize`Gaza`,
    ['PS-HBN']: $localize`Al Khalīl`,
    ['PS-JEM']: $localize`Al Quds`,
    ['PS-JEN']: $localize`Janīn`,
    ['PS-JRH']: $localize`Arīḩā wa al Aghwār`,
    ['PS-KYS']: $localize`Khan Yunis`,
    ['PS-NBS']: $localize`Nablus`,
    ['PS-NGZ']: $localize`North Gaza`,
    ['PS-QQA']: $localize`Qalqilya`,
    ['PS-RBH']: $localize`Ramallah`,
    ['PS-RFH']: $localize`Rafah`,
    ['PS-SLT']: $localize`Salfit`,
    ['PS-TBS']: $localize`Tubas`,
    ['PS-TKM']: $localize`Tulkarm`,
    ['PA-10']: $localize`Panamá Oeste`,
    ['PA-1']: $localize`Bocas del Toro`,
    ['PA-2']: $localize`Coclé`,
    ['PA-3']: $localize`Colón`,
    ['PA-4']: $localize`Chiriquí`,
    ['PA-5']: $localize`Darién`,
    ['PA-6']: $localize`Herrera`,
    ['PA-7']: $localize`Los Santos`,
    ['PA-8']: $localize`Panamá`,
    ['PA-9']: $localize`Veraguas`,
    ['PA-EM']: $localize`Emberá`,
    ['PA-KY']: $localize`Kuna Yala`,
    ['PA-NB']: $localize`Ngöbe-Buglé`,
    ['PG-CPK']: $localize`Chimbu`,
    ['PG-CPM']: $localize`Central`,
    ['PG-EBR']: $localize`East New Britain`,
    ['PG-EHG']: $localize`Eastern Highlands`,
    ['PG-EPW']: $localize`Enga`,
    ['PG-ESW']: $localize`East Sepik`,
    ['PG-GPK']: $localize`Gulf`,
    ['PG-HLA']: $localize`Hela`,
    ['PG-JWK']: $localize`Jiwaka`,
    ['PG-MBA']: $localize`Milne Bay`,
    ['PG-MPL']: $localize`Morobe`,
    ['PG-MPM']: $localize`Madang`,
    ['PG-MRL']: $localize`Manus`,
    ['PG-NCD']: $localize`National Capital District`,
    ['PG-NIK']: $localize`New Ireland`,
    ['PG-NPP']: $localize`Northern`,
    ['PG-NSB']: $localize`Bougainville`,
    ['PG-SAN']: $localize`West Sepik`,
    ['PG-SHM']: $localize`Southern Highlands`,
    ['PG-WBK']: $localize`West New Britain`,
    ['PG-WHM']: $localize`Western Highlands`,
    ['PG-WPD']: $localize`Western`,
    ['PY-10']: $localize`Alto Paraná`,
    ['PY-11']: $localize`Central`,
    ['PY-12']: $localize`Ñeembucú`,
    ['PY-13']: $localize`Amambay`,
    ['PY-14']: $localize`Canindeyú`,
    ['PY-15']: $localize`Presidente Hayes`,
    ['PY-16']: $localize`Alto Paraguay`,
    ['PY-19']: $localize`Boquerón`,
    ['PY-1']: $localize`Concepción`,
    ['PY-2']: $localize`San Pedro`,
    ['PY-3']: $localize`Cordillera`,
    ['PY-4']: $localize`Guairá`,
    ['PY-5']: $localize`Caaguazú`,
    ['PY-6']: $localize`Caazapá`,
    ['PY-7']: $localize`Itapúa`,
    ['PY-8']: $localize`Misiones`,
    ['PY-9']: $localize`Paraguarí`,
    ['PY-ASU']: $localize`Asunción`,
    ['PE-AMA']: $localize`Amarumayu`,
    ['PE-ANC']: $localize`Ancash`,
    ['PE-APU']: $localize`Apurímac`,
    ['PE-ARE']: $localize`Arequipa`,
    ['PE-AYA']: $localize`Ayacucho`,
    ['PE-CAJ']: $localize`Cajamarca`,
    ['PE-CAL']: $localize`El Callao`,
    ['PE-CUS']: $localize`Cusco`,
    ['PE-HUC']: $localize`Huánuco`,
    ['PE-HUV']: $localize`Huancavelica`,
    ['PE-ICA']: $localize`Ica`,
    ['PE-JUN']: $localize`Hunin`,
    ['PE-LAL']: $localize`La Libertad`,
    ['PE-LAM']: $localize`Lambayeque`,
    ['PE-LIM']: $localize`Lima`,
    ['PE-LMA']: $localize`Lima hatun llaqta`,
    ['PE-LOR']: $localize`Loreto`,
    ['PE-MDD']: $localize`Madre de Dios`,
    ['PE-MOQ']: $localize`Moquegua`,
    ['PE-PAS']: $localize`Pasco`,
    ['PE-PIU']: $localize`Piura`,
    ['PE-PUN']: $localize`Puno`,
    ['PE-SAM']: $localize`San Martin`,
    ['PE-TAC']: $localize`Tacna`,
    ['PE-TUM']: $localize`Tumbes`,
    ['PE-UCA']: $localize`Ucayali`,
    ['PH-00']: $localize`National Capital Region`,
    ['PH-01']: $localize`Ilocos`,
    ['PH-02']: $localize`Cagayan Valley`,
    ['PH-03']: $localize`Central Luzon`,
    ['PH-05']: $localize`Bicol`,
    ['PH-06']: $localize`Rehiyon ng Kanlurang Bisaya`,
    ['PH-07']: $localize`Central Visayas`,
    ['PH-08']: $localize`Eastern Visayas`,
    ['PH-09']: $localize`Rehiyon ng Tangway ng Sambuwangga`,
    ['PH-10']: $localize`Northern Mindanao`,
    ['PH-11']: $localize`Davao`,
    ['PH-12']: $localize`Rehiyon ng Soccsksargen`,
    ['PH-13']: $localize`Caraga`,
    ['PH-14']: $localize`Autonomous Region in Muslim Mindanao`,
    ['PH-15']: $localize`Cordillera Administrative Region`,
    ['PH-40']: $localize`Calabarzon`,
    ['PH-41']: $localize`Mimaropa`,
    ['PH-ABR']: $localize`Abra`,
    ['PH-AGN']: $localize`Agusan del Norte`,
    ['PH-AGS']: $localize`Agusan del Sur`,
    ['PH-AKL']: $localize`Aklan`,
    ['PH-ALB']: $localize`Albay`,
    ['PH-ANT']: $localize`Antike`,
    ['PH-APA']: $localize`Apayao`,
    ['PH-AUR']: $localize`Aurora`,
    ['PH-BAN']: $localize`Bataan`,
    ['PH-BAS']: $localize`Basilan`,
    ['PH-BEN']: $localize`Benget`,
    ['PH-BIL']: $localize`Biliran`,
    ['PH-BOH']: $localize`Bohol`,
    ['PH-BTG']: $localize`Batangas`,
    ['PH-BTN']: $localize`Batanes`,
    ['PH-BUK']: $localize`Bukidnon`,
    ['PH-BUL']: $localize`Bulacan`,
    ['PH-CAG']: $localize`Cagayan`,
    ['PH-CAM']: $localize`Camiguin`,
    ['PH-CAN']: $localize`Camarines Norte`,
    ['PH-CAP']: $localize`Capiz`,
    ['PH-CAS']: $localize`Camarines Sur`,
    ['PH-CAT']: $localize`Catanduanes`,
    ['PH-CAV']: $localize`Cavite`,
    ['PH-CEB']: $localize`Cebu`,
    ['PH-COM']: $localize`Compostela Valley`,
    ['PH-DAO']: $localize`Davao Oriental`,
    ['PH-DAS']: $localize`Davao del Sur`,
    ['PH-DAV']: $localize`Davao del Norte`,
    ['PH-DIN']: $localize`Dinagat Islands`,
    ['PH-EAS']: $localize`Eastern Samar`,
    ['PH-GUI']: $localize`Gimaras`,
    ['PH-IFU']: $localize`Ifugao`,
    ['PH-ILI']: $localize`Iloilo`,
    ['PH-ILN']: $localize`Hilagang Iloko`,
    ['PH-ILS']: $localize`Ilocos Sur`,
    ['PH-ISA']: $localize`Isabela`,
    ['PH-KAL']: $localize`Kalinga`,
    ['PH-LAG']: $localize`Laguna`,
    ['PH-LAN']: $localize`Hilagang Lanaw`,
    ['PH-LAS']: $localize`Lanao del Sur`,
    ['PH-LEY']: $localize`Leyte`,
    ['PH-LUN']: $localize`La Union`,
    ['PH-MAD']: $localize`Marinduke`,
    ['PH-MAG']: $localize`Magindanaw`,
    ['PH-MAS']: $localize`Masbate`,
    ['PH-MDC']: $localize`Kanlurang Mindoro`,
    ['PH-MDR']: $localize`Mindoro Oriental`,
    ['PH-MOU']: $localize`Lalawigang Bulubundukin`,
    ['PH-MSC']: $localize`Kanlurang Misamis`,
    ['PH-MSR']: $localize`Misamis Oriental`,
    ['PH-NCO']: $localize`Cotabato`,
    ['PH-NEC']: $localize`Kanlurang Negros`,
    ['PH-NER']: $localize`Negros Oriental`,
    ['PH-NSA']: $localize`Hilagang Samar`,
    ['PH-NUE']: $localize`Nueva Ecija`,
    ['PH-NUV']: $localize`Nueva Vizcaya`,
    ['PH-PAM']: $localize`Pampanga`,
    ['PH-PAN']: $localize`Pangasinan`,
    ['PH-PLW']: $localize`Palawan`,
    ['PH-QUE']: $localize`Keson`,
    ['PH-QUI']: $localize`Kirino`,
    ['PH-RIZ']: $localize`Risal`,
    ['PH-ROM']: $localize`Romblon`,
    ['PH-SAR']: $localize`Sarangani`,
    ['PH-SCO']: $localize`South Cotabato`,
    ['PH-SIG']: $localize`Sikihor`,
    ['PH-SLE']: $localize`Katimogang Leyte`,
    ['PH-SLU']: $localize`Sulu`,
    ['PH-SOR']: $localize`Sorsogon`,
    ['PH-SUK']: $localize`Sultan Kudarat`,
    ['PH-SUN']: $localize`Hilagang Surigaw`,
    ['PH-SUR']: $localize`Surigao del Sur`,
    ['PH-TAR']: $localize`Tarlac`,
    ['PH-TAW']: $localize`Tawi-Tawi`,
    ['PH-WSA']: $localize`Samar`,
    ['PH-ZAN']: $localize`Hilagang Sambuwangga`,
    ['PH-ZAS']: $localize`Timog Sambuwangga`,
    ['PH-ZMB']: $localize`Sambales`,
    ['PH-ZSI']: $localize`Sambuwangga Sibugay`,
    ['PN-PN']: $localize`Pitcairn`,
    ['PL-DS']: $localize`Dolnośląskie`,
    ['PL-KP']: $localize`Kujawsko-pomorskie`,
    ['PL-LB']: $localize`Lubuskie`,
    ['PL-LD']: $localize`Łódzkie`,
    ['PL-LU']: $localize`Lubelskie`,
    ['PL-MA']: $localize`Małopolskie`,
    ['PL-MZ']: $localize`Mazowieckie`,
    ['PL-OP']: $localize`Opolskie`,
    ['PL-PD']: $localize`Podlaskie`,
    ['PL-PK']: $localize`Podkarpackie`,
    ['PL-PM']: $localize`Pomorskie`,
    ['PL-SK']: $localize`Świętokrzyskie`,
    ['PL-SL']: $localize`Śląskie`,
    ['PL-WN']: $localize`Warmińsko-mazurskie`,
    ['PL-WP']: $localize`Wielkopolskie`,
    ['PL-ZP']: $localize`Zachodniopomorskie`,
    ['PT-01']: $localize`Aveiro`,
    ['PT-02']: $localize`Beja`,
    ['PT-03']: $localize`Braga`,
    ['PT-04']: $localize`Bragança`,
    ['PT-05']: $localize`Castelo Branco`,
    ['PT-06']: $localize`Coimbra`,
    ['PT-07']: $localize`Évora`,
    ['PT-08']: $localize`Faro`,
    ['PT-09']: $localize`Guarda`,
    ['PT-10']: $localize`Leiria`,
    ['PT-11']: $localize`Lisboa`,
    ['PT-12']: $localize`Portalegre`,
    ['PT-13']: $localize`Porto`,
    ['PT-14']: $localize`Santarém`,
    ['PT-15']: $localize`Setúbal`,
    ['PT-16']: $localize`Viana do Castelo`,
    ['PT-17']: $localize`Vila Real`,
    ['PT-18']: $localize`Viseu`,
    ['PT-20']: $localize`Região Autónoma dos Açores`,
    ['PT-30']: $localize`Região Autónoma da Madeira`,
    ['PR-PR']: $localize`Puerto Rico`,
    ['QA-DA']: $localize`Ad Dawḩah`,
    ['QA-KH']: $localize`Al Khawr wa adh Dhakhīrah`,
    ['QA-MS']: $localize`Ash Shamāl`,
    ['QA-RA']: $localize`Ar Rayyān`,
    ['QA-US']: $localize`Umm Şalāl`,
    ['QA-WA']: $localize`Al Wakrah`,
    ['QA-ZA']: $localize`Az̧ Z̧a‘āyin`,
    ['RE-RE']: $localize`Reunion`,
    ['RO-AB']: $localize`Alba`,
    ['RO-AG']: $localize`Argeş`,
    ['RO-AR']: $localize`Arad`,
    ['RO-B']: $localize`Bucureşti`,
    ['RO-BC']: $localize`Bacău`,
    ['RO-BH']: $localize`Bihor`,
    ['RO-BN']: $localize`Bistriţa-Năsăud`,
    ['RO-BR']: $localize`Brăila`,
    ['RO-BT']: $localize`Botoşani`,
    ['RO-BV']: $localize`Braşov`,
    ['RO-BZ']: $localize`Buzău`,
    ['RO-CJ']: $localize`Cluj`,
    ['RO-CL']: $localize`Călăraşi`,
    ['RO-CS']: $localize`Caraş-Severin`,
    ['RO-CT']: $localize`Constanţa`,
    ['RO-CV']: $localize`Covasna`,
    ['RO-DB']: $localize`Dâmboviţa`,
    ['RO-DJ']: $localize`Dolj`,
    ['RO-GJ']: $localize`Gorj`,
    ['RO-GL']: $localize`Galaţi`,
    ['RO-GR']: $localize`Giurgiu`,
    ['RO-HD']: $localize`Hunedoara`,
    ['RO-HR']: $localize`Harghita`,
    ['RO-IF']: $localize`Ilfov`,
    ['RO-IL']: $localize`Ialomiţa`,
    ['RO-IS']: $localize`Iaşi`,
    ['RO-MH']: $localize`Mehedinţi`,
    ['RO-MM']: $localize`Maramureş`,
    ['RO-MS']: $localize`Mureş`,
    ['RO-NT']: $localize`Neamţ`,
    ['RO-OT']: $localize`Olt`,
    ['RO-PH']: $localize`Prahova`,
    ['RO-SB']: $localize`Sibiu`,
    ['RO-SJ']: $localize`Sălaj`,
    ['RO-SM']: $localize`Satu Mare`,
    ['RO-SV']: $localize`Suceava`,
    ['RO-TL']: $localize`Tulcea`,
    ['RO-TM']: $localize`Timiş`,
    ['RO-TR']: $localize`Teleorman`,
    ['RO-VL']: $localize`Vâlcea`,
    ['RO-VN']: $localize`Vrancea`,
    ['RO-VS']: $localize`Vaslui`,
    ['RU-AD']: $localize`Adygeja, Respublika`,
    ['RU-AL']: $localize`Altaj, Respublika`,
    ['RU-ALT']: $localize`Altajskij kraj`,
    ['RU-AMU']: $localize`Amurskaja oblast'`,
    ['RU-ARK']: $localize`Arhangel'skaja oblast'`,
    ['RU-AST']: $localize`Astrahanskaja oblast'`,
    ['RU-BA']: $localize`Bashkortostan, Respublika`,
    ['RU-BEL']: $localize`Belgorodskaja oblast'`,
    ['RU-BRY']: $localize`Brjanskaja oblast'`,
    ['RU-BU']: $localize`Burjatija, Respublika`,
    ['RU-CE']: $localize`Čečenskaja Respublika`,
    ['RU-CHE']: $localize`Čeljabinskaja oblast'`,
    ['RU-CHU']: $localize`Chukotskiy avtonomnyy okrug`,
    ['RU-CU']: $localize`Chuvashskaya Respublika`,
    ['RU-DA']: $localize`Dagestan, Respublika`,
    ['RU-IN']: $localize`Ingušetija, Respublika`,
    ['RU-IRK']: $localize`Irkutskaja oblast'`,
    ['RU-IVA']: $localize`Ivanovskaja oblast'`,
    ['RU-KAM']: $localize`Kamčatskij kraj`,
    ['RU-KB']: $localize`Kabardino-Balkarskaja Respublika`,
    ['RU-KC']: $localize`Karačaevo-Čerkesskaja Respublika`,
    ['RU-KDA']: $localize`Krasnodarskij kraj`,
    ['RU-KEM']: $localize`Kemerovskaja oblast'`,
    ['RU-KGD']: $localize`Kaliningradskaja oblast'`,
    ['RU-KGN']: $localize`Kurganskaja oblast'`,
    ['RU-KHA']: $localize`Habarovskij kraj`,
    ['RU-KHM']: $localize`Hanty-Mansijskij avtonomnyj okrug`,
    ['RU-KIR']: $localize`Kirovskaja oblast'`,
    ['RU-KK']: $localize`Hakasija, Respublika`,
    ['RU-KL']: $localize`Kalmykija, Respublika`,
    ['RU-KLU']: $localize`Kaluzhskaya oblast'`,
    ['RU-KO']: $localize`Komi, Respublika`,
    ['RU-KOS']: $localize`Kostromskaja oblast'`,
    ['RU-KR']: $localize`Karelija, Respublika`,
    ['RU-KRS']: $localize`Kurskaja oblast'`,
    ['RU-KYA']: $localize`Krasnojarskij kraj`,
    ['RU-LEN']: $localize`Leningradskaja oblast'`,
    ['RU-LIP']: $localize`Lipeckaja oblast'`,
    ['RU-MAG']: $localize`Magadanskaja oblast'`,
    ['RU-ME']: $localize`Marij Èl, Respublika`,
    ['RU-MO']: $localize`Mordovija, Respublika`,
    ['RU-MOS']: $localize`Moskovskaja oblast'`,
    ['RU-MOW']: $localize`Moskva`,
    ['RU-MUR']: $localize`Murmanskaja oblast'`,
    ['RU-NEN']: $localize`Neneckij avtonomnyj okrug`,
    ['RU-NGR']: $localize`Novgorodskaja oblast'`,
    ['RU-NIZ']: $localize`Nižegorodskaja oblast'`,
    ['RU-NVS']: $localize`Novosibirskaja oblast'`,
    ['RU-OMS']: $localize`Omskaja oblast'`,
    ['RU-ORE']: $localize`Orenburgskaja oblast'`,
    ['RU-ORL']: $localize`Orlovskaja oblast'`,
    ['RU-PER']: $localize`Permskij kraj`,
    ['RU-PNZ']: $localize`Penzenskaja oblast'`,
    ['RU-PRI']: $localize`Primorskij kraj`,
    ['RU-PSK']: $localize`Pskovskaja oblast'`,
    ['RU-ROS']: $localize`Rostovskaja oblast'`,
    ['RU-RYA']: $localize`Rjazanskaja oblast'`,
    ['RU-SAK']: $localize`Sahalinskaja oblast'`,
    ['RU-SAM']: $localize`Samarskaja oblast'`,
    ['RU-SAR']: $localize`Saratovskaja oblast'`,
    ['RU-SA']: $localize`Saha, Respublika`,
    ['RU-SE']: $localize`Severnaja Osetija, Respublika`,
    ['RU-SMO']: $localize`Smolenskaja oblast'`,
    ['RU-SPE']: $localize`Sankt-Peterburg`,
    ['RU-STA']: $localize`Stavropol'skij kraj`,
    ['RU-SVE']: $localize`Sverdlovskaja oblast'`,
    ['RU-TAM']: $localize`Tambovskaja oblast'`,
    ['RU-TA']: $localize`Tatarstan, Respublika`,
    ['RU-TOM']: $localize`Tomskaja oblast'`,
    ['RU-TUL']: $localize`Tul'skaja oblast'`,
    ['RU-TVE']: $localize`Tverskaja oblast'`,
    ['RU-TY']: $localize`Tyva, Respublika`,
    ['RU-TYU']: $localize`Tjumenskaja oblast'`,
    ['RU-UD']: $localize`Udmurtskaja Respublika`,
    ['RU-ULY']: $localize`Ul'janovskaja oblast'`,
    ['RU-VGG']: $localize`Volgogradskaja oblast'`,
    ['RU-VLA']: $localize`Vladimirskaja oblast'`,
    ['RU-VLG']: $localize`Vologodskaja oblast'`,
    ['RU-VOR']: $localize`Voronezhskaya oblast'`,
    ['RU-YAN']: $localize`Jamalo-Neneckij avtonomnyj okrug`,
    ['RU-YAR']: $localize`Jaroslavskaja oblast'`,
    ['RU-YEV']: $localize`Evrejskaja avtonomnaja oblast'`,
    ['RU-ZAB']: $localize`Zabajkal'skij kraj`,
    ['RW-01']: $localize`Ville de Kigal`,
    ['RW-02']: $localize`Est`,
    ['RW-03']: $localize`Nord`,
    ['RW-04']: $localize`Ouest`,
    ['RW-05']: $localize`Sud`,
    ['SH-AC']: $localize`Ascension`,
    ['SH-HL']: $localize`Saint Helena`,
    ['SH-TA']: $localize`Tristan da Cunha`,
    ['KN-01']: $localize`Christ Church Nichola Town`,
    ['KN-02']: $localize`Saint Anne Sandy Point`,
    ['KN-03']: $localize`Saint George Basseterre`,
    ['KN-04']: $localize`Saint George Gingerland`,
    ['KN-05']: $localize`Saint James Windward`,
    ['KN-06']: $localize`Saint John Capisterre`,
    ['KN-07']: $localize`Saint John Figtree`,
    ['KN-08']: $localize`Saint Mary Cayon`,
    ['KN-09']: $localize`Saint Paul Capisterre`,
    ['KN-10']: $localize`Saint Paul Charlestown`,
    ['KN-11']: $localize`Saint Peter Basseterre`,
    ['KN-12']: $localize`Saint Thomas Lowland`,
    ['KN-13']: $localize`Saint Thomas Middle Island`,
    ['KN-15']: $localize`Trinity Palmetto Point`,
    ['KN-K']: $localize`Saint Kitts`,
    ['KN-N']: $localize`Nevis`,
    ['LC-01']: $localize`Anse la Raye`,
    ['LC-02']: $localize`Castries`,
    ['LC-03']: $localize`Choiseul`,
    ['LC-05']: $localize`Dennery`,
    ['LC-06']: $localize`Gros Islet`,
    ['LC-07']: $localize`Laborie`,
    ['LC-08']: $localize`Micoud`,
    ['LC-10']: $localize`Soufrière`,
    ['LC-11']: $localize`Vieux Fort`,
    ['LC-12']: $localize`Canaries`,
    ['VC-01']: $localize`Charlotte`,
    ['VC-02']: $localize`Saint Andrew`,
    ['VC-03']: $localize`Saint David`,
    ['VC-04']: $localize`Saint George`,
    ['VC-05']: $localize`Saint Patrick`,
    ['VC-06']: $localize`Grenadines`,
    ['WS-AA']: $localize`A'ana`,
    ['WS-AL']: $localize`Aiga-i-le-Tai`,
    ['WS-AT']: $localize`Atua`,
    ['WS-FA']: $localize`Fa'asaleleaga`,
    ['WS-GE']: $localize`Gaga'emauga`,
    ['WS-GI']: $localize`Gagaifomauga`,
    ['WS-PA']: $localize`Palauli`,
    ['WS-SA']: $localize`Satupa'itea`,
    ['WS-TU']: $localize`Tuamasaga`,
    ['WS-VF']: $localize`Va'a-o-Fonoti`,
    ['WS-VS']: $localize`Vaisigano`,
    ['SM-01']: $localize`Acquaviva`,
    ['SM-02']: $localize`Chiesanuova`,
    ['SM-03']: $localize`Domagnano`,
    ['SM-04']: $localize`Faetano`,
    ['SM-05']: $localize`Fiorentino`,
    ['SM-06']: $localize`Borgo Maggiore`,
    ['SM-07']: $localize`San Marino`,
    ['SM-08']: $localize`Montegiardino`,
    ['SM-09']: $localize`Serravalle`,
    ['ST-P']: $localize`Príncipe`,
    ['ST-S']: $localize`São Tomé`,
    ['SA-01']: $localize`Ar Riyāḑ`,
    ['SA-02']: $localize`Makkah al Mukarramah`,
    ['SA-03']: $localize`Al Madīnah al Munawwarah`,
    ['SA-04']: $localize`Ash Sharqīyah`,
    ['SA-05']: $localize`Al Qaşīm`,
    ['SA-06']: $localize`Ḩā'il`,
    ['SA-07']: $localize`Tabūk`,
    ['SA-08']: $localize`Al Ḩudūd ash Shamālīyah`,
    ['SA-09']: $localize`Jāzān`,
    ['SA-10']: $localize`Najrān`,
    ['SA-11']: $localize`Al Bāḩah`,
    ['SA-12']: $localize`Al Jawf`,
    ['SA-14']: $localize`'Asīr`,
    ['SN-DB']: $localize`Diourbel`,
    ['SN-DK']: $localize`Dakar`,
    ['SN-FK']: $localize`Fatick`,
    ['SN-KA']: $localize`Kaffrine`,
    ['SN-KD']: $localize`Kolda`,
    ['SN-KE']: $localize`Kédougou`,
    ['SN-KL']: $localize`Kaolack`,
    ['SN-LG']: $localize`Louga`,
    ['SN-MT']: $localize`Matam`,
    ['SN-SE']: $localize`Sédhiou`,
    ['SN-SL']: $localize`Saint-Louis`,
    ['SN-TC']: $localize`Tambacounda`,
    ['SN-TH']: $localize`Thiès`,
    ['SN-ZG']: $localize`Ziguinchor`,
    ['RS-00']: $localize`Beograd`,
    ['RS-01']: $localize`Severnobački okrug`,
    ['RS-02']: $localize`Srednjebanatski okrug`,
    ['RS-03']: $localize`Severnobanatski okrug`,
    ['RS-04']: $localize`Južnobanatski okrug`,
    ['RS-05']: $localize`Zapadnobački okrug`,
    ['RS-06']: $localize`Južnobački okrug`,
    ['RS-07']: $localize`Sremski okrug`,
    ['RS-08']: $localize`Mačvanski okrug`,
    ['RS-09']: $localize`Kolubarski okrug`,
    ['RS-10']: $localize`Podunavski okrug`,
    ['RS-11']: $localize`Braničevski okrug`,
    ['RS-12']: $localize`Šumadijski okrug`,
    ['RS-13']: $localize`Pomoravski okrug`,
    ['RS-14']: $localize`Borski okrug`,
    ['RS-15']: $localize`Zaječarski okrug`,
    ['RS-16']: $localize`Zlatiborski okrug`,
    ['RS-17']: $localize`Moravički okrug`,
    ['RS-18']: $localize`Raški okrug`,
    ['RS-19']: $localize`Rasinski okrug`,
    ['RS-20']: $localize`Nišavski okrug`,
    ['RS-21']: $localize`Toplički okrug`,
    ['RS-22']: $localize`Pirotski okrug`,
    ['RS-23']: $localize`Jablanički okrug`,
    ['RS-24']: $localize`Pčinjski okrug`,
    ['RS-25']: $localize`Kosovski okrug`,
    ['RS-26']: $localize`Pećki okrug`,
    ['RS-27']: $localize`Prizrenski okrug`,
    ['RS-28']: $localize`Kosovsko-Mitrovački okrug`,
    ['RS-29']: $localize`Kosovsko-Pomoravski okrug`,
    ['RS-KM']: $localize`Kosovo-Metohija`,
    ['RS-VO']: $localize`Vojvodina`,
    ['SC-01']: $localize`Anse aux Pins`,
    ['SC-02']: $localize`Ans Bwalo`,
    ['SC-03']: $localize`Anse Etoile`,
    ['SC-04']: $localize`Au Cap`,
    ['SC-05']: $localize`Anse Royale`,
    ['SC-06']: $localize`Baie Lazare`,
    ['SC-07']: $localize`Baie Sainte-Anne`,
    ['SC-08']: $localize`Beau Vallon`,
    ['SC-09']: $localize`Bel Air`,
    ['SC-10']: $localize`Bel Ombre`,
    ['SC-11']: $localize`Cascade`,
    ['SC-12']: $localize`Glacis`,
    ['SC-13']: $localize`Grand'Anse Mahé`,
    ['SC-14']: $localize`Grand'Anse Praslin`,
    ['SC-15']: $localize`Ladig`,
    ['SC-16']: $localize`English River`,
    ['SC-17']: $localize`Mon Bikston`,
    ['SC-18']: $localize`Mon Fleri`,
    ['SC-19']: $localize`Plaisance`,
    ['SC-20']: $localize`Pointe La Rue`,
    ['SC-21']: $localize`Porglo`,
    ['SC-22']: $localize`Saint Louis`,
    ['SC-23']: $localize`Takamaka`,
    ['SC-24']: $localize`Lemamel`,
    ['SC-25']: $localize`Roche Caïman`,
    ['GS-GS']: $localize`S.Georgia & S.Sandwich Islands`,
    ['SL-E']: $localize`Eastern`,
    ['SL-N']: $localize`Northern`,
    ['SL-S']: $localize`Southern`,
    ['SL-W']: $localize`Western Area`,
    ['SG-01']: $localize`Central Singapore`,
    ['SG-02']: $localize`North East`,
    ['SG-03']: $localize`North West`,
    ['SG-04']: $localize`South East`,
    ['SG-05']: $localize`South West`,
    ['SK-BC']: $localize`Banskobystrický kraj`,
    ['SK-BL']: $localize`Bratislavský kraj`,
    ['SK-KI']: $localize`Košický kraj`,
    ['SK-NI']: $localize`Nitriansky kraj`,
    ['SK-PV']: $localize`Prešovský kraj`,
    ['SK-TA']: $localize`Trnavský kraj`,
    ['SK-TC']: $localize`Trenčiansky kraj`,
    ['SK-ZI']: $localize`Žilinský kraj`,
    ['SI-001']: $localize`Ajdovščina`,
    ['SI-002']: $localize`Beltinci`,
    ['SI-003']: $localize`Bled`,
    ['SI-004']: $localize`Bohinj`,
    ['SI-005']: $localize`Borovnica`,
    ['SI-006']: $localize`Bovec`,
    ['SI-007']: $localize`Brda`,
    ['SI-008']: $localize`Brezovica`,
    ['SI-009']: $localize`Brežice`,
    ['SI-010']: $localize`Tišina`,
    ['SI-011']: $localize`Celje`,
    ['SI-012']: $localize`Cerklje na Gorenjskem`,
    ['SI-013']: $localize`Cerknica`,
    ['SI-014']: $localize`Cerkno`,
    ['SI-015']: $localize`Črenšovci`,
    ['SI-016']: $localize`Črna na Koroškem`,
    ['SI-017']: $localize`Črnomelj`,
    ['SI-018']: $localize`Destrnik`,
    ['SI-019']: $localize`Divača`,
    ['SI-020']: $localize`Dobrepolje`,
    ['SI-021']: $localize`Dobrova-Polhov Gradec`,
    ['SI-022']: $localize`Dol pri Ljubljani`,
    ['SI-023']: $localize`Domžale`,
    ['SI-024']: $localize`Dornava`,
    ['SI-025']: $localize`Dravograd`,
    ['SI-026']: $localize`Duplek`,
    ['SI-027']: $localize`Gorenja vas-Poljane`,
    ['SI-028']: $localize`Gorišnica`,
    ['SI-029']: $localize`Gornja Radgona`,
    ['SI-030']: $localize`Gornji Grad`,
    ['SI-031']: $localize`Gornji Petrovci`,
    ['SI-032']: $localize`Grosuplje`,
    ['SI-033']: $localize`Šalovci`,
    ['SI-034']: $localize`Hrastnik`,
    ['SI-035']: $localize`Hrpelje-Kozina`,
    ['SI-036']: $localize`Idrija`,
    ['SI-037']: $localize`Ig`,
    ['SI-038']: $localize`Ilirska Bistrica`,
    ['SI-039']: $localize`Ivančna Gorica`,
    ['SI-040']: $localize`Izola`,
    ['SI-041']: $localize`Jesenice`,
    ['SI-042']: $localize`Juršinci`,
    ['SI-043']: $localize`Kamnik`,
    ['SI-044']: $localize`Kanal`,
    ['SI-045']: $localize`Kidričevo`,
    ['SI-046']: $localize`Kobarid`,
    ['SI-047']: $localize`Kobilje`,
    ['SI-048']: $localize`Kočevje`,
    ['SI-049']: $localize`Komen`,
    ['SI-050']: $localize`Koper`,
    ['SI-051']: $localize`Kozje`,
    ['SI-052']: $localize`Kranj`,
    ['SI-053']: $localize`Kranjska Gora`,
    ['SI-054']: $localize`Krško`,
    ['SI-055']: $localize`Kungota`,
    ['SI-056']: $localize`Kuzma`,
    ['SI-057']: $localize`Laško`,
    ['SI-058']: $localize`Lenart`,
    ['SI-059']: $localize`Lendava`,
    ['SI-060']: $localize`Litija`,
    ['SI-061']: $localize`Ljubljana`,
    ['SI-062']: $localize`Ljubno`,
    ['SI-063']: $localize`Ljutomer`,
    ['SI-064']: $localize`Logatec`,
    ['SI-065']: $localize`Loška Dolina`,
    ['SI-066']: $localize`Loški Potok`,
    ['SI-067']: $localize`Luče`,
    ['SI-068']: $localize`Lukovica`,
    ['SI-069']: $localize`Majšperk`,
    ['SI-070']: $localize`Maribor`,
    ['SI-071']: $localize`Medvode`,
    ['SI-072']: $localize`Mengeš`,
    ['SI-073']: $localize`Metlika`,
    ['SI-074']: $localize`Mežica`,
    ['SI-075']: $localize`Miren-Kostanjevica`,
    ['SI-076']: $localize`Mislinja`,
    ['SI-077']: $localize`Moravče`,
    ['SI-078']: $localize`Moravske Toplice`,
    ['SI-079']: $localize`Mozirje`,
    ['SI-080']: $localize`Murska Sobota`,
    ['SI-081']: $localize`Muta`,
    ['SI-082']: $localize`Naklo`,
    ['SI-083']: $localize`Nazarje`,
    ['SI-084']: $localize`Nova Gorica`,
    ['SI-085']: $localize`Novo Mesto`,
    ['SI-086']: $localize`Odranci`,
    ['SI-087']: $localize`Ormož`,
    ['SI-088']: $localize`Osilnica`,
    ['SI-089']: $localize`Pesnica`,
    ['SI-090']: $localize`Piran`,
    ['SI-091']: $localize`Pivka`,
    ['SI-092']: $localize`Podčetrtek`,
    ['SI-093']: $localize`Podvelka`,
    ['SI-094']: $localize`Postojna`,
    ['SI-095']: $localize`Preddvor`,
    ['SI-096']: $localize`Ptuj`,
    ['SI-097']: $localize`Puconci`,
    ['SI-098']: $localize`Rače-Fram`,
    ['SI-099']: $localize`Radeče`,
    ['SI-100']: $localize`Radenci`,
    ['SI-101']: $localize`Radlje ob Dravi`,
    ['SI-102']: $localize`Radovljica`,
    ['SI-103']: $localize`Ravne na Koroškem`,
    ['SI-104']: $localize`Ribnica`,
    ['SI-105']: $localize`Rogašovci`,
    ['SI-106']: $localize`Rogaška Slatina`,
    ['SI-107']: $localize`Rogatec`,
    ['SI-108']: $localize`Ruše`,
    ['SI-109']: $localize`Semič`,
    ['SI-110']: $localize`Sevnica`,
    ['SI-111']: $localize`Sežana`,
    ['SI-112']: $localize`Slovenj Gradec`,
    ['SI-113']: $localize`Slovenska Bistrica`,
    ['SI-114']: $localize`Slovenske Konjice`,
    ['SI-115']: $localize`Starše`,
    ['SI-116']: $localize`Sveti Jurij`,
    ['SI-117']: $localize`Šenčur`,
    ['SI-118']: $localize`Šentilj`,
    ['SI-119']: $localize`Šentjernej`,
    ['SI-120']: $localize`Šentjur`,
    ['SI-121']: $localize`Škocjan`,
    ['SI-122']: $localize`Škofja Loka`,
    ['SI-123']: $localize`Škofljica`,
    ['SI-124']: $localize`Šmarje pri Jelšah`,
    ['SI-125']: $localize`Šmartno ob Paki`,
    ['SI-126']: $localize`Šoštanj`,
    ['SI-127']: $localize`Štore`,
    ['SI-128']: $localize`Tolmin`,
    ['SI-129']: $localize`Trbovlje`,
    ['SI-130']: $localize`Trebnje`,
    ['SI-131']: $localize`Tržič`,
    ['SI-132']: $localize`Turnišče`,
    ['SI-133']: $localize`Velenje`,
    ['SI-134']: $localize`Velike Lašče`,
    ['SI-135']: $localize`Videm`,
    ['SI-136']: $localize`Vipava`,
    ['SI-137']: $localize`Vitanje`,
    ['SI-138']: $localize`Vodice`,
    ['SI-139']: $localize`Vojnik`,
    ['SI-140']: $localize`Vrhnika`,
    ['SI-141']: $localize`Vuzenica`,
    ['SI-142']: $localize`Zagorje ob Savi`,
    ['SI-143']: $localize`Zavrč`,
    ['SI-144']: $localize`Zreče`,
    ['SI-146']: $localize`Železniki`,
    ['SI-147']: $localize`Žiri`,
    ['SI-148']: $localize`Benedikt`,
    ['SI-149']: $localize`Bistrica ob Sotli`,
    ['SI-150']: $localize`Bloke`,
    ['SI-151']: $localize`Braslovče`,
    ['SI-152']: $localize`Cankova`,
    ['SI-153']: $localize`Cerkvenjak`,
    ['SI-154']: $localize`Dobje`,
    ['SI-155']: $localize`Dobrna`,
    ['SI-156']: $localize`Dobrovnik`,
    ['SI-157']: $localize`Dolenjske Toplice`,
    ['SI-158']: $localize`Grad`,
    ['SI-159']: $localize`Hajdina`,
    ['SI-160']: $localize`Hoče-Slivnica`,
    ['SI-161']: $localize`Hodoš`,
    ['SI-162']: $localize`Horjul`,
    ['SI-163']: $localize`Jezersko`,
    ['SI-164']: $localize`Komenda`,
    ['SI-165']: $localize`Kostel`,
    ['SI-166']: $localize`Križevci`,
    ['SI-167']: $localize`Lovrenc na Pohorju`,
    ['SI-168']: $localize`Markovci`,
    ['SI-169']: $localize`Miklavž na Dravskem Polju`,
    ['SI-170']: $localize`Mirna Peč`,
    ['SI-171']: $localize`Oplotnica`,
    ['SI-172']: $localize`Podlehnik`,
    ['SI-173']: $localize`Polzela`,
    ['SI-174']: $localize`Prebold`,
    ['SI-175']: $localize`Prevalje`,
    ['SI-176']: $localize`Razkrižje`,
    ['SI-177']: $localize`Ribnica na Pohorju`,
    ['SI-178']: $localize`Selnica ob Dravi`,
    ['SI-179']: $localize`Sodražica`,
    ['SI-180']: $localize`Solčava`,
    ['SI-181']: $localize`Sveta Ana`,
    ['SI-182']: $localize`Sveti Andraž v Slovenskih Goricah`,
    ['SI-183']: $localize`Šempeter-Vrtojba`,
    ['SI-184']: $localize`Tabor`,
    ['SI-185']: $localize`Trnovska Vas`,
    ['SI-186']: $localize`Trzin`,
    ['SI-187']: $localize`Velika Polana`,
    ['SI-188']: $localize`Veržej`,
    ['SI-189']: $localize`Vransko`,
    ['SI-190']: $localize`Žalec`,
    ['SI-191']: $localize`Žetale`,
    ['SI-192']: $localize`Žirovnica`,
    ['SI-193']: $localize`Žužemberk`,
    ['SI-194']: $localize`Šmartno pri Litiji`,
    ['SI-195']: $localize`Apače`,
    ['SI-196']: $localize`Cirkulane`,
    ['SI-197']: $localize`Kosanjevica na Krki`,
    ['SI-198']: $localize`Makole`,
    ['SI-199']: $localize`Mokronog-Trebelno`,
    ['SI-200']: $localize`Poljčane`,
    ['SI-201']: $localize`Renče-Vogrsko`,
    ['SI-202']: $localize`Središče ob Dravi`,
    ['SI-203']: $localize`Straža`,
    ['SI-204']: $localize`Sveta Trojica v Slovenskih Goricah`,
    ['SI-205']: $localize`Sveti Tomaž`,
    ['SI-206']: $localize`Šmarješke Toplice`,
    ['SI-207']: $localize`Gorje`,
    ['SI-208']: $localize`Log-Dragomer`,
    ['SI-209']: $localize`Rečica ob Savinji`,
    ['SI-210']: $localize`Sveti Jurij v Slovenskih Goricah`,
    ['SI-211']: $localize`Šentrupert`,
    ['SI-212']: $localize`Mirna`,
    ['SB-CE']: $localize`Central`,
    ['SB-CH']: $localize`Choiseul`,
    ['SB-CT']: $localize`Capital Territory`,
    ['SB-GU']: $localize`Guadalcanal`,
    ['SB-IS']: $localize`Isabel`,
    ['SB-MK']: $localize`Makira-Ulawa`,
    ['SB-ML']: $localize`Malaita`,
    ['SB-RB']: $localize`Rennell and Bellona`,
    ['SB-TE']: $localize`Temotu`,
    ['SB-WE']: $localize`Western`,
    ['SO-AW']: $localize`Awdal`,
    ['SO-BK']: $localize`Bakool`,
    ['SO-BN']: $localize`Banaadir`,
    ['SO-BR']: $localize`Bari`,
    ['SO-BY']: $localize`Bay`,
    ['SO-GA']: $localize`Galguduud`,
    ['SO-GE']: $localize`Gedo`,
    ['SO-HI']: $localize`Hiiraan`,
    ['SO-JD']: $localize`Jubbada Dhexe`,
    ['SO-JH']: $localize`Jubbada Hoose`,
    ['SO-MU']: $localize`Mudug`,
    ['SO-NU']: $localize`Nugaal`,
    ['SO-SA']: $localize`Sanaag`,
    ['SO-SD']: $localize`Shabeellaha Dhexe`,
    ['SO-SH']: $localize`Shabeellaha Hoose`,
    ['SO-SO']: $localize`Sool`,
    ['SO-TO']: $localize`Togdheer`,
    ['SO-WO']: $localize`Woqooyi Galbeed`,
    ['ZA-EC']: $localize`Eastern Cape`,
    ['ZA-FS']: $localize`Foreisetata`,
    ['ZA-GT']: $localize`Gauteng`,
    ['ZA-LP']: $localize`Limpopo`,
    ['ZA-MP']: $localize`iMpumalanga`,
    ['ZA-NC']: $localize`iTlhagwini-Kapa`,
    ['ZA-NL']: $localize`GaZulu-Natala`,
    ['ZA-NW']: $localize`Bokone Bophirima`,
    ['ZA-WC']: $localize`iTjhingalanga-Kapa`,
    ['SS-BN']: $localize`Northern Bahr el Ghazal`,
    ['SS-BW']: $localize`Western Bahr el Ghazal`,
    ['SS-EC']: $localize`Central Equatoria`,
    ['SS-EE']: $localize`Eastern Equatoria`,
    ['SS-EW']: $localize`Western Equatoria`,
    ['SS-JG']: $localize`Jonglei`,
    ['SS-LK']: $localize`Lakes`,
    ['SS-NU']: $localize`Upper Nile`,
    ['SS-UY']: $localize`Unity`,
    ['SS-WR']: $localize`Warrap`,
    ['ES-A']: $localize`Alacant*`,
    ['ES-AB']: $localize`Albacete`,
    ['ES-AL']: $localize`Almería`,
    ['ES-AN']: $localize`Andalucía`,
    ['ES-AR']: $localize`Aragón`,
    ['ES-AS']: $localize`Asturias, Principado de`,
    ['ES-AV']: $localize`Ávila`,
    ['ES-BA']: $localize`Badajoz`,
    ['ES-B']: $localize`Barcelona`,
    ['ES-BI']: $localize`Bizkaia*`,
    ['ES-BU']: $localize`Burgos`,
    ['ES-CA']: $localize`Cádiz`,
    ['ES-C']: $localize`A Coruña`,
    ['ES-CB']: $localize`Cantabria`,
    ['ES-CC']: $localize`Cáceres`,
    ['ES-CE']: $localize`Ceuta`,
    ['ES-CL']: $localize`Castilla y León`,
    ['ES-CM']: $localize`Castilla-La Mancha`,
    ['ES-CN']: $localize`Canarias`,
    ['ES-CO']: $localize`Córdoba`,
    ['ES-CR']: $localize`Ciudad Real`,
    ['ES-CS']: $localize`Castelló*`,
    ['ES-CT']: $localize`Catalunya`,
    ['ES-CU']: $localize`Cuenca`,
    ['ES-EX']: $localize`Extremadura`,
    ['ES-GA']: $localize`Galicia`,
    ['ES-GC']: $localize`Las Palmas`,
    ['ES-GI']: $localize`Girona`,
    ['ES-GR']: $localize`Granada`,
    ['ES-GU']: $localize`Guadalajara`,
    ['ES-H']: $localize`Huelva`,
    ['ES-HU']: $localize`Huesca`,
    ['ES-IB']: $localize`Illes Balears`,
    ['ES-J']: $localize`Jaén`,
    ['ES-LE']: $localize`León`,
    ['ES-L']: $localize`Lleida`,
    ['ES-LO']: $localize`La Rioja`,
    ['ES-LU']: $localize`Lugo`,
    ['ES-MA']: $localize`Málaga`,
    ['ES-MC']: $localize`Murcia, Región de`,
    ['ES-MD']: $localize`Madrid, Comunidad de`,
    ['ES-ML']: $localize`Melilla`,
    ['ES-M']: $localize`Madrid`,
    ['ES-MU']: $localize`Murcia`,
    ['ES-NA']: $localize`Nafarroa*`,
    ['ES-NC']: $localize`Nafarroako Foru Komunitatea*`,
    ['ES-O']: $localize`Asturias`,
    ['ES-OR']: $localize`Ourense`,
    ['ES-PM']: $localize`Balears`,
    ['ES-PO']: $localize`Pontevedra`,
    ['ES-P']: $localize`Palencia`,
    ['ES-PV']: $localize`Euskal Herria`,
    ['ES-RI']: $localize`La Rioja`,
    ['ES-SA']: $localize`Salamanca`,
    ['ES-S']: $localize`Cantabria`,
    ['ES-SE']: $localize`Sevilla`,
    ['ES-SG']: $localize`Segovia`,
    ['ES-SO']: $localize`Soria`,
    ['ES-SS']: $localize`Gipuzkoa*`,
    ['ES-TE']: $localize`Teruel`,
    ['ES-TF']: $localize`Santa Cruz de Tenerife`,
    ['ES-TO']: $localize`Toledo`,
    ['ES-T']: $localize`Tarragona`,
    ['ES-VA']: $localize`Valladolid`,
    ['ES-VC']: $localize`Valenciana, Comunidad`,
    ['ES-VI']: $localize`Álava`,
    ['ES-V']: $localize`Valencia`,
    ['ES-ZA']: $localize`Zamora`,
    ['ES-Z']: $localize`Zaragoza`,
    ['LK-11']: $localize`Colombo`,
    ['LK-12']: $localize`Gampaha`,
    ['LK-13']: $localize`Kalutara`,
    ['LK-1']: $localize`Basnāhira paḷāta`,
    ['LK-21']: $localize`Kandy`,
    ['LK-22']: $localize`Matale`,
    ['LK-23']: $localize`Nuvara Ĕliya`,
    ['LK-2']: $localize`Central Province`,
    ['LK-31']: $localize`Gālla`,
    ['LK-32']: $localize`Matara`,
    ['LK-33']: $localize`Ampāntōṭṭai`,
    ['LK-3']: $localize`Dakuṇu paḷāta`,
    ['LK-41']: $localize`Jaffna`,
    ['LK-42']: $localize`Kilinochchi`,
    ['LK-43']: $localize`Mannar`,
    ['LK-44']: $localize`Vavuniya`,
    ['LK-45']: $localize`Mulativ`,
    ['LK-4']: $localize`Northern Province`,
    ['LK-51']: $localize`Batticaloa`,
    ['LK-52']: $localize`Ampara`,
    ['LK-53']: $localize`Tirukŏṇamalai`,
    ['LK-5']: $localize`Eastern Province`,
    ['LK-61']: $localize`Kuruṇægala`,
    ['LK-62']: $localize`Puttalam`,
    ['LK-6']: $localize`North Western Province`,
    ['LK-71']: $localize`Anuradhapura`,
    ['LK-72']: $localize`Pŏlaṉṉaṛuvai`,
    ['LK-7']: $localize`North Central Province`,
    ['LK-81']: $localize`Badulla`,
    ['LK-82']: $localize`Monaragala`,
    ['LK-8']: $localize`Ūvā mākāṇam`,
    ['LK-91']: $localize`Irattiṉapuri`,
    ['LK-92']: $localize`Kægalla`,
    ['LK-9']: $localize`Chappirakamuva mākāṇam`,
  };
}
