export enum UserRole {
  MerchantAdmin = 'MERCHANT_ADMIN',
  MerchantReviewer = 'MERCHANT_REVIEWER',
  MerchantSupervisor = 'MERCHANT_SUPERVISOR',
  MerchantCashier = 'MERCHANT_CASHIER',
  MerchantUser = 'MERCHANT_USER',
  MerchantUnapproved = 'MERCHANT_UNAPPROVED',
  MerchantPOIAdmin = 'MERCHANT_POI_ADMIN',
  VerifoneAdmin = 'VERIFONE_ADMIN',
  VerifoneUser = 'VERIFONE_USER',
  VerifoneHelpdesk = 'VERIFONE_HELPDESK',
  VerifoneFinOpsUser = 'VERIFONE_FINOPS_USER',
  VerifoneSettlementUser = 'VERIFONE_SETTLEMENT_USER',
  DeveloperAdmin = 'DEVELOPER_ADMIN',
  DeveloperUser = 'DEVELOPER_USER',
  EstateOwnerAdmin = 'ESTATE_OWNER_ADMIN',
  EstateOwnerUser = 'ESTATE_OWNER_USER',
  EstateOwnerDeviceAppManager = 'ESTATE_OWNER_DEVICE_APP_MANAGER',
  EstateOwnerSupportAdmin = 'ESTATE_OWNER_SUPPORT_ADMIN',
  VerifoneSalesManager = 'VERIFONE_SALES_MANAGER',
  VerifoneSalesRepresentative = 'VERIFONE_SALES_REPRESENTATIVE',
  ResellerManager = 'RESELLER_MANAGER',
  ResellerRepresentative = 'RESELLER_REPRESENTATIVE',
  PartnerHelpdesk = 'PARTNER_HELPDESK',
  VerifoneFieldServiceProvider = 'VERIFONE_FIELD_SERVICE_PROVIDER',
  VerifoneFieldServiceSupervisor = 'VERIFONE_FIELD_SERVICE_SUPERVISOR',
  PartnerFieldServiceProvider = 'PARTNER_FIELD_SERVICE_PROVIDER',
  PartnerFieldServiceSupervisor = 'PARTNER_FIELD_SERVICE_SUPERVISOR',
  PartnerFieldServiceUser = 'PARTNER_FIELD_SERVICE_USER',
  PetroSiteAdmin = 'PETRO_SITE_ADMIN',
  MajorOilAdmin = 'MAJOR_OIL_ADMIN',
  MajorOilUser = 'MAJOR_OIL_USER',
  PetroHelpDeskAdmin = 'PETRO_HELPDESK_ADMIN',
  PetroSiteUser = 'PETRO_SITE_USER',
  PetroPartnerAdmin = 'PETRO_PARTNER_ADMIN',
  PetroSalesforceAdmin = 'PETRO_SALESFORCE_ADMIN',
  PetroMerchantOnboarder = 'PETRO_MERCHANT_ONBOARDER',
  CommanderOnboarder = 'COMMANDER_ONBOARDER',
  MerchantOrderAdmin = 'MERCHANT_ORDER_ADMIN',
  ProviderWhiteLabelManager = 'PROVIDER_WHITE_LABEL_MANAGER',
  SystemOnboardingBackend = 'SYSTEM_ONBOARDING_BACKEND',
  MerchantExternalPartner = 'MERCHANT_EXTERNAL_PARTNER',
  VerifoneMaster = 'VERIFONE_MASTER',
  PetroLoyalty = 'PETRO_LOYALTY',
  PetroMobilePayments = 'PETRO_MOBILE_PAYMENTS',
  PetroBackOffice = 'PETRO_BACK_OFFICE',
  PetroFuelPriceSign = 'PETRO_FUEL_PRICE_SIGN',
  PetroDvr = 'PETRO_DVR',
  PetroPropietaryCard = 'PETRO_PROPIETARY_CARD',
  PetroCommanderEF = 'PETRO_COMMANDER_EF',
  PetroInternal = 'PETRO_INTERNAL',
  /* Temporarily removed @CPDB-2268
  ProviderAdmin = 'PROVIDER_ADMIN',
  ProviderUser = 'PROVIDER_USER',*/
}
