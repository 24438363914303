import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '@portal/shared/auth/authentication';

@Injectable()
export class SelfCheckService {
  constructor(private authenticationService: AuthenticationService) {}

  // Check if the user is trying to see his own page
  isSelfPage(route: ActivatedRouteSnapshot): boolean {
    const loggedInUserId = this.authenticationService.userUid;
    const userIdInParam = route.paramMap.get('id');

    return loggedInUserId === userIdInParam;
  }

  isMyProfilePage(route: ActivatedRouteSnapshot): boolean {
    return this.isSelfPage(route) && route.routeConfig?.path === 'administration/users/:id';
  }
}
