export enum SortOrder {
  None = 'NONE',
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SortType {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}
