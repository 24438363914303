import { EventEmitter, Input, OnDestroy, OnInit, Output, Directive } from '@angular/core';
import { cloneDeep, filter } from 'lodash';
import { of, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ISelectInput } from '../box/interfaces/select-input.interface';
import { FilterContentItem } from '../content/item';
import { IFilterToApply } from '../search/interfaces/filter-to-apply.interface';

@Directive()
export abstract class BaseFilter extends FilterContentItem implements OnInit, OnDestroy {
  @Output() filterApplied: EventEmitter<any> = new EventEmitter();
  @Output() filterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();
  @Output() selectedFilterValue: EventEmitter<string> = new EventEmitter();
  @Input() selectedEntities: any;
  @Input() selector: string;
  @Input() filterName: string;
  @Input() searchInput = false;
  @Input() selectedValue: string;
  @Input() entities: ISelectInput[];
  @Input() entityRemoved$ = new Subject<ISelectInput>();
  @Input() closeSubmenus$ = new Subject<boolean>();

  itemToggled: EventEmitter<number> = new EventEmitter();
  isOpen = false;

  searchedText: string;
  localPreSelectedEntities: Map<string, ISelectInput> = new Map();
  showSelectedEntities: Map<string, ISelectInput> = new Map();

  backToMenuSubject$ = new Subject<void>();
  backToMenuSub: Subscription;

  private allEntities: ISelectInput[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.allEntities = cloneDeep(this.entities);
    this.entityRemoved$.subscribe((entityToRemove: ISelectInput) => {
      const selectedEntity = this.localPreSelectedEntities.get(entityToRemove.id);
      if (selectedEntity) {
        this.onEntityRemoved(entityToRemove);
        this.onFilterApplied();
        this.itemToggled.emit();
      }
    });

    this.backToMenuSub = this.backToMenuSubject$.pipe(debounceTime(100)).subscribe(() => {
      const element = document.getElementById('add-filter');
      if (element) {
        element.click();
      }
    });

    this.closeSubmenus$.subscribe((close: boolean) => {
      if (close && this.isOpen) {
        this.itemToggled.emit();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.backToMenuSub) {
      this.backToMenuSub.unsubscribe();
    }
  }

  onFilterApplied(): void {
    this.updateEntities();

    const values: string[] = this.getValues();
    if (values.length) {
      this.filterUpdated.emit({
        operator: 'eq',
        value: Array.from(this.localPreSelectedEntities.values()),
        argument: values.join(','),
        selector: this.selector,
        key: values.map((value) => `${this.selector}-${value}`).join(','),
      });
    } else {
      this.filterUpdated.emit({
        key: this.selector,
        selector: this.selector,
        toRemove: true,
      });
    }

    this.filterApplied.emit();
    this.itemToggled.emit();
  }

  toggleFilter(): void {
    this.isOpen = !this.isOpen;
  }

  onSearch(value: string): void {
    if (!value) {
      this.entities = this.allEntities;
      this.searchedText = null;
    } else {
      this.entities = this.allEntities;
      this.searchedText = value;
      this.entities = filter(this.entities, (entity: ISelectInput) => {
        return entity.text.toLowerCase().includes(value.toLowerCase());
      });
    }
    this.updateEntities();
  }

  updateEntities(): void {
    this.showSelectedEntities = cloneDeep(this.localPreSelectedEntities);
    const entitiesToExclude = Array.from(this.localPreSelectedEntities.keys());
    this.entities = filter(this.entities, (entity: ISelectInput) => {
      return !entitiesToExclude.includes(entity.id);
    });
  }

  openContent(): void {
    this.itemToggled.emit();
    this.onSearch('');
  }

  backToMenu(): void {
    this.itemToggled.emit();
    this.backToMenuSubject$.next();
  }

  onEntityRemoved(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.delete(selectedEntity.id);
  }

  onEntityAdded(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.set(selectedEntity.id, selectedEntity);
  }

  getList(): Observable<ISelectInput[]> {
    return of(this.entities);
  }

  getValues(): string[] {
    const values = new Set<string>();
    Array.from(this.localPreSelectedEntities.values()).forEach((val: ISelectInput) => {
      values.add(val.id);
    });
    return Array.from(values);
  }
}
