import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SingleDropdownComponent } from './single-dropdown.component';
import { ClickOutsideModule } from '@portal/shared/ui/click-outside/src';

@NgModule({
  imports: [CommonModule, ClickOutsideModule],
  declarations: [SingleDropdownComponent],
  exports: [SingleDropdownComponent],
})
export class SingleDropdownModule {}
