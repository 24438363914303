import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  IFilterToApply,
  ISearchCriteria,
  SearchSelector,
  ISelectInput,
} from '@portal/shared/ui/filter';
import {
  addMilliseconds,
  differenceInMilliseconds,
  endOfDay,
  isBefore,
  startOfDay,
} from 'date-fns';
import { ComparableDateService } from './services/date.service';
import { QuickPicks } from './enums/date.enum';
import { IDateFilter } from '@portal/shared/helpers';
import { IQueryParams } from '@portal/shared/ui/table/src';
import { CompareToQuickpicks } from './lists/compate-to-quickpicks.list';
import { DateRangeQuickpicks } from './lists/date-range-quickpicks.list';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateRangeComponent } from './date-range/date-range.component';

declare const $localize;

@Component({
  selector: 'portal-comparable-date-range',
  templateUrl: './comparable-date-range.component.html',
})
export class ComparableDateRangeComponent implements OnInit {
  @Output()
  filterApplied: EventEmitter<Map<string, ISearchCriteria>> = new EventEmitter();
  @Input()
  isNewDesignFilter = false;
  @ViewChild(DateRangeComponent, { static: true })
  dateRangeComponent: DateRangeComponent;
  // Date Range & compare to
  selectedDateRangeQP: QuickPicks = QuickPicks.Last7Days;
  searchCriteriaCompareTo: Map<string, ISearchCriteria> = new Map();
  searchCriteriaDateRange: Map<string, ISearchCriteria> = new Map();
  searchCriteria: Map<string, ISearchCriteria>;
  dateRangeQuickPicksList: ISelectInput[];
  compareToQuickPicksList: ISelectInput[];
  dateRangeSelection: IDateFilter;
  customCompareToSelection: IDateFilter;
  filter: IQueryParams;
  dateRange = $localize`Date range`;
  compareTo = $localize`Compare to`;
  isCompareType = true;
  isCompareToBoxChecked = false;
  isValidDiffLength = true;
  isValidDateRange = true;
  isValidDateRangeFrom = true;
  isValidDateCompareTo = true;
  dateRangeDifferenceInDays: number;
  compareToDifferenceInDays: number;
  isDateRangesOverlapping: boolean;
  isStartDateBiggerThanEndDate: boolean;

  constructor(
    private comparableDateService: ComparableDateService,
    private compareToQuickpicks: CompareToQuickpicks,
    private dateRangeQuickpicks: DateRangeQuickpicks,
  ) {}

  ngOnInit(): void {
    this.searchCriteriaDateRange = this.getSearchDateObject(
      this.comparableDateService.getTodaysDateFilter().start,
      this.comparableDateService.getTodaysDateFilter().end,
      SearchSelector.DateRange,
    );
    this.searchCriteriaCompareTo = this.getSearchDateObject(
      this.comparableDateService.getYesterdaysDateFilter().start,
      this.comparableDateService.getYesterdaysDateFilter().end,
      SearchSelector.CompareTo,
    );
    this.dateRangeQuickPicksList = this.dateRangeQuickpicks.list;
    this.compareToQuickPicksList = this.compareToQuickpicks.list;

    this.searchCriteria = new Map([
      ...this.searchCriteriaDateRange,
      ...(this.isCompareToBoxChecked ? this.searchCriteriaCompareTo : []),
    ]);
    this.filterApplied.emit(this.searchCriteria);
  }

  focusSearchBox(): void {
    if (this.dateRangeComponent) {
      this.dateRangeComponent.setAutoFocusState();
    }
  }

  onCompareToQuickPickApplied(value: QuickPicks): void {
    const dateFilter = this.comparableDateService.getCompareToQPStartEndDate(
      value,
      this.selectedDateRangeQP,
      this.dateRangeSelection,
    );
    this.onCustomSelectionCompareTo(dateFilter);

    this.searchCriteriaCompareTo = this.getSearchDateObject(
      dateFilter.start,
      dateFilter.end,
      SearchSelector.CompareTo,
    );
  }

  onDateRangeQuickPickApplied(value: QuickPicks): void {
    this.selectedDateRangeQP = value;

    const dateFilterForDateRange = this.comparableDateService.getDateRangeQPStartEndDate(value);

    this.searchCriteriaDateRange = this.getSearchDateObject(
      dateFilterForDateRange.start,
      dateFilterForDateRange.end,
      SearchSelector.DateRange,
    );

    // Quick Pick methods can't bring overlapping and date range length differences
    this.dateRangeSelection = dateFilterForDateRange;
    this.dateRangeDifferenceInDays = this.getDifferenceInDays(dateFilterForDateRange);
  }

  onCompareToCheckedUpdated(isChecked: boolean): void {
    this.isCompareToBoxChecked = isChecked;
    if (this.isCompareToBoxChecked) {
      this.searchCriteria.set(`${SearchSelector.CompareTo}_${SearchSelector.FromDate}`, {
        argument: this.searchCriteriaCompareTo.get(
          `${SearchSelector.CompareTo}_${SearchSelector.FromDate}`,
        ).argument,
        selector: SearchSelector.FromDate,
        operator: '=',
      });
      this.searchCriteria.set(`${SearchSelector.CompareTo}_${SearchSelector.ToDate}`, {
        argument: this.searchCriteriaCompareTo.get(
          `${SearchSelector.CompareTo}_${SearchSelector.ToDate}`,
        ).argument,
        selector: SearchSelector.ToDate,
        operator: '=',
      });
      this.filterApplied.emit(this.searchCriteria);
    } else {
      this.searchCriteria.delete(`${SearchSelector.CompareTo}_${SearchSelector.FromDate}`);
      this.searchCriteria.delete(`${SearchSelector.CompareTo}_${SearchSelector.ToDate}`);
      this.filterApplied.emit(this.searchCriteria);
    }
  }

  onCompareToFilterApplied(filters?: IFilterToApply[]): void {
    const toDate = filters.find(
      (filter) => filter.key === `${SearchSelector.CompareTo}_${SearchSelector.ToDate}`,
    );
    const endDate = new Date(
      this.searchCriteria.get(`${SearchSelector.DateRange}_${SearchSelector.ToDate}`)?.argument,
    );

    // When endDate is today
    if (isBefore(endDate, endOfDay(endDate))) {
      const difference = differenceInMilliseconds(endDate, startOfDay(endDate));
      toDate.argument = addMilliseconds(
        startOfDay(new Date(toDate.argument)),
        difference,
      ).toISOString();
    }

    this.updateFilter(filters, this.searchCriteriaCompareTo);

    this.searchCriteria = new Map([...this.searchCriteria, ...this.searchCriteriaCompareTo]);
    this.filterApplied.emit(this.searchCriteria);
  }

  onDateRageFilterApplied(filters?: IFilterToApply[]): void {
    this.updateFilter(filters, this.searchCriteriaDateRange);

    const dateFilterForCompareTo = this.comparableDateService.getCompareToPreviousPeriod(
      this.selectedDateRangeQP,
      this.dateRangeSelection,
    );
    const endDate = new Date(
      this.searchCriteriaDateRange.get(
        `${SearchSelector.DateRange}_${SearchSelector.ToDate}`,
      )?.argument,
    );

    // When endDate is today
    if (isBefore(endDate, endOfDay(endDate))) {
      const difference = differenceInMilliseconds(endDate, startOfDay(endDate));
      dateFilterForCompareTo.end = addMilliseconds(
        startOfDay(new Date(dateFilterForCompareTo.end)),
        difference,
      ).toISOString();
    }

    this.searchCriteriaCompareTo = this.getSearchDateObject(
      dateFilterForCompareTo.start,
      dateFilterForCompareTo.end,
      SearchSelector.CompareTo,
    );

    this.searchCriteria = this.isCompareToBoxChecked
      ? new Map([
          ...this.searchCriteria,
          ...this.searchCriteriaDateRange,
          ...this.searchCriteriaCompareTo,
        ])
      : new Map([...this.searchCriteria, ...this.searchCriteriaDateRange]);

    this.filterApplied.emit(this.searchCriteria);
  }

  onCustomSelectionFromDateRange(dateFrom: Date): void {
    if (!this.dateRangeSelection) {
      return;
    }
    this.dateRangeSelection.start = startOfDay(dateFrom).toISOString();
    this.isValidDateRangeFrom =
      this.comparableDateService.checkValidDateRange(this.dateRangeSelection) &&
      !this.isDateBiggerThanCurrentDate(dateFrom);
  }

  isDateBiggerThanCurrentDate(date: Date): boolean {
    return this.comparableDateService.dateIsBiggerThanCurrentDate(
      NgbDate.from({
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      }),
    );
  }

  onCustomSelectionFromDateCompareTo(dateFrom: Date): void {
    if (!this.customCompareToSelection) {
      return;
    }
    this.customCompareToSelection.start = startOfDay(dateFrom).toISOString();
    this.customCompareToSelection.end = endOfDay(dateFrom).toISOString();
    // check if CompareTo and DateRange date lengths has differences
    this.compareToDifferenceInDays = this.getDifferenceInDays(this.customCompareToSelection);

    this.isValidDiffLength = this.dateRangeDifferenceInDays === this.compareToDifferenceInDays;

    // check date ranges overlapping
    this.isDateRangesOverlapping = this.getDateRangesOverlappingState();

    this.isValidDateCompareTo =
      this.comparableDateService.checkValidDateRange(this.customCompareToSelection) &&
      !this.isDateBiggerThanCurrentDate(dateFrom);
  }

  onCustomSelectionDateRange(dateSelection: IDateFilter): void {
    this.selectedDateRangeQP = QuickPicks.Custom;
    this.dateRangeSelection = dateSelection;
    this.dateRangeDifferenceInDays = this.getDifferenceInDays(this.dateRangeSelection);
    const dateRangeEnd = new Date(this.dateRangeSelection.end);
    this.isValidDateRange =
      this.comparableDateService.checkValidDateRange(this.dateRangeSelection) &&
      !this.isDateBiggerThanCurrentDate(dateRangeEnd);
  }

  onCustomSelectionCompareTo(dateSelection: IDateFilter): void {
    this.customCompareToSelection = dateSelection;

    // check if CompareTo and DateRange date lengths has differences
    this.compareToDifferenceInDays = this.getDifferenceInDays(this.customCompareToSelection);

    this.isValidDiffLength = this.dateRangeDifferenceInDays === this.compareToDifferenceInDays;

    // check date ranges overlapping
    this.isDateRangesOverlapping = this.getDateRangesOverlappingState();
  }

  private getDifferenceInDays(dateSelection: IDateFilter): number {
    return this.comparableDateService.getDifferenceInDays(dateSelection);
  }

  private getDateRangesOverlappingState(): boolean {
    return this.comparableDateService.getDateRangesOverlappingState(
      this.dateRangeSelection,
      this.customCompareToSelection,
    );
  }

  private getSearchDateObject(
    startDate: string,
    endDate: string,
    keyPrefix?: string,
  ): Map<string, ISearchCriteria> {
    return new Map<string, ISearchCriteria>([
      [
        `${keyPrefix}_${SearchSelector.FromDate}`,
        {
          argument: startDate,
          selector: SearchSelector.FromDate,
          operator: '=',
        },
      ],
      [
        `${keyPrefix}_${SearchSelector.ToDate}`,
        {
          argument: endDate,
          selector: SearchSelector.ToDate,
          operator: '=',
        },
      ],
    ]);
  }

  private updateFilter(
    filters: IFilterToApply[],
    searchCriteria: Map<string, ISearchCriteria>,
  ): void {
    filters.forEach((filter: IFilterToApply) => {
      if (filter) {
        if (filter.toRemove) {
          searchCriteria.delete(filter.key);
        } else {
          searchCriteria.set(filter.key, {
            argument: filter.argument,
            selector: filter.selector,
            operator: filter.operator,
            values: new Set(filter.value.map((item) => item.id)),
          });
        }
      }
    });
  }
}
