<div class="is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd">
  <div *ngFor="let group of roleGroups; last as isLast; trackBy: trackByKey">
    <ng-container *ngIf="group.value.length > 0">
      <div class="field">
        <div class="label">{{ getRoleGroupText(group.key) }}</div>
        <div class="notification is-info mt-3" *ngIf="checkVerifoneEmail(group)">
          <div class="icon">
            <i class="far fa-info-circle fa-lg"></i>
          </div>
          <p i18n>Verifone roles are not available for users with external emails.</p>
        </div>
        <div class="control mt-3" *ngFor="let role of group.value">
          <label class="checkbox" attr.data-e2e="checkbox-{{ role }}-label">
            <input
              [disabled]="selfEditMode || disabled || isDisabled(role) || checkVerifoneEmail(group)"
              type="checkbox"
              attr.data-e2e="checkbox-{{ role }}"
              (change)="onRoleChange(role, $event)"
              [checked]="isRoleSelected(role)"
            />
            <span>{{ getRoleText(role) }}</span>
          </label>
          <div
            *ngIf="getRoleDescription(role) as roleDescription"
            class="has-text-grey is-size-7 pl-4"
          >
            <div class="pl-1">{{ roleDescription }}</div>
          </div>
        </div>
      </div>
      <div class="box__separator mx-1" *ngIf="!isLast"></div>
    </ng-container>
  </div>
</div>
