import { Injectable } from '@angular/core';
import { FeeType } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class FeeTypes extends SelectInputsList {
  keyValue = {
    [FeeType.Verifone]: $localize`Verifone`,
    [FeeType.PaymentProvider]: $localize`Payment Provider`,
    [FeeType.BankingPartner]: $localize`Banking Partner`,
  };

  list: ISelectInput[] = this.getSelectInputs();
}
