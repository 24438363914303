<portal-table
  [items]="apps"
  [loading$]="loading$"
  [tableConfig]="tableConfigView"
  [filter]="filter"
  [rowLinkHref]="rowLink"
  rowLinkHeading="Link to Point of Interaction details"
  i18n-rowLinkHeading="Link to app page"
  loadingMessage="Loading Apps"
  i18n-loadingMessage="Loading Applications"
  notFoundMessage="Applications coming soon!"
  i18n-notFoundMessage="Applications coming soon!"
  (sortChanged)="onSortChanged($event)"
></portal-table>
