import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFilterToApply } from '@portal/shared/ui/filter/src/lib/search/interfaces/filter-to-apply.interface';

@Injectable({
  providedIn: 'root',
})
export class UrlQueryParamsBuilderService {
  constructor() {}
  createHttpParams(params: Object, filters?: IFilterToApply[]): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        httpParams = httpParams.append(key, value.toString());
      }
    }

    if (filters && filters.length > 0) {
      for (const filter of filters) {
        httpParams = httpParams.append(filter.selector, filter.argument);
      }
    }
    return httpParams;
  }
  createHttpParamsForAppCatalog(params: Object, filters?: IFilterToApply[]): HttpParams {
    let httpParams = new HttpParams();
    for (const [key, value] of Object.entries(params)) {
      httpParams = httpParams.append(key, value.toString());
    }
    if (filters && filters.length > 0) {
      for (const filter of filters) {
        httpParams = httpParams.append(`latestAppVersion.${filter.selector}`, filter.argument);
      }
    }
    return httpParams;
  }
}
