import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';
import {
  ES_CONSTANTS as CONSTANTS,
  IAccountDetails,
  IBankAccount,
  IPaymentContract,
  IPointInteraction,
  IProcessorConfig,
  IConfigOnboarding,
  IAcquirerConfig,
  IProcessorDetails,
  ISurcharge,
  ICardTableGroup,
} from '@portal/entity-services/interfaces';
import { CustomDataService } from '@portal/shared/vui-http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IProcessorParameters } from '@portal/entity-services/interfaces/src/lib/payment-contracts/interfaces/processor-parameters.interface';

@Injectable({ providedIn: 'root' })
export class CustomPaymentContractDataService extends CustomDataService<IPaymentContract> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super(CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT, http, httpUrlGenerator);
  }

  updatePPCHandle(
    contractUid: string,
    poiUid: string,
    handler: string,
    isActive: boolean,
  ): Observable<IPointInteraction> {
    const dataOrError =
      contractUid && poiUid
        ? { isActive }
        : new Error(`No "${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ADDRESSES}" entity to update`);
    const options = {
      headers: new HttpHeaders({ 'x-vfi-ppc-handler': handler }),
    };
    return this.execute(
      'PATCH',
      `${CONSTANTS.ONBOARDING}/paymentContracts/${contractUid}/handle/${poiUid}`,
      dataOrError,
      options,
    );
  }

  getPoiRelationships(id: string): Observable<IPointInteraction[]> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error(`No "${this.entityName}" key to get`);
    }
    return this.execute(
      'GET',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT_POINT_INTERACTION}`,
      error,
    );
  }

  getPoiRelationshipsWithPayload(id: string, payload: HttpParams): Observable<IPointInteraction[]> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error(`No "${this.entityName}" key to get`);
    }
    return this.execute(
      'GET',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT_POINT_INTERACTION}/`,
      error,
      { params: payload },
    );
  }

  getAcquirer(acquirer: string): Observable<IAcquirerConfig> {
    let error: Error | undefined;
    if (!acquirer) {
      error = new Error(`No "" key to get`);
    }
    return this.execute(
      'GET',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ACQUIRER}/${acquirer}`,
      error,
    );
  }

  getProcessor(processor: string): Observable<IProcessorDetails> {
    let error: Error | undefined;
    if (!processor) {
      error = new Error(`No "" key to get`);
    }
    return this.execute(
      'GET',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.PROCESSOR}/${processor}`,
      error,
    );
  }

  getCountOfPoiRelationships(id: string): Observable<number> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error(`No "${this.entityName}" key to get`);
    }
    return this.execute(
      'GET',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT_POINT_INTERACTION}/count`,
      error,
    );
  }

  addPoiRelationship(poi: Partial<IPointInteraction>, id: string): Observable<IPointInteraction> {
    const entityOrError =
      id && poi
        ? poi
        : new Error(`No "${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION}" entity to add`);
    return this.execute(
      'POST',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT_POINT_INTERACTION}`,
      entityOrError,
    ).pipe(
      // forward the payload if no result
      map((result) => result || poi),
    );
  }

  initiateMerchantOnboardingEcommerce(contractUid: string): Observable<IConfigOnboarding> {
    const merchantOnboardingOrError = contractUid
      ? {
          paymentProviderContract: contractUid,
          isLegalConsentGranted: true,
        }
      : new Error(`No key to get for "${this.entityName}"`);
    return this.execute('POST', CONSTANTS.ONBOARDING_PAYPAL, merchantOnboardingOrError);
  }

  deletePoiRelationship(poi: Partial<IPointInteraction>, id: string): Observable<string> {
    let error: Error | undefined;
    if (!poi || !poi.poiUid || id == null) {
      error = new Error(`No "${CONSTANTS.ENTITY_SERVICE.POINT_INTERACTION}" entity to delete`);
    }
    return this.execute(
      'DELETE',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT_POINT_INTERACTION}/${poi.poiUid}`,
      error,
    ).pipe(
      // forward the id of deleted entity as the result of the HTTP DELETE
      map(() => poi.poiUid as string),
    );
  }

  updateProcessorConfig(config: IProcessorConfig, id: string): Observable<IPaymentContract> {
    const updateOrError =
      !config || !id
        ? new Error(`No "${this.entityName}" processor config update data or id`)
        : config;
    return this.execute(
      'PUT',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT_PROCESSOR_CONFIG}`,
      updateOrError,
    );
  }

  updateProcessorParameters(
    parameters: IProcessorParameters,
    id: string,
  ): Observable<IPaymentContract> {
    const updateOrError =
      !parameters || !id
        ? new Error(`No "${this.entityName}" processor parameters update data or id`)
        : parameters;
    return this.execute(
      'PUT',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT_PROCESSOR_PARAMETERS}`,
      updateOrError,
    );
  }

  updateProcessorAccountDetails(
    accountDetails: IAccountDetails,
    id: string,
  ): Observable<IPaymentContract> {
    const updateOrError =
      !accountDetails || !id
        ? new Error(`No "${this.entityName}" processor accountDetails update data or id`)
        : accountDetails;
    return this.execute(
      'PUT',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.PAYMENT_CONTRACT_PROCESSOR_ACCOUNT_DETAILS}`,
      updateOrError,
    );
  }

  enableDisablePaymentContract(id: string, status: string): Observable<IPaymentContract> {
    const dataOrError =
      !id && !status ? new Error(`No "${this.entityName}" key to get`) : { status };

    return this.execute('PATCH', `${this.entityUrl}${id}`, dataOrError);
  }

  // This method is a duplication of CustomOrganisationDataService.getAccount.
  // It is here so that the PaymentContractService doesn't have to import the OrganisationService
  // in order to get accounts.
  // This is part of the fix for https://jira.verifone.com/browse/CPDB-2398.
  getAccount(id: string): Observable<IBankAccount> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error(`No "${this.entityName}" key to get`);
    }
    return this.execute(
      'GET',
      `${CONSTANTS.ENTITY_SERVICE.ROOT}/${CONSTANTS.ENTITY_SERVICE.ORGANISATION_ACCOUNTS}/${id}`,
      error,
    );
  }

  updateSurcharge(id: string, surchargePayload: ISurcharge[]): Observable<ISurcharge> {
    const updateOrError =
      !surchargePayload || !id
        ? new Error(`No "${this.entityName}" processor accountDetails update data or id`)
        : surchargePayload;
    return this.execute(
      'PUT',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.SURCHARGE_SETTINGS}`,
      updateOrError,
    );
  }

  getSurcharge(id: string): Observable<ISurcharge[]> {
    let error: Error | undefined;
    if (id == null) {
      error = new Error(`No "${this.entityName}" key to get`);
    }
    return this.execute(
      'GET',
      `${this.entityUrl}${id}/${CONSTANTS.ENTITY_SERVICE.SURCHARGE_SETTINGS}`,
      error,
    );
  }

  getBinRules(params: HttpParams): Observable<{ response: string[] }> {
    return this.execute('GET', `${CONSTANTS.BIN_SERVICE.BIN_RULES}/propertyValues`, {}, { params });
  }

  getCardTableGroup(id: string): Observable<ICardTableGroup> {
    return this.execute('GET', `${CONSTANTS.BIN_SERVICE.CARD_TABLE_GROUPS}/${id}`);
  }
}
