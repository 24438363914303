import { IGuardPermissions } from '@portal/shared/auth/authorization/src';
import { RouteName } from './route-name.enum';
import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';

export const routePermissions: { [key in RouteName]?: IGuardPermissions } = {
  [RouteName.tenants]: {
    read: [
      UserRoles.EstateOwnerAdmin,
      UserRoles.VerifoneHelpDesk,
      UserRoles.VerifoneAdmin,
      UserRoles.PartnerHelpdesk,
    ],
  },
};
