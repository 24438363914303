export enum Fonts {
  Roboto = 'Roboto',
  OpenSans = 'Open Sans',
  Lato = 'Lato',
  Slabo = 'Slabo',
  Oswald = 'Oswald',
  SourceSansPro = 'Source Sans Pro',
  Montserrat = 'Montserrat',
  Raleway = 'Raleway',
  PTSans = 'PT Sans',
  Lora = 'Lora',
  NotoSans = 'Noto Sans',
  NotoSerif = 'Noto Serif',
  NunitoSans = 'Nunito Sans',
  ConcertOne = 'Concert One',
  Prompt = 'Prompt',
}
