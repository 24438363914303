import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedUiAccordionsModule } from '@portal/shared/ui/accordions/src/lib/shared-ui-accordions.module';
import { SharedUiFilterModule } from '@portal/shared/ui/filter/src/lib/shared-ui-filter.module';
import { TopNavbarModule } from '@portal/shared/ui/layouts/src/lib/top-navbar/top-navbar.module';
import { SharedUiModalModule } from '@portal/shared/ui/modal/src/lib/shared-ui-modal.module';
import { TableModule } from '@portal/shared/ui/table';
import { FormModule } from '@portal/shared/ui/form';
import { SharedModule } from '@apps/portal/src/app/shared/shared.module';
import { FileInputModule } from '@portal/shared/ui/file-input/src/lib/file-input.module';

import { FormWhiteLabelingEditorComponent } from './form-white-labeling-editor/form-white-labeling-editor.component';
import { WLAddFilterComponent } from './white-labeling-preview-page/white-labeling-preview-content/filter/add-filter/add-filter.component';
import { WLFilterDateComponent } from './white-labeling-preview-page/white-labeling-preview-content/filter/date/date.component';
import { WLFilterHeaderStateComponent } from './white-labeling-preview-page/white-labeling-preview-content/filter/header-state/header-state.component';
import { HeaderStates } from './white-labeling-preview-page/white-labeling-preview-content/filter/header-state/header-state.list';
import { WLStoredCredentialComponent } from './white-labeling-preview-page/white-labeling-preview-content/filter/stored-credential-type/stored-credential-type.component';
import { StoredCredentialTypeList } from './white-labeling-preview-page/white-labeling-preview-content/filter/stored-credential-type/stored-credential-type.list';
import { WLTransactionFilterComponent } from './white-labeling-preview-page/white-labeling-preview-content/filter/transaction-filter.component';
import { WhiteLabelingPreviewContentComponent } from './white-labeling-preview-page/white-labeling-preview-content/white-labeling-preview-content.component';
import { WhiteLabelingPreviewPageComponent } from './white-labeling-preview-page/white-labeling-preview-page.component';
import { WhiteLabelingPreviewComponent } from './white-labeling-preview.component';
import { WLLocaleDatePipe } from './white-labeling-preview-page/white-labeling-preview-content/filter/date/pipes/locale-date.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormModule,
    TopNavbarModule,
    SharedUiAccordionsModule,
    SharedUiModalModule,
    SharedUiFilterModule,
    TableModule,
    FileInputModule,
    SharedModule,
  ],
  declarations: [
    WhiteLabelingPreviewComponent,
    FormWhiteLabelingEditorComponent,
    WhiteLabelingPreviewPageComponent,
    WhiteLabelingPreviewComponent,
    FormWhiteLabelingEditorComponent,
    WhiteLabelingPreviewPageComponent,
    WhiteLabelingPreviewContentComponent,
    WLTransactionFilterComponent,
    WLStoredCredentialComponent,
    WLFilterHeaderStateComponent,
    WLAddFilterComponent,
    WLFilterDateComponent,
    WLLocaleDatePipe,
  ],
  providers: [StoredCredentialTypeList, HeaderStates],
})
export class WhiteLabelingPreviewModule {}
