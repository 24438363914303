import { Injectable } from '@angular/core';
import {
  AsYouType,
  CountryCode,
  format,
  getExampleNumber,
  getPhoneCode,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';
import { CountryService, ICountry } from '@portal/shared/helpers';
import { IIntTelInput } from '../models/int-tel-input.model';
import { PhoneFormat } from '../enum/phone-format.enum';

@Injectable()
export class IntTelInputService {
  constructor(private countryService: CountryService) {}

  getCountriesWithPhoneCodes(): IIntTelInput[] {
    const supportedCountries = this._getPhoneSupportedCountries();
    return supportedCountries.map((country) => ({
      alpha2: country.alpha2,
      name: country.name,
      phoneCode: `+${getPhoneCode(country.alpha2 as CountryCode) as string}`,
    }));
  }

  getCountryFromAlpha2(countryAlpha2Code: string): IIntTelInput {
    return this.getCountriesWithPhoneCodes()
      .filter((country) => country.alpha2 === countryAlpha2Code)
      .shift();
  }

  getExampleNumberForCountry(countryAlpha2Code: string): string {
    return getExampleNumber(countryAlpha2Code as CountryCode, examples).formatNational();
  }

  isValidPhoneNumber(phoneNumber: string, countryAlpha2Code?: string): boolean {
    return isValidPhoneNumber(phoneNumber, countryAlpha2Code as CountryCode);
  }

  getCountryCodeFromPhoneNumber(phoneNumber: string): string {
    const asYouType = new AsYouType();
    asYouType.input(phoneNumber);
    return asYouType.getNumber()?.country;
  }

  getPhoneNumberInput(phoneNumber: string): string {
    const asYouType = new AsYouType();
    asYouType.input(phoneNumber);
    return asYouType.getNationalNumber();
  }

  formatNumber(phoneNumber: string, countryCode: string, phoneFormat: PhoneFormat): string {
    const formattedNumber = format(
      phoneNumber,
      countryCode as CountryCode,
      phoneFormat as PhoneFormat,
    );
    if (formattedNumber.startsWith('0') || countryCode === 'AU') return this.getPhoneNumberInput(phoneNumber);
    else return formattedNumber ? formattedNumber : phoneNumber;
  }

  /***
   * Some alpha2 codes are not related to
   * country. They need to be excluded from
   * the final list
   * @private
   */
  private _getPhoneSupportedCountries(): ICountry[] {
    const phoneUnsupportedAlpha2 = ['AQ', 'BV', 'TF', 'HM', 'PN', 'GS', 'UM'];
    return this.countryService.countries.filter(
      (country: ICountry) => !phoneUnsupportedAlpha2.includes(country.alpha2),
    );
  }
}
