import { InjectionToken } from '@angular/core';
import { Locale } from '../enums/locale.enum';

/**
 * Defines a list of languages that are supported in the application.
 * @default ['Locale.En']
 * @see {@link https://angular.io/api/core/InjectionToken#description}
 *
 * For every supported language the `LanguageHandle` service will try
 * to change the location to `/<app>/<lang>/<current path>` upon a call to `setLanguage(<lang>)`.
 * For all other (unsupported) languages the location will not be changed.
 * To override the default value, provide it in your `app.module.ts` like this:
 * ```js
 * @NgModule({
 *   // ...
 *   providers: [
 *     // ...
 *     {
 *       provide: SUPPORTED_LANGUAGES,
 *       useValue: [ Locale.En, Locale.Fr, Locale.De ],
 *     },
 *   ],
 * });
 * ```
 */
export const SUPPORTED_LANGUAGES = new InjectionToken<Locale[]>('portal-supported-languages', {
  factory: () => [Locale.En],
});
