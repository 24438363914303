import { Injectable } from '@angular/core';
import { IApp, IVfAppMarketApp, StorePagination } from '@portal/marketplace/api-interfaces';
import { IFilterToApply } from '@portal/shared/ui/filter/src';
import { NotifierService } from '@portal/shared/ui/notifier';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import {
  extractAppIds,
  transformIAppVersionAndIVfAppMarketAppToIApp,
} from '../../helpers/app-catalog.helpers';
import { AppCatalogDataService } from '../app-catalog-data.service';
import { AppVersionsService } from '../app-versions/app-versions.service';
import { IAppQuery } from '../apps/apps.query';
import { IAppStore } from '../apps/apps.store';
import { VfAppMarketAppCatalogQuery } from './vf-app-market.query';
import { VfAppMarketAppCatalogStore } from './vf-app-market.store';
import { IQueryParams } from '@portal/shared/ui/table/src';

@Injectable({ providedIn: 'root' })
export class VfAppMarketAppCatalogService extends StorePagination {
  constructor(
    private vfappMarketAppCatalogStore: VfAppMarketAppCatalogStore,
    private appCatalogDataService: AppCatalogDataService,
    private vfAppMarketAppCatalogQuery: VfAppMarketAppCatalogQuery,
    private appVersionsService: AppVersionsService,
    private appsStore: IAppStore,
    public notifierService: NotifierService,
    public appsQuery: IAppQuery,
  ) {
    super();
  }
  loadAppCatalog(USER_TYPE, params?: IFilterToApply[], force = false): void {
    this.appsStore.setLoading(true);
    if (force || !this.hasDataInStore()) {
      const vfAppMarketApps = this.appCatalogDataService.getAppsFromVfAppMarket(
        USER_TYPE,
        null,
        params,
      );
      this.appsStore.setLoading(true);
      vfAppMarketApps
        .pipe(
          switchMap((appMarketApps) => {
            this.vfappMarketAppCatalogStore.set(appMarketApps['data']); //add data to vfappmarket store
            return this.appCatalogDataService
              .getAppsFromAppCatalog(extractAppIds(appMarketApps['data']))
              .pipe(
                tap((appsAppCatalog) => {
                  this.appVersionsService.addDataToAppCatalogVersionsStore(appsAppCatalog['data']);
                  this.appsStore.add(
                    //add data to apps store
                    transformIAppVersionAndIVfAppMarketAppToIApp(
                      appMarketApps['data'],
                      appsAppCatalog['data'],
                    ),
                  );
                  this.appsStore.setHasCache(true);
                  this.appsStore.setLoading(false);
                }),
              );
          }),
        )
        .subscribe();
    } else {
      this.appsStore.setLoading(false);
    }
  }

  loadAppCatalogPaginated(USER_TYPE, paginationConfig?, filters?: IFilterToApply[]): void {
    this.appsStore.setLoading(true);
    if (this.checkIfNeedFetchData(this.appsStore, paginationConfig)) {
      const vfAppMarketApps = this.appCatalogDataService.getAppsFromVfAppMarket(
        USER_TYPE,
        paginationConfig,
        filters,
      );

      vfAppMarketApps
        .pipe(
          catchError(() => {
            this.appsStore.setLoading(false);
            return of({ data: [], metadata: { count: 0 } });
          }),
          switchMap((appMarketApps) => {
            this.vfappMarketAppCatalogStore.update({ metadata: appMarketApps['metadata'] });
            if (appMarketApps['data'].length === 0) {
              this.appsStore.setLoading(false);
              return of([]);
            }
            this.vfappMarketAppCatalogStore.add(appMarketApps['data']); //add data to vfappmarket store
            // Getting AppCatalog Data
            return this.appCatalogDataService
              .getAppsFromAppCatalog(extractAppIds(appMarketApps['data']))
              .pipe(
                tap((appsAppCatalog) => {
                  this.appVersionsService.addDataToAppCatalogVersionsStore(appsAppCatalog['data']);
                  const transformedApps = transformIAppVersionAndIVfAppMarketAppToIApp(
                    appMarketApps['data'],
                    appsAppCatalog['data'],
                  );
                  this.updateStorePagination(
                    this.appsStore,
                    paginationConfig,
                    transformedApps.map((app) => app.appmarketAppId),
                  );
                  this.appsStore.add(
                    //add data to apps store
                    transformedApps,
                  );
                  this.appsStore.setLoading(false);
                }),
              );
          }),
        )
        .subscribe();
    } else {
      this.appsStore.setLoading(false);
    }
  }

  // loadAppForTagsPaginated(USER_TYPE, paginationConfig?, filters?: IFilterToApply[]): void {
  //   this.appsStore.setLoading(true);
  //   if (this.checkIfNeedFetchData(this.appsStore, paginationConfig)) {
  //     const vfAppMarketApps = this.appCatalogDataService.getAppsFromVfAppMarket(
  //       USER_TYPE,
  //       paginationConfig,
  //       filters,
  //     );

  //     vfAppMarketApps
  //       .pipe(
  //         catchError(() => {
  //           this.appsStore.setLoading(false);
  //           return of({ data: [], metadata: { count: 0 } });
  //         }),
  //         switchMap((appMarketApps) => {
  //           this.vfappMarketAppCatalogStore.update({ metadata: appMarketApps['metadata'] });
  //           if (appMarketApps['data'].length === 0) {
  //             this.appsStore.setLoading(false);
  //             return of([]);
  //           }
  //           this.vfappMarketAppCatalogStore.add(appMarketApps['data']); //add data to vfappmarket store
  //           // Getting AppCatalog Data
  //           return this.appCatalogDataService
  //             .getAppsFromAppCatalog(extractAppIds(appMarketApps['data']))
  //             .pipe(
  //               tap((appsAppCatalog) => {
  //                 this.appVersionsService.addDataToAppCatalogVersionsStore(appsAppCatalog['data']);
  //                 const transformedApps = transformIAppVersionAndIVfAppMarketAppToIApp(
  //                   appMarketApps['data'],
  //                   appsAppCatalog['data'],
  //                 );
  //                 this.updateStorePagination(
  //                   this.appsStore,
  //                   paginationConfig,
  //                   transformedApps.map((app) => app.appId),
  //                 );
  //                 this.appsStore.add(
  //                   //add data to apps store
  //                   transformedApps,
  //                 );
  //                 this.appsStore.setLoading(false);
  //               }),
  //             );
  //         }),
  //       )
  //       .subscribe();
  //   } else {
  //     this.appsStore.setLoading(false);
  //   }
  // }

  loadAppByAppMarketAppId(
    appMarketAppId: string,
    USER_TYPE: 'MERCHANT' | 'EO',
    entityUid?: string,
  ): void {
    this.loadAppByAppMarketAppId$(appMarketAppId, USER_TYPE, entityUid).subscribe(
      () => {},
      (error) => {
        this.notifierService.error(error.error.errors[0].message);
      },
    );
  }

  loadAppByAppMarketAppId$(
    appMarketAppId: string,
    USER_TYPE: 'MERCHANT' | 'EO',
    entityUid?: string,
  ): Observable<any> {
    this.appsStore.setLoading(true);

    const vfAppMarketApp = this.appCatalogDataService.getAppFromVfAppMarket(
      appMarketAppId,
      USER_TYPE,
      entityUid,
    );
    return vfAppMarketApp.pipe(
      switchMap((appMarketApp) => {
        return this.appCatalogDataService
          .getAppsFromAppCatalog([appMarketApp['data'][0].appVersionId])
          .pipe(
            map((appsAppCatalog) => {
              const transformedData = transformIAppVersionAndIVfAppMarketAppToIApp(
                [appMarketApp['data'][0]],
                appsAppCatalog['data'],
              );
              this.appsStore.add(transformedData);
              this.appsStore.setLoading(false);
              return transformedData[0];
            }),
          );
      }),
    );
  }

  loadAppById(id: string): Observable<any> {
    return this.appCatalogDataService.getAppsFromAppCatalog([id]);
  }

  loadAppsByIds(apps: IVfAppMarketApp[]): void {
    this.appsStore.setLoading(true);
    this.appCatalogDataService
      .getAppsFromAppCatalog(apps.map((app) => app.appVersionId))
      .pipe(
        tap((appsAppCatalog) => {
          this.appsStore.add(
            //add data to apps store
            transformIAppVersionAndIVfAppMarketAppToIApp(apps, appsAppCatalog['data']),
          );
        }),
        finalize(() => {
          this.appsStore.setLoading(false);
        }),
      )
      .subscribe();
  }

  loadAppsForBundle(apps: string[], USER_TYPE: 'MERCHANT' | 'EO'): Observable<IApp[]> {
    this.appsStore.setLoading(true);
    const requests = apps.map((app) => {
      return this.appCatalogDataService.getAppFromVfAppMarket(app, USER_TYPE).pipe(
        catchError(() => {
          return of(null);
        }),
      );
    });
    return forkJoin(requests).pipe(
      map((res) => {
        return res
          .filter((singleRes) => singleRes != null)
          .map((singleRes) => {
            return singleRes.data[0];
          });
      }),
      switchMap((vfAppMarketApps) => {
        return this.appCatalogDataService
          .getAppsFromAppCatalog(extractAppIds(vfAppMarketApps))
          .pipe(
            map((appCatalogApps) => {
              return transformIAppVersionAndIVfAppMarketAppToIApp(
                vfAppMarketApps,
                appCatalogApps['data'],
              );
            }),
            tap((transformedApps) => {
              this.appsStore.add(transformedApps);
              this.appsStore.setLoading(false);
            }),
          );
      }),
    );
  }

  addAppToVfAppMarketAppCatalogStore(apps: IVfAppMarketApp[]): void {
    this.vfappMarketAppCatalogStore.add(apps);
  }

  getAppUpdates(appId: string, entityUid?: string, fetchAll?: boolean): Observable<any> {
    return this.appCatalogDataService.getUpdatesForApp(appId, entityUid, fetchAll);
  }

  getAppsUpdates(): Observable<any> {
    return this.appCatalogDataService.getAppsUpdates();
  }

  getLoadingState(): Observable<boolean> {
    return this.vfAppMarketAppCatalogQuery.selectLoading();
  }

  getIds(): string[] {
    const appVersionIds: string[] = [];
    const apps = this.vfAppMarketAppCatalogQuery.getAll();
    for (const app of apps) {
      appVersionIds.push(app.appVersionId);
    }
    return appVersionIds;
  }

  getAllApps(): Observable<IApp[]> {
    return this.appsQuery.selectAll();
  }

  getAppsForTags(paginationConfig: IQueryParams): Observable<IApp[]> {
    const storePagination = this.appsStore.getValue().pagination.pages;
    const idsToReturn = storePagination.get(paginationConfig.start / paginationConfig.limit);
    return this.appsQuery.selectMany(idsToReturn);
  }

  hasDataInStore(): boolean {
    return this.vfAppMarketAppCatalogQuery.getHasCache();
  }

  generatePublicCatalog(): void {
    this.appCatalogDataService.generatePublicCatalog().subscribe((res) => {
      if (res['data']) {
        this.notifierService.success('Public Applications Catalog Generated');
      }
    });
  }
  getMetadata(): any {
    return this.vfAppMarketAppCatalogQuery.getValue().metadata;
  }
  reset(): void {
    this.vfappMarketAppCatalogStore.reset();
  }
}
