/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { IEntity, IOrganisation } from '@portal/entity-services/interfaces';
import { OrganisationService } from '@portal/entity-services/organisations/src/lib/services/organisation.service';
import { FilterContentItem } from '@portal/shared/ui/filter/src/lib/content/item';
import { FILTER_CONTENT_ITEM } from '@portal/shared/ui/filter/src/lib/content/token';
import { cloneDeep, filter, forEach } from 'lodash';
import { forkJoin, Observable, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { FilterAutoCompleteInputComponent } from '../../lib/autocomplete/search-autocomplete-input.component';
import { ISelectInput } from '../box/interfaces/select-input.interface';
import { SearchOperator } from '../search/enums/search-operator.enum';
import { SearchSelector } from '../search/enums/search-selector.enum';
import { IFilterToApply } from '../search/interfaces/filter-to-apply.interface';
import { ISearchCriteria } from '../search/interfaces/search-criteria.interface';

declare const $localize;

@UntilDestroy()
@Component({
  selector: 'portal-filter-entity',
  templateUrl: './entity.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: FilterEntityComponent,
    },
  ],
})
export class FilterEntityComponent extends FilterContentItem implements OnInit, OnChanges {
  static initialDebounce = 1000;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _searchSelector: any = SearchSelector.EntityId;
  title = '';
  @Input()
  set searchSelector(val) {
    this._searchSelector = val;
  }
  get searchSelector(): any {
    return this._searchSelector;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _entityText = $localize`Organisations`;

  @Input()
  set filterName(val) {
    this.entityText = val;
  }

  set entityText(val) {
    this._entityText = val;
  }
  get entityText(): string {
    return this._entityText;
  }
  @Input()
  entityType = '';
  @Input()
  parentId = '';

  @Output()
  filterApplied: EventEmitter<IFilterToApply> = new EventEmitter();
  @Output() filterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();

  @Input() entityRemoved$ = new Subject<ISelectInput>();

  @Input()
  selectedEntities: Map<string, ISearchCriteria>; // Pre-selected, from store

  @Input() selectLimit = 0;

  @Output() staticFilterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();

  @ViewChild('entitySearchInput', { static: true })
  entitySearchInput: FilterAutoCompleteInputComponent;

  staticListUpdateSubject$ = new Subject<IFilterToApply>();

  itemToggled: EventEmitter<number> = new EventEmitter();
  isOpen = false;

  localPreSelectedEntities: Map<string, ISelectInput> = new Map();

  entitiesAll: IEntity[] = [];
  entities: IEntity[] = [];
  searchedText = '';

  loading$: Observable<boolean>;
  showSelectedEntities: Map<string, ISelectInput> = new Map();

  searchTerm$ = new Subject<string>();
  search$: Observable<IOrganisation[]>;
  debounce = 1000;

  constructor(public organisationService: OrganisationService) {
    super();
    this.loading$ = organisationService.loading$;
  }

  ngOnChanges(): void {
    this.updateText();
  }

  ngOnInit(): void {
    if (!this.selectedEntities) {
      this.selectedEntities = new Map();
    }
    const entities = cloneDeep(this.selectedEntities.get(SearchSelector.EntityId));

    this.staticListUpdateSubject$
      .pipe(debounceTime(100))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.staticFilterUpdated.emit({
          key: this.searchSelector,
          value: Array.from(this.localPreSelectedEntities.values()),
        });
      });

    if (entities) {
      this.setSelectedEntities(entities);
    }

    this.startListeningForSearch();

    this.entityRemoved$.pipe(untilDestroyed(this)).subscribe((entityToRemove: ISelectInput) => {
      this.removeAllEntities();

      const selectedEntity = this.getEntityById(entityToRemove.id);
      if (selectedEntity) {
        this.onEntityRemoved(entityToRemove);
        this.onFilterApplied();
        this.itemToggled.emit();
      }
    });
  }

  setSelectedEntities(value: ISearchCriteria): void {
    // Set selectedFilters
    const values = new Set<string>(value.argument?.replace('(', '').replace(')', '').split(','));
    forEach(Array.from(values), (argument: string) => {
      this.localPreSelectedEntities.set(argument, {
        id: argument,
        text: '',
      });
    });
    this.updateSelectedEntities();
  }

  startListeningForSearch(): void {
    this.search$ = this.search(this.searchTerm$);
    this.search$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.entities = data;
      this.entitiesAll = data;
      if (this.localPreSelectedEntities.size) {
        this.updateEntities();
      }
    });
  }

  updateSelectedEntities(): void {
    this.showSelectedEntities = cloneDeep(this.localPreSelectedEntities);
    const entitiesToFetch: string[] = [];
    Array.from(this.showSelectedEntities.values()).forEach((val: ISelectInput) => {
      if (!val.text) {
        entitiesToFetch.push(val.id);
      }
    });

    if (entitiesToFetch.length) {
      forkJoin(
        entitiesToFetch.map((id: string) => {
          return this.getEntityById(id);
        }),
      )
        .pipe(untilDestroyed(this))
        .subscribe((orgs: IOrganisation[]) => {
          forEach(orgs, (org: IOrganisation) => {
            const input = this.showSelectedEntities.get(org.entityUid);
            input.text = org.name;
            this.localPreSelectedEntities.set(org.entityUid, {
              id: org.entityUid,
              text: org.name,
            });
          });
          // Just we update if not we lose the results in the filters after to come back from details
          this.filterUpdated.emit();
          this.updateText();
        });
    }
  }

  onSearch(searchValue = ''): void {
    this.searchedText = searchValue;
    this.searchTerm$.next(searchValue);
  }

  search(terms: Observable<string>): Observable<IOrganisation[]> {
    return terms.pipe(debounceTime(this.debounce)).pipe(
      switchMap((term: string) => {
        // Always reset debounce time
        this.debounce = FilterEntityComponent.initialDebounce;
        return this.getEntitiesByName(term);
      }),
    );
  }

  clear(): void {
    this.removeAllEntities();
    this.resetInputValue();
  }

  getEntitiesByName(term: string): Observable<IOrganisation[]> {
    let options = {};
    if (this.entityType !== '') {
      options = { ...options, ...{ entityType: this.entityType } };
    }
    if (this.parentId !== '') {
      options = { ...options, ...{ parentId: this.parentId } };
    }
    if (term) {
      options = { ...options, ...{ name: term } };
    }
    return Object.keys(options).length !== 0
      ? this.organisationService.getWithQuery({
          ...options,
        })
      : this.organisationService.getAll();
  }

  getEntityById(id: string): Observable<IOrganisation> {
    return this.organisationService.getByKey(id);
  }

  onFilterApplied(): void {
    this.updateEntities();
    const values: string[] = this.getValues();
    if (values.length) {
      this.filterApplied.emit({
        selector: this.searchSelector,
        key: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.localPreSelectedEntities.values()),
        argument: `(${Array.from(values).join(',')})`,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        value: Array.from(this.localPreSelectedEntities.values()),
      });
    } else {
      this.filterApplied.emit({
        key: this.searchSelector,
        toRemove: true,
        selector: this.searchSelector,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        toRemove: true,
      });
    }

    this.resetInputValue();
    this.updateText();
    this.itemToggled.emit();
  }

  removeAllEntities(): void {
    this.localPreSelectedEntities.clear();
    this.updateSelectedEntities();
    this.updateText();
  }

  onEntityRemoved(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.delete(selectedEntity.id);
  }

  onEntityAdded(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.set(selectedEntity.id, selectedEntity);
  }

  getList(): Observable<IOrganisation[]> {
    this.debounce = 0;
    this.searchTerm$.next('');
    return null;
  }

  updateEntities(): void {
    if (this.selectLimit > 0) {
      this.localPreSelectedEntities = new Map(
        [...this.localPreSelectedEntities.entries()].slice(0, this.selectLimit),
      );
    }

    this.showSelectedEntities = cloneDeep(this.localPreSelectedEntities);
    const entitiesToExclude = Array.from(this.localPreSelectedEntities.keys());
    this.entities = this.entitiesAll;
    this.entities = filter(this.entities, (entity: IEntity) => {
      return !entitiesToExclude.includes(entity.entityUid);
    });
  }

  getValues(): string[] {
    const values = new Set<string>();
    Array.from(this.localPreSelectedEntities.values()).forEach((val: ISelectInput) => {
      values.add(val.id);
    });
    return Array.from(values);
  }

  updateText(): void {
    const values: string[] = this.getValues();
    // important not to change this line
    const text = this.entityText;
    if (values.length) {
      this.title = `${text} (+${values.length})`;
    } else {
      this.title = text;
    }
  }

  get limitExceeded(): boolean {
    return this.selectLimit > 0 && this.localPreSelectedEntities.size > this.selectLimit;
  }

  resetInputValue(): void {
    this.searchedText = '';
    this.entitySearchInput.reset();
    this.searchTerm$.next('');
  }
}
