/* eslint-disable @typescript-eslint/naming-convention */
import {
  EventEmitter,
  Output,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { get, omitBy, set } from 'lodash';
import { NullifyCondition } from './types/nullify-condition.type';
import { Autoscroll } from '@portal/shared/ui/form/src/lib/autoscroll/autoscroll.component';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FormBase<T> extends Autoscroll implements OnChanges {
  @Output()
  formValidated: EventEmitter<T> = new EventEmitter();
  @Input() disabled: boolean;

  private _form: FormGroup;
  private _whitelistEmptyValue = new Set<string>();
  private _fieldToOmit = new Set<string>();
  private _fieldsToNullify = new Set<string>();
  private _fieldsToNullifyByCondition = new Map<string, NullifyCondition>();

  constructor(form?: FormGroup, protected elementRef?: ElementRef) {
    super(elementRef);
    this._form = form;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && this.form) {
      this.enableDisableForm();
    }
  }

  enableDisableForm(): void {
    (this.disabled ? this.disableForm : this.enableForm).call(this);
  }

  get form(): FormGroup {
    return this._form;
  }

  disableForm(): void {
    this.form.disable({
      emitEvent: false,
    });
  }

  enableForm(): void {
    this.form.enable({
      emitEvent: false,
    });
  }

  setFormGroup(newFormGroup: FormGroup): void {
    this._form = newFormGroup;
  }

  setFormValue(input: T): void {
    this._form.patchValue(input);
  }

  isEmptyValue(): Function {
    return (value, key: string) => {
      return value === '' && !this._whitelistEmptyValue.has(key);
    };
  }

  omitFieldFromPayload(): Function {
    return (_value, key: string) => {
      return this._fieldToOmit.has(key);
    };
  }

  nullifyEmptyValues(formValues: object): object {
    this._fieldsToNullify.forEach((fieldToNullify: string) => {
      const value = get(formValues, fieldToNullify);
      if (value === '') {
        set(formValues, fieldToNullify, null);
      }
    });
    return formValues;
  }

  nullifyByCondition(formValues: object): object {
    this._fieldsToNullifyByCondition.forEach((condition, fieldToNullify) => {
      const value = get(formValues, fieldToNullify);
      if (condition(value)) {
        set(formValues, fieldToNullify, null);
      }
    });
    return formValues;
  }

  submit(): void {
    this._form.markAllAsTouched();
    if (this._form.valid) {
      // If a backend doesn't allow a field to be set as an empty string,
      // we need to set it to `null` in order to clear its value
      const nullifiedEmptyValues = this.nullifyEmptyValues(this._form.getRawValue());
      const nullifiedByConditionValues = this.nullifyByCondition(nullifiedEmptyValues);
      const removeEmptyValue = omitBy(nullifiedByConditionValues, this.isEmptyValue());
      const sanitized = omitBy(removeEmptyValue, this.omitFieldFromPayload());
      this.formValidated.emit(sanitized);
    }
  }

  set whitelistEmptyValue(newList: Set<string>) {
    this._whitelistEmptyValue = newList;
  }

  get whitelistEmptyValue(): Set<string> {
    return this._whitelistEmptyValue;
  }

  get fieldToOmit(): Set<string> {
    return this._fieldToOmit;
  }

  set fieldToOmit(newList: Set<string>) {
    this._fieldToOmit = newList;
  }

  get fieldsToNullify(): Set<string> {
    return this._fieldsToNullify;
  }

  set fieldsToNullify(newList: Set<string>) {
    this._fieldsToNullify = newList;
  }

  get fieldsToNullifyByCondition(): Map<string, NullifyCondition> {
    return this._fieldsToNullifyByCondition;
  }

  set fieldsToNullifyByCondition(newList: Map<string, NullifyCondition>) {
    this._fieldsToNullifyByCondition = newList;
  }
}
