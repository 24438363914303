import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ComparableDateService } from '@portal/shared/ui/comparable-date-range/src/lib/services/date.service';
import {
  FilterContentItem,
  FILTER_CONTENT_ITEM,
  IFilterToApply,
  ISearchCriteria,
  ISelectInput,
  SearchOperator,
  SearchSelector,
} from '@portal/shared/ui/filter';
import { endOfDay, startOfDay } from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { WLLocaleDatePipe } from './pipes/locale-date.pipe';
import { DateFilterQuickPick } from './enums/quick-pick.enum';
import { IFilterDateSelector } from './interfaces/filter-date-selector.interface';
import { DateFilterQuickPicks } from './lists/quick-picks.list';

declare const $localize;

@Component({
  selector: 'portal-wl-filter-date',
  templateUrl: './date.component.html',
  styleUrls: ['date.component.scss'],
  providers: [
    WLLocaleDatePipe,
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: WLFilterDateComponent,
    },
  ],
})
export class WLFilterDateComponent extends FilterContentItem implements OnChanges {
  @Input() selectedEntities = new Map<string, ISearchCriteria>();
  @Input() quickPick = DateFilterQuickPick.Custom;
  @Input() showQuickPicks = false;
  @Input() showAnyDateQuickPick = false;
  @Input() selectors: IFilterDateSelector[] = [
    {
      id: SearchSelector.Created,
      label: $localize`Created`,
      searchSelectorFrom: SearchSelector.CreatedFrom,
      searchSelectorTo: SearchSelector.CreatedTo,
    },
  ];

  @Output() filterApplied = new EventEmitter<IFilterToApply>();
  @Output() filterUpdated = new EventEmitter<IFilterToApply>();
  @Output() itemToggled = new EventEmitter<number>();
  @Output() quickPickChange = new EventEmitter<DateFilterQuickPick>();

  isAnyDateMode = false;
  isSelectorsVisible = false;
  headerText = $localize`Date`;

  quickPicksList = this.getQuickPickList();
  dateFilterQuickPickEnum = DateFilterQuickPick;
  selectedQuickPick: DateFilterQuickPick;

  fromDate: NgbDate;
  toDate: NgbDate;

  radioName = 'dateFilterRadioSelector';
  selectedRadioMap = new Map<string, ISelectInput>();
  refreshRadioButton$ = new Subject<void>();

  private selectedSelector: IFilterDateSelector;

  constructor(
    private formatter: NgbDateParserFormatter,
    private dateFilterQuickPicks: DateFilterQuickPicks,
    private comparableDateService: ComparableDateService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedQuickPick = this.quickPick;
    this.updateIsAnyDateMode(this.quickPick);

    if (changes?.showAnyDateQuickPick) {
      this.quickPicksList = this.getQuickPickList();
    }

    if (changes?.selectors) {
      this.selectedSelector = this.selectors[0];
    }

    this.update();
    this.refreshRadioButton$.next();
  }

  onFilterApplied(): void {
    if (this.isAnyDateMode) {
      this.quickPickUpdate(true);
      return;
    }

    this.filterUpdate();
    this.filterApplied.emit();
    this.itemToggled.emit();
  }

  getList(): Observable<ISelectInput[]> {
    return null;
  }

  onRadioSelected(selector: IFilterDateSelector): void {
    if (selector) {
      this.selectedSelector = selector;
      this.selectedRadioMap.clear();
      this.selectedRadioMap.set(selector.id, {
        id: selector.id,
        text: selector.label,
        key: selector.id,
      });
    }
  }

  onFromDateApplied(fromDate: NgbDate): void {
    this.fromDate = fromDate;
    this.filterUpdate();
  }

  onToDateApplied(toDate: NgbDate): void {
    this.toDate = toDate;
    this.filterUpdate();
  }

  onQuickPickSelect(quickPickId: DateFilterQuickPick): void {
    this.selectedQuickPick = quickPickId;
    this.updateIsAnyDateMode(quickPickId);
    this.setDateRange(quickPickId);
  }

  areInputsValid(): boolean {
    return Boolean(this.toDate && this.fromDate && this.selectedSelector);
  }

  update(): void {
    this.updateSelector();
    this.updateDates();
    this.updateSelectorsVisibility();
    this.updateDateHeaderText();
  }

  private filterUpdate(): void {
    if (this.isAnyDateMode) {
      this.quickPickUpdate();
      return;
    }

    this.quickPickChange.emit(this.selectedQuickPick);

    const selectorsToRemove = this.selectors.filter(
      (selector) => selector.id !== this.selectedSelector?.id,
    );
    selectorsToRemove.forEach((selector) => {
      this.filterUpdated.emit({
        key: selector.searchSelectorFrom,
        toRemove: true,
      });
      this.filterUpdated.emit({
        key: selector.searchSelectorTo,
        toRemove: true,
      });
    });

    if (this.selectedSelector) {
      if (this.fromDate) {
        this.filterUpdated.emit({
          key: this.selectedSelector.searchSelectorFrom,
          operator: SearchOperator.GreaterThanAndEqualTo,
          selector: this.selectedSelector.searchSelectorFrom,
          argument: startOfDay(this.ngbDateToDate(this.fromDate)).toISOString(),
        });
      }

      if (this.toDate) {
        this.filterUpdated.emit({
          key: this.selectedSelector.searchSelectorTo,
          operator: SearchOperator.LessThanAndEqualTo,
          selector: this.selectedSelector.searchSelectorTo,
          argument: endOfDay(this.ngbDateToDate(this.toDate)).toISOString(),
        });
      }
    }

    this.updateDateHeaderText();
  }

  private quickPickUpdate(isApply = false): void {
    this.quickPickChange.emit(DateFilterQuickPick.AnyDate);
    if (isApply) {
      this.filterApplied.emit();
      this.itemToggled.emit();
    }
  }

  private updateSelector(): void {
    const entitiesSelector = this.selectors.find((selector) =>
      this.selectedEntities.has(selector.searchSelectorFrom),
    );
    this.onRadioSelected(entitiesSelector);
  }

  private updateDates(): void {
    if (this.selectedEntities?.size) {
      const entities = Array.from(this.selectedEntities.values());
      const selectorDateFrom = entities.find(
        (entity) => entity.selector === this.selectedSelector?.searchSelectorFrom,
      );
      const selectorDateTo = entities.find(
        (entity) => entity.selector === this.selectedSelector?.searchSelectorTo,
      );
      this.setDates(selectorDateFrom.argument, selectorDateTo.argument);
    }
  }

  private updateDateHeaderText(): void {
    let selectorPrefix = '';
    if (this.isSelectorsVisible) {
      selectorPrefix = `${this.selectedSelector?.label}: `;
    }

    let dateText = '';
    if (this.isAnyDateMode) {
      dateText = $localize`:date filter option|:Any time`;
    } else if (this.fromDate === this.toDate) {
      dateText = this.formatter.format(this.fromDate);
    } else {
      dateText = `${this.formatter.format(this.fromDate)} — ${this.formatter.format(this.toDate)}`;
    }

    this.headerText = `${selectorPrefix}${dateText}`;
  }

  private updateIsAnyDateMode(quickPick: DateFilterQuickPick): void {
    this.isAnyDateMode = quickPick === DateFilterQuickPick.AnyDate;
  }

  private updateSelectorsVisibility(): void {
    this.isSelectorsVisible = this.selectors.length > 1;
  }

  private setDates(fromDateString: string, toDateString: string): void {
    this.fromDate = this.getNgbDate(fromDateString);
    this.toDate = this.getNgbDate(toDateString);
    this.filterUpdate();
  }

  private setDateRange(quickPickId: DateFilterQuickPick): void {
    const dateRange = this.comparableDateService.getQuickPickDateRange(<any>quickPickId);

    if (dateRange) {
      this.fromDate = this.getNgbDate(dateRange.start);
      this.toDate = this.getNgbDate(dateRange.end);
    }
    this.filterUpdate();
  }

  private getNgbDate(dateString: string): NgbDate {
    const date = new Date(dateString);
    return NgbDate.from({
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    });
  }

  private ngbDateToDate(ngbDate: NgbDate): Date {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  private getQuickPickList(): ISelectInput[] {
    return this.dateFilterQuickPicks.list.filter((item) => {
      return this.showAnyDateQuickPick ? true : item.id !== DateFilterQuickPick.AnyDate;
    });
  }
}
