import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './accordion/accordion.component';
import { AccordionWrapperComponent } from './accordion/accordion-wrapper/accordion-wrapper.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AccordionComponent, AccordionWrapperComponent],
  exports: [AccordionComponent, AccordionWrapperComponent],
})
export class SharedUiAccordionsModule {}
