import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { StoredCredentialType } from '../enums/stored-credential-type.enum';
import sortBy from 'lodash-es/sortBy';

export const storedCredentialTypes = {
  [StoredCredentialType.SignUp]: $localize`Sign up`,
  [StoredCredentialType.Charge]: $localize`Charge`,
};

@Injectable()
export class StoredCredentialTypeList extends SelectInputsList {
  keyValue = storedCredentialTypes;
  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);
}
