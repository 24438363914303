import { Pipe, PipeTransform } from '@angular/core';
import { BlockingRulesetsTransactionProperties } from '../../../modules/blocking-rules/enums/blocking-rulesets-transaction-properties.';

declare const $localize;
@Pipe({ name: 'transactionProperties' })
export class TransactionPropertiesPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    const valuesMap = {
      [BlockingRulesetsTransactionProperties.Amount]: $localize`Amount`,
      [BlockingRulesetsTransactionProperties.CurrencyCode]: $localize`Currency code`,
      [BlockingRulesetsTransactionProperties.CustomerId]: $localize`Customer`,
      [BlockingRulesetsTransactionProperties.CustomerCountryCode]: $localize`Customer country code`,
      [BlockingRulesetsTransactionProperties.CustomerIP]: $localize`Customer IP`,
      [BlockingRulesetsTransactionProperties.CustomerIPCountryCode]: $localize`Customer IP country`,
      [BlockingRulesetsTransactionProperties.IssuerCountryCode]: $localize`Issuer country`,
      [BlockingRulesetsTransactionProperties.EntityId]: $localize`Organisation ID`,
      [BlockingRulesetsTransactionProperties.ThreeDSecureEnrollmentStatus]: $localize`3DS enrollment status`,
      [BlockingRulesetsTransactionProperties.ThreeDSecureAuthenticationStatus]: $localize`3DS authentication status`,
      [BlockingRulesetsTransactionProperties.ThreeDSecureErrorNo]: $localize`3DS error`,
      [BlockingRulesetsTransactionProperties.FraudScore]: $localize`Fraud Score`,
    };

    return valuesMap[value] || value;
  }
}
