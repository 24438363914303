import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import {
  paginationInitialState,
  IApp,
  IStorePagination,
} from '@portal/marketplace/api-interfaces/src';

export interface DevAppCatalogState extends IStorePagination<IApp> {} //EntityState<IDevApp>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'dev-app-catalog', idKey: 'id', resettable: true })
export class DevAppCatalogStore extends EntityStore<DevAppCatalogState, IApp> {
  constructor() {
    super(paginationInitialState);
  }
}
