import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';
import { RouteName } from './route-name.enum';

const FULL_ACCESS_READ_CREATE_UPDATE = [
  UserRoles.MerchantAdmin,
  UserRoles.VerifoneMaster,
  UserRoles.VerifoneAdmin,
];

const FULL_ACCESS_ROLES = [UserRoles.MerchantAdmin, UserRoles.VerifoneAdmin];

export const RoutePermissions = {
  [RouteName.Details]: {
    read: [...FULL_ACCESS_ROLES, ...FULL_ACCESS_READ_CREATE_UPDATE],
    'details-organization-group': [...FULL_ACCESS_ROLES, ...FULL_ACCESS_READ_CREATE_UPDATE],
    'edit-details-organization-group': [...FULL_ACCESS_READ_CREATE_UPDATE],
  },
  [RouteName.Create]: {
    read: [...FULL_ACCESS_READ_CREATE_UPDATE],
    'cancel-organization-group': [...FULL_ACCESS_READ_CREATE_UPDATE],
    'discard-changes-organization-group': [...FULL_ACCESS_READ_CREATE_UPDATE],
    'save-organization-group': [...FULL_ACCESS_READ_CREATE_UPDATE],
  },
  [RouteName.Edit]: {
    read: [...FULL_ACCESS_READ_CREATE_UPDATE],
    'edit-organization-group': [...FULL_ACCESS_READ_CREATE_UPDATE],
  },
};
