import { Injectable } from '@angular/core';
import iso3166 from 'iso-3166-2';
import { ICountrySubdivision } from '@portal/entity-services/interfaces/src/lib/organisations/interfaces/country-subdivion.interface';
import intersection from 'lodash-es/intersection';
import { FirstPartOfSubdivisionsList } from '@portal/shared/countries/src/lib/services/country-subdivisions/first-part-of-subdivisions.list';
import { SecondPartOfSubdivisionsList } from '@portal/shared/countries/src/lib/services/country-subdivisions/second-part-of-subdivisions.list';
import { ThirdPartOfSubdivisionsList } from '@portal/shared/countries/src/lib/services/country-subdivisions/third-part-of-subdivisions.list';
import { FourthPartOfSubdivisionsList } from '@portal/shared/countries/src/lib/services/country-subdivisions/fourth-part-of-subdivisions.list';
import { FifthPartOfSubdivisionsList } from '@portal/shared/countries/src/lib/services/country-subdivisions/fifth-part-of-subdivisions.list';

@Injectable({
  providedIn: 'root',
})
export class CountrySubdivisionHelper {
  constructor(
    private firstPartOfSubdivisionsList: FirstPartOfSubdivisionsList,
    private secondPartOfSubdivisionsList: SecondPartOfSubdivisionsList,
    private thirdPartOfSubdivisionsList: ThirdPartOfSubdivisionsList,
    private fourthPartOfSubdivisionsList: FourthPartOfSubdivisionsList,
    private fifthPartOfSubdivisionsList: FifthPartOfSubdivisionsList,
  ) {}

  getSubdivisionFromCountryCode(countryCode: string): ICountrySubdivision[] {
    if (countryCode) {
      const countrySubdivisions = iso3166.country(countryCode)?.sub || [];
      const allSubdivisions = {
        ...this.firstPartOfSubdivisionsList.keyValue,
        ...this.secondPartOfSubdivisionsList.keyValue,
        ...this.thirdPartOfSubdivisionsList.keyValue,
        ...this.fourthPartOfSubdivisionsList.keyValue,
        ...this.fifthPartOfSubdivisionsList.keyValue,
      };
      const subdivisionKeys = Object.keys(allSubdivisions);
      const selectedCountrySubdivisionKeys = Object.keys(countrySubdivisions);
      const subdivisionsIntersection = intersection(
        subdivisionKeys,
        selectedCountrySubdivisionKeys,
      );
      return subdivisionsIntersection.map((code) => ({
        code,
        name: allSubdivisions[code],
      }));
    }
    return [];
  }

  getSubdivisionNameFromCode(subdivisionCode: string): string {
    if (!subdivisionCode) {
      return null;
    }
    return iso3166.subdivision(subdivisionCode)?.name;
  }
}
