import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AuthorizationService } from './authorization.service';

@Injectable()
export class AuthorizationGuard implements CanActivate {
  constructor(private authorizationService: AuthorizationService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authorizationService.isAuthorized().pipe(
      take(1),
      tap((isAuthorized) => {
        if (!isAuthorized) {
          this.router.navigate(['/access-denied'], { skipLocationChange: true });
        }
      }),
    );
  }
}
