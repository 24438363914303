export enum SearchSelector {
  Created = 'created',
  CreatedFrom = 'createdFrom',
  CreatedTo = 'createdTo',
  CreatedAt = 'createdAt',
  StartDate = 'startDate',
  StartTime = 'startTime',
  EndDate = 'endDate',
  EndDateFrom = 'endDateFrom',
  EndDateTo = 'endDateTo',
  EndTime = 'endTime',
  RefId = 'refId',
  Amount = 'amount',
  PaymentMethod = 'paymentMethod',
  MerchantName = 'merchantName',
  EptLabel = 'eptLabel',
  AcquirerSettlementId = 'acquirerSettlementId',
  Acquirer = 'acquirer',
  Stan = 'stan',
  TransactionType = 'transactionType',
  AcquirerStatus = 'acquirerStatus',
  ContractId = 'contractId',
  ContractIdExt = 'contractIdExt',
  Currency = 'currency',
  CurrencyCode = 'currencyCode',
  CheckoutCustomerEmail = 'customer.email_address',
  CheckoutCustomerPhone = 'customer.phone_number',
  CheckoutCustomerFirstName = 'customer.billing.first_name',
  CheckoutCustomerLastName = 'customer.billing.last_name',
  Brand = 'brand',
  BatchId = 'batchId',
  BatchUUID = 'batchUUID',
  BatchNumber = 'batchNumber',
  BatchType = 'batchType',
  BatchStatus = 'batchStatus',
  Rrn = 'rrn',
  TransactionId = 'transactionId',
  ProcessorTransactionId = 'processorTransactionId',
  MerchantId = 'merchantId',
  ExternalId = 'externalId',
  TerminalId = 'poiId',
  ContractTerminalId = 'vfiPoiId',
  TerminalIdVfi = 'altVfiPoiId',
  AltVfiEntityId = 'altVfiEntityId',
  VfiMerchantId = 'vfiMerchantId',
  ParentIds = 'parentIds',
  SerialNumber = 'serialNumber',
  ResponseCode = 'responseCode',
  SubSite = 'subSite',
  AccountType = 'accountType',
  AcquirerAccountType = 'acquirerAccountType',
  TerminalName = 'terminalName',
  SysAccId = 'sysAccId',
  PoiId = 'poiId',
  GatewayTraceId = 'gatewayTraceId',
  TransactionNo = 'transactionNo',
  SettlementBatchFileNumber = 'settlementBatchFileNumber',
  SettlementEntityUid = 'entityUid',
  Channel = 'channel',
  EntityUid = 'entityUid',
  EntityId = 'entityId',
  AncestorId = 'ancestorId',
  CardToken = 'cardToken',
  MaskedCardNumber = 'maskedCardNumber',
  CardNumber = 'CardNumber',
  RelatedTransaction = 'followOnTransaction',
  AnalyticsToken = 'analyticsToken',
  CustomerId = 'customerId',
  MerchantReference = 'refId',
  MerchantReferenceId = 'merchantReferenceId',
  MerchantReferenceName = 'merchantReference',
  HeaderState = 'headerState',
  PaymentSummaryState = 'paymentSummaryState',
  SettledDate = 'settledDate',
  SettledDateFrom = 'settledDateFrom',
  SettledDateTo = 'settledDateTo',
  Name = 'name',
  UpdatedDate = 'updatedDate',
  OrderStatus = 'status',
  ReportName = 'reportParameter=fileName',
  ReportType = 'reportType',
  PaymentType = 'paymentType',
  ReportUid = 'reportUid',
  ServiceProvider = 'type',
  SubscriptionId = 'subscriptionId',
  CountryCodeAlpha3 = 'countries',
  DisplayTransactionType = 'displayTransactionType',
  DisplayTransactionState = 'displayTransactionState',
  TransactionState = 'transactionState',
  DisputeStatuses = 'paymentSummaryDisputeStatuses',
  AuthenticationId = 'authenticationId',
  SecureParesState = 'secureParesState',
  FromDate = 'fromDate',
  ToDate = 'toDate',
  PaymentStatus = 'paymentStatus',
  PaymentContractId = 'paymentContractId',
  InstrumentType = 'instrumentType',
  Wallet = 'wallet',
  DateRange = 'dateRange',
  CompareTo = 'compareTo',
  Hierarchy = 'hierarchy',
  StoredCredentialType = 'storedCredentialType',
  Email = 'email',
  Url = 'url',
  Processor = 'processor',
  RecordId = 'recordId',
  EventType = 'eventType',
  ReUseToken = 'reuseToken',
  SalesDescription = 'salesDescription',
  Entities = 'entities',
  SourcePim = 'sourcePIM',
  SourceId = 'sourceId',
  UserUid = 'userUid',
  Users = 'users',
  EventTypes = 'eventTypes',
  VerifoneStoredCredentialReference = 'storedCredentialReference',
  SchemeReference = 'storedCredentialSchemeReference',
  SchemeReferenceData = 'schemeReferenceData',
  Frequency = 'frequency',
  Status = 'status',
  SchedulerName = 'schedulerName',
  ScheduledOn = 'scheduledOn',
  Organization = 'organization',
  ProductFilter = 'product_filter',
  InvoiceNumber = 'invoiceNumber',
  EntityType = 'entityType',
  TokenizationReuseToken = 'tokenizationReuseToken',
  OrderId = 'orderId',
  ReuseTokenContext = 'reuseTokenContext',
  InstrumentReuseToken = 'instrumentReuseToken',
  CashierId = 'cashierId',
  ReportingGroup = 'reportingGroup',
  SiteReferenceId = 'siteReferenceId',
  CreditorReference = 'creditorReference',
  SettlementBatchId = 'batchUUID',
  TransactionDay = 'transactionDay',
  SettlementStartTimeFrom = 'settlementStartTimeFrom',
  SettlementStartTimeTo = 'settlementStartTimeTo',
  SettlementStartTime = 'settlementStartTime',
  TransactionDayFrom = 'transactionDayFrom',
  TransactionDayTo = 'transactionDayTo',
  PayoutType = 'payoutType',
  SubBatchId = 'subBatchUid',
  FundingSource = 'fundingSource',
  TransactionUUID = 'transactionUUID',
  Created_At = 'created_at',
  ItemID = 'itemId',
  AuthenticationDsTransactionId = 'authentication.ds_transaction_id',
  TransactionDate = 'transactionDate',
  CustomerEmail = 'customerEmail',
}
