import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppTagComponent } from './app-tag.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AppTagComponent],
  exports: [AppTagComponent],
})
export class AppTagModule {}
