<div class="control select">
  <select
    [(ngModel)]="itemSelected"
    (ngModelChange)="onSelect(itemSelected)"
    data-e2e="select-app-version"
  >
    <option *ngFor="let item of items" [ngValue]="item.id" [attr.data-e2e]="item.id">
      {{ item.id }}
    </option>
  </select>
</div>
