import { ISelectInput } from '@portal/shared/ui/filter/src/lib/box/interfaces/select-input.interface';
import map from 'lodash-es/map';
import { TranslationsKeyValue } from './interfaces/translations-key-value.interface';

export abstract class SelectInputsList {
  abstract readonly keyValue: TranslationsKeyValue;
  abstract readonly list: ISelectInput[];

  lookup(key: string): ISelectInput {
    return this.list.find(({ id }) => id === key);
  }

  getValue(key: string, defaultValue = ''): string {
    return this.keyValue[key] || defaultValue;
  }

  protected getSelectInputs(keyValue?: TranslationsKeyValue): ISelectInput[] {
    const targetKeyValue = keyValue || this.keyValue;
    return map(targetKeyValue, (value: string, key: string) => {
      return {
        id: key,
        text: value,
      };
    });
  }
}
