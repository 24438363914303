import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OnKeyPressDirective } from './on-key-press/on-key-press.directive';

@NgModule({
  imports: [CommonModule],
  exports: [OnKeyPressDirective],
  declarations: [OnKeyPressDirective],
})
export class OnKeyPressModule {}
