/* IMPORTANT NOTE : Name of the enum is 'R.E. field id' and value is 'column name'
  If 'R.E. field id' is updated please update this in report-template-field.enum.ts right side value as well
*/
export enum ReportTemplateFieldColumnName {
  'organisation.name' = 'Organization_name',
  Entity = 'Internal_organization_ID',
  merchant_id = 'Merchant_ID',
  created_at_datetime = 'Created_datetime',
  created_at_timezone = 'Timezone',
  created_at_utc = 'Created_in_UTC',
  _id = 'Transaction_ID',
  Reference = 'Reference',
  Product = 'Product',
  'Orig.amount' = 'Original_amount',
  'Orig.amount.currencyCode' = 'Original_currency',
  'Curr.amount' = 'Current_amount',
  'Curr.amount.currencyCode' = 'Current_currency',
  transaction_type = 'Transaction_type',
  state = 'Transaction_state',
  shopper_interaction = 'Channel',
  arn = 'ARN',
  RRN = 'RRN',
  STAN = 'STAN',
  transaction_description = 'Transaction_description',
  settlement_date = 'Settlement_date',
  settlement_id = 'Settlement_ID',
  batch_id = 'Batch_ID',
  terminal_id = 'Terminal_ID',
  'customer._id' = 'Customer_ID',
  'card.bin' = 'Card_bin',
  'card.last_four' = 'Card_last_four_digits',
  'card.issuer_country' = 'Card_issuer_country',
  'customer.billing.city' = 'Customer_billing_city',
  'customer.billing.country_code' = 'Customer_billing_country',
  'customer.billing.first_name' = 'Customer_first_name',
  'customer.email_address' = 'Customer_email',
  'shipping_information.address' = 'Customer_shipping_address',
  'shipping_information.city' = 'Customer_shipping_city',
  'shipping_information.country' = 'Customer_shipping_country',
  'shipping_information.email' = 'Customer_shipping_email',
  'shipping_information.first_name' = 'Customer_shipping_name',
  'shipping_information.phone' = 'Customer_shipping_phone',
  'shipping_information.postal_code' = 'Customer_shipping_postal code',
  'shipping_information.state' = 'Customer_shipping_state',
  estate_management_id = 'Estate_management_ID',
  site_reference_id = 'External_site_reference_ID',
  acquirer_response_code = 'Acquirer_response_code',
  acquirer_authorisation_result = 'Acquirer_authorisation_result',
  issuer_authorisation_result = 'Issuer_authorisation_result',
  id_check_result = 'Customer_ID_check',
  electronic_commerce_indicator = '3D_secure_eci',
  cavv_result = 'CAVV_result',
  credit_term = 'Credit_term',
  issuer_instalment_down_payment_amount = 'Issuer_instalment_down_payment_amount',
  issuer_instalment_instalment_amount = 'Issuer_instalment_amount',
  issuer_instalment_number_of_instalments = 'Issuer_instalments_count',
  processor_card_brand = 'Processor_card_brand',
  processor_acquirer_name = 'Processor_acquirer_name',
  processor_issuer_name = 'Processor_issuer_name',
  technology = 'Payment_technology',
  merchant_country = 'Merchant_country',
  card_issuer_country = 'Fee_configuration_card_issuer_country',
  fallback_transaction = 'Fallback_transaction',
  payment_mode = 'Fee_configuration_card_type',
  payment_processing_fee_amount = 'Processing_fee',
  fee_currency = 'Processing_fee_currency',
  user_agent = 'Browser_version',
  payment_product_type = 'Payment_product_type',
  avs_result_code = 'AVS_result_code',
  masked_card_number = 'Masked_card_number',
  device_serial_number = 'Device_serial_number',
  acquirer_response_message = 'Acquirer_response',
  processor_rrn = 'Processor_RRN',
  deferred_months = 'Deferred_month',
  payment_method = 'Payment_method',
  issuer_instalment_payment_index = 'Issuer_installment_payment_index',
  processor_transaction_id = 'Processor_transaction_ID',
  due_date = 'Due_date',
  'customer.app_phone_number' = 'Customer_app_phone',
  entry_mode = 'Fee_entry_mode',
  sales_description = 'Sales_description',
  cashier_id = 'Cashier_ID',
  account_type = 'Account_type',
  acquirer_account_type = 'Acquirer_account_type',
  sourcePIM = 'Source_PIM',
  payment_document_provider = 'Payment_document_provider',
  payment_document_type = 'Payment_document_type',
  payment_document_id = 'Payment_document_ID',
  third_party_transaction_reference = 'Third_party_reference',
  cashier_name = 'Cashier_name',
  acquirer_merchant_id = 'Acquirer_merchant_ID(MID)',
  merchant_reference = 'Merchant_reference',
  token_type = 'Token_type',
  stored_credentials_type = 'Stored_credential_type',
  tax_indicator = 'Tax_indicator',
  tax_amount = 'Tax_amount',
  purchase_order_number = 'PO_number',
  authorisation_code = 'Authorisation_code',
  cheque_account_number = 'Cheque_account_number',
  cheque_number = 'Cheque_number',
  response_code = 'Transaction_response_code',
  fraud_score = 'Fraud_score',
  fraud_reason_insights = 'Fraud_reason_insight',
  Cashback_amount = 'Cashback_amount',
  Gratuity_amount = 'Gratuity_amount',
  Charitable_Donation = 'Charity_donation_amount',
  'Verifone Terminal ID' = 'Internal_terminal_ID',
  'Store Identifier' = 'Store_identifier',
  'PPC ID' = 'PPC_ID',
  'Parent Transaction ID' = 'Parent_transaction_ID',
  'Invoice Number' = 'Invoice_number',
  'Card Exp Month' = 'Card_expiry_month',
  'Card Exp Year' = 'Card_expiry_year',
  'Stored Credential Reference' = 'Stored_credential_reference',
  transaction_timezone = 'Transaction_timezone',
  created_at_date = 'Creation_date',
  created_at_time = 'Creation_time',
  fraud_reason_code = 'Fraud_reason_code',
  poi_name = 'POI_name',
  'Sales channel' = 'Sales_channel',
  'Acquirer Settlement ID' = 'Acquirer_settlement_ID',
  'Transaction online flag' = 'Transaction_online_flag',
  'Merchant present' = 'Merchant_present',
  'Capture status' = 'Capture_status',
  'Confirm date time' = 'Confirm_date_time',
  'Message no' = 'Message_no',
  'CVV result' = 'CVV_result',
  'Auth date time' = 'Auth_date_time',
  'Payment Account Reference' = 'Payment_account_reference',
  'Scheme identifier' = 'Scheme_identifier',
  'Tax discount' = 'Tax_discount',
  Acquirer = 'Acquirer',
  PRM_type = 'PRM_type',
  Ancestor_name = 'Ancestor_name',
  Authentication_mode = 'Authentication_mode',
  '3DS_status' = '3DS_status',
  '3DS_version' = '3DS_version',
  Terminal_manufacturer = 'Terminal_manufacturer',
  SSN_ICC_ID = 'SSN_ICC_ID',
  Cross_border_fee = 'Cross_border_fee',
  Platform_fee = 'Platform_fee'


}
