export enum UisAuthenticationErrors {
  NoSuchAttribute = 'No Such Attribute',
  ChangedPasswordConstraintViolation = 'constraint.violation',
  ConstraintViolation = 'Constraint Violation',
  LinkExpired = 'magicLink.date.expired',
  NameOrPasswordIncorrect = 'username.or.password.is.incorrect',
  Unexpected = 'Unexpected',
  UserDisabled = 'user.disabled',
  UserLockedOut = 'user.locked.out',
  PasswordExpired = 'password.is.expired',
  Other = 'Other',
}
