import { Pipe, PipeTransform } from '@angular/core';
import { IVersionUpdate } from '@portal/marketplace/api-interfaces/src';
import { IVersionData } from '@portal/marketplace/api-interfaces/src/lib/apps/IVersionData';

@Pipe({ name: 'sortVersions' })
export class SortVersionsPipe implements PipeTransform {
  transform(versions: IVersionData[]): IVersionData[] {
    return versions
      .map((a) => {
        return {
          version: a.version
            .split('.')
            .map((n) => +n + 1000000)
            .join('.'),
          id: a.id,
        };
      })
      .sort((a: IVersionData, b: IVersionData) => {
        if (a.version > b.version) {
          return 1;
        }
        if (a.version < b.version) {
          return -1;
        }
        return 0;
      })
      .map((a) => {
        return {
          version: a.version
            .split('.')
            .map((n) => +n - 1000000)
            .join('.'),
          id: a.id,
        };
      });
  }
}

@Pipe({ name: 'sortVersionsUpdates' })
export class SortVersionsUpdatesPipe implements PipeTransform {
  transform(versions: IVersionUpdate[]): IVersionUpdate[] {
    return versions
      .map((a) => {
        return {
          appVersion: a.appVersion
            .split('.')
            .map((n) => +n + 1000000)
            .join('.'),
          appName: a.appName,
          appPrice: a.appPrice,
          appVersionId: a.appVersionId,
          appmarketAppId: a.appmarketAppId,
          availableForInstall: a.availableForInstall,
          availableForUpgrade: a.availableForUpgrade,
          sumAssignmentsBundle: a.sumAssignmentsBundle,
          sumAssignmentsRegular: a.sumAssignmentsRegular,
          timeCreated: a.timeCreated,
        };
      })
      .sort((a: IVersionUpdate, b: IVersionUpdate) => {
        if (a.appVersion > b.appVersion) {
          return 1;
        }
        if (a.appVersion < b.appVersion) {
          return -1;
        }
        return 0;
      })
      .map((a) => {
        return {
          appVersion: a.appVersion
            .split('.')
            .map((n) => +n - 1000000)
            .join('.'),
          appName: a.appName,
          appPrice: a.appPrice,
          appVersionId: a.appVersionId,
          appmarketAppId: a.appmarketAppId,
          availableForInstall: a.availableForInstall,
          availableForUpgrade: a.availableForUpgrade,
          sumAssignmentsBundle: a.sumAssignmentsBundle,
          sumAssignmentsRegular: a.sumAssignmentsRegular,
          timeCreated: a.timeCreated,
        };
      });
  }
}
