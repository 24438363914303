import { Injectable } from '@angular/core';
import { IApp } from '@portal/marketplace/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class AppCatalogService {
  constructor() {}
  hasInstalls(app: IApp): boolean {
    if (app.versionsToUpdate && app.versionsToUpdate.length > 0) {
      const versionToUpdate = app.versionsToUpdate.find((version) => {
        return app.id === version.appVersionId;
      });
      return !!(versionToUpdate.sumAssignmentsRegular + versionToUpdate.sumAssignmentsBundle);
    }
    return false;
  }
  hasUpdates(app: IApp): boolean {
    if (app && app.versionsToUpdate && app.versionsToUpdate.length > 0) {
      const index = app.versionsToUpdate.findIndex((version) => {
        return version.appVersionId === app.id;
      });
      return app.versionsToUpdate[index + 1]
        ? app.versionsToUpdate[index + 1].availableForUpgrade
        : false;
    }
    return false;
  }
}
