<div class="field has-addons width-full">
  <div class="control is-addon min-width-min-content" [portalTooltip]="getTooltipTextAttribute()">
    <ng-container *ngIf="!currencyIsSelectable">
      <span class="button is-static width-full" data-e2e="empty-currency" *ngIf="!currency.value">
        &nbsp;- &nbsp;
      </span>
      <span class="button is-static width-full" data-e2e="currency-value" *ngIf="currency.value">
        {{ currency.value }}
      </span>
    </ng-container>
    <div class="select min-width-min-content" *ngIf="currencyIsSelectable">
      <select
        class="is-size-7-mobile min-width-min-content"
        [formControl]="currency"
        data-e2e="select-currency"
        (change)="onCurrencySelected()"
      >
        <option
          *ngFor="let currencyOption of currencies"
          [value]="currencyOption.code"
          [selected]="currencyOption.code === currency?.value"
        >
          {{ currencyOption.code }}
        </option>
      </select>
    </div>
  </div>
  <div class="control pl-0">
    <input
      class="input is-size-7-mobile"
      [class.is-danger]="invalid"
      currencyMask
      (input)="onInputChange($event)"
      [options]="amountMask"
      [formControl]="amount"
      [autocomplete]="false"
      [attr.id]="id"
      [maxlength]="maxLength ? maxLength : false"
      (ngModelChange)="onAmountSelected()"
      data-e2e="input-amount"
      pattern="\d*\.?\d*"
      type="text"
      inputmode="decimal"
    />
  </div>
</div>
