import { Injectable } from '@angular/core';
import { SelectInputsList } from '../../../../shared/helpers/src';
import { Fonts } from '../lib/enums/fonts.enum';

@Injectable({
  providedIn: 'root',
})
export class FontsList extends SelectInputsList {
  keyValue = {
    [Fonts.Roboto]: 'Roboto',
    [Fonts.OpenSans]: 'Open Sans',
    [Fonts.Lato]: 'Lato',
    [Fonts.Slabo]: 'Slabo',
    [Fonts.Oswald]: 'Oswald',
    [Fonts.SourceSansPro]: 'Source Sans Pro',
    [Fonts.Montserrat]: 'Montserrat',
    [Fonts.Raleway]: 'Raleway',
    [Fonts.PTSans]: 'PT Sans',
    [Fonts.Lora]: 'Lora',
    [Fonts.NotoSans]: 'Noto Sans',
    [Fonts.NotoSerif]: 'Noto Serif',
    [Fonts.NunitoSans]: 'Nunito Sans',
    [Fonts.ConcertOne]: 'Concert One',
    [Fonts.Prompt]: 'Prompt',
  };

  list = this.getSelectInputs();
}
