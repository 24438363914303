import { Injectable } from '@angular/core';
import { IDateFilter, DateFilter } from '@portal/shared/helpers';
import { QuickPicks } from '../enums/date.enum';
import {
  endOfDay,
  startOfDay,
  subDays,
  subYears,
  subWeeks,
  startOfWeek,
  endOfWeek,
  subMonths,
  startOfMonth,
  endOfMonth,
  differenceInDays,
  areIntervalsOverlapping,
} from 'date-fns';
import cloneDeep from 'lodash-es/cloneDeep';
import { Week } from '../enums/week.enum';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateFilterQuickPick } from '@apps/portal/src/app/shared/components/filter/date/enums/quick-pick.enum';

@Injectable({
  providedIn: 'root',
})
export class ComparableDateService {
  constructor(private calendar: NgbCalendar) {}

  getDateRangeQPStartEndDate(input: QuickPicks): IDateFilter {
    const quickPickMap = {
      [QuickPicks.Today]: this.getTodayDateFilter(),
      [QuickPicks.Yesterday]: this.getYesterdayDateFilter(),
      [QuickPicks.LastWeek]: this.getLastWeekDateFilter(),
      [QuickPicks.LastMonth]: this.getLastMonthDateFilter(),
      [QuickPicks.Last7Days]: this.getLast7DaysDateFilter(),
      [QuickPicks.Last30Days]: this.getLast30DaysDateFilter(),
      [QuickPicks.Last18Months]: this.getLast18MonthsDateFilter(),
    };
    return quickPickMap[input];
  }

  getQuickPickDateRange(quickPick: QuickPicks | DateFilterQuickPick): IDateFilter {
    switch (quickPick) {
      case QuickPicks.Today:
        return this.getTodayDateFilter();
      case QuickPicks.Yesterday:
        return this.getYesterdayDateFilter();
      case QuickPicks.LastWeek:
        return this.getLastWeekDateFilter();
      case QuickPicks.LastMonth:
        return this.getLastMonthDateFilter();
      case QuickPicks.Last7Days:
        return this.subtractDaysFromToday(DateFilter.Last7Days);
      case QuickPicks.Last30Days:
        return this.subtractDaysFromToday(DateFilter.Last30Days);
      case QuickPicks.Last18Months:
        return this.subtractDaysFromToday(DateFilter.Last18Months);
      case QuickPicks.Custom:
        return this.subtractDaysFromToday(DateFilter.Last7Days);
      default:
        return this.subtractDaysFromToday(DateFilter.Last7Days);
    }
  }

  getLast24HoursDates(): IDateFilter {
    return this.subtractDaysFromToday(1);
  }

  getCompareToQPStartEndDate(
    input: QuickPicks,
    selectedQPFromDateRange: QuickPicks,
    date?: IDateFilter,
  ): IDateFilter {
    let quickPickMap;

    if (selectedQPFromDateRange === QuickPicks.Custom) {
      quickPickMap = {
        [QuickPicks.PreviousPeriod]: this.getCompareToPreviousPeriod(selectedQPFromDateRange, date),
        [QuickPicks.PreviousYear]: this.getCompareToPreviousYear(selectedQPFromDateRange, date),
      };
    } else {
      quickPickMap = {
        [QuickPicks.PreviousPeriod]: this.getCompareToPreviousPeriod(selectedQPFromDateRange, date),
        [QuickPicks.PreviousYear]: this.getCompareToPreviousYear(selectedQPFromDateRange),
      };
    }

    return quickPickMap[input];
  }

  getCompareToPreviousPeriod(input: QuickPicks, date?: IDateFilter): IDateFilter {
    let previousPeriod: IDateFilter;
    switch (input) {
      case QuickPicks.Today:
        previousPeriod = this.getYesterdayDateFilter();
        break;
      case QuickPicks.Yesterday:
        previousPeriod = this.getSubtractDay(2);
        break;
      case QuickPicks.LastWeek:
        previousPeriod = this.getSubtractWeek(2);
        break;
      case QuickPicks.Last7Days:
        previousPeriod = this.getSubtractDaysToPreviousPeriod(DateFilter.Last7Days * 2, false);
        break;
      case QuickPicks.Last30Days:
        previousPeriod = this.getSubtractDaysToPreviousPeriod(DateFilter.Last30Days * 2, false);
        break;
      case QuickPicks.LastMonth:
      case QuickPicks.Custom:
        previousPeriod = this.getCustomPreviousPeriod(date);
        break;
    }
    return previousPeriod;
  }

  getCompareToPreviousYear(input: QuickPicks, date?: IDateFilter): IDateFilter {
    let previousYear: IDateFilter;
    switch (input) {
      case QuickPicks.Today:
        previousYear = this.getSubtractYear(
          new Date(this.getTodayDate().toISOString()),
          new Date(this.getTodayDate().toISOString()),
          1,
        );
        break;
      case QuickPicks.Yesterday:
        previousYear = this.getSubtractYear(
          new Date(this.getYesterdayDateFilter().start),
          new Date(this.getYesterdayDateFilter().end),
          1,
        );
        break;
      case QuickPicks.LastWeek:
        previousYear = this.getSubtractYear(
          new Date(this.getLastWeekDateFilter().start),
          new Date(this.getLastWeekDateFilter().end),
          1,
        );
        break;
      case QuickPicks.LastMonth:
        previousYear = this.getSubtractYear(
          new Date(this.getLastMonthDateFilter().start),
          new Date(this.getLastMonthDateFilter().end),
          1,
        );
        break;
      case QuickPicks.Last7Days:
        previousYear = this.getSubtractYear(
          new Date(this.getLast7DaysDateFilter().start),
          new Date(this.getLast7DaysDateFilter().end),
          1,
        );
        break;
      case QuickPicks.Last30Days:
        previousYear = this.getSubtractYear(
          new Date(this.getLast30DaysDateFilter().start),
          new Date(this.getLast30DaysDateFilter().end),
          1,
        );
        break;
      case QuickPicks.Custom:
        previousYear = this.getSubtractYear(new Date(date.start), new Date(date.end), 1);
        break;
    }
    return previousYear;
  }

  getLast7DaysDateFilter(): IDateFilter {
    return this.getSubtractDaysToPreviousPeriod(DateFilter.Last7Days, true);
  }

  getTodaysDateFilter(): IDateFilter {
    return this.subtractDaysFromToday(0);
  }
  getYesterdaysDateFilter(): IDateFilter {
    return this.getYesterdayDateFilter();
  }

  getDateRangesOverlappingState(dateRange: IDateFilter, compareTo: IDateFilter): boolean {
    return areIntervalsOverlapping(
      { start: new Date(dateRange.start), end: new Date(dateRange.end) },
      { start: new Date(compareTo.start), end: new Date(compareTo.end) },
    );
  }

  checkValidDateRange(dateRange: IDateFilter): boolean {
    return new Date(dateRange.end).getTime() - new Date(dateRange.start).getTime() > 0;
  }

  dateIsBiggerThanCurrentDate(date: NgbDate): boolean {
    const maxDate = this.calendar.getToday();
    return date.after(maxDate);
  }

  getDifferenceInDays(date: IDateFilter): number {
    return Math.abs(differenceInDays(new Date(date.start), new Date(date.end)));
  }

  private getTodayDate(): Date {
    const currentDate: Date = new Date();
    return cloneDeep(currentDate);
  }

  private getSubtractDay(amount: number): IDateFilter {
    return {
      start: startOfDay(subDays(this.getTodayDate(), amount)).toISOString(),
      end: endOfDay(subDays(this.getTodayDate(), amount)).toISOString(),
    };
  }

  private getSubtractWeek(amount: number): IDateFilter {
    return {
      start: subWeeks(
        startOfWeek(this.getTodayDate(), { weekStartsOn: Week.Monday }),
        amount,
      ).toISOString(),
      end: subWeeks(
        endOfWeek(this.getTodayDate(), { weekStartsOn: Week.Monday }),
        amount,
      ).toISOString(),
    };
  }

  private getSubtractMonth(amount: number): IDateFilter {
    return {
      start: startOfMonth(subMonths(this.getTodayDate(), amount)).toISOString(),
      end: endOfMonth(subMonths(this.getTodayDate(), amount)).toISOString(),
    };
  }

  private getSubtractDaysToPreviousPeriod(amount: number, tillPreviousDay: boolean): IDateFilter {
    return tillPreviousDay
      ? {
          start: subDays(startOfDay(this.getTodayDate()), amount).toISOString(),
          end: endOfDay(subDays(this.getTodayDate(), 1)).toISOString(),
        }
      : {
          start: subDays(startOfDay(this.getTodayDate()), amount).toISOString(),
          end: endOfDay(subDays(this.getTodayDate(), amount / 2 + 1)).toISOString(),
        };
  }

  private subtractDaysFromToday(amount: number): IDateFilter {
    return {
      start: subDays(startOfDay(this.getTodayDate()), amount).toISOString(),
      end: endOfDay(this.getTodayDate()).toISOString(),
    };
  }

  private getCustomPreviousPeriod(date: IDateFilter): IDateFilter {
    const result = Math.abs(differenceInDays(new Date(date.start), new Date(date.end)));
    return {
      start: startOfDay(subDays(new Date(date.start), result + 1)).toISOString(),
      end: endOfDay(subDays(new Date(date.start), 1)).toISOString(),
    };
  }

  private getSubtractYear(startDate: Date, endDate: Date, amount: number): IDateFilter {
    return {
      start: startOfDay(subYears(startDate, amount)).toISOString(),
      end: endOfDay(subYears(endDate, amount)).toISOString(),
    };
  }

  private getTodayDateFilter(): IDateFilter {
    return {
      start: startOfDay(this.getTodayDate()).toISOString(),
      end: this.getTodayDate().toISOString(),
    };
  }

  private getYesterdayDateFilter(): IDateFilter {
    return this.getSubtractDay(1);
  }

  private getLastWeekDateFilter(): IDateFilter {
    return this.getSubtractWeek(1);
  }

  private getLastMonthDateFilter(): IDateFilter {
    return this.getSubtractMonth(1);
  }

  private getLast30DaysDateFilter(): IDateFilter {
    return this.getSubtractDaysToPreviousPeriod(DateFilter.Last30Days, true);
  }

  private getLast18MonthsDateFilter(): IDateFilter {
    return this.getSubtractDaysToPreviousPeriod(DateFilter.Last18Months, true);
  }
}
