<div class="card sidepanel" [ngClass]="{ 'is-active': !activeState }">
  <div class="columns">
    <div class="column side-panel-header">
      <span class="title" *ngIf="title">{{ title }}</span>
      <button
        class="button is-transparent close-detail"
        (click)="close()"
        data-e2e="button-close-sidepanel"
        type="button"
        title="Close"
        i18n-title
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>

  <div class="side-panel-content">
    <ng-content></ng-content>
  </div>
</div>
