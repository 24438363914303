<div class="dropdown-content multi-select-filter" [class.is-hidden]="!isOpen">
  <a class="is-hidden" (click)="openContent()" id="filter-stored-credential-type-detail">
    <i class="fas fa-chevron-right" aria-hidden="true"></i>
  </a>

  <div class="dropdown-item">
    <a
      tabindex="0"
      (click)="backToMenu()"
      (keyup.enter)="backToMenu()"
      data-e2e="button-back-to-menu"
      title="Back to menu"
      i18n-title
    >
      <span class="icon is-small">
        <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
      </span>
      <span i18n>Back</span>
    </a>

    <h2 class="title is-4 has-margin-top has-margin-bottom" i18n>Stored Credential type</h2>

    <portal-switch-toggle
      #switchToggle
      [text]="labelSelectAllTypes"
      (change)="onSelectAllSwitchToggle()"
    >
    </portal-switch-toggle>
  </div>

  <hr class="dropdown-divider" />

  <ng-container *ngIf="showSelectedEntities.size">
    <div class="dropdown-item">
      <label i18n>Selected</label>
      <ng-container *ngFor="let entity of showSelectedEntities | keyvalue">
        <portal-search-filter-checkbox
          #preSelectedEntities
          [id]="entity.key"
          [text]="entity.value.text"
          [selectedEntities]="localPreSelectedEntities"
          (removed)="onEntityRemoved($event)"
          (added)="onEntityAdded($event)"
        ></portal-search-filter-checkbox>
      </ng-container>
    </div>

    <hr class="dropdown-divider" />
  </ng-container>

  <div class="dropdown-item is-scrollable">
    <ng-container *ngFor="let entity of entities">
      <portal-search-filter-checkbox
        [id]="entity.id"
        [text]="entity.text"
        [selectedEntities]="localPreSelectedEntities"
        [searchedText]="searchedText"
        (removed)="onEntityRemoved($event)"
        (added)="onEntityAdded($event)"
      ></portal-search-filter-checkbox>
    </ng-container>
  </div>

  <hr class="dropdown-divider" />

  <div class="dropdown-item">
    <button
      class="button is-primary"
      (click)="onFilterApplied()"
      data-e2e="button-filter"
      type="button"
      title="Apply"
      i18n-title
      i18n
    >
      Apply
    </button>
    <button
      class="button is-transparent additional-filter-button ml-2"
      data-e2e="button-clear-filter"
      i18n
      (click)="onFilterCleared()"
      (keydown.enter)="onFilterCleared()"
      type="button"
      title="Clear"
      i18n-title
    >
      Clear
    </button>
  </div>
</div>
