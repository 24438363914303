import { Injectable, NgZone } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  private activityEvents$: Observable<Event>;

  constructor(private ngZone: NgZone) {
    this.ngZone.runOutsideAngular(() => {
      const mouseEvents$ = merge(
        fromEvent(document, 'mousemove'),
        fromEvent(document, 'mousedown'),
        fromEvent(document, 'mouseup'),
        fromEvent(document, 'click'),
        fromEvent(document, 'dblclick'),
        fromEvent(document, 'contextmenu'),
      );

      const keyboardEvents$ = merge(fromEvent(window, 'keydown'), fromEvent(document, 'keyup'));

      const windowEvent$ = fromEvent(window, 'scroll', { capture: true });

      const touchEvents$ = merge(
        fromEvent(document, 'touchstart'),
        fromEvent(document, 'touchmove'),
        fromEvent(document, 'touchend'),
        fromEvent(document, 'touchcancel'),
      );

      const pointerEvents$ = merge(
        fromEvent(document, 'pointerdown'),
        fromEvent(document, 'pointerup'),
        fromEvent(document, 'pointermove'),
        fromEvent(document, 'pointerover'),
        fromEvent(document, 'pointerout'),
        fromEvent(document, 'pointerenter'),
        fromEvent(document, 'pointerleave'),
        fromEvent(document, 'pointercancel'),
      );

      this.activityEvents$ = merge(
        mouseEvents$,
        keyboardEvents$,
        windowEvent$,
        touchEvents$,
        pointerEvents$,
      ).pipe(debounceTime(300));
    });
  }

  getActivityEvents(): Observable<Event> {
    return this.activityEvents$;
  }
}
