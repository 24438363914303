import { UrlQueryParamsBuilderService } from './../../../../../shared/url-query-params-builder/src/lib/url-query-params-builder.service';
import { Injectable } from '@angular/core';
import { APPS_API, DEV_APPS_API } from '@portal/marketplace/api-interfaces/src';
import { UserService } from '@portal/shared/user';
import { VuiHttpService } from '@portal/shared/vui-http';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { IFilterToApply } from '@portal/shared/ui/filter/src';
import { IQueryParams } from '@portal/shared/ui/table/src';

@Injectable({
  providedIn: 'root',
})
export class AppCatalogDataService {
  // eslint-disable-next-line
  constructor(
    private userService: UserService,
    private vuiHttpService: VuiHttpService,
    private urlQueryParamsBuilderService: UrlQueryParamsBuilderService,
  ) {}

  getAppsFromVfAppMarket(type, paginationConfig?, filters?: IFilterToApply[]): Observable<any> {
    const orgId = this.userService.getEntityUid();
    let params = new HttpParams();
    let url: string;
    if (type === 'EO') {
      url = APPS_API.EO.GET_APPS(orgId);
    } else if (type === 'DEVELOPER') {
      url = DEV_APPS_API.GET_APPS();
    } else {
      url = APPS_API.MERCHANT.GET_APPS(orgId);
    }

    if (paginationConfig) {
      params = this.urlQueryParamsBuilderService.createHttpParams(
        {
          page: paginationConfig.start / paginationConfig.limit,
          size: paginationConfig.limit,
          ...(paginationConfig.sort
            ? {
                sort: `${paginationConfig.sort.key},${paginationConfig.sort.order}`,
              }
            : {
                sort: `appName,ASC`,
              }),
        },
        filters,
      );
    }
    if (!paginationConfig && filters) {
      params = this.urlQueryParamsBuilderService.createHttpParams(null, filters);
    }
    return this.vuiHttpService.get(url, { params: params });
  }

  getAppFromVfAppMarket(
    appMarketAppId: string,
    type: 'MERCHANT' | 'EO',
    entityUid?: string,
  ): Observable<any> {
    const orgId = this.userService.getEntityUid();
    let url: string;
    if (type === 'MERCHANT') {
      url = APPS_API.MERCHANT.GET_APP(entityUid ? entityUid : orgId, appMarketAppId);
    } else {
      url = APPS_API.EO.GET_APP(orgId, appMarketAppId);
    }

    return this.vuiHttpService.get(url);
  }

  getAppsFromAppCatalog(appsIds: string[]): Observable<any> {
    const url = APPS_API.GET_APPS_FROM_CATALOG(appsIds);
    return this.vuiHttpService.get(url);
  }

  getAppsForMarketplaceManager(
    pagination: IQueryParams,
    searchAndFilters: IFilterToApply[],
    type: string,
  ): Observable<any> {
    let url;
    if (type === 'ALL') url = APPS_API.MARKETPLACE_MANAGER.GET_APPS();
    else url = APPS_API.MARKETPLACE_MANAGER.GET_SUBMITTED_APPS();
    let params = new HttpParams();
    params = this.urlQueryParamsBuilderService.createHttpParamsForAppCatalog(
      {
        page: pagination.start / pagination.limit,
        size: pagination.limit,
        ...(pagination.sort
          ? {
              sort: `latestAppVersion.${pagination.sort.key},${pagination.sort.order}`,
            }
          : {
              sort: `latestAppVersion.appName,ASC`,
            }),
      },
      searchAndFilters,
    );
    return this.vuiHttpService.get(url, { params: params });
  }

  getAppsUpdates(): Observable<any> {
    const entityUid = this.userService.getEntityUid();
    const url = APPS_API.MERCHANT.GET_APPS_UPDATES(entityUid);
    return this.vuiHttpService.get(url);
  }

  getUpdatesForApp(appId: string, entityUid?: string, fetchAll?: boolean): Observable<any> {
    const url = APPS_API.MERCHANT.GET_APP_UPDATES(
      entityUid ? entityUid : this.userService.getEntityUid(),
      appId,
    );

    const params = this.urlQueryParamsBuilderService.createHttpParams({
      ...(fetchAll ? { fetchAll: true } : null),
    });

    return this.vuiHttpService.get(url, { params: params });
  }

  generatePublicCatalog(): Observable<any> {
    const entityUid = this.userService.getEntityUid();
    const url = APPS_API.EO.GENERATE_PUBLIC_CATALOG(entityUid);
    return this.vuiHttpService.get(url);
  }
  getAppVersionFromVfAppMarket(appId: string, appVersion: string): Observable<any> {
    const url = APPS_API.MARKETPLACE_MANAGER.GET_APP_VERSION_FROM_VF_APP_MARKET(appId, appVersion);
    return this.vuiHttpService.get(url);
  }
  getAppVersionFromAppCatalog(appVersion: string): Observable<any> {
    const url = APPS_API.MARKETPLACE_MANAGER.GET_APP_VERSION(appVersion);
    return this.vuiHttpService.get(url);
  }
}
