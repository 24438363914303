<div class="hierarchy-filter" [ngClass]="{ dropdown: showFilterSubMenu }">
  <div [ngClass]="{ 'dropdown-menu is-block': showFilterSubMenu }">
    <div
      [ngClass]="{ 'is-active dropdown multi-select-filter width-full': isOpen }"
      [class.is-hidden]="!isOpen && !isMainDropdownFilter && !isActive"
    >
      <a
        class="is-hidden"
        (click)="openContent()"
        (keyup.enter)="openContent()"
        id="filter-organisation-detail"
      >
        <i class="fas fa-chevron-right" aria-hidden="true"></i>
      </a>
      <div class="dropdown-trigger width-full rows">
        <div class="row width-full" *ngIf="!organizationGroup && !isSubMenuFilter">
          <button
            id="orgFilterButton"
            class="button is-chip width-full is-size-7-mobile"
            [class.is-applied]="getValues().length"
            [class.is-active]="isOpen"
            data-e2e="button-entity-filter"
            (click)="itemToggled.emit()"
            title="{{ title }}"
            type="button"
            [attr.aria-expanded]="isOpen"
          >
            <span>{{ title }}</span>
            <span class="icon">
              <i class="fas fa-chevron-down"></i>
            </span>
          </button>
        </div>

        <div
          class="selected-organizations"
          [ngClass]="{ 'selected-organizations box': allPreSelectedEntityValues?.length > 2 }"
          *ngIf="organizationGroup && allPreSelectedEntityValues?.length"
        >
          <div
            class="buttons selected-organization-groups"
            *ngIf="allPreSelectedEntityValues?.length"
          >
            <button
              type="button"
              class="button button-selected-entity mb-2 mr-2 is-primary is-outlined has-text-black has-text-truncate"
              *ngFor="let selectedEntity of allPreSelectedEntityValues"
              [class.is-hidden]="selectedParentEntity?.entityUid === selectedEntity.id"
              title="{{ selectedEntity.text }}"
            >
              <span>{{ selectedEntity.text }} </span>
              <span class="icon is-size-7" (click)="onRemoveSelectedOrganization(selectedEntity)">
                <i class="fas fa-times"></i>
              </span>
            </button>
          </div>
        </div>

        <div class="input is-flex mb-5 mt-3" *ngIf="organizationGroup">
          <div
            class="selectedFeature-list"
            readonly="true"
            (click)="openContentOrganizationGroup()"
          ></div>
          <span class="input-icon is-size-7" [ngClass]="{ 'icon-rotate': isOpen }">
            <i class="fas fa-chevron-down"></i>
          </span>
        </div>
      </div>

      <div
        class="dropdown-menu organisations-filter"
        [ngClass]="{
          'input-select-items': organizationGroup && allPreSelectedEntities?.size > 2,
          'mt-4': organizationGroup && !allPreSelectedEntities?.size
        }"
      >
        <div class="dropdown-content multi-select-filter">
          <div class="is-flex is-justify-content-space-between pr-2">
            <div class="dropdown-item p-0 search-parameter-list">
              <a
                *ngIf="isSubMenuFilter && !displayFromFilter"
                tabindex="0"
                (click)="backToMenu()"
                (keyup.enter)="backToMenu()"
                data-e2e="button-back-to-men"
              >
                <span class="pl-3">
                  <span class="icon is-small">
                    <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
                  </span>
                  <span i18n>Back</span>
                </span>
              </a>
            </div>
          </div>

          <div class="dropdown-item">
            <div class="field is-grouped is-flex-wrap-wrap">
              <div class="control mr-3 my-1" *ngFor="let searchParameter of searchParameterList">
                <label
                  class="radio"
                  attr.data-e2e="radio-search-parameter-label-{{ searchParameter.id }}"
                >
                  <input
                    [value]="searchParameter.id"
                    [(ngModel)]="searchParameterType"
                    type="radio"
                    attr.data-e2e="radio-search-parameter-{{ searchParameter.id }}"
                  />
                  <span>{{ searchParameter.text }}</span>
                </label>
              </div>
            </div>
          </div>

          <div class="dropdown-item" *ngIf="allowDeleted">
            <div classs="field">
              <div class="control">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    [(ngModel)]="includeDeleted"
                    (ngModelChange)="onStatusChange()"
                  />
                  <span i18n>Include deleted organisations</span>
                </label>
              </div>
            </div>
          </div>

          <hr class="dropdown-divider" />

          <div class="dropdown-item">
            <portal-search-filter-autocomplete-input
              [placeholder]="searchPlaceholder"
              [searchInputValue]="searchedText"
              (search)="onSearch($event)"
            ></portal-search-filter-autocomplete-input>
          </div>

          <hr class="dropdown-divider" />

          <div
            *ngIf="limitExceeded"
            [ngClass]="{ 'pb-0': showSelectedEntities?.size }"
            class="dropdown-item has-text-danger"
            i18n
          >
            Select up to {{ selectLimit }} organisations
          </div>

          <div class="dropdown-item has-text-truncate" *ngIf="loading$ | async">
            <span class="has-text-truncate">
              <portal-spinner
                [isHorizontal]="true"
                [isSmall]="true"
                text="Loading organisations..."
                i18n-text
              ></portal-spinner>
            </span>
          </div>

          <div
            class="dropdown-item"
            *ngIf="!(loading$ | async) && !entities?.length && !isOrgExist"
          >
            {{ notFoundMessage }}
          </div>

          <div class="dropdown-item py-0" *ngIf="!(loading$ | async) && isOpen && isOrgExist">
            <span class="has-text-truncate" *ngIf="!entities?.length" i18n>
              {{ updateEntities() }}
              Start typing to search for organisations..
            </span>
            <div class="hierarchical-wrapper">
              <!-- tree -->
              <tree-root
                #tree
                [options]="options"
                [nodes]="entities"
                [focused]="true"
                [(state)]="treeState"
                (deselect)="deselected($event)"
                (select)="selected($event.node)"
                (keydown.tab)="onKeyDownApply()"
                (focusin)="onFocusIn($event, tree.treeModel)"
                (focusout)="onFocusOut($event, tree.treeModel)"
              >
                <ng-template
                  #treeNodeFullTemplate
                  let-node
                  let-index="index"
                  let-templates="templates"
                >
                  <div
                    [class]="node.getClass()"
                    [class.tree-node]="true"
                    [class.tree-node-expanded]="node.isExpanded && node.hasChildren"
                    [class.tree-node-collapsed]="node.isCollapsed && node.hasChildren"
                    [class.tree-node-leaf]="node.isLeaf"
                    [class.tree-node-active]="node.isActive"
                    [class.tree-node-focused]="node.isFocused"
                  >
                    <tree-node-drop-slot
                      *ngIf="index === 0"
                      [dropIndex]="node.index"
                      [node]="node.parent"
                    >
                    </tree-node-drop-slot>

                    <tree-node-wrapper
                      [node]="node"
                      [index]="index"
                      [templates]="templates"
                    ></tree-node-wrapper>

                    <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                    <a
                      *ngIf="
                        node.isExpanded &&
                        node?.children?.length &&
                        node?.children?.length < node.data.count
                      "
                    >
                      <span
                        id="orgLoadMoreButton"
                        class="ml-5"
                        *ngIf="!(loadingMore$ | async)"
                        (click)="loadMore(node)"
                        i18n
                      >
                        Load more
                      </span>
                    </a>
                    <div class="ml-5" *ngIf="(loadingMore$ | async) === node.id">
                      <div class="load-more-container">
                        <div class="spinner-container is-horizontal is-justify-content-start">
                          <div class="spinner is-small" role="status" aria-live="assertive"></div>
                          <div i18n class="pl-1">Loading...</div>
                        </div>
                      </div>
                    </div>

                    <tree-node-drop-slot
                      [dropIndex]="node.index + 1"
                      [node]="node.parent"
                    ></tree-node-drop-slot>
                  </div>
                </ng-template>

                <ng-template #treeNodeTemplate let-node let-index="index">
                  <div class="is-flex is-flex-direction-column is-word-wrap" (click)="toggleCheckbox(node)">
                    <div>
                      <span class="is-size-6" style="display: inline-block">{{
                        node.data?.name
                      }}</span>
                      <span class="has-text-grey" *ngIf="node.data?.count">
                        ({{ node.data?.count }})
                      </span>
                    </div>
                    <span class="is-size-7" *ngIf="node.data?.path">{{ node.data?.path }}</span>
                  </div>
                </ng-template>
              </tree-root>
            </div>
            <span class="has-text-truncate" *ngIf="loadingNewPortion$ | async">
              <portal-spinner [isSmall]="true"></portal-spinner>
            </span>
          </div>
          <hr class="dropdown-divider" />
          <div class="dropdown-item" *ngIf="isOrgExist">
            <button
              id="orgApplyButton"
              class="button is-primary"
              data-e2e="button-filter"
              i18n
              [disabled]="disabledApply"
              (click)="onFilterApplied()"
              (keyup.enter)="onFilterApplied()"
              title="Apply"
              i18n-title
              type="button"
              tabindex="0"
              #applyButton
            >
              Apply
            </button>
            <button
              id="orgClearButton"
              class="button is-outlined ml-2"
              data-e2e="button-clear-filter"
              i18n
              (click)="onFilterClear()"
              (keydown.enter)="onFilterClear()"
              type="button"
              title="Clear"
              i18n-title
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
