export enum AppStatus {
  REGISTERED = 'REGISTERED',
  SUBMITTED = 'SUBMITTED',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  PUBLISHED = 'PUBLISHED',
  PRIOR_VERSION = 'PRIOR_VERSION',
  DISABLED = 'DISABLED',
  WITHDRAWN = 'WITHDRAWN',
  REJECTED = 'REJECTED',
}

export const StatusColorMapping = {
  REGISTERED: { name: 'Registered', color: 'has-background-info' },
  SUBMITTED: { name: 'Submitted', color: 'has-background-warning' },
  IN_REVIEW: { name: 'In Review', color: 'has-background-warning' },
  APPROVED: { name: 'Approved', color: 'has-background-success' },
  PUBLISHED: { name: 'Published', color: 'has-background-success' },
  PRIOR_VERSION: { name: 'Prior Version', color: 'has-background-warning' },
  DISABLED: { name: 'Disabled', color: 'has-background-danger' },
  WITHDRAWN: { name: 'Withdrawn', color: 'has-background-grey' },
  REJECTED: { name: 'Rejected', color: 'has-background-danger' },
  FAILED: { name: 'Failed', color: 'has-background-info' },
  PENDING: { name: 'Pending', color: 'has-background-warning' },
  AVAILABLE: { name: 'Available', color: 'has-background-warning' },
  READY: { name: 'Ready', color: 'has-background-success' },
  REVOKED: { name: 'Revoked', color: 'has-background-danger' },
};
