import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@apps/portal/src/app/shared/shared.module';
import { SpinnerModule } from '@portal/shared/ui/spinner';
import { SecureCardCaptureComponent } from './secure-card-capture.component';
import { SecureCardCaptureService } from './secure-card-capture.service';

@NgModule({
  imports: [SpinnerModule, SharedModule, CommonModule],
  exports: [SecureCardCaptureComponent],
  declarations: [SecureCardCaptureComponent],
  providers: [SecureCardCaptureService],
})
export class SecureCardCaptureModule {}
