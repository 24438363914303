import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OffCanvasModule } from '@portal/shared/ui/off-canvas';
import { SpinnerModule } from '@portal/shared/ui/spinner';
import { AppCardTemplateModule } from '../app-card-template/app-card-template.module';
import { AppCatalogCardsTemplateComponent } from './app-catalog-cards-template.component';

@NgModule({
  imports: [CommonModule, AppCardTemplateModule, OffCanvasModule, SpinnerModule, RouterModule],
  declarations: [AppCatalogCardsTemplateComponent],
  exports: [AppCatalogCardsTemplateComponent],
})
export class AppCatalogCardsTemplateModule {}
