<div class="card" [ngClass]="{ 'has-border-grey-lighter': disabled }">
  <div class="card-image" (click)="gotoAppPageClick()">
    <marketplace-app-logo-template
      *ngIf="!isPublic"
      [app]="app"
      [showInstallsIndicator]="hasInstallsFlag"
      [showUpdatesIndicator]="hasUpdatesFlag"
    >
    </marketplace-app-logo-template>
    <marketplace-app-logo-public-template [app]="app" *ngIf="isPublic">
    </marketplace-app-logo-public-template>
  </div>
  <div (click)="gotoAppPageClick()" class="card-content">
    <div class="content">
      <div class="app-tags" *ngIf="showTags">
        <vui-app-tag
          *ngIf="app && app.categories && app.categories.length > 0"
          [tags]="mapTags(app.categories)"
          data-e2e="category"
        ></vui-app-tag>

        <vui-app-tag
          *ngIf="app && app.modelFamilies && app.modelFamilies.length > 0"
          [tags]="mapTags(app.modelFamilies)"
          data-e2e="model"
        ></vui-app-tag>
      </div>

      <h3 class="app-title has-text-weight-semibold is-ellipsis" data-e2e="app-card-name">
        {{ app?.appName }}
      </h3>
      <div class="is-flex is-align-items-center">
        <marketplace-app-icon
          *ngIf="hasFeaturedFlag"
          icon="fa-crown"
          title="Featured app"
        ></marketplace-app-icon>
        <marketplace-app-icon
          *ngIf="hasPilotFlag"
          icon="fa-flask"
          title="Pilot app"
        ></marketplace-app-icon>
        <marketplace-app-icon
          *ngIf="hasPublicFlag"
          icon="fa-user-friends"
          title="Public app"
        ></marketplace-app-icon>
        <marketplace-app-icon
          *ngIf="getIcons()"
          icon="has-text-warning has-new-store-icon"
          title="Default app"
        ></marketplace-app-icon>

        <small data-e2e="app-version">{{ app?.version }}</small>
      </div>
      <div class="app-description has-text-dark" data-e2e="app-card-short-description">
        {{ app?.shortDescription }}
      </div>

      <marketplace-app-price
        [price]="app?.price || app?.minimumPrice"
        [paymentModel]="app?.paymentModel"
      ></marketplace-app-price>

      <ng-container *ngIf="!showVaultStatus">
        <marketplace-status-indicator
          *restrictPermission="['app-status']"
          [status]="app?.status"
        ></marketplace-status-indicator>
      </ng-container>
      <marketplace-status-indicator
        *ngIf="showVaultStatus"
        [status]="getStatus(app)"
      ></marketplace-status-indicator>
    </div>
  </div>
</div>
