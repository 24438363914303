import { Injectable } from '@angular/core';
import { ActiveState, EntityStore, StoreConfig } from '@datorama/akita';
import { EntityState } from '@ngrx/entity';
import { IApp } from '@portal/marketplace/api-interfaces';

export interface AllAppsCatalogState extends EntityState<IApp>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'all-apps', idKey: 'id' })
export class AllAppsCatalogStore extends EntityStore<AllAppsCatalogState, IApp> {
  constructor() {
    super();
  }
}
