import { Component, OnDestroy, OnInit } from '@angular/core';
import { Application } from '@portal/shared/auth/authorization/src';
import { FederationType, IUser } from '@portal/entity-services/interfaces';
import { EnvironmentVariableService } from '@portal/shared/helpers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService } from '@portal/shared/auth/authentication';
import { LanguageHandle } from '@portal/shared/languages';
import { VERSION } from './version';
import { WhiteLabelingService } from '@portal/shared/white-labeling/src/lib/services/white-labeling.service';
import { BRAND_NAME } from './constants/brand-name.constant';
import { Title } from '@angular/platform-browser';
import { INavbarItem } from '@portal/shared/ui/layouts/src/lib/top-navbar/interfaces/navbar-item.interface';
import { CommonNavbarService, NavbarItemLink } from '@portal/shared/core/src';
import { ApplicationRoot } from '@portal/shared/core/src/lib/enums/application-root.enum';
import { HeroService } from '@portal/shared/ui/layouts';
import { CommonNavbarState } from '@portal/shared/ui/layouts/src/lib/top-navbar/components/top-common-navbar/common-navbar-state.directive';
import { Features } from '../../../../environments/enums/features.enum';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle';
import { MenuRegion } from '@portal/shared/ui/layouts/src/lib/top-navbar/enum/menu-region.enum';
import { AppService } from './app.service';

@UntilDestroy()
@Component({
  selector: 'vui-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  version = VERSION;
  logoSrc =
    'https://d3slnjjr5hy7v5.cloudfront.net/assets/logos/linear/verifone/verifone_logotype_black.png';
  brandText = BRAND_NAME.verifoneCentral;
  cba = EnvironmentVariableService.getVar('CBA');
  navbarItems: INavbarItem[];
  navbarUserItem: INavbarItem;
  application = Application.Wrapper;
  supportPageLink: NavbarItemLink = null;
  isLoggedIn = false;
  isFederatedUser = false;
  user: IUser = null;
  isWhiteLabelingEnabled = false;

  constructor(
    private title: Title,
    private authenticationService: AuthenticationService,
    private languageService: LanguageHandle,
    private whiteLabelingService: WhiteLabelingService,
    private commonNavbarService: CommonNavbarService,
    private heroService: HeroService,
    private featureToggle: FeatureToggle,
    private appService: AppService,
  ) {
    this.setAppVersion();
  }

  ngOnInit(): void {
    this.isWhiteLabelingEnabled = this.whiteLabelingService.isWhiteLabelingEnabled();
    if (this.cba === true) {
      this.title.setTitle(BRAND_NAME.appMarketPlace);
    }
  }

  login(): void {
    this.authenticationService.authenticate();
  }

  events(e): void {
    if (e.type === AuthenticationService.Event.LoggedIn) {
      this.user = e.data?.authenticatedUser;

      this.languageService.setLanguage(this.user?.language);
      this.isLoggedIn = true;
      if (
        this.user?.entity?.federationStatus ||
        (this.user?.entity?.federationType &&
          this.user?.entity?.federationType !== FederationType.None)
      ) {
        this.isFederatedUser = true;
      }

      this.commonNavbarService
        .getNavItems(this.user, ApplicationRoot.Wrapper)
        .pipe(untilDestroyed(this))
        .subscribe(
          (navItems) => {
            this.initializeNavItems(navItems);
          },
          () => {
            this.initializeNavItems();
          },
        );
    }

    if (e.type === AuthenticationService.Event.LoggedOut) {
      this.isLoggedIn = false;
      if (this.cba) {
        window.location.assign(window.location.origin);
      }
    }
  }

  initializeNavItems(navItems?: {
    navigationItems: INavbarItem[];
    userNavItems: INavbarItem;
  }): void {
    if (navItems) {
      this.navbarItems = navItems.navigationItems;
      this.navbarUserItem = navItems.userNavItems;
    } else {
      this.navbarItems = this.commonNavbarService.getNavigationItems(
        this.user,
        ApplicationRoot.Wrapper,
      );
      this.navbarUserItem = this.commonNavbarService.getNavbarUserItem(
        this.user,
        ApplicationRoot.Wrapper,
      );
    }

    this.supportPageLink = this.commonNavbarService.supportPageUrl;
    this.heroService.showTitle($localize`Welcome, ${this.user.name}`, MenuRegion.Home);
  }

  ngOnDestroy(): void {
    this.heroService.reset();
  }

  onWhiteLabelLoaded(): void {
    this.logoSrc = this.whiteLabelingService.getLogoUrl();
    this.brandText = this.whiteLabelingService.getBrandText() || this.brandText;

    this.title.setTitle(this.brandText);
  }

  updateHero(navbarState: CommonNavbarState): void {
    this.heroService.handleNavbarState(navbarState);
  }

  get isTermsAndConditionsEnabled(): boolean {
    return this.featureToggle.isFeatureActive(Features.TermsAndConditions);
  }
  private setAppVersion(): void {
    const { version, tag, hash } = this.version;
    localStorage.setItem('hash', hash);
    localStorage.setItem('tag', tag);
    localStorage.setItem('version', version);
  }
}
