import { vfappmarket } from '../services.url';

export const API = {
  ASSIGN_APP_TO_DEVICES: {
    ASSIGN: function (merchantEntityUid: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/apps/${appmarketAppId}/assign`;
    },
    EVALUATION: function (merchantEntityUid: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/apps/${appmarketAppId}/assign/evaluate`;
    },
  },
  UNASSIGN_APP_FROM_DEVICES: {
    UNASSIGN: function (merchantEntityUid: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/apps/${appmarketAppId}/unassign`;
    },
    EVALUATION: function (merchantEntityUid: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/apps/${appmarketAppId}/unassign/evaluate`;
    },
  },
  ASSIGN_BUNDLE_TO_DEVICES: {
    EVALUATION: function (merchantEntityUid: string, appmarketBundleId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/bundles/${appmarketBundleId}/assign/evaluate`;
    },
    ASSIGN: function (merchantEntityUid: string, appmarketBundleId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/bundles/${appmarketBundleId}/assign`;
    },
  },
  UNASSIGN_BUNDLE_FROM_DEVICES: {
    EVALUATION: function (merchantEntityUid: string, appmarketBundleId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/bundles/${appmarketBundleId}/unassign/evaluate`;
    },
    UNASSIGN: function (merchantEntityUid: string, appmarketBundleId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/bundles/${appmarketBundleId}/unassign`;
    },
  },
  UPDATE: {
    UPDATE_APP_ON_DEVICES_EVALUATION: function (
      merchantEntityUid: string,
      appmarketAppId: string,
    ): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/apps/${appmarketAppId}/upgrade/pois/evaluate`;
    },
    UPDATE_APP_ON_DEVICES: function (merchantEntityUid: string, appmarketAppId: string): string {
      return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/apps/${appmarketAppId}/upgrade/pois`;
    },
  },
  BULK: {
    BULK_ASSIGNMENT: function (): string {
      return `${vfappmarket}/v1/assignments`;
    },
  },
  RETRY: function (merchantEntityUid: string) {
    return `${vfappmarket}/v1/assignments/merchants/${merchantEntityUid}/retry`;
  },
};
