<div class="progress-bar-container box">
  <div class="tile is-ancestor">
    <div class="tile is-narrow is-parent">
      <img [src]="'assets/images/files-progress/folder-upload.svg'" alt="folder upload" />
    </div>
    <div class="tile is-parent is-vertical">
      <div class="tile has-text-weight-bold">
        {{ name }}
      </div>
      <div class="tile">
        <progress
          [ngClass]="{
            'is-info': percent !== 100,
            'is-success': percent == 100
          }"
          class="progress"
          value="{{ percent }}"
          max="100"
        >
          {{ percent }}
        </progress>
      </div>
      <div class="tile">
        <span class="is-size-7 has-text-grey" *ngIf="bytesLoaded > 0 && totalBytes > 0"
          >{{ _bytesLoaded | readableFileSize }} <span i18n>of</span>
          {{ totalBytes | readableFileSize }}</span
        >
        <span *ngIf="percent !== 100" class="is-size-7 has-text-link pull-right"
          ><span i18n>Uploading...</span> {{ percent }}%</span
        >
        <span *ngIf="percent == 100" class="is-size-7 has-text-success pull-right" i18n
          >Complete</span
        >
      </div>
    </div>
    <div class="tile is-parent is-narrow center-content">
      <button
        *ngIf="percent !== 100"
        (click)="cancelUpload()"
        class="button is-info cancel"
        data-e2e="progress-bar-cancel-btn"
        i18n
      >
        Cancel
      </button>
      <button
        *ngIf="percent == 100 && showDeleteBtn"
        (click)="deleteFile()"
        class="button is-danger is-outlined"
        data-e2e="progress-bar-delete-btn"
        i18n
      >
        Delete
      </button>
    </div>
  </div>
</div>
