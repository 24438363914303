import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { IVfAppMarketApp } from '@portal/marketplace/api-interfaces/src';
import { VfAppMarketAppCatalogStore } from './vf-app-market.store';
import { AppCatalogState } from './vf-app-market.store';

@Injectable({
  providedIn: 'root',
})
export class VfAppMarketAppCatalogQuery extends QueryEntity<AppCatalogState, IVfAppMarketApp> {
  // AppCatalogData$ = this.selectAll();
  itemsCount$ = this.select((state) => state.metadata.count);
  constructor(
    // eslint-disable-next-line
    private routerQuery: RouterQuery,
    protected store: VfAppMarketAppCatalogStore,
  ) {
    super(store);
  }
}
