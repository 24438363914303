import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BundleLogoTemplateComponent } from './bundle-logo-template.component';

@NgModule({
  imports: [CommonModule],
  exports: [BundleLogoTemplateComponent],
  declarations: [BundleLogoTemplateComponent],
})
export class BundleLogoTemplateModule {}
