import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntityStatus, IOrganisation, ITokenScope } from '@portal/entity-services/interfaces';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TokenScopeService } from '../token-scope.service';

@Component({
  selector: 'portal-linked-token-scope',
  templateUrl: './linked-token-scope.component.html',
})
export class LinkedTokenScopeComponent implements OnInit {
  @Input() organisation: IOrganisation;
  @Output() unlinked: EventEmitter<() => void> = new EventEmitter<() => void>();

  filteredTokenScopes$: Observable<ITokenScope[]> = this.tokenScopeService.filteredTokenScopes$;
  linkedTokenScopes$: Observable<ITokenScope[]> = this.tokenScopeService.linkedTokenScopes$;
  selectedTokenScopes: Set<ITokenScope> = new Set();
  isCreateMode = false;

  constructor(private tokenScopeService: TokenScopeService) {}

  ngOnInit(): void {
    if (this.organisation.status === EntityStatus.Active) {
      this.tokenScopeService.getAllLinked(this.organisation?.entityUid).subscribe();
    }
  }

  unlinkTokenScope(linkedTokenScope: ITokenScope): void {
    this.unlinked.emit(() => {
      this.tokenScopeService
        .unlinkTokenScope(this.organisation.entityUid, linkedTokenScope.tokenScopeUid)
        .subscribe();
    });
  }

  linkTokenScopes(): void {
    this.isCreateMode = false;
    if (this.selectedTokenScopes.size) {
      this.tokenScopeService
        .linkTokenScopes(this.organisation.entityUid, [...this.selectedTokenScopes])
        .pipe(finalize(() => this.selectedTokenScopes.clear()))
        .subscribe();
    }
  }

  onCheckboxChanged(tokenScope: ITokenScope, checked: boolean): void {
    if (checked) {
      this.selectedTokenScopes.add(tokenScope);
    } else {
      this.selectedTokenScopes.delete(tokenScope);
    }
  }

  trackById(index: number, item: ITokenScope): ITokenScope['tokenScopeUid'] {
    return item.tokenScopeUid;
  }
}
