import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Application, AuthorizationService } from '@portal/shared/auth/authorization/src';
import sum from 'lodash-es/sum';
import throttle from 'lodash-es/throttle';
import { INavbarItem } from './interfaces/navbar-item.interface';
import { TopNavbarService } from './services/top-navbar.service';
import { SecuredRandomService } from '../../../../../helpers/src/lib/secured-random/secured-random.service';
import { AuthenticationService } from '@portal/shared/auth/authentication';
import { map, startWith } from 'rxjs/operators';
import { IUser } from '@portal/entity-services/interfaces/src';
import { LanguageHandle } from '@portal/shared/languages/src';
import { Features } from 'environments/enums/features.enum';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';
import { EnvironmentVariableService } from '@portal/shared/helpers/src';

@Component({
  selector: 'vui-top-navbar',
  templateUrl: './top-navbar.component.html',
})
export class TopNavbarComponent implements AfterViewChecked, OnChanges, OnInit {
  isLogoUploaded = false;
  @Input() navbarItems: INavbarItem[];
  @Input() set logoSrc(value: string) {
    if (value) {
      this.logoSource = value;
      this.isLogoUploaded = false;
    } else {
      this.isLogoUploaded = true;
    }
  }
  get logoSrc(): string {
    return this.logoSource;
  }
  @Input() navbarUserItem: INavbarItem;
  @Input() isLinksInteractive = true;

  @ViewChild('navbar', { static: true }) navbar: ElementRef<HTMLElement>;
  @ViewChild('navbarBrand', { static: true }) navbarBrand: ElementRef<HTMLElement>;
  @ViewChild('navbarMenu', { static: true }) navbarMenu: ElementRef<HTMLElement>;
  @ViewChildren('navbarMenuItem') navbarMenuItems: QueryList<ElementRef<HTMLElement>>;

  @HostListener('window:resize')
  onResize = throttle(() => {
    if (window.innerWidth > this.tablet) {
      this.showMobileDropDown = false;
      this.setMenuItemWidths();
    }
    this.checkForMoreDropDown();
  }, 300);

  isActive = false;
  showMobileDropDown = false;
  moreDropDownItems: INavbarItem[] = [];
  filteredNavbarItems: INavbarItem[];
  allNavbarItems: INavbarItem[] = [];
  showNewLogo = false;
  readonly moreDropDownMaxWidth = 150;
  readonly hasAppSwitcher$ = this.authenticationService.authEvents.pipe(
    map(() => this.hasAppSwitcher),
    startWith(this.hasAppSwitcher),
  );

  readonly user$ = this.authenticationService.authEvents.pipe(
    map(() => this.user),
    startWith(this.user),
  );
  readonly appSwitcherUrl$ = this.user$.pipe(
    map((user) => {
      if (!user?.language) return `${window.location.origin}/select-apps`;
      const languageUrlSegment = this.languageService.getSupportedLanguagePath(
        user.language,
        Application.Wrapper,
      );
      return languageUrlSegment
        ? `${window.location.origin}/${languageUrlSegment}/select-apps`
        : `${window.location.origin}/select-apps`;
    }),
  );

  private readonly tablet = 769;
  private readonly desktop = 1024;
  private menuItemWidths = new Map<string, number>();
  private logoSource = 'assets/images/verifone-logo-white.png';
  private isViewLoaded = false;

  constructor(
    private languageService: LanguageHandle,
    private topNavbarService: TopNavbarService,
    private authorizationService: AuthorizationService,
    private authenticationService: AuthenticationService,
    private featureToggleService: FeatureToggle,
    @Inject(DOCUMENT) private document: Document,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.showNewLogo = this.featureToggleService.isFeatureActive(Features.NewLogo);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.navbarItems || changes.navbarUserItem) {
      this.initialize();
      if (this.navbarMenuItems) {
        this.setMenuItemWidths();
        this.checkForMoreDropDown();
      }
    }
  }

  ngAfterViewChecked(): void {
    if (
      this.navbarBrand.nativeElement.offsetWidth !== 0 &&
      this.navbar.nativeElement.offsetWidth !== 0 &&
      !this.isViewLoaded
    ) {
      this.setMenuItemWidths();
      this.checkForMoreDropDown();
      this.isViewLoaded = true;
      this.cd.detectChanges();
    }
  }

  toggleMobileDropdown(): void {
    this.showMobileDropDown = !this.showMobileDropDown;
  }

  private get navbarUserItemWidth(): number {
    return this.navbarUserItem ? this.menuItemWidths.get(this.navbarUserItem.id) : 0;
  }

  private get moreDropDownItemWidths(): number {
    if (!this.moreDropDownItems.length) return 0;
    if (this.moreDropDownItems.length > 2) {
      return this.menuItemWidths.get(this.moreDropDownItems[0].id);
    }
    return this.menuItemWidths.get(this.moreDropDownItems[0].id) + this.navbarUserItemWidth;
  }

  private get menuItemsSumWidth(): number {
    const sumWidth = sum(this.filteredNavbarItems.map((item) => this.menuItemWidths.get(item.id)));
    return this.moreDropDownItems.length
      ? this.moreDropDownMaxWidth + sumWidth
      : this.navbarUserItemWidth + sumWidth;
  }

  private get hasAppSwitcher(): boolean {
    const appsPermissions = this.authorizationService.appsPermissions;
    const apps = this.authorizationService.getApps(appsPermissions ?? []);
    return apps.length > 1 && EnvironmentVariableService.getVar('IONIC.ON') === false;
  }

  private initialize(): void {
    this.filteredNavbarItems = this.topNavbarService.filterByPermissions(this.navbarItems);

    this.allNavbarItems = this.navbarUserItem
      ? this.filteredNavbarItems.concat(this.navbarUserItem)
      : this.filteredNavbarItems;

    this.checkForAssigningIds(this.allNavbarItems);
  }

  private setMenuItemWidths(): void {
    this.navbarMenuItems.forEach(({ nativeElement }) => {
      this.menuItemWidths.set(nativeElement.getAttribute('id'), nativeElement.offsetWidth);
    });
  }

  private checkForAssigningIds(navbarItems: INavbarItem[]): void {
    navbarItems.forEach((item) => {
      if (!item.id) {
        item.id = `_${SecuredRandomService.securedRandomId().toString(36).substr(2, 9)}`;
      }
      if (item.children) {
        this.checkForAssigningIds(item.children);
      }
    });
  }

  private checkForMoreDropDown(): void {
    const navbar = this.navbar.nativeElement;
    const navbarBrand = this.navbarBrand.nativeElement;
    let paddings;

    if (window.innerWidth >= this.tablet && window.innerWidth <= this.desktop) {
      paddings = 32 * 2;
    }

    if (window.innerWidth >= this.desktop) {
      paddings = 40 * 2;
    }

    const navbarBrandMarginRight = 24;

    // Paddings and margin takes from styles. Check ui-design-system
    const expectedMenuWidth =
      navbar.offsetWidth - navbarBrand.offsetWidth - paddings - navbarBrandMarginRight;
    if (window.innerWidth >= this.tablet && expectedMenuWidth >= 0) {
      if (this.menuItemsSumWidth > expectedMenuWidth) {
        while (this.menuItemsSumWidth > expectedMenuWidth) {
          if (!this.moreDropDownItems.length && this.navbarUserItem) {
            this.moreDropDownItems.unshift(this.navbarUserItem);
          }
          this.moreDropDownItems.unshift(this.filteredNavbarItems.pop());
        }
      } else if (this.moreDropDownItems.length) {
        while (
          this.moreDropDownItems.length &&
          this.menuItemsSumWidth + this.moreDropDownItemWidths < expectedMenuWidth
        ) {
          this.filteredNavbarItems.push(this.moreDropDownItems.shift());
          if (this.moreDropDownItems.length === 1 && this.navbarUserItem) {
            this.moreDropDownItems.shift();
          }
        }
      }
    }
  }

  private get user(): IUser {
    return this.authorizationService.currentUser;
  }
}
