import { Component } from '@angular/core';

@Component({
  selector: 'vui-zero-permissions',
  templateUrl: './zero-permissions.component.html',
  styleUrls: ['./zero-permissions.component.scss'],
})
export class ZeroPermissionsComponent {
  constructor() {}
}
