<div class="columns is-vcentered">
  <div class="column is-half" aria-label="Transactions">
    <h1 class="title is-2" aria-describedby="Transactions" data-e2e="wl-previewContent-title" i18n>
      Transactions
    </h1>
  </div>
  <div class="column has-text-right">
    <button
      class="button"
      data-e2e="wl-exportCsv-button"
      type="button"
      i18n
      title="Export CSV"
      i18n-title
    >
      Export CSV
    </button>
  </div>
</div>

<portal-wl-transaction-filter></portal-wl-transaction-filter>

<p class="has-margin-bottom has-margin-top" data-e2e="wl-previewContent-totalCount">
  <span i18n>Total: 1430 transactions</span>
</p>

<portal-table [items]="itemsList" [tableConfig]="tableConfig"></portal-table>

<portal-pagination [currentPage]="1" [itemsPerPage]="10" [totalItems]="143"></portal-pagination>
