<span
  class="is-clickable"
  (click)="changeOrder()"
  data-e2e="table-sort"
  title="Sort Order"
  i18n-title
  tabindex="0"
>
  <span class="icon" *ngIf="order === SortOrder.None">
    <span class="is-sr-only" i18n>Not Sort </span>
    <i class="fas fa-sort"></i>
  </span>

  <span class="icon" *ngIf="order === SortOrder.Asc">
    <span class="is-sr-only" i18n>Sort descending</span>
    <i class="fas fa-sort-down"></i>
  </span>
  <span class="icon" *ngIf="order === SortOrder.Desc">
    <span class="is-sr-only" i18n>Sort ascending</span>
    <i class="fas fa-sort-up"></i>
  </span>
</span>
