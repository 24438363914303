import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vui-five-zero-three',
  templateUrl: './five-zero-three.component.html',
  styleUrls: ['./five-zero-three.component.scss'],
})
export class FiveZeroThreeComponent {
  constructor(private router: Router) {}
  navigateDashboard(): void {
    this.router.navigate(['/'], { state: { redirectToInitialPage: true } });
  }
}
