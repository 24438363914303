export enum MenuRegion {
  Administration = 'ADMINISTRATION',
  Boarding = 'BOARDING',
  Commerce = 'COMMERCE',
  VirtualTerminal = 'VT',
  PayByLink = 'PBL',
  Devices = 'DEVICES',
  Home = 'HOME',
  Marketplace = 'MARKETPLACE',
  PetroConfiguration = 'PETRO_CONFIGURATON',
}
