<div class="card-image has-text-centered is-flex is-horizontal-center">
  <figure class="image container is-256x256 is-relative is-flex logo-container has-background-white-bis has-text-black">
    <img
      class="image is-128x128 app-logo has-border-white is-vcentered"
      [src]="icon"
      data-e2e="app-card-image"
      alt="Loading image"
    />
    <img
      class="image is-256x256 blurred"
      [src]="icon"
      alt="Loading image"
    />
  </figure>
</div>
