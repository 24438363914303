import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DeviceCardTemplateModule } from '../device-card-template/device-card-template.module';
import { DevicesCardsTemplateComponent } from './devices-cards-template.component';

@NgModule({
  imports: [CommonModule, DeviceCardTemplateModule],
  declarations: [DevicesCardsTemplateComponent],
  exports: [DevicesCardsTemplateComponent],
})
export class DevicesCardsTemplateModule {}
