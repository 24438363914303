<!-- <section *ngIf="!apps?.length" class="section" data-e2e="no-data">
  <div class="container has-text-centered">
    <h2 class="title is-spaced">There are no Applications Available</h2>
    <ng-container *ngIf="router.url !== '/app-catalog'">
      <p class="subtitle">Try Our Applications Catalog</p>
      <a class="button is-primary" (click)="goToAppCatalog()">Applications Catalog</a>
    </ng-container>
  </div>
</section> -->
<div class="columns is-mobile is-multiline">
  <div *ngIf="!apps?.length" data-e2e="no-data" class="column box">
    <!-- <p i18n>There is no data available for display.</p> -->
    <p>Applications coming soon!</p>
  </div>
  <div
    class="column is-12-mobile is-6-tablet is-3-desktop is-3-desktop is-3-widescreen is-3-fullhd"
    *ngFor="let app of apps"
  >
    <marketplace-app-card-template
      [isFeaturedPath]="isFeaturedPath"
      [isPublicPath]="isPublicPath"
      [isPilotPath]="isPilotPath"
      [isDefaultPath]="isDefaultPath"
      [app]="app"
      [showUpdatesIndicator]="showUpdatesIndicators"
      [showInstallsIndicator]="showInstallsIndicators"
      [showTags]="true"
      [showVaultStatus]="showVaultStatus"
      [isPublic]="isPublic"
      data-e2e="app-card"
      (goToAppPageClickEvent)="goToAppPage($event)"
      (assignAppToDevicesClick)="assignAppToDevices($event)"
    >
    </marketplace-app-card-template>
  </div>
</div>
