<span
  [ngClass]="{'price has-text-primary has-text-weight-semibold is-size-3': !isRegular}"
  data-e2e="app-page-app-status-price"
>
  <ng-container *ngIf="paymentModel === 'FREE'">Free</ng-container>
  <ng-container *ngIf="paymentModel !== 'FREE'"> {{ price | currency : '$' }}</ng-container>
</span>
<span
  *ngIf="displayPaymentModel"
  class="price-model has-text-grey"
  data-e2e="app-page-app-pricing-model-main"
  >/month</span
>

<span
  *ngIf="price === null && paymentModel !== 'FREE'"
  class="price-model has-text-grey"
  data-e2e="app-page-app-pricing-model-main"
  >
  <ng-container *ngIf="paymentModel === 'ONE_TIME'">One time</ng-container>
  <ng-container *ngIf="paymentModel === 'RECURRING'">Monthly</ng-container>
</span
>