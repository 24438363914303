<div class="error-page">
  <div class="error-page-content">
    <div class="box-denied box">
      <h2 class="title is-3 has-text-left" i18n>Access denied</h2>
      <p class="has-text-left" i18n>
        Your account does not have access rights to any of the Verifone applications.
      </p>
    </div>
  </div>
</div>
