/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@portal/shared/auth/authentication/src/lib/authentication.service';

import { GTMUserRoles } from '@portal/shared/analytics';
import * as jwt_decode from 'jwt-decode';
import { IUser } from './IUser';
import { QueryParams } from '@ngrx/data';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { VuiHttpService } from '@portal/shared/vui-http/src/lib/http-wrapper/vui-http.service';
import { CustomUserDataService } from './data.service';
import { catchError, filter, finalize, map } from 'rxjs/operators';
import { ES_CONSTANTS as CONSTANTS, IUser as IESUser } from '@portal/entity-services/interfaces';
import {
  UserRoles,
  VerifoneUserRoles,
} from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';
import { Application } from '@portal/shared/auth/authorization/src/lib/enums/application.enum';
import { IPasswordChange } from '@portal/entity-services/interfaces/src/lib/users/interfaces/password-change.interface';
import { IResetPassword } from '@portal/entity-services/interfaces/src/lib/users/interfaces/reset-password';
import { ICheckEmail } from '@portal/shared/vui-http/src/lib/interfaces/check-email.interface';
import { UserAppmarketSettings } from '@portal/marketplace/api-interfaces/src/lib/user/user-appmarket-settings.enum';
import { IUserAppMarketUserSettingsResponse } from '@portal/marketplace/api-interfaces/src/lib/user/IUser';
import environment from '@environments';

const defaultApplicationKey = 'default_application';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  loading$: Observable<boolean>;

  singleUser: Subject<boolean> = new Subject<boolean>();
  private setLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _user: IUser;
  private _token: string;
  private _userDetails: IUser;

  constructor(
    private authenticationService: AuthenticationService,
    private customDataService: CustomUserDataService,
    private httpService: VuiHttpService,
  ) {
    this.loading$ = this.setLoading$.asObservable();
    this.user = {};

    this.authenticationService.authEvents.subscribe(() => {
      this.setCurrentUser();
    });
  }

  get user(): IUser {
    return this._user;
  }

  set user(val: IUser) {
    this._user = val;
  }

  get name(): string {
    return this._user.name;
  }

  get roles(): any {
    return this.user.roles;
  }

  get email(): string {
    return this.userDetails.email;
  }

  get userId(): string {
    return this.userDetails.userUid;
  }

  set userDetails(value: IUser) {
    this._userDetails = value;
  }

  get userDetails(): IUser {
    return this._userDetails;
  }

  get defaultApplication(): Application {
    const savedApp = window.localStorage.getItem(defaultApplicationKey);

    return Object.values(Application).find((app) => app === savedApp) || null;
  }

  setLoading(state: boolean): void {
    this.setLoading$.next(state);
  }

  setEntityUid(access_token): void {
    const eid: IUser = jwt_decode(access_token);
    this.user.entityUid = eid.entity_id;
  }

  getEntityUid(): string {
    return this._user.entityUid;
  }

  hasRole(role: UserRoles): boolean {
    return this.roles.includes(role);
  }

  setCurrentUser(): void {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) return;

    const payload = JSON.parse(atob(accessToken.split('.')[1]));
    this._user.entityUid = payload['entity_id'];
    this._user.roles = payload['roles'];

    // use this global variable in Google Tag Manager for
    // filtering based on googleTagManagerRole variable
    // the variable value must be VERIFONE_USER or
    // EXTERNAL_USER depends on current User Role
    globalThis.googleTagManagerRole = Object.values(VerifoneUserRoles).some((role) =>
      this.hasRole(role),
    )
      ? GTMUserRoles.VerifoneUser
      : GTMUserRoles.ExternalUser;
  }

  isLogin(): Boolean {
    return this.authenticationService.isAuthenticated;
  }

  checkEmailExist(params?: QueryParams): Observable<ICheckEmail> {
    return this.customDataService.checkEmailExist(params);
  }

  delete(userId: string, reasonForDeletion?: string): Observable<any> {
    return this.customDataService.delete(userId, reasonForDeletion).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  update(user: IESUser): Observable<any> {
    return this.httpService.put(`${CONSTANTS.ENTITY_SERVICE.USER}${user.userUid}`, user).pipe(
      map((response: any) => {
        if (!response) {
          return null;
        }
        return response;
      }),
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  getByKey(id: string): Observable<IESUser> {
    this.setLoading(true);
    return this.customDataService.getByKeyWithQuery(id, { populateEntity: 'true' }).pipe(
      map((user: IESUser) => {
        this.setLoading(false);
        return user;
      }),
      catchError((error) => {
        this.setLoading(false);
        throw error;
      }),
    );
  }

  getUserByID(id: string): Observable<IESUser> {
    return this.customDataService.getByKeyWithQuery(id, {});
  }

  partialUpdate(partialUser: Partial<IESUser>): Observable<IESUser> {
    return this.httpService
      .patch<IESUser>(`${CONSTANTS.ENTITY_SERVICE.USER}${partialUser.userUid}`, partialUser)
      .pipe(
        filter((updatedUser: IESUser) => !!updatedUser),
        finalize(() => {
          this.setLoading(false);
        }),
      );
  }

  setDefaultApplication(application: Application): void {
    window.localStorage.setItem(defaultApplicationKey, application || '');
  }

  changePassword(data: IPasswordChange): Observable<any> {
    return this.httpService.post(`${CONSTANTS.UIS.CHANGE_PASSWORD}`, data).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  resetPassword(data: IResetPassword): Observable<void> {
    return this.httpService.post<void>(CONSTANTS.UIS.RESET_PASSWORD, data).pipe(
      finalize(() => {
        this.setLoading(false);
      }),
    );
  }

  /**
   * Get user settings by identifier
   * https://dev.vfappmarket.verifonecp.com/swagger-ui.html#/user-and-entity-settings/getUserSettingsUsingGET
   */
  getAppMarketUserSettings(): Observable<{ data: IUserAppMarketUserSettingsResponse[] }> {
    return this.httpService.get(`${environment.API_ENDPOINT.VF_MARKETPLACE}/v1/settings`);
  }

  /**
   * Get user settings by identifier
   * https://dev.vfappmarket.verifonecp.com/swagger-ui.html#/user-and-entity-settings/getUserSettingsUsingPOST
   */
  setAppMarketUserSettings(
    identifier: UserAppmarketSettings,
    value: string | boolean,
  ): Observable<{ data: IUserAppMarketUserSettingsResponse[] }> {
    return this.httpService.post(`${environment.API_ENDPOINT.VF_MARKETPLACE}/v1/settings`, {
      data: {
        name: identifier,
        value: value,
      },
    });
  }

  getUsersByEntity(entityUid: string): Observable<IESUser[]> {
    return this.httpService.get(
      `${CONSTANTS.ENTITY_SERVICE.SINGLE_USER_RMS_ENTITY_SERVICE}/${entityUid}/users`,
    );
  }
}
