<ng-container *ngIf="criteriaMap.size === 0; else hasFilters">
  <ng-container *ngIf="getScreenWidth > 808; then legendText; else legendTooltip"></ng-container>
  <ng-template #legendText>
    <div class="columns">
      <div
        class="column is-justify-content-flex-end pt-1 is-align-items-center is-flex"
        [ngClass]="{
          'is-three-quarters': getScreenWidth > 1026,
          'is-three-fifths': getScreenWidth > 908 && getScreenWidth <= 1026
        }"
      >
        <span class="icon is-large">
          <span class="fa-stack fa-md">
            <i class="fas fa-circle fa-stack-2x has-text-success"></i>
            <i class="fas fa-pencil fa-stack-1x has-text-white"></i>
          </span>
        </span>
        <span i18n class="pl-2 has-text-weight-semibold">Editable</span>
      </div>
      <div
        class="column px-0 is-one-fifths is-justify-content-flex-end pt-1 is-align-items-center is-flex not-editable-position"
      >
        <span class="icon is-large">
          <span class="fa-stack fa-md">
            <i class="fas fa-circle fa-stack-2x has-text-warning"></i>
            <i class="fas fa-times fa-stack-1x"></i>
          </span>
        </span>
        <span i18n class="pl-2 has-text-weight-semibold">Not Editable</span>
      </div>
    </div>
  </ng-template>
  <ng-template #legendTooltip>
    <div class="columns">
      <div class="column is-full pt-1">
        <span i18n [attr.data-tooltip]="'Editable'" class="tooltip has-tooltip-top">
          <span class="icon is-large">
            <span class="fa-stack fa-md">
              <i class="fas fa-circle fa-stack-2x has-text-success"></i>
              <i class="fas fa-pencil fa-stack-1x has-text-white"></i>
            </span>
          </span>
        </span>
        <span i18n [attr.data-tooltip]="'Not Editable'" class="tooltip has-tooltip-top pl-3">
          <span class="icon is-large">
            <span class="fa-stack fa-md">
              <i class="fas fa-circle fa-stack-2x has-text-warning"></i>
              <i class="fas fa-times fa-stack-1x"></i>
            </span>
          </span>
        </span>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #hasFilters>
  <ng-container
    *ngIf="
      (criteriaMap.has('startTime') && getScreenWidth > 1102) ||
        (!criteriaMap.has('startTime') && getScreenWidth > 940);
      then legendText;
      else legendTooltip
    "
  >
  </ng-container>
  <ng-template #legendText>
    <div class="columns">
      <div class="column is-justify-content-flex-end pt-1 is-align-items-center is-flex">
        <span class="icon is-large">
          <span class="fa-stack fa-md">
            <i class="fas fa-circle fa-stack-2x has-text-success"></i>
            <i class="fas fa-pencil fa-stack-1x has-text-white"></i>
          </span>
        </span>
        <span i18n class="pl-2 has-text-weight-semibold">Editable</span>
      </div>
      <div
        class="column px-0 is-one-fifths is-justify-content-flex-end pt-1 is-align-items-center is-flex not-editable-position"
        [ngClass]="{
          'has-all-filters': criteriaMap.size > 2 && getScreenWidth < 1504
        }"
      >
        <span class="icon is-large">
          <span class="fa-stack fa-md">
            <i class="fas fa-circle fa-stack-2x has-text-warning"></i>
            <i class="fas fa-times fa-stack-1x"></i>
          </span>
        </span>
        <span i18n class="pl-2 has-text-weight-semibold">Not Editable</span>
      </div>
    </div>
  </ng-template>
  <ng-template #legendTooltip>
    <div class="columns">
      <div class="column is-full pt-1">
        <span i18n [attr.data-tooltip]="'Editable'" class="tooltip has-tooltip-top">
          <span class="icon is-large">
            <span class="fa-stack fa-md">
              <i class="fas fa-circle fa-stack-2x has-text-success"></i>
              <i class="fas fa-pencil fa-stack-1x has-text-white"></i>
            </span>
          </span>
        </span>
        <span i18n [attr.data-tooltip]="'Not Editable'" class="tooltip has-tooltip-top pl-3">
          <span class="icon is-large">
            <span class="fa-stack fa-md">
              <i class="fas fa-circle fa-stack-2x has-text-warning"></i>
              <i class="fas fa-times fa-stack-1x"></i>
            </span>
          </span>
        </span>
      </div>
    </div>
  </ng-template>
</ng-template>
