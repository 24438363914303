import {
  IThreeDSecureProviderContract,
  IThreeDSecureProviderContractEntityResponse,
  IThreeDSecureProviderContractResponse,
} from '@portal/entity-services/interfaces';

/***
 * This type predicate returns true if the argument type is IThreeDSecureProviderContractResponse
 *
 * To learn more about type predicates, see: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @param x
 */
export function isIThreeDSecureProviderContractResponse(
  x: IThreeDSecureProviderContract,
): x is IThreeDSecureProviderContractResponse {
  return (x as IThreeDSecureProviderContractResponse)?.entityUid !== undefined;
}

/***
 * This type predicate returns true if the argument type is IThreeDSecureProviderContractEntityResponse
 *
 * To learn more about type predicates, see: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @param x
 */
export function isIThreeDSecureProviderContractEntityResponse(
  x: IThreeDSecureProviderContract,
): x is IThreeDSecureProviderContractEntityResponse {
  return (x as IThreeDSecureProviderContractEntityResponse)?.entity !== undefined;
}
