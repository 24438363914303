import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TransactionTypes } from '@portal/entity-services/interfaces';
import {
  FILTER_CONTENT_ITEM,
  ISearchCriteria,
  ISelectInput,
  SearchSelector,
} from '@portal/shared/ui/filter';
import { FilterStaticBase } from '@portal/shared/ui/filter/src/lib/components/static-base';
import { TransactionFilterList } from '../transaction-filters.list';
import { FiltersService } from '@portal/shared/ui/filter/src/lib/services/filters.service';
import { SupportedProcessorSettingsService } from '@apps/portal/src/app/services/supported-processor-settings.service';
import { TransactionFilter } from '../../../enums/transaction-filter.enum';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'portal-filter-transaction-type',
  templateUrl: './transaction-type.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: FilterTransactionTypeComponent,
    },
  ],
})
export class FilterTransactionTypeComponent extends FilterStaticBase {
  @Input()
  isMainDropdownFilter: boolean;
  @Input()
  showFilterSubMenu: boolean;
  @Output()
  transactionTypeTextName: EventEmitter<string> = new EventEmitter();

  transactionTypeText = this.transactionfilters.list.find(
    (filter) => filter.id === TransactionFilter.TransactionType,
  ).text;

  constructor(
    private transactionTypes: TransactionTypes,
    private transactionfilters: TransactionFilterList,
    private filtersService: FiltersService,
    private supportedProcessorSettingsService: SupportedProcessorSettingsService,
  ) {
    super(SearchSelector.DisplayTransactionType, transactionTypes.getSupportedTransactionTypes());
    this.filtersService.actionRemoveFilter$.subscribe((entityToRemove: ISelectInput) => {
      if (SearchSelector.DisplayTransactionType === entityToRemove.key) {
        const selectedEntity = this.localPreSelectedEntities.get(entityToRemove.id);
        if (selectedEntity?.id) {
          this.onEntityRemoved(entityToRemove);
          this.onFilterApplied();
          this.itemToggled.emit();
        }
      }
    });

    this.supportedProcessorSettingsService.isProcessorFetched$.subscribe(() => {
      this.setAllEntities(transactionTypes.getSupportedTransactionTypes());
    });

    this.subMenuComponentSelected$.subscribe((submenu) => {
      if (SearchSelector.DisplayTransactionType.includes(submenu)) {
        this.synchronizeLocalPreSelectedEntities();
      }
      this.updateEntities();
    });

    this.updateEntities$.subscribe(() => {
      if (this.isMainDropdownFilter) {
        this.synchronizeLocalPreSelectedEntities();
      }
      this.updateEntities();
    });

    this.updateCounter$.subscribe(() => {
      const localPreSelected: Map<string, ISelectInput> = new Map();
      if (this.criteriaMapPreSelected.has(SearchSelector.DisplayTransactionType)) {
        const argumentArr = this.getArgumentArray(
          this.criteriaMapPreSelected,
          SearchSelector.DisplayTransactionType,
        );

        if (this.criteriaMapPreSelected.get(SearchSelector.DisplayTransactionType)?.argument) {
          argumentArr?.forEach((argument) => {
            localPreSelected.set(argument, {
              id: argument,
              text: this.transactionTypes.getValue(argument),
            });
          });
          this.localPreSelectedEntities = cloneDeep(localPreSelected);
        } else {
          this.localPreSelectedEntities.clear();
        }
      }
      this.updateEntities();
    });
  }

  onFilterAppliedAndEntitiesUpdated(): void {
    this.entities = this.transactionTypes.list;
    this.onFilterApplied();
    this.updateEntities();
    if (this.isOpen) {
      this.closeSubmenus$.next(true);
    }
    this.transactionTypeTextName.emit(this.transactionTypeText);
    this.isOpen = false;
  }

  private synchronizeLocalPreSelectedEntities(): void {
    if (this.criteriaMapPreSelected?.get(SearchSelector.DisplayTransactionType)?.argument) {
      const argumentArr = this.getArgumentArray(
        this.selectedEntities,
        SearchSelector.DisplayTransactionType,
      );
      this.localPreSelectedEntities.clear();
      argumentArr?.forEach((argument) => {
        this.setLocalPreSelectedEntities(argument, this.transactionTypes.getValue(argument));
      });
    } else {
      this.localPreSelectedEntities.clear();
    }
    this.updateEntities();
  }
}
