<div>
  <label class="label">{{ name }}</label>
  <div [ngClass]="{ 'is-danger': invalid }">
    <ng-select
      [formControl]="control"
      [bindValue]="bindValue"
      [bindLabel]="bindLabel"
      [multiple]="multiple"
      [closeOnSelect]="closeOnSelect"
      [items]="salesChannels"
      [attr.data-e2e]="e2eName"
      notFoundText="No items found"
      i18n-notFoundText
    >
      <ng-template ng-label-tmp let-item="item">
        <span class="ng-value-icon left" (click)="remove.emit(item)">×</span>
        <span class="ng-value-label" *ngIf="item">{{ item.text || item.id }}</span>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="checkbox is-pulled-left">
          <input
            [ngModel]="item$.selected"
            [ngModelOptions]="{ standalone: true }"
            [placeholder]="emptyOptionalName"
            disabled="{{ isChannelDisabled(item) }}"
            type="checkbox"
            id="item-{{ index }}"
            data-e2e="channelControl-checkbox"
          />
          <span>{{ item.text }}</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <portal-form-error [formInput]="control" [errorId]="e2eErrorName"></portal-form-error>
</div>
