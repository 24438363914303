import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTagLogoTemplateComponent } from './app-tag-logo-template.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AppTagLogoTemplateComponent],
  exports: [AppTagLogoTemplateComponent],
})
export class AppTagLogoTemplateModule { }
