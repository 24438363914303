import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { MultilingualValidators } from '@portal/shared/ui/form';

@Component({
  selector: 'portal-input-count',
  templateUrl: './input-count.component.html',
})
export class InputCountComponent implements OnInit {
  @Input() name: string;
  @Input() control: FormControl;
  @Input() invalid: boolean;
  @Input() optional: boolean;
  @Input() validators: ValidatorFn[] = [];
  @Input() e2eName = 'textControl-input';
  @Input() maxLength = 25;
  @Input() placeholder = '';

  constructor(private customValidators: MultilingualValidators) {}

  ngOnInit(): void {
    const validators = [this.customValidators.maxLength(this.maxLength)];
    if (this.validators.length) {
      this.validators.forEach((validator) => {
        validators.push(validator);
      });
    }
    this.control.setValidators(validators);
  }
}
