import { Injectable } from '@angular/core';
import { UserRole } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class UserRoleDescription extends SelectInputsList {
  keyValue = {
    [UserRole.MerchantAdmin]: $localize`Administrative role which can on-board new users & update blocking rulesets.`,
    [UserRole.MerchantReviewer]: $localize`An external viewing role. This user role will grant viewing access, but restricts the user from performing actions.`,
    [UserRole.MerchantSupervisor]: $localize`The owner of the organisation, this user will be able to use all payment tools and perform all payment actions.`,
    [UserRole.MerchantCashier]: $localize`This role will have access to the payment tools, but can not issue any refund.`,
    [UserRole.MerchantUser]: $localize`A viewing role. This user role will grant viewing access, but restricts the user from performing actions.`,
    [UserRole.MerchantPOIAdmin]: $localize`This role will have access to manage (read/add/update/disable/enable) the Point of Interactions.`,
    [UserRole.VerifoneAdmin]: $localize`Administrative role which can on-board new merchants`,
    [UserRole.VerifoneUser]: $localize`A viewing role. This user role will grant viewing access, but restricts the user from performing actions.`,
    [UserRole.DeveloperAdmin]: $localize`Publish applications and administrate developer company`,
    [UserRole.DeveloperUser]: $localize`Role for people who develop Marketplace applications`,
    [UserRole.VerifoneHelpdesk]: $localize`A helpdesk Role for VHQ support`,
    [UserRole.EstateOwnerAdmin]: $localize`Administrator and leader of the Estate`,
    [UserRole.EstateOwnerUser]: $localize`Person able to access and review applications`,
    [UserRole.EstateOwnerDeviceAppManager]: $localize`Person managing, operating and troubleshooting devices and apps`,
    [UserRole.VerifoneSalesManager]: $localize`A Verifone employee who can define & view the portfolio, also sets the pricing and campaigns`,
    [UserRole.VerifoneSalesRepresentative]: $localize`A Verifone employee who can view the portfolio on behalf of the country the user is in and can see pricing and campaigns`,
    [UserRole.ResellerManager]: $localize`An external reseller user who can view the portfolio, create, view and manage orders on behalf of the country the user is in `,
    [UserRole.ResellerRepresentative]: $localize`An external reseller employee who can create, view and manage orders orders at any country Level within their reseller group`,
    [UserRole.VerifoneFinOpsUser]: `Able to manage merchant FinOpsConfiguration`,
    [UserRole.VerifoneSettlementUser]: `Able to approve or reject payouts`,
    [UserRole.MerchantUnapproved]: $localize`A role for users who have self-signed up with Verifone and are awaiting KYC/AML approval.`,
    [UserRole.PartnerHelpdesk]: $localize`An external employee that is a member of a Helpdesk organization that is owned (or contracted) and managed by the Merchant`,
    [UserRole.PartnerFieldServiceProvider]: $localize`An external employee that is a member of a Field Service Provider organization that is owned (or contracted) and managed by the Merchant`,
    [UserRole.VerifoneFieldServiceProvider]: $localize`An employee that is a member of an organization Verifone contracted with to provide Field Service offerings`,
    [UserRole.MerchantOrderAdmin]: $localize`A user who have access to place orders and create agreement for onboarding organisations, sites, devices and/or features.`,
    [UserRole.ProviderWhiteLabelManager]: $localize`This role gives special permissions to define white labeling settings. Otherwise it has same access level as Merchant User.`,
    [UserRole.VerifoneMaster]: $localize`Master role which can on-board new verifone roles`,
  };

  list: ISelectInput[] = this.getSelectInputs();
}
