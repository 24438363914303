<div
  class="hierarchy dropdown width-full"
  [ngClass]="{ 'is-active': isOpen }"
  (portalClickOutside)="onClickOutside()"
>
  <div class="select width-full">
    <div *ngIf="!organizationGroup">
      <input type="hidden" [formControl]="controlEntityUid" />
      <input
        #orgSingleInput
        type="text"
        class="input pr-5"
        readonly="true"
        [ngClass]="{ 'is-danger': isInvalid(controlEntityUid) }"
        [attr.id]="id"
        [formControl]="controlEntityName"
        (click)="onToggleDropdown($event)"
        (keydown.enter)="onToggleDropdown($event)"
        (keydown.space)="onToggleDropdown($event)"
      />
    </div>

    <div *ngIf="organizationGroup">
      <input type="hidden" [formControl]="controlEntityUid" />
      <div
        #orgGroupInput
        [ngClass]="{ 'is-danger': isInvalid(controlEntityUid) }"
        class="selected-entities pr-5"
        readonly="true"
        [attr.id]="id"
        (click)="onToggleDropdown($event)"
        (keydown.enter)="onToggleDropdown($event)"
        (keydown.space)="onToggleDropdown($event)"
      >
        <div class="selectedFeature-list" *ngIf="selectedEntities?.name">
          <button
            type="button"
            class="button is-primary mb-1 mr-2 mt-1 ml-2 is-primary is-outlined has-text-black"
            title="{{ selectedEntities?.name }}"
          >
            <span>{{ selectedEntities?.name }}</span>
            <span
              class="icon is-size-7"
              (click)="removeSelectedParentOrg(selectedEntities?.entityUid)"
            >
              <i class="fas fa-times"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="dropdown-menu" [ngClass]="{ 'mt-4': organizationGroup && selectedEntities?.name }">
    <div class="dropdown-content">
      <div class="dropdown-item">
        <portal-search-filter-autocomplete-input
          (search)="onSearch($event)"
        ></portal-search-filter-autocomplete-input>
      </div>

      <hr class="dropdown-divider" />

      <div class="dropdown-item has-text-truncate" *ngIf="loading$ | async">
        <span class="has-text-truncate">
          <portal-spinner
            [isHorizontal]="true"
            [isSmall]="true"
            text="Loading organisations..."
            i18n-text
          ></portal-spinner>
        </span>
      </div>

      <div
        class="dropdown-item is-scrollable"
        *ngIf="!(loading$ | async)"
        #scrollContainer
        (scroll)="onScrolled($event)"
      >
        <span class="has-text-truncate" *ngIf="!entities?.length" i18n>
          Start typing to search for organisations..
        </span>

        <!-- tree -->
        <tree-root
          #tree
          class="tree"
          [options]="options"
          [nodes]="entities"
          [(state)]="treeState"
          (toggleExpanded)="onToggleExpanded($event)"
          (activate)="onActivateItem($event.node.data)"
          (deactivate)="onDeactivateItem()"
          (focusin)="onFocusIn($event, tree.treeModel)"
          (focusout)="onFocusOut($event, tree.treeModel)"
          tabindex="0"
        >
          <ng-template #treeNodeFullTemplate let-node let-index="index" let-templates="templates">
            <div
              [class]="node.getClass()"
              [class.tree-node]="true"
              [class.tree-node-expanded]="node.isExpanded && node.hasChildren"
              [class.tree-node-collapsed]="node.isCollapsed && node.hasChildren"
              [class.tree-node-leaf]="node.isLeaf"
              [class.tree-node-active]="node.isActive"
              [class.tree-node-focused]="node.isFocused"
              [class.is-tree-filtered]="hasFilteredText"
            >
              <tree-node-drop-slot
                *ngIf="index === 0"
                [dropIndex]="node.index"
                [node]="node.parent"
              >
              </tree-node-drop-slot>

              <tree-node-wrapper
                [node]="node"
                [index]="index"
                [templates]="templates"
              ></tree-node-wrapper>

              <tree-node-children [node]="node" [templates]="templates"></tree-node-children>

              <div
                class="load-more"
                *ngIf="
                  node.isExpanded && node?.children && node?.children?.length < node.data.count
                "
              >
                <span
                  *ngIf="!(loadingMore$ | async); else loadingTemplateForMore"
                  class="button has-text-primary is-text pl-5"
                  (click)="onLoadMore(node)"
                  i18n
                >
                  Load more...
                </span>
                <ng-template #loadingTemplateForMore>
                  <ng-container *ngIf="(loadingMore$ | async) === node.id">
                    <div class="load-more-container">
                      <div class="spinner-container">
                        <div class="spinner" role="status" aria-live="assertive"></div>
                      </div>
                      <span i18n> Loading... </span>
                    </div>
                  </ng-container>
                </ng-template>
              </div>

              <tree-node-drop-slot
                [dropIndex]="node.index + 1"
                [node]="node.parent"
              ></tree-node-drop-slot>
            </div>
          </ng-template>

          <ng-template #treeNodeTemplate let-node let-index="index">
            <div class="is-flex is-flex-direction-column">
              <div>
                <span class="is-size-6" style="display: inline-block">{{ node.data?.name }}</span>
                <span class="has-text-grey" *ngIf="node.data?.count">
                  ({{ node.data?.count }})
                </span>
                <span class="is-size-6 ml-4" *ngIf="node.data?.trashCan">
                  <i class="fa fa-trash"></i>
                </span>
              </div>
              <span class="is-size-7" *ngIf="node.data?.path && !node.data?.trashCan">{{
                node.data?.path
              }}</span>
            </div>
          </ng-template>
        </tree-root>
        <span class="has-text-truncate" *ngIf="loadingNewPortion$ | async">
          <portal-spinner
            [isHorizontal]="true"
            [isSmall]="true"
            text="Loading organisations..."
            i18n-text
          ></portal-spinner>
        </span>
      </div>

      <hr class="dropdown-divider" />
      <div class="dropdown-item">
        <div class="buttons" *ngIf="displayOptionApplyAndClear">
          <button
            class="button is-primary"
            (click)="onFilterApplied(true)"
            (keydown.enter)="onFilterApplied(true)"
            type="button"
            i18n
            title="Apply"
            i18n-title
          >
            Apply
          </button>
          <button
            class="level-left button is-text has-text-primary"
            (click)="onClear()"
            (keydown.enter)="onClear()"
            type="button"
            i18n
            title="Clear"
            i18n-title
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
