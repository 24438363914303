import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';

import { ITag, ITagModel, StorePagination, TTagType } from '@portal/marketplace/api-interfaces';
import { IFilterToApply } from '@portal/shared/ui/filter';
import { IQueryParams } from '@portal/shared/ui/table';

import { TagsDataService } from './tags-data.service';
import { TagsQuery } from './tags.query';
import { TagsStore } from './tags.store';

@Injectable({ providedIn: 'root' })
export class TagsService extends StorePagination {
  constructor(
    private tagsDataService: TagsDataService,
    private tagsStore: TagsStore,
    private tagsQuery: TagsQuery,
  ) {
    super();
  }

  itemsCount(): Observable<any> {
    return this.tagsQuery.select((state) => state.metadata.count);
  }

  loadAllTagsByType(
    type?: TTagType,
    filter?: IQueryParams,
    searchAndFilters?: IFilterToApply[],
  ): void {
    this.tagsStore.setLoading(true);
    this.tagsDataService
      .getAllTags(type, filter, searchAndFilters)
      .pipe(
        take(1),
        finalize(() => {
          this.tagsStore.setLoading(false);
        }),
      )
      .subscribe((response) => {
        this.tagsStore.update({ metadata: response['metadata'] });
        this.updateStorePagination(
          this.tagsStore,
          filter || {
            start: 0,
          },
          response['data'].map((tag) => tag.id),
        );
        this.tagsStore.set(response['data']);
      });
  }

  loadPoisForTags(tags: ITag[]): Observable<any> {
    return this.tagsDataService.getAllPoisForTag(tags.map((tag) => tag.id));
  }

  loadBundlesForTags(tags: ITag[]): Observable<any> {
    return this.tagsDataService.getAllBundlesForTag(tags.map((tag) => tag.id));
  }

  searchForTagByName(type: TTagType, name: string): Observable<ITag[]> {
    return this.tagsDataService.getAllTags(type, { start: 0, limit: 20 }, [
      {
        argument: name,
        key: `tagName-${name}`,
        operator: 'cn',
        selector: 'tagName',
      },
    ]);
  }

  getHasCache(): boolean {
    return this.tagsQuery.getHasCache();
  }

  getLoadingState(): Observable<boolean> {
    return this.tagsQuery.selectLoading();
  }

  createTag(tag: ITagModel): Observable<any> {
    return this.tagsDataService.createTag(tag).pipe(
      tap((response: ITag) => {
        this.tagsStore.add(response);
      }),
    );
  }

  updateTag(tag: ITag): Observable<any> {
    return this.tagsDataService.updateTag(tag).pipe(
      tap((response: ITag) => {
        this.tagsStore.replace(response.id, response);
      }),
    );
  }

  removeTag(tag: ITag): Observable<any> {
    return this.tagsDataService.removeTag(tag).pipe(
      tap(() => {
        this.tagsStore.remove(tag.id);
      }),
    );
  }

  getAll(): ITag[] {
    return this.tagsQuery.getAll();
  }

  selectAll(options = {}): Observable<ITag[]> {
    return this.tagsQuery.selectAll(options);
  }

  searchAllTagsByTagName(name: string): ITag[] {
    return this.tagsQuery.getAll({
      filterBy: (entity) => entity.name.toLocaleLowerCase().includes(name.toLocaleLowerCase()),
    });
  }

  isTagNameExist(name: string): boolean {
    return this.tagsQuery.hasEntity(
      (tag: ITag) => tag.name.toLocaleLowerCase() === name.toLocaleLowerCase(),
    );
  }
}
