import { Injectable } from '@angular/core';

import {
  Application,
  AuthorizationService,
  IUserApps,
  UserRoles,
} from '@portal/shared/auth/authorization';
import { LanguageHandle } from '@portal/shared/languages';
import { FederationType, IUser, UserRole } from '@portal/entity-services/interfaces';
import { INavbarItem } from '@portal/shared/ui/layouts/src/lib/top-navbar/interfaces/navbar-item.interface';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';
import { Features } from 'environments/enums/features.enum';
import { AuthenticationService } from '@portal/shared/auth/authentication/src';
import { ApplicationRoot } from '../enums/application-root.enum';
import { CommerceNavbarService } from './commerce/commerce-navbar.service';
import { DevicesNavbarService } from './devices/devices-navbar.service';
import {
  combineNavItems,
  getNavbarItemLink,
  NavbarItemLink,
} from '../helpers/navigation-route-helpers';
import { AdministrationNavbarService } from './administration/administration-navbar.service';
import { BoardingNavbarService } from './boarding/boarding-navbar.service';
import { PetroNavbarService } from './petro/petro-navbar.service';
import { MarketplaceNavbarService } from './marketplace/marketplace-navbar.service';
import { PetroService } from './petro/petro.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommonNavbarService {
  currentApplication = ApplicationRoot.Wrapper;
  languageRoute: string;
  user: IUser;
  supportPageUrl: NavbarItemLink;
  allowedApps: IUserApps[];

  constructor(
    private authorizationService: AuthorizationService,
    private languageService: LanguageHandle,
    private featureToggleService: FeatureToggle,
    private authenticationService: AuthenticationService,
    private commerceNavbarService: CommerceNavbarService,
    private devicesNavbarService: DevicesNavbarService,
    private administrationNavbarService: AdministrationNavbarService,
    private boardingNavbarService: BoardingNavbarService,
    private petroNavbarService: PetroNavbarService,
    private marketplaceNavbarService: MarketplaceNavbarService,
    private petroService: PetroService,
  ) {}

  logout(user: IUser): void {
    if (
      user.entity?.federationStatus ||
      (user?.entity?.federationType && user?.entity?.federationType !== FederationType.None)
    ) {
      this.authenticationService.logout({
        skipLocationChange: true,
        redirectPath: '',
        isFederated: true,
      });
    } else {
      this.authenticationService.logout({ skipLocationChange: true });
    }
  }

  getNavItems(
    user: IUser,
    currentApp: ApplicationRoot,
  ): Observable<{ navigationItems: INavbarItem[]; userNavItems: INavbarItem }> {
    if (this.isPetroUser(user?.roles)) {
      return this.petroService.getPermissions().pipe(
        map((permissions) => {
          this.petroService.setPermissions(permissions);
          return {
            navigationItems: this.getNavigationItems(user, currentApp),
            userNavItems: this.getNavbarUserItem(user, currentApp),
          };
        }),
      );
    } else {
      return of({
        navigationItems: this.getNavigationItems(user, currentApp),
        userNavItems: this.getNavbarUserItem(user, currentApp),
      });
    }
  }

  isPetroUser(roles: UserRole[]): boolean {
    const petroRoles = [
      UserRole.PetroSiteAdmin,
      UserRole.PetroSiteUser,
      UserRole.MajorOilAdmin,
      UserRole.MajorOilUser,
      UserRole.PetroHelpDeskAdmin,
      UserRole.PetroSalesforceAdmin,
      UserRole.PetroMerchantOnboarder,
      UserRole.CommanderOnboarder,
      UserRole.PetroPartnerAdmin,
    ];
    return roles.some((role) => {
      return petroRoles.includes(role);
    });
  }

  getNavbarUserItem(user: IUser, currentApp: ApplicationRoot): INavbarItem {
    this.currentApplication = currentApp;
    this.supportPageUrl = this.getItemUrl('/support', [
      ApplicationRoot.Payments,
      ApplicationRoot.Developer,
      ApplicationRoot.EstateOwner,
      ApplicationRoot.MOP,
      ApplicationRoot.Marketplace,
      ApplicationRoot.VHQ,
    ]);
    const navbarUserItem: INavbarItem = {
      title: user?.name,
      attributes: {
        'data-e2e': 'menu-item-user',
      },
      children: [
        {
          title: $localize`:Main menu navbar item name:My Account`,
          attributes: {
            'data-e2e': 'menu-item-account',
          },
          ...this.getItemUrl(`/administration/users/${user?.userUid}`, [
            ...(this.featureToggleService.isFeatureActive(Features.PaymentsPortalCore)
              ? [ApplicationRoot.Payments]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
            this.marketplaceNavbarService.showMarketplaceApp
              ? [ApplicationRoot.Marketplace]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
            this.marketplaceNavbarService.showEstateOwnerApp
              ? [ApplicationRoot.EstateOwner]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
            this.marketplaceNavbarService.showDevelopApp
              ? [ApplicationRoot.Developer]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
            this.marketplaceNavbarService.showMarketplaceManagerApp
              ? [ApplicationRoot.MarketplaceManager]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.MOPCore)
              ? [ApplicationRoot.MOP]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.PetroCore)
              ? [ApplicationRoot.Petro]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.VHQCore)
              ? [ApplicationRoot.VHQ]
              : []),
          ]),
          restrictPermissions: ['self-read'],
        },
        {
          title: $localize`:Main menu navbar item name:Support`,
          attributes: {
            'data-e2e': 'menu-item-help-and-support',
          },
          ...this.getItemUrl('/support', [
            ...(this.featureToggleService.isFeatureActive(Features.PaymentsPortalCore)
              ? [ApplicationRoot.Payments]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
            this.marketplaceNavbarService.showMarketplaceApp
              ? [ApplicationRoot.Marketplace]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
            this.marketplaceNavbarService.showEstateOwnerApp
              ? [ApplicationRoot.EstateOwner]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
            this.marketplaceNavbarService.showDevelopApp
              ? [ApplicationRoot.Developer]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.MOPCore)
              ? [ApplicationRoot.MOP]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.PetroCore)
              ? [ApplicationRoot.Petro]
              : []),
            ...(this.featureToggleService.isFeatureActive(Features.VHQCore)
              ? [ApplicationRoot.VHQ]
              : []),
          ]),
        },
        {
          title: $localize`:Main menu navbar item name:Logout`,
          href: 'logout',
          attributes: {
            'data-e2e': 'menu-item-logout',
          },
          onClick: () => this.logout(user),
        },
      ],
    };

    if (this.petroService.isPetroAdminOrUser()) {
      navbarUserItem.children.splice(1, 0, {
        title: $localize`:Main menu navbar item name:OTP Settings`,
        attributes: {
          'data-e2e': 'menu-item-otp-settings',
        },
        ...this.getItemUrl('/core/otp-settings', [ApplicationRoot.Petro]),
      });
    }

    if (this.petroService.isAdminUser() || this.petroService.isPartnerUser()) {
      navbarUserItem.children.splice(2, 0, {
        title: $localize`:Main menu navbar item name:Terms`,
        href: 'terms',
        attributes: {
          'data-e2e': 'menu-item-terms',
        },
        onClick: () => this.petroService.showTerms(),
      });
    }

    if (
      this.featureToggleService.isFeatureActive(Features.ApiKeysAuthentication) &&
      !this.isMerchantUnapprovedUser()
    ) {
      navbarUserItem.children.splice(1, 0, {
        title: $localize`:Main menu navbar item name:API Keys`,
        attributes: {
          'data-e2e': 'menu-item-api-keys',
        },
        ...this.getItemUrl('/api-keys', [ApplicationRoot.Payments, ApplicationRoot.Petro]),
      });
    }

    return navbarUserItem;
  }

  getNavigationItems(user: IUser, currentApplication: ApplicationRoot): INavbarItem[] {
    this.currentApplication = currentApplication;
    this.user = user;
    this.setLanguageRoute(user);
    this.allowedApps = this.authorizationService.getApps(
      this.authorizationService.getAppsPermissions() || [],
    );
    const params = {
      currentApplication,
      languageRoute: this.languageRoute,
      user: user,
    };

    if (!user) {
      return [];
    }

    const allowedAppBasedNavItems = [];
    if (
      this.checkIfUserHasPermissionToApp(Application.PaymentsPortal) ||
      this.checkIfUserHasPermissionToApp(Application.Petro)
    ) {
      allowedAppBasedNavItems.push(this.commerceNavbarService.getCommerceNavigationItems(params));
    }

    if (this.checkIfUserHasPermissionToApp(Application.VHQ)) {
      const vhqNavItems = this.devicesNavbarService.getDevicesNavbarItems(params);
      if (this.devicesNavbarService.isVhqDevicesAllowed) {
        allowedAppBasedNavItems.push(vhqNavItems);
      }
    }

    if (
      this.checkIfUserHasPermissionToApp(Application.PaymentsPortal) ||
      this.checkIfUserHasPermissionToApp(Application.Petro) ||
      this.checkIfUserHasPermissionToApp(Application.Marketplace)
    ) {
      allowedAppBasedNavItems.push(
        this.administrationNavbarService.getAdministrationNavbarItems(params),
      );
    }

    if (this.checkIfUserHasPermissionToApp(Application.MOP)) {
      allowedAppBasedNavItems.push(this.boardingNavbarService.getBoardingNavbarItems(params));
    }

    if (this.checkIfUserHasPermissionToApp(Application.Marketplace)) {
      allowedAppBasedNavItems.push(this.marketplaceNavbarService.getMarketplaceNavbarItems(params));
    }

    if (this.checkIfUserHasPermissionToApp(Application.Petro)) {
      allowedAppBasedNavItems.push(this.petroNavbarService.getPetroNavbarItems(params));
    }

    return combineNavItems(allowedAppBasedNavItems);
  }

  setLanguageRoute(user: IUser): void {
    let application: Application;
    switch (this.currentApplication) {
      case ApplicationRoot.Developer:
        application = Application.Developer;
        break;
      case ApplicationRoot.EstateOwner:
        application = Application.EstateOwner;
        break;
      case ApplicationRoot.MOP:
        application = Application.MOP;
        break;
      case ApplicationRoot.Marketplace:
        application = Application.Marketplace;
        break;
      case ApplicationRoot.MarketplaceManager:
        application = Application.MarketplaceManager;
        break;
      case ApplicationRoot.Payments:
        application = Application.PaymentsPortal;
        break;
      case ApplicationRoot.Petro:
        application = Application.Petro;
        break;
      case ApplicationRoot.VHQ:
        application = Application.VHQ;
        break;
      default:
        application = Application.Wrapper;
    }
    this.languageRoute = this.languageService.getSupportedLanguagePath(user?.language, application);
  }

  private checkIfUserHasPermissionToApp(application: Application): boolean {
    return this.allowedApps.some((app) => {
      return app.application === application;
    });
  }

  private isMerchantUnapprovedUser(): boolean {
    return this.authorizationService.getRoles().includes(UserRoles.MerchantUnapproved);
  }

  private getItemUrl(url: string, applications: ApplicationRoot[]): NavbarItemLink {
    return getNavbarItemLink(this.currentApplication, applications, this.languageRoute, url);
  }
}
