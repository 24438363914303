export default {
  paymentContracts: 'PPC',
  entities: 'ENTITIES',
  addresses: 'ADDRESSES',
  contacts: 'CONTACTS',
  businessInformation: 'BUSINESS_INFORMATION',
  paymentContractRelationships: 'PPC_RELATIONSHIPS',
  threeDSContracts: 'THREE_DS_CONTRACTS',
  walletContracts: 'WALLET_CONTRACTS',
  fraudProtectionContracts: 'FRAUD_PROTECTION_CONTRACTS',
  tokenScopes: 'TOKEN_SCOPES',
  fees: 'FEES',
  groups: 'GROUPS',
  users: 'USERS',
  accounts: 'ACCOUNTS',
  processors: 'PROCESSORS',
  poiCutoverConfiguration: 'POI_CUTOVER_CONFIGURATION',
  acquirers: 'ACQUIRER_CONFIG',
  checkApiKey: 'CHECK_API_KEY',
  pricelists: 'PRICE_LIST',
  checkout: 'CHECKOUT',
  theme: 'THEME',
  customer: 'CUSTOMER',
  '/theme/preview': 'PREVIEW_THEME',
  '/apikeys': 'API_KEYS',
  '/pois/': 'POI',
  '/devices': 'DEVICES',
  '/settlement': 'SETTLEMENT_INFORMATION',
  '/billingInformation': 'BILLING_INFORMATION',
  '/transactions': 'TRANSACTIONS',
  '/getTransactions': 'GET_TRANSACTIONS',
  '/surchargeSettings': 'SURCHARGE_SETTINGS',
  '/binRules/propertyValues': 'BIN_RULES',
};
