import { FiltersList, FilterTypes } from '@portal/shared/ui/filter';

export const bundlesFiltersList: FiltersList[] = [
  {
    selector: 'appName',
    title: 'Application name',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'Bundle name',
        id: 'bundleName',
      },

      {
        text: 'Bundle description',
        id: 'bundleDesc',
      },
    ],
  },
];
