import { Notification } from './notification';

export class Notifier {
  notifications: Notification[] = [];

  destroy(notification: Notification): void {
    this.notifications.splice(this.notifications.indexOf(notification), 1);
  }

  add(notification: Notification): void {
    this.notifications.unshift(notification);
  }
}
