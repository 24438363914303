import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  EntityStatus,
  EntityType,
  IEntityAncestors,
  IOrganisation,
  ITokenScope,
} from '@portal/entity-services/interfaces';
import { DeprecatedModalComponent } from '@portal/shared/ui/modal/src';
import { Observable, of } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { OrganisationService } from '../../../services/organisation.service';
import { TokenScopeService } from './token-scope.service';

@Component({
  selector: 'portal-scope-token',
  templateUrl: './token-scope.component.html',
  styleUrls: ['./token-scope.component.scss'],
})
export class TokenScopeComponent implements OnInit, OnDestroy {
  @Input() organisation: IOrganisation;

  @ViewChild('confirmModal') confirmModal: DeprecatedModalComponent;
  editModeState: Set<ITokenScope['tokenScopeUid']> = new Set();
  isCreateMode = false;
  saving = false;
  tokenScopes$: Observable<ITokenScope[]> = this.tokenScopeService.tokenScopes$;
  unlinkCallback: () => void;
  deleteTokenScopeCallback: () => void;

  constructor(
    private tokenScopeService: TokenScopeService,
    private organisationService: OrganisationService,
  ) {}

  canAddTokenScope(): boolean {
    return this.isMerchantCompany && this.isActive;
  }

  get isMerchantCompany(): boolean {
    return this.organisation?.entityType === EntityType.MERCHANT_COMPANY;
  }

  get isActive(): boolean {
    return this.organisation?.status === EntityStatus.Active;
  }

  ngOnInit(): void {
    if (this.isActive) {
      if (this.isMerchantCompany) {
        this.tokenScopeService.getAll(this.organisation.entityUid).subscribe();
      }

      if (this.organisation?.entityType) {
        this.organisationService
          .getAncestors(this.organisation.entityUid)
          .pipe(
            mergeMap((ancestors: IEntityAncestors) => {
              const merchantCompany = this.organisationService.getMerchantCompany(ancestors);

              return merchantCompany
                ? this.tokenScopeService.getAll(merchantCompany.entityUid)
                : of(undefined);
            }),
          )
          .subscribe();
      }
    }
  }

  ngOnDestroy(): void {
    this.tokenScopeService.clearAll();
  }

  onModalConfirmed(confirmed: boolean): void {
    if (confirmed) {
      if (this.isOrganisationTypeStore()) {
        this.unlinkCallback();
      } else {
        this.deleteTokenScopeCallback();
      }
    }
  }

  unlink(callback: () => void): void {
    this.unlinkCallback = callback;
    this.confirmModal.open();
  }

  onClose(tokenScopeUid?: ITokenScope['tokenScopeUid']): void {
    this.isCreateMode = false;
    this.editModeState.delete(tokenScopeUid);
  }

  onSave(tokenScopes: ITokenScope): void {
    this.saving = true;
    this.tokenScopeService
      .addTokenScope(this.organisation.entityUid, tokenScopes)
      .pipe(
        finalize(() => {
          this.onClose();
          this.saving = false;
        }),
      )
      .subscribe();
  }

  onEdit(tokenScopeUid: string): void {
    this.editModeState.add(tokenScopeUid);
    this.isCreateMode = false;
  }

  onDelete(tokenScope: ITokenScope): void {
    this.deleteTokenScopeCallback = () =>
      this.tokenScopeService.deleteTokenScope(tokenScope.tokenScopeUid).subscribe();
    this.confirmModal.open();
  }

  onCreate(): void {
    this.isCreateMode = true;
    this.editModeState.clear();
  }

  trackById(index: number, item: ITokenScope): ITokenScope['tokenScopeUid'] {
    return item.tokenScopeUid;
  }

  isOrganisationTypeStore(): boolean {
    return (
      this.organisation.entityType === EntityType.UNDEFINED ||
      this.organisation.entityType === EntityType.MERCHANT_SITE
    );
  }
}
