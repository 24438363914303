import { Injectable } from '@angular/core';
import { UserRoleGroup } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class UserRoleGroups extends SelectInputsList {
  keyValue = {
    [UserRoleGroup.Merchant]: $localize`Merchant roles`,
    [UserRoleGroup.Provider]: $localize`Provider roles`,
    [UserRoleGroup.Verifone]: $localize`Verifone roles`,
    [UserRoleGroup.Developer]: $localize`Developer roles`,
    [UserRoleGroup.EstateOwner]: $localize`Estate Owner roles`,
    [UserRoleGroup.Reseller]: $localize`Reseller roles`,
    [UserRoleGroup.Partner]: $localize`Partner roles`,
    [UserRoleGroup.Petro]: $localize`Petro Partner Roles`,
  };
  list: ISelectInput[] = this.getSelectInputs();
}
