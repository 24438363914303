<ul [className]="validated ? 'has-text-danger' : 'has-text-dark'">
  <li
    class="mb-1"
    *ngFor="let validation of validations"
    [ngClass]="{ 'has-text-success': passedValidation(validation.name) }"
  >
    <span *ngIf="!passedValidation(validation.name); else tickIcon">
      <i class="fal has-correct-icon has-text-dark" aria-hidden="true"></i> &nbsp;
    </span>
    {{ validation.error }}
  </li>
</ul>

<p class="has-text-grey mt-2" i18n>*Must be different from the last 10 passwords</p>

<ng-template #tickIcon>
  <span>
    <i class="fal has-pictogram-valid-icon has-text-success" aria-hidden="true"></i>
  </span>
</ng-template>
