import { EntityMetadataMap } from '@ngrx/data';
import { ES_CONSTANTS as CONSTANTS, IUser } from '@portal/entity-services/interfaces';

export const entityMetadata: EntityMetadataMap = {
  [CONSTANTS.ENTITY_SERVICE.USER]: {
    selectId: (user: IUser) => {
      return user.userUid;
    },
  },
};
