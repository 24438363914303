import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[portalKeyboardAccessibility]',
})
export class KeyboardAccessibilityDirective {
  @HostBinding('attr.tabindex') tabindex = 0;

  constructor(private _elRef: ElementRef) {}

  @HostListener('keyup.enter')
  @HostListener('keyup.space')
  handleKeyboardEvent(): void {
    this._elRef.nativeElement.click();
  }
}
