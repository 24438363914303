import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReportTemplateComponent } from './report-template.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ReportTemplateComponent],
  exports: [ReportTemplateComponent],
})
export class ReportTemplateModule {}
