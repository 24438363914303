import { IPagination, IQueryParams, QueryParamsService, SortOrder } from '@portal/shared/ui/table';

export abstract class PaginationSortHandler {
  filter: IQueryParams;
  resetPagination: boolean;

  onPagination(pagination: IPagination): void {
    const paginationFilter = QueryParamsService.changePage(this.filter, pagination);
    this.resetPagination = false;
    this.filter = paginationFilter;
    this.afterPaginate();
  }

  onSorting(key: string, order: SortOrder): void {
    this.filter.sort = {
      key,
      order,
    };
    const newSortFilter = QueryParamsService.changeSort(this.filter, order);
    this.resetPagination = true;
    this.filter = newSortFilter;
    this.afterSorting();
  }

  resetPaginationForFilter(): void {
    this.filter.start = 0;
    this.filter.currentPage = 0;
  }

  protected abstract afterPaginate(): void;
  protected abstract afterSorting(): void;
}
