import { SearchOperator } from '../enums/search-operator.enum';
import { SearchSelector } from '../enums/search-selector.enum';

export const SearchSelectorOperator = {
  [SearchSelector.MaskedCardNumber]: SearchOperator.Regex,
  [SearchSelector.CardNumber]: SearchOperator.Regex,
  [SearchSelector.CheckoutCustomerEmail]: SearchOperator.Regex,
  [SearchSelector.CheckoutCustomerPhone]: SearchOperator.Regex,
  [SearchSelector.CheckoutCustomerFirstName]: SearchOperator.Regex,
  [SearchSelector.CheckoutCustomerLastName]: SearchOperator.Regex,
  [SearchSelector.MerchantReferenceName]: SearchOperator.Regex,
};
