import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableModule } from '../table.module';
import { GroupedTableComponent } from './grouped-table.component';

@NgModule({
  imports: [CommonModule, TableModule],
  declarations: [GroupedTableComponent],
  exports: [GroupedTableComponent],
})
export class GroupedTableModule {}
