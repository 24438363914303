import { Subject } from 'rxjs';
import { ISelectInput } from '@portal/shared/ui/filter';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  setFilterToRemove$ = new Subject<ISelectInput>();
  actionRemoveFilter$ = this.setFilterToRemove$.asObservable();

  setFilterToRemove(localPreSelectedEntities: ISelectInput): void {
    this.setFilterToRemove$.next(localPreSelectedEntities);
  }
}
