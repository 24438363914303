// import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OutputType } from '../../enums/output-type.enum';
import { SelectionType } from '../../enums/selection-type.enum';
import { ISelection } from '../../interfaces/selection';
import { ITableConfig } from '../../interfaces/table-config.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class SelectionService {
  constructor() {}
  tableConfig: ITableConfig;
  onSelectedChanged = new Subject();

  private _selection: ISelection[] = [];
  get selection(): ISelection[] {
    return this._selection;
  }
  set selectItem(val: ISelection) {
    if (this.tableConfig.selectionConfig.selectionType === SelectionType.Single) {
      this._selection = [];
    }
    this._selection.push(val);
    this.onSelectedChanged.next(this.activeSelection);
  }
  set unSelectItem(val) {
    this._selection = this._selection.filter((item) => item.id !== val.id);
    this.onSelectedChanged.next(this.activeSelection);
  }

  set defaultSelection(val: ISelection[]) {
    this._selection = val;
    this.onSelectedChanged.next(this.activeSelection);
  }

  get activeSelection(): ISelection[] {
    return this._selection.filter((item) => item.checked === true);
  }

  onRowSelected(selection: ISelection): void {
    if (this.tableConfig.selectionConfig.outputType === OutputType.Id) {
      selection.item = selection.item[this.tableConfig.rowIdKey];
    }

    if (selection.checked) {
      this.selectItem = selection;
    } else {
      this.unSelectItem = selection;
    }
  }

  isSelected(id: any): boolean {
    // return this._selection.some(item => item.id === id);
    const entry = this.selection.filter((item) => item.id === id);
    return entry[0] && entry[0].checked ? true : false;
  }

  isDisabled(id: any): boolean {
    const entry = this.selection.filter((item) => item.id === id);
    return entry[0] && entry[0].disabled ? true : false;
  }

  selectAll(data: any[], check: boolean): void {
    if (check) {
      data.forEach((item) => {
        if (!this._selection.some((seleced) => seleced.id === item[this.tableConfig.rowIdKey])) {
          this._selection.push({
            id: item[this.tableConfig.rowIdKey],
            item: item,
            checked: check,
          });
        }
      });
    } else {
      // this._selection = [];
      this._selection = this._selection.filter((item) => item.disabled === true);
      // this._selection = this.defaultSelection;
    }
    this.onSelectedChanged.next(this.activeSelection);
  }
}
