import { AfterContentInit, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISelectInput } from '../../box/interfaces/select-input.interface';
import { FILTER_CONTENT_ITEM } from '../../content/token';
import { BaseFilter } from '../base-filter';

@Component({
  selector: 'portal-one-selection-filter',
  templateUrl: './one-selection-filter.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: OneSelectionFilterComponent,
    },
  ],
})
export class OneSelectionFilterComponent extends BaseFilter implements AfterContentInit {
  @Input() entitiesAdded$ = new BehaviorSubject<ISelectInput[]>(null);
  constructor() {
    super();
  }
  ngAfterContentInit(): void {
    if (this.entitiesAdded$) {
      this.entitiesAdded$.subscribe((entities) => {
        if (entities) {
          this.mapRetainedEntities(entities);
        }
      });
    }
  }
  mapRetainedEntities(selectors): void {
    if (selectors[this.selector]) {
      selectors[this.selector].forEach((element) => {
        this.onEntityAdded(element);
      });
      this.updateEntities();
    }
  }

  onEntityAdded(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.clear();
    this.localPreSelectedEntities.set(selectedEntity.id, selectedEntity);
  }
}
