export enum PaymentType {
  Affirm = 'AFFIRM',
  Afterpay = 'AFTERPAY',
  Alipay = 'ALIPAY',
  Amex = 'AMEX',
  BaiduPay = 'BAIDU_PAY',
  Bank = 'BANK',
  BankAxept = 'BANKAXEPT',
  BbbyCob = 'BBBY_COB',
  BbbyPlcc = 'BBBY_PLCC',
  Blik = 'BLIK',
  Card = 'CARD',
  Carnet = 'CARNET',
  Cash = 'CASH',
  Cb = 'CB',
  Cheque = 'CHEQUE',
  Clave = 'CLAVE',
  Crypto = 'CRYPTO',
  Dankort = 'DANKORT',
  Debit = 'DEBIT',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Ebt = 'EBT',
  EbtWicOffline = 'EBT_WIC_OFFLINE',
  Electron = 'ELECTRON',
  Edenred = 'EDENRED',
  Flexecash = 'FLEXECASH',
  Forbrugsforeningen = 'FORBRUGSFORENINGEN',
  GarminPay = 'GARMIN_PAY',
  Gift = 'GIFT',
  GiftCard = 'GIFT_CARD',
  Girocard = 'GIROCARD',
  Ikano = 'IKANO',
  IsraCard = 'ISRACARD',
  IsraelPrivateLabel = 'ISRAEL_PRIVATE_LABEL',
  Jcb = 'JCB',
  JdPay = 'JD_PAY',
  Klarna = 'KLARNA',
  KlarnaEcom = 'KLARNA_ECOM',
  KlarnaQr = 'KLARNA_QR',
  Landsbankinn = 'LANDSBANKINN',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Mobilepay = 'MOBILEPAY',
  OpOnlinePayment = 'OP_ONLINE_PAYMENT',
  Other = 'OTHER',
  Paypal = 'PAYPAL',
  PayPalEcom = 'PAYPAL_ECOM',
  PayPalEcomManaged = 'PAYPAL_ECOM_MANAGED',
  PaypalQR = 'PAYPAL_QR',
  Plcc = 'PLCC',
  Qcard = 'Q_CARD',
  ResursBank = 'RESURS_BANK',
  SamsungPay = 'SAMSUNG_PAY',
  Swish = 'SWISH',
  TenPay = 'TEN_PAY',
  Upi = 'UPI',
  Venmo = 'VENMO',
  VenmoQR = 'VENMO_QR',
  Vipps = 'VIPPS',
  Visa = 'VISA',
  Voyager = 'VOYAGER',
  VPay = 'VPAY',
  Wechat = 'WECHAT',
  WestpacDebit = 'WESTPAC_DEBIT',
  Wex = 'WEX',
  WorldpayUS = 'WORLDPAY_US',
  Zelle = 'ZELLE',
  Zip = 'ZIP',
}
