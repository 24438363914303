import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AddressType,
  BankAccountType,
  BusinessIdentifiersType,
  BusinessType,
  ContactType,
  EntityStatus,
  EntityType,
  FederationType,
  IAddress,
  IBankAccount,
  IContact,
  IGeometry,
  IOrganisation,
  IPaymentContract,
  IPoiCutoverConfiguration,
  IReceiptContract,
  IReceiptContractCreatePayload,
  IReceiptTemplate,
  PhoneType,
  SalesChannel,
  TemplateParameters,
} from '@portal/entity-services/interfaces';
import { IBusinessInformation } from '@portal/entity-services/forms/src/lib/interfaces/business-information.interface';
import { EntityTypes } from './entity-type.list';
import { LegalEntityTypes } from './legal-entity-type.list';
import {
  AppPermission,
  AuthorizationService,
  IAppPermissionAssignment,
  IOrganisationAppPermissionManagement,
  RoleGuardService,
  UserRoles,
} from '@portal/shared/auth/authorization';
import { Features } from 'environments/enums/features.enum';
import { CurrencyService, ICountry, ICurrency, Locale } from '@portal/shared/helpers';
import {
  EntityValidators,
  ErrorService,
  FormBase,
  MultilingualValidators,
} from '@portal/shared/ui/form';
import { BaseModalComponent } from '@portal/shared/ui/modal/src';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import omit from 'lodash-es/omit';
import isNull from 'lodash-es/isNull';
import uniqBy from 'lodash-es/uniqBy';
import differenceBy from 'lodash-es/differenceBy';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { OrganisationService } from '../../services/organisation.service';
import { GeometryHelper } from '../helpers/geometry.helper';
import { ContactTypes } from './contact-type.list';
import { MultiselectTreeItem } from '@portal/shared/ui/tree/src/lib/portal-multiselect-tree/multiselect-tree-item.interface';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle';
import { BusinessIdentifiersTypes } from './business-identifiers-type.list';
import { AppPermissionInheritance } from '@portal/shared/auth/authorization/src/lib/enums/app-permission-inheritance.enum';
import { PaymentDocumentTypes } from '../../services/payment-document-types.list';
import { IndustryTypes } from './industry-type.list';
import { FormSharedService } from './form-shared.service';
import { ReceiptProvider } from '../../enums/receipt-provider.enum';
import { FederationTypes } from './federation-type.list';
import { OrganisationValidators } from './organisation.validators';
import { ReceiptAttachmentTypes } from '../../services/receipt-attachment-types.list';
import { ReceiptAttachmentType } from '@portal/entity-services/interfaces/src/lib/organisations/enums/receipt-attachment-type.enum';
import { ISelectInput } from '@portal/shared/ui/filter/src';
import { ReceiptProviderTypes } from '../../services/receipt-provider.list';
import { AuthenticationService } from '@portal/shared/auth/authentication/src';
import { ReceiptSalesChannels } from './receipt-sales-channels.list';
import { ChannelControlComponent } from '@apps/portal/src/app/shared/components/channel-control/channel-control.component';

declare const $localize;
@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'portal-form-organisation',
  templateUrl: './form.component.html',
})
export class FormOrganisationComponent
  extends FormBase<IOrganisation>
  implements OnInit, OnChanges
{
  @Input() templateParameters: TemplateParameters;
  @Input() organisations: IOrganisation[];
  @Input() countries: ICountry[];
  @Input() organisation: IOrganisation;
  @Input() bankAccountsValue: IBankAccount[] = [];
  @Input() isCreatingOrganisation = false;
  @Input() canEditWhiteLabel: boolean;
  @Input() poiCutoverConfigurations: IPoiCutoverConfiguration[];
  @Input() rootEntityIdForParentUpdate: string;
  @Input() merchantCompanyEntityCount: number;
  @Input() trashCanOrgsPresent: IOrganisation[] = [];
  @Output() whiteLabelFeatureActiveForOrg: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('confirmDeleteContactModal') confirmDeleteContactModal: BaseModalComponent;
  @ViewChild('confirmDeleteAllContactsModal') confirmDeleteAllContactsModal: BaseModalComponent;
  @ViewChild('receiptChannelsDropdown') receiptChannelsDropdown: ElementRef<HTMLDivElement>;
  @ViewChildren(ChannelControlComponent)
  channelControlComponents: QueryList<ChannelControlComponent>;

  showFeatureManagement: boolean;
  isFeatureManagementAvailable: boolean;
  isParentFeatureManagement: boolean;
  featureDropDownPlaceholder = $localize`Select Features`;
  verifoneFeatures: AppPermission[];
  entityFeatures: IOrganisationAppPermissionManagement;
  inheritedFeatures: AppPermission[];
  selectedFeaturesWithInheritance: AppPermission[] = [];
  directlyAssignedFeatures: AppPermission[] = [];
  businessTypePhysical = BusinessType.Physical;
  contactTypeList = this.contactTypes.list;
  entityTypeList = this.entityTypes.list;
  legalEntityTypesList = this.legalEntityTypes.list;
  addressTypes = AddressType;
  isInvalid = ErrorService.isFieldInvalid;
  selectedOrganisationBusinessInformation: IBusinessInformation;
  canReadPaymentContracts = false;
  isBankAccountDisplayed = false;
  organisationAddresses = {
    billing: {} as IAddress,
    billingIndex: -1,
    delivery: {} as IAddress,
    deliveryIndex: -1,
    trading: {} as IAddress,
    tradingIndex: -1,
  };
  validators = [this.customValidators.required, this.customValidators.validateCharacters];
  siteReferenceIdValidators = [this.customValidators.siteReferenceId];
  showContactForm = false;
  contactForm: FormGroup;
  contactValue: IContact;
  isCreatingContact = false;
  editingContactIdx = -1;
  contactNameTobeDeleted = '';
  featuresOptions: MultiselectTreeItem[];
  featuresWithInheritanceOptions: MultiselectTreeItem[];
  currenciesList: ICurrency[] = this.currencyService.currencies.filter((currency) => {
    return currency.code !== 'USS';
  });
  validBusinessIdentifierList = this.businessIdentifiersTypes.list;
  invalidBusinessIdentifierList = this.businessIdentifiersTypes.InvalidBusinessIdentifierList;
  businessIdentifierList: ISelectInput[];
  isWhiteLabelFeatureActiveForOrg: boolean;
  isVerifoneAdmin: boolean;
  paymentDocumentTypeListText = this.paymentDocumentTypes.list.map((item) => item.text).join(', ');
  industryTypesList = this.industryTypes.list;
  showPaymentDocumentApiTokenForm = false;
  savingToken = false;
  errorInFetchingFeatures = false;
  federationTypesList = this.federationTypes.list;
  businessIdentifierTypesControl = new FormControl();
  hasSftpDeliveryFeature = false;
  receiptTemplateNamesList: String[];
  receiptAttachmentTypesList = this.receiptAttachmentTypes.list;
  maxLogoFileSizeInKb = 500;
  receiptProviderTypeList = this.invoiceProviderTypes.list;
  receiptProviderName: string;
  showTrashCanEntities = false;
  emptyParentEntityUid = new FormControl({ value: '', disabled: true });
  isAllowChildReused: FormControl = new FormControl(false);
  ecomContract: IPaymentContract;

  isMcrAvailable = this.featureToggleService.isFeatureActive(Features.MerchantChoiceRouting);
  hasPermissionToEditMcr = this.featureToggleService.hasAccessPermission(['et_m:w']);
  receiptSalesChannelsList = this.receiptSalesChannels.list;
  assignedChannels: string[] = [];
  disabledChannels: ISelectInput[] = [];

  constructor(
    public fb: FormBuilder,
    protected elementRef: ElementRef,
    private authorizationService: AuthorizationService,
    private featureToggleService: FeatureToggle,
    private roleGuard: RoleGuardService,
    private organisationService: OrganisationService,
    private contactTypes: ContactTypes,
    private entityTypes: EntityTypes,
    private legalEntityTypes: LegalEntityTypes,
    private customValidators: MultilingualValidators,
    private geometryHelper: GeometryHelper,
    private entityValidators: EntityValidators,
    private currencyService: CurrencyService,
    private formSharedService: FormSharedService,
    private businessIdentifiersTypes: BusinessIdentifiersTypes,
    private paymentDocumentTypes: PaymentDocumentTypes,
    private industryTypes: IndustryTypes,
    private federationTypes: FederationTypes,
    organisationValidators: OrganisationValidators,
    private invoiceProviderTypes: ReceiptProviderTypes,
    private receiptAttachmentTypes: ReceiptAttachmentTypes,
    private authService: AuthenticationService,
    private receiptSalesChannels: ReceiptSalesChannels,
  ) {
    super(
      fb.group({
        entityUid: [''],
        entityType: [EntityType.UNDEFINED, [customValidators.required]],
        locale: fb.group({
          countryCode: [''],
          language: [Locale.En],
          timezoneId: [''],
        }),
        name: ['', customValidators.maxLength(100)],
        description: ['', customValidators.maxLength(255)],
        altVfiEntityId: ['', customValidators.altVfiEntityId],
        parentEntityUid: ['', customValidators.required],
        url: ['', customValidators.urlWithOrWithoutHttp],
        mcc: ['', [customValidators.merchantReferenceCode]],
        taxRegistrationNumber: ['', customValidators.validateCharacters],
        tokenEntityId: [''],
        legalName: ['', customValidators.maxLength(255)],
        legalEntity: [''],
        features: [],
        initialFeatures: [],
        templateParameters: fb.group({
          id: [''],
          logo: [[]],
          favicon: [[]],
          pageStyle: fb.group({
            font: fb.array([fb.control('')]),
            navBar: fb.group({
              navbarBackgroundColor: [''],
              navbarItemColor: [''],
            }),
            brand: fb.group({
              brandBackground: [''],
              brandText: [''],
              brandOutlinedText: [''],
            }),
          }),
        }),
        emailDomainName: [''],
        domainName: ['', [customValidators.domainName]],
        loginDomainName: ['', [customValidators.domainName, customValidators.maxLength(255)]],
        businessType: [BusinessType.Ecommerce, [customValidators.required]],
        status: [EntityStatus.Active, customValidators.required],
        businessInformation: fb.group({}),
        bankAccounts: new FormArray([]),
        addresses: fb.array([fb.group({}), fb.group({}), fb.group({})]),
        isBillingAddressDisplayed: [false],
        isShippingAddressSectionDisplayed: [false],
        isShippingAddressDisplayed: [false],
        isTradingAddressSectionDisplayed: [false],
        isTradingAddressDisplayed: [false],
        isPaymentDocumentSectionDisplayed: [false],
        isReceiptProviderInvoice4U: [false],
        contacts: fb.array(
          [],
          [
            customValidators.conditional(
              () => this.isDisputeManagementCardSchemesSelected(),
              organisationValidators.orgContactEmailRequired,
            ),
          ],
        ),
        externalEntityIdentifiers: fb.group({
          siteReferenceId: ['', [customValidators.siteReferenceId]],
          companyGroupReferenceId: ['', customValidators.maxLength(256)],
          merchantFNSNumber: [''],
        }),
        defaultPoiCutoverConfigurationId: [''],
        federationType: [FederationType.None],
        merchantLogoUrl: ['', customValidators.url],
        verifoneBillingCurrency: ['', []],
        businessIdentifiers: fb.array([]),
        apiToken: [
          '',
          [
            customValidators.conditional(
              () => this.isReceiptProviderInvoice4U && this.showPaymentDocumentApiTokenForm,
              customValidators.required,
            ),
          ],
        ],
        industry: [''],
        creditorReferenceTypeId: ['', customValidators.maxLength(64)],
        creditorReferenceCustomSpecifier: ['', customValidators.maxLength(255)],
        batchThemeId: ['', customValidators.maxLength(64)],
        poiCrossEntityAccessAllowed: [false],
        sftpConnection: ['', customValidators.maxLength(255)],
        receiptProvider: [ReceiptProvider.None],
        receiptTemplate: [
          '',
          customValidators.conditional(
            () =>
              this.hasReceiptProvider && this.receiptProvider.value === ReceiptProvider.Verifone,
            customValidators.required,
          ),
        ],
        customTemplates: fb.array([]),
        receiptMerchantLogo: [''],
        receiptAttachmentType: [ReceiptAttachmentType.Pdf],
      }),
      elementRef,
    );
    this.receiptMerchantLogo.setValidators([
      customValidators.maxFileSizeInKb(this.maxLogoFileSizeInKb),
    ]);
    /***
     * This is necessary in order to be able to clear/reset these fields' during update
     * because NgRx doesn't take into account removed properties and will keep the old
     * values in the entity cache. https://github.com/ngrx/platform/issues/2061
     */
    this.whitelistEmptyValue = new Set([
      'description',
      'taxRegistrationNumber',
      'tokenEntityId',
      'domainName',
      'emailDomainName',
      'url',
      'merchantLogoUrl',
      'defaultPoiCutoverConfigurationId',
    ]);
    this.fieldsToNullify = new Set([
      'addresses[0].countrySubdivision',
      'addresses[1].countrySubdivision',
      'federationType',
    ]);

    this.fieldsToNullifyByCondition = new Map([
      ['addresses[0].geometry', this.geometryConditionCallback],
      ['addresses[1].geometry', this.geometryConditionCallback],
      ['addresses[2].geometry', this.geometryConditionCallback],
    ]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    const change = changes['bankAccountsValue'];
    if (!change?.firstChange && this.bankAccountsValue.length) {
      this.onBankAccountCollapse();
      this.bankAccountsValue.forEach(() => {
        this.onBankAccountExpand();
      });
    }
    this.patchValues();
  }

  ngOnInit(): void {
    this.isFeatureManagementAvailable =
      this.featureToggleService.isFeatureActive(Features.OrganisationServices) &&
      this.authorizationService.getRoles().includes(UserRoles.VerifoneAdmin);
    this.isVerifoneAdmin = this.authorizationService.getRoles().includes(UserRoles.VerifoneAdmin);
    this.isAllowChildReused.setValue(this.organisationService.allowChildReuse);
    // We need to support multiple business types, i.e. checkboxes, but there's no way to achieve it for now.
    // Therefore, by default the organisation will be considered a Physical Store in order to show the contact form.
    this.businessType.setValue(BusinessType.Physical);
    // Setting the first Form Contact in the Form Array
    if (this.organisation) {
      const disabledInputValues = [
        ...this.invalidBusinessIdentifierList
          .filter(
            (item) =>
              this.organisation?.businessIdentifiers?.length &&
              this.organisation.businessIdentifiers.find(
                (businessIdentifer) => businessIdentifer.identifierType === item.id,
              ),
          )
          .map((item) => ({ ...item, disabled: true })),
        ...(this.organisation.businessIdentifiers
          ? this.organisation.businessIdentifiers
              .filter(
                (item) =>
                  !this.validBusinessIdentifierList
                    .map((input) => input.id)
                    .includes(item.identifierType) &&
                  !this.invalidBusinessIdentifierList
                    .map((input) => input.id)
                    .includes(item.identifierType),
              )
              .map((item) => ({
                id: item.identifierType,
                text: item.identifierType,
                disabled: true,
              }))
          : []),
      ];

      this.showTrashCanEntities = this.organisation.entityType === EntityType.MERCHANT_COMPANY;
      if (this.organisation.entityType !== EntityType.MERCHANT_SITE) {
        this.merchantCompanyEntityCount = 0;
      }

      this.businessIdentifierList = [...this.validBusinessIdentifierList, ...disabledInputValues];

      this.populateContactsToEdit();

      this.organisation.parentEntityUid = get(
        this.organisation,
        'parentEntity.entityUid',
        this.organisation.parentEntityUid,
      );
      this.patchValues();
      this.updateParentControl();
      this.getBusinessInformation(this.organisation.entityUid);
      this.entityType.setAsyncValidators(
        this.entityValidators.asyncValidateMerchantCompany(this.organisation),
      );
      if (this.organisation.entityType === EntityType.MERCHANT_COMPANY) {
        this.parentEntityUid.setAsyncValidators(
          this.entityValidators.asyncValidateParentForMerchantCompany(),
        );
      } else if (this.organisation.entityType === EntityType.MERCHANT_SITE) {
        this.parentEntityUid.setAsyncValidators(
          this.entityValidators.asyncValidateParentForMerchantSite(),
        );
      }
      this.entityType.markAsTouched();

      if (this.isFeatureManagementAvailable) {
        this.getAppsPermissions(this.organisation.entityUid);
      }

      if (this.organisation.parentEntity?.entityType === EntityType.MERCHANT_COMPANY) {
        this.poiCrossEntityAccessAllowed.disable();
      }

      if (this.templateParameters) {
        this.templateParametersForm.patchValue({
          ...this.templateParametersForm.value,
          ...this.templateParameters,
        });
      }

      if (this.organisation.contractUids?.receiptContractUidList?.length) {
        this.getReceiptContracts();
      }
    } else {
      this.businessIdentifierList = this.validBusinessIdentifierList;

      // to prevent creating merchant company in hierarchy where it already exists
      this.parentEntityUid.valueChanges.pipe(untilDestroyed(this)).subscribe((parentEntityUid) => {
        this.entityType.setAsyncValidators(
          this.entityValidators.asyncValidateMerchantCompany(
            {
              entityUid: parentEntityUid,
            },
            true,
          ),
        );

        if (this.entityType.value === EntityType.MERCHANT_COMPANY) {
          this.entityType.updateValueAndValidity();
        }

        if (this.isFeatureManagementAvailable) {
          this.getAppsPermissions(parentEntityUid, true);
          this.isParentFeatureManagement = true;
        }

        if (this.entityType.value === EntityType.MERCHANT_SITE) {
          this.setCrossEntityAccess(parentEntityUid);
        }
      });
    }

    this.domainNameControl.setAsyncValidators(
      this.organisationService.domainNameAsyncValidator(this.organisation?.entityUid),
    );

    this.entityType.valueChanges.pipe(untilDestroyed(this)).subscribe((entityType) => {
      if (
        entityType === EntityType.MERCHANT_SITE &&
        this.parentEntityUid.value &&
        !this.isUserAssignedEntitySelected
      ) {
        this.setCrossEntityAccess(this.parentEntityUid.value);
      }
    });

    if (this.organisation?.addresses?.length) {
      this.setOrganisationAddressesValues();
    }

    if (this.bankAccountsValue.length) {
      this.onBankAccountCollapse();
      this.bankAccountsValue.forEach(() => {
        this.onBankAccountExpand();
      });
    }

    const ppcListSnapshot = this.authorizationService.getRouteSnapshot(
      'administration/payment-contracts',
    );
    if (this.roleGuard.canAccess(ppcListSnapshot, 'read')) {
      this.canReadPaymentContracts = true;
    }

    this.receiptProvider.valueChanges.subscribe(() => {
      this.closeUpdateTokenForm();
      if (this.isReceiptProviderVerifone) {
        this.getReceiptTemplatesNames();
      } else {
        this.receiptTemplate.reset();
        this.customTemplates.reset();
      }
      this.receiptTemplate.updateValueAndValidity();
    });

    /***
     * getting WL editor form and
     * organisation filled form values
     * which was opened with routing
     */
    this.subscribeForGettingWhiteLabelEditorForm();

    this.selectedFeatures.valueChanges.subscribe(() => {
      this.contacts.updateValueAndValidity();
    });

    this.businessIdentifierTypesControl.valueChanges.subscribe((businessIdentifierTypes) => {
      const businessIdentifierValues = this.businessIdentifiers.value.map(
        (businessIdentifier) => businessIdentifier.identifierType,
      );
      businessIdentifierTypes
        .filter((type) => !businessIdentifierValues.includes(type))
        .forEach((type) => {
          this.businessIdentifiers.push(
            this.fb.group({
              identifierType: [type],
              value: [
                this.organisation?.businessIdentifiers?.find((item) => item.identifierType === type)
                  ?.value || '',
                [
                  this.customValidators.minLength(9),
                  this.customValidators.maxLength(20),
                  this.customValidators.validateCharacters,
                  this.customValidators.required,
                ],
              ],
            }),
          );

          const disabledBusinessIdentifierTypes = this.businessIdentifierList
            .filter((item) => !this.validBusinessIdentifierList.includes(item))
            .map((item) => item.id);

          if (disabledBusinessIdentifierTypes.includes(type)) {
            const index = this.businessIdentifiers.value.findIndex(
              (businessIdentifier) => businessIdentifier.identifierType === type,
            );
            this.businessIdentifiers.controls[index].get('value').disable();
          }
        });

      businessIdentifierValues
        .filter((type) => !businessIdentifierTypes.includes(type))
        .forEach((type) => {
          const index = this.businessIdentifiers.value.findIndex(
            (businessIdentifier) => businessIdentifier.identifierType === type,
          );
          this.businessIdentifiers.removeAt(index);
        });
    });

    this.customTemplates.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateTemplateChannelsList();
    });
  }

  togglingChildReuseSection(): void {
    this.isAllowChildReused.setValue(!this.isAllowChildReused.value);
    this.organisationService.setAllowChildReuse(this.isAllowChildReused.value);
  }
  populateReceiptFields(rec: IReceiptContract): void {
    this.isPaymentDocumentSectionDisplayed.setValue(true);
    const currentProvider: ISelectInput = this.receiptProviderTypeList?.find(
      (iProvider) => iProvider.id === rec.provider,
    );
    this.receiptProviderName = currentProvider.text;
    this.receiptProvider.setValue(currentProvider.id);

    if (rec.templateConfiguration) {
      this.receiptMerchantLogo.setValue(
        rec.templateConfiguration.receiptMerchantLogo
          ? [rec.templateConfiguration.receiptMerchantLogo]
          : [],
      );
      this.receiptMerchantLogo.updateValueAndValidity();

      this.getReceiptTemplatesNames();
      this.receiptTemplate.setValue(rec.templateConfiguration.templates[0]?.templateId ?? '');
      if (rec.templateConfiguration.templates.length > 1) {
        rec.templateConfiguration.templates.forEach((recTemplate, index) => {
          if (index !== 0) {
            this.pushCustomTemplate(recTemplate);
          }
        });
      }
    }

    this.receiptAttachmentType.setValue(
      rec.notifications[0]?.attachmentType ?? ReceiptAttachmentType.Pdf,
    );
  }

  handlePaymentContractOutput(paymentContracts: IPaymentContract[]): void {
    this.ecomContract = paymentContracts.find((contract) =>
      contract.salesChannels.includes(SalesChannel.Ecommerce),
    );
  }

  geometryConditionCallback = (value: IGeometry): boolean => {
    return (
      this.geometryHelper.isCoordinateEmpty(value?.coordinates[0]) &&
      this.geometryHelper.isCoordinateEmpty(value?.coordinates[1])
    );
  };

  populateContactsToEdit(): void {
    this.organisation.contacts.forEach((contact) => {
      this.addContactForm(true);
      this.contactForm.patchValue(contact);
      this.contactForm.setControl('addresses', this.getContactAddressesFormArray(contact));
      this.contacts.push(this.contactForm);
    });
  }

  getContactAddressesFormArray(contact: IContact): FormArray {
    if (contact.addresses && contact.addresses[0]) {
      return this.fb.array([
        this.fb.group({
          addressUid: ['', []],
          addressLine1: [
            contact.addresses[0].addressLine1,
            [this.customValidators.required, this.customValidators.maxLength(40)],
          ],
          addressLine2: [contact.addresses[0].addressLine2, [this.customValidators.maxLength(40)]],
          city: [
            contact.addresses[0].city,
            [
              this.customValidators.required,
              this.customValidators.validateCharacters,
              this.customValidators.maxLength(28),
            ],
          ],
          postCode: [contact.addresses[0].postCode, [this.customValidators.required]],
          addressType: [contact.addresses[0].addressType, [this.customValidators.required]],
          country: [contact.addresses[0].country, [this.customValidators.required]],
          countrySubdivision: [
            contact.addresses[0].countrySubdivision,
            [this.customValidators.validateCharacters],
          ],
        }),
      ]);
    }
    return this.fb.array([]);
  }

  setOrganisationAddressesValues(): void {
    this.organisation.addresses.forEach((itm, index) => {
      switch (itm.addressType) {
        case AddressType.Billing:
          this.organisationAddresses.billing = itm;
          this.organisationAddresses.billingIndex = index;
          break;
        case AddressType.Delivery:
          this.organisationAddresses.delivery = itm;
          this.organisationAddresses.deliveryIndex = index;
          break;
        case AddressType.Trading:
          this.organisationAddresses.trading = itm;
          this.organisationAddresses.tradingIndex = index;
          break;
        default:
          break;
      }
    });
    if (isEmpty(this.organisationAddresses.billing)) {
      this.isBillingAddressDisplayed.setValue(false);
    } else {
      this.isBillingAddressDisplayed.setValue(true);
    }

    if (isEmpty(this.organisationAddresses.delivery)) {
      this.isShippingAddressSectionDisplayed.setValue(false);
    } else {
      this.isShippingAddressSectionDisplayed.setValue(true);
      if (this.isShippingAddressEqualToBilling()) {
        this.isShippingAddressDisplayed.setValue(false);
      } else {
        this.isShippingAddressDisplayed.setValue(true);
      }
    }

    if (isEmpty(this.organisationAddresses.trading)) {
      this.isTradingAddressSectionDisplayed.setValue(false);
    } else {
      this.isTradingAddressSectionDisplayed.setValue(true);
      if (this.isTradingAddressEqualToBilling()) {
        this.isTradingAddressDisplayed.setValue(false);
      } else {
        this.isTradingAddressDisplayed.setValue(true);
      }
    }
  }

  onBankAccountExpand(): void {
    if (!this.bankAccounts) {
      this.form.setControl('bankAccounts', new FormArray([]));
    }
    this.addBankAccount();

    this.isBankAccountDisplayed = true;
  }

  onBankAccountCollapse(): void {
    this.form.removeControl('bankAccounts');
    this.isBankAccountDisplayed = false;
  }

  addBankAccount(): void {
    this.bankAccounts.push(
      this.fb.group({
        accountUid: ['', []],
        accountType: [BankAccountType.Domestic, [this.customValidators.required]],
        accountNumber: ['', [this.customValidators.required, this.customValidators.digits]],
        routingTransitNumber: [
          '',
          [
            this.customValidators.required,
            this.customValidators.digits,
            this.customValidators.minLength(9),
            this.customValidators.maxLength(9),
          ],
        ],
        accountName: ['', [this.customValidators.required]],
        accountHolderType: ['', [this.customValidators.required]],
        currency: ['', [this.customValidators.required]],
        iban: ['', []],
        country: ['', []],
        description: ['', [this.customValidators.maxLength(35)]],
        nickname: ['', [this.customValidators.required, this.customValidators.maxLength(70)]],
        externalAccountReference: ['', this.customValidators.required],
      }),
    );
  }

  removeBankAccount(index: number): void {
    this.bankAccounts.removeAt(index);
    if (!this.bankAccounts.length) {
      this.onBankAccountCollapse();
    }
  }

  get isBillingAddressDisplayed(): FormControl {
    return this.form.get('isBillingAddressDisplayed') as FormControl;
  }

  get isShippingAddressDisplayed(): FormControl {
    return this.form.get('isShippingAddressDisplayed') as FormControl;
  }

  get isShippingAddressSectionDisplayed(): FormControl {
    return this.form.get('isShippingAddressSectionDisplayed') as FormControl;
  }

  get isTradingAddressDisplayed(): FormControl {
    return this.form.get('isTradingAddressDisplayed') as FormControl;
  }

  get isTradingAddressSectionDisplayed(): FormControl {
    return this.form.get('isTradingAddressSectionDisplayed') as FormControl;
  }

  get isPaymentDocumentSectionDisplayed(): FormControl {
    return this.form.get('isPaymentDocumentSectionDisplayed') as FormControl;
  }

  get receiptProvider(): FormControl {
    return this.form.get('receiptProvider') as FormControl;
  }

  get isReceiptProviderInvoice4U(): boolean {
    return this.form.get('receiptProvider').value === ReceiptProvider.Invoice4U;
  }

  get isReceiptProviderVerifone(): boolean {
    return this.form.get('receiptProvider').value === ReceiptProvider.Verifone;
  }

  get hasReceiptProvider(): boolean {
    return (
      this.form.get('receiptProvider').value &&
      this.form.get('receiptProvider').value !== ReceiptProvider.None
    );
  }

  get hasSavedReceiptProvider(): boolean {
    return this.organisation?.receiptContract?.provider?.length > 0;
  }

  get url(): FormControl {
    return this.form.get('url') as FormControl;
  }

  get entityType(): FormControl {
    return this.form.get('entityType') as FormControl;
  }

  get name(): FormControl {
    return this.form.get('name') as FormControl;
  }

  get description(): FormControl {
    return this.form.get('description') as FormControl;
  }

  get creditorReferenceTypeId(): FormControl {
    return this.form.get('creditorReferenceTypeId') as FormControl;
  }

  get creditorReferenceCustomSpecifier(): FormControl {
    return this.form.get('creditorReferenceCustomSpecifier') as FormControl;
  }

  get batchThemeId(): FormControl {
    return this.form.get('batchThemeId') as FormControl;
  }

  get altVfiEntityId(): FormControl {
    return this.form.get('altVfiEntityId') as FormControl;
  }

  get parentEntityUid(): FormControl {
    return this.form.get('parentEntityUid') as FormControl;
  }

  get mcc(): FormControl {
    return this.form.get('mcc') as FormControl;
  }

  get taxRegistrationNumber(): FormControl {
    return this.form.get('taxRegistrationNumber') as FormControl;
  }

  get siteReferenceId(): FormControl {
    return this.form.get('externalEntityIdentifiers.siteReferenceId') as FormControl;
  }

  get companyGroupReferenceId(): FormControl {
    return this.form.get('externalEntityIdentifiers.companyGroupReferenceId') as FormControl;
  }

  get tokenEntityId(): FormControl {
    return this.form.get('tokenEntityId') as FormControl;
  }

  get legalName(): FormControl {
    return this.form.get('legalName') as FormControl;
  }

  get templateParametersForm(): FormGroup {
    return this.form.get('templateParameters') as FormGroup;
  }

  get legalEntity(): FormControl {
    return this.form.get('legalEntity') as FormControl;
  }

  get businessType(): FormControl {
    return this.form.get('businessType') as FormControl;
  }

  get businessInformation(): FormControl {
    return this.form.get('businessInformation') as FormControl;
  }

  get bankAccounts(): FormArray {
    return this.form.get('bankAccounts') as FormArray;
  }

  get businessIdentifiers(): FormArray {
    return this.form.get('businessIdentifiers') as FormArray;
  }

  get selectedFeatures(): FormControl {
    return this.form.get('features') as FormControl;
  }

  get initialFeatures(): FormControl {
    return this.form.get('initialFeatures') as FormControl;
  }

  get selectedServices(): FormControl {
    return this.form.get('services') as FormControl;
  }

  get addresses(): FormArray {
    return this.form.get('addresses') as FormArray;
  }

  get billingAddress(): FormGroup {
    return this.addresses.at(0) as FormGroup;
  }

  get shippingAddress(): FormGroup {
    return this.addresses.at(1) as FormGroup;
  }

  get tradingAddress(): FormGroup {
    return this.addresses.at(2) as FormGroup;
  }

  get contacts(): FormArray {
    return this.form.get('contacts') as FormArray;
  }

  get merchantLogoUrl(): FormControl {
    return this.form.get('merchantLogoUrl') as FormControl;
  }

  get apiToken(): FormControl {
    return this.form.get('apiToken') as FormControl;
  }

  get verifoneBillingCurrency(): FormControl {
    return this.form.get('verifoneBillingCurrency') as FormControl;
  }

  get domainNameControl(): FormControl {
    return this.form.get('domainName') as FormControl;
  }

  get loginDomainNameControl(): FormControl {
    return this.form.get('loginDomainName') as FormControl;
  }

  get merchantFNSNumber(): FormControl {
    return this.form.get('externalEntityIdentifiers.merchantFNSNumber') as FormControl;
  }

  get emailDomainNameControl(): FormControl {
    return this.form.get('emailDomainName') as FormControl;
  }

  get industry(): FormControl {
    return this.form.get('industry') as FormControl;
  }

  get defaultPoiCutoverConfigurationId(): FormControl {
    return this.form.get('defaultPoiCutoverConfigurationId') as FormControl;
  }

  get federationType(): FormControl {
    return this.form.get('federationType') as FormControl;
  }

  get poiCrossEntityAccessAllowed(): AbstractControl {
    return this.form.get('poiCrossEntityAccessAllowed');
  }

  get validBusinessIdentiferTypes(): string[] {
    return this.validBusinessIdentifierList.map((selectInput) => selectInput.id);
  }

  getBusinessIdentifierTypeLabel(identifierType: BusinessIdentifiersType): string {
    return this.businessIdentifierList.find((selecteInput) => selecteInput.id === identifierType)
      .text;
  }

  get receiptTemplate(): FormControl {
    return this.form.get('receiptTemplate') as FormControl;
  }

  get customTemplates(): FormArray {
    return this.form.get('customTemplates') as FormArray;
  }

  get receiptMerchantLogo(): FormControl {
    return this.form.get('receiptMerchantLogo') as FormControl;
  }

  get receiptAttachmentType(): FormControl {
    return this.form.get('receiptAttachmentType') as FormControl;
  }

  getContactTypeControl(index: number): FormControl {
    return this.contacts.get([index, 'contactType']) as FormControl;
  }

  getFirstNameControl(index: number): FormControl {
    return this.contacts.at(index).get('name.firstName') as FormControl;
  }

  getLastNameControl(index: number): FormControl {
    return this.contacts.at(index).get('name.lastName') as FormControl;
  }

  getPhoneNumberControl(index: number): FormControl {
    return this.contacts.get([index, 'phoneNumbers', 0]).get('value') as FormControl;
  }

  getEmailControl(index: number): FormControl {
    return this.contacts.get([index, 'email']) as FormControl;
  }

  getAddressTypeIndex(addressType: AddressType): number {
    return this.addresses.controls.findIndex(
      (address) => address.value.addressType === addressType,
    );
  }

  get sftpConnection(): FormControl {
    return this.form.get('sftpConnection') as FormControl;
  }

  get isUserAssignedEntitySelected(): boolean {
    return this.organisation?.entityUid === this.authService.entityUid;
  }

  get isAllChannelsAssigned(): boolean {
    return this.receiptSalesChannelsList.length === this.assignedChannels.length;
  }

  isShippingAddressEqualToBilling(): boolean {
    return (
      !Object.keys(this.organisationAddresses.delivery).length ||
      this.organisationService.compareAddresses(
        // The billing address doesn't have time to get value, so we have {} for first call
        omit(
          !isEmpty(this.billingAddress.getRawValue())
            ? this.billingAddress.getRawValue()
            : this.organisationAddresses.billing,
          ['addressUid', 'addressType'],
        ),
        omit(this.organisationAddresses.delivery, ['addressUid', 'addressType']),
      )
    );
  }

  isTradingAddressEqualToBilling(): boolean {
    return (
      !Object.keys(this.organisationAddresses.trading).length ||
      this.organisationService.compareAddresses(
        // The billing address doesn't have time to get value, so we have {} for first call
        omit(
          !isEmpty(this.billingAddress.getRawValue())
            ? this.billingAddress.getRawValue()
            : this.organisationAddresses.billing,
          ['addressUid', 'addressType'],
        ),
        omit(this.organisationAddresses.trading, ['addressUid', 'addressType']),
      )
    );
  }

  getAddressUidByType(type: AddressType): string {
    const billingAddress: IAddress = [
      ...this.addresses.getRawValue(),
      ...(this.organisation?.addresses || []),
    ].find(({ addressType }) => addressType === type);

    return billingAddress?.addressUid || '';
  }

  togglePaymentDocuments(): void {
    this.isPaymentDocumentSectionDisplayed.setValue(!this.isPaymentDocumentSectionDisplayed.value);
  }

  openBillingAddresses(): void {
    this.isBillingAddressDisplayed.setValue(true);
  }

  closeBillingAddresses(): void {
    const billingAddressUid = this.getAddressUidByType(AddressType.Billing);

    this.isBillingAddressDisplayed.setValue(false);

    if (!this.hasAddressInOrganisation(billingAddressUid)) {
      // Billing address must always be in position 0
      this.clearAddressControl(0);
    }
  }

  toggleBillingAddress(): void {
    if (this.isBillingAddressDisplayed.value) {
      this.closeBillingAddresses();
    } else {
      this.openBillingAddresses();
    }
  }

  toggleShippingAddressSection(): void {
    if (this.isShippingAddressSectionDisplayed.value) {
      this.isShippingAddressSectionDisplayed.setValue(false);
      this.clearAddressControl(1);
    } else {
      this.isShippingAddressSectionDisplayed.setValue(true);
    }
  }

  openShippingAddress(): void {
    this.isShippingAddressDisplayed.setValue(true);
  }

  closeShippingAddress(): void {
    const shippingAddressUid = this.getAddressUidByType(AddressType.Delivery);
    this.isShippingAddressDisplayed.setValue(false);

    if (!this.hasAddressInOrganisation(shippingAddressUid)) {
      // Shipping address must always be in position 1
      this.clearAddressControl(1);
    }
  }

  toggleShippingAddress(): void {
    if (this.isShippingAddressDisplayed.value) {
      this.closeShippingAddress();
    } else {
      this.openShippingAddress();
    }
  }

  toggleTradingAddressSection(): void {
    if (this.isTradingAddressSectionDisplayed.value) {
      this.isTradingAddressSectionDisplayed.setValue(false);
      this.clearAddressControl(2);
    } else {
      this.isTradingAddressSectionDisplayed.setValue(true);
    }
  }

  openTradingAddress(): void {
    this.isTradingAddressDisplayed.setValue(true);
  }

  closeTradingAddress(): void {
    this.isTradingAddressDisplayed.setValue(false);
    const tradingAddressUid = this.getAddressUidByType(AddressType.Trading);

    if (!this.hasAddressInOrganisation(tradingAddressUid)) {
      // Trading address must always be in position 2
      this.clearAddressControl(2);
    }
  }

  toggleTradingAddress(): void {
    if (this.isTradingAddressDisplayed.value) {
      this.closeTradingAddress();
    } else {
      this.openTradingAddress();
    }
  }

  featureSelected(selectedItems: MultiselectTreeItem[]): void {
    this.checkForSftpDeliveryFeature(selectedItems);
    this.updateSelectedFeatures();

    let features = this.getFeaturesFromOptions(selectedItems);

    //Merged directlyAssignedFeatures & inheritedFeatures
    const entityFeatures: AppPermission[] = this.isParentFeatureManagement
      ? this.getInheritedFeaturesforNewOrg(this.inheritedFeatures, this.directlyAssignedFeatures)
      : uniqBy([...this.directlyAssignedFeatures, ...this.inheritedFeatures], 'featureId');

    // Check if inheritedFeatures is selected again,
    // then add it into selectedFeaturesWithInheritance &
    // initialFeatures, to avoid any changes which is not submitted yet
    const diffInSelectedFeatures = differenceBy(features, this.initialFeatures.value, 'featureId');

    diffInSelectedFeatures.forEach((element) => {
      const feature = entityFeatures.find((ef) => ef.featureId === element.featureId);
      if (feature && !feature.inheritanceType) {
        this.selectedFeaturesWithInheritance.push(feature);
        this.initialFeatures.value.push(feature);
      } else if (
        this.isParentFeatureManagement &&
        feature?.inheritanceType === AppPermissionInheritance.InheritDescendants
      ) {
        this.selectedFeaturesWithInheritance.push(feature);
        this.initialFeatures.value.push(feature);
      }
    });

    // BLOCK_THIS_ENTITY_AND_DESCENDANTS if selected then
    // remove/add it from/to feature object,
    // based on it has already part of the inheritedFeature or not
    // so going to delete/put api call
    // set NONE for PUT if it was not present in the inheritedFeatures
    // Else check if BlockThisEntityAndDescendants was not selected
    // then remove it from initialFeatures to avoid any differences
    const selectedBlockEAndDesFeature = features.filter((f) =>
      entityFeatures.some(
        (fd) =>
          fd.featureId === f.featureId &&
          fd.inheritanceType === AppPermissionInheritance.BlockThisEntityAndDescendants,
      ),
    );
    const updateInheritanceTypeAsNoneForBlockAndDes: IAppPermissionAssignment[] = [];
    if (selectedBlockEAndDesFeature.length && !this.isParentFeatureManagement) {
      selectedBlockEAndDesFeature.forEach((feature) => {
        //insert into initialFeatures
        if (!this.initialFeatures.value.find((e) => feature.featureId === e.featureId)) {
          this.initialFeatures.value.push({
            featureId: feature.featureId,
            application: feature.application,
            inheritanceType: AppPermissionInheritance.BlockThisEntityAndDescendants,
          });
        }

        if (this.inheritedFeatures.some((e) => feature.featureId === e.featureId)) {
          //remove
          features = features.filter((f) => f.featureId !== feature.featureId);
        } else {
          //update
          updateInheritanceTypeAsNoneForBlockAndDes.push({
            featureId: feature.featureId,
            inheritanceType: AppPermissionInheritance.None,
          });
        }
      });
    } else {
      const notSelectedBlockEAndDesFeature = this.directlyAssignedFeatures.filter(
        (f) =>
          f.inheritanceType === AppPermissionInheritance.BlockThisEntityAndDescendants &&
          !features.some((i) => i.featureId === f.featureId),
      );
      //remove it from initialFeatures
      if (notSelectedBlockEAndDesFeature.length) {
        this.initialFeatures.setValue(
          this.initialFeatures.value.filter((f) =>
            notSelectedBlockEAndDesFeature.some((i) => i.featureId !== f.featureId),
          ),
        );
      }
    }

    //Handle BLOCK_DESCENDANTS if it was not part of the inheritFeatures
    //you are going to select them
    const updateInheritanceTypeNoneForBlockDes: IAppPermissionAssignment[] = [];
    const selectedBlockDesNotInheritedFeature = features
      .filter((feature) =>
        entityFeatures.some(
          (ef) =>
            ef.featureId === feature.featureId &&
            ef.inheritanceType === AppPermissionInheritance.BlockDescendants,
        ),
      )
      .filter((f) => !this.inheritedFeatures.some((i) => f.featureId === i.featureId));

    if (selectedBlockDesNotInheritedFeature.length) {
      selectedBlockDesNotInheritedFeature.forEach((el) => {
        //insert
        if (!this.initialFeatures.value.find((e) => el.featureId === e.featureId)) {
          this.initialFeatures.value.push({
            featureId: el.featureId,
            application: el.application,
            inheritanceType: AppPermissionInheritance.BlockDescendants,
          });
          updateInheritanceTypeNoneForBlockDes.push({
            featureId: el.featureId,
            inheritanceType: AppPermissionInheritance.None,
          });
        }
      });
    } else {
      const notSelectedBlockDesFeature = this.directlyAssignedFeatures
        .filter(
          (directFeature) =>
            directFeature.inheritanceType === AppPermissionInheritance.BlockDescendants &&
            !features.some((f) => f.featureId === directFeature.featureId),
        )
        .filter((f) => !this.inheritedFeatures.some((i) => f.featureId === i.featureId));

      //notSelectedBlockDesFeature remove it from initialFeatures
      if (notSelectedBlockDesFeature.length) {
        this.initialFeatures.setValue(
          this.initialFeatures.value.filter((f) =>
            notSelectedBlockDesFeature.some((i) => i.featureId !== f.featureId),
          ),
        );
      }
    }

    // Check unselected feature which is part of the inheritedFeatures
    // & not BlockThisEntityAndDescendants.
    // Set BlockThisEntityAndDescendants for those feature
    const updateInherit = this.isParentFeatureManagement
      ? entityFeatures
      : this.inheritedFeatures.filter(
          (feature) =>
            !this.directlyAssignedFeatures.some(
              (item) =>
                item.featureId === feature.featureId &&
                item.inheritanceType === AppPermissionInheritance.BlockThisEntityAndDescendants,
            ),
        );

    const setBlockEAndDesFeatures: IAppPermissionAssignment[] = differenceBy(
      updateInherit, //this.inheritedFeatures,
      features,
      'featureId',
    ).map((feature) => ({
      featureId: feature.featureId,
      inheritanceType: AppPermissionInheritance.BlockThisEntityAndDescendants,
    }));

    // Additional check if it is already part of the DirectlyAssignedFeatures
    // then to add it PUT request need to remove the object
    // from initialFeatures list
    if (setBlockEAndDesFeatures.length) {
      const removelist = this.isParentFeatureManagement
        ? setBlockEAndDesFeatures
        : setBlockEAndDesFeatures.filter(
            (f) => !this.directlyAssignedFeatures.some((fd) => fd.featureId === f.featureId),
          );

      this.initialFeatures.setValue(
        differenceBy(this.initialFeatures.value, removelist, 'featureId'),
      );
    }

    // features which is not part of the entityFeatures & selected
    // set it to NONE
    const otherAvailableFeatures: IAppPermissionAssignment[] = features
      .filter((f) => !entityFeatures.some((i) => f.featureId === i.featureId))
      .map((feature) => ({
        featureId: feature.featureId,
        inheritanceType: AppPermissionInheritance.None,
      }));

    const resultFeatures: IAppPermissionAssignment[] = [
      ...setBlockEAndDesFeatures,
      ...otherAvailableFeatures,
      ...updateInheritanceTypeAsNoneForBlockAndDes,
      ...updateInheritanceTypeNoneForBlockDes,
    ];

    const finalFeaturesList: IAppPermissionAssignment[] = features.map((feature) => ({
      featureId: feature.featureId,
      inheritanceType: this.getFeatureInheritedType(feature.featureId),
    }));

    this.selectedFeatures.setValue(uniqBy([...resultFeatures, ...finalFeaturesList], 'featureId'));

    const featuresAvailableForSubOrg = [...features, ...selectedBlockEAndDesFeature];

    this.updateFeaturesWithInheritance(
      this.verifoneFeatures.filter((feature) =>
        featuresAvailableForSubOrg.some((i) => i.featureId === feature.featureId),
      ),
    );

    this.updateWhiteLabelingOnSelectedFeatures(selectedItems);

    this.contacts.updateValueAndValidity();
  }

  inheritanceSelectedForNewOrg(selectedItem: AppPermission[]): void {
    const features = this.selectedFeatures?.value as IAppPermissionAssignment[];
    let notSelectedFeature = [];
    const initialFeature = [];
    const deleteInitialFeature = [];

    const entityFeatures = uniqBy(
      [...this.directlyAssignedFeatures, ...this.inheritedFeatures],
      'featureId',
    );

    if (!isNull(features)) {
      notSelectedFeature = differenceBy(features, selectedItem, 'featureId');

      notSelectedFeature.forEach((element) => {
        if (!features.find((fe) => fe.featureId === element.featureId)) {
          features.push({
            featureId: element.featureId,
            inheritanceType: AppPermissionInheritance.BlockDescendants,
          });
        }
      });

      features.forEach((feature) => {
        if (selectedItem.some((i) => feature.featureId === i.featureId)) {
          feature.inheritanceType = AppPermissionInheritance.InheritDescendants;
        } else if (
          feature.inheritanceType === AppPermissionInheritance.BlockThisEntityAndDescendants ||
          feature.inheritanceType === AppPermissionInheritance.None
        ) {
          //do nothing
        } else if (notSelectedFeature.some((i) => feature.featureId === i.featureId)) {
          feature.inheritanceType = AppPermissionInheritance.BlockDescendants;
        }

        //unChecked or Not Selected featured should be removed
        // from initialFEature to make POST API call
        if (notSelectedFeature.length) {
          const obj = notSelectedFeature.find((f) => f.featureId === feature.featureId);
          if (obj) {
            deleteInitialFeature.push(obj);
          }
        }

        if (
          entityFeatures.some((f) => f.featureId === feature.featureId) &&
          feature.inheritanceType === AppPermissionInheritance.InheritDescendants
        ) {
          const obj = this.directlyAssignedFeatures.find((f) => f.featureId === feature.featureId);
          if (
            obj &&
            obj.inheritanceType !== AppPermissionInheritance.BlockDescendants &&
            obj.inheritanceType !== AppPermissionInheritance.None &&
            obj.inheritanceType !== AppPermissionInheritance.BlockThisEntityAndDescendants
          ) {
            if (!this.initialFeatures.value.find((i) => i.featureId === feature.featureId)) {
              initialFeature.push({
                featureId: feature.featureId,
                application: this.verifoneFeatures.find((i) => i.featureId === feature.featureId)
                  .application,
                inheritanceType: feature.inheritanceType,
              });
            }
          }
        }
      });

      //Reset initial Features
      this.initialFeatures.setValue(
        differenceBy(this.initialFeatures.value, deleteInitialFeature, 'featureId'),
      );

      initialFeature.forEach((element) => {
        this.initialFeatures.value.push(element);
      });
    }
  }

  inheritanceSelected(selectedItems: MultiselectTreeItem[]): void {
    //Selected item set Inherit_Descendant
    //unSelected  item set Block_Descendant
    //But there are many more conditions

    this.selectedFeaturesWithInheritance = this.getFeaturesFromOptions(selectedItems);

    //===========================
    if (this.isParentFeatureManagement) {
      this.inheritanceSelectedForNewOrg(this.selectedFeaturesWithInheritance);
      return;
    }
    //===========================

    // list of not selected Feature
    // which are part of inheritedFeature &
    // not part selectedFeaturesWithInheritance
    // not part of the directlyAssignedFeatures as Block_Descendants
    const notSelectedFeature = this.initialFeatures.value
      .filter(
        (initialFeature) =>
          !this.selectedFeaturesWithInheritance.some(
            (i) => i.featureId === initialFeature.featureId,
          ) && this.inheritedFeatures.some((f) => f.featureId === initialFeature.featureId),
      )
      .filter(
        (feature) =>
          !this.directlyAssignedFeatures.some(
            (directlyAssignedFeature) =>
              feature.featureId === directlyAssignedFeature.featureId &&
              directlyAssignedFeature.inheritanceType === AppPermissionInheritance.BlockDescendants,
          ),
      );

    const features = this.selectedFeatures?.value as IAppPermissionAssignment[];
    if (!isNull(features)) {
      // push "BLOCK_DESCENDANTS" if not selected again in features
      this.directlyAssignedFeatures
        .filter(
          (i) =>
            i.inheritanceType === AppPermissionInheritance.BlockDescendants &&
            !this.selectedFeaturesWithInheritance.some((f) => f.featureId === i.featureId),
        )
        .filter((f) => this.inheritedFeatures.some((i) => f.featureId === i.featureId))
        .forEach((element) => {
          if (!features.find((fe) => fe.featureId === element.featureId)) {
            features.push({
              featureId: element.featureId,
              inheritanceType: element.inheritanceType,
            });
          }
        });

      //update selected and not selected features with new inheratanceType value
      features.forEach((feature, index, object) => {
        if (this.selectedFeaturesWithInheritance.some((i) => feature.featureId === i.featureId)) {
          feature.inheritanceType = AppPermissionInheritance.InheritDescendants;
        } else if (
          feature.inheritanceType === AppPermissionInheritance.BlockThisEntityAndDescendants
        ) {
          //do nothing in sub-org
        } else {
          feature.inheritanceType = this.getInheritanceTypeForUnselectedInSubOrg(feature.featureId);
        }

        // if selectedItem is BLOCK_DESCENDANTS include in
        // directlyAssignedFeatures and inheritedFeatures
        // remove it from current features list to make del api call
        if (
          this.selectedFeaturesWithInheritance.some(
            (i) =>
              i.featureId === feature.featureId &&
              this.directlyAssignedFeatures.some(
                (f) =>
                  f.featureId === i.featureId &&
                  f.inheritanceType === AppPermissionInheritance.BlockDescendants,
              ),
          ) &&
          this.inheritedFeatures.some((inF) => inF.featureId === feature.featureId)
        ) {
          //delete if from current features
          object.splice(index, 1);
        }

        //unChecked or Not Selected featured should be removed
        // from initialFEature to make POST API call
        if (notSelectedFeature.length) {
          const obj = notSelectedFeature.find((f) => f.featureId === feature.featureId);
          if (obj) {
            //remove it from this.initialFeatures
            var itemIndex = this.initialFeatures.value.findIndex(
              (i) => i.featureId === obj.featureId,
            );
            this.initialFeatures.value.splice(itemIndex, 1);
          }
        }

        // Additional Check, if inheritedFeatures uncheck and apply &
        // check it again & apply it should again push to
        // initialFeatures if it was removed early to make consistancy
        if (
          this.inheritedFeatures.some((f) => f.featureId === feature.featureId) &&
          feature.inheritanceType === AppPermissionInheritance.InheritDescendants
        ) {
          if (!this.initialFeatures.value.find((i) => i.featureId === feature.featureId)) {
            this.initialFeatures.value.push({
              featureId: feature.featureId,
              application: this.verifoneFeatures.find((i) => i.featureId === feature.featureId)
                .application,
            });
          }
        }
      });
    }
  }

  updateSelectedFeatures(): void {
    const features = this.selectedFeatures?.value as IAppPermissionAssignment[];
    if (!isNull(features))
      features.forEach(
        (feature) => (feature.inheritanceType = this.getFeatureInheritedType(feature.featureId)),
      );
  }

  updateFeaturesWithInheritance(selectedFeatures: AppPermission[]): void {
    this.selectedFeaturesWithInheritance = this.selectedFeaturesWithInheritance.filter((feature) =>
      selectedFeatures.some((i) => i.featureId === feature.featureId),
    );
    //check if selectedFeatures are also available in direct as inheritdescendent
    const featureAvailableinDirect = this.directlyAssignedFeatures.filter((feature) =>
      selectedFeatures.some(
        (i) =>
          i.featureId === feature.featureId &&
          feature.inheritanceType === AppPermissionInheritance.InheritDescendants,
      ),
    );

    setTimeout(() => {
      this.featuresWithInheritanceOptions = this.getFeaturesOptions(selectedFeatures, [
        ...this.selectedFeaturesWithInheritance,
        ...featureAvailableinDirect,
      ]);
    }, 0);
  }

  getFeatureInheritedType(featureId: string): AppPermissionInheritance {
    if (this.selectedFeaturesWithInheritance.some((feature) => feature.featureId === featureId)) {
      return AppPermissionInheritance.InheritDescendants;
    } else {
      const feature = this.directlyAssignedFeatures.find((f) => f.featureId === featureId);
      return feature?.inheritanceType ? feature.inheritanceType : AppPermissionInheritance.None;
    }
  }

  getFeaturesFromOptions(treeItems: MultiselectTreeItem[]): AppPermission[] {
    return this.verifoneFeatures.filter((feature) =>
      treeItems.some((item) => item.id === feature.featureId),
    );
  }

  clearAddressControl(index: number): void {
    this.addresses.removeAt(index);
    this.addresses.insert(index, this.fb.group({}));
  }

  hasAddressInOrganisation(addressUid): boolean {
    return this.organisation?.addresses?.some((address) => address.addressUid === addressUid);
  }

  hasContactInOrganisation(contactUid): boolean {
    return this.organisation?.contacts?.some((contact) => contact.contactUid === contactUid);
  }

  // https://github.com/angular/angular/issues/31420#issuecomment-509759583
  asIsOrder(): number {
    return 0;
  }

  onBusinessTypeChange(): void {
    if (this.businessType.value === BusinessType.Physical) {
      this.legalName.setValidators([
        this.customValidators.required,
        this.customValidators.validateCharacters,
        this.customValidators.maxLength(255),
      ]);
      this.addContactForm();
    } else {
      this.legalName.setValidators([]);
    }
  }

  toggleContactsTable(): void {
    this.isCreatingOrganisation = !this.isCreatingOrganisation;
  }

  addContactForm(onPageInit?: boolean): void {
    this.contactForm = this.fb.group({
      contactUid: [''],
      contactType: [ContactType.Business],
      name: this.fb.group({
        firstName: [''],
        lastName: [''],
      }),
      phoneNumbers: this.fb.array([
        this.fb.group({
          value: [''],
          phoneType: [PhoneType.Home],
        }),
      ]),
      email: [''],
      addresses: this.fb.array([this.fb.group({})]),
      isPrimary: [false],
    });
    if (!onPageInit) {
      this.contactForm.get('contactType')?.setValidators([this.customValidators.required]);
      this.contactForm.controls['name']
        .get('firstName')
        ?.setValidators([this.customValidators.required]);
      this.contactForm.controls['name']
        .get('lastName')
        ?.setValidators([
          this.customValidators.required,
          this.customValidators.validateCharacters,
          this.customValidators.allSpaces,
          this.customValidators.oneSpaceBetweenWords,
        ]);
      this.contactForm
        .get('email')
        ?.setValidators([this.customValidators.email, this.customValidators.required]);
      this.contactForm.controls['phoneNumbers']
        .get('value')
        ?.setValidators([this.customValidators.required, this.customValidators.phone]);
      this.contactForm.updateValueAndValidity();
    }
  }

  removeContact(index: number): void {
    this.editingContactIdx = index;

    this.contactNameTobeDeleted =
      this.getFirstNameControl(index).value + ' ' + this.getLastNameControl(index).value;

    this.confirmDeleteContactModal.open();
  }

  onModalDeleteConfirmed(): void {
    const removedContactData = this.contacts.at(this.editingContactIdx).value;
    this.contacts.removeAt(this.editingContactIdx);
    this.assignPrimaryContact({ removedContactData });
    this.editingContactIdx = -1;
    this.closeDeleteModal();
  }

  closeDeleteModal(): void {
    this.editingContactIdx = -1;
    this.confirmDeleteContactModal.close();
    this.confirmDeleteAllContactsModal.close();
  }

  removeAllContacts(): void {
    this.confirmDeleteAllContactsModal.open();
  }

  onModalAllDeleteConfirmed(): void {
    this.contacts.clear();
    this.editingContactIdx = -1;
    this.closeDeleteModal();
  }

  closeContactForm(): void {
    this.showContactForm = false;
    this.isCreatingContact = false;
    this.contactValue = undefined;
    this.contactForm = undefined;
  }

  onEditContactByIndex(index: number): void {
    this.editingContactIdx = index;
    this.isCreatingContact = false;
    this.contactForm = this.contacts.at(index) as FormGroup;
    this.contactValue = this.contactForm.getRawValue();
    this.showContactForm = true;
  }

  onDeleteContactByIndex(index: number): void {
    this.removeContact(index);
  }

  onAddContact(): void {
    this.isCreatingContact = true;
    this.addContactForm();
    this.showContactForm = true;
  }

  onContactUpdated(contact: IContact): void {
    const contactGroup = this.contacts.at(this.editingContactIdx) as FormGroup;
    const addresses = contactGroup.get('addresses') as FormArray;
    if (!addresses.length) {
      contactGroup.setControl('addresses', this.getContactAddressesFormArray(contact));
    }
    const oldContactData = contactGroup.value;
    contactGroup.patchValue(contact);
    this.assignPrimaryContact({
      addedContactControl: contactGroup,
      removedContactData: oldContactData,
    });
  }

  onContactCreated(contact: FormGroup): void {
    this.contacts.push(contact);
    this.assignPrimaryContact({ addedContactControl: contact });
  }

  removeCurrency(currencyCode: string): void {
    this.verifoneBillingCurrency.setValue(
      this.verifoneBillingCurrency.value.filter((currency: string) => currency !== currencyCode),
    );
  }

  showPoiCrossEntityAccess(): boolean {
    return this.organisationService.isPoiCrossEntityAccessEditable(this.entityType.value);
  }

  setCrossEntityAccess(parentId: string): void {
    this.poiCrossEntityAccessAllowed.disable();
    this.organisationService.getByKey(parentId, true).subscribe((entity) => {
      if (entity.entityType === EntityType.MERCHANT_COMPANY) {
        this.poiCrossEntityAccessAllowed.setValue(entity.poiCrossEntityAccessAllowed);
      } else {
        this.poiCrossEntityAccessAllowed.enable();
      }
    });
  }

  saveToken(): void {
    if (this.apiToken.valid) {
      this.apiToken.disable();
      this.savingToken = true;
      this.updateReceiptContractApiToken()
        .pipe(untilDestroyed(this))
        .pipe(
          finalize(() => {
            this.apiToken.enable();
            this.savingToken = false;
          }),
        )
        .subscribe(() => {
          this.closeUpdateTokenForm();
        });
    }
  }

  openUpdateTokenForm(): void {
    this.showPaymentDocumentApiTokenForm = true;
  }

  closeUpdateTokenForm(): void {
    this.showPaymentDocumentApiTokenForm = false;
    this.apiToken.setValue('');
    this.apiToken.markAsUntouched();
    this.apiToken.updateValueAndValidity();
  }

  updateReceiptContractApiToken(): Observable<IReceiptContract> {
    const updateReceiptContractParam: IReceiptContractCreatePayload = {
      provider: this.receiptProvider.value,
      apiToken: this.apiToken.value ?? '',
    };
    return this.organisationService.updateReceiptContract(
      this.organisation.receiptContract.receiptContractUid,
      updateReceiptContractParam,
    );
  }

  checkForSftpDeliveryFeature(features: MultiselectTreeItem[]): void {
    this.hasSftpDeliveryFeature = !!features?.find(
      (feature) => feature.id === Features.SftpDelivery,
    );
  }

  getReceiptContracts(): void {
    this.organisationService
      .getReceiptContracts([this.organisation.entityUid])
      .pipe(map((recContracts) => recContracts[0]))
      .pipe(untilDestroyed(this))
      .subscribe((recContract: IReceiptContract) => {
        if (recContract) {
          const currentAttachmentType = recContract.notifications
            ? recContract.notifications[0]?.attachmentType
            : ReceiptAttachmentType.None;

          const attachmentTypeInput = this.receiptAttachmentTypesList.find(
            (attachType) => attachType.id === currentAttachmentType,
          );
          this.organisation.receiptContract = {
            ...recContract,
            provider: recContract.provider,
            providerConfiguration: {
              ...recContract.providerConfiguration,
            },
            notifications: {
              ...recContract.notifications,
              attachmentType: attachmentTypeInput.text as ReceiptAttachmentType,
            },
          };
          this.populateReceiptFields(this.organisation.receiptContract);
        }
      });
  }

  showMcr(): boolean {
    return (
      this.isMcrAvailable &&
      this.hasPermissionToEditMcr &&
      this.organisation?.entityType === EntityType.MERCHANT_SITE &&
      Boolean(this.ecomContract)
    );
  }

  onClickDeleteTemplate(index: number): void {
    this.customTemplates.removeAt(index);
    this.updateTemplateChannelsList();
  }

  updateDisabledChannelsList(): void {
    this.disabledChannels = this.receiptSalesChannelsList.filter((channel) => {
      return this.assignedChannels.includes(channel.id);
    });
  }

  removeTemplateChannel(salesChannelControl: FormControl, channel): void {
    salesChannelControl.setValue(
      salesChannelControl.value.filter((channelItem: string) => channelItem !== channel.id),
    );
    this.updateDisabledItemInAllChannelControls();
  }

  updateDisabledItemInAllChannelControls(): void {
    this.channelControlComponents.forEach((channelControl) => channelControl.updateDisabledItems());
  }

  pushCustomTemplate(recTemplate?: IReceiptTemplate): void {
    this.customTemplates.push(
      this.fb.group({
        templateId: this.fb.control(recTemplate?.templateId ?? null, [
          this.customValidators.required,
        ]),
        salesChannels: [
          recTemplate?.salesChannels ?? [],
          [
            this.customValidators.required,
            this.customValidators.salesChannels(
              this.receiptSalesChannels.list.map((item) => item.id),
            ),
          ],
        ],
      }),
    );
  }

  private patchValues(): void {
    if (this.organisation) {
      this.setFormValue(this.organisation);
      if (this.organisation.businessIdentifiers) {
        this.businessIdentifierTypesControl.setValue(
          this.organisation.businessIdentifiers.map(
            (businessIdentifier) => businessIdentifier.identifierType,
          ),
        );
      }
    }
  }

  private updateParentControl(): void {
    if (
      !(
        this.organisation.entityType === EntityType.MERCHANT_COMPANY &&
        (this.rootEntityIdForParentUpdate || this.trashCanOrgsPresent.length > 1)
      ) &&
      !(
        this.organisation.entityType === EntityType.MERCHANT_SITE &&
        this.rootEntityIdForParentUpdate &&
        this.merchantCompanyEntityCount > 1
      )
    ) {
      this.parentEntityUid.setValidators([]);
      this.parentEntityUid.disable();
    }
  }

  // Todo move this to form-business-information class
  private getBusinessInformation(entityUid: string): void {
    this.organisationService
      .getBusinessInformation(entityUid)
      .pipe(
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((info: IBusinessInformation) => {
        this.selectedOrganisationBusinessInformation = info;
      });
  }

  private getAppsPermissions(entityUid: string, isParent?: boolean): void {
    forkJoin([
      isEmpty(this.verifoneFeatures)
        ? this.authorizationService.getAllAppsPermissions()
        : of(this.verifoneFeatures),
      this.authorizationService.getDetailedAppsPermissionsByEntity(entityUid),
    ]).subscribe(
      ([verifoneFeatures, entityFeatures]) => {
        this.verifoneFeatures = verifoneFeatures;
        this.entityFeatures = entityFeatures;
        this.inheritedFeatures = entityFeatures.inheritedFeatures;

        //Now include all inheritFeatures except BLOCK_DESCENDANTS
        // and BLOCK_THIS_ENTITY_AND_DESCENDANTS
        this.selectedFeaturesWithInheritance = isParent
          ? this.getInheritedFeaturesforNewOrg(
              entityFeatures.inheritedFeatures,
              entityFeatures.directlyAssignedFeatures,
            )
          : this.getSelectedFeaturesWithInheritance(
              entityFeatures.inheritedFeatures,
              entityFeatures.directlyAssignedFeatures,
            );

        this.directlyAssignedFeatures = entityFeatures.directlyAssignedFeatures;

        // Set initialFeatures which is not included
        // BLOCK_THIS_ENTITY_AND_DESCENDANTS and
        // only incllude BLOCK_DESCENDANTS which is part of the inheritedFeatures
        this.form.get('initialFeatures').setValue(
          isParent
            ? this.getInheritedFeaturesforNewOrg(
                entityFeatures.inheritedFeatures,
                entityFeatures.directlyAssignedFeatures,
              )
            : uniqBy(
                [...entityFeatures.directlyAssignedFeatures, ...entityFeatures.inheritedFeatures],
                'featureId',
              )
                .filter(
                  (f) =>
                    f?.inheritanceType !== AppPermissionInheritance.BlockThisEntityAndDescendants,
                )
                .filter(
                  (i) =>
                    i?.inheritanceType !== AppPermissionInheritance.BlockDescendants ||
                    entityFeatures.inheritedFeatures.some((k) => i.featureId === k.featureId),
                ),
        );

        this.featuresOptions = this.getFeaturesOptions(
          verifoneFeatures,
          isParent ? [] : entityFeatures.directlyAssignedFeatures,
          isParent
            ? this.getInheritedFeaturesforNewOrg(
                entityFeatures.inheritedFeatures,
                entityFeatures.directlyAssignedFeatures,
              )
            : entityFeatures.inheritedFeatures.filter(
                (feature) =>
                  !entityFeatures.directlyAssignedFeatures.some(
                    (item) =>
                      item.featureId === feature.featureId &&
                      item.inheritanceType ===
                        AppPermissionInheritance.BlockThisEntityAndDescendants,
                  ),
              ),
        );

        this.featuresWithInheritanceOptions = this.getFeaturesOptions(
          entityFeatures.inheritedFeatures,
          this.selectedFeaturesWithInheritance,
        );

        this.showFeatureManagement = true;
        this.errorInFetchingFeatures = false;
        // expression has been checked
        // issue needs to be checked here
        this.setWhiteLabelingFeatureState();
      },
      () => {
        this.showFeatureManagement = false;
        this.errorInFetchingFeatures = true;
      },
    );
  }

  private getFeaturesOptions(
    verifoneFeatures: AppPermission[],
    entityFeatures: AppPermission[],
    inheritedFeatures: AppPermission[] = [],
  ): MultiselectTreeItem[] {
    const availableFeatures = verifoneFeatures;

    const availableApplications = this.authorizationService.getApps(availableFeatures);

    return availableApplications.map((app) => {
      return {
        id: app.application,
        name: app.name,
        checked: false,
        children: this.authorizationService
          .getAppFeatures(app.application, availableFeatures)
          .map((availableFeature) => {
            return {
              id: availableFeature.featureId,
              name: availableFeature.featureId,
              checked:
                entityFeatures.some(
                  (entityFeature) =>
                    entityFeature.featureId === availableFeature.featureId &&
                    entityFeature.inheritanceType !==
                      AppPermissionInheritance.BlockThisEntityAndDescendants &&
                    entityFeature.inheritanceType !== AppPermissionInheritance.BlockDescendants,
                ) ||
                inheritedFeatures.some(
                  (entityFeature) => entityFeature.featureId === availableFeature.featureId,
                ),
              isInheritedFeatures: inheritedFeatures.length
                ? this.inheritedFeatures.some(
                    (entityFeature) => entityFeature.featureId === availableFeature.featureId,
                  )
                : false,
              children: [],
              visible: true,
            };
          }),
        visible: true,
      };
    });
  }

  private setWhiteLabelingFeatureState(selectedItems: MultiselectTreeItem[] = []): void {
    const isDirectlyAssigned = this.entityFeatures.directlyAssignedFeatures.some(
      (feature) => feature.featureId === Features.PortalWhiteLabeling,
    );
    const isInherited = this.entityFeatures.inheritedFeatures.some(
      (feature) => feature.featureId === Features.PortalWhiteLabeling,
    );
    const isSelected = selectedItems.some((feature) => feature.id === Features.PortalWhiteLabeling);
    // expression has been checked
    // issue needs to be checked in 1115 line
    setTimeout(() => {
      this.isWhiteLabelFeatureActiveForOrg = (isDirectlyAssigned && isSelected) || isInherited;
      this.whiteLabelFeatureActiveForOrg.emit(this.isWhiteLabelFeatureActiveForOrg);
    }, 0);
  }

  private updateWhiteLabelingOnSelectedFeatures(selectedItems: MultiselectTreeItem[]): void {
    const initialState = this.isWhiteLabelFeatureActiveForOrg;
    this.setWhiteLabelingFeatureState(selectedItems);
    if (
      initialState !== this.isWhiteLabelFeatureActiveForOrg &&
      !this.isWhiteLabelFeatureActiveForOrg
    ) {
      this.resetResellerForm();
    }
  }

  private resetResellerForm(): void {
    if (this.organisation) {
      this.domainNameControl.setValue(this.organisation?.domainName);
      this.loginDomainNameControl.setValue(this.organisation?.loginDomainName);
      this.emailDomainNameControl.setValue(this.organisation?.emailDomainName);
    }
  }

  private subscribeForGettingWhiteLabelEditorForm(): void {
    combineLatest([
      this.formSharedService.organisationFormValues$,
      this.formSharedService.whiteLabelPreviewFormValues$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([organisation, templateParameters]: [IOrganisation, TemplateParameters]) => {
        if (templateParameters) {
          const org = organisation || this.form.getRawValue();
          this.form.patchValue({
            ...org,
            templateParameters: {
              ...org.templateParameters,
              ...templateParameters,
            },
          });
        }
      });
  }

  private getInheritedFeaturesforNewOrg(
    inheritedFeatures: AppPermission[],
    directlyAssignedFeatures: AppPermission[],
  ): AppPermission[] {
    return uniqBy(
      [
        ...directlyAssignedFeatures.filter(
          (feature) => feature.inheritanceType === AppPermissionInheritance.InheritDescendants,
        ),
        ...inheritedFeatures.filter(
          (feature) =>
            !directlyAssignedFeatures.some(
              (directlyAssignedFeature) =>
                directlyAssignedFeature.featureId === feature.featureId &&
                (directlyAssignedFeature.inheritanceType ===
                  AppPermissionInheritance.BlockDescendants ||
                  directlyAssignedFeature.inheritanceType ===
                    AppPermissionInheritance.BlockThisEntityAndDescendants ||
                  directlyAssignedFeature.inheritanceType === AppPermissionInheritance.None),
            ),
        ),
      ],
      'featureId',
    );
  }

  private getSelectedFeaturesWithInheritance(
    inheritedFeatures: AppPermission[],
    directlyAssignedFeatures: AppPermission[],
  ): AppPermission[] {
    return [
      ...directlyAssignedFeatures.filter(
        (feature) => feature.inheritanceType === AppPermissionInheritance.InheritDescendants,
      ),
      ...inheritedFeatures.filter(
        (feature) =>
          !directlyAssignedFeatures.some(
            (directlyAssignedFeature) =>
              directlyAssignedFeature.featureId === feature.featureId &&
              (directlyAssignedFeature.inheritanceType ===
                AppPermissionInheritance.BlockDescendants ||
                directlyAssignedFeature.inheritanceType ===
                  AppPermissionInheritance.BlockThisEntityAndDescendants),
          ),
      ),
    ];
  }

  private getInheritanceTypeForUnselectedInSubOrg(featureId: string): AppPermissionInheritance {
    if (
      this.directlyAssignedFeatures.some(
        (f) =>
          f.featureId === featureId &&
          (f.inheritanceType === AppPermissionInheritance.None ||
            f.inheritanceType === AppPermissionInheritance.InheritDescendants),
      ) &&
      !this.inheritedFeatures.some((f) => f.featureId === featureId)
    ) {
      return AppPermissionInheritance.None;
    } else {
      return AppPermissionInheritance.BlockDescendants;
    }
  }

  private isDisputeManagementCardSchemesSelected(): boolean {
    return this.selectedFeatures.value?.some(
      (x) =>
        x.featureId === Features.DisputeManagementCardSchemes &&
        x.inheritanceType !== AppPermissionInheritance.BlockThisEntityAndDescendants,
    );
  }

  private assignPrimaryContact({
    addedContactControl,
    removedContactData,
  }: {
    addedContactControl?: FormGroup;
    removedContactData?: IContact;
  }): void {
    const isPrimary = (contact: IContact): boolean => contact.isPrimary;

    const contacts = this.contacts.getRawValue();
    const getGroup = (specimen: IContact): IContact[] =>
      specimen ? contacts.filter((contact) => contact.contactType === specimen.contactType) : [];

    const addedContactData = addedContactControl?.value;
    const newGroup = getGroup(addedContactData);
    const numberOfPrimaryContactsInNewGroup = newGroup.filter(isPrimary).length;

    if (newGroup.length && numberOfPrimaryContactsInNewGroup < 1) {
      addedContactControl.patchValue({ isPrimary: true });
    } else if (
      newGroup.length &&
      numberOfPrimaryContactsInNewGroup > 1 &&
      addedContactData.isPrimary
    ) {
      addedContactControl.patchValue({ isPrimary: false });
    }

    const oldGroup = getGroup(removedContactData);
    if (oldGroup.length && !oldGroup.some(isPrimary) && removedContactData.isPrimary) {
      oldGroup[0].isPrimary = true;
      this.contacts.patchValue(contacts);
    }
  }

  private getReceiptTemplatesNames(): void {
    this.organisationService
      .getReceiptTemplatesNames()
      .pipe(untilDestroyed(this))
      .subscribe((templates: String[]) => {
        this.receiptTemplateNamesList = templates;
      });
  }

  private updateTemplateChannelsList(): void {
    this.assignedChannels = [];
    if (this.customTemplates.length === 0) {
      this.receiptSalesChannelsList = this.receiptSalesChannels.list;
    } else {
      this.customTemplates.value.forEach((ct) => {
        this.assignedChannels = this.assignedChannels.concat(ct.salesChannels);
      });
      this.assignedChannels = this.assignedChannels.filter((el, i, a) => i === a.indexOf(el));
      this.updateDisabledChannelsList();
    }
  }
}
