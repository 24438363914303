<div class="error-page">
  <div class="error-page-content">
    <div class="notfound-404 has-text-grey-lighter">
        <h1>404</h1>
    </div>
    <div class="box-denied box">
        <h2 class="title is-3 has-text-left" i18n>Error 404. Not found</h2>
        <p class="has-text-left" i18n>The URL you requested is not found on this server.</p>
    </div>
  </div>
</div>
