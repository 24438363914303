import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'portal-file',
  templateUrl: './file.component.html',
})
export class FileComponent {
  @Input() helperText = '';
  @Input() file: File;
  @Input() disabled: boolean;
  @Output() deleted = new EventEmitter<File>();

  constructor(private fileService: FileService) {}

  onDeleteFile(): void {
    this.deleted.emit(this.file);
  }

  openImage(file: File): void {
    globalThis.open(URL.createObjectURL(file));
  }

  isImage(file: File): boolean {
    return this.fileService.isImage(file);
  }
}
