import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';
import { Observable } from 'rxjs';
import { CONSTANTS, CustomDataService } from '../../../core/core.module';
import { IReportsWithTotal } from '../interfaces/reports-with-total.interface';
import { IResultsReportScheduler } from '../../../modules/report-engine/interfaces/results-report-scheduler.interface';
import {
  IReportSchedulerDetails,
  IReportsSchedule,
  IUpdateReportSchedulerReponse,
} from '../../../modules/report-engine/interfaces/report-scheduler-details.interface';

@Injectable()
export class ReportScheduleDataService extends CustomDataService<IReportsWithTotal> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super(CONSTANTS.REPORT_ENGINE.REPORTS_SCHEDULER, http, httpUrlGenerator);
  }

  getWithQueryStringSchedule(query: string): Observable<IResultsReportScheduler> {
    return this.execute(
      'GET',
      `${CONSTANTS.REPORT_ENGINE.REPORTS_SCHEDULER}?ownerApp=PDSP${query}`,
      null,
    );
  }

  createReportScheduler(data: IReportsSchedule): Observable<IUpdateReportSchedulerReponse> {
    return this.execute('POST', `${CONSTANTS.REPORT_ENGINE.REPORTS_SCHEDULER}`, data);
  }

  updateReportScheduler(
    data: IReportSchedulerDetails | IReportsSchedule,
  ): Observable<IUpdateReportSchedulerReponse> {
    return this.execute('PUT', `${CONSTANTS.REPORT_ENGINE.REPORTS_SCHEDULER}`, data);
  }

  deleteReportScheduler(id: number): Observable<IUpdateReportSchedulerReponse> {
    return this.execute('DELETE', `${CONSTANTS.REPORT_ENGINE.REPORTS_SCHEDULER}/${id}`);
  }
}
