import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[marketplaceDragDrop]',
})
export class DragDropDirective implements AfterContentInit {
  @Output() fileDropped = new EventEmitter<any>();
  @Input() aspect: string; // example '4/3' | '1/1' | '16/9'

  constructor(private el: ElementRef) {}

  @HostBinding('style.background-color') background = '#f5fcff';
  @HostBinding('style.opacity') opacity = '1';

  @HostListener('dragover', ['$event']) onDragOver(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#9ecbec';
    this.opacity = '0.8';
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f5fcff';
    this.opacity = '1';
  }

  @HostListener('drop', ['$event']) ondrop(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f5fcff';
    this.opacity = '1';
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

  ngAfterContentInit(): void {
    if (this.aspect) {
      this.el.nativeElement.style.setProperty('--aspect-ratio', this.aspect);
    }
  }
}
