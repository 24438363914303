import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedAuthAuthenticationModule } from '@portal/shared/auth/authentication';
import { SpinnerModule } from '@portal/shared/ui/spinner';
import { TopNavbarModule } from './top-navbar/top-navbar.module';
import { FooterModule } from './footer/footer.module';

@NgModule({
  imports: [
    CommonModule,
    SharedAuthAuthenticationModule,
    SpinnerModule,
    TopNavbarModule,
    BrowserAnimationsModule,
    RouterModule.forChild([]),
    FooterModule,
  ],
  declarations: [],
})
export class LayoutsModule {}
