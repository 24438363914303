import { Injectable } from '@angular/core';
import { IndustryType } from '@portal/entity-services/interfaces/src';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class IndustryTypes extends SelectInputsList {
  keyValue = {
    [IndustryType.Airline]: $localize`Airline`,
    [IndustryType.CashAdvance]: $localize`Cash Advance`,
    [IndustryType.Direct]: $localize`Direct`,
    [IndustryType.Educational]: $localize`Educational`,
    [IndustryType.Government]: $localize`Government`,
    [IndustryType.Internet]: $localize`Internet`,
    [IndustryType.Lodging]: $localize`Lodging`,
    [IndustryType.Medical]: $localize`Medical`,
    [IndustryType.Moto]: $localize`Moto`,
    [IndustryType.Petroleum]: $localize`Petroleum`,
    [IndustryType.QuickServiceRestaurant]: $localize`Quick Service Restaurant`,
    [IndustryType.Recreation]: $localize`Recreation`,
    [IndustryType.Restaurant]: $localize`Restaurant`,
    [IndustryType.Retail]: $localize`Retail`,
    [IndustryType.Supermarket]: $localize`Supermarket`,
    [IndustryType.Transport]: $localize`Transport`,
    [IndustryType.Unknown]: $localize`Unknown`,
    [IndustryType.VechicleRental]: $localize`Vehicle Rental`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
