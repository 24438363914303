<vui-portal
  (useEvents)="events($event)"
  (whiteLabelLoaded)="onWhiteLabelLoaded()"
  [withSignInSplash]="!cba"
  [logoSrc]="logoSrc"
>
  <div class="splash__message" *ngIf="!cba">
    <h4 class="title is-3">{{ brandText }}</h4>
    <p *ngIf="!isFederatedUser" i18n>
      You are being redirected to Login page now. If it does not happen automatically within few
      seconds, then please press the button below.
    </p>
    <p *ngIf="isFederatedUser" i18n>
      Direct login is not supported for your account. For the federated login please contact your
      organization.
    </p>
  </div>

  <div navbar-menu-item-end class="navbar-menu-item" *ngIf="cba && !isFederatedUser">
    <div class="navbar-item">
      <div class="navbar-end">
        <button (click)="login()" data-e2e="app-button-login" class="button is-primary" i18n>
          Sign In
        </button>
      </div>
    </div>
  </div>

  <button
    mobile-login
    *ngIf="cba"
    (click)="login()"
    data-e2e="app-button-login"
    class="button is-primary has-margin-left-auto is-hidden-desktop mt-2 mr-2"
  >
    Sign In
  </button>

  <ng-container vui-public>
    <marketplace-public *ngIf="cba"></marketplace-public>
  </ng-container>

  <vui-top-common-navbar
    vui-top-common-navbar
    [root]="application"
    [navbarItems]="navbarItems"
    [navbarUserItem]="navbarUserItem"
    [logoSrc]="logoSrc"
  ></vui-top-common-navbar>
</vui-portal>

<vui-footer
  *ngIf="!isWhiteLabelingEnabled"
  [isLoggedIn]="isLoggedIn"
  [supportLink]="supportPageLink"
></vui-footer>
