import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import environment from '@environments';
import { HttpUrlGenerator } from '@ngrx/data';
import { UserModule } from '@portal/entity-services/users';
import { PublicModule } from '@portal/marketplace/app-catalog';
import { ErrorHandlerModule } from '@portal/shared/error-handler';
import { BackendApiUrlGenerator } from '@portal/shared/helpers';
import { PortalModule } from '@portal/shared/ui/layouts';
import { AuthInterceptor, SharedVuiHttpModule } from '@portal/shared/vui-http';
import { AppComponent } from './app.component';
import { VuiRoutes } from './vui.routing';
import { AnalyticsModule } from '@portal/shared/analytics';
import { APP_CONFIG } from '@portal/shared/auth/authorization/src/lib/di-tokens/app-token';
import { TopNavbarModule } from '@portal/shared/ui/layouts/src/lib/top-navbar/top-navbar.module';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '@portal/shared/languages';
import {
  APPS_DEFAULT_LANGUAGE,
  APPS_SUPPORTED_LANGUAGES,
} from 'configs/localization/supported-languages';
import { Application } from '@portal/shared/auth/authorization';
import { ChangePasswordPageComponent } from './change-password-page/change-password-page.component';
import { SharedAuthAuthenticationModule } from '@portal/shared/auth/authentication';
import { WHITE_LABEL_CONFIG } from '@portal/shared/white-labeling/src/lib/di-tokens/white-label-token';
import { SharedWhiteLabelingModule } from '@portal/shared/white-labeling/src/lib/shared-white-labeling.module';
import { FooterModule } from '@portal/shared/ui/layouts/src/lib/footer/footer.module';

@NgModule({
  declarations: [AppComponent, ChangePasswordPageComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    SharedVuiHttpModule.init(),
    PortalModule,
    UserModule,
    RouterModule.forRoot([], { initialNavigation: 'enabled', relativeLinkResolution: 'legacy' }),
    VuiRoutes,
    PublicModule,
    AnalyticsModule,
    environment.PRODUCTION
      ? [AkitaNgRouterStoreModule]
      : [AkitaNgDevtools.forRoot(), AkitaNgRouterStoreModule],
    TopNavbarModule,
    SharedAuthAuthenticationModule,
    SharedWhiteLabelingModule,
    ErrorHandlerModule.forRoot({ appName: 'PORTAL_WRAPPER' }),
    FooterModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_CONFIG, useValue: { name: 'Wrapper' } },
    {
      provide: HttpUrlGenerator,
      useClass: BackendApiUrlGenerator,
    },
    {
      provide: SUPPORTED_LANGUAGES,
      useValue: APPS_SUPPORTED_LANGUAGES[Application.PaymentsPortal],
    },
    {
      provide: DEFAULT_LANGUAGE,
      useValue: APPS_DEFAULT_LANGUAGE[Application.Wrapper],
    },
    {
      provide: WHITE_LABEL_CONFIG,
      useValue: {
        enabled: true,
        publicBucketUrl: environment.API_ENDPOINT.PUBLIC_CATALOG.publicBucket,
        ignoreDomains: [
          'localhost',
          'devportal.dimebox.com', // DEV
          'dev.merchant.verifonecp.com', // MP-DEV
          'qatportal.dimebox.com', // QAT
          'cstportal.dimebox.com', // CST deprecated
          'uscst.test.vficloud.net', // CST US
          'emea.test.verifone.cloud', // CST EMEA
          'test.verifone.cloud', // CST EMEA
          'us.live.verifone.cloud', // PROD US
          'emea.live.verifone.cloud', // PROD EMEA
          'nz.live.verifone.cloud', // PROD NZ
          'au.live.verifone.cloud', // PROD AU
        ],
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
