import { AuthenticationDefaultPath } from './../../../../../../apps/authentication/src/app/enums/default-path.enum';
import { DOMAIN_NAME } from './../../../../../../apps/authentication/src/app/constants/storage-keys.constant';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional, Renderer2, RendererFactory2 } from '@angular/core';
import { GOOGLE_FONTS_LINKS } from '@apps/portal/src/app/core/constants/google-fonts.const';
import {
  HelpPageParameter,
  HelpPageParameterResponse,
  TemplateParameters,
  TemplateParametersResponse,
} from '@portal/entity-services/interfaces/src/lib/organisations/interfaces/reseller.interface';
import { Fonts } from '@portal/entity-services/organisations/src/lib/enums/fonts.enum';
import { DOMHelper } from '@portal/shared/helpers/src/lib/dom/dom-helper.service';
import omit from 'lodash-es/omit';
import words from 'lodash-es/words';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, shareReplay, tap } from 'rxjs/operators';
import { WHITE_LABEL_CONFIG } from '../di-tokens/white-label-token';
import { IWhiteLabelConfig } from '../interfaces/white-label-config.interface';
import { WHITE_LABELING_VARS } from '../styles/white-labeling-vars';
import { PaletteGeneratorService } from './palette-generator.service';

@Injectable({ providedIn: 'root' })
export class WhiteLabelingService {
  loading$: Observable<boolean>;

  private setLoading$ = new BehaviorSubject(false);
  private isEnabledForProject: boolean;
  private basePath: string;
  private parameters$: Observable<TemplateParameters>;
  private parameters: TemplateParameters;
  private logo$: Observable<HTMLImageElement>;
  private favicon$: Observable<HTMLImageElement>;
  private favicon: HTMLImageElement;
  private domainName = window.location.hostname;
  private ignoreDomains: string[];
  private renderer: Renderer2;
  private getlocalStorageDomainName: string;

  constructor(
    rendererFactory2: RendererFactory2,
    private paletteGeneratorService: PaletteGeneratorService,
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WHITE_LABEL_CONFIG) @Optional() whiteLabelConfig: IWhiteLabelConfig,
  ) {
    this.isEnabledForProject = whiteLabelConfig?.enabled;
    this.basePath = whiteLabelConfig?.publicBucketUrl;
    this.ignoreDomains = whiteLabelConfig?.ignoreDomains || [];
    this.getlocalStorageDomainName = localStorage.getItem(DOMAIN_NAME);
    this.renderer = rendererFactory2.createRenderer(null, null);
    this.loading$ = this.setLoading$.asObservable();
    this.applyFavicon();
  }

  getBasePath(): string {
    return this.basePath;
  }

  getLogoUrl(organisationDomainName?: string): string {
    const currentUrl = window.location.href;
    if (currentUrl.includes(AuthenticationDefaultPath.Support && this.getlocalStorageDomainName)) {
      return `${this.basePath}/${this.getlocalStorageDomainName}/logo.png`;
    }
    return `${this.basePath}/${organisationDomainName || this.domainName}/logo.png`;
  }

  getFaviconUrl(organisationDomainName?: string): string {
    const currentUrl = window.location.href;
    if (currentUrl.includes(AuthenticationDefaultPath.Support && this.getlocalStorageDomainName)) {
      return `${this.basePath}/${this.getlocalStorageDomainName}/favicon.png`;
    }
    return `${this.basePath}/${organisationDomainName || this.domainName}/favicon.png`;
  }

  getStylesUrl(): string {
    const currentUrl = window.location.href;
    if (currentUrl.includes(AuthenticationDefaultPath.Support && this.getlocalStorageDomainName)) {
      return `${this.basePath}/${this.getlocalStorageDomainName}/style.css`;
    }
    return `${this.basePath}/${this.domainName}/style.css`;
  }

  getParametersUrl(): string {
    const currentUrl = window.location.href;
    if (currentUrl.includes(AuthenticationDefaultPath.Support && this.getlocalStorageDomainName)) {
      return `${this.basePath}/${this.getlocalStorageDomainName}/parameters.json`;
    }
    return `${this.basePath}/${this.domainName}/parameters.json`;
  }

  loadImage(
    image$: Observable<HTMLImageElement>,
    url: string,
    isWLPreviewPage: boolean,
  ): Observable<HTMLImageElement> {
    if (!this.isWhiteLabelingEnabled() && !isWLPreviewPage) return of(null);

    if (image$) {
      return image$;
    }

    const logoSubject = new Subject<any>();
    image$ = logoSubject.asObservable().pipe(shareReplay(1));

    const img = new Image();
    const imageLoadHandler = (): void => {
      logoSubject.next(img);
      this.setLoading(false);
      removeListeners();
    };
    const imageLoadErrorHandler = (): void => {
      logoSubject.next(null);
      this.setLoading(false);
      removeListeners();
    };
    const removeListeners = (): void => {
      img.removeEventListener('load', imageLoadHandler);
      img.removeEventListener('error', imageLoadErrorHandler);
      img.remove();
    };

    this.setLoading(true);
    img.addEventListener('load', imageLoadHandler);
    img.addEventListener('error', imageLoadErrorHandler);
    img.src = url;

    return image$;
  }

  loadLogo(isWLPreviewPage = false): Observable<HTMLImageElement> {
    return this.loadImage(this.logo$, this.getLogoUrl(), isWLPreviewPage);
  }

  loadFavicon(isWLPreviewPage = false): Observable<HTMLImageElement> {
    return this.loadImage(this.favicon$, this.getFaviconUrl(), isWLPreviewPage).pipe(
      tap((favicon) => (this.favicon = favicon)),
    );
  }

  applyFavicon(): void {
    const linkElement = this.document.getElementById('favicon') as HTMLLinkElement;
    if (linkElement) {
      if (this.isWhiteLabelingEnabled()) {
        this.renderer.setAttribute(linkElement, 'type', 'image/png');
        this.renderer.setAttribute(linkElement, 'href', this.getFaviconUrl());
      } else {
        this.renderer.setAttribute(linkElement, 'type', 'image/x-icon');
        this.renderer.setAttribute(linkElement, 'href', 'favicon.ico');
      }
    }
  }

  applyStyles(): boolean {
    const parameters = this.parameters;
    const brandBackground = parameters?.pageStyle?.brand?.brandBackground;
    const brandText = parameters?.pageStyle?.brand?.brandText;
    const brandOutlinedText = parameters?.pageStyle?.brand?.brandOutlinedText;
    const navBarBackground = parameters?.pageStyle?.navBar?.navbarBackgroundColor;
    const navBarText = parameters?.pageStyle?.navBar?.navbarItemColor;

    if (!brandBackground || !brandText || !brandOutlinedText || !navBarBackground || !navBarText) {
      return false;
    }

    const brandPalette = this.paletteGeneratorService.getBrandPalette(
      brandBackground,
      brandText,
      brandOutlinedText,
    );
    const navbarPalette = this.paletteGeneratorService.getNavbarPalette(
      navBarBackground,
      navBarText,
    );

    this.setPaletteVariables(WHITE_LABELING_VARS);
    this.setPaletteVariables(brandPalette);
    this.setPaletteVariables(navbarPalette);
    return true;
  }

  loadParameters(): Observable<TemplateParameters> {
    if (!this.isWhiteLabelingEnabled()) return of(null);

    if (this.parameters$) {
      return this.parameters$;
    }

    this.parameters$ = this.http.get<TemplateParametersResponse>(this.getParametersUrl()).pipe(
      map<TemplateParametersResponse, TemplateParameters>((parameters) => ({
        ...omit(parameters, ['logo']),
        helpPage: this.formatHelpPage(parameters.helpPage),
      })),
      tap((parameters) => (this.parameters = parameters)),
      finalize(() => this.setLoading(false)),
      catchError(() => of(null)),
      shareReplay(1),
    );

    return this.parameters$;
  }

  loadCollection(): Observable<[TemplateParameters, HTMLImageElement, HTMLImageElement]> {
    return combineLatest([this.loadParameters(), this.loadLogo(), this.loadFavicon()]);
  }

  getParameters(): TemplateParameters {
    return this.parameters;
  }

  getParametersObservable(): Observable<TemplateParameters> {
    return this.parameters$;
  }

  getBrandText(): string {
    return this.parameters?.text?.brandText;
  }

  getPrivacyPolicyUrl(): string {
    return this.parameters?.text?.privacyPolicyUrl;
  }

  getLoginDomainName(): string {
    return this.parameters?.text?.loginDomainName;
  }

  applyGoogleFont(googleFont: Fonts): boolean {
    if (!googleFont) return false;

    const linkElement = this.renderer.createElement('link') as HTMLLinkElement;

    this.renderer.setAttribute(linkElement, 'rel', 'stylesheet');
    this.renderer.setAttribute(linkElement, 'href', GOOGLE_FONTS_LINKS[googleFont]);
    this.renderer.appendChild(this.document.head, linkElement);
    DOMHelper.setRootCssVariable(this.document, 'font', googleFont);

    return true;
  }

  applyWhiteLabeling(): void {
    this.document.body.classList.add('white-labeled');
  }

  isParametersExists(): Observable<boolean> {
    return this.loadParameters().pipe(map((parameters) => Boolean(parameters)));
  }

  isLogoExists(): Observable<boolean> {
    return this.loadLogo().pipe(map((logo) => Boolean(logo)));
  }

  isFaviconExists(): Observable<boolean> {
    return this.loadFavicon().pipe(map((favicon) => Boolean(favicon)));
  }

  isWhiteLabelingExists(): Observable<boolean> {
    const isParametersExists$ = this.isParametersExists();
    const isLogoExists$ = this.isLogoExists();

    return combineLatest([isParametersExists$, isLogoExists$]).pipe(
      map(([isParametersExists, isLogoExists]) => {
        return isParametersExists || isLogoExists;
      }),
    );
  }

  /***
   * Receives url as a param
   * and returns only domain
   * @param url
   */
  setDomainName(url: string): void {
    const regExp = new RegExp('^(?:https?:\\/\\/)?(?:[^@\\/\\n]+@)?(?:www\\.)?([^:\\/?\\n]+)');
    this.domainName = regExp.exec(url)[1];
  }

  isWhiteLabelingEnabled(): boolean {
    return this.isEnabledForProject && !this.ignoreDomains.includes(this.domainName);
  }

  private setLoading(state: boolean): void {
    this.setLoading$.next(state);
  }

  private formatHelpPage(helpPageResponse: HelpPageParameterResponse[]): HelpPageParameter[] {
    return (helpPageResponse || []).map((item) => ({
      country: words(Object.keys(item)[0]).join(' '),
      emailOrUrl: Object.values(item)[0].emailOrUrl,
      notes: Object.values(item)[0].notes,
    }));
  }

  private setPaletteVariables(palette: object): void {
    Object.entries(palette).forEach(([key, value]) => {
      DOMHelper.setRootCssVariable(this.document, key, value);
    });
  }
}
