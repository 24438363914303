<div class="box is-flex mt-1 pt-1 pb-1 p-0 is-align-items-center" data-e2e="file">
  <span class="icon is-size-6 pl-3 pr-3">
    <i class="far fa-file-alt fa-lg"></i>
  </span>
  <div class="is-flex is-flex-direction-column width-full is-size-7">
    <div class="is-flex" data-e2e="file-title">
      <span class="width-0 has-text-truncate is-flex-grow-1" title="{{ file.name }}">
        {{ file.name }}
      </span>
    </div>
    <span class="help m-0" data-e2e="file-helper-text" *ngIf="helperText">{{ helperText }}</span>
  </div>
  <div class="action pl-3 pr-3" *ngIf="file.size">
    <button
      class="button has-text-danger is-text is-empty"
      (click)="onDeleteFile()"
      data-e2e="file-remove-button"
      [disabled]="disabled"
      type="button"
      title="Remove"
      i18n-title
    >
      <span class="icon is-size-5 has-text-grey">
        <i class="fas fa-times fa-lg"></i>
      </span>
    </button>
  </div>
</div>
