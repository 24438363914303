import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class OrganisationValidators extends Validators {
  orgContactEmailRequired = (control: AbstractControl): {} => {
    return control.value?.some((x) => x.email)
      ? null
      : {
          required: {
            message: '@@DISPUTE_MANAGEMENT_FEATURE_REQUIRES_CONTACT_EMAIL',
            displayMessage: $localize`Dispute management feature requires a contact e-mail. Please add contact details`,
          },
        };
  };
}
