import { Injectable } from '@angular/core';
import { DEV_APPS_API } from '@portal/marketplace/api-interfaces/src';
import { VuiHttpService } from '@portal/shared/vui-http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AllAppsCatalogDataService {
  constructor(private vuiHttpService: VuiHttpService) {}

  getAppById(versionId: string): Observable<any> {
    const url = DEV_APPS_API.GET_APP_VERSION(versionId);
    return this.vuiHttpService.get(url);
  }

  getAllVersions(appId: string): Observable<any> {
    const url = DEV_APPS_API.GET_APP_VERSIONS(appId);
    return this.vuiHttpService.get(url);
  }
}
