export enum ReportType {
  MerchantSettlementReport = 'MERCHANT_SETTLEMENT_REPORT',
  DailyTransactionReport = 'DAILY_TRANSACTION_REPORT',
  CustomScheduledReport = 'CUSTOM_PDSP_REPORT',
  AbsSettlementResponseReport = 'ABS_SETTLEMENT_RESPONSE_REPORT',
  IntercardCommissionReport = 'INTERCARD_COMMISSION_REPORT',
  IntercardInvoice = 'INTERCARD_INVOICE',
  WoodforestMonthlyInvoiceReport = 'WOODFOREST_MONTHLY_INVOICE_REPORT',
  WoodforestDailyPayoutReport = 'WOODFOREST_DAILY_PAYOUT_REPORT',
  WICReconciliationTransactionReport = 'WIC_AUTO_RECON_TRANSACTION_REPORT',
  WICReconciliationAdjustmentReport = 'WIC_AUTO_RECON_ADJUSTMENT_REPORT',
  WICReconciliationSummaryReport = 'WIC_AUTO_RECON_SUMMARY_REPORT',
  PartnerCommissionReport = 'BBX_PARTNER_COMMISSION_REPORT',
  BBXMonthlyInvoiceReport = 'BBX_MONTHLY_INVOICE_REPORT',
  UkTaxReport = 'UK_AR_TAX_REPORT',
  EuTaxReport = 'EU_AR_TAX_REPORT',
  CommissionReport = 'COMMISSION_REPORT',
  Invoice = 'INVOICE',
  BBXDailyInvoiceReport = 'BBX_DAILY_PAYOUT_REPORT',
}
