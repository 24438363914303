<h2 class="title is-3" i18n>Linked token scopes</h2>

<ng-container *ngIf="(linkedTokenScopes$ | async).length; else emptyLinkedTokenScopes">
  <div
    data-e2e="linked-token-scope"
    class="level"
    *ngFor="let linkedTokenScope of linkedTokenScopes$ | async; trackBy: trackById"
  >
    <div class="level-left">
      <p data-e2e="linked-token-scope-name">{{ linkedTokenScope.name }}</p>
    </div>
    <div class="level-right buttons">
      <button
        class="button is-danger is-primary is-outlined"
        data-e2e="unlink-button"
        (click)="unlinkTokenScope(linkedTokenScope)"
        type="button"
        title="Unlink token scope"
        i18n-title
      >
        <span class="icon">
          <i class="far fa-trash" aria-hidden="true"></i>
        </span>
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isCreateMode">
  <p
    *ngIf="(filteredTokenScopes$ | async).length"
    class="content is-gray help"
    data-e2e="help-text"
    i18n
  >
    Link one or more token scopes to this organisations
  </p>

  <div class="field">
    <div class="control">
      <div
        class="checkbox"
        *ngFor="let tokenScope of filteredTokenScopes$ | async; trackBy: trackById"
      >
        <input
          id="tokenScope-{{ tokenScope.tokenScopeUid }}"
          data-e2e="token-scope-checkbox"
          type="checkbox"
          name="tokenScope"
          #tokenScopeInput
          (change)="onCheckboxChanged(tokenScope, tokenScopeInput.checked)"
        />
        <label for="tokenScope-{{ tokenScope.tokenScopeUid }}">{{ tokenScope.name }}</label>
      </div>
    </div>
  </div>
  <div class="field is-grouped">
    <div class="control">
      <button
        type="button"
        class="button is-primary"
        data-e2e="save-linked-token-scopes-button"
        (click)="linkTokenScopes()"
        i18n
        title="Save token scopes"
        i18n-title
      >
        Save token scopes
      </button>
    </div>
    <div class="control">
      <button
        type="button"
        class="button"
        data-e2e="cancel-button"
        (click)="isCreateMode = false"
        i18n
        title="Cancel"
        i18n-title
      >
        Cancel
      </button>
    </div>
  </div>
</ng-container>

<button
  type="button"
  class="button is-primary is-outlined"
  data-e2e="link-new-token-button"
  i18n
  *ngIf="(filteredTokenScopes$ | async).length && !isCreateMode"
  (click)="isCreateMode = true"
  title="Link new token scopes"
  i18n-title
>
  Link new token scopes
</button>

<ng-template #emptyLinkedTokenScopes>
  <p class="content" data-e2e="empty-token-scopes" i18n *ngIf="!isCreateMode">
    There are no token scopes linked to this organisation
  </p>
</ng-template>
