<label>
  <div class="label">
    {{ name }}
    <span *ngIf="optionalText">
      &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
    </span>
  </div>

  <div [ngClass]="{ 'is-danger': invalid }">
    <ng-select
      [formControl]="control"
      [bindValue]="bindValue"
      [bindLabel]="bindLabel"
      [multiple]="multiple"
      [closeOnSelect]="closeOnSelect"
      [items]="currencies"
      [attr.data-e2e]="e2eName"
      notFoundText="No items found"
      i18n-notFoundText
    >
      <ng-template ng-label-tmp let-item="item">
        <span class="ng-value-icon left" (click)="remove.emit(item.code)">×</span>
        <span class="ng-value-label" *ngIf="item.name">{{ item.name }} [{{ item.code }}]</span>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="checkbox is-pulled-left" *ngIf="multiple; else withoutCheckboxes">
          <input
            [ngModel]="item$.selected"
            [ngModelOptions]="{ standalone: true }"
            type="checkbox"
            id="item-{{ index }}"
            data-e2e="currenciesControl-checkbox"
          />
          <span>{{ item.name }} [{{ item.code }}]</span>
        </div>
        <ng-template #withoutCheckboxes>
          <span>{{ item.name }} [{{ item.code }}]</span>
        </ng-template>
      </ng-template>
    </ng-select>
  </div>
  <portal-form-error [formInput]="control" [errorId]="e2eErrorName"></portal-form-error>
</label>
