export enum DateFilter {
  Last30Days = 30,
  Last18Months = 548,
  Last7Days = 7,
  Last24Hours = 1,
  Today = 0,
  PastYear = 365,
  PastTwoYears = 730,
  Custom = 'Custom',
  Yesterday = 'Yesterday',
  LastWeek = 'LastWeek',
  LastMonth = 'LastMonth',
}
