export enum DateFilterQuickPick {
  Today = 'Today',
  Yesterday = 'Yesterday',
  LastWeek = 'Lastweek',
  LastMonth = 'Lastmonth',
  Last7Days = 'Last7days',
  Last30Days = 'Last30days',
  Last18Months = 'Last18Months',
  Custom = 'Custom',
  AnyDate = 'AnyDate',
}
