declare const globalThis: any;

export class SecuredRandomService {
  static securedRandomId(): number {
    const crypto = globalThis.crypto || globalThis.msCrypto;
    const array = new Uint32Array(10);
    crypto.getRandomValues(array); // Compliant for security-sensitive use cases
    return array[0] / 0x100000000;
  }
}
