import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DateService } from '@portal/shared/helpers/src/lib/date/date.service';
import { plural } from '@portal/shared/helpers/src/lib/translation/translation-helper';
import { compact, get, isNaN, reduce, toNumber } from 'lodash';
import * as moment from 'moment';

declare const $localize;

export const RegexPatterns = {
  // email regex copied from `/lib/regex.js` we dont' limit the email extensions, https://data.iana.org/TLD/tlds-alpha-by-domain.txt
  // All segments of FQDNs (provided that they are not IDNs, which incurs further restrictions), including TLDs are limited to 63 ASCII characters.
  // eslint-disable-next-line max-len

  // this email regex is freezing the browser on fast typing , its to heavy regex
  // example is here : https://stackblitz.com/edit/reactive-form-formbuilder-exam-hap792?file=src%2Fapp%2Fuser-form%2Fuser-form.component.ts
  // email:  /^[\w\u00C0-\u024F]+([\.-]?[\w\u00C0-\u024F]+)+@[\w\u00C0-\u024F]+([\.-]?[\w\u00C0-\u024F]+)*(\.\w{2,63})+$/,
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  verifoneEmail: /@verifone.com$/,
  validCharacters: /[^\s\w\u00C0-\u024F\u2C60-\u2C7F\uA720-\uA7FF\u1E00-\u1EFF,.:&\/()+%'`@-]/g,
  phoneNumber: /^(\+ ?\d+)(( ?\d)+)$/,
  url: new RegExp(
    '^(?:(?:https?):\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$',
    'i',
  ),
  domainName: /^[a-zA-Z0-9-_.]*$/,
  names: /^[^0123456789!@#$%^&*()_+={}\[\]\|\\";:><\?\/]+$/,
  digits: /^\d+$/,
  double: /^\d+(\.\d{1,2})?$|^\d$/,
  integer: /^[0-9]*$/,
  strings: /^[A-Za-z]+$/,
  version: /^\d{1,2}\.\d{1,3}(\.\d{1,4}(\.\d{1,6})?)?$/,
  nonZeroVersion: /^[0\.]*$/,
  validateFirstLastCharacters: /(^[%&?,'`~,:/+@#$^*. )])|([&,':/+@#^*($%]$)/,
  // https://www.w3resource.com/javascript/form/ip-address-validation.php
  ip: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  allSpaces: /^\s+$/,
  price: /^\d{1,17}(\.\d\d?)?$/,
  time: /^\d{2}:\d{2}$/,
  nonZero: /^[0\.]+$/,
  oneSpaceBetweenWords: /^(\S+ )*\S+$/,
  //copied form ES docs gbx-entity-service1.1.12.html#operation/createEntitySepaAccount - iban
  iban: /^[a-zA-Z]{2,2}[0-9]{2,2}[a-zA-Z0-9]{1,30}$/,
  merchantReference: /^[a-zA-Z0-9\+\_\-\,\.\u0590-\u05FF\ ]*$/,
  dynamicDescriptor: /^[a-zA-Z0-9\+\_\-\,\.\ ]*$/,
  merchantReferencePrefix: /^[^=+\-@].*$/,
  merchantReferenceCode: /^[0-9]{4}$/,
  // For Tid list
  terminalId: /^[a-zA-Z0-9]{1,12}$/,
  siteReferenceId: /^[a-zA-Z0-9\-\_\ \.]*$/,
  estateManagementId: /^[a-zA-Z0-9]*$/,
  altVfiEntityId: /^$|^(?!\s*$).{0,30}$/,
  password: [
    {
      name: 'atLeast8Chars',
      regExp: /.{8,}/,
      message: '@@CONTAINS_LESS_THAN_8_CHARACTERS',
      error: $localize`At least 8 characters`,
    },
    {
      name: 'atLeast1Digit',
      regExp: /\d/,
      message: '@@DOES_NOT_CONTAIN_DIGIT',
      error: $localize`At least 1 number: e.g. 123456`,
    },
    {
      name: 'atLeast1CapLet',
      regExp: /[A-Z]/,
      message: '@@DOES_NOT_CAPITAL_LETTER',
      error: $localize`At least 1 upper case letter: e.g. ABC`,
    },
    {
      name: 'atLeast1LowLet',
      regExp: /[a-z]/,
      message: '@@DOES_NOT_CONTAIN_LOWER_CASE_LETTER',
      error: $localize`At least 1 lower case letter: e.g. abc`,
    },
    {
      name: 'atLeast1Sym',
      regExp: /[!"#$%&'\\()*§+±,-\./:;<=>?@[\]^_`{|}~]/,
      message: '@@DOES_NOT_CONTAIN_SYMBOL',
      error: $localize`At least 1 special character: e.g. @#$`,
    },
  ],
  checkoutCustomerEmail:
    /^(?=.{1,64}@[^@]*$)[^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  bundleName: /^[a-zA-Z].*/,
  semiColonSeparatedEmailAddresses:
    /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,
  urlWithOrWithoutHttp:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  businessId: /^\d{7}-\d$/,
  alphanumericWithSpaceAndDash: /[^\s\w\u00C0-\u024F\u2C60-\u2C7F\uA720-\uA7FF\u1E00-\u1EFF_-]+/,
  nonNegativeNumber: /^\d+(\.\d+)?$/,
  invoiceNumber: /^[a-zA-Z0-9+_\-,.;:\/'#$%^&*()!@=}{[\]|\?~` ]+$/,
  alphanumeric: /^[a-zA-Z0-9]*$/,
  colorHexa: /^#?([a-fA-F0-9]{6})$/,
};

export const passwordMatchValidation = {
  name: 'passwordMatch',
  regExp: /g/,
  message: '@@PASSWORD_NOT_MATCH',
  error: $localize`New password must match`,
};

@Injectable({ providedIn: 'root' })
export class MultilingualValidators {
  constructor(private dateService: DateService) {}

  static isValidTime(timeString: string): boolean {
    return RegexPatterns.time.test(timeString) && timeString >= '00:00' && timeString <= '23:59';
  }

  // Source: https://medium.com/ngx/3-ways-to-implement-conditional-validation-of-reactive-forms-c59ed6fc3325
  conditional = (predicate: (parent) => boolean, validator: ValidatorFn) => {
    return (control: AbstractControl) => {
      if (!control.parent) {
        return null;
      }
      if (predicate(control.parent)) {
        return validator(control);
      }
      return null;
    };
  };

  required = (control: AbstractControl): ValidationErrors | null => {
    const baseRequired = Validators.required(control);
    const isRequired = baseRequired && baseRequired.required;
    return isRequired
      ? {
          required: {
            message: '@@FIELD_REQUIRED',
            displayMessage: $localize`Please fill up the required field`,
          },
        }
      : null;
  };

  maxAmount = (input: string | number, message?: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const maxAmount = Validators.max(Number(input))(control);
      if (!maxAmount) {
        return null;
      }
      return {
        max: {
          message: '@@AMOUNT_TOO_HIGH',
          displayMessage: message || $localize`The amount value is above the maximum limit.`,
        },
      };
    };
  };

  minAmount = (input: string | number, message?: string): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const minAmount = Validators.min(Number(input))(control);
      if (!minAmount) {
        return null;
      }
      return {
        min: {
          message: '@@AMOUNT_TOO_LOW',
          displayMessage: message || $localize`The Amount value is below the limit`,
        },
      };
    };
  };

  maxAmountMoreThanAnotherControl = (inputControl: FormControl, message?: string): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const minAmount = Validators.max(Number(inputControl.value))(control);
      if (!minAmount) {
        return null;
      }
      return {
        max: {
          message: '@@AMOUNT_TOO_HIGH',
          displayMessage:
            message || $localize`Maximum amount cannot be less than the minimum amount`,
        },
      };
    };
  };

  minAmountLessThanAnotherControl = (inputControl: FormControl, message?: string): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const minAmount = Validators.min(Number(inputControl.value))(control);
      if (!minAmount) {
        return null;
      }
      return {
        min: {
          message: '@@AMOUNT_TOO_LOW',
          displayMessage:
            message || $localize`Minimum amount cannot be greater than the maximum amount`,
        },
      };
    };
  };

  minLength = (min: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const baseMinLength = Validators.minLength(min)(control);
      if (!baseMinLength) {
        return null;
      }
      const requiredLength = get(baseMinLength, 'maxlength.requiredLength');
      const actualLength = get(baseMinLength, 'maxlength.actualLength');
      return {
        maxLength: {
          message: '@@NUMBER_TOO_LOW',
          displayMessage: $localize`The length of the string is shorter than expected`,
          requiredLength,
          actualLength,
        },
      };
    };
  };

  maxLength = (max: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const baseMaxLength = Validators.maxLength(max)(control);
      if (!baseMaxLength) {
        return null;
      }
      const requiredLength = get(baseMaxLength, 'maxlength.requiredLength');
      const actualLength = get(baseMaxLength, 'maxlength.actualLength');
      return {
        maxLength: {
          message: '@@NUMBER_TOO_HIGH',
          displayMessage: $localize`The length of the string is higher than expected`,
          requiredLength,
          actualLength,
        },
      };
    };
  };

  email = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }
    return RegexPatterns.email.test(control.value)
      ? null
      : {
          email: {
            message: '@@INVALID_EMAIL',
            displayMessage: $localize`Please ensure your email is valid`,
          },
        };
  };

  //message change
  customerEmail = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }
    return RegexPatterns.email.test(control.value)
      ? null
      : {
          email: {
            message: '@@INVALID_EMAIL',
            displayMessage: `Please ensure the email is valid`,
          },
        };
  };

  checkoutCustomerEmail = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }
    return RegexPatterns.checkoutCustomerEmail.test(control.value)
      ? null
      : {
          checkoutCustomerEmail: {
            message: '@@INVALID_EMAIL',
            displayMessage: $localize`Please ensure your email is valid`,
          },
        };
  };

  phone = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.phoneNumber.test(control.value)
      ? null
      : {
          phone: {
            message: '@@NOT_A_PHONE_NUMBER',
            displayMessage: $localize`Please enter a valid phone number`,
          },
        };
  };

  merchantReference = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.merchantReference.test(control.value)
      ? null
      : {
          merchantReference: {
            message: '@@INVALID_MERCHANT_REFERENCE',
            displayMessage: $localize`Invalid characters found. Allowed symbols are "+" "," "." "_" "-"`,
          },
        };
  };

  customMaxLength = (refLength: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control && !control.value) {
        return null;
      }

      if (control.value.length > refLength) {
        return {
          dynamicDescriptorMaxLength: {
            message: '@@CUSTOM_MAX_LENGTH',
            displayMessage: $localize`The length of the string can't be more than ${refLength} characters`,
          },
        };
      }

      return null;
    };
  };

  dynamicDescriptor = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.dynamicDescriptor.test(control.value)
      ? null
      : {
          dynamicDescriptor: {
            message: '@@INVALID_DYNAMIC_DESCRIPTOR',
            displayMessage: $localize`Invalid characters found. Allowed symbols are "+" "," "." "_" "-"`,
          },
        };
  };

  invoiceNumber = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.invoiceNumber.test(control.value)
      ? null
      : {
          invoiceNumber: {
            message: '@@INVALID_INVOICE_NUMBER',
            displayMessage: $localize`Invalid characters found.`,
          },
        };
  };

  merchantReferencePrefix = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.merchantReferencePrefix.test(control.value)
      ? null
      : {
          merchantReference: {
            message: '@@INVALID_MERCHANT_REFERENCE',
            displayMessage: $localize`Merchant reference cannot start with "=", "+", "-", "@"`,
          },
        };
  };

  merchantReferenceCode = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.merchantReferenceCode.test(control.value)
      ? null
      : {
          merchantReferenceCode: {
            message: '@@INVALID_MERCHANT_REFERENCE_CODE',
            displayMessage: $localize`Please enter a valid 4-digit Merchant Category Code`,
          },
        };
  };

  characters =
    (validCharacters: RegExp = RegexPatterns.validCharacters) =>
    (control: AbstractControl): {} => {
      if (control.value && control.value.length > 0) {
        const matches = control.value.match(validCharacters);
        if (matches && matches.length) {
          const characters: string = reduce(
            matches,
            (characterString: string, character: string, index: number) => {
              let string = characterString;
              string += character;
              if (matches.length !== index + 1) {
                string += ', ';
              }

              return string;
            },
            '',
          );
          return {
            validateCharacters: {
              characters,
              message: `@@CHARACTERS_NOT_ALLOWED, invalid: ${characters}`,
              displayMessage: $localize`Please don’t use these invalid characters: ${characters}:characters:`,
            },
          };
        }

        return null;
      }

      return null;
    };

  validateCharacters = (control: AbstractControl): {} =>
    this.characters(RegexPatterns.validCharacters)(control);

  url = (control: AbstractControl): {} => {
    if (control.value && control.value.length > 0) {
      return RegexPatterns.url.test(control.value)
        ? null
        : {
            url: {
              message: '@@INVALID_URL',
              displayMessage: $localize`Please enter a valid URL`,
            },
          };
    }

    return null;
  };

  urlWithOrWithoutHttp = (control: AbstractControl): {} => {
    if (control.value && control.value.length > 0) {
      return RegexPatterns.urlWithOrWithoutHttp.test(control.value)
        ? null
        : {
            url: {
              message: '@@INVALID_URL',
              displayMessage: $localize`Please enter a valid URL`,
            },
          };
    }

    return null;
  };

  domainName = (control: AbstractControl): ValidationErrors | null => {
    if (control.value && control.value.length > 0) {
      return RegexPatterns.domainName.test(control.value)
        ? null
        : {
            domainName: {
              message: '@@INVALID_DOMAIN_NAME',
              displayMessage: $localize`Please enter a valid domain name`,
            },
          };
    }

    return null;
  };

  digits = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.digits.test(control.value)
      ? null
      : {
          digits: {
            message: '@@CONTAINS_NON_DIGITS',
            displayMessage: $localize`Please use only digits characters`,
          },
        };
  };

  double = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.double.test(control.value)
      ? null
      : {
          double: {
            message: '@@CONTAINS_NON_NUMBER_OR_DOUBLE',
            displayMessage: $localize`Please use only integer or double numbers`,
          },
        };
  };

  integer = (): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      return RegexPatterns.integer.test(control.value)
        ? null
        : {
            integer: {
              message: '@@CONTAINS_NON_NUMBER_OR_INTEGER',
              displayMessage: $localize`Please use only integer numbers`,
            },
          };
    };
  };

  onlyStrings = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.strings.test(control.value)
      ? null
      : {
          onlyStrings: {
            message: '@@CONTAINS_NON_ALPAHBET',
            displayMessage: $localize`Please use only alphabet characters`,
          },
        };
  };

  names = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.names.test(control.value)
      ? null
      : {
          onlyStrings: {
            message: '@@CONTAINS_INVALID_NAME',
            displayMessage: $localize`Please enter a valid name`,
          },
        };
  };

  ip = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.ip.test(control.value)
      ? null
      : {
          ip: {
            message: '@@CONTAINS_INVALID_IP_FORMAT',
            displayMessage: $localize`Please enter a valid IP address`,
          },
        };
  };

  ipPort = (lastPort: number): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      return Number(control.value) && Number(control.value) <= lastPort
        ? null
        : {
            ip: {
              message: '@@CONTAINS_INVALID_IP_FORMAT',
              displayMessage: $localize`Please enter a valid port`,
            },
          };
    };
  };

  version = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.version.test(control.value)
      ? null
      : {
          version: {
            message: '@@CONTAINS_INVALID_VERSION_FORMAT',
            displayMessage: $localize`Please enter a valid version format ([2].[3].[4].[6])`,
          },
        };
  };

  password = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }
    return RegexPatterns.password.reduce((errors, validation) => {
      if (!validation.regExp.test(control.value)) {
        errors[validation.name] = validation;
      }
      return errors;
    }, {});
  };

  passwordMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      const errors = control.errors || {};
      if (control.value !== matchingControl.value) {
        errors[passwordMatchValidation.name] = passwordMatchValidation;
        control.setErrors(errors);
        return errors;
      } else {
        delete errors[passwordMatchValidation.name];
        if (Object.keys(errors).length) {
          control.setErrors(errors);
          return errors;
        } else {
          control.setErrors(null);
          return null;
        }
      }
    };
  }

  nonZeroVersion = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return !RegexPatterns.nonZeroVersion.test(control.value)
      ? null
      : {
          nonZeroVersion: {
            message: '@@CONTAINS_ALL_0',
            displayMessage: $localize`Version could not contain all 0`,
          },
        };
  };

  maxWords = (max: number): ValidatorFn => {
    const oneWord = $localize`one word`;
    const words = $localize`words`;

    return (control: AbstractControl) => {
      const maxWords = new RegExp(`(\\w+\\W+){${max}}\\w+(\\W+\\w+)*`);
      if (!control.value) {
        return null;
      }
      return !maxWords.test(control.value)
        ? null
        : {
            minWords: {
              message: `@@MORE_THAN_${max}_WORDS`,
              displayMessage: $localize`Please don't use more than ${plural(max, {
                1: oneWord,
                other: `${max} ${words}`,
              })}`,
            },
          };
    };
  };

  validateFirstLastCharacters = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return !RegexPatterns.validateFirstLastCharacters.test(control.value)
      ? null
      : {
          validateFirstLastCharacters: {
            message: '@@CONTAINS_INVALID_FIRST_OR_LAST_SYMBOL',
            displayMessage: $localize`Please enter a valid first or last symbols`,
          },
        };
  };

  cartesBancairesPaymentType = (
    processorType: AbstractControl,
    defaultPaymentTypes: string[],
  ): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      const invalidTypes = control.value.filter(
        (paymentType) => !defaultPaymentTypes.includes(paymentType),
      );

      const doesNotSupport = $localize`does not support`;

      return invalidTypes.length
        ? {
            version: {
              message: `@@${processorType.value} does not support: ${invalidTypes.join(',')}`,
              displayMessage: `@@${processorType.value} ${doesNotSupport}: ${invalidTypes.join(
                ',',
              )}`,
            },
          }
        : null;
    };
  };

  validSelectedValue = (validValues: string[]): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      const isValidValue = validValues.some((value) => value === control.value);

      return isValidValue
        ? null
        : {
            validSelectedValue: {
              message: '@@CONTAINS_INVALID_VALUE',
              displayMessage: $localize`Please enter a valid value`,
            },
          };
    };
  };

  allSpaces = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return !RegexPatterns.allSpaces.test(control.value)
      ? null
      : {
          allSpaces: {
            message: '@@CONTAINS_ALL_SPACES',
            displayMessage: $localize`Could not contain only spaces`,
          },
        };
  };

  price = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.price.test(control.value)
      ? null
      : {
          price: {
            message: '@@CONTAINS_INVALID_PRICE_FORMAT',
            displayMessage: $localize`Please enter a valid price (X.XX)`,
          },
        };
  };

  nonZero = (control: AbstractControl): {} => {
    if (control.value === null) {
      return null;
    }

    return !RegexPatterns.nonZero.test(control.value)
      ? null
      : {
          nonZero: {
            message: '@@0_NOT_ALLOWED',
            displayMessage: $localize`Amount cannot be zero`,
          },
        };
  };

  nonZeroCustomMessage = (message?: string): ValidatorFn => {
    return (control: AbstractControl): {} => {
      if (control.value === null) {
        return null;
      }

      return !RegexPatterns.nonZero.test(control.value)
        ? null
        : {
            nonZero: {
              message: '@@0_NOT_ALLOWED',
              displayMessage: message || $localize`Amount cannot be zero`,
            },
          };
    };
  };

  oneSpaceBetweenWords = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.oneSpaceBetweenWords.test(control.value.trim())
      ? null
      : {
          version: {
            message: '@@CONTAINS_MORE_THAN_ONE_SPACE_BETWEEN_WORDS',
            displayMessage: $localize`Please avoid extra spaces`,
          },
        };
  };

  higherVersion = (oldVersion: string): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      const oldV = oldVersion.split('.');
      const newV = control.value.split('.');

      while (oldV.length < newV.length) {
        oldV.push('0');
      }
      while (newV.length < oldV.length) {
        newV.push('0');
      }

      for (let i = 0; i < oldV.length; ++i) {
        if (newV.length === i) {
          return null;
        }

        if (+oldV[i] === +newV[i]) {
          continue;
        } else if (+oldV[i] > +newV[i]) {
          return {
            higherVersion: {
              message: `@@VERSION_MUST_BE_MORE_THAN_CURRENT_${oldVersion}`,
              displayMessage: $localize`The version number should be greater than ${oldVersion}:oldVersion:`,
            },
          };
        } else {
          return null;
        }
      }

      return {
        higherVersion: {
          message: `@@VERSION_MUST_BE_MORE_THAN_CURRENT_${oldVersion}`,
          displayMessage: $localize`The version number should be greater than ${oldVersion}:oldVersion:`,
        },
      };
    };
  };

  time = (control: AbstractControl): {} => {
    return !control.value || MultilingualValidators.isValidTime(control.value)
      ? null
      : {
          time: {
            message: '@@INVALID_TIME',
            displayMessage: $localize`Invalid time format`,
          },
        };
  };

  timePair = (group: FormGroup): {} => {
    const startControl = group.get('start');
    const endControl = group.get('end');

    if (
      startControl.value &&
      MultilingualValidators.isValidTime(startControl.value) &&
      endControl.value &&
      MultilingualValidators.isValidTime(endControl.value)
    ) {
      if (startControl.value >= endControl.value) {
        return {
          timePair: {
            message: '@@INVALID_TIME_PAIR',
            displayMessage: $localize`End time should be after start time`,
          },
        };
      }
    }

    return null;
  };

  timePairOverlap = (array: FormArray): {} => {
    const values = compact(
      array.value.flatMap((item) => {
        if (
          MultilingualValidators.isValidTime(item.start) &&
          MultilingualValidators.isValidTime(item.end) &&
          item.start < item.end
        ) {
          return [item.start, item.end];
        }
      }),
    );
    const sortedValues = [...values].sort();

    return values.join(',') === sortedValues.join(',')
      ? null
      : {
          timePairOverlap: {
            message: '@@INVALID_TIME_PAIR_OVERLAP',
            displayMessage: $localize`Timeslots should not overlap`,
          },
        };
  };

  markOneOption = (openingHours: AbstractControl): {} => {
    return openingHours.value.length
      ? null
      : {
          markOneOption: {
            message: '@@INVALID_MARRK_ONE_OPTION',
            displayMessage: $localize`Please mark at least one option and add valid hours`,
          },
        };
  };

  iban = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.iban.test(control.value)
      ? null
      : {
          iban: {
            message: '@@CONTAINS_INVALID_IBAN_FORMAT',
            displayMessage: $localize`Please enter a valid IBAN code`,
          },
        };
  };

  /*
    https://developers.google.com/maps/documentation/javascript/reference/coordinates?hl=fr#LatLng
    "Longitude ranges between -180 and 180 degrees, inclusive."
  */
  longitude = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    const numberValue = toNumber(control.value);

    return !isNaN(numberValue) && numberValue <= 180 && numberValue >= -180
      ? null
      : {
          longitude: {
            message: '@@CONTAINS_INVALID_LONGITUDE',
            displayMessage: $localize`Please enter a valid longitude`,
          },
        };
  };

  /*
    https://developers.google.com/maps/documentation/javascript/reference/coordinates?hl=fr#LatLng
    "Latitude ranges between -90 and 90 degrees, inclusive."
  */
  latitude = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    const numberValue = toNumber(control.value);

    return !isNaN(numberValue) && numberValue <= 90 && numberValue >= -90
      ? null
      : {
          latitude: {
            message: '@@CONTAINS_INVALID_LATITUDE',
            displayMessage: $localize`Please enter a valid latitude`,
          },
        };
  };

  terminalId = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.terminalId.test(control.value)
      ? null
      : {
          terminalId: {
            message: '@@CONTAINS_INVALID_TERMINAL_ID_FORMAT',
            displayMessage: $localize`Please enter a valid terminal ID`,
          },
        };
  };

  businessId = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    return RegexPatterns.businessId.test(control.value)
      ? null
      : {
          businessId: {
            message: '@@CONTAINS_INVALID_BUSINESS_ID_FORMAT',
            displayMessage: $localize`Please enter a valid business id`,
          },
        };
  };

  date = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || control.value instanceof Object) {
      return null;
    }

    return !isNaN(this.dateService.fromLocalisedShortString(control.value).getTime())
      ? null
      : {
          date: {
            message: '@@CONTAINS_INVALID_DATE_FORMAT',
            displayMessage: $localize`Date is invalid`,
          },
        };
  };

  compareDates(start: string, end: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      if (!('controls' in formGroup)) return null;
      const startDate = (formGroup as FormGroup).controls[start];
      const endDate = (formGroup as FormGroup).controls[end];

      if (startDate.value && endDate.value) {
        if (moment(endDate.value).isSameOrAfter(startDate.value, 'day')) {
          return null;
        } else {
          endDate.setErrors({
            date: {
              message: '@@CONTAINS_INVALID_DATE',
              displayMessage: $localize`End date must come after start date`,
            },
          });
        }
      }
    };
  }

  siteReferenceId = (control: AbstractControl): {} => {
    if (control.value && control.value.length > 0) {
      return RegexPatterns.siteReferenceId.test(control.value)
        ? null
        : {
            siteReferenceId: {
              message: '@@CONTAINS_INVALID_SITE_REFERENCE_ID',
              displayMessage: $localize`Please enter a valid site reference`,
            },
          };
    }

    return null;
  };

  estateManagementId = (control: AbstractControl): {} => {
    if (control.value && control.value.length > 0) {
      return RegexPatterns.estateManagementId.test(control.value)
        ? null
        : {
            estateManagementId: {
              message: '@@CONTAINS_INVALID_DEVICE_ID',
              displayMessage: $localize`Please enter a valid device ID`,
            },
          };
    }

    return null;
  };

  altVfiEntityId = (control: AbstractControl): {} => {
    if (control.value && control.value.length > 0) {
      return RegexPatterns.altVfiEntityId.test(control.value)
        ? null
        : {
            altVfiEntityId: {
              message: '@@CONTAINS_INVALID_VERIFONE_ID',
              displayMessage: $localize`Please enter a valid Verifone ID`,
            },
          };
    }
    return null;
  };

  maxFileSizeInKb = (input: number, message?: string): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value || !Array.isArray(control.value) || !control.value.length) {
        return null;
      }
      const fileSize = control.value[0].size;
      const fileName = control.value[0].name;
      const isFileValid = Math.ceil(fileSize / 1024) <= input;
      if (isFileValid) {
        return null;
      } else {
        return {
          [fileName]: {
            logo: {
              message: '@@FILE_SIZE_EXCEEDED',
              displayMessage:
                message || $localize`The size of the file exceeds ${input}:max_size: KB.`,
            },
          },
        };
      }
    };
  };

  salesChannels = (validSalesChannels: string[]): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value || !control.value.length) {
        return null;
      }
      const invalidChannels = control.value.filter(
        (salesChannel) => !validSalesChannels.includes(salesChannel),
      );
      if (!invalidChannels.length) {
        return null;
      } else {
        return {
          logo: {
            message: '@@INVALID_SALES_CHANNEL',
            displayMessage: $localize`Invalid sales channels: ${invalidChannels.join(', ')}`,
          },
        };
      }
    };
  };

  // bundle name: start with letters
  bundleName = (control: AbstractControl): {} => {
    if (control.value && control.value.length > 0) {
      return RegexPatterns.bundleName.test(control.value)
        ? null
        : {
            bundleName: {
              message: '@@INVALID_BUNDLE_NAME',
              displayMessage: $localize`Bundle name should start with a letter`,
            },
          };
    }
    return null;
  };

  semiColonSeparatedEmailList = (control: AbstractControl): {} => {
    if (control.value && control.value.length > 0) {
      return RegexPatterns.semiColonSeparatedEmailAddresses.test(control.value)
        ? null
        : {
            bundleName: {
              message: '@@INVALID_RECEIPIENT_LIST',
              displayMessage: $localize`Please provide email addresses separated by semi colon`,
            },
          };
    }
    return null;
  };

  amountNotGreaterThan = (amountControl: AbstractControl, message?: string): ValidatorFn => {
    return (control) =>
      Number.isFinite(amountControl?.value)
        ? this.maxAmount(amountControl?.value, message)(control)
        : null;
  };

  alphanumericWithSpaceAndDash = (control: AbstractControl): {} =>
    this.characters(RegexPatterns.alphanumericWithSpaceAndDash)(control);

  nonNegativeNumber = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.nonNegativeNumber.test(control.value)
      ? null
      : {
          double: {
            message: '@@CONTAINS_NEGATIVE_NUMBER',
            displayMessage: $localize`Please only use positive number`,
          },
        };
  };

  alphanumeric = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.alphanumeric.test(control.value)
      ? null
      : {
          alphanumeric: {
            message: '@@CONTAINS_NON_ALPHANUMERIC',
            displayMessage: $localize`Please use only alphanumeric characters`,
          },
        };
  };

  colorHexa = (control: AbstractControl): {} => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.colorHexa.test(control.value)
      ? null
      : {
          double: {
            message: '@@INVALID_HEXADECIMAL_COLOR',
            displayMessage: $localize`Invalid color code`,
          },
        };
  };
}
