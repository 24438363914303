<div class="columns is-mobile is-centered is-vcentered is-full-height-with-navbar">
  <div class="column is-full-mobile is-10-tablet is-one-third-desktop content">
    <h2 class="title is-3" data-e2e="title">
      {{ title }}
    </h2>
    <p data-e2e="description">
      {{ description }}
    </p>
    <ng-content></ng-content>
  </div>
</div>
