import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[helpersAttributes]',
})
export class AttributesDirective implements OnChanges {
  @Input() helpersAttributes: { [key: string]: string };

  constructor(private elementRef: ElementRef, private renderer2: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.helpersAttributes) {
      this.setAttributes(changes);
    }
  }

  private setAttributes(changes: SimpleChanges): void {
    if (changes.helpersAttributes.previousValue) {
      const keys = Object.keys(changes.helpersAttributes.previousValue);
      keys.forEach((key) => this.renderer2.removeAttribute(this.elementRef.nativeElement, key));
    }

    if (changes.helpersAttributes.currentValue) {
      Object.entries<string>(changes.helpersAttributes.currentValue).forEach(([key, value]) => {
        this.renderer2.setAttribute(this.elementRef.nativeElement, key, value);
      });
    }
  }
}
