<div class="error-page">
    <div class="error-page-content">
        <div class="box-user-deleted box">
            <h2 class="title is-3 has-text-left" i18n>User deleted</h2>
            <p class="has-text-left" i18n>This user account was permanently deleted.</p>
            <div class="div-links">
                <p><i class="arrow has-border-primary right"></i> <a (click)="navigateUsers()" class="text-redirection has-text-primary" i18n> See all users </a></p>
                <p><i class="arrow has-border-primary right"></i> <a (click)="navigateDashboard()" class="text-redirection has-text-primary" i18n> See dashboard </a></p>
            </div>
        </div>
    </div>
</div>
