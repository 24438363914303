export const vhqOperations = {
  "Objects": [
      {
          "Object": "Device",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vdev:a|vdev:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vdev:a|vdev:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vdev:a|vdev:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vdev:a|vdev:d"
              }
          ]
      },
      {
          "Object": "Advanced Device Management",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vadm:r"
              },
              {
                  "Operation": "Update",
                  "Permission": "vadm:u"
              }
          ]
      },
      {
          "Object": "Device Hierarchies",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vdhy:a|vdhy:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vdhy:a|vdhy:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vdhy:a|vdhy:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vdhy:a|vdhy:d"
              }
          ]
      },
      {
          "Object": "Device Groups",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vdgp:a|vdgp:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vdgp:a|vdgp:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vdgp:a|vdgp:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vdgp:a|vdgp:d"
              }
          ]
      },
      {
          "Object": "Software Configurations",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vsfc:a|vsfc:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vsfc:a|vsfc:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vsfc:a|vsfc:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vsfc:a|vsfc:d"
              }
          ]
      },
      {
          "Object": "Advanced Software Management",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vasm:a|vasm:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vasm:a|vasm:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vasm:a|vasm:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vasm:a|vasm:d"
              }
          ]
      },
      {
          "Object": "Device Software Configuration",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vdsc:a|vdsc:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vdsc:a|vdsc:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vdsc:a|vdsc:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vdsc:a|vdsc:d"
              }
          ]
      },
      {
          "Object": "Hierarchy Software Configuration",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vhsc:a|vhsc:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vhsc:a|vhsc:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vhsc:a|vhsc:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vhsc:a|vhsc:d"
              }
          ]
      },
      {
          "Object": "Device Download Management",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vddl:a|vddl:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vddl:a|vddl:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vddl:a|vddl:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vddl:a|vddl:d"
              }
          ]
      },
      {
          "Object": "Content Configurations",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vctc:a|vctc:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vctc:a|vctc:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vctc:a|vctc:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vctc:a|vctc:d"
              }
          ]
      },
      {
          "Object": "Device Content Management",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vdct:a|vdct:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vdct:a|vdct:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vdct:a|vdct:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vdct:a|vdct:d"
              }
          ]
      },
      {
          "Object": "Diagnostics Configurations",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vdgc:a|vdgc:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vdgc:a|vdgc:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vdgc:a|vdgc:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vdgc:a|vdgc:d"
              }
          ]
      },
      {
          "Object": "Device Diagnostics Management",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vddm:a|vddm:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vddm:a|vddm:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vddm:a|vddm:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vddm:a|vddm:d"
              }
          ]
      },
      {
          "Object": "Parameters Configuration",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vprc:a|vprc:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vprc:a|vprc:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vprc:a|vprc:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vprc:a|vprc:d"
              }
          ]
      },
      {
          "Object": "Parameter Activation",
          "Operations": [
              {
                  "Operation": "Update",
                  "Permission": "vpat:u"
              }
          ]
      },
      {
          "Object": "Advanced Parameter Management",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vapm:a|vapm:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vapm:a|vapm:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vapm:a|vapm:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vapm:a|vapm:d"
              }
          ]
      },
      {
          "Object": "Basic Parameter Management",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vbpm:a|vbpm:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vbpm:a|vbpm:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vbpm:a|vbpm:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vbpm:a|vbpm:d"
              }
          ]
      },
      {
          "Object": "Parameter Templates",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vptm:a|vptm:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vptm:a|vptm:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vptm:a|vptm:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vptm:a|vptm:d"
              }
          ]
      },
      {
          "Object": "Online Keys Configurations",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vokc:a|vokc:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vokc:a|vokc:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vokc:a|vokc:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vokc:a|vokc:d"
              }
          ]
      },
      {
          "Object": "Device Online Key Management",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vdok:a|vdok:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vdok:a|vdok:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vdok:a|vdok:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vdok:a|vdok:d"
              }
          ]
      },
      {
          "Object": "Offline Keys Library and Device Offline Keys Mgt",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vdfk:a|vdfk:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vdfk:a|vdfk:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vdfk:a|vdfk:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vdfk:a|vdfk:d"
              }
          ]
      },
      {
          "Object": "Device Alerts Configurations",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vdac:a|vdac:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vdac:a|vdac:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vdac:a|vdac:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vdac:a|vdac:d"
              }
          ]
      },
      {
          "Object": "Device Alerts",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vdal:r"
              },
              {
                  "Operation": "Update",
                  "Permission": "vdal:u"
              }
          ]
      },
      {
          "Object": "Device Message Management",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vdmm:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vdmm:c"
              }
          ]
      },
      {
          "Object": "Tenant",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vtnt:a|vtnt:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vtnt:a|vtnt:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vtnt:a|vtnt:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vtnt:a|vtnt:d"
              }
          ]
      },
      {
          "Object": "Location",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vloc:a|vloc:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vloc:a|vloc:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vloc:a|vloc:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vloc:a|vloc:d"
              }
          ]
      },
      {
          "Object": "Rollouts",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vrlt:a|vrlt:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vrlt:a|vrlt:c"
              },
              {
                  "Operation": "Update",
                  "Permission": "vrlt:a|vrlt:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vrlt:a|vrlt:d"
              }
          ]
      },
      {
          "Object": "System Settings",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vsst:r"
              },
              {
                  "Operation": "Update",
                  "Permission": "vsst:u"
              }
          ]
      },
      {
          "Object": "Troubleshooting",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vtrb:r"
              }
          ]
      },
      {
          "Object": "Device Care",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vdcr:r"
              }
          ]
      },
      {
          "Object": "Estate Care",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vecr:r"
              }
          ]
      },
      {
          "Object": "Users",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vusr:a|vusr:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vusr:a|vusr:u"
              },
              {
                  "Operation": "Update",
                  "Permission": "vusr:a|vusr:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vusr:a|vusr:u"
              }
          ]
      },
      {
          "Object": "Customers",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vcst:r"
              },
              {
                  "Operation": "Update",
                  "Permission": "vcst:u"
              }
          ]
      },
      {
          "Object": "Global/Bulk Operations",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vblk:r"
              },
              {
                  "Operation": "Update",
                  "Permission": "vblk:u"
              }
          ]
      },
      {
          "Object": "Import",
          "Operations": [
              {
                  "Operation": "Execute",
                  "Permission": "vimp:c"
              }
          ]
      },
      {
          "Object": "Export",
          "Operations": [
              {
                  "Operation": "Execute",
                  "Permission": "vexp:c"
              }
          ]
      },
      {
          "Object": "Reports Configuration (Subscriptions and Custom Reports)",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vrtc:a|vrtc:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vrtc:a|vrtc:u"
              },
              {
                  "Operation": "Update",
                  "Permission": "vrtc:a|vrtc:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vrtc:a|vrtc:u"
              }
          ]
      },
      {
          "Object": "Reports/Charts",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vrpt:r"
              }
          ]
      },
      {
          "Object": "Audits",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vadt:r"
              }
          ]
      },
      {
          "Object": "Asset Tracking Management",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vatm:r"
              }
          ]
      },
      {
          "Object": "Asset Tracking File Chain of Custody",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vatc:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vatc:c"
              }
          ]
      },
      {
          "Object": "Asset Tracking Inspection",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vati:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vati:c"
              }
          ]
      },
      {
          "Object": "Asset Tracking Shipment receipt",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vats:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vats:c"
              }
          ]
      },
      {
          "Object": "APIs",
          "Operations": [
              {
                  "Operation": "View",
                  "Permission": "vapi:a|vapi:r"
              },
              {
                  "Operation": "Add",
                  "Permission": "vapi:a|vapi:u"
              },
              {
                  "Operation": "Update",
                  "Permission": "vapi:a|vapi:u"
              },
              {
                  "Operation": "Delete",
                  "Permission": "vapi:a|vapi:u"
              }
          ]
      }
  ]
}
