export enum ChallengeIndicator {
  NoPreference = '01',
  NoChallengeRequested = '02',
  ChallengeRequested_3DS_RequestorPreference = '03',
  ChallengeRequested_Mandate = '04',
  NoChallengeRequested_TransactionalRiskAnalysis = '05',
  NoChallengeRequested_DataShareOnly = '06',
  NoChallengeRequested_StrongConsumerAuthentication = '07',
  NoChallengeRequested_UtiliseTrustlistExemption = '08',
  ChallengeRequested_TrustlistPromptRequested = '09',
  UsedToCallTheCBScoringPlatform = '90',
}
