export enum PaymentSummaryState {
  NotAuthorised = 'NOT_AUTHORISED',
  AuthenticationRequested = 'AUTHENTICATION_REQUESTED',
  Authorised = 'AUTHORISED',
  PartiallyAuthorised = 'PARTIALLY_AUTHORISED',
  PartiallyCancelled = 'PARTIALLY_CANCELLED',
  Cancelled = 'CANCELLED',
  PartiallySettled = 'PARTIALLY_SETTLED',
  Settled = 'SETTLED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  SettlementRequested = 'SETTLEMENT_REQUESTED',
  Refunded = 'REFUNDED',
  Declined = 'DECLINED',
  TimedOut = 'TIMED_OUT',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Disputed = 'DISPUTED',
}
