import { UserRoles } from '@portal/shared/auth/authorization';
import { RouteName } from './route-name.enum';

const FULL_ACCESS_ROLES = [
  UserRoles.MerchantAdmin,
  UserRoles.VerifoneAdmin,
  UserRoles.SystemOnboardingBackend,

  // Petro
  UserRoles.PetroSiteAdmin,
  UserRoles.PetroSiteUser,
  UserRoles.PetroHelpDeskAdmin,
  UserRoles.MajorOilAdmin,
  UserRoles.MajorOilUser,

  // Marketplace
  UserRoles.EstateOwnerAdmin,
  UserRoles.EstateOwnerSupportAdmin,
  UserRoles.EstateOwnerSupportEngineer,
  UserRoles.EstateOwnerMerchantManager,
  UserRoles.DeveloperAdmin,
  UserRoles.DeveloperSupportAdmin,

  // MOP
  UserRoles.VerifoneSaleManager,
  UserRoles.VerifoneMaster,

  //VHQ
  UserRoles.VerifoneHelpDesk,
];

const READ_ACCESS_ROLES = [
  ...FULL_ACCESS_ROLES,

  UserRoles.MerchantReviewer,
  UserRoles.VerifoneUser,

  // Marketplace
  UserRoles.DeveloperSupportEngineer,
  UserRoles.DeveloperSupportAppReviewer,
  UserRoles.EstateOwnerDeviceAppManager,
  UserRoles.EstateOwnerDeviceAppReviewer,

  // MOP User Roles
  UserRoles.VerifoneSalesRepresentative,
  UserRoles.ResellerRepresentative,
];

const FULL_ACCESS_EXCEPT_SELF_READ_AND_SELF_UPDATE = [
  // VHQ
  UserRoles.ResellerManager,
  UserRoles.PartnerFieldServiceSupervisor,
  UserRoles.VerifoneFieldServiceSupervisor,
];

// eslint-disable-next-line @typescript-eslint/naming-convention
const COMMON_CRUD_ON_EDITnDETAIL = {
  update: [...FULL_ACCESS_ROLES, ...FULL_ACCESS_EXCEPT_SELF_READ_AND_SELF_UPDATE],
  delete: [...FULL_ACCESS_ROLES, ...FULL_ACCESS_EXCEPT_SELF_READ_AND_SELF_UPDATE],
  'self-read': [
    ...READ_ACCESS_ROLES,
    UserRoles.MerchantCashier,
    UserRoles.MerchantSupervisor,
    UserRoles.MerchantUser,
    UserRoles.MerchantUnapproved,
    UserRoles.ProviderWhiteLabelManager,
    UserRoles.VerifoneFinOpsUser,
    UserRoles.VerifoneSettlementUser,
    UserRoles.MerchantExternalPartner,
    UserRoles.VerifoneMaster,

    // Petro
    UserRoles.PetroSalesforceAdmin,
    UserRoles.PetroMerchantOnboarder,
    UserRoles.CommanderOnboarder,

    // Marketplace
    UserRoles.DeveloperUser,

    //Petro Partner Roles
    UserRoles.PetroLoyalty,
    UserRoles.PetroMobilePayments,
    UserRoles.PetroBackOffice,
    UserRoles.PetroFuelPriceSign,
    UserRoles.PetroDvr,
    UserRoles.PetroPropietaryCard,
    UserRoles.PetroCommanderEF,
    UserRoles.PetroInternal,
  ],
  'self-update': [
    ...FULL_ACCESS_ROLES,
    UserRoles.MerchantCashier,
    UserRoles.MerchantSupervisor,
    UserRoles.MerchantUser,
    UserRoles.ProviderWhiteLabelManager,
    UserRoles.MerchantUnapproved,
    UserRoles.VerifoneFinOpsUser,
    UserRoles.VerifoneSettlementUser,
    UserRoles.MerchantExternalPartner,
    UserRoles.VerifoneMaster,
    // Marketplace
    UserRoles.DeveloperUser,
  ],
  'app-notification-setting': [
    UserRoles.DeveloperAdmin,
    UserRoles.DeveloperUser,
    UserRoles.VerifoneAdmin,
  ],
};

const SYSTEM_INFORMATION_VIEW = {
  'system-information-view': [
    UserRoles.VerifoneAdmin,
    UserRoles.VerifoneUser,
    UserRoles.VerifoneHelpDesk,
    UserRoles.VerifoneSaleManager,
    UserRoles.VerifoneSalesRepresentative,
    UserRoles.VerifoneFinOpsUser,
    UserRoles.VerifoneSettlementUser,
    UserRoles.VerifoneFieldServiceProvider,
    UserRoles.VerifoneMaster,
  ],
};

export const RoutePermissions = {
  [RouteName.List]: {
    read: [...READ_ACCESS_ROLES, ...FULL_ACCESS_EXCEPT_SELF_READ_AND_SELF_UPDATE],
    'add-organization-group': [
      UserRoles.MerchantAdmin,
      UserRoles.VerifoneMaster,
      UserRoles.VerifoneAdmin,
    ],
  },
  [RouteName.Create]: {
    read: [...FULL_ACCESS_ROLES, ...FULL_ACCESS_EXCEPT_SELF_READ_AND_SELF_UPDATE],
  },
  [RouteName.Details]: {
    read: [...READ_ACCESS_ROLES, ...FULL_ACCESS_EXCEPT_SELF_READ_AND_SELF_UPDATE],
    ...COMMON_CRUD_ON_EDITnDETAIL,
    ...SYSTEM_INFORMATION_VIEW,
    'delete-user': [UserRoles.VerifoneAdmin, UserRoles.MerchantAdmin],
  },
  [RouteName.Edit]: {
    read: [...FULL_ACCESS_ROLES, ...FULL_ACCESS_EXCEPT_SELF_READ_AND_SELF_UPDATE],
    ...COMMON_CRUD_ON_EDITnDETAIL,
    'reset-password': [UserRoles.VerifoneAdmin, UserRoles.MerchantAdmin],
  },
};
