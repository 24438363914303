export type Rsql<Selector, Operator> =
  | RsqlConstraint<Selector, Operator>
  | RsqlConstraint<Selector, Operator>[];

export type RsqlConstraintOf<R extends Rsql<any, any>> = R extends Rsql<infer S, infer O>
  ? RsqlConstraint<S, O>
  : never;

export function formatRsql<Selector, Operator>(rsql: Rsql<Selector, Operator>): string {
  if (Array.isArray(rsql)) return formatAsGroup(rsqlAndGroup(rsql));
  return formatRsqlConstraint(rsql);
}

export function rsqlAndGroup<Selector, Operator>(
  constraints: RsqlConstraint<Selector, Operator>[],
): RsqlGroup<Selector, Operator> {
  return makeRsqlGroup('and', constraints);
}

export function rsqlOrGroup<Selector, Operator>(
  constraints: RsqlConstraint<Selector, Operator>[],
): RsqlGroup<Selector, Operator> {
  return makeRsqlGroup('or', constraints);
}

export function mapRsqlSelectors<S1, O, S2>(
  rsql: Rsql<S1, O>,
  project: (selector: S1) => S2,
): Rsql<S2, O> {
  if (Array.isArray(rsql))
    return rsql.map((constraint) => mapSelectorsInConstraint(constraint, project));
  return mapSelectorsInConstraint(rsql, project);
}

type RsqlConstraint<S, O> = RsqlComparison<S, O> | RsqlGroup<S, O>;

interface RsqlComparison<S, O> {
  selector: S;
  operator: O;
  argument: any;
}

type RsqlGroupType = 'and' | 'or';
interface RsqlGroup<S, O> {
  type?: RsqlGroupType;
  constraints: RsqlConstraint<S, O>[];
}

function isRsqlGroup<S, O>(constraint: RsqlConstraint<S, O>): constraint is RsqlGroup<S, O> {
  return constraint.hasOwnProperty('constraints');
}

function makeRsqlGroup<S, O>(
  type: RsqlGroupType,
  constraints: RsqlConstraint<S, O>[],
): RsqlGroup<S, O> {
  return { type, constraints };
}

function joinRsqlConstraints<S, O>(
  constraints: RsqlConstraint<S, O>[],
  separator: string,
  context?: RsqlGroup<S, O>,
): string {
  return constraints.map((constraint) => formatRsqlConstraint(constraint, context)).join(separator);
}
function formatAsAndGroup<S, O>(group: RsqlGroup<S, O>): string {
  return joinRsqlConstraints(group.constraints, ';', group);
}
function formatAsOrGroup<S, O>(group: RsqlGroup<S, O>, context?: RsqlGroup<S, O>): string {
  const formatted = joinRsqlConstraints(group.constraints, ',', group);
  if (context?.type === 'and') return `(${formatted})`;
  return formatted;
}

function formatAsGroup<S, O>(group: RsqlGroup<S, O>, context?: RsqlGroup<S, O>): string {
  if (group.type === 'or') return formatAsOrGroup(group, context);
  return formatAsAndGroup(group);
}
function formatAsComparison<S, O>(constraint: RsqlComparison<S, O>): string {
  return `${constraint.selector}${constraint.operator}${formatArgument(constraint.argument)}`;
}
function formatArgument(argument: any): string {
  if (Array.isArray(argument)) {
    return `(${argument.join(',')})`;
  }
  return argument;
}

function formatRsqlConstraint<S, O>(
  constraint: RsqlConstraint<S, O>,
  context?: RsqlGroup<S, O>,
): string {
  if (isRsqlGroup(constraint)) return formatAsGroup(constraint, context);
  return formatAsComparison(constraint);
}

function mapSelectorsInConstraint<S1, O, S2>(
  constraint: RsqlConstraint<S1, O>,
  project: (selector: S1) => S2,
): RsqlConstraint<S2, O> {
  if (isRsqlGroup(constraint))
    return {
      ...constraint,
      constraints: constraint.constraints.map((c) => mapSelectorsInConstraint(c, project)),
    };
  return { ...constraint, selector: project(constraint.selector) };
}
