<div
  [ngClass]="{ 'is-active': activeState, modal: true }"
  [ngStyle]="zIndex && { zIndex: zIndex }"
  cdkTrapFocus="{{ activeState }}"
>
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="card">
      <div class="card-content">
        <div class="is-flex is-justify-content-space-between">
          <p data-e2e="modal-title" class="title pt-1">
            <ng-content select="[title]"></ng-content>
          </p>
          <button
            type="button"
            #closeButton
            class="delete is-medium"
            aria-label="close"
            data-e2e="modal-close-button"
            (click)="close()"
            title="Close"
            i18n-title
          ></button>
        </div>
        <p data-e2e="modal-description">
          <ng-content select="[description]"></ng-content>
        </p>
        <div class="control">
          <ng-content select="[modal-content]"></ng-content>
        </div>
        <br />
        <div>
          <ng-content select="[buttons]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
