export enum ToolTipElements {
  Top = 'top',
  Right = 'right',
  Left = 'left',
  Bottom = 'bottom',
  Multiline = 'multiline',
  Offset = 10,
  Delay = 500,
  MultilineLimit = 20,
}
