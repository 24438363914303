<section *ngIf="style$ | async as style" class="hero is-rebrand" [ngClass]="style.background">
  <div class="hero-body">
    <div class="container is-flex is-justify-content-space-between" *ngIf="title$ | async as title">
      <div class="is-flex is-align-items-center">
        <div class="hero-icon" [ngClass]="style.icon"></div>
        <h1 class="title is-1">{{ title }}</h1>
      </div>
    </div>
  </div>
</section>
