import { FiltersList, FilterTypes } from '@portal/shared/ui/filter';

export const MmfiltersList: FiltersList[] = [
  {
    selector: 'appName',
    title: 'Application Name',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'Application Name',
        id: 'appName',
      },
    ],
  },
  {
    selector: 'categories.name',
    title: 'Categories',
    type: FilterTypes.Multi,
    data: [
      { text: 'Advertising and media', id: 'ADVERTISING_AND_MEDIA' },
      { text: 'Alternate payments', id: 'ALTERNATE_PAYMENTS' },
      { text: 'Charity', id: 'CHARITY' },
      { text: 'Customer engagement', id: 'CUSTOMER_ENGAGEMENT' },
      {
        text: 'Customer relationship management',
        id: 'CUSTOMER_RELATIONSHIP_MANAGEMENT',
      },
      { text: 'Employee management', id: 'EMPLOYEE_MANAGEMENT' },
      { text: 'Finance', id: 'FINANCE' },
      { text: 'Inventory management', id: 'INVENTORY_MANAGEMENT' },
      { text: 'Loyalty and promotion', id: 'LOYALTY_AND_PROMOTION' },
      { text: 'Order management', id: 'ORDER_MANAGEMENT' },
      { text: 'Reporting and analytics', id: 'REPORTING_AND_ANALYTICS' },
    ],
  },
  {
    selector: 'paymentModel',
    title: 'Payment Model',
    type: FilterTypes.Multi,
    data: [
      { text: 'Free', id: 'FREE' },
      { text: 'One time', id: 'ONE_TIME' },
      { text: 'Recurring', id: 'RECURRING' },
    ],
  },
  {
    selector: 'modelFamilies.name',
    title: 'Model Family',
    type: FilterTypes.Multi,
    data: [{ text: 'Carbon Mobile', id: 'CarbonMobile' }],
  },
  {
    selector: 'status',
    title: 'Status',
    type: FilterTypes.Multi,
    data: [
      {
        text: 'Registered',
        id: 'REGISTERED',
      },
      { text: 'Submitted', id: 'SUBMITTED' },
      { text: 'In Review', id: 'IN_REVIEW' },
      { text: 'Approved', id: 'APPROVED' },
      { text: 'Disabled', id: 'DISABLED' },
      { text: 'Withdrawn', id: 'WITHDRAWN' },
      { text: 'Rejected', id: 'REJECTED' },
    ],
  },
];
