<div class="dropdown" [class.is-active]="isOpen">
  <div class="dropdown-trigger">
    <button
      [attr.aria-expanded]="isOpen"
      class="button is-chip is-size-7-mobile"
      (click)="toggleFilter()"
      aria-haspopup="true"
      aria-controls="dropdown-menu"
      title="{{ filterName }}"
      type="button"
    >
      <span>{{ filterName }} </span>
      <span class="icon">
        <i class="fas fa-chevron-down" aria-hidden="true"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu" id="dropdown-menu" role="menu">
    <div class="dropdown-content">
      <hr class="dropdown-divider" />
      <div class="dropdown-item">
        <p class="control">
          <input
            class="input"
            type="text"
            [maxLength]="400"
            [(ngModel)]="searchTerm"
            placeholder=""
          />
        </p>
      </div>
      <div class="dropdown-item">
        <button
          class="button is-primary"
          data-e2e="button-filter"
          i18n
          (click)="onFilterApplied()"
          type="button"
          title="Apply filter"
          i18n-title
        >
          Apply filter
        </button>
      </div>
    </div>
  </div>
</div>
