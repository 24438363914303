import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IFilterToApply, ISearchCriteria, SearchBoxComponent } from '@portal/shared/ui/filter';
import { ISearchBoxValue, ISelectInput } from '@portal/shared/ui/filter';
import { FilterUserStatusComponent } from '@portal/shared/ui/filter/src/lib/user-status/user-status.component';
import { IQueryParams } from '@portal/shared/ui/table';
import { isEmpty, orderBy } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { SearchOperator } from '../../enums/search-operator.enum';
import { SearchSelector } from '../../enums/search-selector.enum';
import { HierarchyFilterComponent } from '@portal/shared/ui/filter/src/lib/hierarchy-filter/hierarchy-filter.component';
import { MobileViewService } from '../../../../../../../apps/portal/src/app/services/mobile-view.service';

declare const $localize;

@Component({
  selector: 'portal-filter-user',
  templateUrl: './filter.component.html',
})
export class FilterUserComponent {
  @ViewChild(HierarchyFilterComponent) filterEntityComponent: HierarchyFilterComponent;
  @ViewChild(FilterUserStatusComponent) filterUserStatusComponent: FilterUserStatusComponent;
  @ViewChild(SearchBoxComponent, { read: SearchBoxComponent, static: true })
  searchBox: SearchBoxComponent;
  @Input()
  filter: IQueryParams;

  @Input()
  filterTouched: boolean;

  @Output()
  usersFiltered: EventEmitter<ISearchBoxValue> = new EventEmitter();

  @Output()
  usersFilterReset: EventEmitter<void> = new EventEmitter();

  @Output()
  userFilterChanged: EventEmitter<string> = new EventEmitter();

  searchEntities: ISelectInput[] = orderBy(
    [
      {
        id: SearchSelector.Name,
        text: $localize`Name`,
      },
      {
        id: SearchSelector.Email,
        text: $localize`Email`,
      },
    ],
    'text',
  );

  searchTerm: Subject<string> = new Subject();
  selectedSearchSelector: Subject<string> = new Subject();

  selectedOrganisations = new Map<string, ISelectInput>();
  orgFilterDisplayDetails: ISelectInput = {
    id: SearchSelector.EntityUId,
    text: $localize`Entities`,
  };
  isDeviceMobile$: Observable<boolean>;
  resetEntity$ = new Subject();

  constructor(private mobileViewService: MobileViewService) {
    this.isDeviceMobile$ = this.mobileViewService.mobileDevice;
  }

  focusSearchBox(): void {
    if (this.searchBox) {
      this.searchBox?.setAutoFocusState();
    }
  }

  isKnownSearchEntity(entityKey: string): boolean {
    return this.searchEntities.some((entity: ISelectInput) => {
      return entity.id === entityKey;
    });
  }

  onUserSearched(input: ISearchBoxValue): void {
    // Check for an unknown search entity.
    if (!this.isKnownSearchEntity(input.key)) {
      return;
    }

    if (isEmpty(input.value)) {
      this.userFilterChanged.emit(input.key);
      this.usersFiltered.emit();
    } else {
      if (input.key === SearchSelector.Email) {
        this.filterUserStatusComponent.deselectDeletedStatus();
        this.filterUserStatusComponent.disableDeletedStatus(true);
      } else {
        this.filterUserStatusComponent.disableDeletedStatus(false);
      }
      this.usersFiltered.emit({
        key: input.key,
        value: input.value,
        operator: SearchOperator.Equal,
      });
    }
  }

  resetFilter(): void {
    this.searchTerm.next('');
    this.resetEntity$.next();

    this.selectedOrganisations.clear();
    this.orgFilterDisplayDetails.text = $localize`Organisations`;

    this.usersFilterReset.emit();
    this.userFilterChanged.emit();
    this.filterEntityComponent.removeAllEntities();
    this.filterUserStatusComponent.removeAllEntities();

    this.filterUserStatusComponent.disableDeletedStatus(false);
  }

  onOrgFilterApplied(filter: IFilterToApply): void {
    const values = new Set<string>();
    this.selectedOrganisations.clear();
    if (filter && filter.value) {
      filter.value.forEach((value: ISelectInput) => {
        values.add(value.id);
        this.selectedOrganisations.set(value.id, value);
      });
    }

    if (values.size) {
      this.orgFilterDisplayDetails.text = $localize`Organisations (${values.size})`;
      this.usersFiltered.emit({
        key: SearchSelector.EntityUId,
        operator: SearchOperator.In,
        value: `${Array.from(values).join(',')}`,
      });
    } else {
      this.orgFilterDisplayDetails.text = $localize`Organisations`;
      this.onSearchEntityRemoved(SearchSelector.EntityUId);
      this.usersFiltered.emit();
    }
  }

  onSearchEntityRemoved(key: string): void {
    this.userFilterChanged.emit(key);
    this.filterUserStatusComponent.disableDeletedStatus(false);
  }

  formatSelectedEntities(): Map<string, ISearchCriteria> {
    const filterFormatted = new Map<string, ISearchCriteria>();
    if (this.filter.searchCriteria.has(SearchSelector.EntityUId)) {
      filterFormatted.set(
        SearchSelector.EntityId,
        this.filter.searchCriteria.get(SearchSelector.EntityUId),
      );
    }

    return filterFormatted;
  }

  onUserStatusFilterUpdated(filter: IFilterToApply): void {
    this.usersFiltered.emit({
      key: SearchSelector.Status,
      value: (filter.value || []).map((v) => v.id).join(','),
      operator: SearchOperator.In,
    });

    if (filter.toRemove) {
      this.onSearchEntityRemoved(SearchSelector.Status);
      this.usersFiltered.emit();
    }
  }

  searchBoxInitComplete(): void {
    this.searchEntities.forEach((entity) => {
      if (this.filter.searchCriteria.has(entity.id)) {
        this.selectedSearchSelector.next(entity.id);
        this.searchTerm.next(this.filter.searchCriteria.get(entity.id).argument);
      }
    });
  }
}
