import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SingleDropdownModule } from '@portal/shared/ui/filter/src/lib/components/single-dropdown/single-dropdown.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginationComponent } from './pagination/pagination.component';
import { GetItemPipe } from './pipes/get-item/get-item.pipe';
import { SortButtonComponent } from './sort-button/sort-button.component';
import { TableComponent } from './table/table.component';
import { TreeTableComponent } from './tree-table/tree-table.component';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, NgxPaginationModule, SingleDropdownModule],

  declarations: [
    TableComponent,
    SortButtonComponent,
    PaginationComponent,
    GetItemPipe,
    TreeTableComponent,
  ],
  exports: [
    TableComponent,
    SortButtonComponent,
    PaginationComponent,
    GetItemPipe,
    TreeTableComponent,
  ],
})
export class TableModule {}
