export enum TransactionInstrumentType {
  MobilePay = 'MOBILEPAY',
  Swish = 'SWISH',
  Vipps = 'VIPPS',
  VenmoQR = 'Venmo',
  Cheque = 'CHEQUE',
  Klarna = 'KLARNA',
  Alipay = 'ALIPAY_GB',
  Wechat = 'WECHAT_GB',
  Affirm = 'AFFIRM',
  OPOnlinePayment = 'OP_ONLINE_PAYMENT',
  Blik = 'BLIK',
}
