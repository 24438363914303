export enum FilterTypes {
  Search = 'Search',
  MultipleSearch = 'MultipleSearch',
  Multi = 'Multi',
  Single = 'Single',
  DatePicker = 'DatePicker',
  DateRange = 'DateRange',
  Entity = 'Entity',
  Hierarchy = 'Hierarchy',
  TreeFilter = 'TreeFilter',
  Country = 'Country',
  Toggle = 'Toggle',
  ButtonGroup = 'buttonGroup',
  IndependentMulti = 'IndependentMulti',
}
