<label class="field">
  <div class="label" data-e2e="textControl-label">
    <span>{{ name }}</span>
    &nbsp;<span *ngIf="optional" class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
  </div>
  <div class="control has-character-count">
    <input
      [formControl]="control"
      [attr.data-e2e]="e2eName"
      [ngClass]="{ 'is-danger': invalid }"
      [placeholder]="placeholder"
      type="text"
      class="input"
    />
    <portal-form-error [formInput]="control" [errorId]="name"></portal-form-error>
    <span class="character-count" [ngClass]="{ 'has-text-danger': invalid }">
      {{ control.value.length }}
      <span class="character-count__divider"> / </span>
      {{ maxLength }}
    </span>
  </div>
</label>
