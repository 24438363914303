import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SrcBlobDirective } from './blob/src-blob.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [SrcBlobDirective],
  exports: [SrcBlobDirective],
})
export class SharedUiBlobModule {}
