import environment from '@environments';
import { UserRoles } from '@portal/shared/auth/authorization/src';
import { FiltersList, FilterTypes } from '@portal/shared/ui/filter';
import { ITableConfig } from '@portal/shared/ui/table';
import { ACQUIRER_NAME_FILTER, GET_FILTER_CONFIG, GET_TABLE_CONFIG } from './shared-configs';

export const BUNDLES_TABLE_CONFIG = (role): ITableConfig => {
  return TABLE_CONFIG;
};

export const BUNDLES_FILTER_CONFIG = (role): FiltersList[] => {
  return FILTER_CONFIG;
};

export const GET_TABLE_CONFIG_BUNDLES = (role): ITableConfig => {
  let table_config: ITableConfig;
  return role.includes(UserRoles.EstateOwnerAdmin) ||
    role.includes(UserRoles.EstateOwnerDeviceAppManager)
    ? (table_config = TABLE_CONFIG)
    : (table_config = TABLE_CONFIG_MM);
};

export const GET_FILTER_CONFIG_BUNDLES = (role): FiltersList[] => {
  let filter_config: FiltersList[];
  return role.includes(UserRoles.EstateOwnerAdmin) ||
    role.includes(UserRoles.EstateOwnerDeviceAppManager)
    ? (filter_config = FILTER_CONFIG)
    : (filter_config = FILTER_CONFIG_MM);
};

const TABLE_CONFIG: ITableConfig = {
  columns: [
    { key: 'name', label: 'Name', isSortable: true },
    { key: 'mcc_code', label: 'Bundle Tag', isSortable: true },
    { key: 'version', label: 'Version', isSortable: true },
    { key: 'one_time_price', label: 'One time price', isSortable: true },
    { key: 'monthly_price', label: 'Monthly price', isSortable: true },
    { key: 'apps_count', label: 'Apps Count', isSortable: true },
  ],
  rowIdKey: 'entityUid',
  rowLabelKey: 'name',
};

const TABLE_CONFIG_MM: ITableConfig = {
  columns: [
    { key: 'eo_name', label: 'Acquirer name', isSortable: true },
    { key: 'name', label: 'Name', isSortable: true },
    { key: 'mcc_code', label: 'Bundle Tag', isSortable: true },
    { key: 'version', label: 'Version', isSortable: true },
    { key: 'one_time_price', label: 'One time price', isSortable: true },
    { key: 'monthly_price', label: 'Monthly price', isSortable: true },
    { key: 'apps_count', label: 'Apps count', isSortable: true },
  ],
  rowIdKey: 'entityUid',
  rowLabelKey: 'name',
};

const FILTER_CONFIG: FiltersList[] = [
  {
    selector: 'time_created',
    title: 'Deploy Value',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'Bundle Tag',
        id: 'mcc_code',
      },
    ],
  },
];

const FILTER_CONFIG_MM: FiltersList[] = [
  {
    selector: 'time_created',
    title: 'Deploy Value',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'Bundle Tag',
        id: 'mcc_code',
      },
    ],
  },
  ACQUIRER_NAME_FILTER,
];

export const BUNDLES_CONFIG = {
  report_id: environment.REPORTS['application-Installsation-status'],
  table: GET_TABLE_CONFIG_BUNDLES,
  filters: GET_FILTER_CONFIG_BUNDLES,
};
