<marketplace-tree-table
  [items]="groups"
  (selectedChange)="groupSelectionChanged($event)"
  [tableConfig]="outerTableConfig"
  [defaultSelection]="groupDefaultSelection"
  loadingMessage="Loading items"
  i18n-loadingMessage="Loading items"
  [loading$]="loading$"
>
  <ng-template #lineHeader let-item>
    <portal-table
      [items]="item.items"
      (selectedChange)="selectionChanged($event, item)"
      [tableConfig]="tableConfig"
      [defaultSelection]="item.defaultSelection"
    >
    </portal-table>
  </ng-template>
</marketplace-tree-table>

<portal-pagination
  [itemsPerPage]="limit"
  [totalItems]="size"
  [pageSizeOptions]="[10, 25]"
  (paginationChanged)="onPagination($event)"
></portal-pagination>
