import { Injectable } from '@angular/core';
import { ISelectInput } from '@portal/shared/ui/filter';

import sortBy from 'lodash-es/sortBy';
import map from 'lodash-es/map';
import { SupportedProcessorSettingsService } from '@apps/portal/src/app/services/supported-processor-settings.service';
import { CurrencyService, ICurrency } from '@apps/portal/src/app/core/core.module';

@Injectable()
export class Currencies {
  list: ISelectInput[];
  currencies: ICurrency[];

  constructor(
    public currencyService: CurrencyService,
    private supportedProcessorSettingsService: SupportedProcessorSettingsService,
  ) {
    this.currencies = this.currencyService.currencies.filter((pt) => {
      return pt.code !== 'USS';
    });
    this.toList();
  }

  toList(currencies = this.currencies): void {
    const list = map(currencies, (value: ICurrency) => {
      return {
        id: value.code,
        text: `${value.name} (${value.code})`,
      };
    });
    this.list = sortBy(list, ['text']);
  }

  getSupportedCurrencies(): ISelectInput[] {
    const supportedProcessorSettings =
      this.supportedProcessorSettingsService.getSupportedProcessorSettingsFromSessionStorage();
    if (supportedProcessorSettings?.currenciesSupported?.length) {
      const supportedCurrencies = this.currencies.filter((currency) => {
        return supportedProcessorSettings.currenciesSupported.includes(currency.code);
      });
      this.toList(supportedCurrencies);
    }
    return this.list;
  }
}
