import { Component, OnInit } from '@angular/core';
import { ButtonGroupBaseFilterDirective } from '../button-group-base-filter.directive';

@Component({
  selector: 'portal-button-group-single',
  templateUrl: './button-group-single.component.html',
})
export class ButtonGroupSingleComponent extends ButtonGroupBaseFilterDirective implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
