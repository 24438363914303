import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AddressType,
  EntityType,
  IAddress,
  IBankAccount,
  IMerchantProcessingSettings,
  IOrganisation,
  IPaymentContract,
  IPoiCutoverConfiguration,
  IReceiptContractCreatePayload,
  PoiCutoverStrategy,
  TemplateParameters,
} from '@portal/entity-services/interfaces';
import { AuthorizationService } from '@portal/shared/auth/authorization/src';
import { RestrictPermissionService } from '@portal/shared/auth/authorization/src/lib/services/restrict-permission.service';
import { CountryService, ICountry } from '@portal/shared/helpers';
import { ToastService } from '@portal/shared/ui/toast';

import compact from 'lodash-es/compact';
import difference from 'lodash-es/difference';
import differenceBy from 'lodash-es/differenceBy';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import omit from 'lodash-es/omit';
import omitBy from 'lodash-es/omitBy';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, finalize, map, switchMap } from 'rxjs/operators';
import { organisationsPermissions } from '../../routing/route-permissions.const';
import { OrganisationService } from '../../services/organisation.service';
import { TemplateParametersService } from '../../services/template-parameters.service';
import { CombinedOrgTemplateParameters } from '../../interfaces/combined-org-template-parameters';
import { PointInteractionService } from '@portal/entity-services/points-of-interaction/src/lib/services/point-interaction.service';
import { ReportEngineService } from '@apps/portal/src/app/modules/report-engine/services/report-engine.service';
import { ISftpConnection } from '@apps/portal/src/app/shared/report-engine/interfaces/sftp-connection.interface';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';
import { Features } from 'environments/enums/features.enum';
import { ReceiptNotificationType } from '../../enums/receipt-notification-type.enum';
import { ReceiptTemplateType } from '../../enums/receipt-template-type.enum';
import { ReceiptAttachmentType } from '@portal/entity-services/interfaces/src/lib/organisations/enums/receipt-attachment-type.enum';
import { ReceiptProvider } from '../../enums/receipt-provider.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'portal-update-organisation',
  templateUrl: './update.component.html',
})
export class UpdateOrganisationComponent implements OnInit, OnDestroy {
  loading = false;
  organisation: IOrganisation;
  organisations: IOrganisation[] = [];
  paymentContracts: IPaymentContract[];
  organisationId: string;
  countries: ICountry[];
  bankAccountsValue: IBankAccount[] = [];
  canEditWhiteLabel: boolean;
  isWhiteLabelFeatureActiveForOrg: boolean;
  templateParameters: TemplateParameters;
  poiCutoverConfigurations: IPoiCutoverConfiguration[];
  isSftpDeliveryEnabled: boolean;
  getOrganization$: any;
  getSftpConnectionName$: any;
  rootEntityIdForParentUpdate: string;
  trashCanOrgsPresent: IOrganisation[];
  merchantCompanyEntityCount: number;

  constructor(
    private organisationService: OrganisationService,
    private countryService: CountryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private title: Title,
    private liveAnnouncer: LiveAnnouncer,
    private authorizationService: AuthorizationService,
    private templateParametersService: TemplateParametersService,
    private restrictPermissionService: RestrictPermissionService,
    private poiService: PointInteractionService,
    private reportEngineService: ReportEngineService,
    private featureToggleService: FeatureToggle,
  ) {}

  ngOnInit(): void {
    this.checkWhiteLabelPermissions();
    this.isSftpDeliveryEnabled = this.featureToggleService.isFeatureActive(Features.SftpDelivery);
    this.countries = this.countryService.countries;
    this.organisationId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getPoiCutOverConfigs();
    if (this.organisationId) {
      this.loading = true;
      forkJoin([
        this.getOrganisation(),
        this.isSftpDeliveryEnabled ? this.getAllSftpConnectionNames() : of(null),
        this.organisationService.getEntityIdForParentUpdate(this.organisationId),
        this.organisationService.getLightWeightWithQuery(
          {
            labels: 'TRASHCAN',
          },
          false,
          0,
        ),
      ])
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe(
          ([
            { org, templateParameters },
            connection,
            { rootEntityId, merchantCompanyEntityCount },
            trashCanOrgs,
          ]) => {
            this.rootEntityIdForParentUpdate = rootEntityId;
            this.merchantCompanyEntityCount = merchantCompanyEntityCount;
            this.trashCanOrgsPresent = trashCanOrgs;
            this.organisation = { ...org, sftpConnection: connection?.connectionName };
            this.title.setTitle($localize`:Page title|:Edit ${this.organisation.name}`);
            this.liveAnnouncer.announce($localize`Navigated to ${this.title.getTitle()}`);
            if (org.parentEntity) {
              this.organisations = [org.parentEntity];
            }
            this.getBankAccounts();
            if (templateParameters) {
              this.templateParameters = templateParameters;
            }
          },
        );
    }
  }

  ngOnDestroy(): void {
    this.templateParametersService.cleanInitialParameters();
  }

  getOrganisation(): Observable<CombinedOrgTemplateParameters> {
    return this.organisationService
      .getByKeyWithAdditionalInfo(this.organisationId)
      .pipe(
        switchMap((organisation) =>
          this.templateParametersService.getCombinedOrgWithTemplateParameters(
            organisation,
            this.canEditWhiteLabel,
            true,
          ),
        ),
      );
  }

  getPoiCutOverConfigs(): void {
    this.poiService
      .getPoiCutoverConfigurations(PoiCutoverStrategy.FlexibleBatch)
      .pipe(
        catchError(() => {
          return of([]);
        }),
        untilDestroyed(this),
      )
      .subscribe((response) => {
        this.poiCutoverConfigurations = response;
      });
  }

  getBankAccounts(): void {
    this.organisationService
      .getAccounts(this.organisation)
      .pipe(untilDestroyed(this))
      .subscribe((bankAccounts) => {
        this.bankAccountsValue = bankAccounts;
      });
  }

  addAddress(addresses: IAddress[] = []): Observable<IAddress>[] {
    return this.organisationService.addAddresses(addresses, this.organisation.entityUid);
  }

  onFormValidated(formValue: IOrganisation): void {
    const formData = this.formValueToRequestData(formValue);
    this.executeCollectionOfRequests(formData);
  }

  /**
   * Returns fields that are redundant for updating organisation
   */
  getNotRelatedToEntityFields(): string[] {
    const notRelatedToEntityFields = [
      'addresses',
      'contacts',
      'bankAccounts',
      'businessInformation',
      'businessType',
      'country',
      'createdDate',
      'modifiedDate',
      'formattedTimezone',
      'objectType',
      'parentEntity',
      'paymentContracts',
      'version',
      'isBillingAddressDisplayed',
      'isShippingAddressDisplayed',
      'isTradingAddressDisplayed',
      'isShippingAddressSectionDisplayed',
      'isTradingAddressSectionDisplayed',
      'features',
      'initialFeatures',
      'templateParameters',
      'isPaymentDocumentSectionDisplayed',
      'receiptProvider',
      'isReceiptProviderInvoice4U',
      'receiptContract',
      'apiToken',
    ];
    return notRelatedToEntityFields;
  }

  compareEntities(entity1: IOrganisation, entity2: IOrganisation): boolean {
    return isEqual(
      omitBy(entity2, (value, prop) => this.getNotRelatedToEntityFields().includes(prop) || !value),
      omitBy(entity1, (value, prop) => this.getNotRelatedToEntityFields().includes(prop) || !value),
    );
  }

  private getAllSftpConnectionNames(): Observable<ISftpConnection> {
    return this.reportEngineService.getAllSftpConnectionDetails().pipe(
      map((response) => {
        const selectedConnection = response.find(
          (connection) => connection.entityUid === this.organisationId,
        );
        return selectedConnection;
      }),
    );
  }

  private executeCollectionOfRequests(formData: IOrganisation): void {
    const getAddressByType = (type: AddressType): IAddress =>
      [...formData.addresses, ...this.organisation.addresses].find(
        ({ addressType }) => addressType === type,
      );

    const billingAddress = getAddressByType(AddressType.Billing);
    const shippingAddress = getAddressByType(AddressType.Delivery);
    const tradingAddress = getAddressByType(AddressType.Trading);
    const entityAddressesToRemove: Set<string> = new Set<string>();

    if (!formData.isBillingAddressDisplayed && !isEmpty(billingAddress)) {
      entityAddressesToRemove.add(billingAddress.addressUid);
    }

    if (!formData.isShippingAddressSectionDisplayed && !isEmpty(shippingAddress)) {
      entityAddressesToRemove.add(shippingAddress.addressUid);
    }

    if (!formData.isTradingAddressSectionDisplayed && !isEmpty(tradingAddress)) {
      entityAddressesToRemove.add(tradingAddress.addressUid);
    }

    if (formData.isBillingAddressDisplayed && !isEmpty(billingAddress)) {
      if (formData.isShippingAddressSectionDisplayed && !formData.isShippingAddressDisplayed) {
        formData.addresses[1] = {
          ...billingAddress,
          addressUid: shippingAddress?.addressUid || '',
          addressType: AddressType.Delivery,
        };
      }

      if (formData.isTradingAddressSectionDisplayed && !formData.isTradingAddressDisplayed) {
        formData.addresses[2] = {
          ...billingAddress,
          addressUid: tradingAddress?.addressUid || '',
          addressType: AddressType.Trading,
        };
      }
    }

    // no longer timzeone will be required, it will be generated from the entity service.
    delete formData.locale.timezoneId;

    formData.locale.countryCode = entityAddressesToRemove.has(billingAddress?.addressUid)
      ? undefined
      : billingAddress?.country;

    if (!formData.locale.countryCode) {
      delete formData.locale;
    }

    formData.addresses = formData.addresses.filter(
      (address) => !entityAddressesToRemove.has(address.addressUid),
    );

    const addressesToAdd = formData.addresses.filter((address) => address.addressUid === '');

    // These addresses will be updated
    formData.addresses = difference(formData.addresses, addressesToAdd).filter(
      (addressToUpdate) => {
        // addressToUpdate will has addressUid because this array already filtered for adding
        const entityAddress = this.organisation.addresses.find(
          ({ addressUid }) => addressToUpdate.addressUid === addressUid,
        );

        return !this.organisationService.compareAddresses(addressToUpdate, entityAddress);
      },
    );

    const deleteAddressRequests = [...entityAddressesToRemove].map((addressUid) =>
      this.organisationService.deleteAddress(addressUid),
    );

    const bankAccountRequests = [];
    (formData.bankAccounts || []).forEach((bankAccount) => {
      if (!this.bankAccountsValue.includes(bankAccount)) {
        bankAccountRequests.push(
          bankAccount.accountUid
            ? this.organisationService.updateAccountWithBankAccount(bankAccount)
            : this.organisationService.addAccount(bankAccount, this.organisation),
        );
      }
    });
    this.bankAccountsValue
      .map((bankAccount) => bankAccount.accountUid)
      .forEach((accountUid) => {
        if (!formData.bankAccounts?.find((bankAccount) => bankAccount.accountUid === accountUid)) {
          bankAccountRequests.push(this.organisationService.deleteAccount(accountUid));
        }
      });

    const templateParametersRequests = this.getTemplateParametersRequests(formData);

    const businessInformationCall = !isEmpty(formData.businessInformation)
      ? this.organisationService.updateBusinessInformation(
          formData.businessInformation,
          formData.entityUid,
        )
      : undefined;

    // Old contacts and the contacts that are not including in formData.contacts for updating
    // Addresses from contacts removed
    const contactsToDelete = [];
    this.organisation.contacts.forEach((organisationContact) => {
      if (
        !formData.contacts.find((contact) => contact.contactUid === organisationContact.contactUid)
      ) {
        contactsToDelete.push(organisationContact);
      }
    });

    const contactsToAdd = formData.contacts.filter((contact) => contact.contactUid === '');
    const contactsToUpdate = difference(formData.contacts, contactsToAdd);
    const changedContactsToUpdate = contactsToUpdate.filter((contactToUpdate) => {
      // addressToUpdate will has addressUid because this array already filtered for adding
      const entityContact = this.organisation.contacts.find(
        ({ contactUid }) => contactToUpdate.contactUid === contactUid,
      );

      return !this.organisationService.compareContacts(contactToUpdate, entityContact);
    });
    const changedAddressesContacts = contactsToUpdate.filter((contact) => {
      const entityContact = this.organisation.contacts.find(
        ({ contactUid }) => contact.contactUid === contactUid,
      );

      if (!entityContact) {
        return false;
      }

      return !this.organisationService.compareAddresses(
        contact.addresses[0],
        entityContact.addresses[0],
      );
    });

    const deleteContactCall = contactsToDelete.map((contact) =>
      this.organisationService.deleteSingleContact(contact.contactUid),
    );

    formData.businessIdentifiers = formData.businessIdentifiers.filter((item) => {
      return !isEmpty(item.value);
    });

    const initialFeatures = formData.initialFeatures;
    const features = formData.features;

    const featuresToAdd = differenceBy(features, initialFeatures, 'featureId');
    const featureDeleteRequests = differenceBy(initialFeatures, features, 'featureId').map(
      (feature) =>
        this.authorizationService.deleteEntityFeature(this.organisationId, feature.featureId),
    );
    const featuresToUpdate = (features || []).filter((feature) => {
      const inheritanceType = initialFeatures.find(
        (i) => i.featureId === feature.featureId,
      )?.inheritanceType;
      return inheritanceType ? inheritanceType !== feature.inheritanceType : false;
    });

    let createOrDeleteReceiptContract$;
    const receiptContractCreationParams: IReceiptContractCreatePayload = {
      provider: formData.receiptProvider || this.organisation?.receiptContract?.provider,
      apiToken: formData.apiToken ?? '',
      allowChildReuse: this.organisationService.allowChildReuse,
    };
    if (formData.receiptProvider === ReceiptProvider.Verifone) {
      receiptContractCreationParams.notifications = [
        {
          type: ReceiptNotificationType.Email,
          attachmentType: formData.receiptAttachmentType ?? ReceiptAttachmentType.None,
        },
      ];
      receiptContractCreationParams.templateConfiguration = {
        receiptMerchantLogo: formData.receiptMerchantLogo?.[0],
        templates: [
          {
            type: ReceiptTemplateType.TemplateId,
            templateId: formData.receiptTemplate,
          },
        ],
      };
      if (formData.customTemplates?.length) {
        formData.customTemplates.forEach((customTemplate) => {
          const fullCustomTemplate = {
            ...customTemplate,
            type: ReceiptTemplateType.TemplateId,
          };
          receiptContractCreationParams.templateConfiguration.templates.push(fullCustomTemplate);
        });
      }
    }

    if (
      (!formData.isPaymentDocumentSectionDisplayed ||
        !formData.receiptProvider ||
        formData.receiptProvider === ReceiptProvider.None) &&
      this.organisation.receiptContract
    ) {
      createOrDeleteReceiptContract$ = this.organisationService.deleteReceiptContract(
        this.organisation.receiptContract?.receiptContractUid,
      );
    }

    if (
      formData.isPaymentDocumentSectionDisplayed &&
      formData.receiptProvider &&
      formData.receiptProvider !== ReceiptProvider.None &&
      !this.organisation.receiptContract
    ) {
      createOrDeleteReceiptContract$ = this.organisationService.createReceiptContract(
        this.organisation.entityUid,
        receiptContractCreationParams,
      );
    }

    if (
      formData.isPaymentDocumentSectionDisplayed &&
      formData.receiptProvider &&
      formData.receiptProvider !== ReceiptProvider.None &&
      this.organisation.receiptContract
    ) {
      createOrDeleteReceiptContract$ = this.organisationService.updateReceiptContract(
        this.organisation.receiptContract.receiptContractUid,
        receiptContractCreationParams,
      );
    }

    if (!this.organisationService.isPoiCrossEntityAccessEditable(formData.entityType)) {
      delete formData.poiCrossEntityAccessAllowed;
    }

    const allCalls: Observable<any>[] = compact([
      !this.compareEntities(formData, this.organisation)
        ? this.organisationService.update(omit(formData, this.getNotRelatedToEntityFields()))
        : of(this.organisation),
      this.organisationService.updateAddresses([...formData.addresses]),
      this.organisationService.updateContactsAddresses(changedAddressesContacts),
      createOrDeleteReceiptContract$,
      this.organisationService
        .addContacts(contactsToAdd, formData.entityUid)
        .pipe(defaultIfEmpty([])),
      this.organisationService.updateContacts(changedContactsToUpdate),
      businessInformationCall,
      ...deleteContactCall,
      ...deleteAddressRequests,
      ...this.addAddress(addressesToAdd),
      ...bankAccountRequests,
      !isEmpty(featuresToAdd)
        ? this.authorizationService.addAppsPermissionsToEntity(this.organisationId, featuresToAdd)
        : null,
      ...featureDeleteRequests,
      !isEmpty(featuresToUpdate)
        ? this.authorizationService.updateEntityAppsPermissions(
            this.organisationId,
            featuresToUpdate,
          )
        : null,
      ...templateParametersRequests,
      ...(formData.entityType === EntityType.MERCHANT_COMPANY
        ? [
            this.organisationService.updateCrossEntityAccessForDescendants(
              this.organisationId,
              formData.poiCrossEntityAccessAllowed,
            ),
          ]
        : []),
      formData.sftpConnection !== this.organisation.sftpConnection
        ? this.reportEngineService.editSftpConnection(
            this.organisation.sftpConnection,
            formData.sftpConnection,
            formData.entityUid,
          )
        : this.reportEngineService.createSftpConnection(
            this.organisation.sftpConnection,
            formData.sftpConnection,
            formData.entityUid,
          ),
    ]);

    forkJoin(allCalls).subscribe(([updatedOrg]: [IOrganisation]) => {
      this.toastService.showToast(
        $localize`${updatedOrg?.name} (${updatedOrg?.entityUid}) is successfully updated`,
      );
      this.router.navigate([`/administration/organisations/${updatedOrg?.entityUid}`]);
    });
  }

  private formValueToRequestData(formValue: IOrganisation): IOrganisation {
    const thresholds = formValue?.merchantChoiceRouting?.thresholds.map((type) => {
      return {
        defaultCardBrand: type?.defaultCardBrand,
        minMcrThreshold: type?.minMcrThreshold,
      };
    });
    const merchantProcessingSettings: IMerchantProcessingSettings = {
      merchantChoiceRouting: {
        enabled: formValue?.merchantChoiceRouting?.enabled,
        thresholds,
      },
    };
    delete formValue.merchantChoiceRouting;
    const requestData: IOrganisation = {
      ...formValue,
      domainName: formValue.domainName?.toLowerCase(),
    };
    if (
      merchantProcessingSettings.merchantChoiceRouting.thresholds &&
      formValue.entityType === EntityType.MERCHANT_SITE
    ) {
      requestData.merchantProcessingSettings = merchantProcessingSettings;
    }
    return requestData;
  }

  private getTemplateParametersRequests(formData: IOrganisation): Observable<any>[] {
    const requests = [];

    if (!formData.loginDomainName) {
      formData.loginDomainName = '';
    }

    if (this.canEditWhiteLabel && this.isWhiteLabelFeatureActiveForOrg) {
      formData.templateParameters.id = formData.domainName;
      formData.templateParameters.text.loginDomainName = formData.loginDomainName;

      if (this.templateParametersService.isTemplateChanged(formData.templateParameters)) {
        requests.push(
          this.templateParametersService.setTemplateParameters(formData.templateParameters),
        );
      }

      if (this.templateParametersService.isLogoChanged(formData.templateParameters.logo)) {
        requests.push(
          this.templateParametersService.setTemplateLogoImage(formData.templateParameters),
        );
      }

      if (this.templateParametersService.isFaviconChanged(formData.templateParameters.favicon)) {
        requests.push(
          this.templateParametersService.setTemplateFaviconImage(formData.templateParameters),
        );
      }
    }

    return requests;
  }

  private checkWhiteLabelPermissions(): void {
    this.canEditWhiteLabel = this.restrictPermissionService.checkPermissions(
      organisationsPermissions.edit,
      ['white-label'],
    );
  }
}
