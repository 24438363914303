import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITokenScope } from '@portal/entity-services/interfaces';
import { finalize } from 'rxjs/operators';
import { TokenScopeService } from '../token-scope.service';

@Component({
  selector: 'portal-update-token-scope',
  templateUrl: './update.component.html',
})
export class UpdateTokenScopeComponent {
  @Input() tokenScope: ITokenScope;
  @Output() closed: EventEmitter<string> = new EventEmitter<string>();
  saving = false;

  constructor(private tokenScopeService: TokenScopeService) {}

  onClose(): void {
    this.closed.emit(this.tokenScope.tokenScopeUid);
  }

  onFormValidated(tokenScope: ITokenScope): void {
    if (tokenScope) {
      this.saving = true;
      this.tokenScopeService
        .updateTokenScope(tokenScope)
        .pipe(
          finalize(() => {
            this.closed.emit(tokenScope.tokenScopeUid);
            this.saving = false;
          }),
        )
        .subscribe();
    }
  }
}
