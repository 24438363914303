import { Directive, Output, EventEmitter, Host, Self, HostListener } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[formGroup][portalFormValidated]',
})
export class FormValidatedDirective {
  @Output() portalFormValidated = new EventEmitter<any>();
  constructor(@Host() @Self() private formGroup: FormGroupDirective) {}

  @HostListener('ngSubmit')
  onNgSubmit(): void {
    this.formGroup.form.markAllAsTouched();
    if (this.formGroup.form.valid) {
      this.portalFormValidated.emit(this.formGroup.form.value);
    }
  }
}
