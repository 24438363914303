import { Component, OnInit, Input } from '@angular/core';
import { ISelectInput } from '../../box/interfaces/select-input.interface';
import { IFilterToApply } from '../../search/interfaces/filter-to-apply.interface';
import { BaseFilter } from '../base-filter';

@Component({
  selector: 'portal-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent extends BaseFilter implements OnInit {
  @Input() applyFilterOnEnter = false;
  @Input() inputType = 'text';
  _searchTerm = '';
  entitySearched: any;
  searchValue = '';
  searchCriteria = '';
  selectChange(): void {}

  ngOnInit(): void {
    if (this.entities && this.entities.length) {
      this.selector = this.entities[0].id;
    }

    this.entityRemoved$.subscribe((entityToRemove: ISelectInput) => {
      // if (entityToRemove.id === this.searchValue) {
      this._searchTerm = '';
      this.searchValue = '';
      this.filterUpdated.emit({
        key: entityToRemove.selector,
        selector: entityToRemove.selector,
        toRemove: true,
      });
      this.filterApplied.emit();
      this.itemToggled.emit();
      // }
    });
  }

  onPressEnter(): void {
    if (this.applyFilterOnEnter) {
      this.onFilterApplied();
    }
  }

  onFilterApplied(): void {
    this._searchTerm = this._searchTerm.trim();
    if (this.isSearchCriteriaOrValueUnchanged()) {
      return;
    }
    if (this.selector) {
      this.searchValue = this._searchTerm;
      this.searchCriteria = this.selector;
      // this.filterUpdated.emit({
      //   key: this.selector,
      //   selector: this.selector,
      //   toRemove: true,
      // });
      this.filterUpdated.emit(this.constructSearchOutput());
      // this.filterApplied.emit();
      this.itemToggled.emit();
      this._searchTerm = '';
    }
  }

  private constructSearchOutput(): IFilterToApply {
    return {
      key: this.selector,
      operator: 'cn',
      argument: this.searchValue,
      selector: this.selector,
    };
  }
  private isSearchCriteriaOrValueUnchanged(): boolean {
    if (this.entities && this.entities.length <= 1) {
      return this._searchTerm === '' || this._searchTerm === this.searchValue;
    }
    return (
      this._searchTerm === '' ||
      (this._searchTerm === this.searchValue && this.searchCriteria === this.selector)
    );
  }
}
