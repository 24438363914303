import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganisationService } from '@portal/entity-services/organisations';
import { IPoi } from '@portal/marketplace/api-interfaces';

@Component({
  selector: 'marketplace-device-page-template',
  templateUrl: './device-page-template.component.html',
  styleUrls: ['./device-page-template.component.scss'],
})
export class DevicePageTemplateComponent implements OnInit {
  @Input() poi: IPoi;
  parentEntityName: string;

  get status(): string {
    if (this.poi && this.poi.device) {
      return this.poi.status;
    } else {
      return 'PENDING';
    }
  }

  get poiIcon(): string {
    if (this.poi && this.poi.device) {
      return `assets/images/devices/${this.poi.device?.model?.deviceType}/details`;
    }

    return `assets/images/devices/PENDING/details`;
  }

  get altVfiPoiId(): string {
    return this.poi ? this.poi.altVfiPoiId : this.route.snapshot.params.id;
  }

  constructor(private route: ActivatedRoute, private organisationService: OrganisationService) {}

  ngOnInit(): void {
    this.organisationService.getEntityByID(this.poi.entity.parentEntityUid).subscribe(entity => {
      this.parentEntityName = entity.name;
    });
  }
}
