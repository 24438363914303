import { Injectable } from '@angular/core';
import { IUser } from '@portal/entity-services/interfaces/src';
import { INavbarItem } from '@portal/shared/ui/layouts/src/lib/top-navbar/interfaces/navbar-item.interface';
import {
  getNavbarItemLink,
  getSubNavItems,
  NavbarItemLink,
} from '../../helpers/navigation-route-helpers';
import { ApplicationRoot } from '../../enums/application-root.enum';
import { PetroService } from './petro.service';
import { MenuRegion } from '../../../../../ui/layouts/src/lib/top-navbar/enum/menu-region.enum';

@Injectable({
  providedIn: 'root',
})
export class PetroNavbarService {
  languageRoute: string;
  currentApplication: ApplicationRoot;

  constructor(private petroService: PetroService) {}

  getPetroNavbarItems(params: {
    currentApplication: ApplicationRoot;
    languageRoute: string;
    user: IUser;
  }): INavbarItem | null {
    this.languageRoute = params.languageRoute;
    this.currentApplication = params.currentApplication;
    const overview = this.getOverviewNavbarItems();
    const viewsItems = this.getViewsNavbarItems();
    const posConfig = this.getPosConfigNavbarItems();

    return getSubNavItems(
      {
        id: 'administration',
        title: $localize`:Main menu navbar group name:C-Site Management`,
        attributes: {
          'data-e2e': 'title-c-site-management',
          groupTitle: $localize`C-Site Management`,
          description: $localize`Configure, manage and monitor every aspect of your convenience store through a single pane of glass with C-Site Management.`,
        },
        region: MenuRegion.PetroConfiguration,
      },
      [overview, viewsItems, posConfig],
    );
  }

  getOverviewNavbarItems(): INavbarItem | null {
    const overviewNavbarItems = {
      title: $localize`:Main menu navbar subgroup name:Overview`,
      attributes: {
        'data-e2e': 'title-overview',
      },
      children: [],
    };
    if (this.petroService.isPetroUser()) {
      overviewNavbarItems.children.push({
        title: $localize`:Main menu navbar item name:Dashboard`,
        attributes: {
          'data-e2e': 'link-petro-dashboard',
        },
        ...this.getItemUrl('/petro-dashboard', [ApplicationRoot.Petro]),
      });
    }

    return overviewNavbarItems;
  }

  getViewsNavbarItems(): INavbarItem | null {
    const viewsNavbarItems = {
      title: $localize`:Main menu navbar subgroup name:Views`,
      attributes: {
        'data-e2e': 'title-views',
      },
      children: [],
    };
    if (this.petroService.showPaymentPartnerConfigurationMenu()) {
      viewsNavbarItems.children.push({
        title: $localize`:Main menu navbar item name:Table Views`,
        attributes: {
          'data-e2e': 'link-views',
        },
        ...this.getItemUrl('/configuration/tables', [ApplicationRoot.Petro]),
      });
    }

    return viewsNavbarItems;
  }

  getPosConfigNavbarItems(): INavbarItem | null {
    const posConfig = {
      title: $localize`:Main menu navbar subgroup name:Configuration`,
      attributes: {
        'data-e2e': 'title-pos-config',
      },
      children: [],
    };

    if (this.petroService.showSiteConfig()) {
      posConfig.children.push({
        title: $localize`:Main menu navbar item name:Site Management`,
        attributes: {
          'data-e2e': 'link-program-site-management',
        },
        ...this.getItemUrl('/configuration/site-management', [ApplicationRoot.Petro]),
      });
    }
    return posConfig;
  }

  private getItemUrl(url: string, applications: ApplicationRoot[]): NavbarItemLink {
    return getNavbarItemLink(this.currentApplication, applications, this.languageRoute, url);
  }
}
