<div class="error-page">
  <div class="error-page-content">
    <div class="box-denied box">
      <h2 class="title is-3 has-text-left" i18n>Access denied</h2>
      <p class="has-text-left" i18n>
        Your account does not have access rights to view this content.
      </p>
      <div class="div-links">
        <p>
          <i class="has-text-link arrow right"></i>
          <a
            (click)="navigateDashboard()"
            class="text-redirections has-text-primary"
            data-e2e="navigation-dashboard"
            i18n
          >
            See dashboard
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
