<div class="section is-small mb-3">
  <div>
    <div class="container">
      <div class="mb-2">
        <a [routerLink]="['/administration', 'organisations']">
          <span class="icon is-small">
            <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
          </span>
          <span i18n>Organisations</span>
        </a>
        <a (click)="onApplyFormAndReturnOrg(true)" data-e2e="wl-current-entity-navigation">
          <span class="icon is-small">
            <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
          </span>
          <span *ngIf="!(loading$ | async); else entityNameLoadingTemplate" i18n>{{
            entityName
          }}</span>
        </a>
        <a class="is-active has-text-black">
          <span class="icon is-small">
            <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
          </span>
          <span i18n>Customize Interface</span>
        </a>
      </div>
      <div>
        <h1 class="title is-2" data-e2e="title" i18n>Customize Interface</h1>
      </div>
    </div>
    <div class="column has-text-right pr-0 return-org-section">
      <button
        type="button"
        class="button is-primary is-outlined"
        data-e2e="wl-org-page-where-comes"
        (click)="onApplyFormAndReturnOrg()"
        i18n
        title="Return to organisation page"
        i18n-title
      >
        Return to organisation page
      </button>
    </div>
  </div>
</div>
<div class="wl-container" tabindex="-1">
  <div
    class="wl-preview-section columns is-desktop is-flex-column-reverse-tablet mt-0 mb-0 has-border-top"
  >
    <div class="wl-preview-page column is-two-thirds-desktop is-three-quarters-fullhd pt-0 pb-0">
      <div class="wl-preview-heading has-border-top">
        <h2 class="title is-5" i18n>Preview - only for testing purposes</h2>
      </div>
      <portal-white-labeling-preview-page
        [domainName]="domainName"
        [templateParameters]="initialTemplateParameters"
      ></portal-white-labeling-preview-page>
    </div>
    <div class="wl-labeling-editor column is-one-third-desktop is-one-quarter-fullhd pt-0 pb-0">
      <portal-form-white-labeling-editor
        [templateParameters]="initialTemplateParameters"
        (appliedChanges)="onWhiteLabelingEditorApplied($event)"
        (resetedChanges)="onWhiteLabelingEditorReseted($event)"
        (valueChanges)="onWhiteLabelingEditorValueChanged($event)"
      ></portal-form-white-labeling-editor>
    </div>
  </div>
</div>

<ng-template #entityNameLoadingTemplate>
  <span class="icon loader"><i class="fas fa-spinner"></i></span>
</ng-template>

<!-- reset applied changes confirmation modal -->
<portal-base-modal #backOrgPageModal>
  <div title i18n>Leave without applying changes?</div>
  <div description i18n>If you leave the interface editor, your changes will be lost</div>
  <div class="level" buttons>
    <div class="level-left"></div>
    <div class="buttons">
      <button type="button" class="button is-outlined" (click)="backOrgPageModal.close()" i18n>
        Keep editing
      </button>
      <button
        type="button"
        class="button is-primary"
        (click)="onBackOrgModalConfirmed()"
        data-e2e="confirm-to-reset-changes"
        i18n
        title="Reset changes"
        i18n-title
      >
        Reset changes
      </button>
    </div>
  </div>
</portal-base-modal>
