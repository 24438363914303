import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { vfappcatalog, FileUploadAcceptType } from '@portal/marketplace/api-interfaces';
import { FileUploadComponent } from '../file-upload/file-upload.component';

@Component({
  selector: 'marketplace-app-icon-upload',
  templateUrl: './app-icon-upload.component.html',
  styleUrls: ['./app-icon-upload.component.scss'],
})
export class AppIconUploadComponent implements OnInit {
  @Input() image: any;
  @Input() showUpload = true;
  @Input() enableUpload: Boolean = true;
  @Output() fileChanged = new EventEmitter<File>();
  supportedTypes: FileUploadAcceptType[] = [
    FileUploadAcceptType.JPG,
    FileUploadAcceptType.JPEG,
    FileUploadAcceptType.PNG,
  ];

  @ViewChildren(FileUploadComponent) fileUpload: QueryList<FileUploadComponent>;

  constructor() {}

  ngOnInit(): void {}

  onChangeImage() {
    this.fileUpload.first.browse();
  }

  get isLoaded(): boolean {
    return this.image && this.image.indexOf('data:image/') >= 0;
  }

  iconChanged(files: File[]): void {
    if (files.length) {
      this.fileChanged.emit(files[0]);
      this.preview(files[0]);
    }
  }

  preview(file: File): void {
    const mimeType = file.type;
    if (mimeType.match(/image\/*/) === null) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.image = reader.result;
    };
  }
}
