import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IDropDownItem } from './interfaces/drop-down-item.interface';

@Component({
  selector: 'marketplace-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
  @Input() items: IDropDownItem[];
  @Output() itemSelected: EventEmitter<IDropDownItem> = new EventEmitter<IDropDownItem>();
  defaultSelection: IDropDownItem;
  isActive = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.items.forEach((item) => {
      if (item.isSelected) {
        this.defaultSelection = item;
      }
    });
  }

  ngOnInit(): void {}

  onSelect(itemId: any): void {
    this.isActive = false;
    this.itemSelected.emit(this.items.find((item) => item.id === itemId));
  }
}
