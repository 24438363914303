<pagination-template
  [id]="paginationId"
  #pApi="paginationApi"
  (pageChange)="onPageChange($event, pApi.getLastPage())"
>
  <div
    class="is-flex is-flex-wrap-wrap is-justify-content-space-between is-mobile is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
  >
    <div class="is-4-mobile mb-2">
      <div class="field is-horizontal is-align-items-center">
        <div class="field-label"><div class="label" i18n>Results per page</div></div>
        <div class="field-body">
          <div class="field">
            <portal-single-dropdown
              [size]="pageDropdownSize"
              [entities]="pageSizeOptionsList"
              [selectedItem]="pageSizeSelectedOption"
              (selected)="onPageSizeChange($event)"
              [isDropUp]="true"
              [isChip]="true"
              [isMiddleButtonAndSmallDropdown]="true"
              data-e2e="select-pagination-result-per-page"
            >
            </portal-single-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="is-vcentered is-flex is-flex-wrap-wrap is-justify-content-space-between">
        <div class="is-4-mobile is-narrow-tablet mr-4" *ngIf="goto">
          <label class="field is-horizontal is-align-items-center mb-2">
            <div class="field-label">
              <div class="label" i18n="e.g. Go to page [10 ]">Go to page</div>
            </div>
            <div class="field-body">
              <div class="field">
                <input
                  #pageValueRef
                  type="text"
                  class="input"
                  data-unit="input"
                  [(ngModel)]="pageValue"
                  (keyup.enter)="onPageChange(pageValueRef.value, pApi.getLastPage())"
                  (blur)="onPageChange(pageValueRef.value, pApi.getLastPage())"
                  [disabled]="pApi.getLastPage() === pageSize.Size1 || totalItems < itemsPerPage"
                  data-e2e="input-pagination-go-to"
                  (keydown)="validateNumber($event)"
                />
              </div>
            </div>
          </label>
        </div>

        <div class="is-4-mobile is-narrow-tablet mr-4">
          <div class="field is-horizontal is-align-items-center mb-2">
            <div class="field-label">
              <div *ngIf="!allowInfiniteTotal" class="is-narrow-tablet" i18n="e.g. Page 3 of 14">
                Page <span data-unit="current">{{ pApi.getCurrent() }}</span> of
                <span data-unit="total">{{ (pApi.getLastPage() | number) || '' }}</span>
              </div>

              <div *ngIf="allowInfiniteTotal" class="mb-2 is-narrow-tablet">
                <span i18n="e.g. Page 3">Page</span>
                <span data-unit="current"> {{ pApi.getCurrent() }}</span>
              </div>
            </div>
            <div class="field-body">
              <div class="is-narrow buttons">
                <button
                  class="button is-primary is-outlined"
                  data-unit="previous"
                  [disabled]="pApi.isFirstPage()"
                  data-e2e="button-pagination-previous"
                  type="button"
                  title="Previous"
                  i18n-title
                  (click)="pApi.previous()"
                >
                  <span class="icon">
                    <span
                      class="is-sr-only"
                      i18n="Previous page"
                      data-e2e="button-pagination-previous-icon"
                      >Previous</span
                    >
                    <i class="fas fa-chevron-left" aria-hidden="true"></i>
                  </span>
                </button>
                <button
                  class="button is-primary is-outlined"
                  data-unit="next"
                  [disabled]="pApi.isLastPage()"
                  data-e2e="button-pagination-next"
                  type="button"
                  title="Next"
                  i18n-title
                  (click)="pApi.next()"
                >
                  <span class="icon">
                    <i class="fas fa-chevron-right" aria-hidden="true"></i>
                    <span class="is-sr-only" i18n="Next page" data-e2e="button-pagination-next-icon"
                      >Next</span
                    >
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</pagination-template>
