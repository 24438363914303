import { Injectable } from '@angular/core';
import { ProcessorType } from '@portal/entity-services/interfaces';
import { Features } from 'environments/enums/features.enum';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import sortBy from 'lodash-es/sortBy';
import { SupportedProcessorSettingsService } from '@apps/portal/src/app/services/supported-processor-settings.service';
import { TranslationsKeyValue } from '@portal/shared/helpers/src/lib/create-list-from-inputs/interfaces/translations-key-value.interface';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class ProcessorTypes extends SelectInputsList {
  keyValue: TranslationsKeyValue = {
    [ProcessorType.AbsWeb]: 'ABS Web',
    [ProcessorType.AbsIso]: 'ABS ISO',
    [ProcessorType.Affirm]: 'Affirm',
    [ProcessorType.AliPay]: 'Alipay',
    [ProcessorType.AmexGB]: 'American Express',
    [ProcessorType.AmexMX]: 'Amex Mexico',
    [ProcessorType.AmexPWP]: 'Amex Pay With Points',
    [ProcessorType.Bankaxept]: 'BankAxept',
    [ProcessorType.Banorte]: 'Banorte',
    [ProcessorType.BanqueChabrieres]: 'Banque Chabrières',
    [ProcessorType.Barclays]: 'Barclays',
    [ProcessorType.BarclaysMc]: 'Barclays Multicurrency',
    [ProcessorType.BbvaMx]: 'BBVA Mexico',
    [ProcessorType.Bfcoi]: 'Banque Française Commerciale Océan Indien',
    [ProcessorType.BlackhawkUs]: 'Blackhawk US',
    [ProcessorType.BlackhawkCa]: 'Blackhawk CA',
    [ProcessorType.Blik]: 'Blik',
    [ProcessorType.BnpParibas]: 'BNPP',
    [ProcessorType.BnppVersAtos]: 'BNPP ATC',
    [ProcessorType.BofAUs]: 'BofA US',
    [ProcessorType.Bred]: 'BRED',
    [ProcessorType.CaisseDepargne]: "Caisse d'Epargne",
    [ProcessorType.Cba]: 'CBA',
    [ProcessorType.ChaseUs]: 'Chase US',
    [ProcessorType.ChaseCa]: 'Chase CA',
    [ProcessorType.Cedicam]: 'CEDICAM',
    [ProcessorType.Cic]: 'Crédit Industriel et Commercial (CIC)',
    [ProcessorType.CitibankCa]: 'Citibank CA',
    [ProcessorType.CitibankUs]: 'Citibank US',
    [ProcessorType.CmArkea]: 'Crédit Mutuel Arkéa',
    [ProcessorType.CreditDuNordVersAtos]: 'Crédit du Nord to ATOS',
    [ProcessorType.CreditDuNordVersNatixis]: 'Crédit du Nord vers Natixis',
    [ProcessorType.CreditAgricole]: 'Crédit Agricole',
    [ProcessorType.CreditMutuel]: 'Crédit Mutuel',
    [ProcessorType.Crypto]: 'Crypto',
    [ProcessorType.Credomatic]: 'CREDOMATIC',
    [ProcessorType.CuscalAu]: 'Cuscal AU',
    [ProcessorType.Dankort]: 'Dankort',
    [ProcessorType.EftposNz]: 'EFTPOS NZ',
    [ProcessorType.Elavon]: 'Elavon',
    [ProcessorType.ElavonIsoUk]: 'Elavon ISO UK',
    [ProcessorType.Fdrc]: 'First Data Rapid Connect North America',
    [ProcessorType.FdrcCa]: 'Fiserv (First Data) CA',
    [ProcessorType.FdrcUs]: 'Fiserv (First Data) US',
    [ProcessorType.FiservLac]: 'Fiserv LAC',
    [ProcessorType.FiservUk]: 'Fiserv UK',
    [ProcessorType.FiservAu]: 'Fiserv AU',
    [ProcessorType.Gicc]: 'GICC',
    [ProcessorType.Globalpay]: 'GlobalPay',
    [ProcessorType.GPP2]: 'GPP2',
    [ProcessorType.Hsbc]: 'HSBC',
    [ProcessorType.Ikano]: 'Ikano',
    [ProcessorType.IncommCa]: 'Incomm CA',
    [ProcessorType.IncommUs]: 'Incomm US',
    [ProcessorType.Intercard]: 'Verifone Payments GmbH',
    [ProcessorType.Isracard]: 'Isracard',
    [ProcessorType.KlarnaEcom]: 'Klarna Ecom',
    [ProcessorType.KlarnaQr]: 'Klarna Pos',
    [ProcessorType.LaBanquePostal]: 'La Banque Postale',
    [ProcessorType.Landsbankinn]: 'Landsbankinn',
    [ProcessorType.Lcl]: 'Crédit Lyonnais (LCL)',
    [ProcessorType.LesBanquesPopulaires]: 'Les Banques Populaires (NATIXIS)',
    [ProcessorType.LloydsCardnet]: 'Lloyds Bank',
    [ProcessorType.Maestro]: 'Maestro',
    [ProcessorType.Mobilepay]: 'MobilePay',
    [ProcessorType.MPGS]: 'Mastercard Payment Gateway Services',
    [ProcessorType.Nets]: 'Nets',
    [ProcessorType.OpOnlinePayment]: 'OpOnlinePayment',
    [ProcessorType.ParkRetailUk]: 'Park Retail',
    [ProcessorType.Paypal]: 'PayPal',
    [ProcessorType.Prosa]: 'Prosa',
    [ProcessorType.Rapyd]: 'Rapyd',
    [ProcessorType.ResursBank]: 'Resurs Bank',
    [ProcessorType.Simulator]: $localize`:Processor type|:Simulator`,
    [ProcessorType.Sg]: 'Société Générale',
    [ProcessorType.SvsUk]: 'SVS UK',
    [ProcessorType.SvsUs]: 'SVS US',
    [ProcessorType.SwedbankPay]: 'Swedbank',
    [ProcessorType.Swish]: 'Swish',
    [ProcessorType.SynchronyUs]: 'Synchrony',
    [ProcessorType.TelecheckUS]: 'Telecheck US',
    [ProcessorType.TietoEvry]: 'TietoEVRY',
    [ProcessorType.UnionPay]: 'UnionPay',
    [ProcessorType.ValuelinkCA]: 'Valuelink CA',
    [ProcessorType.ValuelinkUS]: 'Valuelink US',
    [ProcessorType.VerifoneEu]: 'Verifone Europe',
    [ProcessorType.Vipps]: 'Vipps',
    [ProcessorType.WeChat]: 'Wechat',
    [ProcessorType.Westpac]: 'Westpac',
    [ProcessorType.WestpacAu]: 'Verifone Australia Gateway',
    [ProcessorType.WestpacPacificFiji]: 'Westpac Pacific FIJI',
    [ProcessorType.WestpacPacificPng]: 'Westpac Pacific PNG',
    [ProcessorType.WexUs]: 'WEX (US)',
    [ProcessorType.WicUsTx]: 'WIC Texas',
    [ProcessorType.WoodForestUs]: 'Woodforest National Bank',
    [ProcessorType.WorldlineUK]: 'Worldline UK',
    [ProcessorType.Worldpay]: 'WorldPay',
    [ProcessorType.WorldpayUS]: 'WorldPay US',
    [ProcessorType.Wpay]: 'WPAY',
    [ProcessorType.Prm]: 'PRM',
  };

  list: ISelectInput[];

  constructor(
    private featureToggleService: FeatureToggle,
    private supportedProcessorSettingsService: SupportedProcessorSettingsService,
  ) {
    super();
    if (!this.featureToggleService.isFeatureActive(Features.MobilePay)) {
      delete this.keyValue[ProcessorType.Mobilepay];
    }
    if (!this.featureToggleService.isFeatureActive(Features.Simulator)) {
      delete this.keyValue[ProcessorType.Simulator];
    }
    if (!this.featureToggleService.isFeatureActive(Features.Crypto)) {
      delete this.keyValue[ProcessorType.Crypto];
    }
    this.getSortedList();
  }

  getSupportedProcessors(): ISelectInput[] {
    const supportedProcessorSettings =
      this.supportedProcessorSettingsService.getSupportedProcessorSettingsFromSessionStorage();
    if (supportedProcessorSettings?.processorsSupported?.length) {
      const supportedProcessors: TranslationsKeyValue = {};
      supportedProcessorSettings.processorsSupported.forEach((processor) => {
        supportedProcessors[processor] = this.keyValue[processor] || processor;
      });
      this.getSortedList(supportedProcessors);
    }
    return this.list;
  }

  getSortedList(processors = this.keyValue): void {
    this.list = sortBy(this.getSelectInputs(processors), (item: ISelectInput) =>
      item.text.toLowerCase(),
    );
  }
}
