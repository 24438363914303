import { Component } from '@angular/core';
import { EntityType, IUser } from '@portal/entity-services/interfaces';
import { from, Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IOrganisation } from '@portal/entity-services/interfaces';
import { OrganisationService } from '@portal/entity-services/organisations/src/lib/services/organisation.service';
import { catchError, distinct, finalize, switchMap, toArray } from 'rxjs/operators';
import { UserService } from '@portal/entity-services/users/src/lib/services/user.service';
import { IGroupViewDetailEdit } from '../../interfaces/group-view-detail-edit.interface';
import { DataServiceError } from '@ngrx/data';
import { Router } from '@angular/router';
import { NotifierService } from '@portal/shared/ui/notifier/src';
import { ToastService } from '@portal/shared/ui/toast';
import * as get from 'lodash/get';

@Component({
  selector: 'portal-details-organization-group',
  templateUrl: './details-organization-group.component.html',
  styleUrls: ['./details-organization-group.component.scss'],
})
export class DetailsOrganizationGroupComponent {
  loading$: Observable<boolean>;
  entityUId: string;
  organizationGroup: IOrganisation;
  parentOrganization: IOrganisation;
  subOrganisations: IOrganisation[];
  usersForOrganizationGroup: IUser[];

  groupViewDetailEdit: IGroupViewDetailEdit = {
    groupOrganization: {},
    parentOrganization: {},
    users: [],
    subOrganizations: [],
  };

  maximumLimitOrgToAdd = 100;
  minimumLimitOrgToAdd = 2;
  preSelectedOrganizationNumber: number;
  isOrganisationLoading: boolean;
  isUsersLoading: boolean;
  isEditForm = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private organisationService: OrganisationService,
    private userService: UserService,
    private notifierService: NotifierService,
    private toastService: ToastService,
    private router: Router,
  ) {
    const eventId = this.activatedRoute.snapshot.paramMap.get('id');
    if (eventId) {
      this.entityUId = eventId;
      this.organisationService.getByKey(this.entityUId)?.subscribe((entity) => {
        this.organizationGroup = entity;
        this.parentOrganization = this.organizationGroup.parentEntity;
        this.groupViewDetailEdit.groupOrganization = entity || {};
        this.groupViewDetailEdit.parentOrganization = entity?.parentEntity || {};
        this.getGroupEntityRelationships();
        this.findUsersByEntityId();
      });
    }
    this.loading$ = organisationService.loading$;
  }

  getGroupEntityRelationships(): void {
    this.isOrganisationLoading = true;
    this.organisationService
      .getGroupEntityRelationships(this.organizationGroup?.entityUid)
      .pipe(finalize(() => (this.isOrganisationLoading = false)))
      .subscribe(
        (entitiesUid: IOrganisation[]) => {
          this.groupViewDetailEdit.subOrganizations = entitiesUid || [];
          this.subOrganisations = entitiesUid;
        },
        (errorUserUpdate: DataServiceError) => {
          this.notifierService.error(
            $localize`Error` +
              ` (${get(errorUserUpdate, 'error.status')}) ${get(
                errorUserUpdate,
                'error.error.message',
              )}`,
          );
        },
      );
  }

  findUsersByEntityId(): void {
    this.isUsersLoading = true;
    this.userService
      .getUsersByEntity(this.organizationGroup?.entityUid)
      .pipe(
        finalize(() => {
          this.isUsersLoading = false;
        }),
        switchMap(from),
        distinct((user: IUser) => user.userUid),
        toArray(),
      )
      .subscribe(
        (users) => {
          this.groupViewDetailEdit.users = users || [];
          this.usersForOrganizationGroup = users;
        },
        (errorUserUpdate: DataServiceError) => {
          this.notifierService.error(
            $localize`Error` +
              ` (${get(errorUserUpdate, 'error.status')}) ${get(
                errorUserUpdate,
                'error.error.message',
              )}`,
          );
        },
      );
  }

  onFormValidated(data: Record<string, any>): void {
    if (
      this.preSelectedOrganizationNumber <= this.maximumLimitOrgToAdd &&
      this.preSelectedOrganizationNumber >= this.minimumLimitOrgToAdd
    ) {
      this.replaceRelationshipsUpdateUsers(data);
    }
  }

  closeEditForm(): void {
    this.isEditForm = false;
  }

  onSelectedOrganizationNumber(organizationsNumber: number): void {
    this.preSelectedOrganizationNumber = organizationsNumber;
  }

  getUsersByIds(userIds: string[]): Observable<IUser>[] {
    return userIds.map((userUid) => {
      if (!userUid) {
        return of(null);
      }
      return this.userService.getByKey(userUid).pipe(catchError(() => of({ userUid })));
    });
  }

  onEditGroupForm(): void {
    this.isEditForm = true;
  }

  replaceRelationships(
    paramEntityUids: Record<string, any>,
    entityGroupUid: string,
  ): Observable<any> {
    return this.organisationService.replaceGroupEntityRelationships(
      paramEntityUids,
      entityGroupUid,
    );
  }

  /**
   * data.entityUids = Entities From Form to edit
   * subOrgRelatedFromParentEntity = Entities relationships  Related to Parent entity
   * @param data
   * return (there's relationship between Group organization and sub entities)
   */
  isRelationshipAndSubEntitiesUpdated(data: Record<string, any>): boolean {
    const subOrgRelatedFromParentEntity = this.groupViewDetailEdit?.subOrganizations?.map(
      (org) => org.entityUid,
    );

    return (!this.isTwoArraysEqual(data.entityUids, subOrgRelatedFromParentEntity) &&
      subOrgRelatedFromParentEntity?.length) as unknown as boolean;
  }

  createGroupEntityRelationships(
    paramEntityUids: Record<string, any>,
    entityGroupUid: string,
  ): Observable<any> {
    return this.organisationService.createGroupEntityRelationships(paramEntityUids, entityGroupUid);
  }

  replaceRelationshipsUpdateUsers(data: Record<string, any>): void {
    const singleEntityPayload = {
      name: data.name,
      parentEntityUid: data.parentEntityUid,
      entityType: EntityType.GROUP,
    };

    const entityUids = {
      entityUids: data.entityUids,
    };

    let userIds = data?.userIds;
    userIds = userIds?.filter((id) => id);

    const groupEntityId = data.groupUid;
    const groupName = singleEntityPayload.name;

    // There are relationships, and were updated or removed entities to update relationships
    if (this.isRelationshipAndSubEntitiesUpdated(data)) {
      this.replaceParentEntityRelationShips(entityUids, groupEntityId, userIds, groupName);
    } else {
      // There are no relationships between the group entity and other entities
      if (!this.groupViewDetailEdit?.subOrganizations.length) {
        this.createParentEntityRelationShips(entityUids, groupEntityId, userIds, groupName);
      } else {
        // Just update the users, in case of need
        this.updateTheUsersWithTheParentEntityId(userIds, groupEntityId, groupName);
      }
    }
  }

  isTwoArraysEqual(a: any[], b: any[]): boolean {
    return a.length === b.length && a.filter((x) => !b.includes(x)).length === 0;
  }

  createParentEntityRelationShips(
    entityUids: Record<string, any[]>,
    groupEntityId: string,
    userIds: string[],
    groupName: string,
  ): void {
    this.createGroupEntityRelationships(entityUids, groupEntityId).subscribe(
      (relationship) => {
        // 204 - Entities associated with group entity.
        if (relationship.status === 204) {
          // No users selected
          if (!userIds?.length) {
            this.toastService.showToast(
              $localize`Organization group ${groupName} has been created`,
            );
            this.router.navigate(['/administration/users']);
          } else {
            // Here we need to update the users
            this.updateTheUsersWithTheParentEntityId(userIds, groupEntityId, groupName);
          }
        }
      },
      (errorRelationship: DataServiceError) => {
        this.notifierService.error(
          $localize`Error` +
            ` (${get(errorRelationship, 'error.status')}) ${get(
              errorRelationship,
              'error.error.message',
            )}`,
        );
      },
    );
  }

  updateTheUsersWithTheParentEntityId(
    userIds: string[],
    groupEntityId: string,
    groupName: string,
  ): void {
    let usersUpdated = 0;
    this.getUsersByIds(userIds).map((user) => {
      user.subscribe(
        (userData) => {
          const userToUpdate = { ...userData };
          // If the user is not associated with the organization group, then update the entity Id from user
          if (groupEntityId !== userToUpdate.entityUid) {
            userToUpdate.entityUid = groupEntityId;
            this.userService.update(userToUpdate).subscribe(
              () => {
                usersUpdated++;
                if (usersUpdated >= userIds.length) {
                  this.toastService.showToast(
                    $localize`Organization group ${groupName} has been edited successfully`,
                  );
                  this.router.navigate(['/administration/users']);
                }
              },
              (errorUserUpdate: DataServiceError) => {
                this.notifierService.error(
                  $localize`Error` +
                    ` (${get(errorUserUpdate, 'error.status')}) ${get(
                      errorUserUpdate,
                      'error.error.message',
                    )}`,
                );
              },
            );
          }
        },
        (errorGetUsersByIds: DataServiceError) => {
          this.notifierService.error(
            $localize`Error` +
              ` (${get(errorGetUsersByIds, 'error.status')}) ${get(
                errorGetUsersByIds,
                'error.error.message',
              )}`,
          );
        },
      );
    });
  }

  replaceParentEntityRelationShips(
    entityUids: Record<string, any[]>,
    groupEntityId: string,
    userIds: string[],
    groupName: string,
  ): void {
    this.replaceRelationships(entityUids, groupEntityId).subscribe(
      (relationship) => {
        // 204 - Entities associated with group entity.
        if (relationship.status === 204) {
          // No users selected
          if (!userIds?.length) {
            this.toastService.showToast(
              $localize`Organization group ${groupName} has been edited successfully`,
            );
            this.router.navigate(['/administration/users']);
          } else {
            // We update the users
            this.updateTheUsersWithTheParentEntityId(userIds, groupEntityId, groupName);
          }
        }
      },
      (errorCreateEntity: DataServiceError) => {
        this.notifierService.error(
          $localize`Error` +
            ` (${get(errorCreateEntity, 'error.status')}) ${
              get(errorCreateEntity, 'error.error.message') ||
              get(errorCreateEntity, 'error.message')
            }`,
        );
      },
    );
  }
}
