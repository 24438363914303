import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  passwordMatchValidation,
  RegexPatterns,
} from '@portal/shared/ui/form/src/lib/error/custom-error.validator';

@Component({
  selector: 'portal-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss'],
})
export class PasswordValidationComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() passwordConfirm: boolean;
  @Input() validated: boolean;
  validations = [...RegexPatterns.password];

  ngOnInit(): void {
    if (this.passwordConfirm) {
      this.validations.push(passwordMatchValidation);
    }
  }

  passedValidation(validationName: string): boolean {
    return !Object.keys(this.control.errors || {}).includes(validationName) && this.control.value;
  }
}
