import { Injectable } from '@angular/core';
import { IUser, UserRole } from '@portal/entity-services/interfaces/src';
import { RestrictPermissionService } from '@portal/shared/auth/authorization/src';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';
import { INavbarItem } from '@portal/shared/ui/layouts/src/lib/top-navbar/interfaces/navbar-item.interface';
import {
  getNavbarItemLink,
  getSubNavItems,
  NavbarItemLink,
} from '../../helpers/navigation-route-helpers';

import {
  DeveloperAppCatalogRouteNames,
  DeveloperAppCatalogRoutePermissions,
} from '../../permissions/developer/app-catalog';
import {
  EstateOwnerAppCatalogRouteNames,
  EstateOwnerAppCatalogRoutePermissions,
} from '../../permissions/estate-owner/app-catalog';
import {
  MarketplaceManagerAppCatalogRoutePermissions,
  MarketplaceManagerAppCatalogRouteNames,
} from '../../permissions/marketplace-manager/app-catalog';
import {
  MarketplaceAppCatalogRoutePermissions,
  MarketplaceAppCatalogRouteNames,
} from '../../permissions/marketplace/app-catalog';
import { ApplicationRoot } from '../../enums/application-root.enum';
import { MenuRegion } from '../../../../../ui/layouts/src/lib/top-navbar/enum/menu-region.enum';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceNavbarService {
  languageRoute: string;
  currentApplication: ApplicationRoot;
  constructor(
    private featureToggleService: FeatureToggle,
    private restrictPermissionService: RestrictPermissionService,
  ) {}

  getMarketplaceNavbarItems(params: {
    currentApplication: ApplicationRoot;
    languageRoute: string;
    user: IUser;
  }): INavbarItem | null {
    this.languageRoute = params.languageRoute;
    this.currentApplication = params.currentApplication;
    const dashboardItems = this.getDashboardNavbarItems();
    const applicationItems = this.getApplicationsNavbarItems(params.user);
    const bundlesItems = this.getBundlesNavbarItems();
    const terminalsItems = this.getTerminalsNavbarItems();
    const reportsMarketplaceItems = this.getReportsNavbarItems();
    const marketplaceAdmin = this.getMarketplaceAdminItems();

    return getSubNavItems(
      {
        id: 'marketplace',
        title: $localize`:Main menu navbar group name:App Marketplace`,
        attributes: {
          'data-e2e': 'title-marketplace',
          groupTitle: $localize`App Marketplace`,
          description: $localize`Shaping powerful living connections, relationships, and experiences.`,
        },
        region: MenuRegion.Marketplace,
      },
      [
        dashboardItems,
        applicationItems,
        bundlesItems,
        terminalsItems,
        reportsMarketplaceItems,
        marketplaceAdmin,
      ],
    );
  }

  getDashboardNavbarItems(): INavbarItem | null {
    const dashboardItems = {
      title: $localize`:Main menu navbar subgroup name:Dashboard`,
      attributes: {
        'data-e2e': 'title-dashboard',
      },
      children: [],
    };

    if (
      this.showDevelopApp ||
      this.showEstateOwnerApp ||
      this.showMarketplaceApp ||
      this.showMarketplaceManagerApp
    ) {
      dashboardItems.children.push({
        title: $localize`:Main menu navbar item name:Dashboard`,
        attributes: {
          'data-e2e': 'link-developer-app-catalog',
        },
        ...this.getItemUrl('/dashboard', [
          ApplicationRoot.Marketplace,
          ApplicationRoot.EstateOwner,
          ApplicationRoot.Developer,
          ApplicationRoot.MarketplaceManager,
        ]),
      });
    }

    return dashboardItems;
  }

  getApplicationsNavbarItems(user: IUser): INavbarItem | null {
    const applicationItems = {
      title: $localize`:Main menu navbar subroup name:Applications`,
      attributes: {
        'data-e2e': 'title-applications',
      },
      children: [],
    };

    if (this.showDevelopApp) {
      applicationItems.children.push({
        title: $localize`:Main menu navbar item name:Applications Catalog`,
        attributes: {
          'data-e2e': 'link-developer-app-catalog',
        },
        ...this.getItemUrl('/my-apps', [ApplicationRoot.Developer]),
      });
    }

    if (this.showEstateOwnerApp) {
      applicationItems.children.push(
        {
          title: $localize`:Main menu navbar item name:Applications Catalog`,
          attributes: {
            'data-e2e': 'link-estate-owner-app-catalog',
          },
          ...this.getItemUrl('/applications/app-catalog', [ApplicationRoot.EstateOwner]),
        },
        ...(this.isAppVaultEnableForUserRole(user.roles)
          ? [
              {
                title: $localize`:Main menu navbar item name:App Vault`,
                attributes: {
                  'data-e2e': 'link-estate-owner-app-vault',
                },
                ...this.getItemUrl('/applications/app-vault', [ApplicationRoot.EstateOwner]),
              }
            ]
          : []),
        {
          title: $localize`:Main menu navbar item name:Apps  Tags Management`,
          attributes: {
            'data-e2e': 'link-estate-owner-apps-tags-management',
          },
          ...this.getItemUrl('/tags-management/apps', [ApplicationRoot.EstateOwner]),
        },
      );
    }

    if (this.showMarketplaceApp) {
      applicationItems.children.push(
        {
          title: $localize`:Main menu navbar item name:Applications Catalog`,
          attributes: {
            'data-e2e': 'link-marketplace-apps-catalog',
          },
          ...this.getItemUrl('/app-catalog', [ApplicationRoot.Marketplace]),
        },
        {
          title: $localize`:Main menu navbar item name:Bundles Catalog`,
          attributes: {
            'data-e2e': 'link-marketplace-bundles-catalog',
          },
          ...this.getItemUrl('/app-catalog/bundles', [ApplicationRoot.Marketplace]),
        },
        {
          title: $localize`:Main menu navbar item name:My Applications`,
          attributes: {
            'data-e2e': 'link-marketplace-my-apps',
          },
          ...this.getItemUrl('/my-purchases/applications', [
            ApplicationRoot.Marketplace,
          ]),
        },
        {
          title: $localize`:Main menu navbar item name:My Bundles`,
          attributes: {
            'data-e2e': 'link-marketplace-my-bundles',
          },
          ...this.getItemUrl('/my-purchases/bundles', [ApplicationRoot.Marketplace]),
        },
      );
    }

    if (this.showMarketplaceManagerApp) {
      applicationItems.children.push({
        title: $localize`:Main menu navbar item name:Applications Catalog`,
        attributes: {
          'data-e2e': 'link-marketplace-manager-app-catalog',
        },
        ...this.getItemUrl('/app-catalog', [ApplicationRoot.MarketplaceManager]),
      });
    }

    return applicationItems;
  }

  getBundlesNavbarItems(): INavbarItem | null {
    const bundlesNavbarItems = {
      title: $localize`:Main menu navbar subgroup name:Bundles`,
      attributes: {
        'data-e2e': 'title-bundles',
      },
      children: [],
    };

    if (this.showEstateOwnerApp) {
      bundlesNavbarItems.children.push(
        {
          title: $localize`:Main menu navbar item name:Bundles`,
          attributes: {
            'data-e2e': 'link-estate-owner-bundles',
          },
          ...this.getItemUrl('/bundles', [ApplicationRoot.EstateOwner]),
        },
        {
          title: $localize`:Main menu navbar item name:Create Bundle`,
          attributes: {
            'data-e2e': 'link-estate-owner-bundles-create-bundle',
          },
          ...this.getItemUrl('/bundles/wizard/new', [ApplicationRoot.EstateOwner]),
        },
        {
          title: $localize`:Main menu navbar item name:Bundles  Tags Management`,
          attributes: {
            'data-e2e': 'link-estate-owner-bundles-tags-management',
          },
          ...this.getItemUrl('/tags-management/bundles', [ApplicationRoot.EstateOwner]),
        },
      );
    }

    return bundlesNavbarItems;
  }

  getTerminalsNavbarItems(): INavbarItem | null {
    const terminalsNavbarItems = {
      title: $localize`:Main menu navbar subgroup name:Terminals`,
      attributes: {
        'data-e2e': 'title-terminals',
      },
      children: [],
    };

    if (this.showMarketplaceApp) {
      terminalsNavbarItems.children.push(
        {
          title: $localize`:Main menu navbar item name:Terminals`,
          attributes: {
            'data-e2e': 'link-marketplace-terminals',
          },
          ...this.getItemUrl('/devices', [ApplicationRoot.Marketplace]),
        },
        {
          title: $localize`:Main menu navbar item name:Tags Management`,
          attributes: {
            'data-e2e': 'link-marketplace-tags-management',
          },
          ...this.getItemUrl('/tags-management/devices', [ApplicationRoot.Marketplace]),
        },
      );
    }

    if (this.showEstateOwnerApp) {
      terminalsNavbarItems.children.push({
        title: $localize`:Main menu navbar item name:Terminals`,
        attributes: {
          'data-e2e': 'link-estate-owner-terminals',
        },
        ...this.getItemUrl('/devices', [ApplicationRoot.EstateOwner]),
      });
    }

    return terminalsNavbarItems;
  }

  getReportsNavbarItems(): INavbarItem | null {
    const reportsNavbarItems = {
      title: $localize`:Main menu navbar subgroup name:Reports`,
      attributes: {
        'data-e2e': 'title-reports-marketplace',
      },
      children: [],
    };

    if (this.showMarketplaceApp) {
      reportsNavbarItems.children.push(
        {
          title: $localize`:Main menu navbar item name:App Usage`,
          attributes: {
            'data-e2e': 'link-marketplace-reports-app-usage',
          },
          ...this.getItemUrl('/reports/app-usage', [ApplicationRoot.Marketplace]),
        },
        {
          title: $localize`:Main menu navbar item name:Audit Events`,
          attributes: {
            'data-e2e': 'link-marketplace-reports-audit-events',
          },
          ...this.getItemUrl('/reports/audit-event', [ApplicationRoot.Marketplace]),
        },
      );
    }

    if (this.showEstateOwnerApp) {
      reportsNavbarItems.children.push(
        {
          title: $localize`:Main menu navbar item name:App Usage`,
          attributes: {
            'data-e2e': 'link-estate-owner-reports-app-usage',
          },
          ...this.getItemUrl('/reports/app-usage', [ApplicationRoot.EstateOwner]),
        },
        {
          title: $localize`:Main menu navbar item name:Application Installation History`,
          attributes: {
            'data-e2e': 'link-estate-owner-reports-app-install-history',
          },
          ...this.getItemUrl('/reports/app-install-uninstall', [ApplicationRoot.EstateOwner]),
        },
        {
          title: $localize`:Main menu navbar item name:Application Popularity`,
          attributes: {
            'data-e2e': 'link-estate-owner-reports-app-popularity',
          },
          ...this.getItemUrl('/reports/app-popularity', [ApplicationRoot.EstateOwner]),
        },
        {
          title: $localize`:Main menu navbar item name:Audit Events`,
          attributes: {
            'data-e2e': 'link-estate-owner-reports-audit-events',
          },
          ...this.getItemUrl('/reports/audit-event', [ApplicationRoot.EstateOwner]),
        },
        {
          title: $localize`:Main menu navbar item name:Bundles`,
          attributes: {
            'data-e2e': 'link-estate-owner-reports-bundles',
          },
          ...this.getItemUrl('/reports/mcc-bundles', [ApplicationRoot.EstateOwner]),
        },
        {
          title: $localize`:Main menu navbar item name:Developer Apps Downloads`,
          attributes: {
            'data-e2e': 'link-estate-owner-reports-developer-apps-downloads',
          },
          ...this.getItemUrl('/reports/developer-apps-downloads', [ApplicationRoot.EstateOwner]),
        },
        {
          title: $localize`:Main menu navbar item name:Application Summary`,
          attributes: {
            'data-e2e': 'link-estate-owner-reports-application-summary',
          },
          ...this.getItemUrl('/reports/application-summary', [ApplicationRoot.EstateOwner]),
        },
        {
          title: $localize`:Main menu navbar item name:Merchant Status`,
          attributes: {
            'data-e2e': 'link-estate-owner-reports-merchant-status',
          },
          ...this.getItemUrl('/reports/merchant-status', [ApplicationRoot.EstateOwner]),
        },
        {
          title: $localize`:Main menu navbar item name:Terminal Status`,
          attributes: {
            'data-e2e': 'link-estate-owner-reports-terminal-status',
          },
          ...this.getItemUrl('/reports/device-status', [ApplicationRoot.EstateOwner]),
        },
      );
    }

    if (this.showMarketplaceManagerApp) {
      reportsNavbarItems.children.push(
        {
          title: $localize`:Main menu navbar item name:App Usage`,
          attributes: {
            'data-e2e': 'link-marketplace-manager-reports-app-usage',
          },
          ...this.getItemUrl('/reports/app-usage', [ApplicationRoot.MarketplaceManager]),
        },
        {
          title: $localize`:Main menu navbar item name:Application Installation History`,
          attributes: {
            'data-e2e': 'link-marketplace-manager-reports-apps-install-history',
          },
          ...this.getItemUrl('/reports/app-install-uninstall', [
            ApplicationRoot.MarketplaceManager,
          ]),
        },
        {
          title: $localize`:Main menu navbar item name:Application Installation Status`,
          attributes: {
            'data-e2e': 'link-marketplace-manager-reports-application-installation-status',
          },
          ...this.getItemUrl('/reports/application-installation-status', [
            ApplicationRoot.MarketplaceManager,
          ]),
        },
        {
          title: $localize`:Main menu navbar item name:Application Popularity`,
          attributes: {
            'data-e2e': 'link-marketplace-manager-reports-app-popularity',
          },
          ...this.getItemUrl('/reports/app-popularity', [ApplicationRoot.MarketplaceManager]),
        },
        {
          title: $localize`:Main menu navbar item name:Audit Events`,
          attributes: {
            'data-e2e': 'link-marketplace-manager-reports-audit-event',
          },
          ...this.getItemUrl('/reports/audit-event', [ApplicationRoot.MarketplaceManager]),
        },
        {
          title: $localize`:Main menu navbar item name:Bundles`,
          attributes: {
            'data-e2e': 'link-marketplace-manager-reports-mcc-bundles',
          },
          ...this.getItemUrl('/reports/mcc-bundles', [ApplicationRoot.MarketplaceManager]),
        },
        {
          title: $localize`:Main menu navbar item name:Application Summary`,
          attributes: {
            'data-e2e': 'link-marketplace-manager-application-summary',
          },
          ...this.getItemUrl('/reports/application-summary', [
            ApplicationRoot.MarketplaceManager,
          ]),
        },
        {
          title: $localize`:Main menu navbar item name:Terminal Status`,
          attributes: {
            'data-e2e': 'link-marketplace-manager-reports-device-status',
          },
          ...this.getItemUrl('/reports/device-status', [ApplicationRoot.MarketplaceManager]),
        },
        {
          title: $localize`:Main menu navbar item name:Merchant Status`,
          attributes: {
            'data-e2e': 'link-marketplace-manager-reports-merchant-status',
          },
          ...this.getItemUrl('/reports/merchant-status', [ApplicationRoot.MarketplaceManager]),
        },
      );
    }

    if (this.showDevelopApp) {
      reportsNavbarItems.children.push(
        {
          title: $localize`:Main menu navbar item name:Application Crash`,
          attributes: {
            'data-e2e': 'link-developer-reports-application-crash',
          },
          ...this.getItemUrl('/reports/application-crash', [ApplicationRoot.Developer]),
        },
        {
          title: $localize`:Main menu navbar item name:Application Purchase Summary`,
          attributes: {
            'data-e2e': 'link-developer-reports-application-purchases-Installs-counts',
          },
          ...this.getItemUrl('/reports/application-purchases-Installs-counts', [
            ApplicationRoot.Developer,
          ]),
        },
        {
          title: $localize`:Main menu navbar item name:Audit Events`,
          attributes: {
            'data-e2e': 'link-developer-reports-audit-event',
          },
          ...this.getItemUrl('/reports/audit-event', [ApplicationRoot.Developer]),
        },
        {
          title: $localize`:Main menu navbar item name:Applications Installation Summary`,
          attributes: {
            'data-e2e': 'link-developer-reports-applications-installation-summary',
          },
          ...this.getItemUrl('/reports/applications-installation-summary', [
            ApplicationRoot.Developer,
          ]),
        },
        {
          title: $localize`:Main menu navbar item name:Developer Apps Downloads`,
          attributes: {
            'data-e2e': 'link-developer-reports-developer-apps-downloads',
          },
          ...this.getItemUrl('/reports/developer-apps-downloads', [ApplicationRoot.Developer]),
        },
      );
    }

    return reportsNavbarItems;
  }

  getMarketplaceAdminItems(): INavbarItem | null {
    const marketplaceAdminItems = {
      title: `Administration`,
      attributes: {
        'data-e2e': 'title-applications',
      },
      children: [],
    };

    if (this.showMarketplaceManagerApp) {
      marketplaceAdminItems.children.push(
        // {
        //   title: `Marketplace Settings`,
        //   attributes: {
        //     'data-e2e': 'link-marketplace-manager-marketplace-administration-marketplace-settings',
        //   },
        //   ...this.getItemUrl('/marketplace-administration/marketplace-settings', [
        //     ApplicationRoot.MarketplaceManager,
        //   ]),
        // },
        // {
        //   title: `Bulk Assignment Jobs Status`,
        //   attributes: {
        //     'data-e2e': 'link-marketplace-manager-marketplace-administration-bulk-assignment',
        //   },
        //   ...this.getItemUrl('/marketplace-administration/bulk-assignment', [
        //     ApplicationRoot.MarketplaceManager,
        //   ]),
        // },
        {
          title: `App Assignments Management`,
          attributes: {
            'data-e2e': 'link-marketplace-manager-marketplace-administration-app-management',
          },
          ...this.getItemUrl('/marketplace-administration/app-management', [
            ApplicationRoot.MarketplaceManager,
          ]),
        },
      );
    }

    return marketplaceAdminItems;
  }

  private getItemUrl(url: string, applications: ApplicationRoot[]): NavbarItemLink {
    return getNavbarItemLink(this.currentApplication, applications, this.languageRoute, url);
  }

  get showDevelopApp(): boolean {
    return this.restrictPermissionService.checkPermissions(
      DeveloperAppCatalogRoutePermissions.routePermissions[
        DeveloperAppCatalogRouteNames.RouteName.appCatalog
      ],
      ['read'],
    );
  }

  get showEstateOwnerApp(): boolean {
    return this.restrictPermissionService.checkPermissions(
      EstateOwnerAppCatalogRoutePermissions.routePermissions[
        EstateOwnerAppCatalogRouteNames.RouteName.appCatalog
      ],
      ['read'],
    );
  }

  get showMarketplaceApp(): boolean {
    return this.restrictPermissionService.checkPermissions(
      MarketplaceAppCatalogRoutePermissions.routePermissions[
        MarketplaceAppCatalogRouteNames.RouteName.appCatalog
      ],
      ['read'],
    );
  }

  get showMarketplaceManagerApp(): boolean {
    return this.restrictPermissionService.checkPermissions(
      MarketplaceManagerAppCatalogRoutePermissions.routePermissions[
        MarketplaceManagerAppCatalogRouteNames.RouteName.appCatalog
      ],
      ['read'],
    );
  }

  isAppVaultEnableForUserRole(roles: UserRole[]): boolean {
    const autorisedRolesForAppVault = [
      UserRole.EstateOwnerAdmin,
      UserRole.EstateOwnerSupportAdmin,
      UserRole.EstateOwnerUser
    ];
    return roles.some(value => autorisedRolesForAppVault.includes(value));
  }
}
