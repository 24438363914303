import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '@portal/shared/helpers/src/lib/date/date.service';

@Pipe({
  name: 'localeDate',
})
export class LocaleDatePipe implements PipeTransform {
  constructor(private dateService: DateService) {}

  transform(value: string, type = ''): string {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    };

    if (!value) {
      return '';
    }

    if (type === 'medium') {
      options.month = 'long';
      return this.dateService.toLocalisedLongString(new Date(value), options);
    }

    if (type === 'hourMinutes') {
      return this.dateService.toLocalisedShortString(new Date(value), options);
    }

    if (type === 'rawDate') {
      return this.dateService.toRawDateString(new Date(value));
    }

    return this.dateService.toLocalisedShortString(new Date(value));
  }
}
