import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class GoogleTagManagerInterceptor implements HttpInterceptor {
  intercept<T>(httpRequest: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    // console.log('GoogleTagManagerInterceptor');

    return next.handle(httpRequest).pipe(
      // Skip `sent` event
      filter((e) => e.type !== 0),
      // tap(e => console.log('[FOO]: response!', e)),
    );
  }
}
