import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EntityDataModule, HttpUrlGenerator } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedAuthAuthorizationModule } from '@portal/shared/auth/authorization';
import { ErrorHandlerModule } from '@portal/shared/error-handler';
import { BackendApiUrlGenerator } from '../../../../../libs/shared/helpers/src/lib/api-url/api-url';
import { AccessDeniedComponent } from '@portal/shared/ui/layouts/src/lib/error-page-handling/access-denied/access-denied.component';
import { FiveZeroThreeComponent } from '@portal/shared/ui/layouts/src/lib/error-page-handling/five-zero-three/five-zero-three.component';
import { FourZeroFourComponent } from '@portal/shared/ui/layouts/src/lib/error-page-handling/four-zero-four/four-zero-four.component';
import { UserDeletedComponent } from '@portal/shared/ui/layouts/src/lib/error-page-handling/user-deleted/user-deleted.component';
import { AuthInterceptor } from '@portal/shared/vui-http';
import { FirstResponseInterceptor } from '@portal/shared/vui-http/src/lib/interceptors/no-data/no-data.interceptor';

// Barrel export all files under core module
export * from '../../../../../libs/entity-services/interfaces/src/index';
export * from '../../../../../libs/shared/helpers/src/index';
export * from '@portal/shared/ui/form';
export * from '@portal/shared/ui/notifier';
export * from '@portal/shared/ui/table';
export * from '@portal/shared/vui-http';
export * from './constants/constants';
export { default as TYPES } from './types';

@NgModule({
  declarations: [
    FiveZeroThreeComponent,
    FourZeroFourComponent,
    AccessDeniedComponent,
    UserDeletedComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot({}),
    SharedAuthAuthorizationModule,
    ErrorHandlerModule.forRoot({ appName: 'PORTAL' }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: FirstResponseInterceptor, multi: true },
    {
      provide: HttpUrlGenerator,
      useClass: BackendApiUrlGenerator,
    },
  ],
  exports: [
    FiveZeroThreeComponent,
    FourZeroFourComponent,
    AccessDeniedComponent,
    UserDeletedComponent,
  ],
})
export class CoreModule {
  // Prevent CoreModule being imported again
  // https://angular.io/guide/ngmodule-faq#how-can-i-tell-if-an-ngmodule-or-service-was-previously-loaded
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
