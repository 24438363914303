import { ITableConfig } from '@portal/shared/ui/table';
export const APPLICATION_PURCHASES_INSTALLS_COUNTS: ITableConfig = {
  columns: [
    // { key: 'app_name', label: 'Name', isSortable: true },
    { key: 'app_name', label: 'App Name', isSortable: true },
    { key: 'app_version', label: 'Version', isSortable: true },
    { key: 'downloads', label: 'Downloads' },
    { key: 'assignment_status_val', label: 'Assignment Value' },
    { key: 'installs', label: 'Installs' },
    { key: 'purchases', label: 'purchases' },
  ],
  rowIdKey: 'appmarket_app_id',
  rowLabelKey: 'app_name',
};
