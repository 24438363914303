import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';
import { ES_CONSTANTS as CONSTANTS } from '@portal/entity-services/interfaces/src/lib/core/constants';
import { IUser } from '@portal/entity-services/interfaces/src/lib/users/interfaces/user.interface';
import { CustomDataService } from '@portal/shared/vui-http/src/lib/ngrx-data/custom-data.service';

@Injectable()
export class CustomUserDataService extends CustomDataService<IUser> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super(CONSTANTS.ENTITY_SERVICE.USER, http, httpUrlGenerator);
  }
}
