import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetters',
})
export class FirstLettersPipe implements PipeTransform {
  transform(value: string, limit?: number): string {
    const firstLetters = value.match(/\b(\w)/g);
    return firstLetters.splice(0, limit ? limit : firstLetters.length).join('');
  }
}
