import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@portal/marketplace/api-interfaces';

@Component({
  selector: 'marketplace-app-catalog-cards-template',
  templateUrl: './app-catalog-cards-template.component.html',
  styleUrls: ['./app-catalog-cards-template.component.scss'],
})
export class AppCatalogCardsTemplateComponent implements OnInit {
  @Input() apps: IApp[];
  @Output() navigateToAppPage: EventEmitter<IApp> = new EventEmitter();
  @Output() assignToDevicesClick: EventEmitter<any> = new EventEmitter();
  @Output() goToAppCatalogClick: EventEmitter<any> = new EventEmitter();
  @Input() showUpdatesIndicators = false;
  @Input() showInstallsIndicators = false;
  @Input() showVaultStatus = false;
  @Input() isPublic = false;
  @Input() isFeaturedPath: string;
  @Input() isPublicPath: string;
  @Input() isPilotPath: string;
  @Input() isDefaultPath: string;

  constructor(public router: Router) {}

  goToAppPage(app): void {
    this.navigateToAppPage.emit(app);
  }

  assignAppToDevices(app): void {
    this.assignToDevicesClick.emit(app);
  }
  trackByFn(index, item: IApp): string {
    return index;
  }
  goToAppCatalog(): void {
    this.goToAppCatalogClick.emit();
  }
  ngOnInit(): void {}
}
