export enum AVSOutcome {
  SAMatchesPCDoesNotMatch = 'STREET_ADDRESS_MATCHES_BUT_POSTAL_CODE_DOES_NOT_MATCH',
  SAMatchPCNotVerified = 'STREET_ADDRESS_MATCHES_BUT_POSTAL_CODE_NOT_VERIFIED',
  SAPCNotVerified = 'STREET_ADDRESS_AND_POSTAL_CODE_NOT_VERIFIED',
  SAPCMatch = 'STREET_ADDRESS_AND_POSTAL_CODE_MATCH',
  SAPCDoNotMatch = 'STREET_ADDRESS_AND_POSTAL_CODE_DO_NOT_MATCH',
  SAPCPartiallyMatch = 'STREET_ADDRESS_AND_POSTAL_CODE_PARTIALLY_MATCH',
  SAPCNotProvided = 'STREET_ADDRESS_AND_POSTAL_CODE_NOT_PROVIDED',
  SAPCMatchCardMemberNameDoesNotMatch = 'STREET_ADDRESS_AND_POSTAL_CODE_MATCH_BUT_CARD_MEMBER_NAME_DOES_NOT_MATCH',
  SAMatchCardmemberNameDoesNotMatch = 'STREET_ADDRESS_MATCHES_BUT_CARD_MEMBER_NAME_DOES_NOT_MATCH',
  SAMatchCardMemberNamePCDoesNotMatch = 'STREET_ADDRESS_MATCHES_BUT_CARD_MEMBER_NAME_AND_POSTAL_CODE_DO_NOT_MATCH',
  PCMatchSAUnverifiable = 'POSTAL_CODE_MATCHES_BUT_STREET_ADDRESS_UNVERIFIABLE_DUE_TO_INCOMPATIBLE_FORMATS',
  PCMatchSADoesNotMatch = 'POSTAL_CODE_MATCHES_BUT_STREET_ADDRESS_DOES_NOT_MATCH',
  PCMatchCardMemberNameDoesNotMatch = 'POSTAL_CODE_MATCHES_BUT_CARD_MEMBER_NAME_DOES_NOT_MATCH',
  PCMatchCardMemberNameSADoesNotMatch = 'POSTAL_CODE_MATCHES_BUT_CARD_MEMBER_NAME_AND_STREET_ADDRESS_DO_NOT_MATCH',
  CardMemberNameMatches = 'CARD_MEMBER_NAME_MATCHES',
  CardMemberAndPCMatch = 'CARD_MEMBER_NAME_AND_POSTAL_CODE_MATCH',
  CardmemberAndSAMatch = 'CARD_MEMBER_NAME_AND_STREET_ADDRESS_MATCH',
  CardMemberSAPCMatch = 'CARD_MEMBER_NAME_AND_STREET_ADDRESS_AND_POSTAL_CODE_MATCH',
  CardMemberSAPCDoNotMatch = 'CARD_MEMBER_NAME_AND_STREET_ADDRESS_AND_POSTAL_CODE_DO_NOT_MATCH',
  AddressInformationUnavailable = 'ADDRESS_INFORMATION_UNAVAILABLE',
  AddressNotVerifiedForInternationalTxn = 'ADDRESS_INFORMATION_NOT_VERIFIED_FOR_INTERNATIONAL_TRANSACTION',
  AVSDataInvalidOrAVSNotAllowed = 'AVS_DATA_IS_INVALID_OR_AVS_IS_NOT_ALLOWED_FOR_THIS_CARD_TYPE',
  AVSNotSupported = 'AVS_NOT_SUPPORTED',
  AVSNotPerformed = 'AVS_NOT_PERFORMED',
  AVSNotApplicable = 'AVS_NOT_APPLICABLE',
  AVSUnavailable = 'AVS_UNAVAILABLE',
}
