import { Injectable } from '@angular/core';
import { RateType } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class RateTypes extends SelectInputsList {
  keyValue = {
    [RateType.Buy]: $localize`Buyer`,
    [RateType.Sell]: $localize`Seller`,
  };

  list: ISelectInput[] = this.getSelectInputs();
}
