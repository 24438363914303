import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedAuthAuthorizationModule } from '@portal/shared/auth/authorization';
import { AppIconModule } from '../../shared/app-icon/app-icon.module';
import { AppPriceModule } from '../../shared/app-price/app-price.module';
import { AppTagModule } from '../../shared/app-tag/app-tag.module';
import { InstallsIndicatorModule } from '../../shared/installs-indicator/installs-indicator.module';
import { StatusIndicatorModule } from '../../shared/status-indicator/status-indicator.module';
import { UpdatesIndicatorModule } from '../../shared/updates-indicator/updates-indicator.module';
import { AppLogoPublicTemplateModule } from '../app-logo-public-template/app-logo-public-template.module';
import { AppLogoTemplateModule } from '../app-logo-template/app-logo-template.module';
import { AppCardTemplateComponent } from './app-card-template.component';

@NgModule({
  imports: [
    CommonModule,
    StatusIndicatorModule,
    AppTagModule,
    AppPriceModule,
    UpdatesIndicatorModule,
    SharedAuthAuthorizationModule,
    InstallsIndicatorModule,
    AppLogoTemplateModule,
    AppIconModule,
    AppLogoPublicTemplateModule,
  ],
  declarations: [AppCardTemplateComponent],
  exports: [AppCardTemplateComponent],
})
export class AppCardTemplateModule {}
