import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { endOfDay, startOfDay, subDays } from 'date-fns';

import forEach from 'lodash-es/forEach';
import without from 'lodash-es/without';
import concat from 'lodash-es/concat';
import isEmpty from 'lodash-es/isEmpty';
import orderBy from 'lodash-es/orderBy';

import {
  IFilterToApply,
  ISelectInput,
  SearchOperator,
  SearchSelector,
} from '@portal/shared/ui/filter';
import { DateFilterQuickPick } from './date/enums/quick-pick.enum';

declare const $localize;

@Component({
  selector: 'portal-wl-transaction-filter',
  templateUrl: './transaction-filter.component.html',
})
export class WLTransactionFilterComponent {
  searchEntities: ISelectInput[] = orderBy(
    [
      {
        id: SearchSelector.Rrn,
        text: $localize`RRN`,
      },
      {
        id: SearchSelector.TerminalId,
        text: $localize`Terminal ID`,
      },
      {
        id: SearchSelector.TransactionNo,
        text: $localize`STAN`,
      },
      {
        id: SearchSelector.Stan,
        text: $localize`Processor STAN`,
      },
      {
        id: SearchSelector.CustomerId,
        text: $localize`External Customer ID`,
      },
      {
        id: SearchSelector.SalesDescription,
        text: $localize`Sales Description`,
      },
      {
        id: SearchSelector.AcquirerSettlementId,
        text: $localize`Batch number`,
      },
      {
        id: SearchSelector.BatchUUID,
        text: $localize`Settlement Batch ID`,
      },
      {
        id: SearchSelector.TransactionId,
        text: $localize`Transaction ID`,
      },
      {
        id: SearchSelector.MerchantReference,
        text: $localize`Reference`,
      },
      {
        id: SearchSelector.MerchantReferenceId,
        text: $localize`Merchant reference`,
      },
      {
        id: SearchSelector.MaskedCardNumber,
        text: $localize`Bin Number`,
      },
      {
        id: SearchSelector.SubscriptionId,
        text: $localize`Subscription ID`,
      },
      {
        id: SearchSelector.ProcessorTransactionId,
        text: $localize`Provider Transaction ID`,
      },
      {
        id: SearchSelector.ReUseToken,
        text: $localize`Card token`,
      },
      {
        id: SearchSelector.CardNumber,
        text: $localize`Card number`,
      },
    ],
    'text',
  );
  dateFilterSelectors = [
    {
      id: SearchSelector.Created,
      label: $localize`Created`,
      searchSelectorFrom: SearchSelector.CreatedFrom,
      searchSelectorTo: SearchSelector.CreatedTo,
    },
    {
      id: SearchSelector.SettledDate,
      label: $localize`Settled`,
      searchSelectorFrom: SearchSelector.SettledDateFrom,
      searchSelectorTo: SearchSelector.SettledDateTo,
    },
  ];
  criteriaMap = new Map([
    [
      SearchSelector.CreatedFrom,
      {
        argument: this.getDateFilter().start,
        selector: SearchSelector.CreatedFrom,
        operator: SearchOperator.GreaterThanAndEqualTo,
      },
    ],
    [
      SearchSelector.CreatedTo,
      {
        argument: this.getDateFilter().end,
        selector: SearchSelector.CreatedTo,
        operator: SearchOperator.LessThanAndEqualTo,
      },
    ],
  ]);

  searchTermValue = '';
  removeEntity$ = new Subject<ISelectInput>();
  closeSubmenus$ = new Subject<boolean>();

  defaultSearchSelector = SearchSelector.TransactionId;
  staticFiltersMap: {
    [key: string]: ISelectInput[];
  } = {};
  selectedStaticFilters: ISelectInput[] = [];
  dateFilterQuickPick = DateFilterQuickPick.Custom;

  onSubmenusClosed(closeSubmenu: boolean): void {
    if (closeSubmenu) {
      this.closeSubmenus$.next(true);
    }
  }

  onStaticFilterUpdated(updatedFlter: IFilterToApply): void {
    if (updatedFlter) {
      const { toRemove, key, value } = updatedFlter;
      if (toRemove) {
        delete this.staticFiltersMap[key];
      } else {
        this.staticFiltersMap[key] = [];

        forEach(value, ({ id, text }: ISelectInput) => {
          this.staticFiltersMap[key].push({ id, text, key });
        });
      }
    }

    this.selectedStaticFilters = [];
    if (!isEmpty(this.staticFiltersMap)) {
      forEach(this.staticFiltersMap, (inputs: ISelectInput[]) => {
        this.selectedStaticFilters = concat(this.selectedStaticFilters, inputs);
      });
    }
  }

  removeStaticFilter(input: ISelectInput): void {
    this.selectedStaticFilters = without(this.selectedStaticFilters, input);
    this.removeEntity$.next(input);
  }

  private getDateFilter(): {
    start: string;
    end: string;
  } {
    const currentDate: Date = new Date();
    const timeMap = subDays(startOfDay(currentDate), 7);
    return {
      start: timeMap ? timeMap.toISOString() : subDays(startOfDay(currentDate), 30).toISOString(),
      end: endOfDay(currentDate).toISOString(),
    };
  }
}
