import { Pipe, PipeTransform } from '@angular/core';
import { LocaleCurrencyDecimals } from '../locale-currency-decimals/locale-currency-decimals.pipe';

@Pipe({
  name: 'currencyAmount',
})
export class CurrencyAmountPipe implements PipeTransform {
  constructor(private localeCurrencyDecimals: LocaleCurrencyDecimals) {}
  transform(value: string | number, currencyCode: string): string {
    return `${currencyCode} ${this.localeCurrencyDecimals.transform(value, currencyCode)}`;
  }
}
