import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from '@portal/entity-services/interfaces';
import { UserStatus } from '@portal/entity-services/interfaces';
import { UserStatuses } from '../../services/user-statuses.list';
import { UserAppmarketSettings } from '@portal/marketplace/api-interfaces/src/lib/user/user-appmarket-settings.enum';
import { Application } from '@portal/shared/auth/authorization/src/lib/enums/application.enum';
import { AuthorizationService } from '@portal/shared/auth/authorization/src/lib/authorization.service';
import { SelfCheckService } from '@portal/shared/auth/authorization/src/lib/self-check.service';
import { RestrictPermissionService } from '@portal/shared/auth/authorization/src/lib/services/restrict-permission.service';
import { BaseModalComponent } from '@portal/shared/ui/modal';
import { ToastService } from '@portal/shared/ui/toast';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';
import { ReasonsForDeletion } from './reasons.list';
import { UserRoles } from '@portal/shared/role-group-picker/src/lib/user-role.list';
import { CopyToClipboardComponent } from '@apps/portal/src/app/shared/components/copy-to-clipboard/copy-to-clipboard.component';
import { HeroService } from '@portal/shared/ui/layouts';
import { MenuRegion } from '@portal/shared/ui/layouts/src/lib/top-navbar/enum/menu-region.enum';

declare const $localize;

@Component({
  selector: 'portal-detail-user',
  templateUrl: './detail.component.html',
})
export class DetailUserComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmDeleteModal') confirmDeleteModal: BaseModalComponent;
  @ViewChild('confirmEnableModal') confirmEnableModal: BaseModalComponent;
  @ViewChild('confirmDisableModal') confirmDisableModal: BaseModalComponent;
  @ViewChild(CopyToClipboardComponent) copyAccountInfoButton: CopyToClipboardComponent;
  @ViewChild('disableButton') disableButton: ElementRef<HTMLButtonElement>;
  @ViewChild('deleteButton') deleteButton: ElementRef<HTMLButtonElement>;
  @ViewChild('enableButton') enableButton: ElementRef<HTMLButtonElement>;
  @ViewChild('editButton') editButton: ElementRef<HTMLAnchorElement>;
  @ViewChild('backToUserLink') backToUserLink: ElementRef<HTMLAnchorElement>;

  loading$: Observable<boolean>;
  user: IUser;
  userId: string;
  statuses = UserStatus;
  displayedStatus: string;
  selfEditMode: boolean;
  selfEditPermission: string[];
  isPetroUser;
  notificationUpdateStatus: boolean;
  copyButtonConfig;
  copyMessage;
  version;
  environmentFormat;
  domainURL;
  reasonForDeletion = '';
  disableDelete = false;
  appStatusValue: string;

  reasonsForDeletionOptions = this.reasonsForDeletion.list;

  isUserDeleted = false;

  constructor(
    @Inject('Window') private window,
    public userStatuses: UserStatuses,
    private toastService: ToastService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private title: Title,
    private liveAnnouncer: LiveAnnouncer,
    private selfCheckService: SelfCheckService,
    public authService: AuthorizationService,
    private restrictPermissionService: RestrictPermissionService,
    private reasonsForDeletion: ReasonsForDeletion,
    private userRolesList: UserRoles,
    private heroService: HeroService,
  ) {
    this.loading$ = userService.detailLoading$;
    this.copyButtonConfig = {
      text: $localize`Copy account information`,
      class: 'button is-size-8-mobile',
      iconClass: 'far fa-clone fa-align-left',
    };
    this.copyMessage = $localize`Your account information was successfully copied to clipboard`;
    this.version = localStorage.getItem('version');
    this.domainURL = this.window.location?.origin;
    this.environmentFormat = this.formatEnvironment();
  }

  ngOnInit(): void {
    this.heroService.showTitle($localize`Administration`, MenuRegion.Administration);
    this.activatedRoute.paramMap.subscribe((params) => {
      this.selfEditMode = this.selfCheckService.isMyProfilePage(this.activatedRoute.snapshot);
      this.selfEditPermission = this.selfEditMode ? ['self-update'] : ['update'];

      this.userId = params.get('id');
      if (this.userId) {
        this.getUser();
      }
      this.isPetroUser = this.authService.isApplicationUser(Application.Petro);

      const permissions = this.restrictPermissionService.getPermissionsForRoute(
        this.activatedRoute,
      );

      if (
        this.restrictPermissionService.checkPermissions(permissions, ['app-notification-setting'])
      ) {
        /**
         * This block will be removed in the future after API moved to ES
         */
        this.userService.getAppMarketUserSettings().subscribe((data) => {
          let value = data.data.find(
            (input) => input.name === UserAppmarketSettings.AppUpdateNotification,
          )?.value;

          // according to AC value should be 'true' by default
          if (typeof value === 'undefined') {
            value = 'true';
          }

          this.notificationUpdateStatus = value === 'true';
        });
      }
    });
    this.appStatusValue = this.notificationUpdateStatus ? $localize`Yes` : $localize`No`;
  }

  ngAfterViewInit(): void {
    this.setFocus(0);
  }

  setFocus(counter: number): void {
    if (this.copyAccountInfoButton) {
      this.copyAccountInfoButton.focusButton?.nativeElement?.focus();
    } else if (this.disableButton) {
      this.disableButton.nativeElement.focus();
    } else if (this.deleteButton) {
      this.deleteButton.nativeElement.focus();
    } else if (this.enableButton) {
      this.enableButton.nativeElement.focus();
    } else if (this.editButton) {
      this.editButton.nativeElement.focus();
    } else if (this.backToUserLink) {
      this.backToUserLink.nativeElement.focus();
    } else if (counter < 5) {
      setTimeout(() => {
        this.setFocus(counter + 1);
      }, 1000);
    }
  }

  get disableDeleteButton(): boolean {
    return !this.reasonForDeletion || this.disableDelete;
  }

  hasUserPermission(): boolean {
    return this.authService.hasPermissionToManage(this.user?.roles);
  }

  getUser(): void {
    this.userService.getByKey(this.userId).subscribe((user: IUser) => {
      if (user.status === UserStatus.Deleted) {
        this.isUserDeleted = true;
      }
      this.user = user;
      this.title.setTitle(this.user.name);
      this.displayedStatus = this.userStatuses.lookup(this.user?.status)?.text;
      this.liveAnnouncer.announce($localize`Navigated to ${this.title.getTitle()}`);
    });
  }

  delete(): void {
    this.disableDelete = true;
    this.userService.delete(this.user.userUid, this.reasonForDeletion).subscribe(() => {
      this.toastService.showToast($localize`User (${this.userId}) was successfully deleted`);
      this.router.navigate(['/administration/users']);
    });
  }

  enable(): void {
    this.userService
      .partialUpdate({
        userUid: this.user.userUid,
        status: UserStatus.Active,
      })
      .subscribe((updatedUser: IUser) => {
        this.user = this.restoreUserEntity(updatedUser);
        this.toastService.showToast(
          $localize`User (${updatedUser.userUid}) was successfully enabled`,
        );
        this.updateDisplayedStatusOnChange();
      });
  }

  disable(): void {
    this.userService
      .partialUpdate({
        userUid: this.user.userUid,
        status: UserStatus.Inactive,
      })
      .subscribe((updatedUser: IUser) => {
        this.user = this.restoreUserEntity(updatedUser);
        this.toastService.showToast(
          $localize`User (${updatedUser.userUid}) was successfully disabled`,
        );
        this.updateDisplayedStatusOnChange();
      });
  }

  onConfirmDelete(): void {
    this.confirmDeleteModal.open();
  }

  onModalConfirmed(): void {
    this.delete();
  }

  onClickEnableButton(): void {
    this.confirmEnableModal.open();
    this.setFocusOnCloseButton(this.confirmEnableModal);
  }

  onEnableModalConfirmed(): void {
    this.enable();
    this.confirmEnableModal.close();
  }

  onClickDisableButton(): void {
    this.confirmDisableModal.open();
    this.setFocusOnCloseButton(this.confirmDisableModal);
  }

  onDisableModalConfirmed(): void {
    this.disable();
    this.confirmDisableModal.close();
  }

  setFocusOnCloseButton(modal): void {
    setTimeout(() => {
      if (modal.activeState) {
        modal.setFocusOnCloseButton();
      }
    });
  }

  updateDisplayedStatusOnChange(): void {
    this.displayedStatus = this.userStatuses.lookup(this.user?.status)?.text;
  }

  formatCopyText(): string {
    return `
      Name of environment: ${this.formatEnvironment()} |
      Portal release version: ${this.version} |
      user: ${this.user?.name} | email: ${this.user.email} |
      Roles: ${this.user?.roles?.join(', ')} |
      organization: ${this.user?.entity?.name}`;
  }

  onCloseDeleteModal(): void {
    this.reasonForDeletion = '';
  }

  private formatEnvironment(): string {
    const environmentDomain = new Map([
      ['localhost:4200', 'local'],
      ['devportal.dimebox.com', 'DEV'],
      ['qatportal.dimebox.com', 'QAT'],
      ['emea.test.verifone.cloud', 'CST EMEA'],
      ['test.verifone.cloud', 'CST EMEA'],
      ['uscst.test.vficloud.net', 'CST US'],
      ['emea.live.verifone.cloud', 'PROD-EMEA'],
      ['us.live.verifone.cloud', 'PROD-US'],
      ['nz.live.verifone.cloud', 'PROD-NZ'],
    ]);

    const envName = environmentDomain.get(this.domainURL?.replace(/(^\w+:|^)\/\//, ''));

    return `${envName} / ${this.domainURL}`;
  }

  private restoreUserEntity(user: IUser): IUser {
    return {
      ...user,
      entity: this.user?.entity,
    };
  }
}
