<div class="checkbox mb-1" *ngIf="isCompareType">
  <!--  GAD TODO HERE -->
  <input
    id="{{ labelName }}"
    type="checkbox"
    name="{{ accessoryName }}"
    [checked]="isCheckedDefault"
    (click)="onCheckboxToggle()"
    attr.data-e2e="{{ selector }}-input-checkbox"
  />
  <label for="{{ labelName }}" class="label">{{ labelName }}</label>
</div>
<label *ngIf="!isCompareType" class="label">{{ labelName }}</label>
<div class="dropdown" [class.is-active]="isOpen" attr.data-e2e="{{ selector }}-button-dropdown">
  <div class="drodown-trigger">
    <button
      [className]="isNewDesignFilter ? 'button' : 'button is-chip is-size-7-mobile'"
      [class.is-applied]="selectedEntities.size"
      [class.is-active]="isOpen"
      [attr.aria-expanded]="isOpen"
      [disabled]="!isCheckedDefault"
      (click)="toggleOpen()"
      (keydown.space)="onKeyDownSpace($event)"
      attr.data-e2e="{{ selector }}-button-open"
      title="{{ dateHeaderText }}"
      type="button"
      #dateFilter
    >
      <span attr.data-e2e="{{ selector }}-button-text">{{ dateHeaderText }}</span>
      <span class="icon">
        <i class="fas fa-chevron-down"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu">
    <div class="dropdown-content">
        <div class="is-flex has-border-bottom">
            <div class="is-flex-grow-1 has-border-right quickpicks-label">
              <div class="ml-1 mt-4">
                <label class="label ml-3"
                  ><span attr.data-e2e="{{ selector }}-title-quickpicks" i18n
                    >Quickpicks</span
                  ></label
                >
              </div>
              <div *ngFor="let quickPick of quickPicks" class="mt-1">
                <a
                  tabindex="0"
                  class="dropdown-item py-2"
                  attr.data-e2e="{{ selector }}-{{ quickPick.id }}-button-quickpick"
                  [ngClass]="{ 'is-active': selectedQuickPick === quickPick.id }"
                  (click)="onQuickPickApplied(quickPick.id)"
                  (keydown.space)="onQuickPickApplied(quickPick.id)"
                  (keydown.enter)="onQuickPickApplied(quickPick.id)"
                  title="{{ quickPick.text }}"
                >
                  <span class="ml-1">{{ quickPick.text }}</span>
                </a>
              </div>
            </div>
            <div class="is-flex-grow-2">
              <portal-date-picker
                [fromDate]="fromDate"
                [toDate]="toDate"
                (fromDateApplied)="onFromDateApplied($event)"
                (toDateApplied)="onToDateApplied($event)"
                (isInvalidDate)="updateIsInvalidDate($event)"
              >
              </portal-date-picker>
            </div>
        </div>
        <div class="is-flex-grow-0 p-3">
          <div class="is-flex">
            <button
              class="button is-primary"
              attr.data-e2e="{{ selector }}-button-apply"
              (click)="onFilterApplied()"
              (keydown.space)="onKeyDownSpaceApply($event)"
              [disabled]="!areDatesValid()"
              type="button"
              i18n
              title="Apply"
              i18n-title
            >
              Apply
            </button>
            <button
              class="button is-primary is-outlined ml-3"
              attr.data-e2e="{{ selector }}-button-cancel"
              (click)="itemToggled.emit()"
              type="button"
              i18n
              title="Cancel"
              i18n-title
            >
              Cancel
            </button>
          </div>
        </div>
    </div>
  </div>
</div>
