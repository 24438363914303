<div class="secure-card" *ngIf="canEdit || loading ? true : publicKeys?.length">
  <div class="box">
    <h2 class="title is-3" i18n>Secure card capture</h2>

    <!-- Show only if there is no existing key -->
    <div *ngIf="!totalCount" data-e2e="secureCardCapture-generateNewKey">
      <p class="is-6 secure-card__subtitle" i18n>Generate a new public key.</p>
    </div>
    <!-- /Show only if there is no existing key -->

    <!-- Public key list -->
    <ng-container *ngIf="totalCount">
      <div class="table-container" data-e2e="secureCardCapture-publicKeysTable">
        <table class="table is-hoverable is-focusable is-fullwidth" [class.is-loading]="loading">
          <thead>
            <tr>
              <th i18n>Key alias</th>
              <th i18n>Created date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let publicKey of publicKeys">
              <td>
                {{ publicKey.public_key_alias }}
              </td>
              <td>
                {{ publicKey.created_at | localeDate : 'medium' }}
              </td>
              <td class="has-text-right action-column">
                <button
                  type="button"
                  class="button"
                  data-e2e="secureCardCapture-viewPublicKeyButton"
                  (click)="viewPublicKey(publicKey)"
                  title="View Public key"
                  i18n-title
                >
                  <span class="icon">
                    <i class="fas fa-external-link"></i>
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <!-- Public key list -->

    <button
      type="button"
      class="button is-primary is-outlined secure-card__generate-key-button mb-3"
      data-e2e="secureCardCapture-generateKey"
      (click)="generateKey()"
      *ngIf="canEdit"
      i18n
      title="Create new public key"
      i18n-title
    >
      Create new public key
    </button>
    <portal-pagination
      [reset]="resetPagination"
      [itemsPerPage]="filter.limit"
      [totalItems]="totalCount"
      [pageSizeOptions]="pageSizeOptions"
      (paginationChanged)="onPagination($event)"
      [goto]="false"
    ></portal-pagination>
  </div>
</div>

<!-- Key viewer modal -->
<portal-base-modal #keyViewerModal>
  <div title i18n>Public key for {{ selectedKey?.public_key_alias }}</div>
  <div modal-content>
    <div
      class="container public-key-container mt-1 has-background-white-bis py-3 px-4 has-border-top has-border-right has-border-left has-border-bottom"
      data-e2e="secureCardCapture-publicKey"
    >
      <code class="is-size-6 p-0 has-text-black-bis has-background-white-bis">
        {{ selectedKey?.public_key }}
      </code>
    </div>
  </div>
  <div class="level" buttons>
    <button
      class="level-left button has-text-danger is-text"
      (click)="keyViewerModal.close()"
      type="button"
      i18n
      title="Close"
      i18n-title
    >
      Close
    </button>
    <ng-container class="level-right">
      <portal-copy-to-clipboard
        [valueToCopy]="selectedKey?.public_key"
        [buttonConfig]="copyButtonConfig"
        [copyMessage]="copyMessage"
        dataE2eId="secureCardCapture-copyButton"
      ></portal-copy-to-clipboard>
    </ng-container>
  </div>
</portal-base-modal>
<!-- /Key viewer modal -->
