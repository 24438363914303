export enum ApplicationRoot {
  Marketplace = 'marketplace',
  Developer = 'developer',
  EstateOwner = 'estate-owner',
  MarketplaceManager = 'marketplace-manager',
  Petro = 'petro',
  VHQ = 'vhq',
  MOP = 'mop',
  Payments = 'payments',
  Wrapper = '',
}
