import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RestrictPermissionRouterLinkDirective } from './restrict-permission-router-link.directive';
import { RestrictPermissionDirective } from './restrict-permission.directive';
import { RoleGuardService } from './role-guard.service';
import { SelfCheckService } from './self-check.service';
import { CanLoadVHQModuleGuard } from './vhq-module-guard.service';
import { AuthorizationGuard } from './authorization.guard';
import { NotifierModule } from '@portal/shared/ui/notifier';
import { UserModule } from '@portal/shared/user/src/lib/user.module';

@NgModule({
  imports: [CommonModule, UserModule, NotifierModule],
  declarations: [RestrictPermissionDirective, RestrictPermissionRouterLinkDirective],
  providers: [AuthorizationGuard, RoleGuardService, SelfCheckService, CanLoadVHQModuleGuard],
  exports: [RestrictPermissionDirective, RestrictPermissionRouterLinkDirective],
})
export class SharedAuthAuthorizationModule {}
