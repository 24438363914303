import { ChangeDetectorRef, Component } from '@angular/core';
import { Notification } from '@portal/shared/ui/notifier/src';
import { NotificationType } from './notification-type.enum';
import { Notifier } from './notifier';
import { NotifierService } from './notifier.service';

@Component({
  selector: 'portal-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss'],
})
export class NotifierComponent {
  FAIL = NotificationType.Fail;
  SUCCESS = NotificationType.Success;
  INFO = NotificationType.Info;
  WARN = NotificationType.Warn;
  notifier: Notifier;

  constructor(private notifierService: NotifierService, private cdRef: ChangeDetectorRef) {
    this.notifier = notifierService.notifier;
  }

  destroy(notification: Notification): void {
    this.notifier.destroy(notification);
    this.cdRef.detectChanges();
  }
}
