import { Component } from '@angular/core';

@Component({
  selector: 'vui-change-password-page',
  templateUrl: './change-password-page.component.html',
})
export class ChangePasswordPageComponent {
  config = {
    showBacklink: false,
    navigateUrl: '/',
  };
}
