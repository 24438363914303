<div [ngClass]="{ dropdown: showFilterSubMenu }">
  <div [ngClass]="{ 'dropdown-menu is-block': showFilterSubMenu }">
    <div
      [ngClass]="isMainDropdownFilter ? 'dropdown' : 'dropdown-content multi-select-filter'"
      [class.is-active]="isOpen || isSubMenuFilter"
      [class.is-hidden]="!isOpen && !isMainDropdownFilter && !isActive"
    >
      <a
        class="is-hidden"
        (click)="openContent()"
        id="filter-merchant-id-detail"
        title="Open merchant ID filter"
        i18n-title
      >
        <i class="fas fa-chevron-right" aria-hidden="true"></i>
      </a>
      <div class="dropdown-trigger" *ngIf="!isSubMenuFilter">
        <button
          [attr.aria-expanded]="isOpen"
          id="merchFilterButton"
          class="button is-chip is-size-7-mobile"
          [class.is-applied]="getValues().length"
          [class.is-active]="isOpen"
          data-e2e="button-merchant-id-filter"
          (click)="itemToggled.emit()"
          type="button"
          title="Merchant ID"
          i18n-title
        >
          <span>{{ merchantIdText }}</span>
          <span class="icon">
            <i class="fas fa-chevron-down"></i>
          </span>
        </button>
      </div>
      <div
        [className]="isSubMenuFilter ? 'multi-select-filter' : 'dropdown-menu is-large'"
        [class.is-hidden]="closeSubMenu"
      >
        <div class="dropdown-content">
          <div class="dropdown-item">
            <a
              *ngIf="!isMainDropdownFilter && !displayFromFilter"
              tabindex="0"
              (click)="backToMenu()"
              (keyup.enter)="backToMenu()"
              data-e2e="button-back-to-menu"
              title="Click to go back"
              i18n-title
            >
              <span class="icon is-small">
                <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
              </span>
              <span i18n>Back</span>
            </a>

            <h2
              *ngIf="!isMainDropdownFilter && !displayFromFilter"
              class="title is-4 has-margin-top has-margin-bottom"
              i18n
            >
              Merchant ID
            </h2>
            <portal-search-filter-autocomplete-input
              [searchInputValue]="searchedText"
              (search)="onSearch($event)"
            ></portal-search-filter-autocomplete-input>
          </div>
          <hr class="dropdown-divider" />

          <ng-container *ngIf="showSelectedContracts?.size">
            <div class="dropdown-item is-scrollable has-max-height-250">
              <label class="label" i18n="Label for selected filter items">Selected</label>
              <ng-container *ngFor="let contract of showSelectedContracts | keyvalue">
                <ng-template
                  let-contractUid="contractUid"
                  let-merchantId="merchantId"
                  let-entityName="entityName"
                  [ngTemplateOutletContext]="{
                    contractUid: contract.value.id,
                    entityName: contract.value.text,
                    merchantId: contract.value.merchantId
                  }"
                  [ngTemplateOutlet]="selectedEntities"
                  #selectedEntities
                >
                  <portal-search-filter-checkbox
                    #preSelectedEntities
                    [id]="contractUid"
                    [text]="
                      entityName.includes(merchantId)
                        ? entityName
                        : merchantId + ' (' + entityName + ')'
                    "
                    [merchantId]="merchantId"
                    [selectedEntities]="localPreSelectedContracts"
                    (removed)="onContractRemoved($event)"
                    (added)="onContractAdded($event)"
                  ></portal-search-filter-checkbox>
                </ng-template>
              </ng-container>
            </div>

            <hr class="dropdown-divider" />
          </ng-container>

          <div class="dropdown-item has-text-truncate pt-0">
            <span class="has-text-truncate" *ngIf="loading$ | async">
              <portal-spinner
                [isHorizontal]="true"
                [isSmall]="true"
                text="Loading merchant ids..."
                i18n-text
              ></portal-spinner>
            </span>
          </div>

          <div class="dropdown-item is-scrollable has-max-height-250" *ngIf="!(loading$ | async)">
            <span class="has-text-truncate" *ngIf="contracts?.length && !searchedText" i18n>
              Start typing to search for merchant ids..
            </span>
            <div *ngIf="!contracts?.length && !isSearchValueSelected() && searchedText" i18n>
              No PPC found with this Merchant ID. Please click Apply if you still want to use this
              value for the filter.
            </div>

            <ng-container *ngFor="let contract of contracts">
              <portal-search-filter-checkbox
                [id]="contract.contractUid"
                [text]="contract.merchantId + ' (' + contract.entity.name + ')'"
                [merchantId]="contract.merchantId"
                [selectedEntities]="localPreSelectedContracts"
                [searchedText]="searchedText"
                (removed)="onContractRemoved($event)"
                (added)="onContractAdded($event)"
              ></portal-search-filter-checkbox>
            </ng-container>
          </div>

          <hr class="dropdown-divider" />

          <div class="dropdown-item">
            <button
              class="button is-primary"
              data-e2e="button-filter"
              i18n
              (click)="onFilterApplied()"
              type="button"
              title="Apply"
              i18n-title
            >
              Apply
            </button>
            <button
              class="button is-outlined ml-2"
              data-e2e="button-clear-filter"
              i18n
              (click)="onClear()"
              (keydown.enter)="onClear()"
              type="button"
              title="Clear"
              i18n-title
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
