import { Injectable } from '@angular/core';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({
  providedIn: 'root',
})
export class ThirdPartOfSubdivisionsList {
  list: ISelectInput[];
  keyValue = {
    ['JE-JE']: $localize`Jersey`,
    ['JO-AJ']: $localize`ʽAjlūn`,
    ['JO-AM']: $localize`‘Ammān`,
    ['JO-AQ']: $localize`Al ʽAqabah`,
    ['JO-AT']: $localize`Aţ Ţafīlah`,
    ['JO-AZ']: $localize`Az Zarqā'`,
    ['JO-BA']: $localize`Al Balqā'`,
    ['JO-IR']: $localize`Irbid`,
    ['JO-JA']: $localize`Jarash`,
    ['JO-KA']: $localize`Al Karak`,
    ['JO-MA']: $localize`Al Mafraq`,
    ['JO-MD']: $localize`Mādabā`,
    ['JO-MN']: $localize`Ma'ān`,
    ['KZ-AKM']: $localize`Akmolinskaja oblast'`,
    ['KZ-AKT']: $localize`Aktjubinskaja oblast'`,
    ['KZ-ALA']: $localize`Almaty`,
    ['KZ-ALM']: $localize`Almatinskaja oblast'`,
    ['KZ-AST']: $localize`Astana`,
    ['KZ-ATY']: $localize`Atyraū oblysy`,
    ['KZ-KAR']: $localize`Karagandinskaja oblast'`,
    ['KZ-KUS']: $localize`Kostanajskaja oblast'`,
    ['KZ-KZY']: $localize`Kyzylordinskaja oblast'`,
    ['KZ-MAN']: $localize`Mangghystaū oblysy`,
    ['KZ-PAV']: $localize`Pavlodar oblysy`,
    ['KZ-SEV']: $localize`Severo-Kazahstanskaja oblast'`,
    ['KZ-VOS']: $localize`Shyghys Qazaqstan oblysy`,
    ['KZ-YUZ']: $localize`Južno-Kazahstanskaja oblast'`,
    ['KZ-ZAP']: $localize`Batys Qazaqstan oblysy`,
    ['KZ-ZHA']: $localize`Žambylskaja oblast'`,
    ['KE-01']: $localize`Baringo`,
    ['KE-02']: $localize`Bomet`,
    ['KE-03']: $localize`Bungoma`,
    ['KE-04']: $localize`Busia`,
    ['KE-05']: $localize`Elgeyo/Marakwet`,
    ['KE-06']: $localize`Embu`,
    ['KE-07']: $localize`Garissa`,
    ['KE-08']: $localize`Homa Bay`,
    ['KE-09']: $localize`Isiolo`,
    ['KE-10']: $localize`Kajiado`,
    ['KE-11']: $localize`Kakamega`,
    ['KE-12']: $localize`Kericho`,
    ['KE-13']: $localize`Kiambu`,
    ['KE-14']: $localize`Kilifi`,
    ['KE-15']: $localize`Kirinyaga`,
    ['KE-16']: $localize`Kisii`,
    ['KE-17']: $localize`Kisumu`,
    ['KE-18']: $localize`Kitui`,
    ['KE-19']: $localize`Kwale`,
    ['KE-20']: $localize`Laikipia`,
    ['KE-21']: $localize`Lamu`,
    ['KE-22']: $localize`Machakos`,
    ['KE-23']: $localize`Makueni`,
    ['KE-24']: $localize`Mandera`,
    ['KE-25']: $localize`Marsabit`,
    ['KE-26']: $localize`Meru`,
    ['KE-27']: $localize`Migori`,
    ['KE-28']: $localize`Mombasa`,
    ['KE-29']: $localize`Murang'a`,
    ['KE-30']: $localize`Nairobi City`,
    ['KE-31']: $localize`Nakuru`,
    ['KE-32']: $localize`Nandi`,
    ['KE-33']: $localize`Narok`,
    ['KE-34']: $localize`Nyamira`,
    ['KE-35']: $localize`Nyandarua`,
    ['KE-36']: $localize`Nyeri`,
    ['KE-37']: $localize`Samburu`,
    ['KE-38']: $localize`Siaya`,
    ['KE-39']: $localize`Taita/Taveta`,
    ['KE-40']: $localize`Tana River`,
    ['KE-41']: $localize`Tharaka-Nithi`,
    ['KE-42']: $localize`Trans Nzoia`,
    ['KE-43']: $localize`Turkana`,
    ['KE-44']: $localize`Uasin Gishu`,
    ['KE-45']: $localize`Vihiga`,
    ['KE-46']: $localize`Wajir`,
    ['KE-47']: $localize`West Pokot`,
    ['KI-G']: $localize`Gilbert Islands`,
    ['KI-L']: $localize`Line Islands`,
    ['KI-P']: $localize`Phoenix Islands`,
    ['KP-01']: $localize`P'yǒngyang`,
    ['KP-02']: $localize`P'yǒngan-namdo`,
    ['KP-03']: $localize`P'yǒngan-bukto`,
    ['KP-04']: $localize`Chagang-do`,
    ['KP-05']: $localize`Hwanghae-namdo`,
    ['KP-06']: $localize`Hwanghae-bukto`,
    ['KP-07']: $localize`Kangweonto`,
    ['KP-08']: $localize`Hamgyǒng-namdo`,
    ['KP-09']: $localize`Hamgyǒng-bukto`,
    ['KP-10']: $localize`Ryangkangto`,
    ['KP-13']: $localize`Nasǒn`,
    ['KR-11']: $localize`Seoul-T'ǔkpyǒlshi`,
    ['KR-26']: $localize`Busan Gwang'yeogsi`,
    ['KR-27']: $localize`Daegu Gwang'yeogsi`,
    ['KR-28']: $localize`Inch'ǒn-Kwangyǒkshi`,
    ['KR-29']: $localize`Gwangju Gwang'yeogsi`,
    ['KR-30']: $localize`Daejeon Gwang'yeogsi`,
    ['KR-31']: $localize`Ulsan Gwang'yeogsi`,
    ['KR-41']: $localize`Gyeonggido`,
    ['KR-42']: $localize`Gang'weondo`,
    ['KR-43']: $localize`Ch'ungch'ǒngbuk-do`,
    ['KR-44']: $localize`Ch'ungch'ǒngnam-do`,
    ['KR-45']: $localize`Chǒllabuk-do`,
    ['KR-46']: $localize`Chǒllanam-do`,
    ['KR-47']: $localize`Gyeongsangbugdo`,
    ['KR-48']: $localize`Gyeongsangnamdo`,
    ['KR-49']: $localize`Cheju-do`,
    ['KR-50']: $localize`Sejong`,
    ['KW-AH']: $localize`Al Aḩmadī`,
    ['KW-FA']: $localize`Al Farwānīyah`,
    ['KW-HA']: $localize`Ḩawallī`,
    ['KW-JA']: $localize`Al Jahrā’`,
    ['KW-KU']: $localize`Al Kuwayt`,
    ['KW-MU']: $localize`Mubārak al Kabīr`,
    ['KG-B']: $localize`Batken`,
    ['KG-C']: $localize`Chü`,
    ['KG-GB']: $localize`Bishkek`,
    ['KG-GO']: $localize`Gorod Oš`,
    ['KG-J']: $localize`Džalal-Abadskaja oblast'`,
    ['KG-N']: $localize`Naryn`,
    ['KG-O']: $localize`Osh`,
    ['KG-T']: $localize`Talas`,
    ['KG-Y']: $localize`Issyk-Kul'skaja oblast'`,
    ['LA-AT']: $localize`Attapu`,
    ['LA-BK']: $localize`Bokèo`,
    ['LA-BL']: $localize`Bolikhamxai`,
    ['LA-CH']: $localize`Champasak`,
    ['LA-HO']: $localize`Houaphan`,
    ['LA-KH']: $localize`Khammouan`,
    ['LA-LM']: $localize`Louang Namtha`,
    ['LA-LP']: $localize`Louangphabang`,
    ['LA-OU']: $localize`Oudomsai`,
    ['LA-PH']: $localize`Phôngsali`,
    ['LA-SL']: $localize`Salavan`,
    ['LA-SV']: $localize`Savannakhét`,
    ['LA-VI']: $localize`Vientiane`,
    ['LA-VT']: $localize`Vientiane`,
    ['LA-XA']: $localize`Sayaboury`,
    ['LA-XE']: $localize`Sékong`,
    ['LA-XI']: $localize`Xiangkhouang`,
    ['LA-XN']: $localize`Xaisômboun`,
    ['LV-001']: $localize`Aglonas novads`,
    ['LV-002']: $localize`Aizkraukles novads`,
    ['LV-003']: $localize`Aizputes novads`,
    ['LV-004']: $localize`Aknīstes novads`,
    ['LV-005']: $localize`Alojas novads`,
    ['LV-006']: $localize`Alsungas novads`,
    ['LV-007']: $localize`Alūksnes novads`,
    ['LV-008']: $localize`Amatas novads`,
    ['LV-009']: $localize`Apes novads`,
    ['LV-010']: $localize`Auces novads`,
    ['LV-011']: $localize`Ādažu novads`,
    ['LV-012']: $localize`Babītes novads`,
    ['LV-013']: $localize`Baldones novads`,
    ['LV-014']: $localize`Baltinavas novads`,
    ['LV-015']: $localize`Balvu novads`,
    ['LV-016']: $localize`Bauskas novads`,
    ['LV-017']: $localize`Beverīnas novads`,
    ['LV-018']: $localize`Brocēnu novads`,
    ['LV-019']: $localize`Burtnieku novads`,
    ['LV-020']: $localize`Carnikavas novads`,
    ['LV-021']: $localize`Cesvaines novads`,
    ['LV-022']: $localize`Cēsu novads`,
    ['LV-023']: $localize`Ciblas novads`,
    ['LV-024']: $localize`Dagdas novads`,
    ['LV-025']: $localize`Daugavpils novads`,
    ['LV-026']: $localize`Dobeles novads`,
    ['LV-027']: $localize`Dundagas novads`,
    ['LV-028']: $localize`Durbes novads`,
    ['LV-029']: $localize`Engures novads`,
    ['LV-030']: $localize`Ērgļu novads`,
    ['LV-031']: $localize`Garkalnes novads`,
    ['LV-032']: $localize`Grobiņas novads`,
    ['LV-033']: $localize`Gulbenes novads`,
    ['LV-034']: $localize`Iecavas novads`,
    ['LV-035']: $localize`Ikšķiles novads`,
    ['LV-036']: $localize`Ilūkstes novads`,
    ['LV-037']: $localize`Inčukalna novads`,
    ['LV-038']: $localize`Jaunjelgavas novads`,
    ['LV-039']: $localize`Jaunpiebalgas novads`,
    ['LV-040']: $localize`Jaunpils novads`,
    ['LV-041']: $localize`Jelgavas novads`,
    ['LV-042']: $localize`Jēkabpils novads`,
    ['LV-043']: $localize`Kandavas novads`,
    ['LV-044']: $localize`Kārsavas novads`,
    ['LV-045']: $localize`Kocēnu novads`,
    ['LV-046']: $localize`Kokneses novads`,
    ['LV-047']: $localize`Krāslavas novads`,
    ['LV-048']: $localize`Krimuldas novads`,
    ['LV-049']: $localize`Krustpils novads`,
    ['LV-050']: $localize`Kuldīgas novads`,
    ['LV-051']: $localize`Ķeguma novads`,
    ['LV-052']: $localize`Ķekavas novads`,
    ['LV-053']: $localize`Lielvārdes novads`,
    ['LV-054']: $localize`Limbažu novads`,
    ['LV-055']: $localize`Līgatnes novads`,
    ['LV-056']: $localize`Līvānu novads`,
    ['LV-057']: $localize`Lubānas novads`,
    ['LV-058']: $localize`Ludzas novads`,
    ['LV-059']: $localize`Madonas novads`,
    ['LV-060']: $localize`Mazsalacas novads`,
    ['LV-061']: $localize`Mālpils novads`,
    ['LV-062']: $localize`Mārupes novads`,
    ['LV-063']: $localize`Mērsraga novads`,
    ['LV-064']: $localize`Naukšēnu novads`,
    ['LV-065']: $localize`Neretas novads`,
    ['LV-066']: $localize`Nīcas novads`,
    ['LV-067']: $localize`Ogres novads`,
    ['LV-068']: $localize`Olaines novads`,
    ['LV-069']: $localize`Ozolnieku novads`,
    ['LV-070']: $localize`Pārgaujas novads`,
    ['LV-071']: $localize`Pāvilostas novads`,
    ['LV-072']: $localize`Pļaviņu novads`,
    ['LV-073']: $localize`Preiļu novads`,
    ['LV-074']: $localize`Priekules novads`,
    ['LV-075']: $localize`Priekuļu novads`,
    ['LV-076']: $localize`Raunas novads`,
    ['LV-077']: $localize`Rēzeknes novads`,
    ['LV-078']: $localize`Riebiņu novads`,
    ['LV-079']: $localize`Rojas novads`,
    ['LV-080']: $localize`Ropažu novads`,
    ['LV-081']: $localize`Rucavas novads`,
    ['LV-082']: $localize`Rugāju novads`,
    ['LV-083']: $localize`Rundāles novads`,
    ['LV-084']: $localize`Rūjienas novads`,
    ['LV-085']: $localize`Salas novads`,
    ['LV-086']: $localize`Salacgrīvas novads`,
    ['LV-087']: $localize`Salaspils novads`,
    ['LV-088']: $localize`Saldus novads`,
    ['LV-089']: $localize`Saulkrastu novads`,
    ['LV-090']: $localize`Sējas novads`,
    ['LV-091']: $localize`Siguldas novads`,
    ['LV-092']: $localize`Skrīveru novads`,
    ['LV-093']: $localize`Skrundas novads`,
    ['LV-094']: $localize`Smiltenes novads`,
    ['LV-095']: $localize`Stopiņu novads`,
    ['LV-096']: $localize`Strenču novads`,
    ['LV-097']: $localize`Talsu novads`,
    ['LV-098']: $localize`Tērvetes novads`,
    ['LV-099']: $localize`Tukuma novads`,
    ['LV-100']: $localize`Vaiņodes novads`,
    ['LV-101']: $localize`Valkas novads`,
    ['LV-102']: $localize`Varakļānu novads`,
    ['LV-103']: $localize`Vārkavas novads`,
    ['LV-104']: $localize`Vecpiebalgas novads`,
    ['LV-105']: $localize`Vecumnieku novads`,
    ['LV-106']: $localize`Ventspils novads`,
    ['LV-107']: $localize`Viesītes novads`,
    ['LV-108']: $localize`Viļakas novads`,
    ['LV-109']: $localize`Viļānu novads`,
    ['LV-110']: $localize`Zilupes novads`,
    ['LV-DGV']: $localize`Daugavpils`,
    ['LV-JEL']: $localize`Jelgava`,
    ['LV-JKB']: $localize`Jēkabpils`,
    ['LV-JUR']: $localize`Jūrmala`,
    ['LV-LPX']: $localize`Liepāja`,
    ['LV-REZ']: $localize`Rēzekne`,
    ['LV-RIX']: $localize`Rīga`,
    ['LV-VEN']: $localize`Ventspils`,
    ['LV-VMR']: $localize`Valmiera`,
    ['LB-AK']: $localize`Aakkâr`,
    ['LB-AS']: $localize`Ash Shimāl`,
    ['LB-BA']: $localize`Bayrūt`,
    ['LB-BH']: $localize`Baalbek-Hermel`,
    ['LB-BI']: $localize`Al Biqā‘`,
    ['LB-JA']: $localize`Al Janūb`,
    ['LB-JL']: $localize`Jabal Lubnān`,
    ['LB-NA']: $localize`An Nabaţīyah`,
    ['LS-A']: $localize`Maseru`,
    ['LS-B']: $localize`Butha-Buthe`,
    ['LS-C']: $localize`Leribe`,
    ['LS-D']: $localize`Berea`,
    ['LS-E']: $localize`Mafeteng`,
    ['LS-F']: $localize`Mohale's Hoek`,
    ['LS-G']: $localize`Quthing`,
    ['LS-H']: $localize`Qacha's Nek`,
    ['LS-J']: $localize`Mokhotlong`,
    ['LS-K']: $localize`Thaba-Tseka`,
    ['LR-BG']: $localize`Bong`,
    ['LR-BM']: $localize`Bomi`,
    ['LR-CM']: $localize`Grand Cape Mount`,
    ['LR-GB']: $localize`Grand Bassa`,
    ['LR-GG']: $localize`Grand Gedeh`,
    ['LR-GK']: $localize`Grand Kru`,
    ['LR-GP']: $localize`Gbarpolu`,
    ['LR-LO']: $localize`Lofa`,
    ['LR-MG']: $localize`Margibi`,
    ['LR-MO']: $localize`Montserrado`,
    ['LR-MY']: $localize`Maryland`,
    ['LR-NI']: $localize`Nimba`,
    ['LR-RG']: $localize`River Gee`,
    ['LR-RI']: $localize`Rivercess`,
    ['LR-SI']: $localize`Sinoe`,
    ['LY-BA']: $localize`Banghāzī`,
    ['LY-BU']: $localize`Al Buţnān`,
    ['LY-DR']: $localize`Darnah`,
    ['LY-GT']: $localize`Ghāt`,
    ['LY-JA']: $localize`Al Jabal al Akhḑar`,
    ['LY-JG']: $localize`Al Jabal al Gharbī`,
    ['LY-JI']: $localize`Al Jifārah`,
    ['LY-JU']: $localize`Al Jufrah`,
    ['LY-KF']: $localize`Al Kufrah`,
    ['LY-MB']: $localize`Al Marqab`,
    ['LY-MI']: $localize`Mişrātah`,
    ['LY-MJ']: $localize`Al Marj`,
    ['LY-MQ']: $localize`Murzuq`,
    ['LY-NL']: $localize`Nālūt`,
    ['LY-NQ']: $localize`An Nuqāţ al Khams`,
    ['LY-SB']: $localize`Sabhā`,
    ['LY-SR']: $localize`Surt`,
    ['LY-TB']: $localize`Ţarābulus`,
    ['LY-WA']: $localize`Al Wāḩāt`,
    ['LY-WD']: $localize`Wādī al Ḩayāt`,
    ['LY-WS']: $localize`Wādī ash Shāţi’`,
    ['LY-ZA']: $localize`Az Zāwiyah`,
    ['LI-01']: $localize`Balzers`,
    ['LI-02']: $localize`Eschen`,
    ['LI-03']: $localize`Gamprin`,
    ['LI-04']: $localize`Mauren`,
    ['LI-05']: $localize`Planken`,
    ['LI-06']: $localize`Ruggell`,
    ['LI-07']: $localize`Schaan`,
    ['LI-08']: $localize`Schellenberg`,
    ['LI-09']: $localize`Triesen`,
    ['LI-10']: $localize`Triesenberg`,
    ['LI-11']: $localize`Vaduz`,
    ['LT-01']: $localize`Akmenė`,
    ['LT-02']: $localize`Alytaus miestas`,
    ['LT-03']: $localize`Alytus`,
    ['LT-04']: $localize`Anykščiai`,
    ['LT-05']: $localize`Birštono`,
    ['LT-06']: $localize`Biržai`,
    ['LT-07']: $localize`Druskininkai`,
    ['LT-08']: $localize`Elektrénai`,
    ['LT-09']: $localize`Ignalina`,
    ['LT-10']: $localize`Jonava`,
    ['LT-11']: $localize`Joniškis`,
    ['LT-12']: $localize`Jurbarkas`,
    ['LT-13']: $localize`Kaišiadorys`,
    ['LT-14']: $localize`Kalvarijos`,
    ['LT-15']: $localize`Kauno miestas`,
    ['LT-16']: $localize`Kaunas`,
    ['LT-17']: $localize`Kazlų Rūdos`,
    ['LT-18']: $localize`Kėdainiai`,
    ['LT-19']: $localize`Kelmė`,
    ['LT-20']: $localize`Klaipėdos miestas`,
    ['LT-21']: $localize`Klaipėda`,
    ['LT-22']: $localize`Kretinga`,
    ['LT-23']: $localize`Kupiškis`,
    ['LT-24']: $localize`Lazdijai`,
    ['LT-25']: $localize`Marijampolė`,
    ['LT-26']: $localize`Mažeikiai`,
    ['LT-27']: $localize`Molėtai`,
    ['LT-28']: $localize`Neringa`,
    ['LT-29']: $localize`Pagégiai`,
    ['LT-30']: $localize`Pakruojis`,
    ['LT-31']: $localize`Palangos miestas`,
    ['LT-32']: $localize`Panevėžio miestas`,
    ['LT-33']: $localize`Panevėžys`,
    ['LT-34']: $localize`Pasvalys`,
    ['LT-35']: $localize`Plungė`,
    ['LT-36']: $localize`Prienai`,
    ['LT-37']: $localize`Radviliškis`,
    ['LT-38']: $localize`Raseiniai`,
    ['LT-39']: $localize`Rietavo`,
    ['LT-40']: $localize`Rokiškis`,
    ['LT-41']: $localize`Šakiai`,
    ['LT-42']: $localize`Šalčininkai`,
    ['LT-43']: $localize`Šiaulių miestas`,
    ['LT-44']: $localize`Šiauliai`,
    ['LT-45']: $localize`Šilalė`,
    ['LT-46']: $localize`Šilutė`,
    ['LT-47']: $localize`Širvintos`,
    ['LT-48']: $localize`Skuodas`,
    ['LT-49']: $localize`Švenčionys`,
    ['LT-50']: $localize`Tauragė`,
    ['LT-51']: $localize`Telšiai`,
    ['LT-52']: $localize`Trakai`,
    ['LT-53']: $localize`Ukmergė`,
    ['LT-54']: $localize`Utena`,
    ['LT-55']: $localize`Varėna`,
    ['LT-56']: $localize`Vilkaviškis`,
    ['LT-57']: $localize`Vilniaus miestas`,
    ['LT-58']: $localize`Vilnius`,
    ['LT-59']: $localize`Visaginas`,
    ['LT-60']: $localize`Zarasai`,
    ['LT-AL']: $localize`Alytaus apskritis`,
    ['LT-KL']: $localize`Klaipėdos apskritis`,
    ['LT-KU']: $localize`Kauno apskritis`,
    ['LT-MR']: $localize`Marijampolės apskritis`,
    ['LT-PN']: $localize`Panevėžio apskritis`,
    ['LT-SA']: $localize`Šiaulių apskritis`,
    ['LT-TA']: $localize`Tauragės apskritis`,
    ['LT-TE']: $localize`Telšių apskritis`,
    ['LT-UT']: $localize`Utenos apskritis`,
    ['LT-VL']: $localize`Vilniaus apskritis`,
    ['LU-D']: $localize`Diekirch`,
    ['LU-G']: $localize`Grevenmacher`,
    ['LU-L']: $localize`Lëtzebuerg`,
    ['MO-MO']: $localize`Macau`,
    ['MK-01']: $localize`Aerodrom †`,
    ['MK-02']: $localize`Aračinovo`,
    ['MK-03']: $localize`Berovo`,
    ['MK-04']: $localize`Bitola`,
    ['MK-05']: $localize`Bogdanci`,
    ['MK-06']: $localize`Bogovinje`,
    ['MK-07']: $localize`Bosilovo`,
    ['MK-08']: $localize`Brvenica`,
    ['MK-09']: $localize`Butel †`,
    ['MK-10']: $localize`Valandovo`,
    ['MK-11']: $localize`Vasilevo`,
    ['MK-12']: $localize`Vevčani`,
    ['MK-13']: $localize`Veles`,
    ['MK-14']: $localize`Vinica`,
    ['MK-15']: $localize`Vraneštica`,
    ['MK-16']: $localize`Vrapčište`,
    ['MK-17']: $localize`Gazi Baba †`,
    ['MK-18']: $localize`Gevgelija`,
    ['MK-19']: $localize`Gostivar`,
    ['MK-20']: $localize`Gradsko`,
    ['MK-21']: $localize`Debar`,
    ['MK-22']: $localize`Debarca`,
    ['MK-23']: $localize`Delčevo`,
    ['MK-24']: $localize`Demir Kapija`,
    ['MK-25']: $localize`Demir Hisar`,
    ['MK-26']: $localize`Dojran`,
    ['MK-27']: $localize`Dolneni`,
    ['MK-28']: $localize`Drugovo`,
    ['MK-29']: $localize`Gjorče Petrov †`,
    ['MK-30']: $localize`Želino`,
    ['MK-31']: $localize`Zajas`,
    ['MK-32']: $localize`Zelenikovo`,
    ['MK-33']: $localize`Zrnovci`,
    ['MK-34']: $localize`Ilinden`,
    ['MK-35']: $localize`Jegunovce`,
    ['MK-36']: $localize`Kavadarci`,
    ['MK-37']: $localize`Karbinci`,
    ['MK-38']: $localize`Karpoš †`,
    ['MK-39']: $localize`Kisela Voda †`,
    ['MK-40']: $localize`Kičevo`,
    ['MK-41']: $localize`Konče`,
    ['MK-42']: $localize`Kočani`,
    ['MK-43']: $localize`Kratovo`,
    ['MK-44']: $localize`Kriva Palanka`,
    ['MK-45']: $localize`Krivogaštani`,
    ['MK-46']: $localize`Kruševo`,
    ['MK-47']: $localize`Kumanovo`,
    ['MK-48']: $localize`Lipkovo`,
    ['MK-49']: $localize`Lozovo`,
    ['MK-50']: $localize`Mavrovo i Rostuša`,
    ['MK-51']: $localize`Makedonska Kamenica`,
    ['MK-52']: $localize`Makedonski Brod`,
    ['MK-53']: $localize`Mogila`,
    ['MK-54']: $localize`Negotino`,
    ['MK-55']: $localize`Novaci`,
    ['MK-56']: $localize`Novo Selo`,
    ['MK-57']: $localize`Oslomej`,
    ['MK-58']: $localize`Ohrid`,
    ['MK-59']: $localize`Petrovec`,
    ['MK-60']: $localize`Pehčevo`,
    ['MK-61']: $localize`Plasnica`,
    ['MK-62']: $localize`Prilep`,
    ['MK-63']: $localize`Probištip`,
    ['MK-64']: $localize`Radoviš`,
    ['MK-65']: $localize`Rankovce`,
    ['MK-66']: $localize`Resen`,
    ['MK-67']: $localize`Rosoman`,
    ['MK-68']: $localize`Saraj †`,
    ['MK-69']: $localize`Sveti Nikole`,
    ['MK-70']: $localize`Sopište`,
    ['MK-71']: $localize`Staro Nagoričane`,
    ['MK-72']: $localize`Struga`,
    ['MK-73']: $localize`Strumica`,
    ['MK-74']: $localize`Studeničani`,
    ['MK-75']: $localize`Tearce`,
    ['MK-76']: $localize`Tetovo`,
    ['MK-77']: $localize`Centar †`,
    ['MK-78']: $localize`Centar Župa`,
    ['MK-79']: $localize`Čair †`,
    ['MK-80']: $localize`Čaška`,
    ['MK-81']: $localize`Češinovo-Obleševo`,
    ['MK-82']: $localize`Čučer Sandevo`,
    ['MK-83']: $localize`Štip`,
    ['MK-84']: $localize`Šuto Orizari †`,
    ['MG-A']: $localize`Toamasina`,
    ['MG-D']: $localize`Antsiranana`,
    ['MG-F']: $localize`Fianarantsoa`,
    ['MG-M']: $localize`Mahajanga`,
    ['MG-T']: $localize`Antananarivo`,
    ['MG-U']: $localize`Toliara`,
    ['MW-BA']: $localize`Balaka`,
    ['MW-BL']: $localize`Blantyre`,
    ['MW-C']: $localize`Central Region`,
    ['MW-CK']: $localize`Chikwawa`,
    ['MW-CR']: $localize`Chiradzulu`,
    ['MW-CT']: $localize`Chitipa`,
    ['MW-DE']: $localize`Dedza`,
    ['MW-DO']: $localize`Dowa`,
    ['MW-KR']: $localize`Karonga`,
    ['MW-KS']: $localize`Kasungu`,
    ['MW-LI']: $localize`Lilongwe`,
    ['MW-LK']: $localize`Likoma`,
    ['MW-MC']: $localize`Mchinji`,
    ['MW-MG']: $localize`Mangochi`,
    ['MW-MH']: $localize`Machinga`,
    ['MW-MU']: $localize`Mulanje`,
    ['MW-MW']: $localize`Mwanza`,
    ['MW-MZ']: $localize`Mzimba`,
    ['MW-NB']: $localize`Nkhata Bay`,
    ['MW-N']: $localize`Chakumpoto`,
    ['MW-NE']: $localize`Neno`,
    ['MW-NI']: $localize`Ntchisi`,
    ['MW-NK']: $localize`Nkhotakota`,
    ['MW-NS']: $localize`Nsanje`,
    ['MW-NU']: $localize`Ntcheu`,
    ['MW-PH']: $localize`Phalombe`,
    ['MW-RU']: $localize`Rumphi`,
    ['MW-SA']: $localize`Salima`,
    ['MW-S']: $localize`Chakumwera`,
    ['MW-TH']: $localize`Thyolo`,
    ['MW-ZO']: $localize`Zomba`,
    ['MY-01']: $localize`Johor`,
    ['MY-02']: $localize`Kedah`,
    ['MY-03']: $localize`Kelantan`,
    ['MY-04']: $localize`Melaka`,
    ['MY-05']: $localize`Negeri Sembilan`,
    ['MY-06']: $localize`Pahang`,
    ['MY-07']: $localize`Pulau Pinang`,
    ['MY-08']: $localize`Perak`,
    ['MY-09']: $localize`Perlis`,
    ['MY-10']: $localize`Selangor`,
    ['MY-11']: $localize`Terengganu`,
    ['MY-12']: $localize`Sabah`,
    ['MY-13']: $localize`Sarawak`,
    ['MY-14']: $localize`Wilayah Persekutuan Kuala Lumpur`,
    ['MY-15']: $localize`Wilayah Persekutuan Labuan`,
    ['MY-16']: $localize`Wilayah Persekutuan Putrajaya`,
    ['MV-00']: $localize`Alifu Dhaalu`,
    ['MV-01']: $localize`Addu Atholhu`,
    ['MV-02']: $localize`Alifu Alifu`,
    ['MV-03']: $localize`Faadhippolhu`,
    ['MV-04']: $localize`Felidhu Atholhu`,
    ['MV-05']: $localize`Hadhdhunmathi`,
    ['MV-07']: $localize`Haa Alifu`,
    ['MV-08']: $localize`Kolhumadulu`,
    ['MV-12']: $localize`Meemu`,
    ['MV-13']: $localize`Maalhosmadulu Uthuruburi`,
    ['MV-14']: $localize`Faafu`,
    ['MV-17']: $localize`Dhaalu`,
    ['MV-20']: $localize`Baa`,
    ['MV-23']: $localize`Haa Dhaalu`,
    ['MV-24']: $localize`Miladhunmadulu Uthuruburi`,
    ['MV-25']: $localize`Miladhunmadulu Dhekunuburi`,
    ['MV-26']: $localize`Kaafu`,
    ['MV-27']: $localize`Gaafu Alifu`,
    ['MV-28']: $localize`Gaafu Dhaalu`,
    ['MV-29']: $localize`Fuvammulah`,
    ['MV-CE']: $localize`Central`,
    ['MV-ML']: $localize`,Maale`,
    ['MV-NC']: $localize`Medhu-Uthuru`,
    ['MV-NO']: $localize`North`,
    ['MV-SC']: $localize`Medhu-Dhekunu`,
    ['MV-SU']: $localize`Dhekunu`,
    ['MV-UN']: $localize`Mathi-Uthuru`,
    ['MV-US']: $localize`Mathi-Dhekunu`,
    ['ML-1']: $localize`Kayes`,
    ['ML-2']: $localize`Koulikoro`,
    ['ML-3']: $localize`Sikasso`,
    ['ML-4']: $localize`Ségou`,
    ['ML-5']: $localize`Mopti`,
    ['ML-6']: $localize`Tombouctou`,
    ['ML-7']: $localize`Gao`,
    ['ML-8']: $localize`Kidal`,
    ['ML-BKO']: $localize`Bamako`,
    ['MT-01']: $localize`Attard`,
    ['MT-02']: $localize`Balzan`,
    ['MT-03']: $localize`Birgu`,
    ['MT-04']: $localize`Birkirkara`,
    ['MT-05']: $localize`Birżebbuġa`,
    ['MT-06']: $localize`Bormla`,
    ['MT-07']: $localize`Dingli`,
    ['MT-08']: $localize`Fgura`,
    ['MT-09']: $localize`Floriana`,
    ['MT-10']: $localize`Fontana`,
    ['MT-11']: $localize`Gudja`,
    ['MT-12']: $localize`Gżira`,
    ['MT-13']: $localize`Għajnsielem`,
    ['MT-14']: $localize`Għarb`,
    ['MT-15']: $localize`Għargħur`,
    ['MT-16']: $localize`Għasri`,
    ['MT-17']: $localize`Għaxaq`,
    ['MT-18']: $localize`Ħamrun`,
    ['MT-19']: $localize`Iklin`,
    ['MT-20']: $localize`Isla`,
    ['MT-21']: $localize`Kalkara`,
    ['MT-22']: $localize`Kerċem`,
    ['MT-23']: $localize`Kirkop`,
    ['MT-24']: $localize`Lija`,
    ['MT-25']: $localize`Luqa`,
    ['MT-26']: $localize`Marsa`,
    ['MT-27']: $localize`Marsaskala`,
    ['MT-28']: $localize`Marsaxlokk`,
    ['MT-29']: $localize`Mdina`,
    ['MT-30']: $localize`Mellieħa`,
    ['MT-31']: $localize`Mġarr`,
    ['MT-32']: $localize`Mosta`,
    ['MT-33']: $localize`Mqabba`,
    ['MT-34']: $localize`Msida`,
    ['MT-35']: $localize`Mtarfa`,
    ['MT-36']: $localize`Munxar`,
    ['MT-37']: $localize`Nadur`,
    ['MT-38']: $localize`Naxxar`,
    ['MT-39']: $localize`Paola`,
    ['MT-40']: $localize`Pembroke`,
    ['MT-41']: $localize`Pietà`,
    ['MT-42']: $localize`Qala`,
    ['MT-43']: $localize`Qormi`,
    ['MT-44']: $localize`Qrendi`,
    ['MT-45']: $localize`Rabat Gozo`,
    ['MT-46']: $localize`Rabat Malta`,
    ['MT-47']: $localize`Safi`,
    ['MT-48']: $localize`Saint Julian’s`,
    ['MT-49']: $localize`Saint John`,
    ['MT-50']: $localize`Saint Lawrence`,
    ['MT-51']: $localize`Saint Paul’s Bay`,
    ['MT-52']: $localize`Sannat`,
    ['MT-53']: $localize`Saint Lucia’s`,
    ['MT-54']: $localize`Santa Venera`,
    ['MT-55']: $localize`Siġġiewi`,
    ['MT-56']: $localize`Sliema`,
    ['MT-57']: $localize`Swieqi`,
    ['MT-58']: $localize`Ta’ Xbiex`,
    ['MT-59']: $localize`Tarxien`,
    ['MT-60']: $localize`Valletta`,
    ['MT-61']: $localize`Xagħra`,
    ['MT-62']: $localize`Xewkija`,
    ['MT-63']: $localize`Xgħajra`,
    ['MT-64']: $localize`Żabbar`,
    ['MT-65']: $localize`Żebbuġ Gozo`,
    ['MT-66']: $localize`Żebbuġ Malta`,
    ['MT-67']: $localize`Żejtun`,
    ['MT-68']: $localize`Żurrieq`,
    ['MH-ALK']: $localize`Ailuk`,
    ['MH-ALL']: $localize`Ailinglaplap`,
    ['MH-ARN']: $localize`Arno`,
    ['MH-AUR']: $localize`Aur`,
    ['MH-EBO']: $localize`Ebon`,
    ['MH-ENI']: $localize`Enewetak and Ujelang`,
    ['MH-JAB']: $localize`Jabat`,
    ['MH-JAL']: $localize`Jaluit`,
    ['MH-KIL']: $localize`Bikini and Kili`,
    ['MH-KWA']: $localize`Kwajalein`,
    ['MH-LAE']: $localize`Lae`,
    ['MH-LIB']: $localize`Lib`,
    ['MH-LIK']: $localize`Likiep`,
    ['MH-L']: $localize`Ralik chain`,
    ['MH-MAJ']: $localize`Majuro`,
    ['MH-MAL']: $localize`Maloelap`,
    ['MH-MEJ']: $localize`Mejit`,
    ['MH-MIL']: $localize`Mili`,
    ['MH-NMK']: $localize`Namdrik`,
    ['MH-NMU']: $localize`Namu`,
    ['MH-RON']: $localize`Rongelap`,
    ['MH-T']: $localize`Ratak chain`,
    ['MH-UJA']: $localize`Ujae`,
    ['MH-UTI']: $localize`Utrik`,
    ['MH-WTH']: $localize`Wotho`,
    ['MH-WTJ']: $localize`Wotje`,
    ['MQ-MQ']: $localize`Martinique`,
    ['MR-01']: $localize`Hodh ech Chargui`,
    ['MR-02']: $localize`Hodh el Gharbi`,
    ['MR-03']: $localize`Assaba`,
    ['MR-04']: $localize`Gorgol`,
    ['MR-05']: $localize`Brakna`,
    ['MR-06']: $localize`Trarza`,
    ['MR-07']: $localize`Adrar`,
    ['MR-08']: $localize`Dakhlet Nouâdhibou`,
    ['MR-09']: $localize`Tagant`,
    ['MR-10']: $localize`Guidimaka`,
    ['MR-11']: $localize`Tiris Zemmour`,
    ['MR-12']: $localize`Inchiri`,
    ['MR-NKC']: $localize`Nouakchott`,
    ['MU-AG']: $localize`Agalega Islands`,
    ['MU-BL']: $localize`Black River`,
    ['MU-BR']: $localize`Beau Bassin-Rose Hill`,
    ['MU-CC']: $localize`Cargados Carajos Shoals`,
    ['MU-CU']: $localize`Curepipe`,
    ['MU-FL']: $localize`Flacq`,
    ['MU-GP']: $localize`Grand Port`,
    ['MU-MO']: $localize`Moka`,
    ['MU-PA']: $localize`Pamplemousses`,
    ['MU-PL']: $localize`Port Louis`,
    ['MU-PU']: $localize`Port Louis`,
    ['MU-PW']: $localize`Plaines Wilhems`,
    ['MU-QB']: $localize`Quatre Bornes`,
    ['MU-RO']: $localize`Rodrigues Island`,
    ['MU-RR']: $localize`Rivière du Rempart`,
    ['MU-SA']: $localize`Savanne`,
    ['MU-VP']: $localize`Vacoas-Phoenix`,
    ['YT-YT']: $localize`Mayotte`,
    ['MX-AGU']: $localize`Aguascalientes`,
    ['MX-BCN']: $localize`Baja California`,
    ['MX-BCS']: $localize`Baja California Sur`,
    ['MX-CAM']: $localize`Campeche`,
    ['MX-CHH']: $localize`Chihuahua`,
    ['MX-CHP']: $localize`Chiapas`,
    ['MX-COA']: $localize`Coahuila de Zaragoza`,
    ['MX-COL']: $localize`Colima`,
    ['MX-CMX']: $localize`Ciudad de México`,
    ['MX-DUR']: $localize`Durango`,
    ['MX-GRO']: $localize`Guerrero`,
    ['MX-GUA']: $localize`Guanajuato`,
    ['MX-HID']: $localize`Hidalgo`,
    ['MX-JAL']: $localize`Jalisco`,
    ['MX-MEX']: $localize`México`,
    ['MX-MIC']: $localize`Michoacán de Ocampo`,
    ['MX-MOR']: $localize`Morelos`,
    ['MX-NAY']: $localize`Nayarit`,
    ['MX-NLE']: $localize`Nuevo León`,
    ['MX-OAX']: $localize`Oaxaca`,
    ['MX-PUE']: $localize`Puebla`,
    ['MX-QUE']: $localize`Querétaro`,
    ['MX-ROO']: $localize`Quintana Roo`,
    ['MX-SIN']: $localize`Sinaloa`,
    ['MX-SLP']: $localize`San Luis Potosí`,
    ['MX-SON']: $localize`Sonora`,
    ['MX-TAB']: $localize`Tabasco`,
    ['MX-TAM']: $localize`Tamaulipas`,
    ['MX-TLA']: $localize`Tlaxcala`,
    ['MX-VER']: $localize`Veracruz de Ignacio de la Llave`,
    ['MX-YUC']: $localize`Yucatán`,
    ['MX-ZAC']: $localize`Zacatecas`,
    ['FM-KSA']: $localize`Kosrae`,
    ['FM-PNI']: $localize`Pohnpei`,
    ['FM-TRK']: $localize`Chuuk`,
    ['FM-YAP']: $localize`Yap`,
    ['MD-AN']: $localize`Anenii Noi`,
    ['MD-BA']: $localize`Bălţi`,
    ['MD-BD']: $localize`Bender`,
    ['MD-BR']: $localize`Briceni`,
    ['MD-BS']: $localize`Basarabeasca`,
    ['MD-CA']: $localize`Cahul`,
    ['MD-CL']: $localize`Călăraşi`,
    ['MD-CM']: $localize`Cimişlia`,
    ['MD-CR']: $localize`Criuleni`,
    ['MD-CS']: $localize`Căuşeni`,
    ['MD-CT']: $localize`Cantemir`,
    ['MD-CU']: $localize`Chişinău`,
    ['MD-DO']: $localize`Donduşeni`,
    ['MD-DR']: $localize`Drochia`,
    ['MD-DU']: $localize`Dubăsari`,
    ['MD-ED']: $localize`Edineţ`,
    ['MD-FA']: $localize`Făleşti`,
    ['MD-FL']: $localize`Floreşti`,
    ['MD-GA']: $localize`Găgăuzia, Unitatea teritorială autonomă`,
    ['MD-GL']: $localize`Glodeni`,
    ['MD-HI']: $localize`Hînceşti`,
    ['MD-IA']: $localize`Ialoveni`,
    ['MD-LE']: $localize`Leova`,
    ['MD-NI']: $localize`Nisporeni`,
    ['MD-OC']: $localize`Ocniţa`,
    ['MD-OR']: $localize`Orhei`,
    ['MD-RE']: $localize`Rezina`,
    ['MD-RI']: $localize`Rîşcani`,
    ['MD-SD']: $localize`Şoldăneşti`,
    ['MD-SI']: $localize`Sîngerei`,
    ['MD-SN']: $localize`Stînga Nistrului, unitatea teritorială din`,
    ['MD-SO']: $localize`Soroca`,
    ['MD-ST']: $localize`Străşeni`,
    ['MD-SV']: $localize`Ştefan Vodă`,
    ['MD-TA']: $localize`Taraclia`,
    ['MD-TE']: $localize`Teleneşti`,
    ['MD-UN']: $localize`Ungheni`,
    ['MC-CL']: $localize`La Colle`,
    ['MC-CO']: $localize`La Condamine`,
    ['MC-FO']: $localize`Fontvieille`,
    ['MC-GA']: $localize`La Gare`,
    ['MC-JE']: $localize`Jardin Exotique`,
    ['MC-LA']: $localize`Larvotto`,
    ['MC-MA']: $localize`Malbousquet`,
    ['MC-MC']: $localize`Monte-Carlo`,
    ['MC-MG']: $localize`Moneghetti`,
    ['MC-MO']: $localize`Monaco-Ville`,
    ['MC-MU']: $localize`Moulins`,
    ['MC-PH']: $localize`Port-Hercule`,
    ['MC-SD']: $localize`Sainte-Dévote`,
    ['MC-SO']: $localize`La Source`,
    ['MC-SP']: $localize`Spélugues`,
    ['MC-SR']: $localize`Saint-Roman`,
    ['MC-VR']: $localize`Vallon de la Rousse`,
    ['MN-035']: $localize`Orhon`,
    ['MN-037']: $localize`Darhan uul`,
    ['MN-039']: $localize`Hentiy`,
    ['MN-041']: $localize`Hövsgöl`,
    ['MN-043']: $localize`Hovd`,
    ['MN-046']: $localize`Uvs`,
    ['MN-047']: $localize`Töv`,
    ['MN-049']: $localize`Selenge`,
    ['MN-051']: $localize`Sühbaatar`,
    ['MN-053']: $localize`Ömnögovĭ`,
    ['MN-055']: $localize`Övörhangay`,
    ['MN-057']: $localize`Dzavhan`,
    ['MN-059']: $localize`Dundgovĭ`,
    ['MN-061']: $localize`Dornod`,
    ['MN-063']: $localize`Dornogovĭ`,
    ['MN-064']: $localize`Govĭ-Sümber`,
    ['MN-065']: $localize`Govĭ-Altay`,
    ['MN-067']: $localize`Bulgan`,
    ['MN-069']: $localize`Bayanhongor`,
    ['MN-071']: $localize`Bayan-Ölgiy`,
    ['MN-073']: $localize`Arhangay`,
    ['MN-1']: $localize`Ulaanbaatar`,
    ['ME-01']: $localize`Andrijevica`,
    ['ME-02']: $localize`Bar`,
    ['ME-03']: $localize`Berane`,
    ['ME-04']: $localize`Bijelo Polje`,
    ['ME-05']: $localize`Budva`,
    ['ME-06']: $localize`Cetinje`,
    ['ME-07']: $localize`Danilovgrad`,
    ['ME-08']: $localize`Herceg-Novi`,
    ['ME-09']: $localize`Kolašin`,
    ['ME-10']: $localize`Kotor`,
    ['ME-11']: $localize`Mojkovac`,
    ['ME-12']: $localize`Nikšić`,
    ['ME-13']: $localize`Plav`,
    ['ME-14']: $localize`Pljevlja`,
    ['ME-15']: $localize`Plužine`,
    ['ME-16']: $localize`Podgorica`,
    ['ME-17']: $localize`Rožaje`,
    ['ME-18']: $localize`Šavnik`,
    ['ME-19']: $localize`Tivat`,
    ['ME-20']: $localize`Ulcinj`,
    ['ME-21']: $localize`Žabljak`,
    ['ME-22']: $localize`Gusinje`,
    ['ME-23']: $localize`Petnjica`,
    ['MS-MS']: $localize`Montserrat`,
    ['MA-01']: $localize`Tanger-Tétouan`,
    ['MA-02']: $localize`Gharb-Chrarda-Beni Hssen`,
    ['MA-03']: $localize`Taza-Al Hoceima-Taounate`,
    ['MA-04']: $localize`L'Oriental`,
    ['MA-05']: $localize`Fès-Boulemane`,
    ['MA-06']: $localize`Meknès-Tafilalet`,
    ['MA-07']: $localize`Rabat-Salé-Zemmour-Zaer`,
    ['MA-08']: $localize`Grand Casablanca`,
    ['MA-09']: $localize`Chaouia-Ouardigha`,
    ['MA-10']: $localize`Doukkala-Abda`,
    ['MA-11']: $localize`Marrakech-Tensift-Al Haouz`,
    ['MA-12']: $localize`Tadla-Azilal`,
    ['MA-13']: $localize`Sous-Massa-Draa`,
    ['MA-14']: $localize`Guelmim-Es Semara`,
    ['MA-15']: $localize`Laâyoune-Boujdour-Sakia el Hamra`,
    ['MA-16']: $localize`Oued ed Dahab-Lagouira`,
    ['MA-AGD']: $localize`Agadir-Ida-Outanane`,
    ['MA-AOU']: $localize`Aousserd`,
    ['MA-ASZ']: $localize`Assa-Zag`,
    ['MA-AZI']: $localize`Azilal`,
    ['MA-BEM']: $localize`Beni Mellal`,
    ['MA-BER']: $localize`Berkane`,
    ['MA-BES']: $localize`Ben Slimane`,
    ['MA-BOD']: $localize`Boujdour`,
    ['MA-BOM']: $localize`Boulemane`,
    ['MA-CAS']: $localize`Casablanca`,
    ['MA-CHE']: $localize`Chefchaouen`,
    ['MA-CHI']: $localize`Chichaoua`,
    ['MA-CHT']: $localize`Chtouka-Ait Baha`,
    ['MA-ERR']: $localize`Errachidia`,
    ['MA-ESI']: $localize`Essaouira`,
    ['MA-ESM']: $localize`Es Smara`,
    ['MA-FAH']: $localize`Fahs-Beni Makada`,
    ['MA-FES']: $localize`Fès-Dar-Dbibegh`,
    ['MA-FIG']: $localize`Figuig`,
    ['MA-GUE']: $localize`Guelmim`,
    ['MA-HAJ']: $localize`El Hajeb`,
    ['MA-HAO']: $localize`Al Haouz`,
    ['MA-HOC']: $localize`Al Hoceïma`,
    ['MA-IFR']: $localize`Ifrane`,
    ['MA-INE']: $localize`Inezgane-Ait Melloul`,
    ['MA-JDI']: $localize`El Jadida`,
    ['MA-JRA']: $localize`Jrada`,
    ['MA-KEN']: $localize`Kénitra`,
    ['MA-KES']: $localize`Kelaat es Sraghna`,
    ['MA-KHE']: $localize`Khemisset`,
    ['MA-KHN']: $localize`Khenifra`,
    ['MA-KHO']: $localize`Khouribga`,
    ['MA-LAA']: $localize`Laâyoune`,
    ['MA-LAR']: $localize`Larache`,
    ['MA-MED']: $localize`Médiouna`,
    ['MA-MEK']: $localize`Meknès`,
    ['MA-MMD']: $localize`Marrakech-Medina`,
    ['MA-MMN']: $localize`Marrakech-Menara`,
    ['MA-MOH']: $localize`Mohammadia`,
    ['MA-MOU']: $localize`Moulay Yacoub`,
    ['MA-NAD']: $localize`Nador`,
    ['MA-NOU']: $localize`Nouaceur`,
    ['MA-OUA']: $localize`Ouarzazate`,
    ['MA-OUD']: $localize`Oued ed Dahab`,
    ['MA-OUJ']: $localize`Oujda-Angad`,
    ['MA-RAB']: $localize`Rabat`,
    ['MA-SAF']: $localize`Safi`,
    ['MA-SAL']: $localize`Salé`,
    ['MA-SEF']: $localize`Sefrou`,
    ['MA-SET']: $localize`Settat`,
    ['MA-SIK']: $localize`Sidi Kacem`,
    ['MA-SKH']: $localize`Skhirate-Témara`,
    ['MA-SYB']: $localize`Sidi Youssef Ben Ali`,
    ['MA-TAI']: $localize`Taourirt`,
    ['MA-TAO']: $localize`Taounate`,
    ['MA-TAR']: $localize`Taroudant`,
    ['MA-TAT']: $localize`Tata`,
    ['MA-TAZ']: $localize`Taza`,
    ['MA-TET']: $localize`Tétouan`,
    ['MA-TIZ']: $localize`Tiznit`,
    ['MA-TNG']: $localize`Tanger-Assilah`,
    ['MA-TNT']: $localize`Tan-Tan`,
    ['MA-ZAG']: $localize`Zagora`,
    ['MZ-A']: $localize`Niassa`,
    ['MZ-B']: $localize`Manica`,
    ['MZ-G']: $localize`Gaza`,
    ['MZ-I']: $localize`Inhambane`,
    ['MZ-L']: $localize`Maputo`,
    ['MZ-MPM']: $localize`Maputo`,
    ['MZ-N']: $localize`Nampula`,
    ['MZ-P']: $localize`Cabo Delgado`,
    ['MZ-Q']: $localize`Zambézia`,
    ['MZ-S']: $localize`Sofala`,
    ['MZ-T']: $localize`Tete`,
    ['MM-01']: $localize`Sagaing`,
    ['MM-02']: $localize`Bago`,
    ['MM-03']: $localize`Magway`,
    ['MM-04']: $localize`Mandalay`,
    ['MM-05']: $localize`Taninthayi`,
    ['MM-06']: $localize`Yangon`,
    ['MM-07']: $localize`Ayeyawady`,
    ['MM-11']: $localize`Kachin`,
    ['MM-12']: $localize`Kayah`,
    ['MM-13']: $localize`Kayin`,
    ['MM-14']: $localize`Chin`,
    ['MM-15']: $localize`Mon`,
    ['MM-16']: $localize`Rakhine`,
    ['MM-17']: $localize`Shan`,
    ['MM-18']: $localize`Nay Pyi Taw`,
    ['NA-CA']: $localize`Zambezi`,
    ['NA-ER']: $localize`Erongo`,
    ['NA-HA']: $localize`Hardap`,
    ['NA-KA']: $localize`Karas`,
    ['NA-KE']: $localize`Kavango East`,
    ['NA-KH']: $localize`Khomas`,
    ['NA-KU']: $localize`Kunene`,
    ['NA-KW']: $localize`Kavango West`,
    ['NA-OD']: $localize`Otjozondjupa`,
    ['NA-OH']: $localize`Omaheke`,
    ['NA-ON']: $localize`Oshana`,
    ['NA-OS']: $localize`Omusati`,
    ['NA-OT']: $localize`Oshikoto`,
    ['NA-OW']: $localize`Ohangwena`,
    ['NR-01']: $localize`Aiwo`,
    ['NR-02']: $localize`Anabar`,
    ['NR-03']: $localize`Anetan`,
    ['NR-04']: $localize`Anibare`,
    ['NR-05']: $localize`Baiti`,
    ['NR-06']: $localize`Boe`,
    ['NR-07']: $localize`Buada`,
    ['NR-08']: $localize`Denigomodu`,
    ['NR-09']: $localize`Ewa`,
    ['NR-10']: $localize`Ijuw`,
    ['NR-11']: $localize`Meneng`,
    ['NR-12']: $localize`Nibok`,
    ['NR-13']: $localize`Uaboe`,
    ['NR-14']: $localize`Yaren`,
    ['NP-1']: $localize`Central`,
    ['NP-2']: $localize`Madhya Pashchimanchal`,
    ['NP-3']: $localize`Pashchimanchal`,
    ['NP-4']: $localize`Eastern`,
    ['NP-5']: $localize`Far Western`,
    ['NP-BA']: $localize`Bagmati`,
    ['NP-BH']: $localize`Bheri`,
    ['NP-DH']: $localize`Dhawalagiri`,
    ['NP-GA']: $localize`Gandaki`,
    ['NP-JA']: $localize`Janakpur`,
    ['NP-KA']: $localize`Karnali`,
    ['NP-KO']: $localize`Kosi`,
    ['NP-LU']: $localize`Lumbini`,
    ['NP-MA']: $localize`Mahakali`,
    ['NP-ME']: $localize`Mechi`,
    ['NP-NA']: $localize`Narayani`,
    ['NP-RA']: $localize`Rapti`,
    ['NP-SA']: $localize`Sagarmatha`,
    ['NP-SE']: $localize`Seti`,
    ['NL-AW']: $localize`Aruba`,
    ['NL-BQ1']: $localize`Bonaire`,
    ['NL-BQ2']: $localize`Saba`,
    ['NL-BQ3']: $localize`Sint Eustatius`,
    ['NL-CW']: $localize`Curaçao`,
    ['NL-DR']: $localize`Drenthe`,
    ['NL-FL']: $localize`Flevoland`,
    ['NL-FR']: $localize`Fryslân`,
    ['NL-GE']: $localize`Gelderland`,
    ['NL-GR']: $localize`Groningen`,
    ['NL-LI']: $localize`Limburg`,
    ['NL-NB']: $localize`Noord-Brabant`,
    ['NL-NH']: $localize`Noord-Holland`,
    ['NL-OV']: $localize`Overijssel`,
    ['NL-SX']: $localize`Sint Maarten`,
    ['NL-UT']: $localize`Utrecht`,
    ['NL-ZE']: $localize`Zeeland`,
    ['NL-ZH']: $localize`Zuid-Holland`,
    ['NC-NC']: $localize`New Caledonia`,
  };
}
