import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

import { IFilterToApply, ISelectInput } from '@portal/shared/ui/filter';
import { FilterTypes } from '@portal/shared/ui/filter/src/lib/interfaces/filter-types.enum';
import { FiltersList } from '@portal/shared/ui/filter/src/lib/interfaces/filters-list';
import { isEmpty } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'portal-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnChanges {
  @Input() addEntities: ISelectInput;
  @Input() filtersList: FiltersList[];
  @Input() isFromVhq: boolean = false;
  @Output() filterApplied: EventEmitter<any> = new EventEmitter();
  removeEntity$ = new Subject<ISelectInput>();
  closeSubmenus$ = new Subject<boolean>();
  filterTypes = FilterTypes;
  selectedFilters = [];
  addEntities$ = new BehaviorSubject<ISelectInput>(null);
  filtersCleared = false;

  displayedFilters = [];

  constructor(private cdr: ChangeDetectorRef) {}

  onFilterApplied(): void {
    this.filterApplied.emit(this.selectedFilters);
  }

  isFromVhqFlag() {
    return this.isFromVhq;
  }

  ngOnChanges(): void {
    if (!isEmpty(this.addEntities)) {
      this.addEntities$.next(this.addEntities);
      this.cdr.detectChanges();
    }
  }

  onFilterUpdated(filter: IFilterToApply): void {
    if (filter.toRemove === true) {
      this.selectedFilters = this.selectedFilters.filter(
        (item) => item.selector !== filter.selector,
      );
    } else {
      const filterArguments = this.getFilterArguments(filter);
      this.selectedFilters = this.selectedFilters.filter((item) => {
        if (
          item.selector !== filter.selector ||
          (item.selector === filter.selector && item.operator !== filter.operator)
        ) {
          return true;
        }
        return filterArguments.includes(item.argument);
      });
      // replace common portal in to report service
      if (filter.operator === '=in=') {
        filter.operator = 'eq';
      }
      const filtersWithCurrentSelector = this.selectedFilters
        .filter(({ selector }) => selector === filter.selector)
        .map(({ argument }) => argument);
      if (filterArguments !== undefined) {
        filterArguments.forEach((argument) => {
          if (!filtersWithCurrentSelector.includes(argument)) {
            this.selectedFilters.push({
              argument,
              key: `${filter.selector}-${argument}`,
              operator: filter.operator,
              selector: filter.selector,
              value: filter.value,
            });
          }
        });
      }
    }
    this.getDisplayedFilters();
    this.onFilterApplied();
  }

  getFilterArguments(filter: IFilterToApply): string[] {
    if (this.filtersList) {
      const filterType = this.filtersList.find((item) => {
        if (item.selector === filter.selector) {
          return true;
        }

        if (!item.data) {
          return false;
        }

        if (item.data) {
          return !!item.data.find((index) => index.id === filter.key);
        }
      })?.type;

      if (filterType === this.filterTypes.MultipleSearch) {
        return [filter.argument.trim()];
      } else {
        return filter.argument.split(',').map((argument) => argument.trim());
      }
    }
  }

  removeFilter(filter): void {
    this.removeEntity$.next({ id: filter.argument, selector: filter.selector });
  }

  resetFilter(): void {
    this.filtersCleared = true;
    this.selectedFilters.forEach((filter) => this.removeFilter(filter));
  }

  getFilterListbyType(filterType: FilterTypes): FiltersList[] {
    return this.filtersList.filter(({ type }) => type === filterType);
  }

  getFilterListInDropdown(): FiltersList[] {
    const types = [this.filterTypes.Multi, this.filterTypes.Single];
    return this.filtersList.filter(({ type }) => types.includes(type));
  }

  onSubmenusClosed(closeSubmenu: boolean): void {
    if (closeSubmenu) {
      this.closeSubmenus$.next(true);
    }
  }

  getDisplayedFilters(): void {
    const types = [
      this.filterTypes.DatePicker,
      this.filterTypes.Entity,
      this.filterTypes.Hierarchy,
      this.filterTypes.TreeFilter,
      this.filterTypes.Toggle,
    ];
    const selectors = [];
    this.filtersList.forEach((item) => {
      if (types.includes(item.type)) selectors.push(item.selector);
    });

    this.displayedFilters = this.selectedFilters.filter(
      ({ selector }) => !selectors.includes(selector),
    );
  }
}
