import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestrictPermissionService } from '@portal/shared/auth/authorization/src/lib/services/restrict-permission.service';
import { BaseModalComponent } from '@portal/shared/ui/modal/src/lib/base-modal/base-modal.component';
import { finalize } from 'rxjs/operators';
import { CardCapturePublicKey } from './card-capture-public-key.interface';
import { SecureCardCaptureService } from './secure-card-capture.service';
import { IQueryParams, PageSize, SortOrder } from '@portal/shared/ui/table/src';
import cloneDeep from 'lodash-es/cloneDeep';
import { PaginationSortHandler } from '@portal/shared/helpers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

declare const $localize;

@UntilDestroy()
@Component({
  selector: 'portal-secure-card-capture',
  templateUrl: './secure-card-capture.component.html',
  styleUrls: ['./secure-card-capture.component.scss'],
})
export class SecureCardCaptureComponent extends PaginationSortHandler implements OnInit {
  @ViewChild('keyViewerModal') keyViewerModal: BaseModalComponent;
  copyButtonConfig = {
    text: $localize`Copy public key`,
    class: 'button is-primary',
  };
  copyMessage = $localize`Public key copied to the clipboard`;
  loading;
  loaderMessage = '';
  publicKeys: CardCapturePublicKey[];
  selectedKey: CardCapturePublicKey;
  canEdit: boolean;
  totalCount: number;
  filter: IQueryParams;
  initialFilter: IQueryParams = {
    start: 0,
    limit: PageSize.Size5,
    order: SortOrder.Asc,
  };
  pageSizeOptions: PageSize[] = [
    PageSize.Size5,
    PageSize.Size10,
    PageSize.Size25,
    PageSize.Size50,
    PageSize.Size100,
  ];

  constructor(
    private secureCardCaptureService: SecureCardCaptureService,
    private restrictPermissionService: RestrictPermissionService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setInitialFilter();
    this.getPublicKeysCount();
    this.getPublicKeys();
    const permissions = this.restrictPermissionService.getPermissionsForRoute(this.route);
    this.canEdit = this.restrictPermissionService.checkPermissions(permissions, [
      'secure-card-capture-edit',
    ]);
  }

  generateKey(): void {
    this.loaderMessage = $localize`Generating public key`;
    this.loading = true;
    this.secureCardCaptureService
      .createKeyPair()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(() => {
        this.getPublicKeys();
      });
  }

  viewPublicKey(selectedKey: CardCapturePublicKey): void {
    this.selectedKey = selectedKey;
    this.keyViewerModal.open();
  }

  afterPaginate(): void {
    this.getPublicKeys();
  }

  afterSorting(): void {
    this.getPublicKeys();
  }

  private setInitialFilter(): void {
    this.filter = cloneDeep(this.initialFilter);
  }

  private getPublicKeys(): void {
    this.loading = true;
    this.secureCardCaptureService
      .getKeyPairs(this.filter)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
        untilDestroyed(this),
      )
      .subscribe((keyPairs: CardCapturePublicKey[]) => {
        this.publicKeys = keyPairs;
      });
  }

  private getPublicKeysCount(): void {
    this.loading = true;
    this.secureCardCaptureService
      .getKeyPairsCount()
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
        untilDestroyed(this),
      )
      .subscribe((result) => {
        this.totalCount = result.count;
      });
  }
}
