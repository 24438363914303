import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router } from '@angular/router';
import get from 'lodash-es/get';
import environment from 'environments/environment';
import { AuthorizationService } from '@portal/shared/auth/authorization/src/lib/authorization.service';
import { DEFAULT_FEATURES } from '@portal/shared/auth/authorization/src/lib/constant/default-features';
import { Features } from 'environments/enums/features.enum';
import { AuthenticationGuard } from '@portal/shared/auth/authentication';

@Injectable()
export class FeatureToggle implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private authenticationGuard: AuthenticationGuard,
  ) {}

  isFeatureActive(featureName: Features): boolean {
    const featureFlagEnv = `FEATURE_FLAGS.${featureName}`;
    const featureEnvStatus = get(environment, featureFlagEnv, true);

    const appPermissions = this.authorizationService.appsPermissions;
    const featurePermission = DEFAULT_FEATURES.includes(featureName)
      ? true
      : appPermissions.find((appPermission) => appPermission.featureId === featureName);

    return featurePermission && featureEnvStatus;
  }

  hasAccessPermission(allowedPermissions: string[]): boolean {
    const userPermissions = this.authorizationService.getAccessTokenPermissions();
    return userPermissions.some((userPermission) => {
      return allowedPermissions.includes(userPermission);
    });
  }

  isConfigFeatureActive(featureName: Features): boolean {
    return get(environment, `FEATURE_FLAGS.${featureName}`, false);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.authenticationGuard.canActivate(route) && this.featureStatus(route.routeConfig);
  }

  canLoad(route: Route): boolean {
    return !this.authorizationService.currentUser || this.featureStatus(route);
  }

  private featureStatus(routeConfig: Route): boolean {
    const featureName = routeConfig.data?.featureToggle?.featureName;
    const isActive = this.isFeatureActive(featureName);

    if (!isActive) {
      this.router.navigate(['page-not-found'], { skipLocationChange: true });
    }
    return isActive;
  }
}
