import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { sortBy } from 'lodash';
import { ReportType } from '../enum/report-type.enum';

declare const $localize;
@Injectable()
export class ReportTypes extends SelectInputsList {
  keyValue = {
    [ReportType.MerchantSettlementReport]: $localize`:transaction channel|:APM - Merchant Settlement Report`,
    [ReportType.DailyTransactionReport]: $localize`:transaction channel|:APM - Daily Transaction Report`,
    [ReportType.WICReconciliationTransactionReport]: $localize`:WIC reconciliation report type|:WIC Reconciliation Transaction Report`,
    [ReportType.WICReconciliationAdjustmentReport]: $localize`:WIC reconciliation report type|:WIC Reconciliation Adjustment Report`,
    [ReportType.WICReconciliationSummaryReport]: $localize`:WIC reconciliation report type|:WIC Reconciliation Summary Report`,
    [ReportType.CustomScheduledReport]: $localize`:Custom scheduled report|:Custom Scheduled Report`,
    [ReportType.PartnerCommissionReport]: $localize`:Partner commission report|:Partner Commission Report`,
    [ReportType.BBXMonthlyInvoiceReport]: $localize`:Monthly invoice report|:Monthly invoice report`,
    [ReportType.UkTaxReport]: $localize`:UK Tax Report|:UK Tax Report`,
    [ReportType.EuTaxReport]: $localize`:EU Tax Report|:EU Tax Report`,
    [ReportType.BBXDailyInvoiceReport]: $localize`:BBX Daily invoice report|:Payout report`,
  };
  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);

  constructor() {
    super();
  }
}
