import { FiltersList, FilterTypes } from '@portal/shared/ui/filter';

const APP_CATALOG_APP_STATUS_SELECTOR = {
  selector: 'appcatalogAppStatus',
  title: 'Status',
  type: FilterTypes.Single,
  data: [
    { text: 'Available', id: 'AVAILABLE' },
    { text: 'Pending', id: 'PENDING' },
    { text: 'Approved', id: 'APPROVED' },
    { text: 'Ready', id: 'READY' },
    { text: 'Failed', id: 'FAILED' },
    { text: 'Published', id: 'PUBLISHED' },
    { text: 'Revoked', id: 'REVOKED' },
  ],
};

const APPS_FILTER_LISTS: FiltersList[] = [
  {
    selector: 'appName',
    title: 'Application Name',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'Application Name',
        id: 'appName',
      },
    ],
  },

  {
    selector: 'pricingModel',
    title: 'Payment Model',
    type: FilterTypes.Single,
    data: [
      { text: 'Free', id: 'FREE' },
      { text: 'One time', id: 'ONE_TIME' },
      { text: 'Recurring', id: 'RECURRING' },
    ],
  },
  {
    selector: 'deviceFamily',
    title: 'Model Family',
    type: FilterTypes.Single,
    data: [{ text: 'Carbon Mobile', id: 'CarbonMobile' }],
  },
  {
    selector: 'category',
    title: 'Category',
    type: FilterTypes.Single,
    data: [
      { text: 'Advertising and media', id: 'ADVERTISING_AND_MEDIA' },
      { text: 'Loyalty and promotion', id: 'LOYALTY_AND_PROMOTION' },
      { text: 'Inventory management', id: 'INVENTORY_MANAGEMENT' },
      { text: 'Alternate payments', id: 'ALTERNATE_PAYMENTS' },
      { text: 'Charity', id: 'CHARITY' },
      { text: 'Customer engagement', id: 'CUSTOMER_ENGAGEMENT' },
      { text: 'Customer relationship management', id: 'CUSTOMER_RELATIONSHIP_MANAGEMENT' },
      { text: 'Employee management', id: 'EMPLOYEE_MANAGEMENT' },
      { text: 'Finance', id: 'FINANCE' },
      { text: 'Order management', id: 'ORDER_MANAGEMENT' },
      { text: 'Reporting and analytics', id: 'REPORTING_AND_ANALYTICS' },
    ],
  },
  {
    selector: 'isFeatured',
    title: 'Featured',
    type: FilterTypes.Toggle,
    data: [{ text: 'Featured', id: 'isFeatured' }],
  },
  {
    selector: 'isPublic',
    title: 'Public',
    type: FilterTypes.Toggle,
    data: [{ text: 'Public', id: 'isPublic' }],
  },
  {
    selector: 'isPilot',
    title: 'Pilot',
    type: FilterTypes.Toggle,
    data: [{ text: 'Pilot', id: 'isPilot' }],
  },
];

const APPS_FILTER_LIST: FiltersList[] = [
  {
    selector: 'appName',
    title: 'Application Name',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'Application Name',
        id: 'appName',
      },
    ],
  },

  {
    selector: 'pricingModel',
    title: 'Payment Model',
    type: FilterTypes.Single,
    data: [
      { text: 'Free', id: 'FREE' },
      { text: 'One time', id: 'ONE_TIME' },
      { text: 'Recurring', id: 'RECURRING' },
    ],
  },
  {
    selector: 'deviceFamily',
    title: 'Model Family',
    type: FilterTypes.Single,
    data: [{ text: 'Carbon Mobile', id: 'CarbonMobile' }],
  },
  {
    selector: 'category',
    title: 'Category',
    type: FilterTypes.Single,
    data: [
      { text: 'Advertising and media', id: 'ADVERTISING_AND_MEDIA' },
      { text: 'Loyalty and promotion', id: 'LOYALTY_AND_PROMOTION' },
      { text: 'Inventory management', id: 'INVENTORY_MANAGEMENT' },
      { text: 'Alternate payments', id: 'ALTERNATE_PAYMENTS' },
      { text: 'Charity', id: 'CHARITY' },
      { text: 'Customer engagement', id: 'CUSTOMER_ENGAGEMENT' },
      { text: 'Customer relationship management', id: 'CUSTOMER_RELATIONSHIP_MANAGEMENT' },
      { text: 'Employee management', id: 'EMPLOYEE_MANAGEMENT' },
      { text: 'Finance', id: 'FINANCE' },
      { text: 'Order management', id: 'ORDER_MANAGEMENT' },
      { text: 'Reporting and analytics', id: 'REPORTING_AND_ANALYTICS' },
    ],
  },
  {
    selector: 'isFeatured',
    title: 'Featured',
    type: FilterTypes.Toggle,
    data: [{ text: 'Featured', id: 'isFeatured' }],
  },
  {
    selector: 'isPublic',
    title: 'Public',
    type: FilterTypes.Toggle,
    data: [{ text: 'Public', id: 'isPublic' }],
  },
  {
    selector: 'isPilot',
    title: 'Pilot',
    type: FilterTypes.Toggle,
    data: [{ text: 'Pilot', id: 'isPilot' }],
  },
  {
    selector: 'isDefault',
    title: 'Terminal Default',
    type: FilterTypes.Toggle,
    data: [{ text: 'Default', id: 'isDefault' }],
  },
];

export const appsFiltersList: FiltersList[] = [...APPS_FILTER_LISTS];
export const appsFiltersListEO: FiltersList[] = [...APPS_FILTER_LIST];

export const appsVaultFiltersList: FiltersList[] = [
  {
    selector: 'appName',
    title: 'Application Name',
    type: FilterTypes.MultipleSearch,
    data: [
      {
        text: 'Application Name',
        id: 'appName',
      },
    ],
  },

  {
    selector: 'pricingModel',
    title: 'Payment Model',
    type: FilterTypes.Single,
    data: [
      { text: 'Free', id: 'FREE' },
      { text: 'One time', id: 'ONE_TIME' },
      { text: 'Recurring', id: 'RECURRING' },
    ],
  },
  {
    selector: 'deviceFamily',
    title: 'Model Family',
    type: FilterTypes.Single,
    data: [{ text: 'Carbon Mobile', id: 'CarbonMobile' }],
  },
  {
    selector: 'vaultStatus',
    title: 'Vault Status',
    type: FilterTypes.Single,
    data: [
      { text: 'Available', id: 'AVAILABLE' },
      { text: 'Pending', id: 'PENDING' },
      { text: 'Approved', id: 'APPROVED' },
      { text: 'Ready', id: 'READY' },
      { text: 'Failed', id: 'FAILED' },
      { text: 'Published', id: 'PUBLISHED' },
      { text: 'Revoked', id: 'REVOKED' },
    ],
  },
  {
    selector: 'category',
    title: 'Category',
    type: FilterTypes.Single,
    data: [
      { text: 'Advertising and media', id: 'ADVERTISING_AND_MEDIA' },
      { text: 'Loyalty and promotion', id: 'LOYALTY_AND_PROMOTION' },
      { text: 'Inventory management', id: 'INVENTORY_MANAGEMENT' },
      { text: 'Alternate payments', id: 'ALTERNATE_PAYMENTS' },
      { text: 'Charity', id: 'CHARITY' },
      { text: 'Customer engagement', id: 'CUSTOMER_ENGAGEMENT' },
      { text: 'Customer relationship management', id: 'CUSTOMER_RELATIONSHIP_MANAGEMENT' },
      { text: 'Employee management', id: 'EMPLOYEE_MANAGEMENT' },
      { text: 'Finance', id: 'FINANCE' },
      { text: 'Order management', id: 'ORDER_MANAGEMENT' },
      { text: 'Reporting and analytics', id: 'REPORTING_AND_ANALYTICS' },
    ],
  },
  {
    selector: 'isFeatured',
    title: 'Featured',
    type: FilterTypes.Toggle,
    data: [{ text: 'Featured', id: 'isFeatured' }],
  },
  {
    selector: 'appcatalogIsDefaultApp',
    title: 'Terminal Default Capable',
    type: FilterTypes.Toggle,
    data: [{ text: 'Default', id: 'isDefault' }],
  },
];
