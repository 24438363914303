import { Injectable } from '@angular/core';
import { SettlementType } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class SettlementTypes extends SelectInputsList {
  keyValue = {
    [SettlementType.Online]: $localize`:Settlement type|:Online`,
    [SettlementType.File]: $localize`:Settlement type|:File`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
