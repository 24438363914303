import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vui-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent {
  constructor(private router: Router) {}
  navigateDashboard(): void {
    this.router.navigate(['/'], { state: { redirectToInitialPage: true } });
  }
}
