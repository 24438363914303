import { Injectable } from '@angular/core';
import {
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { DateService } from './date.service';

@Injectable()
export class LocalisedNgbDateParserFormatter
  extends NgbDateNativeAdapter
  implements NgbDateParserFormatter
{
  constructor(private dateService: DateService) {
    super();
  }

  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }
    const nativeDate = this.dateService.fromLocalisedShortString(value);
    return super.fromModel(nativeDate);
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }

    const nativeDate = super.toModel(date);
    return this.dateService.toLocalisedShortString(nativeDate);
  }
}
