import { Injectable } from '@angular/core';
import { ContactType } from '@portal/entity-services/interfaces';
// Fixing cyclic dependency that fails portal unit tests
import { SelectInputsList } from '@portal/shared/helpers/src/lib/create-list-from-inputs/select-input-list';
import { ISelectInput } from '@portal/shared/ui/filter';
import { sortBy } from 'lodash';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class ContactTypes extends SelectInputsList {
  keyValue = {
    [ContactType.Business]: $localize`Business`,
    [ContactType.Owner]: $localize`Owner`,
    [ContactType.Personal]: $localize`Personal`,
    [ContactType.ManageDisputes]: $localize`Manage Disputes`,
  };

  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);

  constructor() {
    super();
  }
}
