<div class="dropdown" [class.is-active]="isOpen" (portalClickOutside)="closeFilter()">
  <div class="dropdown-trigger">
    <button
      [attr.aria-expanded]="isOpen"
      class="button is-chip is-size-7-mobile"
      type="button"
      (click)="toggleFilter()"
      [class.is-active]="isOpen"
      title="{{ title }}"
    >
      <span>{{ title }}</span>
      <span class="icon">
        <i class="fas fa-chevron-down"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu">
    <div class="dropdown-content">
      <div class="dropdown-item">
        <div class="control has-icons-left">
          <input
            #searchRef
            class="input is-small"
            type="text"
            [placeholder]="placeholder"
            (input)="onSearch(searchRef.value)"
          />
          <span class="icon is-small is-left">
            <i class="fa fa-search"></i>
          </span>
        </div>
      </div>
      <div class="dropdown-divider"></div>
      <div class="dropdown-item">
        <ng-container *ngIf="isLoading$ | async"><portal-spinner></portal-spinner></ng-container>

        <tree-root #tree [nodes]="nodes$ | async" [options]="options" (updateData)="onUpdate()">
          <ng-template #loadingTemplate
            ><portal-spinner [isSmall]="true"></portal-spinner
          ></ng-template>
          <ng-template #treeNodeFullTemplate let-node let-index="index" let-templates="templates">
            <div
              [class]="node.getClass()"
              [class.tree-node]="true"
              [class.tree-node-expanded]="node.isExpanded && node.hasChildren"
              [class.tree-node-collapsed]="node.isCollapsed && node.hasChildren"
              [class.tree-node-leaf]="node.isLeaf"
              [class.tree-node-active]="node.isActive"
              [class.tree-node-focused]="node.isFocused"
            >
              <tree-node-drop-slot
                *ngIf="index === 0"
                [dropIndex]="node.index"
                [node]="node.parent"
              >
              </tree-node-drop-slot>

              <tree-node-wrapper [node]="node" [index]="index" [templates]="templates">
              </tree-node-wrapper>

              <tree-node-children [node]="node" [templates]="templates"> </tree-node-children>

              <tree-node-drop-slot [dropIndex]="node.index + 1" [node]="node.parent">
              </tree-node-drop-slot>
            </div>
          </ng-template>

          <ng-template #treeNodeWrapperTemplate let-node let-index="index">
            <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
              <ng-container *ngIf="node.data.type !== lazyloadid">
                <label class="checkbox is-inline">
                  <input
                    type="checkbox"
                    (click)="node.mouseAction('checkboxClick', $event)"
                    [checked]="node.isSelected"
                    [indeterminate]="node.isPartiallySelected"
                    tabindex="-1"
                  />
                  <span></span>
                </label>
                <tree-node-expander [node]="node"></tree-node-expander>
              </ng-container>
              <div class="node-content-wrapper" (click)="node.mouseAction('click', $event)">
                <tree-node-content
                  [node]="node"
                  [index]="index"
                  [template]="treeNodeTemplate"
                ></tree-node-content>
              </div>
            </div>
          </ng-template>

          <ng-template #treeNodeTemplate let-node let-index="index">
            <ng-container
              *ngIf="templates?.treeNodeTemplate; else treeNodeDefaultTemplate"
              [ngTemplateOutlet]="templates?.treeNodeTemplate()"
              [ngTemplateOutletContext]="{ $implicit: node, index: index }"
            ></ng-container>
            <ng-template #treeNodeDefaultTemplate>
              {{ node.id }}
            </ng-template>
          </ng-template>
        </tree-root>
      </div>
      <div class="dropdown-divider"></div>
      <div class="dropdown-item">
        <button
          class="button is-primary"
          type="button"
          (click)="onFilterApplied()"
          title="Apply"
          i18n-title
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</div>
