import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  OnInit,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { IDateFilter, DateService } from '@portal/shared/helpers';
import {
  FilterContentItem,
  FILTER_CONTENT_ITEM,
  IFilterToApply,
  ISearchCriteria,
  ISelectInput,
  SearchSelector,
} from '@portal/shared/ui/filter/src';
import { endOfDay, isAfter, startOfDay } from 'date-fns';
import { Observable } from 'rxjs';
import { QuickPicks } from '../enums/date.enum';
declare const $localize;

@Component({
  selector: 'portal-dashboard-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: DateRangeComponent,
    },
  ],
})
export class DateRangeComponent extends FilterContentItem implements OnInit, OnChanges {
  @Output()
  filterApplied: EventEmitter<IFilterToApply[]> = new EventEmitter();

  @Output()
  filterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();

  @Output()
  quickPickApplied: EventEmitter<QuickPicks> = new EventEmitter();

  @Output()
  checkedUpdated: EventEmitter<boolean> = new EventEmitter();

  @Output()
  customSelection: EventEmitter<IDateFilter> = new EventEmitter();

  @Output()
  fromDateSelection: EventEmitter<Date> = new EventEmitter();

  @Output() closeSubmenus: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  selectedEntities: Map<string, ISearchCriteria> = new Map();

  @Input()
  quickPicks: ISelectInput[];

  @Input()
  labelName: string;

  @Input()
  isCompareType: boolean;

  @Input()
  isCheckedDefault = true;

  @Input()
  isDateRangesOverlapping = false;

  @Input()
  isValidDiffLength = true;

  @Input()
  isValidDateFrom = true;

  @Input()
  isNewDesignFilter = false;

  @ViewChild('dateFilter') dateFilter: ElementRef;

  selector: string;

  itemToggled: EventEmitter<number> = new EventEmitter();
  isOpen = false;
  selectedQuickPick: QuickPicks = QuickPicks.Today;

  fromDate: NgbDate;
  toDate: NgbDate;

  dateHeaderText = $localize`Date`;

  isQuickPickApplied: boolean;
  isInvalidDate = false;
  constructor(
    public formatter: NgbDateParserFormatter,
    private dateService: DateService,
    private elementRef: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.isCompareType) {
      this.selector = SearchSelector.CompareTo;
    } else {
      this.selector = SearchSelector.DateRange;
    }
    this.isQuickPickApplied = false;
    this.setSelectedEntities(this.selectedEntities);
    this.updateDateHeaderText();
  }

  setAutoFocusState(): void {
    this.dateFilter.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['selectedEntities'];
    if (change && !change.firstChange) {
      this.setSelectedEntities(change.currentValue);
    }
  }

  getList(): Observable<ISelectInput[]> {
    return null;
  }

  areDatesValid(): boolean {
    return (
      !this.isInvalidDate &&
      Boolean(this.toDate && this.fromDate) &&
      !this.isDateRangesOverlapping &&
      this.isValidDiffLength &&
      this.isValidDateFrom
    );
  }
  updateIsInvalidDate(date: boolean): void {
    this.isInvalidDate = date;
  }
  onFromDateApplied(fromDate: NgbDate): void {
    this.fromDate = fromDate;
    this.fromDateSelection.emit(
      new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day),
    );
  }

  onToDateApplied(toDate: NgbDate): void {
    this.toDate = toDate;
    if (this.fromDate && this.toDate) {
      this.customSelection.emit({
        start: startOfDay(
          new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day),
        ).toISOString(),
        end: endOfDay(
          new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day),
        ).toISOString(),
      });
    }
  }

  onFilterApplied(): void {
    const dateRangeFilters = [];
    const toDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
    const now = new Date();

    dateRangeFilters.push({
      key: `${this.selector}_${SearchSelector.FromDate}`,
      operator: '=',
      argument: startOfDay(
        new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day),
      ).toISOString(),
      value: [],
      selector: SearchSelector.FromDate,
    });
    dateRangeFilters.push({
      key: `${this.selector}_${SearchSelector.ToDate}`,
      operator: '=',
      argument: (isAfter(endOfDay(toDate), now) ? now : endOfDay(toDate)).toISOString(),
      value: [],
      selector: SearchSelector.ToDate,
    });

    this.updateDateHeaderText();
    this.filterApplied.emit(dateRangeFilters);
    this.isOpen = false;
  }

  onCheckboxToggle(): void {
    this.isCheckedDefault = !this.isCheckedDefault;
    this.updateDateHeaderText();
    this.checkedUpdated.emit(this.isCheckedDefault);
    if (!this.isCheckedDefault && this.isOpen) {
      this.itemToggled.emit();
    }
  }

  onQuickPickApplied(value: QuickPicks): void {
    this.selectedQuickPick = value;
    this.isQuickPickApplied = true;
    this.quickPickApplied.emit(value);
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  onKeyDownSpace(event: Event): void {
    this.isOpen = !this.isOpen;
    event.preventDefault();
  }

  onKeyDownSpaceApply(event: Event): void {
    this.onFilterApplied();
    event.preventDefault();
  }

  private setSelectedEntities(selectedEntities: Map<string, ISearchCriteria>): void {
    this.selectedEntities = selectedEntities;
    if (this.selectedEntities && this.selectedEntities.size) {
      Array.from(selectedEntities.values()).forEach((input: ISearchCriteria) => {
        const date = new Date(input.argument);
        const dateAfterFormatted = NgbDate.from({
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        });
        if (input.selector.includes('from')) {
          this.fromDate = dateAfterFormatted;
        } else {
          this.toDate = dateAfterFormatted;
        }
      });
    }

    if (this.isCompareType && this.isCheckedDefault && !this.isQuickPickApplied) {
      this.updateDateHeaderText();
    }
    this.isQuickPickApplied = false;
  }

  private updateDateHeaderText(): void {
    if (this.isCompareType && !this.isCheckedDefault) {
      this.dateHeaderText = `${this.dateService.getPlaceholder()} — ${this.dateService.getPlaceholder()}`;
      return;
    }

    if (this.fromDate?.equals(this.toDate)) {
      this.dateHeaderText = `${this.formatter.format(this.fromDate)}`;
    } else {
      this.dateHeaderText = `${this.formatter.format(this.fromDate)} — ${this.formatter.format(
        this.toDate,
      )}`;
    }
  }
}
