import { Component, Input } from '@angular/core';
import { ISearchCriteria } from '../search/interfaces/search-criteria.interface';

@Component({
  selector: 'portal-legend-status',
  templateUrl: './legend-status.component.html',
  styleUrls: ['./legend-status.component.scss'],
})
export class LegendStatusComponent {
  @Input() criteriaMap: Map<string, ISearchCriteria>;
  @Input() getScreenWidth: number;
}
