import { EntityMetadataMap } from '@ngrx/data';
import { ES_CONSTANTS as CONSTANTS, IOrganisation } from '@portal/entity-services/interfaces';

export const entityMetadata: EntityMetadataMap = {
  [CONSTANTS.ENTITY_SERVICE.ORGANISATION]: {
    selectId: (org: IOrganisation) => {
      return org.entityUid;
    },
  },
};
