import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { paginationInitialState, IApp, IStorePagination } from '@portal/marketplace/api-interfaces';

export interface IAppState extends IStorePagination<IApp> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'apps', idKey: 'appmarketAppId', resettable: true })
export class IAppStore extends EntityStore<IAppState, IApp> {
  constructor() {
    super(paginationInitialState);
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'apps-mm', idKey: 'id', resettable: true })
export class IAppStoreMMandDev extends EntityStore<IAppState, IApp> {
  constructor() {
    super(paginationInitialState);
  }
}
