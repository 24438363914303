<div class="container is-narrow">
  <a
    restrict-permission
    [routerLink]="['/administration', 'organisations']"
    data-e2e="link-back"
    title="Back to organisations"
    i18n-title
  >
    <span class="icon is-small">
      <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
    </span>
    <span i18n>Organisations</span>
  </a>

  <ng-container *ngIf="!loading; else loadingTemplate">
    <h1 class="title is-2" data-e2e="title">{{ organisation?.name }}</h1>

    <portal-form-organisation
      *ngIf="organisation; else noOrganisationTemplate"
      [canEditWhiteLabel]="canEditWhiteLabel"
      [templateParameters]="templateParameters"
      [organisations]="organisations"
      [disabled]="loading"
      [organisation]="organisation"
      [countries]="countries"
      [bankAccountsValue]="bankAccountsValue"
      [rootEntityIdForParentUpdate]="rootEntityIdForParentUpdate"
      [merchantCompanyEntityCount]="merchantCompanyEntityCount"
      [trashCanOrgsPresent]="trashCanOrgsPresent"
      (formValidated)="onFormValidated($event)"
      (whiteLabelFeatureActiveForOrg)="isWhiteLabelFeatureActiveForOrg = $event"
      [poiCutoverConfigurations]="poiCutoverConfigurations"
    >
    </portal-form-organisation>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <div class="box mt-4" data-e2e="loading">
    <portal-spinner
      [isHorizontal]="true"
      [isSmall]="true"
      text="Loading organisation"
      i18n-text
    ></portal-spinner>
  </div>
</ng-template>

<ng-template #noOrganisationTemplate>
  <div class="box has-text-centered" i18n>No organisation found</div>
</ng-template>
