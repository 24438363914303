import { Component, Input, OnInit } from '@angular/core';
import { AppStatus, IApp } from '@portal/marketplace/api-interfaces/src';

@Component({
  selector: 'marketplace-app-details-template',
  templateUrl: './app-details-template.component.html',
  styleUrls: ['./app-details-template.component.scss'],
})
export class AppDetailsTemplateComponent implements OnInit {
  @Input() app: IApp;
  status = AppStatus;

  constructor() {}

  ngOnInit(): void {}
}
