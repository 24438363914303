import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationExtras,
  Route,
  Router,
  Routes,
} from '@angular/router';
import { AuthenticationService } from '@portal/shared/auth/authentication/src';
import isEmpty from 'lodash-es/isEmpty';
import intersection from 'lodash-es/intersection';
import { IGuardPermissions } from '../interfaces/guard-permissions.interface';
import { IGuardRoute } from '../interfaces/guard-route.interface';
import { AuthorizationService } from '../authorization.service';

@Injectable({
  providedIn: 'root',
})
export class RestrictPermissionService {
  constructor(
    private authorizationService: AuthorizationService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  getPermissionsForUrl(
    commands: string | string[],
    navigationExtras?: NavigationExtras,
  ): IGuardPermissions {
    const path = this.convertRouterCommandsToPath(commands, navigationExtras);
    let urlRoute: Route = this.router.config.find((config: IGuardRoute) => {
      return config.path && config.path.includes(path);
    });

    if (!urlRoute) {
      urlRoute = this.deepFind(this.router.config, path);
    }

    return urlRoute?.data?.permission;
  }

  getPermissionsForRoute(route: ActivatedRoute | ActivatedRouteSnapshot): IGuardPermissions {
    return route.routeConfig?.data?.permission;
  }

  /**
   * Used to determinate access according to described permissions object and actions.
   *
   * If some of the actions not described in permissions object will means that it allowed by default.
   * otherwise will detect if role is allowed for some action.
   */
  checkPermissions(permissions: IGuardPermissions, actions: string[]): boolean {
    const rolesFromToken: string[] = this.authorizationService.getRoles() || [];
    if (!permissions) return true;

    actions = actions.filter((action) => permissions.hasOwnProperty(action));

    if (!actions.length) {
      return true;
    }

    return (
      actions.some((action) => !isEmpty(intersection(rolesFromToken, permissions[action]))) &&
      !this.authorizationService.userIsDeleted
    );
  }

  /**
   * Used for deep findind currentRoute
   * For example, some modules can be wrapped into anather module like in IONIC app
   * this is not adding additional path level, like it used as path: ''
   *
      data: {}
      path: ""
      _loadedConfig: LoadedRouterConfig

        routes: Array(2)
        0: {path: "", _loadedConfig: LoadedRouterConfig, component: ƒ, loadChildren: ƒ}
          path: ""
          _loadedConfig: LoadedRouterConfig
            routes: Array(4)
            0: {path: "administration/organisations", canActivate: Array(2), data: {…}, component: ƒ}
            1: {path: "administration/organisations/create", canActivate: Array(2), data: {…}, component: ƒ}
            2: {path: "administration/organisations/:id/edit", canActivate: Array(2), data: {…}, component: ƒ}
            3: {path: "administration/organisations/:id", canActivate: Array(2), data: {…}, component: ƒ}
   */
  private deepFind(configs: Routes, path: string): Route {
    const pathSegments = path.split('/');
    const isMatch = (route: Route, routePath: string): boolean => {
      if (routePath.includes(path)) return true;
      const routePathSegments = routePath.split('/');
      return (
        pathSegments.length <= routePathSegments.length &&
        pathSegments.every((segment, index) => {
          const routePathSegment = routePathSegments[index];
          return routePathSegment.startsWith(':') || segment === routePathSegment;
        })
      );
    };
    const findInRoutes = (routes: Routes, pathPrefix: string): Route => {
      for (const route of routes) {
        const routePath = [pathPrefix, route.path].filter(Boolean).join('/');
        if (isMatch(route, routePath)) return route;
        if (route.children) {
          const fromChildren = findInRoutes(route.children, routePath);
          if (fromChildren) return fromChildren;
        }
        const loadedChildren = route['_loadedConfig']?.routes;
        if (loadedChildren) {
          const fromLoadedChildren = findInRoutes(loadedChildren, routePath);
          if (fromLoadedChildren) return fromLoadedChildren;
        }
      }
    };

    return findInRoutes(configs, '');
  }

  private convertRouterCommandsToPath(
    commands: string | string[],
    navigationExtras?: NavigationExtras,
  ): string {
    const urlTree = this.router.createUrlTree(
      Array.isArray(commands) ? commands : [commands],
      navigationExtras ?? { relativeTo: this.route },
    );
    let path = urlTree.toString().split('?')[0];
    let id: string = this.route.snapshot.paramMap.get('id');
    if (!id) {
      id = this.route.firstChild?.snapshot?.paramMap?.get('id');
    }
    if (id) {
      path = path.replace(id, ':id');
    }
    path = path.replace(this.authenticationService.userUid, ':id');
    return path.replace(/^\//, '');
  }
}
