import { Component, Input } from '@angular/core';
import { FILTER_CONTENT_ITEM, ISelectInput } from '@portal/shared/ui/filter';
import { FilterBase } from '@portal/shared/ui/filter/src/lib/filter-base';
import { Subject } from 'rxjs';
import { SearchSelector } from '../../../enums/search-selector.enum';
import { EntityTypes } from '../../form/entity-type.list';

@Component({
  selector: 'portal-filter-entity-type',
  templateUrl: './entity-type.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: FilterEntityTypeComponent,
    },
  ],
})
export class FilterEntityTypeComponent extends FilterBase {
  @Input() disabled = false;
  entityTypeList: ISelectInput[] = this.entityTypes.list;
  refresh$ = new Subject<void>();

  constructor(private entityTypes: EntityTypes) {
    super(SearchSelector.EntityType, entityTypes.list);
  }

  removeAllEntities(): void {
    this.localPreSelectedEntities.clear();
    this.refresh$.next();
  }

  onEntityTypeSelection(selectedEntity: ISelectInput): void {
    this.removeAllEntities();
    this.onEntityAdded(selectedEntity);
  }
}
