<div class="container is-narrow">
  <a
    restrict-permission
    [routerLink]="['/administration', 'organisations']"
    data-e2e="link-back"
    title="Back to organisations"
    i18n-title
  >
    <span class="icon is-small">
      <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
    </span>
    <span i18n>Organisations</span>
  </a>

  <h1 class="title is-2" data-e2e="title" i18n>Add new organisation</h1>

  <portal-form-organisation
    [canEditWhiteLabel]="false"
    [countries]="countries"
    [disabled]="saving"
    [isCreatingOrganisation]="true"
    (formValidated)="onFormValidated($event)"
    [poiCutoverConfigurations]="poiCutoverConfigurations"
  ></portal-form-organisation>
</div>
