import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@apps/portal/src/app/shared/shared.module';
import { SharedAuthFeatureToggleModule } from '@portal/shared/auth/feature-toggle/src/lib/shared-auth-feature-toggle.module';
import { NotifierModule } from '@portal/shared/ui/notifier';
import { SpinnerModule } from '@portal/shared/ui/spinner';
import { HeroModule } from '../hero/hero.module';
import { TopNavbarModule } from '../top-navbar/top-navbar.module';
import { PortalComponent } from './portal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TopNavbarModule,
    NotifierModule,
    SpinnerModule,
    SharedAuthFeatureToggleModule,
    SharedModule,
    HeroModule,
  ],
  declarations: [PortalComponent],
  exports: [PortalComponent],
})
export class PortalModule {}
