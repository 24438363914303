<portal-table
  [items]="deviceList"
  [tableConfig]="tableConfig"
  rowLinkHref="/devices/device"
  rowLinkHeading="Link to device details"
  i18n-rowLinkHeading="Link to terminal page"
  loadingMessage="Loading terminals"
  i18n-loadingMessage="Loading Terminals"
  notFoundMessage="No terminals found"
  i18n-notFoundMessage="No Terminals found"
>
</portal-table>
