import { Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { AuthenticationService } from '@portal/shared/auth/authentication';
import { EnvironmentVariableService } from '@portal/shared/helpers/src';
import { BaseModalComponent } from '@portal/shared/ui/modal/src';
import { PortalBase } from '../portal-base';
import environment from '../../../../../../../environments/environment';
import { Features } from '../../../../../../../environments/enums/features.enum';
import { IUser, UserRole, UserStatus } from '@portal/entity-services/interfaces';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import { TermsAndConditions } from '../enums/terms-and-conditions.enum';
import { ITermsAndConditions } from '@portal/entity-services/interfaces/src/lib/users/interfaces/terms-and-conditions.interface';
import { ToastService } from '@portal/shared/ui/toast';
import { WhiteLabelingService } from '@portal/shared/white-labeling/src';
import { take } from 'rxjs/operators';
import { Subscription, timer } from 'rxjs';
import { MandatoryModalComponent } from '@portal/shared/ui/modal/src/lib/mandatory-modal/mandatory-modal.component';
@Component({
  selector: 'vui-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
})
export class PortalComponent extends PortalBase {
  @Input() withSignInSplash = true; // todo: factor out the splash section into its own component to eliminate this kludge
  @Input() isWhiteLabelingPreview = false;
  @Input() isFromVhq = false;
  @Input() set logoSrc(value: string) {
    if (value) {
      this._logoSrc = value;
    }
  }
  get logoSrc(): string {
    return this._logoSrc;
  }

  @Output() useEvents = new EventEmitter<any>();
  @ViewChild('acceptTermsAndConditionsModal') acceptTnCModal: BaseModalComponent;
  @ViewChild('userActivityModal') userActivityModal: MandatoryModalComponent;
  termsConditionLink = environment?.TERMS_AND_CONDITIONS?.TC_URL;
  currentTnCVersion = environment?.TERMS_AND_CONDITIONS?.TC_VERSION;
  isFederatedUser: boolean;
  needsToAcceptTnC: boolean;
  needsToAcceptNewVersion: boolean;
  brandText = 'Verifone Central';
  isCBA = EnvironmentVariableService.getVar('CBA');
  isSessionTimeout: boolean;
  isUserIdleTimeout: boolean;
  channel = new BroadcastChannel('activity_channel');
  private userIdleTimer$: Subscription;
  private userActivity$: Subscription;
  private userActivityModalTimer$: Subscription;
  private userSessionTimer$: Subscription;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _logoSrc =
    'https://d3slnjjr5hy7v5.cloudfront.net/assets/logos/linear/verifone/verifone_logotype_black.png';

  constructor(public injector: Injector, private toastService: ToastService) {
    super(injector);
    this.whiteLabelingService = injector.get(WhiteLabelingService);
    this.authorizationService.federatedUser$.subscribe((data) => {
      this.isFederatedUser = data;
    });

    this.authenticationService.authEvents.subscribe((event: AuthenticationService.IEvent) => {
      this.useEvents.next({
        type: event.type,
        data: {
          ...event.data,
          authenticatedUser: this.authenticatedUser,
          appsPermissions: this.appsPermissions,
        },
      });

      switch (event.type) {
        case AuthenticationService.Event.LoggedIn: {
          this.setAuthLoadingState(false);
          this.isAuthenticated = true;
          this.brandText = this.whiteLabelingService.getBrandText() || this.brandText;
          this.showAcceptTnCModal();
          break;
        }
        case AuthenticationService.Event.LoggedOut:
          this.isAuthenticated = false;
          this.setAuthLoadingState(false);
          this.subscription.unsubscribe();
          if (
            !EnvironmentVariableService.getVar('CBA') &&
            !!!EnvironmentVariableService.getVar('IONIC.ON') &&
            !this.isFederatedUser
          ) {
            this.authenticationService.authenticate();
          }
          break;
        case AuthenticationService.Event.InProgress:
          this.setAuthLoadingState(true);
          break;
        case AuthenticationService.Event.AuthResponseSuccess:
        default:
          break;
      }
    });

    this.initUserActivity();
    if (!this.userSessionTimer$ && localStorage.getItem('session_start_time')) {
      this.startSessionTimer();
    }
    this.authenticationService.sessionTimer.subscribe(() => this.startSessionTimer());
    this.channel.onmessage = (message) => {
      if (message.data === 'reset_timer') {
        this.resetUserActivity();
      }
    };
  }

  continueUserSession(): void {
    this.userActivityModal.close();
    this.initUserActivity();
  }

  closeUserSession(): void {
    this.userActivityModal.close();
    this.userActivity$.unsubscribe();
    this.removeUserActivityModalTimer();
    this.removeUserIdleTimer();
    this.authenticationService.removeSilentRefreshEventListener();
    this.authenticationService.removeTokenRefreshTimers();
    this.authenticationService.logout();
  }

  initUserActivity(): void {
    this.userActivity$ = this.userActivityService.getActivityEvents().subscribe(() => {
      this.resetUserActivity();
      this.channel.postMessage('reset_timer');
    });
    this.resetUserActivity();
  }

  resetUserActivity(): void {
    this.removeUserIdleTimer();
    this.removeUserActivityModalTimer();
    this.startUserIdleTimer();
  }

  startUserIdleTimer(): void {
    this.userIdleTimer$ = timer(
      this.calculateUserInactivityTimeout(
        this.authenticationService.authConfigs.userInactivityTimeout,
        this.authenticationService.authConfigs.userInactivityTimeoutOffset,
      ),
    ).subscribe(() => {
      this.isUserIdleTimeout = true;
      this.isSessionTimeout = false;
      this.removeUserIdleTimer();
      this.userActivity$.unsubscribe();
      this.userActivityModal.open();
      this.userActivityModalTimer$ = timer(
        this.authenticationService.authConfigs.userInactivityTimeoutOffset,
      ).subscribe(() => {
        this.closeUserSession();
      });
    });
  }

  startSessionTimer(): void {
    const currentTime = new Date().getTime();
    const sessionStartTime = parseInt(localStorage.getItem('session_start_time'), 10);
    const diff =
      this.authenticationService.authConfigs.userSessionTimeout - (currentTime - sessionStartTime);
    this.userSessionTimer$ = timer(
      this.calculateUserInactivityTimeout(
        diff,
        this.authenticationService.authConfigs.userInactivityTimeoutOffset,
      ),
    ).subscribe(() => {
      this.isSessionTimeout = true;
      this.isUserIdleTimeout = false;
      this.removeUserIdleTimer();
      this.userActivity$.unsubscribe();
      this.userActivityModal.open();
      timer(this.authenticationService.authConfigs.userInactivityTimeoutOffset).subscribe(() => {
        this.closeUserSession();
      });
    });
  }

  removeUserIdleTimer(): void {
    this.userIdleTimer$?.unsubscribe();
  }

  removeUserActivityModalTimer(): void {
    this.userActivityModalTimer$?.unsubscribe();
  }

  calculateUserInactivityTimeout(timeout: number = 15, offset: number = 2): number {
    return timeout - offset;
  }

  updateUserTermConditions(termsAndConditions: ITermsAndConditions): void {
    const userDetails = this.getUserDetailsToSave();
    const userDetailsToSave = cloneDeep(userDetails);
    userDetailsToSave.termsAndConditions = termsAndConditions;

    this.esUserService.partialUpdate(userDetailsToSave).subscribe(
      (updatedUser) => {
        this.toastService.showToast(
          $localize`The consent of ${updatedUser.name} on Terms and Conditions is registered`,
        );
      },
      (errorObject: Object) => {
        this.notifierService.error(
          $localize`Error` +
            ` (${get(errorObject, 'error.status')}) ${get(errorObject, 'error.error.message')}`,
        );
      },
    );
  }

  getTodayDate(): Date {
    const currentDate: Date = new Date();
    return cloneDeep(currentDate);
  }

  get isTermsAndConditionsEnabled(): boolean {
    return this.featureToggleService.isFeatureActive(Features.TermsAndConditions);
  }

  get isLogoVisible(): boolean {
    return this.whiteLabelingService.isWhiteLabelingEnabled();
  }

  getUserDetailsToSave(): IUser {
    return {
      userUid: this.userService.userDetails.userUid,
      name: this.userService.userDetails.name,
      nameDetails: this.userService.userDetails.nameDetails,
      email: this.userService.userDetails.email,
      roles: this.userService.userDetails.roles as UserRole[],
      status: this.userService.userDetails.status as UserStatus,
      language: this.userService.userDetails.language,
    };
  }

  showAcceptTnCModal(): void {
    this.needsToAcceptTnC = false;
    this.needsToAcceptNewVersion = false;
    this.whiteLabelingService
      .isWhiteLabelingExists()
      .pipe(take(1))
      .subscribe((isWhitelabeledOrg) => {
        if (!isWhitelabeledOrg && this.isTermsAndConditionsEnabled) {
          if (
            !this.userService.userDetails?.termsAndConditions ||
            this.userService.userDetails?.termsAndConditions?.version !== this.currentTnCVersion ||
            this.userService.userDetails?.termsAndConditions?.status !== TermsAndConditions.Accepted
          ) {
            // Needs to accept the terms and conditions
            if (
              !this.userService.userDetails?.termsAndConditions ||
              this.userService.userDetails?.termsAndConditions?.status !==
                TermsAndConditions.Accepted
            ) {
              this.needsToAcceptTnC = true;
            }

            // Needs to accept just the new version
            else if (
              this.userService.userDetails?.termsAndConditions?.version !== this.currentTnCVersion
            ) {
              this.needsToAcceptNewVersion = true;
            }

            if (this.needsToAcceptNewVersion || this.needsToAcceptTnC) {
              this.acceptTnCModal?.open();
            }
          }
        }
      });
  }

  onAcceptTnC(): void {
    this.acceptTnCModal.close();
    const termsAndConditions: ITermsAndConditions = {
      status: TermsAndConditions.Accepted,
      owner: TermsAndConditions.Verifone,
      version: this.currentTnCVersion,
      userActionDate: this.getTodayDate().toISOString(),
    };

    this.updateUserTermConditions(termsAndConditions);
  }

  onTnCLogout(): void {
    const termsAndConditions: ITermsAndConditions = {
      status: TermsAndConditions.Rejected,
      owner: TermsAndConditions.Verifone,
      version: '',
      userActionDate: this.getTodayDate().toISOString(),
    };

    this.updateUserTermConditions(termsAndConditions);
    this.authenticationService.logout();
  }
}
