import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeModule } from '@circlon/angular-tree-component';

import { HierarchySingleSelectComponent } from './components/hierarchy-single-select.component';
import { SharedUiFilterModule } from '@portal/shared/ui/filter/src/lib/shared-ui-filter.module';
import { ClickOutsideModule } from '@portal/shared/ui/click-outside/src/lib/click-outside.module';
import { SpinnerModule } from '@portal/shared/ui/spinner';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TreeModule,
    ClickOutsideModule,
    SharedUiFilterModule,
    SpinnerModule,
    NgSelectModule,
    FormsModule,
  ],
  declarations: [HierarchySingleSelectComponent],
  exports: [HierarchySingleSelectComponent],
})
export class HierarchySingleSelectModule {}
