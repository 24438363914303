<portal-filter-wrapper>
  <div class="control">
    <portal-dashboard-date-range
      [labelName]="dateRange"
      [quickPicks]="dateRangeQuickPicksList"
      [selectedEntities]="searchCriteriaDateRange"
      (quickPickApplied)="onDateRangeQuickPickApplied($event)"
      [isValidDiffLength]="isValidDateRange"
      [isValidDateFrom]="isValidDateRangeFrom"
      (filterApplied)="onDateRageFilterApplied($event)"
      (customSelection)="onCustomSelectionDateRange($event)"
      (fromDateSelection)="onCustomSelectionFromDateRange($event)"
      [isNewDesignFilter]="isNewDesignFilter"
    >
    </portal-dashboard-date-range>
  </div>
  <div class="control">
    <portal-dashboard-date-range
      [labelName]="compareTo"
      [quickPicks]="compareToQuickPicksList"
      [selectedEntities]="searchCriteriaCompareTo"
      [isCompareType]="isCompareType"
      [isCheckedDefault]="isCompareToBoxChecked"
      [isValidDiffLength]="isValidDiffLength"
      [isDateRangesOverlapping]="isDateRangesOverlapping"
      (checkedUpdated)="onCompareToCheckedUpdated($event)"
      (quickPickApplied)="onCompareToQuickPickApplied($event)"
      [isValidDateFrom]="isValidDateCompareTo"
      (filterApplied)="onCompareToFilterApplied($event)"
      (customSelection)="onCustomSelectionCompareTo($event)"
      (fromDateSelection)="onCustomSelectionFromDateCompareTo($event)"
      [isNewDesignFilter]="isNewDesignFilter"
    >
    </portal-dashboard-date-range>
  </div>
</portal-filter-wrapper>
