import { Injectable } from '@angular/core';
import { IUser } from '@portal/entity-services/interfaces';

@Injectable({ providedIn: 'root' })
export class UserFullNameService {
  getFullUserName(user: IUser): string {
    return [
      user.nameDetails?.firstName?.trim(),
      user.nameDetails?.middleName?.trim(),
      user.nameDetails?.lastName?.trim(),
    ]
      .filter((n) => n)
      .join(' ');
  }
}
