import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

export class OperationError extends HttpErrorResponse {
  readonly method: string;
  readonly inTime: number;
  readonly outTime: number;

  constructor(init: {
    error?: any;
    headers?: HttpHeaders;
    status?: number;
    statusText?: string;
    url?: string;
    method: string;
    inTime: number;
    outTime: number;
  }) {
    super(init);
    this.method = init.method;
    this.inTime = init.inTime;
    this.outTime = init.outTime;
  }
}
