export enum UserRoleGroup {
  Merchant = 'MERCHANT',
  Verifone = 'VERIFONE',
  Provider = 'PROVIDER',
  Developer = 'DEVELOPER',
  EstateOwner = 'ESTATE_OWNER',
  Reseller = 'RESELLER',
  Partner = 'PARTNER',
  Petro = 'PETRO',
}
