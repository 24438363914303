import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariableService } from '@portal/shared/helpers/src/lib/environment-variables/environment-variables';
import { Observable } from 'rxjs';

/*

disable VHQ interceptor

Denis:
open console and paste:
localStorage.setItem("overrideMockVHQ", true);

*/

@Injectable({
  providedIn: 'root',
})
export class VhqMockInterceptorService implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let mockVHQ = EnvironmentVariableService.getVar('mockVHQ');

    // when `overrideMockVHQ` defined as false | true in localStorage
    if (localStorage.getItem('overrideMockVHQ') !== null) {
      // use it to override env flag
      mockVHQ = JSON.parse(localStorage.getItem('overrideMockVHQ'));
    }

    if (
      !!mockVHQ === true &&
      (request.url.indexOf('/assign') !== -1 || request.url.indexOf('/upload') !== -1) &&
      request.method !== 'GET'
    ) {
      let mockVHQResult: 'success' | 'failed' = 'success';

      if (localStorage.getItem('overrideMockVHQResult') !== null) {
        mockVHQResult = localStorage.getItem('overrideMockVHQResult') as 'success' | 'failed';
      }

      request = request.clone({
        setHeaders: {
          'use-vhq-stub': `true`,
          'vhq-stub-result': mockVHQResult,
        },
      });
    }

    return next.handle(request);
  }
}
