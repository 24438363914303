import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import isEqual from 'lodash-es/isEqual';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IOrganisation, IUser } from '@portal/entity-services/interfaces';
import { AuthenticationService } from '@portal/shared/auth/authentication';
import { BaseModalComponent } from '@portal/shared/ui/modal';
import { ToastService } from '@portal/shared/ui/toast';
import { LanguageHandle } from '@portal/shared/languages/src';
import { UserService as EntityUserService } from '../../services/user.service';
import { OrganisationService } from '@portal/entity-services/organisations/src/lib/services/organisation.service';
import { UserService } from '@portal/shared/user/src';
import { SelfCheckService } from '@portal/shared/auth/authorization';
import { UserFullNameService } from '../../services/user-full-name.service';
import { AuthorizationService } from '@portal/shared/auth/authorization/src/lib/authorization.service';
import { Application } from '@portal/shared/auth/authorization/src/lib/enums/application.enum';
import { HeroService } from '@portal/shared/ui/layouts';
import { MenuRegion } from '@portal/shared/ui/layouts/src/lib/top-navbar/enum/menu-region.enum';

declare const $localize;

@UntilDestroy()
@Component({
  selector: 'portal-update-user',
  templateUrl: './update.component.html',
})
export class UpdateUserComponent implements OnInit {
  @ViewChild('changeEntityModal') changeEntityModal: BaseModalComponent;

  loading = false;
  organisations$: Observable<IOrganisation[]>;
  user: IUser;
  loggedInUser: IUser;
  userId: string;
  confirmSaveUser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isMyProfile: boolean;
  saving = false;
  isPetroUser;

  constructor(
    private organisationService: OrganisationService,
    private entityUserService: EntityUserService,
    private userService: UserService,
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private liveAnnouncer: LiveAnnouncer,
    private authenticationService: AuthenticationService,
    private selfCheckService: SelfCheckService,
    private languageService: LanguageHandle,
    private userFullNameService: UserFullNameService,
    public authService: AuthorizationService,
    private heroService: HeroService,
  ) {
    this.organisations$ = this.organisationService.entities$;
  }

  ngOnInit(): void {
    this.heroService.showTitle($localize`Administration`, MenuRegion.Administration);
    this.isPetroUser = this.authService.isApplicationUser(Application.Petro);
    this.userId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.userId) {
      this.getUsers();
    }
  }

  getUsers(): void {
    this.loading = true;
    const loggedInUserId = this.authenticationService.userUid;
    const loggedInUser$ = this.userService.getByKey(loggedInUserId);
    const currentUser$ = this.userService.getByKey(this.userId);
    forkJoin([loggedInUser$, currentUser$])
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((users: IUser[]) => {
        this.user = users[1];
        this.loggedInUser = users[0];
        this.isMyProfile = this.selfCheckService.isSelfPage(this.activatedRoute.snapshot);
        this.title.setTitle($localize`:Page title|:Edit user ${this.user.name}`);
        this.liveAnnouncer.announce($localize`Navigated to ${this.title.getTitle()}`);
      });
  }

  onFormValidated(userFormData: IUser): void {
    const fullName = this.userFullNameService.getFullUserName(userFormData);
    userFormData.nameDetails.alternateFullName = userFormData.name = fullName;
    if (userFormData.entityUid !== this.user.entityUid) {
      this.changeEntityModal.open();
      this.confirmSaveUser$.next(false);
    } else {
      this.confirmSaveUser$.next(true);
    }

    if (!this.confirmSaveUser$.observers.length) {
      this.confirmSaveUser$
        .pipe(
          filter((isConfirm) => isConfirm),
          tap(() => {
            this.saving = true;
          }),
          switchMap(() => this.entityUserService.update(userFormData)),
          finalize(() => {
            this.changeEntityModal.close();
            this.saving = false;
          }),
          untilDestroyed(this),
        )
        .subscribe((updatedUser: IUser) => {
          this.toastService.showToast(
            $localize`${updatedUser.name} (${updatedUser.userUid}) is successfully updated`,
          );

          if (userFormData.defaultApplication) {
            this.userService.setDefaultApplication(userFormData.defaultApplication);
          }

          if (this.isMyProfile && !isEqual(this.user.roles, updatedUser.roles)) {
            this.authenticationService.logout();
          } else if (
            this.isMyProfile &&
            userFormData.language &&
            userFormData.language !== this.user.language
          ) {
            this.languageService.setLanguage(userFormData.language);
          } else {
            this.router.navigate([`/administration/users/${updatedUser.userUid}`]);
          }
        });
    }
  }
}
