<div class="card-image has-text-centered is-horizontal-center">
  <marketplace-installs-indicator *ngIf="showInstallsIndicator"></marketplace-installs-indicator>
  <marketplace-updates-indicator *ngIf="showUpdatesIndicator"></marketplace-updates-indicator>

  <figure class="container logo-container" [ngClass]="'is-' + size">
    <canvas *ngIf="!isPublic" #logo class="image app-logo has-border-white is-vcentered"></canvas>
    <img *ngIf="isPublic" class="image app-logo is-vcentered" alt="" [src]="publicImageSrc" (error)="handleMissingImage()">
  </figure>

</div>
