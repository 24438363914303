<nav class="container navbar is-rebrand" role="navigation" aria-label="main navigation" #navbar>
  <div class="navbar-brand is-right-marginless-touch">
    <a class="navbar-item" [attr.href]="homeUrl$ | async" tabindex="0">
      <div [ngClass]="logoClass" [attr.aria-label]="brandText + ' Logo'" #whitelabelingLogo></div>
    </a>
    <a
      role="button"
      (click)="toggleMobileMenu()"
      class="navbar-burger has-text-black"
      [ngClass]="{
        'is-active': showMobileMenu,
        'is-hidden': !navbarUserItem?.children?.length || !navbarItems?.length
      }"
      aria-label="menu"
      aria-expanded="false"
      data-target="#navbarMenu"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
    <ng-content select="[mobile-login]"></ng-content>
  </div>
  <div class="navbar-menu" [ngClass]="{ 'is-active': showMobileMenu && isMobile }">
    <div class="navbar-start">
      <ng-container *ngFor="let navbarItem of navbarItems">
        <ng-container *ngIf="!navbarItem.children">
          <div class="navbar-item is-hoverable is-mega" [attr.id]="navbarItem.id" tabindex="0">
            <a
              *ngIf="navbarItem.routerLink"
              (click)="navigate(navbarItem.routerLink)"
              [helpersAttributes]="navbarItem.attributes"
              class="navbar-link is-arrowless"
              >{{ navbarItem.title }}</a
            >
            <a
              *ngIf="navbarItem.href"
              [attr.href]="navbarItem.href"
              [helpersAttributes]="navbarItem.attributes"
              class="navbar-link is-arrowless"
              >{{ navbarItem.title }}</a
            >
          </div>
        </ng-container>
        <ng-container *ngIf="navbarItem.children">
          <div
            class="navbar-item has-dropdown is-mega p-1"
            [ngClass]="{ 'dropdown-hidden': navDropdownClosed, 'dropdown-toggle': !showMobileMenu }"
            [attr.id]="navbarItem.id"
            (keyup.tab)="!isMobile && toggleDropdown($event)"
            (mouseenter)="!isMobile && toggleDropdown($event)"
            (mouseleave)="!isMobile && closeDropdown()"
            tabindex="0"
          >
            <div class="is-hidden-touch navbar-link flex mr-4 is-arrowless ml-4">
              <span>{{ navbarItem.title }}</span>
            </div>
            <div
              class="navbar-dropdown"
              [ngClass]="{ 'p-4': showMobileMenu, 'p-6': !showMobileMenu }"
            >
              <div class="container">
                <div class="columns is-top-paddingless-touch">
                  <div class="mr-5 is-hidden-mobile is-quarter-width">
                    <div class="title is-4 has-text-primary has-min-width-250">
                      {{ navbarItem.attributes.groupTitle }}
                    </div>
                    <div class="is-size-7">
                      {{ navbarItem.attributes.description }}
                    </div>
                  </div>
                  <div
                    class="dropdown-title-mobile is-hidden-desktop is-hidden-tablet flex-row justify-space-between"
                    (click)="toggleDropDownItems(navbarItem)"
                  >
                    <div class="title is-6 has-text-primary">
                      {{ navbarItem.attributes.groupTitle }}
                    </div>
                    <span
                      class="icon title-icon is-hidden-tablet"
                      ngClass="{'is-active': isNavItemActive(navbarItem)}"
                    >
                      <i class="fas fa-chevron-down" aria-hidden="true"></i>
                      <i class="fas fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div
                    class="columns is-subnav justify-space-between is-flex-grow-1 is-hidden-mobile pr-3 pl-3 is-left-paddingless-touch is-right-paddingless-touch"
                    [ngClass]="{ 'is-hidden-mobile': isNavItemActive(navbarItem) }"
                  >
                    <div
                      class="column pt-0 is-left-paddingless-mobile is-right-paddingless-mobile has-margin-top-20-mobile"
                      *ngFor="let childItem of navbarItem.children"
                    >
                      <div
                        class="title is-6 has-border-bottom has-text-weight-normal pb-1 has-text-black"
                      >
                        {{ childItem.title }}
                      </div>
                      <div class="mb-3" *ngFor="let grandChildItem of childItem.children">
                        <a
                          *ngIf="grandChildItem.routerLink"
                          (click)="navigate(grandChildItem.routerLink)"
                          [helpersAttributes]="grandChildItem.attributes"
                          class="has-text-dark has-text-weight-bold"
                          tabindex="0"
                          (keyup.enter)="enterKeyup($event, grandChildItem.routerLink)"
                          >{{ grandChildItem.title }}</a
                        >
                        <a
                          *ngIf="grandChildItem.href"
                          [attr.href]="grandChildItem.href"
                          [helpersAttributes]="grandChildItem.attributes"
                          class="has-text-dark has-text-weight-bold"
                          tabindex="0"
                          >{{ grandChildItem.title }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="navbar-end" *ngIf="!navbarUserItem">
      <ng-content select="[navbar-menu-item-end]"></ng-content>
    </div>
    <div class="navbar-end" *ngIf="navbarUserItem">
      <div
        class="navbar-item has-dropdown has-circle is-hoverable"
        [ngClass]="{
          'dropdown-hidden': navDropdownClosed,
          'dropdown-toggle': !showMobileMenu,
          'is-flex is-justify-content-start is-align-items-start is-flex-direction-column': isMobile
        }"
        (keyup.tab)="!isMobile && toggleDropdown($event)"
        (keyup.enter)="!isMobile && toggleDropdown($event)"
        (mouseenter)="!isMobile && toggleDropdown($event)"
        (mouseleave)="!isMobile && closeDropdown()"
        tabindex="0"
      >
        <a class="navbar-link is-arrowless m-1" [helpersAttributes]="navbarUserItem.attributes">
          <div class="circle">{{ navbarUserItem.title | firstLetters : 2 }}</div>
        </a>
        <div class="navbar-dropdown is-right">
          <ng-container *ngFor="let userItem of navbarUserItem.children; let index = index">
            <a
              class="navbar-item"
              *ngIf="userItem.href"
              [attr.href]="userItem.href"
              [helpersAttributes]="userItem.attributes"
              (click)="handleClick($event, userItem)"
              (keyup.tab)="toggleAccount()"
              (keydown.tab)="handleKeyDown(index)"
              [restrict-permission]="userItem.restrictPermissions || ['read', 'self-read']"
              tabindex="0"
            >
              {{ userItem.title }}
            </a>
            <a
              *ngIf="userItem.routerLink"
              href="javascript:void(0)"
              [helpersAttributes]="userItem.attributes"
              (click)="navigate(userItem.routerLink)"
              class="navbar-item"
              [restrict-permission]="userItem.restrictPermissions || ['read', 'self-read']"
              tabindex="0"
            >
              {{ userItem.title }}
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</nav>
