import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { SearchSelector } from '@portal/entity-services/users/src/lib/enums/search-selector.enum';
import { UserStatuses } from '@portal/entity-services/users/src/lib/services/user-statuses.list';
import { FilterBase } from '../filter-base';
import { FILTER_CONTENT_ITEM } from '../content/token';
import { ISelectInput } from '../box/interfaces/select-input.interface';
import { SwitchToggleComponent } from '../switch-toggle/switch-toggle.component';
import { UserStatus } from '@portal/entity-services/interfaces/src';
import { SearchOperator } from '../search/enums/search-operator.enum';
import { IUserStatus } from './interfaces/user-status-filter.interface';
import { ISearchCriteria } from '../search/interfaces/search-criteria.interface';
import { cloneDeep } from 'lodash';

declare const $localize;

@Component({
  selector: 'portal-filter-user-status',
  templateUrl: './user-status.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: FilterUserStatusComponent,
    },
  ],
})
export class FilterUserStatusComponent extends FilterBase implements OnInit, OnChanges {
  @ViewChild('switchToggle') switchToggle: SwitchToggleComponent;
  @Input() enableDeletedStatus = false;
  @Input() selectedEntities: Map<string, ISearchCriteria>;
  statuses: IUserStatus[];
  switchTextStatuses = $localize`Select all Statuses`;
  userStatusList: ISelectInput[] = this.userStatuses.list;
  filterStatus = $localize`Status`;
  values: string[];

  constructor(private userStatuses: UserStatuses) {
    super(SearchSelector.Status, userStatuses.list);
    this.statuses = this.userStatuses.list as IUserStatus[];
  }

  ngOnInit(): void {
    const entities: ISearchCriteria = cloneDeep(this.selectedEntities?.get(this.searchSelector));
    if (entities) {
      this.setSelectedEntities(entities);
      this.updateText();
    }
    if (!this.enableDeletedStatus) {
      this.userStatusList = this.userStatusList.filter(
        (status) => status.id !== UserStatus.Deleted,
      );
    }
    if (!this.enableDeletedStatus) {
      this.statuses = this.statuses.filter((status) => status.id !== UserStatus.Deleted);
    }
    this.updateListEntity(this.userStatusList);
  }

  ngOnChanges(): void {
    this.updateText();
  }

  removeAllEntities(): void {
    if (this.switchToggle) {
      this.switchToggle.switchOn = false;
    }
    this.localPreSelectedEntities.clear();
    this.updateText();
  }

  onSelectAllSwitchToggle(): void {
    if (this.switchToggle.switchOn) {
      Array.from(this.statuses).forEach((val) => {
        this.localPreSelectedEntities.set(val.id, val);
      });
    } else if (this.localPreSelectedEntities.size > 0) {
      this.localPreSelectedEntities.clear();
    }
  }

  onFilterApplied(): void {
    this.showSelectedEntities = new Map(this.localPreSelectedEntities);
    this.values = this.getValues();
    if (!this.enableDeletedStatus) {
      this.values?.filter((status) => status !== UserStatus.Deleted);
    }
    if (this.values?.length) {
      this.filterUpdated.emit({
        key: this.searchSelector,
        operator: SearchOperator.Equal,
        value: [...this.localPreSelectedEntities.values()],
        argument: this.values.join(','),
      });
    } else {
      this.filterUpdated.emit({
        key: this.searchSelector,
        toRemove: true,
      });
    }

    this.updateText();
    this.itemToggled.emit();
    this.isOpen = false;
    this.updateSwitchToggleState();
  }

  clear(): void {
    this.isOpen = false;
  }

  deselectDeletedStatus(): void {
    const selectedEntity = this.localPreSelectedEntities.get(UserStatus.Deleted);
    if (selectedEntity) {
      this.onEntityRemoved(this.userStatusList.find((status) => status.id === UserStatus.Deleted));
      this.onFilterApplied();
      if (this.isOpen) {
        this.itemToggled.emit();
      }
    }
  }

  disableDeletedStatus(disabled: boolean): void {
    this.userStatusList = this.userStatusList.map((status) =>
      status.id !== UserStatus.Deleted ? status : { ...status, disabled: disabled },
    );
    this.updateListEntity(this.userStatusList);
    this.updateSwitchToggleState();
  }

  updateText(): void {
    this.values = this.getValues();
    if (!this.enableDeletedStatus) {
      this.values?.filter((status) => status !== UserStatus.Deleted);
    }
    const text = $localize`Status`;
    if (this.values?.length) {
      this.filterStatus = `${text} (+${this.values.length})`;
    } else {
      this.filterStatus = text;
    }
  }
}
