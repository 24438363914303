/* eslint-disable @typescript-eslint/naming-convention */
import {
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Directive,
  EventEmitter,
} from '@angular/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class ModalBase implements OnInit, OnDestroy {
  closeModal: EventEmitter<void> = new EventEmitter<void>();
  private _element: any;
  private _isActive = false;

  constructor(public el: ElementRef) {
    this._element = this.el.nativeElement;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (this.activeState && event.key === 'Escape') {
      this.close();
    }
  }

  ngOnInit(): void {
    this._element.addEventListener('click', this.onClickOutside.bind(this));
  }

  ngOnDestroy(): void {
    this._element.removeEventListener('click', this.onClickOutside.bind(this));
  }

  onClickOutside(event: any): void {
    if (event.target.className === 'modal-background') {
      this.close();
    }
  }

  get activeState(): boolean {
    return this._isActive;
  }

  set activeState(state: boolean) {
    this._isActive = state;
  }

  open(): void {
    this.activeState = true;
  }

  close(): void {
    this.activeState = false;
    this.closeModal.emit();
  }
}
