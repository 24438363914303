export const DeploymentStatusColorMapping = {
  INSTALL_COMPLETE: { name: 'Installed', color: 'has-background-success' },
  UNINSTALL_COMPLETE: { name: 'Succeeded', color: 'has-background-success' },
  INSTALL_PENDING_APPMARKET: {
    name: 'Installation in Progress',
    color: 'has-background-warning',
  },
  UNINSTALL_PENDING_APPMARKET: {
    name: 'Removal in Progress',
    color: 'has-background-warning',
  },
  UNINSTALL_PENDING_VHQ: { name: 'Removal in Progress', color: 'has-background-warning' },
  PENDING_PHYSICAL_BIND: {
    name: 'Installation in Progress',
    color: 'has-background-warning',
  },
  INSTALL_PENDING_VHQ: { name: 'Installation in Progress', color: 'has-background-warning' },
  INSTALL_FAILED: { name: 'Installation Failed', color: 'has-background-danger' },
  UNINSTALL_FAILED: { name: 'Removal Failed', color: 'has-background-danger' },
  INSTALL_FAILED_DELAYED_EVALUATION: {
    name: 'Installation Failed',
    color: 'has-background-danger',
  },
};

export enum DeploymentStatus {
  INSTALL_PENDING_VHQ = 'INSTALL_PENDING_VHQ',
  INSTALL_PENDING_APPMARKET = 'INSTALL_PENDING_APPMARKET',
  UNINSTALL_COMPLETE = 'UNINSTALL_COMPLETE',
  UNINSTALL_PENDING_APPMARKET = 'UNINSTALL_PENDING_APPMARKET',
  UNINSTALL_PENDING_VHQ = 'UNINSTALL_PENDING_VHQ',
  PENDING_PHYSICAL_BIND = 'PENDING_PHYSICAL_BIND',
  INSTALL_FAILED = 'INSTALL_FAILED',
  UNINSTALL_FAILED = 'UNINSTALL_FAILED',
  INSTALL_COMPLETE = 'INSTALL_COMPLETE',
  INSTALL_FAILED_DELAYED_EVALUATION = 'INSTALL_FAILED_DELAYED_EVALUATION',
}
export enum BundleDeployStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PARTIAL = 'PARTIAL',
  PENDING = 'PENDING',
}
