import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DeviceCardTemplateComponent } from './device-card-template.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [DeviceCardTemplateComponent],
  exports: [DeviceCardTemplateComponent],
})
export class DeviceCardTemplateModule {}
