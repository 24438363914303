import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { vfappmarket, FileUploadAcceptType } from '@portal/marketplace/api-interfaces';
import { NotifierService } from '@portal/shared/ui/notifier';
import { DragDropDirective } from './directives/drag-drop.directive';

@Component({
  selector: 'marketplace-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  viewProviders: [DragDropDirective],
})
export class FileUploadComponent implements AfterViewInit, OnInit {
  uploadImage = './assets/images/file-upload/upload.svg';
  files: File[] = [];
  @Input() multiple: Boolean = false;
  @Input() aspect: string; // example '4/3' | '1/1' | '16/9'
  @Input() supportedFileTypes: FileUploadAcceptType[] = [];
  @Input() image: any = null;
  @Input() accept;
  @Input() supportedFilesText: string;
  @Input() sizeMaxLimit: number; // size in kbytes
  // @Input() sizeMinLimit: number; // size in kbytes
  // @Input() dimensionMaxLimit: string; example '512x515'
  // @Input() dimensionMinLimit: string; example '512x515'
  @Output() filesChanged = new EventEmitter<File[]>();
  previewUrl: any = null;
  hasPreviewUrl = false;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('previewContainer') previewContainer: ElementRef;

  constructor(private notifierService: NotifierService) {}

  ngOnInit(): void {
    if (this.image !== undefined && !this.multiple) {
      this.previewUrl = this.image && this.image[0] === '/' ? vfappmarket + this.image : this.image;
    }
  }

  fileProgress(files: File[]): void {
    this.hasPreviewUrl = false;

    if (!this.multiple) {
      this.files = [];
      files = [files[0]];
    }

    files.forEach((file) => {
      if (this.fileIsValid(file)) {
        this.files.push(file);
      }
    });

    if (this.aspect && this.files[0]) {
      this.resizeImage(this.files[0]);
    } else if (this.files[0]) {
      this.preview(this.files[0]);
      this.filesChanged.emit(files);
    }

    this.fileInput.nativeElement.value = null; // allow to reselect same file
  }
  get selectedFiles(): string[] {
    return this.files.map((file) => file.name);
  }
  fileIsValid(file: File): boolean {
    let valid = true;

    if (valid && !this.fileTypeIsValid(file.name)) {
      this.notifierService.error('Invalid file type');
      valid = false;
    }
    if (valid && !this.fileSizeIsValid(file.size)) {
      this.notifierService.error('Invalid file size');
      valid = false;
    }

    return valid;
  }

  fileTypeIsValid(name: string): boolean {
    const type = name.substring(name.lastIndexOf('.')).toLowerCase();
    const isValid =
      !this.supportedFileTypes.length ||
      this.supportedFileTypes.indexOf(<FileUploadAcceptType>type) > -1;
    return isValid;
  }

  fileSizeIsValid(size: number): boolean {
    let isValid = true;
    if (this.sizeMaxLimit) {
      // size comes in Bytes but we expect in kBytes
      isValid = Math.ceil(size / 1024) <= this.sizeMaxLimit;
    }
    return isValid;
  }

  preview(file: File): void {
    if (this.multiple) return;

    const mimeType = file.type;
    if (mimeType.match(/image\/*/) === null) {
      return;
    }
    this.hasPreviewUrl = true;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  ngAfterViewInit(): void {
    if (this.supportedFileTypes.length) {
      const accept = this.supportedFileTypes.join(',');
      this.fileInput.nativeElement.setAttribute('accept', accept);
    }

    if (this.multiple) this.fileInput.nativeElement.setAttribute('multiple', 'multiple');

    if (this.aspect && this.previewContainer) {
      this.previewContainer.nativeElement.style.setProperty('--aspect-ratio', this.aspect);
    }
  }

  resizeImage(file: File): void {
    const mimeType = file.type;
    if (mimeType.match(/image\/*/) === null) {
      return;
    }

    this.hasPreviewUrl = true;

    const tempImage = new Image();
    const reader = new FileReader();

    tempImage.addEventListener('load', () => {
      const a = tempImage.width / tempImage.height;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = Number(this.aspect.split('/')[0]) * 100 * 2;
      canvas.height = Number(this.aspect.split('/')[1]) * 100 * 2;
      const nh = canvas.width / a;
      const dx = 0;
      const dy = canvas.height / 2 - nh / 2;

      ctx.drawImage(tempImage, 0, 0, tempImage.width, tempImage.height, dx, dy, canvas.width, nh);

      this.previewUrl = canvas.toDataURL(mimeType, 1);

      canvas.toBlob(
        (blob: Blob) => {
          const f = new File([blob], file.name, { type: mimeType });
          this.filesChanged.emit([f]);
        },
        'image/png',
        1,
      );
    });

    reader.addEventListener(
      'load',
      () => {
        tempImage.src = reader.result as string;
      },
      false,
    );

    reader.readAsDataURL(file);
  }

  browse(): void {
    this.fileInput.nativeElement.click();
  }

  removeFile(index?: number): void {
    if (index !== undefined) this.files.splice(index, 1);
    else this.files = [];
    this.previewUrl = null;
    this.filesChanged.emit(this.files);
  }
}
