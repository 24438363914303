export enum FeaturesEnum {
  DEVICE_DETAILS = 'Device Details',
  SHIPMENT_RECEIPT = 'Shipment Receipt',
  CHAIN_OF_CUSTODY = 'Chain Of Custody',
  INSPECTION = 'Inspection',
  LOCATIONS = 'Locations',
  TENANTS = 'Tenants',
  DEVICES = 'Devices',
  ACTIVITY_CYCLE = 'Activity Cycle',
  TRACKING_REPORT = 'Tracking Report',
  ROLLOUT = 'Rollouts',
  HIERARCHIES = 'Hierarchies',
  GROUPS = 'Groups',
  ALERTS = 'Alerts',
  SOFTWARE_ASSIGNMENT = 'Software Assignment',
  REFERENCE_SETS = 'Reference Sets',
  SOFTWARE_LIBRARY = 'Software Library',
  DELETED_DEVICES = 'Deleted Devices',
  EXPORT = 'Export',
  IMPORT = 'Import',
  CONTENT = 'Content',
  DIAGNOSTIC_ACTIONS = 'Diagnostic Actions',
  DEVICE_STATUS = 'Device Status',
  AUDIT_LOG = 'Audit Log',
  SWAP = 'Swap',
  MOVE = 'Move',
  DOWNLOAD_SCHEDULE = 'Download Schedule',
  DELETE = 'Delete',
  ADD = 'add',
  ALLOWED = 'Allowed',
  NOT_ALLOWED = 'Not Allowed',
  PACKAGE = 'Package',
  ADMIN_CODE = 'AdminPassCode',
}
