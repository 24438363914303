import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
} from '@angular/core';
import { ModalBase } from '../modal-base';

@Component({
  selector: 'portal-base-modal',
  templateUrl: './base-modal.component.html',
})
export class BaseModalComponent extends ModalBase implements OnInit {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() zIndex: number;
  @Input() width = '';
  @Input() isManualFocusAdded? = false;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  constructor(public el: ElementRef) {
    super(el);
  }
  setFocusOnCloseButton(): void {
    if (this.closeButton && this.closeButton.nativeElement && this.isManualFocusAdded) {
      this.closeButton.nativeElement.focus();
    }
  }
}
