import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAppFile } from '@portal/marketplace/api-interfaces';

import { SwiperOptions } from 'swiper';

@Component({
  selector: 'marketplace-app-screen-carousel',
  templateUrl: './app-screen-carousel.component.html',
  styleUrls: ['./app-screen-carousel.component.scss'],
})
export class AppScreenCarouselComponent implements OnInit {
  @Input() images: IAppFile[];
  @Input() isDeleteAbility = true;
  @Output() deleteImageEvt: EventEmitter<IAppFile> = new EventEmitter();
  @Input() isPublic = false;
  config: SwiperOptions = {
    centerInsufficientSlides: true,
    watchOverflow: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 40,
      },
    },
  };

  constructor() {}

  deleteImage(img: IAppFile): void {
    this.deleteImageEvt.emit(img);
  }

  ngOnInit(): void {}
}
