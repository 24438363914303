export enum ReceiptSalesChannel {
  Ecommerce = 'ECOMMERCE',
  Mail = 'MAIL',
  Moto = 'MOTO',
  NonMoto = 'NON_MOTO',
  Other = 'OTHER',
  Phone = 'PHONE',
  Pos = 'POS',
  Recurring = 'RECURRING',
  Stored = 'STORED',
  Virtual = 'VIRTUAL',
}
