import { Injectable } from '@angular/core';
import { TokenRequestorIdType } from '@portal/entity-services/interfaces/src/lib/organisations/enums/token-requestor-id-type.enum';
import { SelectInputsList } from '@portal/shared/helpers';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class TokenRequestorIdTypeList extends SelectInputsList {
  keyValue = {
    [TokenRequestorIdType.Verifone]: $localize`:token requestor id type|:Verifone`,
    [TokenRequestorIdType.Merchant]: $localize`:token requestor id type|:Merchant`,
  };

  list: ISelectInput[] = this.getSelectInputs();
}
