import { Component, Input, OnInit } from '@angular/core';
import {
  AppStatus,
  StatusColorMapping,
} from '@portal/marketplace/api-interfaces/src/lib/apps/enums/app-status.enum';

@Component({
  selector: 'marketplace-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
})
export class StatusIndicatorComponent implements OnInit {
  @Input() status: AppStatus;
  statusColorMapping = StatusColorMapping;

  constructor() {}

  ngOnInit(): void {}
}
