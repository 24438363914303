import { Injectable } from '@angular/core';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({
  providedIn: 'root',
})
export class FifthPartOfSubdivisionsList {
  list: ISelectInput[];
  keyValue = {
    ['BL-BL']: $localize`St. Barthélemy`,
    ['SX-SX']: $localize`St. Maarten`,
    ['MF-MF']: $localize`St. Martin`,
    ['PM-PM']: $localize`St. Pierre & Miquelon`,
    ['SD-DC']: $localize`Central Darfur`,
    ['SD-DE']: $localize`East Darfur`,
    ['SD-DN']: $localize`North Darfur`,
    ['SD-DS']: $localize`Janūb Dārfūr`,
    ['SD-DW']: $localize`Gharb Dārfūr`,
    ['SD-GD']: $localize`Al Qaḑārif`,
    ['SD-GZ']: $localize`Al Jazīrah`,
    ['SD-KA']: $localize`Kassala`,
    ['SD-KH']: $localize`Al Kharţūm`,
    ['SD-KN']: $localize`North Kordofan`,
    ['SD-KS']: $localize`Janūb Kurdufān`,
    ['SD-NB']: $localize`An Nīl al Azraq`,
    ['SD-NO']: $localize`Ash Shamālīyah`,
    ['SD-NR']: $localize`An Nīl`,
    ['SD-NW']: $localize`An Nīl al Abyaḑ`,
    ['SD-RS']: $localize`Al Baḩr al Aḩmar`,
    ['SD-SI']: $localize`Sennar`,
    ['SR-BR']: $localize`Brokopondo`,
    ['SR-CM']: $localize`Commewijne`,
    ['SR-CR']: $localize`Coronie`,
    ['SR-MA']: $localize`Marowijne`,
    ['SR-NI']: $localize`Nickerie`,
    ['SR-PM']: $localize`Paramaribo`,
    ['SR-PR']: $localize`Para`,
    ['SR-SA']: $localize`Saramacca`,
    ['SR-SI']: $localize`Sipaliwini`,
    ['SR-WA']: $localize`Wanica`,
    ['SJ-SJ']: $localize`Svalbard & Jan Mayen Islands`,
    ['SZ-HH']: $localize`Hhohho`,
    ['SZ-LU']: $localize`Lubombo`,
    ['SZ-MA']: $localize`Manzini`,
    ['SZ-SH']: $localize`Shiselweni`,
    ['SE-AB']: $localize`Stockholms län`,
    ['SE-AC']: $localize`Västerbottens län`,
    ['SE-BD']: $localize`Norrbottens län`,
    ['SE-C']: $localize`Uppsala län`,
    ['SE-D']: $localize`Södermanlands län`,
    ['SE-E']: $localize`Östergötlands län`,
    ['SE-F']: $localize`Jönköpings län`,
    ['SE-G']: $localize`Kronobergs län`,
    ['SE-H']: $localize`Kalmar län`,
    ['SE-I']: $localize`Gotlands län`,
    ['SE-K']: $localize`Blekinge län`,
    ['SE-M']: $localize`Skåne län`,
    ['SE-N']: $localize`Hallands län`,
    ['SE-O']: $localize`Västra Götalands län`,
    ['SE-S']: $localize`Värmlands län`,
    ['SE-T']: $localize`Örebro län`,
    ['SE-U']: $localize`Västmanlands län`,
    ['SE-W']: $localize`Dalarnas län`,
    ['SE-X']: $localize`Gävleborgs län`,
    ['SE-Y']: $localize`Västernorrlands län`,
    ['SE-Z']: $localize`Jämtlands län`,
    ['CH-AI']: $localize`Appenzell Innerrhoden`,
    ['CH-AR']: $localize`Appenzell Ausserrhoden`,
    ['CH-BE']: $localize`Bern`,
    ['CH-BL']: $localize`Basel-Landschaft`,
    ['CH-BS']: $localize`Basel-Stadt`,
    ['CH-FR']: $localize`Freiburg`,
    ['CH-GE']: $localize`Genève`,
    ['CH-GL']: $localize`Glarus`,
    ['CH-GR']: $localize`Graubünden`,
    ['CH-JU']: $localize`Jura`,
    ['CH-LU']: $localize`Luzern`,
    ['CH-NE']: $localize`Neuchâtel`,
    ['CH-NW']: $localize`Nidwalden`,
    ['CH-OW']: $localize`Obwalden`,
    ['CH-SG']: $localize`Sankt Gallen`,
    ['CH-SH']: $localize`Schaffhausen`,
    ['CH-SO']: $localize`Solothurn`,
    ['CH-SZ']: $localize`Schwyz`,
    ['CH-TG']: $localize`Thurgau`,
    ['CH-TI']: $localize`Ticino`,
    ['CH-UR']: $localize`Uri`,
    ['CH-VD']: $localize`Vaud`,
    ['CH-VS']: $localize`Valais`,
    ['CH-ZG']: $localize`Zug`,
    ['CH-ZH']: $localize`Zürich`,
    ['SY-DI']: $localize`Dimashq`,
    ['SY-DR']: $localize`Dar'ā`,
    ['SY-DY']: $localize`Dayr az Zawr`,
    ['SY-HA']: $localize`Al Ḩasakah`,
    ['SY-HI']: $localize`Ḩimş`,
    ['SY-HL']: $localize`Ḩalab`,
    ['SY-HM']: $localize`Ḩamāh`,
    ['SY-ID']: $localize`Idlib`,
    ['SY-LA']: $localize`Al Lādhiqīyah`,
    ['SY-QU']: $localize`Al Qunayţirah`,
    ['SY-RA']: $localize`Ar Raqqah`,
    ['SY-RD']: $localize`Rīf Dimashq`,
    ['SY-SU']: $localize`As Suwaydā'`,
    ['SY-TA']: $localize`Ţarţūs`,
    ['TW-CHA']: $localize`Changhua`,
    ['TW-CYI']: $localize`Chiayi`,
    ['TW-CYQ']: $localize`Chiayi`,
    ['TW-HSQ']: $localize`Hsinchu`,
    ['TW-HSZ']: $localize`Hsinchu`,
    ['TW-HUA']: $localize`Hualien`,
    ['TW-ILA']: $localize`Ilan`,
    ['TW-KEE']: $localize`Keelung`,
    ['TW-KHH']: $localize`Kaohsiung`,
    ['TW-KHQ']: $localize`Kaohsiung`,
    ['TW-MIA']: $localize`Miaoli`,
    ['TW-NAN']: $localize`Nantou`,
    ['TW-PEN']: $localize`Penghu`,
    ['TW-PIF']: $localize`Pingtung`,
    ['TW-TAO']: $localize`Taoyuan`,
    ['TW-TNN']: $localize`Tainan`,
    ['TW-TNQ']: $localize`Tainan`,
    ['TW-TPE']: $localize`Taipei`,
    ['TW-TPQ']: $localize`Taipei`,
    ['TW-TTT']: $localize`Taitung`,
    ['TW-TXG']: $localize`Taichung`,
    ['TW-TXQ']: $localize`Taichung`,
    ['TW-YUN']: $localize`Yunlin`,
    ['TJ-DU']: $localize`Dushanbe`,
    ['TJ-GB']: $localize`Kŭhistoni Badakhshon`,
    ['TJ-KT']: $localize`Khatlon`,
    ['TJ-SU']: $localize`Sughd`,
    ['TZ-01']: $localize`Arusha`,
    ['TZ-02']: $localize`Dar es Salaam`,
    ['TZ-03']: $localize`Dodoma`,
    ['TZ-04']: $localize`Iringa`,
    ['TZ-05']: $localize`Kagera`,
    ['TZ-06']: $localize`Kaskazini Pemba`,
    ['TZ-07']: $localize`Kaskazini Unguja`,
    ['TZ-08']: $localize`Kigoma`,
    ['TZ-09']: $localize`Kilimanjaro`,
    ['TZ-10']: $localize`Kusini Pemba`,
    ['TZ-11']: $localize`Kusini Unguja`,
    ['TZ-12']: $localize`Lindi`,
    ['TZ-13']: $localize`Mara`,
    ['TZ-14']: $localize`Mbeya`,
    ['TZ-15']: $localize`Mjini Magharibi`,
    ['TZ-16']: $localize`Morogoro`,
    ['TZ-17']: $localize`Mtwara`,
    ['TZ-18']: $localize`Mwanza`,
    ['TZ-19']: $localize`Coast`,
    ['TZ-20']: $localize`Rukwa`,
    ['TZ-21']: $localize`Ruvuma`,
    ['TZ-22']: $localize`Shinyanga`,
    ['TZ-23']: $localize`Singida`,
    ['TZ-24']: $localize`Tabora`,
    ['TZ-25']: $localize`Tanga`,
    ['TZ-26']: $localize`Manyara`,
    ['TZ-27']: $localize`Geita`,
    ['TZ-28']: $localize`Katavi`,
    ['TZ-29']: $localize`Njombe`,
    ['TZ-30']: $localize`Simiyu`,
    ['TH-10']: $localize`Krung Thep Maha Nakhon`,
    ['TH-11']: $localize`Samut Prakan`,
    ['TH-12']: $localize`Nonthaburi`,
    ['TH-13']: $localize`Pathum Thani`,
    ['TH-14']: $localize`Phra Nakhon Si Ayutthaya`,
    ['TH-15']: $localize`Ang Thong`,
    ['TH-16']: $localize`Lop Buri`,
    ['TH-17']: $localize`Sing Buri`,
    ['TH-18']: $localize`Chai Nat`,
    ['TH-19']: $localize`Saraburi`,
    ['TH-20']: $localize`Chon Buri`,
    ['TH-21']: $localize`Rayong`,
    ['TH-22']: $localize`Chanthaburi`,
    ['TH-23']: $localize`Trat`,
    ['TH-24']: $localize`Chachoengsao`,
    ['TH-25']: $localize`Prachin Buri`,
    ['TH-26']: $localize`Nakhon Nayok`,
    ['TH-27']: $localize`Sa Kaeo`,
    ['TH-30']: $localize`Nakhon Ratchasima`,
    ['TH-31']: $localize`Buri Ram`,
    ['TH-32']: $localize`Surin`,
    ['TH-33']: $localize`Si Sa Ket`,
    ['TH-34']: $localize`Ubon Ratchathani`,
    ['TH-35']: $localize`Yasothon`,
    ['TH-36']: $localize`Chaiyaphum`,
    ['TH-37']: $localize`Amnat Charoen`,
    ['TH-38']: $localize`Bueng Kan`,
    ['TH-39']: $localize`Nong Bua Lam Phu`,
    ['TH-40']: $localize`Khon Kaen`,
    ['TH-41']: $localize`Udon Thani`,
    ['TH-42']: $localize`Loei`,
    ['TH-43']: $localize`Nong Khai`,
    ['TH-44']: $localize`Maha Sarakham`,
    ['TH-45']: $localize`Roi Et`,
    ['TH-46']: $localize`Kalasin`,
    ['TH-47']: $localize`Sakon Nakhon`,
    ['TH-48']: $localize`Nakhon Phanom`,
    ['TH-49']: $localize`Mukdahan`,
    ['TH-50']: $localize`Chiang Mai`,
    ['TH-51']: $localize`Lamphun`,
    ['TH-52']: $localize`Lampang`,
    ['TH-53']: $localize`Uttaradit`,
    ['TH-54']: $localize`Phrae`,
    ['TH-55']: $localize`Nan`,
    ['TH-56']: $localize`Phayao`,
    ['TH-57']: $localize`Chiang Rai`,
    ['TH-58']: $localize`Mae Hong Son`,
    ['TH-60']: $localize`Nakhon Sawan`,
    ['TH-61']: $localize`Uthai Thani`,
    ['TH-62']: $localize`Kamphaeng Phet`,
    ['TH-63']: $localize`Tak`,
    ['TH-64']: $localize`Sukhothai`,
    ['TH-65']: $localize`Phitsanulok`,
    ['TH-66']: $localize`Phichit`,
    ['TH-67']: $localize`Phetchabun`,
    ['TH-70']: $localize`Ratchaburi`,
    ['TH-71']: $localize`Kanchanaburi`,
    ['TH-72']: $localize`Suphan Buri`,
    ['TH-73']: $localize`Nakhon Pathom`,
    ['TH-74']: $localize`Samut Sakhon`,
    ['TH-75']: $localize`Samut Songkhram`,
    ['TH-76']: $localize`Phetchaburi`,
    ['TH-77']: $localize`Prachuap Khiri Khan`,
    ['TH-80']: $localize`Nakhon Si Thammarat`,
    ['TH-81']: $localize`Krabi`,
    ['TH-82']: $localize`Phangnga`,
    ['TH-83']: $localize`Phuket`,
    ['TH-84']: $localize`Surat Thani`,
    ['TH-85']: $localize`Ranong`,
    ['TH-86']: $localize`Chumphon`,
    ['TH-90']: $localize`Songkhla`,
    ['TH-91']: $localize`Satun`,
    ['TH-92']: $localize`Trang`,
    ['TH-93']: $localize`Phatthalung`,
    ['TH-94']: $localize`Pattani`,
    ['TH-95']: $localize`Yala`,
    ['TH-96']: $localize`Narathiwat`,
    ['TH-S']: $localize`Phatthaya`,
    ['TL-AL']: $localize`Aileu`,
    ['TL-AN']: $localize`Ainaro`,
    ['TL-BA']: $localize`Baucau`,
    ['TL-BO']: $localize`Bobonaro`,
    ['TL-CO']: $localize`Cova Lima`,
    ['TL-DI']: $localize`Díli`,
    ['TL-ER']: $localize`Ermera`,
    ['TL-LA']: $localize`Lautém`,
    ['TL-LI']: $localize`Likisá`,
    ['TL-MF']: $localize`Manufahi`,
    ['TL-MT']: $localize`Manatuto`,
    ['TL-OE']: $localize`Oecussi`,
    ['TL-VI']: $localize`Vikeke`,
    ['TG-C']: $localize`Centre`,
    ['TG-K']: $localize`Kara`,
    ['TG-M']: $localize`Maritime`,
    ['TG-P']: $localize`Plateaux`,
    ['TG-S']: $localize`Savannes`,
    ['TK-TK']: $localize`Tokelau`,
    ['TO-01']: $localize`'Eua`,
    ['TO-02']: $localize`Ha'apai`,
    ['TO-03']: $localize`Niuas`,
    ['TO-04']: $localize`Tongatapu`,
    ['TO-05']: $localize`Vava'u`,
    ['TT-ARI']: $localize`Arima`,
    ['TT-CHA']: $localize`Chaguanas`,
    ['TT-CTT']: $localize`Couva-Tabaquite-Talparo`,
    ['TT-DMN']: $localize`Diego Martin`,
    ['TT-ETO']: $localize`Eastern Tobago`,
    ['TT-PED']: $localize`Penal-Debe`,
    ['TT-POS']: $localize`Port of Spain`,
    ['TT-PRT']: $localize`Princes Town`,
    ['TT-PTF']: $localize`Point Fortin`,
    ['TT-RCM']: $localize`Rio Claro-Mayaro`,
    ['TT-SFO']: $localize`San Fernando`,
    ['TT-SGE']: $localize`Sangre Grande`,
    ['TT-SIP']: $localize`Siparia`,
    ['TT-SJL']: $localize`San Juan-Laventille`,
    ['TT-TUP']: $localize`Tunapuna-Piarco`,
    ['TT-WTO']: $localize`Western Tobago`,
    ['TN-11']: $localize`Tunis`,
    ['TN-12']: $localize`Ariana`,
    ['TN-13']: $localize`Ben Arous`,
    ['TN-14']: $localize`La Manouba`,
    ['TN-21']: $localize`Nabeul`,
    ['TN-22']: $localize`Zaghouan`,
    ['TN-23']: $localize`Bizerte`,
    ['TN-31']: $localize`Béja`,
    ['TN-32']: $localize`Jendouba`,
    ['TN-33']: $localize`Le Kef`,
    ['TN-34']: $localize`Siliana`,
    ['TN-41']: $localize`Kairouan`,
    ['TN-42']: $localize`Kasserine`,
    ['TN-43']: $localize`Sidi Bouzid`,
    ['TN-51']: $localize`Sousse`,
    ['TN-52']: $localize`Monastir`,
    ['TN-53']: $localize`Mahdia`,
    ['TN-61']: $localize`Sfax`,
    ['TN-71']: $localize`Gafsa`,
    ['TN-72']: $localize`Tozeur`,
    ['TN-73']: $localize`Kebili`,
    ['TN-81']: $localize`Gabès`,
    ['TN-82']: $localize`Medenine`,
    ['TN-83']: $localize`Tataouine`,
    ['TR-01']: $localize`Adana`,
    ['TR-02']: $localize`Adıyaman`,
    ['TR-03']: $localize`Afyonkarahisar`,
    ['TR-04']: $localize`Ağrı`,
    ['TR-05']: $localize`Amasya`,
    ['TR-06']: $localize`Ankara`,
    ['TR-07']: $localize`Antalya`,
    ['TR-08']: $localize`Artvin`,
    ['TR-09']: $localize`Aydın`,
    ['TR-10']: $localize`Balıkesir`,
    ['TR-11']: $localize`Bilecik`,
    ['TR-12']: $localize`Bingöl`,
    ['TR-13']: $localize`Bitlis`,
    ['TR-14']: $localize`Bolu`,
    ['TR-15']: $localize`Burdur`,
    ['TR-16']: $localize`Bursa`,
    ['TR-17']: $localize`Çanakkale`,
    ['TR-18']: $localize`Çankırı`,
    ['TR-19']: $localize`Çorum`,
    ['TR-20']: $localize`Denizli`,
    ['TR-21']: $localize`Diyarbakır`,
    ['TR-22']: $localize`Edirne`,
    ['TR-23']: $localize`Elazığ`,
    ['TR-24']: $localize`Erzincan`,
    ['TR-25']: $localize`Erzurum`,
    ['TR-26']: $localize`Eskişehir`,
    ['TR-27']: $localize`Gaziantep`,
    ['TR-28']: $localize`Giresun`,
    ['TR-29']: $localize`Gümüşhane`,
    ['TR-30']: $localize`Hakkâri`,
    ['TR-31']: $localize`Hatay`,
    ['TR-32']: $localize`Isparta`,
    ['TR-33']: $localize`Mersin`,
    ['TR-34']: $localize`İstanbul`,
    ['TR-35']: $localize`İzmir`,
    ['TR-36']: $localize`Kars`,
    ['TR-37']: $localize`Kastamonu`,
    ['TR-38']: $localize`Kayseri`,
    ['TR-39']: $localize`Kırklareli`,
    ['TR-40']: $localize`Kırşehir`,
    ['TR-41']: $localize`Kocaeli`,
    ['TR-42']: $localize`Konya`,
    ['TR-43']: $localize`Kütahya`,
    ['TR-44']: $localize`Malatya`,
    ['TR-45']: $localize`Manisa`,
    ['TR-46']: $localize`Kahramanmaraş`,
    ['TR-47']: $localize`Mardin`,
    ['TR-48']: $localize`Muğla`,
    ['TR-49']: $localize`Muş`,
    ['TR-50']: $localize`Nevşehir`,
    ['TR-51']: $localize`Niğde`,
    ['TR-52']: $localize`Ordu`,
    ['TR-53']: $localize`Rize`,
    ['TR-54']: $localize`Sakarya`,
    ['TR-55']: $localize`Samsun`,
    ['TR-56']: $localize`Siirt`,
    ['TR-57']: $localize`Sinop`,
    ['TR-58']: $localize`Sivas`,
    ['TR-59']: $localize`Tekirdağ`,
    ['TR-60']: $localize`Tokat`,
    ['TR-61']: $localize`Trabzon`,
    ['TR-62']: $localize`Tunceli`,
    ['TR-63']: $localize`Şanlıurfa`,
    ['TR-64']: $localize`Uşak`,
    ['TR-65']: $localize`Van`,
    ['TR-66']: $localize`Yozgat`,
    ['TR-67']: $localize`Zonguldak`,
    ['TR-68']: $localize`Aksaray`,
    ['TR-69']: $localize`Bayburt`,
    ['TR-70']: $localize`Karaman`,
    ['TR-71']: $localize`Kırıkkale`,
    ['TR-72']: $localize`Batman`,
    ['TR-73']: $localize`Şırnak`,
    ['TR-74']: $localize`Bartın`,
    ['TR-75']: $localize`Ardahan`,
    ['TR-76']: $localize`Iğdır`,
    ['TR-77']: $localize`Yalova`,
    ['TR-78']: $localize`Karabük`,
    ['TR-79']: $localize`Kilis`,
    ['TR-80']: $localize`Osmaniye`,
    ['TR-81']: $localize`Düzce`,
    ['TM-A']: $localize`Ahal`,
    ['TM-B']: $localize`Balkan`,
    ['TM-D']: $localize`Daşoguz`,
    ['TM-L']: $localize`Lebap`,
    ['TM-M']: $localize`Mary`,
    ['TM-S']: $localize`Aşgabat`,
    ['TC-TC']: $localize`Turks & Caicos Islands`,
    ['TV-FUN']: $localize`Funafuti`,
    ['TV-NIT']: $localize`Niutao`,
    ['TV-NKF']: $localize`Nukufetau`,
    ['TV-NKL']: $localize`Nukulaelae`,
    ['TV-NMA']: $localize`Nanumea`,
    ['TV-NMG']: $localize`Nanumanga`,
    ['TV-NUI']: $localize`Nui`,
    ['TV-VAI']: $localize`Vaitupu`,
    ['UG-101']: $localize`Kalangala`,
    ['UG-102']: $localize`Kampala`,
    ['UG-103']: $localize`Kiboga`,
    ['UG-104']: $localize`Luwero`,
    ['UG-105']: $localize`Masaka`,
    ['UG-106']: $localize`Mpigi`,
    ['UG-107']: $localize`Mubende`,
    ['UG-108']: $localize`Mukono`,
    ['UG-109']: $localize`Nakasongola`,
    ['UG-110']: $localize`Rakai`,
    ['UG-111']: $localize`Sembabule`,
    ['UG-112']: $localize`Kayunga`,
    ['UG-113']: $localize`Wakiso`,
    ['UG-114']: $localize`Mityana`,
    ['UG-115']: $localize`Nakaseke`,
    ['UG-116']: $localize`Lyantonde`,
    ['UG-117']: $localize`Buikwe`,
    ['UG-118']: $localize`Bukomansibi`,
    ['UG-119']: $localize`Butambala`,
    ['UG-120']: $localize`Buvuma`,
    ['UG-121']: $localize`Gomba`,
    ['UG-122']: $localize`Kalungu`,
    ['UG-123']: $localize`Kyankwanzi`,
    ['UG-124']: $localize`Lwengo`,
    ['UG-201']: $localize`Bugiri`,
    ['UG-202']: $localize`Busia`,
    ['UG-203']: $localize`Iganga`,
    ['UG-204']: $localize`Jinja`,
    ['UG-205']: $localize`Kamuli`,
    ['UG-206']: $localize`Kapchorwa`,
    ['UG-207']: $localize`Katakwi`,
    ['UG-208']: $localize`Kumi`,
    ['UG-209']: $localize`Mbale`,
    ['UG-210']: $localize`Pallisa`,
    ['UG-211']: $localize`Soroti`,
    ['UG-212']: $localize`Tororo`,
    ['UG-213']: $localize`Kaberamaido`,
    ['UG-214']: $localize`Mayuge`,
    ['UG-215']: $localize`Sironko`,
    ['UG-216']: $localize`Amuria`,
    ['UG-217']: $localize`Budaka`,
    ['UG-218']: $localize`Bukwa`,
    ['UG-219']: $localize`Butaleja`,
    ['UG-220']: $localize`Kaliro`,
    ['UG-221']: $localize`Manafwa`,
    ['UG-222']: $localize`Namutumba`,
    ['UG-223']: $localize`Bududa`,
    ['UG-224']: $localize`Bukedea`,
    ['UG-225']: $localize`Bulambuli`,
    ['UG-226']: $localize`Buyende`,
    ['UG-227']: $localize`Kibuku`,
    ['UG-228']: $localize`Kween`,
    ['UG-229']: $localize`Luuka`,
    ['UG-230']: $localize`Namayingo`,
    ['UG-231']: $localize`Ngora`,
    ['UG-232']: $localize`Serere`,
    ['UG-301']: $localize`Adjumani`,
    ['UG-302']: $localize`Apac`,
    ['UG-303']: $localize`Arua`,
    ['UG-304']: $localize`Gulu`,
    ['UG-305']: $localize`Kitgum`,
    ['UG-306']: $localize`Kotido`,
    ['UG-307']: $localize`Lira`,
    ['UG-308']: $localize`Moroto`,
    ['UG-309']: $localize`Moyo`,
    ['UG-310']: $localize`Nebbi`,
    ['UG-311']: $localize`Nakapiripirit`,
    ['UG-312']: $localize`Pader`,
    ['UG-313']: $localize`Yumbe`,
    ['UG-314']: $localize`Amolatar`,
    ['UG-315']: $localize`Kaabong`,
    ['UG-316']: $localize`Koboko`,
    ['UG-317']: $localize`Abim`,
    ['UG-318']: $localize`Dokolo`,
    ['UG-319']: $localize`Amuru`,
    ['UG-320']: $localize`Maracha`,
    ['UG-321']: $localize`Oyam`,
    ['UG-322']: $localize`Agago`,
    ['UG-323']: $localize`Alebtong`,
    ['UG-324']: $localize`Amudat`,
    ['UG-325']: $localize`Buhweju`,
    ['UG-326']: $localize`Kole`,
    ['UG-327']: $localize`Lamwo`,
    ['UG-328']: $localize`Napak`,
    ['UG-329']: $localize`Nwoya`,
    ['UG-330']: $localize`Otuke`,
    ['UG-331']: $localize`Zombo`,
    ['UG-401']: $localize`Bundibugyo`,
    ['UG-402']: $localize`Bushenyi`,
    ['UG-403']: $localize`Hoima`,
    ['UG-404']: $localize`Kabale`,
    ['UG-405']: $localize`Kabarole`,
    ['UG-406']: $localize`Kasese`,
    ['UG-407']: $localize`Kibaale`,
    ['UG-408']: $localize`Kisoro`,
    ['UG-409']: $localize`Masindi`,
    ['UG-410']: $localize`Mbarara`,
    ['UG-411']: $localize`Ntungamo`,
    ['UG-412']: $localize`Rukungiri`,
    ['UG-413']: $localize`Kamwenge`,
    ['UG-414']: $localize`Kanungu`,
    ['UG-415']: $localize`Kyenjojo`,
    ['UG-416']: $localize`Ibanda`,
    ['UG-417']: $localize`Isingiro`,
    ['UG-418']: $localize`Kiruhura`,
    ['UG-419']: $localize`Buliisa`,
    ['UG-420']: $localize`Kiryandongo`,
    ['UG-421']: $localize`Kyegegwa`,
    ['UG-422']: $localize`Mitooma`,
    ['UG-423']: $localize`Ntoroko`,
    ['UG-424']: $localize`Rubirizi`,
    ['UG-425']: $localize`Sheema`,
    ['UG-C']: $localize`Central`,
    ['UG-E']: $localize`Eastern`,
    ['UG-N']: $localize`Northern`,
    ['UG-W']: $localize`Western`,
    ['UA-05']: $localize`Vinnytska oblast`,
    ['UA-07']: $localize`Volynska oblast`,
    ['UA-09']: $localize`Luhanska oblast`,
    ['UA-12']: $localize`Dnipropetrovska oblast`,
    ['UA-14']: $localize`Donetska oblast`,
    ['UA-18']: $localize`Zhytomyrska oblast`,
    ['UA-21']: $localize`Zakarpatska oblast`,
    ['UA-23']: $localize`Zaporizka oblast`,
    ['UA-26']: $localize`Ivano-Frankivska oblast`,
    ['UA-30']: $localize`Kyiv`,
    ['UA-32']: $localize`Kyivska oblast`,
    ['UA-35']: $localize`Kirovohradska oblast`,
    ['UA-40']: $localize`Sevastopol`,
    ['UA-43']: $localize`Avtonomna Respublika Krym`,
    ['UA-46']: $localize`Lvivska oblast`,
    ['UA-48']: $localize`Mykolaivska oblast`,
    ['UA-51']: $localize`Odeska oblast`,
    ['UA-53']: $localize`Poltavska oblast`,
    ['UA-56']: $localize`Rivnenska oblast`,
    ['UA-59']: $localize`Sumska oblast`,
    ['UA-61']: $localize`Ternopilska oblast`,
    ['UA-63']: $localize`Kharkivska oblast`,
    ['UA-65']: $localize`Khersonska oblast`,
    ['UA-68']: $localize`Khmelnytska oblast`,
    ['UA-71']: $localize`Cherkaska oblast`,
    ['UA-74']: $localize`Chernihivska oblast`,
    ['UA-77']: $localize`Chernivetska oblast`,
    ['AE-AJ']: $localize`'Ajmān`,
    ['AE-AZ']: $localize`Abū Z̧aby`,
    ['AE-DU']: $localize`Dubayy`,
    ['AE-FU']: $localize`Al Fujayrah`,
    ['AE-RK']: $localize`Ra's al Khaymah`,
    ['AE-SH']: $localize`Ash Shāriqah`,
    ['AE-UQ']: $localize`Umm al Qaywayn`,
    ['GB-ABD']: $localize`Aberdeenshire`,
    ['GB-ABE']: $localize`Aberdeen City`,
    ['GB-AGB']: $localize`Argyll and Bute`,
    ['GB-AGY']: $localize`Isle of Anglesey`,
    ['GB-ANS']: $localize`Angus`,
    ['GB-ANT']: $localize`Antrim`,
    ['GB-ARD']: $localize`Ards`,
    ['GB-ARM']: $localize`Armagh`,
    ['GB-BAS']: $localize`Bath and North East Somerset`,
    ['GB-BBD']: $localize`Blackburn with Darwen`,
    ['GB-BDF']: $localize`Bedford`,
    ['GB-BDG']: $localize`Barking and Dagenham`,
    ['GB-BEN']: $localize`Brent`,
    ['GB-BEX']: $localize`Bexley`,
    ['GB-BFS']: $localize`Belfast`,
    ['GB-BGE']: $localize`Bridgend`,
    ['GB-BGW']: $localize`Blaenau Gwent`,
    ['GB-BIR']: $localize`Birmingham`,
    ['GB-BKM']: $localize`Buckinghamshire`,
    ['GB-BLA']: $localize`Ballymena`,
    ['GB-BLY']: $localize`Ballymoney`,
    ['GB-BMH']: $localize`Bournemouth`,
    ['GB-BNB']: $localize`Banbridge`,
    ['GB-BNE']: $localize`Barnet`,
    ['GB-BNH']: $localize`Brighton and Hove`,
    ['GB-BNS']: $localize`Barnsley`,
    ['GB-BOL']: $localize`Bolton`,
    ['GB-BPL']: $localize`Blackpool`,
    ['GB-BRC']: $localize`Bracknell Forest`,
    ['GB-BRD']: $localize`Bradford`,
    ['GB-BRY']: $localize`Bromley`,
    ['GB-BST']: $localize`Bristol, City of`,
    ['GB-BUR']: $localize`Bury`,
    ['GB-CAM']: $localize`Cambridgeshire`,
    ['GB-CAY']: $localize`Caerphilly`,
    ['GB-CBF']: $localize`Central Bedfordshire`,
    ['GB-CGN']: $localize`Ceredigion`,
    ['GB-CGV']: $localize`Craigavon`,
    ['GB-CHE']: $localize`Cheshire East`,
    ['GB-CHW']: $localize`Cheshire West and Chester`,
    ['GB-CKF']: $localize`Carrickfergus`,
    ['GB-CKT']: $localize`Cookstown`,
    ['GB-CLD']: $localize`Calderdale`,
    ['GB-CLK']: $localize`Clackmannanshire`,
    ['GB-CLR']: $localize`Coleraine`,
    ['GB-CMA']: $localize`Cumbria`,
    ['GB-CMD']: $localize`Camden`,
    ['GB-CMN']: $localize`Carmarthenshire`,
    ['GB-CON']: $localize`Cornwall`,
    ['GB-COV']: $localize`Coventry`,
    ['GB-CRF']: $localize`Cardiff`,
    ['GB-CRY']: $localize`Croydon`,
    ['GB-CSR']: $localize`Castlereagh`,
    ['GB-CWY']: $localize`Conwy`,
    ['GB-DAL']: $localize`Darlington`,
    ['GB-DBY']: $localize`Derbyshire`,
    ['GB-DEN']: $localize`Denbighshire`,
    ['GB-DER']: $localize`Derby`,
    ['GB-DEV']: $localize`Devon`,
    ['GB-DGN']: $localize`Dungannon and South Tyrone`,
    ['GB-DGY']: $localize`Dumfries and Galloway`,
    ['GB-DNC']: $localize`Doncaster`,
    ['GB-DND']: $localize`Dundee City`,
    ['GB-DOR']: $localize`Dorset`,
    ['GB-DOW']: $localize`Down`,
    ['GB-DRY']: $localize`Derry`,
    ['GB-DUD']: $localize`Dudley`,
    ['GB-DUR']: $localize`Durham County`,
    ['GB-EAL']: $localize`Ealing`,
    ['GB-EAW']: $localize`England and Wales`,
    ['GB-EAY']: $localize`East Ayrshire`,
    ['GB-EDH']: $localize`Edinburgh, City of`,
    ['GB-EDU']: $localize`East Dunbartonshire`,
    ['GB-ELN']: $localize`East Lothian`,
    ['GB-ELS']: $localize`Eilean Siar`,
    ['GB-ENF']: $localize`Enfield`,
    ['GB-ENG']: $localize`England`,
    ['GB-ERW']: $localize`East Renfrewshire`,
    ['GB-ERY']: $localize`East Riding of Yorkshire`,
    ['GB-ESS']: $localize`Essex`,
    ['GB-ESX']: $localize`East Sussex`,
    ['GB-FAL']: $localize`Falkirk`,
    ['GB-FER']: $localize`Fermanagh`,
    ['GB-FIF']: $localize`Fife`,
    ['GB-FLN']: $localize`Flintshire`,
    ['GB-GAT']: $localize`Gateshead`,
    ['GB-GBN']: $localize`Great Britain`,
    ['GB-GLG']: $localize`Glasgow City`,
    ['GB-GLS']: $localize`Gloucestershire`,
    ['GB-GRE']: $localize`Greenwich`,
    ['GB-GWN']: $localize`Gwynedd`,
    ['GB-HAL']: $localize`Halton`,
    ['GB-HAM']: $localize`Hampshire`,
    ['GB-HAV']: $localize`Havering`,
    ['GB-HCK']: $localize`Hackney`,
    ['GB-HEF']: $localize`Herefordshire`,
    ['GB-HIL']: $localize`Hillingdon`,
    ['GB-HLD']: $localize`Highland`,
    ['GB-HMF']: $localize`Hammersmith and Fulham`,
    ['GB-HNS']: $localize`Hounslow`,
    ['GB-HPL']: $localize`Hartlepool`,
    ['GB-HRT']: $localize`Hertfordshire`,
    ['GB-HRW']: $localize`Harrow`,
    ['GB-HRY']: $localize`Haringey`,
    ['GB-IOS']: $localize`Isles of Scilly`,
    ['GB-IOW']: $localize`Isle of Wight`,
    ['GB-ISL']: $localize`Islington`,
    ['GB-IVC']: $localize`Inverclyde`,
    ['GB-KEC']: $localize`Kensington and Chelsea`,
    ['GB-KEN']: $localize`Kent`,
    ['GB-KHL']: $localize`Kingston upon Hull`,
    ['GB-KIR']: $localize`Kirklees`,
    ['GB-KTT']: $localize`Kingston upon Thames`,
    ['GB-KWL']: $localize`Knowsley`,
    ['GB-LAN']: $localize`Lancashire`,
    ['GB-LBH']: $localize`Lambeth`,
    ['GB-LCE']: $localize`Leicester`,
    ['GB-LDS']: $localize`Leeds`,
    ['GB-LEC']: $localize`Leicestershire`,
    ['GB-LEW']: $localize`Lewisham`,
    ['GB-LIN']: $localize`Lincolnshire`,
    ['GB-LIV']: $localize`Liverpool`,
    ['GB-LMV']: $localize`Limavady`,
    ['GB-LND']: $localize`London, City of`,
    ['GB-LRN']: $localize`Larne`,
    ['GB-LSB']: $localize`Lisburn`,
    ['GB-LUT']: $localize`Luton`,
    ['GB-MAN']: $localize`Manchester`,
    ['GB-MDB']: $localize`Middlesbrough`,
    ['GB-MDW']: $localize`Medway`,
    ['GB-MFT']: $localize`Magherafelt`,
    ['GB-MIK']: $localize`Milton Keynes`,
    ['GB-MLN']: $localize`Midlothian`,
    ['GB-MON']: $localize`Monmouthshire`,
    ['GB-MRT']: $localize`Merton`,
    ['GB-MRY']: $localize`Moray`,
    ['GB-MTY']: $localize`Merthyr Tydfil`,
    ['GB-MYL']: $localize`Moyle`,
    ['GB-NAY']: $localize`North Ayrshire`,
    ['GB-NBL']: $localize`Northumberland`,
    ['GB-NDN']: $localize`North Down`,
    ['GB-NEL']: $localize`North East Lincolnshire`,
    ['GB-NET']: $localize`Newcastle upon Tyne`,
    ['GB-NFK']: $localize`Norfolk`,
    ['GB-NGM']: $localize`Nottingham`,
    ['GB-NIR']: $localize`Northern Ireland`,
    ['GB-NLK']: $localize`North Lanarkshire`,
    ['GB-NLN']: $localize`North Lincolnshire`,
    ['GB-NSM']: $localize`North Somerset`,
    ['GB-NTA']: $localize`Newtownabbey`,
    ['GB-NTH']: $localize`Northamptonshire`,
    ['GB-NTL']: $localize`Neath Port Talbot`,
    ['GB-NTT']: $localize`Nottinghamshire`,
    ['GB-NTY']: $localize`North Tyneside`,
    ['GB-NWM']: $localize`Newham`,
    ['GB-NWP']: $localize`Newport`,
    ['GB-NYK']: $localize`North Yorkshire`,
    ['GB-NYM']: $localize`Newry and Mourne`,
    ['GB-OLD']: $localize`Oldham`,
    ['GB-OMH']: $localize`Omagh`,
    ['GB-ORK']: $localize`Orkney Islands`,
    ['GB-OXF']: $localize`Oxfordshire`,
    ['GB-PEM']: $localize`Pembrokeshire`,
    ['GB-PKN']: $localize`Perth and Kinross`,
    ['GB-PLY']: $localize`Plymouth`,
    ['GB-POL']: $localize`Poole`,
    ['GB-POR']: $localize`Portsmouth`,
    ['GB-POW']: $localize`Powys`,
    ['GB-PTE']: $localize`Peterborough`,
    ['GB-RCC']: $localize`Redcar and Cleveland`,
    ['GB-RCH']: $localize`Rochdale`,
    ['GB-RCT']: $localize`Rhondda, Cynon, Taff`,
    ['GB-RDB']: $localize`Redbridge`,
    ['GB-RDG']: $localize`Reading`,
    ['GB-RFW']: $localize`Renfrewshire`,
    ['GB-RIC']: $localize`Richmond upon Thames`,
    ['GB-ROT']: $localize`Rotherham`,
    ['GB-RUT']: $localize`Rutland`,
    ['GB-SAW']: $localize`Sandwell`,
    ['GB-SAY']: $localize`South Ayrshire`,
    ['GB-SCB']: $localize`Scottish Borders, The`,
    ['GB-SCT']: $localize`Scotland`,
    ['GB-SFK']: $localize`Suffolk`,
    ['GB-SFT']: $localize`Sefton`,
    ['GB-SGC']: $localize`South Gloucestershire`,
    ['GB-SHF']: $localize`Sheffield`,
    ['GB-SHN']: $localize`St. Helens`,
    ['GB-SHR']: $localize`Shropshire`,
    ['GB-SKP']: $localize`Stockport`,
    ['GB-SLF']: $localize`Salford`,
    ['GB-SLG']: $localize`Slough`,
    ['GB-SLK']: $localize`South Lanarkshire`,
    ['GB-SND']: $localize`Sunderland`,
    ['GB-SOL']: $localize`Solihull`,
    ['GB-SOM']: $localize`Somerset`,
    ['GB-SOS']: $localize`Southend-on-Sea`,
    ['GB-SRY']: $localize`Surrey`,
    ['GB-STB']: $localize`Strabane`,
    ['GB-STE']: $localize`Stoke-on-Trent`,
    ['GB-STG']: $localize`Stirling`,
    ['GB-STH']: $localize`Southampton`,
    ['GB-STN']: $localize`Sutton`,
    ['GB-STS']: $localize`Staffordshire`,
    ['GB-STT']: $localize`Stockton-on-Tees`,
    ['GB-STY']: $localize`South Tyneside`,
    ['GB-SWA']: $localize`Swansea`,
    ['GB-SWD']: $localize`Swindon`,
    ['GB-SWK']: $localize`Southwark`,
    ['GB-TAM']: $localize`Tameside`,
    ['GB-TFW']: $localize`Telford and Wrekin`,
    ['GB-THR']: $localize`Thurrock`,
    ['GB-TOB']: $localize`Torbay`,
    ['GB-TOF']: $localize`Torfaen`,
    ['GB-TRF']: $localize`Trafford`,
    ['GB-TWH']: $localize`Tower Hamlets`,
    ['GB-UKM']: $localize`United Kingdom`,
    ['GB-VGL']: $localize`Vale of Glamorgan, The`,
    ['GB-WAR']: $localize`Warwickshire`,
    ['GB-WBK']: $localize`West Berkshire`,
    ['GB-WDU']: $localize`West Dunbartonshire`,
    ['GB-WFT']: $localize`Waltham Forest`,
    ['GB-WGN']: $localize`Wigan`,
    ['GB-WIL']: $localize`Wiltshire`,
    ['GB-WKF']: $localize`Wakefield`,
    ['GB-WLL']: $localize`Walsall`,
    ['GB-WLN']: $localize`West Lothian`,
    ['GB-WLS']: $localize`Wales`,
    ['GB-WLV']: $localize`Wolverhampton`,
    ['GB-WND']: $localize`Wandsworth`,
    ['GB-WNM']: $localize`Windsor and Maidenhead`,
    ['GB-WOK']: $localize`Wokingham`,
    ['GB-WOR']: $localize`Worcestershire`,
    ['GB-WRL']: $localize`Wirral`,
    ['GB-WRT']: $localize`Warrington`,
    ['GB-WRX']: $localize`Wrexham`,
    ['GB-WSM']: $localize`Westminster`,
    ['GB-WSX']: $localize`West Sussex`,
    ['GB-YOR']: $localize`York`,
    ['GB-ZET']: $localize`Shetland Islands`,
    ['UM-67']: $localize`Johnston Atoll`,
    ['UM-71']: $localize`Midway Islands`,
    ['UM-76']: $localize`Navassa Island`,
    ['UM-79']: $localize`Wake Island`,
    ['UM-81']: $localize`Baker Island`,
    ['UM-84']: $localize`Howland Island`,
    ['UM-86']: $localize`Jarvis Island`,
    ['UM-89']: $localize`Kingman Reef`,
    ['UM-95']: $localize`Palmyra Atoll`,
    ['US-AK']: $localize`Alaska`,
    ['US-AL']: $localize`Alabama`,
    ['US-AR']: $localize`Arkansas`,
    ['US-AS']: $localize`American Samoa`,
    ['US-AZ']: $localize`Arizona`,
    ['US-CA']: $localize`California`,
    ['US-CO']: $localize`Colorado`,
    ['US-CT']: $localize`Connecticut`,
    ['US-DC']: $localize`District of Columbia`,
    ['US-DE']: $localize`Delaware`,
    ['US-FL']: $localize`Florida`,
    ['US-GA']: $localize`Georgia`,
    ['US-GU']: $localize`Guam`,
    ['US-HI']: $localize`Hawaii`,
    ['US-IA']: $localize`Iowa`,
    ['US-ID']: $localize`Idaho`,
    ['US-IL']: $localize`Illinois`,
    ['US-IN']: $localize`Indiana`,
    ['US-KS']: $localize`Kansas`,
    ['US-KY']: $localize`Kentucky`,
    ['US-LA']: $localize`Louisiana`,
    ['US-MA']: $localize`Massachusetts`,
    ['US-MD']: $localize`Maryland`,
    ['US-ME']: $localize`Maine`,
    ['US-MI']: $localize`Michigan`,
    ['US-MN']: $localize`Minnesota`,
    ['US-MO']: $localize`Missouri`,
    ['US-MP']: $localize`Northern Mariana Islands`,
    ['US-MS']: $localize`Mississippi`,
    ['US-MT']: $localize`Montana`,
    ['US-NC']: $localize`North Carolina`,
    ['US-ND']: $localize`North Dakota`,
    ['US-NE']: $localize`Nebraska`,
    ['US-NH']: $localize`New Hampshire`,
    ['US-NJ']: $localize`New Jersey`,
    ['US-NM']: $localize`New Mexico`,
    ['US-NV']: $localize`Nevada`,
    ['US-NY']: $localize`New York`,
    ['US-OH']: $localize`Ohio`,
    ['US-OK']: $localize`Oklahoma`,
    ['US-OR']: $localize`Oregon`,
    ['US-PA']: $localize`Pennsylvania`,
    ['US-PR']: $localize`Puerto Rico`,
    ['US-RI']: $localize`Rhode Island`,
    ['US-SC']: $localize`South Carolina`,
    ['US-SD']: $localize`South Dakota`,
    ['US-TN']: $localize`Tennessee`,
    ['US-TX']: $localize`Texas`,
    ['US-UM']: $localize`United States Minor Outlying Islands`,
    ['US-UT']: $localize`Utah`,
    ['US-VA']: $localize`Virginia`,
    ['US-VI']: $localize`Virgin Islands, U.S.`,
    ['US-VT']: $localize`Vermont`,
    ['US-WA']: $localize`Washington`,
    ['US-WI']: $localize`Wisconsin`,
    ['US-WV']: $localize`West Virginia`,
    ['US-WY']: $localize`Wyoming`,
    ['UY-AR']: $localize`Artigas`,
    ['UY-CA']: $localize`Canelones`,
    ['UY-CL']: $localize`Cerro Largo`,
    ['UY-CO']: $localize`Colonia`,
    ['UY-DU']: $localize`Durazno`,
    ['UY-FD']: $localize`Florida`,
    ['UY-FS']: $localize`Flores`,
    ['UY-LA']: $localize`Lavalleja`,
    ['UY-MA']: $localize`Maldonado`,
    ['UY-MO']: $localize`Montevideo`,
    ['UY-PA']: $localize`Paysandú`,
    ['UY-RN']: $localize`Río Negro`,
    ['UY-RO']: $localize`Rocha`,
    ['UY-RV']: $localize`Rivera`,
    ['UY-SA']: $localize`Salto`,
    ['UY-SJ']: $localize`San José`,
    ['UY-SO']: $localize`Soriano`,
    ['UY-TA']: $localize`Tacuarembó`,
    ['UY-TT']: $localize`Treinta y Tres`,
    ['UZ-AN']: $localize`Andijon`,
    ['UZ-BU']: $localize`Buxoro`,
    ['UZ-FA']: $localize`Farg‘ona`,
    ['UZ-JI']: $localize`Jizzax`,
    ['UZ-NG']: $localize`Namangan`,
    ['UZ-NW']: $localize`Navoiy`,
    ['UZ-QA']: $localize`Qashqadaryo`,
    ['UZ-QR']: $localize`Qoraqalpog‘iston Respublikasi`,
    ['UZ-SA']: $localize`Samarqand`,
    ['UZ-SI']: $localize`Sirdaryo`,
    ['UZ-SU']: $localize`Surxondaryo`,
    ['UZ-TK']: $localize`Toshkent`,
    ['UZ-TO']: $localize`Toshkent`,
    ['UZ-XO']: $localize`Xorazm`,
    ['VU-MAP']: $localize`Malampa`,
    ['VU-PAM']: $localize`Pénama`,
    ['VU-SAM']: $localize`Sanma`,
    ['VU-SEE']: $localize`Shéfa`,
    ['VU-TAE']: $localize`Taféa`,
    ['VU-TOB']: $localize`Torba`,
    ['VA-VA']: $localize`Vatican City`,
    ['VE-A']: $localize`Distrito Capital`,
    ['VE-B']: $localize`Anzoátegui`,
    ['VE-C']: $localize`Apure`,
    ['VE-D']: $localize`Aragua`,
    ['VE-E']: $localize`Barinas`,
    ['VE-F']: $localize`Bolívar`,
    ['VE-G']: $localize`Carabobo`,
    ['VE-H']: $localize`Cojedes`,
    ['VE-I']: $localize`Falcón`,
    ['VE-J']: $localize`Guárico`,
    ['VE-K']: $localize`Lara`,
    ['VE-L']: $localize`Mérida`,
    ['VE-M']: $localize`Miranda`,
    ['VE-N']: $localize`Monagas`,
    ['VE-O']: $localize`Nueva Esparta`,
    ['VE-P']: $localize`Portuguesa`,
    ['VE-R']: $localize`Sucre`,
    ['VE-S']: $localize`Táchira`,
    ['VE-T']: $localize`Trujillo`,
    ['VE-U']: $localize`Yaracuy`,
    ['VE-V']: $localize`Zulia`,
    ['VE-W']: $localize`Dependencias Federales`,
    ['VE-X']: $localize`Vargas`,
    ['VE-Y']: $localize`Delta Amacuro`,
    ['VE-Z']: $localize`Amazonas`,
    ['VN-01']: $localize`Lai Châu`,
    ['VN-02']: $localize`Lào Cai`,
    ['VN-03']: $localize`Hà Giang`,
    ['VN-04']: $localize`Cao Bằng`,
    ['VN-05']: $localize`Sơn La`,
    ['VN-06']: $localize`Yên Bái`,
    ['VN-07']: $localize`Tuyên Quang`,
    ['VN-09']: $localize`Lạng Sơn`,
    ['VN-13']: $localize`Quảng Ninh`,
    ['VN-14']: $localize`Hòa Bình`,
    ['VN-18']: $localize`Ninh Bình`,
    ['VN-20']: $localize`Thái Bình`,
    ['VN-21']: $localize`Thanh Hóa`,
    ['VN-22']: $localize`Nghệ An`,
    ['VN-23']: $localize`Hà Tĩnh`,
    ['VN-24']: $localize`Quảng Bình`,
    ['VN-25']: $localize`Quảng Trị`,
    ['VN-26']: $localize`Thừa Thiên-Huế`,
    ['VN-27']: $localize`Quảng Nam`,
    ['VN-28']: $localize`Kon Tum`,
    ['VN-29']: $localize`Quảng Ngãi`,
    ['VN-30']: $localize`Gia Lai`,
    ['VN-31']: $localize`Bình Định`,
    ['VN-32']: $localize`Phú Yên`,
    ['VN-33']: $localize`Đắk Lắk`,
    ['VN-34']: $localize`Khánh Hòa`,
    ['VN-35']: $localize`Lâm Ðồng`,
    ['VN-36']: $localize`Ninh Thuận`,
    ['VN-37']: $localize`Tây Ninh`,
    ['VN-39']: $localize`Ðồng Nai`,
    ['VN-40']: $localize`Bình Thuận`,
    ['VN-41']: $localize`Long An`,
    ['VN-43']: $localize`Bà Rịa - Vũng Tàu`,
    ['VN-44']: $localize`An Giang`,
    ['VN-45']: $localize`Ðồng Tháp`,
    ['VN-46']: $localize`Tiền Giang`,
    ['VN-47']: $localize`Kiến Giang`,
    ['VN-49']: $localize`Vĩnh Long`,
    ['VN-50']: $localize`Bến Tre`,
    ['VN-51']: $localize`Trà Vinh`,
    ['VN-52']: $localize`Sóc Trăng`,
    ['VN-53']: $localize`Bắc Kạn`,
    ['VN-54']: $localize`Bắc Giang`,
    ['VN-55']: $localize`Bạc Liêu`,
    ['VN-56']: $localize`Bắc Ninh`,
    ['VN-57']: $localize`Bình Dương`,
    ['VN-58']: $localize`Bình Phước`,
    ['VN-59']: $localize`Cà Mau`,
    ['VN-61']: $localize`Hải Dương`,
    ['VN-63']: $localize`Hà Nam`,
    ['VN-66']: $localize`Hưng Yên`,
    ['VN-67']: $localize`Nam Ðịnh`,
    ['VN-68']: $localize`Phú Thọ`,
    ['VN-69']: $localize`Thái Nguyên`,
    ['VN-70']: $localize`Vĩnh Phúc`,
    ['VN-71']: $localize`Điện Biên`,
    ['VN-72']: $localize`Đắk Nông`,
    ['VN-73']: $localize`Hậu Giang`,
    ['VN-CT']: $localize`Can Tho`,
    ['VN-DN']: $localize`Da Nang`,
    ['VN-HN']: $localize`Ha Noi`,
    ['VN-HP']: $localize`Hai Phong`,
    ['VN-SG']: $localize`Ho Chi Minh`,
    ['VG-VG']: $localize`Virgin Islands`,
    ['VI-VI']: $localize`Virgin Islands`,
    ['WF-WF']: $localize`Wallis & Futuna Islands`,
    ['EH-EH']: $localize`Western Sahara`,
    ['YE-AB']: $localize`Abyān`,
    ['YE-AD']: $localize`'Adan`,
    ['YE-AM']: $localize`'Amrān`,
    ['YE-BA']: $localize`Al Bayḑā'`,
    ['YE-DA']: $localize`Aḑ Ḑāli'`,
    ['YE-DH']: $localize`Dhamār`,
    ['YE-HD']: $localize`Ḩaḑramawt`,
    ['YE-HJ']: $localize`Ḩajjah`,
    ['YE-HU']: $localize`Al Ḩudaydah`,
    ['YE-IB']: $localize`Ibb`,
    ['YE-JA']: $localize`Al Jawf`,
    ['YE-LA']: $localize`Laḩij`,
    ['YE-MA']: $localize`Ma'rib`,
    ['YE-MR']: $localize`Al Mahrah`,
    ['YE-MW']: $localize`Al Maḩwīt`,
    ['YE-RA']: $localize`Raymah`,
    ['YE-SA']: $localize`Amānat al ‘Āşimah`,
    ['YE-SD']: $localize`Şāʻdah`,
    ['YE-SH']: $localize`Shabwah`,
    ['YE-SN']: $localize`Şanʻā'`,
    ['YE-TA']: $localize`Tāʻizz`,
    ['ZM-01']: $localize`Western`,
    ['ZM-02']: $localize`Central`,
    ['ZM-03']: $localize`Eastern`,
    ['ZM-04']: $localize`Luapula`,
    ['ZM-05']: $localize`Northern`,
    ['ZM-06']: $localize`North-Western`,
    ['ZM-07']: $localize`Southern`,
    ['ZM-08']: $localize`Copperbelt`,
    ['ZM-09']: $localize`Lusaka`,
    ['ZM-10']: $localize`Muchinga`,
    ['ZW-BU']: $localize`Bulawayo`,
    ['ZW-HA']: $localize`Harare`,
    ['ZW-MA']: $localize`Manicaland`,
    ['ZW-MC']: $localize`Mashonaland Central`,
    ['ZW-ME']: $localize`Mashonaland East`,
    ['ZW-MI']: $localize`Midlands`,
    ['ZW-MN']: $localize`Matabeleland North`,
    ['ZW-MS']: $localize`Matabeleland South`,
    ['ZW-MV']: $localize`Masvingo`,
    ['ZW-MW']: $localize`Mashonaland West`,
  };
}
