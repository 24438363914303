import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { DataServiceError } from '@ngrx/data';
import get from 'lodash-es/get';
import isArray from 'lodash-es/isArray';
import isObject from 'lodash-es/isObject';
import map from 'lodash-es/map';
import { ErrorCheck } from './enums/error-check.enum';
import { IErrorDetails } from './interfaces/error-details.interface';

// @dynamic
export class ErrorService {
  static isFieldInvalid(control: AbstractControl, checkOn?: ErrorCheck): boolean {
    const shouldValidate: boolean = checkOn
      ? control?.touched || control[checkOn]
      : control?.touched;
    const isInvalid: boolean = shouldValidate && control.invalid;
    return isInvalid;
  }

  static getFormattedMessage(details: IErrorDetails): string {
    return map(details, (value: string[] | string, key: string) => {
      let formattedValue = value;
      if (isArray(value)) {
        const valueAsArray = value as string[];
        formattedValue = valueAsArray.join(',');
      }
      return `${key}: ${formattedValue}. `;
    });
  }

  static getErrorMessage(error: Error | HttpErrorResponse | DataServiceError): string {
    // pick the top level error message by default, this is the lowest priority.
    let message = error.message;

    // pick the error message returned by backend
    const errorMessage = get(error, 'error.message');
    if (errorMessage) {
      message = errorMessage;
    }

    // pick the error message returned by backend in an array
    const errorsMessage: string = get(error, 'error.errors[0].message');
    if (errorsMessage) {
      message = errorsMessage;
    }

    // pick detailed error message returned by backend
    const additionalPropertiesError: string = get(error, 'error.additionalProperties.error');
    if (additionalPropertiesError) {
      message = additionalPropertiesError;
    }

    const embeddedAdditionalPropertiesError: string = get(
      error,
      'error.error.additionalProperties.error',
    );
    if (embeddedAdditionalPropertiesError) {
      message = embeddedAdditionalPropertiesError;
    }

    return message;
  }

  /**
   * @description
   *
   * Returns the status code of error object
   *
   * @Param {Object} httpError - Error which was Threw on http request
   *
   * @Param {number|string} httpError.error.status - The status code of httpError if param instanceof HttpErrorResponse
   *
   * @Param {number|string} httpError.error.status The status code of httpError if param instanceof DataServiceError
   *
   * @Param {number|string} httpError.error.code The status code of httpError if param instanceof DataServiceError or an object
   *
   * @Param {string} httpError.error The error property of httpError object might be an json string if param instanceof DataServiceError or an object.
   * In this case we should parse it and get the status code.
   *
   *  @returns {number} Returns the value of status code.
   */
  static getStatusCode(httpError: HttpErrorResponse | DataServiceError | any): number {
    let status: number | string;
    if (httpError instanceof HttpErrorResponse) {
      status = httpError.status;
    } else if (isObject(httpError.error)) {
      status = httpError.error.status || httpError.error.code;
    } else {
      const parsedError = JSON.parse(httpError.error);
      status = parsedError.status || parsedError.code;
    }
    return Number(status);
  }

  static addMessageToNgbError(ngbError: ValidationErrors): ValidationErrors {
    if (ngbError.invalid) {
      ngbError.message = '@@INVALID_DATE';
    }

    return ngbError;
  }
}
