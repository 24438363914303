import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MultilingualValidators } from './error/custom-error.validator';
import { EntityValidators } from './error/entity.validator';
import { PortalFormErrorComponent } from './error/error.component';
import { ErrorService } from './error/error.service';
import { UserValidators } from './error/user.validator';
import { FormErrorModule } from './error/form-error.module';
import { MopAutocompleteHighlightPipe } from '@apps/merchant-order-portal/src/app/shared/components/mop-autocomplete/mop-autocomplete-highlight-pipe';

export * from './error/error.validator';
export * from './base/base.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormErrorModule],
  providers: [ErrorService, MultilingualValidators, EntityValidators, UserValidators],
  exports: [ReactiveFormsModule, PortalFormErrorComponent, MopAutocompleteHighlightPipe],
  declarations: [MopAutocompleteHighlightPipe],
})
export class FormModule {}
