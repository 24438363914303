import { RouterModule } from '@angular/router';
import { AuthenticationGuard } from '@portal/shared/auth/authentication';
import {
  AuthorizationGuard,
  IGuardRoute,
  RoleGuardService,
} from '@portal/shared/auth/authorization';
import { ES_CONSTANTS as CONSTANTS } from '../../../interfaces/src/lib/core/constants';
import { CreateOrganisationComponent } from './components/create/create.component';
import { DetailOrganisationComponent } from './components/detail/detail.component';
import { ListOrganisationComponent } from './components/list/list.component';
import { UpdateOrganisationComponent } from './components/update/update.component';
import { WhiteLabelingPreviewComponent } from './components/white-labeling-preview/white-labeling-preview.component';
import { organisationsPermissions } from './routing/route-permissions.const';
import { OrganisationRoutesEnum } from './routing/route.enum';

const routes: IGuardRoute[] = [
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/organisations`,
    component: ListOrganisationComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, AuthorizationGuard],
    data: {
      permission: organisationsPermissions.list,
    },
  },
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/organisations/create`,
    component: CreateOrganisationComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, AuthorizationGuard],
    data: {
      permission: organisationsPermissions.create,
    },
  },
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/organisations/:id/edit`,
    component: UpdateOrganisationComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, AuthorizationGuard],
    data: {
      permission: organisationsPermissions.edit,
    },
  },
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/organisations/:id/edit/${OrganisationRoutesEnum.whiteLabelingPreview}`,
    component: WhiteLabelingPreviewComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, AuthorizationGuard],
    data: {
      permission: organisationsPermissions.edit,
    },
  },
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/organisations/:id`,
    component: DetailOrganisationComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, AuthorizationGuard],
    data: {
      permission: organisationsPermissions.details,
    },
  },
  {
    path: `${CONSTANTS.ADMINISTRATION_ROUTE_PREFIX}/organisations/:id/${OrganisationRoutesEnum.whiteLabelingPreview}`,
    component: WhiteLabelingPreviewComponent,
    canActivate: [AuthenticationGuard, RoleGuardService, AuthorizationGuard],
    data: {
      permission: organisationsPermissions.details,
    },
  },
];

export const OrganisationsRoutes = RouterModule.forChild(routes);
