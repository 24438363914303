export enum ProcessorType {
  AbsIso = 'ABS_ISO',
  AbsWeb = 'ABS_WEB',
  Affirm = 'AFFIRM',
  AliPay = 'ALIPAY',
  AmexGB = 'AMEX_GB',
  AmexMX = 'AMEX_MX',
  AmexPWP = 'AMEX_PWP',
  Bankaxept = 'BANKAXEPT',
  Banorte = 'BANORTE_MX',
  BanqueChabrieres = 'BANQUE_CHABRIERES',
  Barclays = 'BARCLAYS',
  BarclaysMc = 'BARCLAYS_MC',
  BbvaMx = 'BBVA_MX',
  Bfcoi = 'BFCOI',
  BlackhawkCa = 'BLACKHAWK_CA',
  BlackhawkUs = 'BLACKHAWK_US',
  Blik = 'BLIK',
  BnpParibas = 'BNP_PARIBAS',
  BnppAtc = 'BNPP_ATC',
  BnppVersAtos = 'BNPP_VERS_ATOS',
  BofAUs = 'BOFA_US',
  Bred = 'BRED',
  CaisseDepargne = 'CAISSE_D_EPARGNE',
  Cba = 'CBA',
  Cedicam = 'CEDICAM',
  ChaseCa = 'CHASE_CA',
  ChaseUs = 'CHASE_US',
  Cic = 'CIC',
  CitibankCa = 'CITIBANK_CA',
  CitibankUs = 'CITIBANK_US',
  CmArkea = 'CM_ARKEA',
  CreditAgricole = 'CREDIT_AGRICOLE',
  CreditDuNordVersAtos = 'CREDIT_DU_NORD_VERS_ATOS',
  CreditDuNordVersNatixis = 'CREDIT_DU_NORD_VERS_NATIXIS',
  CreditMutuel = 'CREDIT_MUTUEL',
  Credomatic = 'CREDOMATIC',
  Crypto = 'CRYPTO',
  CuscalAu = 'CUSCAL_AU',
  Dankort = 'DANKORT',
  EftposNz = 'EFTPOS_NZ',
  Elavon = 'ELAVON',
  ElavonIsoUk = 'ELAVON_ISO_UK',
  Fdrc = 'FDRC',
  FdrcCa = 'FDRC_CA',
  FdrcUs = 'FDRC_US',
  Firstdata = 'FIRSTDATA',
  FiservLac = 'FISERV_LAC',
  FiservUk = 'FISERV_UK',
  FiservAu = 'FISERV_AU',
  Gicc = 'GICC',
  Globalpay = 'GLOBALPAY',
  GPP2 = 'GPP2',
  Hsbc = 'HSBC',
  Ikano = 'IKANO',
  IncommCa = 'INCOMM_CA',
  IncommUs = 'INCOMM_US',
  Intercard = 'INTERCARD',
  Isracard = 'ISRACARD',
  KlarnaEcom = 'KLARNA_ECOM',
  KlarnaQr = 'KLARNA_QR',
  LaBanquePostal = 'LA_BANQUE_POSTAL',
  Landsbankinn = 'LANDSBANKINN',
  Lcl = 'LCL',
  LesBanquesPopulaires = 'LES_BANQUES_POPULAIRES',
  LloydsCardnet = 'LLOYDS_CARDNET',
  Maestro = 'MAESTRO',
  Mobilepay = 'MOBILEPAY',
  MPGS = 'MPGS',
  Nets = 'NETS',
  OpOnlinePayment = 'OP_ONLINE_PAYMENT',
  Other = 'OTHER',
  ParkRetailUk = 'PARK_RETAIL_UK',
  Paypal = 'PAYPAL',
  Prosa = 'PROSA_MX',
  Rapyd = 'RAPYD',
  ResursBank = 'RESURS_BANK',
  Simulator = 'SIMULATOR',
  Sg = 'SG',
  SvsUk = 'SVS_UK',
  SvsUs = 'SVS_US',
  SwedbankPay = 'SWEDBANK_PAY',
  Swish = 'SWISH',
  SynchronyUs = 'SYNCHRONY_US',
  TelecheckUS = 'TELECHECK_US',
  TietoEvry = 'TIETO_EVRY',
  UnionPay = 'UNIONPAY',
  ValuelinkCA = 'VALUELINK_CA',
  ValuelinkUS = 'VALUELINK_US',
  VerifoneEu = 'VERIFONE_EU',
  Vipps = 'VIPPS',
  WeChat = 'WECHAT',
  Westpac = 'WESTPAC',
  WestpacAu = 'VERIFONE_AU',
  WestpacPacificFiji = 'WESTPAC_PACIFIC_FJ',
  WestpacPacificPng = 'WESTPAC_PACIFIC_PG',
  WexUs = 'WEX_US',
  WicUsTx = 'WIC_US_TX',
  WoodForestUs = 'WOODFOREST_US',
  WorldlineUK = 'WORLDLINE_UK',
  Worldpay = 'WORLDPAY',
  WorldpayUS = 'WORLDPAY_US',
  Wpay = 'WPAY_AU',
  Wynid = 'WYNID',
  Prm = 'PRM',
}
