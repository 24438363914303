import { RouterModule } from '@angular/router';
import { IPortalRoute } from '@portal/shared/core';
import { FourZeroFourComponent } from '../lib/error-page-handling/four-zero-four/four-zero-four.component';
import { ZeroPermissionsComponent } from '@portal/shared/ui/layouts/src/lib/error-page-handling/zero-permissions/zero-permissions.component';
import { RedirectToInitialPage } from './guards/redirect-to-initial-page.guard';
import { ChangePasswordPageComponent } from './change-password-page/change-password-page.component';
import { AuthenticationGuard } from '@portal/shared/auth/authentication';

const routes: IPortalRoute[] = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [RedirectToInitialPage],
    loadChildren: () =>
      import('./apps-dashboard/apps-dashboard.module').then((m) => m.AppsDashboardModule),
    data: {
      checkForZeroPermissions: true,
    },
  },
  {
    path: 'commbank',
    redirectTo: 'welcome',
    data: {},
  },
  {
    path: 'applications/access-denied',
    component: ZeroPermissionsComponent,
    canActivate: [RedirectToInitialPage],
    data: {},
  },
  {
    path: 'change-password',
    component: ChangePasswordPageComponent,
    data: {},
    canActivate: [AuthenticationGuard],
  },
  { path: '**', component: FourZeroFourComponent, canActivate: [RedirectToInitialPage], data: {} },
];

export const VuiRoutes = RouterModule.forChild(routes);
