<div class="dropdown" [class.is-active]="isOpen">
  <div class="dropdown-trigger">
    <button
      [attr.aria-expanded]="isOpen"
      class="button is-chip is-size-7-mobile"
      [class.is-applied]="getValues().length"
      [class.is-active]="isOpen"
      data-e2e="service-provider-filter-button-open"
      (click)="itemToggled.emit()"
      type="button"
      title="Service Provider"
      i18n-title
    >
      <span>
        <span i18n>Service provider</span>
        <span *ngIf="showSelectedEntities.size"> (+{{ showSelectedEntities.size }}) </span>
      </span>
      <span class="icon">
        <i class="fas fa-chevron-down"></i>
      </span>
    </button>
  </div>

  <div class="dropdown-menu is-large">
    <div class="dropdown-content multi-select-filter">
      <div class="dropdown-item">
        <portal-search-filter-autocomplete-input
          #providerSearchInput
          [searchInputValue]="searchedText"
          (search)="onSearch($event)"
        ></portal-search-filter-autocomplete-input>

        <portal-switch-toggle
          #switchToggle
          [text]="labelSelectAllServiceProvider"
          (change)="onSelectAllSwitchToggle()"
        >
        </portal-switch-toggle>
      </div>

      <hr class="dropdown-divider" />

      <ng-container *ngIf="showSelectedEntities?.size">
        <div class="dropdown-item">
          <label class="label" i18n>Selected</label>
          <ng-container *ngFor="let entity of showSelectedEntities | keyvalue">
            <portal-search-filter-checkbox
              [id]="entity.key"
              [text]="entity.value.text"
              [selectedEntities]="showSelectedEntities"
              (removed)="onEntityRemoved($event)"
              (added)="onEntityAdded($event)"
            ></portal-search-filter-checkbox>
          </ng-container>
        </div>
      </ng-container>

      <hr class="dropdown-divider" />

      <div class="dropdown-item is-scrollable">
        <ng-container *ngFor="let processorType of entities">
          <portal-search-filter-checkbox
            [id]="processorType.id"
            [text]="processorType.text"
            [selectedEntities]="localPreSelectedEntities"
            (added)="onEntityAdded(processorType)"
            (removed)="onEntityRemoved(processorType)"
          >
          </portal-search-filter-checkbox>
        </ng-container>
      </div>

      <hr class="dropdown-divider" />

      <div class="dropdown-item">
        <button
          class="button is-primary"
          data-e2e="service-provider-filter-button-apply"
          i18n
          (click)="onFilterApplied()"
          type="button"
          title="Apply"
          i18n-title
        >
          Apply
        </button>
        <button
          class="button is-outlined ml-2"
          data-e2e="button-clear-filter"
          i18n
          (click)="clear()"
          (keydown.enter)="clear()"
          type="button"
          title="Clear"
          i18n-title
        >
          Clear
        </button>
      </div>
    </div>
  </div>
</div>
