import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDigitDecimals',
})
export class FormatDigitDecimals implements PipeTransform {
  transform(value: string | number, fractionDigits: number = 2): string {
    const formattedValue = Number(value);
    if (isNaN(formattedValue)) {
      return `${value}`;
    }
    fractionDigits = Math.min(Math.max(fractionDigits, 0), 100);
    return formattedValue.toFixed(fractionDigits);
  }
}
