import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EntityStatus, IOrganisation, IUser } from '@portal/entity-services/interfaces';
import {
  IQueryParams,
  IResultsWithCount,
  PageSize,
  SortOrder,
  ITableConfig,
  QueryParamsService,
  ISort,
} from '@portal/shared/ui/table';
import { ISearchBoxValue, ISearchCriteria } from '@portal/shared/ui/filter';
import { EntityTypes } from '@portal/entity-services/organisations/src/lib/components/form/entity-type.list';
import { OrganisationService } from '@portal/entity-services/organisations';
import { UserService } from '@portal/entity-services/users/src/lib/services/user.service';
import cloneDeep from 'lodash-es/cloneDeep';
import { PaginationSortHandler } from '@portal/shared/helpers';
import { SearchSelector as SearchSelectorOrg } from '@portal/entity-services/organisations/src/lib/enums/search-selector.enum';
import { EntityType } from '@portal/entity-services/interfaces/src/lib/entities/enums/entity-type.enum';
import { SearchOperator } from '@portal/shared/ui/filter';
import { CompareService } from '@portal/shared/helpers/src/lib/compare/compare.service';
import { catchError, distinct, switchMap, filter, take, toArray } from 'rxjs/operators';
import { from, Observable, of, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { RoleGuardService } from '@portal/shared/auth/authorization';

declare const $localize;

@Component({
  selector: 'portal-list-organization-group',
  templateUrl: './list-organization-group.component.html',
})
export class ListOrganizationGroupComponent extends PaginationSortHandler implements OnInit {
  @Input() labelUsers: string;
  @Input() labelGroups: string;
  @Output()
  itemToggled = new EventEmitter<boolean>();

  loading$: Observable<boolean>;
  organisations$: Observable<IOrganisation[]>;
  organisations: IOrganisation[];
  range: string;
  filter: IQueryParams;
  totalCount: number;
  orgSubscription: Subscription;
  orgsWithCount$: Observable<IResultsWithCount<IOrganisation>>;
  canGoToDetail: boolean;
  selectedSearchSelector: Subject<string> = new Subject<string>();
  searchTerm: Subject<string> = new Subject<string>();

  initialFilter: IQueryParams = {
    start: 0,
    limit: PageSize.Size10,
    order: SortOrder.Asc,
    populateParentEntity: true,
    searchCriteria: new Map<string, ISearchCriteria>([
      [
        SearchSelectorOrg.EntityType,
        {
          selector: SearchSelectorOrg.EntityType,
          argument: EntityType.GROUP,
          operator: SearchOperator.Equal,
        },
      ],
    ]),
    status: [EntityStatus.Active_Inactive],
  };

  filterTouched = false;
  tableConfig: ITableConfig = {
    columns: [
      { key: 'name', label: $localize`Group Name`, isSortable: true },
      {
        key: 'user',
        label: $localize`Assigned User Name`,
        isRenderFunction: true,
        renderFunction: (org: IOrganisation) => {
          return this.formatColumnUser(org);
        },
      },
    ],
    rowIdKey: 'entityUid',
    rowLabelKey: 'name',
  };

  switchOn = true;
  constructor(
    private entityTypeList: EntityTypes,
    private organisationService: OrganisationService,
    private compareService: CompareService,
    private userService: UserService,
    private router: Router,
    private title: Title,
    private liveAnnouncer: LiveAnnouncer,
    private roleGuard: RoleGuardService,
    private route: ActivatedRoute,
  ) {
    super();
    this.setInitialFilterState();
    this.loading$ = organisationService.loading$;
    this.organisationService.filter$.pipe(take(1)).subscribe((data: any) => {
      if (!data) {
        this.setInitialFilterState();
      } else {
        this.filter = data.filter;
        this.updateFilterTouchedFlag();
      }
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (!event.url.includes('/users')) {
          this.setInitialFilterState();
          this.organisationService.setFilter({ filter: this.filter });
        }
      });
  }

  ngOnInit(): void {
    this.title.setTitle($localize`:Page title:Organization groups`);
    this.liveAnnouncer.announce($localize`Navigated to ${this.title.getTitle()}`);
    this.canGoToDetail = this.roleGuard.canAccess(this.route.snapshot, 'read');
    this.getOrganisationGroups();
  }

  formatColumnUser(organisation: IOrganisation): string {
    if (organisation?.users?.length >= 3) {
      const usersArray = [];
      organisation.users.forEach((user, index) => {
        // Copy max 3 names
        if (index < 3) {
          usersArray.push(user);
        }
      });
      // Display max 3 names, if there are more than 3 - at the end 3 points
      return usersArray
        .map((user) => user.name)
        .join(',')
        .concat(' ... ');
    } else {
      return organisation?.users?.map((user) => user.name).join(',') || '';
    }
  }

  toggleOnOff(): void {
    this.switchOn = !this.switchOn;
    if (this.switchOn) {
      this.itemToggled.emit(true);
    } else {
      this.itemToggled.emit(false);
    }
  }

  setInitialFilterState(): void {
    this.filter = cloneDeep(this.initialFilter);
    this.filterTouched = false;
  }

  setSearchCriteriaFilter(orgFilter: ISearchBoxValue): void {
    if (orgFilter) {
      this.filter.searchCriteria.set(orgFilter.key, {
        argument: orgFilter.value,
        selector: orgFilter.key,
        operator: SearchOperator.Equal,
      });
      this.updateFilterTouchedFlag();
    }

    this.filter.start = 0;
    this.filter.limit = PageSize.Size10;
  }

  onOrgFiltered(orgFilter: ISearchBoxValue): void {
    this.resetPaginationForFilter();
    this.setSearchCriteriaFilter(orgFilter);
    this.organisationService.setFilter({ filter: this.filter });
    this.getOrganisationGroups();
  }

  onOrganisationGroupsByName(orgFilter: ISearchBoxValue): void {
    this.resetPaginationForFilter();
    this.setSearchCriteriaFilter(orgFilter);
    this.organisationService.setFilter({ filter: this.filter });
    this.getOrganisationGroups();
  }

  onOrgFilterReset(): void {
    this.searchTerm.next('');
    this.setInitialFilterState();
    this.organisationService.setFilter({ filter: this.filter });
    this.getOrganisationGroups();
  }

  onSortChanged(sort: ISort): void {
    super.onSorting(sort.key, sort.order);
  }

  findUsersByEntityId(): void {
    this.organisations?.forEach((org) => {
      const entityId = org.entityUid;
      this.userService
        .getUsersByEntity(entityId)
        .pipe(
          switchMap(from),
          distinct((user: IUser) => user.userUid),
          toArray(),
        )
        .subscribe(
          (users) => {
            org.users = users;
          },
          (error: Object) => {
            catchError((_) => of(`Bad Promise: ${error}`));
          },
        );
    });
  }

  getOrganisationGroups(): void {
    if (this.orgSubscription) {
      this.orgSubscription.unsubscribe();
    }

    this.orgsWithCount$ = this.organisationService.organizationGroupsWithCount(this.filter);
    this.orgSubscription = this.orgsWithCount$.subscribe(
      (result: IResultsWithCount<IOrganisation>) => {
        this.organisations = result.results;
        this.totalCount = result.count;
        this.findUsersByEntityId();
        this.range = QueryParamsService.getRange<IOrganisation>(this.filter, this.organisations);
      },
    );
  }

  updateFilterTouchedFlag(): void {
    this.filterTouched = !this.compareService.mapsEqual(
      this.initialFilter.searchCriteria,
      this.filter.searchCriteria,
    );
  }

  protected afterPaginate(): void {
    this.getOrganisationGroups();
  }

  protected afterSorting(): void {
    this.getOrganisationGroups();
  }
}
