<div class="main-icon">
  <span *restrictPermission>App Icon</span>
  <figure class="is-relative logo-container image is-square">
    <img [src]="image" class="" alt="" />
    <div
      *restrictPermission="['uploadFile']"
      class="btn change-icon-btn has-background-grey is-fullwidth has-text-white is-clickable"
      (click)="changeIcon()"
    >
      <i class="fas fa-exchange-alt is-size-5"></i>
      <span class="is-size-7">Click to change app icon</span>
    </div>
  </figure>
  <p class="has-text-gray is-size-7 has-text-centered">
    Supported formats PNG, SVG, or JPG.<br />
    Image should be at least 300x300 pixels
  </p>
</div>
