/* eslint-disable @typescript-eslint/naming-convention */

import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IActionMapping,
  ITreeOptions,
  ITreeState,
  KEYS,
  TreeComponent,
  TreeModel,
  TreeNode,
} from '@circlon/angular-tree-component';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { BehaviorSubject, EMPTY, forkJoin, Observable, Subject, throwError } from 'rxjs';
import cloneDeep from 'lodash-es/cloneDeep';
import forEach from 'lodash-es/forEach';

import { FilterAutoCompleteInputComponent } from '../autocomplete/search-autocomplete-input.component';
import { FilterContentItem } from '@portal/shared/ui/filter/src/lib/content/item';
import { IEntity, IEntityAncestors, IOrganisation } from '@portal/entity-services/interfaces';
import { AuthenticationService } from '@portal/shared/auth/authentication/src/lib/authentication.service';
import { OrganisationService } from '@portal/entity-services/organisations/src/lib/services/organisation.service';
import { Direction, LanguageHandle } from '@portal/shared/languages';
import { FILTER_CONTENT_ITEM } from '@portal/shared/ui/filter/src/lib/content/token';
import { ISelectInput } from '../box/interfaces/select-input.interface';
import { SearchSelector } from '../search/enums/search-selector.enum';
import { IFilterToApply } from '../search/interfaces/filter-to-apply.interface';
import { ISearchCriteria } from '../search/interfaces/search-criteria.interface';
import { SearchOperator } from '../search/enums/search-operator.enum';
import { FilterSubMenu } from '../enums/filter-sub-menu.enum';
import { ErrorService } from '@portal/shared/ui/form';

declare const $localize;

type SelectedNode = IEntity & { children: SelectedNode[] };

@UntilDestroy()
@Component({
  selector: 'portal-hierarchy-single-filter',
  templateUrl: './hierarchy-single-filter.component.html',
  styleUrls: ['./hierarchy-single-filter.component.scss'],
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: HierarchySingleFilterComponent,
    },
  ],
})
export class HierarchySingleFilterComponent extends FilterContentItem implements OnInit, OnChanges {
  static initialDebounce = 1000;
  static reachedConstant = 19;

  @Input()
  set searchSelector(val: SearchSelector) {
    this._searchSelector = val;
  }

  @Input()
  set filterName(val: string) {
    this.entityText = val;
  }

  @Input()
  isNewDesignFilter = false;

  get searchSelector(): SearchSelector {
    return this._searchSelector;
  }

  set entityText(val: string) {
    this._entityText = val;
  }

  get entityText(): string {
    return this._entityText;
  }

  @Input() entityType = '';
  @Input() entityRemoved$ = new Subject<ISelectInput>();
  @Input() selectedEntity: Map<string, ISearchCriteria> = new Map(); // Pre-selected, from store
  @Input() selectLimit = 0;
  @Input() displayFromFilter: boolean;
  @Input() displaySubOrganisationCheckBox: boolean;
  @Input() closeSubmenus$ = new Subject<boolean>();

  @Output() filterApplied: EventEmitter<IFilterToApply> = new EventEmitter();
  @Output() filterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();
  @Output() staticFilterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();
  @Output() selectedOrganisation: EventEmitter<IEntity> = new EventEmitter();
  @Output() organisationTextName: EventEmitter<any> = new EventEmitter();
  @Output() listAllEntities: EventEmitter<ISelectInput[]> = new EventEmitter();
  @Output() updateCheckBox: EventEmitter<any> = new EventEmitter();
  @Output() buttonFocused: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(TreeComponent) private tree: TreeComponent;
  @ViewChild(forwardRef(() => FilterAutoCompleteInputComponent))
  private searchInput: FilterAutoCompleteInputComponent;

  itemToggled: EventEmitter<number> = new EventEmitter();

  staticListUpdateSubject$ = new Subject<IFilterToApply>();
  loading$: Observable<boolean>;
  loadingMore$ = new BehaviorSubject(0);
  loadingNewPortion$: Observable<boolean>;
  hierarchyViewLoading$: Observable<boolean>;
  searchTerm$ = new Subject<string>();
  search$: Observable<IOrganisation[]>;
  nextPage$ = new Subject<number>();
  pagination$: Observable<IOrganisation[]>;
  showSelectedEntity: Map<string, ISelectInput> = new Map();
  localPreSelectedEntity: Map<string, ISelectInput> = new Map();

  entitiesAll: IEntity[] = [];
  entities: IEntity[] = [];
  allEntities: ISelectInput[];
  selectedNode: SelectedNode;
  searchedText = '';
  title = '';
  isOpen = false;
  hasFilteredText = false;
  isFirstLoad = true;
  debounce = 1000;
  treeState: ITreeState;
  hierarchy = SearchSelector.Hierarchy;
  isCheckedDefault = true;
  treeActionsMapping: IActionMapping = {
    keys: {
      [KEYS.ENTER]: () => {
        this.onFilterApplied();
      },
    },
  };
  options: ITreeOptions = {
    getChildren: (node: TreeNode) => {
      return this.organisationService
        .getHierarchyView(node.id)
        .pipe(
          map((hierarchyList) =>
            hierarchyList.filter((item) => item.entityUid !== this.selectedNode?.entityUid),
          ),
          map((value) =>
            node.id === this.authService.entityUid
              ? [...(this.selectedNode ? [this.selectedNode] : []), ...value]
              : value,
          ),
        )
        .toPromise();
    },
    actionMapping: this.treeActionsMapping,
    useCheckbox: false,
    idField: 'entityUid',
    displayField: 'name',
    rtl:
      this.languageHandle.getCurrentObjectLanguage(this.languageHandle.getBuildLanguage())
        .direction === Direction.RTL,
  };

  private selectedPath: string[];
  private start: number;
  private stopLoading: boolean;
  private loadingStatus = false;
  private _searchSelector: any = SearchSelector.EntityUid;
  private _entityText = $localize`Organisations`;
  private readonly entityUidSelector = 'entityUid';
  private readonly entityNameSelector = 'entityName';
  private currentSelectedEntity: IEntity;

  constructor(
    private organisationService: OrganisationService,
    private authService: AuthenticationService,
    private languageHandle: LanguageHandle,
  ) {
    super();
    this.loading$ = organisationService.loading$.pipe(shareReplay(1));
    this.hierarchyViewLoading$ = organisationService.hierarchyViewLoading$.pipe(shareReplay(1));
    this.loadingNewPortion$ = organisationService.loadingNewPortion$.pipe(
      tap((value) => (this.loadingStatus = value)),
    );
    this.start = 0;
  }

  ngOnInit(): void {
    this.getEntityFromStorage();
    this.staticListUpdateSubject$
      .pipe(debounceTime(100))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.staticFilterUpdated.emit({
          key: this.searchSelector,
          value: Array.from(this.localPreSelectedEntity.values()),
        });
      });

    this.updateText();
    this.startListeningForSearch();
    this.startListeningForPageChange();

    this.itemToggled.pipe(untilDestroyed(this)).subscribe(() => (this.start = 0));

    this.entityRemoved$.pipe(untilDestroyed(this)).subscribe((entityToRemove: ISelectInput) => {
      let selectedEntity;
      if (!this.localPreSelectedEntity) {
        this.removeAllEntities();
        this.start = 0;
        selectedEntity = this.getEntityById(entityToRemove.id);
      } else {
        selectedEntity = this.localPreSelectedEntity.get(entityToRemove.id);
      }
      if (selectedEntity) {
        this.onEntityRemoved(entityToRemove);
        this.onFilterApplied();
        this.itemToggled.emit();
        this.isOpen = false;
      }
    });

    this.closeSubmenus$.subscribe((close: boolean) => {
      if (close && this.isOpen) {
        this.itemToggled.emit();
      }
    });
  }

  getEntityFromStorage(): void {
    const name = localStorage.getItem(this.entityNameSelector);
    const entityUid = localStorage.getItem(this.entityUidSelector);
    this.currentSelectedEntity = {
      entityUid,
      name,
    };
    if (name && entityUid) {
      this.onActivateItem({
        name,
        entityUid,
      });
      this.setSelectedEntities({
        argument: entityUid,
      });
      this.onFilterApplied();
      this.itemToggled.emit();
      this.isOpen = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedEntity = changes.selectedEntity?.currentValue?.get(SearchSelector.EntityUid);
    if (selectedEntity) {
      this.setSelectedEntities(selectedEntity);
    }

    this.updateText();
  }

  onScrolled(event: {
    target: { offsetHeight: number; scrollTop: number; scrollHeight: number };
  }): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight &&
      !this.stopLoading &&
      !this.loadingStatus
    ) {
      this.nextPage$.next(this.start);
    }
  }

  openContent(): void {
    this.itemToggled.emit();
    this.isOpen = true;
  }

  onLoadMore(node: TreeNode): void {
    this.loadingMore$.next(node.id);
    this.organisationService
      .getHierarchyView(
        node.data.entityUid,
        node.data.children.length === 1 ? 0 : node.data.children.length + 1,
      )
      .subscribe((value) => {
        node.data.children.push(
          ...(this.selectedNode?.entityUid
            ? value.filter((value1) => !this.selectedPath.includes(value1.entityUid))
            : value),
        );
        this.loadingMore$.next(0);
        this.tree.treeModel.update();
      });
  }

  onActivateEntity(selectedEntity: IEntity): void {
    this.currentSelectedEntity = selectedEntity;
  }

  onDeactivateEntity(): void {
    this.currentSelectedEntity = null;
  }

  onActivateItem(selectedEntity: IEntity): void {
    if (!selectedEntity) {
      return;
    }
    this.localPreSelectedEntity.clear();
    localStorage.setItem(this.entityUidSelector, selectedEntity?.entityUid);
    localStorage.setItem(this.entityNameSelector, selectedEntity?.name);
    this.localPreSelectedEntity.set(selectedEntity?.entityUid, {
      id: selectedEntity?.entityUid,
      text: selectedEntity?.name,
    });
  }

  onDeactivateItem(): void {
    this.currentSelectedEntity = null;
    if (this.localPreSelectedEntity.size) {
      localStorage.removeItem(this.entityUidSelector);
      localStorage.removeItem(this.entityNameSelector);
      this.localPreSelectedEntity.clear();
    }
  }

  onActivateOrDeactivateItem(selectedEntity?: IEntity): void {
    if (selectedEntity) {
      this.onActivateItem(selectedEntity);
    } else {
      this.onDeactivateItem();
    }
  }

  onSearch(searchValue = ''): void {
    this.searchedText = searchValue;
    this.start = 0;
    this.searchTerm$.next(searchValue);
  }

  onFilterApplied(isCalledFromHtml?: boolean): void {
    if (isCalledFromHtml) {
      this.onActivateItem(this.currentSelectedEntity);
    }
    if (!this.currentSelectedEntity) {
      this.onDeactivateItem();
    }
    this.updateEntities();
    this.start = 0;

    const values: string[] = this.getValues();
    if (values.length) {
      this.filterApplied.emit({
        selector: this.searchSelector,
        key: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.localPreSelectedEntity.values()),
        argument: `${Array.from(values).join(',')}`,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        value: Array.from(this.localPreSelectedEntity.values()),
      });
    } else {
      this.filterApplied.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        toRemove: true,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        toRemove: true,
      });
    }

    this.updateText();
    this.itemToggled.emit();
    this.isOpen = false;
  }

  onKeyDownSpace(): void {
    this.isOpen = !this.isOpen;
  }

  getList(): Observable<IOrganisation[]> {
    this.debounce = 0;
    this.searchedText = '';
    this.searchInput.reset();
    this.searchTerm$.next(this.searchedText);
    return null;
  }

  getValues(): string[] {
    const values = new Set<string>();
    Array.from(this.localPreSelectedEntity.values()).forEach((val: ISelectInput) => {
      values.add(val.id);
    });
    return Array.from(values);
  }

  clear(): void {
    this.removeAllEntities();
  }

  removeAllEntities(): void {
    this.localPreSelectedEntity.clear();
    this.currentSelectedEntity = null;
    this.updateSelectedEntities();
    this.updateText();
  }

  onCheckboxToggle(): void {
    this.updateCheckBox.emit();
  }

  onFocusIn(event: FocusEvent, treeModel: TreeModel): void {
    const treeElement = event.currentTarget as HTMLElement;

    if (event.target === treeElement) {
      treeModel.setFocus(true);
      if (!treeModel.focusedNode) {
        treeModel.getFirstRoot()?.focus();
      }
    } else {
      // prevent inputs inside the tree stealing the focus
      treeElement.focus();
    }
  }

  onFocusOut(event: FocusEvent, treeModel: TreeModel): void {
    if (event.target === event.currentTarget) {
      treeModel.setFocus(false);
    }
  }

  private onEntityRemoved(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntity.delete(selectedEntity.id);
    this.currentSelectedEntity = null;
  }

  private startListeningForPageChange(): void {
    this.pagination$ = this.paginate(this.nextPage$);
    this.pagination$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.entities = [...this.entities, ...data];
      this.entitiesAll = [...this.entitiesAll, ...data];
      if (this.localPreSelectedEntity.size) {
        this.updateEntities();
      }
    });
  }

  private paginate(terms: Observable<number>): Observable<IOrganisation[]> {
    return terms.pipe(debounceTime(500)).pipe(
      switchMap((page: number) => {
        this.start = page + 20;
        return this.getEntitiesByName(this.searchedText, true);
      }),
    );
  }

  private getEntitiesByName(term: string, noLoader?: boolean): Observable<IOrganisation[]> {
    const reachedTheEnd = (organisation: IOrganisation[]): boolean => {
      this.hasFilteredText = Boolean(term);
      return (this.stopLoading =
        organisation.length < HierarchySingleFilterComponent.reachedConstant);
    };

    return term
      ? this.organisationService
          .getLightWeightWithQuery(
            {
              name: term,
            },
            noLoader,
            this.start,
          )
          .pipe(tap(reachedTheEnd))
      : this.organisationService
          .getEntity(this.start, noLoader, this.authService.entityUid)
          .pipe(tap(reachedTheEnd));
  }

  private setSelectedEntities(selectedEntity: ISearchCriteria): void {
    // Set selectedFilters
    const values = new Set<string>(
      selectedEntity.argument.replace('(', '').replace(')', '').split(','),
    );
    const selectedForState = {};
    this.localPreSelectedEntity.clear();
    forEach(Array.from(values), (argument: string) => {
      selectedForState[argument] = true;
      this.localPreSelectedEntity.set(argument, {
        id: argument,
        text: '',
      });
    });

    this.treeState = this.treeState
      ? {
          ...this.treeState,
          activeNodeIds: selectedForState,
        }
      : { activeNodeIds: selectedForState };

    this.updateSelectedEntities();
  }

  private startListeningForSearch(): void {
    this.search$ = this.search(this.searchTerm$);
    this.search$.pipe(untilDestroyed(this)).subscribe((entities: IEntity[]) => {
      if (this.selectedNode && entities[0].hasChildren) {
        this.expandTree();
      }
      this.entities = entities;
      this.entitiesAll = entities;
      if (this.localPreSelectedEntity.size) {
        this.updateEntities();
      }
    });
  }

  private updateSelectedEntities(): void {
    this.showSelectedEntity = cloneDeep(this.localPreSelectedEntity);
    let entitiesToFetch: string;
    Array.from(this.showSelectedEntity.values()).forEach((val: ISelectInput) => {
      if (!val.text) {
        entitiesToFetch = val.id;
      }
    });

    if (entitiesToFetch) {
      this.getEntityById(entitiesToFetch)
        .pipe(
          untilDestroyed(this),
          catchError((err) => {
            const httpStatusCode = ErrorService.getStatusCode(err);
            switch (httpStatusCode) {
              case 403:
              case 404:
                this.onDeactivateItem();
                this.updateSelectedEntities();

                this.onFilterApplied();
                this.itemToggled.emit();
                this.isOpen = false;

                this.filterUpdated.emit();
                this.updateText();
                return EMPTY;
              default:
                return throwError(err);
            }
          }),
        )
        .subscribe((org: IOrganisation) => {
          this.localPreSelectedEntity.set(org.entityUid, {
            id: org.entityUid,
            text: org.name,
          });

          // Just we update if not we lose the results
          // in the filters after to come back from details
          this.onFilterApplied();
          this.updateText();
        });
    } else {
      this.treeState = {
        ...this.treeState,
        activeNodeIds: null,
      };
    }
  }

  private search(terms: Observable<string>): Observable<IOrganisation[]> {
    return terms.pipe(distinctUntilChanged(), debounceTime(this.debounce)).pipe(
      switchMap((term: string) => {
        // Always reset debounce time
        this.debounce = HierarchySingleFilterComponent.initialDebounce;
        return this.getEntitiesByName(term);
      }),
    );
  }

  private getEntityById(id: string): Observable<IOrganisation> {
    return this.organisationService.getByKey(id, true);
  }

  private updateEntities(): void {
    if (this.selectLimit > 0) {
      this.localPreSelectedEntity = new Map(
        [...this.localPreSelectedEntity.entries()].slice(0, this.selectLimit),
      );
    }

    this.showSelectedEntity = cloneDeep(this.localPreSelectedEntity);
    const id = Array.from(this.showSelectedEntity.values())[0]?.id;
    if (id && id !== this.authService.entityUid) {
      this.organisationService.getAncestors(id).subscribe((value) => {
        this.flatten(value);
      });
    }
    const activeNodeIds = this.treeState?.activeNodeIds;
    if (activeNodeIds && id) {
      activeNodeIds[id] = true;
    }
    this.tree?.treeModel?.setState(this.treeState);
    this.entities = this.entitiesAll;

    this.allEntities = this.entitiesAll?.map((entity) => {
      return {
        id: entity.entityUid,
        text: entity.name,
      };
    });

    if (this.showSelectedEntity.size === 0) {
      this.displayFromFilter = false;
    } else {
      this.displayFromFilter = true;
    }

    this.listAllEntities.emit(this.allEntities);
    this.organisationTextName.emit(FilterSubMenu.Organisation);
  }

  private updateText(): void {
    const values: string[] = this.getValues();
    // important not to change this line
    const text = this.entityText;
    if (values.length) {
      this.title = `${text} (+${values.length})`;
    } else {
      this.title = text;
    }
  }

  private async flatten(data: IEntityAncestors): Promise<any> {
    const result = [];
    while (data) {
      result.push(data);
      if (data?.parent?.entityUid === this.authService.entityUid) {
        break;
      }
      data = data.parent;
    }
    result.map((value) => {
      delete value.parent;
      return value;
    });
    this.selectedPath = result.map((value) => value.entityUid);
    const response = await forkJoin(
      result.map((val) =>
        this.organisationService.getTotalCount({ parentId: val[SearchSelector.EntityUid] }),
      ),
    )
      .pipe(
        map((val) =>
          val.map((totalCountData, index) => ({
            count: totalCountData.count,
            ...result[index],
            hasChildren: totalCountData.count > 0,
            isExpanded: index !== 0,
          })),
        ),
      )
      .toPromise();
    const length = result.length;
    const selectedNode = response[length - 1];
    this.insertChild(selectedNode, response);
    this.selectedNode = selectedNode;

    this.expandTree();
  }

  private insertChild(
    selectedNode: SelectedNode,
    array: SelectedNode[],
    index: number = array.length - 2,
  ): void {
    if (index >= 0 && selectedNode) {
      selectedNode.children = [array[index]];
      this.insertChild(selectedNode.children[0], array, index - 1);
    }
  }

  private expandTree(): void {
    const expandedNodeIds = this.entities[0]?.hasChildren
      ? { [this.entities[0].entityUid]: true }
      : {};
    this.selectedPath.forEach((value, index) => {
      if (!index) {
        return;
      }
      expandedNodeIds[value] = true;
      const someNode = this.tree?.treeModel?.getNodeById(value);
      someNode?.expand();
    });

    this.treeState = {
      ...this.treeState,
      expandedNodeIds,
    };
  }
}
