import {
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Directive,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FilterCheckboxComponent,
  SearchSelector,
  SwitchToggleComponent,
} from '@portal/shared/ui/filter/src';
import { ISelectInput } from '@portal/shared/ui/filter/src/lib/box/interfaces/select-input.interface';
import { FilterContentItem } from '@portal/shared/ui/filter/src/lib/content/item';
import { SearchOperator } from '@portal/shared/ui/filter/src/lib/search/enums/search-operator.enum';
import { IFilterToApply } from '@portal/shared/ui/filter/src/lib/search/interfaces/filter-to-apply.interface';
import { ISearchCriteria } from '@portal/shared/ui/filter/src/lib/search/interfaces/search-criteria.interface';
import { cloneDeep, filter, forEach } from 'lodash';
import { of, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
declare const $localize;

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FilterStaticBase extends FilterContentItem implements OnInit, OnChanges, OnDestroy {
  @Output()
  filterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();

  @Output()
  filterPreSelectedUpdated: EventEmitter<IFilterToApply> = new EventEmitter();

  @Output()
  staticFilterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();

  @Output()
  filterApplied: EventEmitter<IFilterToApply> = new EventEmitter();

  @Output()
  listAllEntities: EventEmitter<ISelectInput[]> = new EventEmitter();

  @Input()
  displayFromFilter: boolean;

  @Input()
  selectedEntities: Map<string, ISearchCriteria>;

  @Input()
  criteriaMapPreSelected: Map<string, ISearchCriteria>;

  @Input()
  entityRemoved$ = new Subject<ISelectInput>();

  @Input()
  closeSubmenus$ = new Subject<boolean>();

  @Input()
  filterSubMenuSelected$ = new Subject<string>();

  @Input()
  selectedElementsFilters$ = new Subject<number>();

  @ViewChild('preSelectedEntities') preSelectedEntities: FilterCheckboxComponent;

  @ViewChild('switchToggle') switchToggle: SwitchToggleComponent;

  searchSelector: string;

  itemToggled: EventEmitter<number> = new EventEmitter();
  isOpen = false;

  localPreSelectedEntities: Map<string, ISelectInput> = new Map();

  showSelectedEntities: Map<string, ISelectInput> = new Map();

  entities: ISelectInput[];

  searchedText: string;

  staticListUpdateSubject$ = new Subject<IFilterToApply>();
  staticListUpdateSub: Subscription;

  backToMenuSubject$ = new Subject<void>();
  backToMenuSub: Subscription;

  updateEntities$ = new Subject<void>();
  updateCounter$ = new Subject<number>();
  subMenuComponentSelected$ = new Subject<string>();

  labelSelectAllChannels = $localize`Select all channels`;
  labelSelectAllCurrencies = $localize`Select all currencies`;
  labelSelectAllProducts = $localize`Select all products`;
  labelSelectAllTypes = $localize`Select all types`;
  labelSelectAllTransStatus = $localize`Select all statuses`;
  labelSelectAllWallets = $localize`Select all wallets`;
  labelSelectAllProcessors = $localize`Select all processors`;
  labelSelectAllGroups = $localize`Select all groups`;
  labelSelectAllModels = $localize`Select all models`;
  labelSelectAllOrigin = $localize`Select all origins`;
  labelSelectAllFundingSource = $localize`Select all funding sources`;

  private allEntities: ISelectInput[];

  constructor(selector: string, list: ISelectInput[]) {
    super();
    this.searchSelector = selector;
    this.entities = list;
    this.allEntities = cloneDeep(this.entities);
  }

  ngOnInit(): void {
    if (!this.selectedEntities) {
      this.selectedEntities = new Map();
    }
    const entities: ISearchCriteria = cloneDeep(this.selectedEntities.get(this.searchSelector));

    this.staticListUpdateSub = this.staticListUpdateSubject$
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.staticFilterUpdated.emit({
          key: this.searchSelector,
          selector: this.searchSelector,
          value: Array.from(this.localPreSelectedEntities.values()),
        });
      });

    this.backToMenuSub = this.backToMenuSubject$.pipe(debounceTime(100)).subscribe(() => {
      const element = document.getElementById('add-filter');
      if (element) {
        element.click();
      }
    });

    if (entities) {
      this.setSelectedEntities(entities);
    }

    this.entityRemoved$.subscribe((entityToRemove: ISelectInput) => {
      const selectedEntity = this.localPreSelectedEntities.get(entityToRemove.id);
      if (selectedEntity) {
        this.onEntityRemoved(entityToRemove);
        this.onFilterApplied();
        this.itemToggled.emit();
      }
    });

    this.closeSubmenus$.subscribe((close: boolean) => {
      if (close && this.isOpen) {
        this.itemToggled.emit();
      }
    });

    this.filterSubMenuSelected$.subscribe((submenu) => {
      this.subMenuComponentSelected$.next(submenu);
    });

    this.selectedElementsFilters$.subscribe((selectedNumbers) => {
      this.updateCounter$.next(selectedNumbers);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedEntities && !this.selectedEntities.has(this.searchSelector)) {
      this.localPreSelectedEntities.clear();
    }
    if (this.switchToggle) {
      this.updateSwitchToggleState();
    }
  }

  ngOnDestroy(): void {
    if (this.staticListUpdateSub) {
      this.staticListUpdateSub.unsubscribe();
    }

    if (this.backToMenuSub) {
      this.backToMenuSub.unsubscribe();
    }
  }

  setSelectedEntities(value: ISearchCriteria): void {
    // Set selectedFilters
    let valueArguments = value.argument?.replace('(', '').replace(')', '').split(',');
    valueArguments = [...new Set(valueArguments)];
    const values = new Set<string>(valueArguments);
    forEach(Array.from(values), (argument: string) => {
      const textInput = this.entities.find((entity: ISelectInput) => {
        return entity.id === argument;
      });
      this.localPreSelectedEntities.set(argument, {
        id: argument,
        text: textInput ? textInput.text : '',
      });
    });

    this.staticListUpdateSubject$.next();

    this.updateEntities();
  }

  onSearch(searchValue: string): void {
    if (!searchValue) {
      this.entities = this.allEntities;
      this.searchedText = null;
    } else {
      this.searchedText = searchValue;
      this.entities = filter(this.allEntities, (entity: ISelectInput) => {
        return entity.text.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    this.updateEntities();
  }

  updateEntities(): void {
    this.showSelectedEntities = cloneDeep(this.localPreSelectedEntities);
    const entitiesToExclude = Array.from(this.localPreSelectedEntities.keys());
    const filterEntity = this.searchedText ? this.entities : this.allEntities;
    this.entities = filter(filterEntity, (entity: ISelectInput) => {
      return !entitiesToExclude.includes(entity.id);
    });
  }

  onEntityRemoved(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.delete(selectedEntity.id);
    this.updateSwitchToggleState();
    this.filterPreSelectedEntitiesUpdated();
    this.isOpen = true;
  }

  onEntityAdded(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.set(selectedEntity.id, selectedEntity);
    this.updateSwitchToggleState();
    this.filterPreSelectedEntitiesUpdated();
  }

  getArgumentArray(criteriaMap: Map<string, ISearchCriteria>, selector): string[] {
    if (criteriaMap.has(selector)) {
      return this.getArgumentElementsAsArray(criteriaMap, selector);
    } else {
      return [''];
    }
  }

  getArgumentElementsAsArray(
    criteriaMap: Map<string, ISearchCriteria>,
    searchSelectorElem: SearchSelector,
  ): string[] {
    return (
      criteriaMap
        .get(searchSelectorElem)
        ?.argument?.replace('(', '')
        ?.replace(')', '')
        .split(',') || []
    );
  }

  setLocalPreSelectedEntities(argument: string, text: string): void {
    this.localPreSelectedEntities.set(argument, {
      id: argument,
      text: text,
    });
  }

  filterPreSelectedEntitiesUpdated(): void {
    this.updateEntities();

    const values: string[] = this.getValues();
    if (values.length) {
      this.filterPreSelectedUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.localPreSelectedEntities.values()),
        argument: `(${Array.from(values).join(',')})`,
      });
    } else {
      this.filterPreSelectedUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        toRemove: true,
      });
    }
  }

  onFilterApplied(): void {
    this.updateEntities();
    const values: string[] = this.getValues();
    if (values.length) {
      this.filterUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.localPreSelectedEntities.values()),
        argument: `(${Array.from(values).join(',')})`,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        value: Array.from(this.localPreSelectedEntities.values()),
      });
    } else {
      this.filterUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        toRemove: true,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        selector: this.searchSelector,
        toRemove: true,
      });
    }

    this.searchedText = '';
    this.filterApplied.emit();
    this.itemToggled.emit();
    this.listAllEntities.emit(this.allEntities);
    this.updateSwitchToggleState();
  }

  getList(): Observable<ISelectInput[]> {
    return of(this.entities);
  }
  getValues(): string[] {
    const values = new Set<string>();
    Array.from(this.localPreSelectedEntities.values()).forEach((val: ISelectInput) => {
      values.add(val.id);
    });

    return Array.from(values);
  }

  backToMenu(): void {
    this.itemToggled.emit();
    this.backToMenuSubject$.next();
  }

  syncLocalPreSelectedEntities(): void {
    this.updateEntities$.next();
  }

  openContent(): void {
    this.itemToggled.emit();
    this.onSearch('');

    if (this.displayFromFilter) {
      this.isOpen = this.displayFromFilter;
    }

    this.updateSwitchToggleState();
    this.syncLocalPreSelectedEntities();

    if (Array.from(this.localPreSelectedEntities.values())?.length) {
      this.updateEntities();
    }
  }

  onFilterCleared(): void {
    this.localPreSelectedEntities.clear();
    this.showSelectedEntities.clear();
    this.searchedText = '';
    this.filterPreSelectedEntitiesUpdated();
    this.switchToggle?.reset();
  }

  onSelectAllSwitchToggle(): void {
    if (this.switchToggle.switchOn) {
      Array.from(this.entities).forEach((entityToSelect) => {
        this.localPreSelectedEntities.set(entityToSelect.id, entityToSelect);
      });
      if (this.showSelectedEntities.size > 0) {
        this.showSelectedEntities.forEach((selectedCheckbox) => {
          if (!this.preSelectedEntities.isEntitySelected(selectedCheckbox.id)) {
            this.preSelectedEntities.text = selectedCheckbox.text;
            this.preSelectedEntities.onCheckboxChecked(selectedCheckbox.id);
          }
        });
      }
    } else if (this.localPreSelectedEntities.size > 0) {
      this.localPreSelectedEntities.clear();
    }
    this.filterPreSelectedEntitiesUpdated();
  }

  updateSwitchToggleState(): void {
    if (this.switchToggle) {
      this.switchToggle.switchOn = this.localPreSelectedEntities.size === this.allEntities?.length;
    }
  }

  setAllEntities(list: ISelectInput[]): void {
    this.entities = list;
    this.allEntities = cloneDeep(this.entities);
  }
}
