<div class="dropdown" [ngClass]="{ 'is-active': isActive }">
  <div class="dropdown-trigger" (portalClickOutside)="isActive = false">
    <button type="button" class="button is-chip" (click)="isActive = !isActive">
      <span>
        {{ defaultSelection?.display }}
      </span>
      <span class="icon"><i class="fas fa-chevron-down"></i></span>
    </button>
  </div>
  <div class="dropdown-menu">
    <div class="dropdown-content">
      <a
        class="dropdown-item"
        *ngFor="let item of items"
        [attr.data-e2e]="item.id"
        (click)="onSelect(item.id)"
      >
        {{ item.display }}
      </a>
    </div>
  </div>
</div>
