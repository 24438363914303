export enum SourcePim {
  AmexGB = 'AMEX_GB',
  AbsEcom = 'ABS_ECOM',
  AbsIso = 'ABS_ISO',
  AbsPdm = 'ABS_PDM',
  AbsWeb = 'ABS_WEB',
  Bankaxept = 'BANKAXEPT',
  BanqueChabrieres = 'BANQUE_CHABRIERES',
  Barclays = 'BARCLAYS',
  Banorte = 'BANORTE_MX',
  Bfcoi = 'BFCOI',
  BnppAtc = 'BNPP_ATC',
  BnpParibas = 'BNP_PARIBAS',
  BnppVersAtos = 'BNPP_VERS_ATOS',
  BofAUs = 'BOFA_US',
  Bred = 'BRED',
  CaisseDepargne = 'CAISSE_D_EPARGNE',
  Cba = 'CBA',
  Cedicam = 'CEDICAM',
  Cic = 'CIC',
  CmArkea = 'CM_ARKEA',
  CreditAgricole = 'CREDIT_AGRICOLE',
  CreditDuNordVersAtos = 'CREDIT_DU_NORD_VERS_ATOS', // crédit du nord
  CreditDuNordVersNatixis = 'CREDIT_DU_NORD_VERS_NATIXIS',
  CreditMutuel = 'CREDIT_MUTUEL',
  CreditGuard = 'CREDITGUARD',
  ChaseCa = 'CHASE_CA',
  ChaseUs = 'CHASE_US',
  Dankort = 'DANKORT',
  EftposNz = 'EFTPOS_NZ',
  Rapyd = 'RAPYD',
  Elavon = 'ELAVON',
  Gicc = 'GICC',
  GlobalPay = 'GLOBALPAY',
  Gpp2 = 'GPP2',
  Hsbc = 'HSBC',
  Ikano = 'IKANO',
  InterCard = 'INTERCARD',
  IsraCard = 'ISRACARD',
  LaBanquePostal = 'LA_BANQUE_POSTAL',
  Lcl = 'LCL', // Crédit Lyonnais
  Landsbankinn = 'LANDSBANKINN',
  LesBanquesPopulaires = 'LES_BANQUES_POPULAIRES',
  LloydsCardnet = 'LLOYDS_CARDNET',
  Maestro = 'MAESTRO',
  MobilePay = 'MOBILEPAY',
  Nets = 'NETS',
  Other = 'OTHER',
  PayPal = 'PAYPAL',
  PeleCard = 'PELECARD',
  Prosa = 'PROSA_MX',
  ResursBank = 'RESURS_BANK',
  Rts = 'RTS',
  Sg = 'SG', // Société Générale
  Simulator = 'SIMULATOR',
  SwedBankPay = 'SWEDBANK_PAY',
  Swish = 'SWISH',
  SynchronyUs = 'SYNCHRONY_US',
  TietoEvry = 'TIETO_EVRY',
  VerifoneAu = 'VERIFONE_AU',
  Vipps = 'VIPPS',
  Westpac = 'WESTPAC',
  WoodForestUs = 'WOODFOREST_US',
  WoodForestCA = 'WOODFOREST_CA',
  WorldPay = 'WORLDPAY',
  WorldPayUs = 'WORLDPAY_US',
  WorldpayCa = 'WORLDPAY_CA',
  VerifoneEu = 'VERIFONE_EU',
  ValuelinkUS = 'VALUELINK_US',
  ValuelinkCA = 'VALUELINK_CA',
  MPGS = 'MPGS',
  FdrcUS = 'FDRC_US',
  FdrcCA = 'FDRC_CA',
  Fdrc = 'FDRC',
  Prm = 'PRM',
}
