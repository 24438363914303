import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUser } from '@portal/entity-services/interfaces';
import { UserRoleGroup } from '@portal/entity-services/interfaces/src/lib/users/enums/user-role-groups.enum';
import { UserRole } from '@portal/entity-services/interfaces/src/lib/users/enums/user-role.enum';
import { RolesByGroup } from '@portal/entity-services/interfaces/src/lib/users/enums/user-role-types';
import { ISelectInput } from '@portal/shared/ui/filter';
import { UserRoleGroups } from './user-role-group.list';
import { UserRoles } from './user-role.list';
import { UserRoleDescription } from '@portal/shared/role-group-picker/src/lib/user-role-description.list';
import { RoleGroupKeyValue } from '@portal/shared/role-group-picker/src/lib/interfaces/role-group-key-value.interface';
import { SelfCheckService } from '@portal/shared/auth/authorization/src/lib/self-check.service';

@Component({
  selector: 'portal-role-group-picker',
  templateUrl: './role-group-picker.component.html',
})
export class RoleGroupPickerComponent implements OnInit {
  @Input() user: IUser;
  @Input() loggedInUser: IUser;
  @Input() disabled: boolean;
  @Output() selectedRoles: EventEmitter<UserRole[]> = new EventEmitter();
  @Input() set allRoleGroups(rolesByGroup: RolesByGroup) {
    // filter and change object to list with objects
    this.roleGroups = Object.keys(rolesByGroup).reduce(
      (acc: RoleGroupKeyValue[], curr: UserRoleGroup) =>
        rolesByGroup[curr].length ? [...acc, { key: curr, value: rolesByGroup[curr] }] : acc,
      [],
    );
  }
  @Input() isVerifoneEmail: boolean;

  currentRoles = new Set<UserRole>();
  loggedInUserRoles = new Set<UserRole>();
  currentGroup: UserRoleGroup;
  selfEditMode: boolean;
  roleGroups: RoleGroupKeyValue[];
  canBeDisabledUserRoles: UserRole[] = [UserRole.MerchantOrderAdmin, UserRole.MerchantPOIAdmin];

  userRolesList: ISelectInput[] = this.userRoles.list;
  userRoleGroupsList: ISelectInput[] = this.userRoleGroups.list;
  userRoleDescriptionList: ISelectInput[] = this.userRoleDescription.list;

  constructor(
    private activatedRoute: ActivatedRoute,
    private selfCheckService: SelfCheckService,
    private userRoles: UserRoles,
    private userRoleGroups: UserRoleGroups,
    private userRoleDescription: UserRoleDescription,
  ) {}

  ngOnInit(): void {
    if (this.user && this.user.roles) {
      // set the user role.
      this.currentRoles = new Set(this.user.roles);
      this.currentGroup = this.findRoleGroup(this.user.roles[0]) || null;
    }

    if (this.loggedInUser && this.loggedInUser.roles) {
      this.loggedInUserRoles = new Set(this.loggedInUser.roles);
    }

    this.selfEditMode = this.selfCheckService.isSelfPage(this.activatedRoute.snapshot);
  }

  onRoleChange(role: UserRole, event: any): void {
    const selectedGroup = this.findRoleGroup(role);
    if (!this.currentGroup) {
      this.currentGroup = selectedGroup;
    }
    if (this.selectedMultipleGroups(selectedGroup)) {
      this.currentRoles.clear();
      this.currentGroup = selectedGroup;
    }

    if (this.currentRoles.has(role)) {
      this.currentRoles.delete(role);
    } else {
      this.currentRoles.add(role);
    }

    if (event.target.checked === false && role === UserRole.MerchantAdmin) {
      this.currentRoles.delete(UserRole.MerchantOrderAdmin);
      this.currentRoles.delete(UserRole.MerchantPOIAdmin);
      this.isDisabled(role);
    }

    this.selectedRoles.emit(Array.from(this.currentRoles));
    event.target.checked = true;
  }

  findRoleGroup(role: UserRole): UserRoleGroup {
    let group: UserRoleGroup;
    this.roleGroups.forEach((rolesByGroup: RoleGroupKeyValue) => {
      if (rolesByGroup.value.includes(role)) {
        group = rolesByGroup.key;
      }
    });
    return group;
  }

  isRoleSelected(role: UserRole): boolean {
    return this.currentRoles.has(role);
  }

  isDisabled(role: UserRole): boolean {
    return (
      (!this.currentRoles.has(UserRole.MerchantAdmin || UserRole.MerchantPOIAdmin) &&
        this.canBeDisabledUserRoles.includes(role)) ||
      (!this.loggedInUserRoles.has(UserRole.VerifoneMaster) && role === UserRole.VerifoneAdmin)
    );
  }

  getRoleText(roleId: UserRole): string {
    const roleObj: ISelectInput = this.userRolesList.find((element) => element.id === roleId);
    return roleObj ? roleObj.text : '';
  }

  getRoleGroupText(groupId: UserRoleGroup): string {
    const roleGroupObj: ISelectInput = this.userRoleGroupsList.find(
      (element) => element.id === groupId,
    );
    return roleGroupObj ? roleGroupObj.text : '';
  }

  getRoleDescription(roleId: UserRole): string {
    const roleDescriptionObj: ISelectInput = this.userRoleDescriptionList.find(
      (element) => element.id === roleId,
    );
    return roleDescriptionObj?.text;
  }

  trackByKey(index: number, item: RoleGroupKeyValue): string {
    return item.key;
  }

  checkVerifoneEmail(groupKey: RoleGroupKeyValue): boolean {
    if (groupKey.key === UserRoleGroup.Verifone && !this.isVerifoneEmail) {
      this.deleteVerifoneRoles(groupKey);
      return true;
    }
    return false;
  }

  private deleteVerifoneRoles(groupKey: RoleGroupKeyValue): void {
    if (groupKey && groupKey.value?.length) {
      groupKey.value.forEach((role) => {
        if (this.currentRoles.has(role)) {
          this.currentRoles.delete(role);
        }
      });
      this.selectedRoles.emit(Array.from(this.currentRoles));
    }
  }

  private selectedMultipleGroups(selectedGroup: UserRoleGroup): boolean {
    const currentRoles = [...this.currentRoles];

    if (
      (this.isResellerAndMerchantGroups(selectedGroup) &&
        this.isResellerAndMerchantGroups(this.currentGroup) &&
        !currentRoles.some((role) => role.includes(UserRoleGroup.Provider))) ||
      (this.isProviderAndMerchantGroups(selectedGroup) &&
        this.isProviderAndMerchantGroups(this.currentGroup) &&
        !currentRoles.some((role) => role.includes(UserRoleGroup.Reseller)))
    ) {
      this.currentGroup = selectedGroup;
      return false;
    }

    return selectedGroup !== this.currentGroup;
  }

  private isResellerAndMerchantGroups(groupName: UserRoleGroup): boolean {
    return [UserRoleGroup.Reseller, UserRoleGroup.Merchant].includes(groupName);
  }

  private isProviderAndMerchantGroups(groupName: UserRoleGroup): boolean {
    return [UserRoleGroup.Provider, UserRoleGroup.Merchant].includes(groupName);
  }
}
