export enum InstrumentType {
  Card = 'CARD',
  Token = 'TOKEN',
  Apm = 'APM',
  Crypto = 'CRYPTO',
  Affirm = 'AFFIRM',
  Cheque = 'CHEQUE',
  ReuseToken = 'REUSE_TOKEN',
  OPOnlinePayment = 'OP_ONLINE_PAYMENT',
}
