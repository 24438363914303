import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Title } from '@angular/platform-browser';
import isEqual from 'lodash-es/isEqual';
import { TemplateParameters } from '@portal/entity-services/interfaces/src/lib/organisations/interfaces/reseller.interface';
import { RestrictPermissionService } from '@portal/shared/auth/authorization/src/lib/services/restrict-permission.service';
import { Pages } from '../../enums/pages.enum';
import { TemplateParametersService } from '../../services/template-parameters.service';
import { CombinedOrgTemplateParameters } from '../../interfaces/combined-org-template-parameters';
import { organisationsPermissions } from '../../routing/route-permissions.const';
import { FormWhiteLabelingEditorComponent } from './form-white-labeling-editor/form-white-labeling-editor.component';
import { WhiteLabelingPreviewPageComponent } from './white-labeling-preview-page/white-labeling-preview-page.component';
import { BaseModalComponent } from '@portal/shared/ui/modal/src/lib/base-modal/base-modal.component';

declare const $localize;

@UntilDestroy()
@Component({
  selector: 'portal-white-labeling-preview',
  templateUrl: './white-labeling-preview.component.html',
  styleUrls: ['./white-labeling-preview.component.scss'],
})
export class WhiteLabelingPreviewComponent implements OnInit {
  @ViewChild(FormWhiteLabelingEditorComponent)
  formWhiteLabelingEditorComponent: FormWhiteLabelingEditorComponent;
  @ViewChild(WhiteLabelingPreviewPageComponent)
  whiteLabelingPreviewPageComponent: WhiteLabelingPreviewPageComponent;
  @ViewChild('backOrgPageModal') backOrgPageModal: BaseModalComponent;

  entityUid: string;
  domainName: string;
  entityName = '';
  initialTemplateParameters: TemplateParameters;
  appliedTemplateParameters: TemplateParameters;
  currentTemplateParameters: TemplateParameters;
  logoSrc: string;
  loading$: Observable<boolean>;
  currentPage: Pages;
  fromBreadcrumbs: boolean;
  canEditWhiteLabel: boolean;

  constructor(
    private title: Title,
    private liveAnnouncer: LiveAnnouncer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private templateParametersService: TemplateParametersService,
    private restrictPermissionService: RestrictPermissionService,
  ) {
    this.title.setTitle($localize`White labelling preview`);
    this.liveAnnouncer.announce($localize`Navigated to ${this.title.getTitle()}`);
    this.loading$ = this.templateParametersService.loading$;
  }

  ngOnInit(): void {
    this.currentPage = this.getCurrentPageName();
    this.checkWhiteLabelPermissions(this.currentPage);
    this.entityUid = this.activatedRoute.snapshot.paramMap.get('id');

    this.templateParametersService
      .getOrgWithTemplateParametersAndCombine(this.entityUid, this.canEditWhiteLabel)
      .subscribe(({ org, templateParameters }: CombinedOrgTemplateParameters) => {
        this.entityName = org.name;
        this.domainName = org.domainName;
        if (templateParameters) {
          this.initialTemplateParameters = templateParameters;
        }
      });
  }

  onApplyFormAndReturnOrg(fromBreadcrumbs = false): void {
    this.fromBreadcrumbs = fromBreadcrumbs;
    const isApplied = isEqual(this.appliedTemplateParameters, this.currentTemplateParameters);
    if (isApplied) {
      this.formWhiteLabelingEditorComponent.submit();
      this.backOrgPage();
    } else {
      this.backOrgPageModal.open();
    }
  }

  onBackOrgModalConfirmed(): void {
    this.whiteLabelingPreviewPageComponent.whiteLabelingEditorReseted(
      this.initialTemplateParameters,
    );
    this.backOrgPage();
  }

  onWhiteLabelingEditorApplied(parameters: TemplateParameters): void {
    this.appliedTemplateParameters = parameters;
    this.whiteLabelingPreviewPageComponent.whiteLabelingEditorApplied(parameters);
  }

  onWhiteLabelingEditorReseted(parameters: TemplateParameters): void {
    this.appliedTemplateParameters = parameters;
    this.whiteLabelingPreviewPageComponent.whiteLabelingEditorReseted(parameters);
  }

  onWhiteLabelingEditorValueChanged(parameters: TemplateParameters): void {
    this.currentTemplateParameters = parameters;
  }

  private backOrgPage(): void {
    const routerLink = ['/administration', 'organisations', this.entityUid];
    if (!this.fromBreadcrumbs && this.currentPage === Pages.Edit) {
      routerLink.push(Pages.Edit);
    }

    this.router.navigate(routerLink);
  }

  private getCurrentPageName(): Pages {
    return this.activatedRoute.snapshot.url
      .map((urlSegment) => urlSegment.path)
      .find((path: Pages) => Object.values(Pages).includes(path)) as Pages;
  }

  private checkWhiteLabelPermissions(page: string): void {
    this.canEditWhiteLabel = this.restrictPermissionService.checkPermissions(
      organisationsPermissions[page],
      ['white-label'],
    );
  }
}
