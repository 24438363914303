import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OffCanvasComponent } from './off-canvas/off-canvas.component';

@NgModule({
  imports: [CommonModule],
  declarations: [OffCanvasComponent],
  exports: [OffCanvasComponent],
})
export class OffCanvasModule {}
