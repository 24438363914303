<div
  class="navbar-item has-dropdown"
  [class.is-active]="isActive"
  [ngClass]="classList"
  (portalClickOutside)="close()"
>
  <div class="dropdown-label" tabindex="0" (click)="toggle()" (keyup.enter)="toggle()">
    <ng-content select=".navbar-link"></ng-content>
  </div>

  <div *ngIf="isActive" class="navbar-dropdown" [class.is-right]="isRight">
    <vui-expansion-menu [navbarItems]="navbarItems" (navigationStarted)="close()">
    </vui-expansion-menu>
    <ng-content></ng-content>
  </div>
</div>
