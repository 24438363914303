// export * from './lib/templates.module';
export * from './lib/app-catalog/app-catalog.module';
export * from './lib/app-catalog/app-card-template/app-card-template.module';
export * from './lib/app-catalog/app-catalog-cards-template/app-catalog-cards-template.module';
export * from './lib/app-catalog/app-catalog-table-template/app-catalog-table-template.module';
export * from './lib/app-catalog/app-page-template/app-page-template.module';
export * from './lib/app-catalog/app-details-template/app-details-template.module';
export * from './lib/app-catalog/app-logo-template/app-logo-template.module';
export * from './lib/app-catalog/app-tag-logo-template/app-tag-logo-template.module'

export * from './lib/bundles/bundle-table-template/bundle-table-template.module';
export * from './lib/bundles/bundle-page-template/bundle-page-template.module';

export * from './lib/devices/device-card-template/device-card-template.module';
export * from './lib/devices/device-page-template/device-page-template.module';
export * from './lib/devices/devices-cards-template/devices-cards-template.module';
export * from './lib/devices/devices-table-template/devices-table-template.module';
export * from './lib/shared/app-promo/app-promo.module';

export * from './lib/pages/index-page/index-base-component';
export * from './lib/pages/tags-management-page/tags-management-page-component';
export * from './lib/shared/app-price/app-price.module';
export * from './lib/shared/app-icon-upload/app-icon-upload.module';
export * from './lib/shared/file-upload/file-upload.module';
export * from './lib/shared/file-upload-form-control/file-upload-form-control.module';

export * from './lib/shared/progress-bar/progress-bar.module';

export * from './lib/shared/version-selector/version-selector.module';

export * from './lib/shared/updates-indicator/updates-indicator.module';
export * from './lib/shared/installs-indicator/installs-indicator.module';

export * from './lib/reports/report-template/report-template.module';

export * from './lib/shared/select/select.module';
export * from './lib/shared/select/interfaces/drop-down-item.interface';

//is featured icon
export * from './lib/shared/app-icon/app-icon.module';
export * from './lib/ui/cards-table-template/cards-table-template.module';
