import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPoi } from '@portal/marketplace/api-interfaces';

@Component({
  selector: 'marketplace-devices-cards-template',
  templateUrl: './devices-cards-template.component.html',
  styleUrls: ['./devices-cards-template.component.scss'],
})
export class DevicesCardsTemplateComponent implements OnInit {
  @Output() deviceClicked: EventEmitter<any> = new EventEmitter<any>();
  private _deviceList: IPoi[];
  get deviceList$(): IPoi[] {
    return this._deviceList;
  }
  @Input('deviceList')
  set deviceList$(val) {
    this._deviceList = val;
  }

  // constructor(private appCatalogService: AppCatalogService) {}
  showPanel(device): void {
    this.deviceClicked.emit(device);
  }
  trackByFn(index, item): void {
    return index;
  }
  ngOnInit(): void {
    //this.appCatalogService.loadAppCatalog('MERCHANT');
  }
}
