import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { SortOrder } from '../enums/sort-order.enum';
import { IQueryParams } from '../interfaces/query-params.interface';
import { ISelection } from '../interfaces/selection';
import { ITableConfig } from '../interfaces/table-config.interface';
import { DefaultTableActionsService } from './providers/actions.service';
import { SelectionService } from './providers/selection.service';
import * as DOMPurify from 'dompurify';

declare const $localize;

@Component({
  selector: 'portal-table',
  templateUrl: './table.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'is-block table-container' },
  providers: [SelectionService],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() items: any[];
  @Input() itemsClickable = true;
  @Input() loading$: Observable<boolean>;
  @Input() filter: IQueryParams;

  @Input() tableConfig: ITableConfig;
  @Input() rowLinkHref = '';
  @Input() rowLinkHeading = $localize`Link to item`;
  @Input() loadingMessage = $localize`Loading items`;
  @Input() notFoundMessage = $localize`Items not found`;
  @Input()
  set defaultSelection(val: ISelection[]) {
    this.selectionService.defaultSelection = val;
  }
  @Input() isRowDisabled: ((item: any) => boolean) | undefined;

  @Output() sortChanged: EventEmitter<any> = new EventEmitter();
  @Output() selectedChange = new EventEmitter<any>();
  @Output() oneRowSelectedChange = new EventEmitter<any>();

  hasRowLink: boolean;
  colspan: number;
  errorMessage = $localize`Table configuration is missing`;
  loadingRows: undefined[];
  loadingColumns: undefined[];

  get selection(): ISelection[] {
    return this.selectionService.selection;
  }
  get activeSelection(): ISelection[] {
    return this.selectionService.activeSelection;
  }
  constructor(
    private selectionService: SelectionService,
    private defaultTableActionsService: DefaultTableActionsService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.selectionService.onSelectedChanged.subscribe((data: any) => {
      this.selectedChange.emit(data);
    });
    this.setDefaultActions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableConfig) {
      this.selectionService.tableConfig = this.tableConfig;
      const linkColumnLength = this.rowLinkHref ? 1 : 0;
      const isSelection = this.tableConfig.selectionConfig ? 1 : 0;
      this.hasRowLink = !!linkColumnLength && this.itemsClickable;
      this.colspan = (this.tableConfig?.columns?.length ?? 0) + linkColumnLength + isSelection;
      this.loadingColumns = Array(this.colspan);
      this.setDefaultActions();
    }
    if (changes.filter) {
      this.loadingRows = Array(this.filter?.limit ?? 10);
    }
  }

  hasDisabledRow(item: any): boolean {
    if (this.isRowDisabled) {
      return this.isRowDisabled(item);
    }
    return false;
  }

  setDefaultActions(): void {
    // Export to CSV action flag
    if (this.defaultTableActionsService.exportToCsv) {
      if (this.tableConfig.actions === null) {
        return;
      }

      // if no action object provided put empty
      if (!this.tableConfig.actions) {
        this.tableConfig.actions = {};
      }

      // in case when actions object not empty
      if (!this.tableConfig.actions.hasOwnProperty('exportCsv')) {
        this.tableConfig.actions.exportCsv = {
          filename: 'data',
        };
      }
    }
  }

  onRowSelected(item, event): void {
    const selection: ISelection = {
      id: item[this.tableConfig.rowIdKey],
      item: item,
      checked: event.target.checked,
    };
    this.selectionService.onRowSelected(selection);
    this.oneRowSelectedChange.emit(selection);
  }

  onRowClicked(event: MouseEvent | TouchEvent): void {
    const linkCssClass = 'table-row-link';
    const eventTarget = event.target as HTMLElement;
    if (eventTarget.closest('label')) {
      event.stopPropagation();
      return;
    }
    if (this.hasRowLink && !eventTarget.classList.contains(linkCssClass)) {
      const row = eventTarget.closest('tr');
      const link = row.querySelector(`.${linkCssClass}`) as HTMLLinkElement;
      if (link) {
        link.click();
      }
    }
  }

  onSortChanged(key: string, order: SortOrder): void {
    this.sortChanged.emit({ key, order });
  }

  isSelected(id): boolean {
    return this.selectionService.isSelected(id);
  }
  isDisabled(id: string): boolean {
    return this.selectionService.isDisabled(id);
  }

  selectAll(event): void {
    this.selectionService.selectAll(this.items, event.target.checked);
  }

  getColumnOrder(columnKey: string): string {
    return this.filter.sort && this.filter.sort.key === columnKey
      ? this.filter.sort.order
      : SortOrder.None;
  }

  exportCsv(): void {
    const keys = this.tableConfig.columns.map((column) => column.key);

    let data =
      'data:text/csv;charset=utf-8,' +
      this.tableConfig.columns.map((column) => `"${column.label}"`) +
      '\n';

    this.items.forEach((item) => {
      let value = [];
      value = keys.map((key) => {
        return key
          .replace('[', '.')
          .replace(']', '')
          .split('.')
          .reduce((sym, cur, i, arr) => {
            if (sym[cur]) {
              return sym[cur];
            } else {
              arr.splice(1);
            }
          }, item);
      });
      const str = value.join(',') + '\n';
      data += str;
    });

    const link = document.createElement('a');
    link.setAttribute('href', encodeURI(data));
    link.setAttribute('download', `${this.tableConfig.actions.exportCsv.filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  renderFunction(content: string | SafeHtml, renderImage?: boolean): SafeHtml | string {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const sanitazeSetttings = { FORBID_TAGS: [] };

    if (!renderImage) {
      sanitazeSetttings.FORBID_TAGS = ['img', 'svg'];
    }

    if (typeof content === 'string') {
      return this.sanitizer.bypassSecurityTrustHtml(DOMPurify.sanitize(content, sanitazeSetttings));
    }

    if (
      typeof content === 'object' &&
      content !== null &&
      'changingThisBreaksApplicationSecurity' in content
    ) {
      return this.sanitizer.bypassSecurityTrustHtml(
        DOMPurify.sanitize(
          (content as any).changingThisBreaksApplicationSecurity,
          sanitazeSetttings,
        ),
      );
    }

    return content;
  }
}
