import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ITag, ITagModel, TAGS_API, TTagType } from '@portal/marketplace/api-interfaces';
import { VuiHttpService } from '@portal/shared/vui-http/src/lib/http-wrapper/vui-http.service';
import { IFilterToApply } from '@portal/shared/ui/filter';
import { IQueryParams } from '@portal/shared/ui/table';
import { UserService } from '@portal/shared/user';

import { UrlQueryParamsBuilderService } from '@portal/shared/url-query-params-builder/src/lib/url-query-params-builder.service';

@Injectable({ providedIn: 'root' })
export class TagsDataService {
  constructor(
    private http: VuiHttpService,
    private userService: UserService,
    private urlQueryParamsBuilderService: UrlQueryParamsBuilderService,
  ) {}

  getAllTags(
    type?: TTagType,
    filter?: IQueryParams,
    searchAndFilters?: IFilterToApply[],
  ): Observable<any> {
    const entityUid = this.userService.getEntityUid();
    const url = TAGS_API.GET_ALL_TAGS(entityUid);

    filter = filter || {};

    searchAndFilters = [
      ...(searchAndFilters || []),
      ...(type
        ? [
            {
              argument: type,
              key: `tagType-${type}`,
              operator: 'cn',
              selector: 'tagType',
            },
          ]
        : []),
    ];

    const params = this.urlQueryParamsBuilderService.createHttpParams(
      {
        ...(filter.sort ? { sort: `${filter.sort.key},${filter.sort.order}` } : {}),
        ...(filter.limit ? { size: `${filter.limit}` } : {}),
        ...(filter.start ? { page: `${filter.start / filter.limit}` } : {}),
      },
      searchAndFilters,
    );

    return this.http.get(url, { params: params });
  }

  // TODO: https://jira.verifone.com/browse/GVCCA-8331
  getAllPoisForTag(tagIds: string[]): Observable<string[]> {
    const entityUid = this.userService.getEntityUid();
    // TODO: returns only poids instead of data
    // TODO: not allow to get POIs by LIST of TAGIDs
    const url = TAGS_API.GET_ALL_POIS_FOR_TAG(entityUid, tagIds[0]);
    return this.http.get(url);
  }

  // TODO: https://jira.verifone.com/browse/GVCCA-8329
  getAllBundlesForTag(tagIds: string[]): Observable<string[]> {
    const entityUid = this.userService.getEntityUid();
    // TODO: returns only bundles IDs instead of data
    // TODO: we can't get bunlde by bundleId, where are no API. Only by AppMarketBundleId
    // TODO: not allow to get BUNDLES by LIST of TAGIDs

    const url = TAGS_API.GET_ALL_BUNDLES_FOR_TAG(entityUid, tagIds[0]);
    return this.http.get(url).pipe(map((response) => response['data'] as string[]));
  }

  createTag(tag: ITagModel): Observable<ITag> {
    const entityUid = this.userService.getEntityUid();
    const url = TAGS_API.CREATE_TAG(entityUid);
    return this.http.post(url, { data: tag }).pipe(map((response) => response['data'][0] as ITag));
  }

  updateTag(tag: ITag): Observable<ITag> {
    const entityUid = this.userService.getEntityUid();
    const url = TAGS_API.UPDATE_TAG(entityUid, tag.id);
    return this.http.put(url, { data: tag }).pipe(map((response) => response['data'][0] as ITag));
  }

  removeTag(tag: ITag): Observable<ITag> {
    const entityUid = this.userService.getEntityUid();
    const url = TAGS_API.DELETE_TAG(entityUid, tag.id);
    return this.http.delete(url);
  }
}
