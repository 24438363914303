/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import environment from '@environments';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CardCapturePublicKey } from './card-capture-public-key.interface';
import { IQueryParams, ITotalCount } from '@portal/shared/ui/table';

@Injectable()
export class SecureCardCaptureService {
  private apiEndpoint = environment.API_ENDPOINT.PUBLIC_KEY_MANAGEMENT;

  constructor(private httpClient: HttpClient) {}

  createKeyPair(): Observable<CardCapturePublicKey> {
    return this.httpClient.post<CardCapturePublicKey>(this.getUrl(''), {});
  }

  getKeyPairs(query: IQueryParams): Observable<CardCapturePublicKey[]> {
    return this.httpClient
      .get<{ public_keys: CardCapturePublicKey[] }>(this.getUrl(''), {
        params: {
          ...(query as object),
        },
      })
      .pipe(
        map((response) => {
          // to make sure that the public keys are sorted in reverse order by creation date
          return response.public_keys.sort((pk1, pk2) => {
            return new Date(pk2.created_at).getTime() - new Date(pk1.created_at).getTime();
          });
        }),
        catchError(() => of([])),
      );
  }

  getKeyPairsCount(): Observable<ITotalCount> {
    return this.httpClient.get<ITotalCount>(this.getUrl('count'));
  }

  createKeyPairForEntity(entityId: string): Observable<CardCapturePublicKey> {
    return this.httpClient.post<CardCapturePublicKey>(this.getUrl(''), { entity_id: entityId });
  }

  getKeyPairsForEntity(entityId: string): Observable<CardCapturePublicKey[]> {
    const params = new HttpParams().set('entity_id', entityId);
    return this.httpClient
      .get<{ public_keys: CardCapturePublicKey[] }>(this.getUrl(''), { params })
      .pipe(
        map((response) => {
          return response.public_keys;
        }),
      );
  }

  private getUrl(path: string): string {
    return `${this.apiEndpoint}/${path}`;
  }
}
