import { Application } from '../enums/application.enum';

export const APPLICATION_PATH = {
  [Application.Marketplace]: 'marketplace',
  [Application.Developer]: 'developer',
  [Application.EstateOwner]: 'estate-owner',
  [Application.MarketplaceManager]: 'marketplace-manager',
  [Application.MOP]: 'mop',
  [Application.Petro]: 'petro',
  [Application.PaymentsPortal]: 'payments',
  [Application.VHQ]: 'vhq',
};
