<div class="dropdown" [class.is-active]="isDropdownOpen">
  <div class="dropdown-trigger">
    <button
      [attr.aria-expanded]="isDropdownOpen"
      class="button is-chip is-size-7-mobile"
      (click)="openMenu()"
      data-e2e="button-add-filter"
      type="button"
      id="add-filter"
      title="Add filter"
      i18n-title
    >
      <span i18n> Add filter </span>
      <span class="icon">
        <i class="fas fa-plus"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu">
    <div class="dropdown-content" [class.is-hidden]="!isOpen">
      <ng-container *ngFor="let item of filtersList">
        <a
          class="dropdown-item has-icons"
          data-e2e="button-to-menu-datail"
          (click)="goToSubmenu(item.selector)"
        >
          <span i18n>{{ item.title }}</span>
          <span class="icon is-hover-visible">
            <i class="fas fa-chevron-right" aria-hidden="true"></i>
          </span>
        </a>
      </ng-container>
    </div>

    <!-- Submenu content -->
    <ng-content></ng-content>
  </div>
</div>
