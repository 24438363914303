<div class="tile is-ancestor" *ngIf="promo !== null && promo !== undefined">
  <div class="tile narrow is-parent">
    <figure class="image is-128x128">
      <img
        [src]="promo.imageUrl | secureLoad | async"
        data-e2e="logo-img"
        class="rounded-img has-border-grey"
        alt="{{ promo.name }}"
      />
    </figure>
  </div>
  <div class="tile is-vertical is-parent is-6">
    <div class="tile is-child">
      <span class="has-text-weight-bold" data-e2e="name">{{ promo.name }}</span>
      <div>
        <span class="is-size-7 has-text-weight-bold">Version: </span>
        <span data-e2e="version">{{ promo.version }}</span>
      </div>
      <div *ngIf="promo.categories != null" data-e2e="categories">
        <span class="is-size-7 has-text-weight-bold">Categories: </span>
        <span *ngFor="let tag of promo.categories" class="tag has-border-grey" data-e2e="tag">
          {{ tag.displayName }}
        </span>
      </div>
      <div *ngIf="promo.models != null" data-e2e="models">
        <span class="is-size-7 has-text-weight-bold">Supported terminals: </span>
        <span *ngFor="let tag of promo.models" class="tag has-border-grey" data-e2e="tag">
          {{ tag.displayName }}
        </span>
      </div>
      <div class="tile narrow"><strong>Description: </strong>
      <div class = "text-container" data-e2e="description">{{ promo?.description }}</div>
    </div>
  </div>
  </div>
</div>
