import { Injectable } from '@angular/core';
import { organisationsPermissions } from '@portal/entity-services/organisations/src';
import {
  AuthorizationService,
  RestrictPermissionService,
  UserRoles,
} from '@portal/shared/auth/authorization/src';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle/src';
import { INavbarItem } from '@portal/shared/ui/layouts/src/lib/top-navbar/interfaces/navbar-item.interface';
import {
  getSubNavItems,
  getNavbarItemLink,
  NavbarItemLink,
} from '../../helpers/navigation-route-helpers';
import {
  PaymentContractsRouteName,
  PaymentContractsRoutePermissions,
} from '../../permissions/payments/payment-contracts';
import {
  PointOfInteractionsRouteNames,
  PointOfInteractionsRoutePermissions,
} from '../../permissions/payments/point-of-interaction';
import { ApplicationRoot } from '../../enums/application-root.enum';
import { RoutePermissions as UsersRoutePermissions } from '@portal/entity-services/users';
import { RouteName as UsersRouteNames } from '@portal/entity-services/users';
import { Features } from 'environments/enums/features.enum';
import {
  DemoAccountRouteNames,
  DemoAccountRoutePermissions,
} from '../../permissions/payments/demo-account';
import {
  ThreeDSecureContractRouteNames,
  ThreeDSecureContractRoutePermissions,
} from '../../permissions/payments/secure-contracts';
import {
  ManageMerchantCountryConfigurationRouteNames,
  ManageMerchantCountryConfigurationRoutePermissions,
} from '../../permissions/payments/merchant-country-configuration';
import {
  MerchantConfigurationRouteNames,
  MerchantConfigurationRoutePermissions,
} from '../../permissions/payments/merchant-configuration';
import {
  MerchantSettlementRouteNames,
  MerchantSettlementRoutePermissions,
} from '../../permissions/payments/merchant-settlement';
import {
  FraudPreventionRouteNames,
  FraudPreventionRoutePermissions,
} from '../../permissions/payments/fraud-prevention';
import {
  PriceListsRouteNames,
  PriceListsRoutePermissions,
} from '../../permissions/payments/price-lists';
import { NotificationsPermissions } from '../../permissions/payments/notifications/notifications.permissions';
import { CheckoutThemesPermissions } from '../../permissions/payments/checkout-themes/checkout-themes.permissions';
import { WalletsRouteNames, WalletsRoutePermissions } from '../../permissions/payments/wallets';
import {
  BlockingRulesetsRouteNames,
  BlockingRulesetsRoutePermissions,
} from '../../permissions/payments/blocking-rulesets';
import { PetroService } from '../petro/petro.service';
import { IUser } from '@portal/entity-services/interfaces/src';
import { MenuRegion } from '../../../../../ui/layouts/src/lib/top-navbar/enum/menu-region.enum';
import { MarketplaceNavbarService } from '../marketplace/marketplace-navbar.service';

@Injectable({
  providedIn: 'root',
})
export class AdministrationNavbarService {
  languageRoute: string;
  currentApplication: ApplicationRoot;

  constructor(
    private featureToggleService: FeatureToggle,
    private restrictPermissionService: RestrictPermissionService,
    private authorizationService: AuthorizationService,
    private petroService: PetroService,
    private marketplaceNavbarService: MarketplaceNavbarService,
  ) {}

  getAdministrationNavbarItems(params: {
    currentApplication: ApplicationRoot;
    languageRoute: string;
    user: IUser;
  }): INavbarItem | null {
    this.languageRoute = params.languageRoute;
    this.currentApplication = params.currentApplication;
    const accountSetupItems = this.getAccountSetupNavbarItems();
    const siteManagementItems = this.getSiteManagementNavbarItems();
    const userManagementItems = this.getUserManagementNavbarItems();
    const partnerManagementItems = this.getPartnerManagementNavbarItems();
    const finOpsItems = this.getFinOpsNavbarItems();
    const advancedSettingsItems = this.getAdvancedSettinsNavbarItems();

    return getSubNavItems(
      {
        id: 'administration',
        title: $localize`:Main menu navbar group name:Administration`,
        attributes: {
          'data-e2e': 'title-administration',
          groupTitle: $localize`:Main menu navbar group name:Administration`,
          description:
            this.currentApplication === ApplicationRoot.Petro
              ? `With Roles, define custom permission levels for team members, while Users allow one to add, remove, or modify user accounts. The Hierarchy feature helps establish reporting lines and manage team structures, and with Sites, keep track of physical locations associated with an organization. Bulk upload report feature simplifies the process of uploading large amounts of data in a single step.`
              : $localize`:Main menu navbar group description:Easily set up new accounts streamlined Account Setup process, optimize financial operations with our FinOps tools & fine-tune account with our Advanced Settings options.`,
        },
        region: MenuRegion.Administration,
      },
      [
        accountSetupItems,
        siteManagementItems,
        userManagementItems,
        partnerManagementItems,
        finOpsItems,
        advancedSettingsItems,
      ],
    );
  }

  getAccountSetupNavbarItems(): INavbarItem | null {
    const accountSetup = {
      title: $localize`:Main menu navbar item name:Account Setup`,
      attributes: {
        'data-e2e': 'title-account-setup',
      },
      children: [],
    };

    if (this.showOrganisations) {
      accountSetup.children.push({
        title: $localize`:Main menu navbar item name:Organizations`,
        attributes: {
          'data-e2e': 'link-organizations',
        },
        ...this.getItemUrl('/administration/organisations', [
          ...(this.featureToggleService.isFeatureActive(Features.PaymentsPortalCore)
            ? [ApplicationRoot.Payments]
            : []),
          ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
          this.marketplaceNavbarService.showMarketplaceApp
            ? [ApplicationRoot.Marketplace]
            : []),
          ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
          this.marketplaceNavbarService.showEstateOwnerApp
            ? [ApplicationRoot.EstateOwner]
            : []),
          ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
          this.marketplaceNavbarService.showDevelopApp
            ? [ApplicationRoot.Developer]
            : []),
          ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
          this.marketplaceNavbarService.showMarketplaceManagerApp
            ? [ApplicationRoot.MarketplaceManager]
            : []),
          ApplicationRoot.MOP,
          ApplicationRoot.VHQ,
        ]),
      });
    }

    if (this.showPpc) {
      accountSetup.children.push({
        title: $localize`:Main menu navbar item name:Payment Provider Contracts`,
        attributes: {
          'data-e2e': 'link-payment-contracts',
        },
        ...this.getItemUrl('/administration/payment-contracts', [ApplicationRoot.Payments]),
      });
    }

    if (this.showPois) {
      accountSetup.children.push({
        title: $localize`:Main menu navbar item name:Point of Interaction`,
        attributes: {
          'data-e2e': 'link-pois',
        },
        ...this.getItemUrl('/administration/points-of-interaction', [ApplicationRoot.Payments]),
      });
    }

    if (this.petroService.showAccountsMenu()) {
      accountSetup.children.push({
        title: $localize`:Main menu navbar item name:Accounts`,
        attributes: {
          'data-e2e': 'link-accounts',
        },
        ...this.getItemUrl('/administration/accounts', [ApplicationRoot.Petro]),
      });
    }

    if (this.showUsers) {
      //todo: use roles to differentiate between subusers and users
      const userRoute = this.petroService.showUsersMenu()
        ? '/administration/subuser'
        : '/administration/users';

      let applications = [
        ...(this.featureToggleService.isFeatureActive(Features.PaymentsPortalCore)
          ? [ApplicationRoot.Payments]
          : []),
        ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
        this.marketplaceNavbarService.showMarketplaceApp
          ? [ApplicationRoot.Marketplace]
          : []),
        ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
        this.marketplaceNavbarService.showEstateOwnerApp
          ? [ApplicationRoot.EstateOwner]
          : []),
        ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
        this.marketplaceNavbarService.showDevelopApp
          ? [ApplicationRoot.Developer]
          : []),
        ...(this.featureToggleService.isFeatureActive(Features.MarketplaceCore) &&
        this.marketplaceNavbarService.showMarketplaceManagerApp
          ? [ApplicationRoot.MarketplaceManager]
          : []),
        ApplicationRoot.MOP,
        ApplicationRoot.Petro,
        ApplicationRoot.VHQ,
      ];
      if (this.petroService.showUsersMenu()) {
        applications = [ApplicationRoot.Petro];
      }
      accountSetup.children.push({
        title: $localize`:Main menu navbar item name:Users`,
        attributes: {
          'data-e2e': 'link-users',
        },
        ...this.getItemUrl(userRoute, applications),
      });

      if (this.petroService.isPetroUser()) {
        const index = accountSetup.children.indexOf('Users');
        accountSetup?.children?.splice(index, 1);
      }
    }

    if (this.showDemoAccount) {
      accountSetup.children.push({
        title: $localize`:Main menu navbar item name:Demo Account`,
        attributes: {
          'data-e2e': 'link-demo-account',
        },
        ...this.getItemUrl('/administration/demo-account', [ApplicationRoot.Payments]),
      });
    }

    return accountSetup;
  }

  getSiteManagementNavbarItems(): INavbarItem | null {
    const siteManagement = {
      title: $localize`:Main menu navbar item name: Site Management`,
      attribute: {
        'data-e2e': 'title-site-management',
      },
      children: [],
    };

    if (this.petroService.showHierarchyMenu()) {
      siteManagement.children.push({
        title: $localize`:Main menu navbar item name:Hierarchy`,
        attributes: {
          'data-e2e': 'link-hierarchy',
        },
        ...this.getItemUrl('/administration/hierarchy', [ApplicationRoot.Petro]),
      });
    }

    if (this.petroService.showSitesMenu()) {
      siteManagement.children.push({
        title: $localize`:Main menu navbar item name:Sites`,
        attributes: {
          'data-e2e': 'link-sites',
        },
        ...this.getItemUrl('/administration/sites', [ApplicationRoot.Petro]),
      });
    }

    if (this.petroService.showBulkReportMenu()) {
      siteManagement.children.push({
        title: $localize`:Main menu navbar item name:Bulk Upload Report`,
        attributes: {
          'data-e2e': 'link-bulk-upload-reports',
        },
        ...this.getItemUrl('/administration/bulk-upload-reports', [ApplicationRoot.Petro]),
      });
    }

    return siteManagement;
  }

  getUserManagementNavbarItems(): INavbarItem | null {
    const userManagement = {
      title: $localize`:Main menu navbar item name:User Management`,
      attributes: {
        'data-e2e': 'title-user-management',
      },
      children: [],
    };

    if (this.petroService.showRolesMenu()) {
      userManagement.children.push({
        title: $localize`:Main menu navbar item name:Roles`,
        attributes: {
          'data-e2e': 'link-roles',
        },
        ...this.getItemUrl('/administration/roles', [ApplicationRoot.Petro]),
      });
    }

    if (this.petroService.showUsersMenu()) {
      userManagement.children.push({
        title: $localize`:Main menu navbar item name:Users`,
        attributes: {
          'data-e2e': 'link-users',
        },
        ...this.getItemUrl('/administration/subuser', [ApplicationRoot.Petro]),
      });
    }

    return userManagement;
  }

  getPartnerManagementNavbarItems(): INavbarItem | null {
    const partnerManagement = {
      title: $localize`:Main menu navbar item name:Partner Management`,
      attributes: {
        'data-e2e': 'title-partner-management',
      },
      children: [],
    };

    if (this.petroService.showPartnerMenu()) {
      partnerManagement.children.push({
        title: $localize`:Main menu navbar item name:Partner Site Management`,
        attributes: {
          'data-e2e': 'link-partner',
        },
        ...this.getItemUrl('/administration/partners', [ApplicationRoot.Petro]),
      });
    }
    return partnerManagement;
  }

  getFinOpsNavbarItems(): INavbarItem | null {
    const finOpsItems = {
      title: $localize`:Main menu navbar item name:Acquiring administration`,
      attributes: {
        'data-e2e': 'title-acquiring-administration',
      },
      children: [],
    };

    if (this.showMerchantCountryConfiguration) {
      finOpsItems.children.push({
        title: $localize`:Main menu navbar item name:Merchant Country Configuration`,
        attributes: {
          'data-e2e': 'link-merchant-country-configuration',
        },
        ...this.getItemUrl('/fin-ops/manage-merchant-country-configuration', [
          ApplicationRoot.Payments,
        ]),
      });
    }

    if (this.showMerchantConfiguration) {
      finOpsItems.children.push({
        title: $localize`:Main menu navbar item name:Merchant Configuration`,
        attributes: {
          'data-e2e': 'link-merchant-configuration',
        },
        ...this.getItemUrl('/fin-ops/manage-merchant-configuration', [ApplicationRoot.Payments]),
      });
    }

    if (this.showMerchantSettlement) {
      finOpsItems.children.push({
        title: $localize`:Main menu navbar item name:Merchant Settlement`,
        attributes: {
          'data-e2e': 'link-merchant-settlement',
        },
        ...this.getItemUrl('/fin-ops/merchant-settlement', [ApplicationRoot.Payments]),
      });
    }
    return finOpsItems;
  }

  getAdvancedSettinsNavbarItems(): INavbarItem | null {
    const advancedSettingsItems = {
      title: $localize`:Main menu navbar item name:Advanced Settings`,
      attributes: {
        'data-e2e': 'title-advanced-settings',
      },
      children: [],
    };

    if (this.show3DSProviderContracts) {
      advancedSettingsItems.children.push({
        title: $localize`:Main menu navbar item name:3DS Contracts`,
        attributes: {
          'data-e2e': 'link-3ds-contracts',
        },
        ...this.getItemUrl('/administration/secure-provider-contracts', [ApplicationRoot.Payments]),
      });
    }

    if (this.showFraudPrevention) {
      advancedSettingsItems.children.push({
        title: $localize`:Main menu navbar item name:Fraud Prevention Services`,
        attributes: {
          'data-e2e': 'link-fraud-prevention-services',
        },
        ...this.getItemUrl('/administration/added-value-services/fraud-providers', [
          ApplicationRoot.Payments,
        ]),
      });
    }

    if (this.showPriceLists) {
      advancedSettingsItems.children.push({
        title: $localize`:Main menu navbar item name:Price Lists`,
        attributes: {
          'data-e2e': 'link-price-lists',
        },
        ...this.getItemUrl('/administration/pricelists', [ApplicationRoot.Payments]),
      });
    }

    if (this.showWallets) {
      advancedSettingsItems.children.push({
        title: $localize`:Main menu navbar item name:Wallets`,
        attributes: {
          'data-e2e': 'link-wallets',
        },
        ...this.getItemUrl('/administration/wallets', [ApplicationRoot.Payments]),
      });
    }

    if (this.showBlockingRulesets) {
      advancedSettingsItems.children.push({
        title: $localize`:Main menu navbar item name:Blocking Rulesets`,
        attributes: {
          'data-e2e': 'link-blocking-rules',
        },
        ...this.getItemUrl('/administration/blocking-rulesets', [ApplicationRoot.Payments]),
      });
    }

    if (this.showNotifications) {
      advancedSettingsItems.children.push({
        title: $localize`:Main menu navbar item name:Notifications`,
        attributes: {
          'data-e2e': 'link-notifications',
        },
        ...this.getItemUrl('/administration/notifications', [ApplicationRoot.Payments]),
      });
    }

    if (this.showCheckoutThemes) {
      advancedSettingsItems.children.push({
        title: $localize`:Main menu navbar item name:Checkout Themes`,
        attributes: {
          'data-e2e': 'link-checkout-themes',
        },
        ...this.getItemUrl('/administration/checkout-themes', [ApplicationRoot.Payments]),
      });
    }

    return advancedSettingsItems;
  }

  private getItemUrl(url: string, applications: ApplicationRoot[]): NavbarItemLink {
    return getNavbarItemLink(this.currentApplication, applications, this.languageRoute, url);
  }

  private get showOrganisations(): boolean {
    return this.restrictPermissionService.checkPermissions(organisationsPermissions['list'], [
      'read',
    ]);
  }

  private get showPpc(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.PaymentsPortalCore) &&
      this.restrictPermissionService.checkPermissions(
        PaymentContractsRoutePermissions.routePermissions[PaymentContractsRouteName.RouteName.List],
        ['read'],
      )
    );
  }

  private get showPois(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.PaymentsPortalCore) &&
      this.restrictPermissionService.checkPermissions(
        PointOfInteractionsRoutePermissions.routePermissions[
          PointOfInteractionsRouteNames.RouteName.List
        ],
        ['read'],
      )
    );
  }

  private get showUsers(): boolean {
    return this.restrictPermissionService.checkPermissions(
      UsersRoutePermissions[UsersRouteNames.List],
      ['read'],
    );
  }

  private get showDemoAccount(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.DemoAccount) &&
      this.restrictPermissionService.checkPermissions(
        DemoAccountRoutePermissions.routePermissions[DemoAccountRouteNames.RouteName.home],
        ['read'],
      )
    );
  }
  private get show3DSProviderContracts(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.ThreeDSecureProviderContracts) &&
      this.restrictPermissionService.checkPermissions(
        ThreeDSecureContractRoutePermissions.routePermissions[
          ThreeDSecureContractRouteNames.RouteName.List
        ],
        ['read'],
      )
    );
  }

  private get showMerchantCountryConfiguration(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.MerchantCountryConfiguration) &&
      this.restrictPermissionService.checkPermissions(
        ManageMerchantCountryConfigurationRoutePermissions.routePermissions[
          ManageMerchantCountryConfigurationRouteNames.RouteName.List
        ],
        ['read'],
      )
    );
  }

  private get showMerchantConfiguration(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.MerchantConfiguration) &&
      this.restrictPermissionService.checkPermissions(
        MerchantConfigurationRoutePermissions.routePermissions[
          MerchantConfigurationRouteNames.RouteName.List
        ],
        ['read'],
      )
    );
  }

  private get showMerchantSettlement(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.MerchantSettlement) &&
      this.restrictPermissionService.checkPermissions(
        MerchantSettlementRoutePermissions.routePermissions[
          MerchantSettlementRouteNames.RouteName.List
        ],
        ['read'],
      )
    );
  }

  private get showFraudPrevention(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.FraudPreventionService) &&
      this.restrictPermissionService.checkPermissions(
        FraudPreventionRoutePermissions.routePermissions[FraudPreventionRouteNames.RouteName.list],
        ['read'],
      )
    );
  }

  private get showPriceLists(): boolean {
    return this.restrictPermissionService.checkPermissions(
      PriceListsRoutePermissions.routePermissions[PriceListsRouteNames.RouteName.List],
      ['read'],
    );
  }

  private get showWallets(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.PaymentsPortalCore) &&
      this.restrictPermissionService.checkPermissions(
        WalletsRoutePermissions.routePermissions[WalletsRouteNames.RouteName.List],
        ['read'],
      )
    );
  }

  private get showBlockingRulesets(): boolean {
    return (
      this.restrictPermissionService.checkPermissions(
        BlockingRulesetsRoutePermissions.routePermissions[
          BlockingRulesetsRouteNames.RouteName.List
        ],
        ['read'],
      ) &&
      !this.isMerchantUnapprovedUser() &&
      this.featureToggleService.isFeatureActive(Features.BlockingRulesets)
    );
  }

  private get showNotifications(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.Notifications) &&
      this.restrictPermissionService.checkPermissions(NotificationsPermissions.list, ['read'])
    );
  }

  private get showCheckoutThemes(): boolean {
    return (
      this.featureToggleService.isFeatureActive(Features.CheckoutThemes) &&
      this.restrictPermissionService.checkPermissions(CheckoutThemesPermissions.list, ['read'])
    );
  }

  private isMerchantUnapprovedUser(): boolean {
    return this.authorizationService.getRoles().includes(UserRoles.MerchantUnapproved);
  }
}
