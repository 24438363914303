import { Injectable } from '@angular/core';
import { FederationType } from '@portal/entity-services/interfaces/src/lib/organisations/enums/federation-type.enum';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class FederationTypes extends SelectInputsList {
  keyValue = {
    [FederationType.None]: $localize`Not set`,
    [FederationType.FederatedSelf]: $localize`Users of this organization`,
    [FederationType.FederatedInheritDescendants]: $localize`Users of this and all sub-organization`,
    [FederationType.StandardInheritDescendants]: $localize`Exclude users from federation`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
