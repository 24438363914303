export enum AccountType {
  Debit = 'DEBIT',
  DebitAccount = 'DEBIT_ACCOUNT',
  Credit = 'CREDIT',
  CreditAccount = 'CREDIT_ACCOUNT',
  Default = 'DEFAULT',
  Cheque = 'CHEQUE',
  Current = 'CURRENT',
  Ebt = 'EBT',
  Investment = 'INVESTMENT',
  Msison = 'MSISDN',
  PrivateLabel = 'PRIVATE_LABEL',
  Saving = 'SAVING',
  StoredValue = 'STORED_VALUE',
  Universal = 'UNIVERSAL',
  Fleet = 'FLEET',
  FleetAccount = 'FLEET_ACCOUNT',
}
