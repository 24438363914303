import { MimeTypes } from '../../..';

export abstract class FormDataCreator {
  createFormDataWithFiles(files: File[], type: MimeTypes): FormData {
    const formData: FormData = new FormData();
    for (const file of files) {
      formData.append('files', new Blob([file], { type: type }), file.name);
    }
    return formData;
  }
  createFormDataImageFile(files: File[]): FormData {
    const formData: FormData = new FormData();
    for (const file of files) {
      formData.append('files', new Blob([file], { type: file.type }), file.name);
    }
    return formData;
  }
}
