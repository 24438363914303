<div *ngIf="showTitle" class="field" data-e2e="collapsiblePanel-title">
  <button
    (click)="toggle()"
    [attr.data-e2e]="e2eToggleName"
    type="button"
    class="button is-transparent is-empty is-text"
    title="Toggle Panel"
    i18n-title
  >
    <ng-container *ngIf="expanded">
      <span class="icon"><i class="fas fa-minus"></i></span>
      <span data-e2e="expand">{{ expandedTitle }}</span>
    </ng-container>
    <ng-container *ngIf="!expanded">
      <span class="icon"><i class="fas fa-plus"></i></span>
      <span data-e2e="collapse">{{ collapsedTitle }}</span>
    </ng-container>
  </button>
</div>

<ng-content *ngIf="expanded"></ng-content>
