import { Injectable } from '@angular/core';
import { TransactionType } from '@portal/entity-services/interfaces/src';
import { ISearchCriteria, SearchOperator, SearchSelector } from '@portal/shared/ui/filter/src';
import { CriteriaConverter } from '../../../shared/interfaces/criteria-converter.interface';
import { TransactionState } from '../enums/transaction-state.enum';
import { SearchCriteriaService } from './search-criteria.service';

@Injectable({
  providedIn: 'root',
})
export class SearchCriteriaConvertService {
  private converters: CriteriaConverter[] = [
    {
      condition: (criteria: ISearchCriteria) => {
        return criteria.selector === SearchSelector.DisplayTransactionState;
      },
      convert: (criteria: ISearchCriteria) => {
        const criterias = [];
        const disputedStatuses = [
          TransactionState.Appealed,
          TransactionState.Initiated,
          TransactionState.ReasonUpdated,
        ];
        const disputedStatusesArgument = `(${disputedStatuses.join(',')})`;
        if (criteria.values.has(TransactionState.Disputed)) {
          const disputeStateCriteria: ISearchCriteria = {
            argument: disputedStatusesArgument,
            selector: SearchSelector.DisputeStatuses,
            operator: SearchOperator.In,
          };

          const joinedStatesCriteria = this.searchCriteriaService.joinCriterias([
            criteria,
            disputeStateCriteria,
          ]);

          criterias.push(joinedStatesCriteria);
        } else {
          const excludeDisputeCriteria: ISearchCriteria = {
            argument: disputedStatusesArgument,
            selector: SearchSelector.DisputeStatuses,
            operator: SearchOperator.Out,
          };
          // search criteria with =out= operator does not work with multiple values on backend
          // use query string instead to make it works
          const excludeDisputeQueryString =
            this.searchCriteriaService.criteriaToQueryString(excludeDisputeCriteria);
          criterias.push({
            argument: excludeDisputeQueryString,
          });
          criterias.push({
            argument: criteria.argument,
            selector: criteria.selector,
            operator: criteria.operator,
          });
        }

        return criterias;
      },
    },
    {
      condition: (criteria: ISearchCriteria) => {
        return criteria.selector === SearchSelector.MaskedCardNumber;
      },
      convert: (crit: ISearchCriteria) => {
        return [
          {
            argument: `^${crit.argument}`,
            selector: crit.selector,
            operator: crit.operator,
          },
        ];
      },
    },
    {
      condition: (criteria: ISearchCriteria) => {
        return criteria.selector === SearchSelector.CardNumber;
      },
      convert: (crit: ISearchCriteria) => {
        return [
          {
            argument: this.changeCardNumberInputToRegexString(crit.argument),
            selector: SearchSelector.MaskedCardNumber,
            operator: crit.operator,
          },
        ];
      },
    },
    {
      condition: (criteria: ISearchCriteria) => {
        // remove (From,To) suffixes that added by FilterDateComponent
        const dateSuffixRegexp = /(From|To)$/;
        return dateSuffixRegexp.test(criteria.selector);
      },
      convert: (criteria: ISearchCriteria) => {
        return [
          {
            argument: criteria.argument,
            selector: criteria.selector.replace(/(From|To)$/, ''),
            operator: criteria.operator,
          },
        ];
      },
    },
    {
      condition: (criteria: ISearchCriteria) => {
        return criteria.selector === SearchSelector.RelatedTransaction;
      },
      convert: (criteria: ISearchCriteria) => {
        // Refund, Payout, Chargeback are different types of transactions that are not loaded by default
        // we have to specify it explicitly to get them
        const transactionTypes = [
          TransactionType.Refund,
          TransactionType.Payout,
          TransactionType.Chargeback,
        ];
        const transactionTypeCriteria: ISearchCriteria = {
          argument: this.searchCriteriaService.joinArguments(transactionTypes),
          selector: SearchSelector.TransactionType,
          operator: SearchOperator.In,
        };

        const joinedCriteria = this.searchCriteriaService.joinCriterias([
          criteria,
          transactionTypeCriteria,
        ]);

        return [joinedCriteria];
      },
    },
    {
      condition: (criteria: ISearchCriteria) => {
        return criteria.selector === SearchSelector.TerminalId;
      },
      convert: (criteria: ISearchCriteria) => {
        const contractPoiIdCriteria: ISearchCriteria = {
          argument: criteria.argument,
          selector: SearchSelector.ContractTerminalId,
          operator: criteria.operator,
        };
        const joinedCriteria = this.searchCriteriaService.joinCriterias([
          criteria,
          contractPoiIdCriteria,
        ]);
        return [joinedCriteria];
      },
    },
    {
      condition: (criteria: ISearchCriteria) => {
        return criteria.selector === SearchSelector.ReUseToken;
      },
      convert: (criteria: ISearchCriteria) => {
        const reuseTokenTypes = [
          SearchSelector.ReUseToken,
          SearchSelector.InstrumentReuseToken,
          SearchSelector.TokenizationReuseToken,
        ];
        const criterias = reuseTokenTypes.map((selector: SearchSelector) => ({
          selector,
          argument: criteria.argument,
          operator: SearchOperator.Equal,
        }));
        const joinedCriteria = this.searchCriteriaService.joinCriterias(criterias);
        return [joinedCriteria];
      },
    },
    {
      condition: (criteria: ISearchCriteria) => {
        return criteria.selector === SearchSelector.ReuseTokenContext;
      },
      convert: (criteria: ISearchCriteria) => {
        const argument = criteria.argument.substring(1, criteria.argument.length - 1).split(',');
        const criterias = argument.map((tokenType) => {
          const selector = tokenType;
          return { selector: selector, argument: null, operator: SearchOperator.NotEqual };
        });

        const joinedCriteria = this.searchCriteriaService.joinCriterias(criterias);
        return [joinedCriteria];
      },
    },
    {
      condition: (criteria: ISearchCriteria) => {
        return criteria.selector === SearchSelector.TokenizationReuseToken;
      },
      convert: (criteria: ISearchCriteria) => {
        const criterias = [SearchSelector.TokenizationReuseToken].map(
          (selector: SearchSelector) => ({
            selector,
            argument: criteria.argument,
            operator: SearchOperator.Equal,
          }),
        );
        const joinedCriteria = this.searchCriteriaService.joinCriterias(criterias);
        return [joinedCriteria];
      },
    },
    {
      condition: (criteria: ISearchCriteria) => {
        return criteria.selector === SearchSelector.AccountType;
      },
      convert: (criteria: ISearchCriteria) => {
        const acquirerAccountTypeCriteria: ISearchCriteria = {
          argument: criteria.argument,
          selector: SearchSelector.AcquirerAccountType,
          operator: criteria.operator,
        };
        const joinedCriteria = this.searchCriteriaService.joinCriterias([
          criteria,
          acquirerAccountTypeCriteria,
        ]);

        return [joinedCriteria];
      },
    },
    {
      condition: (criteria: ISearchCriteria) => {
        return criteria.selector === SearchSelector.SchemeReference;
      },
      convert: (criteria: ISearchCriteria) => {
        const schemeReferenceDataCriteria: ISearchCriteria = {
          argument: criteria.argument,
          selector: SearchSelector.SchemeReferenceData,
          operator: criteria.operator,
        };
        const joinedCriteria = this.searchCriteriaService.joinCriterias([
          criteria,
          schemeReferenceDataCriteria,
        ]);

        return [joinedCriteria];
      },
    },
  ];

  private defaultConverter: CriteriaConverter = {
    condition: () => true,
    convert: (criteria: ISearchCriteria) => {
      return [
        {
          argument: criteria.argument,
          selector: criteria.selector,
          operator: criteria.operator,
        },
      ];
    },
  };

  constructor(private searchCriteriaService: SearchCriteriaService) {}

  convert(criterias: ISearchCriteria[]): ISearchCriteria[] {
    return criterias.reduce((output, criteria) => {
      const criteriaConverter =
        this.converters.find((converter) => converter.condition(criteria)) || this.defaultConverter;
      output.push(...criteriaConverter.convert(criteria));

      return output;
    }, []);
  }

  private changeCardNumberInputToRegexString(input: string): string {
    let result = '';
    switch (input.length) {
      case 7:
        result = `^${input.replace('*', '')}`;
        break;
      case 5:
        result = `${input.replace('*', '')}$`;
        break;
      case 11:
        result = `^${input.replace('*', '.*')}$`;
        break;
    }

    return result;
  }
}
