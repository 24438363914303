export enum WalletType {
  ApplePay = 'APPLE_PAY',
  ApplePayInApp = 'APPLE_PAY_IN_APP',
  ApplePayWeb = 'APPLE_PAY_WEB',
  GooglePay = 'GOOGLE_PAY',
  VippsEcom = 'VIPPS_ECOM',
  MobilePayEcom = 'MOBILEPAY_ECOM',
  Atomicwallet = 'ATOMICWALLET',
  Bitcoincom = 'BITCOINCOM',
  Bitfinexoffchain = 'BITFINEXOFFCHAIN',
  Bitpay = 'BITPAY',
  Bitstampoffchain = 'BITSTAMPOFFCHAIN',
  Blockchainwallet = 'BLOCKCHAINWALLET',
  Bravebrowser = 'BRAVEBROWSER',
  Brdwallet = 'BRDWALLET',
  Coinbase = 'COINBASE',
  Coinbaseapp = 'COINBASEAPP',
  Coinbasewallet = 'COINBASEWALLET',
  Coinomi = 'COINOMI',
  Copay = 'COPAY',
  Edgewallet = 'EDGEWALLET',
  Electroncash = 'ELECTRONCASH',
  Electrumwallet = 'ELECTRUMWALLET',
  Enjinwallet = 'ENJINWALLET',
  Exoduswallet = 'EXODUSWALLET',
  Geminioffchain = 'GEMINIOFFCHAIN',
  Kraken = 'KRAKEN',
  Krakenoffchain = 'KRAKENOFFCHAIN',
  Kucoinoffchain = 'KUCOINOFFCHAIN',
  Ledgerwallet = 'LEDGERWALLET',
  Metamask = 'METAMASK',
  Myceliumwallet = 'MYCELIUMWALLET',
  Trezorwallet = 'TREZORWALLET',
  Trustwallet = 'TRUSTWALLET',
  UndeterminedMobileWallet = 'UNDETERMINED_MOBILE_WALLET',
}
