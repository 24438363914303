<div class="dropdown" [class.is-active]="isOpen">
  <div class="dropdown-trigger">
    <button
      [attr.aria-expanded]="isOpen"
      *ngIf="!searchInput"
      class="button is-chip is-size-7-mobile"
      [class.is-applied]="selectedEntities.size"
      [class.is-active]="isOpen"
      data-e2e="button-entity-filter"
      (click)="toggleFilter()"
      type="button"
      title="{{ filterName }}"
    >
      <span
        >{{ filterName }}
        {{ dateHeaderText }}
      </span>
      <span class="icon">
        <i class="fas fa-chevron-down"></i>
      </span>
    </button>
    <div *ngIf="searchInput" (click)="toggleFilter()" class="date-picker-control">
      <div class="wrapper" (click)="clearField()"></div>
      <input
        type="search"
        [disabled]="isInputDisabled"
        [value]="selectedValue"
        [placeholder]="filterName"
        class="search-input"
        [class.is-applied]="selectedEntities.size"
        [class.is-active]="isOpen"
        style="cursor: pointer"
      />
    </div>
  </div>
  <div class="dropdown-menu">
    <div class="dropdown-content">
      <div class="dropdown-item" *ngIf="!searchInput">
        <ng-container *ngFor="let entity of dateTypeSelections">
          <portal-search-filter-radio
            [id]="entity.id"
            [text]="entity.text"
            [radioName]="filterName"
            [selectedEntities]="selectedDateType"
            (selected)="onDateTypeSelection(entity.id)"
          >
          </portal-search-filter-radio>
        </ng-container>
      </div>

      <div class="dropdown-item">
        <div *ngIf="dateTypeSelection === dateTypes.Predefined">
          <ng-container *ngFor="let entity of selectors">
            <portal-search-filter-radio
              [id]="entity.id"
              [text]="entity.text"
              [radioName]="radioName"
              [selectedEntities]="selectedRadioButton"
              (selected)="onRadioFilterApplied($event)"
            >
            </portal-search-filter-radio>
          </ng-container>
        </div>
        <portal-date-picker
          *ngIf="dateTypeSelection === dateTypes.Custom"
          [fromDate]="fromDate"
          [toDate]="toDate"
          (filterApplied)="onFilterApplied()"
          (fromDateApplied)="onFromDateApplied($event)"
          (toDateApplied)="onToDateApplied($event)"
        ></portal-date-picker>
      </div>

      <hr class="dropdown-divider" />

      <div class="dropdown-item">
        <button
          class="button is-primary"
          data-e2e="button-filter"
          (click)="onFilterApplied()"
          [disabled]="!areInputsValid()"
          type="button"
          i18n
          title="Apply"
          i18n-title
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</div>
