import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { IApp } from '@portal/marketplace/api-interfaces/src';
import { IAppState, IAppStore, IAppStoreMMandDev } from './apps.store';

@Injectable({
  providedIn: 'root',
})
export class IAppQuery extends QueryEntity<IAppState, IApp> {
  // eslint-disable-next-line
  constructor(protected store: IAppStore, private routerQuery: RouterQuery) {
    super(store);
  }
  metadataCount$ = this.select((state) => {
    return state.metadata.count;
  });
}

@Injectable({
  providedIn: 'root',
})
export class IAppQueryMMandDev extends QueryEntity<IAppState, IApp> {
  // eslint-disable-next-line
  constructor(protected store: IAppStoreMMandDev, private routerQuery: RouterQuery) {
    super(store);
  }
  loading$ = this.selectLoading();
}
