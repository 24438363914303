<div class="field is-grouped p-3">
  <div class="control">
    <label>{{ text }}</label>
  </div>
  <div class="control">
    <label class="toggle">
      <input
        id="toggle"
        type="checkbox"
        data-e2e="select-all-slider"
        [checked]="switchOn"
        (change)="toggleOnOff()"
      />
      <span></span>
    </label>
  </div>
</div>
