export type TTagName = string;
export type TTagType = 'MERCHANT_DEVICE' | 'ACQUIRER_BUNDLE' | 'ACQUIRER_APP';
export type TTagColor = 'actionable' | 'warning' | 'success' | 'error' | 'special' | 'default';
export enum TagRejectMessages {
  'entity.tag.with.type.already.exists' = 'The entity tag is already exists.',
  'Size.data.description' = 'The tag description cannot contain more than 255 characters.',
}

export interface ITagModel {
  id?: TTagName;
  name?: TTagName;
  tagType: TTagType;
  color?: TTagColor;
  description?: string;
}

export interface ITag extends ITagModel {
  id: string;
  name: TTagName;
  timeCreated: string;
  timeUpdated: string;
  createdBy: string;
  updatedBy: string;
  entityUid: string;
}
