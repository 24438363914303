import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TreeModule } from '@circlon/angular-tree-component';
import { HighlightSearchPipe, PipesModule, RemoveUnderscorePipe } from '@portal/shared/pipes';
import { ClickOutsideModule } from '@portal/shared/ui/click-outside';
import { DatePickerModule } from '@portal/shared/ui/date-picker';
import { OnKeyPressModule } from '@portal/shared/ui/on-key-press';
import { PortalTreeModule } from '@portal/shared/ui/tree';
import { AddFilterModule } from './add-filter/add-filter.module';
import { FilterAutoCompleteInputComponent } from './autocomplete/search-autocomplete-input.component';
import { SearchBoxComponent } from './box/search-box.component';
import { FilterCheckboxComponent } from './checkbox/search-checkbox.component';
import { ButtonGroupFilterModule } from './components/button-group-filter/button-group-filter.module';
import { ButtonGroupMultiComponent } from './components/button-group-filter/button-group-multi/button-group-multi.component';
import { ButtonGroupSingleComponent } from './components/button-group-filter/button-group-single/button-group-single.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import { DateRangeFilterModule } from './components/date-range-filter/date-range-filter.module';
import { MultipleSelectionFilterComponent } from './components/multiple-selection-filter/multiple-selection-filter.component';
import { OneSelectionFilterComponent } from './components/one-selection-filter/one-selection-filter.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { SearchTextFilterComponent } from './components/search-text-filter/search-text-filter.component';
import { ToggleFilterComponent } from './components/toggle-filter/toggle-filter.component';
import { ToggleFilterModule } from './components/toggle-filter/toggle-filter.module';
import { FilterCountryComponent } from './country/country.component';
import { FilterEntityComponent } from './entity/entity.component';
import { FiltersComponent } from './filters/filters.component';
import { HierarchyFilterComponent } from './hierarchy-filter/hierarchy-filter.component';
import { HierarchySingleFilterComponent } from './hierarchy-single-filter/hierarchy-single-filter.component';
import { HierarchyComponent } from './hierarchy/hierarchy.component';
import { FilterLanguageComponent } from './language/language.component';
import { PaymentTypeMultiSelectComponent } from './payment-type-multi-select/payment-type-multi-select.component';
import { PaymentTypeComponent } from './payment-type/payment-type.component';
import { FilterRadioComponent } from './radio/search-radio.component';
import { ServiceProviderMultiSelectComponent } from './service-provider-multi-select/service-provider-multi-select.component';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { SwitchToggleComponent } from './switch-toggle/switch-toggle.component';
import { FilterUserStatusComponent } from './user-status/user-status.component';
import { WrapperComponent } from './wrapper/wrapper.component';
import { LegendStatusComponent } from './legend-status/legend-status.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SpinnerModule } from '@portal/shared/ui/spinner';
import { SingleDropdownModule } from './components/single-dropdown/single-dropdown.module';
import { TreeFilterComponent } from './tree-filter/tree-filter.component';
import { PortalFilterComponent } from './portal-filter/portal-filter.component';
import { UsersFilterComponent } from './users-filter/users-filter.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    DatePickerModule,
    ClickOutsideModule,
    OnKeyPressModule,
    AddFilterModule,
    PortalTreeModule,
    DateRangeFilterModule,
    ToggleFilterModule,
    ButtonGroupFilterModule,
    TreeModule,
    MatTooltipModule,
    SpinnerModule,
    SingleDropdownModule,
  ],
  declarations: [
    FilterUserStatusComponent,
    WrapperComponent,
    SearchBoxComponent,
    FilterCheckboxComponent,
    FilterRadioComponent,
    FilterAutoCompleteInputComponent,
    FilterEntityComponent,
    FilterCountryComponent,
    MultipleSelectionFilterComponent,
    OneSelectionFilterComponent,
    SearchFilterComponent,
    ServiceProviderComponent,
    PaymentTypeComponent,
    SearchTextFilterComponent,
    FiltersComponent,
    HierarchyFilterComponent,
    HierarchyComponent,
    HierarchySingleFilterComponent,
    DateFilterComponent,
    ServiceProviderMultiSelectComponent,
    PaymentTypeMultiSelectComponent,
    FilterLanguageComponent,
    SwitchToggleComponent,
    LegendStatusComponent,
    TreeFilterComponent,
    PortalFilterComponent,
    UsersFilterComponent,
  ],
  exports: [
    FilterUserStatusComponent,
    WrapperComponent,
    SearchBoxComponent,
    FilterCheckboxComponent,
    FilterRadioComponent,
    FilterAutoCompleteInputComponent,
    FilterEntityComponent,
    FilterCountryComponent,
    MultipleSelectionFilterComponent,
    RemoveUnderscorePipe,
    HighlightSearchPipe,
    OneSelectionFilterComponent,
    DateFilterComponent,
    SearchFilterComponent,
    ServiceProviderComponent,
    PaymentTypeComponent,
    SearchTextFilterComponent,
    FiltersComponent,
    HierarchyFilterComponent,
    HierarchyComponent,
    HierarchySingleFilterComponent,
    TreeFilterComponent,
    DateRangeFilterComponent,
    DateFilterComponent,
    ToggleFilterComponent,
    ButtonGroupMultiComponent,
    ButtonGroupSingleComponent,
    ServiceProviderMultiSelectComponent,
    PaymentTypeMultiSelectComponent,
    FilterLanguageComponent,
    SwitchToggleComponent,
    LegendStatusComponent,
    SingleDropdownModule,
    PortalFilterComponent,
    UsersFilterComponent,
  ],
})
export class SharedUiFilterModule {}
