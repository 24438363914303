<ng-container *ngIf="currentItem">
  <a class="navbar-item has-text-primary has-icons" (click)="back()" tabindex="0">
    <span class="icon"><i class="fas fa-chevron-left"></i></span>
    <span i18n>Back</span>
  </a>
  <a class="navbar-item title is-4 is-marginless">
    {{ currentItem.title }}
  </a>
</ng-container>
<ng-container *ngFor="let navbarItem of currentItem?.children || navbarItems; let i = index">
  <ng-container *ngIf="!navbarItem.children; else itemWithChildren">
    <a
      *ngIf="navbarItem.routerLink"
      class="navbar-item"
      (blur)="close(i, currentItem?.children || navbarItems)"
      [routerLink]="navbarItem.routerLink"
      [restrict-permission]="navbarItem.restrictPermissions || ['read', 'self-read']"
      [helpersAttributes]="navbarItem.attributes"
      (click)="onNavbarItemClick($event, navbarItem)"
    >
      {{ navbarItem.title }}
    </a>
    <ng-container *ngIf="navbarItem.href">
      <a
        *restrictPermission="navbarItem.restrictPermissions || ['read', 'self-read']"
        class="navbar-item"
        (blur)="close(i, currentItem?.children || navbarItems)"
        [href]="navbarItem.href"
        [helpersAttributes]="navbarItem.attributes"
        (click)="onNavbarItemClick($event, navbarItem)"
      >
        {{ navbarItem.title }}
      </a>
    </ng-container>
    <ng-container *ngIf="!navbarItem.href && !navbarItem.routerLink">
      <a
        *restrictPermission="navbarItem.restrictPermissions || ['read', 'self-read']"
        class="navbar-item"
        (blur)="close(i, currentItem?.children || navbarItems)"
        [helpersAttributes]="navbarItem.attributes"
        (click)="onNavbarItemClick($event, navbarItem)"
      >
        {{ navbarItem.title }}
      </a>
    </ng-container>
  </ng-container>
  <ng-template #itemWithChildren>
    <a
      class="navbar-item has-icons"
      (click)="forward(navbarItem)"
      tabindex="0"
      (keyup.enter)="forward(navbarItem)"
    >
      <span>{{ navbarItem.title }}</span>
      <span class="icon">
        <i class="fas fa-chevron-right"></i>
      </span>
    </a>
  </ng-template>
</ng-container>
