<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="box">
    <h2 class="title is-3" i18n>General information</h2>
    <div class="columns">
      <div class="column is-half">
        <fieldset class="field">
          <div class="label" i18n>Organisation Type</div>
          <div class="control">
            <label
              class="radio"
              *ngFor="let type of entityTypeList"
              attr.data-e2e="entityType-{{ type.id }}"
            >
              <input type="radio" name="entityType" [value]="type.id" [formControl]="entityType" />
              <span>{{ type.text }}</span>
            </label>
            <portal-form-error [formInput]="entityType" errorId="entityType"></portal-form-error>
          </div>
        </fieldset>
        <label class="checkbox" *ngIf="showPoiCrossEntityAccess()">
          <input
            type="checkbox"
            id="poiCrossEntityAccessAllowed"
            name="poiCrossEntityAccessAllowed"
            [formControl]="poiCrossEntityAccessAllowed"
          />
          <span i18n>Cross Entity Access Allowed</span>
        </label>
      </div>
    </div>

    <div class="field columns">
      <div class="column">
        <portal-input-count
          name="Organisation Name"
          [control]="name"
          [validators]="validators"
          [invalid]="isInvalid(name)"
          [e2eName]="'input-name'"
          [maxLength]="100"
          class="field"
          i18n-name
        ></portal-input-count>
      </div>
    </div>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label" i18n>Parent organisation</div>
          <portal-hierarchy-single-select
            [control]="isUserAssignedEntitySelected ? emptyParentEntityUid : parentEntityUid"
            [organisations]="organisations"
            [rootEntityIdForParentUpdate]="rootEntityIdForParentUpdate"
            [merchantCompanyEntityCount]="merchantCompanyEntityCount"
            [hideOrganisation]="organisation"
            [showTrashCanEntities]="showTrashCanEntities && trashCanOrgsPresent.length > 0"
          ></portal-hierarchy-single-select>
          <portal-form-error
            [formInput]="parentEntityUid"
            errorId="parentEntityUid"
          ></portal-form-error>
        </label>
      </div>
    </div>

    <div class="field columns">
      <div class="column">
        <portal-input-count
          name="Description"
          [control]="description"
          [invalid]="isInvalid(description)"
          [e2eName]="'input-description'"
          [optional]="true"
          [maxLength]="255"
          class="field"
          i18n-name
        ></portal-input-count>
      </div>
    </div>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label">
            <span i18n>Merchant Url</span>
            <span>
              &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
            </span>
          </div>
          <div class="control">
            <input
              class="input"
              formControlName="url"
              data-e2e="input-url"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(url) }"
            />
            <portal-form-error [formInput]="url" errorId="url"></portal-form-error>
          </div>
        </label>
      </div>
    </div>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label">
            <span i18n>Merchant Logo Url</span>
            <span>
              &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
            </span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="merchantLogoUrl"
              data-e2e="input-merchantLogoUrl"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(merchantLogoUrl) }"
            />
            <portal-form-error
              [formInput]="merchantLogoUrl"
              errorId="merchantLogoUrl"
            ></portal-form-error>
          </div>
        </label>
      </div>
    </div>

    <div class="field" *ngIf="businessType.value === businessTypePhysical">
      <div class="is-half">
        <label class="field">
          <div class="label">
            <span i18n>Legal name</span>
            <span>
              &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
            </span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="legalName"
              data-e2e="input-legalName"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(legalName) }"
            />
            <portal-form-error [formInput]="legalName" errorId="legalName"></portal-form-error>
          </div>
        </label>
      </div>
    </div>

    <portal-select-control
      name="Legal entity type"
      placeholder="Select legal entity type"
      [optional]="true"
      [control]="legalEntity"
      [invalid]="isInvalid(legalEntity)"
      [options]="legalEntityTypesList"
      [e2eName]="'input-legalEntity'"
      [e2eErrorName]="'legalEntity'"
      class="field"
      i18n-name
      i18n-placeholder
    ></portal-select-control>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label">
            <span i18n>Verifone ID</span>
            <span>
              &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
            </span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="altVfiEntityId"
              data-e2e="input-altVfiEntityId"
              type="text"
            />
          </div>
          <portal-form-error
            [formInput]="altVfiEntityId"
            errorId="altVfiEntityId"
          ></portal-form-error>
        </label>
      </div>
    </div>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label">
            <span i18n>Token entity ID</span>
            <span>
              &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
            </span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="tokenEntityId"
              data-e2e="input-tokenEntityId"
              type="text"
            />
          </div>
        </label>
      </div>
    </div>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label">
            <span i18n>Merchant category code</span>
            <span>
              &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
            </span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="mcc"
              data-e2e="input-mcc"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(mcc) }"
            />
          </div>
          <portal-form-error [formInput]="mcc" errorId="mcc"></portal-form-error>
        </label>
      </div>
    </div>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label">
            <span i18n>Tax registration number</span>
            <span>
              &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
            </span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="taxRegistrationNumber"
              data-e2e="input-taxRegistrationNumber"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(taxRegistrationNumber) }"
            />
          </div>
          <portal-form-error
            [formInput]="taxRegistrationNumber"
            errorId="taxRegistrationNumber"
          ></portal-form-error>
        </label>
      </div>
    </div>

    <div class="field">
      <div class="label">
        <span i18n>Business Identifier Types</span>
        <span>
          &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
        </span>
      </div>
      <div>
        <ng-select
          [items]="businessIdentifierList"
          bindValue="id"
          bindLabel="text"
          [multiple]="true"
          [closeOnSelect]="false"
          [formControl]="businessIdentifierTypesControl"
          data-e2e="select-businessIdentifierTypes"
          dropdownPosition="top"
          notFoundText="No items found"
          i18n-notFoundText
        >
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <label class="checkbox is-pulled-left">
              <input
                id="item-{{ index }}"
                type="checkbox"
                [ngModel]="item$.selected"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="item$.disabled"
              />
              <span>{{ item.text }}</span>
            </label>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="field" *ngFor="let businessIdentifier of businessIdentifiers.controls">
      <div class="is-half">
        <label class="field">
          <div class="label">
            <span i18n>
              {{ getBusinessIdentifierTypeLabel(businessIdentifier.get('identifierType').value) }}
              Value
            </span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="businessIdentifier.get('value')"
              [attr.data-e2e]="'input-' + businessIdentifier.get('identifierType').value + 'Value'"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(businessIdentifier.get('value')) }"
            />
          </div>
          <portal-form-error
            [formInput]="businessIdentifier.get('value')"
            errorId="businessIdentifierValue"
          ></portal-form-error>
        </label>
      </div>
    </div>

    <div class="field columns">
      <div class="column">
        <portal-input-count
          name="Site Reference"
          [control]="siteReferenceId"
          [validators]="siteReferenceIdValidators"
          [invalid]="isInvalid(siteReferenceId)"
          [optional]="true"
          [e2eName]="'input-siteReferenceId'"
          [maxLength]="256"
          class="field"
          i18n-name
        ></portal-input-count>
      </div>
    </div>
    <div class="field columns">
      <div class="column">
        <portal-input-count
          name="Company Group Reference Identifier"
          [control]="companyGroupReferenceId"
          [invalid]="isInvalid(companyGroupReferenceId)"
          [optional]="true"
          [e2eName]="'input-companyGroupReferenceId'"
          [maxLength]="256"
          data-e2e="input-companyGroupReferenceId"
          class="field"
          i18n-name
        ></portal-input-count>
      </div>
    </div>

    <portal-currencies-control
      name="Billing Currency"
      [optionalText]="true"
      [control]="verifoneBillingCurrency"
      [invalid]="isInvalid(verifoneBillingCurrency)"
      [currencies]="currenciesList"
      [multiple]="false"
      [closeOnSelect]="true"
      [e2eName]="'select-verifoneBillingCurrency'"
      [e2eErrorName]="'verifoneBillingCurrency'"
      (remove)="removeCurrency($event)"
      class="field"
      i18n-name
    ></portal-currencies-control>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label">
            <span i18n>FNS number</span>
            <span>
              &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
            </span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="merchantFNSNumber"
              data-e2e="input-merchantFNSNumber"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(merchantFNSNumber) }"
            />
          </div>
          <portal-form-error
            [formInput]="merchantFNSNumber"
            errorId="merchantFNSNumber"
          ></portal-form-error>
        </label>
      </div>
    </div>

    <label class="field" *ngIf="poiCutoverConfigurations?.length > 0">
      <div class="label">
        <span i18n>POI Cutover Strategy ID</span>
        &nbsp;<span>
          <span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
        </span>
      </div>
      <div class="select" [ngClass]="{ 'is-danger': isInvalid(defaultPoiCutoverConfigurationId) }">
        <select
          [formControl]="defaultPoiCutoverConfigurationId"
          data-e2e="input-defaultPoiCutoverConfigurationId"
        >
          <option value="" i18n>Select strategy Id</option>
          <option
            *ngFor="let configuration of poiCutoverConfigurations"
            [value]="configuration.poiCutoverConfigurationId"
          >
            {{ configuration.poiCutoverConfigurationId }}
          </option>
        </select>
      </div>
      <portal-form-error
        [formInput]="defaultPoiCutoverConfigurationId"
        errorId="defaultPoiCutoverConfigurationId"
      ></portal-form-error>
    </label>

    <portal-select-control
      name="Industry"
      placeholder="Select industry"
      [optional]="true"
      [control]="industry"
      [invalid]="isInvalid(industry)"
      [options]="industryTypesList"
      [e2eName]="'input-industry'"
      [e2eErrorName]="'industry'"
      class="field"
      i18n-name
      i18n-placeholder
    ></portal-select-control>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label">
            <span i18n>Creditor Reference Type</span>
            <span>
              &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
            </span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="creditorReferenceTypeId"
              data-e2e="input-creditorReferenceTypeId"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(creditorReferenceTypeId) }"
            />
          </div>
          <portal-form-error
            [formInput]="creditorReferenceTypeId"
            errorId="creditorReferenceTypeId"
          ></portal-form-error>
        </label>
      </div>
    </div>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label">
            <span i18n>Creditor Reference Custom Specifier</span>
            <span>
              &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
            </span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="creditorReferenceCustomSpecifier"
              data-e2e="input-creditorReferenceCustomSpecifier"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(creditorReferenceCustomSpecifier) }"
            />
          </div>
          <portal-form-error
            [formInput]="creditorReferenceCustomSpecifier"
            errorId="creditorReferenceCustomSpecifier"
          ></portal-form-error>
        </label>
      </div>
    </div>

    <div class="field">
      <div class="is-half">
        <label class="field">
          <div class="label">
            <span i18n>Batch Theme Id</span>
            <span>
              &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
            </span>
          </div>
          <div class="control">
            <input
              class="input"
              [formControl]="batchThemeId"
              data-e2e="input-batchThemeId"
              type="text"
              [ngClass]="{ 'is-danger': isInvalid(batchThemeId) }"
            />
          </div>
          <portal-form-error [formInput]="batchThemeId" errorId="batchThemeId"></portal-form-error>
        </label>
      </div>
    </div>

    <div class="mb-3" *ngIf="isVerifoneAdmin">
      <portal-select-control
        name="Federation login"
        placeholder="Select federationType"
        [optional]="true"
        [control]="federationType"
        [invalid]="isInvalid(federationType)"
        [options]="federationTypesList"
        [e2eName]="'input-federationType'"
        [e2eErrorName]="'federationType'"
        class="field"
        i18n-name
        i18n-placeholder
      ></portal-select-control>
    </div>

    <div class="field" *ngIf="hasSftpDeliveryFeature">
      <label class="field">
        <div class="label">
          <span i18n>SFTP connection</span>
          <span>
            &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
          </span>
        </div>
        <div class="control">
          <input
            class="input"
            [formControl]="sftpConnection"
            data-e2e="input-sftpConnection"
            type="text"
            [ngClass]="{ 'is-danger': isInvalid(sftpConnection) }"
          />
        </div>
        <portal-form-error
          [formInput]="sftpConnection"
          errorId="sftpConnection"
        ></portal-form-error>
      </label>
    </div>
    <div class="box__separator"></div>

    <ng-container *ngIf="showFeatureManagement">
      <h2 class="title is-3" i18n>Feature access</h2>
      <div class="label" i18n>Features</div>
      <div class="field">
        <portal-input-dropdown
          *ngIf="featuresOptions"
          [items]="featuresOptions"
          [placeholder]="featureDropDownPlaceholder"
          [rootDepth]="0"
          (selectItems)="featureSelected($event)"
        ></portal-input-dropdown>
      </div>
      <ng-container>
        <div class="label" i18n>Features enabled for sub-organisations</div>
        <portal-input-dropdown
          [items]="featuresWithInheritanceOptions"
          [placeholder]="featureDropDownPlaceholder"
          [rootDepth]="0"
          (selectItems)="inheritanceSelected($event)"
        ></portal-input-dropdown>
        <span class="has-text-grey has-text-weight-normal" i18n
          >The selected features will be applied to all sub-organisations</span
        >
      </ng-container>
      <div class="box__separator"></div>
    </ng-container>

    <ng-container *ngIf="errorInFetchingFeatures">
      <h2 class="title is-3" i18n>Feature access</h2>
      <div class="notification is-warning">
        <div class="icon">
          <i class="far fa-exclamation-circle fa-lg"></i>
        </div>
        <div i18n>
          Server Error, we could not fetch features at this moment. Please edit other details as of
          now!
        </div>
      </div>
      <div class="box__separator"></div>
    </ng-container>

    <ng-container *ngIf="canEditWhiteLabel && isWhiteLabelFeatureActiveForOrg">
      <portal-reseller-details
        [disabled]="false"
        [entityUid]="organisation.entityUid"
        [templateParametersForm]="templateParametersForm"
        [templateParameters]="templateParameters"
        [domainNameControl]="domainNameControl"
        [loginDomainNameControl]="loginDomainNameControl"
        [emailDomainNameControl]="emailDomainNameControl"
      ></portal-reseller-details>

      <div class="box__separator"></div>
    </ng-container>

    <div class="field">
      <button
        class="button is-text is-empty"
        type="button"
        data-e2e="button-toggle-billing-address"
        (click)="toggleBillingAddress()"
        title="Update billing address"
        i18n-title
      >
        <span class="is-flex is-align-items-center" *ngIf="!isBillingAddressDisplayed.value">
          <span class="icon"><i class="fas fa-plus"></i></span>
          <span data-e2e="button-add-billing-address" i18n>Add billing address</span>
        </span>
        <span class="is-flex is-align-items-center" *ngIf="isBillingAddressDisplayed.value">
          <span class="icon"><i class="fas fa-minus"></i></span>
          <span data-e2e="button-remove-billing-address" i18n>Remove billing address</span>
        </span>
      </button>
    </div>
    <div *ngIf="isBillingAddressDisplayed.value">
      <h2 class="title is-3" i18n>Billing address</h2>
      <portal-form-address
        name="billing"
        *ngIf="billingAddress"
        [form]="billingAddress"
        [countries]="countries"
        [type]="addressTypes.Billing"
        [value]="(organisation?.addresses)[organisationAddresses.billingIndex]"
      ></portal-form-address>
    </div>

    <div class="box__separator"></div>

    <div class="field">
      <button
        class="button is-text is-empty"
        type="button"
        data-e2e="button-toggle-shipping-address"
        (click)="toggleShippingAddressSection()"
        title="Update Shipping address"
      >
        <span
          class="is-flex is-align-items-center"
          *ngIf="!isShippingAddressSectionDisplayed.value"
        >
          <span class="icon"><i class="fas fa-plus"></i></span>
          <span data-e2e="button-add-shipping-address" i18n>Add shipping address</span>
        </span>
        <span class="is-flex is-align-items-center" *ngIf="isShippingAddressSectionDisplayed.value">
          <span class="icon"><i class="fas fa-minus"></i></span>
          <span data-e2e="button-remove-shipping-address" i18n>Remove shipping address</span>
        </span>
      </button>
    </div>
    <div *ngIf="isShippingAddressSectionDisplayed.value">
      <h2 class="title is-3" i18n>Shipping address</h2>
      <label class="checkbox has-margin-top-2x">
        <input
          type="checkbox"
          [disabled]="!isBillingAddressDisplayed.value || isShippingAddressDisplayed.disabled"
          (change)="toggleShippingAddress()"
          [checked]="isBillingAddressDisplayed.value && !isShippingAddressDisplayed.value"
        />
        <span data-e2e="checkbox-shipping-address-as-billing" i18n
          >Shipping address is the same as my billing address</span
        >
      </label>
      <div class="mb-4"></div>
      <ng-container *ngIf="isShippingAddressDisplayed.value || !isBillingAddressDisplayed.value">
        <portal-form-address
          name="shipping"
          *ngIf="shippingAddress"
          [form]="shippingAddress"
          [countries]="countries"
          [type]="addressTypes.Delivery"
          [value]="(organisation?.addresses)[organisationAddresses.deliveryIndex]"
        ></portal-form-address>
      </ng-container>
    </div>

    <div class="box__separator"></div>

    <div class="field">
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <button
            class="button has-text-primary is-text is-empty"
            type="button"
            data-e2e="button-toggle-trading-address"
            (click)="toggleTradingAddressSection()"
            title="Update Trading address"
            i18n-title
          >
            <span
              class="is-flex is-align-items-center"
              *ngIf="!isTradingAddressSectionDisplayed.value"
            >
              <span class="icon"><i class="fas fa-plus"></i></span>
              <span data-e2e="button-add-trading-address" i18n>Add trading address</span>
            </span>
            <span
              class="is-flex is-align-items-center"
              *ngIf="isTradingAddressSectionDisplayed.value"
            >
              <span class="icon"><i class="fas fa-minus"></i></span>
              <span data-e2e="button-remove-shipping-address" i18n>Remove trading address</span>
            </span>
          </button>
        </div>
        <div class="column" *ngIf="!isTradingAddressSectionDisplayed.value">
          <span class="help" i18n>
            The time zone is established by the trading address and if not provided, some payment
            types will fail.
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="isTradingAddressSectionDisplayed.value">
      <h2 class="title is-3" i18n>Trading address</h2>
      <label class="checkbox has-margin-top-2x">
        <input
          type="checkbox"
          [disabled]="!isBillingAddressDisplayed.value || isTradingAddressDisplayed.disabled"
          (change)="toggleTradingAddress()"
          [checked]="isBillingAddressDisplayed.value && !isTradingAddressDisplayed.value"
        />
        <span data-e2e="checkbox-trading-address-as-billing" i18n
          >Trading address is the same as my billing address</span
        >
      </label>
      <div class="mb-4"></div>
      <ng-container *ngIf="isTradingAddressDisplayed.value || !isBillingAddressDisplayed.value">
        <portal-form-address
          name="trading"
          *ngIf="tradingAddress"
          [form]="tradingAddress"
          [countries]="countries"
          [type]="addressTypes.Trading"
          [value]="(organisation?.addresses)[organisationAddresses.tradingIndex]"
        ></portal-form-address>
      </ng-container>
    </div>

    <div class="box__separator"></div>

    <ng-container *ngIf="selectedOrganisationBusinessInformation?.openingHours?.length">
      <div class="box__separator"></div>
      <portal-form-business-information
        [form]="businessInformation"
        [value]="selectedOrganisationBusinessInformation"
        [disabled]="false"
      ></portal-form-business-information>
    </ng-container>

    <label class="field" *ngIf="isCreatingOrganisation">
      <button
        class="button has-text-primary is-text is-empty"
        type="button"
        data-e2e="button-toggle-contact-details"
        (click)="toggleContactsTable()"
        title="Add contact details"
        i18n-title
      >
        <span class="is-flex is-align-items-center">
          <span class="icon"><i class="fas fa-plus"></i></span>
          <span data-e2e="button-add-contact-details" i18n>Add contact details</span>
        </span>
      </button>
      <portal-form-error [formInput]="contacts" errorId="contacts"></portal-form-error>
    </label>

    <ng-container *ngIf="!isCreatingOrganisation">
      <label class="field" *ngIf="contacts.controls.length">
        <button
          class="button has-text-primary is-text is-empty"
          type="button"
          (click)="removeAllContacts()"
          title="Remove contact details"
          i18n-title
        >
          <span class="is-flex is-align-items-center">
            <span class="icon"><i class="fas fa-minus"></i></span>
            <span data-e2e="button-remove-contact-details" i18n>Remove contact details</span>
          </span>
        </button>
      </label>

      <h2 class="title is-3" data-e2e="contact-information-label" i18n>Contact information</h2>

      <div class="field">
        <div class="table-container">
          <table class="table is-fullwidth table-contact">
            <thead>
              <tr>
                <th data-e2e="name-label" i18n>Name</th>
                <th data-e2e="contactTypes-label" i18n>Type</th>
                <th data-e2e="contact-action">
                  <span class="is-sr-only" i18n>Buttons to edit or delete contact</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let contact of contacts.controls; let i = index">
                <td>
                  <div class="level has-text-truncate">
                    {{ getFirstNameControl(i).value }} {{ getLastNameControl(i).value }}
                  </div>
                </td>
                <td>
                  <div class="level has-text-truncate">
                    {{ getContactTypeControl(i).value }}
                  </div>
                </td>
                <td>
                  <div class="buttons is-pulled-right">
                    <button
                      [disabled]="showContactForm"
                      type="button"
                      class="button is-empty has-text-primary is-text"
                      (click)="onEditContactByIndex(i)"
                      title="Edit contact"
                      i18n-title
                    >
                      <span class="icon">
                        <i class="fa fa-edit" aria-hidden="true"></i>
                      </span>
                    </button>

                    <button
                      [disabled]="showContactForm"
                      type="button"
                      class="button is-empty has-text-danger is-text ml-2"
                      (click)="onDeleteContactByIndex(i)"
                      title="Delete contact"
                      i18n-title
                    >
                      <span class="icon">
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
              <tr class="has-text-centered" *ngIf="!contacts.controls.length">
                <td colspan="3" i18n>No contact information available</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <button
            [disabled]="showContactForm"
            class="button is-outlined has-text-right"
            type="button"
            data-e2e="button-add-contact"
            (click)="onAddContact()"
            i18n
            title="Add new contact"
            i18n-title
          >
            Add new contact
          </button>
        </div>
      </div>
      <portal-form-error [formInput]="contacts" errorId="contacts"></portal-form-error>
    </ng-container>

    <ng-container *ngIf="showContactForm">
      <portal-form-contact
        [disabled]="disabled"
        [value]="contactValue"
        [isCreating]="isCreatingContact"
        (close)="closeContactForm()"
        (contactUpdated)="onContactUpdated($event)"
        (contactCreated)="onContactCreated($event)"
      >
      </portal-form-contact>
    </ng-container>

    <div class="box__separator"></div>

    <div
      class="box__separator"
      *ngIf="selectedOrganisationBusinessInformation?.openingHours?.length"
    ></div>

    <portal-collapsible-panel
      collapsedTitle="Add new bank account"
      expandedTitle="Remove bank account"
      [expanded]="isBankAccountDisplayed"
      [e2eToggleName]="'bankAccount-toggle'"
      (expand)="onBankAccountExpand()"
      (collapse)="onBankAccountCollapse()"
      class="field"
      i18n-collapsedTitle="collapse section"
      i18n-expandedTitle="expand section"
      data-e2e="formOnboarding-bankAccountCollapsiblePanel"
    >
      <ng-container *ngIf="isBankAccountDisplayed">
        <div *ngFor="let bankAccount of bankAccounts.controls; let i = index">
          <div class="field">
            <div class="control">
              <div class="label">
                <h2 class="title is-3" i18n>Bank Account</h2>
              </div>
              <portal-form-bank-account
                [bankAccount]="bankAccount"
                [bankAccountValue]="bankAccountsValue[i]"
                [radioButtonGroupName]="'radio-button-' + i"
                data-e2e="formOnboarding-formBankAccount"
              ></portal-form-bank-account>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <button
                type="button"
                class="button is-danger is-primary is-text"
                (click)="removeBankAccount(i)"
                data-e2e="delete-bank-account"
                i18n
                title="Delete Bank Account"
                i18n-title
              >
                Delete Bank Account
              </button>
            </div>
          </div>
          <div class="box__separator"></div>
        </div>
      </ng-container>
    </portal-collapsible-panel>

    <div class="field" *ngIf="isBankAccountDisplayed">
      <div class="control">
        <button
          type="button"
          class="button is-primary is-outlined"
          (click)="addBankAccount()"
          data-e2e="add-extra-bank-account"
          i18n
          title="Add extra bank account"
          i18n-title
        >
          Add extra bank account
        </button>
      </div>
    </div>

    <ng-container *ngIf="isVerifoneAdmin">
      <div class="box__separator"></div>

      <div class="field">
        <button
          class="button is-text is-empty"
          type="button"
          data-e2e="button-toggle-payment-documents"
          (click)="togglePaymentDocuments()"
          title="Update payment documents"
          i18n-title
        >
          <span
            class="is-flex is-align-items-center"
            *ngIf="!isPaymentDocumentSectionDisplayed.value"
          >
            <span class="icon"><i class="fas fa-plus"></i></span>
            <span data-e2e="button-add-payment-documents" i18n>Add payment documents</span>
          </span>
          <span
            class="is-flex is-align-items-center"
            *ngIf="isPaymentDocumentSectionDisplayed.value"
          >
            <span class="icon"><i class="fas fa-minus"></i></span>
            <span data-e2e="button-remove-payment-documents" i18n>Remove payment documents</span>
          </span>
        </button>
      </div>
      <ng-container *ngIf="isPaymentDocumentSectionDisplayed.value">
        <div class="box__separator"></div>

        <div class="field mt-3">
          <h2 class="title is-3" i18n>Payment Documents</h2>
          <div class="field columns mt-5">
            <div class="column is-half">
              <div class="field" *ngIf="!hasSavedReceiptProvider">
                <portal-select-control
                  name="Payment document provider"
                  [optional]="true"
                  [control]="receiptProvider"
                  [options]="receiptProviderTypeList"
                  placeholder="--Select Option--"
                  i18n-placeholder
                  [e2eName]="'select-receipt-provider'"
                  [e2eErrorName]="'select-receipt-provider'"
                  [dropdownPosition]="hasReceiptProvider ? 'bottom' : 'top'"
                  i18n-name
                ></portal-select-control>
              </div>
              <div class="field mt-3" *ngIf="hasSavedReceiptProvider">
                <label class="label">
                  <span i18n>Payment document provider</span>
                </label>
                <div>
                  <span>
                    {{ receiptProviderName }}
                  </span>
                </div>
              </div>
            </div>
            <div class="column">
              <label class="checkbox m-5" *ngIf="isReceiptProviderVerifone">
                <input
                  type="checkbox"
                  id="allowChildReuse"
                  name="allowChildReuse"
                  [formControl]="isAllowChildReused"
                  (click)="togglingChildReuseSection()"
                />
                <span i18n>Allow Child Reuse</span>
              </label>
            </div>
          </div>
        </div>

        <ng-container *ngIf="hasReceiptProvider">
          <div class="field mt-3">
            <label *ngIf="!organisation || !organisation?.receiptContract; else editModeBlock">
              <div class="label">
                <span i18n>API token</span>
                &nbsp;<span
                  class="has-text-grey has-text-weight-normal"
                  *ngIf="!isReceiptProviderInvoice4U"
                  i18n
                  >(Optional)</span
                >
              </div>
              <div class="control">
                <input
                  class="input"
                  [formControl]="apiToken"
                  data-e2e="api-token"
                  type="text"
                  [ngClass]="{ 'is-danger': isInvalid(apiToken) }"
                />
                <portal-form-error [formInput]="apiToken" errorId="apiToken"></portal-form-error>
              </div>
            </label>
          </div>
        </ng-container>

        <ng-template #editModeBlock>
          <div class="field mt-5">
            <label class="label">
              <span i18n>API token</span>
              <span>
                &nbsp;<span
                  class="has-text-grey has-text-weight-normal"
                  *ngIf="!isReceiptProviderInvoice4U"
                  i18n
                  >(Optional)</span
                >
              </span>
            </label>
            <ng-container *ngIf="showPaymentDocumentApiTokenForm; else maskedBlock">
              <div class="columns">
                <div class="column is-two-thirds">
                  <div class="control">
                    <input
                      class="input"
                      [formControl]="apiToken"
                      data-e2e="api-token"
                      type="text"
                      [ngClass]="{ 'is-danger': isInvalid(apiToken) }"
                    />
                  </div>
                  <portal-form-error [formInput]="apiToken" errorId="apiToken"></portal-form-error>
                  <div class="notification is-warning has-margin-top">
                    <div class="icon">
                      <i class="far fa-exclamation-circle fa-lg"></i>
                    </div>
                    <span i18n> Saving a new token will disable the old one </span>
                  </div>
                </div>
              </div>
              <div class="buttons">
                <button
                  type="button"
                  [disabled]="savingToken"
                  [ngClass]="{ 'is-loading': savingToken }"
                  class="button is-primary"
                  (click)="saveToken()"
                  title="Save Token"
                  i18n-title
                >
                  <span i18n>Save Token</span>
                </button>
                <button
                  type="button"
                  [disabled]="savingToken"
                  class="button"
                  (click)="closeUpdateTokenForm()"
                  title="Cancel"
                  i18n-title
                >
                  <span i18n>Cancel</span>
                </button>
              </div>
            </ng-container>
            <ng-template #maskedBlock>
              <div class="columns is-vcentered">
                <div class="column">••••••••••••••••••••••••••••••••</div>
                <div class="column">
                  <a
                    type="button"
                    class="button"
                    (click)="openUpdateTokenForm()"
                    title="Update token"
                    i18n-title
                  >
                    <span i18n>Update Token</span>
                  </a>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-template>

        <div class="field" *ngIf="organisation && organisation?.receiptContract">
          <label class="label">
            <span i18n>Receipt contract ID</span>
          </label>
          <div>
            {{ organisation.receiptContract?.receiptContractUid }}
          </div>
        </div>

        <div class="field" *ngIf="hasReceiptProvider && isReceiptProviderInvoice4U">
          <label class="label">
            <span i18n>Payment document types</span>
          </label>
          <div>
            <span>{{ paymentDocumentTypeListText }}</span>
          </div>
        </div>
        <div *ngIf="isReceiptProviderVerifone">
          <div class="field">
            <label class="label">
              <span i18n>Attachment type</span>
              <span>
                &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
              </span>
            </label>
            <ng-select
              [formControl]="receiptAttachmentType"
              [items]="receiptAttachmentTypesList"
              bindValue="id"
              bindLabel="text"
              placeholder="--Select Option--"
              i18n-placeholder
              data-e2e="select-receipt-attachment-type"
              notFoundText="No items found"
              i18n-notFoundText
            >
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                {{ item.text }}
              </ng-template>
            </ng-select>
            <portal-form-error
              [formInput]="receiptAttachmentType"
              errorId="receiptAttachmentType"
            ></portal-form-error>
          </div>

          <div class="field">
            <label class="label">
              <span i18n>Merchant logo</span>
              <span>
                &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
              </span>
            </label>
            <div>
              <div class="is-flex is-flex-direction-column" *ngIf="receiptMerchantLogo">
                <img
                  class="logo"
                  [srcBlob]="this.receiptMerchantLogo?.value[0]"
                  style="max-height: 400px; max-width: 400px"
                />
              </div>
              <portal-file-input
                [formControl]="receiptMerchantLogo"
                title="Drag & drop your logo here"
                i18n-title="Drag & drop your logo here"
                [acceptableFileTypes]="'.png'"
                [maxFileSizeInKb]="maxLogoFileSizeInKb"
                data-e2e="input-file-upload"
              >
              </portal-file-input>
            </div>
          </div>
          <h2 class="title is-4">
            Default Receipt Template
            <span
              class="pl-1 tooltip has-tooltip-top has-tooltip-multiline"
              data-tooltip="The default receipt template will be used for all transactions."
            >
              <i class="fas fa-question-circle"></i>
            </span>
          </h2>
          <div class="field">
            <ng-select
              [formControl]="receiptTemplate"
              [items]="receiptTemplateNamesList"
              bindValue="id"
              bindLabel="text"
              placeholder="--Select Option--"
              i18n-placeholder
              data-e2e="select-receipt-template"
              notFoundText="No items found"
              i18n-notFoundText
            >
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                {{ item }}
              </ng-template>
            </ng-select>
            <portal-form-error
              [formInput]="receiptTemplate"
              errorId="receiptTemplateId"
            ></portal-form-error>
          </div>

          <h2 class="title is-4 pt-2">
            Custom Receipt Template(s)
            <span
              class="pl-1 tooltip has-tooltip-top has-tooltip-multiline"
              data-tooltip="You may override the default template by assigning a different template to specific sales channels."
            >
              <i class="fas fa-question-circle"></i>
            </span>
          </h2>
          <ng-container *ngFor="let customTemplate of customTemplates.controls; let i = index">
            <div class="box">
              <label class="label" i18n>Receipt Template</label>
              <div class="field">
                <ng-select
                  [formControl]="customTemplate.get('templateId')"
                  [items]="receiptTemplateNamesList"
                  bindValue="id"
                  bindLabel="text"
                  placeholder="--Select Option--"
                  i18n-placeholder
                  data-e2e="select-receipt-template"
                  notFoundText="No items found"
                  i18n-notFoundText
                >
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    {{ item }}
                  </ng-template>
                </ng-select>
                <portal-form-error
                  [formInput]="customTemplate.get('templateId')"
                  errorId="receiptTemplateId"
                ></portal-form-error>
              </div>
              <portal-channel-control
                name="Sales channels"
                emptyOptionalName="- Select sales channel -"
                [control]="customTemplate.get('salesChannels')"
                [invalid]="isInvalid(customTemplate.get('salesChannels'))"
                [salesChannels]="receiptSalesChannelsList"
                [e2eName]="'select-salesChannel'"
                [e2eErrorName]="'salesChannel'"
                [assignedChannels]="assignedChannels"
                [isForOrgTemplate]="true"
                (remove)="removeTemplateChannel(customTemplate.get('salesChannels'), $event)"
                class="field"
                i18n-name
                i18n-emptyOptionalName
              ></portal-channel-control>
              <div
                class="button has-text-danger is-text"
                type="button"
                title="Delete custom template"
                data-e2e="button-delete-custom-template"
                (click)="onClickDeleteTemplate(i)"
                (keydown.enter)="onClickDeleteTemplate(i)"
                tabindex="0"
                i18n-title
                i18n
              >
                Delete
              </div>
            </div>
          </ng-container>
          <div class="buttons is-right">
            <div
              #buttonAddTemplate
              class="button is-primary is-outlined"
              [class.is-disabled]="isAllChannelsAssigned"
              [class.tabsulli]="isAllChannelsAssigned"
              type="button"
              title="Add custom template"
              data-e2e="button-add-custom-template"
              (click)="pushCustomTemplate()"
              (keydown.enter)="pushCustomTemplate()"
              tabindex="0"
              i18n-title
              i18n
            >
              Add template
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="organisation && canReadPaymentContracts">
      <div class="box__separator"></div>

      <h2 class="title is-3" i18n>Associated entities</h2>

      <portal-associated-entities
        [organisationId]="organisation?.entityUid"
        associatedLabelName="Associated Payments Provider Contracts"
        paginationId="associatedPaymentContracts"
        associatedEntityType="paymentContracts"
        i18n-associatedLabelName="Payment Provider Contracts associated with this Organisation"
        (paymentContractOutput)="handlePaymentContractOutput($event)"
      ></portal-associated-entities>
      <ng-container *ngIf="showMcr()">
        <portal-merchant-choice-routing
          [processorType]="ecomContract.processor.type"
          [editable]="true"
          [orgForm]="form"
          [organisation]="organisation"
        ></portal-merchant-choice-routing>
      </ng-container>
    </ng-container>
  </div>

  <div
    class="columns is-vcentered is-flex is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
  >
    <div class="column">
      <ng-container *ngIf="!organisation">
        <a
          class="button has-text-danger is-text"
          data-e2e="link-cancel"
          restrict-permission
          [routerLink]="['/administration', 'organisations']"
          i18n
          title="Cancel"
          i18n-title
        >
          Cancel
        </a>
      </ng-container>
      <ng-container *ngIf="organisation">
        <a
          class="button has-text-danger is-text"
          data-e2e="link-cancel"
          restrict-permission
          [routerLink]="['/administration', 'organisations', organisation?.entityUid]"
          i18n
          title="Discard"
          i18n-title
        >
          Discard changes
        </a>
      </ng-container>
    </div>
    <div class="column has-text-right">
      <button
        type="submit"
        [disabled]="showPaymentDocumentApiTokenForm"
        class="button is-primary"
        data-e2e="button-submit"
        title="Save"
        i18n-title
      >
        <span *ngIf="!organisation" i18n>Save</span>
        <span *ngIf="organisation" i18n>Save changes</span>
      </button>
    </div>
  </div>
</form>
<portal-base-modal #confirmDeleteContactModal>
  <div title i18n>Delete [{{ contactNameTobeDeleted }}]</div>
  <div description i18n>
    Are you sure you want to delete <b>{{ contactNameTobeDeleted }}</b
    >?
  </div>
  <div class="level" buttons>
    <button
      class="level-left button is-text has-text-primary"
      data-e2e="cancel-button"
      (click)="closeDeleteModal()"
      type="button"
      i18n
      title="Cancel"
      i18n-title
    >
      Cancel
    </button>
    <button
      type="button"
      class="button is-danger is-outlined"
      (click)="onModalDeleteConfirmed()"
      data-e2e="delete-button"
      i18n
      title="Delete"
      i18n-title
    >
      Delete
    </button>
  </div>
</portal-base-modal>
<portal-base-modal #confirmDeleteAllContactsModal>
  <div title i18n>Delete contacts</div>
  <div description i18n>
    Are you sure that you want to delete all contacts that have been created for
    <b>{{ name.value }}</b>
  </div>
  <div class="level" buttons>
    <button
      class="level-left button is-text has-text-primary"
      data-e2e="cancel-button"
      (click)="closeDeleteModal()"
      type="button"
      i18n
      title="Cancel"
      i18n-title
    >
      Cancel
    </button>
    <button
      type="button"
      class="button is-danger is-outlined"
      (click)="onModalAllDeleteConfirmed()"
      data-e2e="delete-button"
      i18n
      title="Delete"
      i18n-title
    >
      Delete
    </button>
  </div>
</portal-base-modal>
