import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  ITokenScope,
  SchemeTokenCardType,
  TokenFormat,
  TokenType,
} from '@portal/entity-services/interfaces';
import { SchemeTokenType } from '@portal/entity-services/interfaces/src/lib/organisations/enums/scheme-token-type.enum';
import { TokenRequestorIdType } from '@portal/entity-services/interfaces/src/lib/organisations/enums/token-requestor-id-type.enum';
import { FormBase, MultilingualValidators } from '@portal/shared/ui/form';
import isEqual from 'lodash-es/isEqual';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TokenFormats } from './token-format.list';
import { TokenRequestorIdTypeList } from './token-requestor-id-type.list';
import { TokenTypes } from './token-type.list';

declare const $localize;

const validCharacters = /[^A-Za-z0-9]/g;

@Component({
  selector: 'portal-token-scope-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormTokenScopeComponent extends FormBase<ITokenScope> implements OnInit, OnDestroy {
  @Input() tokenScope: ITokenScope;
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  isAdditionalSettingsOpen = false;
  tokenFormatList = this.tokenFormats.list;
  tokenTypeList = this.tokenTypes.list;
  tokenRequestorIdTypeList = this.tokenRequestorIdType.list;

  hasFormChanged = false;
  initialValue: ITokenScope;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private customValidators: MultilingualValidators,
    private fb: FormBuilder,
    private tokenFormats: TokenFormats,
    private tokenTypes: TokenTypes,
    private tokenRequestorIdType: TokenRequestorIdTypeList,
  ) {
    super(
      fb.group({
        tokenScopeUid: [''],
        name: ['', customValidators.required],
        tokenFormat: [TokenFormat.Alphanumeric, customValidators.required],
        tokenType: [TokenType.Analytics, customValidators.required],
        tokenLength: [
          '',
          [
            customValidators.digits,
            customValidators.minAmount('16', $localize`The token length value is below the limit`),
            customValidators.maxAmount('255', $localize`The token length value is above the limit`),
          ],
        ],
        prefixLengthFromCard: ['', [customValidators.digits, customValidators.maxLength(6)]],
        suffixLengthFromCard: ['', [customValidators.digits, customValidators.maxLength(4)]],
        fixedPrefixStr: [
          '',
          [customValidators.characters(validCharacters), customValidators.maxLength(6)],
        ],
        fixedSuffixStr: [
          '',
          [customValidators.characters(validCharacters), customValidators.maxLength(4)],
        ],
        defaultTokenExpirationInDays: [
          '',
          [customValidators.digits, customValidators.maxAmount('2000')],
        ],
        cardSchemeTokenAvailability: [false],
        schemeToken: fb.group({
          schemeTokenType: [SchemeTokenType.None],
          schemeTokenRequestorType: [
            TokenRequestorIdType.Verifone,
            customValidators.conditional(
              () => this.isTokenRequestorIdType,
              customValidators.required,
            ),
          ],
          schemeTokenRequestIds: fb.array([
            fb.group({
              cardType: [
                SchemeTokenCardType.Visa,
                [
                  customValidators.conditional(
                    () => this.isMerchantRequestorId,
                    customValidators.required,
                  ),
                ],
              ],
              tokenRequestorId: [
                '',
                [
                  customValidators.conditional(
                    () => this.isMerchantRequestorId,
                    customValidators.required,
                  ),
                  customValidators.digits,
                  customValidators.maxLength(11),
                ],
              ],
            }),
            fb.group({
              cardType: [
                SchemeTokenCardType.Mastercard,
                [
                  customValidators.conditional(
                    () => this.isMerchantRequestorId,
                    customValidators.required,
                  ),
                ],
              ],
              tokenRequestorId: [
                '',
                [
                  customValidators.conditional(
                    () => this.isMerchantRequestorId,
                    customValidators.required,
                  ),
                  customValidators.digits,
                  customValidators.maxLength(11),
                ],
              ],
            }),
          ]),
        }),
        merchantRequestorIdVisa: [
          '',
          [
            customValidators.conditional(
              () => this.isMerchantRequestorId,
              customValidators.required,
            ),
            customValidators.digits,
            customValidators.maxLength(11),
          ],
        ],
        merchantRequestorIdMastercard: [
          '',
          [
            customValidators.conditional(
              () => this.isMerchantRequestorId,
              customValidators.required,
            ),
            customValidators.digits,
            customValidators.maxLength(11),
          ],
        ],
      }),
    );
  }

  ngOnInit(): void {
    if (this.tokenScope) {
      this.tokenScope.fixedPrefixStr = this.tokenScope?.fixedPrefix
        ? this.tokenScope.fixedPrefix.toString()
        : this.tokenScope.fixedPrefixStr;
      this.tokenScope.fixedSuffixStr = this.tokenScope?.fixedSuffix
        ? this.tokenScope.fixedSuffix.toString()
        : this.tokenScope.fixedSuffixStr;

      this.form.reset();
      this.setFormValue({ ...this.tokenScope });

      this.isAdditionalSettingsOpen =
        this.tokenScope.tokenType === TokenType.Analytics ? false : true;

      if (this.tokenScope?.schemeToken?.schemeTokenType === SchemeTokenType.Par_Or_Network) {
        this.cardSchemeTokenAvailability.setValue(true);
        this.schemeTokenRequestorTypeControl.setValue(
          this.tokenScope?.schemeToken?.schemeTokenRequestorType,
        );
        if (
          this.tokenScope?.schemeToken?.schemeTokenRequestorType === TokenRequestorIdType.Merchant
        ) {
          this.merchantRequestorIdVisa.setValue(
            this.tokenScope?.schemeToken?.schemeTokenRequestIds[0]?.tokenRequestorId,
          );
          this.merchantRequestorIdMastercard.setValue(
            this.tokenScope?.schemeToken?.schemeTokenRequestIds[1]?.tokenRequestorId,
          );
        } else if (
          this.tokenScope?.schemeToken?.schemeTokenRequestorType === TokenRequestorIdType.Verifone
        ) {
          this.resetMerchantReqIdFormControls();
          this.resetFormControls(this.schemeTokenRequestIdsControl);
        }

        this.getMerchantTokenRequestorFormControl(this.merchantRequestorIdVisa);
        this.getMerchantTokenRequestorFormControl(this.merchantRequestorIdMastercard);
      } else {
        this.cardSchemeTokenAvailability.setValue(false);
        this.isSchemeTokenTypeAsNone();
      }
    }
    if (!this.cardSchemeTokenAvailability.value) {
      this.isSchemeTokenTypeAsNone();
    }
    this.initialValue = this.form.value;
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((formValue) => (this.hasFormChanged = !isEqual(this.initialValue, formValue)));

    this.cardSchemeTokenAvailability.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.setSchemeTokenTypeControl(value);
      });

    this.tokenType.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((tokenTypeValue) => {
      if (tokenTypeValue === TokenType.Analytics) {
        this.isAdditionalSettingsOpen = false;
        this.resetFormControls(this.cardSchemeTokenAvailability);
        this.resetFormControls(this.schemeToken);
        this.resetMerchantReqIdFormControls();
      } else {
        this.isAdditionalSettingsOpen = true;
      }
    });

    this.schemeTokenRequestorTypeControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((tokenTypeValue) => {
        if (tokenTypeValue === TokenRequestorIdType.Verifone) {
          this.resetMerchantReqIdFormControls();
          this.resetFormControls(this.schemeTokenRequestIdsControl);
        } else {
          this.getMerchantTokenRequestorFormControl(this.merchantRequestorIdVisa);
          this.getMerchantTokenRequestorFormControl(this.merchantRequestorIdMastercard);
        }
      });
  }

  isSchemeTokenTypeAsNone(): void {
    this.resetMerchantReqIdFormControls();
    this.resetFormControls(this.schemeTokenRequestIdsControl);
    this.schemeTokenTypeControl.setValue(SchemeTokenType.None);
  }

  setSchemeTokenTypeControl(controlValue: boolean): void {
    if (controlValue) {
      this.schemeTokenTypeControl.setValue(SchemeTokenType.Par_Or_Network);
    } else {
      this.resetFormControls(this.schemeToken);
      this.schemeTokenTypeControl.setValue(SchemeTokenType.None);
    }
    this.schemeTokenRequestorTypeControl.setValue(TokenRequestorIdType.Verifone);
    this.resetMerchantReqIdFormControls();
  }

  resetFormControls(control: AbstractControl): void {
    control.reset();
  }

  resetMerchantReqIdFormControls(): void {
    this.resetFormControls(this.merchantRequestorIdVisa);
    this.resetFormControls(this.merchantRequestorIdMastercard);
  }

  getMerchantTokenRequestorFormControl(merchantReqIdAbstractControl: AbstractControl): void {
    merchantReqIdAbstractControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((controlValue) => {
        if (merchantReqIdAbstractControl === this.merchantRequestorIdVisa) {
          this.visaTokenCardTypeControl.setValue(SchemeTokenCardType.Visa);
          this.visaTokenRequestorIdControl.setValue(controlValue);
        } else if (merchantReqIdAbstractControl === this.merchantRequestorIdMastercard) {
          this.mastercardTokenCardTypeControl.setValue(SchemeTokenCardType.Mastercard);
          this.mastercardTokenRequestorIdControl.setValue(controlValue);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleAdditionalSettings(): boolean {
    this.isAdditionalSettingsOpen = !this.isAdditionalSettingsOpen;

    return this.isAdditionalSettingsOpen;
  }

  onCancel(): void {
    this.canceled.emit();
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get tokenFormat(): AbstractControl {
    return this.form.get('tokenFormat');
  }

  get tokenType(): AbstractControl {
    return this.form.get('tokenType');
  }

  get tokenLength(): AbstractControl {
    return this.form.get('tokenLength');
  }

  get prefixLengthFromCard(): AbstractControl {
    return this.form.get('prefixLengthFromCard');
  }

  get suffixLengthFromCard(): AbstractControl {
    return this.form.get('suffixLengthFromCard');
  }

  get fixedPrefixStr(): AbstractControl {
    return this.form.get('fixedPrefixStr');
  }

  get fixedSuffixStr(): AbstractControl {
    return this.form.get('fixedSuffixStr');
  }

  get defaultTokenExpirationInDays(): AbstractControl {
    return this.form.get('defaultTokenExpirationInDays');
  }

  get cardSchemeTokenAvailability(): AbstractControl {
    return this.form.get('cardSchemeTokenAvailability');
  }

  get schemeToken(): FormGroup {
    return this.form.get('schemeToken') as FormGroup;
  }

  get schemeTokenTypeControl(): AbstractControl {
    return this.form.get('schemeToken.schemeTokenType');
  }

  get schemeTokenRequestorTypeControl(): AbstractControl {
    return this.form.get('schemeToken.schemeTokenRequestorType');
  }

  get schemeTokenRequestIdsControl(): FormArray {
    return this.form.get('schemeToken.schemeTokenRequestIds') as FormArray;
  }

  get tokenRequestorIdTypeControl(): AbstractControl {
    return this.form.get('schemeTokenRequestorType');
  }

  get isCardSchemeTokenAvailable(): boolean {
    return [TokenType.Reuse, TokenType.ReuseAndAnalytics].includes(this.tokenType.value);
  }

  get isTokenRequestorIdType(): boolean {
    return this.cardSchemeTokenAvailability.value ? true : false;
  }

  get isMerchantRequestorId(): boolean {
    return [TokenRequestorIdType.Merchant].includes(this.schemeTokenRequestorTypeControl.value);
  }

  get merchantRequestorIdVisa(): AbstractControl {
    return this.form.get('merchantRequestorIdVisa');
  }

  get merchantRequestorIdMastercard(): AbstractControl {
    return this.form.get('merchantRequestorIdMastercard');
  }

  get schemeTokenRequestIdsArrayFormGroupVisaControl(): FormGroup {
    return this.schemeTokenRequestIdsControl.controls[0] as FormGroup;
  }

  get visaTokenCardTypeControl(): AbstractControl {
    return this.schemeTokenRequestIdsArrayFormGroupVisaControl.get('cardType');
  }

  get visaTokenRequestorIdControl(): AbstractControl {
    return this.schemeTokenRequestIdsArrayFormGroupVisaControl.get('tokenRequestorId');
  }

  get schemeTokenRequestIdsArrayFormGroupMatercardControl(): FormGroup {
    return this.schemeTokenRequestIdsControl.controls[1] as FormGroup;
  }

  get mastercardTokenCardTypeControl(): AbstractControl {
    return this.schemeTokenRequestIdsArrayFormGroupMatercardControl.get('cardType');
  }

  get mastercardTokenRequestorIdControl(): AbstractControl {
    return this.schemeTokenRequestIdsArrayFormGroupMatercardControl.get('tokenRequestorId');
  }
}
