/*Note - Right side value is R.E.Field ID*/
export enum ReportTemplateField {
  AccountType = 'account_type',
  AcquirerAccountType = 'acquirer_account_type',
  AcquirerAuthorisationResult = 'acquirer_authorisation_result',
  AcquirerMerchantID = 'acquirer_merchant_id',
  AcquirerReferenceNumber = 'arn',
  AcquirerResponseCode = 'acquirer_response_code',
  AcquirerResponseMessage = 'acquirer_response_message',
  AcquirerSettlementID = 'Acquirer Settlement ID',
  AddressVerificationServicesResult = 'avs_result_code',
  AuthorisationResponse = 'authorisation_code',
  AuthDateTime = 'Auth date time',
  BatchID = 'batch_id',
  CaptureStatus = 'Capture status',
  CardBIN = 'card.bin',
  CardDetailsIssuerCountry = 'card.issuer_country',
  CardIssuerCountry = 'card_issuer_country',
  CardLastFourDigits = 'card.last_four',
  CardExpiryMonth = 'Card Exp Month',
  CardExpiryYear = 'Card Exp Year',
  CardholderAuthVerification = 'cavv_result',
  CashbackAmount = 'Cashback_amount',
  CashierID = 'cashier_id',
  CashierName = 'cashier_name',
  CharitableDonation = 'Charitable_Donation',
  ChequeAccountNumber = 'cheque_account_number',
  ChequeNumber = 'cheque_number',
  ConfirmDateTime = 'Confirm date time',
  CreationDateTime = 'created_at_datetime',
  CreationTimezone = 'created_at_timezone',
  CreationUTC = 'created_at_utc',
  CreationDate = 'created_at_date',
  CreationTime = 'created_at_time',
  CreditTerm = 'credit_term',
  CurrentAmount = 'Curr.amount',
  CurrentAmountCurrency = 'Curr.amount.currencyCode',
  CustomerAppPhoneNumber = 'customer.app_phone_number',
  CustomerBillingCity = 'customer.billing.city',
  CustomerBillingCountry = 'customer.billing.country_code',
  CustomerBillingFirstName = 'customer.billing.first_name',
  CustomerEmailAddress = 'customer.email_address',
  CustomerID = 'customer._id',
  CVVResult = 'CVV result',	
  DeferredMonths = 'deferred_months',
  DeviceSerialNumber = 'device_serial_number',
  ElectronicCommerceIndicator = 'electronic_commerce_indicator',
  EstateManagementID = 'estate_management_id',
  FallbackTransaction = 'fallback_transaction',
  FeeCurrency = 'fee_currency',
  FraudReason = 'fraud_reason_insights',
  FraudScore = 'fraud_score',
  FraudReasonCode = 'fraud_reason_code',
  GratuityAmount = 'Gratuity_amount',
  IDcheckResult = 'id_check_result',
  InvoiceNumber = 'Invoice Number',
  IssuerAuthorisationResult = 'issuer_authorisation_result',
  IssuerDueDate = 'due_date',
  IssuerInstalmentAmount = 'issuer_instalment_instalment_amount',
  IssuerInstalmentDownPaymentAmount = 'issuer_instalment_down_payment_amount',
  IssuerInstalmentPaymentIndex = 'issuer_instalment_payment_index',
  IssuerInstalmentsNumber = 'issuer_instalment_number_of_instalments',
  MaskedCardNumber = 'masked_card_number',
  MerchantCountry = 'merchant_country',
  MerchantId = 'merchant_id',
  MerchantPresent = 'Merchant present',
  MerchantReference = 'merchant_reference',
  MessageNo = 'Message no',
  OrganisationID = 'Entity',
  OrganisationName = 'organisation.name',
  OriginalAmount = 'Orig.amount',
  OriginalAmountCurrency = 'Orig.amount.currencyCode',
  PoiName = 'poi_name',
  PpcId = 'PPC ID',
  ParentTransactionId = 'Parent Transaction ID',
  PaymentAccountReference = 'Payment Account Reference',
  PaymentChannel = 'shopper_interaction',
  PaymentDocumentID = 'payment_document_id',
  PaymentDocumentProvider = 'payment_document_provider',
  PaymentDocumentType = 'payment_document_type',
  PaymentMethod = 'payment_method',
  PaymentMode = 'payment_mode',
  PaymentProcessingFee = 'payment_processing_fee_amount',
  PaymentProductType = 'payment_product_type',
  PaymentProviderContractType = 'sourcePIM',
  ProcessorAcquirerName = 'processor_acquirer_name',
  ProcessorCardBrand = 'processor_card_brand',
  ProcessorIssuerName = 'processor_issuer_name',
  ProcessorReceiverRegistrationNumber = 'processor_rrn',
  ProcessortransactionID = 'processor_transaction_id',
  Product = 'Product',
  PurchaseOrdernumber = 'purchase_order_number',
  ReceiverRegistrationNumber = 'RRN',
  ResponseCode = 'response_code',
  STAN = 'STAN',
  SalesDescription = 'sales_description',
  SalesChannel = 'Sales channel',
  SchemeIdentifier = 'Scheme identifier',
  SettlementDate = 'settlement_date',
  SettlementID = 'settlement_id',
  ShippingAddress = 'shipping_information.address',
  ShippingCity = 'shipping_information.city',
  ShippingCountry = 'shipping_information.country',
  ShippingCustomerFirstName = 'shipping_information.first_name',
  ShippingEmail = 'shipping_information.email',
  ShippingPhone = 'shipping_information.phone',
  ShippingPostalCode = 'shipping_information.postal_code',
  ShippingState = 'shipping_information.state',
  SiteReferenceID = 'site_reference_id',
  StoreIdentifier = 'Store Identifier',
  StoredCredentialsType = 'stored_credentials_type',
  StoredCredentialReference = 'Stored Credential Reference',
  TaxAmount = 'tax_amount',
  TaxIndicator = 'tax_indicator',
  TaxDiscount = 'Tax discount',
  Technology = 'technology',
  TerminalID = 'terminal_id',
  ThirdPartyTransactionReference = 'third_party_transaction_reference',
  TokenType = 'token_type',
  TransactionDescription = 'transaction_description',
  TransactionEntryMode = 'entry_mode',
  TransactionID = '_id',
  TransactionReference = 'Reference',
  TransactionState = 'state',
  TransactionType = 'transaction_type',
  TransactionTimezone = 'transaction_timezone',
  TransactionOnlineFlag = 'Transaction online flag',
  UserAgent = 'user_agent',
  VerifoneTerminalId = 'Verifone Terminal ID',
  Acquirer = 'Acquirer',
  PrmType = 'PRM_type',
  AncestorName = 'Ancestor_name',
  AuthenticationMode = 'Authentication_mode',
  ThreeDsStatus = '3DS_status',
  ThreeDsVersion = '3DS_version',
  TerminalManufacturer = 'Terminal_manufacturer',
  SimcardIdentifier = 'SSN_ICC_ID',
  CrossBorderFee = 'Cross_border_fee',
  PlatformFee = 'Platform_fee'
}
