import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { TransactionDate } from '../enum/transaction-date.enum';
import sortBy from 'lodash-es/sortBy';

declare const $localize;
@Injectable()
export class TransactionDates extends SelectInputsList {
  keyValue = {
    [TransactionDate.Created]: $localize`Created`,
    [TransactionDate.Settled]: $localize`Settled`,
  };
  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);

  constructor() {
    super();
  }
}
