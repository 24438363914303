export enum PageSize {
  Size1 = 1,
  Size3 = 3,
  Size5 = 5,
  Size10 = 10,
  Size12 = 12,
  Size20 = 20,
  Size25 = 25,
  Size28 = 28,
  Size50 = 50,
  Size52 = 52,
  Size100 = 100,
}
