import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import {
  AllAppsCatalogState,
  AllAppsCatalogStore,
} from '@portal/marketplace/app-catalog/src/lib/state/all-apps-catalog/all-apps-catalog.store';
import { IApp } from '@portal/marketplace/api-interfaces/src';

@Injectable({
  providedIn: 'root',
})
export class AllAppsCatalogQuery extends QueryEntity<AllAppsCatalogState, IApp> {
  // eslint-disable-next-line
  constructor(protected store: AllAppsCatalogStore, private routerQuery: RouterQuery) {
    super(store);
  }
}
