<marketplace-app-page-template
  [app]="app$ | async"
  [showInstallsIndicator]="false"
  [showUpdatesIndicator]="false"
  [isPublic]="true"
>
  <div class="screenshots-header">
    <h2 class="title">Screenshots</h2>
  </div></marketplace-app-page-template
>
