import { Injectable } from '@angular/core';
import sortBy from 'lodash-es/sortBy';

import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { ActionAmlTimeout } from '../enums/action-aml-timeout.enum';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class ActionAmlTimeouts extends SelectInputsList {
  keyValue = {
    [ActionAmlTimeout.Allow]: $localize`Allow`,
    [ActionAmlTimeout.Block]: $localize`Block`,
  };

  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);

  constructor() {
    super();
  }
}
