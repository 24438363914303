<ng-container *ngIf="(thresholdsData?.length || defaultBrands?.length) && enabled">
  <div class="box__separator"></div>

  <div class="columns">
    <div class="column is-1">
      <label *ngIf="enabled" class="toggle mb-3" [class.toggle-disabled]="enabled.value && !isWhiteLabelingEnabled">
        <input type="checkbox" [formControl]="enabled" />
        <span></span>
      </label>
    </div>
    <div class="column">
      <h2 class="title is-3" i18n>Merchant Choice Routing</h2>
    </div>
  </div>

  <div class="label" i18n>Minimum Threshold Value</div>

  <div class="columns is-multiline">
    <div class="control column is-half" *ngFor="let defaultBrand of defaultBrands">
      <label class="label">
        <span>{{ defaultBrand }}</span>
      </label>
      <ng-container *ngIf="getPaymentTypeControl(defaultBrand)">
        <div class="control">
          <portal-currency-amount
            [amount]="getPaymentTypeControl(defaultBrand)"
            [currency]="currency"
            [amountMask]="amountMask"
          ></portal-currency-amount>
        </div>
        <portal-form-error
          [formInput]="getPaymentTypeControl(defaultBrand)"
          errorId="mcrAmount"
        ></portal-form-error>
      </ng-container>
    </div>
    <span class="has-text-weight-semibold" i18n>Note:&nbsp;</span
    ><span i18n>Transactions above or equal to this value will be routed to eftpos</span>
  </div>
</ng-container>
