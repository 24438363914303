import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ReportsInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeOffset = new Date().getTimezoneOffset() * -1;

    if (request.url.includes('s3/download')) {
      const newRequest = request.clone({
        headers: request.headers
          .set('Content-type', 'application/json') //this header breaks image loading
          .set('Accept', 'text/plain, */*')
          .set('Cache-Control', 'no-cache'),
      });

      return next.handle(newRequest);
    }
    if (request.url.includes('reports')) {
      const newRequest = request.clone({
        url: `${request.url}?time_offset=${timeOffset}`,
      });

      return next.handle(newRequest);
    }
    return next.handle(request);
  }
}
