/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ISelectInput } from '../../../../src/lib/box/interfaces/select-input.interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownSize } from './dropdown-size.enum';

const noop = (): void => {};

@Component({
  selector: 'portal-single-dropdown',
  templateUrl: 'single-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SingleDropdownComponent,
      multi: true,
    },
  ],
  styleUrls: ['./single-dropdown.component.scss'],
})
export class SingleDropdownComponent implements OnChanges, ControlValueAccessor {
  @Input() entities: ISelectInput[];
  @Input() size: DropdownSize = DropdownSize.Middle;
  @Input() isAddon = false;
  @Input() placeholder: string;
  @Input() selectedItem: ISelectInput;
  @Input() isDropUp = false;
  @Input() isChip = false;
  @Input() isLightGrey = false;
  @Input() isMiddleButtonAndSmallDropdown = false;
  @Input() isProductQuantityAvailable = false;

  @Output() selected = new EventEmitter<ISelectInput>();

  isOpen = false;
  label = '';

  private onChange: (value: any) => void = noop;
  private onTouched: () => void = noop;

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.entities || changes.placeholder) && this.placeholder) {
      this.entities = [{ id: null, text: this.placeholder }, ...this.entities];
      this.onSelectItem(this.entities[0]);
    }

    if (changes.selectedItem) {
      this.label = this.selectedItem.text;
      this.onChange(this.selectedItem.id);
    }
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  onSelectItem(item: ISelectInput, evt?: Event): void {
    if (evt) {
      evt.preventDefault();
    }
    this.isOpen = false;
    this.label = item.text;
    this.selectedItem = item;
    this.onChange(item.id);
    this.selected.emit(item);
  }

  onOusideclick(): void {
    if (this.isOpen) {
      this.isOpen = false;
    }
  }

  isSmall(): boolean {
    return this.size === DropdownSize.Small;
  }

  isMiddle(): boolean {
    return this.size === DropdownSize.Middle;
  }

  isLarge(): boolean {
    return this.size === DropdownSize.Large;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.selectedItem = this.entities.find((entity) => entity.id === value) || null;
    if (this.selectedItem?.text) {
      this.label = this.selectedItem.text;
    }
  }
}
