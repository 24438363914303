import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private _showLoader = false;
  get showLoader(): boolean {
    return this._showLoader;
  }
  set showLoader(val: boolean) {
    this._showLoader = val;
  }
  constructor() {}
  startLoading(): void {
    // alert(1);
    this.showLoader = true;
  }
  stopLoading(): void {
    // alert(2);

    this.showLoader = false;
  }
}
