import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class FirstResponseInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(req).pipe(
      // Skip `sent` event
      filter((e) => e.type !== 0),
      // tap(e => console.log('[FOO]: response!', e)),
    );
  }
}
