import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISelectInput, ISelectCountFilterInput } from '@portal/shared/ui/filter';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SearchSelector } from '@portal/entity-services/users/src/lib/enums/search-selector.enum';
import { TransactionFilterList } from '@apps/portal/src/app/modules/transactions/components/filter/transaction-filters.list';
import { FilterSubMenu } from '@portal/shared/ui/filter/src/lib/enums/filter-sub-menu.enum';
import { TransactionFilter } from '@apps/portal/src/app/modules/transactions/enums';

export const FILTER_CONSTANTS = {
  iconFilter: 'iconFilter',
  counterFilter: 'counterFilter',
  displayTextFilters: 2,
  maxWidthTextLabel: 300, // maximum px size
};

@Component({
  selector: 'portal-filter',
  templateUrl: './portal-filter.component.html',
  styleUrls: ['./portal-filter.component.scss'],
})
export class PortalFilterComponent implements OnInit {
  @Input()
  selectedStaticFilters: ISelectInput[];
  @Input()
  countItemsSubMenuSelected: number;
  @Input()
  countItemsSubMenuSelectedArray: ISelectCountFilterInput[];
  @Input()
  subMenuSelected: string;
  @Input()
  numberTextFiltersToDisplay: number;
  @Input()
  filterSubMenuSelected: string;
  @Input()
  transactionFiltersArray: Array<string>;
  @Input()
  isDisplayedVerticalAndHorizontal: boolean;
  @Output()
  selectedFiltersToRemove: EventEmitter<Record<string, any>> = new EventEmitter();
  @Output()
  itemToggled = new EventEmitter();
  @Output()
  displayFromFilter: EventEmitter<boolean> = new EventEmitter();
  @Output()
  subMenuName: EventEmitter<string> = new EventEmitter();

  isHoverIconFilter: boolean;
  isHoverCounterFilter: boolean;
  readonly CONSTANTS = FILTER_CONSTANTS;
  private goToSubmenuSubject$ = new Subject<string>();
  private goToSubmenuSub: Subscription;

  constructor(private transactionFilterList: TransactionFilterList) {}

  ngOnInit(): void {
    this.goToSubmenuSub = this.goToSubmenuSubject$
      .pipe(debounceTime(100))
      .subscribe((submenu: string) => {
        const element = document.getElementById(`filter-${submenu}-detail`);
        if (element) {
          element.click();
        }
      });
  }

  goToSubmenu(filterSubMenu: string, event?: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    // TODO: remove condition for transaction date filter
    if (filterSubMenu !== TransactionFilter.TransactionDate) {
      this.subMenuName.emit(filterSubMenu);
      this.itemToggled.emit();
      this.displayFromFilter.emit(true);
      this.goToSubmenuSubject$.next(filterSubMenu);
    }
  }

  onMouseOver(event: Event, actionEvent: string): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.target as HTMLInputElement) {
      if (this.CONSTANTS.iconFilter === actionEvent) {
        this.isHoverIconFilter = true;
      }
      if (this.CONSTANTS.counterFilter === actionEvent) {
        this.isHoverCounterFilter = true;
      }
    }
  }

  onMouseLeave(event: Event, actionEvent: string): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.CONSTANTS.iconFilter === actionEvent) {
      this.isHoverIconFilter = false;
    }
    if (this.CONSTANTS.counterFilter === actionEvent) {
      this.isHoverCounterFilter = false;
    }
  }

  removeAllStaticFilter(selectedInputFilters: ISelectInput[], key: string, evt?: Event): void {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    const selectedFilters = selectedInputFilters.filter((filter) => filter.key === key);
    const index = this.transactionFiltersArray.indexOf(key, 0);
    if (index > -1) {
      this.transactionFiltersArray.splice(index, 1);
    }
    const removeStaticFilter = {
      selectedFilters: selectedFilters,
      filterSubMenu: selectedFilters?.[0]?.filterSubMenu || '',
      filterKey: key || '',
    };
    this.selectedFiltersToRemove.emit(removeStaticFilter);
    if (key === 'currency') {
      localStorage.removeItem('currencyId');
    }
    if (key === 'entityUid') {
      localStorage.removeItem('entityName');
      localStorage.removeItem('entityUid');
    }
  }

  isDisplayingCountFilters(selectedFiltersLength: number): boolean {
    return selectedFiltersLength > this.numberTextFiltersToDisplay;
  }

  isFilterSelected(selectedStaticFilters: ISelectInput[], key: string): boolean {
    if (!selectedStaticFilters || !key) {
      return false;
    }
    return selectedStaticFilters?.find((filterKey) => filterKey.key === key)?.key === key;
  }

  getSelectedFilterSubMenu(selectedStaticFilters: ISelectInput[], key: string): string {
    return key === 'entityUid'
      ? 'organisations'
      : selectedStaticFilters.filter((filterKey) => filterKey?.key === key)[0]?.key;
  }

  isSelectedLabelTextExceedsMaxWidth(selectedStaticFilters: ISelectInput[]): boolean {
    let isValidSize = true;
    this.numberTextFiltersToDisplay = this.CONSTANTS.displayTextFilters;
    selectedStaticFilters?.forEach((filter) => {
      const width = Math.ceil(
        document.createElement('canvas').getContext('2d').measureText(filter.text).width,
      );
      if (width >= this.CONSTANTS.maxWidthTextLabel) {
        isValidSize = false;
        this.numberTextFiltersToDisplay = 0;
      }
    });
    return isValidSize;
  }

  countItemsSubMenuSelectedByKey(filterSubMenu: string): number {
    const itemsSubMenuSelectedByKey = this.countItemsSubMenuSelectedArray?.find(
      (filterSubMenuSelected) => filterSubMenuSelected.filterSubMenu === filterSubMenu,
    );
    return itemsSubMenuSelectedByKey?.countItems || 0;
  }

  currentFilterName(filterName: string): string {
    return this.transactionFilterList?.keyValue?.[filterName] || '';
  }

  currentFilterNameItem(filterName: string, filterKey: string, filterId: string): string {
    if (filterName) {
      return filterName;
    } else {
      if (SearchSelector.EntityId === filterKey) {
        return this.selectedStaticFilters?.find((filter) => filter.id === filterId).id;
      }
    }
  }
}
