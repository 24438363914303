export const vhqFeatureModules = {
  "modules": [
      {
          "Module": "Home",
          "Sections": [
              {
                  "Action": "Devices card",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "High Alerts",
                  "Object": ["Device Alerts"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Failed Updates",
                  "Object": ["Rollouts"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Active",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "New Devices",
                  "Object": ["Device"],
                  "Operation": ["View"]
              }
          ],
          "Tabs": [],
          "Actions": []
      },
      {
          "Module": "Notifications",
          "Sections": [],
          "Tabs": [
              {
                  "Action": "Alerts",
                  "Object": ["Device Alerts"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Notifications",
                  "Object": ["Device"],
                  "Operation": ["View"]
              }
          ],
          "Actions": []
      },
      {
          "Module": "Import/Export",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Device"],
                  "Operation": ["View"]
              }
          ],
          "Tabs": [],
          "Actions": []
      },
      {
          "Module": "Dashboard",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Total Devices",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "High Alerts",
                  "Object": ["Device", "Device Alerts"],
                  "Operation": ["View", "View"]
              },
              {
                  "Action": "Active Rollouts",
                  "Object": ["Device", "Rollouts"],
                  "Operation": ["View", "View"]
              },
              {
                  "Action": "Low Memory Devices",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Low Battery Devices",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "SW Out of Sync Devices",
                  "Object": ["Device", "Estate Care"],
                  "Operation": ["View", "View"]
              },
              {
                  "Action": "Card Reader Failures",
                  "Object": ["Device", "Estate Care"],
                  "Operation": ["View", "View"]
              },
              {
                  "Action": "Open Alerts",
                  "Object": ["Device", "Device Alerts"],
                  "Operation": ["View", "View"]
              },
              {
                  "Action": "Rollouts",
                  "Object": ["Device", "Rollouts"],
                  "Operation": ["View", "View"]
              },
              {
                  "Action": "Swapped Devices",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Health",
                  "Object": ["Device", "Estate Care"],
                  "Operation": ["View", "View"]
              }
          ],
          "Tabs": [],
          "Actions": []
      },
      {
          "Module": "Devices",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Search",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Last Heartbeat",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Hierarchy",
                  "Object": ["Device", "Device Hierarchies"],
                  "Operation": ["View", "View"]
              },
              {
                  "Action": "Status",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Models",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Groups",
                  "Object": ["Device", "Device Groups"],
                  "Operation": ["View", "View"]
              },
              {
                  "Action": "Import",
                  "Object": ["Device", "Import"],
                  "Operation": ["View", "Execute"]
              },
              {
                  "Action": "An Existing Group",
                  "Object": ["Device", "Device", "Device Groups"],
                  "Operation": ["View", "Update", "View"]
              },
              {
                  "Action": "A New Group",
                  "Object": ["Device", "Device", "Device Groups"],
                  "Operation": ["View", "Update", "Add"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "Configure",
                  "Object": [],
                  "Operation": [],
                  "SubActions": [
                      {
                          "Action": "Add",
                          "Object": ["Device", "Device"],
                          "Operation": ["View", "Add"]
                      },
                      {
                          "Action": "Clone",
                          "Object": ["Device", "Device"],
                          "Operation": ["View", "Add"]
                      },
                      {
                          "Action": "Delete",
                          "Object": ["Device", "Device"],
                          "Operation": ["View", "Delete"]
                      }
                  ]
              },
              {
                  "Action": "Assign Hierarchy/Group",
                  "Object": [],
                  "Operation": [],
                  "SubActions": [
                      {
                          "Action": "Move to Hierarchy",
                          "Object": ["Device", "Device", "Device Hierarchies"],
                          "Operation": ["View", "Update", "View"]
                      },
                      {
                          "Action": "Move to Estate Owner Pool",
                          "Object": ["Device", "Device", "Device Hierarchies"],
                          "Operation": ["View", "Update", "View"]
                      },
                      {
                          "Action": "Assign to Group",
                          "Object": ["Device", "Device", "Device Groups"],
                          "Operation": ["View", "Update", "View"]
                      }
                  ]
              },
              {
                  "Action": "Status",
                  "Object": [],
                  "Operation": [],
                  "SubActions": [
                      {
                          "Action": "Active",
                          "Object": ["Device", "Device"],
                          "Operation": ["View", "Update"]
                      },
                      {
                          "Action": "Inactive",
                          "Object": ["Device", "Device"],
                          "Operation": ["View", "Update"]
                      }
                  ]
              },
              {
                  "Action": "Export",
                  "Object": [],
                  "Operation": [],
                  "SubActions": [
                      {
                          "Action": "Devices",
                          "Object": ["Device", "Export"],
                          "Operation": ["View", "Execute"]
                      },
                      {
                          "Action": "Device Metadata",
                          "Object": ["Device", "Export"],
                          "Operation": ["View", "Execute"]
                      },
                      {
                          "Action": "VRK Certificate",
                          "Object": ["Device", "Export"],
                          "Operation": ["View", "Execute"]
                      }
                  ]
              },
              {
                  "Action": "Automation",
                  "Object": [],
                  "Operation": [],
                  "SubActions": [
                      {
                          "Action": "Enable",
                          "Object": ["Device", "Device", "Advanced Device Management"],
                          "Operation": ["View", "Update", "Update"]
                      },
                      {
                          "Action": "Disable",
                          "Object": ["Device", "Device", "Advanced Device Management"],
                          "Operation": ["View", "Update", "Update"]
                      }
                  ]
              },
              {
                  "Action": "Software Assignment",
                  "Object": ["Device", "Device", "Device Software Configuration"],
                  "Operation": ["View", "Update", "Update"]
              }
          ]
      },
      {
          "Module": "Device Profile",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Chain of Custody",
                  "Object": ["Device", "Asset Tracking File Chain of Custody", "Asset Tracking Shipment receipt", "Asset Tracking Inspection", "Location"],
                  "Operation": ["View", "Add", "Add", "Add", "View"]
              },
              {
                  "Action": "Asset Tracking",
                  "Object": ["Device", "Asset Tracking Management"],
                  "Operation": ["View", "View"]
              },
              {
                  "Action": "Key Indicators",
                  "Object": ["Device", "Device Care"],
                  "Operation": ["View", "View"]
              }
          ],
          "Tabs": [
              {
                  "Action": "Device Details",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Organization/Location",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Software",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Care",
                  "Object": ["Device", "Device Care"],
                  "Operation": ["View", "View"]
              },
              {
                  "Action": "Profile",
                  "Object": ["Device"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Details",
                  "Object": [],
                  "Operation": [],
                  "SubTabs": [
                      {
                          "Action": "Software",
                          "Object": ["Device"],
                          "Operation": ["View"]
                      },
                      {
                          "Action": "Software Status",
                          "Object": [],
                          "Operation": [],
                          "ChildTabs": [
                              {
                                  "Action": "Packages",
                                  "Object": ["Device"],
                                  "Operation": ["View"]
                              },
                              {
                                  "Action": "File Status",
                                  "Object": ["Device", "Device Content Management", "Parameters Configuration"],
                                  "Operation": ["View", "View", "View"]
                              },
                              {
                                  "Action": "Key Profiles",
                                  "Object": ["Device", "Device Online Key Management"],
                                  "Operation": ["View", "View"]
                              }
                          ]
                      },
                      {
                          "Action": "Parameters",
                          "Object": ["Device", "Basic Parameter Management|Advanced Parameter Management"],
                          "Operation": ["View", "View"]
                      },
                      {
                          "Action": "Diagnostic Profile",
                          "Object": [],
                          "Operation": [],
                          "ChildTabs": [
                              {
                                  "Action": "Diagnostic Data",
                                  "Object": ["Device", "Device Diagnostics Management"],
                                  "Operation": ["View", "View"]
                              },
                              {
                                  "Action": "Process Data",
                                  "Object": ["Device", "Device Diagnostics Management"],
                                  "Operation": ["View", "View"]
                              },
                              {
                                  "Action": "Process File Handles",
                                  "Object": ["Device", "Device Diagnostics Management"],
                                  "Operation": ["View", "View"]
                              },
                              {
                                  "Action": "System Keys",
                                  "Object": ["Device", "Device Diagnostics Management"],
                                  "Operation": ["View", "View"]
                              }
                          ]
                      },
                      {
                          "Action": "Custom Identifiers",
                          "Object": ["Device"],
                          "Operation": ["View"]
                      },
                      {
                          "Action": "Ports",
                          "Object": ["Device"],
                          "Operation": ["View"]
                      },
                      {
                          "Action": "Sim Card",
                          "Object": ["Device"],
                          "Operation": ["View"]
                      },
                      {
                          "Action": "Custom Attributes",
                          "Object": ["Device"],
                          "Operation": ["View"]
                      },
                      {
                          "Action": "Security",
                          "Object": [],
                          "Operation": [],
                          "ChildTabs": [
                              {
                                  "Action": "VeriShield Retain",
                                  "Object": ["Device"],
                                  "Operation": ["View"]
                              },
                              {
                                  "Action": "Encryption Keys",
                                  "Object": ["Device"],
                                  "Operation": ["View"]
                              },
                              {
                                  "Action": "Verishield Protect",
                                  "Object": ["Device"],
                                  "Operation": ["View"]
                              }
                          ]
                      }

                  ]
              },
              {
                  "Action": "History",
                  "Object": [],
                  "Operation": [],
                  "SubTabs": [
                      {
                          "Action": "Swap History",
                          "Object": ["Device"],
                          "Operation": ["View"]
                      },
                      {
                          "Action": "Connected Devices",
                          "Object": ["Device"],
                          "Operation": ["View"]
                      },
                      {
                          "Action": "Peripheral Devices",
                          "Object": ["Device"],
                          "Operation": ["View"]
                      },
                      {
                          "Action": "Relocation/Status History",
                          "Object": ["Device", "Reports/Charts"],
                          "Operation": ["View", "View"]
                      },
                      {
                          "Action": "Communication History",
                          "Object": [],
                          "Operation": [],
                          "ChildTabs": [
                              {
                                  "Action": "Contact",
                                  "Object": ["Device"],
                                  "Operation": ["View"]
                              },
                              {
                                  "Action": "History",
                                  "Object": ["Device"],
                                  "Operation": ["View"]
                              }
                          ]
                      },
                      {
                          "Action": "Change History",
                          "Object": ["Device", "Audits"],
                          "Operation": ["View", "View"]
                      },
                      {
                          "Action": "Alerts",
                          "Object": ["Device", "Device Alerts"],
                          "Operation": ["View", "View"]
                      },
                      {
                          "Action": "Docking History",
                          "Object": ["Device"],
                          "Operation": ["View"]
                      },
                      {
                          "Action": "Groups Assigned",
                          "Object": ["Device"],
                          "Operation": ["View"]
                      },
                      {
                          "Action": "Application Attributes",
                          "Object": ["Device"],
                          "Operation": ["View"]
                      },
                      {
                          "Action": "Parameter Audit History",
                          "Object": ["Device", "Audits"],
                          "Operation": ["View", "View"]
                      }
                  ]
              },
              {
                  "Action": "Jobs",
                  "Object": [],
                  "Operation": [],
                  "SubTabs": [
                      {
                          "Action": "Download",
                          "Object": ["Device", "Device Download Management"],
                          "Operation": ["View", "View"]
                      },
                      {
                          "Action": "Download Job Details",
                          "Object": ["Device", "Device Download Management"],
                          "Operation": ["View", "View"]
                      },
                      {
                          "Action": "VRK",
                          "Object": ["Device", "Offline Keys Library and Device Offline Keys Mgt"],
                          "Operation": ["View", "View"]
                      },
                      {
                          "Action": "VRK Job Details",
                          "Object": ["Device", "Offline Keys Library and Device Offline Keys Mgt"],
                          "Operation": ["View", "View"]
                      },
                      {
                          "Action": "Diagnostic",
                          "Object": ["Device", "Device Diagnostics Management"],
                          "Operation": ["View", "View"]
                      },
                      {
                          "Action": "Diagnostic Job Details",
                          "Object": ["Device", "Device Diagnostics Management"],
                          "Operation": ["View", "View"]
                      },
                      {
                          "Action": "Content",
                          "Object": ["Device", "Device Content Management"],
                          "Operation": ["View", "View"]
                      },
                      {
                          "Action": "Content Job Details",
                          "Object": ["Device", "Device Content Management"],
                          "Operation": ["View", "View"]
                      }
                  ]
              }
          ],
          "Actions": [
              {
                  "Action": "Clone",
                  "Object": ["Device", "Device"],
                  "Operation": ["View", "Add"]
              },
              {
                  "Action": "Delete",
                  "Object": ["Device", "Device"],
                  "Operation": ["View", "Delete"]
              },
              {
                  "Action": "Move",
                  "Object": ["Device", "Device", "Device Hierarchies"],
                  "Operation": ["View", "Update", "View"]
              },
              {
                  "Action": "Move to Estate Owner Pool",
                  "Object": ["Device", "Device", "Device Hierarchies"],
                  "Operation": ["View", "Update", "View"]
              },
              {
                  "Action": "Swap",
                  "Object": ["Device", "Advanced Device Management"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Automation",
                  "Object": ["Device", "Advanced Device Management"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Generate Report",
                  "Object": ["Device", "Asset Tracking Management"],
                  "Operation": ["View", "View"]
              },
              {
                  "Action": "Set Log Level",
                  "Object": ["Device", "Device Diagnostics Management"],
                  "Operation": ["View", "Add"]
              },
              {
                  "Action": "Download Schedule",
                  "Object": [],
                  "Operation": [],
                  "SubActions": [
                      {
                          "Action": "Software and Parameters",
                          "Object": ["Device", "Rollouts", "Device Download Management", "Device Software Configuration", "Basic Parameter Management|Advanced Parameter Management"],
                          "Operation": ["View", "Add", "Add", "Update", "Update"]
                      },
                      {
                          "Action": "Parameters only",
                          "Object": ["Device", "Rollouts", "Device Download Management", "Device Software Configuration", "Basic Parameter Management|Advanced Parameter Management"],
                          "Operation": ["View", "Add", "Add", "Update", "Update"]
                      },
                      {
                          "Action": "Content",
                          "Object": ["Device", "Rollouts", "Device Content Management"],
                          "Operation": ["View", "Add", "Add"]
                      },
                      {
                          "Action": "Diagnostics",
                          "Object": ["Device", "Rollouts", "Device Diagnostics Management"],
                          "Operation": ["View", "Add", "Add"]
                      }
                  ]
              },
              {
                  "Action": "Software Assignment",
                  "Object": ["Device", "Device Software Configuration"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Status",
                  "Object": [],
                  "Operation": [],
                  "SubActions": [
                      {
                          "Action": "Active",
                          "Object": ["Device", "Device"],
                          "Operation": ["View", "Update"]
                      },
                      {
                          "Action": "Inactive",
                          "Object": ["Device", "Device"],
                          "Operation": ["View", "Update"]
                      }
                  ]
              },
              {
                  "Action": "Edit Serial Number/Device Id",
                  "Object": ["Device", "Device"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Generate Admin Passcode",
                  "Object": ["Device", "Advanced Device Management"],
                  "Operation": ["View", "Update"]
              }
          ]
      },
      {
          "Module": "Deleted Devices",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Device"],
                  "Operation": ["View"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "Export",
                  "Object": ["Device", "Export"],
                  "Operation": ["View", "Execute"]
              },
              {
                  "Action": "Undelete",
                  "Object": ["Device", "Device"],
                  "Operation": ["View", "Delete"]
              },
              {
                  "Action": "Delete Permanently",
                  "Object": ["Device", "Advanced Device Management"],
                  "Operation": ["View", "Update"]
              }
          ]
      },
      {
          "Module": "Rollouts",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Rollouts"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Create New batch",
                  "Object": ["Device", "Rollouts", "Rollouts"],
                  "Operation": ["View", "View", "Add"]
              },
              {
                  "Action": "Edit batch",
                  "Object": ["Device", "Rollouts", "Rollouts"],
                  "Operation": ["View", "View", "Update"]
              },
              {
                  "Action": "Cancel Job",
                  "Object": ["Device", "Rollouts", "Rollouts"],
                  "Operation": ["View", "View", "Delete"]
              },
              {
                  "Action": "Software and Parameter Templates",
                  "Object": ["Rollouts", "Rollouts", "Device Download Management", "Device Software Configuration", "Basic Parameter Management|Advanced Parameter Management"],
                  "Operation": ["View", "Add", "Add", "Update", "Update"]
              },
              {
                  "Action": "Parameters Only",
                  "Object": ["Rollouts", "Rollouts", "Device Download Management", "Device Software Configuration", "Basic Parameter Management|Advanced Parameter Management"],
                  "Operation": ["View", "Add", "Add", "Update","Update"]
              },
              {
                  "Action": "Content",
                  "Object": ["Rollouts", "Rollouts", "Device Content Management"],
                  "Operation": ["View", "Add", "Add"]
              },
              {
                  "Action": "Diagnostics",
                  "Object": ["Rollouts", "Rollouts", "Device Diagnostics Management"],
                  "Operation": ["View", "Add", "Add"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "Create New Rollout",
                  "Object": ["Device", "Rollouts", "Rollouts"],
                  "Operation": ["View", "View", "Add"]
              }
          ]
      },
      {
          "Module": "Hierarchies",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Device Hierarchies"],
                  "Operation": ["View"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "Update Hierarchy",
                  "Object": [],
                  "Operation": [],
                  "SubActions": [
                      {
                          "Action": "Add child node",
                          "Object": ["Device Hierarchies", "Device Hierarchies", "Device Hierarchies"],
                          "Operation": ["View", "Add", "Update"]
                      },
                      {
                          "Action": "Add node to Root",
                          "Object": ["Device Hierarchies", "Device Hierarchies", "Device Hierarchies"],
                          "Operation": ["View", "Add", "Update"]
                      },
                      {
                          "Action": "Edit node",
                          "Object": [],
                          "Operation": [],
                          "ChildActions": [
                              {
                                  "Action": "Details",
                                  "Object": ["Device Hierarchies", "Device Hierarchies"],
                                  "Operation": ["View", "Update"]
                              },
                              {
                                  "Action": "Remove node",
                                  "Object": ["Device Hierarchies", "Device Hierarchies"],
                                  "Operation": ["View", "Delete"]
                              },
                              {
                                  "Action": "Users",
                                  "Object": ["Device Hierarchies", "Device Hierarchies"],
                                  "Operation": ["View", "Update"]
                              }
                          ]
                      }
                  ]
              },
              {
                  "Action": "Import",
                  "Object": ["Device Hierarchies", "Import"],
                  "Operation": ["View", "Execute"]
              }
          ]
      },
      {
          "Module": "Groups",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Device", "Device Groups"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Unassign",
                  "Object": ["Device", "Device", "Device Groups"],
                  "Operation": ["View", "Update", "View"]
              },
              {
                  "Action": "Edit",
                  "Object": ["Device Groups", "Device Groups"],
                  "Operation": ["View", "Update"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "Export",
                  "Object": ["Device Groups", "Export"],
                  "Operation": ["View", "Execute"]
              },
              {
                  "Action": "Add",
                  "Object": ["Device Groups", "Device Groups"],
                  "Operation": ["View", "Add"]
              },
              {
                  "Action": "Delete",
                  "Object": ["Device Groups", "Device Groups"],
                  "Operation": ["View", "Delete"]
              }
          ]
      },
      {
          "Module": "Tenants",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Tenant"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Users",
                  "Object": ["Tenant", "Tenant"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Features",
                  "Object": ["Tenant", "Tenant"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Reference Sets and Parameter Templates",
                  "Object": ["Tenant", "Tenant", "Software Configurations"],
                  "Operation": ["View", "Update", "View"]
              },
              {
                  "Action": "Content",
                  "Object": ["Tenant", "Tenant", "Device Content Management"],
                  "Operation": ["View", "Update", "View"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "View",
                  "Object": ["Tenant"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Add",
                  "Object": ["Tenant", "Tenant"],
                  "Operation": ["View", "Add"]
              },
              {
                  "Action": "Edit",
                  "Object": ["Tenant", "Tenant"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Delete",
                  "Object": ["Tenant", "Tenant"],
                  "Operation": ["View", "Delete"]
              }
          ]
      },
      {
          "Module": "Locations",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Location"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Edit",
                  "Object": ["Location", "Location"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Delete",
                  "Object": ["Location", "Location"],
                  "Operation": ["View", "Delete"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "Export",
                  "Object": ["Location", "Export"],
                  "Operation": ["View", "Execute"]
              },
              {
                  "Action": "Import",
                  "Object": ["Location", "Import"],
                  "Operation": ["View", "Execute"]
              },
              {
                  "Action": "Add",
                  "Object": ["Location", "Location"],
                  "Operation": ["View", "Add"]
              }
          ]
      },
      {
          "Module": "Software Library",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Software Configurations"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Add Folder",
                  "Object": ["Software Configurations", "Software Configurations"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Edit Folder",
                  "Object": ["Software Configurations", "Software Configurations"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Delete Folder",
                  "Object": ["Software Configurations", "Software Configurations"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Upload Parameters Zip",
                  "Object": ["Software Configurations", "Parameters Configuration"],
                  "Operation": ["View", "Add"]
              },
              {
                  "Action": "Download Parameters Zip",
                  "Object": ["Software Configurations", "Parameters Configuration"],
                  "Operation": ["View", "Add"]
              },
              {
                  "Action": "Configure Parameters",
                  "Object": ["Software Configurations", "Parameters Configuration"],
                  "Operation": ["View", "Add"]
              },
              {
                  "Action": "Configure Parameter Templates",
                  "Object": ["Software Configurations", "Parameters Configuration"],
                  "Operation": ["View", "Add"]
              },
              {
                  "Action": "Edit",
                  "Object": ["Software Configurations", "Software Configurations"],
                  "Operation": ["View", "Update"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "Export",
                  "Object": ["Software Configurations", "Export"],
                  "Operation": ["View", "Execute"]
              },
              {
                  "Action": "Add",
                  "Object": ["Software Configurations", "Software Configurations"],
                  "Operation": ["View", "Add"]
              },
              {
                  "Action": "Move",
                  "Object": ["Software Configurations", "Software Configurations"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Delete",
                  "Object": ["Software Configurations", "Software Configurations"],
                  "Operation": ["View", "Delete"]
              },
              {
                  "Action": "Add Reference Set",
                  "Object": ["Software Configurations", "Software Configurations"],
                  "Operation": ["View", "Add"]
              },
              {
                  "Action": "Import Parameter Templates",
                  "Object": ["Software Configurations", "Parameters Configuration", "Import"],
                  "Operation": ["View", "Add", "Execute"]
              }
          ]
      },
      {
          "Module": "Reference Sets",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Software Configurations"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Edit",
                  "Object": ["Software Configurations", "Software Configurations"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Keys",
                  "Object": ["Software Configurations", "Device Online Key Management"],
                  "Operation": ["View", "Update"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "Export",
                  "Object": ["Software Configurations", "Export"],
                  "Operation": ["View", "Execute"]
              },
              {
                  "Action": "Add",
                  "Object": ["Software Configurations", "Software Configurations"],
                  "Operation": ["View", "Add"]
              },
              {
                  "Action": "Copy",
                  "Object": ["Software Configurations", "Software Configurations"],
                  "Operation": ["View", "Add"]
              },
              {
                  "Action": "Lock",
                  "Object": ["Software Configurations", "Advanced Software Management"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Unlock",
                  "Object": ["Software Configurations", "Advanced Software Management"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Delete",
                  "Object": ["Software Configurations", "Software Configurations"],
                  "Operation": ["View", "Delete"]
              }
          ]
      },
      {
          "Module": "Alerts",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Device Alerts"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Notes",
                  "Object": ["Device Alerts", "Advanced Device Management"],
                  "Operation": ["View", "Update"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "Close",
                  "Object": ["Device Alerts", "Device Alerts"],
                  "Operation": ["View", "Update"]
              },
              {
                  "Action": "Export",
                  "Object": ["Device Alerts", "Export"],
                  "Operation": ["View", "Execute"]
              }
          ]
      },
      {
          "Module": "Alert Subscriptions",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Device Alerts", "Device Alerts Configurations"],
                  "Operation": ["View", "View"]
              },
              {
                  "Action": "Edit",
                  "Object": ["Device Alerts", "Device Alerts Configurations", "Device Alerts Configurations"],
                  "Operation": ["View", "View", "Update"]
              }
          ],
          "Tabs": [],
          "Actions": []
      },
      {
          "Module": "Alert Configurations",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Device Alerts Configurations"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Edit",
                  "Object": ["Device Alerts Configurations", "Device Alerts Configurations"],
                  "Operation": ["View", "Update"]
              }
          ],
          "Tabs": [],
          "Actions": []
      },
      {
          "Module": "System Configurations",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["System Settings"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Edit",
                  "Object": ["System Settings", "System Settings"],
                  "Operation": ["View", "Update"]
              }
          ],
          "Tabs": [],
          "Actions": []
      },
      {
          "Module": "Sponsors",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Online Keys Configurations"],
                  "Operation": ["View"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "Export",
                  "Object": ["Online Keys Configurations", "Export"],
                  "Operation": ["View", "Execute"]
              },
              {
                  "Action": "Add",
                  "Object": ["Online Keys Configurations", "Online Keys Configurations"],
                  "Operation": ["View", "Update"]
              }
          ]
      },
      {
          "Module": "Key Handles",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Online Keys Configurations"],
                  "Operation": ["View"]
              },
              {
                  "Action": "Save",
                  "Object": ["Online Keys Configurations", "Online Keys Configurations"],
                  "Operation": ["View", "Update"]
              }
          ],
          "Tabs": [],
          "Actions": []
      },
      {
        "Module": "Standard Reports",
        "Sections": [
            {
                "Action": "View",
                "Object": ["Reports Configuration (Subscriptions and Custom Reports)"],
                "Operation": ["View"]
            },
            {
                "Action": "Search",
                "Object": ["Device"],
                "Operation": ["View"]
            },
            {
                "Action": "Last Heartbeat",
                "Object": ["Device"],
                "Operation": ["View"]
            },
            {
                "Action": "Hierarchy",
                "Object": ["Device", "Device Hierarchies"],
                "Operation": ["View", "View"]
            },
            {
                "Action": "Status",
                "Object": ["Device"],
                "Operation": ["View"]
            },
            {
                "Action": "Models",
                "Object": ["Device"],
                "Operation": ["View"]
            },
            {
                "Action": "Groups",
                "Object": ["Device", "Device Groups"],
                "Operation": ["View", "View"]
            }
        ],
        "Tabs": [],
        "Actions": []
    },
      {
          "Module": "Chain of Custody",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Asset Tracking File Chain of Custody"],
                  "Operation": ["View"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "Export",
                  "Object": ["Asset Tracking File Chain of Custody", "Export"],
                  "Operation": ["View", "Execute"]
              },
              {
                  "Action": "Import",
                  "Object": ["Asset Tracking File Chain of Custody", "Import"],
                  "Operation": ["Add", "Execute"]
              }
          ]
      },
      {
          "Module": "Device Report",
          "Sections": [
              {
                  "Action": "View",
                  "Object": ["Asset Tracking Management"],
                  "Operation": ["View"]
              }
          ],
          "Tabs": [],
          "Actions": [
              {
                  "Action": "Export",
                  "Object": ["Asset Tracking Management", "Export"],
                  "Operation": ["View", "Execute"]
              }
          ]
      }
  ]
}
