<ng-container *ngIf="notifier">
  <div class="notifications">
    <ng-container *ngFor="let notification of notifier.notifications">
      <div
        class="notification is-danger mb-2"
        *ngIf="notification.type === FAIL"
        data-e2e="notifier is-fail"
      >
        <div class="icon">
          <i class="far fa-times-circle fa-lg"></i>
        </div>
        {{ notification.message }}
        <button
          class="close"
          (click)="destroy(notification)"
          data-e2e="notifier-close"
          type="button"
          title="Dismiss notification"
          i18n-title
        >
          <span class="icon">
            <i class="far fa-times fa-lg"></i>
          </span>
          <span class="is-sr-only" i18n>Dismiss notification</span>
        </button>
      </div>
      <div
        class="notification is-success mb-2"
        *ngIf="notification.type === SUCCESS"
        data-e2e="notifier is-success"
      >
        <div class="icon">
          <i class="far fa-check-circle fa-lg"></i>
        </div>
        {{ notification.message }}
        <button
          class="close"
          (click)="destroy(notification)"
          data-e2e="notifier-close"
          type="button"
          title="Dismiss notification"
          i18n-title
        >
          <span class="icon">
            <i class="far fa-times fa-lg"></i>
          </span>
          <span class="is-sr-only" i18n>Dismiss notification</span>
        </button>
      </div>
      <div
        class="notification is-info mb-2"
        *ngIf="notification.type === INFO"
        data-e2e="notifier is-info"
      >
        <div class="icon">
          <i class="far fa-info-circle fa-lg"></i>
        </div>
        {{ notification.message }}
        <button
          class="close"
          (click)="destroy(notification)"
          data-e2e="notifier-close"
          type="button"
          title="Dismiss notification"
          i18n-title
        >
          <span class="icon">
            <i class="far fa-times fa-lg"></i>
          </span>
          <span class="is-sr-only" i18n>Dismiss notification</span>
        </button>
      </div>
      <div
        class="notification is-warning mb-2"
        *ngIf="notification.type === WARN"
        data-e2e="notifier is-warn"
      >
        <div class="icon">
          <i class="far fa-exclamation-circle fa-lg"></i>
        </div>
        {{ notification.message }}
        <button
          class="close"
          (click)="destroy(notification)"
          data-e2e="notifier-close"
          type="button"
          title="Dismiss notification"
          i18n-title
        >
          <span class="icon">
            <i class="far fa-times fa-lg"></i>
          </span>
          <span class="is-sr-only" i18n>Dismiss notification</span>
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>
