import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IVfAppMarketApp } from '@portal/marketplace/api-interfaces/src';

export interface AppCatalogState extends EntityState<IVfAppMarketApp>, ActiveState {
  metadata: {
    count: number;
    limit?: number;
    offset?: number;
    sort?: string;
  };
}
const initialState = {
  metadata: {
    count: 0,
    limit: 0,
    offset: 0,
    sort: '',
  },
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'vf-app-market-app-catalog', idKey: 'appmarketAppId', resettable: true })
export class VfAppMarketAppCatalogStore extends EntityStore<AppCatalogState, IVfAppMarketApp> {
  constructor() {
    super(initialState);
  }
}
