import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { UserService } from '@portal/entity-services/users/src/lib/services/user.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

declare const $localize;

@Injectable()
export class UserValidators {
  constructor(private userService: UserService) {}

  asyncValidateExistingUser(currentEmail?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      // If the current email is given, then it is allowed
      if (currentEmail === control.value) {
        return of(null);
      }
      const users$ = this.userService.checkEmailExist({
        email: control.value,
      });
      return users$.pipe(
        map((user: any) => {
          if (!user.exist) {
            return null;
          }
          return {
            existingEmail: {
              message: '@@EMAIL_ALREADY_EXISTS',
              displayMessage: $localize`There is already a user with this email`,
            },
          };
        }),
      );
    };
  }

  asyncValidateNotExistingEmail(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this.userService
        .checkEmailExist({
          email: control.value,
        })
        .pipe(
          map((email) => {
            if (email.exist) {
              return null;
            }

            return {
              existingEmail: {
                message: '@@EMAIL_DOES_NOT_EXIST',
                displayMessage: $localize`This e-mail does not belong to any registered user of your organization`,
              },
            };
          }),
        );
    };
  }
}
