import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { MultilingualValidators } from '@portal/shared/ui/form';

@Component({
  selector: 'portal-textarea-count',
  templateUrl: './textarea-count.component.html',
})
export class TextareaCountComponent implements OnInit {
  @Input() name: string;
  @Input() control: FormControl;
  @Input() invalid: boolean;
  @Input() e2eName = 'textControl-input';
  @Input() maxLength = 25;
  @Input() placeholder = '';
  @Input() resizable = false;
  @Input() rows = 3;

  hasRequiredValidator: boolean;

  constructor(private customValidators: MultilingualValidators) {}

  ngOnInit(): void {
    this.hasRequiredValidator = this.control.hasValidator(this.customValidators.required);
    this.control.addValidators([this.customValidators.maxLength(this.maxLength)]);
  }
}
