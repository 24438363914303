export enum ProsaAcquirers {
  AmericanExpressOptblue = 'AMEX_OPTBLUE_MX',
  Afirme = 'AFIRME_MX',
  BancaMafiel = 'BANCA_MAFIEL_MX',
  BancoAutofinMexico = 'BANCO_AUTOFIN_MX',
  BancoAzteca = 'BANCO_AZTECA_MX',
  BancoBajio = 'BANCO_BAJIO_MX',
  BancoBansefi = 'BANCO_BANSEFI_MX',
  BancoInbursa = 'BANCO_INBURSA_MX',
  BancoMultiva = 'BANCO_MULTIVA_MX',
  Banjercito = 'BANJERCITO_MX',
  Banregio = 'BANREGIO_MX',
  Bansi = 'BANSI_MX',
  CompartamosBanco = 'COMPARTAMOS_BANCO_MX',
  HSBC = 'HSBC_MX',
  Toka = 'TOKA_MX',
  VePorMas = 'VE_POR_MAS_MX',
}
