import { Injectable } from '@angular/core';
import { InteractionType } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class InteractionTypes extends SelectInputsList {
  keyValue = {
    [InteractionType.Unspecified]: $localize`:interaction type|Interaction type for a POI:Unspecified`,
    [InteractionType.AutoFuelDispenser]: $localize`:interaction type|Interaction type for a POI:Auto Fuel Dispenser`,
    [InteractionType.AutoTellerMachine]: $localize`:interaction type|Interaction type for a POI:Auto Teller Machine`,
    [InteractionType.EletronicCashRegister]: $localize`:interaction type|Interaction type for a POI:Electronic Cash Register`,
    [InteractionType.InflightCommerceTerminal]: $localize`:interaction type|Interaction type for a POI:InFlight Commerce Terminal`,
    [InteractionType.InterVoiceResponse]: $localize`:interaction type|Interaction type for a POI:Inter Voice Response`,
    [InteractionType.PointOfSale]: $localize`:interaction type|Interaction type for a POI:Point of Sale`,
    [InteractionType.Kiosk]: $localize`:interaction type|Interaction type for a POI:Kiosk`,
    [InteractionType.Unattended]: $localize`:interaction type|Interaction type for a POI:Unattended`,
    [InteractionType.Standalone]: $localize`:interaction type|Interaction type for a POI:Standalone`,
    [InteractionType.Mobile]: $localize`:interaction type|Interaction type for a POI:Mobile`,
    [InteractionType.RfDevice]: $localize`:interaction type|Interaction type for a POI:RF Device`,
    [InteractionType.Ticket]: $localize`:interaction type|Interaction type for a POI:Ticket`,
    [InteractionType.CallCentre]: $localize`:interaction type|Interaction type for a POI:Call Centre`,
    [InteractionType.Virtual]: $localize`:interaction type|Interaction type for a POI:Virtual`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
