import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@portal/shared/pipes';
import { AppPromoComponent } from './app-promo.component';

@NgModule({
  imports: [CommonModule, PipesModule],
  declarations: [AppPromoComponent],
  exports: [AppPromoComponent],
})
export class AppPromoModule {}
