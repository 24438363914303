import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';
import { Channel } from '../../../enums/channel.enum';

import sortBy from 'lodash-es/sortBy';

@Injectable()
export class Channels extends SelectInputsList {
  keyValue = {
    [Channel.Mail]: $localize`:transaction channel|:Mail`,
    [Channel.Pos]: $localize`:transaction channel|:Point of sale`,
    [Channel.Ecom]: $localize`:transaction channel|:E-commerce`,
    [Channel.Mobile]: $localize`:transaction channel|:Phone`,
  };
  list: ISelectInput[] = sortBy(this.getSelectInputs(), ['text']);

  constructor() {
    super();
  }
}
