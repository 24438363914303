import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ES_CONSTANTS } from '@portal/entity-services/interfaces/src/lib/core/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WhiteLabelingCaseConverter } from './white-labeling-case-converter';
import { WhiteLabelingService } from './white-labeling.service';

@Injectable()
export class WhiteLabelingInterceptor implements HttpInterceptor {
  constructor(
    private converter: WhiteLabelingCaseConverter,
    private whiteLabelingService: WhiteLabelingService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isPublicBucketRequest(req) || this.isTemplateResellerRequest(req)) {
      return next
        .handle(
          req.clone({
            params: this.converter.httpParamsToKebabCase(req.params),
            body: this.converter.toKebabCase(req.body),
          }),
        )
        .pipe(
          map((event) => {
            if (event instanceof HttpResponse) {
              return event.clone({
                body: this.converter.toCamelCase(event.body),
              });
            }
            return event;
          }),
        );
    }
    return next.handle(req);
  }

  private isPublicBucketRequest(req: HttpRequest<any>): boolean {
    if (!this.whiteLabelingService.getBasePath()) {
      return false;
    }

    return req.url.includes(this.whiteLabelingService.getBasePath());
  }

  private isTemplateResellerRequest(req: HttpRequest<any>): boolean {
    return (
      req.url.includes(ES_CONSTANTS.UIS.TEMPLATE_RESELLER) && req.url.includes('templateParameters')
    );
  }
}
