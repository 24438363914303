import { Component, Input } from '@angular/core';
import { AttachEvent } from '@portal/shared/ui/click-outside/src/lib/enums/attach-event';
import { INavbarItem } from '@portal/shared/ui/layouts/src/lib/top-navbar/interfaces/navbar-item.interface';

@Component({
  selector: 'vui-navbar-dropdown',
  templateUrl: './navbar-dropdown.component.html',
})
export class NavbarDropdownComponent {
  @Input() navbarItems: INavbarItem[] = [];
  @Input() classList = '';
  @Input() isRight = false;

  isActive = false;
  AttachEvent = AttachEvent;

  toggle(): void {
    this.isActive = !this.isActive;
  }

  close(): void {
    this.isActive = false;
  }
}
