export enum SourceId {
  Nzt = 'NZT',
  Aut = 'AUT',
  Tss = 'TSS',
  Gpp = 'GPP',
  Klr = 'KLR',
  Swe = 'SWE',
  Mbp = 'MBP',
  Swh = 'SWH',
  Vps = 'VPS',
  Ppp = 'PPP',
  Vpe = 'VPE',
  Mpe = 'MPE',
  Cre = 'CRE',
  Pwy = 'PWY',
  Opx = 'OPX',
  Opb = 'OPB',
}
