<div class="card-content">
  <div class="media">
    <figure class="media-left image is-64x64">
      <ng-container *ngIf="app?.appIcons?.length">
        <img [src]="app.appIcons[0].publicUrl" data-e2e="quick-view-app-image" alt="no_image" />
      </ng-container>
      <ng-container *ngIf="!app?.appIcons?.length">
        <img src="assets/images/img-placeholder.png" alt="Loading image" />
      </ng-container>
    </figure>
    <div class="media-content">
      <div class="content">
        <h2 class="has-text-white" data-e2e="quick-view-app-name">{{ app?.appName }}</h2>
        <div class="status-price">
          <marketplace-status-indicator [status]="app?.status"></marketplace-status-indicator>

          <div class="price has-text-info" data-e2e="quick-view-app-price">
            <span *ngIf="app?.paymentModel !== 'FREE'">$</span>
            {{ app?.paymentModel !== 'FREE' ? app?.minimumPrice : 'Free' }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="columns">
    <div class="column" data-e2e="quick-view-app-id-label">App Id</div>
    <div class="column" data-e2e="quick-view-app-id">
      {{ app?.app.id }}
    </div>
  </div>

  <div class="columns">
    <div class="column" data-e2e="quick-view-app-publish-date-label">Create date</div>
    <div class="column" data-e2e="quick-view-app-publish-date">
      {{ app?.createdDate | date : 'yyyy-MM-dd' }}
    </div>
  </div>

  <div class="columns">
    <div class="column" data-e2e="quick-view-app-update-date-label">Update date</div>
    <div class="column" data-e2e="quick-view-app-update-date">
      {{ app?.updatedDate | date : 'yyyy-MM-dd' }}
    </div>
  </div>

  <div *ngIf="app?.status === status.APPROVED" class="columns">
    <div class="column" data-e2e="quick-view-approved-by-label">Approved by</div>
    <div class="column" data-e2e="quick-view-approved-by">
      {{ app?.modifiedBy }}
    </div>
  </div>

  <div class="columns">
    <div class="column" data-e2e="quick-view-app-version-label">Version</div>
    <div class="column" data-e2e="quick-view-app-version">
      {{ app?.version }}
    </div>
  </div>

  <div class="columns" *ngIf="app?.description?.length > 0">
    <div class="column" data-e2e="quick-view-description-label">Description</div>
    <div class="column description" data-e2e="quick-view-app-description">
      {{ app?.description }}
    </div>
  </div>
</div>
