import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IApp } from '@portal/marketplace/api-interfaces';

export interface PublicAppCatalogState extends EntityState<IApp> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'public-app-catalog', idKey: 'appmarketAppId' })
export class PublicAppCatalogStore extends EntityStore<PublicAppCatalogState, IApp> {
  constructor() {
    super();
  }
}
