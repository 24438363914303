<table class="table is-hoverable is-focusable is-fullwidth">
  <thead *ngIf="tableConfig && tableConfig.columns">
    <tr class="has-background-white-ter">
      <th *ngIf="tableConfig.selectionConfig" class="column-checkbox">
        <ng-container *ngIf="tableConfig.selectionConfig.selectionType == 'Multi'">
          <label class="checkbox">
            <input
              type="checkbox"
              class="selectAll"
              (click)="selectAll($event)"
              [indeterminate]="
                activeSelection?.length > 0 && activeSelection?.length !== items?.length
              "
              [checked]="activeSelection?.length > 0 && selection?.length === items?.length"
            />
            <span></span>
          </label>
        </ng-container>
      </th>
      <th class="column-dropdown"></th>
      <th *ngFor="let column of tableConfig.columns">
        {{ column.label }}
        <portal-sort-button
          [order]="filter.order"
          (sortChanged)="onSortChanged(column.key, $event)"
          *ngIf="filter && column.isSortable"
        >
        </portal-sort-button>
      </th>
      <th *ngIf="hasRowLink">
        <span class="is-sr-only">{{ rowLinkHeading }}</span>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="tableConfig">
      <ng-container *ngFor="let item of items">
        <tr
          (click)="onRowClicked($event)"
          class="element-row"
          data-e2e="expand-table-row"
          [class.expanded-row]="expandedElement === element"
        >
          <ng-container *ngIf="tableConfig?.selectionConfig?.selectionType === 'Multi'">
            <td>
              <label class="checkbox">
                <input
                  type="checkbox"
                  [checked]="isSelected(item[tableConfig.rowIdKey])"
                  [disabled]="isDisabled(item[tableConfig.rowIdKey])"
                  (change)="onRowSelected(item, $event)"
                  value="item[tableConfig.rowIdKey]"
                />
                <span></span>
              </label>
            </td>
          </ng-container>
          <ng-container *ngIf="tableConfig?.selectionConfig?.selectionType === 'Single'">
            <td>
              <label class="radio">
                <input
                  type="radio"
                  [disabled]="isDisabled(item[tableConfig.rowIdKey])"
                  (change)="onRowSelected(item, $event)"
                  name="radio"
                  value="item[tableConfig.rowIdKey]"
                />
                <span></span>
              </label>
            </td>
          </ng-container>
          <td>
            <span
              (click)="expandedElement = expandedElement === item ? null : item"
              style="padding: 15px"
              class="expand-row"
              [ngClass]="{ expanded: item === expandedElement }"
              ><i class="fa fa-angle-down" aria-hidden="true"></i
            ></span>
          </td>
          <td *ngFor="let column of tableConfig.columns" [ngClass]="column.cssClass">
            <div
              *ngIf="!column.isTemplateRef && !column.isRenderFunction"
              [ngClass]="column.innerCssClass"
              [attr.data-e2e]="column.key"
            >
              {{ item | getItem : column.key }}
            </div>
            <div
              *ngIf="column.isTemplateRef"
              [innerHTML]="column.templateRef"
              [attr.data-e2e]="column.key"
            ></div>
            <div *ngIf="column.isRenderFunction" [innerHTML]="column.renderFunction(item)"></div>
          </td>
          <td *ngIf="hasRowLink">
            <a
              class="table-row-link is-pulled-right"
              [routerLink]="[rowLinkHref, item | getItem : tableConfig.rowIdKey]"
              data-e2e="link-detail"
            >
              <span class="icon">
                <span class="is-sr-only">{{ item | getItem : tableConfig.rowLabelKey }}</span>
                <i class="fas fa-chevron-right" aria-hidden="true"></i>
              </span>
            </a>
          </td>
        </tr>
        <tr class="detail-row">
          <td [attr.colspan]="colspan" class="is-paddingless">
            <div
              class="element-detail"
              [@detailExpand]="item == expandedElement ? 'expanded' : 'collapsed'"
            >
              <ng-container
                *ngTemplateOutlet="lineHeaderTmpl; context: { $implicit: item }"
              ></ng-container>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-container>
    <tr *ngIf="!tableConfig">
      <td [attr.colspan]="colspan">{{ errorMessage }}</td>
    </tr>
    <tr *ngIf="loading$ | async">
      <td [attr.colspan]="colspan">
        <span class="icon loader"> <i class="fas fa-spinner"></i> </span>
        {{ loadingMessage }}
      </td>
    </tr>
    <tr *ngIf="!(loading$ | async) && !items?.length">
      <td [attr.colspan]="colspan">{{ notFoundMessage }}</td>
    </tr>
  </tbody>
</table>
