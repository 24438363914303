import { vfappcatalog } from '@portal/marketplace/api-interfaces/src/lib/api-url/services.url';
import { FileType } from '../../apps/enums/file-type.enum';

export const DEV_APPS_API = {
  GET_APPS: function (): string {
    return `${vfappcatalog}/v1/apps`;
  },
  GET_ALL_APPS_VERSIONS: function (): string {
    return `${vfappcatalog}/v1/apps-versions`;
  },
  GET_APP_VERSION: function (versionId: string): string {
    return `${vfappcatalog}/v1/apps-versions/${versionId}`;
  },
  GET_APP_VERSIONS: function (appId: string): string {
    return `${vfappcatalog}/v1/apps?id=${appId}`;
  },
  ADD_NEW_VERSION: function (appId: string): string {
    return `${vfappcatalog}/v1/apps/${appId}/apps-versions`;
  },
  REGISTER_APP: function (): string {
    return `${vfappcatalog}/v1/apps`;
  },
  APP_PACKAGE: function (appVersionId: string, type: FileType): string {
    return `${vfappcatalog}/v1/apps-versions/${appVersionId}/package/${type}`;
  },
  DELETE_FILE: function (appVersionId: string, type: string, fileNumber: number): string {
    return `${vfappcatalog}/v1/apps-versions/${appVersionId}/package/${type}/fileNumber/${fileNumber}`;
  },
  CHANGE_STATUS(appVersionId: string, status: string): string {
    return `${vfappcatalog}/v1/apps-versions/${appVersionId}/status?status=${status}`;
  },
  GET_APP_VERSIONS_BY_APP_ID: function (appId: string): string {
    return `${vfappcatalog}/v1/apps/${appId}/versions`;
  },
};

export const DICTIONARIES_API = {
  GET_DICT: function (dictionaryType: string): string {
    return `${vfappcatalog}/v1/dictionary/type/${dictionaryType}`;
  },
};
