import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IAppVersion } from '@portal/marketplace/api-interfaces/src';

export interface AppCatalogVersionsState extends EntityState<IAppVersion>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app-catalog-versions', idKey: 'id' })
export class AppCatalogVersionsStore extends EntityStore<AppCatalogVersionsState, IAppVersion> {
  constructor() {
    super();
  }
}
