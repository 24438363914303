import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppPageTemplateModule } from '@portal/marketplace/templates';
import { PublicAppPageComponent } from './public-app-page.component';

@NgModule({
  declarations: [PublicAppPageComponent],
  imports: [CommonModule, AppPageTemplateModule],
  exports: [PublicAppPageComponent],
})
export class PublicAppPageModule {}
