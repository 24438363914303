export enum ListKey {
  Status = 'status',
  CreatedOn = 'createdOn',
  ReportType = 'reportType',
  ClearingEntityUid = 'reportParameter.clearingEntityUid',
  StartDate = 'startDate',
  EndDate = 'endDate',
  CutOffTime = 'cutoff_time',
  CutOffTimeOld = 'cutOff_Time',
  CutOffTimeOperator = 'ct',
  SettledTime = 'settled_time',
  SettledTimeOperator = 'st',
  PostMethod = 'post',
}
