import { Component } from '@angular/core';
import { MenuRegion } from '../top-navbar/enum/menu-region.enum';
import { HeroService } from './services/hero.service';
import { debounceTime, map, pairwise, startWith } from 'rxjs/operators';
import { asapScheduler } from 'rxjs';

interface HeroStyle {
  background: string;
  icon: string;
}

const heroStyles: Record<MenuRegion, HeroStyle> = {
  [MenuRegion.Administration]: {
    background: 'has-administration-background-image',
    icon: 'has-administration-hero-icon has-text-dark',
  },
  [MenuRegion.Boarding]: {
    background: 'has-boarding-background-image',
    icon: 'has-boarding-hero-icon has-text-dark',
  },
  [MenuRegion.Commerce]: {
    background: 'has-commerce-background-image',
    icon: 'has-commerce-hero-icon has-text-dark',
  },
  [MenuRegion.VirtualTerminal]: {
    background: 'has-virtualterminal-background-image',
    icon: 'has-commerce-hero-icon',
  },
  [MenuRegion.PayByLink]: {
    background: 'has-paybylink-background-image',
    icon: 'has-commerce-hero-icon',
  },
  [MenuRegion.Devices]: {
    background: 'has-devices-background-image',
    icon: 'has-devices-hero-icon has-text-dark',
  },
  [MenuRegion.Home]: {
    background: 'has-home-background-image',
    icon: 'has-home-hero-icon has-text-black',
  },
  [MenuRegion.Marketplace]: {
    background: 'has-marketplace-background-image',
    icon: 'has-marketplace-hero-icon',
  },
  [MenuRegion.PetroConfiguration]: {
    background: 'has-petro-background-image',
    icon: 'has-petro-configuration-icon has-text-dark',
  },
};
const onlyBackground = (style: HeroStyle): HeroStyle => ({ ...style, icon: '' });
const defaultHeroStyle: HeroStyle = onlyBackground(heroStyles[MenuRegion.Home]);

@Component({
  selector: 'vui-hero',
  templateUrl: './hero.component.html',
})
export class HeroComponent {
  readonly style$ = this.heroService.menuRegion$.pipe(
    map((l1NavigationItem) => heroStyles[l1NavigationItem]),
    startWith(<HeroStyle>null),
    pairwise(),
    map(
      ([prevStyle, currentStyle]) =>
        currentStyle || (prevStyle ? onlyBackground(prevStyle) : defaultHeroStyle),
    ),
    debounceTime(0, asapScheduler),
  );
  readonly title$ = this.heroService.title$.pipe(debounceTime(0, asapScheduler));

  constructor(private heroService: HeroService) {}
}
