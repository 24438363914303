import { entitiyservices, vfappmarket } from '../services.url';

export const DEVICES_API = {
  GET_DEVICES: function (entityUid: string): string {
    // return `${entitiyservices}/ds-entity-service/pois/${entityUid}`;
    // return `${entitiyservices}/entities/${entityUid}/pois/?populateDevice=true`; // /entities/{entityUid}/pois
    return `${entitiyservices}/pois?ancestorId=${entityUid}&populateDevice=true&populateEntity=true`; // /entities/{entityUid}/pois
    //
    // return `/ds-entity-service/pois/${entityUid}`;
  },
  GET_DEVICES_BY_MULTI_ENTITIES_IDS: function (entityUids: string[]) {
    return `${entitiyservices}/pois?parentIds=${entityUids}&populateDevice=true&populateEntity=true`;
  },
  GET_DEVICES_COUNT: function (entityUid): string {
    return `${entitiyservices}/pois/count?ancestorId=${entityUid}`;
  },
  GET_DEVICE_APPS: function (poiUid: string): string {
    return `${vfappmarket}/v1/apps/pois/${poiUid}?status=DEPLOYED`;
  },
  GET_DEVICE_BUNDLES: function (poiUid: string): string {
    return `${vfappmarket}/v1/bundles/pois/${poiUid}?status=DEPLOYED`;
  },
  GET_UNAVAILABLE_DEVICES_FOR_APP_INSTALL: function (
    merchantEntityUid: string,
    appMarketAppId: string,
  ): string {
    return `${vfappmarket}/v1/devices/merchants/${merchantEntityUid}/app/${appMarketAppId}/unavailable`;
  },
  GET_UNAVAILABLE_DEVICES_FOR_BUNDLE_INSTALL: function (
    merchantEntityUid: string,
    appmarketBundleId: string,
  ): string {
    return `${vfappmarket}/v1/devices/merchants/${merchantEntityUid}/bundle/${appmarketBundleId}/unavailable`;
  },
  GET_POIS_BY_ASSIGNED_BUNDLES_OF_ACQUIRER: function (
    acquirerEntityUid: string,
    bundleId: string,
  ): string {
    return `${vfappmarket}/v1/devices/acquirers/${acquirerEntityUid}/bundle/${bundleId}/available`;
  },
  GET_POIS_BY_ASSIGNED_APPS_OF_ACQUIRER: function (
    acquirerEntityUid: string,
    appId: string,
  ): string {
    return `${vfappmarket}/v1/devices/acquirers/${acquirerEntityUid}/app/${appId}/available`;
  },
  GET_POIS_COUNT_BY_ASSIGNED_BUNDLES_OF_ACQUIRER: function (
    acquirerEntityUid: string,
    bundleId: string,
  ): string {
    return `${vfappmarket}/v1/devices/acquirers/${acquirerEntityUid}/bundle/${bundleId}/available/count`;
  },
  GET_POIS_COUNT_BY_ASSIGNED_APPS_OF_ACQUIRER: function (
    acquirerEntityUid: string,
    appId: string,
  ): string {
    return `${vfappmarket}/v1/devices/acquirers/${acquirerEntityUid}/app/${appId}/available/count`;
  },
  GET_DEVICE_TAGS: function (): string {
    return `${vfappmarket}/v1/entityTags/pois`;
  },
  GET_POIS_BY_TAGS: function (merchantEntityUid: string, entiTagsIds: string[]): string {
    const params = `?entitiesTagIds=${entiTagsIds.join('&entitiesTagIds=')}`;
    return `${vfappmarket}/v1/entityTags/entity/${merchantEntityUid}/entityTag/pois${params}`;
  },

  ASSIGN_TAGS_TO_DEVICES: function (merchantEntityUid: string): string {
    return `${vfappmarket}/v1/entityTags/merchants/${merchantEntityUid}/pois/assign`;
  },

  DELETE_DEVICE_TAG: function (merchantEntityUid: string, tagId: string): string {
    return `${vfappmarket}/v1/entityTags/entity/${merchantEntityUid}/entityTag/${tagId}`;
  },

  UPDATE_DEVICE_TAG: function (merchantEntityUid: string, tagId: string): string {
    return `${vfappmarket}/v1/entityTags/entity/${merchantEntityUid}/entityTag/${tagId}`;
  },

  ASSIGN_TAG_TO_DEVICE: function (merchantEntityUid: string, tagId: string): string {
    return `${vfappmarket}/v1/entityTags/merchants/${merchantEntityUid}/entityTag/${tagId}/pois/assign`;
  },

  UNASSIGN_TAG_FROM_DEVICE: function (merchantEntityUid: string, tagId: string): string {
    return `${vfappmarket}/v1/entityTags/merchants/${merchantEntityUid}/entityTag/${tagId}/pois/unassign`;
  },
};
