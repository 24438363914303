<div class="container is-narrow">
  <a
    restrict-permission
    [routerLink]="['/administration', 'users']"
    data-e2e="link-back"
    title="Back to users"
    i18n-title
  >
    <span class="icon is-small">
      <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
    </span>
    <span i18n>Users</span>
  </a>

  <h1 class="title is-2" i18n>Add new organization group</h1>

  <portal-form-organization-group
    (formValidated)="onFormValidated($event)"
    (selectedOrganizationNumber)="onSelectedOrganizationNumber($event)"
  ></portal-form-organization-group>
</div>
