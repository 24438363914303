<div
  id="wrapper-component"
  class="field is-grouped is-grouped-multiline filter-container is-relative"
  [ngClass]="class"
  (portalClickOutside)="closeAllFilter()"
  (portalOnKeyPress)="closeAllFilter()"
  [isActive]="activeItem !== null"
  [keyCode]="Key.Escape"
>
  <ng-content></ng-content>
</div>
