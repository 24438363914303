import {
  IBankAccount,
  IMerchantChoiceRouting,
  IMerchantProcessingSettings,
  IReceiptTemplate,
  IUser,
  TemplateParameters,
} from '@apps/portal/src/app/core/core.module';
import { IPaymentContract } from '../../payment-contracts/interfaces/payment-contract.interface';
import { IAddress } from './address.interface';
import { IBusinessInformation } from '../../../../../forms/src/lib/interfaces/business-information.interface';
import { IContact } from './contact.interface';
import { IEntity } from './entity.interface';
import { LegalEntityType } from '../../entities/enums/legal-entity-type.enum';
import { IAppPermissionAssignment } from '@portal/shared/auth/authorization/src';
import { IReceiptContract } from './receipt.interface';
import { IEntityAncestors } from './ancestor.interface';
import { FederationType } from './../enums/federation-type.enum';
import { ReceiptAttachmentType } from '../enums/receipt-attachment-type.enum';
import { IReceiptContractUidList } from './receipt-Contract-uid-list.interface';
import { ReceiptProvider } from '@portal/entity-services/organisations';

export interface IOrganisation extends IEntity, IEntityAncestors {
  country?: string;
  parentEntity?: IOrganisation;
  formattedTimezone?: string;
  paymentContracts?: IPaymentContract[];
  businessInformation?: IBusinessInformation;
  addresses?: IAddress[];
  isBillingAddressDisplayed?: boolean;
  isShippingAddressDisplayed?: boolean;
  isTradingAddressDisplayed?: boolean;
  isShippingAddressSectionDisplayed?: boolean;
  isTradingAddressSectionDisplayed?: boolean;
  receiptProvider?: ReceiptProvider;
  isPaymentDocumentSectionDisplayed?: boolean;
  apiToken?: string;
  allowChildReuse?: boolean;
  contacts?: IContact[];
  bankAccounts?: IBankAccount[];
  legalEntity?: LegalEntityType;
  mcc?: string;
  count?: number;
  receiptContract?: IReceiptContract;
  externalEntityIdentifiers?: {
    siteReferenceId?: string;
    companyGroupReferenceId?: string;
    merchantFNSNumber?: string;
  };
  features?: IAppPermissionAssignment[];
  initialFeatures?: IAppPermissionAssignment[];
  templateParameters?: TemplateParameters;
  poiCrossEntityAccessAllowed?: boolean;
  domainName?: string;
  defaultPoiCutoverConfigurationId?: string;
  federationStatus?: boolean;
  federationType?: FederationType;
  sftpConnection?: string;
  receiptTemplate?: string;
  receiptMerchantLogo?: File[];
  receiptAttachmentType?: ReceiptAttachmentType;
  customTemplates?: IReceiptTemplate[];
  contractUids?: IReceiptContractUidList;
  users?: IUser[];
  creditorReferenceTypeId?: string;
  creditorReferenceCustomSpecifier?: string;
  batchThemeId?: string;
  merchantProcessingSettings?: IMerchantProcessingSettings;
  merchantChoiceRouting?: IMerchantChoiceRouting;
}
