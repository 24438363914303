import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageOption } from '@portal/shared/languages/src/lib/interfaces/language-option';

@Component({
  selector: 'portal-filter-language',
  templateUrl: './language.component.html',
  styleUrls: ['language.component.scss'],
})
export class FilterLanguageComponent implements OnInit {
  @Input() label: string;
  @Input() languages: LanguageOption[];
  @Input() selectedLanguage: LanguageOption;
  @Input() isNavbarItemMode = false;
  @Input() hideApplyButton: boolean;
  @Input() isLanguageButtonFocused: boolean;
  @Output() searched: EventEmitter<string> = new EventEmitter();
  @Output() filterApplied = new EventEmitter<LanguageOption>();
  @Output() languageSelected = new EventEmitter<LanguageOption>();

  filteredLanguages: LanguageOption[];
  isLanguageDropdownOpen = false;
  searchedText: string;

  constructor() {}

  ngOnInit(): void {
    this.filteredLanguages = this.languages;
  }

  onFilterApplied(): void {
    this.filterApplied.emit(this.selectedLanguage);
    this.isLanguageDropdownOpen = false;
  }

  onSearch(searchValue: string): void {
    if (searchValue) {
      this.filteredLanguages = this.languages.filter((language) =>
        language.text.toLowerCase().includes(searchValue.toLowerCase()),
      );
      this.searchedText = searchValue;
    } else {
      this.filteredLanguages = this.languages;
      this.searchedText = undefined;
    }
    this.searched.emit(this.searchedText);
  }

  toggleLanguageDropdown(): void {
    this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
  }

  openLanguageDropDown(): void {
    if (!this.isLanguageDropdownOpen) {
      this.toggleLanguageDropdown();
    }
  }
  closeLanguageDropDown(): void {
    if (this.isLanguageDropdownOpen) {
      this.toggleLanguageDropdown();
    }
  }

  onMouseDown($event): void {
    $event.preventDefault();
  }
}
