import { AfterContentInit, Directive, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[portalAutoFocus]',
})
export class AutofocusDirective implements AfterContentInit {
  focus$ = new Subject<void>();

  constructor(private el: ElementRef) {
    this.focus$.pipe(debounceTime(100)).subscribe(() => {
      this.el.nativeElement.focus();
    });
  }

  ngAfterContentInit(): void {
    this.focus$.next();
  }
}
