import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@portal/shared/pipes';
import { DragAndDropDirectiveModule } from './directives/drag-and-drop.module';
import { FileUploadComponent } from './file-upload.component';

@NgModule({
  imports: [CommonModule, PipesModule, DragAndDropDirectiveModule],
  declarations: [FileUploadComponent],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
