export enum TransactionState {
  Initiated = 'INITIATED',
  Authenticating = 'AUTHENTICATING',
  Approved = 'APPROVED',
  Received = 'RECEIVED',
  Declined = 'DECLINED',
  TimedOut = 'TIMED_OUT',
  Routing = 'ROUTING',
  Sent = 'SENT',
  Reversing = 'REVERSING',
  Reversed = 'REVERSED',
  ReverseError = 'REVERSE_ERROR',
  Authorised = 'AUTHORISED',
  Repeating = 'REPEATING',
  OnHold = 'ON_HOLD',
  Expired = 'EXPIRED',
  Cancelled = 'CANCELLED',
  Refunded = 'REFUNDED',
  Completed = 'COMPLETED',
  AwaitingInvoice = 'AWAITING_INVOICE',
  ReadyPP = 'READY_PP',
  Submitted = 'SUBMITTED',
  Success = 'SUCCESS',
  SettlementFailed = 'SETTLEMENT_FAILED',
  SettlementRequested = 'SETTLEMENT_REQUESTED',
  SettlementCompleted = 'SETTLEMENT_COMPLETED',
  Settled = 'SETTLED',
  Failed = 'FAILED',
  Disputed = 'DISPUTED',
  ReasonUpdated = 'REASON_UPDATED',
  Appealed = 'APPEALED',
  Unmatched = 'UNMATCHED',
  Exception = 'EXCEPTION',
  Voided = 'VOIDED',
  Reconciled = 'RECONCILED',
  ReconciliationFailed = 'RECONCILIATION_FAILED',
  Rejected = 'REJECTED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Deferred = 'DEFERRED',
  ClearingRequested = 'CLEARING_REQUESTED',
  ClearingCompleted = 'CLEARING_COMPLETED',
  ClearingFailed = 'CLEARING_FAILED',
}
