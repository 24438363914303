import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Locale } from './enums/locale.enum';
import { DatePositions } from './interfaces/date-positions.interface';

const browserLocale = window.navigator.language;

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor(@Inject(LOCALE_ID) private locale: Locale) {}

  toLocalisedShortString(date: Date, options = {}): string {
    if (!date) {
      return '';
    }

    const formattedTime = new Intl.DateTimeFormat(browserLocale, options).format(date);
    return this.replaceToDoubleZeroHour(formattedTime);
  }

  fromLocalisedShortString(dateString: string): Date {
    if (!dateString) {
      return null;
    }
    const newValueArray = this.splitDate(dateString);
    const localFormatElements = this.getLocalFormatElements();
    const newDateString =
      newValueArray[localFormatElements.yearPost] +
      '-' +
      newValueArray[localFormatElements.monthPost] +
      '-' +
      newValueArray[localFormatElements.dayPost];
    return new Date(newDateString);
  }

  toLocalisedLongString(date: Date, options = {}): string {
    if (!date) {
      return '';
    }
    //using the system format with the Portal language (this.locale)
    return this.replaceToDoubleZeroHour(date.toLocaleDateString(this.locale, options));
  }

  getLocalFormatElements(): DatePositions {
    const datePlaceholderArray = this.splitDate(this.getIntlDateFormatPattern(browserLocale));

    let yearPost = 0;
    let monthPost = 1;
    let dayPost = 2;

    datePlaceholderArray.forEach((value, index) => {
      if (value.indexOf('y') === 0) {
        yearPost = index;
      }
      if (value.indexOf('m') === 0) {
        monthPost = index;
      }
      if (value.indexOf('d') === 0) {
        dayPost = index;
      }
    });

    return { yearPost, monthPost, dayPost };
  }

  getPlaceholder(): string {
    return this.getIntlDateFormatPattern(browserLocale);
  }

  splitDate(date: string): string[] {
    return date.split(/[-\/.]/);
  }

  replaceToDoubleZeroHour(date: string): string {
    // some times Intl.DateTimeFormat returns the hour 24, it's necessary to replace it with 00
    return date.replace(' 24:', ' 00:');
  }

  timeZone(): string {
    return new Intl.DateTimeFormat('default').resolvedOptions().timeZone;
  }

  timezoneOffset(): string {
    const offset = new Date().getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offset) / 60);
    const offsetMinutes = Math.abs(offset) % 60;

    return `${offset <= 0 ? '+' : '-'}${
      this.padZero(offsetHours) + ':' + this.padZero(offsetMinutes)
    }`;
  }

  padZero(number: number): string {
    return number.toString().padStart(2, '0');
  }

  toRawDateString(date: Date): string {
    if (!date) {
      return '';
    }

    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();

    return `${day}/${month}/${year}`;
  }

  private getIntlDateFormatPattern(locale: string): string {
    const getPatternForPart = (
      part: Intl.DateTimeFormatPart,
      _,
      parts: Intl.DateTimeFormatPart[],
    ): string => {
      switch (part.type) {
        case 'day':
          return 'd'.repeat(part.value.length);
        case 'month':
          return 'm'.repeat(part.value.length);
        case 'year':
          return 'y'.repeat(part.value.length);
        case 'literal':
          return part.value;
        default:
          console.error(
            `Unexpected date part: `,
            part,
            `while extracting date format for locale: ${locale}. Date parts: `,
            parts,
          );
          return '';
      }
    };

    try {
      return new Intl.DateTimeFormat(locale, {
        calendar: 'gregory',
        numberingSystem: 'latn',
      } as Intl.DateTimeFormatOptions)
        .formatToParts(new Date('2021-12-21'))
        .map(getPatternForPart)
        .join('');
    } catch {
      return this.getIntlDateFormatPattern('en-gb');
    }
  }
}
