import { Component, OnInit } from '@angular/core';
import { ISelectInput } from '../../box/interfaces/select-input.interface';
import { FILTER_CONTENT_ITEM } from '../../content/token';
import { IFilterToApply } from '../../search/interfaces/filter-to-apply.interface';
import { BaseFilter } from '../base-filter';

@Component({
  selector: 'portal-toggle-filter',
  templateUrl: './toggle-filter.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: ToggleFilterComponent,
    },
  ],
})
export class ToggleFilterComponent extends BaseFilter implements OnInit {
  status;
  constructor() {
    super();
  }

  onChange(e): void {
    this.status = e.target.checked;
    this.onFilterApplied();
  }

  onFilterApplied(): void {
    this.updateEntities();
    if (this.status) {
      this.filterApplied.emit({
        operator: 'eq',
        argument: `${this.status}`,
        selector: this.selector,
        key: `${this.selector}-${this.status}`,
      });
    } else {
      this.filterApplied.emit({
        key: `${this.selector}-${this.status}`,
        selector: this.selector,
        toRemove: true,
      });
    }
    this.itemToggled.emit();
  }

  ngOnInit(): void {
    this.entityRemoved$.subscribe((entityToRemove: ISelectInput) => {
      this.status = false;
      this.filterUpdated.emit({
        key: entityToRemove.selector,
        selector: entityToRemove.selector,
        toRemove: true,
      });
    });
  }

  private constructSearchOutput(): IFilterToApply {
    return {
      key: this.selector,
      argument: this.status,
      selector: this.selector,
    };
  }
}
