import { IGuardPermissions } from '@portal/shared/auth/authorization/src';
import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';

import { RouteName } from './route-name.enum';

export const routePermissions: { [key in RouteName]?: IGuardPermissions } = {
  [RouteName.List]: {
    read: [UserRoles.VerifoneAdmin],
  },
};
