import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readableFileSize',
})
export class ReadableFileSizePipe implements PipeTransform {
  transform(bytes: number): string {
    if (bytes === 0 || bytes === null) return '';
    const i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    return bytes ? (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i] : '';
  }
}
