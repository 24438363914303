import { UserRoles } from '@portal/shared/auth/authorization';
import { FiltersList, FilterTypes } from '@portal/shared/ui/filter';
import { ITableColumn, ITableConfig } from '@portal/shared/ui/table';
export const MERCHANT_NAME: ITableColumn = {
  key: 'merchant_name',
  label: 'Merchant Name',
  isSortable: true,
};
export const ACQUIRER_NAME: ITableColumn = {
  key: 'eo_name',
  label: 'Acquirer Name',
  isSortable: true,
};
export const SUB_MERCHANT_NAME: ITableColumn = { 
  key: 'sub_merchant_name',
  label: 'Sub Merchant Name',
  isSortable: true,
};
export const MID: ITableColumn = { 
  key: 'mid',
  label: 'MID',
  isSortable: true, 
};
export const TAGS: ITableColumn = { 
  key: 'tags',
  label: 'Tag Values',
  isSortable: true
};
export const APP_PACKAGE_NAME: ITableColumn = { 
  key: 'app_package_name',
  label: 'App Package Name',
  isSortable: true,
};

export const MERCHANT_NAME_FILTER = {
  selector: 'merchant_entity_uid',
  title: 'Merchant Name ',
  type: FilterTypes.Entity,
  entityType: 'UNDEFINED,MERCHANT_COMPANY,MERCHANT_SITE',
};
export const DEVELOPER_NAME_FILTER = {
  selector: 'developer_org_entity_uid',
  title: 'Developer Name ',
  type: FilterTypes.Entity,
  entityType: 'DEVELOPER_COMPANY',
};

export const ACQUIRER_NAME_FILTER = {
  selector: 'eo_entity_uid',
  title: 'Acquirer Name ',
  type: FilterTypes.Entity,
  entityType: 'ESTATE_OWNER',
};

export const GET_TABLE_CONFIG = (role, TABLE_CONFIG): ITableConfig => {
  const columns = [...TABLE_CONFIG.columns];
  if (
    role.includes(UserRoles.EstateOwnerAdmin) ||
    role.includes(UserRoles.EstateOwnerDeviceAppManager)
  ) {
    columns.unshift(MERCHANT_NAME);
  }
  if (role.includes(UserRoles.VerifoneAdmin) || role.includes(UserRoles.VerifoneUser)) {
    columns.unshift(ACQUIRER_NAME, MERCHANT_NAME);
  }
  if (role.includes(UserRoles.DeveloperAdmin)) {
    columns.unshift(ACQUIRER_NAME);
  }
  return { ...TABLE_CONFIG, columns };
};

export const GET_TABLE_CONFIG_APP_USAGE = (role, TABLE_CONFIG): ITableConfig => {
  const columns = [...TABLE_CONFIG.columns];
  if (
    role.includes(UserRoles.EstateOwnerAdmin) ||
    role.includes(UserRoles.EstateOwnerDeviceAppManager)
  ) {
    columns.unshift(MERCHANT_NAME, APP_PACKAGE_NAME);
  }
  if (role.includes(UserRoles.VerifoneAdmin) || role.includes(UserRoles.VerifoneUser)) {
    columns.unshift(ACQUIRER_NAME, MERCHANT_NAME, APP_PACKAGE_NAME);
  }
  if (role.includes(UserRoles.MerchantAdmin || UserRoles.MerchantUser)) {
    columns.unshift(/*MERCHANT_NAME, */ SUB_MERCHANT_NAME);
    columns.push(TAGS);
  }
  return { ...TABLE_CONFIG, columns };
};

export const GET_TABLE_CONFIG_APP_SUMMARY = (role, TABLE_CONFIG): ITableConfig => {
  const columns = [...TABLE_CONFIG.columns];
  if (
    role.includes(UserRoles.EstateOwnerAdmin) ||
    role.includes(UserRoles.EstateOwnerDeviceAppManager)
  ) {
    columns.unshift(MERCHANT_NAME);
  }
  if (role.includes(UserRoles.VerifoneAdmin) || role.includes(UserRoles.VerifoneUser)) {
    columns.unshift(ACQUIRER_NAME, MERCHANT_NAME, MID, TAGS );
  }
  if(role.includes(UserRoles.MerchantAdmin || UserRoles.MerchantUser)){
    columns.unshift(MERCHANT_NAME, MID, TAGS );
  }
  return { ...TABLE_CONFIG, columns };
};

export const GET_FILTER_CONFIG = (role, FILTER_CONFIG): FiltersList[] => {
  let filterConfig: FiltersList[] = FILTER_CONFIG;
  if (
    role.includes(UserRoles.EstateOwnerAdmin) ||
    role.includes(UserRoles.EstateOwnerDeviceAppManager)
  ) {
    filterConfig = [MERCHANT_NAME_FILTER, ...filterConfig];
  }
  if (role.includes(UserRoles.VerifoneAdmin)) {
    filterConfig = [ACQUIRER_NAME_FILTER, MERCHANT_NAME_FILTER, ...filterConfig];
  }
  if (role.includes(UserRoles.DeveloperAdmin)) {
    filterConfig = [ACQUIRER_NAME_FILTER, ...filterConfig];
  }
  return filterConfig;
};

export const GET_FILTER_CONFIG_APP_USAGE = (role, FILTER_CONFIG): FiltersList[] => {
  let filterConfig: FiltersList[] = FILTER_CONFIG;
  if (
    role.includes(UserRoles.EstateOwnerAdmin) ||
    role.includes(UserRoles.EstateOwnerDeviceAppManager)
  ) {
    filterConfig = [MERCHANT_NAME_FILTER, ...filterConfig];
  }
  if (role.includes(UserRoles.VerifoneAdmin)) {
    filterConfig = [ACQUIRER_NAME_FILTER, MERCHANT_NAME_FILTER, ...filterConfig];
  }
  if (role.includes(UserRoles.MerchantAdmin || UserRoles.MerchantUser)) {
    filterConfig = [MERCHANT_NAME_FILTER, ...filterConfig];
  }
  return filterConfig;
};

export const GET_FILTER_CONFIG_APP_SUMMARY = (role, FILTER_CONFIG): FiltersList[] => {
  let filterConfig: FiltersList[] = FILTER_CONFIG;
  if (
    role.includes(UserRoles.EstateOwnerAdmin) ||
    role.includes(UserRoles.EstateOwnerDeviceAppManager)
  ) {
    filterConfig = [MERCHANT_NAME_FILTER, ...filterConfig];
  }
  if (role.includes(UserRoles.VerifoneAdmin)) {
    filterConfig = [ACQUIRER_NAME_FILTER, MERCHANT_NAME_FILTER, ...filterConfig];
  }
  if (role.includes(UserRoles.MerchantAdmin || UserRoles.MerchantUser)) {
    filterConfig = [MERCHANT_NAME_FILTER, ...filterConfig];
  }
  return filterConfig;
};
