<label class="select-control">
  <div class="label" *ngIf="name">
    {{ name }}
    <span *ngIf="optional">
      &nbsp;<span class="has-text-grey has-text-weight-normal" i18n>(Optional)</span>
    </span>
  </div>
  <div [ngClass]="{ 'is-danger': invalid }">
    <ng-select
      [formControl]="control"
      [trackByFn]="trackById"
      bindValue="id"
      bindLabel="text"
      [placeholder]="placeholder"
      [items]="options"
      [attr.data-e2e]="e2eName"
      (change)="selectElement(control)"
      notFoundText="No items found"
      i18n-notFoundText
      [dropdownPosition]="dropdownPosition"
      [clearable]="isClearable && !disabled"
      [disabled]="disabled"
      [searchable]="!disabled"
      [readonly]="disabled"
    >
      <ng-template ng-label-tmp let-item="item">
        <span class="ng-value-label pr-2" *ngIf="item.text">{{ item.text }}</span>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <span title="{{ item.text }}">{{ item.text }}</span>
      </ng-template>
    </ng-select>
  </div>
  <portal-form-error [formInput]="control" [errorId]="e2eErrorName"></portal-form-error>
  <p
    class="is-size-7 has-text-grey"
    (click)="onHelperText()"
    *ngIf="helperText && !helperTextAsLink"
  >
    {{ helperText }}
  </p>
  <p
    class="is-size-7 has-text-grey"
    (click)="onHelperText()"
    *ngIf="helperText && helperTextAsLink"
  >
    <a>{{ helperText }}</a>
  </p>
</label>
