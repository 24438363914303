<form class="box" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="field">
    <label class="label" i18n>Tag Name</label>
    <div class="control">
      <input
        class="input"
        type="text"
        placeholder="Tag Name"
        data-e2e="tag-name-field"
        [formControl]="name"
      />
      <portal-form-error
        errorId="name"
        data-e2e="tag-name-field-error"
        [formInput]="name"
      ></portal-form-error>
    </div>
  </div>

  <div class="field">
    <label class="label" i18n>Tag Description</label>
    <div class="control">
      <textarea
        class="textarea"
        data-e2e="tag-description-field"
        [formControl]="description"
      ></textarea>
      <portal-form-error
        errorId="description"
        data-e2e="tag-description-field-error"
        [formInput]="description"
      ></portal-form-error>
    </div>
  </div>

  <div class="field">
    <label class="label" i18n>Tag Color</label>
    <div class="control">
      <div class="new-tag-color-select">
        <label *ngFor="let value of colors">
          <input
            type="radio"
            name="tagcolor"
            [value]="value"
            data-e2e="tag-color-field"
            [formControl]="color"
            hidden
          /><span class="tag has-box-shadow-grey-small is-medium is-bold is-{{ value }} "><i class="fas fa-check"></i></span>
        </label>
      </div>
    </div>
  </div>

  <div class="field">&nbsp;</div>

  <div class="field is-grouped is-grouped-right">
    <div class="control">
      <button
        type="button"
        class="button"
        data-e2e="tag-cancel-button"
        (click)="onCancel()"
        i18n
      >
        Cancel
      </button>
    </div>
    <div class="control">
      <button
        *ngIf="showRemove()"
        type="button"
        class="button is-danger"
        data-e2e="tag-delete-button"
        (click)="onRemove()"
        i18n
      >
        Delete
      </button>
    </div>
    <div class="control">
      <button type="submit" class="button is-primary" data-e2e="tag-submit-button">
        {{ submitLabel }}
      </button>
    </div>
  </div>
</form>
