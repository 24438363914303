import { Directive } from '@angular/core';
import { BaseFilter } from '../base-filter';

@Directive({
  selector: '[portalButtonGroupBaseFilter]',
})
export class ButtonGroupBaseFilterDirective extends BaseFilter {
  constructor() {
    super();
  }
}
