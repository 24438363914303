import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseModalComponent } from './base-modal/base-modal.component';
import { BaseModalModule } from './base-modal/base-modal.module';
import { DeprecatedModalComponent } from './deprecated-modal/deprecated-modal.component';
import { MandatoryModalComponent } from './mandatory-modal/mandatory-modal.component';

@NgModule({
  imports: [CommonModule, A11yModule, BaseModalModule],
  declarations: [DeprecatedModalComponent, MandatoryModalComponent],
  exports: [DeprecatedModalComponent, BaseModalComponent, MandatoryModalComponent],
})
export class SharedUiModalModule {}
