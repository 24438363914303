import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IApp, VaultStatus } from '@portal/marketplace/api-interfaces/src';
import environment from '@environments';
import { AppLogoTemplateService } from './app-logo-template.service';
import { UserService } from '@portal/shared/user/src';

@Component({
  selector: 'marketplace-app-logo-template',
  templateUrl: './app-logo-template.component.html',
  styleUrls: ['./app-logo-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLogoTemplateComponent implements OnInit, OnChanges {
  @ViewChild('logo') logo: ElementRef<any>;

  @Input() app: IApp;

  @Input() showUpdatesIndicator = false;
  @Input() showInstallsIndicator = false;
  @Input() size = 128;

  isPublic = true;
  publicImageSrc = 'assets/images/img-placeholder.png';

  constructor(
    private appLogoTemplateService: AppLogoTemplateService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.app?.currentValue) {
      this.loadAppImage();
    }
  }

  handleMissingImage(): void {
    this.getImage();
  }

  private loadAppImage(): void {
    let isPublic: boolean;

    // Get isPublic property from app.
    if (this.app.acquirers && this.app.acquirers.length !== 0) {
      const acquirerIndex = this.app.acquirers.find(
        (acquirer) => acquirer.acquirerEntityUid === this.userService.getEntityUid(),
      );
      if (acquirerIndex) {
        isPublic = !!acquirerIndex.isPublic;
      }
    } else {
      isPublic = !!this.app.isPublic;
    }

    if (isPublic == null) {
      if (!this.hasPublicUrl()) {
        this.isPublic = true;
      }
    } else if (isPublic) {
      this.isPublic = true;
      if (this.hasPublicUrl()) {
        if (this.hasVaultStatus() && !this.hasVaultStatusAvailible()) {
          this.getImage();
        } else {
          this.publicImageSrc = `${environment.API_ENDPOINT.PUBLIC_APPMARKET.publicBucketUrl}/app_icons/${this.app.app.id}/${this.app.id}/${this.app.appIcons[0].fileName}`;
        }
      }
    } else if (isPublic === false) {
      // app has no icon
      if (!this.hasPublicUrl()) {
        this.isPublic = true;
      } else {
        this.getImage();
      }
    }
  }

  private getImage(): void {
    this.isPublic = false;
    this.appLogoTemplateService.take(this.app.appIcons[0].publicUrl).subscribe(
      (blob) => this.drawImage(blob),
      () => {
        this.isPublic = true;
        this.publicImageSrc = 'assets/images/img-placeholder.png';
      },
    );
  }

  private hasVaultStatus(): boolean {
    if (this.app.acquirers && this.app.acquirers.length !== 0) {
      const acquirerIndex = this.app.acquirers.find(
        (acquirer) => acquirer.acquirerEntityUid === this.userService.getEntityUid(),
      );
      if (acquirerIndex) {
        return acquirerIndex.vaultStatus != null;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private hasVaultStatusAvailible(): boolean {
    if (this.app.acquirers && this.app.acquirers.length !== 0) {
      const acquirerIndex = this.app.acquirers.find(
        (acquirer) => acquirer.acquirerEntityUid === this.userService.getEntityUid(),
      );
      if (acquirerIndex) {
        return acquirerIndex.vaultStatus === VaultStatus.AVAILABLE;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private hasPublicUrl(): boolean {
    return (
      this.app.appIcons &&
      this.app.appIcons.length !== 0 &&
      this.app.appIcons[0].publicUrl &&
      this.app.appIcons[0].publicUrl.length !== 0
    );
  }
  private drawImage(blob: Blob): void {
    if (blob == null) {
      return;
    }

    createImageBitmap(blob).then((image) => {
      this.draw(image);
    });
  }

  private draw(img: ImageBitmap): void {
    this.logo.nativeElement.width = this.size;
    this.logo.nativeElement.height = this.size;
    const ctx = this.logo.nativeElement.getContext('2d');

    ctx.drawImage(img, 0, 0, this.size, this.size);
  }
}
