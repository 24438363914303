import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MenuRegion } from '../../enum/menu-region.enum';
import { INavbarItem } from '../../interfaces/navbar-item.interface';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { merge } from 'rxjs';

export interface CommonNavbarState {
  region?: MenuRegion;
  title?: string;
  item: INavbarItem;
  parents: INavbarItem[];
}

interface PathInfo {
  path: string;
  href?: string;
  state: CommonNavbarState;
}

@UntilDestroy()
@Directive({
  selector: 'vui-top-common-navbar[vuiCommonNavbarStateChange]',
})
export class CommonNavbarStateDirective implements OnChanges, OnInit {
  @Output() vuiCommonNavbarStateChange = new EventEmitter<CommonNavbarState>();
  @Input() navbarItems: INavbarItem[];

  private index: PathInfo[][] = [[], []];
  private path: string;

  constructor(private readonly router: Router, private readonly location: Location) {}

  ngOnInit(): void {
    merge();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects),
        startWith(this.location.path()),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((path) => this.handlePath(path));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.navbarItems) {
      this.index = this.buildIndex();
      this.handlePath(this.path);
    }
  }

  private handlePath(path): void {
    this.path = path;
    const pathInfo = this.find(path ?? '');
    const state = pathInfo?.state;
    this.vuiCommonNavbarStateChange.emit(state);
  }

  private buildIndex(): PathInfo[][] {
    const getPathInfo = (item: INavbarItem, parent?: PathInfo): PathInfo => {
      const region = item.region ?? parent?.state.region;
      const title = parent?.state.title ?? item.title;

      return {
        path: item.path ?? parent?.path,
        href: item.href ?? parent?.href,
        state: {
          region,
          title,
          item,
          parents: [...(parent?.state.parents ?? []), item],
        },
      };
    };

    const getPathInfoWithChildren =
      (parent?: PathInfo) =>
      (item: INavbarItem): PathInfo[] => {
        const info = getPathInfo(item, parent);
        if (item.children?.length) {
          return item.children.flatMap(getPathInfoWithChildren(info));
        } else if (info.path) {
          return [info];
        } else {
          return [];
        }
      };

    const allItems = this.navbarItems?.flatMap(getPathInfoWithChildren()) ?? [];

    // Items with href are the "foreign" items, as opposed to the "native"
    // routes which are addressed via routerLink.
    // These "foreign" items are unlikely to belong to the current app.
    // They are separated to a second batch, which will be searched only
    // after a match can't be found in the first batch of "native" items.
    // This is intended to solve the problem of identical route paths defined in different applications.
    return [allItems.filter(({ href }) => !href), allItems.filter(({ href }) => href)];
  }

  private find(pathToFind: string): PathInfo {
    const transactionsModule = '/reports/transactions';
    const findInBatch = (pathInfos: PathInfo[]): PathInfo =>
      pathInfos.find(({ path }) => path === pathToFind) ||
      pathInfos.find(({ path }) => pathToFind.startsWith(path)) ||
      pathInfos.find(({ path }) => path.startsWith(pathToFind)) ||
      pathInfos.find(({ path }) => path.match(/(.*\/)/)[0] === pathToFind.match(/(.*\/)/)[0]) ||
      pathInfos.find(() => pathToFind.includes(transactionsModule));

    for (const batch of this.index) {
      const pathInfo = findInBatch(batch);
      if (pathInfo) return pathInfo;
    }
  }
}
