import { Injectable } from '@angular/core';
import { endOfDay, startOfDay, subDays, subYears } from 'date-fns';
import * as cloneDeep from 'lodash/cloneDeep';
import { IPagination, ISort } from '@portal/shared/ui/table';
import { IDateFilter, DateFilter } from '@portal/shared/helpers';
import { ISearchPayload } from '../interfaces/search-payload.interface';

@Injectable({
  providedIn: 'root',
})
export class SearchPayloadService {
  static changePage(payload: ISearchPayload, pagination: IPagination): ISearchPayload {
    return {
      ...payload,
      pageSize: pagination.size,
      pageNumber: pagination.page,
    };
  }

  static changeSort(payload: ISearchPayload, sort: ISort): ISearchPayload {
    return {
      ...payload,
      pageNumber: 1,
      orderCriteria: sort.key,
      direction: sort.order,
    };
  }

  static getDateFilter(input: DateFilter): IDateFilter {
    const currentDate: Date = new Date();
    const date: Date = cloneDeep(currentDate);

    const timeMap = {
      [DateFilter.Last24Hours]: subDays(startOfDay(date), 1),
      [DateFilter.Today]: startOfDay(date),
      [DateFilter.Last7Days]: subDays(startOfDay(date), 7),
      [DateFilter.Last30Days]: subDays(startOfDay(date), 30),
      [DateFilter.PastYear]: subYears(startOfDay(date), 1),
      [DateFilter.PastTwoYears]: subYears(startOfDay(date), 2),
    };

    return {
      start: timeMap[input]
        ? timeMap[input].toISOString()
        : subDays(startOfDay(date), 30).toISOString(),
      end: endOfDay(date).toISOString(),
    };
  }

  static getLast30DaysDateFilter(): IDateFilter {
    return SearchPayloadService.getDateFilter(DateFilter.Last30Days);
  }

  static getLast7DaysDateFilter(): IDateFilter {
    return SearchPayloadService.getDateFilter(DateFilter.Last7Days);
  }

  static getLast24HoursDateFilter(): IDateFilter {
    return SearchPayloadService.getDateFilter(DateFilter.Last24Hours);
  }

  static getPastYearDateFilter(): IDateFilter {
    return SearchPayloadService.getDateFilter(DateFilter.PastYear);
  }

  static getPastTwoYearsDateFilter(): IDateFilter {
    return SearchPayloadService.getDateFilter(DateFilter.PastTwoYears);
  }

  static getTodayDateFilter(): IDateFilter {
    return SearchPayloadService.getDateFilter(DateFilter.Today);
  }
}
