import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '@portal/entity-services/interfaces/src';
import { AuthenticationService } from '@portal/shared/auth/authentication/src';
import { Application, AuthorizationService } from '@portal/shared/auth/authorization/src';
import { LanguageHandle, Locale } from '@portal/shared/languages/src';
import { map, startWith } from 'rxjs/operators';
import { INavbarItem } from '../../interfaces/navbar-item.interface';
import { WhiteLabelingService } from '@portal/shared/white-labeling/src';
import { EnvironmentVariableService } from '@portal/shared/helpers/src/lib/environment-variables/environment-variables';
@Component({
  selector: 'vui-top-common-navbar',
  templateUrl: './top-common-navbar.component.html',
  styleUrls: ['./top-common-navbar.component.scss'],
})
export class TopCommonNavbarComponent implements OnChanges, AfterViewInit {
  @Input() navbarItems: INavbarItem[];
  @Input() root: Application;
  @Input() navbarUserItem: INavbarItem;
  @Input() set logoSrc(value: string) {
    if (value) {
      this.logoSource = value;
    }
  }
  get logoSrc(): string {
    return this.logoSource;
  }
  @Input() hasLogoClass = true;
  @ViewChild('whitelabelingLogo', { static: false }) logoRef: ElementRef;
  rootUrl: string;
  showMobileMenu = false;
  toggleNavDropdownsIndexes: boolean[];
  navDropdownClosed = false;
  logoClass = 'logo';
  brandText = 'Verifone Central';
  isMobile = false;
  isMpIonic = false;
  readonly user$ = this.authenticationService.authEvents.pipe(
    map(() => this.user),
    startWith(this.user),
  );

  readonly homeUrl$ = this.user$.pipe(
    map((user) => {
      if (!user?.language) return `${window.location.origin}`;
      let languageUrlSegment = this.languageService.getSupportedLanguagePath(
        user.language,
        this.root,
      );
      if (this.root === Application.MOP && languageUrlSegment) {
        languageUrlSegment = languageUrlSegment.split('-')[0];
        const enumValue: Locale = Locale[languageUrlSegment as keyof typeof Locale];
        this.languageService.setLanguage(enumValue);
      }
      return languageUrlSegment
        ? `${window.location.origin}/${languageUrlSegment}`
        : `${window.location.origin}`;
    }),
    map((url) => (url !== window.location.href.replace(/\/$/, '') ? url : null)),
  );
  private logoSource = '';

  constructor(
    private languageService: LanguageHandle,
    private authorizationService: AuthorizationService,
    private authenticationService: AuthenticationService,
    private elementRef: ElementRef,
    private router: Router,
    private whiteLabelingService: WhiteLabelingService,
  ) {
    this.isMobile = window.innerWidth < 1023;
    this.isMpIonic = Boolean(EnvironmentVariableService.getVar('IONIC.ON'));
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isMobile = window.innerWidth < 1023;
    if (!this.isMobile) {
      this.closeDropdown();
    }
  }

  ngOnChanges(): void {
    this.logoSrc = this.whiteLabelingService.getLogoUrl();
    this.brandText = this.whiteLabelingService.getBrandText() || this.brandText;
    this.toggleNavDropdownsIndexes = new Array(this.navbarItems?.length).fill(true);
    if (this.hasLogoClass || this.isMpIonic) {
      this.logoClass = 'logo';
    } else {
      this.logoClass = '';
    }
  }

  ngAfterViewInit(): void {
    if (this.whiteLabelingService.isWhiteLabelingEnabled()) {
      this.logoRef.nativeElement.style.setProperty(
        'background-image',
        `url('${this.logoSrc}')`,
        'important',
      );
      this.logoRef.nativeElement.style.setProperty('margin', 'inherit');
    }
  }
  toggleDropDownItems(navbarItem: INavbarItem): void {
    if (!this.showMobileMenu || !navbarItem?.children) {
      return;
    }
    const index = this.navbarItems.findIndex((item) => item === navbarItem);
    // Toggle the active state for the clicked navbar item
    this.toggleNavDropdownsIndexes[index] = !this.toggleNavDropdownsIndexes[index];
    // Close all other dropdowns
    for (let i = 0; i < this.toggleNavDropdownsIndexes.length; i++) {
      if (i !== index) {
        this.toggleNavDropdownsIndexes[i] = true;
      }
    }
  }
  isNavItemActive(navbarItem: INavbarItem): boolean {
    const index = this.navbarItems.findIndex((item) => item === navbarItem);
    return this.toggleNavDropdownsIndexes[index];
  }
  handleClick(e: MouseEvent, navItem: INavbarItem): void {
    if (navItem.onClick) {
      e.preventDefault();
      navItem.onClick(e, navItem);
    }
  }

  toggleDropdown(event: MouseEvent | KeyboardEvent): void {
    const target = event.target as HTMLElement;
    const dropdown = target.querySelector('.navbar-dropdown') as HTMLElement;
    const navbarLinkSpan = document.querySelector(
      '.navbar.is-rebrand .navbar-item.has-dropdown:focus .navbar-link span',
    ) as HTMLElement;
    if (dropdown) {
      dropdown.style.display = 'block';
      dropdown.focus();
      this.navDropdownClosed = false;
      // Close other dropdowns
      const allDropdowns = document.querySelectorAll('.navbar-dropdown');
      allDropdowns.forEach((dropdownElement) => {
        if (dropdownElement !== dropdown) {
          (dropdownElement as HTMLElement).style.display = 'none';
        }
      });
      if (navbarLinkSpan) {
        navbarLinkSpan.style.borderBottom = '1px solid transparent';
      }
    }
  }
  toggleAccount(): void {
    const dropdowns = document.querySelectorAll('.dropdown-hidden');
    dropdowns.forEach((dropdown) => {
      (dropdown as HTMLElement).style.display = 'none';
      this.navDropdownClosed = false;
    });
  }
  handleKeyDown(index: number): void {
    if (this.navbarUserItem.children.length - 1 === index) {
      this.navDropdownClosed = true;
    }
  }
  closeDropdown(): void {
    if (this.showMobileMenu) {
      this.toggleMobileMenu();
    } else {
      this.navDropdownClosed = true;
      if (!this.showMobileMenu && !this.isMobile) {
        const dropdowns = document.querySelectorAll('.navbar-dropdown');
        const navbarLinkSpan = document.querySelector(
          '.navbar.is-rebrand .navbar-item.has-dropdown:focus .navbar-link span',
        ) as HTMLElement;
        dropdowns.forEach((dropdown) => {
          (dropdown as HTMLElement).style.display = 'none';
        });
        if (navbarLinkSpan) {
          navbarLinkSpan.style.borderBottom = 'none';
        }
      }
    }
  }
  toggleMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
    this.displayModify();
    if (this.showMobileMenu) {
      for (let i = 0; i < this.toggleNavDropdownsIndexes?.length; i++) {
        this.toggleNavDropdownsIndexes[i] = true;
      }
    }
  }
  displayModify(): void {
    const allDropdowns = document.querySelectorAll('.navbar-dropdown');
    if (this.showMobileMenu) {
      this.navDropdownClosed = false;
      allDropdowns.forEach((dropdownElement) => {
        (dropdownElement as HTMLElement).style.display = 'block';
      });
    }
    if (!this.showMobileMenu) {
      this.navDropdownClosed = true;
      allDropdowns.forEach((dropdownElement) => {
        (dropdownElement as HTMLElement).style.display = 'none';
      });
    }
  }
  navigate(link: string): void {
    if (this.showMobileMenu) {
      this.toggleMobileMenu();
    } else {
      this.navDropdownClosed = true;
    }
    this.router.navigate([link]);
  }

  enterKeyup(event, link: string): void {
    event.target.blur();
    this.navigate(link);
  }

  private get user(): IUser {
    return this.authorizationService.currentUser;
  }
}
