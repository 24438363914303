import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbsModule } from '@portal/shared/ui/breadcrumbs';
import { DevicePageTemplateComponent } from './device-page-template.component';

@NgModule({
  imports: [CommonModule, BreadcrumbsModule, RouterModule],
  declarations: [DevicePageTemplateComponent],
  exports: [DevicePageTemplateComponent],
})
export class DevicePageTemplateModule {}
