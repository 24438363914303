<div class="app-icon">
  <div class="is-flex is-center">
    <figure *ngIf="image" class="is-relative logo-container image is-256x256">
      <img
        [src]="
          !isLoaded
            ? image
              ? (image | secureLoad | async)
              : 'assets/images/img-placeholder.png'
            : image
        "
        class=""
        alt="image"
      />
      <div
        *ngIf="showUpload"
        class="btn change-icon-btn  has-background-grey is-fullwidth has-text-white is-clickable"
        (click)="onChangeImage()"
        data-e2e="app-icon-upload-change-icon-btn"
      >
        <div>
          <img
            src="assets/images/components/exchange-icon.svg"
            alt="exchange"
            class="exchange-img"
          />
          <span class="is-size-7" i18n>Click to change app icon</span>
        </div>
      </div>
    </figure>
  </div>
  <div
    class="is-flex is-center"
    *ngIf="enableUpload"
    [ngClass]="{ 'is-hidden': image !== null && image !== undefined }"
  >
    <div class="is-256x256">
      <marketplace-file-upload
        #fileUpload
        [sizeMaxLimit]="16 * 1024"
        [supportedFileTypes]="supportedTypes"
        (filesChanged)="iconChanged($event)"
        [image]="!isLoaded ? (image ? (image | secureLoad | async) : '') : image"
      >
      </marketplace-file-upload>
    </div>
  </div>
  <div
    class="is-flex is-center"
    *ngIf="!enableUpload"
    [ngClass]="{ 'is-hidden': image !== null && image !== undefined }"
  >
    <div class="is-256x256">
      <img
        [src]="image ? image : 'assets/images/img-placeholder.png'"
        alt="exchange"
        class="exchange-img"
      />
    </div>
  </div>
  <p *ngIf="enableUpload" class="has-text-gray is-size-7 has-text-centered" i18n>
    Supported formats PNG or JPG.<br />
    Image should be at least 512x512 pixels
  </p>
</div>
