import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IOrganisation } from '@portal/entity-services/interfaces/src';
import { TemplateParameters } from '@portal/entity-services/interfaces/src/lib/organisations/interfaces/reseller.interface';
import { FormBase } from '@portal/shared/ui/form/src';
import { DetailResellerForm } from '../../../interfaces/detail-reseller-form.interface';
import { FormSharedService } from '../../form/form-shared.service';

@UntilDestroy()
@Component({
  selector: 'portal-detail-reseller-form',
  templateUrl: 'detail-reseller-form.component.html',
})
export class DetailResellerFormComponent
  extends FormBase<DetailResellerForm>
  implements OnInit, OnChanges
{
  @Input() entityUid: string;
  @Input() disabled: boolean;
  @Input() organisation: IOrganisation;
  @Input() templateParameters: TemplateParameters;

  constructor(
    protected elementRef: ElementRef,
    private fb: FormBuilder,
    private formSharedService: FormSharedService,
  ) {
    super(fb.group({}), elementRef);
    this.setFormGroup(this.createResellerForm());
  }

  ngOnInit(): void {
    this.templateParametersForm.patchValue({
      ...this.templateParametersForm.value,
      ...this.templateParameters,
    });

    /***
     * getting White Label editor form
     * which was opened with routing
     */
    this.formSharedService.whiteLabelPreviewFormValues$
      .pipe(untilDestroyed(this))
      .subscribe((templateParameters: TemplateParameters) => {
        if (templateParameters) {
          this.templateParametersForm.patchValue({
            ...this.templateParametersForm.value,
            ...templateParameters,
          });
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.organisation && this.organisation) {
      this.updateOrganisationFields(this.organisation);
    }
  }

  private createResellerForm(): FormGroup {
    return this.fb.group({
      organisation: this.createOrganisationForm(),
      templateParameters: this.createTemplateForm(),
    });
  }

  private createTemplateForm(): FormGroup {
    return this.fb.group({
      logo: [[]],
      favicon: [[]],
      pageStyle: this.fb.group({
        font: this.fb.array([this.fb.control('')]),
        navBar: this.fb.group({
          navbarBackgroundColor: [''],
          navbarItemColor: [''],
        }),
        brand: this.fb.group({
          brandBackground: [''],
          brandText: [''],
          brandOutlinedText: [''],
        }),
      }),
    });
  }

  private createOrganisationForm(): FormGroup {
    return this.fb.group({
      loginDomainName: [''],
      domainName: [''],
      emailDomainName: [''],
    });
  }

  private updateOrganisationFields(organisation: IOrganisation): void {
    this.organisationForm?.patchValue(organisation);
  }

  get templateParametersForm(): FormGroup {
    return this.form.get('templateParameters') as FormGroup;
  }

  get organisationForm(): FormGroup {
    return this.form.get('organisation') as FormGroup;
  }

  get domainNameControl(): FormControl {
    return this.organisationForm?.get('domainName') as FormControl;
  }

  get emailDomainNameControl(): FormControl {
    return this.organisationForm?.get('emailDomainName') as FormControl;
  }
}
