import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CountryService } from '@portal/shared/helpers/src/lib/country/country.service';
import { ICountry } from '@portal/shared/helpers/src/lib/country/interfaces/country.interface';
import { cloneDeep, filter, forEach } from 'lodash';
import { forkJoin, of, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FilterAutoCompleteInputComponent } from '../../lib/autocomplete/search-autocomplete-input.component';
import { ISelectInput } from '../box/interfaces/select-input.interface';
import { FilterContentItem } from '../content/item';
import { FILTER_CONTENT_ITEM } from '../content/token';
import { SearchOperator } from '../search/enums/search-operator.enum';
import { SearchSelector } from '../search/enums/search-selector.enum';
import { IFilterToApply } from '../search/interfaces/filter-to-apply.interface';
import { ISearchCriteria } from '../search/interfaces/search-criteria.interface';

declare const $localize;

@Component({
  selector: 'portal-filter-country',
  templateUrl: './country.component.html',
  providers: [
    {
      provide: FILTER_CONTENT_ITEM,
      useExisting: FilterCountryComponent,
    },
  ],
})
export class FilterCountryComponent extends FilterContentItem implements OnInit, OnDestroy {
  @Output()
  filterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();

  @Output()
  staticFilterUpdated: EventEmitter<IFilterToApply> = new EventEmitter();

  @Output()
  filterApplied: EventEmitter<IFilterToApply> = new EventEmitter();

  @Input()
  selectedEntities: Map<string, ISearchCriteria>;

  @Input()
  disabled = false;

  @Input()
  entityRemoved$ = new Subject<ISelectInput>();

  @Input()
  closeSubmenus$ = new Subject<boolean>();

  @ViewChild('countrySearchInput', { static: true })
  countrySearchInput: FilterAutoCompleteInputComponent;

  searchSelector: string;

  itemToggled: EventEmitter<number> = new EventEmitter();
  isOpen = false;

  localPreSelectedEntities: Map<string, ISelectInput> = new Map();

  showSelectedEntities: Map<string, ISelectInput> = new Map();

  entities: ICountry[];

  searchedText: string;

  countryText = $localize`Country`;

  staticListUpdateSubject$ = new Subject<IFilterToApply>();
  staticListUpdateSub: Subscription;

  backToMenuSubject$ = new Subject<void>();
  backToMenuSub: Subscription;

  private allEntities: ICountry[];

  constructor(public countryService: CountryService) {
    super();
    this.searchSelector = SearchSelector.CountryCodeAlpha3;
    this.entities = countryService.countries;
    this.allEntities = cloneDeep(this.entities);
  }

  ngOnInit(): void {
    if (!this.selectedEntities) {
      this.selectedEntities = new Map();
    }

    const entities: ISearchCriteria = cloneDeep(this.selectedEntities.get(this.searchSelector));

    this.staticListUpdateSub = this.staticListUpdateSubject$
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.staticFilterUpdated.emit({
          key: this.searchSelector,
          value: Array.from(this.localPreSelectedEntities.values()),
        });
      });

    this.backToMenuSub = this.backToMenuSubject$.pipe(debounceTime(100)).subscribe(() => {
      const element = document.getElementById('add-filter');
      if (element) {
        element.click();
      }
    });

    if (entities) {
      this.setSelectedEntities(entities);
      this.updateText();
    }

    this.entityRemoved$.subscribe((entityToRemove: ISelectInput) => {
      const selectedEntity = this.localPreSelectedEntities.get(entityToRemove.id);
      if (selectedEntity) {
        this.onEntityRemoved(entityToRemove);
        this.onFilterApplied();
        this.itemToggled.emit();
      }
    });

    this.closeSubmenus$.subscribe((close: boolean) => {
      if (close && this.isOpen) {
        this.itemToggled.emit();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.staticListUpdateSub) {
      this.staticListUpdateSub.unsubscribe();
    }

    if (this.backToMenuSub) {
      this.backToMenuSub.unsubscribe();
    }
  }

  setSelectedEntities(value: ISearchCriteria): void {
    // Set selectedFilters
    const values = new Set<string>(value.argument.replace('(', '').replace(')', '').split(','));
    forEach(Array.from(values), (argument: string) => {
      const textInput = this.entities.find((entity: ICountry) => {
        return entity.alpha3 === argument;
      });
      this.localPreSelectedEntities.set(argument, {
        id: argument,
        text: textInput ? textInput.name : '',
      });
    });

    this.staticListUpdateSubject$.next();

    this.updateEntities();
  }

  onSearch(searchValue: string): void {
    if (!searchValue) {
      this.entities = this.allEntities;
      this.searchedText = null;
    } else {
      this.entities = this.allEntities;
      this.searchedText = searchValue;
      this.entities = filter(this.entities, (entity: ICountry) => {
        return entity.name.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    this.updateEntities();
  }

  updateEntities(): void {
    this.showSelectedEntities = cloneDeep(this.localPreSelectedEntities);
    const entitiesToExclude = Array.from(this.localPreSelectedEntities.keys());
    this.entities = filter(this.entities, (entity: ICountry) => {
      return !entitiesToExclude.includes(entity.alpha3);
    });
  }

  clear(): void {
    this.removeAllEntities();
    this.resetInputValue();
  }

  removeAllEntities(): void {
    this.localPreSelectedEntities.clear();
    this.updateSelectedEntities();
    this.updateText();
  }

  updateSelectedEntities(): void {
    this.showSelectedEntities = cloneDeep(this.localPreSelectedEntities);
    const entitiesToFetch: string[] = [];
    Array.from(this.showSelectedEntities.values()).forEach((val: ISelectInput) => {
      if (!val.text) {
        entitiesToFetch.push(val.id);
      }
    });

    if (entitiesToFetch.length) {
      forkJoin(
        entitiesToFetch.map((id: string) => {
          return this.countryService.countriesAlpha3.get(id);
        }),
      ).subscribe((countries: ICountry[]) => {
        forEach(countries, (country: ICountry) => {
          const input = this.showSelectedEntities.get(country.alpha3);
          input.text = country.name;
          this.localPreSelectedEntities.set(country.alpha3, {
            id: country.alpha3,
            text: country.name,
          });
        });
        this.updateText();
      });
    }
  }

  onEntityRemoved(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.delete(selectedEntity.id);
  }

  onEntityAdded(selectedEntity: ISelectInput): void {
    this.localPreSelectedEntities.set(selectedEntity.id, selectedEntity);
  }

  onFilterApplied(): void {
    this.updateEntities();

    const values: string[] = this.getValues();
    if (values.length) {
      this.filterApplied.emit({
        key: this.searchSelector,
        operator: SearchOperator.In,
        value: Array.from(this.localPreSelectedEntities.values()),
        argument: `(${Array.from(values).join(',')})`,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        value: Array.from(this.localPreSelectedEntities.values()),
      });
    } else {
      this.filterApplied.emit({
        key: this.searchSelector,
        toRemove: true,
      });
      this.staticFilterUpdated.emit({
        key: this.searchSelector,
        toRemove: true,
      });
    }

    this.resetInputValue();
    this.updateText();
    this.itemToggled.emit();
  }

  getList(): Observable<ICountry[]> {
    return of(this.entities);
  }

  getValues(): string[] {
    const values = new Set<string>();
    Array.from(this.localPreSelectedEntities.values()).forEach((val: ISelectInput) => {
      values.add(val.id);
    });

    return Array.from(values);
  }

  backToMenu(): void {
    this.itemToggled.emit();
    this.backToMenuSubject$.next();
  }

  openContent(): void {
    this.itemToggled.emit();
    this.onSearch('');
  }

  updateText(): void {
    const values: string[] = this.getValues();
    const text = $localize`Country`;
    if (values.length) {
      this.countryText = `${text} (+${values.length})`;
    } else {
      this.countryText = text;
    }
  }

  resetInputValue(): void {
    this.countrySearchInput.reset();
    this.onSearch('');
  }
}
