<div class="container is-narrow">
  <a
    restrict-permission
    [routerLink]="['/administration', 'users']"
    data-e2e="link-back"
    title="Back to users"
    i18n-title
  >
    <span class="icon is-small">
      <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
    </span>
    <span i18n>Users</span>
  </a>

  <h1 class="title is-2" i18n>Group Details</h1>

  <div class="box" *ngIf="loading$ | async">
    <portal-spinner
      [isHorizontal]="true"
      [isSmall]="true"
      text="Loading Group Details"
      i18n-text
    ></portal-spinner>
  </div>

  <div *ngIf="!isEditForm && !(loading$ | async)">
    <div class="box form-organization-group">
      <h2 class="title is-3" i18n>General Details</h2>
      <div class="label" i18n>Group name</div>
      <div
        class="box selected-organizations has-background-white-bis has-not-shadow has-not-border"
      >
        <span class="has-text-grey" data-e2e="groupOrganization-name">{{
          groupViewDetailEdit?.groupOrganization?.name || '-'
        }}</span>
      </div>
      <div class="box__separator"></div>

      <h2 class="title is-3" i18n>Access Rights</h2>
      <label class="field">
        <div class="label" i18n>Parent Organization</div>
        <div
          class="box selected-organizations has-background-white-bis has-not-shadow has-not-border"
        >
          <span
            class="button is-static has-background-info-lighter has-text-grey has-not-border"
            data-e2e="parentOrganization-name"
            >{{ groupViewDetailEdit?.parentOrganization?.name || '-' }}</span
          >
        </div>
      </label>

      <label class="field">
        <div class="label" i18n>Organizations</div>
        <portal-spinner *ngIf="isOrganisationLoading"></portal-spinner>
        <div *ngIf="!isOrganisationLoading">
          <div
            class="box selected-organizations has-background-white-bis has-not-shadow has-not-border"
            *ngIf="groupViewDetailEdit?.subOrganizations.length"
          >
            <span
              class="button is-static has-background-info-lighter has-text-grey has-not-border my-1 mr-2"
              *ngFor="let selectedEntity of groupViewDetailEdit?.subOrganizations"
              >{{ selectedEntity.name }}
            </span>
          </div>
          <div
            class="box selected-organizations has-background-white-bis has-not-shadow has-not-border has-text-centered"
            *ngIf="!groupViewDetailEdit?.subOrganizations.length"
          >
            -
          </div>
        </div>
      </label>

      <label class="field">
        <div class="label" i18n>User(s)</div>
        <portal-spinner *ngIf="isUsersLoading"></portal-spinner>
        <div *ngIf="!isUsersLoading">
          <div
            class="box selected-organizations has-background-white-bis has-not-shadow has-not-border"
            *ngIf="groupViewDetailEdit?.users.length"
          >
            <span
              class="button is-static has-background-info-lighter has-text-grey has-not-border my-1 mr-2"
              *ngFor="let selectedUsers of groupViewDetailEdit?.users"
              >{{ selectedUsers.name }}
            </span>
          </div>
          <div
            class="box selected-organizations has-background-white-bis has-not-shadow has-not-border has-text-centered"
            *ngIf="!groupViewDetailEdit?.users.length"
          >
            -
          </div>
        </div>
      </label>
    </div>

    <div class="columns is-vcentered is-flex is-variable">
      <div class="column has-text-right">
        <button
          type="button"
          class="button is-primary is-outlined"
          data-e2e="button-edit-organization-group"
          *restrictPermission="['edit-details-organization-group']"
          (click)="onEditGroupForm()"
        >
          <span i18n> Edit</span>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="isEditForm">
    <portal-form-organization-group
      [groupModeEdit]="true"
      [groupViewDetailEdit]="groupViewDetailEdit"
      [selectedParentEntity]="groupViewDetailEdit.parentOrganization"
      (closeEditForm)="closeEditForm($event)"
      (formValidated)="onFormValidated($event)"
      (selectedOrganizationNumber)="onSelectedOrganizationNumber($event)"
    ></portal-form-organization-group>
  </div>
</div>
