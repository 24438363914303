import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FirstLettersPipe } from '@portal/shared/pipes/src/lib/first-letters/first-letters.pipe';
import { HighlightSearchPipe } from './highlight/highlight.pipe';
import { ReadableFileSizePipe } from './readable-file-size/readable-file-size.pipe';
import { RemoveUnderscorePipe } from './remove-underscore/remove-underscore.pipe';
import { SecureLoadPipe } from './secureLoad/secureLoad.pipe';
import { SortVersionsPipe } from './sort-versions/sort-versions.pipe';
import { SortVersionsUpdatesPipe } from './sort-versions/sort-versions.pipe';
import { SplitByCamelCasePipe } from './split-by-camel-case/split-by-camel-case.pipe';
import { TrimTextPipe } from './trim-text/trimText.pipe';
import { FormatDigitDecimals } from './format-digit-decimals/format-digit-decimals.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    HighlightSearchPipe,
    RemoveUnderscorePipe,
    SortVersionsPipe,
    SortVersionsUpdatesPipe,
    ReadableFileSizePipe,
    SecureLoadPipe,
    TrimTextPipe,
    FirstLettersPipe,
    SplitByCamelCasePipe,
    FormatDigitDecimals,
  ],
  exports: [
    HighlightSearchPipe,
    RemoveUnderscorePipe,
    SortVersionsPipe,
    SortVersionsUpdatesPipe,
    ReadableFileSizePipe,
    SecureLoadPipe,
    TrimTextPipe,
    FirstLettersPipe,
    SplitByCamelCasePipe,
    FormatDigitDecimals,
  ],
  providers: [SortVersionsPipe, SecureLoadPipe],
})
export class PipesModule {}
