<div class="box__separator"></div>
<h2 class="title is-3" data-e2e="associatedEntites-label">{{ associatedLabelName }}</h2>

<ng-container *ngIf="associatedEntityType === entityType.MERCHANT_SITE">
  <div class="table-container">
    <table
      class="table is-hoverable is-focusable is-fullwidth table-ppc"
      [class.is-loading]="poiloading$ | async"
    >
      <thead>
        <tr>
          <th i18n>Name</th>
          <th i18n>Terminal ID</th>
          <th i18n>Type</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let poi of associatedPois" data-e2e="points-of-interaction-table-row">
          <td class="has-text-truncate">
            <a
              [routerLink]="['/administration', 'points-of-interaction', poi.poiUid]"
              data-e2e="link-points-of-interaction"
              title="Go to POI details"
              i18n-title
              >{{ poi.name }}
            </a>
          </td>
          <td class="has-text-truncate">{{ poi?.altVfiPoiId || '-' }}</td>
          <td class="has-text-truncate">
            {{ interactionTypes.keyValue[poi?.type] || '-' }}
          </td>
        </tr>
        <ng-container *ngIf="!associatedPois?.length">
          <ng-container *ngIf="poiloading$ | async; else noPoiDataRow">
            <tr *ngFor="let row of loadingRows">
              <td *ngFor="let column of loadingColumns"></td>
            </tr>
          </ng-container>
          <ng-template #noPoiDataRow>
            <tr data-e2e="associated-points-of-interaction-table-row-message">
              <td colspan="3" class="row-message" i18n>No Point of Interaction found</td>
            </tr>
          </ng-template>
        </ng-container>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-container *ngIf="associatedEntityType === entityType.MERCHANT_COMPANY">
  <div class="table-container">
    <table
      class="table is-hoverable is-focusable is-fullwidth"
      [class.is-loading]="organisationloading$ | async"
    >
      <thead>
        <tr>
          <th i18n>Name</th>
          <th i18n>Type</th>
          <th i18n>Country</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let organisation of associatedOrganisations" data-e2e="organisations-table-row">
          <td>
            <a
              [routerLink]="['/administration', 'organisations', organisation.entityUid]"
              data-e2e="link-organisation"
              title="Go to organisation"
              i18n-title
              >{{ organisation.name }}
            </a>
          </td>
          <td>
            {{ entityTypes.keyValue[organisation?.entityType] || '-' }}
          </td>
          <td>
            {{ organisation?.country || '-' }}
          </td>
        </tr>
        <ng-container *ngIf="!associatedOrganisations?.length">
          <ng-container *ngIf="organisationloading$ | async; else noOrgDataRow">
            <tr *ngFor="let row of loadingRows">
              <td *ngFor="let column of loadingColumns"></td>
            </tr>
          </ng-container>
          <ng-template #noOrgDataRow>
            <tr data-e2e="associated-organisation-table-row-message">
              <td colspan="3" class="row-message" i18n>No associated organisation found</td>
            </tr>
          </ng-template>
        </ng-container>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-container *ngIf="associatedEntityType === 'paymentContracts'">
  <div class="table-container">
    <table
      class="table is-hoverable is-focusable is-fullwidth table-ppc"
      [class.is-loading]="ppcloading$ | async"
    >
      <thead>
        <tr>
          <th i18n>Name</th>
          <th i18n>Payment types</th>
          <th i18n>Currencies</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let paymentContract of paymentContracts" data-e2e="payment-contracts-table-row">
          <td class="has-text-truncate">
            <a
              [routerLink]="['/administration', 'payment-contracts', paymentContract.contractUid]"
              data-e2e="link-payment-contracts"
              title="Go to contract details"
              i18n-title
              >{{ paymentContract.name }}
            </a>
          </td>
          <td
            class="has-text-truncate"
            title="{{ paymentContract?.formattedPaymentType.join(', ') || '-' }}"
          >
            {{ paymentContract?.formattedPaymentType.join(', ') || '-' }}
          </td>
          <td class="has-text-truncate" title="{{ paymentContract.currency.join(', ') }}">
            {{ paymentContract.currency.join(', ') }}
          </td>
        </tr>
        <ng-container *ngIf="!paymentContracts?.length">
          <ng-container *ngIf="ppcloading$ | async; else noPpcDataRow">
            <tr *ngFor="let row of loadingRows">
              <td *ngFor="let column of loadingColumns"></td>
            </tr>
          </ng-container>
          <ng-template #noPpcDataRow>
            <tr data-e2e="associated-payment-contracts-table-row-message">
              <td colspan="3" class="row-message" i18n>No Payment Provider Contracts</td>
            </tr>
          </ng-template>
        </ng-container>
      </tbody>
    </table>
  </div>
</ng-container>

<portal-pagination
  [reset]="resetPagination"
  [itemsPerPage]="filter.limit"
  [totalItems]="totalCount"
  [pageSizeOptions]="pageSizeOptions"
  (paginationChanged)="onPagination($event)"
  [paginationId]="paginationId"
  [goto]="false"
></portal-pagination>
