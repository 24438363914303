import { Injectable } from '@angular/core';
import { SalesChannel } from '@portal/entity-services/interfaces';
import { SelectInputsList } from '@portal/shared/helpers/src';
import { ISelectInput } from '@portal/shared/ui/filter';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class SalesChannels extends SelectInputsList {
  keyValue = {
    [SalesChannel.Ecommerce]: $localize`E-commerce`,
    [SalesChannel.Mail]: $localize`Mail`,
    [SalesChannel.Phone]: $localize`Phone`,
    [SalesChannel.Pos]: $localize`Point of sale`,
  };

  list: ISelectInput[] = this.getSelectInputs();

  constructor() {
    super();
  }
}
