<div class="container is-narrow">
  <a
    restrict-permission
    [routerLink]="['/administration', 'organisations']"
    data-e2e="link-back"
    title="Back to organisations"
    i18n-title
  >
    <span class="icon is-small">
      <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
    </span>
    <span i18n>Organisations</span>
  </a>
  <h1 class="title is-2" data-e2e="title">{{ organisation?.name }}</h1>
  <div class="box" *ngIf="loading$ | async">
    <portal-spinner
      [isHorizontal]="true"
      [isSmall]="true"
      text="Loading organisation"
      i18n-text
    ></portal-spinner>
  </div>

  <div class="box has-text-centered" *ngIf="!(loading$ | async) && !organisation" i18n>
    No organisation found
  </div>

  <ng-container *ngIf="!(loading$ | async) && organisation">
    <div class="box">
      <h2 class="title is-3" i18n>General information</h2>
      <dl
        class="columns is-multiline is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
      >
        <ng-container *ngIf="organisation?.parentEntity?.name">
          <dt class="column is-one-third has-text-grey" data-e2e="parentOrganisation-label" i18n>
            Parent organisation
          </dt>
          <dd class="column is-two-thirds" data-e2e="parentOrganisation-value">
            <a
              [routerLink]="[
                '/administration',
                'organisations',
                organisation?.parentEntity?.entityUid
              ]"
              data-e2e="link-organisation"
              title="Go to parent organisation"
              i18n-title
            >
              {{ organisation.parentEntity.name }}
            </a>
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.url">
          <dt class="column is-one-third has-text-grey" data-e2e="url-label" i18n>Merchant Url</dt>
          <dd class="column is-two-thirds" data-e2e="url-value">
            <span [href]="organisation.url" title="Go to merchant URL">{{ organisation.url }}</span>
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.merchantLogoUrl">
          <dt class="column is-one-third has-text-grey" data-e2e="merchantLogoUrl-label" i18n>
            Merchant Logo Url
          </dt>
          <dd class="column is-two-thirds" data-e2e="merchantLogoUrl-value">
            <span [href]="organisation.merchantLogoUrl">{{ organisation.merchantLogoUrl }}</span>
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.entityUid">
          <dt class="column is-one-third has-text-grey" data-e2e="organisationId-label" i18n>
            Organisation ID
          </dt>
          <dd class="column is-two-thirds" data-e2e="organisationId-value">
            {{ organisation?.entityUid }}
            <portal-copy-to-clipboard
              [valueToCopy]="organisation?.entityUid"
            ></portal-copy-to-clipboard>
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.description">
          <dt class="column is-one-third has-text-grey" data-e2e="organisation-label" i18n>
            Description
          </dt>
          <dd class="column is-two-thirds wrap-content" data-e2e="organisation-value">
            {{ organisation.description }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.altVfiEntityId">
          <dt class="column is-one-third has-text-grey" data-e2e="verifoneId-label" i18n>
            Verifone ID
          </dt>
          <dd class="column is-two-thirds" data-e2e="verifoneId-value">
            {{ organisation.altVfiEntityId }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.tokenEntityId">
          <dt class="column is-one-third has-text-grey" data-e2e="tokenEntityId-label" i18n>
            Token entity ID
          </dt>
          <dd class="column is-two-thirds" data-e2e="tokenEntityId-value">
            {{ organisation.tokenEntityId }}
          </dd>
        </ng-container>
        <ng-container
          *ngIf="organisation?.legalEntity && getLegalEntityType(organisation?.legalEntity)"
        >
          <dt class="column is-one-third has-text-grey" data-e2e="legalEntityType-label" i18n>
            Legal entity type
          </dt>
          <dd class="column is-two-thirds" data-e2e="legalEntityType-value">
            {{ getLegalEntityType(organisation.legalEntity) }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.mcc">
          <dt class="column is-one-third has-text-grey" data-e2e="merchantCategoryCode-label" i18n>
            Merchant category code
          </dt>
          <dd class="column is-two-thirds" data-e2e="merchantCategoryCode-value">
            {{ organisation.mcc }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.taxRegistrationNumber">
          <dt class="column is-one-third has-text-grey" data-e2e="taxRegistrationNumber-label" i18n>
            Tax registration number
          </dt>
          <dd class="column is-two-thirds" data-e2e="taxRegistrationNumber-value">
            {{ organisation.taxRegistrationNumber }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.businessIdentifiers?.length">
          <dt class="column is-one-third has-text-grey" data-e2e="businessIdentifiers-label" i18n>
            Business Identifiers
          </dt>
          <dd class="column is-two-thirds" data-e2e="businessIdentifiers-value">
            {{ businessIdentifierTypes.join(', ') }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.externalEntityIdentifiers?.merchantFNSNumber">
          <dt class="column is-one-third has-text-grey" data-e2e="merchantFNSNumber-label" i18n>
            FNS number
          </dt>
          <dd class="column is-two-thirds" data-e2e="merchantFNSNumber-value">
            {{ organisation.externalEntityIdentifiers.merchantFNSNumber }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.defaultPoiCutoverConfigurationId">
          <dt
            class="column is-one-third has-text-grey"
            data-e2e="defaultPoiCutoverConfigurationId-label"
            i18n
          >
            POI Cutover Strategy ID
          </dt>
          <dd class="column is-two-thirds" data-e2e="industryType-value">
            {{ organisation?.defaultPoiCutoverConfigurationId }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.industry && industryTypes?.keyValue">
          <dt class="column is-one-third has-text-grey" data-e2e="industry-label" i18n>Industry</dt>
          <dd class="column is-two-thirds" data-e2e="industryType-value">
            {{ industryTypes.keyValue[organisation.industry] }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.creditorReferenceTypeId">
          <dt
            class="column is-one-third has-text-grey"
            data-e2e="creditorReferenceTypeId-label"
            i18n
          >
            Creditor Reference Type
          </dt>
          <dd class="column is-two-thirds" data-e2e="creditorReferenceTypeId-value">
            {{ organisation?.creditorReferenceTypeId }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.creditorReferenceCustomSpecifier">
          <dt
            class="column is-one-third has-text-grey"
            data-e2e="creditorReferenceCustomSpecifier-label"
            i18n
          >
            Creditor Reference Custom Specifier
          </dt>
          <dd class="column is-two-thirds" data-e2e="creditorReferenceCustomSpecifier-value">
            {{ organisation?.creditorReferenceCustomSpecifier }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.batchThemeId">
          <dt class="column is-one-third has-text-grey" data-e2e="batchThemeId-label" i18n>
            Batch Theme Id
          </dt>
          <dd class="column is-two-thirds" data-e2e="batchThemeId-value">
            {{ organisation?.batchThemeId }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.externalEntityIdentifiers?.siteReferenceId">
          <dt class="column is-one-third has-text-grey" data-e2e="siteReferenceId-label" i18n>
            Site Reference
          </dt>
          <dd class="column is-two-thirds has-text-word-break" data-e2e="siteReferenceId-value">
            {{ organisation.externalEntityIdentifiers.siteReferenceId }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.externalEntityIdentifiers?.companyGroupReferenceId">
          <dt
            class="column is-one-third has-text-grey"
            data-e2e="companyGroupReferenceId-label"
            i18n
          >
            Company Group Reference Identifier
          </dt>
          <dd
            class="column is-two-thirds has-text-word-break"
            data-e2e="companyGroupReferenceId-value"
          >
            {{ organisation.externalEntityIdentifiers.companyGroupReferenceId }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.entityType">
          <dt class="column is-one-third has-text-grey" data-e2e="entityType-label" i18n>
            Organisation type
          </dt>
          <dd class="column is-two-thirds" data-e2e="entityType-value">
            {{ getEntityType(organisation.entityType) }}
          </dd>
        </ng-container>

        <ng-container *ngIf="showPoiCrossEntityAccess()">
          <dt class="column is-one-third has-text-grey" data-e2e="entityType-label" i18n>
            Cross Entity Access
          </dt>
          <dd
            class="column is-two-thirds"
            data-e2e="cross-entity-access-enabled-value"
            *ngIf="organisation?.poiCrossEntityAccessAllowed"
            i18n
          >
            Enabled
          </dd>
          <dd
            class="column is-two-thirds"
            data-e2e="cross-entity-access-disabled-value"
            *ngIf="!organisation?.poiCrossEntityAccessAllowed"
            i18n
          >
            Disabled
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.status">
          <dt class="column is-one-third has-text-grey" data-e2e="status-label" i18n>Status</dt>
          <dd
            class="column is-two-thirds"
            data-e2e="status-enabled-value"
            *ngIf="organisation.status === statusActive"
            i18n
          >
            Enabled
          </dd>
          <dd
            class="column is-two-thirds"
            data-e2e="status-disabled-value"
            *ngIf="organisation.status === statusInactive"
            i18n
          >
            Disabled
          </dd>
          <dd
            class="column is-two-thirds"
            data-e2e="status-disabled-value"
            *ngIf="organisation.status === statusDeleted"
            i18n
          >
            Deleted
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.federationType">
          <dt class="column is-one-third has-text-grey" data-e2e="federation-label" i18n>
            Federation login
          </dt>
          <dd class="column is-two-thirds" data-e2e="federationType-value">
            {{ federationTypes.keyValue[organisation.federationType] }}
          </dd>
        </ng-container>
        <ng-container *ngIf="organisation?.sftpConnection">
          <dt class="column is-one-third has-text-grey" data-e2e="sftpConnection-label" i18n>
            SFTP connection
          </dt>
          <dd class="column is-two-thirds wrap-content" data-e2e="sftpConnection-value">
            {{ organisation.sftpConnection }}
          </dd>
        </ng-container>
      </dl>
      <ng-container *ngIf="canReadAddresses && organisationAddresses?.billing">
        <div class="box__separator"></div>
        <h2 class="title is-3" data-e2e="billingAddress-label" i18n>Billing address</h2>
        <dl
          class="columns is-multiline is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
        >
          <ng-container *ngIf="organisationAddresses.billing?.addressLine1">
            <dt class="column is-one-third has-text-grey" data-e2e="addressLine1-label" i18n>
              Address line 1
            </dt>
            <dd class="column is-two-thirds" data-e2e="addressLine1-value">
              {{ organisationAddresses.billing.addressLine1 }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.billing?.addressLine2">
            <dt class="column is-one-third has-text-grey" data-e2e="addressLine2-label" i18n>
              Address line 2
            </dt>
            <dd class="column is-two-thirds" data-e2e="addressLine2-value">
              {{ organisationAddresses.billing.addressLine2 }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.billing?.city">
            <dt class="column is-one-third has-text-grey" data-e2e="city-label" i18n>City</dt>
            <dd class="column is-two-thirds" data-e2e="city-value">
              {{ organisationAddresses.billing.city }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.billing?.postCode">
            <dt class="column is-one-third has-text-grey" data-e2e="postCode-label" i18n>
              Post code
            </dt>
            <dd class="column is-two-thirds" data-e2e="postCode-value">
              {{ organisationAddresses.billing.postCode }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.billing?.countryName">
            <dt class="column is-one-third has-text-grey" data-e2e="country-label" i18n>Country</dt>
            <dd class="column is-two-thirds" data-e2e="country-value">
              {{ organisationAddresses.billing.countryName }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.billing?.countrySubdivision">
            <dt class="column is-one-third has-text-grey" data-e2e="province-state-label" i18n>
              Province / State
            </dt>
            <dd class="column is-two-thirds" data-e2e="province-state-value">
              {{ organisationAddresses.billing.countrySubdivision }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.billing?.timezoneId">
            <dt class="column is-one-third has-text-grey" data-e2e="time-zone-label" i18n>
              Time zone
            </dt>
            <dd class="column is-two-thirds" data-e2e="time-zone-value">
              {{ organisationAddresses.billing.timezoneId }}
            </dd>
          </ng-container>
          <ng-container
            *ngIf="
              organisationAddresses?.billing?.geometry?.coordinates &&
              (organisationAddresses?.billing?.geometry?.coordinates)[0] &&
              (organisationAddresses?.billing?.geometry?.coordinates)[1]
            "
          >
            <dt class="column is-one-third has-text-grey" data-e2e="latitude-longitude-label" i18n>
              Latitude / Longitude
            </dt>
            <dd class="column is-two-thirds" data-e2e="latitude-longitude-value">
              {{ organisationAddresses.billing.geometry.coordinates[1] }}
              /
              {{ organisationAddresses.billing.geometry.coordinates[0] }}
            </dd>
          </ng-container>
        </dl>
      </ng-container>
      <ng-container *ngIf="canReadAddresses">
        <span class="has-text-grey is-italic">
          {{ getShippingTradingText() }}
        </span>
      </ng-container>
      <ng-container
        *ngIf="
          canReadAddresses && organisationAddresses.delivery && !isShippingAddressSameAsBilling
        "
      >
        <div class="box__separator"></div>

        <h2 class="title is-3" data-e2e="shipping-address-label" i18n>Shipping address</h2>
        <dl
          class="columns is-multiline is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
        >
          <ng-container *ngIf="organisationAddresses.delivery?.addressLine1">
            <dt
              class="column is-one-third has-text-grey"
              data-e2e="shipping-address-line1-label"
              i18n
            >
              Address line 1
            </dt>
            <dd class="column is-two-thirds" data-e2e="shipping-address-line1-value">
              {{ organisationAddresses.delivery.addressLine1 }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.delivery?.addressLine2">
            <dt
              class="column is-one-third has-text-grey"
              data-e2e="shipping-address-line2-label"
              i18n
            >
              Address line 2
            </dt>
            <dd class="column is-two-thirds" data-e2e="shipping-address-line2-value">
              {{ organisationAddresses.delivery.addressLine2 }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.delivery?.city">
            <dt class="column is-one-third has-text-grey" data-e2e="shipping-city-label" i18n>
              City
            </dt>
            <dd class="column is-two-thirds" data-e2e="shipping-city-value">
              {{ organisationAddresses.delivery.city }}
            </dd>
          </ng-container>

          <ng-container *ngIf="organisationAddresses.delivery?.postCode">
            <dt class="column is-one-third has-text-grey" data-e2e="shipping-post-code-label" i18n>
              Post code
            </dt>
            <dd class="column is-two-thirds" data-e2e="shipping-post-code-value">
              {{ organisationAddresses.delivery.postCode }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.delivery?.countryName">
            <dt class="column is-one-third has-text-grey" data-e2e="shipping-country-label" i18n>
              Country
            </dt>
            <dd class="column is-two-thirds" data-e2e="shipping-country-value">
              {{ organisationAddresses.delivery.countryName }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.delivery?.countrySubdivision">
            <dt
              class="column is-one-third has-text-grey"
              data-e2e="shipping-province-state-label"
              i18n
            >
              Province / State
            </dt>
            <dd class="column is-two-thirds" data-e2e="shipping-province-state-value">
              {{ organisationAddresses.delivery.countrySubdivision }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.delivery?.timezoneId">
            <dt class="column is-one-third has-text-grey" data-e2e="time-zone-label" i18n>
              Time zone
            </dt>
            <dd class="column is-two-thirds" data-e2e="time-zone-value">
              {{ organisationAddresses.delivery.timezoneId }}
            </dd>
          </ng-container>
          <ng-container
            *ngIf="
              organisationAddresses?.delivery?.geometry?.coordinates &&
              (organisationAddresses?.delivery?.geometry?.coordinates)[0] &&
              (organisationAddresses?.delivery?.geometry?.coordinates)[1]
            "
          >
            <dt class="column is-one-third has-text-grey" data-e2e="latitude-longitude-label" i18n>
              Latitude / Longitude
            </dt>
            <dd class="column is-two-thirds" data-e2e="latitude-longitude-value">
              {{ organisationAddresses.delivery.geometry.coordinates[1] }}
              /
              {{ organisationAddresses.delivery.geometry.coordinates[0] }}
            </dd>
          </ng-container>
        </dl>
      </ng-container>
      <ng-container
        *ngIf="canReadAddresses && organisationAddresses.trading && !isTradingAddressSameAsBilling"
      >
        <div class="box__separator"></div>

        <h2 class="title is-3" data-e2e="trading-address-label" i18n>Trading address</h2>
        <dl
          class="columns is-multiline is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
        >
          <ng-container *ngIf="organisationAddresses.trading?.addressLine1">
            <dt
              class="column is-one-third has-text-grey"
              data-e2e="trading-address-line1-label"
              i18n
            >
              Address line 1
            </dt>
            <dd class="column is-two-thirds" data-e2e="trading-address-line1-value">
              {{ organisationAddresses.trading.addressLine1 }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.trading?.addressLine2">
            <dt
              class="column is-one-third has-text-grey"
              data-e2e="trading-address-line2-label"
              i18n
            >
              Address line 2
            </dt>
            <dd class="column is-two-thirds" data-e2e="trading-address-line2-value">
              {{ organisationAddresses.trading.addressLine2 }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.trading?.city">
            <dt class="column is-one-third has-text-grey" data-e2e="trading-city-label" i18n>
              City
            </dt>
            <dd class="column is-two-thirds" data-e2e="trading-city-value">
              {{ organisationAddresses.trading.city }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.trading?.postCode">
            <dt class="column is-one-third has-text-grey" data-e2e="trading-post-code-label" i18n>
              Post code
            </dt>
            <dd class="column is-two-thirds" data-e2e="trading-post-code-value">
              {{ organisationAddresses.trading.postCode }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.trading?.countryName">
            <dt class="column is-one-third has-text-grey" data-e2e="trading-country-label" i18n>
              Country
            </dt>
            <dd class="column is-two-thirds" data-e2e="trading-country-value">
              {{ organisationAddresses.trading.countryName }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.trading?.countrySubdivision">
            <dt
              class="column is-one-third has-text-grey"
              data-e2e="trading-province-state-label"
              i18n
            >
              Province / State
            </dt>
            <dd class="column is-two-thirds" data-e2e="trading-province-state-value">
              {{ organisationAddresses.trading.countrySubdivision }}
            </dd>
          </ng-container>
          <ng-container *ngIf="organisationAddresses.trading?.timezoneId">
            <dt class="column is-one-third has-text-grey" data-e2e="time-zone-label" i18n>
              Time zone
            </dt>
            <dd class="column is-two-thirds" data-e2e="time-zone-value">
              {{ organisationAddresses.trading.timezoneId }}
            </dd>
          </ng-container>
          <ng-container
            *ngIf="
              organisationAddresses?.trading?.geometry?.coordinates &&
              (organisationAddresses?.trading?.geometry?.coordinates)[0] &&
              (organisationAddresses?.trading?.geometry?.coordinates)[1]
            "
          >
            <dt class="column is-one-third has-text-grey" data-e2e="latitude-longitude-label" i18n>
              Latitude / Longitude
            </dt>
            <dd class="column is-two-thirds" data-e2e="latitude-longitude-value">
              {{ organisationAddresses.trading.geometry.coordinates[1] }}
              /
              {{ organisationAddresses.trading.geometry.coordinates[0] }}
            </dd>
          </ng-container>
        </dl>
      </ng-container>
      <ng-container *ngIf="canReadContact">
        <div class="box__separator"></div>

        <h2 class="title is-3" data-e2e="contact-information-label" i18n>Contact information</h2>

        <div class="box__table box__table--sticky-bottom">
          <div class="table-container">
            <table class="table is-fullwidth is-borderless table-ppc">
              <thead>
                <tr>
                  <th data-e2e="name-label" i18n>Name</th>
                  <th data-e2e="contactTypes-label" i18n>Type</th>
                  <th data-e2e="contact-action">
                    <span class="is-sr-only"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let contact of contacts">
                  <td>
                    <div class="has-text-truncate">
                      {{ contact.name.firstName }} {{ contact.name.lastName }}
                    </div>
                  </td>
                  <td>
                    <div class="has-text-truncate">
                      {{ contact.contactType }}
                    </div>
                  </td>
                  <td>
                    <span class="is-sr-only"></span>
                  </td>
                </tr>
                <tr class="has-text-centered" *ngIf="!contacts?.length">
                  <td colspan="3" i18n>No contact information available</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>

      <ng-container *restrictPermission="['features']">
        <div class="box__separator"></div>
        <div class="table-container">
          <ng-container *ngIf="entityFeatures?.length && !errorInFetchingFeatures">
            <h2 class="title is-3" data-e2e="features-access-label" i18n>Feature access</h2>
            <portal-entity-features [features]="entityFeatures"></portal-entity-features>
            <div *ngIf="!entityFeatures?.length" class="has-text-centered">
              <span i18n>No assigned features</span>
            </div>
          </ng-container>
          <ng-container *ngIf="errorInFetchingFeatures">
            <h2 class="title is-3" data-e2e="features-access-label" i18n>Feature access</h2>
            <div class="notification is-warning">
              <div class="icon">
                <i class="far fa-exclamation-circle fa-lg"></i>
              </div>
              <div i18n>
                Server Error, we could not fetch features at this moment. Please see other details
                as of now!
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="canEditWhiteLabel && isWhiteLabelFeatureActiveForOrg">
        <div class="box__separator"></div>

        <portal-detail-reseller-form
          [entityUid]="organisation.entityUid"
          [disabled]="resellerDetailsSaving$ | async"
          [templateParameters]="templateParameters"
          [organisation]="organisation"
          (formValidated)="onResellerDetailsSave($event)"
        ></portal-detail-reseller-form>

        <div class="icon mt-1">
          <i class="far fa-info-circle"></i>
        </div>
        <span class="has-text-grey has-text-weight-normal" i18n>
          Synchronization of the changes can take up to 10 minutes.
        </span>
      </ng-container>

      <ng-container *ngIf="organisation.receiptContract">
        <div class="box__separator"></div>
        <h2 class="title is-3" data-e2e="payment-documents-label" i18n>Payment documents</h2>
        <dl
          class="columns is-multiline is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
        >
          <dt class="column is-one-third has-text-grey" data-e2e="invoiceProvider-label" i18n>
            Invoice provider
          </dt>
          <dd class="column is-two-thirds" data-e2e="invoiceProvider-value">
            {{ organisation.receiptContract?.provider | listValue : invoiceProviderTypes }}
          </dd>
          <dt class="column is-one-third has-text-grey" data-e2e="receiptContractId-label" i18n>
            Receipt contract ID
          </dt>
          <dd class="column is-two-thirds" data-e2e="receiptContractId-value">
            {{ organisation.receiptContract?.receiptContractUid }}
          </dd>
          <ng-container *ngIf="isReceiptProviderInvoice4U">
            <dt
              class="column is-one-third has-text-grey"
              data-e2e="paymentDocumentTypes-label"
              i18n
            >
              Payment document types
            </dt>
            <dd class="column is-two-thirds" data-e2e="paymentDocumentTypes-value">
              {{ paymentDocumentTypeListText }}
            </dd>
          </ng-container>
          <ng-container *ngIf="!isReceiptProviderInvoice4U">
            <dt class="column is-one-third has-text-grey" data-e2e="receipt-template-id-label" i18n>
              Receipt template
            </dt>
            <dd class="column is-two-thirds is-word-wrap" data-e2e="receipt-template-id-value">
              {{ organisation.receiptContract.templateConfiguration?.templates[0]?.templateId }}
            </dd>
            <dt
              class="column is-one-third has-text-grey"
              data-e2e="receipt-merchant-logo-label"
              i18n
            >
              Receipt logo
            </dt>
            <dd class="column is-two-thirds" data-e2e="receipt-merchant-logo-value">
              <img
                class="logo"
                [srcBlob]="this.receiptMerchantLogo"
                alt="-"
                style="max-width: 70%;min-width: 10rem;"
              />
            </dd>
            <dt
              class="column is-one-third has-text-grey"
              data-e2e="receipt-attachment-type-label"
              i18n
            >
              Attachment type
            </dt>
            <dd class="column is-two-thirds" data-e2e="receipt-attachment-type-value">
              {{
                organisation.receiptContract.notifications[0]?.attachmentType
                  | listValue : receiptAttachmentTypes
              }}
            </dd>
            <ng-container
              *ngFor="
                let customTemplate of organisation.receiptContract.templateConfiguration?.templates;
                index as index
              "
            >
              <ng-container *ngIf="index !== 0">
                <dt class="column is-full">
                  <div class="separator">
                    <hr />
                    <div class="separator-container">
                      <div class="legend">and</div>
                    </div>
                  </div>
                </dt>
                <dt
                  class="column is-one-third has-text-grey"
                  data-e2e="custom-receipt-template-label"
                  i18n
                >
                  Custom receipt template
                </dt>
                <dd class="column is-two-thirds is-word-wrap" data-e2e="custom-receipt-template-id">
                  {{ customTemplate.templateId }}
                </dd>
                <dt
                  class="column is-one-third has-text-grey"
                  data-e2e="custom-receipt-channels-label"
                >
                  Sales channel(s)
                </dt>
                <dd class="column is-two-thirds" data-e2e="custom-receipt-template-channels">
                  <span *ngFor="let channel of customTemplate.salesChannels"
                    >{{ receiptSalesChannelsList[channel] }}<br
                  /></span>
                </dd>
              </ng-container>
            </ng-container>
          </ng-container>
        </dl>
        <dl
          class="columns is-multiline is-variable is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
        ></dl>
      </ng-container>

      <ng-container *ngFor="let bankAccount of bankAccounts">
        <div class="box__separator"></div>

        <h2 class="title is-3" i18n>Bank Account</h2>
        <dl class="columns is-multiline">
          <ng-container *ngIf="bankAccount?.accountType">
            <dt class="column is-one-third has-text-grey" i18n>Bank Account Type</dt>
            <dd class="column is-two-thirds" data-e2e="bank-account-ype">
              {{ bankAccount.accountType | splitCamelCase }}
            </dd>
          </ng-container>

          <ng-container *ngIf="bankAccount.accountType === bankAccountType.Domestic">
            <ng-container *ngIf="bankAccount?.accountNumber">
              <dt class="column is-one-third has-text-grey" i18n>Account Number</dt>
              <dd class="column is-two-thirds" data-e2e="account-umber">
                {{ bankAccount.accountNumber }}
              </dd>
            </ng-container>
            <ng-container *ngIf="bankAccount?.routingTransitNumber">
              <dt class="column is-one-third has-text-grey" i18n>ABA routing number</dt>
              <dd class="column is-two-thirds" data-e2e="routing-transit-number">
                {{ bankAccount.routingTransitNumber }}
              </dd>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="bankAccount.accountType === bankAccountType.Sepa">
            <ng-container *ngIf="bankAccount?.iban">
              <dt class="column is-one-third has-text-grey" i18n>IBAN</dt>
              <dd class="column is-two-thirds" data-e2e="iban">
                {{ bankAccount.iban }}
              </dd>
            </ng-container>
            <ng-container *ngIf="bankAccount?.countryName">
              <dt class="column is-one-third has-text-grey" i18n>Country</dt>
              <dd class="column is-two-thirds" data-e2e="country">
                {{ bankAccount.countryName }}
              </dd>
            </ng-container>
          </ng-container>

          <ng-container
            *ngIf="
              bankAccount.accountType === bankAccountType.External &&
              bankAccount?.externalAccountReference
            "
          >
            <dt class="column is-one-third has-text-grey" i18n>External Account Reference</dt>
            <dd class="column is-two-thirds" data-e2e="account-umber">
              {{ bankAccount.externalAccountReference }}
            </dd>
          </ng-container>

          <ng-container *ngIf="bankAccount?.accountName">
            <dt class="column is-one-third has-text-grey" i18n>Account Holder Name</dt>
            <dd class="column is-two-thirds" data-e2e="account-holder-name">
              {{ bankAccount.accountName }}
            </dd>
          </ng-container>
          <ng-container *ngIf="bankAccount?.accountHolderType">
            <dt class="column is-one-third has-text-grey" i18n>Account Holder Type</dt>
            <dd class="column is-two-thirds" data-e2e="account-holder-type">
              {{ bankAccount.accountHolderType }}
            </dd>
          </ng-container>
          <ng-container *ngIf="bankAccount?.currency">
            <dt class="column is-one-third has-text-grey" i18n>Currency</dt>
            <dd class="column is-two-thirds" data-e2e="bankAccount-currency">
              {{ bankAccount.currency }}
            </dd>
          </ng-container>
          <ng-container *ngIf="bankAccount?.description">
            <dt class="column is-one-third has-text-grey" i18n>Description</dt>
            <dd class="column is-two-thirds" data-e2e="bankAccount-description">
              {{ bankAccount.description }}
            </dd>
          </ng-container>

          <ng-container *ngIf="bankAccount?.nickname">
            <dt class="column is-one-third has-text-grey" i18n>Nickname</dt>
            <dd class="column is-two-thirds" data-e2e="bankAccount-nickname">
              {{ bankAccount.nickname }}
            </dd>
          </ng-container>
        </dl>
      </ng-container>

      <!-- Associated Payment Contracts - START -->
      <ng-container *ngIf="canReadPaymentContracts && !isDeletedOrg && !isDisabledOrg">
        <portal-associated-entities
          [organisationId]="organisation?.entityUid"
          associatedLabelName="Associated Payments Provider Contracts"
          paginationId="associatedPaymentContracts"
          associatedEntityType="paymentContracts"
          i18n-associatedLabelName="Payment Provider Contracts associated with this Organisation"
        ></portal-associated-entities>
        <ng-container *ngIf="showMcr()">
          <portal-merchant-choice-routing
            [processorType]="ecomContract.processor.type"
            [editable]="false"
            [orgForm]="form"
            [organisation]="organisation"
          ></portal-merchant-choice-routing>
        </ng-container>
      </ng-container>
      <!-- Associated Payment Contracts - END -->

      <!-- Associated Point of Interaction - START -->
      <ng-container
        *ngIf="
          canReadPointOfInteraction &&
          organisation.entityType === entityType.MERCHANT_SITE &&
          !isDeletedOrg &&
          !isDisabledOrg
        "
      >
        <portal-associated-entities
          [organisationId]="organisation?.entityUid"
          [associatedEntityType]="entityType.MERCHANT_SITE"
          associatedLabelName="Associated Points of Interaction"
          paginationId="associatedPois"
          i18n-associatedLabelName
        ></portal-associated-entities>
      </ng-container>
      <!-- Associated Point of Interaction - END -->

      <!-- Associated Organisation - START -->
      <ng-container
        *ngIf="organisation.entityType === entityType.MERCHANT_COMPANY && !isDeletedOrg"
      >
        <portal-associated-entities
          [organisationId]="organisation?.entityUid"
          [associatedEntityType]="entityType.MERCHANT_COMPANY"
          associatedLabelName="Associated Organisations"
          paginationId="associatedOrganisations"
          i18n-associatedLabelName
        ></portal-associated-entities>
      </ng-container>
      <!-- Associated Organisation - END -->
    </div>

    <ng-container *ngIf="isTokenScopeAvailable && !isDeletedOrg">
      <portal-scope-token
        [organisation]="organisation"
        *restrictPermission="['token-scope']"
      ></portal-scope-token>
    </ng-container>
    <ng-container
      *ngIf="
        isSecureCardCaptureAvailable &&
        organisation?.entityUid === userEntityUid &&
        organisation?.status === statusActive
      "
    >
      <portal-secure-card-capture
        *restrictPermission="['secure-card-capture']"
      ></portal-secure-card-capture>
    </ng-container>

    <div
      class="columns is-vcentered is-variable is-flex is-2-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd"
    >
      <div class="column">
        <ng-container
          *ngIf="organisation?.status === statusActive && !isUsersEntityId(organisation.entityUid)"
        >
          <button
            *restrictPermission="['disable']"
            (click)="confirmDisable()"
            data-e2e="button-disable"
            type="button"
            class="button has-text-danger is-text"
            i18n
            title="Disable organisation"
            i18n-title
          >
            Disable organisation
          </button>
        </ng-container>
        <ng-container *ngIf="organisation?.status === statusInactive">
          <button
            *restrictPermission="['disable']"
            (click)="confirmEnable()"
            data-e2e="button-enable"
            type="button"
            class="button is-primary"
            i18n
            title="Enable organisation"
            i18n-title
          >
            Enable organisation
          </button>
        </ng-container>
      </div>
      <div class="column has-text-right" *ngIf="organisation?.status === statusActive">
        <a
          class="button is-primary is-outlined"
          data-e2e="link-edit"
          restrict-permission
          [routerLink]="['/administration', 'organisations', organisation?.entityUid || '', 'edit']"
          i18n
          title="Click to edit"
          i18n-title
        >
          Edit organisation
        </a>
      </div>
      <div class="column has-text-right" *ngIf="organisation?.status === statusInactive">
        <button
          *restrictPermission="['delete']"
          (click)="confirmDelete()"
          data-e2e="button-delete"
          type="button"
          class="button is-danger is-outlined"
          i18n
          title="Delete organisation"
          i18n-title
        >
          Delete organisation
        </button>
      </div>
    </div>
  </ng-container>
</div>

<portal-base-modal #confirmDisableModal>
  <div title i18n>Disable organisation</div>
  <div description i18n>
    You will not be able to process transactions in <b>{{ organisation?.name }}</b> anymore.
  </div>
  <div class="level" buttons>
    <button
      (click)="confirmDisableModal.close()"
      data-e2e="cancel-button"
      type="button"
      class="level-left button is-text has-text-primary"
      i18n
      title="Cancel"
      i18n-title
    >
      Cancel
    </button>
    <div class="buttons">
      <button
        (click)="onModalDisableConfirmed()"
        data-e2e="disable-button"
        type="button"
        class="button is-danger is-outlined"
        i18n
        title="Disable"
        i18n-title
      >
        Disable
      </button>
      <button
        (click)="confirmDisableModal.close()"
        data-e2e="keep-enabled-button"
        type="button"
        class="button is-primary"
        i18n
        title="Keep Enabled"
        i18n-title
      >
        Keep Enabled
      </button>
    </div>
  </div>
</portal-base-modal>

<portal-base-modal #confirmEnableModal>
  <div title i18n>Enable organisation</div>
  <div description i18n>
    You will be able to process transactions in <b>{{ organisation?.name }}</b
    >.
  </div>
  <div class="level" buttons>
    <button
      (click)="confirmEnableModal.close()"
      type="button"
      data-e2e="cancel-button"
      class="level-left button is-text has-text-primary"
      i18n
      title="Cancel"
      i18n-title
    >
      Cancel
    </button>
    <div class="buttons">
      <button
        (click)="onModalEnableConfirmed()"
        type="button"
        data-e2e="enable-button"
        class="button is-danger is-outlined"
        i18n
        title="Enable"
        i18n-title
      >
        Enable
      </button>
      <button
        (click)="confirmEnableModal.close()"
        type="button"
        data-e2e="keep-disable-button"
        class="button is-primary"
        i18n
        title="Keep Disabled"
        i18n-title
      >
        Keep Disabled
      </button>
    </div>
  </div>
</portal-base-modal>

<portal-base-modal #confirmDeleteModal>
  <div title i18n>Delete organisation</div>
  <div description i18n>
    Are you sure you want to delete the organization <b>{{ organisation?.name }}</b
    >? This will permanently delete their account.
  </div>
  <div modal-content>
    <div class="field">
      <div class="label" i18n>Please select a reason for deletion:</div>
      <div class="control">
        <label class="radio" *ngFor="let option of reasonsForDeletion; let i = index">
          <input
            type="radio"
            name="reason"
            [value]="option.id"
            [formControl]="reason"
            attr.data-e2e="reason-{{ i }}"
          />
          <span>{{ option.text }}</span>
        </label>
        <portal-form-error [formInput]="reason" errorId="reason"></portal-form-error>
      </div>
    </div>
  </div>
  <div class="level" buttons>
    <button
      (click)="confirmDeleteModal.close()"
      data-e2e="button-cancel"
      type="button"
      class="level-left button is-text has-text-primary"
      [disabled]="deleting"
      i18n
      title="Cancel"
      i18n-title
    >
      Cancel
    </button>
    <div class="buttons">
      <button
        (click)="onModalDeleteConfirmed()"
        data-e2e="button-confirm-deletion"
        type="button"
        class="button is-danger is-outlined"
        [class.is-loading]="deleting"
        [disabled]="deleting"
        i18n
        title="Delete"
        i18n-title
      >
        Delete
      </button>
      <button
        (click)="confirmDeleteModal.close()"
        data-e2e="button-keep-organisation"
        type="button"
        class="button is-primary"
        [disabled]="deleting"
        i18n
        title="Keep organisation"
        i18n-title
      >
        Keep Organisation
      </button>
    </div>
  </div>
</portal-base-modal>
