import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ISelectInput } from '@portal/shared/ui/filter';
import { Subject } from 'rxjs';

@Component({
  selector: 'portal-search-filter-radio',
  templateUrl: './search-radio.component.html',
})
export class FilterRadioComponent implements OnInit, OnChanges {
  @Input()
  id: string;

  @Input()
  radioName: string;

  @Input()
  text: string;

  @Input()
  selectedEntities: Map<string, ISelectInput>;

  @Input()
  disabled = false;

  @Input()
  refresh$ = new Subject();

  @Output() selected: EventEmitter<ISelectInput> = new EventEmitter();

  selectedEntity: ISelectInput = {
    id: '',
    text: '',
  };

  ngOnInit(): void {
    this.checkForSelectedRadio();
    this.refresh$.subscribe(() => {
      this.checkForSelectedRadio();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedEntities) {
      this.checkForSelectedRadio();
    }
  }

  onRadioSelected(selectedEntity: string): void {
    const entity: ISelectInput = {
      id: selectedEntity,
      text: this.text,
    };
    this.selected.emit(entity);
  }

  checkForSelectedRadio(): void {
    if (this.selectedEntities && this.selectedEntities.size) {
      const keys = this.selectedEntities.keys();
      this.selectedEntity = this.selectedEntities.get(keys.next().value);
    } else {
      this.selectedEntity = {
        id: '',
        text: '',
      };
    }
  }
}
