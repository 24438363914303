import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { EntityType, IOrganisation } from '@portal/entity-services/interfaces';
import { OrganisationService } from '@portal/entity-services/organisations/src/lib/services/organisation.service';
import { ICount } from '@portal/shared/vui-http/src/lib/interfaces/count.interface';
import { Observable, of } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';

declare const $localize;

@Injectable()
export class EntityValidators {
  constructor(private organisationService: OrganisationService) {}

  /**
   * Async validator for checking that only one merchant company in hierarchy
   * @param entity - The organisation in whose hierarchy we are looking for a merchant company
   * @param isCreateMode - When isCreateMode true entityUid is id of parent organisation
   */
  asyncValidateMerchantCompany(entity: IOrganisation, isCreateMode?: boolean): AsyncValidatorFn {
    // we don't need to find merchant company from children of parent organisation
    const merchantDescendantsCount$: Observable<ICount> = isCreateMode
      ? of({ count: 0 })
      : this.organisationService
          .getTotalCount({
            entityType: EntityType.MERCHANT_COMPANY,
            ancestorId: entity.entityUid,
          })
          .pipe(shareReplay(1));
    const ancestors$ = this.organisationService.getAncestors(entity.entityUid).pipe(shareReplay(1));

    return (control: AbstractControl): Observable<ValidationErrors> => {
      return of(control.value).pipe(
        mergeMap((entityType) => {
          if (
            entityType !== EntityType.MERCHANT_COMPANY ||
            entityType === entity.entityType ||
            !entity.entityUid
          ) {
            return of(undefined);
          }

          return merchantDescendantsCount$.pipe(
            mergeMap((companyCount: ICount) => {
              if (companyCount.count !== 0) {
                return of(true);
              }

              return ancestors$.pipe(
                map((ancestors) => !!this.organisationService.getMerchantCompany(ancestors)),
              );
            }),
            map((hasMerchantCompany) => {
              if (!hasMerchantCompany) {
                return undefined;
              }

              return {
                merchantCompany: {
                  message: '@@HAS_MERCHANT_COMPANY',
                  displayMessage: $localize`There is already a Merchant Company created in your hierarchy`,
                },
              };
            }),
          );
        }),
      );
    };
  }

  asyncValidateParentForMerchantCompany(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return of(control.value).pipe(
        mergeMap((parentEntityId) => {
          if (!parentEntityId) {
            return of(undefined);
          }
          const ancestors$ = this.organisationService
            .getAncestors(parentEntityId)
            .pipe(shareReplay(1));
          return ancestors$.pipe(
            map((ancestors) => this.organisationService.getMerchantCompany(ancestors)),
          );
        }),
        map((merchantEntity) => {
          if (merchantEntity && merchantEntity?.entityType === EntityType.MERCHANT_COMPANY) {
            return {
              parentOrg: {
                message: '@@HAS_MERCHANT_COMPANY',
                displayMessage: $localize`There is already a Merchant Company created in your hierarchy`,
              },
            };
          }
          return undefined;
        }),
      );
    };
  }

  asyncValidateParentForMerchantSite(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return of(control.value).pipe(
        mergeMap((parentEntityId) => {
          if (!parentEntityId) {
            return of(undefined);
          }

          return this.organisationService.getByKey(parentEntityId);
        }),
        map((merchantEntity) => {
          if (merchantEntity && merchantEntity?.entityType !== EntityType.MERCHANT_COMPANY) {
            return {
              parentOrg: {
                message: '@@NOT_MERCHANT_COMPANY',
                displayMessage: $localize`Please select a Merchant company as parent.`,
              },
            };
          }
          return undefined;
        }),
      );
    };
  }
}
