import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TypeDefinitionHelper {
  isPrimitive(value: any): boolean {
    const trivialTypes = ['string', 'number', 'boolean'];

    if (trivialTypes.includes(typeof value)) {
      return true;
    }

    if (this.isArray(value)) {
      return value.every((item) => this.isPrimitive(item));
    }
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  isObject(value: any): boolean {
    if (!value) {
      return false;
    }

    return !this.isArray(value) && typeof value === 'object';
  }

  isDate(value: string): boolean {
    const isNumber = Number.isFinite(Number(value));

    return !isNumber && Boolean(Date.parse(value));
  }
}
