import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppIconComponent } from './app-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AppIconComponent],
  exports: [AppIconComponent],
})
export class AppIconModule {}
