export enum IndustryType {
  Airline = 'AIRLINE',
  Direct = 'DIRECT',
  VechicleRental = 'VEHICLE_RENTAL',
  Lodging = 'LODGING',
  Moto = 'MOTO',
  Petroleum = 'PETROLEUM',
  QuickServiceRestaurant = 'QUICK_SERVICE_RESTAURANT',
  Restaurant = 'RESTAURANT',
  Retail = 'RETAIL',
  Transport = 'TRANSPORT',
  Recreation = 'RECREATION',
  Medical = 'MEDICAL',
  Educational = 'EDUCATIONAL',
  Government = 'GOVERNMENT',
  Internet = 'INTERNET',
  CashAdvance = 'CASH_ADVANCE',
  Unknown = 'UNKNOWN',
  Supermarket = 'SUPERMARKET',
}
