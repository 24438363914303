import { UserRoles } from '@portal/shared/auth/authorization';
import { createPermissions } from '@portal/shared/helpers/src';

export const organisationsPermissions = createPermissions({
  list: {
    read: [
      UserRoles.MerchantAdmin,
      UserRoles.MerchantCashier,
      UserRoles.MerchantReviewer,
      UserRoles.MerchantSupervisor,
      UserRoles.MerchantUser,
      UserRoles.ProviderWhiteLabelManager,
      UserRoles.VerifoneAdmin,
      UserRoles.VerifoneUser,
      UserRoles.DeveloperAdmin,
      UserRoles.DeveloperUser,
      UserRoles.EstateOwnerAdmin,
      UserRoles.EstateOwnerDeviceAppManager,
    ],
  },
  create: {
    read: [UserRoles.VerifoneAdmin, UserRoles.EstateOwnerAdmin],
  },
  edit: {
    read: [UserRoles.VerifoneAdmin, UserRoles.EstateOwnerAdmin],
    'white-label': [UserRoles.VerifoneAdmin],
  },
  details: {
    read: [
      UserRoles.MerchantAdmin,
      UserRoles.MerchantCashier,
      UserRoles.MerchantReviewer,
      UserRoles.MerchantSupervisor,
      UserRoles.MerchantUser,
      UserRoles.ProviderWhiteLabelManager,
      UserRoles.VerifoneAdmin,
      UserRoles.VerifoneUser,
      UserRoles.DeveloperAdmin,
      UserRoles.DeveloperUser,
      UserRoles.EstateOwnerAdmin,
      UserRoles.EstateOwnerDeviceAppManager,
    ],
    disable: [UserRoles.VerifoneAdmin, UserRoles.EstateOwnerAdmin],
    delete: [UserRoles.VerifoneAdmin],
    'secure-card-capture': [
      UserRoles.MerchantAdmin,
      UserRoles.VerifoneAdmin,
      UserRoles.VerifoneUser,
    ],
    features: [UserRoles.VerifoneAdmin],
    'secure-card-capture-edit': [UserRoles.MerchantAdmin, UserRoles.VerifoneAdmin],
    'token-scope': [UserRoles.MerchantAdmin, UserRoles.VerifoneAdmin],
    'white-label': [UserRoles.ProviderWhiteLabelManager],
  },
});
