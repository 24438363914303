import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  Self,
  Optional,
} from '@angular/core';
import { FileService } from '../../services/file.service';
import { SafeUrl } from '@angular/platform-browser';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
  ValidationErrors,
} from '@angular/forms';
import { DimensionsRatioRange } from '@portal/shared/ui/file-input/src/lib/interfaces/dimensions-ratio-range.interface';
import uniqBy from 'lodash-es/uniqBy';
import intersection from 'lodash-es/intersection';

declare const $localize;
const noop = (): void => {};

function merge<T extends {}>(...objs: T[]): T {
  return objs.reduce((result, obj) => ({ ...result, ...obj }), {} as T);
}

@Component({
  selector: 'portal-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements ControlValueAccessor {
  @Input() acceptableFileTypes: string;
  @Input() imageDimensionsRatio: DimensionsRatioRange;
  @Input() title = $localize`Choose a file`;
  @Input() disabled = false;
  @Input() multiple;
  @Input() maxFileSizeInKb: string;
  @Input() imgSrc: string | SafeUrl;

  @Output() fileChanged = new EventEmitter<File[]>();
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  files: File[] = [];
  formControl = new FormControl('');
  filesError: string;
  isTabFocusOnInput = false;
  private onChange: (value: any) => void = noop;
  private onTouched: () => void = noop;

  constructor(private fileService: FileService, @Self() @Optional() private ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  uploadAgain(): void {
    // currently not working for multiple selection, will be fixed in SGC-73602
    this.fileInput.nativeElement.click();
  }

  get control(): AbstractControl {
    return this.ngControl?.control || this.formControl;
  }

  getErrorMessage(fileName: string): string {
    const fileError = this.control.errors[fileName] || {};
    return Object.values<any>(fileError)[0]?.displayMessage;
  }

  onFileDeleted(deletedFile: File): void {
    this.files = this.files.filter((file) => file.name !== deletedFile.name);
    const accumulatedErrors = this.control.errors || {};
    delete accumulatedErrors[deletedFile.name];
    this.onChange(this.files);
    this.addErrors(accumulatedErrors);
    this.filesError = null;
    this.fileChanged.emit(this.files);
  }

  onFileChanged(fileList?: FileList): void {
    const files = fileList ? Array.from(fileList) : [];
    const getName = (file: File): string => file.name;
    let changedFileNames: string[];
    if (this.multiple) {
      changedFileNames = intersection(this.files.map(getName), files.map(getName));
      this.files = uniqBy([...this.files, ...files], 'name');
    } else {
      changedFileNames = this.files.map(getName);
      this.files = [files[0]];
    }

    if (files.length) {
      const accumulatedErrors = this.control.errors || {};
      changedFileNames.forEach((fileName) => {
        delete accumulatedErrors[fileName];
      });
      this.onChange(this.files);
      this.addErrors(accumulatedErrors);
      this.filesError = null;
      this.runAdditionalValidators(files);
      this.runAdditionalAsyncValidators(files);
      this.fileChanged.emit(this.files);
    }
    // To be able to select same file after removing
    this.fileInput.nativeElement.value = '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(files: File[]): void {
    this.files = (files || []).filter(Boolean);
    this.runAdditionalValidators(this.files);
    this.runAdditionalAsyncValidators(this.files);
    this.filesError = null;
  }

  private addErrors(errors: ValidationErrors): void {
    if (errors && Object.keys(errors).length) {
      const control = this.control;
      control.setErrors({
        ...control.errors,
        ...errors,
      });
    }
  }

  private runAdditionalValidators(files: File[]): void {
    const errors = merge(...files.map((file) => this.validateFileType(file)));
    this.addErrors(errors);
  }

  private runAdditionalAsyncValidators(files: File[]): void {
    files.forEach((file) => {
      if (this.fileService.isImage(file) && this.imageDimensionsRatio) {
        this.validateImageDimensions(file);
      }
    });
  }

  private validateImageDimensions(file: File): void {
    const img = new Image();
    const imageLoadHandler = (): void => {
      const dimensionsRatio = img.width / img.height;
      const { min, max } = this.imageDimensionsRatio;
      let errors;

      if (dimensionsRatio > max) {
        errors = {
          [file.name]: {
            imageDimensions: {
              message: '@@IMAGE_ASPECT_RATIO_INVALID',
              displayMessage: $localize`Image aspect ratio is greater than ${max}`,
            },
          },
        };
      }
      if (dimensionsRatio < min) {
        errors = {
          [file.name]: {
            imageDimensions: {
              message: '@@IMAGE_ASPECT_RATIO_INVALID',
              displayMessage: $localize`Image aspect ratio is less than ${min}`,
            },
          },
        };
      }

      this.addErrors(errors);

      URL.revokeObjectURL(img.src);
      img.removeEventListener('load', imageLoadHandler);
      img.remove();
    };

    img.src = URL.createObjectURL(file);
    img.addEventListener('load', imageLoadHandler);
  }

  private validateFileType(validatedFile: File): ValidationErrors {
    if (this.files?.find((file) => file.name === validatedFile.name)) {
      this.filesError = $localize`The uploaded file already exists`;
    }

    if (!this.acceptableFileTypes.includes(this.fileService.getFileExtension(validatedFile))) {
      return {
        [validatedFile.name]: {
          fileExtension: {
            message: '@@FILE_EXTENSION_INVALID',
            displayMessage: $localize`The uploaded file should have a ${this.acceptableFileTypes} extension`,
          },
        },
      };
    }

    return null;
  }
}
