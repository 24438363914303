import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import { Subject } from 'rxjs';
import { catchError, debounceTime, finalize, takeUntil } from 'rxjs/operators';

import {
  EntityStatus,
  IOrganisation,
  IUser,
  UserRole,
  UserStatus,
  UserRoleGroup,
  RolesByGroup,
} from '@portal/entity-services/interfaces';
import { UserAppmarketSettings } from '@portal/marketplace/api-interfaces';
import { FeatureToggle } from '@portal/shared/auth/feature-toggle';
import { LanguageHandle, LanguageOption, LanguageOptions } from '@portal/shared/languages';
import {
  ErrorService,
  FormBase,
  MultilingualValidators,
  UserValidators,
  RegexPatterns,
} from '@portal/shared/ui/form';
import { BaseModalComponent } from '@portal/shared/ui/modal';
import { ISelectInput } from '@portal/shared/ui/filter';
import { UserService } from '@portal/shared/user';
import { UserRoleEditorMatrix } from '../../services/user-role-editor-matrix.service';
import environment from '@environments';
import {
  RestrictPermissionService,
  AuthorizationService,
  SelfCheckService,
  Application,
  IUserApps,
} from '@portal/shared/auth/authorization';
import { Features } from 'environments/enums/features.enum';
import { OrganisationService } from '@portal/entity-services/organisations';
import { ErrorLoggingService } from '@portal/shared/error-handler/src';
import { CountryService } from '@portal/shared/helpers/src';

@Component({
  selector: 'portal-form-user',
  templateUrl: './form.component.html',
})
export class FormUserComponent extends FormBase<IUser> implements OnInit, OnDestroy {
  @ViewChild('resetPasswordModal') resetPasswordModal: BaseModalComponent;
  @ViewChild('changePasswordModal') changePasswordModal: BaseModalComponent;
  @ViewChild('fullNameInput') fullNameInput: ElementRef<HTMLInputElement>;
  @ViewChild('firstNameInput') firstNameInput: ElementRef<HTMLInputElement>;

  @Input() user: IUser;
  @Input() isMyProfile: boolean;
  @Input() isUserNameToCreate: boolean;
  @Input() loggedInUser: IUser;
  @Input() saving = false;

  selfEditMode: boolean;
  roleGroups = [
    UserRoleGroup.Merchant,
    UserRoleGroup.EstateOwner,
    UserRoleGroup.Verifone,
    UserRoleGroup.Developer,
    UserRoleGroup.Provider,
    UserRoleGroup.Reseller,
    UserRoleGroup.Partner,
    UserRoleGroup.Petro,
  ];
  editableRoles: RolesByGroup;
  changePasswordUrl = environment.AUTH.CHANGE_PASSWORD_URL;
  canSwitchLanguage: boolean;
  resetPasswordFeatureActive = this.featureToggleService.isFeatureActive(Features.ChangePassword);
  languagesList: LanguageOption[] = this.langOptions.list;
  hasFormChanged = false;
  initialValue: IUser;
  isInvalid = ErrorService.isFieldInvalid;
  unsubscribe$: Subject<void> = new Subject<void>();
  defaultCountryCode: string;

  isPetroUser = false;

  apps: IUserApps[] = [];
  appList: ISelectInput[] = [];
  selectedLanguage: LanguageOption;
  hideApplyButton = true;
  notificationUpdateStatus = false;
  isUserNameToUpdate = false;
  isVerifoneEmail: boolean;
  isDeveloperCompany = false;
  isUserInContextFromDeveloperCompany = false;
  isNamewithNewConfig = false;
  federationStatus: boolean;
  isLanguageButtonFocused = false;
  loadingDefaultCountry = false;

  constructor(
    fb: FormBuilder,
    private userRoleEditMatrix: UserRoleEditorMatrix,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private featureToggleService: FeatureToggle,
    private selfCheckService: SelfCheckService,
    private userValidators: UserValidators,
    customValidators: MultilingualValidators,
    public languageService: LanguageHandle,
    protected elementRef: ElementRef,
    private langOptions: LanguageOptions,
    private restrictPermissionService: RestrictPermissionService,
    private organisationService: OrganisationService,
    private errorLoggingService: ErrorLoggingService,
    private countryService: CountryService,
  ) {
    super(
      fb.group(
        {
          userUid: [''],
          email: [
            '',
            [customValidators.email, customValidators.required],
            userValidators.asyncValidateExistingUser(),
          ],
          name: ['', [customValidators.validateCharacters, customValidators.maxLength(62)]],
          nameDetails: fb.group({
            firstName: [
              '',
              [
                customValidators.required,
                customValidators.validateCharacters,
                customValidators.maxLength(20),
              ],
            ],
            middleName: ['', [customValidators.validateCharacters, customValidators.maxLength(20)]],
            lastName: ['', [customValidators.validateCharacters, customValidators.maxLength(20)]],
          }),
          roles: [[], [customValidators.required]],
          status: [EntityStatus.Active, customValidators.required],
          entityUid: ['', [customValidators.required]],
          language: [languageService.getBuildLanguage()],
          defaultApplication: [],
          mobilePhoneNumber: ['', customValidators.phone],
        },
        elementRef,
      ),
    );

    const rolesByGroup = this.userRoleEditMatrix.getRolesByGroup(
      this.authorizationService.getRoles(),
      this.roleGroups,
    );
    this.editableRoles = this.filterDisabledFeatureRoles(rolesByGroup);
  }

  ngOnInit(): void {
    this.canSwitchLanguage = this.featureToggleService.isFeatureActive(Features.LanguageSwitching);
    const permissions = this.restrictPermissionService.getPermissionsForRoute(this.activatedRoute);
    this.selfEditMode = this.selfCheckService.isSelfPage(this.activatedRoute.snapshot);
    this.organisationService
      .getByKey(this.authorizationService.getUserEntityId())
      .subscribe((entity) => {
        this.isUserInContextFromDeveloperCompany = entity.entityType === 'DEVELOPER_COMPANY';
      });
    if (
      this.restrictPermissionService.checkPermissions(permissions, ['app-notification-setting'])
    ) {
      /**
       * This block will be removed in the future after API moved to ES
       */
      this.formValidated.subscribe(() => {
        this.userService
          .setAppMarketUserSettings(
            UserAppmarketSettings.AppUpdateNotification,
            this.notificationUpdateStatus,
          )
          .subscribe();
      });
      this.userService.getAppMarketUserSettings().subscribe((data) => {
        let value = data.data.find(
          (input) => input.name === UserAppmarketSettings.AppUpdateNotification,
        )?.value;

        // according to AC value should be 'true' by default
        if (typeof value === 'undefined') {
          value = 'true';
        }

        this.notificationUpdateStatus = value === 'true';
        this.initialValue.notificationUpdateStatus = this.notificationUpdateStatus;
        this.form.controls['entityUid'].valueChanges.subscribe((entityId) => {
          if (entityId) {
            this.organisationService.getByKey(entityId).subscribe((entity) => {
              this.isDeveloperCompany = entity.entityType === 'DEVELOPER_COMPANY';
            });
          }
        });
      });
      /**
       * End of the block
       */
    }

    if (this.user) {
      this.user.entityUid = get(this.user, 'entity.entityUid', this.user.entityUid);
      this.isDeveloperCompany = this.user.entity?.entityType === 'DEVELOPER_COMPANY';
      this.setFormValue(this.user);
      this.email.disable();
      this.apps = this.getAvailableApps();
      this.appList = this.apps.map(({ name, application }) => ({
        id: application,
        text: name,
      }));
      this.setDefaultApplication();
      this.email.setAsyncValidators(this.userValidators.asyncValidateExistingUser(this.user.email));
      if (this.selfEditMode) {
        this.roles.disable();
        this.entityUid.disable();
      } else {
        this.checkingVerifoneEmail(this.user?.email);
      }
      if (!this.isUserNameToCreate) {
        this.name.disable();
      }
      if (this.user.roles.length === 1 && this.user.roles[0] === UserRole.MerchantUnapproved) {
        this.editableRoles.MERCHANT = this.editableRoles.MERCHANT.filter((role) => {
          return role === UserRole.MerchantUnapproved || role === UserRole.MerchantAdmin;
        });
      } else {
        this.removeMerchantUnapprovedRole();
      }
      if (this.authorizationService.isApplicationUser(Application.Petro)) {
        this.isPetroUser = true;
        this.name.disable();
        this.language.disable();
      }
      if (this.user.nameDetails && Object.keys(this.user.nameDetails).length > 0) {
        this.isNamewithNewConfig = true;
        this.isUserNameToUpdate = true;
      }
    } else {
      this.language.setValue(
        this.languageService.resolveAlias(this.languageService.getBuildLanguage()),
      );
      this.removeMerchantUnapprovedRole();
    }
    this.initialValue = this.form.value;
    this.initialValue.notificationUpdateStatus = this.notificationUpdateStatus;
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.updateHasFormChanged());

    this.email.valueChanges.pipe(debounceTime(100)).subscribe((change) => {
      if (change) {
        this.checkingVerifoneEmail(change);
      }
    });

    const lang = this.language.value;
    this.selectedLanguage =
      this.languagesList.find((i) => i.id === lang) ||
      this.languageService.getCurrentObjectLanguage(lang);

    this.setDefaultCountryCode();
  }

  setDefaultCountryCode(): void {
    if (window.localStorage.getItem('defaultCountryCode')) {
      this.defaultCountryCode = window.localStorage.getItem('defaultCountryCode');
    } else {
      this.loadingDefaultCountry = true;
      this.organisationService
        .getEntityByID(this.authorizationService.getUserEntityId())
        .pipe(
          catchError((err) => {
            this.errorLoggingService.postErrorLog(err);
            return null;
          }),
          finalize(() => (this.loadingDefaultCountry = false)),
        )
        .subscribe((entity) => {
          if (entity) {
            this.defaultCountryCode = this.countryService.countriesAlpha3.get(
              entity['locale']?.countryCode,
            )?.alpha2;
          }
        });
    }
  }

  onOrganisationSelected(organisation: IOrganisation): void {
    this.federationStatus = organisation?.federationStatus;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get canSaveUser(): boolean {
    if (!this.user) {
      return true;
    }

    return (
      this.hasFormChanged &&
      this.user?.status === UserStatus.Active &&
      (this.authorizationService.hasPermissionToManage(this.user.roles) || this.selfEditMode)
    );
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get firstName(): AbstractControl {
    return this.form.get('nameDetails.firstName');
  }

  get middleName(): AbstractControl {
    return this.form.get('nameDetails.middleName');
  }

  get lastName(): AbstractControl {
    return this.form.get('nameDetails.lastName');
  }

  get alternateFullName(): AbstractControl {
    return this.form.get('nameDetails.alternateFullName');
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get phoneNumberControl(): AbstractControl {
    return this.form.get('mobilePhoneNumber');
  }

  get roles(): AbstractControl {
    return this.form.get('roles');
  }

  get entityUid(): AbstractControl {
    return this.form.get('entityUid');
  }

  get language(): AbstractControl {
    return this.form.get('language');
  }

  get defaultApplication(): AbstractControl {
    return this.form.get('defaultApplication');
  }

  setIsUserNameToUpdate(): void {
    this.isUserNameToUpdate = true;
  }

  checkFirstName(): void {
    // this.firstName mandatory value, force to enter first name when we update users without first name
    if (!this.isUserNameToCreate && !this.firstName.value && this.canSaveUser) {
      this.isUserNameToUpdate = true;
    }
  }

  onSetRoles(roles: UserRole[]): void {
    this.roles.patchValue(roles);
  }

  toShowDeveloperNotificationSettings(): boolean {
    return (
      (this.isDeveloperCompany && !this.isMyProfile) ||
      (this.isMyProfile && this.isUserInContextFromDeveloperCompany)
    );
  }

  onResetPasswordModalConfirmed(): void {
    const userUid = this.user.userUid;

    this.userService
      .resetPassword({
        data: { userUid },
      })
      .pipe(
        finalize(() => {
          this.resetPasswordModal.close();
        }),
      )
      .subscribe();
  }

  onChangePasswordModalConfirmed(): void {
    // Redirect to change password in the authentication server
    window.location.assign(this.changePasswordUrl);
    this.changePasswordModal.close();
  }

  getAvailableApps(): IUserApps[] {
    const appsPermissions = this.authorizationService.appsPermissions;
    if (appsPermissions?.length) {
      return this.authorizationService.getApps(appsPermissions);
    }

    return [];
  }

  onSelectLanguage(language: LanguageOption): void {
    this.selectedLanguage = language;
    this.language.setValue(this.languageService.resolveAlias(language?.id));
  }

  setDefaultApplication(): void {
    if (this.apps.length < 2) {
      return;
    }

    const savedAppKey = this.userService.defaultApplication || Application.PaymentsPortal;

    if (this.apps.find((item) => item.application === savedAppKey)) {
      this.defaultApplication.setValue(savedAppKey);
    } else {
      this.defaultApplication.setValue(this.apps[0].application);
    }
  }

  onNotificationUpdate(event: Event): void {
    const target = <HTMLInputElement>event.target;
    this.notificationUpdateStatus = target.checked;
    this.updateHasFormChanged();
  }

  updateHasFormChanged(): void {
    this.hasFormChanged = !isEqual(this.initialValue, {
      ...this.form.value,
      ...(typeof this.initialValue.notificationUpdateStatus !== 'undefined'
        ? { notificationUpdateStatus: this.notificationUpdateStatus }
        : null),
    });
  }

  onTabKeyNavigation(): void {
    this.isLanguageButtonFocused = document.activeElement === document.getElementById('langButton');
    if (document.getElementById('langDropdown').classList.contains('is-active')) {
      document.querySelectorAll('.langListButton').forEach((elem) => {
        if (elem === document.activeElement) {
          elem.classList.add('has-border-primary');
        } else {
          elem.classList.remove('has-border-primary');
        }
      });
    }
  }

  private removeMerchantUnapprovedRole(): void {
    this.editableRoles.MERCHANT = this.editableRoles?.MERCHANT?.filter((role) => {
      return role !== UserRole.MerchantUnapproved;
    });
  }

  private filterDisabledFeatureRoles(rolesByGroup: RolesByGroup): RolesByGroup {
    const featureDisabledRoles = [
      {
        name: Features.VHQCore,
        roles: [UserRole.VerifoneHelpdesk],
      },
    ];

    return Object.keys(rolesByGroup).reduce((filteredRolesByGroup, key) => {
      filteredRolesByGroup[key] = rolesByGroup[key].filter((role: UserRole) => {
        return featureDisabledRoles.every((feature) => {
          const isFeatureActive = this.featureToggleService.isFeatureActive(feature.name);
          return isFeatureActive || !feature.roles.includes(role);
        });
      });

      return filteredRolesByGroup;
    }, {});
  }

  private checkingVerifoneEmail(email: string): void {
    this.isVerifoneEmail = RegexPatterns.verifoneEmail.test(email?.toLowerCase());
  }
}
