import { Pipe, PipeTransform } from '@angular/core';
import { get, isArray } from 'lodash';

@Pipe({ name: 'getItem' })
export class GetItemPipe implements PipeTransform {
  transform(item: any, path: string, placeholder: string = '—'): string {
    let value = get(item, path, placeholder);
    if (isArray(value)) {
      value = value.join(', ');
    }
    if (value === '' || !value) {
      return '—';
    }
    return value;
  }
}
