import { IFilterToApply } from '@portal/shared/ui/filter/src';
import { IQueryParams } from '@portal/shared/ui/table/src';
import { entitiyservices, vfappmarket } from '../services.url';

export const ESTATE_OWNERS_API = {
  GET_USERS: (pagination?: IQueryParams, filters?: IFilterToApply[], parentId?: string): string => {
    if (filters.length > 0) {
      return `${entitiyservices}/entities?parentId=${parentId}&entityType=ESTATE_OWNER&limit=${pagination.limit.toString()}&start=${pagination.start.toString()}&name=${
        filters[0]?.argument
      }`;
    }
    return `${entitiyservices}/entities?parentId=${parentId}&entityType=ESTATE_OWNER&limit=${pagination.limit.toString()}&start=${pagination.start.toString()}`;
  },
  GET_USERS_COUNT: (pagination?: IQueryParams, filters?: IFilterToApply[]): string => {
    if (filters.length > 0) {
      return `${entitiyservices}/entities?entityType=ESTATE_OWNER/count&limit=${pagination.limit.toString()}&start=${pagination.start.toString()}&name=${
        filters[0]?.argument
      }`;
    }
    return `${entitiyservices}/entities?entityType=ESTATE_OWNER/count&limit=${pagination.limit.toString()}&start=${pagination.start.toString()}`;
  },
  PUBLISH_APP_TO_EOS: (): string => {
    return `${vfappmarket}/v1/apps/acquirers`;
  },
};
