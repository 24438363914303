import { ToastNotification } from './toast-notification';

export class ToastNotifier {
  notifications: ToastNotification[] = [];

  destroy(notification: ToastNotification): void {
    this.notifications.splice(this.notifications.indexOf(notification), 1);
  }

  add(notification: ToastNotification): void {
    this.notifications.unshift(notification);
  }
}
