import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { MultiselectTreeItem } from '@portal/shared/ui/tree/src/lib/portal-multiselect-tree/multiselect-tree-item.interface';

declare const $localize;

@Component({
  selector: 'portal-input-dropdown',
  templateUrl: './portal-input-dropdown.component.html',
  styleUrls: ['./portal-input-dropdown.component.scss'],
})
// ToDo: refactor this control to implement ControlValueAccessor
export class PortalInputDropdownComponent implements OnInit, OnDestroy, OnChanges {
  @Input() placeholder: string = $localize`Select`;
  @Input() items: MultiselectTreeItem[] = [];
  @Input() isDisabled: boolean;
  @Input() rootDepth = 0;
  @Output() selectItems = new EventEmitter<MultiselectTreeItem[]>();

  selectedItems: MultiselectTreeItem[] = [];
  roots: { item: MultiselectTreeItem; selectedCount: number }[] = [];
  isOpen = false;

  private clickListener: () => void;

  constructor(private elementRef: ElementRef, private renderer2: Renderer2) {}

  ngOnInit(): void {
    this.selectItems.emit(this.newSelectedItems);
    this.clickListener = this.renderer2.listen('document', 'click', ({ target }) => {
      if (!this.elementRef.nativeElement.contains(target)) {
        this.isOpen = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.setSelectedRoots(this.rootDepth, this.items);
    }
  }

  changeDropdownState(): void {
    if (this.isDisabled) return;
    this.isOpen = !this.isOpen;
  }

  getSelectedItems(selectedItems: MultiselectTreeItem[]): void {
    this.selectItems.emit(selectedItems);
    this.setSelectedRoots(this.rootDepth, this.items);
    this.isOpen = false;
  }

  removeSelected(item: MultiselectTreeItem): void {
    if (this.isDisabled) return;
    item.checked = false;
    if (item.children.length) {
      this.uncheckAllChildren(item.children);
    }
    this.setSelectedRoots(this.rootDepth, this.items);
    this.selectItems.emit(this.newSelectedItems);
  }

  uncheckAllChildren(children: MultiselectTreeItem[]): void {
    children.forEach((child) => {
      child.checked = false;
      if (child.children.length) {
        this.uncheckAllChildren(child.children);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.clickListener) {
      this.clickListener();
    }
  }

  private setSelectedRoots(depth: number, items: MultiselectTreeItem[]): void {
    if (this.rootDepth === depth) {
      this.roots = [];
    }
    items.forEach((item) => {
      if (item.checked || this.isPartiallySelected(item.children)) {
        if (depth === 0) {
          const selectedCount = item.children.length ? this.getSelectedCount(item.children) : 0;
          this.roots.push({ item, selectedCount });
        } else {
          this.setSelectedRoots(depth - 1, item.children || []);
        }
      }
    });
  }

  private isPartiallySelected(children: MultiselectTreeItem[]): boolean {
    if (children.length) {
      return !!children.find((child) => {
        if (child.checked) {
          return true;
        } else {
          return this.isPartiallySelected(child.children);
        }
      });
    }
    return false;
  }

  private getSelectedCount(children: MultiselectTreeItem[]): number {
    let selectedCount = 0;
    children.forEach((child) => {
      if (child.checked) {
        selectedCount++;
      }

      if (child.children.length) {
        selectedCount = selectedCount + this.getSelectedCount(child.children);
      }
    });
    return selectedCount;
  }

  private get newSelectedItems(): MultiselectTreeItem[] {
    const items = [];
    this.items.forEach((item) => this.fillSelectedItems(items, item));
    return items;
  }

  private fillSelectedItems(selectedItems: MultiselectTreeItem[], item: MultiselectTreeItem): void {
    if (item?.checked && !this.isPartiallySelected(item.children)) {
      selectedItems.push(item);
    }

    if (item?.children?.length) {
      item.children.forEach((childItem) => {
        this.fillSelectedItems(selectedItems, childItem);
      });
    }
  }
}
