// https://confluence.verifone.com:8443/pages/viewpage.action?pageId=260083414
export enum UnavailableDeviceReason {
  'assigned.to.that.app.version' = 'The application is already installed on this terminal',
  'assigned.to.other.app.version' = 'A different version of the application is already installed on this terminal',
  'app.install.pending' = 'The application is in the process of being installed on this terminal',
  'app.uninstall.pending' = 'The application is in the process of being removed from this terminal',
  'assigned.to.that.bundle.version' = 'The bundle is already installed on this terminal',
  'assigned.to.other.bundle.version' = 'A different version of the bundle is already installed on this terminal',
  'assigned.to.any.app.from.bundle' = 'One or more of the applications in this bundle are already installed on this terminal as part of a different bundle',
  'assigned.to.newer.app.from.bundle' = 'A newer version of at least one of the applications in this bundle is already installed on this terminal',
  'bundle.install.pending' = 'The bundle is in the process of being installed on this terminal',
  'bundle.uninstall.pending' = 'The application is in the process of being removed from this terminal',
  'no.other.version.installed' = 'No other version is installed',
  'none' = 'No info',
}
