import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'vui-off-canvas',
  templateUrl: './off-canvas.component.html',
  styleUrls: ['./off-canvas.component.scss'],
})
export class OffCanvasComponent implements OnInit, OnDestroy {
  private _element: any;
  private _isActive = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onOpen: EventEmitter<any> = new EventEmitter();
  @Input() title?: string;

  get activeState(): boolean {
    return this._isActive;
  }

  set activeState(state: boolean) {
    this._isActive = state;
  }

  // eslint-disable-next-line
  constructor(private el: ElementRef) {
    this._element = el.nativeElement;
  }
  ngOnInit(): void {
    this._element.addEventListener('click', this.onClickOutside.bind(this));
  }

  ngOnDestroy(): void {
    this._element.removeEventListener('click', this.onClickOutside.bind(this));
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (this.activeState && event.key === 'Escape') {
      this.close();
    }
  }

  onClickOutside(event: any): void {
    if (event.target.className === 'modal-background') {
      this.close();
    }
  }

  open(): void {
    this.activeState = true;
    this.onOpen.emit(this.activeState);
  }

  close(): void {
    this._isActive = false;
    this.onClose.emit(this.activeState);
  }

  toggle(): void {
    this.activeState = !this._isActive;
    if (this.activeState) {
      this.onOpen.emit(this.activeState);
    } else {
      this.onClose.emit(this.activeState);
    }
  }
}
