import { IGuardPermissions } from '@portal/shared/auth/authorization/src';
import { RouteName } from './route-name.enum';
import { UserRoles } from '@portal/shared/auth/authorization/src/lib/enums/user-roles.enum';

export const routePermissions: { [key in RouteName]?: IGuardPermissions } = {
  [RouteName.List]: {
    read: [
      UserRoles.MerchantAdmin,
      UserRoles.MerchantCashier,
      UserRoles.MerchantReviewer,
      UserRoles.MerchantSupervisor,
      UserRoles.MerchantUser,
      UserRoles.ProviderWhiteLabelManager,
      UserRoles.VerifoneAdmin,
      UserRoles.VerifoneUser,
      UserRoles.MerchantExternalPartner,
    ],
    Refund: [UserRoles.MerchantSupervisor],
    Capture: [UserRoles.MerchantSupervisor],
    Void: [UserRoles.MerchantSupervisor, UserRoles.MerchantCashier],
    accessReceipts: [
      UserRoles.MerchantAdmin,
      UserRoles.MerchantSupervisor,
      UserRoles.MerchantCashier,
      UserRoles.VerifoneAdmin,
    ],
    VoidCapture: [UserRoles.MerchantSupervisor, UserRoles.MerchantCashier],
  },
};
