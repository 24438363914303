import { Component, OnInit } from '@angular/core';
import { ISelectInput } from '../../box/interfaces/select-input.interface';
import { IFilterToApply } from '../../search/interfaces/filter-to-apply.interface';
import { BaseFilter } from '../base-filter';

@Component({
  selector: 'portal-search-text-filter',
  templateUrl: './search-text-filter.component.html',
})
export class SearchTextFilterComponent extends BaseFilter implements OnInit {
  searchTerm = '';
  searchValue = '';
  entitySearched: any;

  constructor() {
    super();
  }

  selectChange(): void {}

  ngOnInit(): void {
    this.entityRemoved$.subscribe((entityToRemove: ISelectInput) => {
      if (entityToRemove.id === this.searchValue) {
        this.searchTerm = '';
        this.searchValue = '';
        this.filterUpdated.emit({
          key: entityToRemove.selector,
          selector: entityToRemove.selector,
          toRemove: true,
        });
        this.filterApplied.emit();
        this.itemToggled.emit();
      }
    });
  }
  onFilterApplied(): void {
    if (this.searchTerm === '' || this.searchTerm === this.searchValue) {
      return;
    }
    if (this.selector) {
      this.filterUpdated.emit({
        key: this.selector,
        toRemove: true,
      });
      this.searchValue = this.searchTerm;
      this.filterUpdated.emit(this.constructSearchOutput());
      this.filterApplied.emit();
      this.itemToggled.emit();
      this.isOpen = false;
    }
  }

  private constructSearchOutput(): IFilterToApply {
    return {
      key: this.selector,
      operator: 'cn',
      argument: this.searchValue,
      selector: this.selector,
    };

    // this.updateText();
  }
}
