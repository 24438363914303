<nav
  class="breadcrumb is-marginless is-small"
  aria-label="breadcrumbs"
  data-e2e="app-page-breadcrumbs"
>
  <ul>
    <li *ngFor="let breadcrumb of breadcrumbs">
      <a [routerLink]="breadcrumb.url" aria-current="page" routerLinkActive="router-link-active">
        {{ breadcrumb.label }}
      </a>
    </li>
    <li class="is-active">
      <a><ng-content></ng-content></a>
    </li>
  </ul>
</nav>
