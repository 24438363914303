import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateRangeFilterComponent } from './date-range-filter.component';

@NgModule({
  imports: [CommonModule],
  declarations: [DateRangeFilterComponent],
  exports: [DateRangeFilterComponent],
})
export class DateRangeFilterModule {}
