<portal-filter-wrapper>
  <div class="dropdown-content" [class.is-hidden]="!isOpen">
    <a class="is-hidden" (click)="openContent()" id="filter-{{ selector }}-detail">
      <i class="fas fa-chevron-right" aria-hidden="true"></i>
    </a>
    <div class="dropdown-item">
      <a (click)="backToMenu()" data-e2e="button-back-to-menu">
        <span class="icon is-small">
          <i class="fas fa-chevron-left fa-xs" aria-hidden="true"></i>
        </span>
        <span i18n>Back</span>
      </a>
      <h2 class="title is-4 has-margin-top has-margin-bottom" i18n>{{ filterName }}</h2>
      <portal-search-filter-autocomplete-input
        (search)="onSearch($event)"
      ></portal-search-filter-autocomplete-input>
    </div>
    <hr class="dropdown-divider" />
    <ng-container *ngIf="showSelectedEntities.size">
      <div class="dropdown-item">
        <label i18n>Selected</label>
        <ng-container *ngFor="let entity of showSelectedEntities | keyvalue">
          <portal-search-filter-radio
            [id]="entity.key"
            [text]="entity.value.text"
            [selectedEntities]="localPreSelectedEntities"
            (added)="onEntityAdded($event)"
          ></portal-search-filter-radio>
        </ng-container>
      </div>
      <hr class="dropdown-divider" />
    </ng-container>
    <div class="dropdown-item is-scrollable">
      <ng-container *ngFor="let entity of entities">
        <portal-search-filter-radio
          [id]="entity.id"
          [text]="entity.text"
          [selectedEntities]="localPreSelectedEntities"
          (selected)="onEntityAdded($event)"
        ></portal-search-filter-radio>
      </ng-container>
    </div>
    <hr class="dropdown-divider" />
    <div class="dropdown-item">
      <button
        class="button is-primary"
        (click)="onFilterApplied()"
        data-e2e="button-filter"
        type="button"
        i18n
        title="Apply"
        i18n-title
      >
        Apply
      </button>
    </div>
  </div>
</portal-filter-wrapper>
