import { EventEmitter, Output, Directive } from '@angular/core';
import { Observable } from 'rxjs';

@Directive()
export abstract class FilterContentItem {
  @Output()
  abstract filterApplied: EventEmitter<any>;

  itemToggled: EventEmitter<number>;
  isOpen: boolean;

  abstract onFilterApplied(): void;
  abstract getList(): Observable<any>;
}
