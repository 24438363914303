import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import environment from '@environments';
import { IBundle } from '@portal/marketplace/api-interfaces/src';
import { from, Observable } from 'rxjs';

@Component({
  selector: 'marketplace-bundle-logo-template',
  templateUrl: './bundle-logo-template.component.html',
  styleUrls: ['./bundle-logo-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BundleLogoTemplateComponent implements OnInit, OnChanges {
  @ViewChild('bundleIcon') bundleIcon: ElementRef<any>;
  @Input() bundle: IBundle;

  isPublic = true;
  publicImageSrc = 'assets/images/bundles/bundle-no-image.svg';

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bundle.currentValue) {
      const isPublic = !!changes.bundle.currentValue.isPublic;

      if (isPublic == null) {
        if (!this.hasPublicUrl()) {
          this.isPublic = true;
        }
      } else if (isPublic) {
        this.isPublic = true;
        if (this.hasPublicUrl()) {
          this.publicImageSrc = `${environment.API_ENDPOINT.PUBLIC_APPMARKET.publicBucketUrl}/bundle_icons/${this.bundle.appmarketBundleId}`;
        }
      } else if (isPublic === false && !this.hasPublicUrl()) {
        // bundle has no image and it's not public
        this.isPublic = true;
      } else {
        this.loadImageFromCatalog();
      }
    }
  }

  handleMissingImage(): void {
    this.loadImageFromCatalog();
  }

  private loadImageFromCatalog(): void {
    this.isPublic = false;
    this.take(this.bundle.bundleIcon).subscribe(
      (image) => this.drawImage(image),
      () => {
        this.isPublic = true;
        this.publicImageSrc = 'assets/images/bundles/bundle-no-image.svg';
      },
    );
  }

  private hasPublicUrl(): boolean {
    return this.bundle.bundleIcon && this.bundle.bundleIcon.length !== 0;
  }

  private take(url: string): Observable<any> {
    const accessToken = JSON.parse(localStorage.getItem('access_token'));

    const obs = from(
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          throw new Error(`Response status: ${response.status}`);
        })
        .catch(() => {}),
    );

    return obs;
  }

  private drawImage(blob: Blob): void {
    if (blob == null) {
      return;
    }

    createImageBitmap(blob).then((image) => {
      this.draw(image);
    });
  }

  private draw(img: ImageBitmap | HTMLImageElement): void {
    this.bundleIcon.nativeElement.width = img.width;
    this.bundleIcon.nativeElement.height = img.height;
    const ctx = this.bundleIcon.nativeElement.getContext('2d');

    ctx.drawImage(img, 0, 0, img.width, img.height);
  }
}
