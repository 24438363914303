<div
  class="spinner-container"
  [class.is-vertical]="isVertical"
  [class.is-horizontal]="isHorizontal"
>
  <div
    data-e2e="spinner"
    class="spinner"
    [class.show-text]="showText && !inlineText"
    [class.inline-text]="inlineText"
    [class.is-large]="isLarge"
    [class.is-small]="isSmall"
    [class.is-medium]="isMedium"
    role="status"
    aria-live="assertive"
  >
    <span *ngIf="showSpan">{{ text }}</span>
  </div>
  <div
    *ngIf="showDiv"
    [class.pl-2]="isHorizontal"
    [class.pt-1]="isVertical"
    [class.is-size-4]="isLarge"
    [class.is-size-6]="isSmall"
    [class.is-size-5]="isMedium"
  >
    {{ text }}
  </div>
</div>
