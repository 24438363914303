<div class="hierarchy dropdown" [class.is-active]="isOpen">
  <div class="dropdown-trigger">
    <button
      [attr.aria-expanded]="isOpen"
      class="button is-chip is-size-7-mobile"
      [class.is-active]="isOpen"
      [class.is-applied]="selectedNodes?.length"
      data-e2e="button-hierarchy-filter"
      (click)="openMenu()"
      title="{{ entityText }}"
      type="button"
    >
      <span>{{ entityText }}</span>
      <span class="icon">
        <i class="fas fa-chevron-down"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu">
    <div class="dropdown-content" [class.is-hidden]="!isOpen">
      <div class="field is-grouped is-grouped-multiline filter-container is-relative">
        <div class="dropdown-item">
          <p class="control has-icons-left">
            <input
              #searchRef
              class="input is-small"
              type="text"
              placeholder="Search by Name in Hierarchy"
              (input)="tree.filterNodes($event.target.value)"
              data-e2e="input-search-autocomplete"
              portalAutoFocus
            />
            <span class="icon is-small is-left">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
          </p>
        </div>
      </div>
      <hr class="dropdown-divider" />
      <div class="dropdown-item is-scrollable">
        <portal-tree
          #tree
          [nodes]="nodeData"
          [showCheckBox]="true"
          [showCount]="showCount"
          [isCalculateCount]="isCalculateCount"
          [disableCheckbox]="disableCheckbox"
          (modifiedNodeData)="nodeSelectEvent($event)"
          [selectedNodeIds]="selectedNodes"
        >
        </portal-tree>
      </div>
      <hr class="dropdown-divider" />
      <div class="dropdown-item">
        <button
          class="button is-primary"
          (click)="onFilterApplied()"
          data-e2e="button-filter"
          type="button"
          i18n
          title="Apply"
          i18n-title
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</div>
