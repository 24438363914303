import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TreeModule } from '@circlon/angular-tree-component';
import { PortalTreeComponent } from './portal-tree/portal-tree.component';
import { PortalMultiselectTreeComponent } from '@portal/shared/ui/tree/src/lib/portal-multiselect-tree/portal-multiselect-tree.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { FormModule } from '@portal/shared/ui/form';
import { PortalInputDropdownComponent } from '@portal/shared/ui/tree/src/lib/portal-input-dropdown/portal-input-dropdown.component';
import { MatChipsModule } from '@angular/material/chips';
import { SpinnerModule } from '@portal/shared/ui/spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { PipesModule } from '@portal/shared/pipes/src/lib/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    MatTreeModule,
    MatIconModule,
    FormsModule,
    TreeModule,
    FormModule,
    MatChipsModule,
    MatIconModule,
    SpinnerModule,
    MatDividerModule,
    MatListModule,
    PipesModule,
  ],
  declarations: [PortalTreeComponent, PortalMultiselectTreeComponent, PortalInputDropdownComponent],
  exports: [PortalTreeComponent, PortalMultiselectTreeComponent, PortalInputDropdownComponent],
})
export class PortalTreeModule {}
