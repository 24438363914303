import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { code } from 'currency-codes';
import { sortBy } from 'lodash';
import { ICurrency } from './interfaces/currency.interface';
import { CurrencyByCountry } from './enum/currency-by-country.enum';

declare const $localize;

@Injectable({ providedIn: 'root' })
export class CurrencyService {
  currencies: ICurrency[] = sortBy(
    [
      {
        name: $localize`:Name of currency with code MMK:Kyat`,
        code: 'MMK',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BIF:Burundi Franc`,
        code: 'BIF',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code KHR:Riel`,
        code: 'KHR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code CAD:Canadian Dollar`,
        code: 'CAD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code CVE:Cape Verde Escudo`,
        code: 'CVE',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code KYD:Cayman Islands Dollar`,
        code: 'KYD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code LKR:Sri Lanka Rupee`,
        code: 'LKR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code CLP:Chilean Peso`,
        code: 'CLP',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code CNY:Yuan Renminbi`,
        code: 'CNY',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code COP:Colombian Peso`,
        code: 'COP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code KMF:Comoro Franc`,
        code: 'KMF',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code CRC:Costa Rican Colon`,
        code: 'CRC',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code HRK:Croatian Kuna`,
        code: 'HRK',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code CUP:Cuban Peso`,
        code: 'CUP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code CYP:Cyprus Pound`,
        code: 'CYP',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code CZK:Czech Koruna`,
        code: 'CZK',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code DKK:Danish Krone`,
        code: 'DKK',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code DOP:Dominican Peso`,
        code: 'DOP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code SVC:El Salvador Colon`,
        code: 'SVC',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code ETB:Ethiopian Birr`,
        code: 'ETB',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code ERN:Nakfa`,
        code: 'ERN',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code EEK:Kroon`,
        code: 'EEK',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code FKP:Falkland Islands Pound`,
        code: 'FKP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code FJD:Fiji Dollar`,
        code: 'FJD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code DJF:Djibouti Franc`,
        code: 'DJF',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code GMD:Dalasi`,
        code: 'GMD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code GIP:Gibraltar Pound`,
        code: 'GIP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code GTQ:Quetzal`,
        code: 'GTQ',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code GNF:Guinea Franc`,
        code: 'GNF',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code GYD:Guyana Dollar`,
        code: 'GYD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code HTG:Gourde`,
        code: 'HTG',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code HNL:Lempira`,
        code: 'HNL',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code HKD:Hong Kong Dollar`,
        code: 'HKD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code HUF:Forint`,
        code: 'HUF',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code ISK:Iceland Krona`,
        code: 'ISK',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code INR:Indian Rupee`,
        code: 'INR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code IDR:Rupiah`,
        code: 'IDR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code IRR:Iranian Rial`,
        code: 'IRR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code IQD:Iraqi Dinar`,
        code: 'IQD',
        exponential: 3,
      },
      {
        name: $localize`:Name of currency with code ILS:New Israeli Sheqel`,
        code: 'ILS',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code JMD:Jamaican Dollar`,
        code: 'JMD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code JPY:Yen`,
        code: 'JPY',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code KZT:Tenge`,
        code: 'KZT',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code JOD:Jordanian Dinar`,
        code: 'JOD',
        exponential: 3,
      },
      {
        name: $localize`:Name of currency with code KES:Kenyan Shilling`,
        code: 'KES',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code KPW:North Korean Won`,
        code: 'KPW',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code KRW:Won`,
        code: 'KRW',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code KWD:Kuwaiti Dinar`,
        code: 'KWD',
        exponential: 3,
      },
      {
        name: $localize`:Name of currency with code KGS:Som`,
        code: 'KGS',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code LAK:Kip`,
        code: 'LAK',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code LBP:Lebanese Pound`,
        code: 'LBP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code LSL:Loti`,
        code: 'LSL',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code LVL:Latvian Lats`,
        code: 'LVL',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code LRD:Liberian Dollar`,
        code: 'LRD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code LYD:Libyan Dinar`,
        code: 'LYD',
        exponential: 3,
      },
      {
        name: $localize`:Name of currency with code LTL:Lithuanian Litas`,
        code: 'LTL',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MOP:Pataca`,
        code: 'MOP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MWK:Kwacha`,
        code: 'MWK',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MYR:Malaysian Ringgit`,
        code: 'MYR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MVR:Rufiyaa`,
        code: 'MVR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MTL:Maltese Lira`,
        code: 'MTL',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code MRO:Ouguiya`,
        code: 'MRO',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MUR:Mauritius Rupee`,
        code: 'MUR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MXN:Mexican Peso `,
        code: 'MXN',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MNT:Tugrik`,
        code: 'MNT',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MDL:Moldovan Leu`,
        code: 'MDL',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MAD:Moroccan Dirham`,
        code: 'MAD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code OMR:Rial Omani`,
        code: 'OMR',
        exponential: 3,
      },
      {
        name: $localize`:Name of currency with code NAD:Namibia Dollar`,
        code: 'NAD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code NPR:Nepalese Rupee`,
        code: 'NPR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code ANG:Netherlands Antillian Guilder`,
        code: 'ANG',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code AWG:Aruban Guilder`,
        code: 'AWG',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code VUV:Vatu`,
        code: 'VUV',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code NZD:New Zealand Dollar`,
        code: 'NZD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code NIO:Cordoba Oro`,
        code: 'NIO',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code NGN:Naira`,
        code: 'NGN',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code NOK:Norwegian Krone`,
        code: 'NOK',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code PKR:Pakistan Rupee`,
        code: 'PKR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code PAB:Balboa`,
        code: 'PAB',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code PGK:Kina`,
        code: 'PGK',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code PYG:Guarani`,
        code: 'PYG',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code PEN:Nuevo Sol`,
        code: 'PEN',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code PHP:Philippine Peso`,
        code: 'PHP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code GWP:Guinea-Bissau Peso`,
        code: 'GWP',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code QAR:Qatari Rial`,
        code: 'QAR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code RUB:Russian Ruble`,
        code: 'RUB',

        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code RWF:Rwanda Franc`,
        code: 'RWF',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code SHP:Saint Helena Pound`,
        code: 'SHP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code STD:Dobra`,
        code: 'STD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code SAR:Saudi Riyal`,
        code: 'SAR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code SCR:Seychelles Rupee`,
        code: 'SCR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code SLL:Leone`,
        code: 'SLL',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code SGD:Singapore Dollar`,
        code: 'SGD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code SKK:Slovak Koruna`,
        code: 'SKK',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code VND:Dong`,
        code: 'VND',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code SOS:Somali Shilling`,
        code: 'SOS',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code ZAR:Rand`,
        code: 'ZAR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code ZWD:Zimbabwe Dollar`,
        code: 'ZWD',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code SZL:Lilangeni`,
        code: 'SZL',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code SEK:Swedish Krona`,
        code: 'SEK',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code CHF:Swiss Franc`,
        code: 'CHF',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code SYP:Syrian Pound`,
        code: 'SYP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code THB:Baht`,
        code: 'THB',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code TOP:Paanga`,
        code: 'TOP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code TTD:Trinidad and Tobago Dollar`,
        code: 'TTD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code AED:UAE Dirham`,
        code: 'AED',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code TND:Tunisian Dinar`,
        code: 'TND',
        exponential: 3,
      },
      {
        name: $localize`:Name of currency with code TMM:Manat`,
        code: 'TMM',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code UGX:Uganda Shilling`,
        code: 'UGX',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code MKD:Denar`,
        code: 'MKD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code RUR:Russian Ruble (obsolete)`,
        code: 'RUR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code EGP:Egyptian Pound`,
        code: 'EGP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code GBP:Pound Sterling`,
        code: 'GBP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code TZS:Tanzanian Shilling`,
        code: 'TZS',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code USD:US Dollar`,
        code: 'USD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code UYU:Peso Uruguayo `,
        code: 'UYU',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code UZS:Uzbekistan Sum`,
        code: 'UZS',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code WST:Tala`,
        code: 'WST',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code YER:Yemeni Rial`,
        code: 'YER',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code ZMK:Kwacha`,
        code: 'ZMK',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code TWD:New Taiwan Dollar`,
        code: 'TWD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code GHS:Ghana Cedi`,
        code: 'GHS',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code VEF:Bolivar Fuerte`,
        code: 'VEF',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code SDG:Sudanese Pound`,
        code: 'SDG',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code UYI:Uruguay Peso en Unidades Indexadas`,
        code: 'UYI',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code RSD:Serbian Dinar`,
        code: 'RSD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MZN:Metical`,
        code: 'MZN',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code AZN:Azerbaijanian Manat`,
        code: 'AZN',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code RON:New Leu`,
        code: 'RON',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code CHE:WIR Euro`,
        code: 'CHE',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code CHW:WIR Franc`,
        code: 'CHW',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code TRY:New Turkish Lira`,
        code: 'TRY',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code XAF:CFA Franc BEAC`,
        code: 'XAF',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XCD:East Caribbean Dollar`,
        code: 'XCD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code XOF:CFA Franc BCEAO †`,
        code: 'XOF',

        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XPF:CFP Franc`,
        code: 'XPF',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XBA:Bond Markets Units European Composite Unit (EURCO)`,
        code: 'XBA',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XBB:European Monetary Unit (E.M.U.-6)`,
        code: 'XBB',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XBC:European Unit of Account 9(E.U.A.-9)`,
        code: 'XBC',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XBD:European Unit of Account 17(E.U.A.-17)`,
        code: 'XBD',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XAU:Gold`,
        code: 'XAU',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XDR:SDR`,
        code: 'XDR',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XAG:Silver`,
        code: 'XAG',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XPT:Platinum`,
        code: 'XPT',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XTS:Codes specifically reserved for testing purposes`,
        code: 'XTS',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XPD:Palladium`,
        code: 'XPD',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code SRD:Surinam Dollar`,
        code: 'SRD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MGA:Malagasy Ariary`,
        code: 'MGA',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code COU:Unidad de Valor Real`,
        code: 'COU',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code AFN:Afghani`,
        code: 'AFN',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code TJS:Somoni`,
        code: 'TJS',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code AOA:Kwanza`,
        code: 'AOA',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BYR:Belarussian Ruble`,
        code: 'BYR',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code BGN:Bulgarian Lev`,
        code: 'BGN',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code CDF:Franc Congolais`,
        code: 'CDF',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BAM:Convertible Marks`,
        code: 'BAM',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code EUR:Euro`,
        code: 'EUR',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code MXV:Mexican Unidad de Inversion (UDI)`,
        code: 'MXV',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code UAH:Hryvnia`,
        code: 'UAH',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code GEL:Lari`,
        code: 'GEL',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BOV:Mvdol`,
        code: 'BOV',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code PLN:Zloty`,
        code: 'PLN',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BRL:Brazilian Real`,
        code: 'BRL',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code CLF:Unidades de fomento`,
        code: 'CLF',
        exponential: 4,
      },
      {
        name: $localize`:Name of currency with code USN:US Dollar (Next day)`,
        code: 'USN',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code USS:US Dollar (Same day)`,
        code: 'USS',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code XXX:The codes assigned for transactions where no currency is involved are:`,
        code: 'XXX',
        exponential: '',
      },
      {
        name: $localize`:Name of currency with code ALL:Lek`,
        code: 'ALL',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code DZD:Algerian Dinar`,
        code: 'DZD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code ARS:Argentine Peso`,
        code: 'ARS',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code AMD:Armenian Dram`,
        code: 'AMD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code AUD:Australian Dollar`,
        code: 'AUD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BSD:Bahamian Dollar`,
        code: 'BSD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BHD:Bahraini Dinar`,
        code: 'BHD',
        exponential: 3,
      },
      {
        name: $localize`:Name of currency with code BDT:Taka`,
        code: 'BDT',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BBD:Barbados Dollar`,
        code: 'BBD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BZD:Belize Dollar`,
        code: 'BZD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BMD:Bermudian Dollar`,
        code: 'BMD',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BTN:Ngultrum`,
        code: 'BTN',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BOB:Boliviano`,
        code: 'BOB',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BWP:Pula`,
        code: 'BWP',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code BND:Brunei Dollar`,
        code: 'BND',
        exponential: 2,
      },
      {
        name: $localize`:Name of currency with code SBD:Solomon Islands Dollar`,
        code: 'SBD',
        exponential: 2,
      },
    ],
    'name',
  );

  currenciesCode = new Map<string, ICurrency>(
    this.currencies.map((currency: ICurrency) => {
      return [currency.code, currency];
    }),
  );

  decimal: string;
  thousands: string;

  constructor(@Inject(LOCALE_ID) private locale: string) {
    const testValue = 3500.99;

    const formatterOptions: any = {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'name',
    };
    // Transform 3500.99 into the same number formatted as a currency by the browser according to current browser language (ex: "3 500,99 EUR")
    const formatter = new Intl.NumberFormat(this.locale, formatterOptions);
    /* // Use this code when Internet Explorer is no longer supported by the Common Portal
    const options: any[] = formatter.formatToParts(testValue);
    this.decimal = options.find((info : {type: string}) => info.type === 'decimal').value;
    this.thousands = options.find((info : {type: string}) => info.type === 'group').value;
    */
    let formattedNumber: String = formatter.format(testValue);
    // Fix to Internet Explorer which puts "EUR " before the value
    formattedNumber = formattedNumber.replace('EUR', '').trim();
    // If there is no separator for thousands we get the decimals separator one index earlier
    this.decimal =
      formattedNumber.charAt(5) !== '9' ? formattedNumber.charAt(5) : formattedNumber.charAt(4);
    // If the 2nd character isn't '5' we get the separator
    this.thousands = formattedNumber.charAt(1) !== '5' ? formattedNumber.charAt(1) : '';
  }

  format(amount: number, currencyCode?: string): string {
    if (!currencyCode) {
      console.log(`Missing currency code! ${currencyCode}`);
      return null;
    }

    const currency = this.currenciesCode.get(currencyCode);

    if (!currency) {
      console.log(`Unknown currency code ${currencyCode}`);
      return null;
    }

    const exponential = currency.exponential;

    const n = amount / Math.pow(10, exponential) || 0;
    const fixed = n.toFixed(exponential);

    return `${fixed} ${currencyCode}`;
  }

  getCurrencyDigits(currencyCode: string): number {
    const { digits = 2 } = code(currencyCode ?? '') || {};
    return digits;
  }

  formatAmount(amount: any, currencyCode: string): number {
    if (typeof amount !== 'number') {
      return amount;
    }

    const digits = this.getCurrencyDigits(currencyCode);
    return Number((amount * 10 ** digits).toFixed(0));
  }

  parseAmount(amount: any, currencyCode: string): number {
    if (typeof amount !== 'number') {
      return amount;
    }
    const digits = this.getCurrencyDigits(currencyCode);
    return amount / 10 ** digits;
  }

  roundOff(amount: number, currencyCode: string): number {
    if (typeof amount !== 'number') {
      return amount;
    }

    const digits = this.getCurrencyDigits(currencyCode);
    return Number(amount.toFixed(digits));
  }

  getCurrencyByCountry(countryCode: string): string {
    return CurrencyByCountry[countryCode] || '';
  }

  getDefaultCurrencyCode(currenciesArray: ICurrency[]): string {
    if (!currenciesArray.length) {
      return '';
    }
    const storageDefCurrency = window.localStorage.getItem('defaultCurrencyCode');
    if (storageDefCurrency && this.arrayHasCurrencyCode(currenciesArray, storageDefCurrency)) {
      return storageDefCurrency;
    }
    const storageCurrencyId = window.localStorage.getItem('currencyId');
    if (storageCurrencyId && this.arrayHasCurrencyCode(currenciesArray, storageCurrencyId)) {
      return storageCurrencyId;
    }
    const localCountryCode = window.localStorage.getItem('defaultCountryCode');
    if (localCountryCode) {
      const localCurrencyByCountry = this.getCurrencyByCountry(localCountryCode);
      if (this.arrayHasCurrencyCode(currenciesArray, localCurrencyByCountry)) {
        return localCurrencyByCountry;
      }
    }
    return this.arrayHasCurrencyCode(currenciesArray, 'USD') ? 'USD' : currenciesArray[0].code;
  }

  arrayHasCurrencyCode(currenciesArray: ICurrency[], code: string): boolean {
    if (!code || !currenciesArray.length) {
      return false;
    }
    return (
      currenciesArray.filter((cur) => {
        return cur.code === code;
      }).length > 0
    );
  }

  setLocalStorageDefaultCurrencyCode(currencyCode: string): void {
    window.localStorage.setItem('defaultCurrencyCode', currencyCode);
    window.localStorage.setItem('currencyId', currencyCode);
  }
}
