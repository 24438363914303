<div class="dropdown" [class.is-active]="isOpen">
  <div class="dropdown-trigger">
    <button
      [attr.aria-expanded]="isOpen"
      [class.is-applied]="localPreSelectedEntities.size && isFilterApplied"
      (click)="itemToggled.emit()"
      class="button is-chip is-size-7-mobile"
      type="button"
      title="User"
      i18n-title
    >
      <span>
        <span i18n>User</span>
        <span
          *ngIf="localPreSelectedEntities.size && isFilterApplied"
          data-e2e="userFilter-selected-count"
        >
          (+{{ localPreSelectedEntities.size }})
        </span>
      </span>
      <span class="icon">
        <i class="fas fa-chevron-down"></i>
      </span>
    </button>
  </div>

  <div class="dropdown-menu">
    <div class="dropdown-content">
      <div class="dropdown-item">
        <portal-search-filter-autocomplete-input
          placeholder="Search for user"
          i18n-placeholder="Search input placeholder"
          (search)="onSearch($event)"
        ></portal-search-filter-autocomplete-input>

        <label class="checkbox mt-3">
          <input [(ngModel)]="isInactiveVisible" (change)="onInactiveChange()" type="checkbox" />
          <span i18n>Show inactive users</span>
        </label>
      </div>

      <hr class="dropdown-divider" />

      <ng-container *ngIf="!(loading$ | async); else loadingTemplate">
        <div *ngIf="!users.length" class="dropdown-item" i18n>No users found</div>
        <div class="dropdown-item is-scrollable">
          <ng-container *ngFor="let user of users">
            <portal-search-filter-checkbox
              [id]="user.userUid"
              [text]="user.name"
              [selectedEntities]="localPreSelectedEntities"
              (added)="onEntityAdded($event)"
              (removed)="onEntityRemoved($event)"
            ></portal-search-filter-checkbox>
            <div class="has-text-grey pb-2" *ngIf="user?.email">{{ user.email }}</div>
          </ng-container>
        </div>

        <hr class="dropdown-divider" />

        <div class="dropdown-item">
          <button
            class="button is-primary"
            data-e2e="event-types-filter-button-apply"
            (click)="onFilterApplied()"
            type="button"
            title="Apply"
            i18n-title
          >
            <span i18n>Apply</span>
          </button>
          <button
            *ngIf="localPreSelectedEntities.size"
            (click)="onFilterClear()"
            class="button is-transparent button-clear-filter ml-2"
            type="button"
            title="Clear"
            i18n-title
          >
            <span i18n>Clear</span>
          </button>
        </div>
      </ng-container>

      <ng-template #loadingTemplate>
        <div class="dropdown-item has-text-truncate">
          <portal-spinner
            [inlineText]="true"
            [isSmall]="true"
            text="Loading..."
            i18n-text
          ></portal-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>
