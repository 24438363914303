import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'portal-tooltip-blocking-rules',
  templateUrl: './tooltip.component.html',
})
export class TooltipBlockingRulesComponent implements OnInit {
  @Input() show: Subject<boolean>;
  showTooltip: boolean;

  ngOnInit(): void {
    this.show.pipe(delay(0)).subscribe(show => (this.showTooltip = show));
  }
}
