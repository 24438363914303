import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IApp, IBundle } from '@portal/marketplace/api-interfaces';

@Component({
  selector: 'marketplace-bundle-page-template',
  templateUrl: './bundle-page-template.component.html',
  styleUrls: ['./bundle-page-template.component.scss'],
})
export class BundlePageTemplateComponent implements OnInit {
  @Input() set bundle(bundle: IBundle) {
    if (bundle) {
      this.currentBundle = bundle;
      this.selectedVersion = bundle.bundleVersionId;

      this.updateShowIsDefaultMessage();
    }
  }
  @Input() apps: IApp[] = [];
  @Input() appsLoadingState: boolean;
  @Input() bundleLoadingState: boolean;
  @Output() navigateToBundlesList: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectVersion: EventEmitter<string> = new EventEmitter<string>();
  @Output() navigateToAppPage = new EventEmitter();

  selectedVersion: string;

  showIsDefaultMessage = false;

  private currentBundle: IBundle;

  get bundle(): IBundle {
    return this.currentBundle;
  }

  constructor() {}

  ngOnInit(): void {}

  countAppsByPricingModel(pricingModel: string): number {
    let counter = 0;
    for (const app of this.apps) {
      if (app.pricingModel === pricingModel) {
        counter++;
      }
    }
    return counter;
  }

  navigateBundlesListClick(): void {
    this.navigateToBundlesList.emit();
  }

  getAppPrice(app: IApp, bundle: IBundle): number {
    const appConList = bundle.bundleAppConList;
    for (const appInConList of appConList) {
      if (appInConList.appmarketAppId === app.appmarketAppId) {
        return appInConList.appBundlePrice > 0 ? appInConList.appBundlePrice : app.price;
      }
    }
  }

  onSelectVersion(version: string): void {
    this.selectVersion.emit(version);
  }

  calculatePrice(app: IApp): number {
    const bundleAppConList = this.bundle.bundleAppConList;
    for (const bundleApp of bundleAppConList) {
      if (app.appmarketAppId === bundleApp.appmarketAppId) return bundleApp.appBundlePrice;
    }
  }

  private updateShowIsDefaultMessage(): void {
    const hasDefaultAppCatalogApp = !!this.currentBundle.assignedAppmarketApps.find(
      (app) => app.appcatalogIsDefaultApp,
    );
    this.showIsDefaultMessage = hasDefaultAppCatalogApp;
  }
}
