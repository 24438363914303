import { Injectable } from '@angular/core';
import { SelectInputsList } from '@portal/shared/helpers/src/lib/create-list-from-inputs/select-input-list';
import { ISelectInput } from '@portal/shared/ui/filter';
import sortBy from 'lodash-es/sortBy';
import { TransactionType } from './enums/transaction-type.enum';
import { SupportedProcessorSettingsService } from '@apps/portal/src/app/services/supported-processor-settings.service';
import { TranslationsKeyValue } from '@portal/shared/helpers/src/lib/create-list-from-inputs/interfaces/translations-key-value.interface';

declare const $localize;

@Injectable()
export class TransactionTypes extends SelectInputsList {
  keyValue: TranslationsKeyValue = {
    [TransactionType.Authorisation]: $localize`:transaction type|:Authorisation`,
    [TransactionType.Balance]: $localize`:transaction type|:Balance`,
    [TransactionType.Cancel]: $localize`:transaction type|:Cancel`,
    [TransactionType.Capture]: $localize`:transaction type|:Capture`,
    [TransactionType.CardActivation]: $localize`:transaction type|:Card activation`,
    [TransactionType.CardDeactivation]: $localize`:transaction type|:Card deactivation`,
    [TransactionType.CardVerification]: $localize`:transaction type|:Card verification`,
    [TransactionType.CashAdvance]: $localize`:transaction type|:Cash advance`,
    [TransactionType.CashDeposit]: $localize`:transaction type|:Cash deposit`,
    [TransactionType.ClearBatch]: $localize`:transaction type|:Clear batch`,
    [TransactionType.Declined]: $localize`:transaction type|:Declined`,
    [TransactionType.DelayedCharge]: $localize`:transaction type|:Delayed charge`,
    [TransactionType.ForexRate]: $localize`:transaction type|:Forex rate`,
    [TransactionType.Load]: $localize`:transaction type|:Load`,
    [TransactionType.NoShow]: $localize`:transaction type|:No show`,
    [TransactionType.NonFinancial]: $localize`:transaction type|:Non financial`,
    [TransactionType.PreAuth]: $localize`:transaction type|:PreAuthorisation`,
    [TransactionType.PreAuthIncrement]: $localize`:transaction type|:Preauth increment`,
    [TransactionType.PreInquiry]: $localize`:transaction type|:Preauth inquiry`,
    [TransactionType.PreAuthCompletion]: $localize`:transaction type|:Preauth completion`,
    [TransactionType.PreUpload]: $localize`:transaction type|:Preauth upload`,
    [TransactionType.PreReversal]: $localize`:transaction type|:Preauth reversal`,
    [TransactionType.Settlement]: $localize`:transaction type|:Settlement`,
    [TransactionType.Rate]: $localize`:transaction type|:Rate`,
    [TransactionType.Refund]: $localize`:transaction type|:Refund`,
    [TransactionType.RefundVoid]: $localize`:transaction type|A refund that has been voided:Refund void`,
    [TransactionType.Reversal]: $localize`:transaction type|:Reversal`,
    [TransactionType.Sale]: $localize`:transaction type|:Sale`,
    [TransactionType.Unload]: $localize`:transaction type|:Unload`,
    [TransactionType.Void]: $localize`:transaction type|:Void`,
    [TransactionType.Reauthorisation]: $localize`:transaction type|:Reauthorisation`,
    [TransactionType.Extend]: $localize`:transaction type|:Extend`,
    [TransactionType.FundsTransfer]: $localize`:transaction type|:Funds transfer`,
    [TransactionType.Hold]: $localize`:transaction type|:Hold`,
    [TransactionType.Payout]: $localize`:transaction type|:Payout`,
    [TransactionType.Release]: $localize`:transaction type|:Release`,
    [TransactionType.Chargeback]: $localize`:transaction type|:Chargeback`,
    [TransactionType.DisputeDebit]: $localize`:transaction type|:Dispute debit`,
    [TransactionType.DisputeCredit]: $localize`:transaction type|:Dispute credit`,
    [TransactionType.RedemptionOrder]: $localize`:transaction type|:Redemption Order`,
  };

  list: ISelectInput[];

  constructor(private supportedProcessorSettingsService: SupportedProcessorSettingsService) {
    super();
    this.getSortedList();
  }

  getSupportedTransactionTypes(): ISelectInput[] {
    const supportedProcessorSettings =
      this.supportedProcessorSettingsService.getSupportedProcessorSettingsFromSessionStorage();
    if (supportedProcessorSettings?.transactionTypesSupported?.length) {
      const supportedTransactionTypes: TranslationsKeyValue = {};
      supportedProcessorSettings.transactionTypesSupported.forEach((product) => {
        supportedTransactionTypes[product] = this.keyValue[product] || product;
      });
      this.getSortedList(supportedTransactionTypes);
    }
    return this.list;
  }

  getSortedList(transactionTypes = this.keyValue): void {
    this.list = sortBy(this.getSelectInputs(transactionTypes), (item: ISelectInput) =>
      item.text.toLowerCase(),
    );
  }

  filteredList = (trxTypes: TransactionType[]): ISelectInput[] => {
    return trxTypes.map((trxType) => {
      return {
        id: trxType,
        text: this.keyValue[trxType],
      };
    });
  };
}
