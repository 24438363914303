export interface IAccountDetails {
  AcqInstId?: number;
  returnPolicyMessage?: string;
  retailerReceiptData?: string;
  smbPricing?: boolean;
  logoUrl?: string;
  organisationId?: string;
  username?: string;
  password?: string;
  acquiringInstitutionCode?: string;
  forwardingInstitutionCode?: string;
  fundsTransferAccountType?: string;
  fundsTransferAccountNumber?: string;
  tokenTypeId?: string;
  apiKey?: string;
  clientId?: string;
  eftposMTC?: string;
  visaMVV?: string;
  mastercardMAID?: string;
}
