import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IApp } from '@portal/marketplace/api-interfaces';
import { IQueryParams, ISort, ITableConfig } from '@portal/shared/ui/table';
import { Observable } from 'rxjs';

@Component({
  selector: 'marketplace-app-catalog-table-template',
  templateUrl: './app-catalog-table-template.component.html',
  styleUrls: ['./app-catalog-table-template.component.scss'],
})
export class AppCatalogTableTemplateComponent implements OnInit {
  @Input() apps: IApp[];
  @Input() loading$: Observable<boolean>;
  @Input() rowLink = '/app-catalog/page';
  @Input() filter: IQueryParams;
  @Output() sortChanged: EventEmitter<any> = new EventEmitter();

  @Input() set tableConfig(tableConfig: ITableConfig) {
    // this should re-setted in afterViewInit,
    // but as it is passed as @Input,
    // we should put it new zone loop tick
    setTimeout(() => {
      this.tableConfigView = tableConfig;
    });
  }

  tableConfigView: ITableConfig = {
    columns: [],
    rowIdKey: '',
    rowLabelKey: '',
  };

  constructor() {}

  ngOnInit(): void {}

  onSortChanged(sort: ISort): void {
    this.sortChanged.emit(sort);
  }
}
